import React, { useRef } from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core';
import { SelectField, withForm } from '@manakin/core';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
    root: {
        [theme.breakpoints.up('md')]: {
            marginTop: '0',
            width: '30rem',
        },
        '& > div': {
            margin: 0,
        },
        '& .MuiFormLabel-root': {
            color: theme.manakin.defaultContentColor[500],
        },
        '& .MuiInput-input': {
            padding: '21.5px 0',
            '& > div > div': {
                marginTop: -26,
            },
            '& > div:nth-child(2)': {
                marginTop: 30,
            },
        },
    },
});

const sortOptions = (t) => [
    { id: 'DEFAULT_ASC', label: t('app.dashboard.filters.boxes.options.default'), value: { programBoxSortColumn: 'DEFAULT', descending: false } },
    { id: 'DATE_ASC', label: t('app.dashboard.filters.boxes.options.date-asc'), value: { programBoxSortColumn: 'DATE', descending: false } },
    { id: 'DATE_DESC', label: t('app.dashboard.filters.boxes.options.date-desc'), value: { programBoxSortColumn: 'DATE', descending: true } },
    { id: 'PROGRESS_ASC', label: t('app.dashboard.filters.boxes.options.progress-asc'), value: { programBoxSortColumn: 'PROGRESS', descending: false } },
    { id: 'PROGRESS_DESC', label: t('app.dashboard.filters.boxes.options.progress-desc'), value: { programBoxSortColumn: 'PROGRESS', descending: true } },
    { id: 'TITLE_ASC', label: t('app.dashboard.filters.boxes.options.title'), value: { programBoxSortColumn: 'TITLE', descending: false } },
];

const FilterSortBoxes = (props) => {
    const { classes, form, programData, onChange } = props;
    const { t } = useTranslation();
    const options = useRef(sortOptions(t));

    const handleChange = (data) => {
        if (data) {
            const option = options.current.find(option => option.id === data.value);
            if (option && onChange) {
                onChange(option.value);
            }
        }
    };

    if (!programData) return '';

    return (
        <div className={classes.root}>
            <SelectField
                options={options.current}
                name='sort'
                onChange={handleChange}
                label={t('app.dashboard.filters.boxes.label')}
                initialValue={
                    { id: `${programData.boxSortColumn}_${programData.boxSortDirection}` }
                }
                form={form}
            />
        </div>
    );
};

export default compose(
    withForm({}),
    withStyles(styles, { name: 'AppDashboardFilter' }),
)(FilterSortBoxes);
