'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = exports.styles = function styles(theme) {
    return {
        root: _extends({}, theme.manakin.largeWrapper, _defineProperty({
            maxWidth: '1500px',
            width: '100%',
            display: 'flex',
            padding: '4.8rem 0',
            transition: 'opacity .2s, transform .2s .2s',
            opacity: 0,
            transform: 'translateX(50px)'
        }, theme.breakpoints.up('md'), {
            padding: '50px'
        })),
        largeRoot: _extends({}, theme.manakin.extraLargeWrapper, _defineProperty({
            width: '100%',
            display: 'flex',
            opacity: 0,
            padding: '4.8rem 0',
            transition: 'opacity .2s, transform .2s .2s',
            transform: 'translateX(50px)'
        }, theme.breakpoints.up('md'), {
            padding: '50px'
        })),
        animateIn: {
            transition: 'opacity .2s, transform .2s',
            transform: 'translateX(0)',
            opacity: 1
        }
    };
};