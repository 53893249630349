"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _core = require("@material-ui/core");

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactRenderHtml = require("react-render-html");

var _reactRenderHtml2 = _interopRequireDefault(_reactRenderHtml);

var _AccountWrapper = require("../AccountWrapper");

var _AccountWrapper2 = _interopRequireDefault(_AccountWrapper);

var _graphql = require("./graphql");

var _reactHooks = require("@apollo/react-hooks");

var _reactRouterDom = require("react-router-dom");

var _appCore = require("@manakin/app-core");

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useStyles = (0, _core.makeStyles)(function (theme) {
	return {
		root: {},
		embed: {
			marginTop: "20px"
		}
	};
});

var Book = function Book(props) {
	var classes = useStyles();
	var params = (0, _reactRouterDom.useParams)();
	var history = (0, _reactRouterDom.useHistory)();

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var _useQuery = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_BOOK, {
		variables: {
			id: params.bookId
		}
	}),
	    data = _useQuery.data,
	    loading = _useQuery.loading,
	    error = _useQuery.error;

	var _ref = data || {},
	    book = _ref.book;

	var handleClick = function handleClick() {
		history.goBack();
	};

	if (error) t("app.account.books.details.loading-failed");
	if (loading) return _react2.default.createElement(_appCore.Loader, null);
	return _react2.default.createElement(
		_AccountWrapper2.default,
		{
			title: book.title || t("app.account.books.details.title"),
			wrapper: "medium"
		},
		_react2.default.createElement(
			"div",
			{ className: classes.root },
			_react2.default.createElement(_appCore.BackButton, { onClick: handleClick }),
			_react2.default.createElement(
				"div",
				{ className: classes.embed },
				(0, _reactRenderHtml2.default)(book.embedCode)
			)
		)
	);
};

exports.default = Book;