'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _graphql = require('../graphql');

var _VideoPlayer = require('@manakin/app-core/VideoPlayer');

var _VideoPlayer2 = _interopRequireDefault(_VideoPlayer);

var _reactHooks = require('@apollo/react-hooks');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var VideoBridge = function VideoBridge(props) {
	var _classNames;

	var classes = props.classes,
	    item = props.item;

	var _useQuery = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_VIDEO_BRIDGE, {
		variables: {
			id: item.id
		}
	}),
	    _useQuery$data = _useQuery.data,
	    data = _useQuery$data === undefined ? {} : _useQuery$data;

	var _data$videoBridge = data.videoBridge,
	    videoBridge = _data$videoBridge === undefined ? {} : _data$videoBridge;

	var _ref = videoBridge || {},
	    videoId = _ref.vimeoId,
	    _ref$image = _ref.image,
	    image = _ref$image === undefined ? {} : _ref$image;

	return _react2.default.createElement(
		'div',
		{
			className: (0, _classnames2.default)(classes.root, (_classNames = {}, _defineProperty(_classNames, classes.fullWidth, item.fullWidth), _defineProperty(_classNames, classes.noBackground, true), _defineProperty(_classNames, classes.show, true), _classNames), _defineProperty({}, classes.primaryBackgroundColor, item.backgroundColor == 'SECONDARY'), _defineProperty({}, classes.secondaryBackgroundColor, item.backgroundColor == 'PRIMARY')),
			style: { transition: 'all .5s .' + (1 + 1) + 's' }
		},
		data && videoId && _react2.default.createElement(_VideoPlayer2.default, {
			videoId: videoId,
			playing: false,
			withControls: true,
			fullWidth: false,
			placeholderImage: image ? image.url || null : null
		})
	);
};

exports.default = VideoBridge;