import gql from 'graphql-tag';
import { asset } from '@manakin/app-core/graphQlHelpers';

export const GQL_FETCH_SLIDESHOW = gql`
    query($id: ID!) {
        slideshow(id: $id) {
            id
            title
            slides {
                id
                title
                text
                asset {
                    ${asset}
                }
            }
        }
    }
`;
