import { makeStyles, createStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				pointerEvents: 'visible',
				width: '100%',
			},
			selected: {},
			listItemText: {
				margin: '0 0 0 10px',
			},
			menuItem: {
				height: '50px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				backgroundColor: 'white',
				'&:nth-child(even)': {
					backgroundColor: '#f2f2f2',
				},
				'&:hover': {
					backgroundColor: theme.palette.primary[300],
					'& $listItemText': {
						color: theme.palette.getContrastText(theme.palette.primary[300]),
					},
				},
				[theme.breakpoints.up('md')]: {
					minWidth: '450px',
				},
				'&$selected': {
					backgroundColor: theme.palette.primary[500],
					'&:hover': {
						backgroundColor: theme.palette.primary[300],
						color: theme.palette.getContrastText(theme.palette.primary[300]),
					},
					'& $listItemText': {
						color: theme.palette.getContrastText(theme.palette.primary[500]),
					},
				},
			},
			formControlLabel: {
				color: theme.manakin.defaultContentColor[500],
			},
			title: {
				display: 'flex',
				alignItems: 'center',
				marginLeft: '5px',
			},
			main: {
				fontWeight: 800,
				marginLeft: 0,
			},
			select: {},
			icon: {},
		}),
	{
		name: "AppSimpleSelect",
	}
);
