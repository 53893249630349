import gql from 'graphql-tag';
import {currentAppUser } from '@manakin/app-core/graphQlHelpers';
import { appAssetWithThumbnail } from '@manakin/app-core/graphQlHelpers';

export const GQL_CURRENT_APP_USER = gql`
    query currentAppUser {
        currentAppUser {
            ${currentAppUser}
        }
    }
`;

export const GQL_FETCH_APP_WIDGETS = gql`
    query appWidgets($page: Int!, $pageSize: Int!) {
        appWidgets(page: $page, pageSize: $pageSize) {
            appWidgets {
                id
                title
                type
                variant
                intro
                actionTitle
                enabled
                poll {
                    id
                    title
                    question
                    answers {
                        id
                        title
                        correct
                    }
                }
                showNextButton
                description
                backgroundColor
            }
        }
    }
`;

export const GQL_FETCH_NEWS_LIST = gql`
    query($filter: NewsListFilter, $pagesize: Int, $page: Int) {
        newsList(filter: $filter, pagesize: $pagesize, page: $page) {
            news {
                id
                title
                introText
                category
                factCards
                content
                image {
                    ${appAssetWithThumbnail}
                }
                lastModifiedOn
                createdOn
                tags {
                    id
                    name
                    visibleInApp
                    image {
                        id
                        url
                    }
                }
            }
            count
        }
    }
`;


export const GQL_SEND_SUGGESTION_BOX = gql`
    mutation sendSuggestionBoxResponse($appWidgetId: ID!, $text: String!, $allowContact: Boolean!) {
        sendSuggestionBoxResponse(input: { appWidgetId: $appWidgetId, text: $text, allowContact: $allowContact })
    }
`;


