import React, { useEffect, useState } from 'react';
import OpenQuestion from './OpenQuestion';
import { handleWorkformStartState, arrayToObject } from '../lib';

const OpenQuestionContainer = (props) => {
	const { data } = props;

	const [state, setState] = useState({
		hasAnswer: false,
		buttonDisabled: false,
	});
	const [buttonDisabled, setButtonDisabled] = useState(false);
	const [answer, setAnswer] = useState('');
	const [storedAnswer, storeAnswer] = useState('');
	const [step, setStep] = useState(1);
	const [checkboxAnswers, setCheckboxAnswers] = useState([...props.data.attentionPoints]);

	const handleCheckAnswerClick = (e) => {
		setAnswer(storedAnswer);

		if (storedAnswer) {
			setStep(2);
		}
	};

	const handleTextAreaChange = (e) => {
		storeAnswer(e.currentTarget.value);
	};

	const handleChangeAnswerClick = () => {
		setStep(1);
	};

	const handleChangeCheckbox = (idx) => (e) => {
		const checkboxAnswersCopy = [...checkboxAnswers];
		checkboxAnswersCopy[idx].checked = e.currentTarget.checked;

		setState({
			...state,
			answers: checkboxAnswersCopy.map((i) => {
				return { id: i.id, selected: i.checked || false };
			}),
		});
	};

	const handleFinish = () => {
		props.onFinished({
			correct: true,
			workform: {
				...state,
				answer: answer,
			},
		});
		setState({
			...state,
			finished: true,
		});
		setButtonDisabled(true);
	};

	useEffect(() => {
		setState(handleWorkformStartState(props));
	}, []);

	useEffect(() => {
		if (state.rawResult && state.rawResult.answers && state.rawResult.answers.length) {
			const answers = arrayToObject(state.rawResult.answers);
			const temp = checkboxAnswers.map((i) => {
				return {
					...i,
					selected: answers[i.id].selected,
				};
			});

			setCheckboxAnswers(temp);
			setState({
				...state,
				answers: temp,
			});
		}
	}, [state.rawResult]);

	useEffect(() => {
		if (state.answer) {
			setAnswer(state.answer);
			storeAnswer(state.answer);
			setButtonDisabled(true);
			setStep(2);
		}
	}, [state.answer]);

	return (
		<OpenQuestion
			data={state}
			answer={answer}
			step={step}
			storedAnswer={storedAnswer}
			checkboxAnswers={checkboxAnswers}
			onTextAreaChange={handleTextAreaChange}
			onCheckAnswerClick={handleCheckAnswerClick}
			onChangeAnswerClick={handleChangeAnswerClick}
			onChangeCheckbox={handleChangeCheckbox}
			onClick={handleFinish}
			buttonDisabled={buttonDisabled}
		/>
	);
};

export default OpenQuestionContainer;
