'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = exports.styles = function styles(theme) {
	var _bookCheck, _bookCheckContent, _content, _bridgeContent, _listView, _tags, _bookcheckTag, _ratingCardHeading;

	return {
		contentTitle: {},
		videoBG: {
			display: 'flex',
			height: '100%',
			width: '890px',
			pointerEvents: 'none',
			position: 'relative',
			left: '50%',
			transform: 'translateX(-50%)'
		},
		root: {
			height: '100%',
			width: '100%',
			position: 'relative',
			backgroundColor: theme.manakin.defaultElementBGColor[500],
			overflow: 'hidden',
			display: 'inline-block',
			opacity: 0,
			transform: 'translateX(-20px)',
			transition: 'transform 1s, opacity 1s',
			'&:hover': {
				'& $backgroundImage': _defineProperty({}, theme.breakpoints.up('md'), {
					transform: 'scale(1.1)'
				})
			}
		},
		lightTextOverlay: _defineProperty({
			'&:after': {
				content: "''",
				background: 'linear-gradient(2deg, rgba(0,0,0,0.3) 0%, rgba(255,255,255,0) 35%, rgba(255,255,255,0) 65%, rgba(0,0,0,0.3) 100%)',
				position: 'absolute',
				top: '0',
				bottom: '0',
				left: '0',
				right: '0'
			}

		}, theme.breakpoints.down('sm'), {
			'&:after': {
				background: 'linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.3) 100%)'
			}
		}),
		textOverlayTitle: {
			fontSize: '200px',
			letterSpacing: '40px',
			color: theme.palette.getContrastText(theme.manakin.defaultElementBGColor[500]),
			position: 'absolute',
			top: '-20%',
			left: '50%',
			transform: 'translate(-50%, -113%)'
		},
		overflowRoot: {
			overflow: 'visible'
		},
		Trainer: {},
		show: {
			opacity: 1,
			transform: 'translateX(0)'
		},
		bookCheck: (_bookCheck = {
			backgroundColor: theme.manakin.primaryElementBGColor[500],
			padding: '3.6rem 3rem'
		}, _defineProperty(_bookCheck, theme.breakpoints.up('md'), {
			minHeight: '18rem',
			display: 'flex',
			flexDirection: 'row',
			padding: '2.5rem 5rem'
		}), _defineProperty(_bookCheck, '& $backgroundImage', {
			display: 'none'
		}), _defineProperty(_bookCheck, theme.breakpoints.down('md'), {
			'& $backgroundImage': {
				display: 'none'
			}
		}), _bookCheck),
		bookCheckFlex: _defineProperty({
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			width: '100%'
		}, theme.breakpoints.down('md'), {
			flexDirection: 'column'
		}),
		fullWidth: _defineProperty({}, theme.breakpoints.up('md'), {
			'& $videoBG': {
				width: '100%'
			},
			'&$bookCheck': {
				'& $backgroundImage': {
					display: 'block',
					width: '150px',
					height: '100%'
				},
				'& $bookCheckFlex': {
					display: 'flex',
					width: '100%',
					alignItems: 'center',
					flexDirection: 'row'
				},
				'& $bookCheckButton': {
					minWidth: '26rem'
				},
				padding: '2.5rem 5rem 2.5rem 20rem'
			}
		}),
		body: {
			color: theme.palette.getContrastText(theme.manakin.primaryElementBGColor[500])
		},
		bookCheckContent: (_bookCheckContent = {
			textAlign: 'center',
			'& $bodytitle': {
				marginBottom: '1rem',
				color: theme.palette.getContrastText(theme.manakin.primaryElementBGColor[500])
			},
			'& $body': {
				marginBottom: '3rem',
				color: theme.palette.getContrastText(theme.manakin.primaryElementBGColor[500])
			}
		}, _defineProperty(_bookCheckContent, theme.breakpoints.up('md'), {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			paddingRight: '2rem',
			zIndex: 999,
			textAlign: 'left',
			'& p': {
				marginBottom: 0,
				width: 'auto',
				maxWidth: '100% !important'
			},
			'& $bodytitle': {
				marginBottom: '1rem'
			},
			'& $body': {
				marginBottom: 0
			}
		}), _defineProperty(_bookCheckContent, '& > p', {
			maxWidth: '100% !important'
		}), _bookCheckContent),
		darkContent: {
			'& $bodytitle': {
				color: '#000'
			},
			'& $body': {
				color: '#000'
			}
		},
		lightContent: {
			'& $bodytitle': {
				color: '#fff'
			},
			'& $body': {
				color: '#fff'
			}
		},
		button: {
			width: 'auto',
			minWidth: '260px',
			minHeight: '60px'
		},
		minHeight: _defineProperty({
			minHeight: '250px',
			'& $done': {
				top: '1rem',
				right: '1rem',
				position: 'absolute'
			}
		}, theme.breakpoints.up('md'), {
			minHeight: '40rem',
			'& $done': {
				top: '3rem',
				right: '3rem',
				position: 'absolute'
			}
		}),
		backgroundImage: {
			position: 'absolute',
			zIndex: '0',
			height: '100%',
			width: '100%',
			left: '0',
			top: '0',
			transition: 'width .3s, transform .3s, background-color .3s'
		},
		overflow: {
			height: 'calc(100% + 20px)',
			bottom: 0,
			top: 'auto',
			'& img': {
				objectFit: 'contain',
				objectPosition: 'right'
			}
		},
		image: {
			width: '100%',
			height: '100%',
			objectFit: 'cover',
			fontFamily: "'object-fit: cover'"
		},
		noBackground: {
			'& $backgroundImage': {
				display: 'none'
			}
		},
		link: {
			textDecoration: 'none',
			display: 'inline',
			cursor: 'pointer',
			transform: 'translateX(-20px)'
			// opacity: 1,
		},
		bridgeLink: {
			cursor: 'default'
		},
		linkToSlideShowEdit: {
			display: 'flex',
			color: theme.palette.primary.main,
			textDecoration: 'none'
		},
		linkToSlideShowEditIcon: {
			marginRight: theme.spacing(2)
		},
		slideshowLinks: {
			marginBottom: theme.spacing(4)
		},
		slideshowLinksItem: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			marginBottom: theme.spacing(2)
		},
		slideshowTitle: {
			color: theme.palette.getContrastText(theme.manakin.defaultElementBGColor[500]),
			lineHeight: 'normal',
			margin: 0
		},
		slideshowAuthor: {
			margin: 0,
			lineHeight: 'normal',
			fontSize: '16px',
			color: theme.palette.getContrastText(theme.manakin.defaultElementBGColor[500])
		},
		content: (_content = {
			position: 'absolute',
			bottom: '26px',
			padding: '0 26px'
		}, _defineProperty(_content, theme.breakpoints.up('md'), {
			padding: '0 50px',
			bottom: '50px'
		}), _defineProperty(_content, 'zIndex', 1), _content),
		type: {
			display: 'block',
			fontFamily: theme.manakin.secondaryTitleFont,
			fontSize: '2rem',
			lineHeight: '2rem',
			color: theme.palette.getContrastText(theme.manakin.defaultElementBGColor[500]),
			marginBottom: '1.4rem'
		},
		secondaryPrefixColor: {
			color: theme.manakin.secondaryTypeColor[500]
		},
		primaryPrefixColor: {
			color: theme.manakin.primaryTypeColor[500]
		},
		name: _defineProperty({
			margin: 0,
			color: theme.palette.getContrastText(theme.manakin.defaultElementBGColor[500])
		}, theme.breakpoints.up('md'), {}),
		primaryTitleColor: {
			color: theme.manakin.primaryBoxColor[400]
		},
		secondaryTitleColor: {
			color: theme.manakin.secondaryBoxColor[400]
		},
		contentColor: {},
		darkColor: {
			color: '#000'
		},
		lightColor: {},
		doneIcon: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			height: '1.5rem',
			width: '1.5rem',
			color: theme.palette.getContrastText(theme.manakin.defaultElementBGColor[500])
		},
		primaryBackgroundColor: {
			backgroundColor: theme.manakin.primaryElementBGColor[500],
			'& $contentColor': {
				color: theme.palette.getContrastText(theme.manakin.primaryElementBGColor[500])
			},
			'& $downloadButton': {
				color: theme.palette.getContrastText(theme.manakin.primaryElementBGColor[500])
			},
			'& $doneIcon': {
				color: theme.palette.getContrastText(theme.manakin.primaryElementBGColor[500])
			}
		},
		secondaryBackgroundColor: {
			backgroundColor: theme.manakin.secondaryElementBGColor[500],
			'& $contentColor': {
				color: theme.palette.getContrastText(theme.manakin.secondaryElementBGColor[500])
			},
			'& $downloadButton': {
				color: theme.palette.getContrastText(theme.manakin.secondaryElementBGColor[500])
			},
			'& $doneIcon': {
				color: theme.palette.getContrastText(theme.manakin.secondaryElementBGColor[500])
			}
		},
		slideshowCard: {
			display: 'flex',
			flexFlow: 'column nowrap',
			paddingLeft: 20
		},
		slideshowBox: _defineProperty({
			display: 'inherit',
			flexFlow: 'inherit',
			flexGrow: 1,
			padding: '26px'
		}, theme.breakpoints.up('md'), {
			padding: '50px'
		}),
		slideshowContent: {
			position: 'initial'
		},
		slideshowActions: {
			display: 'flex',
			flexDirection: 'column'
		},
		newSlideshowButton: {
			marginTop: 'auto'
		},
		bridgeContent: (_bridgeContent = {
			padding: theme.manakin.defaultPadding
		}, _defineProperty(_bridgeContent, theme.breakpoints.up('md'), {
			padding: '100px'
		}), _defineProperty(_bridgeContent, '& h2', _defineProperty({
			fontFamily: theme.manakin.secondaryTitleFont,
			color: theme.palette.getContrastText(theme.manakin.defaultElementBGColor[500]),
			fontSize: '3rem',
			lineHeight: '4rem',
			fontWeight: '500'
		}, theme.breakpoints.up('md'), {
			fontSize: '3.6rem',
			lineHeight: '5.2rem'
		})), _defineProperty(_bridgeContent, '& $contentColor', {
			color: theme.palette.getContrastText(theme.manakin.defaultElementBGColor[500]),
			'&>ul': _defineProperty({
				paddingLeft: '3rem'
			}, theme.breakpoints.up('md'), {
				paddingLeft: '5rem'
			})
		}), _defineProperty(_bridgeContent, '& $darkColor', {
			color: '#000'
		}), _defineProperty(_bridgeContent, '& $lightColor', {
			color: '#fff'
		}), _bridgeContent),
		done: _defineProperty({
			marginLeft: theme.spacing(2),
			flexShrink: 0,
			width: '30px',
			height: '30px',
			borderRadius: '100%',
			backgroundColor: theme.palette.secondary[500],
			zIndex: '2',
			right: '34px',
			top: '50%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		}, theme.breakpoints.up('md'), {
			width: '60px',
			height: '60px'
		}),
		continue: {
			backgroundColor: 'transparent',
			'& $doneIcon': {
				width: '2.5rem',
				height: '2.5rem'
			}
		},
		bookCheckDone: {
			width: '32px',
			height: '32px'
		},
		inCorrect: {
			backgroundColor: theme.palette.error['light']
		},
		iconRoot: {
			flex: '1',
			width: 'auto',
			height: 'auto'
		},
		listView: (_listView = {
			height: '6rem',
			minHeight: '6rem',
			border: '1px solid ' + theme.manakin.defaultBorderColor[500]
		}, _defineProperty(_listView, theme.breakpoints.up('md'), {
			minHeight: '10rem',
			height: '10rem'
		}), _defineProperty(_listView, '& $content', _defineProperty({
			padding: '0 0 0 8rem',
			position: 'relative',
			bottom: 0,
			height: '100%',
			display: 'flex',
			width: '100%',
			alignItems: 'center'
		}, theme.breakpoints.up('md'), {
			padding: '0 0 0 14rem'
		})), _defineProperty(_listView, '& $type', {
			display: 'none'
		}), _defineProperty(_listView, '& $backgroundImage', _defineProperty({
			width: '6rem'
		}, theme.breakpoints.up('md'), {
			width: '10rem'
		})), _defineProperty(_listView, '& $name', {
			display: 'block',
			fontSize: '1.8rem',
			letterSpacing: '0rem',
			fontWeight: '600',
			lineHeight: '2rem',
			fontFamily: theme.manakin.defaultTitleFont,
			color: theme.manakin.defaultBoxColor[400],
			'&:hover': {
				color: theme.manakin.defaultContentHover[500]
			}
		}), _defineProperty(_listView, '& $doneIcon', {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			height: '1.5rem',
			width: '1.5rem',
			color: 'white'
		}), _defineProperty(_listView, '& $done', _defineProperty({
			position: 'absolute',
			width: '30px',
			height: '30px',
			borderRadius: '100%',
			zIndex: '2',
			right: '20px',
			top: '50%',
			transform: 'translateY(-50%)',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		}, theme.breakpoints.up('md'), {
			width: '60px',
			height: '60px'
		})), _defineProperty(_listView, '& $continue', {
			'& $doneIcon': {
				color: 'black',
				width: '2.5rem',
				height: '2.5rem'
			}
		}), _defineProperty(_listView, '& $time', {
			top: '50%',
			left: '1.2rem',
			transform: 'translateY(-50%)'
		}), _defineProperty(_listView, '&$Trainer', {
			borderColor: 'transparent',
			border: 0,
			marginTop: '5.5rem',
			position: 'relative',
			overflow: 'visible',
			'& $name': {
				color: 'white',
				fontWeight: 'normal'
			},
			'&:hover': {
				'& $name': {
					color: 'black'
				}
			},
			'&:before': {
				content: '""',
				width: '100%',
				height: '1px',
				backgroundColor: theme.manakin.defaultBorderColor[500],
				position: 'absolute',
				top: '-4rem',
				left: 0
			}
		}), _listView),
		downloadButton: {
			color: theme.palette.getContrastText(theme.manakin.primaryElementBGColor[500])
		},
		downloadLinks: {
			'& $iconRoot': {
				flex: 0,
				display: 'flex',
				marginRight: '2rem',
				marginTop: '-3px',
				transition: 'transform .3s, color .3s'
			},
			'& $downloadButton': {
				cursor: 'pointer',
				fontWeight: 'bold',
				marginBottom: 0,
				display: 'flex',
				transition: 'color .3s',
				alignItems: 'center',
				'&:hover': {
					color: theme.manakin.defaultContentHover[500],
					'& $iconRoot': {
						transform: 'translateX(2px)',
						color: theme.manakin.defaultContentHover[500]
					}
				}
			}
		},
		disabled: {
			'& $show': {
				opacity: '.3'
			}
		},
		time: _defineProperty({
			color: theme.manakin.primaryTypeColor[500],
			position: 'absolute',
			zIndex: 10,
			top: '1.4rem',
			left: '1.4rem',
			display: 'flex',
			alignItems: 'center'
		}, theme.breakpoints.up('md'), {
			top: '4.8rem',
			left: '3rem'
		}),
		timeIcon: {
			marginRight: '10px'
		},
		procent: _defineProperty({
			color: theme.manakin.primaryTypeColor[500],
			zIndex: 10,
			display: 'flex',
			alignItems: 'center',
			lineHeight: '4rem',
			justifyContent: 'center',
			marginRight: '0.5rem'
		}, theme.breakpoints.up('md'), {
			marginRight: 0
		}),
		playIconContainer: _defineProperty({
			position: 'absolute',
			top: '50%',
			left: '50%',
			color: 'white',
			transform: 'translate(-50%, -50%)',
			width: '100px',
			height: '100px',
			borderRadius: '100%',
			border: '8px solid white'
		}, theme.breakpoints.down('md'), {
			width: '50px',
			height: '50px'
		}),
		playIcon: {
			width: '100%',
			height: '100%'
		},
		play: _defineProperty({
			position: 'absolute',
			top: '50%',
			left: '50%',
			color: 'white',
			transform: 'translate(-50%, -50%)',
			letterSpacing: '20px',
			width: '107px',
			wordBreak: 'break-all',
			lineHeight: '40px',
			textAlign: 'center'
		}, theme.breakpoints.up('md'), {
			fontSize: '13rem',
			lineHeight: '110px',
			width: '240px',
			letterSpacing: '30px'
		}),
		bookCheckButton: {
			minWidth: '20rem'
		},
		darkBookCheckButtonColor: {
			color: '#000',
			borderColor: '#000'
		},
		lightBookCheckButtonColor: {
			color: '#fff',
			borderColor: '#fff'
		},
		tags: (_tags = {
			top: '10px',
			right: '10px',
			position: 'absolute',
			zIndex: '99',
			paddingRight: '10px'
		}, _defineProperty(_tags, theme.breakpoints.up('md'), {
			right: '30px',
			top: '30px'
		}), _defineProperty(_tags, theme.breakpoints.down('sm'), {
			width: '30px',
			height: '30px'
		}), _defineProperty(_tags, '&>figure', {
			marginRight: '0'
		}), _tags),
		withIcon: _defineProperty({
			top: '10px',
			right: '40px'
		}, theme.breakpoints.up('md'), {
			right: '90px',
			top: '30px'
		}),
		bookcheckTag: (_bookcheckTag = {}, _defineProperty(_bookcheckTag, theme.breakpoints.up('md'), {
			top: 0,
			right: 0
		}), _defineProperty(_bookcheckTag, '&$withIcon', _defineProperty({}, theme.breakpoints.up('md'), {
			right: '0',
			top: '0'
		})), _bookcheckTag),
		textOverlay: {
			position: 'absolute',
			width: '100%',
			bottom: 0,
			left: '0',
			zIndex: 99
		},
		useTextOverlay: {
			'& $content': {
				top: '35px',
				bottom: 'auto'
			}
		},
		bodytitle: {},
		ratingCardHeading: (_ratingCardHeading = {
			color: 'white',
			fontFamily: 'Anton',
			textTransform: 'uppercase',
			wordBreak: 'break-word',
			fontSize: '3.8rem',
			marginBottom: theme.spacing()
		}, _defineProperty(_ratingCardHeading, theme.breakpoints.up('md'), {
			lineHeight: '7rem'
		}), _defineProperty(_ratingCardHeading, '&:after', {
			display: 'none'
		}), _ratingCardHeading),
		ratingCardAction: {
			color: 'white'
		},
		ratingCardLink: {
			display: 'flex',
			flexDirection: 'row'
		},
		ratingCardLongArrow: {
			marginLeft: theme.spacing(2),
			color: theme.manakin.indicationColor[500]
		},
		update: {
			position: 'absolute',
			right: '20px',
			top: '20px',
			width: '10px',
			height: '10px',
			borderRadius: '100%',
			backgroundColor: 'red',
			zIndex: 99
		},
		hide: {
			display: 'none'
		}
	};
};