import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Search from '../Search';
import TableToolbarAction from '../TableToolbarAction';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import { compose } from 'recompose';
import { withTranslation } from "react-i18next";

const styles = (theme) => ({
    root: {
        position: 'relative',
        paddingRight: theme.spacing(1),
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        display: 'flex',
        color: theme.palette.getContrastText(theme.palette.grey[800]),
        backgroundColor: theme.palette.grey[800],
    },
    selection: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        color: theme.palette.getContrastText(theme.palette.grey[800]),
        backgroundColor: theme.palette.grey[800],
    },
    link: {
        color: 'inherit',
        marginLeft: theme.spacing(1),
    },
});

class TableToolbar extends React.Component {
    handleSelectAll = (event) => {
        if (event) event.preventDefault();
        this.props.onSelectAll();
    };

    handleDeselectAll = (event) => {
        if (event) event.preventDefault();
        this.props.onDeselectAll();
    };

    handleActionClick = (originalHandler) => (event) => {
        if (originalHandler) {
            originalHandler(this.props.selection, this.props.selectAll);
        }
    };

    render() {
        const {
            selection,
            selectAll,
            totalCount,
            classes,
            onSearch,
            defaultSearch,
            theme,
            actions,
            hideSearch,
            customToolbarItems,
            t
        } = this.props;

        const transitionDuration = {
            enter: theme.transitions.duration.shorter,
            exit: theme.transitions.duration.shortest,
        };

        return (
			<div className={classes.root}>
				<Toolbar className={classes.toolbar}>
					{!hideSearch && <Search defaultValue={defaultSearch} onSearch={onSearch} className={classes.search} />}
					<div className={classes.spacer} />
					<div className={classes.actions} />
					{customToolbarItems}
				</Toolbar>
				<Fade unmountOnExit in={selection.length > 0} timeout={transitionDuration}>
					<Toolbar className={[classes.toolbar, classes.selection].join(' ')}>
						{selectAll ? (
							<Typography color="inherit" variant="subtitle1">
								<span>{t('common.rows-all-amount-selected', { amount: totalCount })}</span>
							</Typography>
						) : (
							<Typography color="inherit" variant="subtitle1">
								<span>
									{selection.length === 1
										? t('common.rows-one-selected')
										: t('common.rows-amount-selected', { amount: selection.length })}
								</span>
								<a onClick={this.handleSelectAll} className={classes.link} href="#">
									{t('common.rows-all-amount-selected', { amount: totalCount })}
								</a>
							</Typography>
						)}

						<div className={classes.actions}>
							{actions &&
								actions.map((action) =>
									React.cloneElement(action, {
										onClick: this.handleActionClick(action.props ? action.props.onClick : ''),
									})
								)}
							<TableToolbarAction
								onClick={this.handleDeselectAll}
								tooltip={t('common.rows-all-deselect')}
								icon={<CloseIcon />}
							/>
						</div>
					</Toolbar>
				</Fade>
			</div>
		);
    }
}

export default compose(
    withTranslation(),
    withStyles(styles, {
        name: 'CoreTableToolbar',
        withTheme: true,
    })
)(TableToolbar);
