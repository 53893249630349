import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(
	(theme) =>
		createStyles({
			stepperRoot: {
				width: '100%',
				alignItems: 'center',
				justifyContent: 'center',
			},
			stepRoot: {
				flex: '0 0 auto',
			},
			root: {
				padding: '14rem 0',
			},
			container: {
				display: 'flex',
				flexWrap: 'wrap',
				width: '100%',
				alignItems: 'center',
				margin: '0 auto',
				justifyContent: 'center',
			},
			wrapper: {
				...theme.manakin.smallWrapper,
				maxWidth: '550px',
			},
			defaultWrapper: {
				...theme.manakin.defaultWrapper,
				maxWidth: '750px',
			},
			largeWrapper: {
				...theme.manakin.largeWrapper,
			},
			extraLargeWrapper: {
				...theme.manakin.extraLargeWrapper,
			},
			products: {
				display: 'flex',
				flexWrap: 'wrap',
				flexDirection: 'column',
				marginBottom: '4rem',
				[theme.breakpoints.up('md')]: {
					flexDirection: 'row',
				},
			},
			buttonGroup: {
				zIndex: 0,
				textAlign: 'center',
				width: '100%',
			},
			buttonRoot: {
				display: 'block',
				margin: '0 auto',
			},
			content: {
				width: '100%',
				margin: '0',
				padding: '20px 0',
			},
			regiatrationAppear: {
				display: 'block',
			},
			registrationEnter: {
				opacity: 0,
				position: 'relative',
				transform: 'translateX(100px)',
			},
			registrationEnterActive: {
				opacity: 1,
				transform: 'translateX(0)',
			},
			heading: {},
			buttonPrimary: {},
			introduction: {
				textAlign: 'center',
			},
			centered: {
				textAlign: 'center',
			},
			row: {
				margin: '2rem auto',
			},
			logout: {
				position: 'absolute',
				top: '20px',
				right: '20px',
				cursor: 'pointer',
			},
		}),
	{
		name: 'AppWebshop',
	}
);
