export const styles = (theme) => ({
    image: {
        position: 'relative',
        width: '100%',
        margin: '0 auto',
        maxWidth: '800px',
        paddingLeft: '25px',
        paddingRight: '25px'
    },
    overlay: {
        backgroundColor: 'white',
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: '999',
        opacity: 0,
        transform: 'scale(.8)',
        transformOrigin: 'center',
        display: 'flex',
        alignItems: 'center',
        transition: 'transform .2s, opacity .2s',
        pointerEvents: 'none',
    },
    show: {
        opacity: 1,
        transform: 'scale(1)',
        pointerEvents: 'visible',
    },
    fullscreen: {
        width: '50px',
        height: '50px',
        backgroundColor: theme.palette.primary[300],
        position: 'absolute',
        top: 0,
        right: '0',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    content: {
        marginTop: '3rem',
        marginBottom: 0,
        textAlign: 'center',
        fontWeight: '300',
    },
    button: {
        position: 'absolute',
        bottom: '0',
        right: '0',
    },
});
