import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import classNames from 'classnames';
import { compose } from 'recompose';
import { withTranslation } from "react-i18next";
import { FormHelperText } from '@material-ui/core';

const styles = (theme) => ({
	root: {
		margin: theme.manakin.normalMargin,
	},
	label: {
		color: theme.manakin.defaultTitleColor[500],
		fontSize: '18px',
	},
	errorMessage: {
		color: theme.manakin.otherRedThemeColor[500],
	},
	loading: {
		height: theme.spacing(6),
		backgroundColor: theme.palette.grey[100],
		'& *': { opacity: 0, pointerEvents: 'none' },
	},
	quillContainer: {
		padding: '20px 0 0',
		'& .ql-tooltip': {
			left: '0 !important',
		},
	},
	disabledLabel: {
		color: theme.manakin.disabledFieldLabelColor,
	},
	disabledInput: {
		color: theme.manakin.disabledFieldTextColor,
	},
	disabledBorder: {
		border: `none`,
	},
});

// Custom buttons
const CustomTagToHyphensButton = () => <span>[-]</span>;

/*
 * Event handler to be attached using Quill toolbar module
 * http://quilljs.com/docs/modules/toolbar/
 */
function customTagToHyphens() {
	const cursorPosition = this.quill.getSelection().index;
	this.quill.insertText(cursorPosition, '[-]');
	this.quill.setSelection(cursorPosition + 3);
}

/*
 * RichTextEditorCustom component with custom toolbar and content containers
 */
class RichTextEditorCustom extends React.Component {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
	}

	quillRef = React.createRef();
	timeout;

	state = {
		value: '',
		showMaxLengthWarning: false,
	};

	handleChange = (value) => {
		const { form, name } = this.props;

		const maxLength = form.schema[name]
			? form.schema[name].maxLength
			: null;

		//strip html tags for count
		const valueToCheck = value.replace(/(<([^>]+)>)/gi, '');

		const contentExceedsMaxLength = maxLength
			? valueToCheck.length > maxLength
			: false;

		const newValue = value;

		this.setState({
			value: newValue,
			showMaxLengthWarning: contentExceedsMaxLength,
		});

		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			form.onFieldChange({ key: name, value: newValue });

			// Focus the editor selection on the last character if the maximum length was exceeded
			const quill = this.quillRef.current;
			if (quill && contentExceedsMaxLength) {
				quill.setEditorSelection(quill.getEditor(), {
					index: newValue.length - 1,
					length: 0,
				});
			}
		}, 400);
	};

	componentDidUpdate(prevProps) {
		if (prevProps.initialValue != this.props.initialValue) {
			const { form, name, initialValue = '' } = this.props;
			this.setState({ value: initialValue });
			form.onFieldChange({ key: name, value: initialValue });
		}
	}

	componentDidMount() {
		const { form, name, initialValue = '' } = this.props;
		this.setState({ value: initialValue });
		form.onFieldChange({ key: name, value: initialValue });
	}

	render() {
		const {
			classes,
			name,
			className: classNameProp,
			label = '',
			readOnly = false,
			placeholder,
			loading,
			form,
		    t
        } = this.props;
		const { value } = this.state;
		const { errors, schema, fields } = form;
		const className = classNames(
			classes.root,
			{ [classes.loading]: loading },
			{ [classes.error]: errors[name] && errors[name].length > 0 },
			classNameProp
		);
		const toolbarId = `toolbar-${name}`;

		/*
		* Quill modules to attach to editor
		* See http://quilljs.com/docs/modules/ for complete options
		*/
		const modules = {
			toolbar: {
				container: `#${toolbarId}`,
				handlers: {
					customTagToHyphens: customTagToHyphens,
				},
			},
		};

		return (
			<div className={className}>
				<InputLabel
					className={classNames(classes.label, {
						[classes.disabledLabel]: readOnly,
					})}
				>
					{label}
				</InputLabel>
				<div
					className={classNames(classes.quillContainer, {
						[classes.disabledBorder]: readOnly,
						[classes.disabledInput]: readOnly,
					})}
				>
					<div id={`toolbar-${name}`}>
						<button className="ql-customTagToHyphens">
							<CustomTagToHyphensButton />
						</button>
					</div>
					<ReactQuill
						ref={this.quillRef}
						value={value}
						onChange={this.handleChange}
						placeholder={placeholder}
						modules={modules}
						readOnly={readOnly}
					/>
					{errors[name] &&
						errors[name].length > 0 &&
						errors[name].map((e, i) => (
							<FormHelperText className={classes.errorMessage} key={i}>
								{e}
							</FormHelperText>
						))}
					{this.state.showMaxLengthWarning && (
						<FormHelperText className={classes.errorMessage}>
							{t('core.rich-text-editor.max-characters-exceeded', { amount: schema[name].maxLength })}
						</FormHelperText>
					)}
				</div>
			</div>
		);
	}
}

export default compose(
	withStyles(styles),
	withTranslation()
)(RichTextEditorCustom);