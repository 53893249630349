import React from 'react';
import WhatIsWhatQuestion from './WhatIsWhatQuestion';
import shuffle from 'shuffle-array';

class WhatIsWhatQuestionContainer extends React.PureComponent {
	state = {
		buttonDisabled: false,
		allCorrect: false,
	};

	componentDidMount() {
		this.setStartState();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.data.id != this.props.data.id) {
			this.setStartState();
			this.setState({ buttonDisabled: false });
		}
	}

	setStartState = () => {
		const { data, options = {} } = this.props;
		const questions = this.getQuestions(data);
		const answers = this.getAnswers(data);

		this.setState(
			{
				...this.props.data,
				answers: answers,
				questions: questions,
				animateNext: -1,
				animatePrev: -1,
				options: options,
				feedback: data.finished && options && options.showFeedback ? true : false,
				done: data.finished,
			},
			() => {
				this.setState({
					correct: data.correct || this.checkOrder(),
					allCorrect: data.finished && data.correct,
				});
			}
		);
	};

	getQuestions = (data) => {
		const questions =
			(data.items &&
				data.items.map((item) => ({
					id: item.id,
					text: item.firstText,
					image: item.firstImage,
				}))) ||
			[];

		return questions;
	};

	getAnswers = (data) => {
		const answers =
			(data.items &&
				data.items.map((item) => ({
					id: item.id,
					text: item.secondText,
					image: item.secondImage,
				}))) ||
			[];

		if (!(data.finished && data.correct)) shuffle(answers);

		return answers;
	};

	swapItems = (_array, a, b) => {
		let array = [..._array];
		let temp = array[a];

		array[a] = array[b];
		array[b] = temp;
		return array;
	};

	checkOrder = () => {
		const { answers, questions } = this.state;

		let same = true;
		answers.forEach((answer, idx) => {
			if (questions[idx] && answer.id != questions[idx].id) same = false;
		});

		return same;
	};

	handleClick = (item) => {
		const { answers: _answers, correctAnswer } = this.state;
		let swapIdx;
		let gap;
		switch (item.direction) {
			case 'next':
				swapIdx = _answers[item.idx + 1].id == correctAnswer ? item.idx + 2 : item.idx + 1;
				gap = _answers[item.idx + 1].id == correctAnswer ? true : false;
				break;
			case 'back':
				swapIdx = _answers[item.idx - 1].id == correctAnswer ? item.idx - 2 : item.idx - 1;
				gap = _answers[item.idx - 1].id == correctAnswer ? true : false;
				break;
		}

		if (swapIdx < _answers.length && swapIdx >= 0) {
			const answers = this.swapItems(_answers, item.idx, swapIdx);
			this.setState({
				animateNext: item.direction == 'next' ? item.idx : swapIdx,
				animatePrev: item.direction == 'next' ? swapIdx : item.idx,
				animationSettings: { gap: gap },
			});

			setTimeout(() => {
				this.setState(
					{
						answers: answers,
						animateNext: -1,
						animatePrev: -1,
					},
					() => {
						this.setState({
							correct: this.checkOrder(),
						});
					}
				);
			}, 300);
		}
	};

	giveHint = () => {
		const hasHint = this.props.data.hintText ? (this.props.data.hintText == '<p><br></p>' ? false : true) : false;

		const { correct } = this.state;
		if (hasHint && !correct) {
			const { answers, questions } = this.state;
			const id = answers[1].id;
			let idx = 0;
			let direction = 'back';

			if (questions[1].id == id) {
				this.setState({ correctAnswer: id });
			} else {
				questions.forEach((item, i) => {
					if (item.id == id) idx = i;
				});
				if (idx > 1) direction = 'next';

				this.handleClick({ direction: direction, idx: 1, i: idx });
				setTimeout(() => {
					this.setState({
						correctAnswer: this.state.answers[idx].id,
					});
				}, 1000);
			}
		}
	};

	handleSubmit = () => {
		const { options = {} } = this.props;

		if (options && options.showFeedback) {
			this.giveHint();
			this.setState({ feedback: true, options: options });
		} else {
			this.setState({ buttonDisabled: true });
			this.handleFinish();
		}
	};

	handleFinish = () => {
		const { correct } = this.state;
		if (correct) {
			this.setState(
				{
					allCorrect: true,
				},
				() => {
					this.setFinishedState();
				}
			);
		} else {
			this.setFinishedState();
		}
	};

	handleCorrect = () => {
		this.setState({
			allCorrect: true,
		});
	};

	setFinishedState = () => {
		this.props.onFinished({
			correct: this.state.correct,
			workform: { ...this.state },
		});
	};

	render() {
		const { saving } = this.props;
		return (
			<WhatIsWhatQuestion
				data={{ ...this.state }}
				saving={saving}
				onClick={this.handleClick}
				onFinished={this.handleFinish}
				onSubmit={this.handleSubmit}
				disabled={this.state.buttonDisabled}
				onCorrect={this.handleCorrect}
			/>
		);
	}
}

export default WhatIsWhatQuestionContainer;
