'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styles = require('@material-ui/core/styles');

var _appCore = require('@manakin/app-core');

var _IconButton = require('@material-ui/core/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _Edit = require('@manakin/core/icons/Edit');

var _Edit2 = _interopRequireDefault(_Edit);

var _Save = require('@manakin/core/icons/Save');

var _Save2 = _interopRequireDefault(_Save);

var _reactApollo = require('react-apollo');

var _recompose = require('recompose');

var _reactRedux = require('react-redux');

var _actions = require('@manakin/core/actions');

var _graphql = require('../../graphql');

var _actions2 = require('@manakin/authentication/actions');

var _reactI18next = require('react-i18next');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = function styles(theme) {
    return {
        profilePicture: {
            background: theme.manakin.primaryColor[500],
            width: '200px',
            height: '200px',
            borderRadius: '100%',
            display: 'flex',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
        },
        editPicture: {
            zIndex: 10,
            position: 'absolute',
            right: '-24px',
            top: 'calc(50% - 24px)',
            backgroundColor: theme.manakin.primaryColor[500],
            alignSelf: 'center'
        }
    };
};

var ProfilePicture = function (_React$PureComponent) {
    _inherits(ProfilePicture, _React$PureComponent);

    function ProfilePicture() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, ProfilePicture);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = ProfilePicture.__proto__ || Object.getPrototypeOf(ProfilePicture)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
            uploadOpen: false,
            file: null
        }, _this.handleFileUpload = function (file) {
            _this.setState({
                file: file
            });
        }, _this.handleSaveFileUpload = function (event) {
            var t = _this.props.t;


            event.preventDefault();
            var file = _this.state.file;

            if (file) {
                _this.props.onUpdateProfilePicture({ file: file }).then(function (result) {
                    if (!result.errors) {
                        if (result && result.data && result.data.uploadProfilePicture && result.data.uploadProfilePicture.success === true) {
                            _this.props.processSuccess(t);
                            _this.props.client.query({
                                query: _graphql.GQL_FETCH_CURRENT_APP_USER,
                                variables: {
                                    id: _this.props.user.id
                                }
                            }).then(function (result) {
                                if (result && result.data && result.data.appUser && result.data.appUser.profilePicture) {
                                    _this.props.onLogin(_extends({}, _this.props.user, {
                                        profilePicture: result.data.appUser.profilePicture
                                    }));
                                }
                            });
                        } else {
                            _this.props.processFailure(t);
                        }
                    } else {
                        _this.props.processFailure(t);
                    }
                });
            } else {
                _this.setState({
                    uploadOpen: true
                });
            }
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(ProfilePicture, [{
        key: 'render',
        value: function render() {
            var _props = this.props,
                classes = _props.classes,
                profilePicture = _props.profilePicture;
            var _state = this.state,
                file = _state.file,
                uploadOpen = _state.uploadOpen;


            return _react2.default.createElement(
                _appCore.FileUpload,
                {
                    accept: 'image/*',
                    enabled: uploadOpen,
                    onFileUpload: this.handleFileUpload
                },
                _react2.default.createElement(
                    'div',
                    {
                        className: classes.profilePicture,
                        style: _extends({}, profilePicture && {
                            backgroundImage: 'url(' + profilePicture.url + ')'
                        }, file && {
                            backgroundImage: 'url(' + URL.createObjectURL(file) + ')'
                        })
                    },
                    (!uploadOpen || file) && _react2.default.createElement(
                        _IconButton2.default,
                        {
                            classes: {
                                root: classes.editPicture
                            },
                            onClick: this.handleSaveFileUpload
                        },
                        file ? _react2.default.createElement(_Save2.default, null) : _react2.default.createElement(_Edit2.default, null)
                    )
                )
            );
        }
    }]);

    return ProfilePicture;
}(_react2.default.PureComponent);

exports.default = (0, _recompose.compose)(_reactApollo.withApollo, (0, _reactI18next.withTranslation)(), (0, _reactRedux.connect)(null, function (dispatch) {
    return {
        onLogin: function onLogin(user) {
            return dispatch((0, _actions2.login)('/', user));
        },
        processSuccess: function processSuccess(t) {
            return dispatch((0, _actions.showSnackbarMessage)({
                text: t("app.account.profile-picture.save-success"),
                variant: 'success'
            }));
        },
        processFailure: function processFailure(t) {
            return dispatch((0, _actions.showSnackbarMessage)({
                text: t("app.account.profile-picture.save-failed"),
                variant: 'error'
            }));
        }
    };
}), (0, _reactApollo.graphql)(_graphql.GQL_CHANGE_PROFILE_PICTURE), (0, _recompose.withHandlers)({
    onUpdateProfilePicture: function onUpdateProfilePicture(_ref2) {
        var mutate = _ref2.mutate;
        return function (event) {
            return mutate({
                variables: _extends({}, event),
                context: {
                    hasUpload: true
                }
            });
        };
    }
}), (0, _styles.withStyles)(styles, { name: 'AppProfilePicture' }))(ProfilePicture);