import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    ElementOverlay,
    LoadImage,
    VideoPlayer,
    Tags,
} from '@manakin/app-core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { styles } from './styles';
import classNames from 'classnames';
import renderHTML from 'react-render-html';
import PlayIcon from '@material-ui/icons/PlayArrow';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

const DefaultFrontPage = (props) => {
    const { t } = useTranslation();

    const {
        classes,
        controls = true,
        preTitle = t("common.element-types.entry-test"),
        image = {},
        playButton = false,
        factCards = false,
        scenes = false,
        background = '',
        disabled: disabledProp = false,
        disabledMessage = false,
        outroButton = false,
    } = props;

    //state hooks
    const [disabled, setIsDisabled] = useState(false);
    const [doReplay, setDoReplay] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [videoId, setVideoId] = useState(null);
    const [openMessage, setOpenMessage] = useState(false);

    //functions
    const handleClick = () => {
        if (disabledProp) {
            setOpenMessage(true);
        } else {
            setIsDisabled(true);
            if (props.onClick) props.onClick();
        }
    };

    const handleScenesClick = () => {
        if (props.onScenesClick) props.onScenesClick();
    };

    const handleReplay = (workform) => {
        setDoReplay(true);
        setVideoId(workform);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleReplayEnd = () => {
        setDialogOpen(false);
    };

    const handleCloseMessage = () => {
        setOpenMessage(false);
    };

    return (
        <div className={classes.root}>
            <Dialog
                open={openMessage}
                onClose={handleCloseMessage}
                PaperProps={{ className: classes.dialogPaper }}
            >
                <Typography variant="body1">
                    {disabledMessage
                        ? t("app.default-pages.front-page.dialog.description-disabled")
                        : t("app.default-pages.front-page.dialog.description")}
                </Typography>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleCloseMessage}
                >
                    {t("common.ok")}
                </Button>
            </Dialog>

            <ElementOverlay
                title={preTitle}
                fullWidth={true}
                controls={controls}
                customHeight={true}
                background={background}
            >
                <div
                    className={classNames(classes.innerRoot, {
                        [classes.showContent]:
                            factCards.length || scenes.length,
                    })}
                >
                    <LoadImage
                        src={image ? image.url || '' : ''}
                        placeholder={image ? image.thumbnailUrl || '' : ''}
                        withOverlay={true}
                        className={classes.backgroundImage}
                    />
                    <div className={classes.wrapper}>
                        <div className={classes.content}>
                            <Typography
                                component="h1"
                                variant="h1"
                                className={classNames(
                                    classes.fontStyle,
                                    classes.title,
                                    { [classes.secondaryFontStyle]: props.titleColor == 'SECONDARY', },
                                    { [classes.primaryFontStyle]: props.titleColor == 'PRIMARY', }
                                )}
                            >
                                {props.introTitle || ''}
                            </Typography>
                            <Typography
                                component="div"
                                variant="body1"
                                className={classNames(
                                    classes.fontStyle,
                                    { [classes.secondaryFontStyle]: props.titleColor == 'SECONDARY', },
                                    { [classes.primaryFontStyle]: props.titleColor == 'PRIMARY', }
                                )}
                            >
                                {renderHTML(props.introText || '')}
                            </Typography>
                            {playButton && (
                                <div
                                    onClick={handleClick}
                                    className={classes.playButton}
                                >
                                    <PlayIcon />
                                </div>
                            )}
                            {!playButton && (
                                <Button
                                    disabled={disabled}
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={handleClick}
                                >
                                    {props.buttonText || t("common.start")}
                                </Button>
                            )}
                            {outroButton && scenes.length > 0 && (
                                <Button
                                    disabled={disabled}
                                    variant="contained"
                                    color="primary"
                                    className={classNames(
                                        classes.button,
                                        classes.scenesButton
                                    )}
                                    onClick={handleScenesClick}
                                >
                                    {props.scenesText || t("app.default-pages.front-page.scenes")}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </ElementOverlay>

            {factCards.length ? (
                <div className={classes.contentWrapper}>
                    <div className={classes.contentBlock}>
                        <Typography
                            component="h2"
                            variant="h2"
                            className={classes.contentHeading}
                            children={t("common.fact-cards")}
                        />
                        <Grid container spacing={0}>
                            <div className={classes.gridGutterHelper}>
                                {factCards.map((factcard, idx) => (
                                    <Grid
                                        key={idx}
                                        item
                                        xs={4}
                                        className={classNames(
                                            classes.gridBlock,
                                            classes.factCardContainer
                                        )}
                                    >
                                        <Link
                                            className={classes.cardHover}
                                            to={`/factcard/${factcard.id}`}
                                        >
                                            <Tags
                                                className={classes.tags}
                                                tags={factcard.tags || []}
                                            />
                                            {factcard.image &&
                                                factcard.image.thumbnailUrl && (
                                                    <img
                                                        className={classes.gridBlockThumbnail}
                                                        src={factcard.image.thumbnailUrl}
                                                        alt={t("app.default-pages.front-page.fact-card-image")}
                                                    />
                                                )}
                                            <Typography
                                                component="h4"
                                                variant="h2"
                                                className={classes.gridBlockTitle}
                                                children={factcard.title}
                                            />
                                            {factcard.text && (
                                                <Typography
                                                    className={classes.gridBlockText}
                                                    children={factcard.text}
                                                />
                                            )}
                                        </Link>
                                    </Grid>
                                ))}
                            </div>
                        </Grid>
                    </div>
                </div>
            ) : (
                ''
            )}

            {scenes.length ? (
                <div className={classes.contentWrapper}>
                    <Typography
                        component="h2"
                        variant="h2"
                        className={classes.contentHeading}
                        children={t("app.default-pages.front-page.scenes")}
                    />
                    <Grid className={classes.scenesGrid} container spacing={0}>
                        <div className={classes.gridGutterHelper}>
                            {scenes.map((workform, idx) => {
                                if (workform.type === 'Scene') {
                                    return (
                                        <Grid
                                            key={idx}
                                            item
                                            xs={12}
                                            className={classes.gridBlock}
                                        >
                                            <Grid
                                                container
                                                className={classes.scenesGrid}
                                            >
                                                <div className={classes.gridGutterHelper}>
                                                    <Grid
                                                        item
                                                        xs={8}
                                                        className={classes.gridBlock}
                                                    >
                                                        <Typography
                                                            className={classes.gridBlockSceneTitle}
                                                            component="h3"
                                                            variant="h2"
                                                        >
                                                            {workform.title}
                                                        </Typography>
                                                        <Typography
                                                            component="div"
                                                            className={classes.gridBlockText}
                                                        >
                                                            {renderHTML(workform.text)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={4}
                                                        className={classes.gridBlock}
                                                    >
                                                        <div className={classes.playContainer}>
                                                            <PlayIcon
                                                                className={classNames(
                                                                    classes.scenePlayButton,
                                                                    {
                                                                        [classes.hasImage]:
                                                                            workform.image
                                                                                ? true
                                                                                : false,
                                                                    }
                                                                )}
                                                                onClick={() => handleReplay(workform)}
                                                            />
                                                            {workform.image && (
                                                                <img
                                                                    className={classes.sceneThumbnail}
                                                                    src={workform.image.thumbnailUrl}
                                                                />
                                                            )}
                                                        </div>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    );
                                }
                            })}
                        </div>
                    </Grid>
                </div>
            ) : (
                ''
            )}
            {doReplay && (
                <Dialog
                    open={dialogOpen}
                    maxWidth={'lg'}
                    fullWidth={true}
                    onClose={handleDialogClose}
                    PaperComponent="div"
                >
                    <VideoPlayer
                        videoId={videoId.video}
                        subtitle={videoId.subtitle}
                        handleEnded={handleReplayEnd}
                    />
                </Dialog>
            )}
        </div>
    );
};

export default compose(
    withStyles(styles, { name: 'AppDefaultFrontPage' })
)(DefaultFrontPage);
