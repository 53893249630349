import React from 'react';
import TextMPC from './TextMPC';
import { handleWorkformStartState, checkAnswer, hasHint } from '../lib';

class TextMpcContainer extends React.Component {
	state = {
		hasAnswer: false,
		buttonDisabled: false,
	};

	componentDidMount() {
		this.setStartState();
	}

	componentDidUpdate(prevProps) {
		if ((!prevProps.data.done && this.props.data.done) || prevProps.data.id != this.props.data.id) {
			this.setStartState(true);
			this.setState({ buttonDisabled: false });
		}
	}

	setStartState = (second) => {
		const newState = handleWorkformStartState({
			...this.props,
			second: second,
		});

		this.setState({
			...newState,
		});
	};

	handleChange = (event) => {
		const _answers = [...this.state.answers];
		const correct = checkAnswer(event.target.value, _answers);
		const val = event.target.value;

		const answers = _answers.map((answer) => {
			let selected = false;
			if (answer.id == val) selected = true;
			return {
				...answer,
				selected: selected,
			};
		});

		this.setState({
			value: event.target.value,
			correct: correct,
			answers: answers,
		});
	};

	handleSubmit = () => {
		const { options = {}, data } = this.props;

		if (options.showFeedback) {
			if(!hasHint(data)) {
				this.handleSetWorkformDone();
			}
			this.setState({ feedback: true, options: options });
		} else {
			this.setState({ buttonDisabled: true });
			this.handleFinish();
		}
	};

	handleFinish = () => {
		this.props.onFinished({
			correct: this.state.correct,
			workform: { ...this.state },
		});
	};

	handleSetWorkformDone = () => {
		const { onSetWorkformDone } = this.props;
		if(onSetWorkformDone) { 
			onSetWorkformDone({
				workform: { ...this.state }
			}); 
		}
	}

	render() {
		const { direction = 'default', data = {}, saving } = this.props;
		const { answers = [] } = this.state;

		return (
			<TextMPC
				data={{ ...this.state }}
				saving={saving}
				onSubmit={this.handleSubmit}
				onFinished={this.handleFinish}
				onSetWorkformDone={this.handleSetWorkformDone}
				onChange={this.handleChange}
				direction={direction}
				disabled={!answers.some((item) => item.selected) || this.state.buttonDisabled || data.disableButton}
			/>
		);
	}
}

export default TextMpcContainer;
