'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _AccountWrapper = require('../AccountWrapper');

var _AccountWrapper2 = _interopRequireDefault(_AccountWrapper);

var _graphql = require('../graphql');

var _appCore = require('@manakin/app-core');

var _TableCell = require('@material-ui/core/TableCell');

var _TableCell2 = _interopRequireDefault(_TableCell);

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _TableRow = require('@material-ui/core/TableRow');

var _TableRow2 = _interopRequireDefault(_TableRow);

var _core = require('@material-ui/core');

var _NearMe = require('@material-ui/icons/NearMe');

var _NearMe2 = _interopRequireDefault(_NearMe);

var _Link = require('@material-ui/icons/Link');

var _Link2 = _interopRequireDefault(_Link);

var _DeleteOutline = require('@material-ui/icons/DeleteOutline');

var _DeleteOutline2 = _interopRequireDefault(_DeleteOutline);

var _IconButton = require('@material-ui/core/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _reactHooks = require('@apollo/react-hooks');

var _InviteChangeTrainerDialog = require('./parts/InviteChangeTrainerDialog');

var _InviteChangeTrainerDialog2 = _interopRequireDefault(_InviteChangeTrainerDialog);

var _reactI18next = require('react-i18next');

var _InviteTrainerCard = require('./parts/InviteTrainerCard');

var _InviteTrainerCard2 = _interopRequireDefault(_InviteTrainerCard);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var InviteStatus = {
	SEND: "INVITATION_SEND",
	ACCEPTED: "INVITATION_ACCEPTED"
};

var useStyles = (0, _core.makeStyles)(function (theme) {
	return {
		body: {
			margin: 0,
			overflow: 'hidden',
			textOverflow: 'ellipsis'
		},
		bodyCell: {
			verticalAlign: 'middle'
		},
		inviteCard: {
			marginBottom: theme.spacing(8)
		},
		link: {},
		tableCellWithIconText: {
			display: 'inline',
			verticalAlign: 'middle'
		},
		tableCellWithAction: {
			padding: 0,
			cursor: 'pointer'
		},
		activeStatusColor: {
			color: theme.manakin.indicationColor[500]
		}
	};
});

var InviteTrainer = function InviteTrainer(props) {
	var classes = useStyles();

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var _useState = (0, _react.useState)(false),
	    _useState2 = _slicedToArray(_useState, 2),
	    openDeleteDialog = _useState2[0],
	    setOpenDeleteDialog = _useState2[1];

	var _useState3 = (0, _react.useState)(null),
	    _useState4 = _slicedToArray(_useState3, 2),
	    currentInvite = _useState4[0],
	    setCurrentInvite = _useState4[1];

	var _useQuery = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_INVITES),
	    _useQuery$data = _useQuery.data,
	    data = _useQuery$data === undefined ? {} : _useQuery$data,
	    refetch = _useQuery.refetch;

	var _data$invitations = data.invitations,
	    invitations = _data$invitations === undefined ? [] : _data$invitations;


	var getIconForStatus = function getIconForStatus(status) {
		if (status === InviteStatus.SEND) {
			return _react2.default.createElement(_NearMe2.default, null);
		} else if (status === InviteStatus.ACCEPTED) {
			return _react2.default.createElement(_Link2.default, null);
		} else {
			return _react2.default.createElement(_react2.default.Fragment, null);
		}
	};

	var mapStateToHumanReadable = function mapStateToHumanReadable(state) {
		switch (state) {
			case InviteStatus.SEND:
				return t('app.account.invite.status.sent');
			case InviteStatus.ACCEPTED:
				return t('app.account.invite.status.accepted');
		}
	};

	var handleOpenDeleteDialog = function handleOpenDeleteDialog(item) {
		setCurrentInvite(item);
		setOpenDeleteDialog(true);
	};

	var handleDeleteDialogSuccess = function handleDeleteDialogSuccess() {
		setOpenDeleteDialog(false);
		refetch();
	};

	return _react2.default.createElement(
		_AccountWrapper2.default,
		{
			title: t('app.account.invite.title'),
			introduction: _react2.default.createElement(
				_react2.default.Fragment,
				null,
				t('app.account.invite.intro')
			),
			wrapper: 'medium'
		},
		_react2.default.createElement(_InviteTrainerCard2.default, {
			invitations: invitations,
			className: classes.inviteCard,
			onSuccess: refetch
		}),
		_react2.default.createElement(
			_appCore.ContentHeader,
			{
				title: t('app.account.invite.invites-header')
			},
			invitations && _react2.default.createElement(_appCore.SimpleTable, {
				renderHead: function renderHead() {
					return _react2.default.createElement(
						_react2.default.Fragment,
						null,
						_react2.default.createElement(
							_TableCell2.default,
							{ align: 'left' },
							t('common.table-headers.email')
						),
						_react2.default.createElement(
							_TableCell2.default,
							{ align: 'left' },
							t('common.table-headers.role')
						),
						_react2.default.createElement(
							_TableCell2.default,
							{ align: 'left' },
							t('common.table-headers.status')
						),
						_react2.default.createElement(
							_TableCell2.default,
							{ align: 'left' },
							t('common.table-headers.action')
						)
					);
				},
				renderBody: function renderBody() {
					return _react2.default.createElement(
						_react2.default.Fragment,
						null,
						invitations.map(function (item) {
							return _react2.default.createElement(
								_TableRow2.default,
								{ key: item.id },
								_react2.default.createElement(
									_TableCell2.default,
									{ align: 'left', classes: { body: classes.bodyCell } },
									_react2.default.createElement(
										_Typography2.default,
										{ variant: 'body1', className: classes.body },
										item.trainerEmail
									)
								),
								_react2.default.createElement(
									_TableCell2.default,
									{ align: 'left', classes: { body: classes.bodyCell } },
									_react2.default.createElement(
										_Typography2.default,
										{ style: {}, variant: 'body1', className: classes.body },
										item.trainerRole.title
									)
								),
								_react2.default.createElement(
									_TableCell2.default,
									{
										align: 'left', width: 150,
										classes: { body: classes.bodyCell },
										className: classes.tableCellWithAction
									},
									_react2.default.createElement(
										_IconButton2.default,
										{
											'aria-label': 'Status',
											className: (0, _classnames2.default)(_defineProperty({}, classes.activeStatusColor, item.state === InviteStatus.ACCEPTED))
										},
										getIconForStatus(item.state)
									),
									_react2.default.createElement(
										_Typography2.default,
										{
											variant: 'body1',
											className: (0, _classnames2.default)(classes.body, classes.tableCellWithIconText, _defineProperty({}, classes.activeStatusColor, item.state === InviteStatus.ACCEPTED))
										},
										mapStateToHumanReadable(item.state)
									)
								),
								_react2.default.createElement(
									_TableCell2.default,
									{
										align: 'left',
										width: 160,
										classes: { body: classes.bodyCell },
										className: classes.tableCellWithAction,
										onClick: function onClick() {
											return handleOpenDeleteDialog(item);
										}
									},
									_react2.default.createElement(
										_IconButton2.default,
										{ 'aria-label': t('app.account.invite.delete') },
										_react2.default.createElement(_DeleteOutline2.default, null)
									),
									_react2.default.createElement(
										_Typography2.default,
										{
											variant: 'body1',
											className: (0, _classnames2.default)(classes.body, classes.tableCellWithIconText)
										},
										t('app.account.invite.delete')
									)
								)
							);
						})
					);
				}
			})
		),
		_react2.default.createElement(_InviteChangeTrainerDialog2.default, {
			open: openDeleteDialog,
			invite: currentInvite,
			onSuccess: handleDeleteDialogSuccess,
			onClose: function onClose() {
				return setOpenDeleteDialog(false);
			}
		})
	);
};

exports.default = InviteTrainer;