"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _core = require("@material-ui/core");

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactApollo = require("react-apollo");

var _AccountWrapper = require("../AccountWrapper");

var _AccountWrapper2 = _interopRequireDefault(_AccountWrapper);

var _NotificationList = require("./NotificationList");

var _NotificationList2 = _interopRequireDefault(_NotificationList);

var _graphql = require("../graphql");

var _appCore = require("@manakin/app-core");

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useStyles = (0, _core.makeStyles)(function (theme) {
	return {
		list: {
			marginBottom: "52px"
		}
	};
});

var Notifications = function Notifications(props) {
	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var classes = useStyles();

	var _useQuery = (0, _reactApollo.useQuery)(_graphql.GQL_FETCH_NOTIFICATIONS),
	    _useQuery$data = _useQuery.data,
	    data = _useQuery$data === undefined ? {} : _useQuery$data,
	    loading = _useQuery.loading;

	var _data$notifications = data.notifications,
	    notifications = _data$notifications === undefined ? [] : _data$notifications;


	if (loading) return _react2.default.createElement(_appCore.Loader, { fullScreen: true });
	return _react2.default.createElement(
		_AccountWrapper2.default,
		{
			title: t("app.account.notifications.title"),
			introduction: t("app.account.notifications.intro"),
			wrapper: "medium"
		},
		notifications.map(function (notification) {
			return _react2.default.createElement(
				"div",
				{ className: classes.list, key: notification.id },
				_react2.default.createElement(_NotificationList2.default, { data: notification })
			);
		})
	);
};

exports.default = Notifications;