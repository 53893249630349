import gql from 'graphql-tag';
import { fileAsset, appAssetWithThumbnail } from '@manakin/app-core/graphQlHelpers';

export const GQL_FETCH_EXAM_TRAINER = gql`
    query examTrainer($id: ID!) {
        examTrainer(id: $id) {
            id
            type
            introTitle
            introText
            preTitle
            prefixColor
            titleColor
            styleSheet {
                id
                indicationColor
                defaultWorkformColor
                primaryWorkformColor
                secondaryWorkformColor
                themeColor
            }
            image {
                ${appAssetWithThumbnail}
            }
            workforms {
                id
                type
            }
            examTrainerTags {
                id
                name
            }
            successText
            failureText
        }
    }
`;

export const GQL_FETCH_EXAM_TRAINER_QUESTIONS_FOR_EXAM_TRAINER = gql`
	query examTrainerQuestionsForExamTrainer($examTrainerId: ID!) {
		examTrainerQuestionsForExamTrainer(examTrainerId: $examTrainerId) {
			id
			type
		}
	}
`;

export const GQL_FETCH_EXAM_TRAINER_QUESTIONS = gql`
	query examTrainerQuestions($tags: [ID!]!, $category: ID) {
		examTrainerQuestions(tags: $tags, category: $category) {
			workforms {
				id
				type
			}
		}
	}
`;

export const GQL_FETCH_ELEMENT_RESULTS = gql`
	query elementResult($box: ID!, $program: ID!, $user: ID!, $element: ID!) {
		elementResult(box: $box, program: $program, user: $user, element: $element) {
			bestTime {
				id
				time
			}
		}
	}
`;

export const GQL_CREATE_ELEMENT_RESULT = gql`
	mutation createElementResult($user: ID!, $program: ID!, $box: ID!, $element: ID!, $finished: Boolean, $bestTime: Int) {
		createElementResult(
			input: { user: $user, program: $program, box: $box, element: $element, finished: $finished, bestTime: $bestTime }
		) {
			result {
				finished
			}
		}
	}
`;

export const GQL_FETCH_GLOBAL_SETTINGS = gql`
    query settings{
        settings {
            settings {
                name
                value
                file {
                    ${fileAsset}
                }
            }
        }
    }
`;

export const GQL_FETCH_STYLESHEET = gql`
	query styleSheet($id: ID!) {
		styleSheet(id: $id) {
			id
			title
			description
			customStyle
			indicationColor
			defaultWorkformColor
			primaryWorkformColor
			secondaryWorkformColor
			themeColor
		}
	}
`;

export const GQL_FETCH_SCORES_FOR_ELEMENT = gql`
	query examTrainerScores($examTrainerId: ID, $programId: ID) {
		examTrainerScores(examTrainerId: $examTrainerId, programId: $programId) {
			id
			score
			tags {
				id
				name
			}
			createdOn
		}
	}
`;

export const GQL_CREATE_EXAM_TRAINER_SCORE = gql`
	mutation createExamTrainerScore($input: CreateExamTrainerScoreInput) {
		createExamTrainerScore(input: $input) {
			id
			score
			tags {
				id
				name
			}
		}
	}
`;
