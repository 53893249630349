import gql from 'graphql-tag';

export const GQL_FETCH_SERVER_INFO = gql`
	query serverInfo {
		serverInfo {
			currentYear
		}
	}
`;

export const GQL_FETCH_GROUPS = gql`
	query groups($filter: GroupFilter, $page: Int, $pagesize: Int, $search: String) {
		groups(filter: $filter, page: $page, pagesize: $pagesize, search: $search) {
			groups {
				id
				name
				enabled
			}
		}
	}
`;

export const GQL_FETCH_REGISTRATION_GROUPS = gql`
	query registrationGroups($page: Int!, $pagesize: Int!, $search: String, $filter: RegistrationGroupFilter) {
		registrationGroups(page: $page, pagesize: $pagesize, search: $search, filter: $filter) {
			id
			name
			visibleForRegistration
			type
		}
	}
`;

export const GQL_FETCH_MAIN_ORGANISATION = gql`
	query mainOrganisation($id: ID!) {
		mainOrganisation(id: $id) {
			id
			name
			organisations {
				id
				schools {
					id
					classes {
						id
						name
					}
				}
			}
		}
	}
`;

export const GQL_UPDATE_ORGANISATION = gql`
	mutation updateOrganisation(
		$email: String!
		$password: String
		$firstName: String
		$middleName: String
		$lastName: String
		$gender: Gender
		$dateOfBirth: String
		$phonenumber: String
		$registerCode: String
		$newsLetter: Boolean
		$UserDataCbdInput: UserDataCbdInput
		$roles: [String]
		$schoolClasses: [ID]
	) {
		updateOrganisation(
			input: {
				email: $email
				password: $password
				firstName: $firstName
				middleName: $middleName
				lastName: $lastName
				gender: $gender
				dateOfBirth: $dateOfBirth
				phonenumber: $phonenumber
				registerCode: $registerCode
				newsLetter: $newsLetter
				UserDataCbdInput: $UserDataCbdInput
				roles: $roles
				schoolClasses: $schoolClasses
			}
		) {
			user {
				id
			}
		}
	}
`;

export const GQL_REGISTER_APP_USER = gql`
	mutation registerAppUser (
		$email: String!
		$password: String
		$firstName: String
		$middleName: String
		$lastName: String
		$gender: Gender
		$dateOfBirth: String
		$phonenumber: String
		$school: Long
		$schoolClasses: [ID]
		$registerCode: String
		$registerType: String
		$sendVerifyMail: Boolean
	) {
		registerAppUser(
			input: {
				email: $email
				password: $password
				firstName: $firstName
				middleName: $middleName
				lastName: $lastName
				gender: $gender
				dateOfBirth: $dateOfBirth
				phonenumber: $phonenumber
				school: $school
				schoolClasses: $schoolClasses
				registerCode: $registerCode
				registerType: $registerType
				sendVerifyMail: $sendVerifyMail
			}) {
			user {
				id
				fullName
			}
		}
	}
`;

