import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { KeyboardArrowDown } from '@material-ui/icons';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				pointerEvents: 'visible',
			},
			selected: {},
			menuItem: {
				height: '50px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				backgroundColor: 'white',
				'&:nth-child(even)': {
					backgroundColor: '#f2f2f2',
				},
				'&:hover': {
					backgroundColor: theme.palette.primary[300],
					'& $listItemText': {
						color: theme.palette.getContrastText(
							theme.palette.primary[300]
						),
					},
				},
				[theme.breakpoints.up('md')]: {
					minWidth: '450px',
				},
				'&$selected': {
					backgroundColor: theme.palette.primary[500],
					color: theme.palette.getContrastText(
						theme.palette.primary[500]
					),
					'&:hover': {
						backgroundColor: theme.palette.primary[300],
						color: theme.palette.getContrastText(
							theme.palette.primary[300]
						),
					},
				},
			},
			formControlLabel: {
				// transform: "translate(0, 33px)",
				color: theme.manakin.defaultContentColor[500],
			},
			listItemText: {
				margin: '0 0 0 10px',
			},
			checked: {
				color: 'black',
			},
			title: {
				display: 'flex',
				alignItems: 'center',
			},
			icon: {
				width: '20px',
				height: '20px',
				display: 'flex',
			},
			label: {
				color: theme.manakin.defaultContentColor[500],
			},
			chips: {
				marginTop: '10px',
				whiteSpace: 'nowrap',
			},
			chip: {
				backgroundColor: 'white',
				marginRight: '20px',
			},
		}),
	{
		name: 'AppDasboardSelectField',
	}
);

const SelectField = (props) => {
	const { label, options = [] } = props;
	const classes = useStyles();

	//state hooks
	const [value, setValue] = useState([]);
	const [open, setOpen] = useState(false);

	//effect hooks
	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
	}, []);

	//functions
	const handleForm = (val) => {
		let arr = [];
		options.forEach((item) => {
			if (val.some((i) => i === item.label)) arr.push(item);
		});

		if (props.onChange) props.onChange(arr);
	};

	const handleChange = (event) => {
		const val = event.target.value;
		setValue(val);
		handleForm(val);
	};

	const handleDelete = (val) => () => {
		const arr = [];

		value.forEach((item) => {
			if (item !== val) arr.push(item);
		});
		setValue(arr);
		handleForm(arr);
	};

	const handleOpenState = (state) => {
		setOpen(state);
	};

	const handleScroll = () => {
		handleOpenState(false);
	};

	return (
		<FormControl className={classes.root}>
			<InputLabel
				id="filter-label"
				classes={{ formControl: classes.formControlLabel }}
				shrink
			>
				{label}
			</InputLabel>
			<Select
				labelId="filter-label"
				id="theme-select"
				value={value}
				multiple
				renderValue={(selected) => ''}
				onChange={handleChange}
				onOpen={() => handleOpenState(true)}
				onClose={() => handleOpenState(false)}
				open={open}
				IconComponent={KeyboardArrowDown}
			>
				{options.map((option) => (
					<MenuItem
						key={option.id}
						value={option.label}
						classes={{
							root: classes.menuItem,
							selected: classes.selected,
						}}
					>
						<div className={classes.title}>
							{option.icon && (
								<figure className={classes.icon}>
									<img src={option.icon.url} />
								</figure>
							)}
							<Typography
								variant="body1"
								className={classes.listItemText}
							>
								{option.label}
							</Typography>
						</div>
						<Checkbox
							checked={value.indexOf(option.label) > -1}
							color="primary"
							classes={{
								checked: classes.checked,
							}}
						/>
					</MenuItem>
				))}
			</Select>
			<div className={classes.chips}>
				{value.map((val) => (
					<Chip
						key={val}
						label={val}
						className={classes.chip}
						onDelete={handleDelete(val)}
					/>
				))}
			</div>
		</FormControl>
	);
};

export default SelectField;
