export const PersonalDataFormSettings = {
    firstName: {
        required: true,
    },
    middleName: {
        required: false,
    },
    lastName: {
        required: true,
    },
    dateOfBirth: {
        required: true,
        format: 'date',
    },
    function: {},
    educationOrganisation: {}
};
