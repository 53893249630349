'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactHooks = require('@apollo/react-hooks');

var _graphql = require('./graphql');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useViewCounting = function useViewCounting() {
    var _useMutation = (0, _reactHooks.useMutation)(_graphql.GQL_VIEW_FACT_CARD),
        _useMutation2 = _slicedToArray(_useMutation, 2),
        viewFactCardMutation = _useMutation2[0],
        viewFactCardCalled = _useMutation2[1].called;

    var _useMutation3 = (0, _reactHooks.useMutation)(_graphql.GQL_VIEW_ELEMENT),
        _useMutation4 = _slicedToArray(_useMutation3, 2),
        viewElementMutation = _useMutation4[0],
        viewElementCalled = _useMutation4[1].called;

    var viewElement = function viewElement(id) {
        if (viewElementCalled) return;
        viewElementMutation({ variables: { elementId: id } });
    };

    var viewFactCard = function viewFactCard(id) {
        if (viewFactCardCalled) return;
        viewFactCardMutation({ variables: { factCardId: id } });
    };

    return { viewElement: viewElement, viewFactCard: viewFactCard };
};

exports.default = useViewCounting;