import React from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { DialogContent, DialogContentText } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import { ImageFit } from '@manakin/app-workforms/lib';
import { SimpleSelect } from '@manakin/core';

const useStyles = makeStyles(
    (theme) =>
        createStyles({
            content: {
                margin: 24,
                padding: 0,
                position: "relative",
                overflow: "auto",
                minHeight: "auto",
            },
            imageWrapper: {
                position: "relative",
                display: "inline-block",
            },
            image: {
                overflow: "hidden",
            },
            focusPoint: {
                position: "absolute",
                width: "20px",
                height: "20px",
                transform: "translate(-50%, -50%)",
                backgroundColor: theme.manakin.otherRedThemeColor[500],
                zIndex: "2",
                borderRadius: "100%",
            },
        }),
    {
        name: "FocusPointDialogContent",
    },
);

const FocusPointDialogContent = (props) => {
    const { form, name, image, focusPointCoordinates, setFocusPointCoordinates, onHandleFit, initialValue } = props;
    const { fields } = form;
    const classes = useStyles();
    const { t } = useTranslation();

    const handleImageClick = (event) => {
        const imageHeight = event.nativeEvent.target.clientHeight;
        const imageWidth = event.nativeEvent.target.clientWidth;

        const x = Math.round((event.nativeEvent.layerX / imageWidth) * 100);
        const y = Math.round((event.nativeEvent.layerY / imageHeight) * 100);

        setFocusPointCoordinates({ x, y });
    };

    const focusPointX = (focusPointCoordinates.x !== undefined && focusPointCoordinates.x !== null) ? focusPointCoordinates.x :
        (fields[name] && fields[name].focusPointX !== undefined && fields[name].focusPointX !== null) ? fields[name].focusPointX : undefined;
    const focusPointY = (focusPointCoordinates.y !== undefined && focusPointCoordinates.y !== null) ? focusPointCoordinates.y :
        (fields[name] && fields[name].focusPointY !== undefined && fields[name].focusPointY !== null) ? fields[name].focusPointY : undefined;

    return (
		<DialogContent className={classes.content}>
			<DialogContentText>{t('core.focus-point-dialog.description')}</DialogContentText>
			<span className={classes.imageWrapper}>
				<img className={classes.image} src={image} onClick={handleImageClick} />

				{fields[name] &&
					((focusPointCoordinates.x !== undefined && focusPointCoordinates.x !== null) ||
						(fields[name].focusPointX !== undefined && fields[name].focusPointX !== null)) && (
						<div
							style={{
								left: `${focusPointX}%`,
								top: `${focusPointY}%`,
							}}
							className={classes.focusPoint}
						/>
					)}
			</span>
			<SimpleSelect
				onChange={onHandleFit}
				initialValue={initialValue || ImageFit.NOT_SPECIFIED}
				options={[
					{
						id: ImageFit.NOT_SPECIFIED,
						label: t('common.fields.images.default'),
					},
					{
						id: ImageFit.COVER,
						label: t('common.fields.images.cover'),
					},
					{
						id: ImageFit.CONTAIN,
						label: t('common.fields.images.contain'),
					},
				]}
			/>
		</DialogContent>
	);
};

export default FocusPointDialogContent;