"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _core = require("@manakin/core");

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SelectFieldComponent = function SelectFieldComponent(props) {
	var form = props.form;

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	return _react2.default.createElement(
		"div",
		null,
		_react2.default.createElement(_core.SelectField, {
			name: "Filter",
			label: t("common.fields.locale.label"),
			form: form,
			options: [{
				label: "test",
				id: "test"
			}, {
				label: "test 2",
				id: "test 2"
			}]
		})
	);
};

exports.default = SelectFieldComponent;