"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _styles = require("@material-ui/styles");

var _Typography = require("@material-ui/core/Typography");

var _Typography2 = _interopRequireDefault(_Typography);

var _DescriptionOutlined = require("@material-ui/icons/DescriptionOutlined");

var _DescriptionOutlined2 = _interopRequireDefault(_DescriptionOutlined);

var _DeleteOutline = require("@material-ui/icons/DeleteOutline");

var _DeleteOutline2 = _interopRequireDefault(_DeleteOutline);

var _IconButton = require("@material-ui/core/IconButton");

var _IconButton2 = _interopRequireDefault(_IconButton);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useStyles = (0, _styles.makeStyles)(function (theme) {
	return {
		root: {
			display: "flex",
			flexDirection: "row",
			marginTop: theme.spacing(),
			marginBottom: theme.spacing(),
			padding: theme.spacing(2),
			border: "1px solid " + theme.manakin.defaultBorderColor[500],
			alignItems: "center",
			borderRadius: 4,
			cursor: "pointer",
			justifyContent: "space-between",
			"&:hover": {
				borderColor: theme.manakin.indicationColor[500]
			}
		},
		flex: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center"
		},
		icon: {
			width: 24,
			height: 24,
			color: theme.manakin.defaultBorderColor[500]
		},
		title: {
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(2),
			flexGrow: 1,
			marginBottom: 0,
			overflow: "hidden"
		}
	};
});

var UploadedFile = function UploadedFile(props) {
	var _props$asset = props.asset,
	    asset = _props$asset === undefined ? {} : _props$asset,
	    readOnly = props.readOnly,
	    onFileClick = props.onFileClick,
	    onFileDelete = props.onFileDelete;

	var classes = useStyles();

	return _react2.default.createElement(
		"div",
		{ key: asset.id, className: classes.root },
		_react2.default.createElement(
			"div",
			{ className: classes.flex, onClick: function onClick() {
					return onFileClick(asset);
				} },
			_react2.default.createElement(_DescriptionOutlined2.default, { className: classes.icon }),
			_react2.default.createElement(
				_Typography2.default,
				{
					component: "div",
					variant: "body1",
					className: (0, _classnames2.default)(classes.contentColor, classes.title)
				},
				asset.name
			)
		),
		!readOnly && _react2.default.createElement(
			_IconButton2.default,
			{ onClick: function onClick() {
					return onFileDelete(asset);
				} },
			_react2.default.createElement(_DeleteOutline2.default, { className: classes.icon })
		)
	);
};

exports.default = UploadedFile;