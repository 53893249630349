'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _lib = require('@manakin/app-core/lib');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useGetBoxResult = function useGetBoxResult(result, box) {
    var _result$elementResult = result.elementResults,
        elementResults = _result$elementResult === undefined ? false : _result$elementResult;
    var _box$elements = box.elements,
        elements = _box$elements === undefined ? [] : _box$elements;

    if (!elementResults) {
        return 0;
    } else if (!elements) {
        return 100;
    } else {
        return Math.ceil((0, _lib.getPercentage)(elementResults, elements)) > 100 ? 100 : Math.ceil((0, _lib.getPercentage)(elementResults, elements));
    }
};

exports.default = useGetBoxResult;