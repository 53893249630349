'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styles = require('@material-ui/core/styles');

var _Input = require('@material-ui/core/Input');

var _Input2 = _interopRequireDefault(_Input);

var _FormControl = require('@material-ui/core/FormControl');

var _FormControl2 = _interopRequireDefault(_FormControl);

var _recompose = require('recompose');

var _reactI18next = require('react-i18next');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
    return {
        root: {
            width: '100%'
        },
        title: _defineProperty({
            fontFamily: theme.manakin.primaryTitleFont,
            fontSize: '2.8rem',
            lineHeight: '2rem',
            letterSpacing: '.4rem'
        }, theme.breakpoints.up('md'), {
            paddingLeft: '2.4rem'
        }),
        inputRoot: _defineProperty({
            fontSize: '2.8rem'
        }, theme.breakpoints.down('md'), {
            fontSize: '2rem'
        }),
        input: {
            transition: 'opacity 200ms linear',
            marginRight: theme.spacing(2)
        },
        inputLarge: {
            fontSize: '40px'
        },
        smallInputMargin: {
            marginBottom: '20px'
        }
    };
};

var timeout = void 0;

var SearchInput = function (_React$Component) {
    _inherits(SearchInput, _React$Component);

    function SearchInput() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, SearchInput);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = SearchInput.__proto__ || Object.getPrototypeOf(SearchInput)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
            query: ''
        }, _this.handleSearchQueryChange = function (event) {
            var onQueryChange = _this.props.onQueryChange;

            var value = event.target.value;

            clearTimeout(timeout);
            _this.setState({
                query: value
            });

            if (value.length === 0) {
                onQueryChange(value);
            } else {
                timeout = setTimeout(function () {
                    return onQueryChange(value);
                }, 400);
            }
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(SearchInput, [{
        key: 'render',
        value: function render() {
            var _props = this.props,
                classes = _props.classes,
                _props$fullWidth = _props.fullWidth,
                fullWidth = _props$fullWidth === undefined ? true : _props$fullWidth,
                t = _props.t;
            var query = this.state.query;


            return _react2.default.createElement(
                'div',
                { className: classes.root },
                _react2.default.createElement(
                    _FormControl2.default,
                    {
                        fullWidth: fullWidth,
                        margin: 'normal',
                        className: classes.root
                    },
                    _react2.default.createElement(_Input2.default, {
                        id: 'search',
                        type: 'text',
                        disableUnderline: true,
                        autoFocus: true,
                        value: query,
                        onChange: this.handleSearchQueryChange,
                        placeholder: t("common.fields.search.placeholder"),
                        className: classes.input,
                        classes: {
                            root: classes.title,
                            input: classes.inputRoot
                        }
                    })
                )
            );
        }
    }]);

    return SearchInput;
}(_react2.default.Component);

exports.default = (0, _recompose.compose)((0, _reactI18next.withTranslation)(), (0, _styles.withStyles)(styles, { name: 'AppSearchInput' }))(SearchInput);