'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _actions = require('@manakin/app-core/actions');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useAppPermissions = function useAppPermissions() {
    var currentAppUser = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    if (currentAppUser && currentAppUser.roles) {
        (0, _actions.savePermissions)(currentAppUser.roles);
    }
};

exports.default = useAppPermissions;