import gql from "graphql-tag";

export const GQL_FETCH_CURRENT_APP_USER = gql`
	query currentAppUser {
		currentAppUser {
			id
			email
		}
	}
`;

export const GQL_FETCH_CURRENT_CMS_USER = gql`
	query currentCmsUser {
		currentCmsUser {
			id
			email
		}
	}
`;