'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var useAssetUpload = function useAssetUpload(url) {
	var _useState = (0, _react.useState)(null),
	    _useState2 = _slicedToArray(_useState, 2),
	    data = _useState2[0],
	    setData = _useState2[1];

	var _useState3 = (0, _react.useState)(null),
	    _useState4 = _slicedToArray(_useState3, 2),
	    input = _useState4[0],
	    setInput = _useState4[1];

	var _useState5 = (0, _react.useState)(null),
	    _useState6 = _slicedToArray(_useState5, 2),
	    error = _useState6[0],
	    setError = _useState6[1];

	var _useState7 = (0, _react.useState)(false),
	    _useState8 = _slicedToArray(_useState7, 2),
	    loading = _useState8[0],
	    setLoading = _useState8[1];

	var returnData = {
		data: data,
		error: error,
		loading: loading,
		input: input
	};

	var fetchData = function fetchData(data) {
		setLoading(true);
		var formData = new FormData();
		formData.append('file', data);
		setInput(data);

		fetch(url, {
			method: 'POST',
			body: formData
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			setData(data.data || data);
		}).catch(function (error) {
			setError(error);
		}).then(function () {
			setLoading(false);
		});
	};

	return [fetchData, returnData];
};

exports.default = useAssetUpload;