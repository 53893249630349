import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import Typography from '@material-ui/core/Typography';
import Media from 'react-media';

const styles = (theme) => ({
    container: {
        width: '100%',
        padding: theme.spacing(16),
    },
    containerMobile: {
        width: '100%',
        padding: theme.spacing(3),
    },
    title: {
        width: '100%',
        maxWidth: '382px',
        color: '#000000',
        fontSize: '40px',
        fontWeight: '800',
        lineHeight: '54px',
    },
    text: {
        paddingTop: theme.spacing(4),
        color: '#000000',
        fontSize: '18px',
        lineHeight: '36px',
        width: '100%',
        maxWidth: '500px',
    },
    leftFloat: {
        float: 'left',
        paddingRight: theme.spacing(16),
    },
    rightFloat: {
        float: 'left',
    },
    showMore: {
        marginTop: theme.spacing(2),
        fontWeight: 'bold',
        color: '#000000',
        fontSize: '18px',
        lineHeight: '36px',
        letterSpacing: '3pt',
        textTransform: 'uppercase',
        cursor: 'pointer',
        userSelect: 'none',
    },
    showMoreDiv: {
        opacity: '0',
        height: '0px',
        transition: 'opacity 0.3s linear',
        display: 'none',
    },
    keyboardArrow: {
        verticalAlign: 'text-bottom',
        transition: 'transform 0.3s linear',
    },
});

class TitleText extends React.Component {
    state = {
        showMoreState: false,
    };

    showMoreDiv = () => {
        let showMoreDiv = document.getElementById('showMoreDiv');
        let keyBoardDown = document.getElementById('keyBoardDown');

        if (this.state.showMoreState) {
            showMoreDiv.style.display = 'none';
            showMoreDiv.style.height = '0px';
            showMoreDiv.style.opacity = '0';

            keyBoardDown.style.transform = 'rotate(0deg)';
        } else {
            showMoreDiv.style.display = 'initial';
            showMoreDiv.style.height = 'auto';
            showMoreDiv.style.opacity = '1';

            keyBoardDown.style.transform = 'rotate(180deg)';
        }

        this.setState({
            showMoreState: !this.state.showMoreState,
        });
    };

    render() {
        const { classes, backgroundColor, text, title } = this.props;

        return (
            <Media query="(max-width: 860px)">
                {(matches) =>
                    matches ? (
                        <div
                            className={classes.containerMobile}
                            style={{ backgroundColor: backgroundColor }}
                        >
                            <Typography className={classes.title}>
                                {title}
                            </Typography>

                            {text.length > 0 && (
                                <Typography className={classes.text}>
                                    {text[0].text}
                                </Typography>
                            )}

                            <Typography
                                className={classes.showMore}
                                onClick={this.showMoreDiv}
                            >
                                Lees meer{' '}
                                <KeyboardArrowDown
                                    id={'keyBoardDown'}
                                    className={classes.keyboardArrow}
                                />
                            </Typography>

                            <div
                                className={classes.showMoreDiv}
                                id={'showMoreDiv'}
                            >
                                {text.map((e, index) => {
                                    if (index > 0) {
                                        return (
                                            <Typography
                                                className={classes.text}
                                            >
                                                {e.text}
                                            </Typography>
                                        );
                                    }
                                })}
                            </div>

                            <div style={{ clear: 'both' }}></div>
                        </div>
                    ) : (
                        <div
                            className={classes.container}
                            style={{ backgroundColor: backgroundColor }}
                        >
                            <div className={classes.leftFloat}>
                                <Typography className={classes.title}>
                                    {title}
                                </Typography>

                                <Typography className={classes.text}>
                                    {text.length > 0 && text[0].text}
                                </Typography>
                            </div>

                            <div className={classes.rightFloat}>
                                <Typography className={classes.text}>
                                    {text.length > 1 && text[1].text}
                                </Typography>

                                <Typography className={classes.text}>
                                    {text.length > 2 && text[2].text}
                                </Typography>
                            </div>

                            <div style={{ clear: 'both' }}></div>
                        </div>
                    )
                }
            </Media>
        );
    }
}

export default withStyles(styles, { withTheme: true })(TitleText);
