import gql from 'graphql-tag';

export const GQL_FETCH_CMS_ASSETS = gql`
    query(
        $page: Int!
        $pagesize: Int!
        $customerName: String
        $projectName: String
        $search: String
        $type: String
    ) {
        mediaList(
            page: $page
            pagesize: $pagesize
            customerName: $customerName
            projectName: $projectName
            search: $search
            type: $type
        ) {
            mediaList {
                id
                type
                name
                description
                type
                thumbnails
                original
            }
            count
        }
    }
`;

export const GQL_FETCH_CMS_SETTINGS = gql`
    query {
        settings {
            settings {
                name
                value
            }
        }
    }
`;