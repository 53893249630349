import React, { useState, useEffect, useRef } from 'react';
import CalendarButton from './CalendarButton';
import { FullScreenDialog, BigCalendar } from '@manakin/core';
import { useTranslation } from 'react-i18next';
import { GQL_FETCH_EVENTS } from '../graphql';
import { useLazyQuery } from '@apollo/react-hooks';
import PlannerPopup from './PlannerPopup';
import moment from 'moment';
import 'moment/min/locales';

const orderEvents = (dates) => {
	return dates.sort((a, b) => {
		return new Date(a.date) - new Date(b.date);
	});
};

const getFirstOfMonth = () => {
    return moment().startOf('month').toISOString(true);
};

const Planner = (props) => {
	const { className } = props;
	const [open, setOpen] = useState(false);
	const [eventOpen, setEventOpen] = useState(false);
	const [events, setEvents] = useState([]);
	const [event, setEvent] = useState();
	const [date, setDate] = useState(new Date());
	const { t } = useTranslation();
	const mounted = useRef(false);

	const [getEvents, { loading }] = useLazyQuery(GQL_FETCH_EVENTS, {
		onCompleted: (data) => handleCompleted(data),
	});

	useEffect(() => {
		getEvents({
			variables: {
				filter: {
					fromDate: getFirstOfMonth(),
				},
			},
		});
	}, []);

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
    
	const handleRangeChange = (dates) => {
		if (moment(dates.start).isAfter(date)) setDate(moment(date).add(1, 'months'));
		else setDate(moment(date).subtract(1, 'months'));
		getEvents({
			variables: {
				filter: {
					fromDate: new Date(dates.start),
					toDate: new Date(dates.end),
				},
			},
		});
	};

	const normalizeEvents = (events) => {
		const eventsMap = orderEvents(events || []).map((event) => {
			const dateNow = new Date();
			const dateEvent = new Date(event.date);

			if(event.state === "PLANNED" && moment(dateEvent).isSameOrAfter(dateNow)) {
				return {
					...event,
					start: event.date,
					end: event.date,
					allDay: true,
				};
			}

			return null;
		});

		return eventsMap.filter((event) => event !== null);
	};

	const handleCompleted = (data) => {
		if (data && data.events) {
			const newEvents = normalizeEvents(data.events.content);

			if (newEvents.length && !mounted.current) {
				mounted.current = true;
				setDate(newEvents[0].date);
			}
			setEvents(newEvents || []);
		}
	};

	const handleEventClick = (event) => {
		setEvent(event);
		setEventOpen(true);
	};
	const handleCloseEvent = () => {
		setEvent(null);
		setEventOpen(false);
	};

	return (
		<div>
			<CalendarButton className={className} onClick={handleClick} />
			<FullScreenDialog open={open} onClose={handleClose} title={t('app.dashboard.alt-content.planner.title')}>
				<BigCalendar
					onRangeChange={handleRangeChange}
					loading={loading}
					events={events}
					date={date}
					onSelectEvent={handleEventClick}
				/>
			</FullScreenDialog>
			<PlannerPopup onClose={handleCloseEvent} open={eventOpen} event={event} />
		</div>
	);
};

export default Planner;
