"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _appCore = require("@manakin/app-core");

var _core = require("@material-ui/core");

var _reactRedux = require("react-redux");

var _selectors = require("@manakin/app-core/ProgramsDropdown/selectors");

var _graphql = require("./graphql");

var _reactApollo = require("react-apollo");

var _reactRouterDom = require("react-router-dom");

var _selectors2 = require("@manakin/authentication/selectors");

var _lib = require("@manakin/app-core/lib");

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useStyles = (0, _core.makeStyles)(function (theme) {
	return {
		heading: {
			padding: "16px  0"
		},
		content: {
			margin: 0,
			color: "#BFBFBF",
			fontSize: "1.5rem",
			fontWeight: "600"
		}
	};
});

var AssignedStudentFinalRatings = function AssignedStudentFinalRatings(props) {
	var studentId = props.studentId;

	var classes = useStyles();
	var history = (0, _reactRouterDom.useHistory)();

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	//state hooks


	var _useState = (0, _react.useState)(false),
	    _useState2 = _slicedToArray(_useState, 2),
	    open = _useState2[0],
	    setOpen = _useState2[1];

	//selectors


	var program = (0, _reactRedux.useSelector)(function (state) {
		return (0, _selectors.getProgram)(state);
	});
	var appUser = (0, _reactRedux.useSelector)(function (state) {
		return (0, _selectors2.getAppUser)(state);
	});
	var isPo = appUser.roles.some(function (role) {
		return role.name === _lib.poRole || role.name === _lib.managementRole || role.name === _lib.bpvRole;
	});

	//queries

	var _useQuery = (0, _reactApollo.useQuery)(_graphql.GQL_FETCH_RATINGS, {
		variables: {
			id: program
		}
	}),
	    data = _useQuery.data,
	    loading = _useQuery.loading;

	var _ref = data || {},
	    _ref$finalRatings = _ref.finalRatings,
	    finalRatings = _ref$finalRatings === undefined ? [] : _ref$finalRatings;

	var handleClick = function handleClick(rating) {
		var ratingRequests = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

		if (ratingRequests.length) {
			history.push("/FinalRating/" + rating.id + "/" + studentId);
		} else setOpen(true);
	};

	var handleClose = function handleClose() {
		setOpen(false);
	};

	if (loading) return "";
	return _react2.default.createElement(
		"div",
		null,
		_react2.default.createElement(
			_appCore.StudentRatingCardLayout,
			{ className: classes.heading },
			_react2.default.createElement(
				_core.Typography,
				{ variant: "h5" },
				t("app.assigned-student.final-ratings.title")
			),
			_react2.default.createElement(
				_core.Typography,
				{ className: classes.content },
				t("common.uploads")
			),
			_react2.default.createElement(_core.Typography, { className: classes.content })
		),
		finalRatings.map(function (rating) {
			return _react2.default.createElement(_appCore.StudentRatingCard, {
				disabled: !isPo,
				data: rating,
				programId: program,
				finalRating: true,
				key: rating.id,
				studentId: studentId,
				onClick: function onClick(ratingRequests) {
					return handleClick(rating, ratingRequests);
				}
			});
		}),
		_react2.default.createElement(_appCore.Dialog, {
			open: open,
			title: t("app.assigned-student.no-invite-dialog.title"),
			content: t("app.assigned-student.no-invite-dialog.description"),
			buttonText: t("common.close"),
			onClose: handleClose
		})
	);
};

exports.default = AssignedStudentFinalRatings;