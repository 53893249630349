'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _graphql = require('./graphql');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _reactHooks = require('@apollo/react-hooks');

var _styles = require('@material-ui/styles');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var useStyles = (0, _styles.makeStyles)(function (theme) {
    return (0, _styles.createStyles)({
        loading: {
            opacity: '0'
        }
    });
});

var checkAccess = function checkAccess(currentUser, renderRules) {
    var giveAccess = false;

    if (currentUser) {
        // If any of the user's roles has any of the given rule privileges, allow access
        renderRules.forEach(function (rule) {
            currentUser.roles.forEach(function (role) {
                role.privileges.forEach(function (privilege) {
                    if (privilege.id === rule) giveAccess = true;
                });
            });
        });
    }

    return giveAccess;
};

var AccessControl = function AccessControl(props) {
    var children = props.children,
        _props$renderNoAccess = props.renderNoAccess,
        customRenderNoAccess = _props$renderNoAccess === undefined ? function () {
        return '';
    } : _props$renderNoAccess,
        renderRules = props.renderRules,
        onHasAccess = props.onHasAccess,
        disable = props.disable;

    var classes = useStyles();

    var _useQuery = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_CURRENT_APP_USER),
        _useQuery$data = _useQuery.data,
        data = _useQuery$data === undefined ? {} : _useQuery$data,
        loading = _useQuery.loading;

    var renderNoAccess = (0, _react.useCallback)(function () {
        return _react2.default.createElement(
            'div',
            { className: (0, _classnames2.default)(classes.noAccess, _defineProperty({}, classes.loading, loading)) },
            customRenderNoAccess()
        );
    }, []);

    // No access, no matter the rules
    if (disable) {
        return renderNoAccess();
    }

    // onHasAccess callback is provided and will be called instead of rendering children
    if (onHasAccess !== undefined && checkAccess(data.currentAppUser, renderRules)) {
        return onHasAccess();
    }

    if (checkAccess(data.currentAppUser, renderRules)) {
        return _react2.default.createElement(
            'div',
            { className: (0, _classnames2.default)(classes.root, _defineProperty({}, classes.loading, loading)) },
            children
        );
    }

    return renderNoAccess();
};

exports.default = AccessControl;