import React from 'react';
import { compose } from 'recompose';
import DragItem from './DragItem';
import { withStyles } from '@material-ui/core/styles';
import { DropTarget } from 'react-dnd';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import getDndContext from '../HTML5BackendContext/HTML5BackendContext';
import { withTranslation } from "react-i18next";

const styles = (theme) => ({
    wrapper: {
        maxWidth: theme.manakin.wrapper['small'],
        position: 'relative',
        width: '100%',
    },
    buttonWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    boxesHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '20px',
        alignItems: 'center',
    },
});

const itemSpecs = {};

function collectDrop(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
    };
}

class DragAndDrop extends React.Component {
    state = {
        items: [],
        grouping: false,
        ungroup: null,
    };

    componentDidMount() {
        const itemsFromProp = this.props.elements.map((item) =>
            item.map((i) => {
                return { ...i, selected: false };
            })
        );
        this.setState({ items: itemsFromProp });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.elements != this.props.elements) {
            const copied = this.props.elements.map((item) => [...item]);
            const itemsFromProp = copied.map((item) =>
                item.map((i) => {
                    return { ...i, selected: false };
                })
            );

            this.setState({ items: itemsFromProp });
        }
    }

    handleMoveItem = (dragIdx, hoverIdx, dragId, source) => {
        const { items } = this.state;
        if (items) {
            let tempArr = [...items];
            let ArrIdx;

            if (dragId) {
                tempArr.map((arr, id) => {
                    arr.map((subArr, idx) => {
                        if (subArr.id == dragId) ArrIdx = id;
                    });
                });
                if (source == 'item') {
                    const temp = items[dragIdx];
                    tempArr[dragIdx] = tempArr[hoverIdx];
                    tempArr[hoverIdx] = temp;
                } else {
                    const temp = items[ArrIdx][dragIdx];
                    tempArr[ArrIdx][dragIdx] = tempArr[ArrIdx][hoverIdx];
                    tempArr[ArrIdx][hoverIdx] = temp;
                }
            }

            this.setState({ items: tempArr }, this.props.onChange(tempArr));
        }
    };

    handleGrouping = () => {
        const { grouping: isGrouping, items } = this.state;
        let _items = [...items];

        if (isGrouping) {
            let newItems = [];
            let ids = [];
            _items.map((item, idx) =>
                item.map((_item) => {
                    if (_item.selected) {
                        _item.selected = false;
                        newItems.push(_item);
                        ids.push(idx);
                    }
                })
            );

            if (ids.length) {
                for (var i = ids.length - 1; i > 0; i--) {
                    _items.splice(ids[i], 1);
                }
                _items[ids[0]] = newItems;
            }
        }
        this.setState(
            {
                grouping: !isGrouping,
                ungroup: null,
                items: _items,
            },
            this.props.onChange(_items)
        );
    };

    handleUngrouping = (index) => {
        const { ungroup, items } = this.state;
        if (ungroup === null || ungroup != index) {
            this.setState({ ungroup: index, grouping: false });
        } else if (ungroup == index) {
            let _items = [...items];
            let arr = [];
            let ids = [];

            _items[index].map((item, idx) => {
                if (item.selected) {
                    arr.push([item]);
                    ids.push(idx);
                }
            });

            if (ids.length) {
                for (var i = ids.length - 1; i >= 0; i--) {
                    _items[index].splice(ids[i], 1);
                }
            }
            if (_items[index].length == 0) _items.splice(index, 1);
            _items.splice(index, 0, ...arr);
            this.setState(
                { ungroup: null, grouping: false, items: _items },
                this.props.onChange(_items)
            );
        } else {
            this.setState({ ungroup: null, grouping: false });
        }
    };

    changeBoxSelection = (id, idx) => (event) => {
        let updatedArr = [...this.state.items];
        updatedArr[id][idx].selected = event.target.checked;
        this.setState({ items: updatedArr });
    };

    onRemove = (index, idx) => {
        this.props.onRemove(index, idx);
    };

    handleItemClicked = (id) => {
        const { onItemClicked } = this.props;
        if (onItemClicked) onItemClicked(id);
    };

    render() {
        const {
            classes,
            connectDropTarget,
            hasGrouping = false,
            title = '',
            readOnly = false,
            t
        } = this.props;
        const { items, grouping, ungroup } = this.state;
        let groupButtonLabel = grouping ? t("core.drag-and-drop.group.save") : t("core.drag-and-drop.group.create");

        return connectDropTarget(
            <div className={classes.root}>
                <div className={classes.wrapper}>
                    <div className={classes.boxesHeader}>
                        <Typography
                            className={classes.boxesHeaderTitle}
                            variant="h6"
                        >
                            {title}
                        </Typography>
                        {!readOnly && hasGrouping && items.length > 0 && (
                            <Button
                                className={classes.groupingButton}
                                onClick={this.handleGrouping}
                            >
                                <SelectAllIcon />
                                {groupButtonLabel}
                            </Button>
                        )}
                    </div>

                    {items.map((arr, index) => (
                        <div key={index}>
                            {arr && arr.length == 1 && (
                                <div className={classes.buttonWrapper}>
                                    {grouping && (
                                        <Checkbox
                                            color="primary"
                                            checked={arr.selected}
                                            onChange={this.changeBoxSelection(index, 0)}
                                            value={arr.selected ? arr.selected.toString() : 'false'}
                                            name="checkbox"
                                        />
                                    )}
                                    <DragItem
                                        index={index}
                                        idx={0}
                                        id={arr[0].id}
                                        readOnly={readOnly}
                                        moveItem={this.handleMoveItem}
                                        category="Box"
                                        title={arr[0].name}
                                        source="item"
                                        type="drag"
                                        onItemClicked={this.handleItemClicked}
                                        image={arr[0].image && arr[0].image.url}
                                        redirect={arr[0].redirect}
                                        description={arr[0].description}
                                        onRemove={this.onRemove}
                                    />
                                </div>
                            )}
                            {arr && arr.length > 1 && (
                                <DragItem
                                    index={index}
                                    idx={0}
                                    id={arr[0].id}
                                    readOnly={readOnly}
                                    moveItem={this.handleMoveItem}
                                    source="item"
                                    type="container"
                                    onItemClicked={this.handleItemClicked}
                                    redirect={arr[0].redirect}
                                    onUngroupClick={this.handleUngrouping}
                                    ungroup={ungroup}
                                    image={arr[0].image && arr[0].image.url}
                                    description={arr[0].description}
                                    onRemove={this.onRemove}
                                >
                                    {arr.map((subArr, idx) => (
                                        <div
                                            key={subArr.id}
                                            className={classes.buttonWrapper}
                                        >
                                            {ungroup != null &&
                                                ungroup == index && (
                                                    <div>
                                                        <Checkbox
                                                            color="primary"
                                                            checked={subArr.selected}
                                                            onChange={this.changeBoxSelection(index, idx)}
                                                            value={arr.selected ? arr.selected.toString() : 'false'}
                                                            name="checkbox"
                                                        />
                                                    </div>
                                                )}
                                            <DragItem
                                                index={index}
                                                idx={idx}
                                                id={subArr.id}
                                                readOnly={readOnly}
                                                moveItem={this.handleMoveItem}
                                                category="Box"
                                                title={subArr.name}
                                                source="subitem"
                                                onItemClicked={
                                                    this.handleItemClicked
                                                }
                                                type="drag"
                                                image={
                                                    subArr.image &&
                                                    subArr.image.url
                                                }
                                                description={subArr.description}
                                                onRemove={this.onRemove}
                                            />
                                        </div>
                                    ))}
                                </DragItem>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default compose(
    getDndContext(),
    withTranslation(),
    DropTarget('subitem', itemSpecs, collectDrop),
    withStyles(styles)
)(DragAndDrop);
