import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import { useStyles } from '../styles/index';
import renderHTML from 'react-render-html';
import { ButtonGroup } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const OpenQuestionTextArea = (props) => {
	const { onClick, feedback, onChangeAnswerClick, buttonDisabled } = props;
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div>
			<FormControl component="fieldset" className={classes.formControl}>
				<FormGroup className={classes.feedbackText}>
					<Typography component="div" variant="body1" classes={{ body1: classes.body }} className={classes.contentColor}>
						{renderHTML(feedback || '')}
					</Typography>
				</FormGroup>
			</FormControl>

			<FormControl component="fieldset" className={classes.formControl}>
				<ButtonGroup fullWidth className={classes.buttonGroup}>
					<Button disabled={buttonDisabled} onClick={onClick} variant="contained" color="primary" fullWidth>
						{t('common.ok')}
					</Button>
					<Button variant="contained" disabled={buttonDisabled} color="primary" fullWidth onClick={onChangeAnswerClick}>
						{t('common.adjust')}
					</Button>
				</ButtonGroup>
			</FormControl>
		</div>
	);
};

export default OpenQuestionTextArea;
