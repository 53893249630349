import React from "react";
import { useStyles } from "./styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";

const MenuItemContent = React.forwardRef((props, ref) => {
	const classes = useStyles();
	const { label, main } = props;

	return (
		<div className={classes.title}>
			<Typography
				variant="body1"
				className={classNames(classes.listItemText, {
					[classes.main]: main,
				})}
			>
				{label}
			</Typography>
		</div>
	);
});

export default MenuItemContent;
