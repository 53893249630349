import logo from "../assets/logo.svg";

export default async () =>
	await new Promise(async (resolve) => {
		const config = {
			whiteLabel: "BeautyLevel",
			defaultLang: "nl_NL",
			files: [
				{
					id: "testfile",
					file: logo,
				},
			],
			pages: {
				appLogin: {
					showHeaderTitle: false,
					headerTitleIsHtml: false,
					showFootNote: false,
					showLogo: true,
					visualTitle: true,
				},
				appRegistration: {
					showBirthDate: true,
					showPhoneNumber: false,
					showRegisterType: false,
					showRegisterCode: false,
					registerCodeRequired: false,
					addMoreLicenses: true,
				},
				appNoLicense: {
					showFootNote: false,
				},
				appAccount: {
					showEducation: true,
					hideOrganisation: true,
					hideFunction: true,
				},
				box: {
					scrollTo: false,
				},
				boxCard: {
					showDescription: true,
				},
				dashboard: {
					scrollTo: true,
				},
				appElementOverlay: {
					showHeaderTitle: true,
				},
				general: {
					showStudentRapports: true,
					rapportType: "userprogress",
				},
			},
			elementCardConfig: {
				noPercentage: true
			},
			caseConfig: {
				redoButtons: true,
			},
			assignmentConfig: {
				redoButtons: true,
			},
			general: {
				hideTimer: false,
				downloadRapportage: true,
				showTags: true,
				playButtonAsIcon: true,
				secondStatisticsBox: true,
				licenseUrl: "https://www.visavi.nl/Leermiddelen/",
				showScenesButton: true,
				hideTeacherMessage: true,
				showNoSchoolDialog: true
			},
			elements: {
				exerciseCard: true,
			},
			getFile: (id) => config.files.find((file) => file.id === id),
			getPage: (key) => config[key],
		};

		resolve(config);
	});
