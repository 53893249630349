import React from "react";
import { useStyles } from "./styles";
import ExternalLink from "./ExternalLink";


const ExternalLinkContainer = (props) => {
	const { data } = props;
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<div className={classes.wrapper}>
				<ExternalLink
					data={data}
				/>
			</div>
		</div>
	);
};

export default ExternalLinkContainer;
