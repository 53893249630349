'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styles = require('@material-ui/styles');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _ = require('../');

var _appWorkforms = require('@manakin/app-workforms');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var useStyles = (0, _styles.makeStyles)(function (theme) {
    return (0, _styles.createStyles)({
        backgroundImage: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0
        },
        overlay: {
            backgroundColor: 'rgba(0,0,0,0.3)',
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%'
        },
        relativeBackground: {
            position: 'relative'
        }
    });
}, { name: 'AppLoadImage' });

var LoadImage = function LoadImage(props) {
    var classNameProp = props.className,
        src = props.src,
        _props$placeholder = props.placeholder,
        placeholder = _props$placeholder === undefined ? false : _props$placeholder,
        _props$withOverlay = props.withOverlay,
        withOverlay = _props$withOverlay === undefined ? false : _props$withOverlay,
        _props$relative = props.relative,
        relative = _props$relative === undefined ? false : _props$relative,
        _props$loader = props.loader,
        loader = _props$loader === undefined ? false : _props$loader,
        _props$align = props.align,
        align = _props$align === undefined ? 'default' : _props$align,
        _props$objectFitConta = props.objectFitContain,
        objectFitContain = _props$objectFitConta === undefined ? false : _props$objectFitConta,
        _props$bottomCenter = props.bottomCenter,
        bottomCenter = _props$bottomCenter === undefined ? false : _props$bottomCenter,
        _props$enableFocusPoi = props.enableFocusPoint,
        enableFocusPoint = _props$enableFocusPoi === undefined ? false : _props$enableFocusPoi,
        asset = props.asset;


    var classes = useStyles();

    var className = (0, _classnames2.default)(classes.backgroundImage, _defineProperty({}, classes.relativeBackground, relative), classNameProp);

    var contain = (0, _appWorkforms.isContain)(asset && asset.fit, objectFitContain);

    return _react2.default.createElement(
        'div',
        { className: className },
        _react2.default.createElement(_.ProgressiveImage, {
            preview: placeholder,
            image: src,
            loader: loader,
            align: align,
            bottomCenter: bottomCenter,
            objectFitContain: contain,
            enableFocusPoint: enableFocusPoint,
            asset: asset
        }),
        withOverlay && _react2.default.createElement('div', { className: classes.overlay })
    );
};

exports.default = LoadImage;