import React, { useCallback } from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { GQL_ACCEPT_INVITATION } from './graphql';
import { useMutation } from '@apollo/react-hooks';
import { Loader } from '@manakin/app-core';
import { showSnackbarMessage } from '@manakin/core/actions';
import { useTranslation } from 'react-i18next';

const ConfirmInvitation = (props) => {
	const classes = useStyles();
	const params = useParams();
	const history = useHistory();
	const dispatch = useDispatch();
	const [mutate, { loading }] = useMutation(GQL_ACCEPT_INVITATION);
	const { t } = useTranslation();

	//dispatch
	const processError = useCallback(
		(message) =>
			dispatch(
				showSnackbarMessage({
					text: message ||t("app.confirm-invitation.failed"),
					variant: 'error',
				})
			),
		[dispatch]
	);
	const processSuccess = useCallback(
		(message) =>
			dispatch(
				showSnackbarMessage({
					text:
						message || t("app.confirm-invitation.success"),
					variant: 'success',
				})
			),
		[dispatch]
	);

	//functions
	const handleResult = (r) => {
		if (r.errors && r.errors.length) {
			let message = t("app.confirm-invitation.failed");
			r.errors.forEach((e) => {
				if (e.message.indexOf('invitation.already.accepted') !== -1) {
					message = t("app.confirm-invitation.failed-already-exists");
				}
			});
			processError(message);
		} else {
			processSuccess();
			history.push('/');
		}
	};

	const handleClick = () => {
		mutate({
			variables: {
				input: {
					token: params.token,
				},
			},
		})
			.then((r) => {
				handleResult(r);
			})
			.catch((e) => {
				processError();
			});
	};

	const handleCancel = () => {
		history.push('/');
	};

	if (loading) return <Loader fullScreen />;
	return (
		<div className={classes.root}>
			<div className={classes.content}>
				<Typography variant="h3">
					{t("app.confirm-invitation.dialog.title")}
				</Typography>
				<Typography>
					{t("app.confirm-invitation.dialog.description")}
				</Typography>
				<Button
					variant="contained"
					color="primary"
					className={classes.button}
					onClick={handleClick}
				>
					{t("app.confirm-invitation.dialog.action")}
				</Button>
				<Button
					variant="outlined"
					color="primary"
					className={classes.button}
					onClick={handleCancel}
				>
					{t("common.cancel")}
				</Button>
			</div>
		</div>
	);
};

export default ConfirmInvitation;
