import React from 'react';
import { compose } from 'recompose';
import { withStyles, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { getAppUser } from '@manakin/authentication/selectors';
import { BestTimes, ElementOverlay } from '@manakin/app-core';
import { setBackButton } from '@manakin/app-core/AppBar/actions';
import { useTranslation } from 'react-i18next';
import { SettingsKeys } from '@manakin/core/lib/constants';

const styles = (theme) => ({
    root: {
        backgroundColor: theme.manakin.defaultElementBGColor[500],
        padding: '10rem 0',
        [theme.breakpoints.up('lg')]: {
            padding: '16rem 0 0',
        },
    },
    points: {
        position: 'absolute',
        bottom: '2rem',
        left: '2rem',
        [theme.breakpoints.up('lg')]: {
            bottom: '10rem',
            left: 'auto',
            right: '10rem',
        },
    },
    preTitle: {
        color: theme.manakin.defaultElementBGColor.contrastText,
        fontWeight: 'bold',
        [theme.breakpoints.up('md')]: {
            textAlign: 'center',
        },
    },
    title: {
        color: theme.manakin.defaultElementBGColor.contrastText,
        [theme.breakpoints.up('md')]: {
            textAlign: 'center',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '14rem',
            lineHeight: '24rem',
        },
    },
    body: {
        color: theme.manakin.defaultElementBGColor.contrastText,
        [theme.breakpoints.up('md')]: {
            textAlign: 'center',
            maxWidth: '55rem',
            margin: '0 auto 3rem',
        },
        [theme.breakpoints.up('lg')]: {
            margin: '0 auto 7rem',
        },
    },
    wrapper: {
        ...theme.manakin.largeWrapper,
    },
});

const ResultsPage = (props) => {
    const {
        points,
        classes,
        controls = true,
        elementId,
        settings = [],
    } = props;
    const { t } = useTranslation();

    const handleBackButtonClick = () => {
        if (props.onBackButtonClick) props.onBackButtonClick();
    };

    let prefix = t("app.elements.betting-game.title");

    settings.forEach((i) => {
        if (i.name === SettingsKeys.BETTINGGAME_PREFIX && i.value) prefix = i.value;
    });

    return (
        <div className={classes.root}>
            <ElementOverlay
                fullWidth={true}
                controls={controls}
                background="TRANSPARENT"
                backButton={points ? false : true}
                onBackButtonClick={handleBackButtonClick}
            >
                <div className={classes.wrapper}>
                    <Typography variant="body2" className={classes.preTitle}>
                        {prefix}
                    </Typography>
                    <Typography variant="h1" className={classes.title}>
                        {t("app.elements.betting-game.results-page.title")}
                    </Typography>
                    <Typography variant="body1" className={classes.body}>
                        {t("app.elements.betting-game.results-page.intro")}
                    </Typography>
                    <BestTimes
                        elementId={elementId}
                        variant="points"
                        max="6"
                        noTitle={true}
                        noPadding={true}
                    />
                </div>
            </ElementOverlay>
        </div>
    );
};

export default compose(
    withStyles(styles, { name: 'AppBettingGameResultsPage' }),
    connect(
        (state) => ({
            appUser: getAppUser(state),
        }),
        (dispatch) => ({
            setBackButton: (data) => dispatch(setBackButton(data)),
        })
    )
)(ResultsPage);
