"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.GQL_FETCH_INVITATION_STUDENTS = exports.GQL_CREATE_APPOINTMENT = exports.GQL_FETCH_APPOINTMENT_SUBJECTS = undefined;

var _templateObject = _taggedTemplateLiteral(["\n\tquery appointmentSubjects {\n\t\tappointmentSubjects {\n\t\t\tdescription\n\t\t\tid\n\t\t\ttitle\n\t\t}\n\t}\n"], ["\n\tquery appointmentSubjects {\n\t\tappointmentSubjects {\n\t\t\tdescription\n\t\t\tid\n\t\t\ttitle\n\t\t}\n\t}\n"]),
    _templateObject2 = _taggedTemplateLiteral(["\n\tmutation($input: CreateAppointmentInput!) {\n\t\tcreateAppointment(input: $input) {\n\t\t\tappointment {\n\t\t\t\tstudent {\n\t\t\t\t\tid\n\t\t\t\t}\n\t\t\t\tsubject {\n\t\t\t\t\tid\n\t\t\t\t}\n\t\t\t\ttime\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tmutation($input: CreateAppointmentInput!) {\n\t\tcreateAppointment(input: $input) {\n\t\t\tappointment {\n\t\t\t\tstudent {\n\t\t\t\t\tid\n\t\t\t\t}\n\t\t\t\tsubject {\n\t\t\t\t\tid\n\t\t\t\t}\n\t\t\t\ttime\n\t\t\t}\n\t\t}\n\t}\n"]),
    _templateObject3 = _taggedTemplateLiteral(["\n\tquery invitationStudents {\n\t\tinvitationStudents {\n\t\t\tid\n\t\t\tfullName\n\t\t\temail\n\t\t}\n\t}\n"], ["\n\tquery invitationStudents {\n\t\tinvitationStudents {\n\t\t\tid\n\t\t\tfullName\n\t\t\temail\n\t\t}\n\t}\n"]);

var _graphqlTag = require("graphql-tag");

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_APPOINTMENT_SUBJECTS = exports.GQL_FETCH_APPOINTMENT_SUBJECTS = (0, _graphqlTag2.default)(_templateObject);

var GQL_CREATE_APPOINTMENT = exports.GQL_CREATE_APPOINTMENT = (0, _graphqlTag2.default)(_templateObject2);

var GQL_FETCH_INVITATION_STUDENTS = exports.GQL_FETCH_INVITATION_STUDENTS = (0, _graphqlTag2.default)(_templateObject3);