import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GQL_MUTATE_SUBMIT_POLL_QUESTION_ANSWER, GQL_FETCH_POLL_QUESTION_RESULT } from './graphql';
import { getBoxId } from '@manakin/app-core/Boxes/selectors';
import { getProgram } from '@manakin/app-core/ProgramsDropdown/selectors';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useSnackBar } from "@manakin/hooks";
import { useTranslation } from 'react-i18next';
import PollQuestion from './PollQuestion';

const PollQuestionContainer = (props) => {
	const { data } = props;
	const { t } = useTranslation();
	const snackBar = useSnackBar();

	const [value, setValue] = useState('');
	const [showPollResults, setShowPollResults] = useState(false);
	const [pollResults, setPollResults] = useState(null);
	const [answers] = useState([...data.answers]);

	const params = useParams();

	const programId = useSelector((state) => getProgram(state));
	const boxId = useSelector((state) => getBoxId(state));

	const [submitPollAnswer] = useMutation(GQL_MUTATE_SUBMIT_POLL_QUESTION_ANSWER);

	const { loading, data: pollData = {} } = useQuery(GQL_FETCH_POLL_QUESTION_RESULT, {
		variables: {
			program: programId,
			box: boxId,
			element: params.elementId,
			pollQuestion: data.id,
		},
	});

	useEffect(() => {
		if (!loading && pollData && pollData.pollQuestionResult && pollData.pollQuestionResult.selectedAnswer) {
			setPollResults(pollData.pollQuestionResult);
			setShowPollResults(true);
		}
	}, [loading, pollData]);

	const handleChange = (event) => {
		setValue(event.target.value);

		submitPollAnswer({
			variables: {
				program: programId,
				box: boxId,
				element: params.elementId,
				pollQuestion: data.id,
				answer: event.target.value,
			},
		}).then(
			(response) => {
				if (response.data && response.data.submitPollQuestionAnswer) {
					setPollResults(response.data.submitPollQuestionAnswer);
					setShowPollResults(true);
				}
			},
			() => {
				snackBar.error(t('app.workforms.poll-question.save-failed'));
			}
		);
	};

	return (
		<PollQuestion
			value={value}
			loading={loading}
			data={props.data}
			pollResults={pollResults}
			answers={answers}
			showPollResults={showPollResults}
			onChange={handleChange}
		/>
	);
};

export default PollQuestionContainer;
