'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.getCompletedScene = exports.getCompletedWorkform = exports.APP_WORKFORMS_STATE_ROOT = undefined;

var _reselect = require('reselect');

var _selectors = require('../selectors');

var APP_WORKFORMS_STATE_ROOT = exports.APP_WORKFORMS_STATE_ROOT = 'workformsViewer';

var getState = (0, _reselect.createSelector)([_selectors.getCoreState], function (coreState) {
    return coreState[APP_WORKFORMS_STATE_ROOT];
});

var getCompletedWorkform = exports.getCompletedWorkform = (0, _reselect.createSelector)([getState], function (state) {
    return state.data;
});

var getCompletedScene = exports.getCompletedScene = (0, _reselect.createSelector)([getState], function (state) {
    return state.scene;
});