import React from 'react';
import classNames from 'classnames';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { compose } from 'recompose';
import { withApollo } from 'react-apollo';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { withTranslation } from "react-i18next";

const styles = (theme) => ({
    root: {
        flex: '1 0 auto',
        transition: 'background-color 200ms linear',
        backgroundColor: 'transparent',
        '& *': { opacity: 1, pointerEvents: 'auto' },
        margin: theme.manakin.normalMargin,
    },
    loading: {
        height: theme.spacing(6),
        backgroundColor: theme.palette.grey[100],
        '& *': { opacity: 0, pointerEvents: 'none' },
    },
    input: {
        display: 'flex',
        height: 'auto',
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
    },
    chip: {
        margin: theme.spacing(0.25),
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light'
                ? theme.palette.grey[300]
                : theme.palette.grey[700],
            0.08
        ),
    },
    noOptionsMessage: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 16,
        color: 'rgba(0, 0, 0, 0.87)',
        fontStyle: 'italic',
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
        marginBottom: theme.spacing(2),
    },
    divider: {
        height: theme.spacing(2),
    },
    disabledLabel: {
        color: theme.manakin.disabledFieldLabelColor,
    },
    disabledInput: {
        color: theme.manakin.disabledFieldTextColor,
    },
});
function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            Geen opties
        </Typography>
    );
}

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

function Control(props) {
    const classes = props.selectProps.classes;

    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: classNames(classes.input, {
                        [classes.disabledInput]: props.isDisabled,
                    }),
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
            {...props.selectProps.textFieldProps}
            disabled={props.isDisabled}
        />
    );
}

function Option(props) {
    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            onMouseDown={props.innerProps.onClick}
            {...props.innerProps}
            onClick={null}
        >
            {props.children}
        </MenuItem>
    );
}

function Placeholder(props) {
    return (
        <Typography
            color="textSecondary"
            className={classNames(props.selectProps.classes.placeholder, {
                [props.selectProps.classes.disabledInput]: props.isDisabled,
            })}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function SingleValue(props) {
    return (
        <Typography
            className={props.selectProps.classes.singleValue}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function ValueContainer(props) {
    return (
        <div className={props.selectProps.classes.valueContainer}>
            {props.children}
        </div>
    );
}

function MultiValue(props) {
    return (
        <Chip
            tabIndex={-1}
            label={props.children}
            className={classNames(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
            })}
            onDelete={props.isDisabled ? null : props.removeProps.onClick}
            deleteIcon={<CancelIcon {...props.removeProps} />}
        />
    );
}

function Menu(props) {
    return (
        <Paper
            square
            className={props.selectProps.classes.paper}
            {...props.innerProps}
        >
            {props.children}
        </Paper>
    );
}

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    ValueContainer,
};
class MultiSelect extends React.Component {
    state = {
        value: [],
    };

    componentDidMount(prevProps) {
        if (!this.props.loading) {
            const { form, name, initialValue, options = [] } = this.props;
            const _initialValue = initialValue
                ? initialValue.map((item) => {
                      let value =
                          item.name ||
                          item.title ||
                          item.fullName ||
                          item.label;
                      let obj = {
                          label: value,
                          value: item.id,
                      };

                      if (!value) {
                          options.forEach((option) => {
                              if (option.id === item) {
                                  obj = {
                                      label:
                                          option.name ||
                                          option.title ||
                                          option.fullName ||
                                          option.label ||
                                          '',
                                      value: item,
                                  };
                              }
                          });
                      }

                      return obj;
                  })
                : [];

            const ids = _initialValue.map((item) => {
                return item.value;
            });

            this.setState({ value: _initialValue });
            form.onFieldChange({ key: name, value: ids });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loading && !this.props.loading) {
            const { form, name, initialValue, options = [] } = this.props;
            const _initialValue = initialValue
                ? initialValue.map((item) => {
                      let value =
                          item.name ||
                          item.title ||
                          item.fullName ||
                          item.label;
                      let obj = {
                          label: value,
                          value: item.id,
                      };

                      if (!value) {
                          options.forEach((option) => {
                              if (option.id === item) {
                                  obj = {
                                      label:
                                          option.name ||
                                          option.title ||
                                          option.fullName ||
                                          option.label ||
                                          '',
                                      value: item,
                                  };
                              }
                          });
                      }

                      return obj;
                  })
                : [];

            const ids = _initialValue.map((item) => {
                return item.value;
            });

            this.setState({ value: _initialValue });
            form.onFieldChange({ key: name, value: ids });
        } else if (prevProps.valueOverride !== this.props.valueOverride) {
            if (this.state.value !== this.props.valueOverride) {
                this.handleChange(this.props.valueOverride);
            }
        }
    }

    promiseOptions = (inputValue) => {
        const { query, queryName, queryType, filter, t } = this.props;

        if (query) {
            let variables = {
                page: 0,
                pagesize: 99,
                search: inputValue || '',
            };

            if (filter) {
                variables.filter = { ...filter };
            }

            return this.props.client
                .query({
                    query: query,
                    variables: { ...variables },
                })
                .then((result) => {
                    return result.data[queryName][queryType || queryName].map(
                        (r) => ({
                            value: r.id,
                            label:
                                r.name || r.fullName || r.title || t("common.fields.name.no-value"),
                        })
                    );
                });
        }
    };

    handleChange = (value) => {
        const { form, name, returnWithLabel } = this.props;

        let ids = [];
        if (returnWithLabel) {
            ids = value ? value.map((item) => item) : [];
        } else {
            ids = value
                ? value.map((item) => {
                      return item.value;
                  })
                : [];
        }

        form.onFieldChange({
            key: name,
            value: ids,
        });

        this.setState({ value: value }, () => {
            if (this.props.onChange) this.props.onChange(value);
        });
    };

    render() {
        const {
            classes,
            form,
            options = [],
            loading,
            name,
            readOnly = false,
            label,
            placeholder,
            isAsync = false,
            closeMenuOnSelect = false,
            style,
            t
        } = this.props;
        const { errors, schema } = form;
        const className = classNames(classes.root, {
            [classes.loading]: loading,
        });

        const _placeholder = placeholder
            ? placeholder
            : t("common.select");

        const _options = options.map((item) => {
            let label = item.name || item.title || item.fullName || item.label;
            return {
                label: label,
                value: item.id,
            };
        });

        const { value } = this.state;

        return (
            <div className={className} style={style}>
                {!isAsync && (
                    <Select
                        classes={classes}
                        isMulti
                        required={(schema[name] || {}).required}
                        defaultOptions
                        isDisabled={readOnly}
                        closeMenuOnSelect={closeMenuOnSelect}
                        placeholder={_placeholder}
                        textFieldProps={{
                            ...(errors[name] &&
                                errors[name].length > 0 && {
                                    error: errors[name][0],
                                }),
                            required: (schema[name] || {}).required,
                            label: label || '',
                            InputLabelProps: {
                                shrink: true,
                                ...(readOnly && {
                                    classes: {
                                        root: classes.disabledLabel,
                                    },
                                }),
                            },
                            readOnly: readOnly,
                        }}
                        options={_options}
                        onChange={this.handleChange}
                        components={components}
                        value={value}
                        menuPortalTarget={document.body}
                        styles={{
                            menuPortal: (base) => ({
                                ...base,
                                zIndex: '1301 !important',
                            }),
                        }}
                    />
                )}
                {isAsync && (
                    <AsyncSelect
                        classes={classes}
                        closeMenuOnSelect={closeMenuOnSelect}
                        isMulti
                        required={(schema[name] || {}).required}
                        defaultOptions
                        isDisabled={readOnly}
                        placeholder={_placeholder}
                        textFieldProps={{
                            ...(errors[name] &&
                                errors[name].length > 0 && {
                                    error: errors[name][0],
                                }),
                            required: (schema[name] || {}).required,
                            label: label || '',
                            InputLabelProps: {
                                shrink: true,
                                ...(readOnly && {
                                    classes: {
                                        root: classes.disabledLabel,
                                    },
                                }),
                            },
                            readOnly: readOnly,
                        }}
                        loadOptions={this.promiseOptions}
                        onChange={this.handleChange}
                        components={components}
                        value={value}
                        menuPortalTarget={document.body}
                        styles={{
                            menuPortal: (base) => ({
                                ...base,
                                zIndex: '1301 !important',
                            }),
                        }}
                    />
                )}
            </div>
        );
    }
}

export default compose(
    withStyles(styles, { withTheme: true }),
    withTranslation(),
    withApollo
)(MultiSelect);
