'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.GQL_FETCH_SETTINGS = undefined;

var _templateObject = _taggedTemplateLiteral(['\n    query settings {\n        settings {\n            settings {\n                name\n                value\n                file {\n                    ', '\n                }\n            }\n        }\n    }\n'], ['\n    query settings {\n        settings {\n            settings {\n                name\n                value\n                file {\n                    ', '\n                }\n            }\n        }\n    }\n']);

var _graphqlTag = require('graphql-tag');

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

var _graphQlHelpers = require('@manakin/app-core/graphQlHelpers');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_SETTINGS = exports.GQL_FETCH_SETTINGS = (0, _graphqlTag2.default)(_templateObject, _graphQlHelpers.fileAsset);