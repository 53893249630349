import React, { useState, useEffect } from 'react';
import { FrontPage, Questions, ResultsPage } from './pages';
import { compose } from 'recompose';
import PointsPopup from './PointsPopup';
import RowBonusPopup from './RowBonusPopup';
import TimeBonusPopup from './TimeBonusPopup';
import { withStepper, Loader } from '@manakin/app-core';
import correctSound from './sounds/correct-choice.wav';
import wrongSound from './sounds/wrong-choice.wav';

const timer = {
    timer: null,
};

const BettingGameView = (props) => {
    const {
        workformsData = {},
        bettingGame,
        elementId,
        stepper,
        controls = true,
        settings = [],
    } = props;
    const { loading: workformsLoading } = workformsData;

    const popupTimeout = bettingGame.popupTimeout
        ? bettingGame.popupTimeout
        : 1500;

    //state hooks
    const [idx, setIdx] = useState(0);
    const [points, setPoints] = useState(0);
    const [bonusPoints, setBonusPoints] = useState(0);
    const [openPopup, setOpenPopup] = useState({ open: false, correct: null });
    const [openRowPopup, setOpenRowPopup] = useState(false);
    const [openTimePopup, setOpenTimePopup] = useState(false);
    const [currentBet, setCurrentBet] = useState(0);
    const [corrects, setCorrects] = useState(0);
    const [hadBonus, setHadBonus] = useState(false);

    //effect hooks
    useEffect(() => {
        if (!workformsLoading) {
            stepper.setOptions({ isTrainer: false });
            stepper.setItems(workformsData.workforms || []);
        }
    }, [workformsLoading]);

    //function
    const handleStartGame = (_points) => {
        setPoints(_points);
        setOpenPopup({ ...openPopup, open: true });
    };

    const handleStart = () => {
        setIdx(1);
    };

    const handleClosePopup = () => {
        setOpenTimePopup(false);
    };

    const handleRealClosePopup = () => {
        setOpenPopup({ ...openPopup, open: false });
    };

    const handleRowBonusClose = () => {
        setOpenRowPopup(false);
        handleNextQuestion(true);
    };

    const handleNextQuestion = (correct) => {
        if (stepper.step + 1 === stepper.items.length) {
            playCorrectSound();
            handleFinish(correct);
        } else {
            if (corrects == 2 && correct) {
                handlePoints(correct);
                setTimeout(() => {
                    setOpenRowPopup(true);
                }, popupTimeout);
            } else {
                handlePoints(correct);
                setTimeout(() => {
                    setOpenPopup({ correct, open: true });
                }, popupTimeout);
            }
        }
    };

    const handleChange = (value) => {
        setCurrentBet(value);
    };

    const handleFinish = (correct) => {
        stepper.stopCounting();
        const { count } = stepper;
        let bonusPoints = 1500 - 5 * count;

        bonusPoints = bonusPoints < 0 ? 0 : bonusPoints;

        const tempPoints = correct ? points + currentBet : points - currentBet;
        const newPoints = tempPoints + bonusPoints;

        setBonusPoints(bonusPoints);
        setPoints(newPoints);
        setTimeout(() => {
            setOpenTimePopup(true);
            if (props.saveData && workformsData.saveElement) {
                workformsData
                    .saveElement({
                        count: newPoints,
                        elementId: elementId,
                    })
                    .then((r) => {
                        setIdx(2);
                    })
                    .catch((e) => {
                        console.log('error', e);
                    });
            } else {
                setIdx(2);
            }
        }, popupTimeout);
    };

    const playCorrectSound = () => {
        const correctAudio = new Audio(correctSound);
        correctAudio.play();
    };

    const playWrongSound = () => {
        const wrongAudio = new Audio(wrongSound);
        wrongAudio.play();
    };

    const handlePoints = (correct) => {
        let newPoints = correct ? points + currentBet : points - currentBet;
        if (correct) {
            playCorrectSound();
            const newCorrects = corrects + 1;
            setCorrects(newCorrects);
            if (newCorrects === 3 && !hadBonus) {
                newPoints = points + 50;
                setHadBonus(true);
            }
        } else {
            playWrongSound();
            setCorrects(0);
        }

        setPoints(newPoints);
    };

    const handleBackButtonClick = () => {
        setIdx(0);
    };

    if (workformsLoading) {
        return (
            <div>
                <Loader fullScreen={true} />
            </div>
        );
    }
    return (
        <div>
            <TimeBonusPopup
                open={openTimePopup}
                points={bonusPoints}
                onClose={handleClosePopup}
            />
            <RowBonusPopup
                open={openRowPopup}
                onClose={handleClosePopup}
                onRealClose={handleRowBonusClose}
            />
            <PointsPopup
                open={openPopup.open}
                correct={openPopup.correct}
                onClose={handleClosePopup}
                onRealClose={handleRealClosePopup}
                onStart={handleStart}
                stepper={stepper}
                onChange={handleChange}
                points={points}
                onFinish={handleFinish}
            />
            {idx === 0 && (
                <FrontPage
                    onClick={handleStartGame}
                    onHighscoreClick={() => setIdx(2)}
                    data={bettingGame}
                    controls={controls}
                    settings={settings}
                />
            )}
            {idx === 1 && (
                <Questions
                    points={points}
                    data={workformsData}
                    stepper={stepper}
                    onNext={handleNextQuestion}
                    controls={controls}
                    settings={settings}
                />
            )}
            {idx === 2 && (
                <ResultsPage
                    controls={controls}
                    points={points}
                    elementId={elementId}
                    onBackButtonClick={handleBackButtonClick}
                    settings={settings}
                />
            )}
        </div>
    );
};

export default compose(withStepper(timer))(BettingGameView);
