import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import { useStyles } from '../styles/index';
import { useTranslation } from 'react-i18next';

const OpenQuestionTextArea = (props) => {
	const { storedAnswer, onTextAreaChange, onCheckAnswerClick } = props;
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div>
			<FormControl component="fieldset" className={classes.formControl}>
				<InputLabel placeholder={t('common.type-answer')}>{t('common.type-answer')}</InputLabel>
				<Input onChange={onTextAreaChange} multiline={true} value={storedAnswer} />
			</FormControl>

			<FormControl component="fieldset" className={classes.formControl}>
				<Button variant="contained" color="primary" fullWidth onClick={onCheckAnswerClick} disabled={storedAnswer ? false : true}>
					{t('common.check-answer')}
				</Button>
			</FormControl>
		</div>
	);
};

export default OpenQuestionTextArea;
