import React from 'react';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const EventState = {
	PLANNED: 'PLANNED',
	CANCELLED: 'CANCELLED',
};

const PlannerPopupButtons = (props) => {
	const { event = {}, enrollments, onEnrollment } = props;
	const { state = '', date } = event || {};
	const { t } = useTranslation();

	if (enrollments && enrollments.length) {
		if (date && moment().isAfter(date) && state === EventState.PLANNED)
			return (
				<React.Fragment>
					<a href={`https://whereby.com/${event.onlineLocation || ''}`} target="_blank">
						<Button color="primary" variant="contained">
							{t('app.dashboard.alt-content.planner.event.go-to-event')}
						</Button>
					</a>
				</React.Fragment>
			);
		if (state === EventState.CANCELLED) return <div>{t('app.dashboard.alt-content.planner.event.event-cancelled')}</div>;
		return <div>{t('app.dashboard.alt-content.planner.event.signed-up-for-event')}</div>;
	} else {
		if (date && moment().isAfter(date)) {
			return <div>{t('app.dashboard.alt-content.planner.event.registrations-closed')}</div>;
		}
		if (state === EventState.CANCELLED) return <div>{t('app.dashboard.alt-content.planner.event.event-cancelled')}</div>;
		return (
			<Button color="primary" variant="contained" onClick={onEnrollment}>
				{t('app.dashboard.alt-content.planner.event.sign-up')}
			</Button>
		);
	}
};

export default PlannerPopupButtons;
