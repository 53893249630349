import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AuthForm from '../forms/AuthForm';
import Introduction from './parts/Introduction';
import useStyles from './styles';
import { FormKeys } from '@manakin/core';

const AuthData = (props) => {
	const { form } = props;
	const classes = useStyles();
	const { t } = useTranslation();

	useEffect(() => {
		form.onRequiredChange(FormKeys.email, true);
		form.onRequiredChange(FormKeys.password, true);
		form.onAddField({
			secondEmail: {
				required: true,
				couple: FormKeys.email,
				coupleLabel: 'e-mailadres',
				format: FormKeys.email,
			},
			secondPassword: {
				required: true,
				couple: FormKeys.password,
				coupleLabel: 'wachtwoord',
				format: FormKeys.password,
			},
		});
		return () => {
			form.onRequiredChange(FormKeys.email, false);
			form.onRequiredChange(FormKeys.acceptTerms, false);
			form.onRequiredChange(FormKeys.password, false);
			form.onAddField({
				secondEmail: {
					required: false,
				},
				secondPassword: {
					required: false,
				},
			});
		};
	}, []);

	return (
		<React.Fragment>
			<Introduction
				title={`
                    ${t('authentication.registration.email-and-password')}
                `}
			/>
			<div className={classes.content}>
				<div className={classes.defaultWrapper}>
					<div className={classes.content}>
						<AuthForm form={form} />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default AuthData;
