import React, { useCallback } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import { GQL_DELETE_APPOINTMENT } from "../graphql";
import { useMutation } from "react-apollo";
import { showSnackbarMessage } from "@manakin/core/actions";
import { useTranslation } from 'react-i18next';

const DeleteDialog = (props) => {
	const { open, onClose, data } = props;
	const { t } = useTranslation();

	//mutations
	const [mutate] = useMutation(GQL_DELETE_APPOINTMENT);

	//dispatches
	const dispatch = useDispatch();
	const onError = useCallback(
		(data) =>
			dispatch(
				showSnackbarMessage({
					text: data || t("common.delete-failed"),
					variant: "error",
				})
			),
		[dispatch]
	);
	const onSuccess = useCallback(
		(data) =>
			dispatch(
				showSnackbarMessage({
					text: data || t("common.delete-success"),
					variant: "success",
				})
			),
		[dispatch]
	);

	//functoins

	const handleClick = () => {
		mutate({
			variables: {
				id: data,
			},
		})
			.then((r) => {
				onClose();
				if (!r.errors) {
					onSuccess();
				} else {
					onError();
				}
			})
			.catch((e) => {
				onError();
			});
	};

	return (
		<Dialog onClose={onClose} open={open}>
			<DialogTitle>
				{t("app.planner.delete-dialog.title")}
			</DialogTitle>
			<Button onClick={handleClick} variant="contained" color="primary">
				{t("common.delete")}
			</Button>
			<Button color="primary" onClick={onClose}>
				{t("common.cancel")}
			</Button>
		</Dialog>
	);
};

export default DeleteDialog;
