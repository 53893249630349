'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = exports.styles = function styles(theme) {
    return {
        root: {
            width: '100%',
            padding: '8rem 0 11rem',
            '& $body': _defineProperty({
                margin: 0,
                fontSize: '1.4rem'
            }, theme.breakpoints.up('md'), {
                fontSize: '1.8rem'
            }),
            '& $heading': {
                textAlign: 'center',
                marginBottom: '5rem!important',
                '&:after': {
                    background: theme.manakin.defaultContentColor[500]
                }
            }
        },
        noPadding: {
            padding: 0
        },
        body: {},
        heading: {},
        user: _defineProperty({
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            padding: '1rem',
            backgroundColor: theme.manakin.primaryColor[50],
            marginBottom: '.1rem'
        }, theme.breakpoints.up('md'), {
            padding: '1.6rem 4rem 1.6rem 1.6rem'
        }),
        currentUser: {
            backgroundColor: theme.manakin.primaryColor[500],
            '& $body': {
                color: theme.palette.getContrastText(theme.manakin.primaryColor[500])
            },
            '& $time': {
                color: theme.palette.getContrastText(theme.manakin.primaryColor[500])
            }
        },
        image: _defineProperty({
            width: '7rem',
            height: '7rem',
            position: 'relative',
            borderRadius: '100%',
            marginRight: '3rem',
            overflow: 'hidden',
            display: 'none'
        }, theme.breakpoints.up('md'), {
            display: 'block'
        }),
        wrapper: _extends({}, theme.manakin.smallWrapper),
        name: {
            flexGrow: '2',
            color: theme.palette.getContrastText(theme.manakin.primaryColor[500])
        },
        time: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.getContrastText(theme.manakin.primaryColor[500])
        },
        clockIcon: {
            fontFamily: theme.manakin.defaultContentFont,
            width: '2rem',
            height: '2rem',
            marginRight: '1rem'
        }
    };
};