import React from 'react';
import Hotspot from './Hotspot';

class HotspotContainer extends React.PureComponent {
	state = {
		buttonDisabled: false,
	};

	componentDidMount() {
		this.setStartState();
	}

	componentDidUpdate(prevProps) {
		if (
			(!prevProps.data.done && this.props.data.done) ||
			prevProps.data.id != this.props.data.id
		) {
			this.setStartState();
			this.setState({ buttonDisabled: false });
		}
	}

	setStartState = () => {
		const { answers = [] } = this.props.data;
		const { options = {} } = this.props;

		const _answers =
			answers &&
			answers.map((answer) => ({
				...answer,
				selected:
					answer.selected == undefined ? false : answer.selected,
			}));

		let value = null;
		_answers.forEach((answer) => {
			if (answer.selected) value = answer.id;
		});

		this.setState(
			{
				...this.props.data,
				options: { ...options },
				initialValue: value,
				answers: _answers,
			},
			() => {
				const correct = this.checkAnswer(value);
				this.setState({
					correct: correct,
					feedback:
						value && options && options.showFeedback ? true : false,
					done: value ? true : false,
				});
			}
		);
	};

	checkAnswer = (value) => {
		let correct = false;
		const { answers = [] } = this.state;

		answers.forEach((answer) => {
			if (answer.correct && answer.id == value) correct = true;
		});

		return correct;
	};

	handleChange = (event) => {
		const correct = this.checkAnswer(event.target.value);
		const _answers = [...this.state.answers];
		const val = event.target.value;

		const answers = _answers.map((answer) => {
			let selected = false;
			if (answer.id == val) selected = true;
			return {
				...answer,
				selected: selected,
			};
		});

		this.setState({
			value: event.target.value,
			correct: correct,
			answers: answers,
		});
	};

	handleSubmit = () => {
		const { options = {} } = this.props;

		if (options && options.showFeedback) {
			this.setState({ feedback: true, options: options });
		} else {
			this.setState({ buttonDisabled: true });
			this.handleFinish();
		}
	};

	handleFinish = () => {
		this.props.onFinished({
			correct: this.state.correct,
			workform: { ...this.state },
		});
	};

	render() {
		return (
			<Hotspot
				data={{ ...this.state }}
				onChange={this.handleChange}
				onFinished={this.handleFinish}
				onSubmit={this.handleSubmit}
				disabled={this.state.buttonDisabled}
			/>
		);
	}
}

export default HotspotContainer;
