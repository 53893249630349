import React from "react";
import MuiDialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { TextField, withForm } from "@manakin/core";
import { compose } from "recompose";
import Button from "@material-ui/core/Button";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) =>
    createStyles({
        deleteButton: {
            color: "red",
            marginTop: "5rem",
        },
        dialogTitle: {
            display: "flex",
            justifyContent: "space-between",
        },
    }),
);

const MilestoneDialog = (props) => {
    const { form, mileStone, mileStones, open, onSave, onClose } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    const handleSubmit = () => {
        onSave({ id: mileStone, text: form.fields.text });
        onClose();
    };

    const handleDelete = () => {
        onSave({ id: mileStone, text: "" });
        onClose();
    };

    return (
        <MuiDialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>
                <div className={classes.dialogTitle}>
                    <span>{t("app.teacher-program.milestone-dialog.title")}</span>
                    <IconButton onClick={handleDelete}>
                        <DeleteIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <TextField
                    name='text'
                    autoFocus={true}
                    label={t("app.teacher-program.milestone-dialog.fields.period.label")}
                    placeholder={t("app.teacher-program.milestone-dialog.fields.period.placeholder")}
                    form={form}
                    initialValue={(mileStones[mileStone] && mileStones[mileStone].text) || ""}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleSubmit}
                    color='primary'
                    variant='contained'
                >
                    {t("common.create")}
                </Button>
                <Button onClick={onClose} color='primary'>
                    {t("common.cancel")}
                </Button>
            </DialogActions>
        </MuiDialog>
    );
};

export default compose(withForm({}))(MilestoneDialog);
