"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _core = require("@material-ui/core");

var _core2 = require("@manakin/core");

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var useStyles = (0, _core.makeStyles)(function (theme) {
	return (0, _core.createStyles)({
		root: _defineProperty({
			borderTop: "1px solid " + theme.manakin.defaultBorderColor[500],
			minHeight: "50px",
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between"
		}, theme.breakpoints.up("md"), {
			minHeight: "100px"
		}),
		last: {
			borderBottom: "1px solid " + theme.manakin.defaultBorderColor[500],
			marginBottom: "2rem"
		}
	});
}, { name: "AppNotificationListItem" });

var NotificationListItem = function NotificationListItem(props) {
	var last = props.last,
	    data = props.data,
	    disabled = props.disabled,
	    loading = props.loading;

	var classes = useStyles();

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var handleChange = function handleChange(value, name) {
		props.onChange(value, name);
	};

	var name = "";
	switch (data) {
		case "EMAIL":
			name = t("app.account.notifications.email-header");
			break;
		case "PORTAL":
			name = t("app.account.notifications.portal-header");
			break;
		default:
			name = t("app.account.notifications.default-header");
	}

	return _react2.default.createElement(
		"div",
		{ className: (0, _classnames2.default)(classes.root, _defineProperty({}, classes.last, last)) },
		_react2.default.createElement(
			"span",
			null,
			name
		),
		_react2.default.createElement(
			"div",
			null,
			_react2.default.createElement(_core2.SwitchField, {
				name: data,
				onChange: handleChange,
				initialValue: !disabled,
				loading: loading
			})
		)
	);
};

exports.default = NotificationListItem;