import React, {useEffect} from 'react';
import {withWorkforms} from '@manakin/app-core';
import {compose} from 'recompose';
import {GQL_FETCH_STYLESHEET} from './graphql';
import {useQuery} from '@apollo/react-hooks';
import {connect} from 'react-redux';
import {changeColor} from '@manakin/app-core/ColorService/actions';
import {useLocation} from 'react-router-dom';
import Evaluation from "./Evaluation";

const queryString = require('query-string');

const EvaluationPreviewContainer = (props) => {
    const {match, workformsData, changeColor} = props;
    const query = match.params.query;
    const evaluationData = queryString.parse(query);
    const location = useLocation();

    const {data = {}, loading} = useQuery(GQL_FETCH_STYLESHEET, {
        variables: {
            id: evaluationData.styleSheet || '',
        },
    });

    useEffect(() => {
        const items = Array.isArray(evaluationData.items)
            ? evaluationData.items
            : evaluationData.items
                ? [evaluationData.items]
                : [];

        if (!loading) {
            const obj = {
                ...evaluationData,
                headerImage: {
                    url: evaluationData.headerImage,
                },
                image: {
                    url: evaluationData.image,
                },
                workforms: [
                    ...items.map((item, idx) => ({
                        id: item,
                        type: evaluationData.itemTypes[idx],
                    })),
                ],
                options: {
                    showFeedback: true,
                    nextButton: false,
                },
            };
            changeColor({
                ...data.styleSheet,
                location: location.pathname,
            });
            workformsData.loadWorkforms({...obj});
        }
    }, [loading]);

    const handleFinish = () => {
        return true;
    };

    return (
        <Evaluation
            {...workformsData}
            ealuationData={{...workformsData.rawData}}
            preview={true}
            workforms={workformsData.workforms}
            onFinish={handleFinish}
        />
    );
};

export default compose(
    connect(null, (dispatch) => ({
        changeColor: (styleSheet) => dispatch(changeColor(styleSheet)),
    })),
    withWorkforms()
)(EvaluationPreviewContainer);
