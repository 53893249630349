export default {
	appUsers: {
		read: 'APP_USERS_READ',
		write: 'APP_USERS_WRITE',
		delete: 'APP_USERS_DELETE',
	},
	appRoles: {
		read: 'APP_ROLES_READ',
		write: 'APP_ROLES_WRITE',
		delete: 'APP_ROLES_DELETE',
	},
	cmsUsers: {
		read: 'CMS_USERS_READ',
		write: 'CMS_USERS_WRITE',
		delete: 'CMS_USERS_DELETE',
	},
	cmsRoles: {
		read: 'CMS_ROLES_READ',
		write: 'CMS_ROLES_WRITE',
		delete: 'CMS_ROLES_DELETE',
	},
	programs: {
		read: 'PROGRAMS_READ',
		write: 'PROGRAMS_WRITE',
		delete: 'PROGRAMS_DELETE',
	},
	products: {
		read: 'PRODUCTS_READ',
		write: 'PRODUCTS_WRITE',
		delete: 'PRODUCTS_DELETE',
	},
	licenses: {
		read: 'LICENSES_READ',
		write: 'LICENSES_WRITE',
		delete: 'LICENSES_DELETE',
	},
	learningTargets: {
		read: 'LEARNING_TARGETS_READ',
		write: 'LEARNING_TARGETS_WRITE',
		delete: 'LEARNING_TARGETS_DELETE',
	},
	boxes: {
		read: 'BOXES_READ',
		write: 'BOXES_WRITE',
		delete: 'BOXES_DELETE',
	},
	workforms: {
		read: 'WORKFORMS_READ',
		write: 'WORKFORMS_WRITE',
		delete: 'WORKFORMS_DELETE',
	},
	elements: {
		read: 'ELEMENTS_READ',
		write: 'ELEMENTS_WRITE',
		delete: 'ELEMENTS_DELETE',
	},
	tags: {
		read: 'TAG_READ',
		write: 'TAG_WRITE',
		delete: 'TAG_DELETE',
	},
	settings: {
		read: 'SETTINGS_READ',
		write: 'SETTINGS_WRITE',
		delete: 'SETTINGS_DELETE',
	},
	factCards: {
		read: 'FACT_CARDS_READ',
		write: 'FACT_CARDS_WRITE',
		delete: 'FACT_CARDS_DELETE',
	},
	faq: {
		read: 'FAQ_READ',
		write: 'FAQ_WRITE',
		delete: 'FAQ_DELETE',
	},
	organisations: {
		read: 'ORGANISATIONS_READ',
		write: 'ORGANISATIONS_WRITE',
		delete: 'ORGANISATIONS_DELETE',
	},
	messages: {
		read: 'MESSAGE_READ',
		write: 'MESSAGE_WRITE',
		delete: 'MESSAGE_DELETE',
	},
	notifications: {
		read: 'NOTIFICATION_READ',
		write: 'NOTIFICATION_WRITE',
		delete: 'NOTIFICATION_DELETE',
	},
	stylesheets: {
		read: 'STYLESHEET_READ',
		write: 'STYLESHEET_WRITE',
		delete: 'STYLESHEET_DELETE',
	},
	books: {
		read: 'BOOK_READ',
		write: 'BOOK_WRITE',
		delete: 'BOOK_DELETE',
	},
	themes: {
		read: 'THEME_READ',
		write: 'THEME_WRITE',
		delete: 'THEME_DELETE',
	},
	groups: {
		read: 'GROUPS_READ',
		write: 'GROUPS_WRITE',
		delete: 'GROUPS_DELETE',
	},
	appointment: {
		read: 'APPOINTMENT_READ',
		write: 'APPOINTMENT_WRITE',
		delete: 'APPOINTMENT_DELETE',
	},
	appointmentSubject: {
		read: 'APPOINTMENT_SUBJECT_READ',
		write: 'APPOINTMENT_SUBJECT_WRITE',
		delete: 'APPOINTMENT_SUBJECT_DELETE',
	},
	news: {
		read: 'NEWS_READ',
		write: 'NEWS_WRITE',
		delete: 'NEWS_DELETE',
	},
	impersonate: {
		appUser: 'APPUSER_IMPERSONATE',
		cmsUser: 'CMSUSER_IMPERSONATE',
	},
	homepage: {
		read: 'HOMEPAGE_READ',
		write: 'HOMEPAGE_WRITE',
		delete: 'HOMEPAGE_DELETE',
	},
	commonSettings: {
		read: 'SETTINGS_COMMON_READ',
		write: 'SETTINGS_COMMON_WRITE',
	},
	platformSettings: {
		read: 'SETTINGS_PLATFORM_READ',
		write: 'SETTINGS_PLATFORM_WRITE',
	},
	colorSettings: {
		read: 'SETTINGS_COLOR_READ',
		write: 'SETTINGS_COLOR_WRITE',
	},
	systemTextSettings: {
		read: 'SETTINGS_SYSTEM_TEXT_READ',
		write: 'SETTINGS_SYSTEM_TEXT_WRITE',
	},
	examTrainerQuestions: {
		read: 'EXAM_TRAINER_QUESTIONS_READ',
		write: 'EXAM_TRAINER_QUESTIONS_WRITE',
		delete: 'EXAM_TRAINER_QUESTIONS_DELETE',
	},
	events: {
		read: 'EVENT_READ',
		write: 'EVENT_WRITE',
		delete: 'EVENT_DELETE',
	},
	invoices: {
		read: 'PRODUCTS_READ',
		write: 'PRODUCTS_WRITE',
		delete: 'PRODUCTS_DELETE',
	},
	widgets: {
		write: 'APP_WIDGET_WRITE',
		delete: 'APP_WIDGET_DELETE',
		read: 'APP_WIDGET_READ',
	},
	cmsStatistics: {
		write: 'CMS_STATISTICS_WRITE',
		delete: 'CMS_STATISTICS_DELETE',
		read: 'CMS_STATISTICS_READ',
	},
	colophons: {
		write: 'COLOPHON_WRITE',
		delete: 'COLOPHON_DELETE',
		read: 'COLOPHON_READ',
	},
	autogenLicenses: {
		read: 'AUTOGEN_LICENSES_READ',
	},
};
