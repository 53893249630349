'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _styles = require('@material-ui/styles');

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var useStyles = (0, _styles.makeStyles)(function (theme) {
	var _boxDescription, _imageIcon, _createStyles;

	return (0, _styles.createStyles)((_createStyles = {
		lockedIcon: _defineProperty({
			position: 'absolute',
			bottom: '2.3rem',
			left: '2rem',
			color: theme.manakin.defaultBoxColor[500],
			width: '2rem',
			height: '2rem'

		}, theme.breakpoints.up('md'), {
			width: '30px',
			height: '30px',
			top: "50%",
			transform: "translateY(-50%)"
		}),
		root: {
			position: 'relative'
		},
		boxRoot: _defineProperty({
			padding: 0,
			overflow: 'hidden',
			marginBottom: '2rem'
		}, theme.breakpoints.up('md'), {
			marginBottom: '5rem'
		}),
		boxRootOverflow: _defineProperty({
			paddingTop: '2rem',
			marginTop: '0'
		}, theme.breakpoints.up('md'), {
			marginTop: '-2rem'
		}),
		listViewRoot: {
			marginBottom: '0rem',
			paddingTop: 0,
			'&$boxRootOverflow': {
				marginTop: 0
			}
		},
		inner: _defineProperty({
			background: theme.manakin.defaultBoxBGColor[500],
			height: '24rem',
			width: '100%',
			padding: '2rem',
			position: 'relative',
			cursor: 'pointer',
			opacity: 0,
			transition: 'opacity .3s, transform .3s',
			transform: 'translateX(-10rem)',
			flexWrap: 'wrap',
			display: 'flex',
			flexFlow: 'column',
			justifyContent: 'center',
			overflow: 'hidden'
		}, theme.breakpoints.up('md'), {
			height: '50rem',
			padding: '0 15rem',
			'&:hover': {
				'& $backgroundImage': {
					transform: 'scale(1.1)'
				},
				'& $boxDescription': {
					maxHeight: '300px',
					transform: 'none',
					transition: 'all .2s'
				},
				'& $boxSubtitle': {
					visibility: 'hidden',
					transition: 'all .1s'
				}
			}
		}),
		innerDone: {
			height: '30rem'
		},
		innerDoneProgressionDashboard: {
			height: '40rem'
		},
		withOverflow: _defineProperty({}, theme.breakpoints.up('md'), {
			overflow: 'visible'
		}),
		show: {
			opacity: 1,
			transform: 'translateX(0rem)'
		},
		link: {
			textDecoration: 'none'
		},
		backgroundImage: {
			position: 'absolute',
			zIndex: '0',
			height: '100%',
			width: '100%',
			left: '0',
			top: '0',
			backgroundSize: 'cover',
			backgroundPosition: 'center',
			transition: 'width .3s, transform .3s, background-color .3s'
		},
		overflow: _defineProperty({
			transform: 'scale(1) translateY(-20px)',
			height: 'calc(100% + 20px)',
			'& img': {
				objectFit: 'contain'
			}
		}, theme.breakpoints.down('md'), {
			transform: 'scale(1) translateY(-20px)',
			height: 'calc(100% + 20px)',
			'& img': {
				objectFit: 'cover'
			}
		}),
		themeIcon: {
			backgroundPosition: 'center',
			width: 'calc(100% - 30px)',
			height: 'calc(100% - 30px)',
			top: '50%',
			left: '15px',
			position: 'absolute',
			transform: 'translateY(-50%)',
			borderRadius: '100%',
			border: '1px solid ' + theme.manakin.defaultBorderColor[500]
		},
		boxName: _defineProperty({
			position: 'relative',
			display: 'inline-block',
			fontFamily: theme.manakin.defaultTitleFont,
			color: theme.palette.getContrastText(theme.manakin.defaultBoxBGColor[500]),
			fontSize: '1.6rem',
			fontWeight: 'bold',
			letterSpacing: '.4rem',
			lineHeight: '2.6rem',
			maxWidth: '30rem',
			transition: 'padding .3s',
			textDecoration: 'none',
			zIndex: 2,
			marginRight: '45px'

		}, theme.breakpoints.up('md'), {
			fontSize: '3.8rem',
			lineHeight: '5.2rem',
			maxWidth: '70%',
			padding: '0 0 0 33px',
			marginRight: '0'
		}),
		boxNameHover: _defineProperty({
			opacity: '1!important',
			visibility: 'visible!important',
			transform: 'unset!important'

		}, theme.breakpoints.up('md'), {
			padding: '0'
		}),
		boxSubtitle: _defineProperty({
			fontFamily: theme.manakin.secondaryTitleFont,
			fontSize: '2rem',
			lineHeight: '2rem',
			position: 'relative',
			display: 'inline-block',
			color: theme.palette.getContrastText(theme.manakin.defaultBoxBGColor[500]),
			maxWidth: '20rem',
			zIndex: 2,
			transition: 'padding .3s',
			textDecoration: 'none',
			marginBottom: '1rem'
		}, theme.breakpoints.up('md'), {
			padding: '0 0 0 33px',
			maxWidth: '100%'
		}),
		boxDescription: (_boxDescription = {
			zIndex: '1',
			position: 'relative',
			marginTop: '2rem',
			color: theme.palette.getContrastText(theme.manakin.defaultBoxBGColor[500]),
			lineHeight: '1.5',
			maxHeight: 0,
			overflow: 'hidden',
			transition: 'all .2s ease'
		}, _defineProperty(_boxDescription, 'zIndex', 2), _defineProperty(_boxDescription, theme.breakpoints.up('md'), {
			padding: '0 0 0 33px',
			maxWidth: '50%'
		}), _boxDescription),
		hasLabel: {},
		listView: _defineProperty({
			backgroundColor: 'white',
			display: 'flex',
			minHeight: '6.8rem',
			height: 'auto',
			padding: '1rem 1rem 1rem 9rem',
			marginBottom: '1rem',
			border: '1px solid ' + theme.manakin.defaultBorderColor[500],
			borderRadius: '8px',
			'& $backgroundImage': _defineProperty({
				width: '7rem'
			}, theme.breakpoints.up('md'), {
				width: '10rem'
			}),
			'& $themes': {
				display: 'none'
			},
			'& $boxName': _defineProperty({
				color: 'black',
				fontSize: '1.4rem',
				maxWidth: 'none',
				lineHeight: '2rem',
				letterSpacing: '.17rem',
				transition: 'color .2s',
				display: 'flex',
				alignItems: 'center',
				padding: 0
			}, theme.breakpoints.up('md'), {
				fontSize: '1.8rem',
				letterSpacing: '.2rem'
			}),
			'& $hasLabel': _defineProperty({}, theme.breakpoints.up('md'), {
				marginTop: 0
			}),
			'& $boxSubtitle': {
				display: 'none'
			},
			'& $boxDescription': {
				display: 'none'
			},
			'& $percentage': _defineProperty({}, theme.breakpoints.up('md'), {
				top: 'calc(50% - 2px)',
				transform: 'translateY(-50%)'
			}),
			'& $boxCardLabel': _defineProperty({
				top: 'auto',
				zIndex: 99,
				bottom: 0,
				left: 0,
				margin: 0,
				position: 'absolute',
				padding: '5px',
				display: 'inline-block',
				lineHeight: '12px',
				fontSize: '12px',
				backgroundColor: 'white',
				color: 'black',
				borderRadius: '0 32px 32px 0'
			}, theme.breakpoints.up('md'), {
				padding: '10px 32px',
				fontSize: '16px'
			}),
			'&:hover': {
				'&:hover': {
					borderColor: theme.manakin.defaultBorderHoverColor[500],
					'& $boxName': {
						color: theme.manakin.defaultContentHover[500]
					}
				}
			}
		}, theme.breakpoints.up('md'), {
			height: '10rem',
			padding: '4rem 4rem 4rem 14rem',
			marginBottom: '1.8rem'
		}),
		labelAndlist: _defineProperty({
			padding: '1rem 1rem 1rem 9rem'
		}, theme.breakpoints.up('md'), {
			padding: '4rem 4rem 4rem 14rem'
		}),
		primaryColor: {
			color: 'white'
		},
		secondaryColor: {
			color: 'black'
		},
		iconRoot: {
			width: 'auto',
			height: 'auto',
			Zindex: 2
		},
		doneIcon: {
			height: '1.5rem',
			width: '1.5rem',
			color: theme.palette.getContrastText(theme.palette.secondary[500])
		},
		done: _extends(_defineProperty({
			position: 'absolute',
			width: '30px',
			height: '30px',
			borderRadius: '100%',
			backgroundColor: theme.palette.secondary[500],
			zIndex: '2',
			right: '20px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		}, theme.breakpoints.up('md'), {
			width: '60px',
			height: '60px',
			top: '20px'
		}), theme.manakin.elementCardDone),
		themes: _defineProperty({
			position: 'absolute',
			top: '20px',
			left: 0,
			zIndex: 3
		}, theme.breakpoints.up('md'), {
			top: '20px'
		}),
		boxCardLabel: _defineProperty({
			top: '10px',
			zIndex: 99,
			left: 0,
			position: 'absolute',
			padding: '5px 32px',
			display: 'inline-block',
			backgroundColor: 'white',
			color: 'black',
			borderRadius: '0 32px 32px 0',
			transition: 'margin .1s ease',
			minWidth: '70px'
		}, theme.breakpoints.up('md'), {
			top: '30px',
			padding: '10px 32px',
			'&:not(:hover)': {
				'& $boxCardText': {
					marginLeft: '-300px',
					opacity: 0,
					transition: 'all .1s ease'
				},
				'& $boxCardLabelAnimate': {
					'& $boxCardText': {
						paddingRight: theme.spacing(2.5) + 'px',
						transform: 'translateX(-' + theme.spacing(8.75) + 'px)'
					}
				}
			}
		}),
		boxCardLabelAndTag: _defineProperty({
			padding: "0 8px",
			top: 3,
			fontSize: "1.2rem"
		}, theme.breakpoints.up('md'), {
			top: 10,
			padding: '0px 16px',
			fontSize: "1.4rem"
		}),
		boxCardText: {
			fontWeight: 600,
			fontSize: '13px',
			lineHeight: '1',
			letterSpacing: '2px',
			textTransform: 'uppercase',
			marginBottom: 0,
			color: 'black',
			opacity: 1,
			transition: 'margin .1s ease',
			whiteSpace: "nowrap"
		},
		boxCardIcon: {
			width: '15px',
			marginRight: '1rem'
		},
		boxCardIconLabel: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			padding: '10px 20px 10px 10px'
		},
		percentage: _defineProperty({
			color: theme.manakin.defaultContentColor[500],
			backgroundColor: 'white',
			width: '4.2rem',
			height: '4.2rem',
			position: 'absolute',
			top: '1.3rem',
			right: '10px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			borderRadius: '100%',
			fontFamily: theme.manakin.defaultTitleFont,
			fontSize: '1rem',
			fontWeight: 'bold',
			letterSpacing: '2.4px',
			marginRight: 8
		}, theme.breakpoints.up('md'), {
			width: '6.2rem',
			height: '6.2rem',
			fontSize: '1.2rem',
			top: '2rem'
		}),
		percentageDone: _defineProperty({
			backgroundColor: theme.palette.secondary[500],
			width: '2rem',
			height: '2rem',
			borderRadius: '22px',
			top: '2.3rem',
			color: theme.palette.getContrastText(theme.palette.secondary[500])
		}, theme.breakpoints.up('md'), {
			width: '12rem',
			height: '4rem',
			top: '2.7rem'
		}),
		percentageDoneListView: _defineProperty({}, theme.breakpoints.up('md'), {
			width: '4rem'
		})
	}, _defineProperty(_createStyles, 'done', {
		display: 'flex',
		alignItems: 'center'
	}), _defineProperty(_createStyles, 'voltooid', _defineProperty({
		display: 'none',
		marginLeft: '10px'
	}, theme.breakpoints.up('md'), {
		display: 'block'
	})), _defineProperty(_createStyles, 'percentageDoneIcon', _defineProperty({
		width: '1.4rem',
		height: '1.4rem'
	}, theme.breakpoints.up('md'), {
		width: '2rem',
		height: '2rem'
	})), _defineProperty(_createStyles, 'longArrow', {
		'& .arrow': {}
	}), _defineProperty(_createStyles, 'boxCardLabelAnimate', {
		padding: theme.spacing(0, 0, 0, 1.25),
		background: 'transparent',
		'& $boxCardIcon': {
			position: 'relative',
			zIndex: '2',
			width: '32px'
		},
		'& $boxCardText': {
			display: 'flex',
			alignItems: 'center',
			transform: 'translateX(calc(-100% + ' + theme.spacing(1.25) + 'px))',
			padding: theme.spacing(1.25, 9.75, 1.25),
			background: 'white',
			borderRadius: 'inherit',
			transition: 'padding .2s ease, transform .2s ease'
		}
	}), _defineProperty(_createStyles, 'tag', _defineProperty({
		position: 'absolute',
		right: '20px',
		top: '13px',
		zIndex: 99
	}, theme.breakpoints.up('md'), {
		right: '40px',
		top: '21px'
	})), _defineProperty(_createStyles, 'withLabel', _defineProperty({
		right: '68px'
	}, theme.breakpoints.up('md'), {
		right: '84px'
	})), _defineProperty(_createStyles, 'tagdone', _defineProperty({
		right: '40px'
	}, theme.breakpoints.up('md'), {
		right: '140px'
	})), _defineProperty(_createStyles, 'listViewTags', _defineProperty({
		visibility: 'hidden'
	}, theme.breakpoints.up('md'), {
		visibility: 'visible',
		top: '20px',
		'&$withLabel': {
			right: '76px'
		}
	})), _defineProperty(_createStyles, 'imageIcon', (_imageIcon = {
		width: '30px',
		height: '30px',
		position: 'relative',
		zIndex: 99,
		marginRight: '10px',
		backgroundColor: 'white',
		display: 'none',
		'& img': {
			width: '30px',
			height: '30px',
			objectFit: 'contain'
		}
	}, _defineProperty(_imageIcon, theme.breakpoints.up('sm'), {
		display: 'block'
	}), _defineProperty(_imageIcon, theme.breakpoints.up('md'), {
		height: '40px',
		width: '40px',
		'& img': {
			height: '40px',
			width: '40px'
		}
	}), _imageIcon)), _defineProperty(_createStyles, 'progressionDashboard', _defineProperty({}, theme.breakpoints.up('md'), {
		width: '50%',
		marginRight: '2.5rem'
	})), _defineProperty(_createStyles, 'double', _defineProperty({}, theme.breakpoints.up('md'), {
		display: 'flex'
	})), _createStyles));
}, {
	name: 'AppBoxCard'
});

exports.default = useStyles;