import { makeStyles } from "@material-ui/core";
import React from "react";
import QRCodeGenerator from "qrcode.react";

const useStyles = makeStyles((theme) => ({
	root: {},
	qr: {
		width: "100%",
		maxWidth: "200px",
		objectFit: "contain",
		margin: "50px 0",
	},
}));

const QRCode = (props) => {
	const { dataUri, showSecretKey } = props;
	const classes = useStyles();

	if (!dataUri) return (<div />);

	return (
		<div className={classes.root}>
			<div className={classes.qr}>
				<QRCodeGenerator value={dataUri} size={200} renderAs='svg' />
			</div>
			{showSecretKey && (
				<p>{(new URL(dataUri)).searchParams.get("secret")}</p>
			)}
		</div>
	);
};

export default QRCode;
