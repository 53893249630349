import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { WorkformLayout, LongArrow } from '@manakin/app-core';
import { FeedbackBox } from '@manakin/app-workforms';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import { styles } from './styles';
import { ImageWithFocusPoint } from '@manakin/app-core';
import { withTranslation } from 'react-i18next';
import { isDone } from '../lib';

class ImageCheckQuestion extends React.PureComponent {
	handleClick = (idx) => {
		if (this.props.onChange && !this.props.data.done) this.props.onChange(idx);
	};

	handleSubmit = () => {
		if (this.props.onSubmit) this.props.onSubmit();
	};

	render() {
		const { classes, data, onFinished, disabled = false, t, saving } = this.props;
		const { correct } = data;

		return (
			<WorkformLayout
				question={data.question || ''}
				instruction={data.introduction || t('app.workforms.image-check-question.introduction')}
				loading={false}
				saving={saving}
				renderAnswers={() => (
					<div className={classes.wrapper}>
						<div className={classes.answersRoot}>
							{data.answers &&
								data.answers.map((answer, i) => (
									<div key={answer.id} className={classes.card} onClick={() => this.handleClick(i)}>
										<div
											className={classNames(classes.innerCard, {
												[classes.notActive]: data.answers.some((i) => i.selected) && !answer.selected,
												[classes.active]: isDone(data) && !correct && answer.correct,
											})}
										>
											<div className={classes.image}>
												<ImageWithFocusPoint
													asset={answer.image}
													imageProps={{
														className: classes.backgroundImage,
													}}
												/>
												{answer.text && <div className={classes.text}>{answer.text}</div>}
											</div>
											<div className={classNames(classes.selected, { [classes.showIcon]: answer.selected })} />
										</div>
									</div>
								))}
						</div>
						<div className={classes.buttons}>
							{!data.feedback ? (
								<Button
									variant="contained"
									color="primary"
									fullWidth
									className={classes.button}
									onClick={this.handleSubmit}
									disabled={disabled}
								>
									{data.buttonText || t('common.check-answer')}
									<LongArrow className={classes.longArrowRoot} />
								</Button>
							) : (
								<div
									className={classNames(classes.feedback, {
										[classes.activeFeedback]: data.feedback,
									})}
								>
									<FeedbackBox data={data} onFinished={onFinished} outlined={true} />
								</div>
							)}
						</div>
					</div>
				)}
			/>
		);
	}
}

export default compose(withStyles(styles, { name: 'AppImageCheckQuestion' }), withTranslation())(ImageCheckQuestion);
