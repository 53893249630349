"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.GQL_FETCH_GROUP = exports.GQL_FETCH_ELEMENT_RESULTS = exports.GQL_FETCH_GROUPS = exports.GQL_FETCH_SCHOOL_CLASS = exports.GQL_FETCH_PROGRAM_RESULTS = exports.GQL_FETCH_SCHOOL = undefined;

var _templateObject = _taggedTemplateLiteral(["\n\tquery school($id: ID!) {\n\t\tschool(id: $id) {\n\t\t\tid\n\t\t\tname\n\t\t\tclasses {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tmanagers {\n\t\t\t\t\tid\n\t\t\t\t}\n\t\t\t\tteachers {\n\t\t\t\t\tid\n\t\t\t\t}\n\t\t\t\tstudents {\n\t\t\t\t\tid\n\t\t\t\t\tfirstName\n\t\t\t\t\troles {\n\t\t\t\t\t\tid\n\t\t\t\t\t\tname\n\t\t\t\t\t}\n\t\t\t\t\tschoolClasses {\n\t\t\t\t\t\tid\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t\tstudents {\n\t\t\t\tid\n\t\t\t}\n\t\t\tmanagers {\n\t\t\t\tid\n\t\t\t\tfullName\n\t\t\t\troles {\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tquery school($id: ID!) {\n\t\tschool(id: $id) {\n\t\t\tid\n\t\t\tname\n\t\t\tclasses {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tmanagers {\n\t\t\t\t\tid\n\t\t\t\t}\n\t\t\t\tteachers {\n\t\t\t\t\tid\n\t\t\t\t}\n\t\t\t\tstudents {\n\t\t\t\t\tid\n\t\t\t\t\tfirstName\n\t\t\t\t\troles {\n\t\t\t\t\t\tid\n\t\t\t\t\t\tname\n\t\t\t\t\t}\n\t\t\t\t\tschoolClasses {\n\t\t\t\t\t\tid\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t\tstudents {\n\t\t\t\tid\n\t\t\t}\n\t\t\tmanagers {\n\t\t\t\tid\n\t\t\t\tfullName\n\t\t\t\troles {\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"]),
    _templateObject2 = _taggedTemplateLiteral(["\n\tquery programResults($programs: [ID], $users: [ID]!) {\n\t\tprogramResults(programs: $programs, users: $users) {\n\t\t\tid\n\t\t\tprogress\n\t\t\tprogram {\n\t\t\t\tid\n\t\t\t}\n\t\t\tuser {\n\t\t\t\tid\n\t\t\t\tfullName\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tquery programResults($programs: [ID], $users: [ID]!) {\n\t\tprogramResults(programs: $programs, users: $users) {\n\t\t\tid\n\t\t\tprogress\n\t\t\tprogram {\n\t\t\t\tid\n\t\t\t}\n\t\t\tuser {\n\t\t\t\tid\n\t\t\t\tfullName\n\t\t\t}\n\t\t}\n\t}\n"]),
    _templateObject3 = _taggedTemplateLiteral(["\n\tquery schoolClass($id: ID!) {\n\t\tschoolClass(id: $id) {\n\t\t\tid\n\t\t\tname\n\t\t\tproducts {\n\t\t\t\tid\n\t\t\t\tprogram {\n\t\t\t\t\tid\n\t\t\t\t\tboxes {\n\t\t\t\t\t\tid\n\t\t\t\t\t\telements {\n\t\t\t\t\t\t\tid\n\t\t\t\t\t\t\ttype\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t\tstudents {\n\t\t\t\tid\n\t\t\t\tsessionAverageDuration\n\t\t\t\tsessionTotalDuration\n\t\t\t\tsessionCount\n\t\t\t}\n\t\t\tschools {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tquery schoolClass($id: ID!) {\n\t\tschoolClass(id: $id) {\n\t\t\tid\n\t\t\tname\n\t\t\tproducts {\n\t\t\t\tid\n\t\t\t\tprogram {\n\t\t\t\t\tid\n\t\t\t\t\tboxes {\n\t\t\t\t\t\tid\n\t\t\t\t\t\telements {\n\t\t\t\t\t\t\tid\n\t\t\t\t\t\t\ttype\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t\tstudents {\n\t\t\t\tid\n\t\t\t\tsessionAverageDuration\n\t\t\t\tsessionTotalDuration\n\t\t\t\tsessionCount\n\t\t\t}\n\t\t\tschools {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n"]),
    _templateObject4 = _taggedTemplateLiteral(["\n\tquery groups($filter: GroupFilter) {\n\t\tgroups(filter: $filter) {\n\t\t\tcount\n\t\t\tgroups {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\ttype\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tquery groups($filter: GroupFilter) {\n\t\tgroups(filter: $filter) {\n\t\t\tcount\n\t\t\tgroups {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\ttype\n\t\t\t}\n\t\t}\n\t}\n"]),
    _templateObject5 = _taggedTemplateLiteral(["\n\tquery elementResults($box: ID!, $program: ID!, $user: ID!) {\n\t\telementResults(box: $box, program: $program, user: $user) {\n\t\t\tfinished\n\t\t\telement {\n\t\t\t\tid\n\t\t\t\ttitle\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tquery elementResults($box: ID!, $program: ID!, $user: ID!) {\n\t\telementResults(box: $box, program: $program, user: $user) {\n\t\t\tfinished\n\t\t\telement {\n\t\t\t\tid\n\t\t\t\ttitle\n\t\t\t}\n\t\t}\n\t}\n"]),
    _templateObject6 = _taggedTemplateLiteral(["\n\tquery group($id: ID!) {\n\t\tgroup(id: $id) {\n\t\t\tid\n\t\t\ttype\n\t\t}\n\t}\n"], ["\n\tquery group($id: ID!) {\n\t\tgroup(id: $id) {\n\t\t\tid\n\t\t\ttype\n\t\t}\n\t}\n"]);

var _graphqlTag = require("graphql-tag");

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_SCHOOL = exports.GQL_FETCH_SCHOOL = (0, _graphqlTag2.default)(_templateObject);

var GQL_FETCH_PROGRAM_RESULTS = exports.GQL_FETCH_PROGRAM_RESULTS = (0, _graphqlTag2.default)(_templateObject2);

var GQL_FETCH_SCHOOL_CLASS = exports.GQL_FETCH_SCHOOL_CLASS = (0, _graphqlTag2.default)(_templateObject3);

var GQL_FETCH_GROUPS = exports.GQL_FETCH_GROUPS = (0, _graphqlTag2.default)(_templateObject4);

var GQL_FETCH_ELEMENT_RESULTS = exports.GQL_FETCH_ELEMENT_RESULTS = (0, _graphqlTag2.default)(_templateObject5);

var GQL_FETCH_GROUP = exports.GQL_FETCH_GROUP = (0, _graphqlTag2.default)(_templateObject6);