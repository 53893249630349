import React from 'react';
import { FeedbackBox } from '@manakin/app-workforms';
import classNames from 'classnames';
import { useStyles } from '../styles';

const Feedback = (props) => {
    const { data = {}, onFinished, onSetWorkformDone } = props;
    const classes = useStyles();

    if (!data.feedback) return <div></div>;
    return (
        <div
            className={classNames(classes.feedback, {
                [classes.activeFeedback]: data.feedback,
            })}
        >
            <FeedbackBox data={data} onFinished={onFinished} onSetWorkformDone={onSetWorkformDone} outlined={true} />
        </div>
    );
};

export default Feedback;
