'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _HelpButton = require('./HelpButton');

var _HelpButton2 = _interopRequireDefault(_HelpButton);

var _graphql = require('./graphql');

var _hooks = require('@manakin/hooks');

var _reactHooks = require('@apollo/react-hooks');

var _reactI18next = require('react-i18next');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var HelpButtonContainer = function HelpButtonContainer(props) {
    var _useState = (0, _react.useState)(false),
        _useState2 = _slicedToArray(_useState, 2),
        show = _useState2[0],
        setIsShow = _useState2[1];

    var _useState3 = (0, _react.useState)(null),
        _useState4 = _slicedToArray(_useState3, 2),
        expanded = _useState4[0],
        setIsExpanded = _useState4[1];

    var _useTranslation = (0, _reactI18next.useTranslation)(),
        i18n = _useTranslation.i18n;

    var _useLazyQuery = (0, _reactHooks.useLazyQuery)(_graphql.GQL_FETCH_GLOBAL_SETTINGS, {
        fetchPolicy: 'no-cache'
    }),
        _useLazyQuery2 = _slicedToArray(_useLazyQuery, 2),
        fetchSettings = _useLazyQuery2[0],
        _useLazyQuery2$1$data = _useLazyQuery2[1].data,
        globalSettingsData = _useLazyQuery2$1$data === undefined ? {} : _useLazyQuery2$1$data;

    (0, _react.useEffect)(function () {
        fetchSettings();
    }, [i18n.language]);

    var settings = (0, _hooks.useArrayToObject)(globalSettingsData.settings && globalSettingsData.settings.settings);

    var handleClick = function handleClick() {
        setIsShow(!show);
    };

    var handleChange = function handleChange(panel) {
        return function (event, expanded) {
            setIsExpanded(expanded ? panel : false);
        };
    };

    if (!settings.helpTitle) {
        return '';
    }
    return _react2.default.createElement(_HelpButton2.default, {
        show: show,
        expanded: expanded,
        settings: settings,
        onClick: handleClick,
        onChange: handleChange
    });
};

exports.default = HelpButtonContainer;