import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { compose } from "recompose";
import classNames from "classnames";

const styles = (theme) => ({
	root: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		marginBottom: theme.spacing(3),
	},
	circle: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: "100%",
		width: "45px",
		height: "45px",
		minWidth: "45px",
		minHeight: "45px",
		backgroundColor: theme.manakin.indicationColor[500],
		marginRight: theme.spacing(3),
		[theme.breakpoints.up("md")]: {
			width: "96px",
			height: "96px",
			minWidth: "96px",
			minHeight: "96px",
			marginRight: theme.spacing(6),
		},
	},
	number: {
		marginBottom: 0,
		color: theme.palette.getContrastText(
			theme.manakin.indicationColor[500]
		),
	},
	text: {
		marginBottom: 0,
	},
});

const CriteriaItem = (props) => {
	const { classes, number, text } = props;

	return (
		<div className={classes.root}>
			<div className={classes.circle}>
				<Typography
					component="p"
					variant="subtitle1"
					className={classNames(classes.subHeader, classes.number)}
				>
					{number}
				</Typography>
			</div>
			<Typography
				component="p"
				variant="subtitle1"
				className={classNames(
					classes.subHeader,
					classes.contentColor,
					classes.text
				)}
			>
				{text}
			</Typography>
		</div>
	);
};

export default compose(withStyles(styles, { name: "AppCriteriaItem" }))(
	CriteriaItem
);
