'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styles = require('@material-ui/core/styles');

var _reactRouterDom = require('react-router-dom');

var _recompose = require('recompose');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _appCore = require('@manakin/app-core');

var _styles2 = require('./styles');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Stepper = function (_React$Component) {
    _inherits(Stepper, _React$Component);

    function Stepper() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, Stepper);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Stepper.__proto__ || Object.getPrototypeOf(Stepper)).call.apply(_ref, [this].concat(args))), _this), _this._isMounted = false, _this.state = {
            animate: ''
        }, _this.handleNext = function () {
            var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
            var _this$props = _this.props,
                save = _this$props.save,
                elementId = _this$props.elementId;

            var _correct = data.correct != undefined ? data.correct : data || false;

            if (save) {
                save(_extends({}, data, {
                    data: _correct,
                    elementId: elementId
                }));
            }

            _this.setState({
                animate: ''
            }, function () {
                setTimeout(function () {
                    _this.props.stepper.onSubmit(_correct, data).then(function (data) {
                        if (data < 0) {
                            _this.props.onExit();
                        }
                        if (_this._isMounted) {
                            _this.setState({
                                animate: 'in'
                            });
                        }
                    });
                }, 100);
            });
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(Stepper, [{
        key: 'componentWillUnmount',
        value: function componentWillUnmount() {
            this._isMounted = false;
        }
    }, {
        key: 'componentDidMount',
        value: function componentDidMount() {
            var _this2 = this;

            this._isMounted = true;
            setTimeout(function () {
                if (_this2._isMounted) {
                    _this2.setState({
                        animate: 'in'
                    });
                }
            }, 100);
            if (this.props.variant == 'trainer') {
                this.props.stepper.startCounting();
            }
        }
    }, {
        key: 'componentDidUpdate',
        value: function componentDidUpdate(prevProps) {
            if (prevProps.stepper.step != this.props.stepper.step && this.props.stepper.step === 0) {
                this.setState({
                    animate: 'in'
                });
            }
        }
    }, {
        key: 'render',
        value: function render() {
            var _props = this.props,
                stepper = _props.stepper,
                classes = _props.classes,
                options = _props.options,
                classNameProp = _props.className,
                tagClass = _props.tagClass;

            var step = stepper.step;
            var type = stepper.items[step] ? stepper.items[step].type : '';
            var animate = this.state.animate;


            var className = (0, _classnames2.default)(classes.root, classNameProp, _defineProperty({}, classes.animateIn, animate == 'in'));

            return _react2.default.createElement(
                _react2.default.Fragment,
                null,
                type != 'StatementQuestion' && _react2.default.createElement(
                    'div',
                    { className: className },
                    _react2.default.createElement(_appCore.WorkformViewer, {
                        type: type,
                        item: stepper.items[step],
                        options: options,
                        onFinish: this.handleNext,
                        tagClass: tagClass
                    })
                ),
                type == 'StatementQuestion' && _react2.default.createElement(
                    'div',
                    {
                        className: (0, _classnames2.default)(classes.largeRoot, _defineProperty({}, classes.animateIn, animate == 'in'))
                    },
                    _react2.default.createElement(_appCore.WorkformViewer, {
                        type: type,
                        item: stepper.items[step],
                        options: options,
                        onFinish: this.handleNext,
                        tagClass: tagClass
                    })
                )
            );
        }
    }]);

    return Stepper;
}(_react2.default.Component);

exports.default = (0, _recompose.compose)((0, _styles.withStyles)(_styles2.styles), _reactRouterDom.withRouter)(Stepper);