import { makeStyles } from "@material-ui/core";
import React, { useCallback } from "react";
import { Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { logout } from "@manakin/authentication/actions";
import { useDispatch } from "react-redux";
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	root: {},
	paper: {
		padding: "25px",
		width: "100%",
		maxWidth: "600px",
		[theme.breakpoints.up("md")]: {
			padding: "50px",
		},
		height: "min-content",
		margin: "auto"
	},
	wrapper: {
		...theme.manakin.defaultWrapper,
	},
	content: {
		marginBottom: "2rem",
	},
	link: {
		textDescoration: "underline",
		cursor: "pointer",
	},
}));

const Layout = (props) => {
	const { children, title = "test" } = props;
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const onLogout = useCallback(() => dispatch(logout()), [dispatch]);

	// Function
	const handleClick = () => {
		onLogout();
	};

	return (
		<Paper className={classes.paper}>
			<div className={classes.root}>
				<div className={classes.wrapper}>
					{title && (
						<Typography variant="h2" component="h1">
							{title}
						</Typography>
					)}
					<div className={classes.content}>{children}</div>
					<Typography className={classes.link} onClick={handleClick}>
						{t("common.logout")}
					</Typography>
				</div>
			</div>
		</Paper>
	);
};

export default Layout;
