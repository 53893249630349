import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import AddIcon from "@material-ui/icons/Add";
import Avatar from "@material-ui/core/Avatar";
import EditIcon from "@material-ui/icons/Edit";

const ListItemComponent = (props) => {
	const { onClick, label = "", icon } = props;
	return (
		<List>
			<ListItem button onClick={onClick}>
				<ListItemAvatar>
					<Avatar>
						{icon === "edit" ? <EditIcon /> : <AddIcon />}
					</Avatar>
				</ListItemAvatar>
				<ListItemText primary={label} />
			</ListItem>
		</List>
	);
};

export default ListItemComponent;
