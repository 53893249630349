'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.GQL_FETCH_BOX_RESULTS = exports.GQL_FETCH_PROGRAM_RESULTS = exports.GQL_FETCH_ELEMENT_RESULTS = undefined;

var _templateObject = _taggedTemplateLiteral(['\n    query fetchElementResult($box: ID!, $program: ID!, $user: ID!) {\n        elementResults(box: $box, program: $program, user: $user) {\n            finished\n            element {\n                id\n                title\n                type\n            }\n            progress\n            rating\n            correct\n            bestTime {\n                id\n                time\n                user {\n                    id\n                    fullName\n                }\n            }\n        }\n    }\n'], ['\n    query fetchElementResult($box: ID!, $program: ID!, $user: ID!) {\n        elementResults(box: $box, program: $program, user: $user) {\n            finished\n            element {\n                id\n                title\n                type\n            }\n            progress\n            rating\n            correct\n            bestTime {\n                id\n                time\n                user {\n                    id\n                    fullName\n                }\n            }\n        }\n    }\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n    query programResults($programs: [ID], $users: [ID]!) {\n        programResults(programs: $programs, users: $users) {\n            id\n            progress\n            program {\n                id\n            }\n            user {\n                id\n                fullName\n            }\n        }\n    }\n'], ['\n    query programResults($programs: [ID], $users: [ID]!) {\n        programResults(programs: $programs, users: $users) {\n            id\n            progress\n            program {\n                id\n            }\n            user {\n                id\n                fullName\n            }\n        }\n    }\n']),
    _templateObject3 = _taggedTemplateLiteral(['\n    query boxResults($program: ID!, $user: ID!, $box: ID) {\n        boxResults(program: $program, user: $user, box: $box) {\n            ', '\n        }\n    }\n'], ['\n    query boxResults($program: ID!, $user: ID!, $box: ID) {\n        boxResults(program: $program, user: $user, box: $box) {\n            ', '\n        }\n    }\n']);

var _graphqlTag = require('graphql-tag');

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

var _graphQlHelpers = require('../graphQlHelpers');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_ELEMENT_RESULTS = exports.GQL_FETCH_ELEMENT_RESULTS = (0, _graphqlTag2.default)(_templateObject);

var GQL_FETCH_PROGRAM_RESULTS = exports.GQL_FETCH_PROGRAM_RESULTS = (0, _graphqlTag2.default)(_templateObject2);

var GQL_FETCH_BOX_RESULTS = exports.GQL_FETCH_BOX_RESULTS = (0, _graphqlTag2.default)(_templateObject3, _graphQlHelpers.boxResults);