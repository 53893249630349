import React, { useCallback } from 'react';
import { GQL_FETCH_CURRENT_CMS_USER } from './graphql';
import classNames from 'classnames';
import { useQuery } from '@apollo/react-hooks';

import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) =>
    createStyles({
        loading: {
            // display: 'none'
            opacity: '0',
        },
    })
);

const checkAccess = (currentUser, renderRules) => {
    let giveAccess = false;

    if (currentUser) {
        // If any of the user's roles has any of the given rule privileges, allow access
        renderRules.forEach((rule) => {
            currentUser.roles.forEach((role) => {
                role.privileges.forEach((privilege) => {
                    if (privilege.id === rule) giveAccess = true;
                });
            });
        });
    }

    return giveAccess;
};

const AccessControl = (props) => {
    const {
        children,
        renderNoAccess: customRenderNoAccess = () => '',
        renderRules,
        onHasAccess,
        disable,
    } = props;
    const classes = useStyles();

    const { data = {}, loading } = useQuery(GQL_FETCH_CURRENT_CMS_USER);

    const renderNoAccess = useCallback(() => (
        <div className={classNames(classes.noAccess, {
            [classes.loading]: loading,
        })}>
            {customRenderNoAccess()}
        </div>
    ), []);

    // No access, no matter the rules
    if (disable) {
        return renderNoAccess();
    }

    // onHasAccess callback is provided and will be called instead of rendering children
    if (onHasAccess !== undefined && checkAccess(data.currentCmsUser, renderRules)) {
        return onHasAccess();
    }

    if (checkAccess(data.currentCmsUser, renderRules)) {
        return (
            <div className={classNames(classes.root, {
                [classes.loading]: loading,
            })}>
                {children}
            </div>
        );
    }

    return renderNoAccess();
};

export default AccessControl;
