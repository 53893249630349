import React from 'react';
import { Carousel, Loader } from '@manakin/app-core';
import classNames from 'classnames';
import { GQL_FETCH_WIDGET_BOXES } from './graphql';
import { useTranslation } from 'react-i18next';
import BoxBlock from './BoxBlock';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { backgroundColorKeys } from '../../constants';
import WidgetHeader from '../WidgetHeader';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: '45px',
        paddingBottom: '2.5rem',

        '& .slider-control-centerleft': {
            bottom: '-30px!important',
            top: 'auto!important',
        },
        '& .slider-control-centerright': {
            bottom: '-30px!important',
            top: 'auto!important',
        },

        [theme.breakpoints.up('sm')]: {
            padding: '45px 0',
        },
    },
    boxItem: {
        padding: '0 12px 0',
    },
    first: {
        padding: '0 12px 0 0',
    },
    carouselRoot: {
        paddingRight: '8px',
        paddingBottom: '6.5rem',

        [theme.breakpoints.up('sm')]: {
            overflow: 'hidden',
        },
    },
    carouselWrapper: {
        overflow: 'hidden',
    },
    primary: {
		backgroundColor: theme.manakin.primaryWorkformColor[500],
        padding: 50,
	},
	secondary: {
		backgroundColor: theme.manakin.secondaryWorkformColor[500],
        padding: 50,
	},
	noBG: {
		backgroundColor: 'white',
        padding: 50,
	}
}));

const BoxesCarousel = (props) => {
    const { title, variant = 'FEATURED', gridProps, backgroundColor } = props;
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();

    const { data: data = {}, loading } = useQuery(GQL_FETCH_WIDGET_BOXES, {
        variables: { variant: variant },
    });
    const { appWidgetBoxes = {} } = data;

    const handleGoToBoxes = () => history.push('/dashboard');

    if (loading) return <Loader />;
    if (appWidgetBoxes.length === 0) return '';

    return (
        <Grid className={classes.root} item {...gridProps}>
            <div className={classNames(
                classes.carouselWrapper,
                { [classes.primary]: backgroundColor === backgroundColorKeys.primary },
                { [classes.secondary]: backgroundColor === backgroundColorKeys.secondary },
                { [classes.noBG]: backgroundColor === backgroundColorKeys.none },
            )}>
                <WidgetHeader title={title || t('common.boxes')} backgroundColor={backgroundColor} onClick={handleGoToBoxes}></WidgetHeader>
                    <div className={classes.carouselRoot}>
                        <Carousel
                            slidesToShow={1.2}
                            slidesToShowSM={2.2}
                            slidesToShowMD={3.1}
                            withoutControls={false}
                            backgroundColor={backgroundColor}
                        >
                            {appWidgetBoxes && appWidgetBoxes.map((box, idx) => (
                                <div key={box.id} className={classNames(classes.boxItem, {
                                    [classes.first]: idx === 0,
                                })}>
                                    <BoxBlock {...box} backgroundColor={backgroundColor} />
                                </div>
                            ))}
                        </Carousel>
                    </div>
            </div>
        </Grid>
    );
};

export default BoxesCarousel;
