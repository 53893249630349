"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _styles = require("@material-ui/core/styles");

var _Button = require("@material-ui/core/Button");

var _Button2 = _interopRequireDefault(_Button);

var _Dialog = require("@material-ui/core/Dialog");

var _Dialog2 = _interopRequireDefault(_Dialog);

var _DialogContent = require("@material-ui/core/DialogContent");

var _DialogContent2 = _interopRequireDefault(_DialogContent);

var _Typography = require("@material-ui/core/Typography");

var _Typography2 = _interopRequireDefault(_Typography);

var _appCore = require("@manakin/app-core");

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var useStyles = (0, _styles.makeStyles)(function (theme) {
	return {
		root: {
			textAlign: "center",
			paddingBottom: "50px",
			minWidth: "200px",
			minHeight: "200px"
		},
		title: {
			color: theme.manakin.defaultContentColor[100]
		},
		row: _defineProperty({
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			borderBottom: "1px solid " + theme.manakin.defaultBorderColor[300],
			height: "40px"
		}, theme.breakpoints.up("md"), {
			width: "500px",
			height: "80px"
		}),
		content: {
			margin: 0
		},
		button: {
			marginTop: "20px"
		}
	};
});

var DialogComponent = function DialogComponent(props) {
	var open = props.open,
	    onClose = props.onClose,
	    onSubmit = props.onSubmit,
	    loading = props.loading;

	var classes = useStyles();

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var handleClose = function handleClose() {
		onClose();
	};

	return _react2.default.createElement(
		_Dialog2.default,
		{ onClose: handleClose, open: open },
		_react2.default.createElement(
			_DialogContent2.default,
			null,
			loading ? _react2.default.createElement(
				"div",
				{ className: classes.root },
				_react2.default.createElement(_appCore.Loader, null)
			) : _react2.default.createElement(
				"div",
				{ className: classes.root },
				_react2.default.createElement(
					_Typography2.default,
					{ variant: "h4" },
					t("app.account.bpv-report.dialog.title")
				),
				_react2.default.createElement(
					_Typography2.default,
					null,
					t("app.account.bpv-report.dialog.description")
				),
				_react2.default.createElement(
					_Button2.default,
					{
						color: "primary",
						variant: "contained",
						className: classes.button,
						fullWidth: true,
						onClick: onSubmit
					},
					t("app.account.bpv-report.dialog.action")
				),
				_react2.default.createElement(
					_Button2.default,
					{
						color: "primary",
						variant: "contained",
						className: classes.button,
						fullWidth: true,
						onClick: handleClose
					},
					t("common.close")
				)
			)
		)
	);
};

exports.default = DialogComponent;