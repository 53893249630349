import React, { useState } from "react";
import { useStyles } from "../styles";
import { Typography } from "@material-ui/core";
import { RatingRequestsAssignments } from "@manakin/app-core";
import { useSelector } from "react-redux";
import MailIcon from '@material-ui/icons/MailOutlined';
import classNames from "classnames";
import { GQL_FETCH_RATING_REQUESTS } from "../graphql";
import { getProgram } from "@manakin/app-core/ProgramsDropdown/selectors";
import { getAppUser } from '@manakin/authentication/selectors';
import { useQuery } from "react-apollo";
import { filterPendingRatingRequests } from "@manakin/app-core/lib";
import { useTranslation } from 'react-i18next';

const Content = (props) => {
	const classes = useStyles();
	const { t } = useTranslation();

	// Selectors
	const program = useSelector((state) => getProgram(state) || {});
	const appUser = useSelector((state) => getAppUser(state)) || {};
	const [ ratingRequests, setRatingRequests ] = useState([]);

	const { loading: loading } = useQuery(
		GQL_FETCH_RATING_REQUESTS,
		{
			skip: program === undefined || !appUser.id,
			variables: {
				filter: {
					programId: program,
					studentId: appUser && appUser.id,
				},
			},
			onCompleted: data => {
				const requests = filterPendingRatingRequests(data);
				if (requests) setRatingRequests(requests);
			}
		}
	);

	return (
		<div className={classes.contentRoot}>
			<div className={classes.contentWrapper}>
				<div className={classes.contentTop}>
					<div className={classes.titleAndIconContainer}>
						<MailIcon className={classes.icon}/>
						<Typography
							component="h1"
							variant="h3"
							className={classNames(
								classes.title
							)}>
							{ratingRequests.length}
						</Typography>
					</div>
					<Typography variant="h4" component="h2" className={classes.header}>
						{t("app.rating-requests.title")}
					</Typography>
					<Typography
						className={classes.body}
						variant="div"
						component="body2">
						{t("app.rating-requests.description-first-part")}
						{" "}<span className={classes.title}>{ratingRequests.length}</span>{" "}
						{t("app.rating-requests.description-second-part")}
					</Typography>
				</div>
				<div className={classes.row}>
					<RatingRequestsAssignments/>
				</div>
			</div>
		</div>
	);
};

export default Content;
