import React, { useState, useEffect, useRef } from 'react';
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import { withStyles } from "@material-ui/core/styles";
import createUuid from "uuid/v4";
import classNames from "classnames";
import InputAdornment from "@material-ui/core/InputAdornment";
import AccountCircle from "@material-ui/icons/AccountCircle";

const styles = (theme) => ({
	root: {
		flex: "1 0 auto",
		transition: "background-color 200ms linear",
		backgroundColor: "transparent",
		"& *": { opacity: 1, pointerEvents: "auto" },
	},
	loading: {
		height: theme.spacing(6),
		backgroundColor: theme.palette.grey[100],
		"& *": { opacity: 0, pointerEvents: "none" },
	},
	input: {
		transition: "opacity 200ms linear",
	},
	inputLarge: {
		fontSize: "40px",
	},
	smallInputMargin: {
		marginBottom: "20px",
	},
	dateInput: {
		lineHeight: "27px",
		minHeight: "27px",
	},
	disabledLabel: {
		color: theme.manakin.disabledFieldLabelColor,
	},
	disabledInput: {
		color: theme.manakin.disabledFieldTextColor,
	},
	label: {},
});

const TextField = (props) => {
	const {
		classes = {},
		className: classNameProp,
		name,
		label,
		autoFocus = false,
		readOnly = false,
		type = "text",
		fullWidth = true,
		hideAsterisk,
		form,
		loading,
		margin = "normal",
		initialValue: _initial = false,
		shrink = true,
		inputLarge = false,
		onClick,
		placeholder,
		inputProps,
		multiline = false,
		onEnterPressed,
		icon: Icon = false,
		helperText,
		autoComplete,
		onPaste,
		onCopy
	} = props;
	const { errors = {}, schema = {} } = form || {};
	const className = classNames(
		classes.root,
		{ [classes.loading]: loading },
		classNameProp
	);
	const initialValue = _initial ? _initial : "";

	//state hooks
	const [uuid] = useState(createUuid());
	const [value, setValue] = useState(initialValue);
	const [inputLabelProps, setInputLabelProps] = useState({});

	const timer = useRef();
	
	useEffect(() => {
		return () => {
			clearTimeout(timer.current);
		};
	}, []);

	useEffect(() => {
		let _temp = {
			required: (schema[name] || {}).required && !hideAsterisk,
			htmlFor: uuid,
			className: classes.label,
		};
		if (shrink)
			setInputLabelProps({
				..._temp,
				shrink,
			});
		else
			setInputLabelProps({
				..._temp,
			});
	}, [classes]);

	useEffect(() => {
		if (!loading) {
			setValue(initialValue);
			if (form) form.onFieldChange({ key: name, value: initialValue });
		}
	}, [loading, initialValue]);

	const handleFieldChange = (event) => {
		let _value =
			type == "number"
				? isNaN(parseInt(event.target.value))
					? event.target.value
					: parseInt(event.target.value)
				: type == "double"
					? isNaN(parseFloat(event.target.value))
						? event.target.value
						: parseFloat(event.target.value)
					: event.target.value;

		setValue(_value);

		clearTimeout(timer.current);
		timer.current = setTimeout(() => {
			if (props.onChange) props.onChange(_value);
			if (form) form.onFieldChange({ key: name, value: _value });
		}, 400);
	};

	const handleEnterPress = (event) => {
		if (event.keyCode === 13 && onEnterPressed) {
			let _value =
				type == "number"
					? parseInt(event.target.value)
					: event.target.value;
			onEnterPressed(_value);
		}
	};

	return (
		<FormControl
			fullWidth={fullWidth}
			margin={margin}
			required={(schema[name] || {}).required}
			error={errors[name] && errors[name].length > 0}
			className={className}
		>
			<InputLabel
				{...inputLabelProps}
				classes={{
					...(readOnly && { root: classes.disabledLabel }),
				}}
			>
				{label}
			</InputLabel>
			<Input
				id={uuid}
				type={type === "double" ? "number" : type}
				value={value}
				onKeyUp={handleEnterPress}
				onChange={handleFieldChange}
				placeholder={placeholder}
				disabled={readOnly}
				onClick={onClick}
				inputProps={inputProps}
				autoFocus={autoFocus}
				readOnly={readOnly}
				multiline={multiline}
				autoComplete={autoComplete}
				onPaste={onPaste}
				onCopy={onCopy}
				endAdornment={
					<InputAdornment position="start">
						{Icon && <Icon />}
					</InputAdornment>
				}
				className={classNames(classes.input, {
					[classes.inputLarge]: inputLarge,
					[classes.disabledInput]: readOnly,
				})}
				classes={{
					...(type == "date" && { input: classes.dateInput }),
					underline: classes.underline,
				}}
			/>
			{errors[name] &&
				errors[name].length > 0 &&
				errors[name].map((e, i) => (
					<FormHelperText key={i}>{e}</FormHelperText>
				))}

			{helperText && helperText.length > 0 &&
			// Do not show if there are any errors, adds to much clutter
			!(errors[name] && errors[name].length > 0) && (
				<FormHelperText>{helperText}</FormHelperText>
			)}
		</FormControl>
	);
};

export default withStyles(styles)(TextField);
