import React from 'react';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import AltContent from './AltContent';
import { getSettingFile } from '@manakin/core/actions';
import { SettingsKeys } from '@manakin/core/lib/constants';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = (props = {}) =>
	makeStyles(
		(theme) =>
			createStyles({
				contentColor: {
					opacity: 1,
					transition: 'opacity .3s',
				},
				headingName: {
					[theme.breakpoints.up('md')]: {
						display: 'block',
					},
				},
				root: {
					width: '100%',
					minHeight: '500px',
					height: 'auto',
					backgroundColor: theme.manakin.primaryColor[500],
					padding: '20rem 0 50rem',
					'& $contentColor': {
						color: theme.palette.getContrastText(theme.manakin.primaryColor[500]),
					},
					backgroundImage: `url(${props.stylsheetBackgroundImage})`,
					backgroundPosition: 'center',
					backgroundRepeat: 'repeat',
					backgroundSize: '320px',
					[theme.breakpoints.up('md')]: {
						padding: '18rem 0 50rem',
						backgroundImage: `
						url(${props.stylesheetLeftSideImage}), 
						url(${props.stylesheetRightSideImage}),
						url(${props.stylsheetBackgroundImage})`,
						backgroundPosition: 'bottom 40px left, top 175px right, center',
						backgroundRepeat: 'no-repeat, no-repeat, repeat',
						backgroundSize: '245px, 190px, 320px'
					},
				},
				wrapper: {
					...theme.manakin.defaultWrapper,
				},
				content: {
					[theme.breakpoints.up('md')]: {
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						marginBottom: '2.3rem',
					},
				},
				title: {
					[theme.breakpoints.up('md')]: {
						maxWidth: '410px',
						width: '100%',
						padding: '2rem 0',
					},
				},
				heading: {},
				body: {
					marginBottom: '2.8rem',
				},
				introduction: {
					[theme.breakpoints.up('md')]: {
						maxWidth: '450px',
						width: '100%',
					},
				},
				loading: {
					opacity: 0,
				},
			}),
		{
			name: 'AppDashboardHeader',
		}
	);

const Header = (props) => {
	const { user, data, loading, config = {}, children } = props;

	const stylesheetLeftSideImage = getSettingFile(SettingsKeys.STYLESHEET_LEFT_SIDE_IMAGE)
	const stylesheetRightSideImage = getSettingFile(SettingsKeys.STYLESHEET_RIGHT_SIDE_IMAGE)
	const stylsheetBackgroundImage = getSettingFile(SettingsKeys.STYLESHEET_BACKGROUND_IMAGE)

	const classes = useStyles({
		stylesheetLeftSideImage:  stylesheetLeftSideImage && stylesheetLeftSideImage.url,
		stylesheetRightSideImage: stylesheetRightSideImage && stylesheetRightSideImage.url,
		stylsheetBackgroundImage: stylsheetBackgroundImage && stylsheetBackgroundImage.url,
	})();
	const { t } = useTranslation();

	const { pages, general } = config;
	const appDashboardConfig = pages.appDashboard || {};
	const { alternativeContent } = appDashboardConfig;
	const showName = appDashboardConfig.showName != undefined ? appDashboardConfig.showName : true;

	return (
		<React.Fragment>
			<div className={classes.root}>
				<div className={classes.wrapper}>
					<div className={classes.content}>
						<div className={classes.title}>
							<Typography component="h1" variant="h2" className={classes.contentColor} classes={{ h2: classes.heading }}>
								{t('app.dashboard.title')} <span className={classes.headingName}>{(showName && user.firstName) || ''}</span>
							</Typography>
						</div>
						<div className={classes.introduction}>
							{alternativeContent ? (
								<AltContent data={data} />
							) : (
								<div>
									<Typography
										component="p"
										variant="body2"
										classes={{ body2: classes.body }}
										className={classNames(classes.contentColor, {
											[classes.loading]: loading,
										})}
									>
										{data.description || ''}
									</Typography>
								</div>
							)}
						</div>
					</div>
					{children}
				</div>
			</div>
		</React.Fragment>
	);
};

export default compose(
	connect(({ config }) => ({ config }))
)(Header);
