import React, { useState, useCallback } from 'react';
import ShoppingListContext from './context/shoppingListContext';
import { GQL_CURRENT_APP_USER } from '../graphql';
import { useQuery } from '@apollo/react-hooks';
import useStyles from './styles';
import DefaultWrapper from './parts/DefaultWrapper';
import ProductsData from './ProductsData';
import Cookies from 'js-cookie';
import { Loader } from '@manakin/app-core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import { logout } from '@manakin/authentication/actions';
import { useDispatch } from 'react-redux';

const Webshop = (props) => {
	const classes = useStyles();
	const { loading, error } = useQuery(GQL_CURRENT_APP_USER);
	const productsFromCookie = Cookies.get('shop') ? JSON.parse(Cookies.get('shop')) || {} : {};
	const [products, setProducts] = useState(productsFromCookie);
	const shoppingListValue = { products, setProducts };
    const dispatch = useDispatch();
	const { t } = useTranslation();
	const history = useHistory();
	var productsAmount = Object.keys(products).length;
    const onLogout = useCallback(() => dispatch(logout()), [dispatch]);

    const handleLogout = () => {
        onLogout();
    }

	const handleBack = () => {
		history.goBack();
	};
	const handleClick = () => {
		history.push('/auth/pay');
	};

	if (loading) return <Loader fullscreen />;
	if (error)
		return (
			<DefaultWrapper>
				{t('common.something-went-wrong')}
				<Button onClick={handleBack} color="primary">
					{t('common.back')}
				</Button>
			</DefaultWrapper>
		);
	return (
		<ShoppingListContext.Provider value={shoppingListValue}>
			<Typography onClick={handleLogout} className={classes.logout}>
				{t('common.logout')}
			</Typography>
			<ProductsData />
			<div className={classes.buttonGroup}>
				<Button onClick={handleBack} color="primary">
					{t('common.back')}
				</Button>
				<Button
					onClick={handleClick}
					color="primary"
					variant="contained"
					className={classes.mainButtonStyle}
					disabled={productsAmount < 1}
				>
					{t('common.continue')}
				</Button>
			</div>
		</ShoppingListContext.Provider>
	);
};

export default Webshop;
