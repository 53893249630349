import React from 'react';
import { compose } from 'recompose';
import { withStyles, Typography, Button } from '@material-ui/core';
import { styles } from './styles';
import renderHTML from 'react-render-html';
import { PointsInfo } from '../../parts';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const Heading = (props) => {
    const { classes, data = {}, hasPoints = true } = props;
    const { t } = useTranslation();

    //functions
    const handleClick = () => {
        if (props.onClick) props.onClick();
    };
    const handleHighscoreClick = () => {
        if (props.onHighscoreClick) props.onHighscoreClick();
    };

    return (
		<div
			className={classNames(classes.headingRoot, {
				[classes.fullHeight]: !hasPoints,
			})}
		>
			<div className={classes.wrapper}>
				<div className={classes.title}>
					<Typography
						variant="subtitle1"
						className={classes.preTitle}
					>
						{t('app.elements.betting-game.front-page.pre-title')}
					</Typography>
					<Typography variant="h1" className={classes.titleFont}>
						{data.introTitle ||
							t('app.elements.betting-game.title')}
					</Typography>
				</div>
				<div className={classes.introduction}>
					<Typography
						variant="body1"
						className={classes.body}
						component="div"
					>
						{renderHTML(data.introText || '')}
					</Typography>
				</div>
				<div className={classes.buttons}>
					<Button
						variant="contained"
						color="primary"
						className={classes.firstButton}
						onClick={handleClick}
					>
						{t('app.elements.betting-game.front-page.start-game')}
					</Button>
					<Button
						variant="contained"
						color="primary"
						className={classes.secondButton}
						onClick={handleHighscoreClick}
					>
						{t('app.elements.betting-game.front-page.highscores')}
					</Button>
				</div>
			</div>
			<div className={classes.points}>
				<PointsInfo points={300} />
			</div>
		</div>
	);
};

export default compose(
    withStyles(styles, { name: 'AppBettingGameFrontpageHeading' })
)(Heading);
