'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var DefaultCard = function DefaultCard(props) {
    var _classNames;

    var classes = props.classes,
        item = props.item,
        disabled = props.disabled,
        children = props.children,
        handleLinkClick = props.handleLinkClick,
        bridge = props.bridge;

    return _react2.default.createElement(
        'div',
        {
            className: (0, _classnames2.default)(classes.link, (_classNames = {}, _defineProperty(_classNames, classes.bridgeLink, bridge), _defineProperty(_classNames, classes.disabled, disabled), _classNames)),
            onClick: function onClick(e) {
                return handleLinkClick(e, item);
            }
        },
        children
    );
};

exports.default = DefaultCard;