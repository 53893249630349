import React from 'react';
import useStyles from '../styles';

const DefaultWrapper = (props) => {
	const { children } = props;
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<div className={classes.container}>{children}</div>
		</div>
	);
};

export default DefaultWrapper;
