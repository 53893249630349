import React from 'react';
import Button from '@material-ui/core/Button';
import { TextField, PasswordField, withForm } from '@manakin/core';
import { compose } from 'recompose';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

const form = {
	password: {
		required: true,
	},
	email: {
		required: true,
		format: 'email',
	},
};

const LoginForm = (props) => {
	const {
		form,
		styles,
		initialValue,
		readOnly,
		loginBackgroundColor = false,
	} = props;
	const classes = useStyles({ loginBackgroundColor: loginBackgroundColor })();
	const { t } = useTranslation();

	const handleSubmit = () => {
		const { onSubmit } = form;
		const hasEmail = !!(form.fields ? form.fields.email : '');
		const hasPassword = !!(form.fields ? form.fields.password : '');
		setTimeout(onSubmit, hasPassword && hasEmail ? 0 : 750);
	};

	const { onSubmit } = form;

	return (
		<div className={classes.root}>
			<form>
				<TextField
					autoFocus
					name="email"
					label={t("common.fields.email-address.label")}
					type="email"
					shrink={false}
					hideAsterisk
					form={form}
					margin={'dense'}
					inputProps={{
						className: styles
							? styles.nativeInput
							: classes.nativeInput,
					}}
					classes={{
						root: styles ? styles.inputRoot : classes.inputRoot,
						input: styles ? styles.input : classes.input,
						label: styles ? styles.label : classes.label,
					}}
					readOnly={readOnly}
					initialValue={initialValue}
				/>
				<PasswordField
					name="password"
					label={t("common.fields.password.label")}
					onEnterPressed={onSubmit}
					form={form}
					hideAsterisk={true}
					inputProps={{
						className: styles
							? styles.nativeInput
							: classes.nativeInput,
					}}
					classes={{
						root: styles ? styles.inputRoot : classes.inputRoot,
						input: styles ? styles.input : classes.input,
						label: styles ? styles.label : classes.label,
						adornment: styles
							? styles.adornment
							: classes.adornment,
					}}
				/>
				<Button
					className={classes.submit}
					color="primary"
					variant="contained"
					fullWidth
					onClick={handleSubmit}
				>
					{t("common.login")}
				</Button>
			</form>
		</div>
	);
};

export default compose(
	withForm(form)
)(LoginForm);
