'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.changeColor = changeColor;
var CHANGE_COLOR = exports.CHANGE_COLOR = '@manakin/app-core/CHANGE_COLOR';

function changeColor() {
    var styleSheet = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    return {
        type: CHANGE_COLOR,
        payload: styleSheet
    };
}