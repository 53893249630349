import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import RepeatIcon from '@material-ui/icons/Repeat';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    listItem: {
        width: '100%',
        paddingLeft: 0,
    },
    listItemIcon: {
        marginRight: theme.spacing(1),
        color: '#242445',
    },
    listItemTitle: {
        color: '#242445',
        fontWeight: 500,
        textDecoration: 'underline',
    },
});

/**
 * List item that contains a title, description and edit button
 */
class InfoSideBarActionField extends React.Component {
    render() {
        const { classes, key, title, onActionClick } = this.props;

        return (
            <ListItem
                key={key}
                dense
                button
                className={classes.listItem}
                onClick={() => onActionClick()}
            >
                <ListItemIcon
                    classes={{
                        root: classes.listItemIcon,
                    }}
                >
                    <RepeatIcon className={classes.listItemIcon} />
                </ListItemIcon>
                <ListItemText
                    classes={{ primary: classes.listItemTitle }}
                    primary={title}
                />
            </ListItem>
        );
    }
}

export default withStyles(styles)(InfoSideBarActionField);
