'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactPlayer = require('react-player');

var _reactPlayer2 = _interopRequireDefault(_reactPlayer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Player = _react2.default.forwardRef(function (props, ref) {
    var className = props.className,
        url = props.url,
        playing = props.playing,
        onEnded = props.onEnded,
        onProgress = props.onProgress,
        onStart = props.onStart,
        loop = props.loop,
        volume = props.volume,
        onReady = props.onReady,
        textTrack = props.textTrack;


    return _react2.default.createElement(_reactPlayer2.default, {
        ref: ref,
        width: '100%',
        height: '100%',
        controls: false,
        className: className,
        url: url,
        playing: playing,
        onEnded: onEnded,
        onProgress: onProgress,
        onStart: onStart,
        loop: loop,
        volume: volume,
        onReady: onReady,
        config: {
            vimeo: {
                playerOptions: {
                    transparent: 0,
                    byline: 0,
                    title: 0,
                    controls: 0,
                    texttrack: textTrack
                }
            }
        }
    });
});

exports.default = Player;