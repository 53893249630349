import React from 'react';
import { useStyles } from './styles';
import { Typography } from '@material-ui/core';
import AuthDataForm from '../forms/AuthDataForm';
import {useTranslation} from 'react-i18next';

const AuthData = (props) => {
    const { form, initial } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <Typography variant="h2" className={classes.heading}>
                {t("common.fields.email.label")} &
                <br />
                {t("common.fields.password.label")}
            </Typography>
            <AuthDataForm form={form} initial={initial} />
        </div>
    );
};

export default AuthData;
