import React from 'react';
import StatementQuestion from './StatementQuestion';

class StatementQuestionContainer extends React.Component {
	state = {
		buttonDisabled: false,
	};

	componentDidMount() {
		this.setStartState();
	}

	componentDidUpdate(prevProps) {
		if ((!prevProps.data.done && this.props.data.done) || prevProps.data.id != this.props.data.id) {
			this.setStartState();
			this.setState({ buttonDisabled: false });
		}
	}

	setStartState = () => {
		const { data, options = {} } = this.props;

		this.setState({
			...data,
			options: this.props.options,
			feedback: data.finished && options && options.showFeedback ? true : false,
			done: data.finished,
			agree: data.finished ? (data.correct ? data.answer : !data.answer) : null,
		});
	};

	handleSubmit = (userAnswer) => {
		const { data, options = {} } = this.props;

		this.setState(
			{
				agree: userAnswer,
				correct: data.answer == userAnswer,
			},
			() => {
				if (options && options.showFeedback) {
					this.setState({ feedback: true, options: options });
				} else {
					this.setState({ buttonDisabled: true });
					this.handleFinish();
				}
			}
		);
	};

	handleFinish = () => {
		this.props.onFinished({
			correct: this.state.correct,
			workform: { ...this.state },
		});
	};

	render() {
		const { direction = 'default', saving } = this.props;

		return (
			<StatementQuestion
				data={{ ...this.state }}
				saving={saving}
				onSubmit={this.handleSubmit}
				onFinished={this.handleFinish}
				direction={direction}
				disabled={this.state.buttonDisabled}
			/>
		);
	}
}

export default StatementQuestionContainer;
