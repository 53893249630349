import { createSelector } from 'reselect';
import { getCoreState } from '../selectors';

export const APP_MENU_STATE_ROOT = 'appMenu';

const getState = createSelector(
    [getCoreState],
    (coreState) => coreState[APP_MENU_STATE_ROOT]
);

export const isAppMenuOpen = createSelector([getState], (state) => state.open);
