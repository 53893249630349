'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _core = require('@manakin/core');

var _reactBeautifulDnd = require('react-beautiful-dnd');

var _recompose = require('recompose');

var _styles = require('@material-ui/core/styles');

var _appCore = require('@manakin/app-core');

var _OpenWith = require('@material-ui/icons/OpenWith');

var _OpenWith2 = _interopRequireDefault(_OpenWith);

var _selectors = require('@manakin/app-core/AppBar/selectors');

var _actions = require('@manakin/app-core/AppBar/actions');

var _reactRedux = require('react-redux');

var _styles2 = require('./styles');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _reactI18next = require('react-i18next');

var _selectors2 = require('@manakin/app-core/ProgramsDropdown/selectors');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var mapStateToProps = function mapStateToProps(state) {
    return {
        isSave: (0, _selectors.getSave)(state)
    };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
    return {
        onSaveProgram: function onSaveProgram(data) {
            return dispatch((0, _actions.saveAppBar)(data));
        }
    };
};

var ProgramOrder = function ProgramOrder(props) {
    var onSaveProgram = props.onSaveProgram,
        schoolClass = props.schoolClass,
        onSave = props.onSave,
        classes = props.classes,
        _props$mileStones = props.mileStones,
        mileStones = _props$mileStones === undefined ? {} : _props$mileStones,
        _props$disable = props.disable,
        disable = _props$disable === undefined ? false : _props$disable,
        isSave = props.isSave,
        program = props.program;

    var storedProgramId = (0, _reactRedux.useSelector)(function (state) {
        return (0, _selectors2.getProgram)(state);
    });

    //state hooks

    var _useState = (0, _react.useState)([]),
        _useState2 = _slicedToArray(_useState, 2),
        data = _useState2[0],
        setData = _useState2[1];

    var _useState3 = (0, _react.useState)(true),
        _useState4 = _slicedToArray(_useState3, 2),
        loading = _useState4[0],
        setLoading = _useState4[1];

    var _useTranslation = (0, _reactI18next.useTranslation)(),
        t = _useTranslation.t;

    //effect hooks


    (0, _react.useEffect)(function () {
        onSaveProgram('empty');
        setDataBoxes();
    }, []);

    (0, _react.useEffect)(function () {
        setDataBoxes();
    }, [schoolClass.id]);

    (0, _react.useEffect)(function () {
        setDataBoxes();
    }, [storedProgramId]);

    (0, _react.useEffect)(function () {
        setDataBoxes();
    }, [program]);

    (0, _react.useEffect)(function () {
        if (isSave === 'teacherProgram') {
            onSave(data);
        }
        onSaveProgram('empty');
    }, [isSave]);

    (0, _react.useEffect)(function () {
        if (data.length) setLoading(false);
        if (data && data.length && !loading) {
            onSave(data);
        }
    }, [data]);

    (0, _react.useEffect)(function () {
        if (!loading) {
            onSave(data);
        }
    }, [mileStones]);

    //functions
    var setDataBoxes = function setDataBoxes() {
        if (program && program.boxes) {
            setData(program.boxes);
        }
    };

    var handleClick = function handleClick(data) {
        if (props.onClick) props.onClick(data);
    };

    var handleSetItems = function handleSetItems(_items) {
        setData([].concat(_toConsumableArray(_items)));
    };

    return _react2.default.createElement(
        'div',
        { className: classes.root },
        _react2.default.createElement(
            _core.Dropable,
            { custom: true, items: data, onSetItems: handleSetItems },
            function (provided) {
                return _react2.default.createElement(
                    _react2.default.Fragment,
                    null,
                    data && data.map(function (boxContainer, i) {
                        return _react2.default.createElement(
                            _reactBeautifulDnd.Draggable,
                            {
                                draggableId: boxContainer[0].id,
                                index: i,
                                key: boxContainer[0].id
                            },
                            function (provided) {
                                return _react2.default.createElement(
                                    'div',
                                    _extends({
                                        className: classes.boxCardRoot,
                                        ref: provided.innerRef
                                    }, provided.draggableProps, provided.dragHandleProps),
                                    _react2.default.createElement(
                                        'div',
                                        {
                                            className: (0, _classnames2.default)(classes.mileStone, _defineProperty({}, classes.activeMileStone, mileStones[boxContainer[0].id] && mileStones[boxContainer[0].id].text)),
                                            onClick: function onClick() {
                                                return handleClick(boxContainer);
                                            }
                                        },
                                        _react2.default.createElement(
                                            'span',
                                            { className: classes.mileStoneText },
                                            mileStones[boxContainer[0].id] ? mileStones[boxContainer[0].id].text || t("app-core.program-order.add-period") : t("app-core.program-order.add-period")
                                        )
                                    ),
                                    boxContainer.length === 1 ? _react2.default.createElement(
                                        'div',
                                        { className: classes.wrapper },
                                        _react2.default.createElement(
                                            _appCore.SingleBoxCard,
                                            { data: boxContainer[0] },
                                            _react2.default.createElement(
                                                'div',
                                                { className: classes.move },
                                                _react2.default.createElement(_OpenWith2.default, null)
                                            )
                                        )
                                    ) : _react2.default.createElement(
                                        'div',
                                        { className: classes.smallWrapper },
                                        _react2.default.createElement(
                                            _appCore.MultiBoxCard,
                                            {
                                                disable: disable,
                                                data: boxContainer
                                            },
                                            _react2.default.createElement(
                                                'div',
                                                { className: classes.move },
                                                _react2.default.createElement(_OpenWith2.default, null)
                                            )
                                        )
                                    )
                                );
                            }
                        );
                    })
                );
            }
        )
    );
};

exports.default = (0, _recompose.compose)((0, _styles.withStyles)(_styles2.styles, { name: 'appProgramOrder' }), (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps))(ProgramOrder);