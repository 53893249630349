"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _AccountWrapper = require("../AccountWrapper");

var _AccountWrapper2 = _interopRequireDefault(_AccountWrapper);

var _parts = require("./parts");

var _graphql = require("./graphql");

var _reactHooks = require("@apollo/react-hooks");

var _appCore = require("@manakin/app-core");

var _lib = require("@manakin/app-core/lib");

var _reactI18next = require("react-i18next");

var _selectors = require("@manakin/authentication/selectors");

var _DemoAccountNoAccessDialog = require("../DemoAccountNoAccessDialog/DemoAccountNoAccessDialog");

var _DemoAccountNoAccessDialog2 = _interopRequireDefault(_DemoAccountNoAccessDialog);

var _reactRedux = require("react-redux");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var BPVRapport = function BPVRapport(props) {
	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var appUser = (0, _reactRedux.useSelector)(function (state) {
		return (0, _selectors.getAppUser)(state);
	});

	var _useState = (0, _react.useState)(false),
	    _useState2 = _slicedToArray(_useState, 2),
	    showDemoAccountNoAccessDialog = _useState2[0],
	    setShowDemoAccountNoAccessDialog = _useState2[1];

	var _useQuery = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_TRAINERS),
	    trainersData = _useQuery.data,
	    trainersLoading = _useQuery.loading;

	var _ref = trainersData || {},
	    _ref$invitationTraine = _ref.invitationTrainers,
	    invitationTrainers = _ref$invitationTraine === undefined ? [] : _ref$invitationTraine;

	var _useQuery2 = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_RATING_REQUESTS, {
		variables: {
			page: 0,
			pagesize: 99,
			filter: { finalRatings: true }
		}
	}),
	    data = _useQuery2.data,
	    loading = _useQuery2.loading;

	var _useQuery3 = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_RATING_REQUESTS, {
		variables: {
			page: 0,
			pagesize: 99,
			filter: { supervisorRatings: true }
		}
	}),
	    superRatingData = _useQuery3.data,
	    superRatingDataLoading = _useQuery3.loading;

	var _ref2 = data || {},
	    ratingRequests = _ref2.ratingRequests;

	var _ref3 = superRatingData || {},
	    _ref3$ratingRequests = _ref3.ratingRequests,
	    superRatingRequests = _ref3$ratingRequests === undefined ? [] : _ref3$ratingRequests;

	var BPVBTrainers = invitationTrainers.filter(function (i) {
		return i.roles.some(function (r) {
			return _lib.bpvRole.includes(r.name);
		});
	});

	var superObj = {};
	superRatingRequests.forEach(function (i) {
		superObj[i.element.id] = i;
	});

	(0, _react.useEffect)(function () {
		if (appUser && appUser.roles && appUser.roles.some(function (role) {
			return role.name === _lib.demoRole;
		})) {
			setShowDemoAccountNoAccessDialog(true);
		}
	}, []);

	if (loading || superRatingDataLoading || trainersLoading) return _react2.default.createElement(_appCore.Loader, { fullScreen: true });

	return _react2.default.createElement(
		_AccountWrapper2.default,
		{
			title: t("app.account.bpv-report.title"),
			introduction: t("app.account.bpv-report.title"),
			wrapper: "medium"
		},
		_react2.default.createElement(_parts.Program, {
			ratingRequests: ratingRequests,
			BPVBTrainers: BPVBTrainers,
			superObj: superObj
		}),
		_react2.default.createElement(_DemoAccountNoAccessDialog2.default, {
			open: showDemoAccountNoAccessDialog,
			onClose: function onClose() {
				return setShowDemoAccountNoAccessDialog(false);
			} })
	);
};

exports.default = BPVRapport;