import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PageLayout from '../PageLayout';
import { TextField, RadioField, SelectField, DatePicker } from '@manakin/core';
import moment from 'moment';

const styles = (theme) => ({
    wrapper: {
        ...theme.manakin.smallWrapper,
        maxWidth: '770px',
    },
    groupElements: {
        '@media (min-width: 700px)': {
            display: 'flex',
            justifyContent: 'space-between',
            '&>*': {
                flex: '0 0 auto',
                width: '300px',
            },
        },
    },
    paragraph: {
        textAlign: 'center',
        width: '100%',
        margin: '0 auto 3em',
        maxWidth: '560px',
    },
    heading: {},
});

const PersonalData = (props) => {
    const { classes, form } = props;

    //effect hooks
    useEffect(() => {
        props.onReady(true);
    }, []);

    return (
        <PageLayout
            title="Persoonlijke gegevens"
            intro="Vul hier je persoonlijke gegevens in. "
        >
            <div className={classes.wrapper}>
                <RadioField
                    form={form}
                    name="gender"
                    legendName="Geslacht"
                    color="primary"
                    options={[
                        { label: 'Man', value: 'MALE' },
                        { label: 'Vrouw', value: 'FEMALE' },
                        { label: 'Onbekend', value: 'UNKNOWN' },
                    ]}
                    initialValue={form.fields && form.fields.gender}
                />
                <div className={classes.groupElements}>
                    <TextField
                        form={form}
                        name="initials"
                        label="Voorletters"
                        shrink={false}
                        initialValue={form.fields && form.fields.initials}
                    />
                </div>
                <div className={classes.groupElements}>
                    <TextField
                        form={form}
                        name="firstName"
                        label="Voornaam"
                        shrink={false}
                        initialValue={form.fields && form.fields.firstName}
                    />
                    <TextField
                        form={form}
                        name="middleName"
                        label="Tussenvoegsel"
                        shrink={false}
                        initialValue={form.fields && form.fields.middleName}
                    />
                </div>
                <div className={classes.groupElements}>
                    <TextField
                        form={form}
                        name="lastName"
                        label="Achternaam"
                        shrink={false}
                        initialValue={form.fields && form.fields.lastName}
                    />
                    {/* <TextField
            form={form}
            name="dateOfBirth"
            label="Geboortedatum"
            placeholder="Notatie: dd - mm -jjjj"
            type="date"
            defaultValue="2000-01-01"
            initialValue={form.fields && form.fields.dateOfBirth}
          /> */}
                    <DatePicker
                        form={form}
                        name="dateOfBirth"
                        keyboardOnly
                        label="Geboortedatum"
                        initialValue={form.fields && form.fields.dateOfBirth}
                        defaultValue="2000-01-01"
                        maxDate={moment().format('YYYY-MM-DD')}
                    />
                </div>
            </div>
        </PageLayout>
    );
};

export default withStyles(styles, { name: 'AppRegistrationPersonalData' })(
    PersonalData
);
