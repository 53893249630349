import React, { useEffect, useState } from "react";
import { GQL_GET_INVITAION } from "./graphql";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { Loader } from "@manakin/app-core";
import Registration from "./Registration";
import InvitationWrapper from "./InvitationWrapper";
import Cookies from "js-cookie";
import Login from "./Login";
import { useStyles } from "./styles";
import { Typography } from "@material-ui/core";
import {useTranslation} from 'react-i18next';

const AppInvitation = (props) => {
	const classes = useStyles();
	const params = useParams();
	const { t } = useTranslation();

	// State
	const [login, setLogin] = useState(false);

	// Query
	const { data = {}, loading } = useQuery(GQL_GET_INVITAION, {
		variables: {
			token: params.token,
		},
	});

	// Effect hooks
	useEffect(() => {
		Cookies.remove("locale");
	}, []);

	// Functions
	const handleSwitch = () => {
		setLogin(!login);
	};

	if (loading) return <Loader fullScreen />;
	if (!loading && !data.invitationByToken)
		return (
			<InvitationWrapper>
				<div className={classes.root}>
					<div className={classes.container}>
						<Typography variant="h2">
							{t("authentication.invitation.failed")}
						</Typography>
						<Typography>
							{t("authentication.invitation.load-failed")}
						</Typography>
					</div>
				</div>
			</InvitationWrapper>
		);
	if (login) {
		return (
			<InvitationWrapper>
				<Login
					token={params.token}
					onSwitch={handleSwitch}
					data={data}
				/>
			</InvitationWrapper>
		);
	}
	return (
		<InvitationWrapper>
			<Registration
				token={params.token}
				data={data}
				onSwitch={handleSwitch}
			/>
		</InvitationWrapper>
	);
};

export default AppInvitation;
