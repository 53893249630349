import React from 'react';
import { FrontPage as FrontPageContainer } from '../DefaultPages';
import { ElementOverlay } from '@manakin/app-core';
import { useTranslation } from 'react-i18next';
import { GQL_FETCH_EXAM_TRAINER } from './graphql';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useHistory, useLocation, useParams } from 'react-router';
import { useViewCounting } from "@manakin/hooks";

const FrontPage = () => {
	const params = useParams();
	const { viewElement } = useViewCounting();

	const { data, loading } = useQuery(GQL_FETCH_EXAM_TRAINER, {
		variables: {
			id: params.elementId,
		},
		onCompleted: () => {
			if(data && !loading) {
				viewElement(params.elementId);
			}
		}
	});
	const { examTrainer = {} } = data || {};
	const history = useHistory();
	const location = useLocation();
	const { t } = useTranslation();

	const handleClick = () => {
		history.push(`${location.pathname}/trainer`);
	};

	return (
		<ElementOverlay title={t('common.element-types.exam-trainer')} fullWidth>
			<FrontPageContainer
				loading={loading}
				image={examTrainer.image || {}}
				data={examTrainer}
				onClick={handleClick}
				buttonLabel={t('app.elements.exam-trainer.start-quiz')}
			></FrontPageContainer>
		</ElementOverlay>
	);
};

export default FrontPage;
