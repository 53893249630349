import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from 'react-i18next';
import Grid from "@material-ui/core/Grid";
import classNames from "classnames";
import { useStyles } from './styles/index';
import { Link } from 'react-router-dom';

const ExternalLink = (props) => {
    const { data } = props;
    const classes = useStyles();
    const { t } = useTranslation();

	const handleClick = () => {
		window.open(data.buttonUrl, data.openInSameWindow ? '_self' : '_blank');
	}

	const handleMiddleClick = (event) => {
		if (event.button === 1) {
			window.open(data.buttonUrl, '_blank');
		}
	};

	return (
		<Grid item key={data.id}>
			<div className={classNames(classes.root,
                { 
					[classes.primary]: data.backgroundColor == 'PRIMARY',
                	[classes.secondary]: data.backgroundColor == 'SECONDARY',
                	[classes.noBG]: data.backgroundColor == 'NONE' })
				}>
				<div className={classes.image}>
					<img className={classes.imageSrc} src={data.image ? data.image.url : null} />
				</div>
				<div className={classes.content}>
					<div className={classes.textContent}>
						<Typography
							component="h4"
							variant="subtitle1"
							classes={{
								subtitle1: classes.title,
							}}
						>
							{data.title || t('app.workforms.external-link.title')}
						</Typography>
						<Typography component="h4" variant="body1" classes={{ body1: classes.body }}>
							{data.text || t('app.workforms.external-link.description')}
						</Typography>
					</div>
					<Button
						component={Link}
						variant="contained"
						color="primary"
						onMouseDown={handleMiddleClick}
						className={classes.button}
						onClick={handleClick}
					>
						{data.buttonText || t('app.workforms.external-link.button-text')}
					</Button>
				</div>
			</div>
		</Grid>
	);
};

export default ExternalLink;