import gql from 'graphql-tag';
import { appAssetWithThumbnail } from '@manakin/app-core/graphQlHelpers';

export const GQL_FETCH_NEWS_LIST = gql`
    query($filter: NewsListFilter, $pagesize: Int, $page: Int) {
        newsList(filter: $filter, pagesize: $pagesize, page: $page) {
            news {
                id
                title
                introText
                category
                factCards
                content
                image {
                    ${appAssetWithThumbnail}
                }
                lastModifiedOn
                createdOn
                tags {
                    id
                    name
                    visibleInApp
                    image {
                        id
                        url
                    }
                }
            }
            count
        }
    }
`;
