import gql from 'graphql-tag';
import { appAssetNoThumbnail, appAsset, fileAsset } from '@manakin/app-core/graphQlHelpers';

export const GQL_FETCH_FACT_CARD = gql`
    query factCard($id: ID!) {
        factCard(id: $id) {
            id
            title
            text
            tags {
                id
                name
                visibleInApp
                image {
                    ${appAssetNoThumbnail}
                }
            }
            image {
                ${appAsset}
            }
            backgroundImage {
                ${appAsset}
            }
            file {
                ${appAssetNoThumbnail}
            }
        }
    }
`;

export const GQL_FETCH_FACT_CARDS = gql`
    query factCards($page: Int!, $pagesize: Int!, $filter: FactCardFilter) {
        factCards(page: $page, pagesize: $pagesize, filter: $filter) {
            factCards {
                id
                title
                text
                tags {
                    id
                    name
                    visibleInApp
                    image {
                        ${appAssetNoThumbnail}
                    }
                }
                file {
                    id
                    url
                }
                image {
                    ${appAsset}
                }
                backgroundImage {
                    ${appAsset}
                }
            }
            count
        }
    }
`;

export const GQL_FETCH_BOX = gql`
	query box($id: ID!) {
		box(id: $id) {
			name
		}
	}
`;

export const GQL_FETCH_CURRENT_APP_USER = gql`
	query currentAppUser {
		currentAppUser {
			id
			licenses {
				validUntil
				code
				product {
					id
					name
					subProducts {
						id
						name
					}
				}
			}
		}
	}
`;

export const GQL_FETCH_GLOBAL_SETTINGS = gql`
	query settings($category: String!) {
		settings(filter: { category: $category }) {
			settings {
				name
				value
				index
			}
		}
	}
`;

export const GQL_FETCH_TAG_ORDER = gql`
	query settingTagOrder {
		setting(name: "tagOrder") {
			value
		}
	}
`;