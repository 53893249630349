let itemMargin = 13;

export const styles = (theme) => ({
    wrapper: {
        ...theme.manakin.defaultWrapper,
        [theme.breakpoints.up('md')]: {
            padding: 0,
        },
    },
    checked: {},
    showIcon: {},
    answersRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '-' + itemMargin + 'px',
    },
    card: {
        height: 0,
        margin: itemMargin + 'px',
        position: 'relative',
        zIndex: '1',
    },
    col2: {
        paddingBottom: `calc(50% - ${itemMargin * 2}px)`,
        flex: `0 1 calc(50% - ${itemMargin * 2}px)`,
    },
    col3: {
        paddingBottom: `calc(33.33% - ${itemMargin * 2}px)`,
        flex: `0 1 calc(33.33% - ${itemMargin * 2}px)`,
    },
    innerCard: {
        backgroundColor: theme.manakin.primaryColor,
        position: 'absolute',
        left: 0,
        top: 0,
        opacity: 1,
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        transition: 'opacity .2s',
        '&:hover': {
            '& img': {
                transform: 'scale(1.2)',
            },
        },
    },
    notActive: {
        opacity: '0.5',
    },
    active: {
        border: '2px solid black',
        padding: '10px',
        opacity: 1,
    },
    image: {
        width: '100%',
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        position: 'relative',
        transition: 'transform .2s',
        overflow: 'hidden',
        zIndex: '2',
        '& img': {
            transition: 'transform .2s!important',
        },
    },
    backgroundImage: {
        objectFit: 'cover',
        fontFamily: "'object-fit: cover'",
        width: '100%',
        height: '100%',
        backfaceVisibility: 'hidden',
    },
    iconRoot: {
        width: '1.3rem',
        height: '1.3rem',
    },
    feedback: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        zIndex: 1,
        transform: 'translateY(20px)',
        opacity: 0,
        transition: 'transform .3s, opacity .3s',
        pointerEvents: 'none',
        [theme.breakpoints.up('md')]: {
            position: 'relative',
            transition: 'transform .3s, opacity .3s',
        },
    },
    activeFeedback: {
        position: 'relative',
        transform: 'translateY(0)',
        height: 'auto',
        opacity: 1,
        pointerEvents: 'auto',
        [theme.breakpoints.up('md')]: {
            height: 'auto',
        },
    },
    buttons: {
        position: 'relative',
        marginTop: '2rem',
    },
    button: {
        '& span': {
            justifyContent: 'space-between',
            paddingLeft: '2rem',
            paddingRight: '2rem',
        },
    },
    longArrow: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    },
    hide: {
        display: 'none',
    },
    disabled: {
        opacity: 0.5,
        '&:hover': {
            '& img': {
                transform: 'scale(1)',
            },
        },
    },
});
