import React, { useContext } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import useStyles from '../styles';
import StepsContext from '../context/stepsContext';
import { Typography } from '@material-ui/core';

const StepperComponent = (props) => {
	const { steps = [] } = props;
	const { step } = useContext(StepsContext);
	const classes = useStyles();

	return (
		<Stepper classes={{ root: classes.stepperRoot }} activeStep={step} alternativeLabel>
			{steps.map((e, index) => (
				<Step classes={{ root: classes.stepRoot }} key={index}>
					<StepLabel>
						<Typography>{index}</Typography>
					</StepLabel>
				</Step>
			))}
		</Stepper>
	);
};

export default StepperComponent;
