import React from "react";
import { compose } from "recompose";
import { withForm } from "@manakin/core";
import { PersonalDataForm } from "./forms/PersonalDataForm";
import { SchoolDataForm } from "./forms/SchoolDataForm";
import { authDataFormStrong } from "./forms/AuthDataFormStrong";
import AppRegistrationView from "./AppRegistrationView";

const form = {
	...PersonalDataForm,
	...SchoolDataForm,
	...authDataFormStrong,
};

const PasswordView = (props) => {
	const { form } = props;

	return <AppRegistrationView form={form} />;
};

export default compose(withForm(form))(PasswordView);
