import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MaterialCard from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import MoreHorizontalIcon from '@material-ui/icons/MoreHoriz';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const styles = {
    cardContainer: {
        border: '1px solid #e7e8ee',
        backgroundColor: 'white',
        borderRadius: 10,
        height: '100%',
    },
    card: {
        backgroundSize: 'cover',
        borderRadius: 10,
        position: 'relative',
        height: '100%',
    },
    cardHeader: {
        paddingBottom: 0,
    },
    menuIcon: {
        color: '#242445',
    },
    menuItem: {
        width: 100,
    },
    cardContent: {
        paddingTop: 0,
        minHeight: 75,
        paddingBottom: '16px !important',
    },
};

/**
 * Element containing an element of a box
 */
class Card extends React.Component {
    state = {
        showMenu: null,
    };

    /**
     * Handle clicking on options menu toggle
     */
    handleClick = (event) => {
        this.setState({ showMenu: event.currentTarget });
    };

    /**
     * Handle closing options menu
     */
    handleClose = () => {
        this.setState({ showMenu: null });
    };

    /**
     * Menu options should have the following structure:
     * [
     *  {
     *      id: 0,
     *      label: "Option one"
     *  }
     * ]
     * @returns {*}
     */
    render() {
        const { showMenu } = this.state;
        const {
            classes,
            children,
            menuOptions,
            onMenuItemClick,
            style,
            title,
            isBigTitle,
        } = this.props;

        return (
            <div
                style={{
                    style,
                }}
                className={classes.cardContainer}
            >
                <MaterialCard className={classes.card}>
                    <CardHeader
                        className={classes.cardHeader}
                        action={
                            <div>
                                <IconButton
                                    onMouseDown={(event) =>
                                        event.stopPropagation()
                                    }
                                >
                                    <ImportExportIcon
                                        className={classes.menuIcon}
                                    />
                                </IconButton>
                                <IconButton
                                    aria-owns={showMenu ? 'options-menu' : null}
                                    aria-haspopup="true"
                                    onClick={(event) => this.handleClick(event)}
                                >
                                    <MoreHorizontalIcon
                                        className={classes.menuIcon}
                                    />
                                </IconButton>
                                <Menu
                                    id="options-menu"
                                    anchorEl={showMenu}
                                    open={Boolean(showMenu)}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    onClose={() => this.handleClose()}
                                >
                                    {menuOptions &&
                                        menuOptions.map((option) => {
                                            return (
                                                <MenuItem
                                                    className={classes.menuItem}
                                                    onClick={() => {
                                                        this.handleClose();
                                                        onMenuItemClick(
                                                            option.id
                                                        );
                                                    }}
                                                >
                                                    {option.label}
                                                </MenuItem>
                                            );
                                        })}
                                </Menu>
                            </div>
                        }
                        title={isBigTitle ? title : ''}
                        subheader={isBigTitle ? '' : title}
                    />
                    <CardContent className={classes.cardContent}>
                        {children}
                    </CardContent>
                </MaterialCard>
            </div>
        );
    }
}

export default withStyles(styles)(Card);
