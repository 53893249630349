const defaultState = {};

const LOAD_CONFIG = 'LOAD_CONFIG';

export default (state = defaultState, { type, payload }) => {
    switch (type) {
        case LOAD_CONFIG:
            return payload;
        default:
            return state;
    }
};

export const loadConfig = (config) => ({
    type: LOAD_CONFIG,
    payload: config,
});
