import React from "react";
import Block from "./Block";
import Grid from "@material-ui/core/Grid";
import RateRequestsBlock from "./RateRequestsBlock";
import { AskFeedbackBox } from "@manakin/app-workforms";
import {
	cardGridDimensions,
	largeCardGridDimensions,
	maxCardGridDimensions,
} from "./lib";
import { useDispatch } from "react-redux";
import { useStyles } from "../styles";
import { GQL_FETCH_FEEDBACK } from "../graphql/graphql";
import { useQuery } from "react-apollo";
import PlannerBlock from "./PlannerBlock";
import { useHistory } from "react-router-dom";
import { setBoxId } from "@manakin/app-core/actions";
import { useTranslation } from 'react-i18next';

const StudentView = (props) => {
	const { currentAppUser, program } = props;
	const { t } = useTranslation();
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();

	const { data } = useQuery(GQL_FETCH_FEEDBACK, {
		skip: program.id === undefined,
		variables: {
			filter: {
				programs: [ program.id ],
			},
		},
	});
	const { feedbacks: feedbacksContainer } = data || {};
	const { feedbacks = [] } = feedbacksContainer || {};

	const goToFeedback = () => {
		if (feedbacks && feedbacks.length > 0 && feedbacks[0]) {
			dispatch(setBoxId(feedbacks[0].box.id));
			const element = feedbacks[0].element;
			history.push(`/${element.type}/${element.id}`);
		}
	}

	return (
		<React.Fragment>
			<Grid item {...cardGridDimensions}>
				<Block
					title={t("app.progress-dashboard.student.rating-requests")}
				>
					<RateRequestsBlock
						currentAppUser={currentAppUser}
						program={program}
					/>
				</Block>
			</Grid>
			<Grid item {...largeCardGridDimensions}>
				<Block
					onClick={goToFeedback}
					title={t("app.progress-dashboard.student.ask-feedback")}
				>
					<AskFeedbackBox
						data={feedbacks[0] || { feedback: t("app.progress-dashboard.student.no-feedback"), }}
					/>
				</Block>
			</Grid>
			<Grid item {...maxCardGridDimensions}>
				<Block className={classes.plannerBlock}>
					<PlannerBlock student />
				</Block>
			</Grid>
		</React.Fragment>
	);
};

export default StudentView;
