import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { styles } from './styles';
import { GQL_FETCH_CMS_FACT_CARD } from './graphql';
import { useTranslation } from 'react-i18next';
import FactCardReferralCard from './FactCardReferralCard';
import Grid from '@material-ui/core/Grid';
import { useSnackBar } from '@manakin/hooks';
import { useApolloClient } from 'react-apollo';
import { useViewCounting } from "@manakin/hooks";

var FileSaver = require('file-saver');

const FactCardReferral = (props) => {
    const { classes, data, onFinished, withNext = false, customLink = false } = props;
    const { t } = useTranslation();
    const snackBar = useSnackBar();
    const history = useHistory();
    const client = useApolloClient();
    const [ factCards, setFactCards ] = useState([]);
    const { viewFactCard } = useViewCounting();

    useEffect(() => {
        if (data.referrals && data.referrals.length) {
            Promise.all(
                data.referrals.map((item) => client.query({
                    query: GQL_FETCH_CMS_FACT_CARD,
                    variables: { id: item.factCardId },
                })),
            ).then(all => {
                setFactCards(
                    all.reduce((accumulator, currentValue) => {
                        if (currentValue.data && currentValue.data.factCard) {
                            accumulator.push(currentValue.data.factCard);
                        }

                        return accumulator;
                    }, []),
                );
            });
        }
    }, []);

    const handleClick = (id, factCard) => {
        if (factCard.file && factCard.file.url) {
            viewFactCard(factCard.id);
            snackBar.success(t('app.workforms.fact-card-referral.download-progress'));
            FileSaver.saveAs(factCard.file.url, factCard.title);
        } else {
            if (!customLink) {
                history.push(`/factcard/${id}`);
            } else {
                history.push({
                    pathname: `/factcard/${id}`,
                    state: { ...customLink.state },
                });
            }
        }
    };

    const handleNext = () => {
        if (onFinished) onFinished();
    };

    if (factCards.length <= 0 || factCards[0] === null) return '';

    return (
        <div>
            <div className={classes.root}>
                <Grid container spacing={data && data.referrals && data.referrals.length === 2 ? 0 : 1}>
                    {data.referrals &&
                        factCards.length === data.referrals.length &&
                        data.referrals.map((item, idx) => (
                            <FactCardReferralCard
                                key={item.id}
                                item={item}
                                index={idx}
                                factCards={factCards}
                                onClick={handleClick}
                                backgroundColor={data.backgroundColor}
                            />
                        ))}
                </Grid>
            </div>
            {withNext && (
                <Button variant='contained' color='primary' className={classes.nextButton} onClick={handleNext}>
                    {t('common.next')}
                </Button>
            )}
        </div>
    );
};

export default withStyles(styles, { name: 'AppFactcardReferral' })(FactCardReferral);
