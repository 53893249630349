export const styles = (theme) => ({
    root: {
        position: 'relative',
        padding: '6rem 0 10rem',
        [theme.breakpoints.up('md')]: {
            padding: ' 0 0 4rem',
        },
        [theme.breakpoints.up('lg')]: {
            padding: 0,
        },
    },
    points: {
        position: 'absolute',
        bottom: '2rem',
        left: '2rem',
        [theme.breakpoints.up('lg')]: {
            bottom: '10rem',
            left: 'auto',
            right: '10rem',
        },
    },
});
