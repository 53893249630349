import gql from "graphql-tag";

export const GQL_FETCH_FEEDBACK = gql`
	query feedbacks($filter: feedbackFilter) {
		feedbacks(filter: $filter) {
			feedbacks {
				feedback
				respondent
				createdOn
				feedbackOn
				box {
					id
				}
				element {
					id
					type
				}
			}
		}
	}
`;

export const GQL_FETCH_SESSIONS = gql`
	query sessionDataPastYear {
		sessionDataPastYear {
			months {
				month
				sessionAverageDuration
				sessionCount
				sessionTotalValue
				year
			}
			totalSessionCount
		}
	}
`;

export const GQL_FETCH_RATING_PROGRESS = gql`
	query ratingProgress($programId: ID!) {
		ratingProgress(programId: $programId) {
			totalRatingElements
			totalRankedSufficient
			progress
		}
	}
`;

export const GQL_FETCH_PROGRAM = gql`
	query getProgram($programId: ID!) {
		program(id: $programId) {
			id
			name
			description
		}
	}
`;
export const GQL_FETCH_PROGRAMS_PROGRESS = gql`
	query programResults($programs: [ID!]!, $users: [ID!]!) {
		programResults(programs: $programs, users: $users) {
			id
			program {
				id
			}
			progress
		}
	}
`;

export const GQL_FETCH_SESSION_DURATION_AVERAGE = gql`
	query getAverageSessionDuration {
		sessionDataPastYear {
			sessionAverageDuration
		}
	}
`;

export const GQL_FETCH_INVITATION_STUDENT_IDS = gql`
	query getInvitationStudents {
		invitationStudents {
			id
		}
	}
`;

export const GQL_FETCH_RATING_REQUESTS = gql`
	query ratingRequestsTotal(
		$pageNr: Int!
		$pageSize: Int!
		$ratingRequestTotalFilter: RatingRequestFilter
	) {
		ratingRequests(
			pageNr: $pageNr
			pageSize: $pageSize
			filter: $ratingRequestTotalFilter
		) {
			id
			attempt
			finalRating
			box {
				id
			}
			result {
				id
			}
		}
	}
`;

export const GQL_FETCH_APP_USERS = gql`
    query appUsers(
        $page: Int!
        $pagesize: Int!
        $search: String
        $direction: String
        $sortProperties: [String]
        $filter: AppUserFilter
    ) {
        appUsers(
            page: $page
            pagesize: $pagesize
            search: $search
            direction: $direction
            sortProperties: $sortProperties
            filter: $filter
        ) {
            users {
                id
                firstName
                lastName
                fullName
                email
                group {
                    id
                    name
                }
            }
            count
        }
    }
`;