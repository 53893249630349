import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';

const styles = (theme) => ({
    root: {},
    card: {
        height: "100%",
        background: "white",
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(6),
        boxShadow: "none",
        display: "flex",
        flexDirection: "column",
        cursor: props => props.onClick ? "pointer" : ""
    },
    title: {
        fontWeight: "bold",
        marginBottom: props => props.subtitle ? 0 : theme.spacing(3),
        wordBreak: "break-word"
    },
    text: {
        color: theme.manakin.subHeaderColor[500],
        marginBottom: theme.spacing(3)
    },
    subHeader: {},
    contentColor: {}
});

const Block = (props) => {
    const { classes, className, title, subtitle, children, onClick } = props;

    return (
        <Paper className={classNames(classes.card, className)} onClick={onClick}>
            {title && <Typography
                component="p"
                variant="subtitle1"
                className={classNames(
                    classes.subHeader,
                    classes.contentColor,
                    classes.title
                )}>
                {title}
            </Typography>}

            {subtitle && <Typography
                component="p"
                variant="body2"
                className={classNames(
                    classes.subHeader,
                    classes.contentColor,
                    classes.text
                )}>
                {subtitle}
            </Typography>}
            {children}
        </Paper>
    );
};

export default compose(
    withStyles(styles, { name: 'AppProgressDashboardBlock' }),
    connect(({ config }) => ({ config }))
)(Block);
