"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require("react-redux");

var _selectors = require("@manakin/authentication/selectors");

var _lib = require("../lib");

var _reactHooks = require("@apollo/react-hooks");

var _graphql = require("./graphql");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AccessControl = function AccessControl(props) {
    var appUser = (0, _reactRedux.useSelector)(function (state) {
        return (0, _selectors.getAppUser)(state);
    });
    var _props$role = props.role,
        role = _props$role === undefined ? [] : _props$role,
        _props$not = props.not,
        notRoles = _props$not === undefined ? [] : _props$not,
        children = props.children,
        subRole = props.subRole;

    var _useState = (0, _react.useState)(false),
        _useState2 = _slicedToArray(_useState, 2),
        access = _useState2[0],
        setAccess = _useState2[1];

    var _useLazyQuery = (0, _reactHooks.useLazyQuery)(_graphql.GQL_FETCH_APP_ROLE, {
        onCompleted: function onCompleted(data) {
            var _data$appRole = data.appRole,
                appRole = _data$appRole === undefined ? {} : _data$appRole;

            if (appRole.privileges && appRole.privileges.length) {
                subRole && subRole.forEach(function (sub) {
                    if (appRole.privileges.some(function (item) {
                        return item.id === sub;
                    })) setAccess(true);
                });
            }
        }
    }),
        _useLazyQuery2 = _slicedToArray(_useLazyQuery, 1),
        getAppRole = _useLazyQuery2[0];

    (0, _react.useEffect)(function () {
        var roles = appUser.roles;

        var hasAccess = false;

        // Specific roles are only allowed access
        if (roles && roles.length && role.length) {
            roles.forEach(function (item) {
                if ((0, _lib.isInArray)(item.name, role)) hasAccess = true;
            });
        }

        // Specific roles are not allowed access
        if (roles && notRoles.length) {
            hasAccess = true;
            roles.forEach(function (item) {
                if ((0, _lib.isInArray)(item.name, notRoles)) {
                    hasAccess = false;
                }
            });
        }

        // No roles found to exclude
        if (roles === undefined && notRoles.length) {
            hasAccess = true;
        }

        if (subRole && subRole.length) {
            if (roles && roles.length) {
                hasAccess = false;
                roles.forEach(function (item) {
                    getAppRole({ variables: { id: item.id } });
                });
            }
        }

        setAccess(hasAccess);
    }, []);

    return _react2.default.createElement(
        _react2.default.Fragment,
        null,
        access && _react2.default.createElement(
            _react2.default.Fragment,
            null,
            children
        )
    );
};

exports.default = AccessControl;