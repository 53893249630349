import React, { useEffect, useReducer } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { FilterSelectField } from '@manakin/app-core';
import { GQL_FETCH_GLOBAL_SETTINGS } from '../graphql';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { SettingsKeys } from '@manakin/core/lib/constants';

const useStyles = makeStyles((theme) => ({
	filters: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			maxWidth: '800px',
			marginBottom: '80px',
		},
	},
	filter: {
		width: '100%',
		padding: '0 30px',
	},
}));

const Filters = (props) => {
	const { onFilter, initialFilter } = props;
	const classes = useStyles();
	const { t } = useTranslation();

	const [ filter, setFilter ] = useReducer((state, action) => ({
		...(state || {}),
		[action.name]: [ ...action.filter ],
	}), false);

	const { data = {}, loading } = useQuery(GQL_FETCH_GLOBAL_SETTINGS, {
		variables: {
			category: 'SETTINGS_CATEGORY_FACT_CARD_SETTINGS',
		},
	});
	const { settings = [] } = data ? data.settings || {} : {};
	const settingsObject = settings.length && settings.reduce((obj, setting) => ({ ...obj, [setting.name]: setting }), {});

	const getOptions = (setting) => {
		if (setting && setting.value && setting.value !== 'null') {
			return JSON.parse(setting.value).map((i) => ({
				id: i.value,
				label: i.name || i.label,
			})) || [];
		}

		return [];
	};

	const getLabel = (name) => {
		let possibleTitle = null;
		if (name === SettingsKeys.FACT_CARD_FILTER_CATEGORIES) {
			possibleTitle = settingsObject[SettingsKeys.FACT_CARD_FILTER_NAME_CATEGORIES];
		} else if (name === SettingsKeys.FACT_CARD_FILTER_TAGS) {
			possibleTitle = settingsObject[SettingsKeys.FACT_CARD_FILTER_NAME_TAGES];
		}

		return possibleTitle ? possibleTitle.value : t(`app.fact-card.${name}`);
	};

	const handleChange = (selected, name) => {
		const newFilter = [ ...selected.map((i) => i.id, []) ];
		setFilter({
			name: name,
			filter: newFilter,
		});
	};

	useEffect(() => {
		if (filter) {
			onFilter(filter);
		}
	}, [filter]);

	if (loading) return <div />;

	const categoriesFilter = settingsObject[SettingsKeys.FACT_CARD_FILTER_CATEGORIES] || {};
	const tagsFilter = settingsObject[SettingsKeys.FACT_CARD_FILTER_TAGS] || {};

	return (
		<div className={classes.filters}>
			{getOptions(categoriesFilter).length > 0 && (
				<div className={classes.filter}>
					<FilterSelectField
						initialValue={initialFilter || []}
						label={getLabel(categoriesFilter.name)}
						name={categoriesFilter.name}
						onChange={handleChange}
						options={getOptions(categoriesFilter)}
					/>
				</div>
			)}

			{getOptions(tagsFilter).length > 0 && (
				<div className={classes.filter}>
					<FilterSelectField
						initialValue={initialFilter || []}
						label={getLabel(tagsFilter.name)}
						name={tagsFilter.name}
						onChange={handleChange}
						options={getOptions(tagsFilter)}
					/>
				</div>
			)}
		</div>
	);
};

export default Filters;
