'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = exports.styles = function styles(theme) {
	var _button, _feedback, _columns;

	return {
		questionsRoot: {
			border: '1px solid ' + theme.manakin.defaultBorderColor[500],
			position: 'relative'
		},
		questions: {
			backgroundColor: theme.manakin.secondaryWorkformColor[500],
			position: 'relative'
		},
		progress: {
			fontFamily: theme.manakin.defaultTitleFont,
			backgroundColor: 'white',
			position: 'absolute',
			top: 0,
			right: 0,
			width: '8rem',
			height: '8rem',
			zIndex: 2,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			fontSize: '1.8rem',
			lineHeight: '2rem',
			fontWeight: 'bold'
		},
		question: _defineProperty({
			opacity: '0',
			transform: 'translateX(20%)',
			transition: 'opacity .3s, transform .3s',
			width: '100%',
			position: 'absolute',
			left: 0,
			top: 0,
			minHeight: '30rem',
			paddingLeft: theme.manakin.defaultPadding,
			paddingRight: theme.manakin.defaultPadding,
			paddingTop: '8.6rem',
			paddingBottom: '2rem',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		}, theme.breakpoints.up('md'), {
			paddingTop: '10rem',
			paddingBottom: '10rem'
		}),
		visible: {
			top: '0',
			opacity: '1',
			transform: 'translateX(0)',
			position: 'relative'
		},
		prev: {
			opacity: '0',
			transform: 'translateX(-20%)'
		},
		topContent: _defineProperty({
			position: 'relative'
		}, theme.breakpoints.up('md'), {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		}),
		backgroundImage: {
			width: '100%',
			height: '100%',
			position: 'absolute',
			left: 0,
			top: 0,
			objectFit: 'cover',
			objectPosition: 'center',
			fontFamily: "'object-fit: cover'"
		},
		image: {
			position: 'absolute'
		},
		questionContent: _defineProperty({
			fontFamily: theme.manakin.secondaryTitleFont,
			fontSize: '4.5rem',
			lineHeight: '6rem',
			color: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
			overflowWrap: 'anywhere'
		}, theme.breakpoints.up('md'), {
			fontSize: '2.2rem',
			lineHeight: '4rem',
			maxWidth: '32rem'
		}),
		controls: _defineProperty({
			paddingTop: '5rem',
			paddingLeft: theme.manakin.defaultPadding,
			paddingRight: theme.manakin.defaultPadding,
			backgroundColor: 'white',
			paddingBottom: '5rem'
		}, theme.breakpoints.up('md'), {
			backgroundColor: 'white',
			padding: '5rem',
			display: 'flex'
		}),
		button: (_button = {
			marginBottom: '1.2rem'
		}, _defineProperty(_button, theme.breakpoints.up('md'), {
			marginBottom: 0,
			marginRight: '2.5rem'
			// padding: '36px 0',
			// borderRadius: '5rem',e
		}), _defineProperty(_button, '&:nth-child(even)', _defineProperty({}, theme.breakpoints.up('md'), {
			marginRight: '0rem',
			marginLeft: '2.5rem'
		})), _button),
		feedback: (_feedback = {
			border: '1px solid ' + theme.manakin.defaultBorderColor[500],
			backgroundColor: 'white',
			width: '100%',
			minHeight: '0',
			top: 0,
			left: 0,
			zIndex: 99,
			height: 0,
			transform: 'translateY(0)',
			opacity: 0,
			position: 'absolute',
			transition: 'transform .3s, opacity .3s',
			display: 'flex',
			justifyContent: 'flex-start',
			flexDirection: 'column',
			paddingTop: theme.manakin.defaultPadding,
			paddingBottom: theme.manakin.defaultPadding,
			paddingLeft: theme.manakin.defaultPadding,
			paddingRight: theme.manakin.defaultPadding
		}, _defineProperty(_feedback, 'zIndex', '10'), _defineProperty(_feedback, 'overflow', 'hidden'), _defineProperty(_feedback, 'pointerEvents', 'none'), _defineProperty(_feedback, theme.breakpoints.up('md'), {
			paddingTop: '8rem',
			paddingBottom: '6rem',
			paddingLeft: '40px',
			paddingRight: '40px'
		}), _feedback),
		showFeedback: _defineProperty({
			height: 'auto',
			transform: 'translateY(-253px)',
			marginBottom: '-253px',
			opacity: 1,
			pointerEvents: 'visible',
			position: 'relative'
		}, theme.breakpoints.up('md'), {
			transform: 'translateY(-200px)',
			marginBottom: '-200px'
		}),
		feedbackIntro: _defineProperty({
			fontFamily: theme.manakin.secondaryTitleFont,
			fontWeight: '500',
			fontSize: '2rem',
			lineHeight: '4rem',
			marginBottom: '4rem',
			width: '100%'
		}, theme.breakpoints.up('md'), {
			fontSize: '2.4rem'
		}),
		columns: (_columns = {
			display: 'flex',
			justifyContent: 'space-between',
			maxWidth: '550px',
			flexDirection: 'column',
			width: '100%',
			flexWrap: 'wrap'
		}, _defineProperty(_columns, theme.breakpoints.up('md'), {
			flexDirection: 'row'
		}), _defineProperty(_columns, '@global ul', {
			paddingLeft: '2.25rem'
		}), _columns),
		columnLeft: {
			paddingRight: '1rem',
			width: '50%',
			minWidth: '27rem'
		},
		columnRight: _defineProperty({
			paddingLeft: '0rem',
			width: '50%',
			minWidth: '27rem'
		}, theme.breakpoints.up('md'), {
			paddingLeft: '1rem'
		}),
		columnItem: {
			fontFamily: theme.manakin.defaultAnswerFont,
			fontSize: '1.8rem',
			lineHeight: '3.6rem'
		},
		list: {
			marginBottom: '3rem'
		},
		title: {
			fontFamily: theme.manakin.defaultTitleFont,
			fontSize: '1.4rem',
			lineHeight: '3.2rem',
			letterSpacing: '.4rem',
			paddingBottom: '2rem',
			position: 'relative',
			marginBottom: '1.8rem',
			'&:after': {
				content: '""',
				position: 'absolute',
				width: '250px',
				height: '1px',
				bottom: '0',
				left: '0',
				backgroundColor: 'rgba(0, 0, 0, 0.2)'
			}
		},
		feedbackButton: {
			width: 'auto',
			alignSelf: 'flex-start'
		}
	};
};