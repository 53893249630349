import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import renderHTML from 'react-render-html';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import { useReadSpeaker } from "@manakin/hooks";
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
    wrapper: {
        ...theme.manakin.smallWrapper,
        maxWidth: '800px',
    },
    content: {
        margin: 0,
        color: theme.manakin.defaultContentColor[500],
        '@global ul': {
            paddingLeft: '2.25rem',
        },
    },
    lightContent: {
        '& $content': {
            color: 'white',
        },
    },
    button: {
        marginTop: '3rem',
    },
});

const ShortText = (props) => {
    const {
        classes,
        data,
        contentStyle = 'default',
        withNext = false,
    } = props;
    const { t } = useTranslation();
    const { readSpeaker, readSpeakerId } = useReadSpeaker("readSpeaker-shortText-" + data.id);

    const handleNext = () => {
        if (props.onFinished) props.onFinished();
    };

    return (
        <div
            className={classNames(classes.root, {
                [classes.lightContent]: contentStyle === 'light',
            })}
        >
            <div className={classes.wrapper} id={readSpeakerId}>
                {readSpeaker && renderHTML(readSpeaker)}
                <Typography
                    component="div"
                    variant="body1"
                    classes={{ body1: classes.content }}
                >
                    {renderHTML(data.text || '')}
                </Typography>
                {withNext && (
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={handleNext}
                    >
                        {t("common.next")}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default compose(
    withStyles(styles, { name: 'AppShortText' })
)(ShortText);
