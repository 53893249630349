import React from 'react';
import { Loader, LoadImage } from '@manakin/app-core';
import { useStyles } from './styles';
import Content from './Content';

const FrontPage = (props) => {
	const { loading, image = {}, data, buttonLabel, onClick } = props;
	const classes = useStyles();

	if (loading) return <Loader />;
	return (
		<div className={classes.root}>
			<div className={classes.inner}>
				<LoadImage src={image.url || ''} placeholder={image.thumbnailUrl || ''} withOverlay={true} />
				<Content data={data} buttonLabel={buttonLabel} onClick={onClick} />
			</div>
		</div>
	);
};

export default FrontPage;
