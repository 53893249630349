import gql from 'graphql-tag';

export const GQL_FETCH_CURRENT_USER_PRODUCTS = gql`
    query getCurrentUserProducts {
        currentAppUser {
            licenses {
                product {
                    id
                    name
                    program {
                        id
                    }
                    colophon {
                        name
                        chapters {
                            id
                            title
                            credits {
                                id
                                header
                                text
                                logo {
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;