'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.GQL_VIEW_FACT_CARD = exports.GQL_VIEW_ELEMENT = undefined;

var _templateObject = _taggedTemplateLiteral(['\n    mutation viewElement($elementId: ID!) {\n        viewElement(elementId: $elementId) {\n            totalViews\n        }\n    }\n'], ['\n    mutation viewElement($elementId: ID!) {\n        viewElement(elementId: $elementId) {\n            totalViews\n        }\n    }\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n    mutation viewFactCard($factCardId: ID!){\n        viewFactCard(factCardId: $factCardId){\n            factCardView {\n                id\n            }\n        }\n    }\n'], ['\n    mutation viewFactCard($factCardId: ID!){\n        viewFactCard(factCardId: $factCardId){\n            factCardView {\n                id\n            }\n        }\n    }\n']);

var _graphqlTag = require('graphql-tag');

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_VIEW_ELEMENT = exports.GQL_VIEW_ELEMENT = (0, _graphqlTag2.default)(_templateObject);

var GQL_VIEW_FACT_CARD = exports.GQL_VIEW_FACT_CARD = (0, _graphqlTag2.default)(_templateObject2);