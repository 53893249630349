import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
    (theme) =>
        createStyles({
            root: {
                width: '100%',
                minHeight: '100%',
            },
            smallWrapper: {
                ...theme.manakin.smallWrapper,
                paddingBottom: '100px',
                zIndex: 1,
            },
            heading: {},
        }),
    {
        name: 'AppRegistrationScreen',
    }
);

const InvitationWrapper = (props) => {
    const { children } = props;
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.smallWrapper}>{children}</div>
        </div>
    );
};

export default InvitationWrapper;
