import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { graphql, useMutation } from 'react-apollo';
import { compose } from 'recompose';
import { TransitionRouter, withWorkforms, Loader } from '@manakin/app-core';
import { GQL_FETCH_FILM, GQL_FETCH_GLOBAL_SETTINGS } from './graphql';
import { Route, withRouter } from 'react-router-dom';
import { DefaultFrontPage } from '../DefaultPages';
import Questions from './Questions';
import { connect } from 'react-redux';
import { changeColor } from '@manakin/app-core/ColorService/actions';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useViewCounting } from "@manakin/hooks";

const FILM_ROOT_PATH = 'Film';

const styles = (theme) => ({});

const FilmContainer = (props) => {
    const { data, workformsData, classes, changeColor, config = {} } = props;
    const { general = {} } = config;
    const { loading = true, film = {} } = data;
    const location = useLocation();
    const { t } = useTranslation();
    const { viewElement } = useViewCounting();

    //state hooks
    const [factCards, setFactCards] = useState([]);
    const [isFinished, setIsFinished] = useState(false);

    //Effect hooks
    useEffect(() => {
        if (!loading) {
            window.scrollTo(0, 0);
            const options = {
                showFeedback: true,
                nextButton: true,
            };
            if (film.workforms) {
                const elementId = props.match.params.elementId;

                changeColor({
                    ...film.styleSheet,
                    location: location.pathname,
                });
                workformsData.loadWorkforms({
                    ...film,
                    options: options,
                    elementId: elementId,
                });
                workformsData.loadElementResult(elementId);

                viewElement(elementId);
            }
        }
    }, [props.data.loading]);

    useEffect(() => {
        if (!workformsData.loading) {
            if (
                workformsData.elementResult &&
                workformsData.elementResult.finished &&
                workformsData.elementResult.correct &&
                general.showScenesButton
            ) {
                setIsFinished(true);
            }
            if (
                workformsData.rawData &&
                workformsData.rawData.factCards &&
                workformsData.rawData.factCards.length
            ) {
                setFactCards([...workformsData.rawData.factCards]);
            }
        }
    }, [workformsData]);

    //functions
    const handleClick = () => {
        props.history.push(`${props.match.url}/questions`);
    };

    const handleFinish = (workforms) => {
        workformsData
            .saveElement({
                elementId: props.match.params.elementId,
                finished: true,
                correct: true,
            })
            .then(() => {
                props.history.push(`${props.match.url}/outro`);
            });
    };

    const handleScenesClick = () => {
        props.history.push(`${props.match.url}/outro`);
    };

    if (loading || workformsData.loading) {
        return (
            <div className={classes.loading}>
                <Loader fullScreen={true} />
            </div>
        );
    }
    return (
        <React.Fragment>
            <TransitionRouter>
                <Route
                    exact
                    path={`/${FILM_ROOT_PATH}/:elementId`}
                    render={() => (
                        <DefaultFrontPage
                            playButton={true}
                            outroButton={isFinished}
                            scenesText={t("app.elements.masterclass.view-scenes")}
                            onScenesClick={handleScenesClick}
                            {...film}
                            factCards={factCards}
                            onClick={handleClick}
                        />
                    )}
                />
                <Route
                    exact
                    path={`/${FILM_ROOT_PATH}/:elementId/questions`}
                    render={() => (
                        <Questions
                            {...workformsData}
                            onFinish={handleFinish}
                            background={workformsData.rawData.borderColor}
                        />
                    )}
                />
                <Route
                    exact
                    path={`/${FILM_ROOT_PATH}/:elementId/outro`}
                    render={() => (
                        <DefaultFrontPage
                            buttonText={t("common.replay")}
                            {...film}
                            factCards={factCards}
                            onClick={handleClick}
                            scenes={workformsData.workforms}
                        />
                    )}
                />
            </TransitionRouter>
        </React.Fragment>
    );
};

export default compose(
    connect(
        (state) => ({ config: state.config }),
        (dispatch) => ({
            changeColor: (styleSheet) => dispatch(changeColor(styleSheet)),
        })
    ),
    graphql(GQL_FETCH_FILM, {
        options: (props) => ({
            variables: { id: props.match.params.elementId },
        }),
    }),
    graphql(GQL_FETCH_GLOBAL_SETTINGS, {
        name: 'SETTINGS',
    }),
    withRouter,
    withWorkforms(),
    withStyles(styles)
)(FilmContainer);
