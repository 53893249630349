import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
	(theme) =>
		createStyles({
			icon: {},
			body: {},
			root: {
				'& $body': {
					maxWidth: '700px',
					margin: '0 auto',
				},
			},
			innerRoot: {
				height: 'auto',
				minHeight: '100vh',
				width: '100vw',
				backgroundSize: 'cover',
				opacity: 0,
				animation: 'fadeIn .3s ease 1s forwards',
				position: 'relative',
			},
			button: {
				minWidth: '21rem',
				marginTop: '3rem'
			},
			wrapper: {
				...theme.manakin.largeWrapper,
				textAlign: 'center',
				height: '100vh'
			},
			loader: {
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
			},
			content: {
				opacity: 0,
				animation: 'show .3s ease 1.4s forwards',
				height: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				flexDirection: 'column'
			},
			body1: {
				marginBottom: '3.7rem',
			},
			title: {
				display: 'flex',
				justifyContent: 'center',
			},
			primaryTitleColor: {
				color: theme.manakin.primaryBoxColor[400],
			},
			secondaryTitleColor: {
				color: theme.manakin.secondaryBoxColor[400],
			},
			titleRoot: {
				marginBottom: '2.3rem',
				[theme.breakpoints.down('xs')]: {
					fontSize: '3rem',
					lineHeight: '4rem',
					letterSpacing: '.4rem',
				},
			},
			counter: {
				fontFamily: theme.manakin.secondaryTitleFont,
				fontSize: '2rem',
				lineHeight: '3.6rem',
			},
			bold: {
				fontFamily: theme.manakin.defaultContentFont,
				fonWeight: 'bold',
			},
			topScores: {
				cursor: 'pointer',
				margin: 0,
				position: 'absolute',
				left: '50%',
				bottom: '4.4rem',
				transform: 'translateX(-50%)',
				fontWeight: 'bold',
				display: 'flex',
				alignItems: 'center',
				fontSize: '1.5rem',
				'& $icon': {
					marginRight: '2rem',
					transform: 'translateY(0)',
					transition: 'transform .3s',
				},
				'&:hover': {
					'& $icon': {
						transform: 'translateY(1rem)',
					},
				},
				[theme.breakpoints.up('md')]: {
					fontSize: '1.8rem',
				},
			},
			selectField: {
				width: '100%',

				[theme.breakpoints.up('md')]: {
					width: '500px'
				},
			}
		}),
	{ name: 'AppFrontPage' }
);
