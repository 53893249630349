"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.selectProgram = selectProgram;
var SELECT_PROGRAM = exports.SELECT_PROGRAM = "@manakin/app-core/SELECT_PROGRAM";
var SELECT_CLASS = exports.SELECT_CLASS = "@manakin/app-core/SELECT_CLASS";

function selectProgram() {
	var program = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

	return {
		type: SELECT_PROGRAM,
		payload: program
	};
}