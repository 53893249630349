import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import MoveIcon from "@manakin/core/icons/Move";
import CardContent from "@material-ui/core/CardContent";
import classNames from "classnames";
import ReactHtmlParser from "react-html-parser";
import Typography from "@material-ui/core/Typography";
import RedirectIcon from "@manakin/core/icons/Redirect";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@manakin/core/icons/Menu";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withTranslation } from "react-i18next";

const styles = (theme) => ({
	card: {
		display: "flex",
		height: "120px",
		border: "1px solid",
		borderColor: theme.palette.grey["400"],
		borderRadius: "10px",
		boxShadow: "none",
		position: "relative",
		cursor: "pointer",
	},
	cardContentContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
	},
	thumbnail: {
		width: "120px",
		minWidth: "120px",
		height: "100%",
		objectFit: "cover",
		fontFamily: "'object-fit: cover'",
		backgroundColor: theme.palette.grey["100"],
	},
	cardContainerChild: {
		marginBottom: "5px",
	},
	cardHeader: {
		paddingBottom: 0,
		fontSize: "16px",
		marginBottom: ".2rem",
	},
	cardContent: {
		paddingTop: 0,
	},
	cardDrag: {
		opacity: 0.5,
	},
	cardText: {
		color: theme.manakin.defaultTextColor["500"],
		fontSize: "16px",
	},
	cardTitle: {
		overflow: "hidden",
		whiteSpace: "nowrap",
		marginBottom: ".2rem",
	},
	cardDescription: {
		fontFamily: theme.manakin.primaryFont,
		lineHeight: "19px",
		maxHeight: "38px",
		overflow: "hidden",
	},
	icons: {
		display: "flex",
		position: "absolute",
		top: "10px",
		right: "10px",
	},
	moveButtonContainer: {
		display: "inline-flex",
		flex: "0 0 auto",
		width: 48,
		color: "rgba(0, 0, 0, 0.54)",
		height: 48,
		padding: 0,
		fontSize: "1.5rem",
		textAlign: "center",
		transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
		borderRadius: "50%",
		justifyContent: "center",
		alignItems: "center",
		cursor: "move",
	},
	moveButton: {
		display: "flex",
	},
	cardRoot: {
		width: "100%",
		marginBottom: "24px",
	},
	small: {
		width: "calc(50% - 10px)",
		display: "inline-block",
		margin: "0 5px 24px",
	},
	switch: {
		position: "absolute",
		bottom: "-20px",
		right: "10px",
	},
});

const ElementCard = (props) => {
	const {
		provided,
		item,
		index,
		snapshot,
		subHeader = "",
		classes,
		readOnly = false,
		small = false,
		useSwitch = false,
		switchLabel = "",
		config = {},
		t
	} = props;
	const { general = {} } = config;
	const [anchorEl, setAnchorEl] = useState(null);

	const handleItemClick = () => {
		const { onItemClicked, item } = props;
		if (onItemClicked) onItemClicked(item.id);
	};

	const handleRemove = () => {
		setAnchorEl(null);
		props.onRemove(index);
	};

	const handleSwitch = (e, i) => {
		props.onSwitch && props.onSwitch(e, index);
	};

	return (
		<Draggable draggableId={item.id} index={index}>
			{(provided, snapshot) => (
				<div
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					className={classNames(classes.cardRoot, {
						[classes.small]: small,
					})}
				>
					<div
						className={classNames({
							[classes.cardDrag]: snapshot.isDragging,
						})}
					>
						<Card className={classNames(classes.card)}>
							{!general.hideDummy && (
								<img
									className={classes.thumbnail}
									src={
										item.backgroundImage || item.image
											? item.backgroundImage.url ||
											  item.image.url
											: "/cms/images/dummy-image.png"
									}
								/>
							)}
							{general.hideDummy && (
								<React.Fragment>
									{item.backgroundImage || item.image ? (
										<img
											className={classes.thumbnail}
											src={
												item.backgroundImage
													? item.backgroundImage.url
													: item.image.url
											}
										/>
									) : (
										<div className={classes.thumbnail} />
									)}
								</React.Fragment>
							)}
							<div className={classes.cardContentContainer}>
								<CardHeader
									className={classes.cardHeader}
									action={
										<div className={classes.icons}>
											<IconButton
												onClick={handleItemClick}
											>
												<RedirectIcon />
											</IconButton>
											{!readOnly && (
												<div>
													<IconButton
														onClick={(event) =>
															setAnchorEl(event.currentTarget)
														}
														aria-owns={anchorEl ? "simple-menu" : null}
														aria-haspopup="true"
													>
														<MenuIcon />
													</IconButton>
													<Menu
														id="simple-menu"
														anchorEl={anchorEl}
														open={Boolean(anchorEl)}
														onClose={(event) => setAnchorEl(null)}
													>
														<MenuItem onClick={handleRemove}>
															{t("common.delete")}
														</MenuItem>
													</Menu>
												</div>
											)}
											{!readOnly && (
												<div className={classes.moveButtonContainer}>
													<div className={classes.moveButton}>
														<MoveIcon />
													</div>
												</div>
											)}
										</div>
									}
									subheader={subHeader}
								/>
								<CardContent className={classes.cardContent}>
									<Typography
										className={classes.cardTitle}
										variant="h5"
									>
										{item.title}
									</Typography>
									<div
										className={classNames(
											classes.cardText,
											classes.cardDescription
										)}
									>
										{ReactHtmlParser(item.text) || ""}
									</div>
									{useSwitch && (
										<div className={classes.switch}>
											<FormGroup
												row
												classes={{
													root: classes.formGroupRoot,
												}}
											>
												<FormControlLabel
													control={
														<Switch
															color="primary"
															disabled={readOnly}
															checked={item.switch || false}
															onChange={handleSwitch}
															value={index.toString()}
														/>
													}
													label={switchLabel}
												/>
											</FormGroup>
										</div>
									)}
								</CardContent>
							</div>
						</Card>
					</div>
				</div>
			)}
		</Draggable>
	);
};

export default compose(
	connect((state) => ({ config: state.config })),
	withTranslation(),
	withStyles(styles)
)(ElementCard);
