import tinycolor from 'tinycolor2';

export const multiplyColor = (rgb1, rgb2) => {
    rgb1.b = Math.floor((rgb1.b * rgb2.b) / 255);
    rgb1.g = Math.floor((rgb1.g * rgb2.g) / 255);
    rgb1.r = Math.floor((rgb1.r * rgb2.r) / 255);
    return tinycolor('rgb ' + rgb1.r + ' ' + rgb1.g + ' ' + rgb1.b);
};

export const createColor = (baseHexColor) => {
    const baseLight = tinycolor('#ffffff');
    const baseDark = multiplyColor(
        tinycolor(baseHexColor).toRgb(),
        tinycolor(baseHexColor).toRgb()
    );
    const baseTriad = tinycolor(baseHexColor).tetrad();

    const contrastText = tinycolor.mostReadable(baseHexColor, [], {
        includeFallbackColors: true,
    });

    return {
        50: tinycolor.mix(baseLight, baseHexColor, 12).toHexString(),
        100: tinycolor.mix(baseLight, baseHexColor, 30).toHexString(),
        200: tinycolor.mix(baseLight, baseHexColor, 50).toHexString(),
        300: tinycolor.mix(baseLight, baseHexColor, 70).toHexString(),
        400: tinycolor.mix(baseLight, baseHexColor, 85).toHexString(),
        500: tinycolor.mix(baseLight, baseHexColor, 100).toHexString(),
        600: tinycolor.mix(baseDark, baseHexColor, 87).toHexString(),
        700: tinycolor.mix(baseDark, baseHexColor, 70).toHexString(),
        800: tinycolor.mix(baseDark, baseHexColor, 54).toHexString(),
        900: tinycolor.mix(baseDark, baseHexColor, 25).toHexString(),
        A100: tinycolor
            .mix(baseDark, baseTriad[4], 15)
            .saturate(80)
            .lighten(65)
            .toHexString(),
        A200: tinycolor
            .mix(baseDark, baseTriad[4], 15)
            .saturate(80)
            .lighten(55)
            .toHexString(),
        A400: tinycolor
            .mix(baseDark, baseTriad[4], 15)
            .saturate(100)
            .lighten(45)
            .toHexString(),
        A700: tinycolor
            .mix(baseDark, baseTriad[4], 15)
            .saturate(100)
            .lighten(40)
            .toHexString(),
        contrastText: contrastText ? contrastText.toHexString() : '#000000',
        transparant: 'transparant'
    };
};
