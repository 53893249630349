'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var useGetConfig = function useGetConfig() {
    var cat = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'pages';
    var sCat = arguments[1];
    var key = arguments[2];
    var config = arguments[3];

    if (config[cat] && config[cat][sCat] && config[cat][sCat][key]) {
        return config[cat][sCat][key];
    }

    return false;
};

exports.default = useGetConfig;