"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _core = require("@material-ui/core");

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var useStyles = (0, _core.makeStyles)(function (theme) {
	var _root;

	return {
		root: (_root = {
			display: "grid",
			gridTemplateColumns: "auto 130px 180px 180px",
			alignItems: "center",
			"&>*:nth-child(3)": {
				justifySelf: "center"
			},
			"&>*:nth-child(4)": {
				justifySelf: "center"
			}
		}, _defineProperty(_root, theme.breakpoints.down("sm"), {
			gridTemplateColumns: "auto 100px 170px 170px"
		}), _defineProperty(_root, theme.breakpoints.down("xs"), {
			"&>*:nth-child(1)": {
				gridArea: "header"
			},
			"&>*:nth-child(2)": {
				gridArea: "child2"
			},
			"&>*:nth-child(3)": {
				gridArea: "child3"
			},
			"&>*:nth-child(4)": {
				gridArea: "child4"
			},

			gridTemplateColumns: "1fr auto 1fr",
			gridTemplateAreas: "\n                \"header header header\"\n                \". child2 .\"\n                \". child3 .\"\n                \". child4 .\"\n            "
		}), _root)
	};
});

var StudentRatingCardLayout = function StudentRatingCardLayout(props) {
	var children = props.children,
	    className = props.className;

	var classes = useStyles();
	return _react2.default.createElement(
		"div",
		{ className: (0, _classnames2.default)(classes.root, className) },
		children
	);
};

exports.default = StudentRatingCardLayout;