'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _core = require('@material-ui/core');

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _selectors = require('@manakin/authentication/selectors');

var _Replay = require('@material-ui/icons/Replay');

var _Replay2 = _interopRequireDefault(_Replay);

var _Close = require('@material-ui/icons/Close');

var _Close2 = _interopRequireDefault(_Close);

var _styles = require('@material-ui/core/styles');

var _reactI18next = require('react-i18next');

var _lib = require('@manakin/app-core/lib');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var useStyles = (0, _core.makeStyles)(function (theme) {
	var _redoButtons, _closeButtonRedo;

	return (0, _core.createStyles)({
		redoButtons: (_redoButtons = {
			width: '100%',
			display: 'flex',
			overflow: 'hidden'
		}, _defineProperty(_redoButtons, theme.breakpoints.up('md'), {
			height: '40rem',
			flexFlow: function flexFlow(props) {
				return props.reverse ? 'row-reverse' : '';
			}
		}), _defineProperty(_redoButtons, theme.breakpoints.down('md'), {
			flexDirection: 'column',
			flexFlow: function flexFlow(props) {
				return props.reverse ? 'column-reverse' : '';
			}
		}), _redoButtons),
		closeButtonRedoLabel: _defineProperty({
			fontSize: '2rem'
		}, theme.breakpoints.up('md'), {
			fontSize: '4rem'
		}),
		closeButtonRedo: (_closeButtonRedo = {
			width: '100%',
			height: '10rem',
			fontFamily: '' + theme.manakin.defaultTitleFont,
			fontSize: '2rem',
			alignItems: 'center',
			justifyContent: 'center',
			textAlign: 'center',
			textDecoration: 'none',
			transition: 'background-color .3s',
			cursor: 'pointer',
			position: 'relative',
			backgroundColor: theme.manakin.LessonButtonColor[500],
			color: theme.palette.getContrastText(theme.manakin.LessonButtonColor[500]),
			padding: theme.spacing(2)
		}, _defineProperty(_closeButtonRedo, theme.breakpoints.up('md'), {
			fontSize: '4rem',
			height: '40rem',
			fontWeight: '600'
		}), _defineProperty(_closeButtonRedo, '&:hover', {
			backgroundColor: theme.manakin.LessonButtonColor[700]
		}), _closeButtonRedo),
		closeButtonSecondary: {
			color: theme.palette.getContrastText(theme.manakin.LessonButtonColor[50]),
			backgroundColor: theme.manakin.LessonButtonColor[50],
			'&:hover': {
				backgroundColor: theme.manakin.LessonButtonColor[100],
				color: theme.palette.getContrastText(theme.manakin.LessonButtonColor[100])
			}
		},
		question: {
			position: 'relative',
			zIndex: '2'
		},
		iconContainer: {
			position: 'absolute',
			left: '50%',
			top: '50%',
			transform: 'translate(-50%, -50%)',
			zIndex: '1',
			display: 'none'
		},
		icon: _defineProperty({
			color: 'black',
			width: '7rem',
			height: '7rem'
		}, theme.breakpoints.up('md'), {
			width: '30rem',
			height: '30rem'
		}),
		secondaryIcon: {
			color: theme.manakin.indicationColor[500]
		},
		closeIcon: _defineProperty({
			width: '4.2rem',
			height: '4.2rem'
		}, theme.breakpoints.up('md'), {
			width: '18rem',
			height: '18rem'
		})
	});
}, {
	name: 'AppBottomButtons'
});

var BottomButtons = function BottomButtons(props) {
	var classes = useStyles(props);
	var theme = (0, _styles.useTheme)();
	var onClose = props.onClose,
	    onReset = props.onReset,
	    closeTitle = props.closeTitle,
	    redoTitle = props.redoTitle;

	var appUser = (0, _reactRedux.useSelector)(function (state) {
		return (0, _selectors.getAppUser)(state);
	});

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var isTrainer = appUser.roles.some(function (role) {
		return role.name === _lib.bpvRole || role.name === _lib.poRole;
	});

	return _react2.default.createElement(
		'div',
		{ className: classes.redoButtons },
		_react2.default.createElement(
			_core.Button,
			{
				variant: 'contained',
				color: 'primary',
				'aria-label': t("common.close"),
				className: classes.closeButtonRedo,
				classes: {
					label: classes.closeButtonRedoLabel
				},
				onClick: onClose
			},
			_react2.default.createElement(
				'div',
				{ className: classes.iconContainer },
				theme.manakin.closeIcon ? _react2.default.createElement('img', {
					alt: t("common.close"),
					src: theme.manakin.closeIcon,
					className: (0, _classnames2.default)(classes.icon, classes.secondaryIcon, classes.closeIcon)
				}) : _react2.default.createElement(_Close2.default, {
					className: (0, _classnames2.default)(classes.icon, classes.secondaryIcon)
				})
			),
			_react2.default.createElement(
				'div',
				{ className: classes.question },
				closeTitle || t("app-core.bottom-buttons.close").toLocaleUpperCase()
			)
		),
		onReset && !isTrainer && _react2.default.createElement(
			_core.Button,
			{
				variant: 'contained',
				color: 'primary',
				'aria-label': t("common.close"),
				className: (0, _classnames2.default)(classes.closeButtonRedo, classes.closeButtonSecondary),
				classes: {
					label: classes.closeButtonRedoLabel
				},
				onClick: onReset
			},
			_react2.default.createElement(
				'div',
				{ className: classes.iconContainer },
				theme.manakin.refreshIcon ? _react2.default.createElement('img', {
					alt: t("common.close"),
					src: theme.manakin.refreshIcon,
					className: (0, _classnames2.default)(classes.icon, classes.secondaryIcon, classes.closeIcon)
				}) : _react2.default.createElement(_Replay2.default, {
					className: (0, _classnames2.default)(classes.icon, classes.secondaryIcon)
				})
			),
			_react2.default.createElement(
				'div',
				{ className: classes.question },
				redoTitle || t("app-core.bottom-buttons.redo")
			)
		)
	);
};

exports.default = BottomButtons;