'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styles = require('../styles');

var _styles2 = _interopRequireDefault(_styles);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var LabelAndTag = function LabelAndTag(props) {
	var label = props.label,
	    showIconLabel = props.showIconLabel,
	    tags = props.tags,
	    animateLabel = props.animateLabel;


	var classes = (0, _styles2.default)();

	return _react2.default.createElement(
		_react2.default.Fragment,
		null,
		label && !showIconLabel && _react2.default.createElement(
			_Typography2.default,
			{
				className: (0, _classnames2.default)(classes.boxCardLabel, classes.boxCardLabelAndTag)
			},
			label
		),
		_react2.default.createElement(
			'div',
			{ className: classes.themes },
			tags && tags.map(function (tag) {
				return _react2.default.createElement(
					_react2.default.Fragment,
					{ key: tag.id },
					tag.visibleInApp && _react2.default.createElement(
						'div',
						{
							key: tag.id,
							className: (0, _classnames2.default)(classes.boxCardLabel, classes.boxCardIconLabel, _defineProperty({}, classes.boxCardLabelAnimate, animateLabel))
						},
						tag.image && tag.image.url && _react2.default.createElement(
							'figure',
							{ className: classes.imageIcon },
							_react2.default.createElement('img', { src: tag.image.url, className: classes.labelIcon })
						),
						_react2.default.createElement(
							_Typography2.default,
							{ className: classes.boxCardText },
							tag.name
						)
					)
				);
			})
		)
	);
};

exports.default = LabelAndTag;