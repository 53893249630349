import React from 'react';

const withAccessControl = (currentUser) => (WrappedComponent) => {
    return class WithAccessControl extends React.Component {
        render() {
            const { ...otherProps } = this.props;

            return <WrappedComponent {...otherProps} accessControl={{}} />;
        }
    };
};

export default withAccessControl;
