'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = exports.styles = function styles(theme) {
	var _container, _answers, _question, _$answers, _$question, _headerAsParagraph;

	return {
		root: {
			display: 'flex',
			width: '100%',
			justifyContent: 'center',
			alignItems: 'center'
		},
		button: {},
		contentColor: {
			'&::after': {
				backgroundColor: theme.manakin.indicationColor[500]
			}
		},
		secondaryColors: _defineProperty({
			paddingTop: '3rem',
			paddingBottom: '3rem',
			backgroundColor: theme.manakin.secondaryWorkformColor[500],
			'& $contentColor': {
				color: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
				'&::after': {
					backgroundColor: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500])
				}
			}
		}, theme.breakpoints.up('md'), {
			padding: '10rem'
		}),
		primaryColors: _defineProperty({
			paddingTop: '3rem',
			paddingBottom: '3rem',
			backgroundColor: theme.manakin.primaryWorkformColor[500],
			'& $contentColor': {
				color: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),
				'&::after': {
					backgroundColor: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500])
				}
			}
		}, theme.breakpoints.up('md'), {
			paddingTop: '10rem',
			paddingBottom: '10rem'
		}),
		biggerAnswer: {},
		container: (_container = {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			maxWidth: '1200px'
		}, _defineProperty(_container, theme.breakpoints.up('md'), {
			flexDirection: 'row'
		}), _defineProperty(_container, theme.breakpoints.up('lg'), {}), _container),
		fullWidth: {
			maxWidth: '100%'
		},
		answers: (_answers = {}, _defineProperty(_answers, theme.breakpoints.up('md'), {
			width: '50%'
		}), _defineProperty(_answers, '&$biggerAnswer', _defineProperty({}, theme.breakpoints.up('md'), {
			width: '60%'
		})), _answers),
		question: (_question = {}, _defineProperty(_question, theme.breakpoints.up('md'), {
			paddingRight: theme.manakin.defaultPadding,
			width: '50%'
		}), _defineProperty(_question, '&$biggerAnswer', _defineProperty({}, theme.breakpoints.up('md'), {
			width: '40%'
		})), _question),
		quarterQuestion: _defineProperty({}, theme.breakpoints.up('md'), {
			width: '37.5%'
		}),
		columnDirection: {
			flexDirection: 'column',
			'& $answers': (_$answers = {}, _defineProperty(_$answers, theme.breakpoints.up('md'), {
				width: '100%'
			}), _defineProperty(_$answers, '&$biggerAnswer', _defineProperty({}, theme.breakpoints.up('md'), {
				width: '100%'
			})), _$answers),
			'& $question': (_$question = {}, _defineProperty(_$question, theme.breakpoints.up('md'), {
				width: '100%'
			}), _defineProperty(_$question, '&$biggerAnswer', _defineProperty({}, theme.breakpoints.up('md'), {
				width: '100%'
			})), _$question)
		},
		mirror: {
			'& $question': _defineProperty({}, theme.breakpoints.up('md'), {
				paddingLeft: theme.manakin.defaultPadding,
				order: 2
			}),
			'& $answers': _defineProperty({}, theme.breakpoints.up('md'), {
				paddingLeft: 0
			}),
			'& $wrapper': _defineProperty({}, theme.breakpoints.up('md'), {
				paddingLeft: theme.manakin.defaultPadding,
				paddingRight: 0
			})
		},
		header: _defineProperty({
			fontWeight: 'bold',
			marginBottom: '7rem',
			fontSize: '4rem',
			marginTop: '1.8rem'
		}, theme.breakpoints.down('xs'), {
			fontSize: '2.4rem',
			lineHeight: '4rem',
			marginBottom: '3rem'
		}),
		compactHeader: {
			marginBottom: '1rem'
		},
		headerAsParagraph: (_headerAsParagraph = {
			fontSize: "2.4rem",
			lineHeight: " 4rem"
		}, _defineProperty(_headerAsParagraph, theme.breakpoints.down('sm'), {
			fontSize: "1.8rem",
			lineHeight: "2.5rem"
		}), _defineProperty(_headerAsParagraph, theme.breakpoints.down('xs'), {
			fontSize: "1.6rem",
			lineHeight: "2.1rem"
		}), _headerAsParagraph),
		subHeader: {},
		loader: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			zIndex: 100
		},
		wrapper: _extends({}, theme.manakin.defaultWrapper, _defineProperty({}, theme.breakpoints.up('md'), {
			maxWidth: '500px',
			paddingLeft: '0',
			margin: '0'
		})),
		fullAnswers: _defineProperty({}, theme.breakpoints.up('md'), {
			width: '62.5%'
		}),
		saving: {
			position: 'relative',
			'&:after': {
				content: '""',
				position: 'absolute',
				width: '100%',
				height: '100%',
				backgroundColor: 'white',
				opacity: '0.7',
				zIndex: '99',
				top: 0,
				left: 0
			}
		}
	};
};