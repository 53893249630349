import React, { useState, useEffect } from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import createUuid from 'uuid/v4';
import InputLabel from '@material-ui/core/InputLabel';
import { toIsoString } from '@manakin/core';

const DateTimePicker = (props) => {
	const {
		form,
		name,
		initialValue,
		loading = false,
		placeholder = 'dd-mm-yyyy HH-mm',
		label = '',
		margin = 'normal',
		maxDate = '3000-01-01',
		ampm = false,
	} = props;
	const { errors, schema } = form;
	const uuid = createUuid();

	//state hooks
	const [value, setValue] = useState(initialValue || null);

	//effect hooks
	useEffect(() => {
		if (!loading && initialValue) {
			setValue(initialValue || null);
			form.onFieldChange({ key: name, value: toIsoString(new Date(initialValue)) });
		}
	}, [initialValue, loading]);

	const handleDateChange = (date) => {
		form.onFieldChange({
			key: name,
			value: toIsoString(date),
		});
		setValue(date);
	};

	return (
		<FormControl required={(schema[name] || {}).required} margin={margin} error={errors[name] && errors[name].length > 0}>
			<InputLabel shrink={true}>{label}</InputLabel>
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<KeyboardDateTimePicker
					clearable
					maxDate={maxDate}
					margin="normal"
					id={uuid}
					invalidDateMessage=""
					format="dd-MM-yyyy HH:mm"
					value={value}
					placeholder={placeholder}
					onChange={handleDateChange}
					ampm={ampm}
					KeyboardButtonProps={{
						'aria-label': 'change date',
					}}
				/>
			</MuiPickersUtilsProvider>
			{errors[name] && errors[name].length > 0 && errors[name].map((e, i) => <FormHelperText key={i}>{e}</FormHelperText>)}
		</FormControl>
	);
};

export default DateTimePicker;
