import React from "react";
import { useSelector } from "react-redux";
import PasswordView from "./PasswordView";
import StrongPasswordView from "./StrongPasswordView";

const ChangePasswordForm = (props) => {
	const { onSubmit } = props;
	const config = useSelector((state) => state.config);
	const { general = {} } = config || {};

	if (general.strongPassword)
		return <StrongPasswordView onSubmit={onSubmit} />;
	return <PasswordView onSubmit={onSubmit} />;
};

export default ChangePasswordForm;
