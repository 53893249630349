import React, { useEffect, useState } from 'react';
import { withStyles, Typography } from '@material-ui/core';
import { LoadImage, Loader } from '@manakin/app-core';
import { getProgram } from '@manakin/app-core/ProgramsDropdown/selectors';
import { getAppUser } from '@manakin/authentication/selectors';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { GQL_FETCH_ELEMENT_RESULTS } from '../graphql';
import { graphql } from 'react-apollo';
import { useGetBoxResult, useSetting } from '@manakin/hooks';
import { useQuery } from "@apollo/react-hooks";
import { SettingsKeys } from '@manakin/core/lib/constants';
import classNames from 'classnames';
import { GQL_FETCH_GLOBAL_SETTINGS } from "../graphql";
import { useTranslation } from 'react-i18next';

export const DEFAULT_POINTS_PER_BOX = 50;
const styles = (theme) => ({
    root: {
        backgroundColor: 'white',
        position: 'relative',
        paddingLeft: '7.5rem',
        width: '100%',
        minHeight: '5rem',
        display: 'flex',
        alignItems: 'center',
        paddingRight: '7rem',
        [theme.breakpoints.up('md')]: {
            paddingLeft: '12.5rem',
            paddingRight: '9rem',
            minHeight: '10rem',
        },
    },
    image: {
        position: 'absolute',
        height: '100%',
        width: '5rem',
        left: 0,
        top: 0,
        [theme.breakpoints.up('md')]: {
            width: '10rem',
        },
    },
    body: {
        margin: 0,
    },
    score: {
        position: 'absolute',
        margin: 0,
        right: '3.6rem',
        top: '50%',
        transform: 'translateY(-50%)',
        fontWeight: '500',
    },
    active: {
        color: theme.palette.primary[500],
    },
});

const BoxInfo = (props) => {
    const { classes, box = {}, data } = props;
    const { image = {} } = box;
    const { loading } = data;
    const { data: settingsData = {} } = useQuery(GQL_FETCH_GLOBAL_SETTINGS);
    const POINTS_PER_FINISHED_BOX = parseInt(useSetting(settingsData.settings, SettingsKeys.BETTINGGAME_POINTS_PER_FINISHED_BOX) || DEFAULT_POINTS_PER_BOX);
    const { t } = useTranslation();

    //state hooks
    const [percentage, setPercentage] = useState(0);

    //effect hooks
    useEffect(() => {
        setPercentage(useGetBoxResult(data, box));
    }, [loading]);

    useEffect(() => {
        if (percentage >= 100) {
            if (props.onSetPoints) props.onSetPoints(POINTS_PER_FINISHED_BOX);
        }
    }, [percentage]);

    if (loading) {
        return <Loader fullScreen={true} />;
    }
    return (
        <div className={classes.root}>
            {image && image.url && (
                <div className={classes.image}>
                    <LoadImage
                        placeholder={image.thumbnailUrl || image.url}
                        src={image.url}
                    />
                </div>
            )}
            <Typography variant="body1" className={classes.body}>
                {box.name || ''}
            </Typography>
            <Typography
                variant="body1"
                className={classNames(classes.score, {
                    [classes.active]: percentage >= 100,
                })}
            >
                {percentage >= 100 ?
                    `+${t("app.elements.betting-game.amount-points-abbreviated", { points: POINTS_PER_FINISHED_BOX })}`
                    : t("app.elements.betting-game.amount-points-abbreviated", { points : 0 })}
            </Typography>
        </div>
    );
};

export default compose(
    connect((state) => ({
        program: getProgram(state),
        appUser: getAppUser(state),
    })),
    withStyles(styles, { name: 'AppBoxInfo' }),
    graphql(GQL_FETCH_ELEMENT_RESULTS, {
        options: (props) => ({
            variables: {
                program: props.program,
                user: props.appUser && props.appUser.id,
                box: props.box.id,
            },
        }),
    })
)(BoxInfo);
