import { REFRESH_DATA } from './actions';

const initialState = {
    trigger: {
        name: 'Geen naam',
        switch: false,
    },
};

export const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case REFRESH_DATA: {
            return {
                ...state,
                trigger: {
                    name: action.payload.name,
                    switch: !state.trigger.switch,
                },
            };
        }
        default:
            return state;
    }
};

export default reducer;
