import React from 'react';
import SeeMoreButton from './SeeMoreButton';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    widgetHeader: {
        marginBottom: 30,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',

        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
        }
    },
    title: {
        marginBottom: 0
    },
    primary: {
        '& $title': {
            color: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),
        }
	},
	secondary: {
        '& $title': {
            color: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
        }
	},
	noBG: {
        '& $title': {
            color: theme.manakin.defaultContentColor[500],
        }
	},
}));

const WidgetHeader = (props) => {
    const { title, backgroundColor, onClick } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <div className={classes.widgetHeader}>
            <Typography
                component="h3"
                variant="h3"
                className={classes.title}
            >
                {title}
            </Typography>
            <SeeMoreButton onClick={onClick} backgroundColor={backgroundColor} />
        </div>
    )
}

export default WidgetHeader;