import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/react-hooks';
import { GQL_FETCH_LOGGED_IN_USERS_INFO } from '@manakin/app-core/graphql';
import PersonIcon from '@material-ui/icons/Person';
import { useTranslation } from 'react-i18next';

export const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				padding: '0 2.4rem',
				display: 'flex',
				justifyContent: 'flex-end',
				alignItems: 'center',
				top: 70,
				position: 'absolute',
				right: 0,

				[theme.breakpoints.up('md')]: {
					top: 100,
				},
			},
			profilePictures: {
				display: 'flex',
			},
			profilePicture: {
				borderRadius: '50%',
				width: 40,
				height: 40,
				border: `1px solid ${theme.palette.grey[700]}`,
				zIndex: 0,
				position: 'relative',
				background: 'white',
				objectFit: 'cover',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',

				'& svg': {
					height: '3.4em',
					width: '3.4rem',
					color: theme.palette.grey[500],
				},
			},
			profilePictureWrapper: {
				position: 'relative',

				'&:nth-child(1)': {
					zIndex: 2,
				},

				'&:nth-child(2)': {
					zIndex: 1,
				},

				'&:not(:last-child)': {
					marginRight: -8,
				},

				'&:after': {
					content: "''",
					width: 10,
					height: 10,
					background: '#3f9e3f',
					borderRadius: '50%',
					position: 'absolute',
					bottom: 0,
					right: 0,
					zIndex: 10,
				},
			},
			amountOnline: {
				color: theme.manakin.primaryColor[700],
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				marginLeft: 10,
				textTransform: 'lowercase',

				'& span': {
					display: 'block',
					fontSize: '1.5rem',
				},
			},
		}),
	{
		name: 'ActiveUsersApp',
	}
);

const ActiveUsers = (props) => {
	const { showProfilePictures } = props;

	const classes = useStyles();
	const { t } = useTranslation();

	const [randomLoggedInUsers, setRandomLoggedInUsers] = useState([]);
	const [loggedInUserCount, setLoggedInUserCount] = useState(0);

	const { data, loading } = useQuery(GQL_FETCH_LOGGED_IN_USERS_INFO, {
		onCompleted: () => {
			if (
				data &&
				data.loggedInAppUsersInfo &&
				data.loggedInAppUsersInfo.randomLoggedInUsers &&
				data.loggedInAppUsersInfo.loggedInUserCount
			) {
				setRandomLoggedInUsers(data.loggedInAppUsersInfo.randomLoggedInUsers);
				setLoggedInUserCount(data.loggedInAppUsersInfo.loggedInUserCount);
			}
		},
	});

	return (
		<div className={classes.root}>
			<div className={classes.profilePictures}>
				{randomLoggedInUsers &&
					randomLoggedInUsers.map((user) => (
						<div className={classes.profilePictureWrapper}>
							{user.profilePicUrl && showProfilePictures ? (
								<img className={classes.profilePicture} src={user.profilePicUrl} />
							) : (
								<div className={classes.profilePicture}>
									<PersonIcon />
								</div>
							)}
						</div>
					))}
			</div>

			{loggedInUserCount > 3 && (
				<div className={classes.amountOnline}>
					<span>+ {loggedInUserCount - randomLoggedInUsers.length}</span>
					<span>{t('common.online')}</span>
				</div>
			)}
		</div>
	);
};

export default ActiveUsers;
