import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: "100px 0",
		[theme.breakpoints.up("md")]: {
			display: "flex",
			width: "100%",
			padding: "200px 0 100px",
		},
	},
	wrapper: {
		...theme.manakin.smallWrapper,
	},
}));

const Layout = (props) => {
	const { children } = props;
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<div className={classes.wrapper}>{children}</div>
		</div>
	);
};

export default Layout;
