import React from 'react';
import { WorkformViewer } from '@manakin/app-core';
import { makeStyles, createStyles } from '@material-ui/styles';

const useStyles = makeStyles(
    createStyles((theme) => ({
        normalMargin: {
            margin: '3rem 0',
            [theme.breakpoints.up('md')]: {
                margin: '10rem 0',
            },
        },
    }))
);

const Workforms = (props) => {
    const { workforms = [], options, onFinish } = props;
    const classes = useStyles();

    return (
        <div>
            {workforms &&
                workforms.map((item, idx) => (
                    <React.Fragment key={item.id || `empty${idx}`}>
                        {item.type !== 'FileDownload' && (
                            <div className={classes.normalMargin}>
                                <WorkformViewer
                                    type={item.type}
                                    item={item}
                                    animate={false}
                                    onFinish={onFinish}
                                />
                            </div>
                        )}
                    </React.Fragment>
                ))}
        </div>
    );
};

export default Workforms;
