import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { WorkformLayout, AppRadioGroup, LongArrow } from '@manakin/app-core';
import { FeedbackBox } from '@manakin/app-workforms';
import { styles } from './styles';
import { withTranslation } from 'react-i18next';
import { isDone } from '../lib';

class TextMPC extends React.Component {
	handleChange = (event) => {
		if (this.props.onChange) this.props.onChange(event);
	};

	handleSubmit = (event) => {
		const { data } = this.props;
		if (this.props.onSubmit) this.props.onSubmit(data);
	};

	render() {
		const { classes, data, onFinished, direction = 'default', disabled, t, saving, onSetWorkformDone } = this.props;
		const { answers = [], initialValue = '' } = data;

		return (
			<WorkformLayout
				question={data.question || ''}
				saving={saving}
				instruction={data.introduction || t('app.workforms.text-mpc.introduction')}
				loading={false}
				direction={direction}
				renderAnswers={() => (
					<div className={classes.answers}>
						<AppRadioGroup done={isDone(data)} answers={answers} onChange={this.handleChange} initialValue={initialValue} />

						<div className={classes.buttons}>
							{!data.feedback ? (
								<Button
									variant="contained"
									color="primary"
									fullWidth
									className={classes.button}
									onClick={() => this.handleSubmit()}
									disabled={disabled}
								>
									{data.buttonText || t('common.check-answer')}
									<LongArrow className={classes.longArrowRoot} />
								</Button>
							) : (
								<div
									className={classNames(classes.feedback, {
										[classes.activeFeedback]: data.feedback,
									})}
								>
									<FeedbackBox onSetWorkformDone={onSetWorkformDone} data={data} onFinished={onFinished} outlined={true} />
								</div>
							)}
						</div>
					</div>
				)}
			/>
		);
	}
}

export default compose(withStyles(styles, { name: 'AppTextMPC' }), withTranslation())(TextMPC);
