'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.GQL_FETCH_BEST_TIMES = exports.GQL_FETCH_APP_USER = undefined;

var _templateObject = _taggedTemplateLiteral(['\n    query($id: ID!) {\n        appUser(id: $id) {\n            id\n            firstName\n            lastName\n            fullName\n            schoolClasses {\n                id\n                name\n            }\n        }\n    }\n'], ['\n    query($id: ID!) {\n        appUser(id: $id) {\n            id\n            firstName\n            lastName\n            fullName\n            schoolClasses {\n                id\n                name\n            }\n        }\n    }\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n    query(\n        $schoolClass: ID!\n        $box: ID\n        $program: ID!\n        $user: ID!\n        $element: ID\n    ) {\n        bestTimes(\n            schoolClass: $schoolClass\n            box: $box\n            program: $program\n            user: $user\n            element: $element\n        ) {\n            id\n            time\n            user {\n                id\n                fullName\n                profilePicture {\n                    id\n                    url\n                    thumbnailUrl\n                }\n            }\n        }\n    }\n'], ['\n    query(\n        $schoolClass: ID!\n        $box: ID\n        $program: ID!\n        $user: ID!\n        $element: ID\n    ) {\n        bestTimes(\n            schoolClass: $schoolClass\n            box: $box\n            program: $program\n            user: $user\n            element: $element\n        ) {\n            id\n            time\n            user {\n                id\n                fullName\n                profilePicture {\n                    id\n                    url\n                    thumbnailUrl\n                }\n            }\n        }\n    }\n']);

var _graphqlTag = require('graphql-tag');

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_APP_USER = exports.GQL_FETCH_APP_USER = (0, _graphqlTag2.default)(_templateObject);

var GQL_FETCH_BEST_TIMES = exports.GQL_FETCH_BEST_TIMES = (0, _graphqlTag2.default)(_templateObject2);