"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.useStyles = undefined;

var _styles = require("@material-ui/styles");

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var useStyles = exports.useStyles = (0, _styles.makeStyles)(function (theme) {
	var _date;

	return {
		imageContainer: {
			display: "flex",
			alignItems: "center"
		},
		image: {
			width: "100px",
			height: "100px",
			marginRight: "30px",
			position: "relative"
		},
		content: {
			margin: 0
		},
		ratingNeeded: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			border: "2px solid " + theme.palette.warning.main,
			borderRadius: "100%",
			height: "54px",
			width: "54px"
		},
		rating: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			border: "2px solid white",
			borderRadius: "100%",
			height: "54px",
			width: "54px"
		},
		good: {
			borderColor: theme.palette.success.main
		},
		suffiecient: {
			borderColor: theme.manakin.warningColor[500]
		},
		inSuffiecient: {
			borderColor: theme.palette.warning.main
		},
		padding: {
			padding: "20px 0"
		},
		layout: {
			cursor: "pointer",
			backgroundColor: "white",
			marginBottom: "10px",
			transform: "scale(1)",
			transition: "transform .2s",
			"&:hover": {
				transform: "scale(1.01)"
			}
		},
		children: {
			gridTemplateColumns: "1fr 130px 100px 100px",
			display: "grid"
		},
		disabled: {
			cursor: "default",
			"&:hover": {
				transform: "scale(1)"
			}
		},
		link: {
			textDecoration: "none"
		},
		iconContainer: {
			display: "flex",
			alignItems: "center"
		},
		icon: {
			color: theme.manakin.defaultBorderColor[500],
			marginRight: "10px"
		},
		ratingsMobile: _defineProperty({
			display: "flex",
			alignItems: "center"

		}, theme.breakpoints.down("xs"), {
			marginBottom: "10px"
		}),
		mobileContent: _defineProperty({
			display: "none"
		}, theme.breakpoints.down("xs"), {
			display: "inline-block",
			color: theme.manakin.defaultContentColor[400],
			marginRight: "10px"
		}),
		dates: {
			marginLeft: 8
		},
		date: (_date = {
			display: 'block',
			fontSize: 14
		}, _defineProperty(_date, "display", 'flex'), _defineProperty(_date, "alignItems", 'center'), _defineProperty(_date, "color", theme.manakin.defaultContentColor[500]), _defineProperty(_date, '& svg', {
			marginRight: 3
		}), _defineProperty(_date, '&:nth-child(1)', {
			marginBottom: 5
		}), _date)
	};
});