import React from 'react';
import { Route } from 'react-router-dom';
import { ENTRY_EXAM_TRAINER_ROOT_PATH } from '../Router/Router';
import ExamTrainerContainer from './ExamTrainerContainer';
import Results from './Results';
import SaveResults from './SaveResults';
import TrainerReview from './ExamTrainerReview';
import FrontPage from './FrontPage';
import ProgramTrainerFrontPage from './ProgramTrainerFrontPage';

const Router = (props) => {
	return (
		<React.Fragment>
			<Route exact path={`/${ENTRY_EXAM_TRAINER_ROOT_PATH}/:programId/programTrainer/start`} component={ProgramTrainerFrontPage} />
			<Route exact path={`/${ENTRY_EXAM_TRAINER_ROOT_PATH}/:programId/programTrainer`} component={ExamTrainerContainer} />
			<Route exact path={`/${ENTRY_EXAM_TRAINER_ROOT_PATH}/:elementId`} component={FrontPage} />
			<Route exact path={`/${ENTRY_EXAM_TRAINER_ROOT_PATH}/:elementId/trainer`} component={ExamTrainerContainer} />
			<Route exact path={`/${ENTRY_EXAM_TRAINER_ROOT_PATH}/:elementId/saveResults`} component={SaveResults} />
			<Route exact path={`/${ENTRY_EXAM_TRAINER_ROOT_PATH}/:programId/saveResultsProgram`} component={SaveResults} />
			<Route exact path={`/${ENTRY_EXAM_TRAINER_ROOT_PATH}/:elementId/results`} component={Results} />
			<Route exact path={`/${ENTRY_EXAM_TRAINER_ROOT_PATH}/:programId/programResults`} component={Results} />
			<Route exact path={`/${ENTRY_EXAM_TRAINER_ROOT_PATH}/:elementId/trainerReview`} component={TrainerReview} />
		</React.Fragment>
	);
};

export default Router;
