import React from 'react';
import AppConfirmRegistration from '@manakin/authentication/AppConfirmRegistration';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
    (theme) =>
        createStyles({
            root: {
                width: '100%',
                minHeight: '100%',
            },
        }),
    {
        name: 'AppRegistrationScreen',
    }
);

const ConfirmRegistration = (props) => {
    const { mutate, match } = props;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.smallWrapper}>
                <div className={classes.registrationRoot}>
                    <AppConfirmRegistration
                        mutate={mutate}
                        token={match.params.confirm}
                    />
                </div>
            </div>
        </div>
    );
};

export default ConfirmRegistration;
