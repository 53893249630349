import gql from 'graphql-tag';

export const GQL_FORGOT_MFA = gql`
    mutation forgotMfa {
        forgotMfa
    }
`;

export const GQL_RESET_MFA_TOKEN = gql`
    mutation resetMfaByToken($token: String!) {
        resetMfaByToken(input: {
            token: $token
        })
    }
`;
