import React from 'react';
import { scroller } from 'react-scroll';
import { Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { NoUndefinedVariablesRule } from 'graphql';

const useStyles = makeStyles(
	createStyles((theme) => ({
        root: {
            position: 'absolute',
			bottom: '0',
			transform: 'translateX(-50%)',
			left: '50%',
			right: '50%',
			width: '50px',
			height: '50px',
			cursor: 'pointer',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
        },
        text: {
            display: 'none',

            [theme.breakpoints.up('sm')]: {
                display: 'block',
                marginRight: 200,
                fontWeight: 'bold',
                marginBottom: 15
            }
        },
		scrollIndicator: {
			position: 'absolute',
			height: '50px',
			animation: '$UpDown .8s alternate infinite',
			cursor: 'pointer',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',

			'& span': {
				position: 'absolute',
				top: '0',
				width: '24px',
				height: '24px',
				borderLeft: '2px solid #000',
				borderBottom: '2px solid #000',
				transform: 'rotate(-45deg)',

				'&:nth-of-type(2)': {
					top: '14px',
				},
			},
		},
		'@keyframes UpDown': {
			from: {
				bottom: '0',
			},
			to: {
				bottom: '25px',
			},
		},
	})),
	{ name: 'AppScrollIndicator' }
);

const ScrollIndicator = (props) => {
    const { text } = props;
    const classes = useStyles();
    const { t } = useTranslation();
	const config = useSelector((state) => state.config);
	const { general = {} } = config || {};

	const onScrollIndicatorClick = () => {
		scroller.scrollTo('scrollIndicator', {
			duration: 400,
			smooth: 'easeInOutQuint',
		});
	};

	return (
		<div className={classes.root} onClick={onScrollIndicatorClick}>
            {general.showScrollIndicatorText &&
                <Typography
                    className={classes.text}
                >
                    {text || t('authentication.homepage.scroll-indicator-text')}
                </Typography>
            }
			<div name="scrollIndicator" className={classes.scrollIndicator}>
				<span></span>
				<span></span>
			</div>
		</div>
	);
};

export default ScrollIndicator;
