'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Dialog = require('@material-ui/core/Dialog');

var _Dialog2 = _interopRequireDefault(_Dialog);

var _core = require('@material-ui/core');

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _Button = require('@material-ui/core/Button');

var _Button2 = _interopRequireDefault(_Button);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _core2 = require('@manakin/core');

var _recompose = require('recompose');

var _reactRedux = require('react-redux');

var _actions = require('@manakin/core/actions');

var _reactHooks = require('@apollo/react-hooks');

var _lib = require('@manakin/app-core/lib');

var _graphql = require('./graphql');

var _reactI18next = require('react-i18next');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var useStyles = (0, _core.makeStyles)(function (theme) {
	return {
		dialogPaper: {
			flexFlow: 'row wrap',
			padding: theme.spacing(6),
			textAlign: "center"
		},
		dialogTitle: _defineProperty({
			width: '100%',
			margin: 0,
			padding: '12px 0',
			fontSize: '4rem',
			lineHeight: '4rem'
		}, theme.breakpoints.up('md'), {
			padding: '32px 0'
		}),
		dialogActions: {
			display: 'flex',
			flexFlow: 'row wrap',
			flexDirection: "column",
			width: '100%',
			marginTop: theme.spacing(2)
		},
		button: {
			minHeight: "6rem",
			padding: 0
		},
		ok: {
			backgroundColor: theme.manakin.indicationColor[500],
			color: 'white',
			marginBottom: theme.spacing(),
			'&:hover': {
				backgroundColor: theme.manakin.indicationColor[700]
			}
		}
	};
});

var formBundle = {
	trainer: {}
};

var ResendRatingRequestDialog = function ResendRatingRequestDialog(props) {
	var form = props.form,
	    isFinalRating = props.isFinalRating,
	    ratingRequest = props.ratingRequest,
	    onClose = props.onClose,
	    open = props.open;

	var classes = useStyles();
	var dispatch = (0, _reactRedux.useDispatch)();

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	// Queries


	var _useQuery = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_INVITATION_TRAINERS),
	    _useQuery$data = _useQuery.data,
	    invitationTrainersData = _useQuery$data === undefined ? {} : _useQuery$data;

	var _invitationTrainersDa = invitationTrainersData.invitationTrainers,
	    invitationTrainers = _invitationTrainersDa === undefined ? [] : _invitationTrainersDa;

	var trainers = invitationTrainers.filter(function (trainer) {
		return trainer.roles.some(function (i) {
			return i.name === _lib.poRole;
		});
	}).map(function (trainer) {
		return {
			name: trainer.fullName,
			id: trainer.id
		};
	});

	var _useMutation = (0, _reactHooks.useMutation)(_graphql.GQL_UPDATE_RATING_REQUEST),
	    _useMutation2 = _slicedToArray(_useMutation, 2),
	    updateRatingRequest = _useMutation2[0],
	    _useMutation2$ = _useMutation2[1],
	    updateRatingRequestData = _useMutation2$.data,
	    updateRatingRequestError = _useMutation2$.error;

	var handleUpdateRatingRequest = function handleUpdateRatingRequest() {
		var onSubmit = form.onSubmit;

		form.onValidate().then(function () {
			return onSubmit().then(function (data) {
				updateRatingRequest({
					variables: {
						id: ratingRequest.id,
						trainerId: parseInt(data.trainer)
					}
				});
			});
		}, function (error) {
			// Handled by inputs
		});
	};

	(0, _react.useEffect)(function () {
		if (updateRatingRequestData && updateRatingRequestData.updateRatingRequest) {
			dispatch((0, _actions.showSnackbarMessage)({
				text: t("app.rating-request.resend-dialog.success"),
				variant: "success"
			}));
			onClose();
		}

		if (updateRatingRequestError) {
			dispatch((0, _actions.showSnackbarMessage)({
				text: t("app.rating-request.resend-dialog.failed"),
				variant: "error"
			}));
		}
	}, [updateRatingRequestData, updateRatingRequestError]);

	return _react2.default.createElement(
		_Dialog2.default,
		{
			open: open,
			onClose: onClose,
			PaperProps: { className: classes.dialogPaper }
		},
		_react2.default.createElement(
			_Typography2.default,
			{ variant: 'h3', className: classes.dialogTitle },
			isFinalRating ? t("app.rating-request.resend-dialog.final-rating.title") : t("app.rating-request.resend-dialog.rating.title")
		),
		_react2.default.createElement(
			_core.DialogContent,
			null,
			_react2.default.createElement(
				_core.DialogContentText,
				null,
				isFinalRating ? t("app.rating-request.resend-dialog.final-rating.description", { name: ratingRequest.trainer.fullName }) : t("app.rating-request.resend-dialog.rating.description", { name: ratingRequest.trainer.fullName })
			),
			_react2.default.createElement(_core2.SelectField, {
				options: trainers,
				label: t("common.fields.trainer.label"),
				placeholder: ' ',
				form: form,
				name: 'trainer'
			})
		),
		_react2.default.createElement(
			'div',
			{ className: classes.dialogActions },
			_react2.default.createElement(
				_Button2.default,
				{
					variant: 'contained',
					className: (0, _classnames2.default)(classes.button, classes.ok),
					onClick: handleUpdateRatingRequest
				},
				t("app.rating-request.resend-dialog.action")
			),
			_react2.default.createElement(
				_Button2.default,
				{
					variant: 'contained',
					className: (0, _classnames2.default)(classes.button),
					onClick: onClose
				},
				t("common.close")
			)
		)
	);
};

exports.default = (0, _recompose.compose)((0, _core2.withForm)(formBundle))(ResendRatingRequestDialog);