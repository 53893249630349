'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.toggleSearchBar = toggleSearchBar;
var TOGGLE_SEARCH_BAR = exports.TOGGLE_SEARCH_BAR = '@manakin/core/TOGGLE_SEARCH_BAR';

function toggleSearchBar() {
    return {
        type: TOGGLE_SEARCH_BAR,
        payload: {}
    };
}