import { StorageKeys } from '@manakin/core/lib/constants';

export const isSettingEnabled = (name, manualSettings) => {
	const settings = manualSettings ? manualSettings : JSON.parse(localStorage.getItem(StorageKeys.SETTINGS)) || [];
	const setting = settings.find((setting) => setting.name === name);

    return setting && setting.value === 'true' ? true : false;
}

export const getSettings = (name) => {
	const settings = JSON.parse(localStorage.getItem(StorageKeys.SETTINGS)) || [];
	return settings;
}

export const getSettingValue = (name) => {
	const settings = JSON.parse(localStorage.getItem(StorageKeys.SETTINGS)) || [];
	const setting = settings.find((setting) => setting.name === name);

	return setting && setting.value;
}

export const getSettingFile = (name) => {
	const settings = JSON.parse(localStorage.getItem(StorageKeys.SETTINGS)) || [];
	const setting = settings.find((setting) => setting.name === name);

	return setting && setting.file;
}


