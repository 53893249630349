'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _core = require('@material-ui/core');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _ErrorOutline = require('@material-ui/icons/ErrorOutline');

var _ErrorOutline2 = _interopRequireDefault(_ErrorOutline);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useStyles = (0, _core.makeStyles)(function (theme) {
    return {
        root: {
            background: function background(props) {
                return (props.color || theme.manakin.warningColor[500]) + '26';
            },
            padding: theme.spacing(2),
            borderRadius: 4,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%"
        },
        body: {
            marginBottom: 0,
            opacity: .87
        },
        icon: {
            marginRight: theme.spacing(2),
            color: function color(props) {
                return props.color || theme.manakin.warningColor[500];
            },
            width: 32,
            height: 32
        }
    };
});

var WarningBox = function WarningBox(props) {
    var classNameProp = props.className,
        message = props.message;

    var classes = useStyles(props);

    return _react2.default.createElement(
        'div',
        { className: (0, _classnames2.default)(classes.root, classNameProp) },
        _react2.default.createElement(_ErrorOutline2.default, { className: classes.icon }),
        _react2.default.createElement(
            _Typography2.default,
            {
                variant: 'body1',
                className: classes.body
            },
            message
        )
    );
};

exports.default = WarningBox;