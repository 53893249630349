'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styles = require('@material-ui/core/styles');

var _recompose = require('recompose');

var _reactApollo = require('react-apollo');

var _graphql = require('./graphql');

var _Button = require('@material-ui/core/Button');

var _Button2 = _interopRequireDefault(_Button);

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _reactRouterDom = require('react-router-dom');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _reactI18next = require('react-i18next');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
    return {
        root: _defineProperty({}, theme.breakpoints.up('md'), {
            display: 'flex'
        }),
        referralRoot: _defineProperty({
            width: '100%',
            backgroundColor: theme.manakin.secondaryColor[200]
        }, theme.breakpoints.up('md'), {
            display: 'flex'
        }),
        content: _defineProperty({
            padding: '3rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%'
        }, theme.breakpoints.up('md'), {
            padding: '3rem 5rem'
        }),
        multi: {
            '& $body': {
                marginBottom: '1.2rem'
            },
            '& $image': _defineProperty({}, theme.breakpoints.up('md'), {
                width: '42%'
            }),
            '& $content': _defineProperty({}, theme.breakpoints.up('md'), {
                width: '58%',
                display: 'block'
            })
        },
        image: _defineProperty({
            backgroundColor: theme.manakin.primaryColor,
            position: 'relative',
            height: '14rem',
            width: '100%'
        }, theme.breakpoints.up('md'), {
            height: '100%',
            width: '14rem'
        }),
        body: {
            margin: 0
        },
        title: {
            margin: 0
        },
        imageSrc: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            fontFamily: "'object-fit: cover'",
            position: 'absolute',
            left: '0',
            right: '0'
        }
    };
};

var FactCardReferral = function (_React$PureComponent) {
    _inherits(FactCardReferral, _React$PureComponent);

    function FactCardReferral() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, FactCardReferral);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = FactCardReferral.__proto__ || Object.getPrototypeOf(FactCardReferral)).call.apply(_ref, [this].concat(args))), _this), _this.handleClick = function (id) {
            _this.props.history.push('/factcard/' + id);
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(FactCardReferral, [{
        key: 'componentDidUpdate',
        value: function componentDidUpdate(prevProps) {
            if (prevProps.data.loading && !this.props.data.loading) {
                if (this.props.isLoading) {
                    this.props.isLoading(false);
                }
            }
        }
    }, {
        key: 'render',
        value: function render() {
            var _this2 = this;

            var _props = this.props,
                data = _props.data,
                classes = _props.classes,
                t = _props.t;
            var factCardReferral = data.factCardReferral,
                loading = data.loading;

            var factCardReferralData = factCardReferral || {};

            var factCardLength = factCardReferralData.referrals ? factCardReferralData.referrals.length : 0;

            return _react2.default.createElement(
                'div',
                { className: classes.root },
                factCardReferralData.referrals && factCardReferralData.referrals.map(function (item) {
                    return _react2.default.createElement(
                        'div',
                        {
                            className: (0, _classnames2.default)(classes.referralRoot, _defineProperty({}, classes.multi, factCardLength == 2)),
                            key: item.id
                        },
                        _react2.default.createElement(
                            'div',
                            { className: classes.image },
                            _react2.default.createElement('img', {
                                className: classes.imageSrc,
                                src: item.image ? item.image.url : null
                            })
                        ),
                        _react2.default.createElement(
                            'div',
                            { className: classes.content },
                            _react2.default.createElement(
                                'div',
                                null,
                                _react2.default.createElement(
                                    _Typography2.default,
                                    {
                                        component: 'h4',
                                        variant: 'h6',
                                        classes: { h6: classes.title }
                                    },
                                    item.title
                                ),
                                _react2.default.createElement(
                                    _Typography2.default,
                                    {
                                        component: 'h4',
                                        variant: 'body1',
                                        classes: { body1: classes.body }
                                    },
                                    item.text
                                )
                            ),
                            _react2.default.createElement(
                                _Button2.default,
                                {
                                    variant: 'contained',
                                    color: 'primary',
                                    className: classes.button,
                                    onClick: function onClick() {
                                        return _this2.handleClick(item.factCardId);
                                    }
                                },
                                t("app-core.fact-cards-card.go-to-fact-card")
                            )
                        )
                    );
                })
            );
        }
    }]);

    return FactCardReferral;
}(_react2.default.PureComponent);

exports.default = (0, _recompose.compose)(_reactRouterDom.withRouter, (0, _styles.withStyles)(styles), (0, _reactI18next.withTranslation)(), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_FACTCARD_REFERRAL, {
    options: function options(props) {
        return { variables: { id: props.workformId } };
    }
}))(FactCardReferral);