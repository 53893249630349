'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _styles = require('@material-ui/core/styles');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useStyles = (0, _styles.makeStyles)(function (theme) {
    return {
        root: {
            display: 'flex',
            alignItems: 'center'
        },
        outer: {
            width: '10rem',
            height: '.8rem',
            backgroundColor: '#e6e6e6',
            position: 'relative',
            marginRight: '2rem'
        },
        inner: {
            backgroundColor: theme.manakin.secondaryColor[500],
            position: 'absolute',
            height: '.8rem',
            left: 0,
            top: 0,
            transition: 'width .3s'
        },
        content: {
            margin: 0,
            minWidth: '3rem'
        }
    };
}, { name: 'appPercentageBar' });

var PercentageBar = function PercentageBar(props) {
    var _props$percentage = props.percentage,
        percentage = _props$percentage === undefined ? 0 : _props$percentage,
        classes = props.classes;

    var styles = classes ? classes : useStyles();
    var perc = percentage > 0 ? percentage : 0;

    return _react2.default.createElement(
        'div',
        { className: styles.root },
        _react2.default.createElement(
            'div',
            { className: styles.outer },
            _react2.default.createElement('div', {
                className: styles.inner,
                style: { width: perc + '%' }
            })
        ),
        _react2.default.createElement(
            _Typography2.default,
            {
                component: 'p',
                variant: 'body1',
                className: styles.content
            },
            perc,
            '%'
        )
    );
};

exports.default = PercentageBar;