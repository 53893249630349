import { SettingsKeys } from "@manakin/core/lib/constants";

export const defaultElementLabels = (t) => {
    return {
		BettingGame: t('common.element-types.betting-game'),
		BookCheck: t('common.element-types.book-check'),
		BookDownload: t('common.element-types.book-download'),
		Bridge: t('common.element-types.bridge'),
		Case: t('common.element-types.case'),
		DigitalBook: t('common.element-types.digital-book'),
		EntryTest: t('common.element-types.entry-test'),
		ExamTrainer: t('common.element-types.exam-training'),
		ExerciseCard: t('common.element-types.exercise-card'),
		ExternalLinkCollection: t('common.element-types.external-link-collection'),
		FactCardCollection: t('common.element-types.fact-card-collection'),
		Film: t('common.element-types.film'),
		FinalRating: t('common.element-types.final-rating'),
		Lesson: t('common.element-types.lesson'),
		MasterClass: t('common.element-types.master-class'),
		Rating: t('common.element-types.rating'),
		SelfEvaluation: t('common.element-types.self-evaluation'),
		SlideshowCreator: t('common.element-types.slideshow-creator'),
		Summary: t('common.element-types.summary'),
		Test: t('common.element-types.test'),
		Trainer: t('common.element-types.trainer'),
		VideoBridge: t('common.element-types.video-bridge'),
	};
};

const elementTypes = (t) => ({
	BookCheck: {
		id: 'upsertBookCheck',
		type: 'BookCheck',
		label: defaultElementLabels(t).BookCheck,
	},
	Bridge: {
		id: 'upsertBridge',
		type: 'Bridge',
		label: defaultElementLabels(t).Bridge,
	},
	Case: {
		id: 'upsertCase',
		type: 'Case',
		label: defaultElementLabels(t).Case,
	},
	DigitalBook: {
		id: 'upsertDigitalBook',
		type: 'DigitalBook',
		label: defaultElementLabels(t).DigitalBook,
	},
	ExternalLinkCollection: {
		id: 'upsertExternalLinkCollection',
		type: 'ExternalLinkCollection',
		label: defaultElementLabels(t).ExternalLinkCollection,
	},
	FactCardCollection: {
		id: 'upsertFactCardCollection',
		type: 'FactCardCollection',
		label: defaultElementLabels(t).FactCardCollection,
	},
	Film: {
		id: 'upsertFilm',
		type: 'Film',
		label: defaultElementLabels(t).Film,
	},
	Lesson: {
		id: 'upsertLesson',
		type: 'Lesson',
		label: defaultElementLabels(t).Lesson,
	},
	MasterClass: {
		id: 'upsertMasterClass',
		type: 'MasterClass',
		label: defaultElementLabels(t).MasterClass,
	},
	Trainer: {
		id: 'upsertTrainer',
		type: 'Trainer',
		label: defaultElementLabels(t).Trainer,
	},
	BookDownload: {
		id: 'upsertBookDownload',
		type: 'BookDownload',
		label: defaultElementLabels(t).BookDownload,
	},
	Summary: {
		id: 'upsertSummary',
		type: 'Summary',
		label: defaultElementLabels(t).Summary,
	},
	VideoBridge: {
		id: 'upsertVideoBridge',
		type: 'VideoBridge',
		label: defaultElementLabels(t).VideoBridge,
	},
	SlideshowCreator: {
		id: 'upsertSlideshowCreator',
		type: 'SlideshowCreator',
		label: defaultElementLabels(t).SlideshowCreator,
	},
	Test: {
		id: 'upsertTest',
		type: 'Test',
		label: defaultElementLabels(t).Test,
	},
	BettingGame: {
		id: 'upsertBettingGame',
		type: 'BettingGame',
		label: defaultElementLabels(t).BettingGame,
	},
	ExerciseCard: {
		id: 'upsertExerciseCard',
		type: 'ExerciseCard',
		label: defaultElementLabels(t).ExerciseCard,
	},
	EntryTest: {
		id: 'upsertEntryTest',
		type: 'EntryTest',
		label: defaultElementLabels(t).EntryTest,
	},
	Rating: {
		id: 'upsertRating',
		type: 'Rating',
		label: defaultElementLabels(t).Rating,
	},
	FinalRating: {
		id: 'upsertFinalRating',
		type: 'FinalRating',
		label: defaultElementLabels(t).FinalRating,
	},
	SelfEvaluation: {
		id: 'upsertSelfEvaluation',
		type: 'SelfEvaluation',
		label: defaultElementLabels(t).SelfEvaluation,
	},
	ExamTrainer: {
		id: 'upsertExamTrainer',
		type: 'ExamTrainer',
		label: defaultElementLabels(t).ExamTrainer,
	},
});

const elementNameAndSettingsKey = {
	BettingGame: SettingsKeys.ELEMENT_BETTING_GAME_ACTIVE,
	BookCheck: SettingsKeys.ELEMENT_BOOKCHECK_ACTIVE,
	BookDownload: SettingsKeys.ELEMENT_BOOK_DOWNLOAD_ACTIVE,
	Bridge: SettingsKeys.ELEMENT_BRIDGE_ACTIVE,
	Case: SettingsKeys.ELEMENT_CASE_ACTIVE,
	DigitalBook: SettingsKeys.ELEMENT_DIGITAL_BOOK_ACTIVE,
	EntryTest: SettingsKeys.ELEMENT_ENTRY_TEST_ACTIVE,
	ExamTrainer: SettingsKeys.ELEMENT_EXAM_TRAINING_ACTIVE,
	ExerciseCard: SettingsKeys.ELEMENT_EXERCISE_CARD_ACTIVE,
	FactCardCollection: SettingsKeys.ELEMENT_FACT_CARD_COLLECTION_ACTIVE,
	Film: SettingsKeys.ELEMENT_FILM_ACTIVE,
	Lesson: SettingsKeys.ELEMENT_LESSON_ACTIVE,
	MasterClass: SettingsKeys.ELEMENT_MASTERCLASS_ACTIVE,
	Rating: SettingsKeys.ELEMENT_RATING_ACTIVE,
	FinalRating: SettingsKeys.ELEMENT_FINAL_RATING_ACTIVE,
	SelfEvaluation: SettingsKeys.ELEMENT_SELF_EVALUATION_ACTIVE,
	SlideshowCreator: SettingsKeys.ELEMENT_SLIDESHOW_CREATOR_ACTIVE,
	Summary: SettingsKeys.ELEMENT_SUMMARY_ACTIVE,
	Test: SettingsKeys.ELEMENT_TEST_ACTIVE,
	Trainer: SettingsKeys.ELEMENT_TRAINER_ACTIVE,
	VideoBridge: SettingsKeys.ELEMENT_VIDEO_BRIDGE_ACTIVE,
};

export const allElementTypes = (t) => {
    const types = elementTypes(t);

    const list = [
        types.BookCheck,
        types.Bridge,
        types.Case,
        types.DigitalBook,
        types.ExamTrainer,
		types.ExternalLinkCollection,
        types.FactCardCollection,
        types.Film,
        types.Lesson,
        types.MasterClass,
        types.Trainer,
        types.BookDownload,
        types.Summary,
        types.VideoBridge,
        types.SlideshowCreator,
        types.Test,
        types.BettingGame,
        types.ExerciseCard,
        types.EntryTest,
        types.Rating,
        types.FinalRating,
        types.SelfEvaluation,
    ];

    // Sort by label alphabetically ascending
    return list.sort((a, b) => {
        const _a = a.label.toUpperCase();
        const _b = b.label.toUpperCase();

        if (_a > _b) return 1;
        else if (_a < _b) return -1;
        else return 0;
    });
};

export const activeElementTypes = (settingsData, t) => {
    const settings = settingsData && settingsData.settings && settingsData.settings.settings;
    if (settings) {
        return allElementTypes(t).filter(item => {
            // Loop through all elements and try to get the corresponding setting
            for (let key of Object.keys(elementNameAndSettingsKey)) {
                if (item.type === key &&
                    settings.find(setting => setting.name === elementNameAndSettingsKey[key]) &&
                    settings.find(setting => setting.name === elementNameAndSettingsKey[key]).value === "false") {
                    // The setting has been set to "false", meaning the element is not active and should not be returned
                    return false;
                }
            }

            return true;
        });
    }

    return [];
};
