"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.useStyles = undefined;

var _core = require("@material-ui/core");

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var useStyles = exports.useStyles = (0, _core.makeStyles)(function (theme) {
	return {
		dialog: {
			textAlign: "center"
		},
		contentWrap: _defineProperty({}, theme.breakpoints.down("md"), {
			padding: "20px"
		}),
		title: {
			marginBottom: "15px"
		},
		closeButton: {
			position: "absolute",
			right: "15px",
			top: "20px"
		},
		button: {
			width: "100%",
			minHeight: "60px"
		},
		select: {
			width: "100%"
		}
	};
});