'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.getGroup = exports.SELECT_GROUP_ROOT = undefined;

var _reselect = require('reselect');

var _selectors = require('../selectors');

var SELECT_GROUP_ROOT = exports.SELECT_GROUP_ROOT = 'selectGroup';

var getState = (0, _reselect.createSelector)([_selectors.getCoreState], function (coreState) {
    return coreState[SELECT_GROUP_ROOT];
});

var getGroup = exports.getGroup = (0, _reselect.createSelector)([getState], function (state) {
    return state.group;
});