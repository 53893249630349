import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';
import Done from '@material-ui/icons/Done';
import Typography from '@material-ui/core/Typography';
import Media from 'react-media';

const styles = (theme) => ({
    container: {
        width: '100%',
        maxWidth: '650px',
    },
    button: {
        display: 'inline',
        width: '642px',
        height: '80px',
        backgroundColor: '#000000',
        color: '#FFFFFF',
        border: '1px solid #FDC228',
        textAlign: 'left',
    },
    buttonMobile: {
        display: 'inline',
        width: '342px',
        height: '80px',
        backgroundColor: '#000000',
        color: '#FFFFFF',
        border: '1px solid #FDC228',
        textAlign: 'left',
        marginBottom: '32px !important',
    },
    buttonMarkup: {
        height: '20px',
        color: '#FFFFFF',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '1px',
        lineHeight: '24px',
        marginLeft: '40px',
    },
    image: {
        paddingLeft: theme.spacing(1),
        width: '212px',
        height: '140px',
        objectFit: 'cover',
        fontFamily: "'object-fit: cover'",
    },
    imageMobile: {
        width: '342px',
        height: '275px',
        objectFit: 'cover',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    question: {
        width: '100%',
        maxWidth: '650px',
        display: 'inline-block',
        userSelect: 'none',
    },
    smallContainer: {
        display: 'flex',
        float: 'left',
        height: '156px',
        maxWidth: '400px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    smallContainerMobile: {
        display: 'initial',
    },
    imageText: {
        marginLeft: '48px',
        fontSize: '16px',
    },
    seperator: {
        height: theme.spacing(1),
    },
    devider: {
        marginBottom: theme.spacing(2),
    },
    mobileDone: {
        position: 'absolute',
        marginTop: '-165px',
        marginLeft: '159px',
        width: '50px',
        height: '50px',
        textAlign: 'center',
        lineHeight: '53px',
        backgroundColor: '#FFFFFF',
    },
    mobileDoneIcon: {
        width: '12px',
        height: '12px',
    },
});

class ImageSingleChoiceText extends React.Component {
    state = {
        value: 0,
        selectedAnswers: new Map(),
    };

    handleClick = (id, isMobile) => {
        let element = document.getElementById(id);

        if (isMobile) {
            let elementDone = document.getElementById('done' + id);
            elementDone.style.display = 'initial';

            this.props.questions.forEach((e) => {
                if (e.id !== id) {
                    document.getElementById('done' + e.id).style.display =
                        'none';
                }
            });
        } else {
            element.style.outline = '2px solid #000000';

            this.props.questions.forEach((e) => {
                if (e.id !== id) {
                    document.getElementById(e.id).style.outline = '0px';
                }
            });
        }

        this.setState({
            value: id,
        });
    };

    handleSubmit = (event) => {
        if (this.state.value !== 0) {
            const value = this.state.value - 1;

            if (this.props.questions[value].answer) {
                // Right answer
                return true;
            } else {
                // Wrong answer
                return false;
            }
        } else {
            // No answer
            return false;
        }
    };

    render() {
        const { classes, buttonText, questions } = this.props;

        return (
            <div className={classes.container}>
                {questions.map((e) => {
                    return (
                        <div className={classes.devider}>
                            <Media query="(max-width: 860px)">
                                {(matches) =>
                                    matches ? (
                                        <div
                                            className={classes.question}
                                            onClick={() =>
                                                this.handleClick(e.id, true)
                                            }
                                            id={e.id}
                                            data-clicked="false"
                                        >
                                            <div
                                                className={
                                                    classes.smallContainerMobile
                                                }
                                            >
                                                <img
                                                    className={
                                                        classes.imageMobile
                                                    }
                                                    src={e.image}
                                                />

                                                <div
                                                    id={'done' + e.id}
                                                    className={
                                                        classes.mobileDone
                                                    }
                                                    onClick={() =>
                                                        this.handleClick(
                                                            e.id,
                                                            true
                                                        )
                                                    }
                                                    style={{ display: 'none' }}
                                                >
                                                    <Done
                                                        className={
                                                            classes.mobileDoneIcon
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={classes.question}
                                            onClick={() =>
                                                this.handleClick(e.id, false)
                                            }
                                            id={e.id}
                                            data-clicked="false"
                                        >
                                            <div
                                                className={
                                                    classes.smallContainer
                                                }
                                            >
                                                <img
                                                    className={classes.image}
                                                    src={e.image}
                                                />
                                            </div>

                                            <div
                                                className={
                                                    classes.smallContainer
                                                }
                                                onClick={() =>
                                                    this.handleClick(
                                                        e.id,
                                                        false
                                                    )
                                                }
                                            >
                                                <Typography
                                                    className={
                                                        classes.imageText
                                                    }
                                                >
                                                    {e.question}
                                                </Typography>
                                            </div>
                                        </div>
                                    )
                                }
                            </Media>
                        </div>
                    );
                })}

                <div className={classes.seperator}></div>

                <Media query="(max-width: 860px)">
                    {(matches) =>
                        matches ? (
                            // Mobile
                            <div style={{ textAlign: 'center' }}>
                                <button
                                    className={classes.buttonMobile}
                                    onClick={this.handleSubmit}
                                >
                                    <div style={{ float: 'left' }}>
                                        <p className={classes.buttonMarkup}>
                                            {buttonText.toUpperCase()}
                                        </p>
                                    </div>
                                    <div style={{ float: 'right' }}>
                                        <p style={{ marginRight: '40px' }}>
                                            <ArrowRightAlt />
                                        </p>
                                    </div>
                                </button>
                            </div>
                        ) : (
                            <div style={{ textAlign: 'right' }}>
                                <button
                                    className={classes.button}
                                    onClick={this.handleSubmit}
                                >
                                    <div style={{ float: 'left' }}>
                                        <p className={classes.buttonMarkup}>
                                            {buttonText.toUpperCase()}
                                        </p>
                                    </div>
                                    <div style={{ float: 'right' }}>
                                        <p style={{ marginRight: '40px' }}>
                                            <ArrowRightAlt />
                                        </p>
                                    </div>
                                </button>
                            </div>
                        )
                    }
                </Media>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(ImageSingleChoiceText);
