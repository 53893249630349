import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { compose } from 'recompose';
import renderHTML from 'react-render-html';
import { ContentExpansionPanel } from '@manakin/core';

const styles = (theme) => ({
    wrapper: {
        ...theme.manakin.defaultWrapper,
        paddingTop: theme.manakin.defaultPadding,
        marginBottom: '3rem',
        opacity: 0,
        animation: 'showBackwards .3s ease 1.3s forwards',
        [theme.breakpoints.up('md')]: {
            minHeight: '240px',
            paddingTop: '12rem',
            marginBottom: '8rem',
        },
    },
    panelRoot: {
        backgroundColor: theme.manakin.defaultWorkformColor[500],
        minHeight: '100px',
        marginBottom: '.1rem',
        alignItems: 'center',
        padding: '2rem 2rem',
    },
    panelHeading: {
        margin: 0,
        fontSize: '2rem',
        color: theme.palette.getContrastText(
            theme.manakin.defaultWorkformColor[500]
        ),
        '& $header': {
            fontWeight: '600',
            lineHeight: '4rem',
        },
    },
    expansionPanelDetails: {},
    expansionPanelSummary: {},
    content: {
        color: theme.palette.getContrastText(
            theme.manakin.defaultWorkformColor[500]
        ),
        margin: 0,
    },
});

class Content extends React.PureComponent {
    state = {
        open: null,
    };

    handleExpansion = (id) => {
        this.setState((prevState) => ({
            open: prevState.open === id ? null : id,
        }));
    };

    render() {
        const { classes, data, theme } = this.props;
        const { open } = this.state;

        return (
            <div className={classes.root}>
                <div className={classes.wrapper}>
                    {data &&
                        data.items &&
                        data.items.map((item) => (
                            <ContentExpansionPanel
                                key={item.id}
                                stackingMode={true}
                                expanded={item.id === open}
                                onExpansion={() =>
                                    this.handleExpansion(item.id)
                                }
                                title={item.question}
                                classes={{
                                    root: classes.panelRoot,
                                    heading: classes.panelHeading,
                                    expansionPanelDetails:
                                        classes.expansionPanelDetails,
                                    expansionPanelSummary:
                                        classes.expansionPanelSummary,
                                }}
                                content={
                                    <div className={classes.panel}>
                                        <Typography
                                            component="div"
                                            variant="body1"
                                            classes={{ body1: classes.content }}
                                        >
                                            {renderHTML(item.answer || '')}
                                        </Typography>
                                    </div>
                                }
                            />
                        ))}
                </div>
            </div>
        );
    }
}

export default compose(
    withStyles(styles, { withTheme: true, name: 'AccordionFaq' })
)(Content);
