export const styles = (theme) => ({
	root: {
		width: "100%",
		overflowX: "hidden",
	},
	hide: {
		display: "none",
	},
	wrapper: {
		[theme.breakpoints.up("md")]: {
			...theme.manakin.largeWrapper,
		},
	},
	largeWrapper: {
		[theme.breakpoints.up("md")]: {
			...theme.manakin.extraLargeWrapper,
			paddingLeft: "16px",
			paddingRight: "16px",
		},
	},
	normalMargin: {
		padding: "0 25px",
		margin: "3rem 0",
		[theme.breakpoints.up("md")]: {
			margin: "15rem 0",
		},
	},
	chatWrapper: {
		[theme.breakpoints.up("md")]: {
			...theme.manakin.largeWrapper,
			maxWidth: "1100px",
		},
	},
});
