import React from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import PercentageBar from './PercentageBar';
import { useStyles } from '../styles';

const Answers = (props) => {
	const { answers, showPollResults, value, data, pollResults } = props;
	const classes = useStyles();

	const handleChange = (event) => {
		if (props.onChange) props.onChange(event);
	};

	const getPollQuestionAnswerResult = (index) => {
		return pollResults.pollQuestionAnswerResults[index];
	};

	const isChecked = (item) => {
		if (pollResults && pollResults.selectedAnswer) {
			return pollResults.selectedAnswer.answer.id === item.id;
		} else {
			return false;
		}
	};

	return (
		<div>
			<FormControl component="fieldset" className={classes.formControl} disabled={showPollResults}>
				<RadioGroup name="textMPC" onChange={handleChange} value={value}>
					{answers &&
						answers.map((item, index) => (
							<React.Fragment key={item.id}>
								<FormControlLabel
									key={item.id}
									classes={{
										root: classes.formControlRoot,
										label: classes.formControlLabel,
									}}
									className={{'active': isChecked(item)}}
									control={<Radio className={'radio-hidden'} />}
									label={item.title}
									value={item.id}
								/>
								{showPollResults && <PercentageBar percentage={Math.floor(getPollQuestionAnswerResult(index).percentage * 100)} />}
							</React.Fragment>
						))}
				</RadioGroup>
			</FormControl>
		</div>
	);
};

export default Answers;
