import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiTableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { withTranslation } from "react-i18next";
import { compose } from 'recompose';

const styles = (theme) => ({
    root: {},
});

class TableHead extends React.Component {
    handleSort = (columnId) => (event) => {
        this.props.onSort(event, columnId);
    };

    handleCheckboxClick = (event) => {
        const {
            selectedCount,
            rowCount,
            onDeselectAll,
            onSelectAll,
            onSelectVisible,
        } = this.props;

        if (
            (selectedCount > 0 && selectedCount < rowCount) ||
            selectedCount == rowCount
        ) {
            onDeselectAll();
        } else if (selectedCount === 0) {
            onSelectVisible();
        }
    };

    render() {
        const {
            onSelectAll,
            order,
            orderBy,
            selectedCount,
            rowCount,
            columns,
            t,
            hideCheckBoxes
        } = this.props;

        return (
            <MuiTableHead>
                <TableRow>
                    {!hideCheckBoxes && (
                        <TableCell padding='checkbox'>
                            {rowCount > 0 && (
                                <Checkbox
                                    indeterminate={
                                        selectedCount > 0 &&
                                        selectedCount < rowCount
                                    }
                                    checked={selectedCount === rowCount}
                                    onClick={this.handleCheckboxClick}
                                    color='default'
                                />
                            )}
                        </TableCell>
                    )}

                    {columns.allIds.map((columnId) => {
                        const column = columns.byId[columnId];
                        return (
                            <TableCell
                                key={column.id}
                                numeric={column.numeric}
                                padding={
                                    column.disablePadding ? 'none' : 'default'
                                }
                                sortDirection={
                                    orderBy === column.id ? order : false
                                }
                            >
                                {column.disableSorting ? (
                                    <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={order}
                                        style={{ cursor: 'initial' }}
                                    >
                                        {column.label}
                                    </TableSortLabel>
                                ) : (
                                    <Tooltip
                                        title={t("common.sort")}
                                        placement={
                                            column.numeric
                                                ? 'bottom-end'
                                                : 'bottom-start'
                                        }
                                        enterDelay={300}
                                    >
                                        <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={order}
                                            onClick={this.handleSort(column.id)}
                                        >
                                            {column.label}
                                        </TableSortLabel>
                                    </Tooltip>
                                )}
                            </TableCell>
                        );
                    })}
                </TableRow>
            </MuiTableHead>
        );
    }
}

export default compose(
    withStyles(styles, { name: 'CoreTableHead' }),
    withTranslation()
)(TableHead);
