import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox, Typography } from '@material-ui/core';
import { ButtonGroup } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import { useStyles } from '../styles/index';
import { useTranslation } from 'react-i18next';

const OpenQuestionCheckboxFeedback = (props) => {
	const { buttonDisabled, checkboxAnswers, onChangeCheckbox, onClick, onChangeAnswerClick, data = {} } = props;
	const classes = useStyles();
	const backgroundColor = data.backgroundColor || null;
	const { t } = useTranslation();

	return (
		<div>
			<Typography>{t('app.workforms.open-question.check-answer')}</Typography>
			<FormControl component="fieldset" className={classes.formControl}>
				<FormGroup>
					{checkboxAnswers.map((item, idx) => (
						<FormControlLabel
							key={item.id}
							classes={{
								root: classes.formControlRoot,
								label: classes.formControlLabel,
							}}
							className={classNames({
								[classes.strike]: item.checked || item.selected,
								[classes.primary]: backgroundColor == 'PRIMARY',
								[classes.secondary]: backgroundColor == 'SECONDARY'
							})}
							disabled={buttonDisabled}
							control={
								<Checkbox
									checked={item.checked || item.selected || false}
									onChange={onChangeCheckbox(idx)}
									value={item.id}
									icon={<div className={classes.outer} />}
									checkedIcon={
										<div className={classes.outer}>
											<div className={classes.inner} />
										</div>
									}
								/>
							}
							label={item.title}
						/>
					))}
				</FormGroup>
			</FormControl>

			<FormControl component="fieldset" className={classes.formControl}>
				<ButtonGroup fullWidth className={classes.buttonGroup}>
					<Button disabled={buttonDisabled} variant="contained" color="primary" fullWidth onClick={onClick}>
						{t('common.ok')}
					</Button>
					<Button disabled={buttonDisabled} variant="contained" color="primary" fullWidth onClick={onChangeAnswerClick}>
						{t('common.adjust')}
					</Button>
				</ButtonGroup>
			</FormControl>
		</div>
	);
};

export default OpenQuestionCheckboxFeedback;
