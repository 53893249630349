"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _styles = require("@material-ui/core/styles");

var _recompose = require("recompose");

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _FormControl = require("@material-ui/core/FormControl");

var _FormControl2 = _interopRequireDefault(_FormControl);

var _FormControlLabel = require("@material-ui/core/FormControlLabel");

var _FormControlLabel2 = _interopRequireDefault(_FormControlLabel);

var _Checkbox = require("@material-ui/core/Checkbox");

var _Checkbox2 = _interopRequireDefault(_Checkbox);

var _FormGroup = require("@material-ui/core/FormGroup");

var _FormGroup2 = _interopRequireDefault(_FormGroup);

var _Check = require("@material-ui/icons/Check");

var _Check2 = _interopRequireDefault(_Check);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
	return {
		formControlRoot: _defineProperty({
			width: "100%",
			minHeight: "10rem",
			padding: "1rem",
			borderTop: "1px solid " + theme.manakin.defaultBorderColor[500],
			margin: "0",
			"&:last-child": {
				borderBottom: "1px solid " + theme.manakin.defaultBorderColor[500]
			}
		}, theme.breakpoints.up("md"), {
			padding: "1rem 3.4rem",
			borderLeft: "1px solid " + theme.manakin.defaultBorderColor[500],
			borderRight: "1px solid " + theme.manakin.defaultBorderColor[500]
		}),
		active: {
			backgroundColor: theme.manakin.correctAnswer[500],
			"& $formControlLabel": {
				color: theme.palette.getContrastText(theme.manakin.correctAnswer[500])
			}
		},
		formControlLabel: _defineProperty({
			color: theme.manakin.defaultContentColor[500],
			fontFamily: theme.manakin.defaultAnswerFont,
			fontSize: "1.6rem",
			lineHeight: "3.2rem",
			margin: "0 0 0 1rem"
		}, theme.breakpoints.up("md"), {
			margin: "0 0 0 3rem",
			fontSize: "1.8rem",
			lineHeight: "3.8rem"
		}),
		formControl: {
			marginBottom: "2rem"
		},
		correct: {
			border: "2px solid black",
			borderBottom: "2px solid black!important",
			"& $formControlLabel": {
				color: theme.manakin.defaultContentColor[500]
			}
		},
		outer: {
			border: "1px solid " + theme.manakin.defaultBorderColor[500],
			backgroundColor: "white",
			width: "30px",
			height: "30px",
			display: "flex",
			alignItems: "center",
			justifyContent: "center"
		},
		inner: {
			color: theme.manakin.defaultContentColor[500],
			width: "14px",
			height: "14px"
		}
	};
};

var CheckboxGroup = function CheckboxGroup(props) {
	var classes = props.classes,
	    classNameProp = props.className,
	    answers = props.answers,
	    _props$done = props.done,
	    done = _props$done === undefined ? false : _props$done,
	    _props$readOnly = props.readOnly,
	    readOnly = _props$readOnly === undefined ? false : _props$readOnly;


	var handleChange = (0, _react.useCallback)(function (index, event) {
		var selected = event.target.checked;
		props.onChange(index, selected);
	}, [props.onChange]);

	return _react2.default.createElement(
		_FormControl2.default,
		{
			component: "fieldset",
			className: (0, _classnames2.default)(classes.formControl, classNameProp),
			disabled: done
		},
		_react2.default.createElement(
			_FormGroup2.default,
			null,
			answers && answers.map(function (item, index) {
				var _classNames;

				return _react2.default.createElement(_FormControlLabel2.default, {
					key: item.id,
					classes: {
						root: classes.formControlRoot,
						label: classes.formControlLabel
					},
					className: (0, _classnames2.default)((_classNames = {}, _defineProperty(_classNames, classes.active, answers[index] && answers[index].selected), _defineProperty(_classNames, classes.correct, done && item.correct), _classNames)),
					control: _react2.default.createElement(_Checkbox2.default, {
						key: item.id,
						disabled: readOnly,
						checked: answers[index] && answers[index].selected || false,
						onChange: function onChange(event) {
							return handleChange(index, event);
						},
						value: item.id,
						icon: _react2.default.createElement("div", { className: classes.outer }),
						checkedIcon: _react2.default.createElement(
							"div",
							{ className: classes.outer },
							_react2.default.createElement(_Check2.default, { className: classes.inner })
						)
					}),
					label: item.title
				});
			})
		)
	);
};

exports.default = (0, _recompose.compose)((0, _styles.withStyles)(styles, { name: "CheckboxGroup" }))(CheckboxGroup);