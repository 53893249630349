import React from 'react';
import { ElementOverlay } from '@manakin/app-core';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';
import History from './History';
import { useTranslation } from 'react-i18next';
import { GQL_FETCH_GLOBAL_SETTINGS } from './graphql';
import { useQuery } from '@apollo/react-hooks';
import { useSetting } from '@manakin/hooks';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { SettingsKeys } from '@manakin/core/lib/constants';

const Results = (props) => {
	const { title, variantTitle, grade, history, onReset, onCheck, customControls = false, onClick, failureTitle, successTitle } = props;
	const classes = useStyles();
	const { t } = useTranslation();

	const { data = {} } = useQuery(GQL_FETCH_GLOBAL_SETTINGS);
	const minGrade = parseFloat(useSetting(data.settings, SettingsKeys.MIN_GRADE) || 5.5);

	return (
		<ElementOverlay
			variant="entryTest"
			title={variantTitle || ''}
			controls={customControls ? false : true}
			customControls={customControls}
			onClick={onClick}
		>
			<div className={classes.root}>
				<div className={classNames(classes.wrapper, classes.header)}>
					<Typography variant="h4" component="h1">
						{title || t('common.results-page.your-score')}
					</Typography>
					<Typography variant="h4" component="h2">
						{grade || ''}
					</Typography>
					{parseFloat(minGrade) < parseFloat(grade) && (
						<Typography variant="h3">{successTitle || title || t('common.results-page.success-message')}</Typography>
					)}
					{parseFloat(minGrade) >= parseFloat(grade) && (
						<Typography variant="h3">{failureTitle || title || t('common.results-page.failed-message')}</Typography>
					)}
					<div className={classes.buttons}>
						<Button className={classes.retryButton} variant="outlined" color="primary" onClick={onReset}>
							{t('common.retry')}
						</Button>
						<Button variant="outlined" color="primary" onClick={onCheck}>
							{t('common.check-answers')}
						</Button>
					</div>
				</div>
				{history && <History history={history} />}
			</div>
		</ElementOverlay>
	);
};

export default Results;
