import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { useStyles } from './styles';
import {useTranslation} from 'react-i18next';

const AppRegistrationSuccess = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <form className={classes.container}>
                <div className={classes.content}>
                    <Typography
                        classes={{ body1: classes.smallParagraph }}
                        variant="body1"
                        gutterBottom
                    >
                        {t("authentication.registration.success.title")}
                    </Typography>
                    <Typography className={classes.heading} variant="h2">
                        {t("authentication.registration.success.description")}
                    </Typography>
                    <Typography
                        classes={{ body1: classes.paragraph }}
                        variant="body1"
                        gutterBottom
                    >
                        {t("authentication.registration.success.thanks-note", { whitelabel: t("common.whitelabel") })}
                    </Typography>
                </div>
                <div className={classes.buttonGroup}>
                    <Link to={'/'}>
                        <Button
                            variant="contained"
                            classes={{ root: classes.buttonRoot }}
                            color="primary"
                        >
                            {t("authentication.registration.success.action")}
                        </Button>
                    </Link>
                </div>
            </form>
        </div>
    );
};

export default AppRegistrationSuccess;
