import React from 'react';
import Slideshow from './Slideshow';
import { GQL_FETCH_SLIDESHOW } from './graphql';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';

class SlideShowContainer extends React.PureComponent {
    state = {
        slides: [],
        loading: true,
    };

    componentDidUpdate(prevProps) {
        if (prevProps.data.loading && !this.props.data.loading) {
            const { data } = this.props;
            const { slideshow } = data;

            if (slideshow.slides) {
                const slides = slideshow.slides.map((slide) => ({
                    type: slide.asset ? 'image' : 'text',
                    data: {
                        title: slide.title || '',
                        text: slide.text || '',
                        src: slide.asset ? slide.asset.url : '',
                    },
                }));

                this.setState({
                    slides,
                    loading: false,
                });
            }
        }
    }

    render() {
        const { loading } = this.state;

        if (loading) {
            return <div>loading</div>;
        } else {
            return (
                <div>
                    <Slideshow {...this.state} />
                </div>
            );
        }
    }
}

export default compose(
    graphql(GQL_FETCH_SLIDESHOW, {
        options: (props) => ({
            variables: { id: props.match.params.elementId },
        }),
    })
)(SlideShowContainer);
