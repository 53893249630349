import React, { useEffect, useState } from 'react';
import { withStyles, Typography } from '@material-ui/core';
import { BoxInfo } from '../../parts';
import { GQL_FETCH_PROGRAM } from './graphql';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { Loader } from '@manakin/app-core';
import { getProgram } from '@manakin/app-core/ProgramsDropdown/selectors';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
	root: {
		backgroundColor: theme.manakin.defaultElementBGColor[500],
		padding: '50px 0',
	},
	smallWrapper: {
		...theme.manakin.smallWrapper,
	},
	wrapper: {
		...theme.manakin.defaultWrapper,
		maxWidth: '1250px',
	},
	boxes: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			flexWrap: 'wrap',
		},
	},
	box: {
		marginBottom: '1rem',
		[theme.breakpoints.up('md')]: {
			width: 'calc(50% - 40px)',
			margin: '0 2rem 2rem',
		},
	},
	body: {
		fontWeight: 'bold',
		textAlign: 'center',
		marginBottom: '6rem',
		lineHeight: '3.8rem',
	},
	additionalPoints: {
		color: theme.palette.getContrastText(
			theme.manakin.defaultElementBGColor[500]
		),
	},
});

const Body = (props) => {
    const { classes, data, boxId } = props;
    const { loading, program = {} } = data;
    const { boxes = [] } = program;
    const { t } = useTranslation();

    //use state
    const [boxData, setBoxData] = useState([]);
    const [points, setPoints] = useState(0);
    let _points = 0;
    //effect hooks
    useEffect(() => {
        if (!loading && boxes != undefined) {
            let arr = [];
            boxes.forEach((_box) =>
                _box.forEach((box) => {
                    if (box.id !== boxId) arr.push(box);
                })
            );
            setBoxData(arr);
        }
        if (!loading) {
            props.stoppedLoading();
        }
    }, [loading]);

    useEffect(() => {
        if (props.onAddPoints) props.onAddPoints(points);
    }, [points]);

    //function
    const handleSetPoints = (data) => {
        _points += data;
        setTimeout(() => {
            setPoints(_points);
        }, 100);
    };

    if (loading) {
        return <Loader fullScreen={true} />;
    }
    return (
        <div className={classes.root}>
            <div className={classes.smallWrapper}>
                <Typography variant="body2" className={classes.body}>
                    {t("app.elements.betting-game.front-page.intro")}{" "}
                    <span className={classes.additionalPoints}>
                        {t("app.elements.betting-game.amount-points", { points: points })}
                    </span>
                </Typography>
            </div>
            <div className={classes.wrapper}>
                <div className={classes.boxes}>
                    {boxData.map((box, idx) => (
                        <div className={classes.box} key={box.id}>
                            <BoxInfo
                                box={box}
                                count={idx + 1}
                                onSetPoints={handleSetPoints}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default compose(
    connect((state) => ({
        program: getProgram(state),
    })),
    withStyles(styles, { name: 'AppBettingGameFrontPageBody' }),
    graphql(GQL_FETCH_PROGRAM, {
        options: (props) => ({
            fetchPolicy: 'no-cache',
            variables: {
                id: props.program,
            },
        }),
    })
)(Body);
