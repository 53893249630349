import gql from "graphql-tag";

export const GQL_ADD_RESULT = gql`
	mutation addResultToRatingRequest($input: AddResultToRatingRequestInput!) {
		addResultToRatingRequest(input: $input) {
			ratingRequest {
				id
			}
		}
	}
`;

export const GQL_UPLOAD_ASSET = gql`
	mutation uploadAsset($file: Upload!) {
		uploadAsset(file: $file) {
			asset {
				id
			}
		}
	}
`;

export const GQL_RATING_REQUEST = gql`
	query ratingRequestById($id: ID!) {
		ratingRequestById(id: $id) {
			attempt
			finalRating
			id
			program {
				id
				name
			}
            result {
                ranking
                explanation
            }
			element {
				id
			}
			student {
				id
				fullName
			}
		}
	}
`;

export const GQL_RATING_REQUESTS = gql`
	query ratingRequests($programId: ID, $studentId: ID, $elementId: ID) {
		ratingRequests(
			pageNr: 0, 
			pageSize: 20, 
			filter: {
				programId: $programId, 
				studentId: $studentId, 
				elementId: $elementId, 
				supervisorRatings: false, 
				finalRatings: true
			}
		) {
			id
			result {
				ranking
				explanation
			}
		}
	}

`;
