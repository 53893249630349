import React from 'react';
import { WorkformLayout } from '@manakin/app-core';
import { useStyles } from './styles';
import classNames from 'classnames';
import Answers from './components/Answers';
import { useTranslation } from 'react-i18next';

const PollQuestion = (props) => {
	const classes = useStyles();
	const { data, loading } = props;
    const {t} = useTranslation()

	return (
		<div
			className={classNames(
				{ [classes.primary]: data.backgroundColor == 'PRIMARY' },
				{ [classes.secondary]: data.backgroundColor == 'SECONDARY' }
			)}
		>
			<WorkformLayout
				question={data.question || ''}
				instruction={data.introduction || t('app.workforms.poll-question.introduction')}
				loading={loading}
				renderAnswers={() => <Answers {...props} />}
			/>
		</div>
	);
};

export default PollQuestion;
