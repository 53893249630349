"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.GQL_FETCH_APPOINTMENTS = undefined;

var _templateObject = _taggedTemplateLiteral(["\n\tquery appointments($includePastAppointments: Boolean) {\n\t\tappointments(page: 0, pagesize: 999, direction: \"ASC\", sortProperties: [\"time\"], filter: { includePastAppointments: $includePastAppointments }) {\n\t\t\tcount\n\t\t\tappointments {\n\t\t\t\tid\n\t\t\t\tstudent {\n\t\t\t\t\tid\n\t\t\t\t\tfullName\n\t\t\t\t}\n\t\t\t\tsubject {\n\t\t\t\t\tid\n\t\t\t\t\tdescription\n\t\t\t\t\ttitle\n\t\t\t\t}\n\t\t\t\ttime\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tquery appointments($includePastAppointments: Boolean) {\n\t\tappointments(page: 0, pagesize: 999, direction: \"ASC\", sortProperties: [\"time\"], filter: { includePastAppointments: $includePastAppointments }) {\n\t\t\tcount\n\t\t\tappointments {\n\t\t\t\tid\n\t\t\t\tstudent {\n\t\t\t\t\tid\n\t\t\t\t\tfullName\n\t\t\t\t}\n\t\t\t\tsubject {\n\t\t\t\t\tid\n\t\t\t\t\tdescription\n\t\t\t\t\ttitle\n\t\t\t\t}\n\t\t\t\ttime\n\t\t\t}\n\t\t}\n\t}\n"]);

var _graphqlTag = require("graphql-tag");

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_APPOINTMENTS = exports.GQL_FETCH_APPOINTMENTS = (0, _graphqlTag2.default)(_templateObject);