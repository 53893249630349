"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _styles = require("@material-ui/styles");

var _Typography = require("@material-ui/core/Typography");

var _Typography2 = _interopRequireDefault(_Typography);

var _appCore = require("@manakin/app-core");

var _UploadBox = require("./parts/UploadBox");

var _UploadBox2 = _interopRequireDefault(_UploadBox);

var _Grid = require("@material-ui/core/Grid");

var _Grid2 = _interopRequireDefault(_Grid);

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var useStyles = (0, _styles.makeStyles)(function (theme) {
	var _root, _wrapper;

	return {
		root: (_root = {
			width: "100%",
			backgroundColor: "white",
			maxWidth: "100%",
			padding: "25px"
		}, _defineProperty(_root, theme.breakpoints.up("md"), {
			padding: "6rem"
		}), _defineProperty(_root, theme.breakpoints.up("lg"), {
			padding: "10rem 15rem"
		}), _root),
		wrapper: (_wrapper = {}, _defineProperty(_wrapper, theme.breakpoints.up("md"), {
			padding: 0,
			paddingRight: "100px"
		}), _defineProperty(_wrapper, "marginBottom", "3rem"), _wrapper),
		filesWrapper: _defineProperty({
			height: "100%",
			paddingRight: 0,
			display: "flex",
			flexDirection: "column"
		}, theme.breakpoints.up("md"), {
			paddingLeft: "50px"
		}),
		recentFeedbackHeader: _defineProperty({
			marginTop: "3rem"
		}, theme.breakpoints.up("md"), {
			marginBottom: "3rem"
		}),
		uploadBox: _defineProperty({}, theme.breakpoints.up("md"), {
			marginTop: "3rem",
			marginBottom: "3rem"
		}),
		subHeader: {},
		header: {},
		contentColor: {}
	};
});

var DocumentUpload = function DocumentUpload(props) {
	var _props$data = props.data,
	    data = _props$data === undefined ? {} : _props$data,
	    readOnly = props.readOnly,
	    hideDelete = props.hideDelete;

	var classes = useStyles();

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	return _react2.default.createElement(
		"div",
		{ className: classes.root },
		_react2.default.createElement(
			_Grid2.default,
			{ container: true },
			_react2.default.createElement(
				_Grid2.default,
				_extends({ item: true }, { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }),
				_react2.default.createElement(
					"div",
					{ className: classes.wrapper },
					_react2.default.createElement(
						_Typography2.default,
						{
							component: "h2",
							variant: "h3",
							className: (0, _classnames2.default)(classes.header, classes.contentColor)
						},
						t("app.document-upload.title")
					),
					_react2.default.createElement(
						_Typography2.default,
						{ component: "div", variant: "body1" },
						t("app.document-upload.description")
					),
					_react2.default.createElement(_appCore.WarningBox, {
						message: t("app.document-upload.warning")
					})
				)
			),
			_react2.default.createElement(
				_Grid2.default,
				_extends({ item: true }, { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }),
				_react2.default.createElement(
					"div",
					{
						className: (0, _classnames2.default)(classes.wrapper, classes.filesWrapper)
					},
					_react2.default.createElement(_UploadBox2.default, {
						data: data,
						readOnly: readOnly,
						className: classes.uploadBox,
						hideDelete: hideDelete
					})
				)
			)
		)
	);
};

exports.default = DocumentUpload;