import React from 'react';
import { isSettingEnabled } from './actions';

const SettingAccessControl = (props) => {
	const { name, children } = props;

	if (isSettingEnabled(name)) {
		return <React.Fragment>{children}</React.Fragment>;
	}

	return '';
};

export default SettingAccessControl;
