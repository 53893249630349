"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _core = require("@material-ui/core");

var _reactRedux = require("react-redux");

var _parts = require("./parts");

var _graphql = require("./graphql");

var _selectors = require("@manakin/app-core/ProgramsDropdown/selectors");

var _reactHooks = require("@apollo/react-hooks");

var _selectors2 = require("@manakin/authentication/selectors");

var _lib = require("@manakin/app-core/lib");

var _Button = require("@material-ui/core/Button");

var _Button2 = _interopRequireDefault(_Button);

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var useStyles = (0, _core.makeStyles)(function (theme) {
	return {
		root: {},
		topBar: {
			display: "flex",
			width: "100%",
			justifyContent: "space-between",
			marginBottom: "30px"
		},
		textField: {
			maxWidth: "300px",
			width: "100%"
		},
		selectField: {
			maxWidth: "300px",
			width: "100%",
			marginTop: "16px"
		},
		loadMore: {
			marginLeft: "auto",
			marginRight: "auto",
			display: "block",
			marginTop: 24
		}
	};
});

var maxPageSize = 10;

var timer = null;
var AssignedStudentsOverview = function AssignedStudentsOverview(props) {
	var classes = useStyles();

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	//use state


	var _useState = (0, _react.useState)(""),
	    _useState2 = _slicedToArray(_useState, 2),
	    search = _useState2[0],
	    setSearch = _useState2[1];

	var _useState3 = (0, _react.useState)([]),
	    _useState4 = _slicedToArray(_useState3, 2),
	    students = _useState4[0],
	    setStudents = _useState4[1];

	var _useState5 = (0, _react.useState)(0),
	    _useState6 = _slicedToArray(_useState5, 2),
	    page = _useState6[0],
	    setPage = _useState6[1];

	var _useState7 = (0, _react.useState)(true),
	    _useState8 = _slicedToArray(_useState7, 2),
	    showMoreButton = _useState8[0],
	    setShowMoreButton = _useState8[1];

	//selectors


	var program = (0, _reactRedux.useSelector)(function (state) {
		return (0, _selectors.getProgram)(state);
	});
	var appUser = (0, _reactRedux.useSelector)(function (state) {
		return (0, _selectors2.getAppUser)(state);
	});
	var isTrainer = appUser && appUser.roles && appUser.roles.some(function (role) {
		return _lib.trainerRoles.includes(role.name) || _lib.managementRole.includes(role.name);
	});

	//queries

	var _useQuery = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_PROGRAM, {
		variables: {
			id: program
		}
	}),
	    programData = _useQuery.data;

	var _ref = programData || {},
	    _ref$program = _ref.program,
	    programObj = _ref$program === undefined ? {} : _ref$program;

	var _useLazyQuery = (0, _reactHooks.useLazyQuery)(_graphql.GQL_STUDENTS, {
		skip: !isTrainer,
		fetchPolicy: "no-cache",
		variables: {
			input: {
				page: page,
				pagesize: maxPageSize,
				programId: program,
				filter: {
					search: search
				}
			}
		},
		onCompleted: function onCompleted(data) {
			var _ref2 = data || {},
			    _ref2$invitationStude = _ref2.invitationStudentOverview,
			    invitationStudentOverview = _ref2$invitationStude === undefined ? [] : _ref2$invitationStude;

			setStudents([].concat(_toConsumableArray(students)).concat(invitationStudentOverview));

			if (invitationStudentOverview.length < maxPageSize || invitationStudentOverview.length === 0) {
				// No more students left, hide show more button
				setShowMoreButton(false);
			} else {
				if (showMoreButton === false) {
					setShowMoreButton(true);
				}
			}
		}
	}),
	    _useLazyQuery2 = _slicedToArray(_useLazyQuery, 1),
	    queryStudents = _useLazyQuery2[0];

	(0, _react.useEffect)(function () {
		queryStudents();
		return function () {
			return clearTimeout(timer);
		};
	}, []);

	(0, _react.useEffect)(function () {
		queryStudents();
	}, [search]);

	var handleChange = function handleChange(value) {
		clearTimeout(timer);
		timer = setTimeout(function () {
			setPage(0);
			setSearch(value);
			setStudents([]);
		}, 400);
	};

	var handleShowMore = function handleShowMore() {
		setPage(page + 1);
	};

	return _react2.default.createElement(
		"div",
		{ className: classes.root },
		_react2.default.createElement(
			"div",
			{ className: classes.topBar },
			_react2.default.createElement(
				"div",
				{ className: classes.textField },
				_react2.default.createElement(_parts.SearchField, { onChange: handleChange })
			),
			programObj ? programObj.name : "" || ''
		),
		_react2.default.createElement(
			"div",
			{ className: classes.list },
			_react2.default.createElement(_parts.List, { data: students }),
			showMoreButton && _react2.default.createElement(
				_Button2.default,
				{
					size: "small",
					color: "primary",
					variant: "contained",
					onClick: handleShowMore,
					className: classes.loadMore
				},
				t("common.load-more")
			)
		)
	);
};

exports.default = AssignedStudentsOverview;