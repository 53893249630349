import React, { useEffect, useState } from 'react';
import WebshopWrapper from './WebshopWrapper';
import GroupsData from './GroupsData';
import ProductsData from './ProductsData';
import { withForm } from '@manakin/core';
import { GroupsDataForm } from './forms';
import StepsContext from './context/stepsContext';
import ShoppingListContext from './context/shoppingListContext';
import PersonalData from './PersonalData';
import { useMutation, useQuery } from '@apollo/react-hooks';
import AuthData from './AuthData';
import { GQL_LOGIN_APP, GQL_FETCH_SETTINGS, GQL_REGISTER_APP_USER } from '../graphql';
import { useDispatch } from 'react-redux';
import { login } from '../actions';
import Cookies from 'js-cookie';
import { useSnackBar } from '@manakin/hooks';
import { getErrorMessage, WebshopSteps } from '../lib';
import { Loader } from '@manakin/app-core';
import { SettingsKeys } from '@manakin/core/lib/constants';
import { useTranslation } from 'react-i18next';

const formBundle = {
	...GroupsDataForm,
};

const AppWebshop = (props) => {
	const { form } = props;
	const dispatch = useDispatch();
	const snackBar = useSnackBar();
	const { t } = useTranslation();

	const productsFromCookie = Cookies.get('shop') ? JSON.parse(Cookies.get('shop')) || {} : {};

	const [step, setStep] = useState(0);
	const [disabled, setDisabled] = useState(0);
	const [products, setProducts] = useState(productsFromCookie);

	//set context value
	const value = { step, setStep, disabled, setDisabled };
	const shoppingListValue = { products, setProducts };

	const { loading, data = {} } = useQuery(GQL_FETCH_SETTINGS);
	const { settings: _settings } = data || {};
	const { settings = [] } = _settings || {};
	let role = null;
	settings.forEach((setting) => {
		if (setting.name === SettingsKeys.STANDARD_ROLE) {
			role = setting.value;
		}
	});

	const [loginUser] = useMutation(GQL_LOGIN_APP, {
		onCompleted: (data) => {
			if (data && data.loginApp && data.loginApp.user) {
				dispatch(login('/auth/pay', data.loginApp.user));
			}
		},
	});

	const [createUser, { data: mutationData, error }] = useMutation(GQL_REGISTER_APP_USER);

	useEffect(() => {
		if (error) {
			if (error.code === 409 || error.message === 'GraphQL error: APP user already exists!') {
				snackBar.error(t('authentication.registration.email-already-exists'));
			} else {
				const message = getErrorMessage(error.message, t);
				snackBar.error(message);
			}
		} else if (mutationData) {
			form.onSubmit().then((data) => {
				const { password, email } = data;

				loginUser({
					variables: {
						password: password,
						email: email,
					},
				});
			});
		}
	}, [mutationData, error]);

	const handleSubmit = () => {
		form.onSubmit().then((data) => {
			const { secondPassword, secondEmail, ...otherProps } = data;

			createUser({
				variables: {
					...otherProps,
					roles: [role],
				},
			});
		});
	};

	if (loading) return <Loader fullscreen />;
	return (
		<StepsContext.Provider value={value}>
			<ShoppingListContext.Provider value={shoppingListValue}>
				<WebshopWrapper form={form} onSubmit={handleSubmit}>
					{step === WebshopSteps.GROUPS && <GroupsData form={form} />}
					{step === WebshopSteps.PRODUCTS && <ProductsData form={form} />}
					{step === WebshopSteps.PERSONAL && <PersonalData form={form} />}
					{step === WebshopSteps.AUTH && <AuthData form={form} />}
				</WebshopWrapper>
			</ShoppingListContext.Provider>
		</StepsContext.Provider>
	);
};

export default withForm(formBundle)(AppWebshop);
