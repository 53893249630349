'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.getPropName = exports.getQuery = undefined;

var _graphql = require('../graphql');

var getQuery = exports.getQuery = function getQuery(type) {
	if (type == 'HotspotMap') return _graphql.GQL_FETCH_HOTSPOTMAP;
	if (type == 'Hotspot') return _graphql.GQL_FETCH_HOTSPOT;
	if (type == 'ImageAndTextMPC') return _graphql.GQL_FETCH_IMAGE_AND_TEXT_MPC;
	if (type == 'TextMPC') return _graphql.GQL_FETCH_TEXT_MPC;
	if (type == 'TextMPCWithImage') return _graphql.GQL_FETCH_TEXT_MPC_WITH_IMAGE;
	if (type == 'ShortText') return _graphql.GQL_FETCH_SHORT_TEXT;
	if (type == 'VideoWithTitle') return _graphql.GQL_FETCH_VIDEO_WITH_TITLE;
	if (type == 'ImageMPC') return _graphql.GQL_FETCH_IMAGE_MPC;
	if (type == 'FactCardReferral') return _graphql.GQL_FETCH_FACTCARD_REFERRAL;
	if (type == 'FactCardsReferral') return _graphql.GQL_FETCH_FACTCARDS_REFERRAL;
	if (type == 'OrderQuestion') return _graphql.GQL_FETCH_ORDER_QUESTION;
	if (type == 'Moodboard') return _graphql.GQL_FETCH_MOODBOARD;
	if (type == 'TextCheckQuestion') return _graphql.GQL_FETCH_TEXT_CHECK_QUESTION;
	if (type == 'ImageCheckQuestion') return _graphql.GQL_FETCH_IMAGE_CHECK_QUESTION;
	if (type == 'ImageAndTextCheckQuestion') return _graphql.GQL_FETCH_TEXT_IMAGE_CHECK_QUESTION;
	if (type == 'StatementQuestion') return _graphql.GQL_FETCH_STATEMENT_QUESTION;
	if (type == 'ImageWithSubtitle') return _graphql.GQL_FETCH_IMAGE_WITH_SUBTITLE;
	if (type == 'WhatIsWhatQuestion') return _graphql.GQL_FETCH_WHAT_IS_WHAT_QUESTION;
	if (type == 'TitleAndText') return _graphql.GQL_FETCH_TITLE_AND_TEXT;
	if (type == 'TitleAndTextImage') return _graphql.GQL_FETCH_TITLE_AND_TEXT_IMAGE;
	if (type == 'FileDownload') return _graphql.GQL_FETCH_CMS_WORK_FORM_FILE_DOWNLOAD;
	if (type == 'SortQuestion') return _graphql.GQL_FETCH_SORT_QUESTION;
	if (type == 'ChatBubble') return _graphql.GQL_FETCH_CHAT_BUBBLE;
	if (type == 'Scene') return _graphql.GQL_FETCH_SCENE;
	if (type == 'ChoiceQuestion') return _graphql.GQL_FETCH_CHOICE_QUESTION;
	if (type == 'ReferralElement') return _graphql.GQL_FETCH_ELEMENT_REFERRAL;
	if (type == 'Roadmap') return _graphql.GQL_FETCH_ROADMAP;
	if (type == 'OpenQuestion') return _graphql.GQL_FETCH_OPEN_QUESTION;
	if (type == 'PollQuestion') return _graphql.GQL_FETCH_POLL_QUESTION;
	if (type === 'ProgressionFeedback') return _graphql.GQL_FETCH_PROGRESSION_FEEDBACK;
	if (type === 'AskFeedback') return _graphql.GQL_FETCH_ASK_FEEDBACK;
	if (type === 'Criteria') return _graphql.GQL_FETCH_CRITERIA;
	if (type === 'ProcessStep') return _graphql.GQL_FETCH_PROCESS_STEP;
	if (type === 'ExternalLink') return _graphql.GQL_FETCH_EXTERNAL_LINK;
	return false;
};

var getPropName = exports.getPropName = function getPropName(type) {
	if (type == 'Hotspot') return 'hotspot';
	if (type == 'HotspotMap') return 'hotspotMap';
	if (type == 'ImageAndTextMPC') return 'imageAndTextMPC';
	if (type == 'TextMPC') return 'textMPC';
	if (type == 'TextMPCWithImage') return 'textMPCWithImage';
	if (type == 'ShortText') return 'shortText';
	if (type == 'VideoWithTitle') return 'videoWithTitle';
	if (type == 'ImageMPC') return 'imageMPC';
	if (type == 'FactCardReferral') return 'factCardReferral';
	if (type == 'FactCardsReferral') return 'factCardsReferral';
	if (type == 'OrderQuestion') return 'orderQuestion';
	if (type == 'Moodboard') return 'moodboard';
	if (type == 'TextCheckQuestion') return 'textCheckQuestion';
	if (type == 'ImageCheckQuestion') return 'imageCheckQuestion';
	if (type == 'ImageAndTextCheckQuestion') return 'imageAndTextCheckQuestion';
	if (type == 'StatementQuestion') return 'statementQuestion';
	if (type == 'ImageWithSubtitle') return 'imageWithSubtitle';
	if (type == 'WhatIsWhatQuestion') return 'whatIsWhatQuestion';
	if (type == 'TitleAndText') return 'titleAndText';
	if (type == 'TitleAndTextImage') return 'titleAndTextImage';
	if (type == 'FileDownload') return 'fileDownload';
	if (type == 'SortQuestion') return 'sortQuestion';
	if (type == 'ChoiceQuestion') return 'choiceQuestion';
	if (type == 'ChatBubble') return 'chatBubble';
	if (type == 'Scene') return 'scene';
	if (type == 'ReferralElement') return 'referralElement';
	if (type == 'Roadmap') return 'roadmap';
	if (type == 'OpenQuestion') return 'openQuestion';
	if (type == 'PollQuestion') return 'pollQuestion';
	if (type == 'ProgressionFeedback') return 'progressionFeedback';
	if (type == 'AskFeedback') return 'askFeedback';
	if (type == 'Criteria') return 'criteria';
	if (type == 'ProcessStep') return 'processStep';
	if (type == 'ExternalLink') return 'externalLink';
	return false;
};