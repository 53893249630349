import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import Filter from './Filter';
import { ProductsFilter } from '@manakin/app-core';
import FilterSortBoxes from './FilterSortBoxes';
import { SettingAccessControl } from '@manakin/core';
import { SettingsKeys } from '@manakin/core/lib/constants';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: "column",
		[theme.breakpoints.up('md')]: {
			flexDirection: "row",
			maxWidth: '70rem',
			justifyContent: 'space-between',
			'&>*': {
				width: '50%',
				maxWidth: '34rem',
			},
		},
		gap: 16
	},
}));

const Dropdowns = (props) => {
	const { onChange, appUser, activeTags = [], programData, onProgramBoxesFilterChange } = props;
	const classes = useStyles();
	const config = useSelector((state) => state.config);
	const { general = {} } = config || {};
	const { showFilters } = general;

	return (
		<div className={classes.root}>
			<ProductsFilter />
			{showFilters && activeTags.length > 0 && <Filter onChange={onChange} appUser={appUser} activeTags={activeTags} />}
            <SettingAccessControl name={SettingsKeys.SHOW_PROGRAM_BOXES_FILTER}>
                <FilterSortBoxes programData={programData} onChange={onProgramBoxesFilterChange} />
            </SettingAccessControl>
		</div>
	);
};

export default Dropdowns;
