'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.GQL_VIEW_ELEMENT = exports.GQL_FETCH_GLOBAL_SETTINGS = exports.GQL_FETCH_LOGGED_IN_USERS_INFO = exports.GQL_FETCH_LOCALES = exports.GQL_FETCH_BOX_CONDITIONS_BY_PROGRAM_ID = exports.GQL_GET_BOX_CONDITION = exports.GQL_FETCH_PROGRAM = undefined;

var _templateObject = _taggedTemplateLiteral(['\n    query($id: ID!) {\n        program(id: $id) {\n            id\n            name\n            description\n            tags {\n                id\n                name\n            }\n            learningTargetGroup {\n                id\n                name\n            }\n            image {\n                ', '\n            }\n            boxes {\n                id\n                name\n                description\n                image {\n                    ', '\n                }\n            }\n            author {\n                initials\n                lastName\n                roles {\n                    id\n                    name\n                }\n            }\n            createdOn\n            lastModifiedBy {\n                initials\n                lastName\n            }\n            lastModifiedOn\n        }\n    }\n'], ['\n    query($id: ID!) {\n        program(id: $id) {\n            id\n            name\n            description\n            tags {\n                id\n                name\n            }\n            learningTargetGroup {\n                id\n                name\n            }\n            image {\n                ', '\n            }\n            boxes {\n                id\n                name\n                description\n                image {\n                    ', '\n                }\n            }\n            author {\n                initials\n                lastName\n                roles {\n                    id\n                    name\n                }\n            }\n            createdOn\n            lastModifiedBy {\n                initials\n                lastName\n            }\n            lastModifiedOn\n        }\n    }\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n\tquery getBoxCondition($programId: ID!) {\n\t\tboxCondition(programId: $programId) {\n\t\t\tid\n\t\t\tprogramId\n\t\t\tblockedMessage\n\t\t\tgrantedMessage\n\t\t\tboxes\n\t\t\tfulfilled\n\t\t}\n\t}\n'], ['\n\tquery getBoxCondition($programId: ID!) {\n\t\tboxCondition(programId: $programId) {\n\t\t\tid\n\t\t\tprogramId\n\t\t\tblockedMessage\n\t\t\tgrantedMessage\n\t\t\tboxes\n\t\t\tfulfilled\n\t\t}\n\t}\n']),
    _templateObject3 = _taggedTemplateLiteral(['\n    query getBoxConditionsByProgramId($programId: ID!) {\n        boxConditionsByProgramId(programId: $programId) {\n            id\n\t\t\tprogramId\n            boxId\n\t\t\tblockedMessage\n\t\t\tgrantedMessage\n\t\t\tboxes\n\t\t\tfulfilled\n        }\n    }\n'], ['\n    query getBoxConditionsByProgramId($programId: ID!) {\n        boxConditionsByProgramId(programId: $programId) {\n            id\n\t\t\tprogramId\n            boxId\n\t\t\tblockedMessage\n\t\t\tgrantedMessage\n\t\t\tboxes\n\t\t\tfulfilled\n        }\n    }\n']),
    _templateObject4 = _taggedTemplateLiteral(['\n\tquery getLocales {\n\t\tlocales {\n\t\t\tlocales {\n\t\t\t\tcode\n\t\t\t\ttitle\n\t\t\t\tenabled\n\t\t\t}\n\t\t}\n\t}\n'], ['\n\tquery getLocales {\n\t\tlocales {\n\t\t\tlocales {\n\t\t\t\tcode\n\t\t\t\ttitle\n\t\t\t\tenabled\n\t\t\t}\n\t\t}\n\t}\n']),
    _templateObject5 = _taggedTemplateLiteral(['\n\tquery loggedInAppUsersInfo {\n\t\tloggedInAppUsersInfo {\n\t\t\tloggedInUserCount\n\t\t\trandomLoggedInUsers {\n\t\t\t\tid\n\t\t\t\tprofilePicUrl\n\t\t\t}\n\t\t}\n\t}\n'], ['\n\tquery loggedInAppUsersInfo {\n\t\tloggedInAppUsersInfo {\n\t\t\tloggedInUserCount\n\t\t\trandomLoggedInUsers {\n\t\t\t\tid\n\t\t\t\tprofilePicUrl\n\t\t\t}\n\t\t}\n\t}\n']),
    _templateObject6 = _taggedTemplateLiteral(['\n    query settings {\n        settings {\n            settings {\n                name\n                value\n            }\n        }\n    }\n'], ['\n    query settings {\n        settings {\n            settings {\n                name\n                value\n            }\n        }\n    }\n']),
    _templateObject7 = _taggedTemplateLiteral(['\n    mutation viewElement($elementId: ID!) {\n        viewElement(elementId: $elementId) {\n            totalViews\n        }\n    }\n'], ['\n    mutation viewElement($elementId: ID!) {\n        viewElement(elementId: $elementId) {\n            totalViews\n        }\n    }\n']);

var _graphqlTag = require('graphql-tag');

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

var _graphQlHelpers = require('@manakin/app-core/graphQlHelpers');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_PROGRAM = exports.GQL_FETCH_PROGRAM = (0, _graphqlTag2.default)(_templateObject, _graphQlHelpers.asset, _graphQlHelpers.asset);

var GQL_GET_BOX_CONDITION = exports.GQL_GET_BOX_CONDITION = (0, _graphqlTag2.default)(_templateObject2);

var GQL_FETCH_BOX_CONDITIONS_BY_PROGRAM_ID = exports.GQL_FETCH_BOX_CONDITIONS_BY_PROGRAM_ID = (0, _graphqlTag2.default)(_templateObject3);

var GQL_FETCH_LOCALES = exports.GQL_FETCH_LOCALES = (0, _graphqlTag2.default)(_templateObject4);

var GQL_FETCH_LOGGED_IN_USERS_INFO = exports.GQL_FETCH_LOGGED_IN_USERS_INFO = (0, _graphqlTag2.default)(_templateObject5);

var GQL_FETCH_GLOBAL_SETTINGS = exports.GQL_FETCH_GLOBAL_SETTINGS = (0, _graphqlTag2.default)(_templateObject6);

var GQL_VIEW_ELEMENT = exports.GQL_VIEW_ELEMENT = (0, _graphqlTag2.default)(_templateObject7);