import React, { useState, useEffect } from 'react';
import {
    ElementOverlay,
    Stepper,
    withStepper,
    Loader,
} from '@manakin/app-core';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
    stepperRoot: {
        maxWidth: '100%',
    },
});

const Questions = (props) => {
    const { stepper, classes } = props;
    const { t } = useTranslation();

    //state hooks
    const [isLoading, setIsLoading] = useState(false);

    //effect hooks
    useEffect(() => {
        if (!props.loading) {
            props.stepper.setItems(props.workforms || []);
        }
    }, [props.loading, props.workforms]);

    //functions
    const handleExit = () => {
        setIsLoading(true);
        if (props.onExit) props.onExit(stepper);
    };

    if (isLoading) {
        return (
            <div>
                <Loader fullScreen={true} />
            </div>
        );
    }
    return (
        <ElementOverlay
            variant="entryTest"
            title={
                t("app.elements.entry-test.questions-title", {
                    preTitle: props.preTitle || props.prefix || t("common.element-types.entry-test"),
                    questionNumber: stepper && stepper.step + 1
                })
            }
            items={stepper.items}
            controls={true}
        >
            <Stepper
                stepper={stepper}
                variant="entryTest"
                onExit={handleExit}
                className={classes.stepperRoot}
            />
        </ElementOverlay>
    );
};

export default compose(withStepper(), withStyles(styles))(Questions);
