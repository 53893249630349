import React, { useEffect, useState } from 'react';
import SlideshowSetup from './SlideshowSetup';
import { GQL_DELETE_SLIDESHOW, GQL_FETCH_SLIDESHOW, GQL_SAVE_SLIDESHOW } from './graphql';
import { ElementOverlay } from '@manakin/app-core';
import createUuid from 'uuid/v4';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useSnackBar } from '@manakin/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { changeColor } from '@manakin/app-core/ColorService/actions';
import ExitSaveDialog from './parts/ExitSaveDialog';
import { getBoxId } from '@manakin/app-core/Boxes/selectors';
import { useTranslation } from 'react-i18next';

// Little function to reorder the slides after drag and drop
const reorder = (previewList, startIndex, endIndex) => {
	const result = Array.from(previewList);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
};

const defaultState = {
	anchorEl: null,
	popperPlacement: null,
	imageDialogVisible: false,
	selectedDialogImage: false,
	title: '',
	isPublic: true,
	slideShowId: null,
	exitSaveDialogOpen: false,
};

const defaultSlidesState = {
	slides: [],
	activeSlide: null,
};

const SlideshowSetupContainer = (props) => {
	const [state, setState] = useState(defaultState);
	const [slidesState, setSlidesState] = useState(defaultSlidesState);
	const snackBar = useSnackBar();
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const params = useParams();
	const boxId = useSelector((state) => getBoxId(state));
	const { t } = useTranslation();

	const [saveSlideShow] = useMutation(GQL_SAVE_SLIDESHOW);
	const [deleteSlideShow] = useMutation(GQL_DELETE_SLIDESHOW);
	const { data = {}, loading } = useQuery(GQL_FETCH_SLIDESHOW, {
		variables: {
			id: params.elementId,
		},
	});
	const { slideshowCreator = {} } = data;

	useEffect(() => {
		if (data.slideshowCreator && !loading) {
			const { slideshowCreator = {} } = data;
			const slideShowId = params.slideId;
			let _slideshow = null;
			let _slides = [];

			slideshowCreator.slideshows &&
				slideshowCreator.slideshows.forEach((slide) => {
					if (slide.id === slideShowId) {
						_slides = [...slide.slides];
						_slideshow = {
							...slide,
						};
					}
				});

			if (slideshowCreator) {
				dispatch(
					changeColor({
						...slideshowCreator.styleSheet,
						location: location.pathname,
					})
				);
			}

			if (_slideshow) {
				const { slides, ...otherProps } = _slideshow;
				let slideshow = { ...otherProps };

				slideshow.slides = [];

				_slides &&
					_slides.forEach((slide) => {
						slideshow.slides.push({
							idx: createUuid(),
							type: slide.asset ? 'image' : 'text',
							image: {
								asset: { ...slide.asset },
							},
							data: {
								title: slide.title,
								text: slide.text,
							},
						});
					});

				if (slideshow) {
					setState({
						...state,
						isPublic: slideshow.isPublic,
						title: slideshow.title,
						slideShowId: slideShowId,
					});

					setSlidesState({
						...slidesState,
						slides: slideshow.slides,
					});
				}
			}
		}
	}, [loading]);

	// Toggles Material UI's menu to add a new slide
	const addSlideMenu = (event, placement) => {
		setState({
			...state,
			anchorEl: event.currentTarget,
			popperPlacement: placement,
		});
	};

	// Closes the above function
	const addSlideMenuExit = () => {
		setState({
			...state,
			anchorEl: null,
		});
	};

	useEffect(() => {
		// Scroll to the activeSlide if the previewList has overflow
		const activeSlide = document.querySelector('.activeSlide');
		if (activeSlide) {
			activeSlide.scrollIntoView({
				behavior: 'smooth',
				inline: 'end',
			});
		}
	}, [slidesState.activeSlide]);

	// Handle new slides
	const addSlide = (type, idx) => {
		let slides = [...slidesState.slides];
		addSlideMenuExit();

		slides.push({
			type: type,
			idx: idx,
			image: state.selectedDialogImage,
		});

		setSlidesState({
			slides: [...slides],
			activeSlide: slides.pop().idx,
		});
	};

	// Function to open the images
	const addImageDialog = () => {
		setState({
			...state,
			anchorEl: null,
			imageDialogVisible: true,
		});
	};

	const closeImageDialog = () => {
		setState({
			...state,
			imageDialogVisible: false,
			selectedDialogImage: null,
		});
	};

	const handleImageDialogClick = (image) => {
		setState({
			...state,
			selectedDialogImage: image,
		});
	};

	// Handle the data of a slide
	const handleDataChange = (idx, prop, event) => {
		let slides = [...slidesState.slides];
		slides.map((slide, slideIndex) => {
			if (slide.idx === idx) {
				slides[slideIndex] = {
					...slides[slideIndex],
					data: {
						...slides[slideIndex].data,
						[prop]: event.target.value,
					},
				};
			}
		});

		setSlidesState({
			...slidesState,
			slides: [...slides],
		});
	};

	// Sets the clicked slide as activeSlide
	const editSlide = (idx) => {
		setSlidesState({
			...slidesState,
			activeSlide: idx,
		});
	};

	// Removes the slide
	const deleteSlide = (idx) => {
		let updateSlides = slidesState.slides.filter((slide) => slide.idx !== idx);

		setSlidesState({
			...slidesState,
			slides: [...updateSlides],
		});
	};

	// Function which handles a drag/drop change (react-beautiful-dnd)
	const onDragEnd = (result) => {
		if (!result.destination) {
			return;
		}
		const items = reorder(slidesState.slides, result.source.index, result.destination.index);

		setSlidesState({
			...slidesState,
			slides: items,
		});
	};

	const handleSaveSlideshow = () => {
		const { title, slideShowId, isPublic } = state;
		const { slides } = slidesState;

		const _slides = slides.map((slide) => {
			if (slide.type === 'image') {
				const { url, name, __typename, ...otherProps } = slide.image.asset;
				return {
					asset: otherProps,
				};
			} else if (slide.data) {
				return {
					title: slide.data.title || '',
					text: slide.data.text || '',
				};
			}
		});

		let variables = {
			title: title,
			slideshowCreator: params.elementId,
			isPublic,
			slides: _slides,
		};

		if (slideShowId !== 'new') variables.id = slideShowId;

		if (title) {
			saveSlideShow({
				variables: variables,
			})
				.then((result) => {
					if (!result.errors) {
						snackBar.success(t('app.elements.slideshow-creator.save-success'));
						props.history.goBack();
					} else {
						snackBar.error(t('app.elements.slideshow-creator.save-failed'));
					}
				})
				.catch((e) => {
					snackBar.error(t('app.elements.slideshow-creator.save-failed'));
				});
		} else {
			snackBar.error(t('app.elements.slideshow-creator.save-failed-missing-title'));
		}
	};

	const handleChange = (e) => {
		setState({
			...state,
			title: e.target.value,
		});
	};

	const handleCheckboxChange = (e) => {
		setState((prevState) => ({
			...prevState,
			isPublic: !prevState.isPublic,
		}));
	};

	const handleDelete = () => {
		if (state.slideShowId !== 'new') {
			deleteSlideShow({
				variables: {
					id: state.slideShowId,
				},
			})
				.then((result) => {
					if (!result.errors) {
						history.goBack();
					} else {
						snackBar.error(t('app.elements.slideshow-creator.delete-failed'));
					}
				})
				.catch((e) => {
					snackBar.error(t('app.elements.slideshow-creator.delete-failed'));
				});
		}
	};

	const handleShowExitSaveDialog = () => {
		setState({
			...state,
			exitSaveDialogOpen: true,
		});
	};

	const handleCloseExitSaveDialog = () => {
		history.push(`/box/${boxId}`);
	};

	const handleSaveAndExit = () => {
		setState({
			...state,
			exitSaveDialogOpen: false,
		});
		handleSaveSlideshow();
	};

	if (loading) return <div>loading</div>;

	return (
		<ElementOverlay
			fullWidth={true}
			title={t('app.elements.slideshow-creator.title')}
			onClick={handleShowExitSaveDialog}
			controls={false}
			customControls={true}
		>
			<SlideshowSetup
				{...state}
				{...slidesState}
				addSlide={addSlide}
				addSlideMenu={addSlideMenu}
				addSlideMenuExit={addSlideMenuExit}
				editSlide={editSlide}
				deleteSlide={deleteSlide}
				activeSlide={slidesState.activeSlide}
				handleDataChange={handleDataChange}
				onDragEnd={onDragEnd}
				addImageDialog={addImageDialog}
				imageDialogState={state.imageDialogVisible}
				closeImageDialog={closeImageDialog}
				handleImageDialogClick={handleImageDialogClick}
				selectedDialogImage={state.selectedDialogImage}
				slideshowCreator={slideshowCreator}
				onSaveSlideshow={handleSaveSlideshow}
				onChange={handleChange}
				onCheckboxChange={handleCheckboxChange}
				onDeleteSlideshow={handleDelete}
			/>

			<ExitSaveDialog open={state.exitSaveDialogOpen} onClose={handleCloseExitSaveDialog} onSaveClick={handleSaveAndExit} />
		</ElementOverlay>
	);
};

export default SlideshowSetupContainer;
