"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require("react-redux");

var _ChangePasswordView = require("./ChangePasswordView");

var _ChangePasswordView2 = _interopRequireDefault(_ChangePasswordView);

var _ChangeStrongPasswordView = require("./ChangeStrongPasswordView");

var _ChangeStrongPasswordView2 = _interopRequireDefault(_ChangeStrongPasswordView);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ChangePasswordWrapper = function ChangePasswordWrapper(props) {
	var config = (0, _reactRedux.useSelector)(function (state) {
		return state.config;
	});

	var _ref = config || {},
	    _ref$general = _ref.general,
	    general = _ref$general === undefined ? {} : _ref$general;

	if (general.strongPassword) return _react2.default.createElement(_ChangeStrongPasswordView2.default, props);else return _react2.default.createElement(_ChangePasswordView2.default, props);
};

exports.default = ChangePasswordWrapper;