import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { LoadImage } from '@manakin/app-core';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
    root: {
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    wrapper: {
        ...theme.manakin.defaultWrapper,
        [theme.breakpoints.up('lg')]: {
            padding: '0 25rem 0 14rem',
        },
    },
    imageRoot: {
        width: '100%',
        height: '20rem',
        position: 'relative',
        order: 2,
        [theme.breakpoints.up('md')]: {
            minHeight: '20rem',
            height: 'auto',
        },
        [theme.breakpoints.up('lg')]: {
            width: '36%',
        },
    },
    contentBody: {
        order: 1,
        width: '100%',
        backgroundColor: theme.manakin.primaryWorkformColor[500],
        padding: '3rem 0',
        [theme.breakpoints.up('md')]: {
            padding: '5rem 0',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '9.6rem 0',
        },
    },
    title: {
        fontWeight: 'bold',
        color: theme.palette.getContrastText(theme.manakin.primaryColor[500]),
        fontSize: '2.4rem',
        lineHeight: '4rem',
        marginBottom: '4rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '4rem',
            paddingBottom: '4rem',
        },
        '&:after': {
            backgroundColor: theme.palette.getContrastText(
                theme.manakin.primaryColor[500]
            ),
        },
    },
    content: {
        color: theme.palette.getContrastText(theme.manakin.primaryColor[500]),
        [theme.breakpoints.up('lg')]: {
            marginBottom: '4rem',
        },
    },
});

const ReferralElement = (props) => {
    const { classes, data } = props;
    const { t } = useTranslation();

    const handleClick = () => {
        if (data.element && data.element.id) {
            props.history.push(`/${data.element.type}/${data.element.id}`);
        }
    };

    return (
        <div className={classes.root}>
            {data.image && data.image.url && (
                <div className={classes.imageRoot}>
                    <LoadImage
                        src={data.image && data.image.url}
                        asset={data.image}
                        className={classes.image}
                        enableFocusPoint={true}
                    />
                </div>
            )}
            <div className={classes.contentBody}>
                <div className={classes.wrapper}>
                    <Typography
                        variant="h3"
                        className={classNames('smallUnderline', classes.title)}
                    >
                        {data.workTitle || data.title || ''}
                    </Typography>
                    <Typography variant="body1" className={classes.content}>
                        {data.text || ''}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClick}
                    >
                        {data.introduction || t("app.workforms.referral-element.start-conversation")}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default compose(
    withStyles(styles, { name: 'AppReferralElement' }),
    withRouter
)(ReferralElement);
