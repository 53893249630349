"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _core = require("@material-ui/core");

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _styles = require("./styles");

var _reactRedux = require("react-redux");

var _actions = require("@manakin/app-core/actions");

var _Layout = require("./Layout");

var _Layout2 = _interopRequireDefault(_Layout);

var _appCore = require("@manakin/app-core");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RatingRequestAssignmentCard = function RatingRequestAssignmentCard(props) {
	var classes = (0, _styles.useStyles)();
	var data = props.data,
	    onClick = props.onClick;

	var _ref = data || {},
	    box = _ref.box;

	var image = box.image;

	var dispatch = (0, _reactRedux.useDispatch)();
	var onSetBoxId = (0, _react.useCallback)(function (id) {
		return dispatch((0, _actions.setBoxId)(id));
	}, [dispatch]);

	var handleClick = function handleClick() {
		onSetBoxId(data.box.id);
		onClick();
	};

	return _react2.default.createElement(
		"div",
		{ onClick: handleClick },
		_react2.default.createElement(
			_Layout2.default,
			{ className: classes.layout },
			_react2.default.createElement(
				"div",
				{ className: classes.imageContainer },
				_react2.default.createElement(
					"div",
					{ className: classes.image },
					image && image.thumbnailUrl && _react2.default.createElement(_appCore.LoadImage, {
						src: image && image.url,
						placeholder: image && image.thumbnailUrl
					})
				),
				_react2.default.createElement(
					_core.Typography,
					{ className: classes.content },
					box && box.name || ""
				)
			),
			_react2.default.createElement(
				"div",
				{ className: (0, _classnames2.default)(classes.padding, classes.rightAligned) },
				_react2.default.createElement(
					_core.Typography,
					{ className: (0, _classnames2.default)(classes.content) },
					data.trainer && data.trainer.fullName
				)
			)
		)
	);
};

exports.default = RatingRequestAssignmentCard;