import gql from 'graphql-tag';

export const GQL_FETCH_POLL_QUESTION_RESULT = gql`
	query pollQuestionResult($program: ID!, $box: ID!, $element: ID!, $pollQuestion: ID!) {
		pollQuestionResult(program: $program, box: $box, element: $element, pollQuestion: $pollQuestion) {
			selectedAnswer {
				answer {
					id
				}
			}
			totalVotes
			pollQuestionAnswerResults {
				answerId
				votes
				percentage
			}
		}
	}
`;

export const GQL_MUTATE_SUBMIT_POLL_QUESTION_ANSWER = gql`
	mutation submitPollQuestionAnswer($program: ID!, $box: ID!, $element: ID!, $pollQuestion: ID!, $answer: ID!) {
		submitPollQuestionAnswer(program: $program, box: $box, element: $element, pollQuestion: $pollQuestion, answer: $answer) {
			selectedAnswer {
				answer {
					id
				}
			}
			totalVotes
			pollQuestionAnswerResults {
				answerId
				votes
				percentage
			}
		}
	}
`;
