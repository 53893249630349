'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _graphql = require('../../graphql');

var _Button = require('@material-ui/core/Button');

var _Button2 = _interopRequireDefault(_Button);

var _recompose = require('recompose');

var _core = require('@manakin/core');

var _Grid = require('@material-ui/core/Grid');

var _Grid2 = _interopRequireDefault(_Grid);

var _core2 = require('@material-ui/core');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _reactHooks = require('@apollo/react-hooks');

var _reactI18next = require('react-i18next');

var _hooks = require('@manakin/hooks');

var _appCore = require('@manakin/app-core');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useStyles = (0, _core2.makeStyles)(function (theme) {
	return {
		root: {
			margin: 0,
			background: '#FFFFFF',
			padding: theme.spacing(6),
			paddingTop: theme.spacing(4),
			borderRadius: 4
		},
		inputs: {
			marginBottom: theme.spacing(2)
		}
	};
});

var formBundle = {
	email: {
		required: true,
		format: 'email'
	}
};

var InviteCard = function InviteCard(props) {
	var form = props.form,
	    classNameProp = props.className;

	var classes = useStyles();
	var snackBar = (0, _hooks.useSnackBar)();

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	// Mutations


	var _useMutation = (0, _reactHooks.useMutation)(_graphql.GQL_SEND_INVITE_AS_TRAINER),
	    _useMutation2 = _slicedToArray(_useMutation, 2),
	    sendInvite = _useMutation2[0],
	    _useMutation2$ = _useMutation2[1],
	    loading = _useMutation2$.loading,
	    data = _useMutation2$.data,
	    error = _useMutation2$.error;

	(0, _react.useEffect)(function () {
		if (data && data.inviteStudent) {
			snackBar.success(t('app.account.invite.send-student-success'));
			form.clear();
			props.onSuccess();
		}

		if (error) {
			var message = t('app.account.invite.send-failed');
			if (error.toString().includes('user.not.found')) {
				message = t('app.account.invite.send-user-not-exists-failed');
			} else if (error.toString().includes('invitation.already.sent')) {
				message = t('app.account.invite.send-already-accepted-failed');
			} else if (error.toString().includes("invitation.user.not.student")) {
				message = t('app.account.invite.send-user-not-student-failed');
			}

			snackBar.error(message);
		}
	}, [data, error]);

	var handleSendInvite = function handleSendInvite() {
		form.onValidate().then(function () {
			return form.onSubmit().then(function (data) {
				sendInvite({
					variables: _extends({}, data)
				});
			});
		}, function (error) {
			// Handled by inputs
		});
	};

	return _react2.default.createElement(
		'div',
		{ className: (0, _classnames2.default)(classes.root, classNameProp) },
		loading ? _react2.default.createElement(_appCore.Loader, null) : _react2.default.createElement(
			_react2.default.Fragment,
			null,
			_react2.default.createElement(
				_Grid2.default,
				{
					container: true,
					spacing: 2,
					alignItems: 'center',
					classes: {
						container: classes.inputs
					}
				},
				_react2.default.createElement(
					_Grid2.default,
					{ item: true, xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
					_react2.default.createElement(_core.TextField, {
						name: 'email',
						label: t('common.fields.email-address.label'),
						placeholder: t('common.fields.email-address.placeholder'),
						initialValue: '',
						form: form
					})
				)
			),
			_react2.default.createElement(
				_Button2.default,
				{
					variant: 'contained',
					color: 'primary',
					fullWidth: true,
					onClick: handleSendInvite,
					disabled: false
				},
				t('app.account.invite.send')
			)
		)
	);
};

exports.default = (0, _recompose.compose)((0, _core.withForm)(formBundle))(InviteCard);