import gql from 'graphql-tag';

export const GQL_FETCH_WIDGET_BOXES = gql`
    query appWidgetBoxes($variant: Variant!) {
        appWidgetBoxes(variant: $variant){
            id
            name
            preTitle
            image {
                url
            }
            tags {
                id
                name
                visibleInApp
                image {
                    id
                    url
                }
            }
            backgroundColor
        }
    }    
`;