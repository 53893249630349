import React, { useEffect, useState, useRef } from 'react';
import { useStyles } from './styles';
import Typography from '@material-ui/core/Typography';
import renderHTML from 'react-render-html';
import { ChatBubble } from '@manakin/app-workforms';
import { WorkformViewer, BottomButtons } from '@manakin/app-core';
import scrollToComponent from 'react-scroll-to-component';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const caseQuestionWorkformTypes = [
	'TextCheckQuestion',
	'TextMPC',
	'StatementQuestion',
	'TextMPCWithImage',
	'PollQuestion',
];

const Content = (props) => {
	const { characteristic = [], workforms = [], step, options = {}, closeChatBubble, redoButtons, onClose, redo, onNext, onSetWorkformDone, onUpdateCharacteristic } = props;
	const classes = useStyles();
	const { t } = useTranslation();
	const question = useRef(null);
	const redoButton = useRef(null);
	const location = useLocation();

	const [nextData, setNextData] = useState(null);
	const [hasNext, setHasNext] = useState(true);

	const scrollToQuestion = () => {
		scrollToComponent(question.current, {
			offset: 0,
			align: 'top',
			duration: 500,
		});
	};

	useEffect(() => {
		if (step !== undefined && step !== null && step > -1) {
			if (workforms.length && workforms[step].type != 'ChatBubble') {
				setTimeout(() => {
					scrollToQuestion();
				}, 500);
			}
		}
	}, [step]);

	useEffect(() => {
		if (closeChatBubble) {
			setTimeout(() => {
				scrollToQuestion();
			}, 100);
		}
	}, [closeChatBubble]);

	useEffect(() => {
		if (redoButtons) {
			setTimeout(() => {
				scrollToComponent(redoButton.current, {
					offset: 0,
					align: 'bottom',
					duration: 500,
				});
			}, 100);
		}
	}, [redoButtons]);

	const showButton = (data) => {
		onUpdateCharacteristic(data);
		setNextData(data);
	};

	const handleNext = (data) => {
		onNext(data || nextData);
		setNextData(null);
	};

	useEffect(() => {
		if (step === (workforms.length - 1)) {
			// Last, mark as finished to not show any "next" button IF the workform is not a question type
			const workform = workforms[workforms.length - 1];

			if (!caseQuestionWorkformTypes.includes(workform.type)) {
				// Last workform is not a question type, we can safely finish this case
				setHasNext(false);
				handleNext();
			}
		}
	}, [step]);

	return (
		<div className={classes.root}>
			<div className={classes.topRoot}>
				<div className={classes.left}>
					<div className={classes.content}>
						<div className={classes.chatBubble}>
							<div className={classes.chatBubbles}>
								{workforms.map((item, idx) => (
									<div key={item.id}>
										{item.type == 'ChatBubble' && (
											<div>
												<ChatBubble data={item} animate={step === idx} show={step > idx} onNext={showButton} />
											</div>
										)}
									</div>
								))}
								{hasNext && nextData && (
									<div className={classes.showButton}>
										<Button variant="contained" color="primary" onClick={handleNext}>
											{t('app.elements.case.to-question')}
										</Button>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className={classes.sidebar}>
					<Typography variant="h6" classes={{ h6: classes.title }}>
						{t('app.elements.case.features')}
					</Typography>
					<ul className={classes.list}>
						{characteristic &&
							characteristic.map((item, idx) => (
								<React.Fragment key={idx}>
									{item !== '<p><br></p>' && (
										<li>
											<Typography
												component="div"
												variant="body1"
												classes={{
													body1: classes.body,
												}}
											>
												{renderHTML(item)}
											</Typography>
										</li>
									)}
								</React.Fragment>
							))}
					</ul>
				</div>
			</div>
			{workforms.map((item, idx) => (
				<div
					ref={(section) => {
						question.current = section;
					}}
					key={item.id}
				>
					{step == idx && item.type != 'ChatBubble' && item.type != 'FactCardReferral' && (
						<div className={classes.question}>
							<WorkformViewer
								type={item.type}
								item={item}
								onFinish={handleNext}
								options={options}
								withNext={hasNext}
								onSetWorkformDone={onSetWorkformDone} />
						</div>
					)}
					{step == idx && item.type === 'FactCardReferral' && (
						<div className={classes.question}>
							<WorkformViewer
								type={item.type}
								item={item}
								onFinish={handleNext}
								options={options}
								withNext={true}
								customLink={{
									withId: true,
									state: {
										url: location.pathname,
										idx: step,
										characteristic,
									},
								}}
							/>
						</div>
					)}
				</div>
			))}
			{closeChatBubble && (
				<div className={classes.question}>
					<Button variant="contained" color="primary" aria-label="Close" className={classes.closeButton} onClick={onClose}>
						{t('app.elements.case.close')}
					</Button>
				</div>
			)}
			{redoButtons && (
				<div
					ref={(section) => {
						redoButton.current = section;
					}}
				>
					<BottomButtons onClose={onClose} onReset={redo} />
				</div>
			)}
		</div>
	);
};

export default Content;
