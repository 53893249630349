import React from "react";
import Typography from "@material-ui/core/Typography";
import renderHTML from "react-render-html";
import classNames from "classnames";
import { useReadSpeaker } from "@manakin/hooks";
import { createStyles, makeStyles } from "@material-ui/styles";
import { useQuery } from "@apollo/react-hooks";
import { GQL_FETCH_CMS_HOME_PAGE_BLOCK_TITLE_AND_TEXT } from "../graphql";

const useStyles = makeStyles(
    (theme) =>
        createStyles({
            root: {
                backgroundColor: theme.manakin.defaultWorkformColor[500],
                padding: "5rem 0",
                [theme.breakpoints.up("lg")]: {
                    padding: "15rem 0rem",
                },
            },
            primary: {
                backgroundColor: theme.manakin.homePageBlockPrimaryBackgroundColor[500],
                "& $content": {
                    color: theme.palette.getContrastText(
                        theme.manakin.homePageBlockPrimaryBackgroundColor[500],
                    ),
                },
                "& $header": {
                    color: theme.palette.getContrastText(
                        theme.manakin.homePageBlockPrimaryBackgroundColor[500],
                    ),
                    "&.smallUnderline": {
                        "&:after": {
                            backgroundColor: theme.palette.getContrastText(
                                theme.manakin.homePageBlockPrimaryBackgroundColor[500],
                            ),
                        },
                    },
                },
            },
            secondary: {
                backgroundColor: theme.manakin.homePageBlockSecondaryBackgroundColor[500],
                "& $content": {
                    color: theme.palette.getContrastText(
                        theme.manakin.homePageBlockSecondaryBackgroundColor[500],
                    ),
                },
                "& $header": {
                    color: theme.palette.getContrastText(
                        theme.manakin.homePageBlockSecondaryBackgroundColor[500],
                    ),
                    "&.smallUnderline": {
                        "&:after": {
                            backgroundColor: theme.palette.getContrastText(
                                theme.manakin.homePageBlockSecondaryBackgroundColor[500],
                            ),
                        },
                    },
                },
            },
            noBG: {
                backgroundColor: "white",
                "& $content": {
                    color: theme.manakin.defaultContentColor[500],
                },
                "& $header": {
                    color: theme.manakin.defaultContentColor[500],
                    "&.smallUnderline": {
                        "&:after": {
                            backgroundColor: theme.manakin.indicationColor[500],
                        },
                    },
                },
            },
            wrapper: {
                ...theme.manakin.extraLargeWrapper,
                [theme.breakpoints.up("md")]: {
                    padding: "0 6rem",
                },
                [theme.breakpoints.up("lg")]: {
                    padding: "0 15rem",
                },
            },
            secondTextEmpty: {
                [theme.breakpoints.up("md")]: {
                    columns: "2",
                    columnGap: "10rem",
                },
            },
            columns: {
                [theme.breakpoints.up("md")]: {
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "90px",
                },
            },
            header: {
                ...theme.manakin.workformTitleFont,
                paddingBottom: "3rem",
                marginBottom: "3rem",
                color: theme.palette.getContrastText(
                    theme.manakin.defaultWorkformColor[500],
                ),
                [theme.breakpoints.down("xs")]: {
                    fontSize: "3rem",
                    lineHeight: "4rem",
                },
                "&.smallUnderline": {
                    "&:after": {
                        backgroundColor: theme.palette.getContrastText(
                            theme.manakin.defaultWorkformColor[500],
                        ),
                    },
                },
            },
            content: {
                color: theme.palette.getContrastText(
                    theme.manakin.defaultWorkformColor[500],
                ),
                "& ul, & ol": {
                    paddingLeft: "20px",
                },
                fontWeight: 400,
                fontSize: "1.8rem",
                lineHeight: "3.6rem",
                fontFamily: theme.manakin.defaultContentFont,
                "& p": {
                    marginBottom: "2rem",
                },
            },
        }),
    {
        name: "AppAppHomepageBlockTitleAndText",
    },
);

const emptyHtml = "<p><br></p>";

const TitleAndText = (props) => {
    const classes = useStyles();
    const { readSpeaker, readSpeakerId } = useReadSpeaker("readSpeaker-titleAndText-" + props.id);

    const { data = {} } = useQuery(GQL_FETCH_CMS_HOME_PAGE_BLOCK_TITLE_AND_TEXT, {
        variables: {
            id: props.id,
        },
    });
    const { titleAndTextHomepageBlock = {} } = data;
    const { backgroundColor, title, text, text2, workTitle } = titleAndTextHomepageBlock;

    return (
        <div
            className={classNames(
                classes.root,
                { [classes.primary]: backgroundColor === "PRIMARY" },
                { [classes.secondary]: backgroundColor === "SECONDARY" },
                { [classes.noBG]: backgroundColor === "NONE" },
            )}
        >
            <div className={classes.wrapper}>
                <div
                    className={classNames(classes.content, {
                        [classes.secondTextEmpty]: !text2 || text2 === "" || text2 === emptyHtml,
                    })}
                    id={readSpeakerId}
                >
                    <Typography
                        component='h2'
                        variant='h2'
                        className={classNames("smallUnderline", classes.header)}
                    >
                        {title || workTitle || ""}
                    </Typography>
                    {readSpeaker && renderHTML(readSpeaker)}
                    <div
                        className={classNames({
                            [classes.columns]: text2 && text2 !== "" && text2 !== emptyHtml,
                        })}
                    >
                        <div>
                            {renderHTML(text || "")}
                        </div>
                        {text2 && text2 !== "" && text2 !== emptyHtml && (
                            <div>
                                {renderHTML(text2)}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TitleAndText;
