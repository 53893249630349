"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _Dialog = require("@manakin/core/Dialog");

var _Dialog2 = _interopRequireDefault(_Dialog);

var _Dialog3 = require("@material-ui/core/Dialog");

var _Dialog4 = _interopRequireDefault(_Dialog3);

var _DialogTitle = require("@material-ui/core/DialogTitle");

var _DialogTitle2 = _interopRequireDefault(_DialogTitle);

var _DialogActions = require("@material-ui/core/DialogActions");

var _DialogActions2 = _interopRequireDefault(_DialogActions);

var _recompose = require("recompose");

var _Button = require("@material-ui/core/Button");

var _Button2 = _interopRequireDefault(_Button);

var _styles = require("@material-ui/core/styles");

var _reactI18next = require("react-i18next");

var _styles2 = require("@material-ui/styles");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useStyles = (0, _styles2.makeStyles)(function (theme) {
    return (0, _styles2.createStyles)({
        input: {
            marginBottom: '4rem!important',
            marginTop: '0!important'
        }
    });
});

var DeleteMessageDialog = function DeleteMessageDialog(props) {
    var _useTranslation = (0, _reactI18next.useTranslation)(),
        t = _useTranslation.t;

    var classes = useStyles();

    var handleSubmit = function handleSubmit(onClose) {
        if (props.onDelete) props.onDelete();
        onClose();
    };

    return _react2.default.createElement(_Dialog2.default, {
        name: "appDeleteMessageDialog",
        render: function render(_ref) {
            var open = _ref.open,
                onClose = _ref.onClose;
            return _react2.default.createElement(
                _Dialog4.default,
                { open: open, onClose: onClose },
                _react2.default.createElement(
                    _DialogTitle2.default,
                    null,
                    t("app.account.messages.delete-dialog.title")
                ),
                _react2.default.createElement(
                    _DialogActions2.default,
                    null,
                    _react2.default.createElement(
                        _Button2.default,
                        {
                            onClick: function onClick() {
                                return handleSubmit(onClose);
                            },
                            color: "primary",
                            variant: "contained"
                        },
                        t("app.account.messages.delete-dialog.action")
                    ),
                    _react2.default.createElement(
                        _Button2.default,
                        { onClick: onClose, color: "primary" },
                        t("common.cancel")
                    )
                )
            );
        }
    });
};

exports.default = DeleteMessageDialog;