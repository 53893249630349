export const OPEN_DIALOG = '@manakin/core/OPEN_DIALOG';
export const CLOSE_DIALOG = '@manakin/core/CLOSE_DIALOG';

export function openDialog(id, data) {
    return {
        type: OPEN_DIALOG,
        payload: {
            id,
            data,
        },
    };
}

export function closeDialog(id) {
    return {
        type: CLOSE_DIALOG,
        payload: {
            id,
        },
    };
}
