import { makeStyles, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';

const useStyles = makeStyles((theme) => ({
	root: {
		height: '151px',
		backgroundColor: theme.manakin.primaryPaletteColor[500],
		position: 'relative',
		cursor: 'pointer',
		transform: 'scale(1)',
		transition: 'transform .3s',
		'&:hover': {
			transform: 'scale(1.1)',
		},
	},
	image: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		position: 'absolute',
		display: 'block',
		top: 0,
		left: 0,
	},
	overlay: {
		const: '""',
		position: 'absolute',
		width: '100%',
		height: '100%',
		background: 'rgba(0,0,0,0.25)',
		top: 0,
		left: 0,
		zIndex: 1,
	},
	time: {
		margin: 0,
		fontSize: '11px',
		fontWeight: 'bold',
		lineHeight: '12px',
		zIndex: 2,
		position: 'relative',
		top: '5px',
		left: '5px',
		color: theme.palette.getContrastText(theme.manakin.primaryPaletteColor[500]),
	},
	event: {
		margin: 0,
		fontSize: '14px',
		fontWeight: 'bold',
		lineHeight: '14px',
		zIndex: 2,
		position: 'relative',
		color: theme.palette.getContrastText(theme.manakin.primaryPaletteColor[500]),
		textAlign: 'center',
		marginTop: '16px',
	},
}));

const Event = (props) => {
	const { event = {} } = props;
	const { image, date, title } = event;
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Typography className={classes.time}>{moment(date).format('HH:mm')}</Typography>
			{image && image.url && (
				<React.Fragment>
					<img className={classes.image} src={image.url} />
				</React.Fragment>
			)}
			<div className={classes.overlay} />
			<Typography className={classes.event}>{title || ''}</Typography>
		</div>
	);
};

export default Event;
