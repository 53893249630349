import React from 'react';
import { Switch, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

class ModuleRouter extends React.PureComponent {
    render() {
        const { location, children } = this.props;

        return <Switch location={location}>{children}</Switch>;
    }
}

export default withRouter(ModuleRouter);
