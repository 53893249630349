"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.GQL_FETCH_GROUP = exports.GQL_FETCH_PROGRAMS = undefined;

var _templateObject = _taggedTemplateLiteral(["\n\tquery programs($pagesize: Int = 10, $filter: ProgramFilter) {\n\t\tprograms(pagesize: $pagesize, filter: $filter) {\n\t\t\tcount\n\t\t\tprograms {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tquery programs($pagesize: Int = 10, $filter: ProgramFilter) {\n\t\tprograms(pagesize: $pagesize, filter: $filter) {\n\t\t\tcount\n\t\t\tprograms {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t}\n"]),
    _templateObject2 = _taggedTemplateLiteral(["\n\tquery group($id: ID!) {\n\t\tgroup(id: $id) {\n\t\t\tid\n\t\t\ttype\n\t\t}\n\t}\n"], ["\n\tquery group($id: ID!) {\n\t\tgroup(id: $id) {\n\t\t\tid\n\t\t\ttype\n\t\t}\n\t}\n"]);

var _graphqlTag = require("graphql-tag");

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_PROGRAMS = exports.GQL_FETCH_PROGRAMS = (0, _graphqlTag2.default)(_templateObject);

var GQL_FETCH_GROUP = exports.GQL_FETCH_GROUP = (0, _graphqlTag2.default)(_templateObject2);