"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.GQL_FETCH_APP_USERS = exports.GQL_FETCH_PROGRAM = exports.GQL_STUDENTS = undefined;

var _templateObject = _taggedTemplateLiteral(["\n\tquery invitationStudentOverview($input: InvitationStudentOverviewInput) {\n\t\tinvitationStudentOverview(input: $input) {\n\t\t\tprogramResult {\n\t\t\t\tprogress\n\t\t\t}\n\t\t\tratingRequests {\n\t\t\t\tid\n\t\t\t\tresult {\n\t\t\t\t\tid\n\t\t\t\t}\n\t\t\t}\n\t\t\tstudent {\n\t\t\t\tid\n\t\t\t\tfullName\n\t\t\t\tschool {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tquery invitationStudentOverview($input: InvitationStudentOverviewInput) {\n\t\tinvitationStudentOverview(input: $input) {\n\t\t\tprogramResult {\n\t\t\t\tprogress\n\t\t\t}\n\t\t\tratingRequests {\n\t\t\t\tid\n\t\t\t\tresult {\n\t\t\t\t\tid\n\t\t\t\t}\n\t\t\t}\n\t\t\tstudent {\n\t\t\t\tid\n\t\t\t\tfullName\n\t\t\t\tschool {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"]),
    _templateObject2 = _taggedTemplateLiteral(["\n\tquery program($id: ID!) {\n\t\tprogram(id: $id) {\n\t\t\tid\n\t\t\tname\n\t\t}\n\t}\n"], ["\n\tquery program($id: ID!) {\n\t\tprogram(id: $id) {\n\t\t\tid\n\t\t\tname\n\t\t}\n\t}\n"]),
    _templateObject3 = _taggedTemplateLiteral(["\n\tquery appUsers(\n\t\t$page: Int!\n\t\t$pagesize: Int!\n\t\t$search: String\n\t\t$direction: String\n\t\t$sortProperties: [String]\n\t\t$filter: AppUserFilter\n\t) {\n\t\tappUsers(\n\t\t\tpage: $page\n\t\t\tpagesize: $pagesize\n\t\t\tsearch: $search\n\t\t\tdirection: $direction\n\t\t\tsortProperties: $sortProperties\n\t\t\tfilter: $filter\n\t\t) {\n\t\t\tusers {\n\t\t\t\tid\n\t\t\t\tfirstName\n\t\t\t\tlastName\n\t\t\t\tfullName\n\t\t\t\temail\n\t\t\t\tgroup {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t\tcount\n\t\t}\n\t}\n"], ["\n\tquery appUsers(\n\t\t$page: Int!\n\t\t$pagesize: Int!\n\t\t$search: String\n\t\t$direction: String\n\t\t$sortProperties: [String]\n\t\t$filter: AppUserFilter\n\t) {\n\t\tappUsers(\n\t\t\tpage: $page\n\t\t\tpagesize: $pagesize\n\t\t\tsearch: $search\n\t\t\tdirection: $direction\n\t\t\tsortProperties: $sortProperties\n\t\t\tfilter: $filter\n\t\t) {\n\t\t\tusers {\n\t\t\t\tid\n\t\t\t\tfirstName\n\t\t\t\tlastName\n\t\t\t\tfullName\n\t\t\t\temail\n\t\t\t\tgroup {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t\tcount\n\t\t}\n\t}\n"]);

var _graphqlTag = require("graphql-tag");

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_STUDENTS = exports.GQL_STUDENTS = (0, _graphqlTag2.default)(_templateObject);

var GQL_FETCH_PROGRAM = exports.GQL_FETCH_PROGRAM = (0, _graphqlTag2.default)(_templateObject2);

var GQL_FETCH_APP_USERS = exports.GQL_FETCH_APP_USERS = (0, _graphqlTag2.default)(_templateObject3);