import React, { useMemo } from 'react';
import { SimpleTable } from '@manakin/app-core';
import TableCell from '@material-ui/core/TableCell';
import ListItem from './ListItem';
import { makeStyles } from '@material-ui/core';
import { GQL_FETCH_SETTINGS } from '../../graphql';
import { useSetting } from '@manakin/hooks';
import { useQuery } from '@apollo/react-hooks';
import { SettingsKeys } from '@manakin/core/lib/constants';
import { activeElementTypes } from '@manakin/core/lib/elements';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '100px 0',
	},
	wrapper: {
		...theme.manakin.defaultWrapper,
	},
}));

const sortFunction = (a, b) => {
	const _a = a.title.toUpperCase();
	const _b = b.title.toUpperCase();

	let comparison = 0;
	if (_a > _b) {
		comparison = 1;
	} else if (_a < _b) {
		comparison = -1;
	}
	return comparison;
};

const List = (props) => {
	const { program = {}, appUser, onMessageClick } = props;
	const { boxes = [[]] } = program;
	const classes = useStyles();
	const { t } = useTranslation();

	const { data: settingsData = {} } = useQuery(GQL_FETCH_SETTINGS);
	const exercisecardEnableTeacherMessages = useSetting(settingsData.settings, SettingsKeys.EXERCISE_CARD_ENABLE_TEACHER_MESSAGES);

	const cards = useMemo(() => {
		const activeElements = activeElementTypes(settingsData, t);
		let temp = [];
		if (boxes && boxes.length) {
			boxes.forEach((box) => {
				if (box.length) {
					box.forEach((i) => {
						if (i.elements && i.elements.length) {
							i.elements
								.filter(
									(element) => activeElements.find((activeElement) => activeElement.type === element.type) !== undefined
								)
								.forEach((element) => {
									if (element.type === 'ExerciseCard')
										temp.push({
											...element,
											box: i,
										});
								});
						}
					});
				}
			});
		}
		return temp.sort(sortFunction);
	}, [boxes, settingsData]);

	return (
		<div className={classes.root}>
			<div className={classes.wrapper}>
				<SimpleTable
					renderHead={() => (
						<React.Fragment>
							<TableCell alitn="left">{t('common.table-headers.assignment')}</TableCell>
							<TableCell align="left">{t('app.student-report.table-headers.completed')}?</TableCell>
							<TableCell align="left">{t('common.table-headers.result')}</TableCell>
							{exercisecardEnableTeacherMessages !== 'false' && (
								<TableCell align="left">{t('common.table-headers.action')}</TableCell>
							)}
						</React.Fragment>
					)}
					renderBody={() => (
						<React.Fragment>
							{cards.map((element) => (
								<ListItem
									key={element.id}
									element={element}
									box={element.box}
									program={program}
									appUser={appUser}
									onMessageClick={onMessageClick}
									settings={settingsData}
								/>
							))}
						</React.Fragment>
					)}
				/>
			</div>
		</div>
	);
};

export default List;
