import React from 'react';
import { useStyles } from '../styles';
import Answers from './Answers';
import ButtonContainer from './Button';
import Feedback from './Feedback';

const Question = (props) => {
    const {
        disabled = false,
        data = {},
        onChange,
        onSubmit,
        onFinished,
        onSetWorkformDone
    } = props;
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <div className={classes.mobile}>
                <div className={classes.image}>
                    <img src={data.image ? data.image.url || '' : ''} />
                </div>
            </div>
            <div className={classes.answers}>
                <Answers data={data} onChange={onChange} />
                <div className={classes.relative}>
                    <ButtonContainer onSubmit={onSubmit} disabled={disabled} />
                    <Feedback data={data} onFinished={onFinished} onSetWorkformDone={onSetWorkformDone} />
                </div>
            </div>
        </div>
    );
};

export default Question;
