import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import classNames from "classnames";
import { ChatBox } from "@manakin/app-core";
import Typography from "@material-ui/core/Typography";
import Person from "@material-ui/icons/PersonOutline";
import moment from "moment";

const styles = (theme) => ({
	feedbackUserIcon: {
		color: "#C4C4C4",
		width: 24,
		height: 24,
	},
	feedbackUser: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	feedbackUserContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		[theme.breakpoints.down("xs")]: {
			alignItems: "start",
			display: "flex",
			flexDirection: "column",
			justifyContent: "start",
			marginLeft: theme.spacing(2),
		},
	},
	feedbackUserName: {
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(3),
		marginBottom: 0,
		[theme.breakpoints.down("xs")]: {
			marginLeft: 0,
		},
	},
	feedbackDate: {
		marginBottom: 0,
		paddingBottom: "0 !important",
		color: "#8a8a8a",
	},
	subHeader: {},
});

const AskFeedbackBox = (props) => {
	const { classes, data = {} } = props;

	return (
		<div>
			<ChatBox text={data.feedback ? data.feedback : ""} />
			<div className={classes.feedbackUser}>
				<Person className={classes.feedbackUserIcon} />
				<div className={classes.feedbackUserContainer}>
					<Typography
						component="p"
						variant="subtitle1"
						className={classNames(
							classes.contentColor,
							classes.feedbackUserName
						)}
					>
						{data.respondent || ""}
					</Typography>
					{data.feedbackOn && (
						<Typography
							component="p"
							variant="body1"
							className={classNames(
								classes.subHeader,
								classes.contentColor,
								classes.feedbackDate
							)}
						>
							{moment(data.feedbackOn).locale("nl").fromNow()}
						</Typography>
					)}
				</div>
			</div>
		</div>
	);
};

export default compose(
	withStyles(styles, { name: "AppAskFeedbackBox" })
)(AskFeedbackBox);
