import React from 'react';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
    sliderLabel: {},
});

const SliderInput = (props) => {
    const { classes, max = 100, min = 0, label = '' } = props;
    const valuetext = (value) => {
        return value;
    };

    const handleChange = (e, value) => {
        if (props.onChange) props.onChange(value);
    };

    return (
        <div className={classes.root}>
            <Typography className={classes.sliderLabel}>{label}</Typography>
            <Slider
                defaultValue={0}
                getAriaValueText={valuetext}
                aria-labelledby="discrete-slider-always"
                step={10}
                valueLabelDisplay="on"
                onChange={handleChange}
                max={max}
                min={min}
            />
        </div>
    );
};

export default compose(withStyles(styles, { name: 'AppSliderInput' }))(
    SliderInput
);
