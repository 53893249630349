'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.FactCardCollectionVariant = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _styles = require('@material-ui/core/styles');

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _styles2 = require('./styles');

var _Button = require('@material-ui/core/Button');

var _Button2 = _interopRequireDefault(_Button);

var _reactRouterDom = require('react-router-dom');

var _appCore = require('@manakin/app-core');

var _reactI18next = require('react-i18next');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var FactCardCollectionVariant = exports.FactCardCollectionVariant = {
    ALL: "ALL",
    SPECIFIC: "SPECIFIC"
};

var FactCardsCard = function FactCardsCard(props) {
    var classes = props.classes,
        factCards = props.factCards,
        image = props.image,
        item = props.item,
        _props$disabled = props.disabled,
        disabled = _props$disabled === undefined ? false : _props$disabled,
        theme = props.theme;

    var _useTranslation = (0, _reactI18next.useTranslation)(),
        t = _useTranslation.t;

    var handleClick = function handleClick() {
        if (disabled && props.onDisabledClick) {
            props.onDisabledClick();
        }
    };

    var textStyle = _extends({}, item && item.backgroundColor && {
        color: theme.palette.getContrastText(item.backgroundColor)
    });

    return _react2.default.createElement(
        'div',
        {
            className: (0, _classnames2.default)(classes.root, classes.bookCheck, classes.fullWidth, _defineProperty({}, classes.disabled, disabled)),
            style: _extends({}, item && item.backgroundColor && {
                backgroundColor: item.backgroundColor
            }),
            onClick: handleClick
        },
        _react2.default.createElement(
            'div',
            { className: classes.backgroundImage },
            image && _react2.default.createElement(_appCore.LoadImage, {
                src: image.url || '',
                placeholder: image.thumbnailUrl || ''
            })
        ),
        _react2.default.createElement(
            'div',
            { className: classes.bookCheckFlex },
            _react2.default.createElement(
                'div',
                { className: classes.bookCheckContent },
                _react2.default.createElement(
                    _Typography2.default,
                    {
                        component: 'h5',
                        variant: 'h5',
                        style: textStyle,
                        className: (0, _classnames2.default)(classes.bodytitle, classes.name)
                    },
                    item && item.title || t("app-core.fact-cards-card.title")
                ),
                _react2.default.createElement(
                    _Typography2.default,
                    {
                        component: 'p',
                        variant: 'body1',
                        style: textStyle,
                        className: classes.body
                    },
                    item && item.introText || t("app-core.fact-cards-card.description")
                )
            ),
            _react2.default.createElement(
                _Button2.default,
                {
                    component: _reactRouterDom.Link,
                    variant: 'contained',
                    color: 'primary',
                    fullWidth: true,
                    style: textStyle,
                    className: (0, _classnames2.default)(classes.button, classes.factCardsButton),
                    disabled: disabled,
                    to: {
                        pathname: '/boxCards',
                        state: {
                            fromElement: item && item.variant === FactCardCollectionVariant.SPECIFIC,
                            factCards: [].concat(_toConsumableArray(factCards)),
                            tags: [].concat(_toConsumableArray(item ? item.tags || [] : []))
                        }
                    }
                },
                t("app-core.fact-cards-card.action")
            )
        )
    );
};

exports.default = (0, _styles.withStyles)(_styles2.styles, { name: 'AppFactcardsCard', withTheme: true })(FactCardsCard);