import React, { useEffect, useState, useRef } from 'react';
import ChatBubble from './ChatBubble';
import scrollToComponent from 'react-scroll-to-component';

const ChatBubbleContainer = (props) => {
	const { data, animate, onNext } = props;
	const bubbles = useRef(null);

	const [characteristic, setCharacteristic] = useState([]);

	useEffect(() => {
		if (animate) {
			const chatLength = data.bubbles ? data.bubbles.length : 0;
			let characteristic = [];
			data.bubbles &&
				data.bubbles.forEach((item) => {
					item.characteristic ? characteristic.push(item.characteristic) : null;
				});

			setCharacteristic(characteristic);
			if (props.onNext) {
				scrollToComponent(bubbles.current, {
					offset: -100,
					align: 'top',
					duration: 500,
				});
				setTimeout(() => {
					if (onNext) onNext({ characteristic: characteristic });
				}, chatLength * 1000 + 1000);
			}
		}
	}, [animate]);

	return (
		<ChatBubble
			{...props}
			ref={(section) => {
				bubbles.current = section;
			}}
		/>
	);
};

export default ChatBubbleContainer;
