"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _ = require("../../");

var _core = require("@material-ui/core");

var _reactHooks = require("@apollo/react-hooks");

var _appCore = require("@manakin/app-core");

var _Done = require("@material-ui/icons/Done");

var _Done2 = _interopRequireDefault(_Done);

var _graphql = require("../graphql");

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var DefaultItem = function DefaultItem(props) {
	var _classNames, _classNames4, _classNames5;

	var query = props.query,
	    classes = props.classes,
	    item = props.item,
	    disabled = props.disabled,
	    dataName = props.dataName,
	    tags = props.tags,
	    show = props.show,
	    elementResult = props.elementResult,
	    appUser = props.appUser,
	    boxId = props.boxId,
	    programId = props.programId,
	    settings = props.settings,
	    prefix = props.prefix;

	var _ref = elementResult || {},
	    _ref$ratingTeacher = _ref.ratingTeacher,
	    ratingTeacher = _ref$ratingTeacher === undefined ? "FINISHED" : _ref$ratingTeacher;

	var _useState = (0, _react.useState)(false),
	    _useState2 = _slicedToArray(_useState, 2),
	    hover = _useState2[0],
	    setHover = _useState2[1];

	var _useQuery = (0, _reactHooks.useQuery)(query, {
		variables: {
			id: item.id
		}
	}),
	    _useQuery$data = _useQuery.data,
	    data = _useQuery$data === undefined ? {} : _useQuery$data;

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var _useQuery2 = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_RATING_REQUESTS, {
		variables: {
			programId: programId,
			boxId: boxId,
			elementId: item.id,
			studentId: appUser.id
		}
	}),
	    _useQuery2$data = _useQuery2.data,
	    ratingData = _useQuery2$data === undefined ? {} : _useQuery2$data;

	var done = ratingData && ratingData.ratingRequest && ratingData.ratingRequest.some(function (request) {
		return request.result && (request.result.ranking === "SUFFICIENT" || request.result.ranking === "GOOD");
	});

	var _data$rating = data.rating,
	    rating = _data$rating === undefined ? {} : _data$rating;
	var title = rating.title;


	var handlePointer = function handlePointer(data) {
		setHover(true);
	};
	var handleScroll = function handleScroll() {
		if (hover) setHover(false);
	};

	var imageUrl = item.image ? item.image.url : "";
	var placeholder = item.image ? item.image.thumbnailUrl : "";

	var _ref2 = data && data[dataName] || {},
	    _ref2$preTitle = _ref2.preTitle,
	    preTitle = _ref2$preTitle === undefined ? "" : _ref2$preTitle,
	    _ref2$prefixColor = _ref2.prefixColor,
	    prefixColor = _ref2$prefixColor === undefined ? "" : _ref2$prefixColor;

	var imageOverflow = data[dataName] ? data[dataName].imageOverflow || false : false;
	var backgroundColor = data[dataName] ? data[dataName].backgroundColor || null : null;

	return _react2.default.createElement(
		"div",
		{
			className: (0, _classnames2.default)(classes.root, classes[item.type], (_classNames = {}, _defineProperty(_classNames, classes.fullWidth, item.fullWidth), _defineProperty(_classNames, classes.minHeight, true), _defineProperty(_classNames, classes.disabled, disabled), _defineProperty(_classNames, classes.show, show), _defineProperty(_classNames, classes.overflowRoot, imageOverflow), _classNames)),
			style: {
				transition: "transform .5s ." + 1 + "s, opacity .5s ." + 1 + "s",
				backgroundColor: backgroundColor
			},
			onPointerOver: handlePointer,
			onPointerLeave: function onPointerLeave() {
				return setHover(false);
			},
			onPointerOut: function onPointerOut() {
				return setHover(false);
			},
			onScroll: handleScroll
		},
		_react2.default.createElement(_appCore.Tags, {
			tags: tags,
			className: (0, _classnames2.default)(classes.tags, _defineProperty({}, classes.withIcon, item.done != undefined))
		}),
		ratingTeacher === "RATED" && _react2.default.createElement("div", { className: classes.update }),
		_react2.default.createElement(
			"div",
			{
				className: (0, _classnames2.default)(classes.backgroundImage, _defineProperty({}, classes.overflow, imageOverflow))
			},
			_react2.default.createElement(_.LoadImage, { src: imageUrl, placeholder: placeholder })
		),
		done && _react2.default.createElement(
			"div",
			{ className: classes.done },
			_react2.default.createElement(
				"span",
				{ className: classes.doneIcon },
				_react2.default.createElement(_Done2.default, { classes: { root: classes.iconRoot } })
			)
		),
		_react2.default.createElement(
			"div",
			{ className: classes.content },
			_react2.default.createElement(
				"span",
				{
					className: (0, _classnames2.default)(classes.type, (_classNames4 = {}, _defineProperty(_classNames4, classes.secondaryPrefixColor, prefixColor === 'SECONDARY'), _defineProperty(_classNames4, classes.primaryPrefixColor, prefixColor === 'PRIMARY'), _classNames4))
				},
				preTitle || settings && settings[item.type.toLowerCase() + "Prefix"] || ''
			),
			_react2.default.createElement(
				_core.Typography,
				{
					component: "h1",
					variant: "h2",
					classes: { h2: classes.ratingCardHeading }
				},
				title || t("common.rating")
			),
			_react2.default.createElement(
				"div",
				{ className: classes.ratingCardLink },
				_react2.default.createElement(
					"span",
					{
						className: (0, _classnames2.default)(classes.type, classes.ratingCardAction, (_classNames5 = {}, _defineProperty(_classNames5, classes.secondaryPrefixColor, prefixColor === "SECONDARY"), _defineProperty(_classNames5, classes.primaryPrefixColor, prefixColor === "PRIMARY"), _classNames5))
					},
					t("common.rating")
				),
				_react2.default.createElement(_appCore.LongArrow, {
					className: (0, _classnames2.default)(classes.longArrowRoot, classes.ratingCardLongArrow)
				})
			)
		)
	);
};

exports.default = DefaultItem;