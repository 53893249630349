import React, { useEffect, useState } from 'react';
import { Loader } from '@manakin/app-core';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Typography from '@material-ui/core/Typography';
import { useSnackBar } from '@manakin/hooks';
import { GQL_FETCH_POLL_RESULT, GQL_MUTATE_SUBMIT_POLL_ANSWER } from './Poll/graphql';
import Answers from './Poll/components/Answers';
import classNames from 'classnames';
import { backgroundColorKeys } from '../../constants';

export const useStyles = makeStyles(
    (theme) =>
        createStyles({
            root: {},
            wrapper: {
                paddingBottom: '2.5rem',
                height: '100%',
            },
            content: {
                backgroundColor: theme.manakin.primaryColor[500],
                padding: `24px 40px`,
                height: '100%',
            },
            contentSubTitle: {
                fontSize: '1.5rem',
                lineHeight: '3rem',
                marginBottom: 0,
                color: theme.palette.getContrastText(theme.manakin.primaryColor[500]),
            },
            contentTitle: {
                fontSize: '2.5rem',
                lineHeight: '2.9rem',
                marginBottom: 30,
                color: theme.palette.getContrastText(theme.manakin.primaryColor[500]),
            },
            primary: {
				backgroundColor: theme.manakin.primaryWorkformColor[500],
				padding: 50,
				'& $contentSubTitle': {
					color: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),
				},
				'& $contentTitle': {
					color: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),
				}
			},
			secondary: {
				backgroundColor: theme.manakin.secondaryWorkformColor[500],
				padding: 50,
				'& $contentSubTitle': {
					color: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
				},
				'& $contentTitle': {
					color: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
				}
			},
			noBG: {
				backgroundColor: 'white',
				padding: 50,
				'& $contentSubTitle': {
					color: theme.manakin.defaultContentColor[500],
				},
				'& $contentTitle': {
					color: theme.manakin.defaultContentColor[500],
				}
			}
        }),
    {
        name: 'PollAppWidget',
    },
);

const PollWidget = (props) => {
    const { gridProps, loading, poll = {}, backgroundColor } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const snackBar = useSnackBar();

    const [ value, setValue ] = useState('');
    const [ showPollResults, setShowPollResults ] = useState(false);
    const [ pollResults, setPollResults ] = useState(null);

    const [ submitPollAnswer ] = useMutation(GQL_MUTATE_SUBMIT_POLL_ANSWER);
    const { loading: loadingResult, data: pollData = {} } = useQuery(GQL_FETCH_POLL_RESULT, {
        variables: {
            poll: poll.id,
        },
    });

    useEffect(() => {
        if (!loadingResult && pollData && pollData.pollResult && pollData.pollResult.selectedAnswer) {
            setPollResults(pollData.pollResult);
            setShowPollResults(true);
        }
    }, [ loadingResult, pollData ]);

    const handleChange = (event) => {
        setValue(event.target.value);

        submitPollAnswer({
            variables: {
                poll: poll.id,
                answer: event.target.value,
            },
        }).then(
            (response) => {
                if (response.data && response.data.submitPollAnswer) {
                    setPollResults(response.data.submitPollAnswer);
                    setShowPollResults(true);
                }
            },
            () => {
                snackBar.error(t('app.widget-dashboard.poll-widget.save-failed'));
            },
        );
    };

    if (loading || loadingResult) return <Loader />;

    return (
        <Grid className={classes.root} item {...gridProps}>
            <div className={classes.wrapper}>
                <div className={classNames(
					classes.content,
					{ [classes.primary]: backgroundColor === backgroundColorKeys.primary },
					{ [classes.secondary]: backgroundColor === backgroundColorKeys.secondary },
					{ [classes.noBG]: backgroundColor === backgroundColorKeys.none },
				)}>
                    <Typography className={classes.contentSubTitle}>{t('app.widget-dashboard.poll-widget.subtitle')}</Typography>
                    <Typography component='h3' variant='h3' className={classes.contentTitle}>
                        {poll && poll.question || ''}
                    </Typography>

                    {poll && poll.answers && (
                        <Answers
                            value={value}
                            poll={poll}
                            pollResults={pollResults}
                            showPollResults={showPollResults}
                            onChange={handleChange}
                            backgroundColor={backgroundColor} />
                    )}
                </div>
            </div>
        </Grid>
    );
};

export default PollWidget;
