import React from 'react';
import { styles } from './styles';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import SingleDot from './SingleDot';
import { WorkformLayout } from '@manakin/app-core';
import Button from '@material-ui/core/Button';
import { ProgressiveImage } from '@manakin/app-core';
import { withTranslation } from 'react-i18next';

class HotspotsMap extends React.PureComponent {
	state = {
		isVisible: false,
	};

	openAll = () => {
		this.setState((prevState) => ({
			isVisible: !prevState.isVisible,
		}));
	};

    render() {
        const {
            classes,
            question = '',
            introduction: _introduction = false,
            image = {},
            mapSpots = [],
            t
        } = this.props;

        const introduction = _introduction
            ? _introduction
            : t("app.workforms.hotspot-map.introduction");

		return (
			<div className={classes.container}>
				<WorkformLayout
					question={question}
					instruction={introduction}
					loading={false}
					bigAnswer={true}
					renderAnswers={() => (
						<div className={classes.HotspotWrapper}>
							<ProgressiveImage
								preview={
									image && image.thumbnailUrl
										? image.thumbnailUrl
										: ''
								}
								image={image ? image.url : ''}
							/>
							{mapSpots &&
								mapSpots.map((hotSpots) => (
									<SingleDot
										{...this.state}
										{...hotSpots}
										key={hotSpots.id}
									/>
								))}

                            <Button
                                variant="contained"
                                className={classes.button}
                                onClick={this.openAll}
                                color="primary"
                            >
                                {this.state.isVisible
                                    ? t("app.workforms.hotspot-map.close-all")
                                    : t("app.workforms.hotspot-map.open-all")}
                            </Button>
                        </div>
                    )}
                />
            </div>
        );
    }
}

export default compose(
    withStyles(styles, { name: 'HotspotsMap' }),
    withTranslation()
)(HotspotsMap);
