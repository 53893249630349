import React, { useEffect, useState } from 'react';
import Fab from '@material-ui/core/Fab';
import MoreIcon from '@material-ui/icons/MoreVert';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'absolute',
            width: '30px',
            height: '30px',
            transform: 'translate(-50%, -50%)',
            zIndex: '2',
            borderRadius: '100%',
        },
        fields: {
            position: 'absolute',
            left: '100%',
            transform: 'translateX(10px)',
            padding: '10px',
            zIndex: '2',
        },
        navigation: {
            backgroundColor: 'white',
            width: '150px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
        field: {
            minWidth: '300px',
            margin: '10px 30px',
        },
    }),
);

const Hotspot = (props) => {
    const { hotspot, id, onChange, onDelete, onClose, onMove, onHotspotClick } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const [ edit, setEdit ] = useState(false);

    useEffect(() => {
        onClose();
    }, [ edit ]);

    const handleClick = () => {
        setEdit(!edit);
    };

    const handleChange = (name) => (event) => {
        if (onChange) {
            onChange({
                name: name,
                id: id,
                value: event.target.value,
            });
        }
    };

    const handleMove = () => {
        if (onMove) onMove(id);
        onClose();
    };

    const handleHotspotClick = (id) => (event) => {
        if (onHotspotClick) onHotspotClick(id);
    };

    return (
        <div
            className={classes.root}
            style={{ left: `${hotspot.x}%`, top: `${hotspot.y}%` }}
        >
            <Fab
                size='small'
                color='secondary'
                aria-label={t('common.add')}
                className={classes.hotspot}
                onClick={handleHotspotClick(id)}
            >
                <MoreIcon />
            </Fab>
            {hotspot.open && (
                <div className={classes.navigation}>
                    <List component='nav'>
                        <ListItem button onClick={handleClick}>
                            <ListItemText primary={t('common.edit')} />
                        </ListItem>
                        <ListItem button onClick={handleMove}>
                            <ListItemText primary={t('common.move-plural')} />
                        </ListItem>
                        <ListItem button onClick={() => onDelete(id)}>
                            <ListItemText primary={t('common.delete')} />
                        </ListItem>
                        <ListItem button onClick={onClose}>
                            <ListItemText primary={t('common.close')} />
                        </ListItem>
                    </List>
                </div>
            )}
            <Dialog
                open={edit}
                aria-labelledby='simple-dialog-title'
                className={classes.dialog}
            >
                <DialogTitle id='simple-dialog-title'>
                    {t('cms.workforms.hotspot.edit-dialog.title')}
                </DialogTitle>
                <TextField
                    id='title'
                    label={t('common.fields.title.label')}
                    className={classes.field}
                    value={hotspot.title}
                    onChange={handleChange('title')}
                    margin='normal'
                />
                <TextField
                    id='text'
                    label={t('common.fields.text.label')}
                    className={classes.field}
                    value={hotspot.text}
                    onChange={handleChange('text')}
                    margin='normal'
                />
                <FormControl className={classes.field}>
                    <InputLabel
                        shrink
                        htmlFor='popupOrientation-placeholder'
                    >
                        {t('cms.workforms.hotspot.edit-dialog.orientation')}
                    </InputLabel>
                    <Select
                        value={hotspot.popupOrientation}
                        onChange={handleChange('popupOrientation')}
                        input={
                            <Input
                                name='popupOrientation'
                                id='popupOrientation-placeholder'
                            />
                        }
                        name={t('cms.workforms.hotspot.edit-dialog.orientation')}
                    >
                        <MenuItem value={'LEFT_TOP'}>LEFT_TOP</MenuItem>
                        <MenuItem value={'LEFT_BOTTOM'}>LEFT_BOTTOM</MenuItem>
                        <MenuItem value={'RIGHT_BOTTOM'}>RIGHT_BOTTOM</MenuItem>
                        <MenuItem value={'RIGHT_TOP'}>RIGHT_TOP</MenuItem>
                    </Select>
                </FormControl>
                <DialogActions>
                    <Button
                        onClick={handleClick}
                        color='primary'
                        autoFocus
                    >
                        {t('common.close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Hotspot;
