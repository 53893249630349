import React from 'react';
import Button from '@material-ui/core/Button';
import { useStyles } from '../styles';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ButtonContainer = (props) => {
	const { disabled = false, onSubmit, data = {} } = props;
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<Button variant="contained" color="primary" fullWidth className={classes.button} onClick={onSubmit} disabled={disabled}>
			{data.buttonText || t('common.check-answer')}
		</Button>
	);
};

export default compose(connect((state) => ({ config: state.config })))(ButtonContainer);
