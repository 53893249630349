"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _appCore = require("@manakin/app-core");

var _Table = require("./Table");

var _Table2 = _interopRequireDefault(_Table);

var _styles = require("@material-ui/styles");

var _Dialog = require("./Dialog");

var _Dialog2 = _interopRequireDefault(_Dialog);

var _graphql = require("../graphql");

var _reactHooks = require("@apollo/react-hooks");

var _downloadjs = require("downloadjs");

var _downloadjs2 = _interopRequireDefault(_downloadjs);

var _ResendRatingRequestDialog = require("./ResendRatingRequestDialog");

var _ResendRatingRequestDialog2 = _interopRequireDefault(_ResendRatingRequestDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useStyles = (0, _styles.makeStyles)(function (theme) {
	return {
		button: {
			marginBottom: "50px"
		},
		request: {
			margin: "0 0 50px 0"
		}
	};
});

var Program = function Program(props) {
	var _props$ratingRequests = props.ratingRequests,
	    ratingRequests = _props$ratingRequests === undefined ? [] : _props$ratingRequests,
	    BPVBTrainers = props.BPVBTrainers,
	    superObj = props.superObj;
	//state hooks

	var classes = useStyles();
	var defaultState = {
		open: false,
		resendDialogOpen: false,
		trainer: null,
		request: null
	};

	var _useState = (0, _react.useState)(defaultState),
	    _useState2 = _slicedToArray(_useState, 2),
	    state = _useState2[0],
	    setState = _useState2[1];

	//mutations


	var _useMutation = (0, _reactHooks.useMutation)(_graphql.GQL_REQUEST_APPROVAL, {
		refetchQueries: ["ratingRequests", "invitations"]
	}),
	    _useMutation2 = _slicedToArray(_useMutation, 2),
	    mutate = _useMutation2[0],
	    loading = _useMutation2[1].loading;

	var uniqueRequests = [];
	ratingRequests.forEach(function (req) {
		if (!uniqueRequests.some(function (i) {
			return i.program.id;
		})) {
			uniqueRequests.push(req);
		}
	});

	var handleClick = function handleClick(trainer, request) {
		if (request.element && request.element.id && superObj[request.element.id] && !superObj[request.element.id].result) {
			// Pending BPV report
			setState({
				open: false,
				resendDialogOpen: true,
				trainer: trainer,
				request: request
			});
		} else {
			setState({
				open: true,
				resendDialogOpen: false,
				trainer: trainer,
				request: request
			});
		}
	};

	var handleClose = function handleClose() {
		setState(defaultState);
	};

	var handleSubmit = function handleSubmit() {
		mutate({
			variables: {
				input: {
					finalRatingRequestId: state.request.id,
					supervisorId: state.trainer.id
				}
			}
		}).then(function (r) {
			handleClose();
		});
	};

	var handleDownload = function handleDownload(request) {
		(0, _downloadjs2.default)("/api/pdf/bpv/report/" + request.id + "/Rapport.pdf");
	};

	return _react2.default.createElement(
		_react2.default.Fragment,
		null,
		ratingRequests.filter(function (request) {
			return request.result;
		}).map(function (request) {
			return _react2.default.createElement(
				"div",
				{ className: classes.request, key: request.id },
				_react2.default.createElement(_appCore.BPVBDownloadButton, {
					className: classes.button,
					onClick: function onClick() {
						return handleDownload(request);
					},
					program: request.program
				}),
				_react2.default.createElement(_Table2.default, {
					onClick: handleClick,
					request: request,
					BPVBTrainers: BPVBTrainers,
					superObj: superObj
				}),
				_react2.default.createElement(_Dialog2.default, {
					onClose: handleClose,
					open: state.open,
					onSubmit: handleSubmit,
					loading: loading
				})
			);
		}),
		_react2.default.createElement(_ResendRatingRequestDialog2.default, {
			open: state.resendDialogOpen,
			finalRatingRequest: state.request,
			supervisor: state.trainer,
			onClose: handleClose })
	);
};

exports.default = Program;