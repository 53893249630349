export const styles = (theme) => ({
	root: {
		flexGrow: 1,
	},
	container: {
		maxWidth: "1300px",
		margin: "0 auto",
	},
	button: {
		display: "none",
		[theme.breakpoints.up("md")]: {
			width: "200px",
			position: "absolute",
			bottom: "10px",
			right: "10px",
			display: "block",
		},
	},
	image: {
		width: "100%",
		height: "100%",
		objectFit: "cover",
	},
	HotspotWrapper: {
		position: "relative",
	},
	addIcon: {
		position: "absolute",
		transform: "translate(-50%, -50%)",
		borderRadius: "50%",
		border: `18px solid black`,
		color: "white",
		zIndex: 1,
		cursor: "pointer",
		backgroundColor: "black",
		"& svg": {
			background: "transparent",
			display: "block !important",
			transform: "rotate(0deg)",
			transition: "0.2s",
		},
		[theme.breakpoints.up("md")]: {
			zIndex: 3,
		},
	},
	addIconRoot: {
		border: "2px solid black",
	},
	closeIcon: {
		display: "none",
		[theme.breakpoints.up("md")]: {
			top: "0",
			zIndex: "4",
			display: "block",
			right: "auto",
			borderRadius: "100%",
			"& svg": {
				transform: "rotate(45deg)",
				transition: "0.2s",
			},
		},
	},

	closeMobile: {
		zIndex: "10",
		background: "black",
		width: "60px",
		height: "60px",
		right: "0%",
		position: "absolute",
		top: "0%",
		color: "white",
		textAlign: "center",

		"& svg": {
			transform: "rotate(45deg)",
			position: "relative",
			marginTop: "20px",
		},

		[theme.breakpoints.up("md")]: {
			display: "none",
		},
		[theme.breakpoints.down("sm")]: {
			position: "fixed",
		},
	},
	positioning: {
		[theme.breakpoints.up("md")]: {
			position: "absolute",
		},
	},
	positioningMobile: {
		position: "absolute",
		[theme.breakpoints.up("md")]: {
			position: "unset",
		},
	},
	title: {
		margin: 0,
	},
	HotspotTitle: {
		top: "0",
		width: "100%",
		height: "100%",
		position: "absolute",
		background: "white",
		zIndex: "2",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		opacity: 0,
		transition: "opacity .2s",
		pointerEvents: "none",
		[theme.breakpoints.down("sm")]: {
			position: "fixed",
		},

		[theme.breakpoints.up("md")]: {
			width: "auto",
			left: "5px",
			height: "60px",
			paddingLeft: "40px",
			whiteSpace: "nowrap",
			paddingTop: "0",
			alignItems: "center",
			top: "-30px",

			"&:after": {
				content: '""',
				backgroundColor: "white",
				position: "absolute",
				right: "-50px",
				top: "0",
				width: "50px",
				height: "60px",
				borderTopRightRadius: "50%",
				borderBottomRightRadius: "50%",
			},
		},
	},
	titleLeft: {
		paddingRight: "0px",
		[theme.breakpoints.up("md")]: {
			right: "0px",
			position: "absolute",
			paddingRight: "40px",
			paddingLeft: "0px",
			left: "auto",

			"&:after": {
				content: '""',
				left: "-50px",
				borderTopRightRadius: "0",
				borderBottomRightRadius: "0",
				borderBottomLeftRadius: "50%",
				borderTopLeftRadius: "50%",
			},
		},
	},
	HotspotBlockWrapper: {
		position: "absolute",
		top: "0%",
		left: "0%",
		background: "white",
		width: "100%",
		padding: "25px",
		height: "100%",
		zIndex: "2",
		opacity: 0,
		transition: "opacity .2s",
		pointerEvents: "none",
		[theme.breakpoints.up("md")]: {
			position: "absolute",
			background: "white",
			padding: "36px 40px",
			width: "400px",
			height: "auto",
			textAlign: "left",
			zIndex: "10 !important",
		},
		[theme.breakpoints.down("sm")]: {
			position: "fixed",
		},
	},
	HotspotBlockContent: {
		transform: "translate(0%, -50%)",
		top: "50%",
		position: "relative",
		textAlign: "center",
		[theme.breakpoints.up("md")]: {
			top: "0%",
			position: "relative",
			transform: "none",
			textAlign: "left",
		},
	},
	HotspotBlockTitle: {
		fontWeight: "bold",
		letterSpacing: "2px",
		marginBottom: "10px",
		fontSize: "18px",
		fontFamily: theme.manakin.defaultTitleFont,
	},
	HotspotBlockText: {
		fontSize: "18px",
		lineHeight: "36px",
		fontFamily: theme.manakin.defaultTextfont,
	},
	leftTop: {
		transform: "none",

		[theme.breakpoints.up("md")]: {
			position: "absolute",
			transform: "translate(-110%, -100%)",
		},
	},
	leftBottom: {
		transform: "none",

		[theme.breakpoints.up("md")]: {
			position: "absolute",
			transform: "translate(-110%, 0%)",
		},
	},
	rightBottom: {
		transform: "none",

		[theme.breakpoints.up("md")]: {
			position: "absolute",
			transform: "translate(10%, 0%)",
		},
	},
	rightTop: {
		transform: "none",
		[theme.breakpoints.up("md")]: {
			position: "absolute",
			transform: "translate(10%, -100%)",
		},
	},
	show: {
		opacity: 1,
		pointerEvents: "visible",
		zIndex: "3",
	},
});
