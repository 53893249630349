import React, { useEffect, useMemo, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { AccessControl } from "@manakin/app-core";
import Typography from "@material-ui/core/Typography";
import { GQL_FETCH_SCHOOL_CLASS, GQL_FETCH_TEACHER_SCHOOL_CLASSES } from './graphql';
import { useDispatch, useSelector } from "react-redux";
import { setAppBarBackgroundColor, setBackButton } from "@manakin/app-core/AppBar/actions";
import { SimpleSelect } from "@manakin/core";
import { useTranslation } from "react-i18next";
import { useQuery, useApolloClient } from "@apollo/react-hooks";
import { useLocation } from "react-router-dom";
import ProgramOrder from "./ProgramOrder";
import { getAppUser } from '@manakin/authentication/selectors';
import { getGroup } from "@manakin/app-core/GroupsDropdown/selectors";
import { schoolManagerRole, teacherRoles, userHasAnyRole } from '@manakin/app-core/lib';

const useStyles = makeStyles((theme) =>
        createStyles({
            root: {
                minHeight: "100vh",
                backgroundColor: theme.manakin.primaryColor[500],
            },
            wrapper: {
                ...theme.manakin.smallWrapper,
            },
            heading: {
                padding: "18rem 0 10rem 0rem",
                textAlign: "center",
            },
            title: {
                fontSize: "4rem",
                lineHeight: "5.2rem",
                letterSpacing: ".4rem",
            },
            description: {
                marginBottom: 0,
            },
            center: {
                textAlign: "center",
            },
            contentWrapper: {
                ...theme.manakin.defaultWrapper,
                maxWidth: "880px",
                padding: "0 1rem",
            },
            select: {
                marginBottom: "6rem",
                minWidth: "20rem",
            },
            selectField: {
                marginBottom: "3rem!important",
                "& span": {
                    marginBottom: "0!important",
                },
            },
        }),
    {
        name: "AppTeacherProgram",
    },
);

const TeacherProgram = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const client = useApolloClient();

    const config = useSelector((state) => state.config) || {};
    const group = useSelector((state) => getGroup(state)) || {};
    const storedAppUser = useSelector((state) => getAppUser(state)) || {};
    const [ programOptions, setProgramOptions ] = useState([]);
    const [ selectedProgramId, setSelectedProgramId ] = useState();
    const [ hasSchoolClasses, setHasSchoolClasses ] = useState(true);
    const isTeacher = userHasAnyRole(storedAppUser, teacherRoles);

    const { data: schoolClassData = {}, loading: schoolClassLoading } = useQuery(GQL_FETCH_SCHOOL_CLASS, {
        variables: {
            id: group.id || 0,
        },
    });
    const { schoolClass } = schoolClassData || {};

    useEffect(() => {
        const { customColors = {} } = config;

        dispatch(setAppBarBackgroundColor({
            backgroundColor: customColors.accountAppBar || "white",
            color: "secondary",
            path: location.pathname,
        }));

        dispatch(setBackButton({
            show: true,
            location: location.pathname || "/teacherProgram",
        }));

        checkTeacherSchoolClasses();
    }, []);

    useEffect(() => {
        if (!schoolClassLoading && schoolClass) {
            if (schoolClass.products) {
                let programs = [];
                for (let product of schoolClass.products) {
                    programs.push({
                        id: product.program.id,
                        label: product.program.name,
                    });
                }

                setProgramOptions(programs);
            }
        }
    }, [ schoolClassLoading ]);

    const handleChange = (value) => {
        setSelectedProgramId(value);
    };

    const checkTeacherSchoolClasses = () => {
        if (!isTeacher) return;

        // Fetch all schools and classes of the given teacher to check if there is any schoolclass
        // connected to the teacher. If not, notify the teacher to connect with a schoolclass first
        client.query({
            query: GQL_FETCH_TEACHER_SCHOOL_CLASSES,
            variables: {
                filter: {
                    manager: storedAppUser.id,
                    types: [ 'School' ],
                },
            },
            fetchPolicy: 'no-cache',
        }).then(result => {
            if (result && result.data && result.data.groups && result.data.groups.groups) {
                let teacherHasAnyClass = false;
                result.data.groups.groups.forEach(group => {
                    if ('classes' in group) { // School
                        if (group.classes.some(schoolClass => schoolClass.teachers && schoolClass.teachers.some((teacher) => teacher.id === storedAppUser.id))) {
                            teacherHasAnyClass = true;
                        }
                    } else if ('teachers' in group) { // Schoolclass
                        if (group.teachers && group.teachers.some((teacher) => teacher.id === storedAppUser.id)) {
                            teacherHasAnyClass = true;
                        }
                    }
                });

                setHasSchoolClasses(teacherHasAnyClass);
            }
        }).catch(() => null);
    };

    const { program, programName, programDescription } = useMemo(() => {
        if (schoolClass) {
            const products = schoolClass.products;
            const product = products && products.length ? products.find(product => product.program && product.program.id === selectedProgramId) || products[0] : null;

            const program = product && product.program ? product.program : {};
            const programName = product === null ? t("app.teacher-program.school-class-no-program") : program.name || t("app.teacher-program.choose-program");
            const programDescription = product === null ? "" : program.description || (program.id ? "" : t("app.teacher-program.choose-school-class-detailed"));

            return { program, programName, programDescription };
        }

    }, [ schoolClass, selectedProgramId ]) || {};


    if (schoolClassLoading) {
        return <div>{t("common.loading")}</div>;
    }

    if (!schoolClass || !hasSchoolClasses) {
        return (
            <div className={classes.root}>
                <div className={classes.heading}>
                    <div className={classes.wrapper}>
                        <Typography
                            component='h1'
                            variant='h2'
                            classes={{ h2: classes.title }}
                        >
                            {t("app.teacher-program.choose-school-class")}
                        </Typography>
                    </div>
                </div>
                <div className={classes.contentWrapper}>
                    <Typography component='p' className={classes.center}>
                        {!hasSchoolClasses ? t("app.teacher-program.no-school-class-connected") : t("app.teacher-program.no-school-class-selected")}
                    </Typography>
                </div>
            </div>
        );
    }

    return (
        <AccessControl role={[ ...teacherRoles, schoolManagerRole ]}>
            <div className={classes.root}>
                <div className={classes.heading}>
                    <div className={classes.wrapper}>
                        {programOptions && programOptions.length > 1 &&
                            <div className={classes.selectField}>
                                <SimpleSelect
                                    options={programOptions}
                                    onChange={handleChange}
                                    emptyLabel={t("app.teacher-program.choose-program")}
                                    initialValue={programOptions[0].id}
                                />
                            </div>
                        }
                        <Typography component='h1' variant='h2' classes={{ h2: classes.title }}>
                            {programName || ""}
                        </Typography>
                        <Typography component='p' variant='body1' className={classes.description}>
                            {programDescription || ""}
                        </Typography>
                    </div>
                </div>
                <div className={classes.contentWrapper}>
                    {program && (
                        <div>
                            <ProgramOrder
                                program={program}
                                schoolClass={schoolClass}
                                disable={schoolClass.fixedBoxes}
                            />
                        </div>
                    )}
                </div>
            </div>
        </AccessControl>
    );
};

export default TeacherProgram;