'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.GQL_FETCH_CMS_EXTERNAL_LINK_COLLECTION = exports.GQL_FETCH_CMS_FACT_CARD = exports.GQL_FETCH_BOX_RESULTS = exports.GQL_FETCH_FACTCARDREFERRAL = exports.GQL_FETCH_MASTERCLASS = undefined;

var _templateObject = _taggedTemplateLiteral(['\n    query masterClass($id: ID!) {\n        masterClass(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query masterClass($id: ID!) {\n        masterClass(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n    query factCardReferral($id: ID!) {\n        factCardReferral(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query factCardReferral($id: ID!) {\n        factCardReferral(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject3 = _taggedTemplateLiteral(['\n    query boxResults($program: ID!, $user: ID!) {\n        boxResults(program: $program, user: $user) {\n            ', '\n        }\n    }\n'], ['\n    query boxResults($program: ID!, $user: ID!) {\n        boxResults(program: $program, user: $user) {\n            ', '\n        }\n    }\n']),
    _templateObject4 = _taggedTemplateLiteral(['\n    query factCard($id: ID!) {\n        factCard(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query factCard($id: ID!) {\n        factCard(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject5 = _taggedTemplateLiteral(['\n\tquery externalLinkCollection($id: ID!) {\n\t\texternalLinkCollection(id: $id) {\n\t\t\t', '\n\t\t}\n\t}\n'], ['\n\tquery externalLinkCollection($id: ID!) {\n\t\texternalLinkCollection(id: $id) {\n\t\t\t', '\n\t\t}\n\t}\n']);

var _graphqlTag = require('graphql-tag');

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

var _graphQlHelpers = require('../graphQlHelpers');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_MASTERCLASS = exports.GQL_FETCH_MASTERCLASS = (0, _graphqlTag2.default)(_templateObject, _graphQlHelpers.masterClass);

var GQL_FETCH_FACTCARDREFERRAL = exports.GQL_FETCH_FACTCARDREFERRAL = (0, _graphqlTag2.default)(_templateObject2, _graphQlHelpers.factCardReferral);

var GQL_FETCH_BOX_RESULTS = exports.GQL_FETCH_BOX_RESULTS = (0, _graphqlTag2.default)(_templateObject3, _graphQlHelpers.boxResults);

var GQL_FETCH_CMS_FACT_CARD = exports.GQL_FETCH_CMS_FACT_CARD = (0, _graphqlTag2.default)(_templateObject4, _graphQlHelpers.factCard);

var GQL_FETCH_CMS_EXTERNAL_LINK_COLLECTION = exports.GQL_FETCH_CMS_EXTERNAL_LINK_COLLECTION = (0, _graphqlTag2.default)(_templateObject5, _graphQlHelpers.externalLinkCollection);