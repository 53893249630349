'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _recompose = require('recompose');

var _styles = require('@material-ui/core/styles');

var _reactApollo = require('react-apollo');

var _appCore = require('@manakin/app-core');

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _TableRow = require('@material-ui/core/TableRow');

var _TableRow2 = _interopRequireDefault(_TableRow);

var _TableCell = require('@material-ui/core/TableCell');

var _TableCell2 = _interopRequireDefault(_TableCell);

var _MailOutline = require('@material-ui/icons/MailOutline');

var _MailOutline2 = _interopRequireDefault(_MailOutline);

var _reactRedux = require('react-redux');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _lib = require('./lib');

var _reactI18next = require('react-i18next');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
	return {
		name: {
			margin: 0,
			fontWeight: '500'
		},
		hover: {
			cursor: 'pointer',
			'&:hover': {
				color: theme.manakin.defaultContentHover[500]
			}
		},
		content: {
			margin: 0
		},
		icon: {
			cursor: 'pointer',
			transition: 'color .3s',
			'&:hover': {
				color: theme.manakin.defaultContentHover[500]
			}
		},
		tableRow: {
			display: 'none',
			'& td:first-of-type': {
				paddingLeft: theme.spacing(6)
			}
		},
		tableRowVisible: {
			display: 'table-row'
		}
	};
};

var Student = function Student(props) {
	var _props$classData = props.classData,
	    classData = _props$classData === undefined ? {} : _props$classData,
	    selectedPrograms = props.selectedPrograms,
	    selectedElements = props.selectedElements,
	    selectedBoxes = props.selectedBoxes,
	    availablePrograms = props.availablePrograms,
	    userData = props.userData,
	    client = props.client,
	    classes = props.classes,
	    _props$config = props.config,
	    config = _props$config === undefined ? {} : _props$config,
	    hover = props.hover;

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var _config$pages = config.pages,
	    pages = _config$pages === undefined ? {} : _config$pages,
	    _config$elements = config.elements,
	    elementsConfig = _config$elements === undefined ? {} : _config$elements;
	var _pages$rapportage = pages.rapportage,
	    rapportage = _pages$rapportage === undefined ? {} : _pages$rapportage;

	var hideProgress = rapportage.hideProgress ? rapportage.hideProgress : false;

	var _useState = (0, _react.useState)(false),
	    _useState2 = _slicedToArray(_useState, 2),
	    loadingResults = _useState2[0],
	    setLoadingResults = _useState2[1];

	var _useState3 = (0, _react.useState)(null),
	    _useState4 = _slicedToArray(_useState3, 2),
	    learningResult = _useState4[0],
	    setLearningResult = _useState4[1];

	var _useState5 = (0, _react.useState)(0),
	    _useState6 = _slicedToArray(_useState5, 2),
	    averageProgramPercentage = _useState6[0],
	    setAverageProgramPercentage = _useState6[1];

	var _useState7 = (0, _react.useState)(),
	    _useState8 = _slicedToArray(_useState7, 2),
	    averageBoxPercentage = _useState8[0],
	    setAverageBoxPercentage = _useState8[1];

	var _useState9 = (0, _react.useState)(),
	    _useState10 = _slicedToArray(_useState9, 2),
	    averageElementPercentage = _useState10[0],
	    setAverageElementPercentage = _useState10[1];

	(0, _react.useEffect)(function () {
		setAverageBoxPercentage(0);
		setAverageProgramPercentage(0);
		setAverageElementPercentage(0);
	}, [classData.id]);

	(0, _react.useEffect)(function () {
		updatePrograms();
	}, [selectedPrograms], [selectedBoxes]);

	(0, _react.useEffect)(function () {
		updatePrograms();
	});

	(0, _react.useEffect)(function () {
		(0, _lib.fetchBoxesResults)(client, userData, selectedPrograms, selectedBoxes, availablePrograms).then(function (progress) {
			setLoadingResults(false);
			setAverageBoxPercentage(progress);
		}, function () {
			return setLoadingResults(false);
		});
	}, [selectedBoxes]);

	(0, _react.useEffect)(function () {
		if (selectedPrograms && selectedBoxes) {
			Promise.all(selectedPrograms.map(function (programOption) {
				return (0, _lib.fetchProgram)(client, userData, selectedBoxes, availablePrograms, programOption.value);
			})).then(function (programBoxesResults) {
				var progressAndResult = (0, _lib.calculateBoxElementsProgressAndResult)(selectedBoxes, selectedElements, programBoxesResults);
				setAverageElementPercentage(progressAndResult.progress);
				setLearningResult(progressAndResult.learningResult);
				setLoadingResults(false);
			}).catch(function () {
				setAverageElementPercentage(0);
				setLoadingResults(false);
			});
		}
	}, [selectedElements]);

	var updatePrograms = function updatePrograms() {
		(0, _lib.fetchProgramsResults)(client, userData, selectedPrograms).then(function (progress) {
			setLoadingResults(false);
			setAverageProgramPercentage(progress);
		}, function () {
			return setLoadingResults(false);
		});
	};

	var handleMailClick = function handleMailClick() {
		if (props.onMailClick) props.onMailClick(userData);
	};

	var handleClick = function handleClick() {
		if (!elementsConfig.exerciseCard) return;
		if (props.onClick) props.onClick(userData);
	};

	return _react2.default.createElement(
		_TableRow2.default,
		null,
		_react2.default.createElement(
			_TableCell2.default,
			{ component: 'th', scope: 'row', onClick: handleClick },
			_react2.default.createElement(
				_Typography2.default,
				{
					component: 'p',
					variant: 'body1',
					className: (0, _classnames2.default)(classes.name, _defineProperty({}, classes.hover, elementsConfig.exerciseCard && hover))
				},
				userData.fullName
			)
		),
		_react2.default.createElement(
			_TableCell2.default,
			{ align: 'left' },
			_react2.default.createElement(
				_Typography2.default,
				{ component: 'p', variant: 'body1', className: classes.content },
				classData.name
			)
		),
		!hideProgress && _react2.default.createElement(
			_TableCell2.default,
			{ align: 'left' },
			loadingResults ? _react2.default.createElement(_appCore.Loader, null) : _react2.default.createElement(_appCore.PercentageBar, {
				percentage: selectedElements && selectedElements.length ? averageElementPercentage : selectedBoxes && selectedBoxes.length ? averageBoxPercentage : averageProgramPercentage
			})
		),
		_react2.default.createElement(
			_TableCell2.default,
			{ align: 'left' },
			_react2.default.createElement(
				_Typography2.default,
				{ component: 'p', variant: 'body1', className: classes.content },
				learningResult ? learningResult : t('common.not-applicable-abbreviated')
			)
		),
		_react2.default.createElement(
			_TableCell2.default,
			{ align: 'right' },
			_react2.default.createElement(
				'span',
				{ className: classes.icon },
				_react2.default.createElement(_MailOutline2.default, { onClick: handleMailClick })
			)
		)
	);
};

exports.default = (0, _recompose.compose)((0, _reactRedux.connect)(function (state) {
	return { config: state.config };
}), (0, _styles.withStyles)(styles), _reactApollo.withApollo)(Student);