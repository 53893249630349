export const styles = (theme) => ({
	headingRoot: {
		padding: '100px 0 50px',
		backgroundColor: theme.manakin.defaultElementBGColor[500],
		position: 'relative',
		[theme.breakpoints.up('lg')]: {
			padding: '116px 0 50px',
		},
	},
	wrapper: {
		...theme.manakin.largeWrapper,
		textAlign: 'center',
	},
	preTitle: {
		color: theme.palette.getContrastText(
			theme.manakin.defaultElementBGColor[500]
		),
	},
	title: {
		maxWidth: '70rem',
		margin: '0 auto',
		[theme.breakpoints.up('lg')]: {
			maxWidth: '1050px',
			padding: '3rem 0',
			marginBottom: '2.4rem',
		},
	},
	titleFont: {
		marginBottom: '4.4rem',
		color: theme.palette.getContrastText(
			theme.manakin.defaultElementBGColor[500]
		),
		letterSpacing: 0,
		[theme.breakpoints.up('lg')]: {
			fontSize: '14rem',
			lineHeight: '16rem',
		},
	},
	body: {
		marginBottom: '4rem',
		color: theme.palette.getContrastText(
			theme.manakin.defaultElementBGColor[500]
		),
	},
	introduction: {
		maxWidth: '620px',
		margin: '0 auto',
	},
	firstButton: {
		marginRight: '1rem',
	},
	secondButton: {
		marginLeft: '1rem',
		backgroundColor: 'white',
		color: 'black',
	},
	innerRoot: {
		width: '100%',
	},
	points: {
		position: 'absolute',
		bottom: '-4rem',
		left: '50%',
		transform: 'translateX(-50%)',
		[theme.breakpoints.up('md')]: {
			bottom: '2.6rem',
			left: 'auto',
			transform: 'translateX(0)',
			right: '4rem',
		},
	},
	fullHeight: {
        height: '100vh'
    }
});
