import React from 'react';
import { styles } from './styles';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { WorkformLayout } from '@manakin/app-core';
import Item from './parts';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { FeedbackBox } from '@manakin/app-workforms';
import { withTranslation } from 'react-i18next';

class WhatIsWhatQuestion extends React.PureComponent {
	handleClick = (item) => {
		if (this.props.onClick && !this.props.data.done) this.props.onClick(item);
	};

	handleSubmit = () => {
		if (this.props.onSubmit) this.props.onSubmit();
	};

	render() {
		const { classes, data = {}, disabled, onFinished, onCorrect, t, saving } = this.props;
		const { allCorrect = false, correct = false } = data;

		return (
			<WorkformLayout
				quarterQuestion={true}
				question={data.question || ''}
				instruction={data.introduction || t('app.workforms.what-is-what-question.introduction')}
				loading={false}
				saving={saving}
				backgroundColor={data.backgroundColor || false}
				renderAnswers={() => (
					<div className={classes.root}>
						<div className={classes.wrapper}>
							<div className={classes.content}>
								<div className={classes.questions}>
									{data.questions &&
										data.questions.map((item, idx) => (
											<Item
												done={data.done || false}
												key={item.id}
												idx={idx}
												item={item}
												length={data.questions.length}
												onArrowClick={this.handleClick}
												animateNext={data.animateNext}
												animatePrev={data.animatePrev}
												animationSettings={data.animationSettings}
												correctAnswer={data.correctAnswer || null}
												allCorrect={allCorrect && correct}
											/>
										))}
								</div>
								<div className={classes.answers}>
									{data.answers &&
										data.answers.map((item, idx) => (
											<Item
												done={data.done || false}
												key={item.id}
												idx={idx}
												item={item}
												isAnswer={true}
												length={data.answers.length}
												onArrowClick={this.handleClick}
												animationSettings={data.animationSettings}
												animateNext={data.animateNext}
												animatePrev={data.animatePrev}
												correctAnswer={data.correctAnswer || null}
												allCorrect={allCorrect && correct}
											/>
										))}
								</div>
							</div>
							<div className={classes.buttons}>
								{!data.feedback ? (
									<Button
										variant="contained"
										color="primary"
										fullWidth
										disabled={disabled}
										className={classNames(classes.button, {
											[classes.hide]: data.feedback || false,
										})}
										onClick={this.handleSubmit}
									>
										{data.buttonText || t('common.check-answer')}
									</Button>
								) : (
									<div className={classNames(classes.feedback, { [classes.activeFeedback]: data.feedback })}>
										<FeedbackBox data={data} onFinished={onFinished} onCorrect={onCorrect} outlined={true} />
									</div>
								)}
							</div>
						</div>
					</div>
				)}
			/>
		);
	}
}

export default compose(withStyles(styles, { name: 'AppWhatIsWhatQuestion' }), withTranslation())(WhatIsWhatQuestion);
