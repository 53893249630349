import React, { useState } from 'react';
import { withForm, SelectField } from '@manakin/core';
import { StyledDialog } from '@manakin/app-core';
import { compose } from 'recompose';
import { useMutation } from 'react-apollo';
import { GQL_CREATE_BOX_RESULT } from '../graphql/graphql';
import { useTranslation } from 'react-i18next';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Cookies from 'js-cookie';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			selectField: {
				marginTop: '2rem',
			},
			registerTitle: {
				marginBottom: '15px',
			},
		}),
	{
		name: 'AppRegisterDialog',
	}
);

const form = {
	register: {
		required: true,
	},
};

const CreateBoxResultDialog = (props) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { form, onClose, open, boxData, program, appUser } = props;

	//states
	const [boxResult, setBoxResult] = useState(false);

	//mutations
	const [mutate] = useMutation(GQL_CREATE_BOX_RESULT);

	const handleSubmit = () => {
		const { onSubmit } = form;

		form.onValidate().then(() =>
			onSubmit().then((data) => {
				mutate({
					variables: {
						...data,
						box: boxData.id,
						program: program,
						user: appUser.id,
						finished: true,
					},
				}).then((result) => {
					if (
						result &&
						result.data &&
						result.data.upsertBoxResult &&
						result.data.upsertBoxResult.result &&
						result.data.upsertBoxResult.result.register
					) {
						setBoxResult(result.data.upsertBoxResult.result.register);
						Cookies.set(`box${program}${appUser.id}popup`, '1');
					}
				});
			})
		);
	};

	return (
		<StyledDialog
			open={open}
			title={boxResult ? t('app.box.create-box-result-dialog.title-result') : t('app.box.create-box-result-dialog.title')}
			fullWidth={true}
			positiveButtonText={boxResult ? t('common.resume') : t('common.send')}
			negativeButtonText={boxResult ? '' : t('common.cancel')}
			onNegativeButtonClick={onClose}
			onPositiveButtonClick={boxResult ? onClose : handleSubmit}
			onClose={onClose}
			contentText={
				boxResult ? t('app.box.create-box-result-dialog.content-text-result') : t('app.box.create-box-result-dialog.content-text')
			}
			extraContent={
				boxResult ? (
					<Typography className={classes.registerTitle} variant="h4">
						{boxResult.title}
					</Typography>
				) : (
					<div className={classes.selectField}>
						<SelectField
							noLoading={true}
							options={boxData.registers || []}
							placeholder={t('app.box.create-box-result-dialog.placeholder')}
							label={t('app.box.create-box-result-dialog.label')}
							form={form}
							name="register"
						/>
					</div>
				)
			}
		></StyledDialog>
	);
};

export default compose(withForm(form))(CreateBoxResultDialog);
