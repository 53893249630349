import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent, DialogContentText, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Trans, useTranslation } from 'react-i18next';
import { SimpleSelect } from '@manakin/core';
import { GQL_CHANGE_FAVOURITE_PRODUCT_ON_LICENSE } from './graphql';
import { useMutation } from 'react-apollo';
import { useSnackBar } from '@manakin/hooks';

const useStyles = makeStyles((theme) => ({
	dialogPaper: {
		flexDirection: 'column',
		padding: theme.spacing(6),
	},
	dialogTitle: {
		width: '100%',
		margin: 0,
		paddingBottom: '12px',
		fontSize: '3rem',
		lineHeight: '3rem',
		textTransform: 'uppercase',
		[theme.breakpoints.up('md')]: {
			paddingBottom: '32px',
		},
	},
	dialogActions: {
		display: 'flex',
		flexFlow: 'row wrap',
		flexDirection: 'column',
		marginTop: theme.spacing(2),
	},
	dialogText: {},
	button: {
		minHeight: '6rem',
		padding: 0,
		backgroundColor: theme.manakin.indicationColor[500],
		color: 'white',
		marginBottom: theme.spacing(),
		'&:hover': {
			backgroundColor: theme.manakin.indicationColor[700],
		},
	},
	selectFieldWrapper: {
		marginBottom: '15px',
	},
}));

const FavouriteProgramDialog = (props) => {
	const { onClose, open, appUser } = props;
	const classes = useStyles();
	const { t } = useTranslation();
	const snackBar = useSnackBar();
	const { licenses = [] } = appUser;
	const [selectedFavouriteProducts, setSelectedFavouriteProducts] = useState([]);

	const [changeFavouriteProductOnLicense] = useMutation(GQL_CHANGE_FAVOURITE_PRODUCT_ON_LICENSE, {
		refetchQueries: ['programs', 'currentAppUser'],
	});

	const getFavouriteProducts = (license) => {
		let favouriteProducts = [];

		if (license) {
			const product = license.product;

			if (!license.favouriteProductId) {
				if (product.favouriteRequired) {
					favouriteProducts.push({
						licenseId: license.code,
						id: product.id,
						label: product.name,
					});
				}

				if (product.subProducts) {
					product.subProducts.map((subProduct) => {
                        favouriteProducts.push({
                            licenseId: license.code,
                            id: subProduct.id,
                            label: subProduct.name,
                        });
					});
				}
			}
		}

		return favouriteProducts;
	};

	const handleChange = (license) => (selectedProductId) => {
		const selectedItem = {
			licenseId: license.code,
			productId: selectedProductId,
		};

		let array = [...selectedFavouriteProducts];
		if (array.some((item) => item.licenseId === license.code)) {
			array = array.map((item) => (item.licenseId === license.code ? selectedItem : item));
		} else {
			array.push(selectedItem);
		}

		setSelectedFavouriteProducts(array);
	};

	const handleClose = (event, reason) => {
		if (reason === 'backdropClick') return;
		if (onClose) onClose();
	};

	const handleClick = () => {
		let licensesWithoutFavouriteId = licenses.filter(
			(license) => !license.favouriteProductId && license.product.favouriteRequired && license.licenseGroup
		);

		let promises = [];
		if (selectedFavouriteProducts.length === licensesWithoutFavouriteId.length) {
			selectedFavouriteProducts.forEach((selectedFavouriteProduct) => {
				const promise = new Promise((resolve, reject) => {
					changeFavouriteProductOnLicense({
						variables: {
							licenseId: selectedFavouriteProduct.licenseId,
							favouriteProductId: selectedFavouriteProduct.productId,
						},
					}).then((response) => {
						resolve(response);
					});
				});

				promises.push(promise);
			});

			Promise.all(promises).then((responses) => {
				if (!responses.some((response) => response.errors)) {
					handleClose();
				} else {
					snackBar.error(t('app.dashboard.favourite-program-dialog.save-failed'));
				}
			});
		} else {
			snackBar.error(t('app.dashboard.favourite-program-dialog.form-error'));
		}
	};

	return (
		<Dialog open={open} handleClose={handleClose} PaperProps={{ className: classes.dialogPaper }}>
			<Typography variant="h3" className={classes.dialogTitle}>
				{t('app.dashboard.favourite-program-dialog.title')}
			</Typography>

			<DialogContent>
				<DialogContentText className={classes.dialogText}>
					<Trans i18nKey="app.dashboard.favourite-program-dialog.description-before-select-fields" />
				</DialogContentText>

				{licenses &&
					licenses.map((license) => {
						if (!license.favouriteProductId && license.product.favouriteRequired && license.licenseGroup) {
							return (
								<div className={classes.selectFieldWrapper}>
									<SimpleSelect
										emptyLabel={t('app.dashboard.favourite-program-dialog.select-label', {
											product: license.product ? license.product.name : '',
										})}
										onChange={handleChange(license)}
										options={getFavouriteProducts(license)}
									/>
								</div>
							);
						}
					})}

				<DialogContentText className={classes.dialogText}>
					<Trans i18nKey="app.dashboard.favourite-program-dialog.description-after-select-fields" />
				</DialogContentText>
			</DialogContent>

			<div className={classes.dialogActions}>
				<Button variant="contained" className={classes.button} onClick={handleClick}>
					{t('common.ok')}
				</Button>
			</div>
		</Dialog>
	);
};

export default FavouriteProgramDialog;
