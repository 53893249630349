import React from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { widget } from '../constants';
import BoxesCarousel from './BoxWidget/BoxesCarousel';
import NewsWidget from './NewsWidget/NewsWidget';
import SuggestionBoxWidget from './SuggestionBoxWidget/SuggestionBoxWidget';
import Grid from '@material-ui/core/Grid';
import PollWidget from './PollWidget/PollWidget';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				marginTop: '-51rem',
				backgroundColor: 'transparent',
				transition: 'background-color .3s',
			},
			wrapper: {
				...theme.manakin.defaultWrapper,
				textAlign: 'right',
				paddingBottom: '3rem',
				paddingLeft: 37,
				paddingRight: 37,
				maxWidth: 1230,
				[theme.breakpoints.up('md')]: {
					paddingBottom: '4.8rem',
				},
			},
			widgetsWrapper: {
				...theme.manakin.extraLargeWrapper,
				maxWidth: 1230
			},
		}),
	{ name: 'AppWidgetContainer' }
);

const WidgetContainer = (props) => {
	const { data = {}, loading } = props;
	const classes = useStyles();

	const fullWidthProps = { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 };
	const halfWidthProps = { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 };

	return (
		<div className={classes.root}>
			<Grid
				container
				spacing={3}
				classes={{
					container: classes.widgetsWrapper,
				}}
			>

				{data &&
					data.map((data, idx) => {
						if (data.type === widget.newsAppWidget) return <NewsWidget gridProps={fullWidthProps} key={data.id} {...data} />;
						else if (data.type === widget.boxAppWidget) return <BoxesCarousel gridProps={fullWidthProps} key={data.id} {...data} />;
						else if (data.type === widget.suggestionBoxAppWidget) return <SuggestionBoxWidget gridProps={halfWidthProps} {...data} loading={loading} />;
						else if (data.type === widget.pollAppWidget) return <PollWidget gridProps={halfWidthProps} {...data} loading={loading} />;
						else return '';
					})
				}
			</Grid>
		</div>
	);
};

export default WidgetContainer;
