'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.setCompletedWorkform = setCompletedWorkform;
exports.setCorrectScene = setCorrectScene;
var SET_COMPLETED_WORKFORM = exports.SET_COMPLETED_WORKFORM = '@manakin/app-core/SET_COMPLETED_WORKFORM';
var SET_CORRECT_SCENE = exports.SET_CORRECT_SCENE = '@manakin/app-core/SET_CORRECT_SCENE';

function setCompletedWorkform(data) {
    return {
        type: SET_COMPLETED_WORKFORM,
        payload: { data: data }
    };
}

function setCorrectScene(scene) {
    return {
        type: SET_CORRECT_SCENE,
        payload: { data: data }
    };
}