'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.getBoxResults = exports.CHANGE_BOX_RESULTS_ROOT = undefined;

var _reselect = require('reselect');

var _selectors = require('../selectors');

var CHANGE_BOX_RESULTS_ROOT = exports.CHANGE_BOX_RESULTS_ROOT = 'changeBoxResults';

var getState = (0, _reselect.createSelector)([_selectors.getCoreState], function (coreState) {
    return coreState[CHANGE_BOX_RESULTS_ROOT];
});

var getBoxResults = exports.getBoxResults = (0, _reselect.createSelector)([getState], function (state) {
    return state.results;
});