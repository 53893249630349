import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
    (theme) =>
        createStyles({
            preHeading: {
                textAlign: 'center',
                width: '100%',
                margin: '0 auto 1em',
                maxWidth: '560px',
            },
            wrapper: {
                textAlign: 'center',
            },
            link: {
                textDecoration: 'none',
            },
            root: {
                height: '100vh',
                padding: '80px 0 0',
                backgroundColor: theme.manakin.primaryColor[500],
                display: 'flex',
            },
            container: {
                display: 'flex',
                flexWrap: 'wrap',
                width: '100%',
                height: '100%',
                maxWidth: '900px',
                alignItems: 'center',
                margin: '0 auto',
                alignSelf: 'center',
                [theme.breakpoints.down('sm')]: {
                    width: '80%',
                },
            },
            content: {
                width: '100%',
                margin: '0 0 80px',
                textAlign: 'center',
                position: 'relative',
                zIndex: 1,
            },

            heading: {
                textAlign: 'center',
                width: '100%',
                [theme.breakpoints.down('sm')]: {
                    letterSpacing: '0.1rem',
                },
            },
            paragraph: {
                textAlign: 'center',
                width: '100%',
                margin: '0 auto 3em',
                maxWidth: '560px',
            },
            smallParagraph: {
                textAlign: 'center',
                width: '100%',
                margin: '0 auto',
                marginBottom: '0.5em',
                maxWidth: '560px',
            },
        }),
    {
        name: 'appConfirmRegistration',
    }
);
