import React, { useEffect } from 'react';
import Lesson from './Lesson';
import { withWorkforms } from '@manakin/app-core';
import { compose } from 'recompose';
import { GQL_FETCH_STYLESHEET } from './graphql';
import { useQuery } from '@apollo/react-hooks';
import { connect } from 'react-redux';
import { changeColor } from '@manakin/app-core/ColorService/actions';
import { useLocation } from 'react-router-dom';

const queryString = require('query-string');

const PreviewContainer = (props) => {
    const { match, workformsData, changeColor } = props;
    const query = match.params.query;
    const lessonData = queryString.parse(query);
    const location = useLocation();

    const { data = {}, loading } = useQuery(GQL_FETCH_STYLESHEET, {
        variables: {
            id: lessonData.styleSheet || '',
        },
    });

    useEffect(() => {
        const items = Array.isArray(lessonData.items)
            ? lessonData.items
            : lessonData.items
            ? [lessonData.items]
            : [];

        if (!loading) {
            const obj = {
                ...lessonData,
                headerImage: {
                    url: lessonData.headerImage,
                },
                image: {
                    url: lessonData.image,
                },
                workforms: [
                    ...items.map((item, idx) => ({
                        id: item,
                        type: lessonData.itemTypes[idx],
                    })),
                ],
                options: {
                    showFeedback: true,
                    nextButton: false,
                },
            };
            changeColor({
                ...data.styleSheet,
                location: location.pathname,
            });
            workformsData.loadWorkforms({ ...obj });
        }
    }, [loading]);

    const handleFinish = () => {
        return true;
    };

    return (
        <Lesson
            {...workformsData}
            lessonData={{ ...workformsData.rawData }}
            preview={true}
            workforms={workformsData.workforms}
            onFinish={handleFinish}
        />
    );
};

export default compose(
    connect(null, (dispatch) => ({
        changeColor: (styleSheet) => dispatch(changeColor(styleSheet)),
    })),
    withWorkforms()
)(PreviewContainer);
