import React from 'react';
import { GQL_FETCH_REGISTRATION_GROUPS } from '../graphql';
import { SelectField } from '@manakin/core';
import { useQuery} from "@apollo/react-hooks";
import { useTranslation } from 'react-i18next';

const SchoolClassSelector = (props) => {
	const { form, id } = props;
	const { t } = useTranslation();
	const schoolsOptions = [];
	const { data, error } = useQuery(GQL_FETCH_REGISTRATION_GROUPS, {
		variables: {
			page: 0,
			pagesize: 9999,
			filter: {
				types: [ 'SchoolClass' ],
				mainOrganisations: [ id ],
			},
		},
	});

	//School in this instance might be confusing since CBD has chosen to name the type school "Filiaal" 
	//However the data type name is "school" in the back & front-end hence the variable name school
	if (data && data.registrationGroups) { 
		data.registrationGroups.map((school) => {
			schoolsOptions.push({ id: school.id, label: school.name })
		});
	}

	return (
		<SelectField
			options={schoolsOptions}
			name='affiliate'
			label={t('common.branches')}
			form={form}
			initialValue={{ id: (form.fields && form.fields.affiliate) || '' }}
		/>
	);
};

export default SchoolClassSelector;
