import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Typography } from "@material-ui/core";
import { useParams } from "react-router";
import { GQL_RESET_MFA_TOKEN } from "../graphql";
import { useStyles } from "./styles";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { Loader } from "@manakin/app-core";
import {useTranslation} from 'react-i18next';

const Confirm2FactorReset = (props) => {
  const classes = useStyles();
  const [mutate, { loading }] = useMutation(GQL_RESET_MFA_TOKEN);
  const params = useParams();
  const { t } = useTranslation();

  const [text, setText] = useState(t("authentication.two-factor.confirm-reset.success"));

  const handleText = (r) => {
    if (r.errors && r.errors.length) {
      let message = t("authentication.two-factor.confirm-reset.failed");
      r.errors.forEach((e) => {
        if (e.message.indexOf("Invalid or expired token") !== -1) {
          message = t("authentication.two-factor.confirm-reset.failed-already-exists");
        }
      });
      setText(message);
    }
  };

  const handleMutation = () => {
    mutate({
      variables: {
        token: params.token,
      },
    })
      .then((r) => {
        handleText(r);
      })
      .catch((e) => {
        setText(t("authentication.two-factor.confirm-reset.failed"));
      });
  };

  useEffect(() => {
    if (params && params.token) {
      handleMutation();
    }
  }, []);

  if (loading) return <Loader fullScreen />;

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography className={classes.heading} variant="h2">
          {t("authentication.two-factor.confirm-reset.title")}
        </Typography>
        <Typography>{text}</Typography>
      </div>
      <Link to={"/"}>
        <Button
          variant="contained"
          classes={{ root: classes.buttonRoot }}
          color="primary"
        >
          {t("common.login")}
        </Button>
      </Link>
    </div>
  );
};

export default Confirm2FactorReset;
