"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _core = require("@material-ui/core");

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _appCore = require("@manakin/app-core");

var _styles = require("./styles");

var _graphql = require("./graphql");

var _reactApollo = require("react-apollo");

var _reactRedux = require("react-redux");

var _actions = require("@manakin/app-core/actions");

var _RatingInfo = require("./RatingInfo");

var _RatingInfo2 = _interopRequireDefault(_RatingInfo);

var _RatingDates = require("./RatingDates");

var _RatingDates2 = _interopRequireDefault(_RatingDates);

var _DescriptionOutlined = require("@material-ui/icons/DescriptionOutlined");

var _DescriptionOutlined2 = _interopRequireDefault(_DescriptionOutlined);

var _StudentCard = require("./StudentCard");

var _StudentCard2 = _interopRequireDefault(_StudentCard);

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StudentRatingCard = function StudentRatingCard(props) {
	var classes = (0, _styles.useStyles)();
	var data = props.data,
	    programId = props.programId,
	    studentId = props.studentId,
	    onClick = props.onClick,
	    disabled = props.disabled,
	    finalRating = props.finalRating;

	var _ref = data || {},
	    image = _ref.image;

	var dispatch = (0, _reactRedux.useDispatch)();
	var onSetBoxId = (0, _react.useCallback)(function (id) {
		return dispatch((0, _actions.setBoxId)(id));
	}, [dispatch]);

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	//query


	var _useQuery = (0, _reactApollo.useQuery)(_graphql.GQL_FETCH_CURRENT_USER),
	    currentUserData = _useQuery.data,
	    currentUserLoading = _useQuery.loading;

	var _ref2 = currentUserData || {},
	    _ref2$currentAppUser = _ref2.currentAppUser,
	    currentAppUser = _ref2$currentAppUser === undefined ? {} : _ref2$currentAppUser;

	var _useQuery2 = (0, _reactApollo.useQuery)(_graphql.GQL_FETCH_RATING_REQUESTS, {
		variables: {
			filter: {
				elementId: data.id,
				programId: programId,
				studentId: studentId
			}
		}
	}),
	    requestData = _useQuery2.data,
	    loading = _useQuery2.loading,
	    error = _useQuery2.error;

	var _ref3 = requestData || {},
	    _ref3$ratingRequests = _ref3.ratingRequests,
	    ratingRequests = _ref3$ratingRequests === undefined ? [] : _ref3$ratingRequests;

	var finishedRatingRequests = ratingRequests.filter(function (request) {
		return request && request.result !== null;
	});

	var _useQuery3 = (0, _reactApollo.useQuery)(_graphql.GQL_FETCH_RATING_STUDENT_RESULT_ASSETS, {
		skip: loading,
		variables: {
			elementId: data.id,
			programId: programId,
			studentId: studentId,
			boxId: ratingRequests[0] ? ratingRequests[0].box.id : null
		}
	}),
	    assetsData = _useQuery3.data,
	    assetsLoading = _useQuery3.loading;

	var _ref4 = assetsData || {},
	    ratingStudentResult = _ref4.ratingStudentResult;

	var _ref5 = ratingStudentResult || {},
	    _ref5$assets = _ref5.assets,
	    assets = _ref5$assets === undefined ? [] : _ref5$assets;

	//function


	var getRating = function getRating(rate) {
		return _react2.default.createElement(_RatingInfo2.default, {
			data: rate,
			currentAppUser: currentAppUser
		});
	};

	var getRatingDates = function getRatingDates(rate) {
		return _react2.default.createElement(_RatingDates2.default, {
			data: rate
		});
	};

	var handleClick = function handleClick() {
		if (!disabled) {
			if (ratingRequests.length) {
				onSetBoxId(ratingRequests[0].box.id);
			}
			onClick(ratingRequests);
		}
	};

	var finishedRatingRequest = finishedRatingRequests.length > 0 && finishedRatingRequests[finishedRatingRequests.length - 1];

	if (error) t("common.loading-data-failed");
	if (loading || currentUserLoading || assetsLoading) return _react2.default.createElement(_appCore.Loader, null);
	return _react2.default.createElement(
		"div",
		{ onClick: handleClick },
		_react2.default.createElement(
			_StudentCard2.default,
			{ image: image, data: data, disabled: disabled },
			_react2.default.createElement(
				_react2.default.Fragment,
				null,
				_react2.default.createElement(
					"div",
					{
						className: (0, _classnames2.default)(classes.uploads, classes.padding)
					},
					_react2.default.createElement(
						_core.Typography,
						{
							className: (0, _classnames2.default)(classes.content, classes.iconContainer)
						},
						_react2.default.createElement(
							"span",
							{ className: classes.mobileContent },
							"uploads"
						),
						_react2.default.createElement(_DescriptionOutlined2.default, { className: classes.icon }),
						assets.length || 0
					)
				),
				finalRating && finishedRatingRequests.length > 0 && _react2.default.createElement(
					_react2.default.Fragment,
					null,
					_react2.default.createElement("div", null),
					_react2.default.createElement(
						"div",
						{
							key: finishedRatingRequest.id,
							className: classes.ratingsMobile
						},
						_react2.default.createElement(
							"span",
							{ className: classes.mobileContent },
							t("common.first-abbreviated")
						),
						getRating(finishedRatingRequest)
					)
				),
				!finalRating && ratingRequests.map(function (request, idx) {
					return _react2.default.createElement(
						"div",
						{
							key: request.id,
							className: classes.ratingsMobile
						},
						_react2.default.createElement(
							"span",
							{ className: classes.mobileContent },
							idx + 1,
							"e"
						),
						getRating(request)
					);
				})
			)
		)
	);
};

exports.default = StudentRatingCard;