'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _appCore = require('@manakin/app-core');

var _reactI18next = require('react-i18next');

var _selectors = require('@manakin/authentication/selectors');

var _DemoAccountNoAccessDialog = require('../DemoAccountNoAccessDialog/DemoAccountNoAccessDialog');

var _DemoAccountNoAccessDialog2 = _interopRequireDefault(_DemoAccountNoAccessDialog);

var _lib = require('@manakin/app-core/lib');

var _InviteStudent = require('./InviteStudent');

var _InviteStudent2 = _interopRequireDefault(_InviteStudent);

var _InviteTrainer = require('./InviteTrainer');

var _InviteTrainer2 = _interopRequireDefault(_InviteTrainer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var InviteContainer = function InviteContainer(props) {
	var config = (0, _reactRedux.useSelector)(function (state) {
		return state.config;
	}) || {};
	var appUser = (0, _reactRedux.useSelector)(function (state) {
		return (0, _selectors.getAppUser)(state);
	});
	var _config$account = config.account,
	    account = _config$account === undefined ? {} : _config$account;

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var _useState = (0, _react.useState)(false),
	    _useState2 = _slicedToArray(_useState, 2),
	    showDemoAccountNoAccessDialog = _useState2[0],
	    setShowDemoAccountNoAccessDialog = _useState2[1];

	(0, _react.useEffect)(function () {
		if (appUser && appUser.roles && appUser.roles.some(function (role) {
			return role.name === _lib.demoRole;
		})) {
			setShowDemoAccountNoAccessDialog(true);
		}
	}, []);

	if (!account.showInvites) return _react2.default.createElement(
		'div',
		null,
		t('common.page-does-not-exist')
	);

	return _react2.default.createElement(
		_react2.default.Fragment,
		null,
		_react2.default.createElement(
			_appCore.AccessControl,
			{ role: _lib.studentRoles },
			_react2.default.createElement(_InviteTrainer2.default, null)
		),
		_react2.default.createElement(
			_appCore.AccessControl,
			{ role: _lib.trainerRoles },
			_react2.default.createElement(_InviteStudent2.default, null)
		),
		_react2.default.createElement(_DemoAccountNoAccessDialog2.default, {
			open: showDemoAccountNoAccessDialog,
			onClose: function onClose() {
				return setShowDemoAccountNoAccessDialog(false);
			} })
	);
};

exports.default = InviteContainer;