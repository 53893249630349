"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.useStyles = undefined;

var _styles = require("@material-ui/styles");

var useStyles = exports.useStyles = (0, _styles.makeStyles)(function (theme) {
	return {
		imageContainer: {
			display: "flex",
			alignItems: "center"
		},
		image: {
			width: "100px",
			height: "100px",
			marginRight: "30px",
			position: "relative"
		},
		content: {
			margin: 0,
			paddingRight: theme.spacing(3)
		},
		padding: {
			padding: "20px 0"
		},
		layout: {
			cursor: "pointer",
			backgroundColor: "white",
			marginBottom: "10px",
			transform: "scale(1)",
			transition: "transform .2s",
			"&:hover": {
				transform: "scale(1.01)"
			}
		},
		link: {
			textDecoration: "none"
		},
		rightAligned: {
			textAlign: "right"
		}
	};
});