import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				display: 'flex',
				width: '100%',
			},
			container: {
				paddingTop: '4.8rem',
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				[theme.breakpoints.up('md')]: {
					flexDirection: 'row',
					padding: '100px',
				},
			},
			header: {
				fontWeight: 'bold',
				marginBottom: '7rem',
			},
			loader: {
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
			},
			wrapper: {
				...theme.manakin.defaultWrapper,
				[theme.breakpoints.up('md')]: {
					maxWidth: '500px',
					paddingLeft: '0',
					margin: '0',
				},
			},
			formControlRoot: {
				width: '100%',
				minHeight: '10rem',
				padding: '1rem',
				borderTop: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
				margin: '0',
				backgroundColor: theme.manakin.defaultWorkformColor[500],
				'& $formControlLabel': {
					color: theme.palette.getContrastText(theme.manakin.correctAnswer[500]),
				},
				'&:last-child': {
					borderBottom: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
				},
				[theme.breakpoints.up('md')]: {
					padding: '1rem 3.4rem',
					borderLeft: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
					borderRight: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
				},
			},
			active: {},
			formControlLabel: {
				color: theme.manakin.defaultContentColor[500],
				fontFamily: theme.manakin.defaultAnswerFont,
				fontSize: '1.6rem',
				lineHeight: '3.2rem',
				margin: '0 0 0 1rem',
				[theme.breakpoints.up('md')]: {
					margin: '0 0 0 3rem',
					fontSize: '1.8rem',
					lineHeight: '3.8rem',
				},
			},
			formControl: {
				marginBottom: '2rem',
			},
			outer: {
				border: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
				backgroundColor: 'white',
				width: '30px',
				height: '30px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			},
			inner: {
				backgroundColor: theme.manakin.defaultWorkformColor[500],
				width: '10px',
				height: '10px',
			},
			buttons: {
				display: 'inline-block',
				width: '100%',
				position: 'relative',
			},
			button: {
				margin: '20px',
				width: 'calc(100% - 40px)!important',
				'& span': {
					justifyContent: 'space-between',
					paddingLeft: '2rem',
					paddingRight: '2rem',
				},
				[theme.breakpoints.up('md')]: {
					width: '100%!important',
					margin: '0',
				},
			},
			question: {
				[theme.breakpoints.up('md')]: {
					width: '50%',
				},
			},
			strike: {
				'text-decoration': 'line-through',
			},
			buttonGroup: {
				'column-gap': '20px',
			},
			feedback: {
				position: 'relative',
				top: '-100px',
				zIndex: 1,
			},
			feedbackText: {
				padding: '20px',
				border: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
			},
			root: {
				backgroundColor: theme.manakin.defaultWorkformColor[500],
				padding: '5rem 0',
				'& $content': {
					'& span, & p, & h2, & label, & textarea': {
						color: theme.palette.getContrastText(theme.manakin.defaultWorkformColor[500]),
					},

					'& .smallUnderline': {
						'&:after': {
							backgroundColor: theme.palette.getContrastText(theme.manakin.defaultWorkformColor[500]),
						},
					},

					'& $answerBox': {
						padding: '8% 0',
						color: theme.palette.getContrastText(theme.manakin.defaultWorkformColor[500]),
					},
					'& $feedback': {
						'& span, & p, & h2, & label, & textarea': {
							color: theme.manakin.defaultContentColor[500],
						},
					},
				},

				[theme.breakpoints.up('lg')]: {
					padding: '15rem 0rem',
				},
			},
			answerBox: {
				'& small': {
					display: 'block',
				},
			},
			primary: {
				backgroundColor: theme.manakin.primaryWorkformColor[500],
				'& $content': {
					'& span, & p, & h2, & label, & textarea': {
						color: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),
					},

					'& .smallUnderline': {
						'&:after': {
							backgroundColor: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),
						},
					},

					'& $answerBox': {
						padding: '8% 0',
						color: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),
					},
					'& $feedback': {
						'& span, & p, & h2, & label, & textarea': {
							color: theme.manakin.defaultContentColor[500],
						},
					},
				},
			},
			secondary: {
				backgroundColor: theme.manakin.secondaryWorkformColor[500],
				'& $content': {
					'& span, & p, & h2, & label, & textarea': {
						color: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
					},

					'& .smallUnderline': {
						'&:after': {
							backgroundColor: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
						},
					},

					'& $answerBox': {
						padding: '8% 0',
						color: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
					},
				},
			},
			noBG: {
				backgroundColor: 'white',
				'& $content': {
					'& span, & p, & h2, & label, & textarea': {
						color: '#000',
					},

					'& .smallUnderline': {
						'&:after': {
							backgroundColor: '#000',
						},
					},

					'& $answerBox': {
						padding: '8% 0',
						color: '#000',
					},
					'& $feedback': {
						'& span, & p, & h2, & label, & textarea': {
							color: '#000',
						},
					},
				},
			},
			wrapper: {
				...theme.manakin.extraLargeWrapper,
				[theme.breakpoints.up('md')]: {
					padding: '0 6rem',
				},
				[theme.breakpoints.up('lg')]: {
					padding: '0 15rem',
				},
			},
			content: {
				color: theme.palette.getContrastText(theme.manakin.defaultWorkformColor[500]),
				'& ul, & ol': {
					paddingLeft: '20px',
				},
				fontWeight: 400,
				fontSize: '1.8rem',
				lineHeight: '3.6rem',
				fontFamily: theme.manakin.defaultContentFont,
				'& p': {
					marginBottom: '2rem',
				},
			},
		}),
	{ name: 'AppOpenQuestion' }
);
