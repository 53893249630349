import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				paddingTop: "160px",
				[theme.breakpoints.up("md")]: {
					paddingTop: "0",
				},
			},
			loading: {
				width: "100vw",
				height: "100vh",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			},
			container: {
				...theme.manakin.defaultWrapper,
				maxWidth: 1600,
			},
			plannerBlock: {
				backgroundColor: theme.manakin.indicationColor[500],
				padding: theme.spacing(9),
				paddingBottom: 0,
			},
			reportBlock: {
				backgroundColor: theme.manakin.indicationColor[500],
			},
			subHeader: {
				fontWeight: "bold",
			},
			contentColor: {}
		}),
	{ name: "AppProgressDashboard" }
);
