import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    actions: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: '10px'
    },
	description: {
		padding: "16px 24px 4px 24px"
	}
}))

const SimpleDialog = (props) => {
	const {
		onClose,
		open,
		title,
		description,
		onButtonClick,
		children,
		cancelButtonLabel,
		actionButtonLabel,
		fullWidth,
        className
	} = props;
    const classes = useStyles()

	return (
		<Dialog onClose={onClose} open={open} fullWidth={fullWidth} classes={{ paper: className }}>
			<DialogTitle onClose={onClose}>{title || ''}</DialogTitle>
			{description && (<DialogContentText className={classes.description}>{description}</DialogContentText>)}
			<DialogContent dividers>{children}</DialogContent>
			<DialogActions className={classes.actions}>
				{cancelButtonLabel && (
					<Button autoFocus onClick={onClose} color="primary">
						{cancelButtonLabel}
					</Button>
				)}
				{actionButtonLabel && (
					<Button onClick={onButtonClick} color="primary">
						{actionButtonLabel}
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};

export default SimpleDialog;
