'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.isSearchBarOpen = exports.SEARCH_BAR_STATE_ROOT_APP = undefined;

var _reselect = require('reselect');

var _selectors = require('../selectors');

var SEARCH_BAR_STATE_ROOT_APP = exports.SEARCH_BAR_STATE_ROOT_APP = 'searchBar';

var getState = (0, _reselect.createSelector)([_selectors.getCoreState], function (coreState) {
    return coreState[SEARCH_BAR_STATE_ROOT_APP];
});

var isSearchBarOpen = exports.isSearchBarOpen = (0, _reselect.createSelector)([getState], function (state) {
    return state.searchBarOpen;
});