import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import renderHTML from 'react-render-html';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {},
        logo: {
            maxHeight: 32,
            minHeight: 32,
        },
        header: {
            marginBottom: 4,
            marginTop: 6,
            color: theme.palette.getContrastText(theme.manakin.indicationColor[600])
        },
        text: {
            color: theme.palette.getContrastText(theme.manakin.indicationColor[600]),
            lineHeight: "2.5rem",
            "& ul, ol": {
                listStylePosition: "inside"
            },
            "& a": {
                textDecoration: "none",
                color: theme.palette.getContrastText(theme.manakin.indicationColor[600]),
            }
        }
    }),
);

const ColophonCreditItem = ({ credit: { header, text, logo } }) => {
    const { url } = logo || {};
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <img className={classes.logo} src={url}/>
            <Typography
                classes={{ body1: classes.header }}
                component='p'
                variant='body1'
            >
                <strong>{header}</strong>
            </Typography>
            <Typography
                classes={{ body1: classes.text }}
                component='p'
                variant='body1'
            >
                {renderHTML(text || '')}
            </Typography>
        </div>
    );
};

export default ColophonCreditItem;
