let itemMargin = 13;

export const styles = (theme) => ({
    wrapper: {
        ...theme.manakin.defaultWrapper,
        //overflow: 'hidden',
        [theme.breakpoints.up('md')]: {
            padding: 0,
        },
    },
    answersRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '-' + itemMargin + 'px',
    },
    card: {
        height: '150px',
        position: 'relative',
        flex: '0 1 calc(33.33% - ' + itemMargin * 2 + 'px)',
        margin: itemMargin + 'px',
        [theme.breakpoints.down('xs')]: {
            flex: '0 1 calc(50% - 26px)',
        },
    },
    innerCard: {
        backgroundColor: theme.manakin.primaryColor,
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        opacity: 1,
        transition: 'opacity .2s',
        '&:hover': {
            '& $image img': {
                transform: 'scale(1.2)',
            },
        },
    },
    notActive: {
        opacity: '0.5',
    },
    text: {
        width: '100%',
        lineHeight: '3.6rem',
        fontSize: '1.8rem',
        fontFamily: theme.manakin.defaultAnswerFont,
        padding: '1rem',
        textAlign: 'center',
        position: 'absolute',
        bottom: 0,
        left: 0,
        backgroundColor: theme.manakin.primaryColor,
    },
    image: {
        width: '100%',
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        overflow: 'hidden',
        position: 'relative',
        zIndex: '2',

        '& img': {
            transition: 'transform .2s',
        },
    },
    backgroundImage: {
        objectFit: 'cover',
        fontFamily: "'object-fit: cover'",
        width: '100%',
        height: '100%',
        backfaceVisibility: 'hidden',
    },
    selected: {
        position: 'absolute',
        width: 'calc(100% + 14px)',
        height: 'calc(100% + 14px)',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) scale(.85)',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 0,
        display: 'flex',
        pointerEvents: 'none',
        transition: 'transform .2s ease, opacity .2s ease',
        backfaceVisibility: 'hidden',
    },
    active: {
        border: '2px solid black',
    },
    iconRoot: {
        width: '1.3rem',
        height: '1.3rem',
    },
    showIcon: {
        opacity: 1,
        transform: 'translate(-50%, -50%) scale(1)',
    },
    feedback: {
        position: 'relative',
        left: '0',
        zIndex: '2',
        opacity: '0',
        height: 0,
        width: '100%',
        overflow: 'hidden',
        pointerEvents: 'none',
        transition: 'opacity .3s',
    },
    activeFeedback: {
        opacity: '1',
        pointerEvents: 'auto',
        height: 'auto',
    },
    buttons: {
        position: 'relative',
        marginTop: '2rem',
        display: 'inline-block',
        verticalAlign: 'top',
        width: '100%',
    },
    button: {
        '& span': {
            justifyContent: 'space-between',
            paddingLeft: '2rem',
            paddingRight: '2rem',
        },
    },
    longArrow: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    },
});
