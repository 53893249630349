import React, { useEffect, useState } from "react";
import { graphql } from "react-apollo";
import { compose } from "recompose";
import BookCheck from "./BookCheck";
import {
	GQL_FETCH_BOOKCHECK,
	GQL_FETCH_GLOBAL_SETTINGS,
} from "./graphql/graphql";
import { withWorkforms, Loader } from "@manakin/app-core";
import { connect } from "react-redux";
import { changeColor } from "@manakin/app-core/ColorService/actions";
import { showSnackbarMessage } from "@manakin/core/actions";
import { withTranslation } from "react-i18next";
import { GQL_VIEW_ELEMENT } from "@manakin/app-core/graphql";
import { useMutation } from "react-apollo";
import { useViewCounting } from "@manakin/hooks";

const BookCheckContainer = (props) => {
	const {
		data = {},
		SETTINGS = {},
		workformsData,
		location,
		changeColorProp,
	} = props;
	const { settings = {} } = SETTINGS;
	const { loading } = data;
	const { viewElement } = useViewCounting();

	//state hooks
	const [done, setDone] = useState(false);

	//effect hooks
	useEffect(() => {
		if (!loading) {
			const options = {
				showFeedback: true,
				nextButton: true,
			};

			if (data.bookCheck) {
				const elementId = props.match.params.elementId;

				changeColorProp({
					...data.bookCheck.styleSheet,
					location: location.pathname,
				});
				data.bookCheck.workforms &&
					workformsData.loadWorkforms({
						...data.bookCheck,
						options: options,
						elementId: elementId,
					});
				workformsData.loadElementResult(elementId);

				viewElement(elementId);
			}
		}
	}, [loading]);

	useEffect(() => {
		if (
			!workformsData.loading &&
			workformsData.workforms &&
			workformsData.workforms.length
		) {
			let isFinished = true;
			workformsData.workforms.forEach((i) => {
				if (!i.finished && i.type !== "SortQuestion") {
					isFinished = false;
				}
			});
			if (isFinished) {
				setDone(true);
			}
		}
	}, [workformsData.loading, workformsData.workforms]);

	useEffect(() => {
		if (done) {
			if (location.pathname !== `${props.match.url}/outro/`) {
				props.history.push(`${props.match.url}/outro/`);
			}
		}
	}, [done]);

	//functions
	const handleExit = () => {
		setDone(true);
	};

	const handleClick = (event) => {
		if (event) {
			if (props.match.params.elementId) {
				workformsData.resetElement(
					props.match.params.elementId,
					`/BookCheck/${props.match.params.elementId}`
				);
			} else {
				props.errorMessage(this.props.t);
			}
		} else {
			props.history.push(`${props.match.url}/questions`);
		}
	};

	if (workformsData.loading) return <Loader fullScreen />;
	return (
		<React.Fragment>
			<BookCheck
				{...workformsData}
				settings={settings.settings || []}
				onExit={handleExit}
				onClick={handleClick}
				elementId={props.match.params.elementId}
			/>
		</React.Fragment>
	);
};

export default compose(
	withTranslation(),
	connect(null, (dispatch) => ({
		changeColorProp: (styleSheet) => dispatch(changeColor(styleSheet)),
		errorMessage: (t) => {
			dispatch(
				showSnackbarMessage({
					text: t("common.something-went-wrong"),
					variant: "error",
				})
			);
		},
	})),
	graphql(GQL_FETCH_GLOBAL_SETTINGS, {
		name: "SETTINGS",
	}),
	graphql(GQL_FETCH_BOOKCHECK, {
		options: (props) => ({
			variables: { id: props.match.params.elementId },
		}),
	}),
	withWorkforms()
)(BookCheckContainer);
