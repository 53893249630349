import React from "react";
import renderHTML from "react-render-html";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { ImageWithFocusPoint } from "@manakin/app-core";
import { useReadSpeaker } from "@manakin/hooks";
import { createStyles, makeStyles } from "@material-ui/styles";
import { GQL_FETCH_CMS_HOME_PAGE_BLOCK_TITLE_AND_TEXT_IMAGE } from "../graphql";
import { useQuery } from "@apollo/react-hooks";

const useStyles = makeStyles(
    (theme) =>
        createStyles({
            root: {
                display: "flex",
                flexFlow: "column nowrap",
                backgroundColor: theme.manakin.defaultWorkformColor[500],
                [theme.breakpoints.up("lg")]: {
                    flexFlow: "row wrap",
                }
            },
            contentColor: {
                color: theme.palette.getContrastText(
                    theme.manakin.defaultWorkformColor[500],
                ),
            },
            primary: {
                backgroundColor: theme.manakin.homePageBlockPrimaryBackgroundColor[500],
                "& $contentColor": {
                    color: theme.palette.getContrastText(
                        theme.manakin.homePageBlockPrimaryBackgroundColor[500],
                    ),
                    "&::after": {
                        backgroundColor: theme.palette.getContrastText(
                            theme.manakin.homePageBlockPrimaryBackgroundColor[500],
                        ),
                    },
                },
                "& $header": {
                    color: theme.palette.getContrastText(
                        theme.manakin.homePageBlockPrimaryBackgroundColor[500],
                    ),
                    "&.smallUnderline": {
                        "&:after": {
                            backgroundColor: theme.palette.getContrastText(
                                theme.manakin.homePageBlockPrimaryBackgroundColor[500],
                            ),
                        },
                    },
                },
            },
            secondary: {
                backgroundColor: theme.manakin.homePageBlockSecondaryBackgroundColor[500],
                "& $contentColor": {
                    color: theme.palette.getContrastText(
                        theme.manakin.homePageBlockSecondaryBackgroundColor[500],
                    ),
                    "&::after": {
                        backgroundColor: theme.palette.getContrastText(
                            theme.manakin.homePageBlockSecondaryBackgroundColor[500],
                        ),
                    },
                },
                "& $header": {
                    color: theme.palette.getContrastText(
                        theme.manakin.homePageBlockSecondaryBackgroundColor[500],
                    ),
                    "&.smallUnderline": {
                        "&:after": {
                            backgroundColor: theme.palette.getContrastText(
                                theme.manakin.homePageBlockSecondaryBackgroundColor[500],
                            ),
                        },
                    },
                },
            },
            contentBody: {
                position: "relative",
                padding: theme.manakin.defaultPadding,
                width: "100%",
                paddingTop: "60px",
                "& ul, & ol": {
                    listStylePosition: "inside",
                },
                [theme.breakpoints.up("md")]: {
                    padding: "4.5rem",
                },
                [theme.breakpoints.up("lg")]: {
                    padding: "15rem",
                    width: "50%",
                },
            },
            contentWrapper: {
                position: "relative",
                top: 0,
                left: 0,
                display: "flex",
                flexFlow: "column",
                justifyContent: "flex-start",
                height: "100%",
                overflow: "hidden",
                WebkitOverflowScrolling: "touch",
                [theme.breakpoints.up("lg")]: {
                    position: "absolute",
                    padding: "8% 15%",
                },
            },
            content: {
                margin: "auto",
            },
            header: {
                ...theme.manakin.workformTitleFont,
                marginBottom: "2.2rem",
                paddingBottom: "3.4rem",
                [theme.breakpoints.down("xs")]: {
                    fontSize: "3rem",
                    lineHeight: "4rem",
                },
                "&.smallUnderline": {
                    "&:after": {
                        backgroundColor: theme.palette.getContrastText(
                            theme.manakin.defaultWorkformColor[500],
                        ),
                    },
                },
            },
            body: {
                margin: 0,
                "@global ul": {
                    paddingLeft: "2.25rem",
                },
            },
            imageWrapper: {
                position: "relative",
                width: "100%",
                height: 0,
                paddingTop: "50%",
                [theme.breakpoints.up("lg")]: {
                    width: "50%",
                },
            },
            image: {
                width: "100%",
                height: "100%",
                objectFit: "cover",
                position: "absolute",
                top: "0",
                left: "0",
            },
            left: {
                order: 1,
            },
            right: {
                order: 2,
            },
        }),
    {
        name: "AppAppHomepageBlockTitleAndTextImage",
    },
);

const TitleAndTextImageBlock = (props) => {
    const classes = useStyles();
    const { readSpeaker, readSpeakerId } = useReadSpeaker("readSpeaker-titleAndTextImage-" + props.id);

    const { data = {} } = useQuery(GQL_FETCH_CMS_HOME_PAGE_BLOCK_TITLE_AND_TEXT_IMAGE, {
        variables: {
            id: props.id,
        },
    });
    const { titleAndTextImageHomepageBlock = {} } = data;
    const { imagePosition, title, workTitle, backgroundColor, image, text } = titleAndTextImageHomepageBlock;

    return (
        <div>
            <div
                className={classNames(
                    classes.root,
                    { [classes.primary]: backgroundColor === "PRIMARY" },
                    { [classes.secondary]: backgroundColor === "SECONDARY" },
                )}
            >
                <div
                    className={classNames(classes.contentBody, {
                        [classes.right]: imagePosition === "links",
                    })}
                >
                    <div className={classes.contentWrapper}>
                        <div className={classes.content} id={readSpeakerId}>
                            <Typography
                                component='h2'
                                variant='h2'
                                classes={{ h2: classes.header }}
                                className={classNames(
                                    "smallUnderline",
                                    classes.contentColor,
                                )}
                            >
                                {title || workTitle || ""}
                            </Typography>
                            <Typography
                                component='div'
                                variant='body1'
                                classes={{ body1: classes.body }}
                                className={classes.contentColor}
                            >
                                {readSpeaker && renderHTML(readSpeaker)}
                                {renderHTML(text || "")}
                            </Typography>
                        </div>
                    </div>
                </div>
                <div className={classes.imageWrapper}>
                    <ImageWithFocusPoint
                        asset={image}
                        imageProps={{
                            className: classNames(classes.image, {
                                [classes.left]: imagePosition === "links",
                            }),
                        }} />
                </div>
            </div>
        </div>
    );
};

export default TitleAndTextImageBlock;
