import gql from 'graphql-tag';
import { currentAppUser } from '@manakin/app-core/graphQlHelpers';

export const GQL_GET_INVITAION = gql`
    query invitationByToken($token: String!) {
        invitationByToken(token: $token) {
            id
            state
            trainerEmail
            trainerRole {
                id
                name
            }
        }
    }
`;

export const GQL_GET_CURRENT_USER = gql`
    query currentAppUser {
        currentAppUser {
            ${currentAppUser}
        }
    }
`;

export const GQL_LOGIN_APP = gql`
    mutation loginApp($email: String!, $password: String!) {
        loginApp(input: { email: $email, password: $password }) {
            mfa
            mfaDataUri
            showQr
            user {
                ${currentAppUser}
            }
        }
    }
`;


export const GQL_CREATE_TRAINER_BY_INVITE_TOKEN = gql`
    mutation createTrainerByInviteToken (
        $email: String!
		$password: String
		$firstName: String!
		$middleName: String
		$lastName: String!
		$gender: Gender
		$dateOfBirth: String
		$phonenumber: String
		$school: Long
		$schoolClasses: [ID]
		$registerCode: String
		$registerType: String
		$sendVerifyMail: Boolean
        $inviteToken: String!
    ) {
        createTrainerByInviteToken(
            input: {
                dateOfBirth: $dateOfBirth
                email: $email
				password: $password
				firstName: $firstName
				middleName: $middleName
				lastName: $lastName
				gender: $gender
				dateOfBirth: $dateOfBirth
				phonenumber: $phonenumber
				school: $school
				schoolClasses: $schoolClasses
				registerCode: $registerCode
				registerType: $registerType
				sendVerifyMail: $sendVerifyMail
                inviteToken: $inviteToken
            }) {
            user {
                id
            }
        }
    }
`
export const GQL_REGISTER_APP_USER = gql`
	mutation registerAppUser (
		$email: String!
		$password: String
		$firstName: String
		$middleName: String
		$lastName: String
		$gender: Gender
		$dateOfBirth: String
		$phonenumber: String
		$school: Long
		$schoolClasses: [ID]
		$registerCode: String
		$registerType: String
		$sendVerifyMail: Boolean
	) {
		registerAppUser(
			input: {
				email: $email
				password: $password
				firstName: $firstName
				middleName: $middleName
				lastName: $lastName
				gender: $gender
				dateOfBirth: $dateOfBirth
				phonenumber: $phonenumber
				school: $school
				schoolClasses: $schoolClasses
				registerCode: $registerCode
				registerType: $registerType
				sendVerifyMail: $sendVerifyMail
			}) {
			user {
				id
				fullName
			}
		}
	}
`;

export const GQL_ACCEPT_INVITATION = gql`
    mutation acceptInvitation($input: AcceptInvitationInput!) {
        acceptInvitation(input: $input) {
            id
            state
        }
    }
`;
