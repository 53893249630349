import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ClaimLicense from '@manakin/licenses/ClaimLicense';
import { hideAppBar } from '@manakin/app-core/actions';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';
import { GQL_VALID_LICENSE_CHECK } from './graphql';
import { Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import DefaultHeading from './parts/DefaultHeading';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '@manakin/authentication/actions';

const styles = (theme) => ({
	root: {
		paddingTop: '6rem',
		paddingBottom: '6rem',
		width: '100%',
		height: '100%',
		minHeight: '100%',
	},
	paragraph: {
		textAlign: 'center',
		width: '100%',
		margin: '0 auto 3em',
		maxWidth: '560px',
	},
	wrapper: {
		...theme.manakin.defaultWrapper,
		maxWidth: theme.manakin.wrapper['small'],
		position: 'relative',
		width: '100%',
		margin: '0 auto',
		paddingTop: '10rem',
	},
	smallWrapper: {
		maxWidth: theme.manakin.wrapper['extraSmall'],
		position: 'relative',
		zIndex: 1,
		width: '90%',
		margin: '0 auto',
	},
	footNote: {
		marginTop: theme.spacing(4),
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'column',
	},
	link: {
		marginTop: theme.spacing(1),
		textDecoration: 'none',
		color: '#009AC3',
		cursor: 'pointer',
	},
	heading: {
		textAlign: 'center',
	},
	logoutButton: {
		margin: '0 auto',
		display: 'block',
	},
});

const NoLicenses = (props) => {
	const { classes, config = {}, onHideAppBar } = props;
    const appRegistration = (config && config.pages && config.pages.appRegistration) || {};
	const { general = {} } = config;
	const [hasValidLicense, setHasValidLicense] = useState(false);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const isIosApp = navigator.userAgent.indexOf('wrapper') !== -1;

	//queries
	const { loading, error, data } = useQuery(GQL_VALID_LICENSE_CHECK);

	useEffect(() => {
		onHideAppBar({ path: props.location.pathname });
	}, []);

	useEffect(() => {
		if (!loading && data.validLicenseCheck) {
			if (data.validLicenseCheck.hasValidLicense) {
				setHasValidLicense(true);
			}
		}
	}, [data]);

	const handleGoToLink = (link) => {
		const withHttp = (url) => (!/^https?:\/\//i.test(url) ? `https://${url}` : url);
		window.open(withHttp(link), '_blank');
	};

	const handleLogout = () => {
		dispatch(logout());
	};

	if (loading) {
		return t('common.loading');
	}
	if (error) {
		return t('common.something-went-wrong');
	}
	if (hasValidLicense) {
		return <Redirect to={'/'} />;
	}
    if (!isIosApp && appRegistration.webshop) {
        return <Redirect to={process.env.REACT_APP_WEBSHOP_URL || '/auth/webshop'} />
    }
	if (general.sso) {
        const ssoUrl = process.env.REACT_APP_SSO_NO_LICENSE_URL || '';
		return (
			<div className={classes.root}>
				<div className={classes.wrapper}>
					<DefaultHeading />
					<Typography variant="body1" className={classes.paragraph}>
						{t('authentication.sso.login-redirect.instruction')}
						<a className={classes.link} href={ssoUrl}>
							{t('authentication.sso.login-redirect.label')}
						</a>
					</Typography>
				</div>
			</div>
		);
	}

	if (isIosApp) {
		return (
			<div className={classes.root}>
				<div className={classes.wrapper}>
					<Typography className={classes.heading} variant='h3'>
						{t('app.no-licenses.ios-app.title')}
					</Typography>
					<Typography
						classes={{ body1: classes.paragraph }}
						variant='body1'
						gutterBottom
					>
						{t('app.no-licenses.ios-app.description')}
					</Typography>
					<Button
						onClick={handleLogout}
						color='primary'
						className={classes.logoutButton}
					>
						{t('app.no-licenses.ios-app.action')}
					</Button>
				</div>
			</div>
		);
	}

	return (
		<div className={classes.root}>
			<div className={classes.wrapper}>
				<DefaultHeading />
				{general.licenseUrl && (
					<Typography classes={{ body1: classes.paragraph }} variant="body1" gutterBottom>
						{t('app.no-licenses.action-text')}
						<a href={general.licenseUrl} target="_blank">
							{t('app.no-licenses.action-link') || ''}
						</a>
					</Typography>
				)}
				<ClaimLicense redirect="/dashboard" />
				{config && config.pages && config.pages.appNoLicense && config.pages.appNoLicense.showFootNote && (
					<Typography component="div" classes={{ body1: classes.footNote }} variant="body1">
						{renderHTML(t('app.no-licenses.footer.text'))}
						{t('app.no-licenses.footer.link') !== '' && (
							<div className={classes.link} onClick={() => handleGoToLink(t('app.no-licenses.footer.text'))}>
								{t('app.no-licenses.footer.text')}
							</div>
						)}
					</Typography>
				)}
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => ({
	onHideAppBar: (data) => dispatch(hideAppBar(data)),
});

export default compose(
	withStyles(styles, { name: 'AppRegistrationScreen' }),
	connect(
		({ config }) => ({
			config,
		}),
		mapDispatchToProps
	)
)(NoLicenses);
