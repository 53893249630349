import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import SingleDot from './SingleDot';
import Button from '@material-ui/core/Button';
import { LoadImage } from '@manakin/app-core';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
    container: {
        width: '100%',
    },
    image: {
        position: 'relative',
        width: '100%',
    },
    button: {
        position: 'absolute',
        bottom: '10px',
        right: '10px',
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    },
    question: {
        textAlign: 'center',
        marginBottom: '3.7rem',
        fontWeight: 'bold',
        fontSize: '4rem',
        marginTop: '1.8rem',
        [theme.breakpoints.down('xs')]: {
            marginTop: '0rem',
            padding: '0 25px',
            fontSize: '2.4rem',
            lineHeight: '4rem',
        },
    },
    subHeader: {
        textAlign: 'center',
        marginBottom: 0,
        padding: 0,
        '&::after': {
            backgroundColor: theme.manakin.indicationColor[500],
        },
    },
});

class HotspotsMap extends React.PureComponent {
    state = {
        isVisible: false,
    };

    openAll = () => {
        this.setState((prevState) => ({
            isVisible: !prevState.isVisible,
        }));
    };

    render() {
        const {
            classes,
            question = '',
            introduction: _introduction = false,
            image = {},
            mapSpots = [],
            t
        } = this.props;

        const introduction = _introduction
            ? _introduction
            : t("app.workforms.hotspot-map.introduction");

        return (
            <div className={classes.container}>
                <Typography
                    component="p"
                    variant="subtitle1"
                    className={classNames(
                        'smallUnderlineCenter',
                        classes.subHeader
                    )}
                >
                    {introduction}
                </Typography>
                <Typography
                    component="h3"
                    variant="h3"
                    className={classes.question}
                >
                    {question}
                </Typography>
                <div className={classes.image}>
                    <LoadImage
                        relative={true}
                        src={image ? image.url || '' : ''}
                        placeholder={image ? image.thumbnailUrl || '' : ''}
                    />
                    {mapSpots &&
                        mapSpots.map((hotSpots) => (
                            <SingleDot
                                {...this.state}
                                {...hotSpots}
                                key={hotSpots.id}
                            />
                        ))}

                    <Button
                        variant="contained"
                        className={classes.button}
                        onClick={this.openAll}
                        color="primary"
                    >
                        {this.state.isVisible
                            ? t("app.workforms.hotspot-map.close-all")
                            : t("app.workforms.hotspot-map.open-all")}
                    </Button>
                </div>
            </div>
        );
    }
}

export default compose(
    withStyles(styles, { name: 'AppHotspotMapLandscape' }),
    withTranslation()
)(HotspotsMap);
