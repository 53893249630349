import gql from "graphql-tag";
import { currentAppUser } from '@manakin/app-core/graphQlHelpers';

export const GQL_FETCH_CURRENT_APP_USER = gql`
	query currentAppUser {
		currentAppUser {
			id
			fullName
			roles {
				id
				name
				title
			}
			profilePicture {
				url
				id
			}
		}
	}
`;

export const GQL_STUDENTS_FOR_EXPORT = gql`
	query invitationStudentOverviewCompact($input: InvitationStudentOverviewInput) {
        invitationStudentOverviewCompact(input: $input) {
			id
		}
	}
`;

export const GQL_FETCH_DEMO_ENVIRONMENT_ENABLED = gql`
    query demoEnvironmentEnabled {
        setting(name: "demoEnvironmentEnabled") {
            name
            value
        }
    }
`;

export const GQL_CREATE_DEMO_USER = gql`
    mutation demoUser {
        createDemoAppUser {
            user {
                ${currentAppUser}
            }
        }
    }  
`;

export const GQL_FETCH_CURRENT_APP_USER_FOR_BPV = gql`
	query currentUserForBPV {
		currentAppUser {
			function
			educationOrganisation
		}
	}
`;