import React from 'react';
import { graphql } from 'react-apollo';
import { compose, withHandlers } from 'recompose';
import Trainer from './Trainer';
import { GQL_FETCH_TRAINER, GQL_FETCH_GLOBAL_SETTINGS } from './graphql';
import { withWorkforms } from '@manakin/app-core';
import { connect } from 'react-redux';
import { changeColor } from '@manakin/app-core/ColorService/actions';
import { SettingsKeys } from '@manakin/core/lib/constants';
import { GQL_VIEW_ELEMENT } from '@manakin/app-core/graphql'

class TrainerContainer extends React.PureComponent {
    state = {};

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data.loading && !this.props.data.loading) {
            const { data, workformsData, changeColor, location } = this.props;

            const options = {
                showFeedback: false,
                nextButton: false,
            };

            if (data.trainer) {
                changeColor({
                    ...data.trainer.styleSheet,
                    location: location.pathname,
                });
                data.trainer.workforms &&
                    workformsData.loadWorkforms({
                        ...data.trainer,
                        options: options,
                        elementId: this.props.match.params.elementId,
                    });
                workformsData.loadElementResult(
                    this.props.match.params.elementId
                );

                // TODO if this ever gets refactored, use the useViewCounting hook instead
                this.props.onViewElement({
                    elementId: this.props.match.params.elementId,
                })
            }
        }
        if (prevProps.SETTINGS.loading && !this.props.SETTINGS.loading) {
            const { SETTINGS } = this.props;
            const { settings = {} } = SETTINGS;

            settings.settings &&
                settings.settings.forEach((item) => {
                    if (item.name == SettingsKeys.TRAINER_PREFIX) {
                        this.setState({
                            prefix: item.value,
                        });
                    }
                });
        }
    }

    handleExit = (count) => {
        this.props.workformsData
            .saveElement({
                count: count,
                elementId: this.props.match.params.elementId,
            })
            .then((result) => {
                this.props.history.push(
                    `${this.props.match.url}/outro/${this.props.match.params.elementId}/${count}`
                );
            });
    };

    handleClick = () => {
        this.props.history.push(`${this.props.match.url}/questions`);
    };

    render() {
        const { workformsData } = this.props;

        return (
            <React.Fragment>
                <Trainer
                    {...workformsData}
                    {...this.state}
                    onExit={this.handleExit}
                    onClick={this.handleClick}
                />
            </React.Fragment>
        );
    }
}

export default compose(
    connect(null, (dispatch) => ({
        changeColor: (styleSheet) => dispatch(changeColor(styleSheet)),
    })),
    graphql(GQL_FETCH_TRAINER, {
        options: (props) => ({
            variables: { id: props.match.params.elementId },
        }),
    }),
    graphql(GQL_FETCH_GLOBAL_SETTINGS, {
        name: 'SETTINGS',
    }),
    graphql(GQL_VIEW_ELEMENT, {
		name: 'VIEW_ELEMENT',
	}),
	withHandlers({
		onViewElement:
			({ VIEW_ELEMENT }) =>
			(event) =>
				VIEW_ELEMENT({
					variables: {
						...event,
					},
				}),
	}),
    withWorkforms()
)(TrainerContainer);
