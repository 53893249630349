import React from "react";
import { TextField } from "@manakin/core";
import { makeStyles } from "@material-ui/core";
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	root: {
		margin: "20px 0",
	},
}));

const CodeBlock = (props) => {
	const { form } = props;
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.root}>
			<TextField
				autoFocus={true}
				autoComplete="one-time-code"
				name="mfaCode"
				label={t("authentication.two-factor.login.fields.one-time-code.label")}
				form={form}
			/>
		</div>
	);
};

export default CodeBlock;
