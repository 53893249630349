import React, { useEffect } from "react";
import { VideoPlayer } from "@manakin/app-core";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { styles } from "./styles";
import { compose } from "recompose";

const FactCardVideo = (props) => {
    const { classes, vimeoId, subtitle } = props;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div
            className={classNames(
                classes.root,
                classes.factCardVideo,
            )}
        >
            <div className={classes.factCardVideoContent}>
                <VideoPlayer
                    videoId={vimeoId}
                    playing={false}
                    subtitle={subtitle}
                />
            </div>
        </div>
    );
};

export default compose(
    withStyles(styles, { name: "AppFactCard" }),
)(FactCardVideo);
