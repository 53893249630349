import React from 'react';
import classNames from 'classnames';
import { useStyles } from '../styles';
import { backgroundColorKeys } from '../../../../constants';

const PercentageBar = (props) => {
    const { percentage = 0, backgroundColor } = props;
    const perc = percentage > 0 ? percentage : 0;
    const classes = useStyles();

    return (
        <div className={classNames(
            classes.percentageBarRoot,
            { [classes.primary]: backgroundColor === backgroundColorKeys.primary },
            { [classes.secondary]: backgroundColor === backgroundColorKeys.secondary },
            { [classes.noBG]: backgroundColor === backgroundColorKeys.none },
        )}>
            <div className={classes.percentageBarOuter}>
                <div className={classNames([ classes.percentageBarInner, { lowPercentage: perc < 10 } ])} style={{ width: perc + '%' }} />
            </div>
        </div>
    );
};

export default PercentageBar;
