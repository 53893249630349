import React, { useState, useEffect, useReducer, useMemo, useRef } from "react";
import AskFeedback from "./AskFeedback";
import { reducer } from "../lib";
import { GQL_FETCH_FEEDBACK, GQL_MUTATE_FEEDBACK_LINK } from "./graphql";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { getBoxId } from "@manakin/app-core/Boxes/selectors";
import { getProgram } from "@manakin/app-core/ProgramsDropdown/selectors";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Dialog } from "@manakin/app-core";
import { Typography } from "@material-ui/core";
import { getAppUser, getSuperUser } from "@manakin/authentication/selectors";
import { useStyles } from "./styles";
import { useTranslation } from 'react-i18next';

const AskFeedbackContainer = (props) => {
	const { data = {} } = props;
	const classes = useStyles();
	const { t } = useTranslation();

	//state
	const [feedbackLink, setFeedbackLink] = useState(null);
	const linkSelectionRef = useRef();

	//reducer hooks
	const [state, setState] = useReducer(reducer, {});
	const params = useParams();

	//selectors
	const programId = useSelector((state) => getProgram(state));
	const boxId = useSelector((state) => getBoxId(state));
	const appUser = useSelector((state) => getAppUser(state));
	const superUser = useSelector((state) => getSuperUser(state));

	const ids = useMemo(() => {
		return {
			askFeedbackId: data.id,
			boxId: boxId,
			elementId: params.elementId,
			programId: programId,
		};
	}, [programId, boxId, data.id, params.elementId]);

	//queries
	const { data: feedbackData = {} } = useQuery(GQL_FETCH_FEEDBACK, {
		variables: {
			...ids,
			userId: superUser || appUser.id,
		},
	});

	//mutations
	const [mutate] = useMutation(GQL_MUTATE_FEEDBACK_LINK);

	//functions
	const setStartState = () => {
		setState({
			...data,
			feedback: false,
			initial: true,
		});
	};

	const handleSubmit = () => {
		mutate({
			variables: {
				input: ids,
			},
		}).then((r) => {
			if (
				r.data &&
				r.data.generateFeedbackLink &&
				r.data.generateFeedbackLink.feedback &&
				r.data.generateFeedbackLink.feedback.feedbackLink
			) {
				setFeedbackLink(
					r.data.generateFeedbackLink.feedback.feedbackLink
				);
			}
		});
	};

	const handleClose = () => {
		setFeedbackLink(null);
	};

	useEffect(() => {
		if (data.id) {
			setStartState();
		}
	}, [data.id]);

	const handleSelectWholeLink = () => {
		if (linkSelectionRef && linkSelectionRef.current) {
			const range = document.createRange();
			const selection = window.getSelection();
			range.selectNodeContents(linkSelectionRef.current);
			selection.removeAllRanges();
			selection.addRange(range);
		}
	};

	return (
		<React.Fragment>
			<AskFeedback
				data={state}
				onSubmit={handleSubmit}
				feedback={feedbackData.feedback}
			/>
			<Dialog
				open={Boolean(feedbackLink)}
				title={t("app.workforms.ask-feedback.link-dialog.title")}
				buttonText={t("common.close")}
				onClose={handleClose}
				content={
					<div>
						<Typography>
							{t("app.workforms.ask-feedback.link-dialog.description")}
						</Typography>
						<div
							className={classes.feedbackLink}
							onClick={handleSelectWholeLink}
						>
							<Typography
								ref={linkSelectionRef}
								className={classes.feedbackLinkContent}
							>
								<a
									href={`${process.env.REACT_APP_APP_URL}${feedbackLink}`}
									onClick={(event) => event.preventDefault()}
								>{`${process.env.REACT_APP_APP_URL}${feedbackLink}`}</a>
							</Typography>
						</div>
					</div>
				}
			/>
		</React.Fragment>
	);
};

export default AskFeedbackContainer;
