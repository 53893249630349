'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.selectOption = selectOption;
var SELECT_OPTION = exports.SELECT_OPTION = '@manakin/app-core/SELECT_OPTION';

function selectOption() {
    var option = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    return {
        type: SELECT_OPTION,
        payload: option
    };
}