import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { WorkformLayout, LoadImage, LongArrow } from '@manakin/app-core';
import { FeedbackBox } from '@manakin/app-workforms';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import { styles } from './styles';
import { withTranslation } from 'react-i18next';
import { isDone } from '../lib';

class ImageMPC extends React.PureComponent {
	handleClick = (index) => {
		const done = isDone(this.props.data);
		if (this.props.onChange && !done) this.props.onChange(index);
	};

	handleSubmit = () => {
		if (this.props.onSubmit) this.props.onSubmit();
	};

	render() {
		const { classes, data, onFinished, direction = 'default', disabled = false, t, saving } = this.props;
		const { answers = [] } = data;

		return (
			<WorkformLayout
				question={data.question || ''}
				instruction={data.introduction || t('app.workforms.image-mpc.introduction')}
				loading={false}
				saving={saving}
				direction={direction}
				renderAnswers={() => (
					<div className={classes.wrapper}>
						<div className={classes.answersRoot}>
							{answers &&
								answers.map((answer, i) => (
									<div
										key={answer.id}
										className={classNames(
											classes.card,
											{ [classes.col2]: answers.length <= 4 },
											{ [classes.col3]: answers.length > 4 }
										)}
										onClick={() => this.handleClick(i)}
									>
										<div
											className={classNames(classes.innerCard, {
												[classes.notActive]: answers.some((i) => i.selected) && !answer.selected,
												[classes.active]: isDone(data) && !answer.selected && answer.correct,
												[classes.disabled]: isDone(data) && !answer.selected && !answer.correct,
											})}
										>
											<div className={classes.image}>
												<LoadImage
													src={(answer.image && answer.image.url) || ''}
													placeholder={(answer.image && answer.image.thumbnailUrl) || ''}
													asset={answer.image}
													enableFocusPoint={true}
												/>
											</div>
											<div className={classNames(classes.checked, { [classes.showIcon]: answer.selected })} />
										</div>
									</div>
								))}
						</div>
						<div className={classes.buttons}>
							<Button
								variant="contained"
								color="primary"
								fullWidth
								className={classNames(classes.button, {
									[classes.hide]: data.feedback || false,
								})}
								onClick={this.handleSubmit}
								disabled={disabled}
							>
								{data.buttonText || t('common.check-answer')}
								<LongArrow className={classes.longArrowRoot} />
							</Button>
							{data.feedback && (
								<div
									className={classNames(classes.feedback, {
										[classes.activeFeedback]: data.feedback,
									})}
								>
									<FeedbackBox data={data} onFinished={onFinished} outlined={true} />
								</div>
							)}
						</div>
					</div>
				)}
			/>
		);
	}
}

export default compose(withStyles(styles, { name: 'AppImageMPC' }), withTranslation())(ImageMPC);
