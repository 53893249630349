import React, { useMemo } from "react";
import FactCard from "./FactCard";
import { ElementOverlay, Loader } from "@manakin/app-core";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";

const FactCardPreview = (props) => {
    const params = useParams();

    const factCardData = useMemo(() => {
        let data;
        const id = `${params.factCardId}/${params.userId}`;
        if (process.env.NODE_ENV === "development") data = Cookies.get(id);
        else data = localStorage.getItem(id);

        if (data) return JSON.parse(data);
        else return null;
    }, []);

    if (!factCardData) {
        return (<Loader fullScreen={true} />);
    }

    return (
        <ElementOverlay
            onClick={() => {
                // Cannot close in previews
            }}
            controls={false}
            customControls={true}
        >
            <FactCard {...factCardData} />
        </ElementOverlay>
    );
};

export default FactCardPreview;
