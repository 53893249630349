import React, { useReducer, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { makeStyles, createStyles } from '@material-ui/styles';
import { LayoutButtons, Boxes, ResumeButton, AccessControl } from '@manakin/app-core';
import { Typography } from '@material-ui/core';
import Dropdowns from './Dropdowns';
import { useTranslation } from 'react-i18next';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import { schoolManagerAndTeacherRoles } from '@manakin/app-core/lib';
import { permissionsScheme, Loader } from '@manakin/core';
import { SettingsKeys } from '@manakin/core/lib/constants';
import { SettingAccessControl } from '@manakin/core';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				marginTop: '-51rem',
				backgroundColor: 'transparent',
				transition: 'background-color .3s',
			},
			boxes: {
				transform: 'translateX(0)',
				opacity: 1,
				transition: 'transform .2s, opacity, .3s',
			},
			listView: {
				backgroundColor: theme.manakin.primaryColor[500],
			},
			editLink: {
				cursor: 'pointer',
				fontFamily: theme.manakin.defaultTitleFont,
				fontSize: '1.4rem',
				fontWeight: 'bold',
				letterSpacing: '.4rem',
			},
			wrapper: {
				...theme.manakin.defaultWrapper,
				textAlign: 'right',
				paddingBottom: '3rem',
				[theme.breakpoints.up('md')]: {
					paddingBottom: '4.8rem',
				},
			},
			shuffle: {
				marginRight: '1.1rem',
				top: '.6rem',
				position: 'relative',
			},
			link: {
				textDecoration: 'none',
				color: theme.manakin.defaultContentColor[500],
				transition: 'color .2s',
				'&:hover': {
					color: theme.manakin.defaultContentHover[500],
				},
			},
			normalWrapper: {
				...theme.manakin.defaultWrapper,
				pointerEvents: 'none',
				paddingBottom: '3rem',
				[theme.breakpoints.up('md')]: {
					marginTop: '-110px',
					height: '0',
					paddingBottom: '4.8rem',
					marginBottom: '8rem;'
				},
			},
			noBoxesMessage: {
				textAlign: 'left',
			},
			footerLogo: {},
			loading: {
				opacity: 0,
				transform: 'translateX(-3%)',
			}
		}),
	{ name: 'AppBoxLayout' }
);

const BoxLayout = (props) => {
	const {
		listView,
		data = {},
		loading,
		config = {},
		percentage = {},
		listViewOnly = false,
		user = {},
		resume = null,
		option = false,
		onProgramBoxesFilterChange
	} = props;
	const { t } = useTranslation();
	const classes = useStyles();
	const { appUser = {}, activeTags = [] } = data;
	const { general = {} } = config || {};

	const [yearsArray, setYearsArray] = useState(null);
	const [functionObj, setFunctionObj] = useState(false);

	const filterForCbd = (data) => {
		if (yearsArray && yearsArray.length && functionObj) {
			let filteredBoxes = [];
			data.boxes && data.boxes.forEach((item) => {
				item.forEach((box) => {
					let isDrogist = false;
					if (box.yearProgress) {
						if (functionObj[box.yearProgress] === 'Drogist') {
							isDrogist = true;
						}
					}

					if (!isDrogist && box.yearProgress) {
						// User should not see this particular box, don't add it
					} else if (isDrogist && box.yearProgress) {
						if (yearsArray.some((year) => year.year == box.yearProgress)) {
							if (box.childVersion && box.childVersion.id) {
								if (percentage[box.id] && percentage[box.id] > 0) {
									filteredBoxes.push(box);
								}
							} else {
								filteredBoxes.push(box);
							}
						}
					} else {
						if (box.childVersion && box.childVersion.id) {
							if (percentage[box.id] && percentage[box.id] > 0) {
								filteredBoxes.push(box);
							}
						} else {
							filteredBoxes.push(box);
						}
					}
				});
			});

			return { milestones: data.milestones, boxes: [filteredBoxes] };
		} else {
			return data;
		}
	}

	useEffect(() => {
		const getSecondPart = (str, firstPart) => str.split(firstPart)[1];
		if (!loading && Object.keys(user).length > 0) {
			if (user.userDataCbd) {
				let arr = [];
				let _obj = {};
				for (let [key, value] of Object.entries(user.userDataCbd)) {
					if (key.indexOf('trainingMandatory') !== -1 && value) {
						arr.push({
							year: getSecondPart(key, 'trainingMandatory'),
						});
					}
					if (key.indexOf('function20') !== -1 && value) {
						_obj[getSecondPart(key, 'function')] = value || user.userDataCbd.function || 'Assistent drogist';
					}
				}

				setFunctionObj(_obj);
				setYearsArray(arr);
			}
		}
	}, [loading, user]);

	const [boxes, setBoxes] = useReducer(
		(state, action) => {
            let newData = general.cbd ? filterForCbd(data) : data;

            // If no filter is set, immediately return the correct data
			if (!action || !action.length) {
				return {
					boxes: newData.boxes || [],
					milestones: newData.milestones || [],
				};
			}

            // Filter the boxes by the given filter tags
			let arr = [];
			newData.boxes.forEach((boxGroup) => {
				boxGroup.forEach((box) => {
					box.tags &&
						box.tags.forEach((tag) => {
							if (action.some((i) => i.id === tag.id)) arr.push(box);
						});
				});
			});

			return {
				boxes: [arr],
				milestones: null,
			};
		},
		{
			boxes: data.boxes || [],
			milestones: data.milestones || [],
		}
	);

	useEffect(() => {
		setBoxes();
	}, [data]);

	//functions
	const handleChange = (data) => {
		setBoxes(data);
	};

	const empty = boxes && boxes.boxes && !boxes.boxes.length && (!data || !data.boxes || !data.boxes.length) && !loading;

	return (
		<div
			className={classNames(classes.root, {
				[classes.listView]: listView || listViewOnly,
			})}
		>
			<AccessControl role={schoolManagerAndTeacherRoles}>
				<SettingAccessControl name={SettingsKeys.CHANGE_LESSON_ENABLED}>
					<div className={classes.wrapper}>
						<Link to={'/teacherProgram'} className={classes.link}>
							<span className={classes.shuffle}>
								<ShuffleIcon />
							</span>
							<span className={classes.editLink}>{t('app.dashboard.box.edit-lesson')}</span>
						</Link>
					</div>
				</SettingAccessControl>
			</AccessControl>
			{resume && (
				<div className={classes.wrapper}>
					<ResumeButton {...resume} />
				</div>
			)}
			{!listViewOnly && <LayoutButtons config={config} />}
			<div className={classes.normalWrapper}>
				<Dropdowns
					onChange={handleChange}
					onProgramBoxesFilterChange={onProgramBoxesFilterChange}
					programData={data}
					appUser={appUser}
					activeTags={activeTags}
				/>
			</div>
			{loading ? (
				<div className={classes.normalWrapper}>
					<Loader center />
				</div>
			) : (
				<div
					className={classNames(classes.boxes, {
						[classes.loading]: loading && !empty,
					})}
				>
					{empty ? (
						<Typography variant="body1" className={classes.noBoxesMessage}>
							{t('app.dashboard.box.no-products')}
						</Typography>
					) : (
						<Boxes
							data={boxes}
							percentage={percentage}
							loading={loading}
							user={user}
							resume={resume}
							listViewOnly={listViewOnly}
							option={option}
						/>
					)}
				</div>
			)}
			{config.pages && config.pages.appLogin && config.pages.appLogin.footerLogo && <div className={classes.footerLogo} />}
		</div>
	);
};

export default compose(connect(({ config }) => ({ config })))(BoxLayout);
