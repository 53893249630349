import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { ProgressiveImage } from '@manakin/app-core';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import { styles } from './styles';
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';

class ImageWithSubtitle extends React.PureComponent {
    handleClick = () => {
        if (this.props.onClick) this.props.onClick();
    };

    handleSubmit = () => {
        if (this.props.onNext) this.props.onNext();
    };

    handleNext = () => {
        if (this.props.onFinished) this.props.onFinished();
    };

    render() {
        const { classes, data, buttonText = '', withNext, t } = this.props;

        return (
            <div>
            <div className={classes.root}>
                <div className={classes.image}>
                    {data.zoom && (
                        <div
                            className={classes.fullscreen}
                            onClick={this.handleClick}
                        >
                            <FullscreenIcon />
                        </div>
                    )}
                    <ProgressiveImage
                        preview={data.image ? data.image.thumbnailUrl : ''}
                        image={data.image ? data.image.url : ''}
                    />
                    {buttonText && (
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={this.handleSubmit}
                        >
                            {buttonText}
                        </Button>
                    )}
                </div>
                {data.subtitle && (
                    <Typography
                        component="p"
                        variant="body2"
                        classes={{ body2: classes.content }}
                    >
                        {data.subtitle}
                    </Typography>
                )}
                {data.zoom && (
                    <div
                        className={classNames(classes.overlay, {
                            [classes.show]: data.fullscreen,
                        })}
                    >
                        <div
                            className={classes.fullscreen}
                            onClick={this.handleClick}
                        >
                            <FullscreenExitIcon />
                        </div>
                        <ProgressiveImage
                            autoHeight={true}
                            preview={data.image ? data.image.thumbnailUrl : ''}
                            image={data.image ? data.image.url : ''}
                        />
                    </div>
                )}
            </div>
            {withNext && (
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.nextButton}
                        onClick={this.handleNext}
                    >
                        {t("common.next")}
                    </Button>
                )}
            </div>
        );
    }
}

export default compose(
    withStyles(styles, { name: 'AppImageWithSubtitle' }),
    withTranslation()
)(ImageWithSubtitle);
