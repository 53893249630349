import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { compose } from 'recompose';
import {
    StatisticsBox,
    StatisticsBox2,
    AccessControl,
} from '@manakin/app-core';
import { getGroup } from '@manakin/app-core/GroupsDropdown/selectors';
import CreateDialog from '../CreateDialog';
import UpdateDialog from '../UpdateDialog';
import { openDialog } from '@manakin/core/actions';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import classNames from "classnames";
import { teacherRoles, schoolManagerRole } from '@manakin/app-core/lib';

const styles = (theme) => ({
	root: {
		backgroundColor: theme.manakin.secondaryBackgroundColor[100],
		width: '100%',
		padding: '12rem 0 8rem',
		[theme.breakpoints.up('md')]: {
			padding: '18rem 0 8rem',
		},
	},
	heading: {
		textAlign: 'center'
	},
	titleHeading: {
        marginBottom: `${theme.spacing(3)}px !important`
    },
    subHeading: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        textAlign: "center"
    },
    content: {
		'& $heading': {
			marginBottom: '3.4rem',
			color: theme.palette.getContrastText(theme.manakin.secondaryBackgroundColor[100]),
			[theme.breakpoints.up('md')]: {
				marginBottom: '8.4rem',
			},
		},
	},
	wrapper: {
		...theme.manakin.defaultWrapper,
	},
	links: {
		textAlign: 'right',
	},
	button: {
		marginBottom: '1rem',
		[theme.breakpoints.up('md')]: {
			marginBottom: 0,
			marginLeft: '1rem',
		},
	},
});

const Header = (props) => {
    const { classes, group = {}, config = {} } = props;
    const { general = {} } = config;
    const { t } = useTranslation();

    const openDialog = (dialog) => {
        props.onOpenDialog(dialog);
    };

    return (
		<div className={classes.root}>
			{group && group.type && group.type === 'SchoolClass' && (
				<AccessControl role={[schoolManagerRole]}>
					<CreateDialog />
					{group.id && <UpdateDialog />}
				</AccessControl>
			)}

			<div className={classes.wrapper}>
				<div className={classes.content}>
					<Typography
						component="h1"
						variant="h2"
						className={classNames(classes.heading, {
							[classes.titleHeading]: !group,
						})}
					>
						{t('app.report.header.title')}
					</Typography>
					{!group && (
						<Typography component="p" variant="p" className={classes.subHeading}>
							{t('app.report.header.introduction')}
						</Typography>
					)}
				</div>
				{group && typeof group.id == 'string' && (
					<div>
						{general.secondStatisticsBox ? (
							<React.Fragment>
								<AccessControl role={[schoolManagerRole]}>
									<StatisticsBox2 variant="rapportage" roles={[schoolManagerRole]} dashboard={false} />
								</AccessControl>
								<AccessControl role={teacherRoles} not={[schoolManagerRole]}>
									<StatisticsBox2 variant="rapportage_bak" roles={teacherRoles} dashboard={false} />
								</AccessControl>
							</React.Fragment>
						) : (
							<React.Fragment>
								<AccessControl role={[schoolManagerRole]}>
									<StatisticsBox variant="rapportage" roles={[schoolManagerRole]} dashboard={false} />
								</AccessControl>
								<AccessControl role={teacherRoles} not={[schoolManagerRole]}>
									<StatisticsBox variant="rapportage" roles={teacherRoles} dashboard={false} />
								</AccessControl>
							</React.Fragment>
						)}
					</div>
				)}
				{group && group.type && group.type === 'SchoolClass' && (
					<div className={classes.links}>
						<AccessControl role={[schoolManagerRole]}>
							<Button
								size="small"
								color="primary"
								variant="contained"
								onClick={() => openDialog('appClassCreateDialog')}
								className={classes.button}
							>
								{t('app.report.header.create-school-class')}
							</Button>
							{group && (
								<Button
									size="small"
									color="primary"
									variant="contained"
									onClick={() => openDialog('appClassUpdateDialog')}
									className={classes.button}
								>
									{t('app.report.header.change-school-class')}
								</Button>
							)}
						</AccessControl>
					</div>
				)}
			</div>
		</div>
	);
};

export default compose(
    withStyles(styles, { name: 'AppTeacherReport' }),
    connect(
        (state) => ({
            group: getGroup(state),
            config: state.config,
        }),
        (dispatch) => ({
            onOpenDialog: (data) => dispatch(openDialog(data)),
        })
    )
)(Header);
