import React from "react";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from 'react-i18next';

const ErrorPage = (props) => {
	const { classes } = props;
	const { t } = useTranslation();

	return (
		<div className={classes.root}>
			<div className={classes.content}>
				<Typography classes={classes.heading} variant="h2">
					{t("authentication.confirm-registration.error.title")}
				</Typography>
				<Typography
					classes={{ body1: classes.paragraph }}
					variant="body1"
					gutterBottom
				>
					{t("authentication.confirm-registration.error.description")}
				</Typography>
			</div>
		</div>
	);
};

export default ErrorPage;
