import React, { useEffect, useState } from "react";
import { AccessControl, Loader } from "@manakin/app-core";
import { connect } from "react-redux";
import { compose } from "recompose";
import { setBackButton } from "@manakin/app-core/AppBar/actions";
import { setAppBarBackgroundColor } from "@manakin/app-core/actions";
import { useLocation, useParams } from "react-router-dom";
import { Header, List } from "./parts";
import { useQuery } from "@apollo/react-hooks";
import { GQL_FETCH_APP_USER, GQL_FETCH_PROGRAM } from "./graphql";
import MessageDialog from "./parts/MessageDialog";
import { teacherRoles, schoolManagerRole } from '@manakin/app-core/lib';

const initialMessageState = {
	open: false,
	title: "",
	studentId: null,
	name: "",
};

const StudentRapportage = (props) => {
	const { backButton, config = {}, onSetAppBarColor } = props;
	const { elements: elementsConfig = {} } = config;
	const location = useLocation();
	const params = useParams();
	const [message, setMessage] = useState(initialMessageState);

	//queries
	const { data = {}, loading } = useQuery(GQL_FETCH_APP_USER, {
		variables: {
			id: params.id,
		},
	});
	const { appUser = {} } = data;

	const { data: programData = {}, loading: programLoading } = useQuery(
		GQL_FETCH_PROGRAM,
		{
			variables: {
				id: params.programId,
			},
		}
	);
	const { program } = programData;

	useEffect(() => {
		backButton({
			show: true,
			location: location.pathname,
			url: "/rapportage",
		});
	}, []);

	//functions
	const handleMessageClick = (student, element) => {
		setMessage({
			open: true,
			studentId: student.id,
			title: element.title,
			name: student.fullName,
		});
	};

	const handleClose = () => {
		setMessage(initialMessageState);
	};

	if (!appUser || loading || programLoading || !elementsConfig.exerciseCard)
		return <Loader fullScreen />;
	return (
		<AccessControl role={[...teacherRoles, schoolManagerRole]}>
			<div>
				<Header
					appUser={appUser}
					params={params}
					config={config}
					setAppBarColor={onSetAppBarColor}
				/>
				<List
					appUser={appUser}
					program={program}
					onMessageClick={handleMessageClick}
				/>
				<MessageDialog {...message} onClose={handleClose} />
			</div>
		</AccessControl>
	);
};

export default compose(
	connect(
		(state) => ({ config: state.config }),
		(dispatch) => ({
			backButton: (data) => dispatch(setBackButton(data)),
			onSetAppBarColor: (color) =>
				dispatch(setAppBarBackgroundColor(color)),
		})
	)
)(StudentRapportage);
