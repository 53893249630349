import React from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core';
import SelectField from './SelectField';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
	root: {
		[theme.breakpoints.up('md')]: {
			width: '30rem',
		},
		'&>div': {
			margin: 0,
			marginTop: '6px',
		},

		marginTop: '30px',
		[theme.breakpoints.up('md')]: {
			marginTop: '0',
		},
	},
	select: {
		cursor: 'pointer',
	},
});

const Filter = (props) => {
	const { classes, activeTags = [] } = props;
	const { t } = useTranslation();

    //queries
    const options = activeTags.map((tag) => ({
		id: tag.id,
		label: tag.name, 
		icon: tag.image || '',
	}));

	//functions
	const handleChange = (data) => {
		if (props.onChange) props.onChange(data);
	};

    if (!activeTags) return
		return (
			<div className={classes.root}>
				<SelectField label={t('app.dashboard.fields.themes.label')} onChange={handleChange} options={options} />
			</div>
		);
};

export default compose(
	withStyles(styles, { name: 'AppDashboardFilter' }),
)(Filter);
