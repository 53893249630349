import React from 'react';
import MuiDialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import useStyles, { styles } from "../parts/styles/index"
import { useTranslation } from 'react-i18next';

const ExitSaveDialog = props => {
    const { open, onClose, onSaveClick } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <MuiDialog
            className={classes.exitSaveDialog}
            open={open}
            onClose={onClose}
        >
            <DialogTitle>{t("app.elements.slideshow-creator.exit-save-dialog.title")}</DialogTitle>
            <DialogContent>
                {t("app.elements.slideshow-creator.exit-save-dialog.description")}
            </DialogContent>
            <DialogActions className={classes.exitSaveDialogActions}>
                <Button color="primary" onClick={onClose}>
                    {t("app.elements.slideshow-creator.exit-save-dialog.action")}
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={onSaveClick}
                >
                    {t("common.save")}
                </Button>
            </DialogActions>
        </MuiDialog>
    );
};

export default withStyles(styles)(ExitSaveDialog);
