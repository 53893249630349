export const RESET_FORM = '@manakin/core/RESET_FORM';
export const CLEAR_FORM = '@manakin/core/CLEAR_FORM';
export const INIT_FORM = '@manakin/core/INIT_FORM';
export const DESTROY_FORM = '@manakin/core/DESTROY_FORM';

export function clearForm(id) {
    return {
        type: CLEAR_FORM,
        payload: {
            id,
        },
    };
}

export function resetForm(id) {
    return {
        type: RESET_FORM,
        payload: {
            id,
        },
    };
}

export function initForm(id) {
    return {
        type: INIT_FORM,
        payload: {
            id,
        },
    };
}

export function destroyForm(id) {
    return {
        type: DESTROY_FORM,
        payload: {
            id,
        },
    };
}
