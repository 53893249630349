import React, { useEffect, useState } from 'react';
import { withWorkforms, ElementOverlay } from '@manakin/app-core';
import { compose } from 'recompose';
import { GQL_FETCH_STYLESHEET } from './graphql';
import { useQuery } from '@apollo/react-hooks';
import { connect } from 'react-redux';
import { changeColor } from '@manakin/app-core/ColorService/actions';
import { useLocation } from 'react-router-dom';
import Case from './Case';
const queryString = require('query-string');
import { useTranslation } from 'react-i18next';

const CasePreview = (props) => {
    const { match, workformsData, changeColor } = props;
    const query = match.params.query;
    const elementData = queryString.parse(query);
    const location = useLocation();
    const { t } = useTranslation();

    //state hooks
    const [step, setStep] = useState(-1);
    const [showContent, setShowContent] = useState(false);
    const [characteristic, setCharacteristic] = useState([]);
    const [closeChatBubble, setCloseChatBubble] = useState(false);
    const [items, setItems] = useState([]);
    const [itemTypes, setItemTypes] = useState([]);

    //queries
    const { data = {}, loading } = useQuery(GQL_FETCH_STYLESHEET, {
        variables: {
            id: elementData.styleSheet || '',
        },
    });

    useEffect(() => {
        if (elementData && elementData.items) {
            if (Array.isArray(elementData.items)) {
                setItems(elementData.items);
                setItemTypes(elementData.itemTypes);
            } else {
                setItems([elementData.items]);
                setItemTypes([elementData.itemTypes]);
            }
        }
    }, [query]);

    useEffect(() => {
        if (!loading) {
            const obj = {
                ...elementData,
                headerImage: {
                    url: elementData.headerImage,
                },
                image: {
                    url: elementData.image,
                },
                workforms: [
                    ...items.map((item, idx) => ({
                        id: item,
                        type: itemTypes[idx],
                    })),
                ],
                options: {
                    showFeedback: true,
                    showFeedbackTitle: false,
                    nextButton: true,
                },
            };

            changeColor({
                ...data.styleSheet,
                location: location.pathname,
            });
            workformsData.loadWorkforms({ ...obj });
        }
    }, [loading, items, itemTypes]);

    //functions
    const handleClick = (initial = 0) => {
        setShowContent(true);
        setStep(initial);
    };

    const handleNext = (data) => {
        const { workforms } = workformsData;
        const workformLength = workforms.length || 0;

        if (step < workformLength - 1) {
            setStep(step + 1);
        } else {
            handleFinish();
        }

        if (data && data.characteristic && data.characteristic.length) {
            let _characteristic = [...(characteristic || [])];
            const merge = _characteristic.concat(data.characteristic);
            setCharacteristic(merge);
        }
    };

    const handleFinish = () => {
        if (
            workformsData.workforms &&
            workformsData.workforms[workformsData.workforms.length - 1].type ===
                'ChatBubble'
        ) {
            closeChatBubble(true);
            setCloseChatBubble(true);
        }
    };

    const handleClose = () => {
        return;
    };

    return (
        <ElementOverlay
            fullWidth={true}
            title={elementData.preTitle || t("common.element-types.case")}
            controls={false}
        >
            <Case
                {...workformsData}
                stateData={{
                    showContent: showContent,
                    step: step,
                    characteristic: characteristic,
                    closeChatBubble: false,
                }}
                onExit={handleFinish}
                onClick={handleClick}
                onNext={handleNext}
                onClose={handleClose}
            />
        </ElementOverlay>
    );
};

export default compose(
    connect(null, (dispatch) => ({
        changeColor: (styleSheet) => dispatch(changeColor(styleSheet)),
    })),
    withWorkforms()
)(CasePreview);
