import React from "react";
import { compose } from "recompose";
import { withForm } from "@manakin/core";
import ChangePasswordView from "./ChangePasswordView";

const form = {
	password: {
		required: true,
		format: "strong-password",
	},
	secondPassword: {
		required: true,
		couple: "password",
		coupleLabel: "wachtwoord",
		format: "strong-password",
	},
};

const StrongPasswordView = (props) => {
	const { form, onSubmit } = props;

	return <ChangePasswordView form={form} onSubmit={onSubmit} />;
};

export default compose(withForm(form))(StrongPasswordView);
