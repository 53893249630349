"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _core = require("@manakin/core");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DEFAULT_DURATION = 6000;

var useSnackBar = function useSnackBar() {
	var context = (0, _react.useContext)(_core.UiContext);

	var success = function success(text) {
		var duration = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_DURATION;

		context.showSnackBar(text, "success", duration);
	};

	var info = function info(text) {
		var duration = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_DURATION;

		context.showSnackBar(text, "info", duration);
	};

	var error = function error(text) {
		var duration = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_DURATION;

		context.showSnackBar(text, "error", duration);
	};

	var warning = function warning(text) {
		var duration = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_DURATION;

		context.showSnackBar(text, "warning", duration);
	};

	return { success: success, info: info, error: error, warning: warning };
};

exports.default = useSnackBar;