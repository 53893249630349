import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	paragraph: {
		textAlign: 'center',
		width: '100%',
		margin: '0 auto 3em',
		maxWidth: '560px',
	},
	heading: {
		textAlign: 'center',
	},
}));

const DefaultHeading = (props) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<React.Fragment>
			<Typography className={classes.heading} variant="h2">
				{t('app.no-licenses.title')}
			</Typography>
			<Typography classes={{ body1: classes.paragraph }} variant="body1" gutterBottom>
				{t('app.no-licenses.description')}
			</Typography>
		</React.Fragment>
	);
};

export default DefaultHeading;
