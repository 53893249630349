"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _appCore = require("@manakin/app-core");

var _ListBody = require("./ListBody");

var _ListBody2 = _interopRequireDefault(_ListBody);

var _ListHead = require("./ListHead");

var _ListHead2 = _interopRequireDefault(_ListHead);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var List = function List(props) {
	var _props$data = props.data,
	    data = _props$data === undefined ? [] : _props$data,
	    program = props.program;


	return _react2.default.createElement(_appCore.SimpleTable, {
		renderHead: function renderHead() {
			return _react2.default.createElement(_ListHead2.default, null);
		},
		renderBody: function renderBody() {
			return _react2.default.createElement(
				_react2.default.Fragment,
				null,
				data && data.map(function (item) {
					return _react2.default.createElement(_ListBody2.default, { data: item, key: item.student.id });
				})
			);
		}
	});
};

exports.default = List;