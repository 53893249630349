import gql from 'graphql-tag';
import { fileAsset, appAssetWithThumbnail } from '@manakin/app-core/graphQlHelpers';

export const GQL_FETCH_TRAINER = gql`
    query trainer($id: ID!) {
        trainer(id: $id) {
            id
            type
            introTitle
            introText
            preTitle
            titleColor
            styleSheet {
                id
                indicationColor
                defaultWorkformColor
                primaryWorkformColor
                secondaryWorkformColor
                themeColor
            }
            image {
                ${appAssetWithThumbnail}
            }
            workforms {
                id
                type
            }
        }
    }
`;

export const GQL_FETCH_ELEMENT_RESULTS = gql`
	query elementResult($box: ID!, $program: ID!, $user: ID!, $element: ID!) {
		elementResult(box: $box, program: $program, user: $user, element: $element) {
			bestTime {
				id
				time
			}
		}
	}
`;

export const GQL_CREATE_ELEMENT_RESULT = gql`
    mutation(
        $user: ID!
        $program: ID!
        $box: ID!
        $element: ID!
        $finished: Boolean
        $bestTime: Int
    ) {
        createElementResult(
            input: {
                user: $user
                program: $program
                box: $box
                element: $element
                finished: $finished
                bestTime: $bestTime
            }
        ) {
            result {
                finished
            }
        }
    }
`;

export const GQL_FETCH_GLOBAL_SETTINGS = gql`
    query {
        settings {
            settings {
                name
                value
                file {
                    ${fileAsset}
                }
            }
        }
    }
`;

export const GQL_FETCH_STYLESHEET = gql`
    query styleSheet($id: ID!) {
        styleSheet(id: $id) {
            id
            title
            description
            customStyle
            indicationColor
            defaultWorkformColor
            primaryWorkformColor
            secondaryWorkformColor
            themeColor
        }
    }
`;
