"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _TableCell = require("@material-ui/core/TableCell");

var _TableCell2 = _interopRequireDefault(_TableCell);

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TableHead = function TableHead(props) {
	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	return _react2.default.createElement(
		_react2.default.Fragment,
		null,
		_react2.default.createElement(
			_TableCell2.default,
			{ align: "left" },
			t("common.table-headers.email")
		),
		_react2.default.createElement(
			_TableCell2.default,
			{ align: "left" },
			t("common.table-headers.role")
		),
		_react2.default.createElement(
			_TableCell2.default,
			{ align: "left" },
			t("common.table-headers.agreed")
		),
		_react2.default.createElement(
			_TableCell2.default,
			{ align: "right" },
			t("common.table-headers.action")
		)
	);
};

exports.default = TableHead;