'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _styles = require('@material-ui/core/styles');

var _Button = require('@material-ui/core/Button');

var _Button2 = _interopRequireDefault(_Button);

var _styles2 = require('./styles');

var _appCore = require('@manakin/app-core');

var _reactI18next = require('react-i18next');

var _recompose = require('recompose');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SortSlider = function (_React$PureComponent) {
	_inherits(SortSlider, _React$PureComponent);

	function SortSlider() {
		var _ref;

		var _temp, _this, _ret;

		_classCallCheck(this, SortSlider);

		for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
			args[_key] = arguments[_key];
		}

		return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = SortSlider.__proto__ || Object.getPrototypeOf(SortSlider)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
			step: 0,
			prev: null,
			correctAnswers: 0
		}, _this.setStartState = function () {
			_this.setState({
				step: 0,
				prev: null,
				correctAnswers: 0
			});
		}, _this.handleClick = function (event) {
			if (_this.state.step >= 0) {
				var _this$props = _this.props,
				    questions = _this$props.questions,
				    _this$props$options = _this$props.options,
				    options = _this$props$options === undefined ? {} : _this$props$options;
				var step = _this.state.step;

				var _step = step + 1 >= questions.length ? -1 : step + 1;
				var _prev = _step == -1 ? questions.length - 1 : _step - 1;
				_this.setState({
					step: _step,
					prev: _prev
				}, function () {
					if (_this.state.step < 0 && !options.nextButton) {
						_this.handleFeedbackClick();
					}
				});
			}
		}, _this.handleFeedbackClick = function () {
			if (_this.props.onNext) _this.props.onNext();
		}, _temp), _possibleConstructorReturn(_this, _ret);
	}

	_createClass(SortSlider, [{
		key: 'componentDidMount',
		value: function componentDidMount() {
			this.setStartState();
		}
	}, {
		key: 'componentDidUpdate',
		value: function componentDidUpdate(prevProps) {
			if (!prevProps.data.done && this.props.data.done || prevProps.data.id != this.props.data.id) {
				this.setStartState();
			}
		}
	}, {
		key: 'render',
		value: function render() {
			var _props = this.props,
			    _props$questions = _props.questions,
			    questions = _props$questions === undefined ? [] : _props$questions,
			    classes = _props.classes,
			    _props$leftButton = _props.leftButton,
			    leftButton = _props$leftButton === undefined ? '' : _props$leftButton,
			    _props$rightButton = _props.rightButton,
			    rightButton = _props$rightButton === undefined ? '' : _props$rightButton,
			    _props$data = _props.data,
			    data = _props$data === undefined ? {} : _props$data,
			    _props$options = _props.options,
			    options = _props$options === undefined ? {} : _props$options,
			    t = _props.t;
			var _state = this.state,
			    step = _state.step,
			    prev = _state.prev;


			return _react2.default.createElement(
				'div',
				null,
				_react2.default.createElement(
					'div',
					{ className: classes.questionsRoot },
					_react2.default.createElement(
						'div',
						{ className: classes.questions },
						_react2.default.createElement(
							'div',
							{ className: classes.progress },
							step + 1,
							'/',
							questions.length
						),
						questions && step > -1 && questions.map(function (question, idx) {
							return _react2.default.createElement(
								'div',
								{
									key: question.id,
									className: (0, _classnames2.default)(classes.question, _defineProperty({}, classes.visible, idx == step), _defineProperty({}, classes.prev, idx == prev))
								},
								question.image && question.image.url && _react2.default.createElement(_appCore.ImageWithFocusPoint, {
									asset: question.image,
									imageProps: {
										className: classes.backgroundImage
									}
								}),
								_react2.default.createElement(
									'div',
									{ className: classes.topContent },
									_react2.default.createElement(
										'p',
										{ className: classes.questionContent },
										question.text || ''
									)
								)
							);
						})
					),
					_react2.default.createElement(
						'div',
						{ className: classes.controls },
						_react2.default.createElement(
							_Button2.default,
							{ variant: 'outlined', color: 'primary', className: classes.button, fullWidth: true, onClick: this.handleClick },
							leftButton
						),
						_react2.default.createElement(
							_Button2.default,
							{ variant: 'outlined', color: 'primary', fullWidth: true, className: classes.button, onClick: this.handleClick },
							rightButton
						)
					),
					_react2.default.createElement(
						'div',
						{
							className: (0, _classnames2.default)(classes.feedback, _defineProperty({}, classes.showFeedback, step < 0))
						},
						data.hintText && _react2.default.createElement(
							'div',
							{ className: classes.feedbackIntro },
							data.hintText || ''
						),
						_react2.default.createElement(
							'div',
							{ className: classes.columns },
							_react2.default.createElement(
								'div',
								{ className: classes.columnLeft },
								_react2.default.createElement(
									'div',
									{ className: classes.title },
									data.indicationTitle || t('app.workforms.sort-question.indication')
								),
								_react2.default.createElement(
									'ul',
									{ className: classes.list },
									data.indications && data.indications.map(function (item, idx) {
										return _react2.default.createElement(
											'li',
											{ key: idx, className: classes.columnItem },
											item
										);
									})
								)
							),
							_react2.default.createElement(
								'div',
								{ className: classes.columnRight },
								_react2.default.createElement(
									'div',
									{ className: (0, _classnames2.default)(classes.title) },
									data.contraIndicationTitle || t('app.workforms.sort-question.contra-indication')
								),
								_react2.default.createElement(
									'ul',
									{ className: classes.list },
									data.contraIndications && data.contraIndications.map(function (item, idx) {
										return _react2.default.createElement(
											'li',
											{ key: idx, className: classes.columnItem },
											item
										);
									})
								)
							)
						),
						options && options.nextButton && _react2.default.createElement(
							_Button2.default,
							{
								className: classes.feedbackButton,
								variant: 'contained',
								color: 'primary',
								fullWidth: true,
								onClick: this.handleFeedbackClick
							},
							t('common.resume')
						)
					)
				)
			);
		}
	}]);

	return SortSlider;
}(_react2.default.PureComponent);

exports.default = (0, _recompose.compose)((0, _styles.withStyles)(_styles2.styles, { name: 'AppSortSlider' }), (0, _reactI18next.withTranslation)())(SortSlider);