import React from "react";
import { useSelector } from "react-redux";
import PasswordView from "./PasswordView";
import StrongPasswordView from "./StrongPasswordView";

const AppRegistration = (props) => {
	const config = useSelector((state) => state.config);
	const { general = {} } = config || {};

	if (general.strongPassword) return <StrongPasswordView />;
	else return <PasswordView />;
};

export default AppRegistration;
