'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _selectors = require('@manakin/authentication/selectors');

var _selectors2 = require('@manakin/app-core/GroupsDropdown/selectors');

var _recompose = require('recompose');

var _core = require('@material-ui/core');

var _styles = require('./styles');

var _reactRedux = require('react-redux');

var _reactRouterDom = require('react-router-dom');

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _ArrowRightAlt = require('@material-ui/icons/ArrowRightAlt');

var _ArrowRightAlt2 = _interopRequireDefault(_ArrowRightAlt);

var _statisticsContent = require('./statisticsContent');

var _appCore = require('@manakin/app-core');

var _reactApollo = require('react-apollo');

var _graphql = require('./graphql');

var _reactI18next = require('react-i18next');

var _lib = require('../lib');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StatisticsBox = function StatisticsBox(props) {
	var classes = props.classes,
	    _props$config = props.config,
	    config = _props$config === undefined ? {} : _props$config,
	    roles = props.roles,
	    appUser = props.appUser,
	    _props$group = props.group,
	    group = _props$group === undefined ? {} : _props$group,
	    _props$variant = props.variant,
	    variant = _props$variant === undefined ? 'default' : _props$variant,
	    _props$dashboard = props.dashboard,
	    dashboard = _props$dashboard === undefined ? true : _props$dashboard;
	var _config$general = config.general,
	    general = _config$general === undefined ? {} : _config$general;

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var _useQuery = (0, _reactApollo.useQuery)(_graphql.GQL_FETCH_GROUP, {
		variables: {
			id: group ? group.id || null : null
		}
	}),
	    _useQuery$data = _useQuery.data,
	    data = _useQuery$data === undefined ? {} : _useQuery$data,
	    loading = _useQuery.loading;

	var _ref = data || {},
	    _ref$group = _ref.group,
	    groupData = _ref$group === undefined ? {} : _ref$group;

	//state hooks


	var _useState = (0, _react.useState)(false),
	    _useState2 = _slicedToArray(_useState, 2),
	    open = _useState2[0],
	    setOpen = _useState2[1];

	//functions


	var handleClose = function handleClose() {
		setOpen(!open);
	};

	(0, _react.useEffect)(function () {
		if (group) {
			if (!group.id && (0, _lib.requestedRolesInGivenRoles)(_lib.teacherRoles, roles)) {
				setOpen(true);
			} else {
				setOpen(false);
			}
		} else {
			setOpen(false);
		}
	}, [group]);

	if (loading || !groupData) return '';
	if (groupData.type === 'School' && (0, _lib.requestedRolesInGivenRoles)(_lib.teacherRoles, roles)) return '';
	return _react2.default.createElement(
		_react2.default.Fragment,
		null,
		_react2.default.createElement(
			'div',
			{ className: classes.root },
			dashboard && _react2.default.createElement(
				'div',
				{ className: classes.headingContent },
				_react2.default.createElement(
					'div',
					{ className: classes.right },
					_react2.default.createElement(
						_reactRouterDom.Link,
						{ className: classes.link, to: '/rapportage' },
						_react2.default.createElement(
							_Typography2.default,
							{ className: classes.linkContent, component: 'p', variant: 'body1' },
							t('app.statistics-box.classes-overview'),
							_react2.default.createElement(
								'span',
								null,
								_react2.default.createElement(_ArrowRightAlt2.default, null)
							)
						)
					)
				)
			),
			group && group.id && groupData && groupData.type && groupData.type === 'SchoolClass' && _react2.default.createElement(
				'div',
				{ className: classes.statistics },
				(0, _lib.requestedRolesInGivenRoles)([_lib.schoolManagerRole], roles) && _react2.default.createElement(_statisticsContent.Manager, { variant: variant, appUser: appUser }),
				(0, _lib.requestedRolesInGivenRoles)(_lib.teacherRoles, roles) && _react2.default.createElement(_statisticsContent.Teacher, { variant: variant, appUser: appUser, group: group.id, type: groupData.type || null })
			)
		),
		_react2.default.createElement(_appCore.Dialog, {
			open: open && !general.hideTeacherMessage,
			onClose: handleClose,
			title: t('app.statistics-box.no-access-dialog.title'),
			content: t('app.statistics-box.no-access-dialog.description')
		})
	);
};

exports.default = (0, _recompose.compose)((0, _reactRedux.connect)(function (state) {
	return {
		config: state.config,
		group: (0, _selectors2.getGroup)(state),
		appUser: (0, _selectors.getAppUser)(state)
	};
}), (0, _core.withStyles)(_styles.styles, { name: 'AppStatisticsBox' }))(StatisticsBox);