import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		marginBottom: '50px',
		width: '100%',
		justifyContent: 'center',
	},
	content: {
		display: 'flex',
		justifyContent: 'space-between',
		[theme.breakpoints.up('md')]: {
			width: '400px',
		},
	},
	iconButton: {
		backgroundColor: theme.palette.primary[500],
		'&:hover': {
			backgroundColor: theme.palette.primary[400],
		},
		[theme.breakpoints.up('md')]: {
			flex: '0 0 60px',
		},
	},
	icon: {
		color: theme.palette.getContrastText(theme.palette.primary[500]),
		[theme.breakpoints.up('md')]: {
			width: '30px',
			height: '30px',
		},
	},
	label: {
		margin: 0,
	},
}));

const Toolbar = (props) => {
	const { onNavigate, label } = props;
	const classes = useStyles();

	const handlePrevClick = () => {
		onNavigate('PREV');
	};
	const handleNextClick = () => {
		onNavigate('NEXT');
	};

	return (
		<div className={classes.root}>
			<div className={classes.content}>
				<IconButton onClick={handlePrevClick} classes={{ root: classes.iconButton }} className={classes.iconButton}>
					<ChevronLeftIcon fontSize="large" className={classes.icon} />
				</IconButton>
				<Typography variant="h3" className={classes.label}>
					{label || ''}
				</Typography>
				<IconButton onClick={handleNextClick} classes={{ root: classes.iconButton }} className={classes.iconButton}>
					<ChevronRightIcon fontSize="large" className={classes.icon} />
				</IconButton>
			</div>
		</div>
	);
};

export default Toolbar;
