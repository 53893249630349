import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { WorkformLayout, LongArrow } from '@manakin/app-core';
import { FeedbackBox } from '@manakin/app-workforms';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import { styles } from './styles';
import { ImageWithFocusPoint } from '@manakin/app-core';
import { withTranslation } from 'react-i18next';

class ImageAndTextMPC extends React.PureComponent {
	handleClick = (index) => {
		const done = this.props.data.done ? this.props.data.done : false;
		if (this.props.onChange && !done) this.props.onChange(index);
	};

	handleSubmit = () => {
		if (this.props.onSubmit) this.props.onSubmit();
	};

	render() {
		const { classes, data, onFinished, direction = 'default', disabled = false, t, saving } = this.props;
		const { answers = [], done } = data;

		return (
			<WorkformLayout
				question={data.question || ''}
				direction={direction}
				instruction={data.introduction || t('app.workforms.image-and-text-mpc.introduction')}
				loading={false}
				saving={saving}
				renderAnswers={() => (
					<div className={classes.wrapper}>
						<div className={classes.answersRoot}>
							{answers &&
								answers.map((answer, i) => (
									<div
										key={answer.id}
										className={classNames(classes.card, {
											[classes.correct]: done && !answer.selected && answer.correct,
											[classes.disabled]: done && !answer.correct && !answer.selected,
											[classes.selected]: answer.selected,
										})}
										onClick={() => this.handleClick(i)}
									>
										<div
											className={classNames(classes.innerCard, {
												[classes.active]: answer.selected,
												[classes.disabled]: done && !answer.correct && !answer.selected,
											})}
										>
											<div className={classes.image}>
												<ImageWithFocusPoint asset={answer.image} className={classes.backgroundImage} />
											</div>
										</div>
										<div className={classNames(classes.answerTitleContainer, { [classes.active]: answer.selected })}>
											<p className={classes.answerTitle}>{answer.title}</p>
										</div>
									</div>
								))}
						</div>
						<div className={classes.buttons}>
							{!data.feedback ? (
								<Button
									variant="contained"
									color="primary"
									fullWidth
									className={classNames(classes.button, {
										[classes.hide]: data.feedback || false,
									})}
									onClick={this.handleSubmit}
									disabled={disabled}
								>
									{data.buttonText || t('common.check-answer')}
									<LongArrow className={classes.longArrowRoot} />
								</Button>
							) : (
								<div
									className={classNames(classes.feedback, {
										[classes.activeFeedback]: data.feedback,
									})}
								>
									<FeedbackBox data={data} onFinished={onFinished} outlined={true} />
								</div>
							)}
						</div>
					</div>
				)}
			/>
		);
	}
}

export default compose(withStyles(styles, { name: 'AppImageAndTextMPC' }), withTranslation())(ImageAndTextMPC);
