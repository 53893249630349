'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Messages = require('./Messages');

var _Messages2 = _interopRequireDefault(_Messages);

var _graphql = require('../graphql');

var _graphql2 = require('./graphql');

var _reactApollo = require('react-apollo');

var _recompose = require('recompose');

var _DeleteMessageDialog = require('./DeleteMessageDialog');

var _DeleteMessageDialog2 = _interopRequireDefault(_DeleteMessageDialog);

var _actions = require('@manakin/core/actions');

var _reactRedux = require('react-redux');

var _actions2 = require('@manakin/app-core/actions');

var _reactI18next = require('react-i18next');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var MessagesContainer = function (_React$PureComponent) {
    _inherits(MessagesContainer, _React$PureComponent);

    function MessagesContainer() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, MessagesContainer);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = MessagesContainer.__proto__ || Object.getPrototypeOf(MessagesContainer)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
            panel: null
        }, _this.handleChange = function (panel) {
            _this.props.onUpdate({ id: panel, read: true }).then(function (result) {
                _this.props.data.refetch();
            });
        }, _this.handleDeleteDialog = function (panel) {
            _this.setState({ panel: panel }, function () {
                _this.props.onOpenDialog('appDeleteMessageDialog');
            });
        }, _this.handleDelete = function () {
            var _this$props = _this.props,
                t = _this$props.t,
                client = _this$props.client,
                processSuccess = _this$props.processSuccess,
                data = _this$props.data;


            client.mutate({
                mutation: _graphql2.GQL_DELETE_MESSAGE,
                variables: {
                    id: _this.state.panel
                }
            }).then(function () {
                processSuccess(t);
                data.refetch();
            });
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(MessagesContainer, [{
        key: 'componentDidMount',
        value: function componentDidMount() {
            var _props = this.props,
                onSetAppBarColor = _props.onSetAppBarColor,
                _props$config = _props.config,
                config = _props$config === undefined ? {} : _props$config;
            var _config$customColors = config.customColors,
                customColors = _config$customColors === undefined ? {} : _config$customColors;

            onSetAppBarColor({
                backgroundColor: customColors.accountAppBar || 'transparent',
                color: customColors.accountAppBarColor || 'secondary',
                path: location.pathname
            });
        }
    }, {
        key: 'render',
        value: function render() {
            var data = this.props.data;
            var _data$currentMessages = data.currentMessagesReceived,
                currentMessagesReceived = _data$currentMessages === undefined ? {} : _data$currentMessages;


            return _react2.default.createElement(
                'div',
                null,
                _react2.default.createElement(_DeleteMessageDialog2.default, { onDelete: this.handleDelete }),
                _react2.default.createElement(_Messages2.default, _extends({}, currentMessagesReceived, {
                    onChange: this.handleChange,
                    onDelete: this.handleDeleteDialog
                }))
            );
        }
    }]);

    return MessagesContainer;
}(_react2.default.PureComponent);

exports.default = (0, _recompose.compose)((0, _reactApollo.graphql)(_graphql.GQL_FETCH_MESSAGES), (0, _reactApollo.graphql)(_graphql2.GQL_UPDATE_MESSAGE), _reactApollo.withApollo, (0, _reactI18next.withTranslation)(), (0, _recompose.withHandlers)({
    onUpdate: function onUpdate(_ref2) {
        var mutate = _ref2.mutate;
        return function (event) {
            return mutate({
                variables: _extends({}, event)
            });
        };
    }
}), (0, _reactRedux.connect)(function (state) {
    return {
        config: state.config
    };
}, function (dispatch) {
    return {
        onSetAppBarColor: function onSetAppBarColor(color) {
            return dispatch((0, _actions2.setAppBarBackgroundColor)(color));
        },
        onOpenDialog: function onOpenDialog(data) {
            return dispatch((0, _actions.openDialog)(data));
        },
        processSuccess: function processSuccess(t) {
            dispatch((0, _actions.showSnackbarMessage)({
                text: t("app.account.messages.delete-success"),
                variant: 'success'
            }));
        }
    };
}))(MessagesContainer);