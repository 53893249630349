"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _appCore = require("@manakin/app-core");

var _lib = require("@manakin/app-core/lib");

var _selectors = require("./selectors");

var _index = require("./styles/index.js");

var _index2 = _interopRequireDefault(_index);

var _reactScroll = require("react-scroll");

var _reactRedux = require("react-redux");

var _reactHooks = require("@apollo/react-hooks");

var _graphql = require("../graphql");

var _core = require("@manakin/core");

var _Typography = require("@material-ui/core/Typography");

var _Typography2 = _interopRequireDefault(_Typography);

var _reactI18next = require("react-i18next");

var _contexts = require("@manakin/core/contexts");

var _selectors2 = require("@manakin/app-core/ProgramsDropdown/selectors");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var Boxes = function Boxes(props) {
	//selectors
	var listView = _lib.isMobile || (0, _reactRedux.useSelector)(function (state) {
		return (0, _selectors.isListView)(state);
	});
	var config = (0, _reactRedux.useSelector)(function (state) {
		return state.config;
	}) || {};
	var programId = (0, _reactRedux.useSelector)(function (state) {
		return (0, _selectors2.getProgram)(state);
	});
	//props
	var classes = (0, _index2.default)();

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var data = props.data,
	    _props$percentage = props.percentage,
	    percentage = _props$percentage === undefined ? {} : _props$percentage,
	    _props$listViewOnly = props.listViewOnly,
	    listViewOnly = _props$listViewOnly === undefined ? false : _props$listViewOnly,
	    _props$resume = props.resume,
	    resume = _props$resume === undefined ? {} : _props$resume,
	    _props$option = props.option,
	    option = _props$option === undefined ? false : _props$option;
	var _config$pages = config.pages,
	    pages = _config$pages === undefined ? {} : _config$pages,
	    _config$general = config.general,
	    general = _config$general === undefined ? {} : _config$general;

	var progressionDashboard = general.progressionDashboard || false;
	var _pages$dashboard = pages.dashboard,
	    dashboard = _pages$dashboard === undefined ? {} : _pages$dashboard;

	var doScroll = dashboard.scrollTo || false;
	var boxes = data.boxes || [];
	var _milestones = data.milestones || [];
	var _boxes = [];
	var newest = null;

	//useState

	var _useState = (0, _react.useState)(false),
	    _useState2 = _slicedToArray(_useState, 2),
	    openConditionDialog = _useState2[0],
	    setOpenConditionDialog = _useState2[1];

	var _useState3 = (0, _react.useState)(null),
	    _useState4 = _slicedToArray(_useState3, 2),
	    boxCondition = _useState4[0],
	    setBoxCondition = _useState4[1];

	var boxContextValue = { boxCondition: boxCondition, setBoxCondition: setBoxCondition };

	//queries

	var _useQuery = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_BOX_CONDITIONS_BY_PROGRAM_ID, {
		variables: { programId: programId }
	}),
	    _useQuery$data = _useQuery.data,
	    boxConditionData = _useQuery$data === undefined ? {} : _useQuery$data,
	    boxConditionLoading = _useQuery.loading;

	//props and functions


	var _ref = boxConditionData || {},
	    _ref$boxConditionsByP = _ref.boxConditionsByProgramId,
	    boxConditionsByProgramId = _ref$boxConditionsByP === undefined ? [] : _ref$boxConditionsByP;

	boxes.forEach(function (e) {
		return e.forEach(function (box) {
			_boxes.push(box);
			box.percentage = percentage[box.id];
			box.new = false;
			box.condition = boxConditionsByProgramId.find(function (boxCondition) {
				return boxCondition.boxId === box.id;
			}) || null;
			box.locked = boxConditionsByProgramId.some(function (boxCondition) {
				return boxCondition.boxId === box.id;
			});
		});
	});

	if (_boxes.find(function (e) {
		return e.percentage !== 100;
	})) {
		newest = _boxes.find(function (e) {
			return e.percentage !== 100;
		}).id;
	}

	var milestones = {};
	_milestones && _milestones.forEach(function (item) {
		milestones[item.box.id] = {
			id: item.box.id,
			text: item.text
		};
	});

	//effect hooks
	(0, _react.useEffect)(function () {
		if (!option || option != "no-scroll") {
			if (doScroll) {
				setTimeout(function () {
					_reactScroll.scroller.scrollTo("current", {
						duration: 400,
						offset: -200,
						smooth: "easeInOutQuint"
					});
				}, 100);
			}
		}
	}, [option]);

	var onConditionDialogOpen = function onConditionDialogOpen() {
		setOpenConditionDialog(true);
	};

	var handleConditionDialogClick = function handleConditionDialogClick() {
		setOpenConditionDialog(false);
	};

	return _react2.default.createElement(
		"div",
		{
			className: (0, _classnames2.default)(classes.wrapper, _defineProperty({}, classes.smallWrapper, listView || listViewOnly))
		},
		_react2.default.createElement(
			_contexts.BoxContext.Provider,
			{ value: boxContextValue },
			boxes.length > 0 && boxes.map(function (_item, i) {
				return _react2.default.createElement(
					_react2.default.Fragment,
					{ key: i },
					_item[0] && _item[0].id && _react2.default.createElement(
						"div",
						{ key: _item[0].id },
						milestones[_item[0].id] && _react2.default.createElement(
							"div",
							{ className: classes.milestone },
							_react2.default.createElement(
								"span",
								{
									className: classes.innerMileStone
								},
								milestones[_item[0].id].text
							)
						),
						_item.map(function (item, idx) {
							return _react2.default.createElement(
								_react2.default.Fragment,
								{ key: idx },
								item && item.id && _react2.default.createElement(
									_reactScroll.Element,
									{
										name: resume && resume.id && item.id === resume.id ? "current" : "null"
									},
									_react2.default.createElement(_appCore.BoxCard, {
										key: item.id,
										tags: item.tags || [],
										themes: item.themes || [],
										content: item,
										locked: item.locked,
										boxCondition: item.condition,
										onConditionDialogOpen: onConditionDialogOpen,
										listView: listView || listViewOnly,
										progressionDashboard: progressionDashboard && i === 0 && !listView,
										idx: idx + i,
										percentage: percentage[item.id]
									})
								)
							);
						})
					)
				);
			}),
			_react2.default.createElement(
				_core.SimpleDialog,
				{
					open: openConditionDialog,
					onButtonClick: handleConditionDialogClick,
					title: t("app-core.boxes.condition-dialog.title"),
					actionButtonLabel: t("common.ok")
				},
				boxCondition && boxCondition.blockedMessage && _react2.default.createElement(
					_Typography2.default,
					null,
					boxCondition.blockedMessage
				)
			)
		)
	);
};

exports.default = Boxes;