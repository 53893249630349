'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styles = require('@material-ui/core/styles');

var _appCore = require('@manakin/app-core');

var _Done = require('@material-ui/icons/Done');

var _Done2 = _interopRequireDefault(_Done);

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _reactRouterDom = require('react-router-dom');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _graphql = require('./graphql');

var _reactApollo = require('react-apollo');

var _recompose = require('recompose');

var _reactI18next = require('react-i18next');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
    return {
        root: _defineProperty({
            backgroundColor: theme.manakin.defaultBackgroundColor[500],
            width: '100%',
            minHeight: '10rem',
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            opacity: 0,
            transform: 'translateX(-20px)',
            position: 'relative',
            padding: '1.6rem'
        }, theme.breakpoints.up('md'), {
            minHeight: '15rem'
        }),
        show: {
            opacity: 1,
            transform: 'translateX(0)'
        },
        fontStyle: {
            margin: 0,
            color: theme.palette.getContrastText(theme.manakin.defaultBackgroundColor[500])
        },
        themeIcon: _defineProperty({
            width: '6rem',
            height: '6rem',
            margin: '0 .4rem',
            display: 'none'
        }, theme.breakpoints.up('md'), _defineProperty({
            display: 'block',
            margin: '0 2rem'
        }, 'margin', '0 5rem')),
        doneIcon: _defineProperty({
            position: 'absolute',
            backgroundColor: theme.palette.secondary[500],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '3rem',
            width: '3rem',
            color: theme.palette.getContrastText(theme.palette.secondary[500]),
            right: '1rem',
            top: '50%',
            transform: 'translateY(-50%)',
            borderRadius: '100%'
        }, theme.breakpoints.up('md'), {
            right: '3rem',
            width: '6rem',
            height: '6rem'
        }),
        iconRoot: {
            width: '1.5rem',
            height: '1.5rem'
        }
    };
};

var EntryTestCard = function EntryTestCard(props) {
    var classes = props.classes,
        idx = props.idx,
        show = props.show,
        item = props.item,
        data = props.data;
    var _data$entryTest = data.entryTest,
        entryTest = _data$entryTest === undefined ? {} : _data$entryTest;

    var _useTranslation = (0, _reactI18next.useTranslation)(),
        t = _useTranslation.t;

    return _react2.default.createElement(
        _reactRouterDom.Link,
        {
            className: (0, _classnames2.default)(classes.root, _defineProperty({}, classes.show, show)),
            style: {
                transition: 'transform .5s .' + (idx + 1) + 's, opacity .5s .' + (idx + 2) + 's'
            },
            to: '/entryTest/' + item.id
        },
        _react2.default.createElement(_appCore.ThemeIcon, { variant: 'brains', className: classes.themeIcon }),
        _react2.default.createElement(
            'div',
            { className: classes.content },
            _react2.default.createElement(
                _Typography2.default,
                { variant: 'h6', className: classes.fontStyle },
                item.title || t("common.element-types.entry-test")
            ),
            _react2.default.createElement(
                _Typography2.default,
                { variant: 'body1', className: classes.fontStyle },
                entryTest.preTitle || ''
            )
        ),
        item.done && _react2.default.createElement(
            'div',
            { className: classes.doneIcon },
            _react2.default.createElement(_Done2.default, { classes: { root: classes.iconRoot } })
        )
    );
};

exports.default = (0, _recompose.compose)((0, _styles.withStyles)(styles, { name: 'AppEntryTestCard' }), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_ENTRY_TEST, {
    options: function options(props) {
        return {
            variables: { id: props.item.id }
        };
    }
}))(EntryTestCard);