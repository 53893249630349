import React from 'react';
import { makeStyles } from '@material-ui/core';
import classNames from "classnames";
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles((theme) => ({
    root: {
        background: props => `${props.color || theme.manakin.warningColor[500]}26`,
        padding: theme.spacing(2),
        borderRadius: 4,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%"
    },
    body: {
        marginBottom: 0,
        opacity: .87
    },
    icon: {
        marginRight: theme.spacing(2),
        color: props => props.color || theme.manakin.warningColor[500],
        width: 32,
        height: 32
    }
}));

const WarningBox = (props) => {
    const { className: classNameProp, message } = props;
    const classes = useStyles(props);

    return (
        <div className={classNames(classes.root, classNameProp)}>
            <WarningIcon className={classes.icon}/>
            <Typography
                variant="body1"
                className={classes.body}
            >
                {message}
            </Typography>
        </div>
    );
};

export default WarningBox;