import gql from 'graphql-tag';
import { fileAsset, appAssetWithThumbnail } from '@manakin/app-core/graphQlHelpers';

export const GQL_FETCH_CASE = gql`
    query($id: ID!) {
        case(id: $id) {
            id
            preTitle
            styleSheet {
                id
                indicationColor
                defaultWorkformColor
                primaryWorkformColor
                secondaryWorkformColor
                themeColor
            }
            image {
                ${appAssetWithThumbnail}
            }
            workforms {
                id
                type
            }
            introTitle
            introText
            introActionTitle
        }
    }
`;

export const GQL_CREATE_ELEMENT_RESULT = gql`
    mutation(
        $user: ID!
        $program: ID!
        $box: ID!
        $element: ID!
        $finished: Boolean
    ) {
        createElementResult(
            input: {
                user: $user
                program: $program
                box: $box
                element: $element
                finished: $finished
            }
        ) {
            result {
                finished
            }
        }
    }
`;

export const GQL_FETCH_GLOBAL_SETTINGS = gql`
    query {
        settings {
            settings {
                name
                value
                file {
                    ${fileAsset}
                }
            }
        }
    }
`;

export const GQL_FETCH_STYLESHEET = gql`
	query styleSheet($id: ID!) {
		styleSheet(id: $id) {
			id
			title
			description
			customStyle
			indicationColor
			defaultWorkformColor
			primaryWorkformColor
			secondaryWorkformColor
			themeColor
		}
	}
`;
