import React from "react";
import {
	AccessControl,
	StatisticsBox,
	StatisticsBox2,
} from "@manakin/app-core";
import Header from "./Header";
import { teacherRoles, schoolManagerRole } from '@manakin/app-core/lib';

const TeacherHeader = (props) => {
	const { secondStatisticsBox } = props;

	return (
		<React.Fragment>
			<Header {...props}>
				{secondStatisticsBox ? (
					<React.Fragment>
						<AccessControl role={[schoolManagerRole]}>
							<StatisticsBox2 roles={[schoolManagerRole]} />
						</AccessControl>
						<AccessControl
							role={teacherRoles}
							not={[schoolManagerRole]}
						>
							<StatisticsBox2 roles={teacherRoles} />
						</AccessControl>
					</React.Fragment>
				) : (
					<React.Fragment>
						<AccessControl role={[schoolManagerRole]}>
							<StatisticsBox roles={[schoolManagerRole]} />
						</AccessControl>
						<AccessControl
							role={teacherRoles}
							not={[schoolManagerRole]}
						>
							<StatisticsBox roles={teacherRoles} />
						</AccessControl>
					</React.Fragment>
				)}
			</Header>
		</React.Fragment>
	);
};

export default TeacherHeader;
