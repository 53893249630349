import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PageLayout from '../PageLayout';
import { compose } from 'recompose';
import { Loader } from '@manakin/app-core';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router';

const styles = (theme) => ({
    wrapper: {
        ...theme.manakin.smallWrapper,
        maxWidth: '550px',
        textAlign: 'center',
        minHeight: '100vh',
    },
});

const EtosDone = (props) => {
    const { classes } = props;
    const handleClick = () => {
        if (props.onLogout) props.onLogout();
    };

    return (
        <PageLayout
            title="Je registratie is gelukt! "
            intro={'Je kunt nu verder naar je Dashboard'}
        >
            <div className={classes.root}>
                <div className={classes.wrapper}>
                    <Button
                        onClick={handleClick}
                        color="secondary"
                        variant="contained"
                    >
                        Naar Dashboard
                    </Button>
                </div>
            </div>
        </PageLayout>
    );
};

export default compose(withStyles(styles), withRouter)(EtosDone);
