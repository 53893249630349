"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _AccountWrapper = require("../AccountWrapper");

var _AccountWrapper2 = _interopRequireDefault(_AccountWrapper);

var _graphql = require("./graphql");

var _reactHooks = require("@apollo/react-hooks");

var _appCore = require("@manakin/app-core");

var _BookTile = require("./BookTile");

var _BookTile2 = _interopRequireDefault(_BookTile);

var _reactRouterDom = require("react-router-dom");

var _reactI18next = require("react-i18next");

var _lib = require("@manakin/app-core/lib");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var Books = function Books(props) {
	var history = (0, _reactRouterDom.useHistory)();

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var _useQuery = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_BOOKS),
	    data = _useQuery.data,
	    loading = _useQuery.loading;

	var _ref = data || {},
	    bookData = _ref.books;

	var _ref2 = bookData || {},
	    _ref2$books = _ref2.books,
	    books = _ref2$books === undefined ? [] : _ref2$books;

	var handleClick = function handleClick(book) {
		history.push("/account/books/" + book.id);
	};

	if (loading) _react2.default.createElement(_appCore.Loader, null);
	return _react2.default.createElement(
		_react2.default.Fragment,
		null,
		_react2.default.createElement(
			_appCore.AccessControl,
			{ role: [].concat(_toConsumableArray(_lib.teacherRoles), [_lib.schoolManagerRole]) },
			_react2.default.createElement(
				_AccountWrapper2.default,
				{
					title: t("app.account.books.title"),
					wrapper: "medium",
					introduction: t("app.account.books.intro")
				},
				books.map(function (book) {
					return _react2.default.createElement(_BookTile2.default, {
						data: book,
						onClick: function onClick() {
							return handleClick(book);
						}
					});
				})
			)
		),
		_react2.default.createElement(
			_appCore.AccessControl,
			{ not: [].concat(_toConsumableArray(_lib.teacherRoles), [_lib.schoolManagerRole]) },
			t("common.page-not-available")
		)
	);
};

exports.default = Books;