'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _core = require('@material-ui/core');

var _PlayArrow = require('@material-ui/icons/PlayArrow');

var _PlayArrow2 = _interopRequireDefault(_PlayArrow);

var _Pause = require('@material-ui/icons/Pause');

var _Pause2 = _interopRequireDefault(_Pause);

var _Fullscreen = require('@material-ui/icons/Fullscreen');

var _Fullscreen2 = _interopRequireDefault(_Fullscreen);

var _Subtitles = require('@material-ui/icons/Subtitles');

var _Subtitles2 = _interopRequireDefault(_Subtitles);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _styles = require('@material-ui/core/styles');

var _styles2 = require('./styles');

var _styles3 = require('@material-ui/styles');

var _useMediaQuery = require('@material-ui/core/useMediaQuery');

var _useMediaQuery2 = _interopRequireDefault(_useMediaQuery);

var _screenfull = require('screenfull');

var _screenfull2 = _interopRequireDefault(_screenfull);

var _reactDom = require('react-dom');

var _core2 = require('@manakin/core');

var _LoadImage = require('../LoadImage');

var _LoadImage2 = _interopRequireDefault(_LoadImage);

var _Player = require('./Player');

var _Player2 = _interopRequireDefault(_Player);

var _reactHooks = require('@apollo/react-hooks');

var _graphql = require('./graphql');

var _constants = require('@manakin/core/lib/constants');

var _hooks = require('@manakin/hooks');

var _reactI18next = require('react-i18next');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var VideoPlayer = function VideoPlayer(props) {
    var _classNames;

    var player = (0, _react.useRef)(null);
    var playerContainer = (0, _react.useRef)(null);
    var mounted = (0, _react.useRef)(false);

    var _useTranslation = (0, _reactI18next.useTranslation)(),
        t = _useTranslation.t;

    var videoId = props.videoId,
        classes = props.classes,
        _props$showSkipButton = props.showSkipButton,
        showSkipButton = _props$showSkipButton === undefined ? false : _props$showSkipButton,
        _props$playing = props.playing,
        _playing = _props$playing === undefined ? true : _props$playing,
        _props$withControls = props.withControls,
        withControls = _props$withControls === undefined ? true : _props$withControls,
        hoverState = props.hoverState,
        _props$overflow = props.overflow,
        overflow = _props$overflow === undefined ? false : _props$overflow,
        _props$fullWidth = props.fullWidth,
        fullWidth = _props$fullWidth === undefined ? false : _props$fullWidth,
        _props$placeholderIma = props.placeholderImage,
        placeholderImage = _props$placeholderIma === undefined ? null : _props$placeholderIma,
        _props$loop = props.loop,
        loop = _props$loop === undefined ? false : _props$loop,
        _props$volume = props.volume,
        volume = _props$volume === undefined ? null : _props$volume,
        subtitle = props.subtitle,
        max = props.max;

    var _useQuery = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_SETTINGS),
        _useQuery$data = _useQuery.data,
        settingsData = _useQuery$data === undefined ? {} : _useQuery$data;

    var subtitlesEnabled = (0, _hooks.useSetting)(settingsData.settings, _constants.SettingsKeys.SUBTITLES_ENABLED);

    var theme = (0, _styles3.useTheme)();
    var matchesMD = (0, _useMediaQuery2.default)(theme.breakpoints.down('md'));

    //state hooks

    var _useState = (0, _react.useState)(matchesMD ? false : _playing),
        _useState2 = _slicedToArray(_useState, 2),
        playing = _useState2[0],
        setPlaying = _useState2[1];

    var _useState3 = (0, _react.useState)(0),
        _useState4 = _slicedToArray(_useState3, 2),
        played = _useState4[0],
        setPlayed = _useState4[1];

    var _useState5 = (0, _react.useState)(false),
        _useState6 = _slicedToArray(_useState5, 2),
        controls = _useState6[0],
        setControls = _useState6[1];

    var _useState7 = (0, _react.useState)(false),
        _useState8 = _slicedToArray(_useState7, 2),
        seeking = _useState8[0],
        setSeeking = _useState8[1];

    var _useState9 = (0, _react.useState)(false),
        _useState10 = _slicedToArray(_useState9, 2),
        fullScreen = _useState10[0],
        setFullscreen = _useState10[1];

    var _useState11 = (0, _react.useState)(playing ? false : true),
        _useState12 = _slicedToArray(_useState11, 2),
        hideLoader = _useState12[0],
        setHideLoader = _useState12[1];

    var _useState13 = (0, _react.useState)(false),
        _useState14 = _slicedToArray(_useState13, 2),
        ready = _useState14[0],
        setReady = _useState14[1];

    var _useState15 = (0, _react.useState)(subtitle ? true : false),
        _useState16 = _slicedToArray(_useState15, 2),
        showSubtitles = _useState16[0],
        setShowSubtitles = _useState16[1];

    var _useState17 = (0, _react.useState)(false),
        _useState18 = _slicedToArray(_useState17, 2),
        loading = _useState18[0],
        setLoading = _useState18[1];

    (0, _react.useEffect)(function () {
        var shouldShowSubtitles = void 0;
        if (subtitlesEnabled !== null && subtitlesEnabled !== undefined) {
            if (subtitlesEnabled === "true") {
                // Only show subtitles if enabled AND subtitle language is set up
                shouldShowSubtitles = !!subtitle;
            } else {
                shouldShowSubtitles = false;
            }
        } else {
            // No subtitle configuration yet, only show if subtitle language is set up
            shouldShowSubtitles = !!subtitle;
        }

        setShowSubtitles(shouldShowSubtitles);
    }, [subtitlesEnabled]);

    (0, _react.useEffect)(function () {
        setPlaying(matchesMD ? false : _playing);
        setHideLoader(matchesMD ? true : !_playing);
    }, [matchesMD]);

    (0, _react.useEffect)(function () {
        mounted.current = true;

        return function () {
            return mounted.current = false;
        };
    });

    (0, _react.useEffect)(function () {
        if (played) {
            setPlaying(true);
            player.current.seekTo(parseFloat(played));
        }
        setTimeout(function () {
            if (mounted.current) {
                setLoading(false);
            }
        }, 1550);
    }, [player.current]);

    //effect hooks
    (0, _react.useEffect)(function () {
        if (controls) {
            setTimeout(function () {
                if (mounted.current) {
                    setControls(false);
                }
            }, 1000);
        }
    }, [controls]);

    (0, _react.useEffect)(function () {
        if (fullScreen) {
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
            document.documentElement.style.overflow = 'unset';
        }
    }, [fullScreen]);

    (0, _react.useEffect)(function () {
        if (hoverState && !playing && !matchesMD) {
            setPlaying(true);
        } else if (hoverState != undefined) {
            setPlaying(false);
        }
    }, [hoverState]);

    //functions
    var onProgress = function onProgress(state) {
        setPlayed(state.played);

        if (max && state.playedSeconds >= max) {
            if (loop) {
                player.current.seekTo(0);
            } else {
                setPlaying(false);
            }
        }
        if (!seeking) setSeeking(state);
    };

    var onReady = function onReady(props) {
        setHideLoader(true);
    };

    var handleMouseMove = function handleMouseMove(event) {
        if (!controls && !matchesMD && withControls) {
            setControls(true);
        }
    };

    var playPause = function playPause(e) {
        e.stopPropagation();
        setPlaying(!playing);
    };

    var handleSeekChange = function handleSeekChange(e) {
        setPlayed(parseFloat(e.target.value));
    };

    var handleSeekMouseUp = function handleSeekMouseUp(e) {
        setSeeking(false);
        setPlaying(true);
        player.current.seekTo(parseFloat(e.target.value));
    };

    var handleSeekMouseDown = function handleSeekMouseDown(e) {
        setSeeking(true);
    };

    var handleFullscreen = function handleFullscreen(e) {
        _screenfull2.default.toggle((0, _reactDom.findDOMNode)(playerContainer.current));
        setFullscreen(!fullScreen);
    };

    var handleEnded = function handleEnded() {
        if (props.handleEnded) props.handleEnded();
        _screenfull2.default.exit((0, _reactDom.findDOMNode)(playerContainer.current));
        setFullscreen(false);
        setPlaying(false);
    };

    var handleReady = function handleReady() {
        setReady(true);
    };

    var handleSubtitles = function handleSubtitles() {
        var time = player.current.getCurrentTime();

        if (time) {
            setReady(false);
            setLoading(true);
            player.current = null;
            setShowSubtitles(!showSubtitles);
            setPlayed(time);
        }
    };

    _screenfull2.default.on && _screenfull2.default.on('change', function () {
        _screenfull2.default.isFullscreen ? setFullscreen(true) : setFullscreen(false);
    });

    return _react2.default.createElement(
        'div',
        {
            ref: playerContainer,
            className: (0, _classnames2.default)(classes.videoContainer, (_classNames = {}, _defineProperty(_classNames, classes.fullScreen, fullScreen && !matchesMD), _defineProperty(_classNames, classes.overflow, overflow), _defineProperty(_classNames, classes.fullWidth, fullWidth), _classNames)),
            onMouseMove: handleMouseMove
        },
        _react2.default.createElement(
            'div',
            {
                className: (0, _classnames2.default)(classes.loader, _defineProperty({}, classes.hide, hideLoader))
            },
            _react2.default.createElement(_core2.Loader, null)
        ),
        showSkipButton && _react2.default.createElement(
            _core.Button,
            {
                className: (0, _classnames2.default)(classes.button, _defineProperty({}, classes.showButton, controls && withControls)),
                variant: 'contained',
                color: 'primary',
                onClick: handleEnded
            },
            t("common.skip")
        ),
        withControls && _react2.default.createElement(
            'div',
            {
                onClick: playPause,
                className: (0, _classnames2.default)(classes.playButton, _defineProperty({}, classes.zoomOut, playing), _defineProperty({}, classes.zoomIn, !playing))
            },
            _react2.default.createElement(
                'div',
                {
                    className: (0, _classnames2.default)(classes.icon, _defineProperty({}, classes.show, !playing))
                },
                _react2.default.createElement(_PlayArrow2.default, { classes: { root: classes.playButtonIcon } })
            ),
            _react2.default.createElement(
                'div',
                {
                    className: (0, _classnames2.default)(classes.icon, _defineProperty({}, classes.show, playing))
                },
                _react2.default.createElement(_Pause2.default, { classes: { root: classes.playButtonIcon } })
            )
        ),
        showSubtitles && _react2.default.createElement(
            'div',
            { className: classes.playerContainer, onClick: playPause },
            _react2.default.createElement(_Player2.default, {
                ref: player,
                className: (0, _classnames2.default)(classes.videoRoot, _defineProperty({}, classes.ready, ready)),
                url: 'https://vimeo.com/' + videoId,
                playing: playing,
                onEnded: handleEnded,
                onProgress: onProgress,
                onStart: onReady,
                loop: loop,
                volume: volume,
                onReady: handleReady,
                textTrack: subtitle
            })
        ),
        !showSubtitles && _react2.default.createElement(
            'div',
            { className: classes.playerContainer, onClick: playPause },
            _react2.default.createElement(_Player2.default, {
                ref: player,
                className: (0, _classnames2.default)(classes.videoRoot, _defineProperty({}, classes.ready, ready)),
                url: 'https://vimeo.com/' + videoId,
                playing: playing,
                onEnded: handleEnded,
                onProgress: onProgress,
                onStart: onReady,
                loop: loop,
                volume: volume,
                onReady: handleReady
            })
        ),
        placeholderImage && !playing && _react2.default.createElement(
            'div',
            { className: classes.backgroundImage },
            _react2.default.createElement(_LoadImage2.default, {
                src: placeholderImage,
                placeholder: placeholderImage
            })
        ),
        (!ready || loading) && _react2.default.createElement(
            'div',
            { className: classes.loadingState },
            _react2.default.createElement(_core2.Loader, null)
        ),
        withControls && _react2.default.createElement(
            'div',
            {
                className: (0, _classnames2.default)(classes.controls, _defineProperty({}, classes.showControls, controls && withControls), _defineProperty({}, classes.showControls, !playing))
            },
            _react2.default.createElement(
                'div',
                {
                    className: classes.PlayPauseButton,
                    onClick: playPause
                },
                _react2.default.createElement(
                    'div',
                    {
                        className: (0, _classnames2.default)(classes.icon, _defineProperty({}, classes.show, !playing))
                    },
                    _react2.default.createElement(_PlayArrow2.default, null)
                ),
                _react2.default.createElement(
                    'div',
                    {
                        className: (0, _classnames2.default)(classes.icon, _defineProperty({}, classes.show, playing))
                    },
                    _react2.default.createElement(_Pause2.default, null)
                )
            ),
            _react2.default.createElement(
                'div',
                { className: classes.progressBar },
                _react2.default.createElement('input', {
                    type: 'range',
                    min: 0,
                    max: 1,
                    step: 'any',
                    value: played,
                    onMouseDown: handleSeekMouseDown,
                    onChange: handleSeekChange,
                    onMouseUp: handleSeekMouseUp,
                    onTouchEnd: handleSeekMouseUp
                })
            ),
            subtitle && _react2.default.createElement(
                'div',
                {
                    className: (0, _classnames2.default)(classes.icon, classes.show, _defineProperty({}, classes.activeIcon, showSubtitles)),
                    onClick: handleSubtitles
                },
                _react2.default.createElement(_Subtitles2.default, null)
            ),
            !matchesMD && _react2.default.createElement(
                'div',
                {
                    className: (0, _classnames2.default)(classes.icon, classes.show),
                    onClick: handleFullscreen
                },
                _react2.default.createElement(_Fullscreen2.default, null)
            )
        )
    );
};

exports.default = (0, _styles.withStyles)(_styles2.styles, { name: 'AppVideoPlayer' })(VideoPlayer);