"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _core = require("@material-ui/core");

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _Typography = require("@material-ui/core/Typography");

var _Typography2 = _interopRequireDefault(_Typography);

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var useStyles = (0, _core.makeStyles)(function (theme) {
	return {
		chip: _defineProperty({
			display: "inline-block",
			borderRadius: "100px",
			padding: theme.spacing(0.5),
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
			border: "2px solid " + theme.manakin.defaultBorderColor[200],
			marginRight: theme.spacing(2),
			marginLeft: theme.spacing(2)
		}, theme.breakpoints.down("md"), {
			marginRight: theme.spacing()
		}),
		chipTitle: {
			marginBottom: 0,
			fontSize: "1.5rem",
			fontWeight: "bold"
		},
		good: {
			border: "2px solid " + theme.manakin.successColor[500],
			"& > div": {
				color: theme.manakin.successColor[500]
			}
		},
		sufficient: {
			border: "2px solid " + theme.manakin.warningColor[500],
			"& > div": {
				color: theme.manakin.warningColor[500]
			}
		},
		insufficient: {
			border: "2px solid " + theme.manakin.wrongColor[500],
			"& > div": {
				color: theme.manakin.wrongColor[500]
			}
		},
		disabled: {
			cursor: "default"
		}
	};
});

var Chip = function Chip(props) {
	var _classNames;

	var ranking = props.ranking,
	    className = props.className;

	var classes = useStyles();

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var mapRankingToRankingText = function mapRankingToRankingText() {
		switch (ranking) {
			case "GOOD":
				return t("app.rating-request.rating.good");
			case "SUFFICIENT":
				return t("app.rating-request.rating.sufficient");
			case "INSUFFICIENT":
				return t("app.rating-request.rating.insufficient");
			default:
				return t("app.rating-request.rating.requested");
		}
	};

	return _react2.default.createElement(
		"div",
		{
			className: (0, _classnames2.default)(classes.chip, (_classNames = {}, _defineProperty(_classNames, classes.good, ranking === "GOOD"), _defineProperty(_classNames, classes.sufficient, ranking === "SUFFICIENT"), _defineProperty(_classNames, classes.insufficient, ranking === "INSUFFICIENT"), _defineProperty(_classNames, classes.disabled, !ranking), _classNames), className)
		},
		_react2.default.createElement(
			_Typography2.default,
			{
				component: "div",
				variant: "body1",
				className: classes.chipTitle
			},
			mapRankingToRankingText()
		)
	);
};

exports.default = Chip;