import gql from 'graphql-tag';
import { fileAsset } from '@manakin/app-core/graphQlHelpers';

export const GQL_FETCH_APP_USER = gql`
    query appUser($id: ID!) {
        appUser(id: $id) {
            id
            fullName
            school {
                id
                name
            }
        }
    }
`;

export const GQL_FETCH_PROGRAM = gql`
    query program($id: ID!) {
        program(id: $id) {
            id
            boxes {
                id
                name
                elements {
                    id
                    title
                    type
                }
            }
        }
    }
`;

export const GQL_FETCH_ELEMENT_RESULT = gql`
    query elementResult($box: ID!, $program: ID!, $user: ID!, $element: ID!) {
        elementResult(
            box: $box
            program: $program
            user: $user
            element: $element
        ) {
            finished
            rating
            ratingTeacher
            progress
            correct
        }
    }
`;

export const GQL_CREATE_ELEMENT_RESULT = gql`
    mutation createElementResult(
        $user: ID!
        $program: ID!
        $box: ID!
        $element: ID!
        $finished: Boolean
        $rating: String
        $ratingTeacher: String
    ) {
        createElementResult(
            input: {
                user: $user
                program: $program
                box: $box
                element: $element
                finished: $finished
                rating: $rating
                ratingTeacher: $ratingTeacher
            }
        ) {
            result {
                finished
                ratingTeacher
            }
        }
    }
`;

export const GQL_SEND_MESSAGE = gql`
    mutation(
        $title: String!
        $text: String!
        $sender: ID!
        $receivers: [ID]!
        $send: Boolean!
    ) {
        upsertMessageSend(
            input: {
                title: $title
                text: $text
                sender: $sender
                receivers: $receivers
                send: $send
            }
        ) {
            messageSend {
                id
            }
        }
    }
`;

export const GQL_FETCH_SETTINGS = gql`
    query settings {
        settings {
            settings {
                name
                value
                file {
                    ${fileAsset}
                }
            }
        }
    }
`;
