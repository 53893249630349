'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _Done = require('@material-ui/icons/Done');

var _Done2 = _interopRequireDefault(_Done);

var _ArrowForward = require('@material-ui/icons/ArrowForward');

var _ArrowForward2 = _interopRequireDefault(_ArrowForward);

var _Clear = require('@material-ui/icons/Clear');

var _Clear2 = _interopRequireDefault(_Clear);

var _Schedule = require('@material-ui/icons/Schedule');

var _Schedule2 = _interopRequireDefault(_Schedule);

var _ = require('../../');

var _graphql = require('../graphql');

var _core = require('@material-ui/core');

var _reactHooks = require('@apollo/react-hooks');

var _reactRedux = require('react-redux');

var _appCore = require('@manakin/app-core');

var _reactI18next = require('react-i18next');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var Lesson = function Lesson(props) {
	var _classNames, _classNames2, _classNames3, _classNames5;

	var classes = props.classes,
	    item = props.item,
	    disabled = props.disabled,
	    settings = props.settings,
	    elementResult = props.elementResult,
	    tags = props.tags,
	    show = props.show;

	var config = (0, _reactRedux.useSelector)(function (state) {
		return state.config;
	}) || {};
	var _config$elementCardCo = config.elementCardConfig,
	    elementCardConfig = _config$elementCardCo === undefined ? {} : _config$elementCardCo;

	var imageUrl = item.image ? item.image.url : '';
	var placeholder = item.image ? item.image.thumbnailUrl : '';

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var _useQuery = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_LESSON, {
		variables: {
			id: item.id
		}
	}),
	    _useQuery$data = _useQuery.data,
	    data = _useQuery$data === undefined ? {} : _useQuery$data,
	    loading = _useQuery.loading;

	var _data$lesson = data.lesson,
	    lesson = _data$lesson === undefined ? {} : _data$lesson;

	var _ref = data && data.lesson || {},
	    _ref$preTitle = _ref.preTitle,
	    preTitle = _ref$preTitle === undefined ? '' : _ref$preTitle,
	    _ref$prefixColor = _ref.prefixColor,
	    prefixColor = _ref$prefixColor === undefined ? '' : _ref$prefixColor,
	    _ref$titleColor = _ref.titleColor,
	    titleColor = _ref$titleColor === undefined ? '' : _ref$titleColor,
	    _ref$time = _ref.time,
	    time = _ref$time === undefined ? 0 : _ref$time,
	    introTitle = _ref.introTitle,
	    _ref$useTextOverlay = _ref.useTextOverlay,
	    useTextOverlay = _ref$useTextOverlay === undefined ? false : _ref$useTextOverlay;

	var percentage = Math.floor(elementResult ? elementResult.progress || 0 : 0);

	return _react2.default.createElement(
		'div',
		{
			className: (0, _classnames2.default)(classes.root, classes[item.type], (_classNames = {}, _defineProperty(_classNames, classes.fullWidth, item.fullWidth), _defineProperty(_classNames, classes.minHeight, item.type != 'BookCheck'), _defineProperty(_classNames, classes.listView, lesson.compact), _defineProperty(_classNames, classes.disabled, disabled), _defineProperty(_classNames, classes.show, show), _defineProperty(_classNames, classes.overflowRoot, lesson.imageOverflow), _defineProperty(_classNames, classes.useTextOverlay, useTextOverlay), _defineProperty(_classNames, classes.lightTextOverlay, titleColor == 'PRIMARY' || prefixColor == 'PRIMARY'), _classNames)),
			style: {
				transition: 'transform .5s .' + 1 + 's, opacity .5s .' + 1 + 's',
				backgroundColor: lesson.backgroundColor
			}
		},
		_react2.default.createElement(_appCore.Tags, {
			tags: tags,
			className: (0, _classnames2.default)(classes.tags, (_classNames2 = {}, _defineProperty(_classNames2, classes.withIcon, item.done != undefined), _defineProperty(_classNames2, classes.bookcheckTag, item.type === 'BookCheck'), _classNames2))
		}),
		_react2.default.createElement(
			'div',
			{
				className: (0, _classnames2.default)(classes.done, (_classNames3 = {}, _defineProperty(_classNames3, classes.inCorrect, item.done && !item.correct), _defineProperty(_classNames3, classes.continue, !item.done), _classNames3))
			},
			!item.done && !elementCardConfig.noPercentage && percentage > 0 && _react2.default.createElement(
				'div',
				{
					className: (0, _classnames2.default)(classes.procent, _defineProperty({}, classes.hide, item.done))
				},
				'' + (percentage || 0),
				'%'
			),
			item.done && item.correct && _react2.default.createElement(
				'span',
				{ className: classes.doneIcon },
				_react2.default.createElement(_Done2.default, { classes: { root: classes.iconRoot } })
			),
			item.done && !item.correct && _react2.default.createElement(
				'span',
				{ className: classes.doneIcon },
				_react2.default.createElement(_Clear2.default, { classes: { root: classes.iconRoot } })
			),
			!item.done && !(percentage > 0) && _react2.default.createElement(
				'span',
				{ className: classes.doneIcon },
				_react2.default.createElement(_ArrowForward2.default, {
					classes: {
						root: (0, _classnames2.default)(classes.iconRoot, (_classNames5 = {}, _defineProperty(_classNames5, classes.secondaryTitleColor, titleColor == 'SECONDARY'), _defineProperty(_classNames5, classes.primaryTitleColor, titleColor == 'PRIMARY'), _classNames5))
					}
				})
			)
		),
		time > 0 && _react2.default.createElement(
			'div',
			{ className: classes.time },
			_react2.default.createElement(_Schedule2.default, { className: classes.timeIcon }),
			_react2.default.createElement(
				'div',
				null,
				t('common.time-format.minutes', { minutes: time })
			)
		),
		imageUrl && placeholder && _react2.default.createElement(
			'div',
			{
				className: (0, _classnames2.default)(classes.backgroundImage, _defineProperty({}, classes.overflow, lesson.imageOverflow))
			},
			_react2.default.createElement(_.LoadImage, { src: imageUrl, placeholder: placeholder })
		),
		_react2.default.createElement(
			'div',
			{ className: classes.content },
			_react2.default.createElement(
				'span',
				{
					className: (0, _classnames2.default)(classes.type, _defineProperty({}, classes.secondaryPrefixColor, prefixColor == 'SECONDARY'), _defineProperty({}, classes.primaryPrefixColor, prefixColor == 'PRIMARY'))
				},
				preTitle || settings && settings[item.type.toLowerCase() + 'Prefix'] || t('common.element-types.lesson') || ''
			),
			_react2.default.createElement(
				_core.Typography,
				{
					variant: 'h3',
					className: (0, _classnames2.default)(classes.name, _defineProperty({}, classes.secondaryTitleColor, titleColor == 'SECONDARY'), _defineProperty({}, classes.primaryTitleColor, titleColor == 'PRIMARY'))
				},
				introTitle || item.title
			)
		),
		useTextOverlay && _react2.default.createElement(
			'div',
			{ className: classes.textOverlay },
			_react2.default.createElement(
				_core.Typography,
				{
					variant: 'h1',
					className: (0, _classnames2.default)(classes.textOverlayTitle, _defineProperty({}, classes.secondaryTitleColor, titleColor == 'SECONDARY'), _defineProperty({}, classes.primaryTitleColor, titleColor == 'PRIMARY'))
				},
				t('common.intro').toLocaleUpperCase()
			)
		)
	);
};

exports.default = Lesson;