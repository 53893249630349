import React from 'react';
import { LoadImage, ThemeIcon, Tags } from '@manakin/app-core';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import renderHTML from "react-render-html";
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { backgroundColorKeys } from '../../constants';

const useStyles = makeStyles((theme) => ({
    image: {
        transition: 'transform .3s',
    },
    root: {
        overflow: 'hidden',
        width: '100%',
        height: '25rem',
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-end',
        padding: '4rem',
        textDecoration: 'none',
        marginBottom: '2rem',
        [theme.breakpoints.up('sm')]: {
            height: '29rem',
        },
        [theme.breakpoints.up('md')]: {
            height: '30rem',
        },
        [theme.breakpoints.up('lg')]: {
            height: '33rem',
            marginBottom: '5rem',
        },
        '&:hover': {
            '& $image': {
                transform: 'scale(1.1)',
            },
        },
    },
    themeIcon: {
        position: 'absolute',
        width: '7rem',
        height: '7rem',
        zIndex: 1,
        right: '3rem',
        top: '3rem',
    },
    content: {
        position: 'relative',
        zIndex: 1,
    },
    contentStyle: {
        margin: 0,
        color: theme.manakin.defaultBoxColor[500],
        textDecoration: 'none',
    },
    secondaryColor: {
        color: theme.manakin.primaryColor[500],
    },
    contentBody: {
        fontSize: '1.5rem',
        lineHeight: '3rem'
    },
    contentTitle: {
        fontSize: '2.5rem',
        lineHeight: '2.9rem'
    },
    tags: {
        zIndex: '1',
        position: 'absolute',
        right: '15px',
        top: '15px',
        width: '50px',
        height: '50px'
    },
    primary: {
        '& $contentStyle': {
            color: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),
        }
    },
    secondary: {
        '& $contentStyle': {
            color: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
        }
    },
    noBG: {
        '& $contentStyle': {
            color: theme.manakin.defaultContentColor[500],
        }
    }
}));

const BoxBlock = (props) => {
    const { image = {}, name = '', id, preTitle = '', themes = [], tags, backgroundColor } = props;
    const classes = useStyles();

    return (
        <Link className={classNames(
            classes.root,
            { [classes.primary]: backgroundColor === backgroundColorKeys.primary },
            { [classes.secondary]: backgroundColor === backgroundColorKeys.secondary },
            { [classes.noBG]: backgroundColor === backgroundColorKeys.none },
        )} to={{ pathname: `/box/${id}`, state: { fromProgramDashboard: false } }}>
            {tags && <Tags className={classes.tags} tags={tags} />}
            <LoadImage
                src={image ? image.url || '' : ''}
                placeholder={image ? image.thumbnailUrl || false : ''}
                className={classes.image}
            />
            {themes &&
                themes.map((theme) => (
                    <ThemeIcon
                        key={theme.id}
                        variant={theme.title}
                        className={classes.themeIcon}
                    />
                ))}
            <div className={classes.content}>
                <Typography
                    variant='body1'
                    className={classNames(classes.contentStyle, classes.contentBody, {
                        [classes.secondaryColor]:
                        props.prefixColor === 'SECONDARY',
                    })}
                >
                    {preTitle}
                </Typography>
                <Typography
                    variant='h3'
                    className={classNames(classes.contentStyle, classes.contentTitle, {
                        [classes.secondaryColor]:
                        props.titleColor === 'SECONDARY',
                    })}
                >
                    {renderHTML(name)}
                </Typography>
            </div>
        </Link>
    );
};

export default BoxBlock;
