'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _styles = require('@material-ui/core/styles');

var _recompose = require('recompose');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _reactRouterDom = require('react-router-dom');

var _selectors = require('@manakin/app-core/GroupsDropdown/selectors');

var _selectors2 = require('@manakin/authentication/selectors');

var _reactRedux = require('react-redux');

var _selectors3 = require('@manakin/app-core/ProgramsDropdown/selectors');

var _graphql = require('./graphql');

var _ArrowRightAlt = require('@material-ui/icons/ArrowRightAlt');

var _ArrowRightAlt2 = _interopRequireDefault(_ArrowRightAlt);

var _reactApollo = require('react-apollo');

var _styles2 = require('./styles');

var _appCore = require('@manakin/app-core');

var _lib = require('../lib');

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

require('moment/min/locales');

var _momentDurationFormat = require('moment-duration-format');

var _momentDurationFormat2 = _interopRequireDefault(_momentDurationFormat);

var _reactI18next = require('react-i18next');

var _lib2 = require('@manakin/app-core/lib');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

(0, _momentDurationFormat2.default)(_moment2.default);

var StatisticsBox = function (_React$Component) {
    _inherits(StatisticsBox, _React$Component);

    function StatisticsBox() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, StatisticsBox);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = StatisticsBox.__proto__ || Object.getPrototypeOf(StatisticsBox)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
            loading: true,
            access: false,
            schoolClasses: [],
            results: [],
            elements: [],
            totalFinished: 0,
            totalElements: 0
        }, _this.handleGroup = function () {
            var _this$props = _this.props,
                client = _this$props.client,
                _this$props$group = _this$props.group,
                group = _this$props$group === undefined ? {} : _this$props$group;

            _this.setState({ loading: true });
            client.query({
                query: _graphql.GQL_FETCH_SCHOOL_CLASS,
                variables: {
                    id: group.id
                }
            }).then(function (result) {
                if (result.data && result.data.schoolClass && result.data.schoolClass.schools && result.data.schoolClass.schools.length) {
                    _this.setState({
                        currentGroup: result.data.schoolClass.schools[0].id
                    }, function () {
                        _this.loadSchool();
                    });
                    if (result.data.schoolClass) {
                        _this.handleSchoolclasses(result.data.schoolClass);
                        _this.setState({
                            studentAverage: [].concat(_toConsumableArray(result.data.schoolClass.students)),
                            schoolStudents: result.data.schoolClass.students.length || 0
                        });
                    }
                } else {
                    _this.setState({ loading: false });
                }
            });
        }, _this.handleSchoolclass = function (student, classData) {
            if ((0, _lib2.requestedRolesInGivenRoles)([_lib2.schoolManagerRole], _this.props.roles)) return;
            var _this$props2 = _this.props,
                client = _this$props2.client,
                programId = _this$props2.programId;
            var products = classData.products;

            var storedProduct = products.find(function (product) {
                return product.program.id === programId;
            });
            var program = storedProduct || products && products.length ? products[0].program || {} : {};
            var boxes = program ? program.boxes || [] : [];

            if (boxes.length) {
                boxes.map(function (boxContainer) {
                    return boxContainer.map(function (box) {
                        client.query({
                            query: _graphql.GQL_FETCH_ELEMENT_RESULTS,
                            variables: {
                                box: box.id,
                                program: program.id,
                                user: student.id
                            }
                        }).then(function (result) {
                            if (result.data && result.data.elementResults) {
                                var finished = 0;
                                var elements = box.elements;

                                var savableWorkforms = (0, _lib.workformElements)(elements);

                                var results = result.data.elementResults;
                                results && results.forEach(function (item) {
                                    if (item.finished) finished++;
                                });

                                _this.setState(function (prevState) {
                                    return {
                                        totalFinished: prevState.totalFinished + finished,
                                        totalElements: prevState.totalElements + savableWorkforms.length
                                    };
                                });
                            }
                        });
                    });
                });
            }
        }, _this.handleSchoolclasses = function (classData) {
            classData.students && classData.students.forEach(function (student) {
                _this.handleSchoolclass(student, classData);
            });
        }, _this.loadSchool = function () {
            var currentGroup = _this.state.currentGroup;

            if (currentGroup) {
                _this.props.client.query({
                    query: _graphql.GQL_FETCH_SCHOOL,
                    variables: {
                        id: currentGroup
                    }
                }).then(function (result) {
                    if (result.data.school) {
                        var _result$data$school = result.data.school,
                            school = _result$data$school === undefined ? {} : _result$data$school;
                        var _school$classes = school.classes,
                            classes = _school$classes === undefined ? [] : _school$classes,
                            _school$students = school.students,
                            students = _school$students === undefined ? [] : _school$students;

                        var arr = [];
                        var newStudents = [];

                        classes.forEach(function (item) {
                            item.students && item.students.forEach(function (student) {
                                if (students && students.some(function (i) {
                                    return i.id === student.id;
                                })) {
                                    arr.push({ id: student.id });
                                }
                            });
                        });

                        students.forEach(function (s) {
                            if (!arr.some(function (i) {
                                return i.id == s.id;
                            })) {
                                newStudents.push(s);
                            }
                        });

                        _this.setState({
                            loading: false,
                            schoolClasses: [].concat(_toConsumableArray(result.data.school.classes)),
                            students: newStudents || []
                        });
                    }
                });
            }
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(StatisticsBox, [{
        key: 'componentDidUpdate',
        value: function componentDidUpdate(prevProps) {
            if (prevProps.group != this.props.group) {
                this.handleGroup();
            }
        }
    }, {
        key: 'componentDidMount',
        value: function componentDidMount() {
            var _this2 = this;

            var _props = this.props,
                client = _props.client,
                _props$group = _props.group,
                group = _props$group === undefined ? {} : _props$group,
                appUser = _props.appUser;


            if (appUser && appUser.id && (!group || !group.id)) {
                client.query({
                    query: _graphql.GQL_FETCH_GROUPS,
                    variables: {
                        filter: {
                            manager: appUser.id,
                            types: ['School']
                        },
                        pagesize: 9999
                    }
                }).then(function (result) {
                    if (!result.errors) {
                        var _result$data = result.data,
                            data = _result$data === undefined ? {} : _result$data;
                        var groups = data.groups;


                        if (groups.groups.length) {
                            _this2.setState({
                                currentGroup: groups.groups[0].id
                            }, function () {
                                _this2.loadSchool();
                            });
                        } else {
                            _this2.setState({
                                loading: false,
                                noData: true
                            });
                        }
                    }
                });
            } else if (group.id) {
                this.handleGroup();
            }
        }
    }, {
        key: 'render',
        value: function render() {
            var _props2 = this.props,
                _props2$variant = _props2.variant,
                variant = _props2$variant === undefined ? 'default' : _props2$variant,
                classes = _props2.classes,
                _props2$group = _props2.group,
                group = _props2$group === undefined ? {} : _props2$group,
                t = _props2.t;
            var _state = this.state,
                loading = _state.loading,
                _state$students = _state.students,
                students = _state$students === undefined ? [] : _state$students,
                schoolStudents = _state.schoolStudents,
                schoolClasses = _state.schoolClasses,
                studentAverage = _state.studentAverage,
                totalFinished = _state.totalFinished,
                totalElements = _state.totalElements;

            var amountNoSchool = students.length;
            var teacherLength = 0;
            var schoolClassesLength = schoolClasses ? schoolClasses.length : 0;
            var sessionTotal = 0;
            var sessionAverage = 0;

            schoolClasses && schoolClasses.forEach(function (item) {
                if (group && group.id && item.id === group.id) teacherLength = item.teachers.length || 0;
            });

            studentAverage && studentAverage.forEach(function (obj) {
                sessionTotal += obj.sessionAverageDuration || 0;

                if (sessionTotal) {
                    sessionAverage = sessionTotal / schoolStudents;
                }
            });
            var percentage = Math.floor(totalFinished / totalElements * 100);

            if (loading) {
                return _react2.default.createElement(
                    'div',
                    { className: 'loadContainer' },
                    _react2.default.createElement(_appCore.Loader, null)
                );
            } else {
                return _react2.default.createElement(
                    'div',
                    { className: classes.root },
                    _react2.default.createElement(
                        'div',
                        { className: classes.headingContent },
                        _react2.default.createElement(
                            'div',
                            { className: classes.right },
                            variant == 'default' && _react2.default.createElement(
                                _reactRouterDom.Link,
                                {
                                    className: classes.link,
                                    to: '/rapportage'
                                },
                                _react2.default.createElement(
                                    _Typography2.default,
                                    {
                                        classes: {
                                            body1: (0, _classnames2.default)(classes.linkContent)
                                        },
                                        component: 'p',
                                        variant: 'body1'
                                    },
                                    t("app.statistics-box.classes-overview"),
                                    _react2.default.createElement(
                                        'span',
                                        null,
                                        _react2.default.createElement(_ArrowRightAlt2.default, null)
                                    )
                                )
                            )
                        )
                    ),
                    _react2.default.createElement(
                        'div',
                        { className: classes.statistics },
                        _react2.default.createElement(
                            _appCore.AccessControl,
                            {
                                role: [_lib2.schoolManagerRole],
                                not: _lib2.teacherRoles
                            },
                            _react2.default.createElement(
                                'div',
                                { className: classes.row },
                                _react2.default.createElement(
                                    'div',
                                    { className: classes.specificStatisticItem },
                                    _react2.default.createElement(
                                        _Typography2.default,
                                        {
                                            classes: {
                                                h2: (0, _classnames2.default)(classes.statisticsTitle)
                                            },
                                            component: 'p',
                                            variant: 'h2'
                                        },
                                        amountNoSchool
                                    ),
                                    _react2.default.createElement(
                                        _Typography2.default,
                                        {
                                            classes: {
                                                body1: (0, _classnames2.default)(classes.stasticsBody)
                                            },
                                            component: 'p',
                                            variant: 'body1'
                                        },
                                        t("app.statistics-box.students-without-class")
                                    )
                                ),
                                _react2.default.createElement(
                                    'div',
                                    { className: classes.specificStatisticItem },
                                    _react2.default.createElement(
                                        _Typography2.default,
                                        {
                                            classes: {
                                                h2: (0, _classnames2.default)(classes.statisticsTitle)
                                            },
                                            component: 'p',
                                            variant: 'h2'
                                        },
                                        schoolStudents != undefined ? schoolStudents : 0
                                    ),
                                    _react2.default.createElement(
                                        _Typography2.default,
                                        {
                                            classes: {
                                                body1: (0, _classnames2.default)(classes.stasticsBody)
                                            },
                                            component: 'p',
                                            variant: 'body1'
                                        },
                                        t("app.statistics-box.students-in-class")
                                    )
                                ),
                                _react2.default.createElement(
                                    'div',
                                    { className: classes.specificStatisticItem },
                                    _react2.default.createElement(
                                        _Typography2.default,
                                        {
                                            classes: {
                                                h2: (0, _classnames2.default)(classes.statisticsTitle)
                                            },
                                            component: 'p',
                                            variant: 'h2'
                                        },
                                        teacherLength
                                    ),
                                    _react2.default.createElement(
                                        _Typography2.default,
                                        {
                                            classes: {
                                                body1: (0, _classnames2.default)(classes.stasticsBody)
                                            },
                                            component: 'p',
                                            variant: 'body1'
                                        },
                                        _react2.default.createElement(_reactI18next.Trans, { i18nKey: 'app.statistics-box.amount-of-teachers' })
                                    )
                                ),
                                _react2.default.createElement(
                                    'div',
                                    { className: classes.specificStatisticItem },
                                    _react2.default.createElement(
                                        _Typography2.default,
                                        {
                                            classes: {
                                                h2: (0, _classnames2.default)(classes.statisticsTitle)
                                            },
                                            component: 'p',
                                            variant: 'h2'
                                        },
                                        schoolClassesLength
                                    ),
                                    _react2.default.createElement(
                                        _Typography2.default,
                                        {
                                            classes: {
                                                body1: (0, _classnames2.default)(classes.stasticsBody)
                                            },
                                            component: 'p',
                                            variant: 'body1'
                                        },
                                        _react2.default.createElement(_reactI18next.Trans, { i18nKey: 'app.statistics-box.amount-of-classes' })
                                    )
                                )
                            )
                        ),
                        _react2.default.createElement(
                            _appCore.AccessControl,
                            { role: _lib2.teacherRoles },
                            _react2.default.createElement(
                                'div',
                                { className: classes.rowTeacher },
                                _react2.default.createElement(
                                    'div',
                                    {
                                        className: classes.specificStatisticItemTeacher
                                    },
                                    _react2.default.createElement(
                                        _Typography2.default,
                                        {
                                            classes: {
                                                h2: (0, _classnames2.default)(classes.statisticsTitle)
                                            },
                                            component: 'p',
                                            variant: 'h2'
                                        },
                                        schoolStudents || 0
                                    ),
                                    _react2.default.createElement(
                                        _Typography2.default,
                                        {
                                            classes: {
                                                body1: (0, _classnames2.default)(classes.stasticsBody)
                                            },
                                            component: 'p',
                                            variant: 'body1'
                                        },
                                        t("app.statistics-box.students-in-class")
                                    )
                                ),
                                _react2.default.createElement(
                                    'div',
                                    {
                                        className: classes.specificStatisticItemTeacher
                                    },
                                    _react2.default.createElement(
                                        _Typography2.default,
                                        {
                                            classes: {
                                                h2: (0, _classnames2.default)(classes.statisticsTitle)
                                            },
                                            component: 'p',
                                            variant: 'h2'
                                        },
                                        _moment2.default.duration(sessionAverage, 'milliseconds').format('hh:mm', { trim: false })
                                    ),
                                    _react2.default.createElement(
                                        _Typography2.default,
                                        {
                                            classes: {
                                                body1: (0, _classnames2.default)(classes.stasticsBody)
                                            },
                                            component: 'p',
                                            variant: 'body1'
                                        },
                                        t("app.statistics-box.average-session-duration")
                                    )
                                ),
                                _react2.default.createElement(
                                    'div',
                                    {
                                        className: classes.specificStatisticItemTeacher
                                    },
                                    _react2.default.createElement(
                                        _Typography2.default,
                                        {
                                            classes: {
                                                h2: (0, _classnames2.default)(classes.statisticsTitle)
                                            },
                                            component: 'p',
                                            variant: 'h2'
                                        },
                                        percentage || 0
                                    ),
                                    _react2.default.createElement(
                                        _Typography2.default,
                                        {
                                            classes: {
                                                body1: (0, _classnames2.default)(classes.stasticsBody)
                                            },
                                            component: 'p',
                                            variant: 'body1'
                                        },
                                        t("app.statistics-box.average-progress")
                                    )
                                )
                            )
                        )
                    )
                );
            }
        }
    }]);

    return StatisticsBox;
}(_react2.default.Component);

exports.default = (0, _recompose.compose)((0, _reactRedux.connect)(function (state) {
    return {
        group: (0, _selectors.getGroup)(state),
        appUser: (0, _selectors2.getAppUser)(state),
        programId: (0, _selectors3.getProgram)(state)
    };
}), (0, _reactI18next.withTranslation)(), _reactApollo.withApollo, (0, _styles.withStyles)(_styles2.styles, { name: 'AppStatisticsBox' }))(StatisticsBox);