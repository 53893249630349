import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
	(theme) =>
		createStyles({
			headerRoot: {
				/*padding: "8rem 0 4rem",*/
			},
			heading: {
				marginBottom: "4.4rem",
			},
			wrapper: {
				...theme.manakin.smallWrapper,
				[theme.breakpoints.up("md")]: {
					paddingTop: "15rem",
					textAlign: "center",
				},
			},
			contentWrapper: {
				...theme.manakin.defaultWrapper,
			},
			contentTop: {
				marginBottom: "6rem",
				textAlign: "center",
				maxWidth: 600,
				marginLeft: "auto",
				marginRight: "auto"
			},
			row: {
				marginBottom: "50px",
			},
			titleAndIconContainer: {
				display: "flex",
				flexDirection: "row",
				justifyContent: "center",
				gap: theme.spacing(2),
				marginBottom: theme.spacing(2)
			},
			icon: {
				width: 56,
				height: 56,
				color: theme.manakin.subHeaderColor[500],
				marginRight: theme.spacing(2)
			},
			title: {
				color: theme.manakin.indicationColor[500],
				fontWeight: "400",
				marginBottom: 0
			},
			header: {
				wordBreak: "break-word"
			},
			body: {}
		}),
	{ name: "AppAssignedStudent" }
);
