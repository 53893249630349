'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.GQL_FETCH_WORKFORM_TYPES = undefined;

var _templateObject = _taggedTemplateLiteral(['\n    query workformTypes {\n        workformTypes {\n            id\n            label\n            canHaveResult\n        }\n    }\n'], ['\n    query workformTypes {\n        workformTypes {\n            id\n            label\n            canHaveResult\n        }\n    }\n']);

var _graphqlTag = require('graphql-tag');

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_WORKFORM_TYPES = exports.GQL_FETCH_WORKFORM_TYPES = (0, _graphqlTag2.default)(_templateObject);