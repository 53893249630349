import React, { useEffect, useCallback } from 'react';
import MuiDialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { TextField, withForm } from '@manakin/core';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { useMutation } from 'react-apollo';
import { GQL_SEND_MESSAGE } from '../../graphql';
import { useDispatch, useSelector } from 'react-redux';
import { getAppUser } from '@manakin/authentication/selectors';
import { showSnackbarMessage } from '@manakin/core/actions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    input: {
        marginBottom: '4rem!important',
        marginTop: '0!important',
    },
}));

const formOptions = {
    title: { required: true },
    text: { required: true },
};

const MessageDialog = (props) => {
    const { name, form, open, title, studentId } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    //selectors
    const appUser = useSelector((state) => getAppUser(state));

    //dispatch
    const dispatch = useDispatch();
    const onSucces = useCallback(
        () =>
            dispatch(
                showSnackbarMessage({
                    text: t("app.student-report.message-dialog.send-success"),
                    variant: 'success',
                })
            ),
        [dispatch]
    );
    const onError = useCallback(
        () =>
            dispatch(
                showSnackbarMessage({
                    text: t("app.student-report.message-dialog.send-failed"),
                    variant: 'error',
                })
            ),
        [dispatch]
    );

    //mutations
    const [mutate, { data, error }] = useMutation(GQL_SEND_MESSAGE);

    //functions
    const handleClose = () => {
        if (props.onClose) props.onClose();
    };

    const handleSubmit = () => {
        form.onSubmit().then((r) => {
            if (r) {
                mutate({
                    variables: {
                        title: r.title,
                        text: r.text,
                        sender: appUser.id,
                        receivers: [studentId],
                        send: true,
                    },
                });
            }
        });
    };
    //effect hooks
    useEffect(() => {
        if (data) {
            onSucces();
            if (props.onClose) props.onClose();
        }
        if (error) {
            onError();
        }
    }, [data, error]);

    return (
        <MuiDialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle>
                {t("app.student-report.message-dialog.title", { name: name || t("common.student") })}
            </DialogTitle>
            <DialogContent>
                <TextField
                    name="title"
                    label={t("app.student-report.message-dialog.fields.subject.label")}
                    placeholder={t("app.student-report.message-dialog.fields.subject.placeholder")}
                    form={form}
                    className={classes.input}
                    initialValue={title || ''}
                />
                <TextField
                    name="text"
                    label={t("app.student-report.message-dialog.fields.your-message.label")}
                    placeholder={t("app.student-report.message-dialog.fields.your-message.placeholder")}
                    multiline={true}
                    form={form}
                    className={classes.input}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                >
                    {t("common.send")}
                </Button>
                <Button onClick={handleClose} color="primary">
                    {t("common.cancel")}
                </Button>
            </DialogActions>
        </MuiDialog>
    );
};

export default withForm(formOptions)(MessageDialog);
