export const styles = (theme) => ({
    imageTitle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    root: {
        padding: '40px 0',
        width: '100vw',
        [theme.breakpoints.up('md')]: {
            ...theme.manakin.largeWrapper,
            maxWidth: '100%',
            display: 'flex',
        },
    },
    amount3: {},
    amount4: {},
    amount5: {},
    amount6: {},
    amount7: {},
    amount8: {},
    amount9: {},
    noHover: {},
    desktopImage: {
        position: 'relative',
        width: 'calc(100% - 2rem)',
        margin: '1rem',
        cursor: 'pointer',
        transform: 'scale(1)',
        transition: 'box-shadow .3s, transform .3s, opacity .3s',

        '&:hover': {
            boxShadow: '-1px 0px 29px -10px rgba(0,0,0,0.75)',
            transform: 'scale(1.01)',
        },
        '& img': {
            transition: 'all .2s',
        },
        '&$noHover': {
            '&:hover': {
                boxShadow: 'none',
                transform: 'scale(.9)',
            },
        },
    },
    imageContainer: {
        width: '100%',
        position: 'relative',
        display: 'none',
        flexDirection: 'row',
        flexWrap: 'wrap',
        height: 'calc(100vh - 16rem)',
        minHeight: '80rem',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            width: '50%',
        },
        '&$amount3, &$amount4, &$amount5, &$amount6, &$amount7, &$amount8, &$amount9': {
            flexWrap: 'wrap',
            flexDirection: 'row',
            '& $desktopImage': {
                width: 'calc(50% - 2rem)',
            },
        },
        '&$amount1': {
            maxHeight: '750px',
        },
        '&$amount1, &$amount2, &$amount3': {
            flexWrap: 'no-wrap',
            '& $desktopImage': {
                width: '100%',
            },
        },
    },
    wrapper: {
        ...theme.manakin.defaultWrapper,
        [theme.breakpoints.up('md')]: {
            width: '50%',
            paddingLeft: '10rem',
            paddingTop: '4rem',
        },
    },
    image: {
        width: '100%',
        paddingRight: '2rem',
        '&>div': {
            padding: '0 1.6rem 0 0',
        },
        [theme.breakpoints.up('md')]: {
            height: '14rem',
        },
    },
    active: {
        transform: 'scale(.9)',
        border: `2px solid ${theme.manakin.defaultContentHover[500]}`,
        '& img': {
            boxShadow: '-1px 0px 29px -10px rgba(0,0,0,0.75)',
        },
    },
    blurred: {
        opacity: '.5',
    },
    preTitle: {},
    title: {},
    content: {
        opacity: 1,
        transition: 'opacity .2s, max-height .4s',
        marginBottom: '2.8rem',
        maxHeight: '40rem',
        '& $preTitle': {
            fontWeight: '400',
            [theme.breakpoints.up('md')]: {
                paddingBottom: '3.4rem',
            },
        },
        '& $title': {
            fontWeight: '600',
        },
        '& h3': {
            fontFamily: theme.manakin.defaultTitleFont,
            fontSize: '1.8rem',
            fontWeight: '600',
            letterSpacing: '.2rem',
            lineHeight: '2rem',
        },
        '& h4': {
            fontWeight: '600',
        },
        '& h5': {
            fontWeight: '600',
            fontSize: '1.8rem',
        },
        '& ol': {
            paddingLeft: '2rem',
        },
        '& ul': {
            paddingLeft: '2rem',
        },
        [theme.breakpoints.up('md')]: {
            maxHeight: 'none',
        },
    },
    images: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    carousel: {
        marginBottom: '4rem',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    carouselImg: {
        height: '150px',
        width: '100%',
        objectFit: 'contain',
        fontFamily: "'object-fit: contain'",
    },
    animate: {
        '& $content': {
            opacity: 0,
            maxHeight: '13rem',
            [theme.breakpoints.up('md')]: {
                maxHeight: 'none',
            },
        },
    },
    tags: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        zIndex: 999,
    },
    factCardVideo: {
        display: "flex",
        flexDirection: "column",
        paddingTop: theme.spacing(3),
        [theme.breakpoints.down("sm")]: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3)
        }
    },
    factCardVideoContent: {
        maxHeight: "85vh",
        // Mostly aimed at mobile devices with landscape options
        [theme.breakpoints.down("sm")]: {
            maxHeight: "65vh"
        },
        "&> div ": {
            height: "auto"
        }
    },
	[`@media print`]: {
		noPrint:{
			display: "none",
		},
	}
});
