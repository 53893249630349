import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { getBreadcrumb } from './selectors';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { NavLink, Route } from 'react-router-dom';

const styles = (theme) => ({
    link: {
        fontSize: '16px',
        textDecoration: 'none',
        fontWeight: '600',
        color: theme.manakin.defaultTitleColor[500],
        margin: '0 5px',
    },
});

const mapStateToProps = (state) => {
    return {
        customBreadcrumb: getBreadcrumb()(state),
    };
};

const routes = [
    { path: '/', breadcrumb: 'Home' },
    { path: '/widgets', breadcrumb: null },
    { path: '/groups/School', breadcrumb: null },
    { path: '/groups/SchoolClass', breadcrumb: null },
    { path: '/groups/Organisation', breadcrumb: null },
    { path: '/workforms/TitleAndText', breadcrumb: null },
    { path: '/workforms/TitleAndTextImage', breadcrumb: null },
    { path: '/workforms/ImageWithSubtitle', breadcrumb: null },
    { path: '/workforms/Moodboard', breadcrumb: null },
    { path: '/workforms/FactCardReferral', breadcrumb: null },
    { path: '/workforms/Scene', breadcrumb: null },
    { path: '/workforms/OrderQuestion', breadcrumb: null },
    { path: '/workforms/Roadmap', breadcrumb: null },
    { path: '/workforms/Hotspot', breadcrumb: null },
    { path: '/workforms/SortQuestion', breadcrumb: null },
    { path: '/workforms/ImageMPC', breadcrumb: null },
    { path: '/workforms/StatementQuestion', breadcrumb: null },
    { path: '/workforms/WhatIsWhatQuestion', breadcrumb: null },
    { path: '/workforms/TextMPC', breadcrumb: null },
    { path: '/workforms/ImageAndTextMPC', breadcrumb: null },
    { path: '/workforms/ImageCheckQuestion', breadcrumb: null },
    { path: '/workforms/ShortText', breadcrumb: null },
    { path: '/workforms/TextCheckQuestion', breadcrumb: null },
    { path: '/workforms/VideoWithTitle', breadcrumb: null },
    { path: '/workforms/ImageAndTextCheckQuestion', breadcrumb: null },
    { path: '/workforms/ChatBubble', breadcrumb: null },
    { path: '/workforms/FileDownload', breadcrumb: null },
    { path: '/elements/Lesson', breadcrumb: null },
    { path: '/elements/MasterClass', breadcrumb: null },
    { path: '/elements/Film', breadcrumb: null },
    { path: '/elements/BookCheck', breadcrumb: null },
    { path: '/elements/Trainer', breadcrumb: null },
    { path: '/elements/Case', breadcrumb: null },
    { path: '/elements/Bridge', breadcrumb: null },
];

const Breadcrump = (props) => {
    const path = props.path;
    let newBreadcrump = props.breadcrumb;
    if (
        props.customBreadcrumb.Breadcrumb &&
        props.customBreadcrumb.Breadcrumb[path]
    ) {
        newBreadcrump = props.customBreadcrumb.Breadcrumb[path];
    }
    return newBreadcrump;
};

class Breadcrumbs extends React.Component {
    render() {
        const {
            breadcrumbs,
            separator,
            customBreadcrumb,
            classes,
        } = this.props;
        console.log('breadcrumbs', breadcrumbs);
        return (
            <div>
                {/* {breadcrumbs &&
          breadcrumbs.map((breadcrumb, index) => (
            <span key={breadcrumb.key}>
              <NavLink
                to={breadcrumb.props ? breadcrumb.props.match.url : '/'}
                className={classes.link}
              >
                <Breadcrump
                  path={breadcrumb.key}
                  breadcrumb={breadcrumb}
                  customBreadcrumb={customBreadcrumb}
                />
              </NavLink>
              {index < breadcrumbs.length - 1 && <i> / </i>}
            </span>
          ))} */}
            </div>
        );
    }
}

export default compose(
    withStyles(styles),
    withBreadcrumbs(routes),
    connect(mapStateToProps)
)(Breadcrumbs);
