import React, { useEffect, useState } from 'react';
import FactCard from './FactCard';
import FactCardTitles from './FactCardTitles';
import { GQL_FETCH_FACT_CARD } from './graphql/graphql';
import { ElementOverlay } from '@manakin/app-core';
import FactCardVideo from './FactCardVideo';
import { useViewCounting } from "@manakin/hooks";
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';

const FactCardContainer = (props) => {
    const params = useParams();
    const [ noImages, setNoImages ] = useState(true);
    const { viewFactCard } = useViewCounting();
    const { data, loading } = useQuery(GQL_FETCH_FACT_CARD, {
        variables: { id: params.factCardId },
    });
    const { factCard } = data || {};

    useEffect(() => {
        if (!loading && factCard) {
            viewFactCard(factCard.id);

            if (factCard.items.find((item) => item.image != null)) {
                setNoImages(false);
            }
        }
    }, [ loading ]);

	// Functions
	const handleCloseClick = () => {
		if (props.location && props.location.state && props.location.state.url) {
			props.history.push({
				pathname: `${props.location.state.url}`,
				state: { idx: props.location.state.idx },
			});
		} else {
			props.history.goBack();
		}
	};

	if (loading) {
		return <div />;
	} else if (factCard && factCard.vimeoId) {
		return (
			<ElementOverlay onClick={handleCloseClick} controls={false} customControls={true} title={factCard.title || ''}>
				<FactCardVideo {...factCard} />
			</ElementOverlay>
		);
	} else if (noImages) {
		return (
			<ElementOverlay onClick={handleCloseClick} controls={false} customControls={true} title={factCard.title || ''}>
				<FactCardTitles {...factCard} />
			</ElementOverlay>
		);
	} else {
		return (
			<ElementOverlay onClick={handleCloseClick} controls={false} customControls={true} title={factCard.title || ''}>
				<FactCard {...factCard} />
			</ElementOverlay>
		);
	}
};

export default FactCardContainer;
