"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _recompose = require("recompose");

var _styles = require("@material-ui/core/styles");

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _Typography = require("@material-ui/core/Typography");

var _Typography2 = _interopRequireDefault(_Typography);

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

var _reactRedux = require("react-redux");

var _Dialog = require("./Dialog");

var _Dialog2 = _interopRequireDefault(_Dialog);

var _Chip = require("./Chip");

var _Chip2 = _interopRequireDefault(_Chip);

var _ResendRatingRequestDialog = require("./ResendRatingRequestDialog");

var _ResendRatingRequestDialog2 = _interopRequireDefault(_ResendRatingRequestDialog);

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
	return {
		root: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			paddingTop: theme.spacing(2),
			paddingBottom: theme.spacing(2),
			borderBottom: "2px solid " + theme.manakin.defaultBorderColor[200],
			cursor: "pointer"
		},
		text: {
			marginBottom: 0
		},
		item: _defineProperty({
			display: "grid",
			gridAutoColumns: "40% 25% 35%"
		}, theme.breakpoints.down("md"), {
			gridAutoColumns: "auto min-content"
		}),
		title: _defineProperty({
			gridColumn: "1"
		}, theme.breakpoints.down("md"), {
			gridColumn: "1",
			gridRow: "1"
		}),
		chipContent: _defineProperty({
			gridColumn: "2"
		}, theme.breakpoints.down("md"), {
			gridColumn: "2",
			gridRow: "1/3"
		}),
		date: _defineProperty({
			textAlign: "right",
			gridColumn: "3"
		}, theme.breakpoints.down("md"), {
			textAlign: "left",
			gridColumn: "1",
			gridRow: "2"
		})
	};
};

var RequestRatingItem = function RequestRatingItem(props) {
	var classes = props.classes,
	    classNameProp = props.className,
	    data = props.data,
	    title = props.title,
	    _props$element = props.element,
	    element = _props$element === undefined ? [] : _props$element,
	    attempt = props.attempt,
	    refetch = props.refetch,
	    isFinalRating = props.isFinalRating,
	    isMM = props.isMM;

	var result = data.result || {};

	var _useState = (0, _react.useState)(false),
	    _useState2 = _slicedToArray(_useState, 2),
	    open = _useState2[0],
	    setOpen = _useState2[1];

	var _useState3 = (0, _react.useState)(false),
	    _useState4 = _slicedToArray(_useState3, 2),
	    resendDialogOpen = _useState4[0],
	    setResendDialogOpen = _useState4[1];

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var handleClick = function handleClick() {
		if (!data.result) {
			setResendDialogOpen(!isMM);
		} else {
			setOpen(true);
		}
	};

	var handleClose = function handleClose() {
		setOpen(false);
	};

	var handleCloseResendDialog = function handleCloseResendDialog() {
		refetch();
		setResendDialogOpen(false);
	};

	return _react2.default.createElement(
		_react2.default.Fragment,
		null,
		_react2.default.createElement(
			"div",
			{
				className: (0, _classnames2.default)(classes.root, classes.item, classNameProp),
				onClick: handleClick
			},
			_react2.default.createElement(
				_Typography2.default,
				{
					component: "div",
					variant: "body1",
					className: (0, _classnames2.default)(classes.text, classes.title)
				},
				title
			),
			_react2.default.createElement(
				"div",
				{ className: classes.chipContent },
				_react2.default.createElement(_Chip2.default, { ranking: result.ranking })
			),
			result.gradedAt && _react2.default.createElement(
				_Typography2.default,
				{
					component: "div",
					variant: "body1",
					className: (0, _classnames2.default)(classes.text, classes.date)
				},
				(0, _moment2.default)(result.gradedAt.replace("Z", "")).locale(t("common.locale-code")).fromNow()
			)
		),
		data.result && _react2.default.createElement(_Dialog2.default, {
			title: title,
			data: result,
			element: element[0].element || {},
			attempt: attempt,
			open: open,
			onClose: handleClose
		}),
		!data.result && _react2.default.createElement(_ResendRatingRequestDialog2.default, {
			isFinalRating: isFinalRating,
			open: resendDialogOpen,
			ratingRequest: element[attempt - 1],
			onClose: handleCloseResendDialog
		})
	);
};

exports.default = (0, _recompose.compose)((0, _styles.withStyles)(styles, { name: "AppRequestRatingItem" }), (0, _reactRedux.connect)(function (_ref) {
	var config = _ref.config;
	return { config: config };
}))(RequestRatingItem);