import { SET_BREADCRUMB } from './actions';
import React from 'react';

const initialState = {};

export const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_BREADCRUMB: {
            return {
                ...state,
                Breadcrumb: {
                    ...state.Breadcrumb,
                    [action.payload.path]: action.payload.data,
                },
            };
        }
        default:
            return state;
    }
};

export default reducer;
