"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _Typography = require("@material-ui/core/Typography");

var _Typography2 = _interopRequireDefault(_Typography);

var _styles = require("@material-ui/core/styles");

var _recompose = require("recompose");

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _reactGoogleCharts = require("react-google-charts");

var _WarningOutlined = require("@material-ui/icons/WarningOutlined");

var _WarningOutlined2 = _interopRequireDefault(_WarningOutlined);

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = function styles(theme) {
	return {
		root: {},
		chartContainer: {
			position: "relative"
		},
		chartHoleText: {
			position: "absolute",
			top: "50%",
			left: "50%",
			margin: "auto",
			transform: "translate(-50%, -50%)",
			fontSize: "2.6rem"
		},
		statusText: {
			marginBottom: 0,
			paddingBottom: "0 !important",
			color: theme.manakin.subHeaderColor[500],
			textAlign: "center",
			textTransform: "uppercase",
			fontWeight: "500"
		},
		warningIcon: {
			position: "absolute",
			margin: "auto",
			bottom: theme.spacing(),
			left: 0,
			right: 0,
			color: theme.manakin.warningColor[500]
		},
		subHeader: {}
	};
};

var defaultGraphOptions = function defaultGraphOptions(fillColor, borderColor) {
	return {
		legend: { position: "none" },
		chartArea: {
			left: 0,
			top: 0,
			bottom: 0,
			width: "100%",
			height: "100%"
		},
		pieHole: 0.6,
		pieSliceText: "none",
		pieStartAngle: 217.5,
		slices: {
			0: { color: fillColor },
			1: { color: borderColor },
			2: { color: "transparent" }
		},
		tooltip: {
			trigger: "none"
		},
		enableInteractivity: false
	};
};

var defaultGraphHeader = function defaultGraphHeader(t) {
	return ["", t("app-core.progress-block.title")];
};
var defaultTransparentSliceSize = 20;

var SessionsBlock = function SessionsBlock(props) {
	var classes = props.classes,
	    progress = props.progress,
	    warning = props.warning,
	    subtitle = props.subtitle,
	    theme = props.theme,
	    className = props.className;

	var _useState = (0, _react.useState)([]),
	    _useState2 = _slicedToArray(_useState, 2),
	    graphData = _useState2[0],
	    setGraphData = _useState2[1];

	var _useState3 = (0, _react.useState)({}),
	    _useState4 = _slicedToArray(_useState3, 2),
	    graphOptions = _useState4[0],
	    setGraphOptions = _useState4[1];

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	(0, _react.useEffect)(function () {
		// In order to not render some part of the circle, we need a transparent segment. Therefore,
		// the percentages have to be calculated
		var total = 100;
		var percentageSize = (total - defaultTransparentSliceSize) * ((progress || 0) / 100);

		setGraphData([defaultGraphHeader(t), ["", percentageSize], ["", total - defaultTransparentSliceSize - percentageSize], ["", defaultTransparentSliceSize]]);

		setGraphOptions(defaultGraphOptions(warning ? theme.manakin.warningColor[500] : theme.manakin.indicationColor[500], theme.manakin.softBorderColor[500]));
	}, [progress, warning]);

	return _react2.default.createElement(
		"div",
		{ className: (0, _classnames2.default)(classes.root, className) },
		_react2.default.createElement(
			"div",
			{ className: classes.chartContainer },
			_react2.default.createElement(_reactGoogleCharts.Chart, {
				chartType: "PieChart",
				width: "100%",
				data: graphData,
				options: graphOptions
			}),
			_react2.default.createElement(
				_Typography2.default,
				{
					component: "p",
					variant: "subtitle1",
					className: (0, _classnames2.default)(classes.subHeader, classes.contentColor, classes.chartHoleText)
				},
				parseInt(progress || 0),
				"%"
			),
			warning && _react2.default.createElement(_WarningOutlined2.default, { className: classes.warningIcon })
		),
		_react2.default.createElement(
			_Typography2.default,
			{
				component: "p",
				variant: "body1",
				className: (0, _classnames2.default)(classes.subHeader, classes.contentColor, classes.statusText)
			},
			subtitle,
			" "
		)
	);
};

exports.default = (0, _recompose.compose)((0, _styles.withStyles)(styles, {
	name: "AppProgressDashboardSessionsBlock",
	withTheme: true
}))(SessionsBlock);