import React from 'react';
import { TextField, RadioField, SelectField, DatePicker } from '@manakin/core';
import { Loader } from '@manakin/app-core';
import moment from 'moment';
import { GQL_FETCH_LOCALES, GQL_FETCH_SETTINGS } from '../graphql';
import { useQuery } from 'react-apollo';
import { useBooleanSetting } from '@manakin/hooks';
import { SettingsKeys } from '@manakin/core/lib/constants';
const {
	REGISTRATION_GENDER_ENABLED,
	REGISTRATION_GENDER_REQUIRED,
	REGISTRATION_BIRTH_DATE_ENABLED,
	REGISTRATION_BIRTH_DATE_REQUIRED,
	REGISTRATION_PHONE_NUMBER_ENABLED,
	REGISTRATION_PHONE_NUMBER_REQUIRED,
	REGISTRATION_LANGUAGE_ENABLED
} = SettingsKeys;
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useStyles } from './styles';
import FormHelperText from '@material-ui/core/FormHelperText';

const PersonalDataForm = (props) => {
	const { form } = props;
	const { fields: fieldsData } = form;
	const classes = useStyles();
	const { t } = useTranslation();
	const config = useSelector((state) => state.config) || {};
	const { pages = {} } = config;
	const { appRegistration = {} } = pages;

	const { data: settingsData = {}, loading: settingsLoading } = useQuery(GQL_FETCH_SETTINGS, {
		onCompleted: (data) => {
			if (data && data.settings && data.settings.settings) {
				const settings = data.settings.settings.reduce((obj, item) => Object.assign(obj, { [item.name]: item }), {});

				changeFormRequired(settings, 'gender', REGISTRATION_GENDER_ENABLED, REGISTRATION_GENDER_REQUIRED);
				changeFormRequired(settings, 'dateOfBirth', REGISTRATION_BIRTH_DATE_ENABLED, REGISTRATION_BIRTH_DATE_REQUIRED);
				changeFormRequired(settings, 'phonenumber', REGISTRATION_PHONE_NUMBER_ENABLED, REGISTRATION_PHONE_NUMBER_REQUIRED);
			}
		},
	});
	const changeFormRequired = (settings, name, enabledKey, settingKey) => {
		if (!settings[enabledKey]) return;
		// If not enabled, default to not required
		const required = settings[enabledKey].value === 'true' ? settings[settingKey].value === 'true' : false;
		form.onSchemaChange(name, { ...form.schema[name], required: required });
	};

	const { data, loading } = useQuery(GQL_FETCH_LOCALES);
	const { locales = {} } = data || {};

	const languageSelectEnabled = useBooleanSetting(settingsData.settings, REGISTRATION_LANGUAGE_ENABLED);
	const registrationGenderEnabled = useBooleanSetting(settingsData.settings, REGISTRATION_GENDER_ENABLED);
	const registrationBirthDateEnabled = useBooleanSetting(settingsData.settings, REGISTRATION_BIRTH_DATE_ENABLED);
	const registrationPhoneNumberEnabled = useBooleanSetting(settingsData.settings, REGISTRATION_PHONE_NUMBER_ENABLED);

	let locOptions = [];

	locales.locales &&
		locales.locales.map((item) => {
			if (item.enabled) {
				locOptions.push({
					id: item.code,
					label: item.title,
				});
			}
		});

	if (loading || settingsLoading) return <Loader />;
	return (
		<React.Fragment>
			{registrationGenderEnabled && (
				<RadioField
					form={form}
					name="gender"
					legendName={t('common.fields.gender.label')}
					color="primary"
					options={[
						{ label: t('common.fields.gender-types.male'), value: 'MALE' },
						{ label: t('common.fields.gender-types.female'), value: 'FEMALE' },
						{ label: t('common.fields.gender-types.unknown'), value: 'UNKNOWN' },
					]}
					initialValue={fieldsData.gender || ''}
				/>
			)}
			<div className={classes.groupElements}>
				<TextField
					form={form}
					name="firstName"
					label={t('common.fields.first-name.label')}
					initialValue={fieldsData.firstName || ''}
					shrink={false}
				/>
				<TextField
					form={form}
					name="middleName"
					label={t('common.fields.middle-name.label')}
					initialValue={fieldsData.middleName || ''}
					shrink={false}
				/>
			</div>
			<div className={classes.groupElements}>
				<TextField
					form={form}
					name="lastName"
					label={t('common.fields.last-name.label')}
					initialValue={fieldsData.lastName || ''}
					shrink={false}
				/>
				{languageSelectEnabled && (
					<SelectField
						loading={loading}
						noLoading={true}
						options={locOptions}
						name="locale"
						label={t('common.fields.locale.label')}
						initialValue={{
							id: fieldsData.locale,
						}}
						classes={{
							root: classes.selectField,
							placeholder: classes.selectFieldPlaceholder,
							singleValue: classes.selectFieldValue,
						}}
						form={form}
					/>
				)}

				{/* Show birthdate here if language select is disabled */}
				{!languageSelectEnabled && registrationBirthDateEnabled && (
					<DatePicker
						keyboardOnly
						form={form}
						name="dateOfBirth"
						label={t('common.fields.birthdate.label')}
						initialValue={fieldsData.dateOfBirth}
						maxDate={moment().format('YYYY-MM-DD')}
					/>
				)}
			</div>
			{appRegistration && (
				<div className={classes.groupElements}>
					{registrationPhoneNumberEnabled && (
						<TextField
							form={form}
							name="phonenumber"
							label={t('common.fields.phone-number.label')}
							initialValue={fieldsData.phonenumber}
							shrink={false}
							className={classes.phoneField}
						/>
					)}

					{/* Show birthdate here if language select is enabled */}
					{languageSelectEnabled && registrationBirthDateEnabled ? (
						<DatePicker
							form={form}
							name="dateOfBirth"
							label={t('common.fields.birthdate.label')}
							initialValue={fieldsData.dateOfBirth}
							defaultValue="2000-01-01"
							maxDate={moment().format('YYYY-MM-DD')}
							keyboardOnly
						/>
					) : (
						<span />
					)}
				</div>
			)}
			{appRegistration && (
				<div className={classes.groupElements}>
					{appRegistration.showRegisterType && (
						<SelectField
							label={t('authentication.registration.personal-data.fields.register-type.label')}
							options={[
								{
									id: 'Algemeen_fysiotherapeut',
									name: t(
										'authentication.registration.personal-data.fields.register-type.options.general-physiotherapist'
									),
								},
								{
									id: 'Keurmerk_fysiotherapie',
									name: t(
										'authentication.registration.personal-data.fields.register-type.options.quality-mark-physiotherapy'
									),
								},
								{
									id: 'Kwaliteitsdeel',
									name: t('authentication.registration.personal-data.fields.register-type.options.quality-part'),
								},
								{
									id: 'Manueeltherapeut',
									name: t('authentication.registration.personal-data.fields.register-type.options.manual-therapist'),
								},
								{
									id: 'Sportfysiotherapeut',
									name: t('authentication.registration.personal-data.fields.register-type.options.sports-therapist'),
								},
							]}
							form={form}
							name="registerType"
							shrink={false}
							initialValue={fieldsData.registrationType}
						/>
					)}
					{appRegistration.showRegisterCode && (
						<div>
							<TextField
								form={form}
								name="registerCode"
								label={t('authentication.registration.personal-data.fields.register-code.label')}
								initialValue={fieldsData.registerCode}
								shrink={false}
							/>
							<FormHelperText>
								{t('authentication.registration.personal-data.fields.register-code.helper-text')}
							</FormHelperText>
						</div>
					)}
				</div>
			)}
		</React.Fragment>
	);
};

export default PersonalDataForm;
