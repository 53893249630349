export const styles = (theme) => ({
    root: {
        display: 'flex',
        width: '100%',
    },
    container: {
        paddingTop: '4.8rem',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            padding: '100px',
        },
    },
    header: {
        fontWeight: 'bold',
        marginBottom: '7rem',
    },
    loader: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    wrapper: {
        ...theme.manakin.defaultWrapper,
        [theme.breakpoints.up('md')]: {
            maxWidth: '500px',
            paddingLeft: '0',
            margin: '0',
        },
    },
    formControlRoot: {
        width: '100%',
        minHeight: '10rem',
        padding: '1rem',
        borderTop: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
        margin: '0',
        '&:last-child': {
            borderBottom: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
        },
        [theme.breakpoints.up('md')]: {
            padding: '1rem 3.4rem',
            borderLeft: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
            borderRight: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
        },
    },
    active: {
        backgroundColor: theme.manakin.correctAnswer[500],
        '& $formControlLabel': {
            color: theme.palette.getContrastText(
                theme.manakin.correctAnswer[500]
            ),
        },
    },
    formControlLabel: {
        color: theme.manakin.defaultContentColor[500],
        fontFamily: theme.manakin.defaultAnswerFont,
        fontSize: '1.6rem',
        lineHeight: '3.2rem',
        margin: '0 0 0 1rem',
        [theme.breakpoints.up('md')]: {
            margin: '0 0 0 3rem',
            fontSize: '1.8rem',
            lineHeight: '3.8rem',
        },
    },
    formControl: {
        marginBottom: '2rem',
    },
    outer: {
        border: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
        backgroundColor: 'white',
        width: '30px',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inner: {
        backgroundColor: theme.palette.primary[500],
        width: '10px',
        height: '10px',
    },
    buttons: {
        display: 'inline-block',
        width: '100%',
        position: 'relative',
    },
    button: {
        margin: '20px',
        width: 'calc(100% - 40px)!important',
        '& span': {
            justifyContent: 'space-between',
            paddingLeft: '2rem',
            paddingRight: '2rem',
        },
        [theme.breakpoints.up('md')]: {
            width: '100%!important',
            margin: '0',
        },
    },
    longArrow: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    },
    feedback: {
        position: 'relative',
        left: '0',
        zIndex: '2',
        opacity: '0',
        height: 0,
        width: '100%',
        overflow: 'hidden',
        pointerEvents: 'none',
        transition: 'opacity .3s',
    },
    activeFeedback: {
        opacity: '1',
        pointerEvents: 'auto',
        height: 'auto',
    },
    question: {
        [theme.breakpoints.up('md')]: {
            width: '50%',
        },
    },
    correct: {
        border: '2px solid',
        borderColor: 'black',
        '&:last-child': {
            borderColor: 'black',
            borderBottom: '2px solid black',
        },
    },
});
