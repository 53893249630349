import React from 'react';
import { Loader } from '@manakin/app-core';
import { withStyles } from '@material-ui/core/styles';
import Rating from './Rating';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
    loader: {
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

const RatingView = (props) => {
    const { classes, onReset, preview = false, onClose } = props;
    const { t } = useTranslation();

    if (props.loading) {
        return (
            <div className={classes.loader}>
                <Loader/>
            </div>
        );
    } else if (props.workforms) {
        return (
            <React.Fragment>
                <Rating
                    prefix={props.title}
                    ratingData={{ ...props.ratingData }}
                    workforms={[ ...props.workforms ]}
                    options={{ ...props.options }}
                    onFinish={props.onFinish}
                    onReset={onReset}
                    preview={preview}
                    onClose={onClose}
                />
            </React.Fragment>
        );
    } else {
        return t("common.something-went-wrong");
    }
};

export default withStyles(styles)(RatingView);
