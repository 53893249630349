import React, { useEffect, useState } from 'react';
import { withWorkforms } from '@manakin/app-core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { changeColor } from '@manakin/app-core/ColorService/actions';
import BettingGameView from './BettingGameView';
import { GQL_FETCH_STYLESHEET, GQL_FETCH_GLOBAL_SETTINGS } from './graphql';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { useLocation } from 'react-router-dom';
const queryString = require('query-string');
import { useTranslation } from 'react-i18next';
import { graphql } from 'react-apollo';

const PreviewBettingGame = (props) => {
    const { match, workformsData, changeColor, SETTINGS = {} } = props;
    const { settings = {} } = SETTINGS;
    const query = match.params.query;
    const elementData = queryString.parse(query);
    const [items, setItems] = useState([]);
    const [itemTypes, setItemTypes] = useState([]);
    const location = useLocation();
    const { t } = useTranslation();

    const [getStyling, { data = {}, loading }] = useLazyQuery(GQL_FETCH_STYLESHEET, {
		variables: {
			id: elementData.styleSheet || '',
		},
	});

    useEffect(() => {
        getStyling()
    }, [])

		useEffect(() => {
			if (elementData && elementData.items) {
				if (Array.isArray(elementData.items)) {
					setItems(elementData.items);
					setItemTypes(elementData.itemTypes);
				} else {
					setItems([elementData.items]);
					setItemTypes([elementData.itemTypes]);
				}
			}
		}, [query]);

    useEffect(() => {
        if (!loading) {
            const obj = {
                ...elementData,
                headerImage: {
                    url: elementData.headerImage,
                },
                image: {
                    url: elementData.image,
                },
                workforms: [
                    ...items.map((item, idx) => ({
                        id: item,
                        type: itemTypes[idx],
                    })),
                ],
                options: {
                    showFeedback: false,
                    nextButton: false,
                },
            };
            changeColor({
                ...data.styleSheet,
                location: location.pathname,
            });
            workformsData.loadWorkforms({ ...obj });
        }
    }, [loading, items, itemTypes]);

    if (!elementData.items) return t("app.elements.betting-game.preview.no-connected-workforms");

    return (
		<BettingGameView
			settings={settings.settings || []}
			workformsData={workformsData}
			bettingGame={elementData}
			controls={false}
		/>
	);
};

export default compose(
	connect(null, (dispatch) => ({
		changeColor: (styleSheet) => dispatch(changeColor(styleSheet)),
	})),
	graphql(GQL_FETCH_GLOBAL_SETTINGS, {
		name: 'SETTINGS',
	}),
	withWorkforms()
)(PreviewBettingGame);
