import { createSelector } from 'reselect';
import { getCoreState } from '../selectors';

export const BREADCRUMBS_STATE_ROOT = 'breadcrumbs';

const getState = createSelector(
    [getCoreState],
    (coreState) => coreState[BREADCRUMBS_STATE_ROOT]
);

export const setBreadcrumb = () => createSelector([getState], (state) => state);

export const getBreadcrumb = () => createSelector([getState], (state) => state);
