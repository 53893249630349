import gql from 'graphql-tag';
import { fileAsset } from '@manakin/app-core/graphQlHelpers';

export const GQL_FETCH_GLOBAL_SETTINGS = gql`
    query {
        settings {
            settings {
                name
                value
                file {
                    ${fileAsset}
                }
            }
        }
    }
`;

export const GQL_FETCH_BOX = gql`
    query($id: ID!) {
        box(id: $id) {
            id
            name
        }
    }
`;

export const GQL_FETCH_APP_USER = gql`
    query($id: ID!) {
        appUser(id: $id) {
            id
            dateOfBirth
            fullName
        }
    }
`;

export const GQL_DELETE_BOX_RESULTS = gql`
    mutation($id: ID!) {
        deleteBoxResult(id: $id) {
            result
        }
    }
`;
