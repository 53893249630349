import gql from 'graphql-tag';
import { asset } from '@manakin/app-core/graphQlHelpers';

export const GQL_FETCH_SLIDESHOW = gql`
    query($id: ID!) {
        slideshowCreator(id: $id) {
            id
            title
            introTitle
            assets {
                asset {
                    ${asset}
                }
            }
            styleSheet {
                id
                indicationColor
                defaultWorkformColor
                primaryWorkformColor
                secondaryWorkformColor
                themeColor
            }
            slideshows {
                id
                title
                isPublic
                slides {
                    id
                    title
                    text
                    asset {
                        ${asset}
                    }
                }
            }
        }
    }
`;

export const GQL_SAVE_SLIDESHOW = gql`
	mutation upsertSlideshow(
		$id: ID
		$title: String!
		$introduction: String
		$backgroundImage: AssetInput
		$slideshowCreator: ID
		$slides: [SlideInput]
		$isPublic: Boolean
	) {
		upsertSlideshow(
			input: {
				id: $id
				title: $title
				introduction: $introduction
				backgroundImage: $backgroundImage
				slideshowCreator: $slideshowCreator
				slides: $slides
				isPublic: $isPublic
			}
		) {
			slideshow {
				id
			}
		}
	}
`;

export const GQL_DELETE_SLIDESHOW = gql`
	mutation ($id: ID!) {
		deleteWorkform(id: $id) {
			result
		}
	}
`;

export const GQL_FETCH_STYLESHEET = gql`
	query styleSheet($id: ID!) {
		styleSheet(id: $id) {
			id
			title
			description
			customStyle
			indicationColor
			defaultWorkformColor
			primaryWorkformColor
			secondaryWorkformColor
			themeColor
		}
	}
`;
