import React from "react";
import { connect } from "react-redux";
import ChangePasswordForm from "../ChangePasswordForm";
import Typography from "@material-ui/core/Typography";
import { withRouter, useHistory } from "react-router-dom";
import { compose } from "recompose";
import { showSnackbarMessage } from "@manakin/core/actions";
import useStyles from "./styles";
import { GQL_CHANGE_PASSWORD } from "../graphql";
import { useMutation } from "@apollo/react-hooks";
import { getErrorMessage } from "../lib";
import {useTranslation} from 'react-i18next';

const ConfirmReset = (props) => {
	const classes = useStyles();
	const history = useHistory();
	const { t } = useTranslation();

	//mutation hooks
	const [changePassword, { data }] = useMutation(GQL_CHANGE_PASSWORD);

	//functions
	const handleSubmit = (event) => {
		onChangePassword({
			password: event.password,
			token: props.match.params.token,
		});
	};

	const onChangePassword = (event) => {
		changePassword({
			variables: {
				password: event.password,
				token: event.token,
			},
		}).then((data) => {
			if (!data.errors) {
				props.processSuccess(t);
				history.push("/");
			} else {
				let errorMessage = t("common.something-went-wrong");
				data.errors.forEach((error) => {
					const { message = "" } = error || {};
					errorMessage = getErrorMessage(message, t);
				});
				props.processError(errorMessage);
			}
		});
	};

	return (
		<div className={classes.root}>
			<div className={classes.smallWrapper}>
				<Typography classes={{ h3: classes.heading }} variant="h3">
					{t("authentication.confirm-reset.title")}
				</Typography>
				<ChangePasswordForm
					submitLabel={t("authentication.confirm-reset.title")}
					onSubmit={handleSubmit}
				/>
			</div>
		</div>
	);
};

export default compose(
	connect(
		null,
		(dispatch) => ({
			processError: (message) => {
				dispatch(
					showSnackbarMessage({
						text: message,
						variant: "error",
					})
				);
			},
			processSuccess: (t) => {
				dispatch(
					showSnackbarMessage({
						text: t("authentication.confirm-reset.save-success"),
						variant: "success",
					})
				);
			},
		})
	),
	withRouter
)(ConfirmReset);
