"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _styles = require("@material-ui/core/styles");

var _Button = require("@material-ui/core/Button");

var _Button2 = _interopRequireDefault(_Button);

var _Dialog = require("@material-ui/core/Dialog");

var _Dialog2 = _interopRequireDefault(_Dialog);

var _DialogContent = require("@material-ui/core/DialogContent");

var _DialogContent2 = _interopRequireDefault(_DialogContent);

var _Typography = require("@material-ui/core/Typography");

var _Typography2 = _interopRequireDefault(_Typography);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _Chip = require("./Chip");

var _Chip2 = _interopRequireDefault(_Chip);

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var useStyles = (0, _styles.makeStyles)(function (theme) {
	return {
		root: {
			textAlign: "center",
			paddingBottom: "50px"
		},
		title: {
			color: theme.manakin.defaultContentColor[100]
		},
		ranking: {
			marginBottom: "26px"
		},
		row: _defineProperty({
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			borderBottom: "1px solid " + theme.manakin.defaultBorderColor[300],
			minHeight: "40px"
		}, theme.breakpoints.up("md"), {
			width: "500px",
			minHeight: "80px"
		}),
		content: {
			margin: 0
		},
		feedback: {
			textAlign: "left",
			padding: "36px 0",
			borderBottom: "1px solid " + theme.manakin.defaultBorderColor[300]
		},
		signatureContainer: _defineProperty({
			display: "flex",
			alignItems: "center",
			marginTop: theme.spacing()
		}, theme.breakpoints.down("xs"), {
			marginTop: theme.spacing(2)
		}),
		signature: {
			width: "50%",
			marginRight: "50px"
		},
		button: {
			marginTop: "20px"
		},
		rowTitle: {
			textAlign: "left",
			marginRight: theme.spacing()
		},
		rowContent: {
			textAlign: "right"
		}
	};
});

var DialogComponent = function DialogComponent(props) {
	var open = props.open,
	    onClose = props.onClose,
	    data = props.data,
	    _props$element = props.element,
	    element = _props$element === undefined ? {} : _props$element,
	    attempt = props.attempt,
	    title = props.title;

	var classes = useStyles();

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var handleClose = function handleClose() {
		onClose();
	};

	return _react2.default.createElement(
		_Dialog2.default,
		{ onClose: handleClose, open: open },
		_react2.default.createElement(
			_DialogContent2.default,
			null,
			_react2.default.createElement(
				"div",
				{ className: classes.root },
				_react2.default.createElement(_Chip2.default, { ranking: data.ranking, className: classes.ranking }),
				_react2.default.createElement(
					_Typography2.default,
					{ variant: "h4" },
					title
				),
				_react2.default.createElement(
					"div",
					{ className: classes.row },
					_react2.default.createElement(
						_Typography2.default,
						{
							variant: "h6",
							className: (0, _classnames2.default)(classes.content, classes.title, classes.rowTitle)
						},
						t("app.rating-request.detail-dialog.description")
					),
					_react2.default.createElement(
						_Typography2.default,
						{ className: (0, _classnames2.default)(classes.content, classes.rowContent) },
						element.title || ""
					)
				),
				_react2.default.createElement(
					"div",
					{ className: classes.row },
					_react2.default.createElement(
						_Typography2.default,
						{
							variant: "h6",
							className: (0, _classnames2.default)(classes.content, classes.title, classes.rowTitle)
						},
						t("app.rating-request.detail-dialog.date-and-time")
					),
					_react2.default.createElement(
						_Typography2.default,
						{ className: (0, _classnames2.default)(classes.content, classes.rowContent) },
						(0, _moment2.default)(data.gradedAt).locale(t("common.locale-code")).format("DD MMM YYYY HH:mm")
					)
				),
				_react2.default.createElement(
					"div",
					{ className: classes.feedback },
					_react2.default.createElement(
						_Typography2.default,
						{ variant: "h6", className: classes.title },
						t("common.explanation")
					),
					_react2.default.createElement(
						_Typography2.default,
						{ className: classes.content },
						data.explanation || ""
					)
				),
				_react2.default.createElement(
					"div",
					{ className: classes.signatureContainer },
					_react2.default.createElement(
						"figure",
						{ className: classes.signature },
						_react2.default.createElement("img", { src: data.signature && data.signature.url })
					),
					_react2.default.createElement(
						"div",
						{ className: classes.trainer },
						_react2.default.createElement(
							_Typography2.default,
							{
								variant: "h6",
								className: classes.content
							},
							t("common.trainer")
						),
						_react2.default.createElement(
							_Typography2.default,
							{ className: classes.content },
							data.rater.fullName
						)
					)
				),
				_react2.default.createElement(
					_Button2.default,
					{
						color: "primary",
						variant: "contained",
						className: classes.button,
						fullWidth: true,
						onClick: handleClose
					},
					t("common.close")
				)
			)
		)
	);
};

exports.default = DialogComponent;