import React from "react";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import {useTranslation} from 'react-i18next';

const SuccessPage = (props) => {
	const { classes, licenses } = props;
	const { t } = useTranslation();

	return (
		<div className={classes.root}>
			<form className={classes.container}>
				<div className={classes.content}>
					<Typography
						classes={{ body1: classes.smallParagraph }}
						variant="body1"
						gutterBottom
					>
						{t("authentication.confirm-registration.success.title")}
					</Typography>
					<Typography classes={classes.heading} variant="h2">
						{t("authentication.confirm-registration.success.description")}
					</Typography>
					<Typography
						classes={{ body1: classes.paragraph }}
						variant="body1"
						gutterBottom
					>
						{t("authentication.confirm-registration.success.thanks-note", { whitelabel: t("common.whitelabel") })}
					</Typography>

					{licenses.length > 0 && (
						<Link className={classes.link} to={"/dashboard"}>
							<Button variant="contained" color="primary">
								{t("authentication.confirm-registration.success.action")}
							</Button>
						</Link>
					)}
					{licenses.length === 0 && (
						<Link className={classes.link} to={"/auth/no-license"}>
							<Button variant="contained" color="primary">
								{t("authentication.confirm-registration.success.action")}
							</Button>
						</Link>
					)}
				</div>
			</form>
		</div>
	);
};

export default SuccessPage;
