'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.GQL_FETCH_ELEMENT_RESULT = exports.GQL_CREATE_ELEMENT_RESULT = undefined;

var _templateObject = _taggedTemplateLiteral(['\n    mutation(\n        $user: ID!\n        $program: ID!\n        $box: ID!\n        $element: ID!\n        $finished: Boolean\n        $bestTimes: [Int]\n    ) {\n        createElementResult(\n            input: {\n                user: $user\n                program: $program\n                box: $box\n                element: $element\n                finished: $finished\n                bestTimes: $bestTimes\n            }\n        ) {\n            result {\n                finished\n            }\n        }\n    }\n'], ['\n    mutation(\n        $user: ID!\n        $program: ID!\n        $box: ID!\n        $element: ID!\n        $finished: Boolean\n        $bestTimes: [Int]\n    ) {\n        createElementResult(\n            input: {\n                user: $user\n                program: $program\n                box: $box\n                element: $element\n                finished: $finished\n                bestTimes: $bestTimes\n            }\n        ) {\n            result {\n                finished\n            }\n        }\n    }\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n    query($box: ID!, $program: ID!, $user: ID!, $element: ID!) {\n        elementResult(\n            box: $box\n            program: $program\n            user: $user\n            element: $element\n        ) {\n            finished\n            element {\n                id\n                title\n            }\n        }\n    }\n'], ['\n    query($box: ID!, $program: ID!, $user: ID!, $element: ID!) {\n        elementResult(\n            box: $box\n            program: $program\n            user: $user\n            element: $element\n        ) {\n            finished\n            element {\n                id\n                title\n            }\n        }\n    }\n']);

var _graphqlTag = require('graphql-tag');

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_CREATE_ELEMENT_RESULT = exports.GQL_CREATE_ELEMENT_RESULT = (0, _graphqlTag2.default)(_templateObject);

var GQL_FETCH_ELEMENT_RESULT = exports.GQL_FETCH_ELEMENT_RESULT = (0, _graphqlTag2.default)(_templateObject2);