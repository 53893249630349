import React, { useMemo } from 'react';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import { GQL_FETCH_GLOBAL_SETTING } from './graphql';
import { useQuery } from '@apollo/react-hooks';
import Planner from './Planner';
import { SettingsKeys } from '@manakin/core/lib/constants';
const { CALENDAR_FUNCTION_ACTIVE } = SettingsKeys;

const useStyles = makeStyles((theme) => ({
	buttonRoot: {
		textAlign: 'left',
		width: '100%',
		marginBottom: '40px',
		[theme.breakpoints.up('md')]: {
			textAlign: 'right',
			marginBottom: '20px',
		},
	},
	calendarButton: {
		marginBottom: '20px',
	},
}));

const AltContent = (props) => {
	const { data } = props;
	const { examTrainerTags: unsortedExamTrainerTags = [] } = data || {};
	const classes = useStyles();
	const { t } = useTranslation();
	const history = useHistory();

	const { data: settingsData } = useQuery(GQL_FETCH_GLOBAL_SETTING, {
		variables: {
			name: CALENDAR_FUNCTION_ACTIVE,
		},
	});
	const { setting } = settingsData || {};
	const showCalendar = setting ? setting.value && setting.value === 'true' : false;
	
	const examTrainerTags = useMemo(() => {
		return unsortedExamTrainerTags.sort((a, b) => {
			const nameA = a.name.toLocaleUpperCase();
			const nameB = b.name.toLocaleUpperCase();
			if (nameA > nameB) return 1;
			else if (nameA < nameB) return -1;
			return 0;
		});
	}, [unsortedExamTrainerTags]);

	const handleClick = () => {
		history.push({
			pathname: `/ExamTrainer/${data.id}/programTrainer/start`,
			state: {
				tags: examTrainerTags,
				programId: data.id,
				category: data.category && data.category.id
			},
		});
	};

	return (
		<div>
			<div className={classes.buttonRoot}>
				{showCalendar && <Planner className={classes.calendarButton} />}
				{examTrainerTags && examTrainerTags.length > 0 && (
					<React.Fragment>
						<Button onClick={handleClick} color='secondary' variant='contained' className={classes.button}>
							{t('app.dashboard.alt-content.start-exam')}
						</Button>
					</React.Fragment>
				)}
			</div>
		</div>
	);
};

export default AltContent;
