import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { WorkformLayout, LongArrow } from '@manakin/app-core';
import { FeedbackBox } from '@manakin/app-workforms';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import { styles } from './styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withTranslation } from 'react-i18next';
import { isDone } from '../lib';
import renderHTML from 'react-render-html';

class TextCheckQuestion extends React.PureComponent {
	handleChange = (idx) => {
		if (this.props.onChange) this.props.onChange(idx);
	};

	handleSubmit = () => {
		if (this.props.onSubmit) this.props.onSubmit();
	};

	render() {
		const { classes, data, onFinished, disabled = false, t, saving, onSetWorkformDone, direction } = this.props;
		const { correct } = data;

		return (
			<WorkformLayout
				question={data.question || ''}
				instruction={data.introduction || t('app.workforms.text-check-question.introduction')}
				loading={false}
				saving={saving}
				direction={direction}
				renderAnswers={() => (
					<div className={classes.answers}>
						<FormControl component="fieldset" className={classes.formControl} disabled={isDone(data)}>
							<FormGroup>
								{data.answers &&
									data.answers.map((item, idx) => (
										<FormControlLabel
											key={item.id}
											classes={{
												root: classes.formControlRoot,
												label: classes.formControlLabel,
											}}
											className={classNames({
												[classes.active]: data.answers[idx] && data.answers[idx].selected,
												[classes.correct]: isDone(data) && !correct && item.correct,
											})}
											control={
												<Checkbox
													checked={data.answers[idx] && data.answers[idx].selected}
													onChange={() => this.handleChange(idx)}
													value={item.id}
													icon={<div className={classes.outer} />}
													checkedIcon={
														<div className={classes.outer}>
															<div className={classes.inner} />
														</div>
													}
												/>
											}
											label={renderHTML(item.title)}
										/>
									))}
							</FormGroup>
						</FormControl>
						<div className={classes.buttons}>
							{!data.feedback ? (
								<Button
									variant="contained"
									color="primary"
									fullWidth
									className={classNames(classes.button, {
										[classes.hide]: data.feedback || false,
									})}
									onClick={this.handleSubmit}
									disabled={disabled}
								>
									{data.buttonText || t('common.check-answer')}
									<LongArrow className={classes.longArrowRoot} />
								</Button>
							) : (
								<div
									className={classNames(classes.feedback, {
										[classes.activeFeedback]: data.feedback,
									})}
								>
									<FeedbackBox data={data} onFinished={onFinished} outlined={true} onSetWorkformDone={onSetWorkformDone} />
								</div>
							)}
						</div>
					</div>
				)}
			/>
		);
	}
}

export default compose(withStyles(styles, { name: 'AppCheckQuestion' }), withTranslation())(TextCheckQuestion);
