import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent, DialogContentText, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
	dialogPaper: {
		flexFlow: 'row wrap',
		padding: theme.spacing(6),
	},
	dialogTitle: {
		width: '100%',
		margin: 0,
		padding: '12px 0',
		fontSize: '2.5rem',
		lineHeight: '2.9rem',
		[theme.breakpoints.up('md')]: {
			paddingTop: 0,
			paddingBottom: 32
		},
	},
	dialogActions: {
		display: 'flex',
		flexFlow: 'row wrap',
		flexDirection: 'column',
		width: '100%',
		marginTop: theme.spacing(2),
	},
	button: {
		borderRadius: 0,
        paddingTop: '2.25rem',
        paddingBottom: '2.25rem',
        fontSize: '1.8rem',

        '& .MuiButton-label': {
            fontSize: '1.8rem',
        }
	},
	ok: {
		backgroundColor: theme.manakin.primaryColor[500],
		color: theme.palette.getContrastText(theme.manakin.primaryColor[500]),
		marginBottom: theme.spacing(),
		'&:hover': {
			backgroundColor: theme.manakin.primaryColor[700],
		},
	},
}));

const StyledDialog = (props) => {
	const { t } = useTranslation();
	const {
		onClose,
		open,
		title,
		contentText,
		extraContent,
		positiveButtonText = t('common.ok'),
		onPositiveButtonClick,
		negativeButtonText,
		onNegativeButtonClick,
	} = props;
	const classes = useStyles();

	return (
		<Dialog open={open} onClose={onClose} PaperProps={{ className: classes.dialogPaper }}>
			{title && (
				<Typography variant='h3' className={classes.dialogTitle}>
					{title}
				</Typography>
			)}

			<DialogContent>
				<DialogContentText>{contentText}</DialogContentText>
				{extraContent}
			</DialogContent>

			<div className={classes.dialogActions}>
				{positiveButtonText && (
					<Button
						variant="contained"
						className={classNames(classes.button, classes.ok)}
						onClick={onPositiveButtonClick || onClose}
					>
						{positiveButtonText}
					</Button>
				)}
				{negativeButtonText && (
					<Button variant="contained" className={classes.button} onClick={onNegativeButtonClick || onClose}>
						{negativeButtonText}
					</Button>
				)}
			</div>
		</Dialog>
	);
};

export default StyledDialog;
