import React from 'react';
import { useStyles } from '../styles';

const Image = (props) => {
    const { data = {} } = props;
    const classes = useStyles();

    return (
        <div className={classes.desktop}>
            <div className={classes.image}>
                <img src={data.image ? data.image.url || '' : ''} />
            </div>
        </div>
    );
};

export default Image;
