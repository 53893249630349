export default {
	HOME_PAGE_BACKGROUND_IMAGE: 'homePageBackgroundImage',
	HOME_PAGE_BACKGROUND_COLOR: 'homePageBackgroundColor',
	HOME_PAGE_BLOCKS: 'homePageBlocks',
	HOME_PAGE_BLOCK_PRIMARY_BACKGROUND_COLOR: 'homePageBlockPrimaryBackgroundColor',
	HOME_PAGE_BLOCK_SECONDARY_BACKGROUND_COLOR: 'homePageBlockSecondaryBackgroundColor',
	HOME_PAGE_WITTY_LOGO_ENABLED: 'homePageWittyLogoEnabled',
	CUSTOMER_LOGO: 'customerLogo',
	EXERCISE_CARD_ENABLE_TEACHER_MESSAGES: 'exercisecardEnableTeacherMessages',
	TEACHER_CAN_GIVE_FEEDBACK_ON_EXERCISE_CARD: 'teacherCanGiveFeedbackOnExerciseCard',
	ELEMENT_BETTING_GAME_ACTIVE: 'elementBettingGameActive',
	ELEMENT_BOOKCHECK_ACTIVE: 'elementBookCheckActive',
	ELEMENT_BOOK_DOWNLOAD_ACTIVE: 'elementBookDownloadActive',
	ELEMENT_BRIDGE_ACTIVE: 'elementBridgeActive',
	ELEMENT_CASE_ACTIVE: 'elementCaseActive',
	ELEMENT_DIGITAL_BOOK_ACTIVE: 'elementDigitalBookActive',
	ELEMENT_ENTRY_TEST_ACTIVE: 'elementEntryTestActive',
	ELEMENT_EXERCISE_CARD_ACTIVE: 'elementExerciseCardActive',
	ELEMENT_EXAM_TRAINING_ACTIVE: 'elementExamTrainingActive',
	ELEMENT_FACT_CARD_COLLECTION_ACTIVE: 'elementFactCardCollectionActive',
	ELEMENT_FILM_ACTIVE: 'elementFilmActive',
	ELEMENT_LESSON_ACTIVE: 'elementLessonActive',
	ELEMENT_MASTERCLASS_ACTIVE: 'elementMasterClassActive',
	ELEMENT_RATING_ACTIVE: 'elementRatingActive',
	ELEMENT_FINAL_RATING_ACTIVE: 'elementFinalRatingActive',
	ELEMENT_SELF_EVALUATION_ACTIVE: 'elementSelfEvaluationActive',
	ELEMENT_SLIDESHOW_CREATOR_ACTIVE: 'elementSlideshowCreatorActive',
	ELEMENT_SUMMARY_ACTIVE: 'elementSummaryActive',
	ELEMENT_TEST_ACTIVE: 'elementTestActive',
	ELEMENT_TRAINER_ACTIVE: 'elementTrainerActive',
	ELEMENT_VIDEO_BRIDGE_ACTIVE: 'elementVideoBridgeActive',
	BETTINGGAME_POINTS_PER_FINISHED_BOX: 'bettinggamePointsPerFinishedBox',
	BETTINGGAME_START_CAPITAL: 'bettinggameStartCapital',
	BETTINGGAME_GROUP_HIGH_SCORE: 'bettinggameGroupHighScore',
	SUBTITLES_ENABLED: 'subtitlesEnabled',
	REGISTRATION_GENDER_ENABLED: 'registrationGenderEnabled',
	REGISTRATION_BIRTH_DATE_ENABLED: 'registrationBirthDateEnabled',
	REGISTRATION_PHONE_NUMBER_ENABLED: 'registrationPhoneNumberEnabled',
	REGISTRATION_LANGUAGE_ENABLED: 'registrationLanguageEnabled',
	REGISTRATION_FUNCTION_ENABLED: 'registrationFunctionEnabled',
	REGISTRATION_EDUCATION_ORGANISATION_ENABLED: 'registrationEducationOrganisationEnabled',
	REGISTRATION_GENDER_REQUIRED: 'registrationGenderRequired',
	REGISTRATION_BIRTH_DATE_REQUIRED: 'registrationBirthDateRequired',
	REGISTRATION_PHONE_NUMBER_REQUIRED: 'registrationPhoneNumberRequired',
	REGISTRATION_FUNCTION_REQUIRED: 'registrationFunctionRequired',
	REGISTRATION_EDUCATION_ORGANISATION_REQUIRED: 'registrationEducationOrganisationRequired',
	HOMEPAGE_BLOCKS: 'homepageBlocks',
	BETTINGGAME_PREFIX: 'bettinggamePrefix',
	BOOKCHECK_FINISHED_RULES: 'bookcheckFinishedRules',
	BOOKCHECK_PREFIX: 'bookcheckPrefix',
	CASE_PREFIX: 'casePrefix',
	CMS_LOGO: 'cmsLogo',
	DISPLAY_NAME: 'displayName',
	EXAMINATION_BOARD_EMAIL: 'examinationBoardEmail',
	LOGIN_BUTTON_NAME: 'loginButtonName',
	LOGIN_BUTTON_URL: 'loginButtonUrl',
	FILE: 'file',
	GENERAL_BUTTON_CHECK_TEXT: 'generalButtonCheckText',
	HELP_F_A_Q_TITLE: 'helpFAQTitle',
	HELP_FEEDBACK_TEXT: 'helpFeedbackText',
	HELP_FEEDBACK_TITLE: 'helpFeedbackTitle',
	HELP_FEEDBACK_URL: 'helpFeedbackUrl',
	HELP_FEEDBACK_URL_TEXT: 'helpFeedbackUrlText',
	HELP_INTRODUCTION: 'helpIntroduction',
	HELP_MAIL_EMAIL: 'helpMailEmail',
	HELP_MAIL_TITLE: 'helpMailTitle',
	HELP_PHONE_NUMBER: 'helpPhoneNumber',
	HELP_PHONE_TITLE: 'helpPhoneTitle',
	HELP_TITLE: 'helpTitle',
	DEFAULT_BOX_COLOR: 'defaultBoxColor',
	DEFAULT_ELEMENT_COLOR: 'defaultElementColor',
	PRIMARY_ELEMENT_COLOR: 'primaryElementColor',
	SECONDARY_ELEMENT_COLOR: 'secondaryElementColor',
	PRIMARY_BUTTON_BACKGROUND_COLOR: 'primaryButtonBackgroundColor',
	PRIMARY_BUTTON_HOVER_BACKGROUND_COLOR: 'primaryButtonHoverBackgroundColor',
	PRIMARY_BUTTON_BORDER_COLOR: 'primaryButtonBorderColor',
	PRIMARY_BUTTON_HOVER_BORDER_COLOR: 'primaryButtonHoverBorderColor',
	SECONDARY_BUTTON_BACKGROUND_COLOR: 'secondaryButtonBackgroundColor',
	SECONDARY_BUTTON_HOVER_BACKGROUND_COLOR: 'secondaryButtonHoverBackgroundColor',
	SECONDARY_BUTTON_BORDER_COLOR: 'secondaryButtonBorderColor',
	SECONDARY_BUTTON_HOVER_BORDER_COLOR: 'secondaryButtonHoverBorderColor',
	PRIMARY_BACKGROUND_COLOR: 'primaryBackgroundColor',
	THEME_COLOR: 'themeColor',
	INDICATION_COLOR: 'indicationColor',
	PRIMARY_FONT_COLOR: 'primaryFontColor',
	BUTTON_BORDER_COLOR: 'buttonBorderColor',
	BUTTON_BORDER_RADIUS: 'buttonBorderRadius',
	HOMEPAGE_BUTTON_RADIUS: 'homepageButtonRadius',
	HOTSPOT_INSTRUCTION_TEXT: 'hotspotInstructionText',
	HOTSPOT_MAP_INSTRUCTION_TEXT: 'hotspotMapInstructionText',
	IMAGE_AND_TEXT_CHECK_QUESTION_INSTRUCTION_TEXT: 'imageAndTextCheckQuestionInstructionText',
	IMAGE_AND_TEXT_M_P_C_INSTRUCTION_TEXT: 'imageAndTextMPCInstructionText',
	IMAGE_CHECK_QUESTION_INSTRUCTION_TEXT: 'imageCheckQuestionInstructionText',
	IMAGE_M_P_C_INSTRUCTION_TEXT: 'imageMPCInstructionText',
	LESSON_FINISHED_RULES: 'lessonFinishedRules',
	LESSON_PREFIX: 'lessonPrefix',
	MIN_GRADE: 'minGrade',
	MODULE_REGISTERS_ENABLED: "moduleRegistersEnabled",
	MODULE_WEBSHOP_ENABLED: "moduleWebshopEnabled",
	MODULE_DIGITAL_BOOKS_ENABLED: "moduleDigitalBooksEnabled",
	MULTILANGUAGE_ENABLED: 'multilanguageEnabled',
	ORDER_QUESTION_INSTRUCTION_TEXT: 'orderQuestionInstructionText',
	PROJECT_NAME: 'projectName',
	SORT_QUESTION_INSTRUCTION_TEXT: 'sortQuestionInstructionText',
	STANDARD_ROLE: 'standardRole',
	STATEMENT_QUESTION_INSTRUCTION_TEXT: 'statementQuestionInstructionText',
	TEXT_CHECK_QUESTION_INSTRUCTION_TEXT: 'textCheckQuestionInstructionText',
	TEXT_M_P_C_INSTRUCTION_TEXT: 'textMPCInstructionText',
	TRAINER_PREFIX: 'trainerPrefix',
	WHAT_IS_WHAT_QUESTION_INSTRUCTION_TEXT: 'whatIsWhatQuestionInstructionText',
	CALENDAR_FUNCTION_ACTIVE: 'calendarFunctionActive',
	FACT_CARD_FILTER_CATEGORIES: 'factCardFilterCategories',
	FACT_CARD_FILTER_TAGS: 'factCardFilterTags',
	FACT_CARD_FILTER_NAME_CATEGORIES: 'factCardFilterNameCategories',
	FACT_CARD_FILTER_NAME_TAGES: 'factCardFilterNameTages',
	TAG_ORDER: 'tagOrder',
	TERMS: 'terms',
	USER_LOG_STATE_ENABLED: "userLogStateEnabled",
	USER_LOG_ENABLED: "userLogEnabled",
	SHOW_ACTIVE_USERS: "showActiveUsers",
	SHOW_ACTIVE_USERS_PROFILE_PICTURE: "showActiveUsersProfilePicture",
	SHOW_HOMEPAGE_NEWS: "showHomepageNews",
	SHOW_PROGRAM_BOXES_FILTER: "showProgramBoxesFilter",
	SHOW_COLOPHON: "showColophon",
	CUSTOMER_NAME: "customerName",
	SHOW_ASSET_UPLOAD: "showAssetUpload",
	EXPORT_REPORT_USER_LOG_ENABLED: "exportReportUserLogEnabled",
	EXPORT_REPORT_BASE_STATS_ENABLED: "exportReportBaseStatsEnabled",
	EXPORT_REPORT_CONTENT_ENABLED: "exportReportContentEnabled",
	EXPORT_REPORT_TAG_CONTENT_ENABLED: "exportReportTagContentEnabled",
	EXPORT_REPORT_GLOBAL_PROGRESSION_ENABLED: "exportReportGlobalProgressionEnabled",
	REGISTER_CODE_FIELD_ENABLED: "registerCodeFieldEnabled",
	REGISTER_CODE_FIELD_REQUIRED: "registerCodeFieldRequired",
	COPY_PROTECTION_ENABLED: "copyProtectionEnabled",
	STYLESHEET_LEFT_SIDE_IMAGE: "stylesheetLeftSideImage",
	STYLESHEET_RIGHT_SIDE_IMAGE: "stylesheetRightSideImage",
	STYLESHEET_BACKGROUND_IMAGE: "stylesheetBackgroundImage",
	DEMO_BUTTON_ON_LOGIN_PAGE_ENABLED: "demoButtonOnLoginPageEnabled",
	DEMO_ENVIRONMENT_ENABLED: "demoEnvironmentEnabled",
	CHANGE_LESSON_ENABLED: "changeLessonEnabled",
	USER_MAY_REDO_EXERCISE_CARD: "userMayRedoExerciseCard",
	USER_MAY_REVISE_EXERCISE_CARD_CHOICE: "userMayReviseExerciseCardChoice",
	SCRIPT_TAGS: "scriptTags"
};