import { StorageKeys } from '@manakin/core/lib/constants';

export function hasPermission(permission) {
    const privileges = JSON.parse(localStorage.getItem(StorageKeys.PRIVILEGES) || "[]")
    return privileges.indexOf(permission) !== -1
}

export const savePermissions = (roles) => {
    const privileges = [];
    roles.forEach(role => {
        role.privileges.forEach(privilege => {
            privileges.push(privilege.id);
        });
    });

    localStorage.setItem(StorageKeys.PRIVILEGES, JSON.stringify([...new Set(privileges)]));
};