import React from 'react';
import { useStyles } from '../styles';
import Button from '@material-ui/core/Button';
import {useTranslation} from 'react-i18next';

const ButtonGroup = (props) => {
    const { onSubmit, onNext, onBack, step } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    const handleClick = () => {
        step === 1 ? onSubmit() : onNext();
    };

    return (
        <div className={classes.buttonGroup}>
            <Button
                variant="contained"
                classes={{ root: classes.buttonRoot }}
                onClick={handleClick}
                color="primary"
            >
                {t("common.continue")}
            </Button>
            {step !== 0 && (
                <Button
                    classes={{ root: classes.buttonRoot }}
                    onClick={onBack}
                    color="primary"
                >
                    {t("common.back")}
                </Button>
            )}
        </div>
    );
};

export default ButtonGroup;
