"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _styles = require("./styles");

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _lib = require("@manakin/app-core/lib");

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var RatingInfo = function RatingInfo(props) {
	var data = props.data,
	    currentAppUser = props.currentAppUser;

	var _ref = data || {},
	    result = _ref.result;

	var _ref2 = result || {},
	    _ref2$ranking = _ref2.ranking,
	    ranking = _ref2$ranking === undefined ? "" : _ref2$ranking;

	var classes = (0, _styles.useStyles)();

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var isManagement = currentAppUser && currentAppUser.roles && currentAppUser.roles.some(function (role) {
		return _lib.managementRole.includes(role.name);
	});

	var getRating = function getRating(rate) {
		switch (rate) {
			case "GOOD":
				return t("app.rating-request.rating-abbreviated.good");
			case "INSUFFICIENT":
				return t("app.rating-request.rating-abbreviated.insufficient");
			case "SUFFICIENT":
				return t("app.rating-request.rating-abbreviated.sufficient");
			default:
				return "";
		}
	};

	if (!result && (currentAppUser.id === data.trainer.id || isManagement)) {
		return _react2.default.createElement(
			"div",
			{ className: classes.ratingNeeded },
			"!"
		);
	}

	return _react2.default.createElement(
		"div",
		{
			className: (0, _classnames2.default)(classes.rating, _defineProperty({}, classes.good, ranking === "GOOD"), _defineProperty({}, classes.suffiecient, ranking === "SUFFICIENT"), _defineProperty({}, classes.inSuffiecient, ranking === "INSUFFICIENT"))
		},
		getRating(ranking)
	);
};

exports.default = RatingInfo;