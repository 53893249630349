export const styles = (theme) => ({
    root: {
        minHeight: '1px',
    },
    bubbles: {
        padding: '0 1.8rem',
    },
    bubble: {
        transform: 'translateX(-20px)',
        opacity: 0,
        height: 0,
        overflow: 'hidden',
    },
    rightPosition: {
        transform: 'translateX(20px)',
    },
    animate: {
        transform: 'translateX(0)',
        opacity: 1,
        height: 'auto',
    },
    showAnimate: {
        transform: 'translateX(0)',
        opacity: 1,
        height: 'auto',
        transition: 'opacity 0s 0s, transform 0s 0s',
    },
    questions: {
        height: 0,
        opacity: 0,
        transition: 'opacity .3s .3s',
        [theme.breakpoints.up('md')]: {
            ...theme.manakin.defaultWrapper,
        },
    },
    show: {
        height: 'auto',
        opacity: 1,
    },
});
