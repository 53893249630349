import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { getGradeFromItems, Loader } from '@manakin/app-core';
import { ENTRY_EXAM_TRAINER_ROOT_PATH } from '../Router/Router';
import { GQL_CREATE_EXAM_TRAINER_SCORE, GQL_FETCH_EXAM_TRAINER } from './graphql';
import { useMutation, useQuery } from '@apollo/react-hooks';

const element = (props) => {
	const location = useLocation();
	const params = useParams();
	const history = useHistory();
	const { state } = location;
	const { items = [] } = state || {};
	const grade = getGradeFromItems(items);

	const [mutate, { data: mutationData, loading, error }] = useMutation(GQL_CREATE_EXAM_TRAINER_SCORE);
	const { data } = useQuery(GQL_FETCH_EXAM_TRAINER, {
		variables: {
			id: params.elementId,
		},
	});
	const { examTrainer = {} } = data || {};

	const handleMutate = (input) => {
		mutate({
			variables: {
				input: input,
			},
		});
	};

	const push = (url) => {
		history.push({
			pathname: url,
			state: {
				items: items,
				tags: state.tags || null,
				category: state.category
			},
		});
	};

	useEffect(() => {
		if (params.elementId && examTrainer && !loading) {
			handleMutate({
				score: grade,
				examTrainerId: params.elementId,
				tags: examTrainer.examTrainerTags ? examTrainer.examTrainerTags.map((i) => i.id, []) : [],
			});
		}
	}, [examTrainer]);

	useEffect(() => {
		if (state.tags && params.programId) {
			handleMutate({
				score: grade,
				tags: state.tags,
				programId: params.programId,
			});
		}
	}, []);

	useEffect(() => {
		if ((!loading && mutationData) || error) {
			if (params.programId) {
				push(`/${ENTRY_EXAM_TRAINER_ROOT_PATH}/${params.programId}/programResults`);
			} else if (params.elementId) {
				push(`/${ENTRY_EXAM_TRAINER_ROOT_PATH}/${params.elementId}/results`);
			}
		}
	}, [loading, mutationData, error]);

	return <Loader fullScreen />;
};

export default element;
