'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _lib = require('../lib');

var _selectors = require('@manakin/app-core/Boxes/selectors');

var _selectors2 = require('@manakin/app-core/ProgramsDropdown/selectors');

var _selectors3 = require('@manakin/authentication/selectors');

var _reactRouterDom = require('react-router-dom');

var _recompose = require('recompose');

var _reactRedux = require('react-redux');

var _graphql = require('./graphql');

var _reactApollo = require('react-apollo');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var mapStateToProps = function mapStateToProps(state) {
    return {
        boxId: (0, _selectors.getBoxId)(state),
        appUser: (0, _selectors3.getAppUser)(state),
        program: (0, _selectors2.getProgram)(state)
    };
};

var ElementService = function (_React$PureComponent) {
    _inherits(ElementService, _React$PureComponent);

    function ElementService() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, ElementService);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = ElementService.__proto__ || Object.getPrototypeOf(ElementService)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
            savedEvents: [],
            savableWorkforms: []
        }, _this.handleSave = function (event) {
            var _this$state = _this.state,
                savedEvents = _this$state.savedEvents,
                savableWorkforms = _this$state.savableWorkforms;

            if (savedEvents.length == savableWorkforms.length) {
                _this.save();
            }
        }, _this.save = function () {
            var boxId = _this.props.boxId;
            var userId = _this.props.appUser.id;
            var elementId = _this.props.match.params.elementId;
            var programId = _this.props.program;
            if (userId && programId && boxId && elementId) {
                _this.props.onUpdate({
                    user: userId,
                    program: programId,
                    box: boxId,
                    element: elementId,
                    finished: true
                });
            }
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(ElementService, [{
        key: 'componentDidUpdate',
        value: function componentDidUpdate(prevProps) {
            var _this2 = this;

            if (prevProps.loading && !this.props.loading) {
                var savableWorkforms = (0, _lib.savable)(this.props.data.workforms);
                this.setState({ savableWorkforms: savableWorkforms });
            }

            if (prevProps.event != this.props.event) {
                var savedEvents = [].concat(_toConsumableArray(this.state.savedEvents));

                savedEvents.push(this.props.event.workform);
                this.setState({ savedEvents: savedEvents }, function () {
                    return _this2.handleSave();
                });
            }
        }
    }, {
        key: 'render',
        value: function render() {
            var children = this.props.children;


            return _react2.default.createElement(
                _react2.default.Fragment,
                null,
                children
            );
        }
    }]);

    return ElementService;
}(_react2.default.PureComponent);

exports.default = (0, _recompose.compose)(_reactRouterDom.withRouter, (0, _reactRedux.connect)(mapStateToProps), (0, _reactApollo.graphql)(_graphql.GQL_CREATE_ELEMENT_RESULT), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_ELEMENT_RESULT, {
    options: function options(props) {
        return {
            variables: {
                box: props.boxId || props.match.params.boxid,
                program: props.program,
                user: props.appUser.id,
                element: props.match.params.elementId
            }
        };
    }
}), (0, _recompose.withHandlers)({
    onUpdate: function onUpdate(_ref2) {
        var mutate = _ref2.mutate;
        return function (event) {
            return mutate({
                variables: _extends({}, event)
            }).then(function (result) {});
        };
    }
}))(ElementService);