import React from "react";
import { compose } from "recompose";
import { withForm } from "@manakin/core";
import { PersonalDataForm } from "./forms/PersonalDataForm";
import { SchoolDataForm } from "./forms/SchoolDataForm";
import { authDataForm } from "./forms/AuthDataForm";
import AppRegistrationView from "./AppRegistrationView";

const form = {
	...PersonalDataForm,
	...SchoolDataForm,
	...authDataForm,
};

const PasswordView = (props) => {
	const { form } = props;
	return <AppRegistrationView form={form} />;
};

export default compose(withForm(form))(PasswordView);
