'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _core = require('@material-ui/core');

var _appCore = require('@manakin/app-core');

var _graphql = require('../../graphql');

var _reactHooks = require('@apollo/react-hooks');

var _reactI18next = require('react-i18next');

var _hooks = require('@manakin/hooks');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useStyles = (0, _core.makeStyles)(function (theme) {
    return {
        warningBox: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing()
        }
    };
});

/**
 * If `cancelMode` is true, it is assumed that the invite should be cancelled. Otherwise it will default to disconnecting the user
 */
var InviteChangeTrainerDialog = function InviteChangeTrainerDialog(props) {
    var onClose = props.onClose,
        onSuccess = props.onSuccess,
        open = props.open,
        invite = props.invite;

    var classes = useStyles();
    var undoInvite = invite && invite.state === "INVITATION_SEND";

    var _useTranslation = (0, _reactI18next.useTranslation)(),
        t = _useTranslation.t;

    var snackBar = (0, _hooks.useSnackBar)();

    // Mutations

    var _useMutation = (0, _reactHooks.useMutation)(_graphql.GQL_CANCEL_INVITE),
        _useMutation2 = _slicedToArray(_useMutation, 2),
        cancelInvite = _useMutation2[0],
        _useMutation2$ = _useMutation2[1],
        data = _useMutation2$.data,
        error = _useMutation2$.error;

    var handleAction = function handleAction() {
        cancelInvite({
            variables: {
                id: invite.id
            }
        });
    };

    (0, _react.useEffect)(function () {
        if (data && data.cancelInvitation && data.cancelInvitation.result) {
            snackBar.success(undoInvite ? t("app.account.invite.cancel-dialog.success") : t("app.account.invite.user-disconnect-dialog.success"));
            onSuccess();
        }
        if (error) snackBar.error(undoInvite ? t("app.account.invite.cancel-dialog.failed") : t("app.account.invite.user-disconnect-dialog.failed"));
    }, [data, error]);

    return _react2.default.createElement(_appCore.StyledDialog, {
        onClose: onClose,
        open: open,
        title: undoInvite ? t('app.account.invite.cancel-dialog.title') : t('app.account.invite.user-disconnect-dialog.title'),
        contentText: undoInvite ? t('app.account.invite.cancel-dialog.description') : t('app.account.invite.user-disconnect-dialog.description'),
        extraContent: _react2.default.createElement(_appCore.WarningBox, {
            className: classes.warningBox,
            message: t('app.account.invite.cancel-dialog.warning') }),
        positiveButtonText: undoInvite ? t('app.account.invite.cancel-dialog.action') : t('app.account.invite.user-disconnect-dialog.action'),
        onPositiveButtonClick: handleAction,
        negativeButtonText: t('common.cancel'),
        onNegativeButtonClick: onClose });
};

exports.default = InviteChangeTrainerDialog;