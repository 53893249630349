import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { compose } from "recompose";
import { Loader, LoadImage } from "@manakin/app-core";
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
    root: {
        padding: '84px 30px',
        minHeight: 'calc(100vh - 100px)',
        position: 'relative',
        opacity: 0,
        animation: 'fadeIn .3s ease 1s forwards',
        [theme.breakpoints.up('md')]: {
            height: '100%',
            display: 'flex',
            padding: '0',
            alignItems: 'center',
            width: '100%',
        },
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'space-between',
        },
    },
    content: {
        position: 'relative',
        zIndex: '1',
        opacity: 0,
        animation: 'showBackwards .3s ease 1s forwards',
        [theme.breakpoints.up('md')]: {
            padding: '20px 20px 20px 80px',
            width: '70%',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '20px 20px 20px 130px',
            maxWidth: '600px',
        },
    },
    bgImage: {
        position: 'absolute',
        width: '50vw',
        bottom: 0,
        right: 0,
        opacity: 0,
        animation: 'show .3s ease 1s forwards',
        [theme.breakpoints.up('md')]: {
            width: '40%',
            height: '100%',
        },
    },
    button: {
        [theme.breakpoints.up('md')]: {
            width: 'auto!important',
        },
    },
    loader: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    headingRoot: {
        fontSize: '4rem',
        lineHeight: '5.4rem',
        letterSpacing: '1.6rem',
        marginBottom: '2.4rem',
        [theme.breakpoints.down('xs')]: {
            fontSize: '3rem',
            lineHeight: '4rem',
            letterSpacing: '.4rem',
        },
    },
    bodyRoot: {
        marginBottom: '3rem',
    },
});

const Header = function(props) {
    const { t } = useTranslation();

    const handleClick = () => {
        props.onClick();
    };

    const { classes, data, loading } = props;
    const image = data.image ? data.image.url : null;
    const placeholder = data.image ? data.image.thumbnailUrl : null;
    const dataObj = data || {};

    return (
        <React.Fragment>
            {!loading ? (
                <div className={classes.root}>
                    <div className={classes.content}>
                        <Typography
                            component='h1'
                            variant='h1'
                            classes={{ h1: classes.headingRoot }}
                        >
                            {dataObj.introTitle || ""}
                        </Typography>
                        <Typography
                            component='p'
                            variant='body2'
                            classes={{ body2: classes.bodyRoot }}
                        >
                            {dataObj.introText || ""}
                        </Typography>
                        <Button
                            variant='contained'
                            color='primary'
                            fullWidth
                            className={classes.button}
                            onClick={handleClick}
                        >
                            {t("app.elements.book-check.start-book-check")}
                        </Button>
                    </div>
                    {image && (
                        <div className={classes.bgImage}>
                            <LoadImage
                                src={image}
                                placeholder={placeholder}
                            />
                        </div>
                    )}
                </div>
            ) : (
                <div className={classes.loader}>
                    <Loader />
                </div>
            )}
        </React.Fragment>
    );
};

export default compose(
    withStyles(styles, { name: 'AppBookCheckHeader' })
)(Header);
