"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _recompose = require("recompose");

var _core = require("@manakin/core");

var _ChangePassword = require("./ChangePassword");

var _ChangePassword2 = _interopRequireDefault(_ChangePassword);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var form = {
    oldPassword: {
        required: true
    },
    password: {
        format: 'password',
        required: true
    },
    repeatPassword: {
        format: 'password',
        required: true
    }
};

var ChangePasswordView = function ChangePasswordView(props) {
    var form = props.form;

    return _react2.default.createElement(_ChangePassword2.default, _extends({ form: form }, props));
};

exports.default = (0, _recompose.compose)((0, _core.withForm)(form))(ChangePasswordView);