'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styles = require('@material-ui/core/styles');

var _recompose = require('recompose');

var _core = require('@material-ui/core');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = function styles(theme) {
    return {
        root: {
            border: '2px solid ' + theme.manakin.softBorderColor[500],
            borderRadius: 4,
            padding: theme.spacing(2),
            marginBottom: theme.spacing(3),
            position: "relative",
            '&::after': {
                content: "''",
                position: "absolute",
                bottom: -18,
                width: 0,
                height: 0,
                left: 22,
                border: "9px solid transparent",
                borderLeftColor: "#FFFFFF",
                transform: "rotate(90deg)"
            },
            '&::before': {
                content: "''",
                position: "absolute",
                bottom: -22.5,
                left: 20,
                width: 0,
                height: 0,
                border: "11px solid transparent",
                borderLeftColor: theme.manakin.softBorderColor[500],
                transform: "rotate(90deg)"
            }
        },
        text: {
            marginLeft: theme.spacing(1.5),
            marginRight: theme.spacing(1.5),
            marginBottom: 0
        }
    };
};

var ChatBox = function ChatBox(props) {
    var classes = props.classes,
        classNameProp = props.className,
        text = props.text;


    return _react2.default.createElement(
        'div',
        { className: (0, _classnames2.default)(classes.root, classNameProp) },
        _react2.default.createElement(
            _core.Typography,
            {
                component: 'div',
                variant: 'body1',
                className: classes.text
            },
            text
        )
    );
};

exports.default = (0, _recompose.compose)((0, _styles.withStyles)(styles, { name: 'ChatBox' }))(ChatBox);