import React, { useState } from 'react';
import { Loader } from '@manakin/app-core';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { backgroundColorKeys } from '../../constants';
import Header from './Header';

export const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {}
		}),
	{
		name: 'WelcomeAppWidget',
	}
);

const WelcomeWidget = (props) => {
	const { gridProps, loading, backgroundColor, programData, user, resume, data } = props;
	const { t } = useTranslation();
	const classes = useStyles();

	return (
        <Grid className={classes.root} item {...gridProps}>
			<Header {...data} data={programData} user={user} resume={resume} loading={loading} />
		</Grid>
	);
};

export default WelcomeWidget;
