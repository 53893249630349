import gql from "graphql-tag";
import { rating } from "@manakin/app-core/graphQlHelpers";
import { fileAsset } from '@manakin/app-core/graphQlHelpers';

export const GQL_FETCH_RATING = gql`
    query rating($id: ID!) {
        rating(id: $id) {
            ${rating}
        }
    }
`;

export const GQL_FETCH_CMS_BOX = gql`
	query ($id: ID!) {
		box(id: $id) {
			id
			elements {
				id
				type
			}
		}
	}
`;

export const GQL_FETCH_GLOBAL_SETTINGS = gql`
	query {
		settings {
			settings {
				name
				value
				file {
					${fileAsset}
				}
			}
		}
	}
`;

export const GQL_FETCH_STYLESHEET = gql`
	query styleSheet($id: ID!) {
		styleSheet(id: $id) {
			id
			title
			description
			customStyle
			indicationColor
			defaultWorkformColor
			primaryWorkformColor
			secondaryWorkformColor
			themeColor
		}
	}
`;
