import React, { useCallback } from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import classNames from 'classnames';
import { scroller } from "react-scroll";

const styles = (theme) => ({
	root: {
		display: "flex",
		alignItems: "center",
		flexGrow: 1,
		justifyContent: "center",
		textAlign: "center",
		flexDirection: "column",
		cursor: "pointer"
	},
	title: {
		color: theme.manakin.indicationColor[500],
		fontWeight: "400",
		marginBottom: 0
	},
	text: {
		marginBottom: 0,
		paddingBottom: '0 !important',
		color: theme.manakin.subHeaderColor[500],
		textAlign: "center",
		textTransform: "uppercase",
		fontWeight: "500",
	},
	subHeader: {
		fontWeight: "bold"
	},
	contentColor: {},
});

const InfoBlock = (props) => {
	const { classes, data = {} } = props;

	const handleStudentsClick = useCallback(() => {
		setTimeout(() => {
			scroller.scrollTo("assignedStudentsOverview", {
				duration: 400,
				offset: -150,
				smooth: "easeInOutQuint",
			});
		}, 1);
	}, []);

	return (
		<div className={classes.root} onClick={handleStudentsClick}>
			<Typography
				component="h1"
				variant="h3"
				className={classNames(
					classes.title
				)}>
				{data.value}
			</Typography>
			<Typography
				component="p"
				variant="body1"
				className={classNames(
					classes.subHeader,
					classes.contentColor,
					classes.text
				)}>
				{data.title}
			</Typography>
		</div>
	);
};

export default compose(
	withStyles(styles, { name: 'AppProgressDashboardInfoBlock' })
)(InfoBlock);
