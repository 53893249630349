'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _recompose = require('recompose');

var _styles = require('@material-ui/core/styles');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _Fade = require('@material-ui/core/Fade');

var _Fade2 = _interopRequireDefault(_Fade);

var _reactRedux = require('react-redux');

var _RatingRespondTab = require('./RatingRespondTab');

var _RatingRespondTab2 = _interopRequireDefault(_RatingRespondTab);

var _graphql = require('./graphql');

var _reactHooks = require('@apollo/react-hooks');

var _selectors = require('@manakin/authentication/selectors');

var _selectors2 = require('@manakin/app-core/ProgramsDropdown/selectors');

var _reactRouterDom = require('react-router-dom');

var _selectors3 = require('@manakin/app-core/Boxes/selectors');

var _reactI18next = require('react-i18next');

var _WarningBox = require('../WarningBox');

var _WarningBox2 = _interopRequireDefault(_WarningBox);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var mapStateToProps = function mapStateToProps(state) {
	return {
		appUser: (0, _selectors.getAppUser)(state),
		program: (0, _selectors2.getProgram)(state),
		boxId: (0, _selectors3.getBoxId)(state)
	};
};

var styles = function styles(theme) {
	var _root, _rootFinalRating;

	return {
		root: (_root = {
			display: 'flex',
			width: '100%',
			flexDirection: 'column',
			maxWidth: '100%',
			background: 'white',
			paddingBottom: '25px'
		}, _defineProperty(_root, theme.breakpoints.up('md'), {
			paddingBottom: '6rem'
		}), _defineProperty(_root, theme.breakpoints.up('lg'), {
			paddingBottom: '10rem'
		}), _root),
		rootFinalRating: (_rootFinalRating = {
			paddingTop: '25px'
		}, _defineProperty(_rootFinalRating, theme.breakpoints.up('md'), {
			paddingTop: '6rem'
		}), _defineProperty(_rootFinalRating, theme.breakpoints.up('lg'), {
			paddingTop: '10rem'
		}), _rootFinalRating),
		tabs: {
			display: 'flex',
			flexDirection: 'row'
		},
		tab: {
			padding: theme.spacing(3),
			width: '100%',
			justifyContent: 'center',
			display: 'flex',
			marginBottom: theme.spacing(6),
			cursor: 'pointer'
		},
		hiddenTabs: {
			display: 'none'
		},
		hiddenTab: {
			display: 'none'
		},
		tabTitle: {
			fontWeight: '700',
			marginBottom: 0
		},
		unselectedTab: {
			backgroundColor: theme.manakin.secondaryContentColor[100],
			'& > div': {
				color: theme.manakin.secondaryContentColor[500]
			}
		},
		contentColor: {},
		subHeader: {},
		wrongPoWarning: _extends({}, theme.manakin.smallWrapper, {
			maxWidth: '800px',
			marginBottom: theme.spacing(4)
		}),
		wrongPoWarningInTab: {
			marginTop: theme.spacing(6),
			marginBottom: 0
		}
	};
};

var RatingRespond = function RatingRespond(props) {
	var classes = props.classes,
	    data = props.data,
	    appUser = props.appUser,
	    isFinalRating = props.isFinalRating,
	    studentId = props.studentId;

	var _useState = (0, _react.useState)(0),
	    _useState2 = _slicedToArray(_useState, 2),
	    selectedTab = _useState2[0],
	    setSelectedTab = _useState2[1];

	var params = (0, _reactRouterDom.useParams)();

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	// Queries


	var _useQuery = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_RATING_REQUESTS, {
		variables: {
			pageNr: 0,
			pageSize: 999,
			filter: {
				programId: parseInt(props.program),
				elementId: parseInt(params.elementId),
				studentId: parseInt(params.studentId)
			}
		},
		onCompleted: function onCompleted(data) {
			if (!data) return;
			var _data$ratingRequests = data.ratingRequests,
			    ratingRequests = _data$ratingRequests === undefined ? [] : _data$ratingRequests;

			var firstRating = ratingRequests.find(function (rating) {
				return rating.attempt === 1;
			});
			var secondRating = ratingRequests.find(function (rating) {
				return rating.attempt === 2;
			});
			if (firstRating && firstRating.result && secondRating) {
				// First rating already done, pre-select second rating
				setSelectedTab(1);
			}
		}
	}),
	    loading = _useQuery.loading,
	    _useQuery$data = _useQuery.data,
	    ratingRequestData = _useQuery$data === undefined ? {} : _useQuery$data,
	    refetch = _useQuery.refetch;

	var _ratingRequestData$ra = ratingRequestData.ratingRequests,
	    ratingRequests = _ratingRequestData$ra === undefined ? [] : _ratingRequestData$ra;

	var firstRating = ratingRequests.find(function (rating) {
		return rating.attempt === 1;
	});
	var secondRating = ratingRequests.find(function (rating) {
		return rating.attempt === 2;
	});

	var allRatingsCompleted = firstRating && firstRating.result && secondRating && secondRating.result || isFinalRating && firstRating && firstRating.result;
	var currentTrainerIsNotRater = firstRating && !secondRating && firstRating.trainer.id !== appUser.id || firstRating && secondRating && secondRating.trainer.id !== appUser.id;

	var handleChangeTab = function handleChangeTab(tab) {
		// User wants to navigate to the second rating even though the first rating is not filled in
		if ((!firstRating || !firstRating.result) && tab === 1) return;

		// User wants to navigate to the second rating even though it does not exist
		if (!secondRating) return;

		setSelectedTab(tab);
	};

	// If there is only 1 request (with no result!), which is directed to another PO, only show the warning
	if (ratingRequests.length === 1 && firstRating && !firstRating.result && currentTrainerIsNotRater) {
		return _react2.default.createElement(
			'div',
			{ className: classes.wrongPoWarning },
			_react2.default.createElement(_WarningBox2.default, { message: t('app.rating-request.respond.wrong-trainer') })
		);
	}

	if (loading || ratingRequests.length === 0) return _react2.default.createElement('div', null);

	return _react2.default.createElement(
		'div',
		{
			className: (0, _classnames2.default)(classes.root, _defineProperty({}, classes.rootFinalRating, isFinalRating))
		},
		_react2.default.createElement(
			'div',
			{
				className: (0, _classnames2.default)(classes.tabs, _defineProperty({}, classes.hiddenTabs, isFinalRating))
			},
			_react2.default.createElement(
				'div',
				{
					onClick: function onClick() {
						return handleChangeTab(0);
					},
					className: (0, _classnames2.default)(classes.tab, _defineProperty({}, classes.unselectedTab, selectedTab !== 0))
				},
				_react2.default.createElement(
					_Typography2.default,
					{ component: 'div', variant: 'body1', className: (0, _classnames2.default)(classes.contentColor, classes.tabTitle) },
					t('app.rating-request.respond.first-rating')
				)
			),
			_react2.default.createElement(
				'div',
				{
					onClick: function onClick() {
						return handleChangeTab(1);
					},
					className: (0, _classnames2.default)(classes.tab, _defineProperty({}, classes.unselectedTab, selectedTab !== 1))
				},
				_react2.default.createElement(
					_Typography2.default,
					{ component: 'div', variant: 'body1', className: (0, _classnames2.default)(classes.contentColor, classes.tabTitle) },
					t('app.rating-request.respond.second-rating')
				)
			)
		),
		_react2.default.createElement(
			_Fade2.default,
			{ 'in': selectedTab === 0, timeout: 200 },
			_react2.default.createElement(
				'div',
				{
					className: (0, _classnames2.default)(_defineProperty({}, classes.hiddenTab, selectedTab !== 0))
				},
				_react2.default.createElement(_RatingRespondTab2.default, {
					onSuccess: refetch,
					active: selectedTab === 0,
					appUser: appUser,
					studentId: studentId,
					ratingRequest: firstRating,
					program: props.program,
					boxId: props.boxId,
					readOnly: currentTrainerIsNotRater,
					elementId: params.elementId
				}),
				currentTrainerIsNotRater && !allRatingsCompleted && _react2.default.createElement(
					'div',
					{ className: (0, _classnames2.default)(classes.wrongPoWarning, classes.wrongPoWarningInTab) },
					_react2.default.createElement(_WarningBox2.default, { message: t('app.rating-request.respond.wrong-trainer') })
				)
			)
		),
		_react2.default.createElement(
			_Fade2.default,
			{ 'in': selectedTab === 1, timeout: 200 },
			_react2.default.createElement(
				'div',
				{
					className: (0, _classnames2.default)(_defineProperty({}, classes.hiddenTab, selectedTab !== 1))
				},
				secondRating && _react2.default.createElement(_RatingRespondTab2.default, {
					onSuccess: refetch,
					active: selectedTab === 1,
					appUser: appUser,
					studentId: studentId,
					ratingRequest: secondRating,
					program: props.program,
					boxId: props.boxId,
					readOnly: currentTrainerIsNotRater,
					elementId: params.elementId
				}),
				currentTrainerIsNotRater && !allRatingsCompleted && _react2.default.createElement(
					'div',
					{ className: (0, _classnames2.default)(classes.wrongPoWarning, classes.wrongPoWarningInTab) },
					_react2.default.createElement(_WarningBox2.default, { message: t('app.rating-request.respond.wrong-trainer') })
				)
			)
		)
	);
};

exports.default = (0, _recompose.compose)((0, _styles.withStyles)(styles, { name: 'AppStudentRatings' }), _reactRouterDom.withRouter, (0, _reactRedux.connect)(mapStateToProps, null))(RatingRespond);