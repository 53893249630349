"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _styles = require("@material-ui/core/styles");

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _reactSignaturePadWrapper = require("react-signature-pad-wrapper");

var _reactSignaturePadWrapper2 = _interopRequireDefault(_reactSignaturePadWrapper);

var _IconButton = require("@material-ui/core/IconButton");

var _IconButton2 = _interopRequireDefault(_IconButton);

var _Delete = require("@manakin/core/icons/Delete");

var _Delete2 = _interopRequireDefault(_Delete);

var _InputLabel = require("@material-ui/core/InputLabel");

var _InputLabel2 = _interopRequireDefault(_InputLabel);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = function styles(theme) {
	return {
		root: {
			width: "100%",
			backgroundColor: "white",
			borderRadius: 4,
			border: "1px solid " + theme.manakin.defaultBorderColor[500],
			position: "relative"
		},
		clearButton: {
			position: "absolute",
			bottom: theme.spacing(2),
			right: theme.spacing(2)
		},
		clearIcon: {
			color: theme.manakin.defaultBorderColor[500]
		},
		label: {
			marginBottom: "20px"
		}
	};
};

/**
 * In order to use this signature component, supply it with a ref. Via the ref, several image format can be
 * requested. Because there is no drawing canvas, this component cannot simply use a callback to signal a
 * new signature has been drawn. In order to get a SVG, just use the ref: ref.current.getImageAsSvg()
 */
var SignatureField = function SignatureField(props, ref) {
	var classes = props.classes,
	    readOnly = props.readOnly,
	    value = props.value,
	    _props$label = props.label,
	    label = _props$label === undefined ? "" : _props$label;

	var signaturePad = (0, _react.useRef)();

	(0, _react.useEffect)(function () {
		if (value) {
			fileToBase64(value).then(function (result) {
				if (signaturePad.current) {
					signaturePad.current.fromDataURL(result);
				}
			});
		}
	}, [value]);

	(0, _react.useEffect)(function () {
		if (readOnly) {
			signaturePad.current.off();
		}
	}, [readOnly]);

	var handleClear = function handleClear() {
		if (signaturePad && signaturePad.current.clear) {
			signaturePad.current.clear();
		}
	};

	var fileToBase64 = function fileToBase64(url) {
		return new Promise(function (resolve) {
			fetch(value).then(function (res) {
				return res.blob();
			}).then(function (blob) {
				var reader = new FileReader();
				reader.onloadend = function () {
					resolve(reader.result);
				};
				reader.readAsDataURL(blob);
			});
		});
	};

	var base64ToFile = function base64ToFile(url, filename, mimeType) {
		return fetch(url).then(function (res) {
			return res.arrayBuffer();
		}).then(function (buf) {
			return new File([buf], filename, { type: mimeType });
		});
	};

	(0, _react.useImperativeHandle)(ref, function () {
		return {
			getImageAsPng: function getImageAsPng() {
				return new Promise(function (resolve) {
					if (signaturePad.current) {
						base64ToFile(signaturePad.current.toDataURL(), "signature.png", "image/png").then(function (file) {
							resolve(file);
						});
					} else {
						resolve(null);
					}
				});
			},
			getImageAsSvg: function getImageAsSvg() {
				return signaturePad.current ? signaturePad.current.toDataURL("image/svg+xml") : null;
			},
			isEmpty: function isEmpty() {
				return signaturePad.current.isEmpty();
			}
		};
	}, []);

	return _react2.default.createElement(
		"div",
		null,
		_react2.default.createElement(
			_InputLabel2.default,
			{
				classes: {
					root: classes.label
				}
			},
			label
		),
		_react2.default.createElement(
			"div",
			{ className: (0, _classnames2.default)(classes.root) },
			_react2.default.createElement(_reactSignaturePadWrapper2.default, {
				ref: signaturePad,
				options: { minWidth: 2, maxWidth: 2, penColor: "black" },
				redrawOnResize: true,
				height: 250
			}),
			!readOnly && _react2.default.createElement(
				_IconButton2.default,
				{
					onClick: handleClear,
					className: classes.clearButton
				},
				_react2.default.createElement(_Delete2.default, { className: classes.clearIcon })
			)
		)
	);
};

exports.default = (0, _styles.withStyles)(styles, { name: "AppSignatureField" })((0, _react.forwardRef)(SignatureField));