import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { graphql } from 'react-apollo';
import { GQL_RESET_PASSWORD } from './graphql/graphql';
import ResetPassword from '@manakin/authentication/ResetPassword';

const styles = (theme) => ({
    root: {
        backgroundColor: 'white',
        [theme.breakpoints.up('md')]: {
            height: '100vh',
            minHeight: '900px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    content: {
        height: 'auto',
    },
});

const AppResetPassword = graphql(GQL_RESET_PASSWORD)(ResetPassword);

class ResetPasswordView extends React.PureComponent {
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.content}>
                    <AppResetPassword />
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(ResetPasswordView);
