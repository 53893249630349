import React from "react";
import List from "@material-ui/core/List";
import Dialog from "@material-ui/core/Dialog";
import moment from "moment";
import "moment/min/locales";
import { useStyles } from "../styles";
import ListItem from "./ListItem";
import { Typography } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import { parseLocalDateTime } from "@manakin/app-core/lib";

const ChoiseDialog = (props) => {
	const { open, onClose, appointments = [], data } = props;
	const classes = useStyles();
	const { t } = useTranslation();

	const handleListItemClick = (value) => {
		onClose(value);
	};

	const sameDay = appointments.filter((i) => {
		return moment(i.time).isSame(data, "day");
	});

	const handleClose = () => {
		onClose();
	};

	return (
		<Dialog onClose={handleClose} open={open}>
			<div className={classes.dialogInner}>
				<Typography className={classes.dialogTitle} variant="h5">
					{t("app.planner.choice-dialog.title")}
				</Typography>
				<List>
					<ListItem
						label={t("app.planner.choice-dialog.new-appointment")}
						onClick={() => handleListItemClick("addAccount")}
					/>
				</List>
				{t("app.planner.choice-dialog.change-appointment")}
				{sameDay.map((appointment) => (
					<List>
						<ListItem
							label={
							<div>
								<div className={classes.appointmentItemHeader}>{appointment.student.fullName}</div>
								<div className={classes.appointmentItemContent}>{appointment.subject.title} - {parseLocalDateTime(appointment.time, t).format("HH:mm")}</div>
							</div>
							}
							icon="edit"
							onClick={() => handleListItemClick(appointment)}
						/>
					</List>
				))}
			</div>
		</Dialog>
	);
};

export default ChoiseDialog;
