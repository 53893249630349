import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import StyledDialog from '../../StyledDialog';
import { TextField, CheckboxField } from '@manakin/core';
import { GQL_SEND_SUGGESTION_BOX } from '../../graphql';
import { useMutation } from '@apollo/react-hooks';
import { useSnackBar } from '@manakin/hooks';

const useStyles = makeStyles((theme) => ({
	content: {
		marginBottom: 32,
	},
	checkbox: {
		'& *': {
			margin: 0,
			width: 'auto',
			fontSize: '1.5rem',
			lineHeight: '3rem',
		},
	},
}));

const Popup = (props) => {
	const { onClose, open, form, id, title } = props;
	const classes = useStyles();
	const snackBar = useSnackBar();
	const { t } = useTranslation();

	//Mutations
	const [sendSuggestionBox] = useMutation(GQL_SEND_SUGGESTION_BOX);

	const handleSubmit = () => {
		const { onSubmit } = form;
		form.onValidate().then(
			() =>
				onSubmit().then((data) => {
					sendSuggestionBox({
						variables: {
							...data,
							appWidgetId: id || null,
			
						}
					}).then((response) => {
						if(!response.errors) {
							snackBar.success(t('app.widget-dashboard.suggestion-box-widget.popup.save-success'));
						} else {
							snackBar.error(t('app.widget-dashboard.suggestion-box-widget.popup.save-failed'));
						}

						onClose();
					}).catch(() => {
						snackBar.error(t('app.widget-dashboard.suggestion-box-widget.popup.save-failed'));
					});
				})
		);
	};

	return (
		<StyledDialog
			open={open}
			title={t('app.widget-dashboard.suggestion-box-widget.popup.title')}
			fullWidth={true}
			positiveButtonText={t('app.widget-dashboard.suggestion-box-widget.popup.send-button')}
			onPositiveButtonClick={handleSubmit}
			onClose={onClose}
			extraContent={
				<div>
					<TextField
						name="text"
						form={form}
						label={t('app.widget-dashboard.suggestion-box-widget.popup.fields.idea.label')}
						multiline
						placeholder={t('app.widget-dashboard.suggestion-box-widget.popup.fields.idea.placeholder')}
					/>
					<CheckboxField
						name="allowContact"
						label={t('app.widget-dashboard.suggestion-box-widget.popup.contact-me')}
						initialValue={false}
						form={form}
						className={classes.checkbox}
					/>
				</div>
			}
		/>
	);
};

export default Popup;
