import React from 'react';
import FactCards from './FactCards';
import { withApollo } from 'react-apollo';
import { compose } from 'recompose';
import { GQL_FETCH_FACT_CARD, GQL_FETCH_FACT_CARDS } from './graphql';
import { Loader } from '@manakin/app-core';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { setBackButton } from '@manakin/app-core/AppBar/actions';

const styles = (theme) => ({});

class BoxCardsContainer extends React.PureComponent {
	state = {
		factCards: [],
		loading: true,
		fromElement: false,
		isTags: false,
	};

	componentDidMount() {
		const { location = {}, client } = this.props;
		const { state } = location;
		this.props.setBackButton({
			show: true,
			location: location.pathname || '/boxCards',
		});

		if (state && state.tags && state.tags.length) {
			const { tags } = state;
			client
				.query({
					query: GQL_FETCH_FACT_CARDS,
					variables: {
						page: 0,
						pagesize: 999,
						filter: {
							tagIds: tags.map((i) => i.id, []),
						},
					},
				})
				.then((result) => {
					if (result.data && result.data.factCards && result.data.factCards.factCards) {
						this.setState({
							factCards: result.data.factCards.factCards,
							loading: false,
							isTags: true,
						});
					}
				});
		}
		if (state && state.factCards.length) {
			const { fromElement, factCards } = state;

			this.setState((prevState) => ({
				...prevState,
				fromElement: fromElement,
			}));

			const arr = [...new Set(factCards)];
			let count = 0;
			arr.forEach((item) => {
				client
					.query({
						query: GQL_FETCH_FACT_CARD,
						variables: {
							id: item,
						},
					})
					.then((result) => {
						count++;
						if (!result.errors) {
							this.setState((prevState) => ({
								factCards: [...prevState.factCards, result.data.factCard],
								loading: count < arr.length,
							}));
						}
					});
			});
		}
	}

	render() {
		const { classes } = this.props;
		const { fromElement, loading, factCards, isTags } = this.state;

		if (loading) {
			return (
				<div className={classes.loader}>
					<Loader />
				</div>
			);
		}
		return (
			<div>
				<FactCards
					FactCardsData={{ factCards: factCards }}
					fromBox={true}
					fromElement={fromElement}
					isTags={isTags}
					hideFilters={true}
				/>
			</div>
		);
	}
}

export default compose(
	withApollo,
	withStyles(styles),
	connect(null, (dispatch) => ({
		setBackButton: (data) => dispatch(setBackButton(data)),
	}))
)(BoxCardsContainer);
