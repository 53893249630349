"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _appCore = require("@manakin/app-core");

var _TableHead = require("./TableHead");

var _TableHead2 = _interopRequireDefault(_TableHead);

var _TableRow = require("./TableRow");

var _TableRow2 = _interopRequireDefault(_TableRow);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Table = function Table(props) {
	var _onClick = props.onClick,
	    _props$request = props.request,
	    request = _props$request === undefined ? {} : _props$request,
	    _props$BPVBTrainers = props.BPVBTrainers,
	    BPVBTrainers = _props$BPVBTrainers === undefined ? [] : _props$BPVBTrainers,
	    _props$superObj = props.superObj,
	    superObj = _props$superObj === undefined ? {} : _props$superObj;


	return _react2.default.createElement(_appCore.SimpleTable, {
		renderHead: function renderHead() {
			return _react2.default.createElement(_TableHead2.default, null);
		},
		renderBody: function renderBody() {
			return _react2.default.createElement(
				_react2.default.Fragment,
				null,
				_react2.default.createElement(_TableRow2.default, {
					onClick: _onClick,
					key: request.id,
					data: request,
					request: request
				}),
				BPVBTrainers.map(function (trainer) {
					return _react2.default.createElement(_TableRow2.default, {
						key: trainer.id,
						active: false,
						onClick: function onClick() {
							return _onClick(trainer, request);
						},
						data: trainer,
						request: request,
						superObj: superObj
					});
				})
			);
		}
	});
};

exports.default = Table;