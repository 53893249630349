import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import ExamTrainer from './ExamTrainer';
import { GQL_FETCH_EXAM_TRAINER_QUESTIONS_FOR_EXAM_TRAINER, GQL_FETCH_EXAM_TRAINER_QUESTIONS } from './graphql';
import { ENTRY_EXAM_TRAINER_ROOT_PATH } from '../Router/Router';
import { useLazyQuery } from '@apollo/react-hooks';
import { Loader } from '@manakin/app-core';

const ExamTrainerContainer = () => {
	const params = useParams();
	const history = useHistory();
	const location = useLocation();
	const { state = {} } = location;

	const [getQuestionBasedOnId, { data, loading }] = useLazyQuery(GQL_FETCH_EXAM_TRAINER_QUESTIONS_FOR_EXAM_TRAINER, {
		variables: {
			examTrainerId: params.elementId,
		},
	});
	const { examTrainerQuestionsForExamTrainer } = data || {};

	const [getQuestionBasedOnTags, { data: dataTags, loading: loadingTags }] = useLazyQuery(GQL_FETCH_EXAM_TRAINER_QUESTIONS, {
		variables: {
			tags: state.tags,
			category: state.category
		},
	});
	const { examTrainerQuestions } = dataTags || {};
	const { workforms } = examTrainerQuestions || {};

	useEffect(() => {
		if (params.elementId) {
			getQuestionBasedOnId();
		} else if (state.tags) {
			getQuestionBasedOnTags();
		}
	}, []);

	const push = (url, stepper) => {
		history.push({
			pathname: url,
			state: {
				items: stepper.items,
				tags: state.tags || null,
				category: state.category
			},
		});
	};

	const handleExit = (stepper) => {
		if (params.elementId) {
			push(`/${ENTRY_EXAM_TRAINER_ROOT_PATH}/${params.elementId}/saveResults`, stepper);
		} else if (params.programId) {
			push(`/${ENTRY_EXAM_TRAINER_ROOT_PATH}/${params.programId}/saveResultsProgram`, stepper);
		}
	};

	if (loading || loadingTags) return <Loader fullScreen />;
	return <ExamTrainer workforms={examTrainerQuestionsForExamTrainer || workforms || []} onExit={handleExit} />;
};

export default ExamTrainerContainer;
