import React from "react";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import { TextField } from "@manakin/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				textAlign: "center",
				width: "100%",
			},
			submit: {
				margin: `${theme.spacing(2)}px auto 0`,
			},
		}),
	{
		name: "AuthenticationChangePasswordForm",
	}
);

const ChangePasswordForm = (props) => {
	const { submitLabel, form} = props;
	const { onSubmit } = form;
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.root}>
			<TextField
				name="password"
				label={t("common.fields.new-password.label")}
				form={form}
				type="password"
			/>
			<TextField
				name="secondPassword"
				label={t("common.fields.repeat-password.label")}
				onEnterPressed={onSubmit}
				form={form}
				type="password"
			/>

			<FormControl margin="normal">
				<Button
					className={classes.submit}
					color="primary"
					variant="contained"
					onClick={onSubmit}
				>
					{submitLabel || t("common.confirm")}
				</Button>
			</FormControl>
		</div>
	);
};

export default ChangePasswordForm;
