import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import classNames from "classnames";
import { useTranslation } from 'react-i18next';
import DemoButton from './DemoButton';

const styles = (theme) => ({
	contentColor: {
		opacity: 1,
		transition: "opacity .3s",
	},
	headingName: {},
	root: {
		width: "100%",
		height: "auto",
		"& $contentColor": {
			color: theme.palette.getContrastText(
				theme.manakin.primaryColor[500]
			),
		},
		[theme.breakpoints.up("md")]: {
			padding: "17rem 0 0",
		},
	},
	wrapper: {
		...theme.manakin.defaultWrapper,
		maxWidth: "1600px",
		paddingLeft: "36px",
		[theme.breakpoints.up("md")]: {
			paddingBottom: "20px",
		},
	},
	content: {
		[theme.breakpoints.up("md")]: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
		},
	},
	title: {},
	heading: { fontWeight: "600" },
	body: {
		marginBottom: "2.8rem",
	},
	introduction: {
		[theme.breakpoints.up("md")]: {
			maxWidth: "450px",
			width: "100%",
		},
	},
	loading: {
		opacity: 0,
	},
});

const Header = (props) => {
	const { classes, data, loading, children } = props;
	const { t } = useTranslation();

	return (
		<React.Fragment>
			<div className={classes.root}>
				<div className={classes.wrapper}>
					<div className={classes.content}>
						<div className={classes.title}>
							<Typography
								component="h1"
								variant="h3"
								className={classes.contentColor}
								classes={{ h3: classes.heading }}
							>
								{t("app.progress-dashboard.title")}
							</Typography>
						</div>
						<div className={classes.introduction}>
							<Typography
								component="p"
								variant="body2"
								classes={{ body2: classes.body }}
								className={classNames(classes.contentColor, {
									[classes.loading]: loading,
								})}
							>
								{data.description || ""}
							</Typography>
						</div>
						<DemoButton/>
					</div>
					{children}
				</div>
			</div>
		</React.Fragment>
	);
};

export default compose(
	withStyles(styles, { name: "AppProgressDashboardHeader" })
)(Header);
