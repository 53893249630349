"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _core = require("@manakin/core");

var _Search = require("@material-ui/icons/Search");

var _Search2 = _interopRequireDefault(_Search);

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SearchField = function SearchField(props) {
	var onChange = props.onChange;

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	return _react2.default.createElement(
		"div",
		null,
		_react2.default.createElement(_core.TextField, {
			label: t("app.assigned-student.overview.fields.search.label"),
			icon: _Search2.default,
			onChange: onChange
		})
	);
};

exports.default = SearchField;