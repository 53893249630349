import React from 'react';
import { GQL_FETCH_CASE, GQL_FETCH_GLOBAL_SETTINGS } from './graphql';
import { graphql } from 'react-apollo';
import { compose, withHandlers } from 'recompose';
import { ElementOverlay, withWorkforms } from '@manakin/app-core';
import Case from './Case';
import { getBoxId } from '@manakin/app-core/Boxes/selectors';
import { connect } from 'react-redux';
import { changeColor } from '@manakin/app-core/ColorService/actions';
import { withTranslation } from "react-i18next";
import { SettingsKeys } from '@manakin/core/lib/constants';
import { GQL_VIEW_ELEMENT } from '@manakin/app-core/graphql';

const mapStateToProps = (state) => ({
	config: state.config,
	boxId: getBoxId(state),
});

const initialState = {
	showContent: false,
	step: -1,
	characteristic: [],
	closeChatBubble: false,
	redoButtons: false,
};

class CaseContainer extends React.Component {
	state = initialState;

	componentDidUpdate(prevProps) {
		if (prevProps.data.loading && !this.props.data.loading) {
			const { data, workformsData, location, changeColor } = this.props;

			const options = {
				showFeedback: true,
				nextButton: true,
				showFeedbackTitle: true,
			};

			if (data.case) {
				changeColor({
					...data.case.styleSheet,
					location: location.pathname,
				});
				data.case.workforms &&
					workformsData.loadWorkforms({
						...data.case,
						options: options,
						elementId: this.props.match.params.elementId,
					});

					// TODO if this ever gets refactored, use the useViewCounting hook instead
					this.props.onViewElement({
						elementId: this.props.match.params.elementId,
					})
			}

			if (
				location.state &&
				(location.state.idx || location.state.idx === 0)
			) {
				setTimeout(() => {
					this.setState({
						showContent: true,
						step: location.state.idx,
						characteristic: location.state.characteristic,
					});
				}, 2000);
			}
		}

		if (prevProps.SETTINGS.loading && !this.props.SETTINGS.loading) {
			const { SETTINGS } = this.props;
			const { settings = {} } = SETTINGS;
			settings.settings &&
				settings.settings.forEach((item) => {
					if (item.name == SettingsKeys.CASE_PREFIX) {
						this.setState({
							prefix: item.value,
						});
					}
				});
		}
	}

	handleClick = (initial = 0) => {
		this.setState({ showContent: true, step: initial });
	};

	handleSetWorkformDone = (data) => {
		this.props.workformsData.setWorkformDone({...data});
	}

	handleNext = (data) => {   
		const { workformsData } = this.props;
		const { workforms } = workformsData;
		const { step } = this.state;
		const workformLength = workforms.length || 0;

		if (step < workformLength - 1) {
			this.setState({
				step: step + 1,
			});
		} else {
			this.handleFinish();
		}

		if (data && data.characteristic && data.characteristic.length) {
			let _characteristic = [...(this.state.characteristic || [])];
			const merge = _characteristic.concat(data.characteristic);
			this.setState({ characteristic: merge });
		}
	};

	handleUpdateCharacteristic = (data) => {
		if (data && data.characteristic && data.characteristic.length) {
			let _characteristic = [...(this.state.characteristic || [])];
			const merge = _characteristic.concat(data.characteristic);
			this.setState({ characteristic: merge });
		}
	}

	handleFinish = () => {
		const { config = {} } = this.props;
		const { caseConfig = {} } = config;
		this.props.workformsData
			.saveElement({
				elementId: this.props.match.params.elementId,
			})
			.then(() => {
				const { workformsData = {}, boxId } = this.props;
				if (caseConfig.redoButtons) {
					this.setState({ redoButtons: true });
				} else if (
					workformsData.workforms &&
					workformsData.workforms[workformsData.workforms.length - 1]
						.type === 'ChatBubble'
				) {
					this.setState({ closeChatBubble: true });
				} else {
					this.props.history.push(`/box/${boxId}`);
				}
			});
	};

	handleClose = () => {
		const { boxId } = this.props;
		this.props.history.push(`/box/${boxId}`);
	};

	redo = () => {
		location.reload();
	};

	render() {
		const { workformsData = {}, t } = this.props;
		const { prefix = null, redoButtons } = this.state;
		const { rawData = {} } = workformsData;

		return (
			<ElementOverlay
				fullWidth={true}
				title={rawData.preTitle || prefix || t("common.element-types.case")}
			>
				<Case
					{...workformsData}
					stateData={{ ...this.state }}
					onExit={this.handleFinish}
					onClick={this.handleClick}
					onNext={this.handleNext}
					onUpdateCharacteristic={this.handleUpdateCharacteristic}
					onClose={this.handleClose}
					redoButtons={redoButtons}
					redo={this.redo}
					onSetWorkformDone={this.handleSetWorkformDone}
				/>
			</ElementOverlay>
		);
	}
}

export default compose(
	connect(mapStateToProps, (dispatch) => ({
		changeColor: (styleSheet) => dispatch(changeColor(styleSheet)),
	})),
	graphql(GQL_FETCH_CASE, {
		options: (props) => ({
			variables: { id: props.match.params.elementId },
		}),
	}),
	graphql(GQL_FETCH_GLOBAL_SETTINGS, {
		name: 'SETTINGS',
	}),
	graphql(GQL_VIEW_ELEMENT, {
		name: 'VIEW_ELEMENT',
	}),
	withHandlers({
		onViewElement:
			({ VIEW_ELEMENT }) =>
			(event) =>
				VIEW_ELEMENT({
					variables: {
						...event,
					},
				}),
	}),
	withTranslation(),
	withWorkforms()
)(CaseContainer);
