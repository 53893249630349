import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import classNames from "classnames";
import { Chart } from "react-google-charts";
import moment from "moment";
import { GQL_FETCH_SESSIONS } from "../graphql/graphql";
import { useQuery } from "react-apollo";
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
	root: {
		flexGrow: 1,
	},
	header: {
		display: "flex",
		flexDirection: "row",
	},
	headerTitle: {
		flexGrow: 1,
		fontWeight: "bold",
	},
	stats: {
		display: "flex",
		flexDirection: "row",
		marginBottom: theme.spacing(2),
	},
	statsItem: {
		display: "flex",
		flexDirection: "column",
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	divider: {
		height: "100%",
		width: 2,
		backgroundColor: theme.manakin.softBorderColor[500],
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
	},
	title: {
		fontWeight: "bold",
		marginBottom: 0,
	},
	text: {
		color: theme.manakin.subHeaderColor[500],
		marginBottom: 0,
	},
	[theme.breakpoints.down("sm")]: {
		header: {
			flexDirection: "column",
		},
		stats: {
			flexDirection: "column",
		},
		statsItem: {
			flexDirection: "row",
			alignItems: "center",
			paddingLeft: 0,
			paddingRight: 0,
		},
		title: {
			marginRight: theme.spacing(),
		},
	},
	subHeader: {},
	contentColor: {},
});

const columnNormalStyle = "fill: #FAFAFA; color: #EFEFEF;";
const columnSelectedStyle = "fill: #FAFAFA; color: #1944E5;";
const defaultGraphOptions = (borderColor) => ({
	bars: "vertical",
	legend: { position: "none" },
	chartArea: {
		left: 32,
		top: 8,
		bottom: 32,
		width: "100%",
		height: 250,
	},
	animation: {
		startup: true,
		easing: "linear",
		duration: 1500,
	},
	vAxis: {
		baseline: 0,
		baselineColor: borderColor,
		gridlines: {
			count: 0,
		},
		minorGridlines: {
			count: 0,
		}
	},
});

const SessionsBlock = (props) => {
	const { classes, theme } = props;
	const [ graphData, setGraphData ] = useState([]);
	const [ sessionData, setSessionData ] = useState({});
	const [ graphOptions, setGraphOptions ] = useState(defaultGraphOptions(theme.manakin.softBorderColor[500]))
	const { t } = useTranslation();
	const defaultGraphHeader = [
		"Month",
		t("app.progress-dashboard.blocks.sessions.amount-of-sessions"),
		{ role: "style" },
	];

	//query
	const { data, loading, error } = useQuery(GQL_FETCH_SESSIONS);
	const { sessionDataPastYear = {} } = data || {};
	const { months = [] } = sessionDataPastYear || {};

	useEffect(() => {
		const today = new Date();

		setGraphData(
			[ defaultGraphHeader ].concat(
				months.map((item) => [
					moment(new Date(item.year, item.month - 1))
						.locale(t("common.locale-code"))
						.format("MMM"),
					item.sessionCount,
					moment(new Date(item.year, item.month - 1)).isSame(
						today,
						"month"
					)
						? columnSelectedStyle
						: columnNormalStyle,
				])
			)
		);

		setSessionData({
			total: sessionDataPastYear.totalSessionCount,
			currentMonth: months
				.filter((item) => item.month - 1 === today.getMonth())
				.reduce(
					(accumulator, item) => accumulator + item.sessionCount,
					0
				),
		});

		if (months.length > 0) {
			const options = defaultGraphOptions(theme.manakin.softBorderColor[500]);
			const highestCount = Math.max(...sessionDataPastYear.months.map(item => item.sessionCount));

			if (highestCount <= 5) {
				// Ugly decimal numbers might appear, prevent it
				options.vAxis.ticks = [ 1, 2, 3, 4, 5 ];
			}
			setGraphOptions(options);
		}
	}, [ months.length ]);

	if (error) return t("common.loading-data-failed");
	if (loading) return "";

	return (
		<div className={classes.root}>
			<div className={classes.header}>
				<Typography
					component="p"
					variant="subtitle1"
					className={classNames(
						classes.subHeader,
						classes.contentColor,
						classes.headerTitle
					)}
				>
					{t("app.progress-dashboard.blocks.sessions.title")}
				</Typography>
				<div className={classes.stats}>
					<div className={classes.statsItem}>
						<Typography
							component="p"
							variant="subtitle1"
							className={classNames(
								classes.subHeader,
								classes.contentColor,
								classes.title
							)}
						>
							{sessionData.total || 0}
						</Typography>
						<Typography
							component="p"
							variant="body2"
							className={classNames(
								classes.subHeader,
								classes.contentColor,
								classes.text
							)}
						>
							{t("app.progress-dashboard.blocks.sessions.total")}
						</Typography>
					</div>
					<div className={classes.divider}/>
					<div className={classes.statsItem}>
						<Typography
							component="p"
							variant="subtitle1"
							className={classNames(
								classes.subHeader,
								classes.contentColor,
								classes.title
							)}
						>
							{sessionData.currentMonth || 0}
						</Typography>
						<Typography
							component="p"
							variant="body2"
							className={classNames(
								classes.subHeader,
								classes.contentColor,
								classes.text
							)}
						>
							{t("app.progress-dashboard.blocks.sessions.this-month")}
						</Typography>
					</div>
				</div>
			</div>

			<Chart
				chartType="ColumnChart"
				width="100%"
				height={250}
				data={graphData}
				options={graphOptions}
			/>
		</div>
	);
};

export default compose(
	withStyles(styles, {
		name: "AppProgressDashboardSessionsBlock",
		withTheme: true,
	})
)(SessionsBlock);
