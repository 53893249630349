import React from "react";
import Block from "./Block";
import Grid from "@material-ui/core/Grid";
import PlannerBlock from "./PlannerBlock";
import { AssignedStudentsOverview } from "@manakin/app-core";
import { AccessControl } from "@manakin/app-core";
import { maxCardGridDimensions } from "./lib";
import { useStyles } from "../styles";
import { Element } from "react-scroll";
import { useTranslation } from 'react-i18next';
import { poRole } from '@manakin/app-core/lib';

const TrainerView = (props) => {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<React.Fragment>
			<AccessControl role={[poRole]}>
				<Grid item {...maxCardGridDimensions}>
					<Block className={classes.plannerBlock}>
						<PlannerBlock/>
					</Block>
				</Grid>
			</AccessControl>
			<Grid item {...maxCardGridDimensions}>
				<Element name="assignedStudentsOverview">
					<Block title={t("common.students")}>
						<AssignedStudentsOverview/>
					</Block>
				</Element>
			</Grid>
		</React.Fragment>
	);
};

export default TrainerView;
