import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(
	(theme) =>
		createStyles({
			stepperRoot: {
				width: '100%',
				alignItems: 'center',
				justifyContent: 'center',
			},
			stepRoot: {
				flex: '0 0 auto',
			},
			root: {
				padding: '14rem 0 0',
			},
			container: {
				display: 'flex',
				flexWrap: 'wrap',
				width: '100%',
				maxWidth: '900px',
				alignItems: 'center',
				margin: '0 auto',
			},
			buttonGroup: {
				zIndex: 0,
				textAlign: 'center',
				width: '100%',
			},
			buttonRoot: {
				display: 'block',
				margin: '0 auto',
			},
			content: {
				width: '100%',
				margin: '0 0 80px',
			},
			regiatrationAppear: {
				display: 'block',
			},
			registrationEnter: {
				opacity: 0,
				position: 'relative',
				transform: 'translateX(100px)',
			},
			registrationEnterActive: {
				opacity: 1,
				transform: 'translateX(0)',
			},
			heading: {},
			buttonPrimary:{}
		}),
	{
		name: 'AppRegister',
	}
);
