import gql from "graphql-tag";

export const GQL_FETCH_SETTINGS = gql`
	query settingsLoginHeader {
		settings {
			settings {
				name
				value
				file {
					id
					url
				}
			}
		}
	}
`;

export const GQL_FETCH_CMS_HOME_PAGE_BLOCKS_IDS = gql`
    query getHomepageBlocks {
        homepageBlocks {
            homepageBlocks {
                id
                type
            }
        }
    }
`;