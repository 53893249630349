import React, { useEffect, useCallback } from "react";
import { GQL_FETCH_RATING } from "./graphql";
import { ElementOverlay, withWorkforms } from "@manakin/app-core";
import { getBoxId } from "@manakin/app-core/Boxes/selectors";
import { changeColor } from "@manakin/app-core/ColorService/actions";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAppUser } from "@manakin/authentication/selectors";
import FinalRatingView from "./FinalRatingView";
import { poRole, managementRole, trainerRoles } from "@manakin/app-core/lib";
import { useTranslation } from 'react-i18next';
import { AppBarMessage } from "@manakin/app-core/AppBar";
import { useViewCounting } from "@manakin/hooks";

let mounted = false;
const FinalRatingContainer = (props) => {
	const { workformsData, location } = props;
	const params = useParams();
	const history = useHistory();
	const { t } = useTranslation();
	const { viewElement } = useViewCounting();

	//useSelector
	const appUser = useSelector((state) => getAppUser(state));
	const boxId = useSelector((state) => getBoxId(state));
	const config = useSelector((state) => state.config) || {};

	const isTrainer = appUser.roles.some(
		(role) => role.name === poRole || role.name === managementRole
	);

	//dispatch functions
	const dispatch = useDispatch();
	const handleChangeColor = useCallback(
		(styleSheet) => dispatch(changeColor(styleSheet)),
		[dispatch]
	);

	//queries
	const { data = {}, loading } = useQuery(GQL_FETCH_RATING, {
		variables: { id: params.elementId },
	});
	const { finalRating = {} } = data;

	//effect hooks
	useEffect(() => {
		if (!loading) {
			const options = {
				showFeedback: true,
				nextButton: false,
			};

			if (data.finalRating) {
				handleChangeColor({
					...data.finalRating.styleSheet,
					location: location.pathname,
				});

				data.finalRating.workforms &&
					workformsData.loadWorkforms({
						...data.finalRating,
						options: options,
						elementId: params.elementId,
					});

				viewElement(params.elementId);
			}
		}
	}, [loading]);

	const handleFinish = (data) => {
		workformsData.saveWorkform({
			...data,
			elementId: params.elementId,
			config: config,
			element: "finalRating",
		});
	};

	const handleReset = (id) => {
		workformsData.resetElement(id);
	};

	const handleClose = () => {
		if (isTrainer && params.studentId)
			history.push(`/student/${params.studentId}`);
		else history.push(`/box/${boxId}`);
	};

	const showRatingAppBarMessage = appUser.roles && appUser.roles.some((role) => trainerRoles.includes(role.name));

	return (
		<React.Fragment>
			{showRatingAppBarMessage && (<AppBarMessage fixed large message={t('app-core.app-bar-messages.rating-for-student')} />)}
			<ElementOverlay
				fullWidth={true}
				title={t("common.element-types.final-rating")}
				controls={false}
				customControls
				onClick={handleClose}
			>
				<FinalRatingView
					{...workformsData}
					finalRatingData={finalRating}
					onFinish={handleFinish}
					onReset={handleReset}
					onClose={handleClose}
					preview={false}
				/>
			</ElementOverlay>
		</React.Fragment>
	);
};

export default withWorkforms()(FinalRatingContainer);
