import gql from 'graphql-tag';

export const GQL_SEND_MESSAGE = gql`
    mutation(
        $title: String!
        $text: String!
        $sender: ID!
        $receivers: [ID]!
        $send: Boolean!
    ) {
        upsertMessageSend(
            input: {
                title: $title
                text: $text
                sender: $sender
                receivers: $receivers
                send: $send
            }
        ) {
            messageSend {
                id
            }
        }
    }
`;
