'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Fab = require('@material-ui/core/Fab');

var _Fab2 = _interopRequireDefault(_Fab);

var _Clear = require('@material-ui/icons/Clear');

var _Clear2 = _interopRequireDefault(_Clear);

var _ArrowRightAlt = require('@material-ui/icons/ArrowRightAlt');

var _ArrowRightAlt2 = _interopRequireDefault(_ArrowRightAlt);

var _styles = require('@material-ui/core/styles');

var _Paper = require('@material-ui/core/Paper');

var _Paper2 = _interopRequireDefault(_Paper);

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _appCore = require('@manakin/app-core');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _styles2 = require('./styles');

var _reactRouterDom = require('react-router-dom');

var _reactRouter = require('react-router');

var _recompose = require('recompose');

var _reactI18next = require('react-i18next');

var _constants = require('@manakin/core/lib/constants');

var _selectors = require('@manakin/authentication/selectors');

var _reactRedux = require('react-redux');

var _core = require('@manakin/core');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var HelpButton = function HelpButton(props) {
    var classes = props.classes,
        onClick = props.onClick,
        show = props.show,
        settings = props.settings,
        location = props.location;

    var pathname = location.pathname;
    var history = (0, _reactRouterDom.useHistory)();

    var _useTranslation = (0, _reactI18next.useTranslation)(),
        t = _useTranslation.t;

    var authenticated = (0, _reactRedux.useSelector)(function (state) {
        return (0, _selectors.isAuthenticated)(state);
    });

    return _react2.default.createElement(
        'div',
        { className: classes.root },
        _react2.default.createElement(
            _Paper2.default,
            {
                className: (0, _classnames2.default)(classes.paper, _defineProperty({}, classes.show, show)),
                elevation: 20
            },
            _react2.default.createElement(
                _Typography2.default,
                {
                    component: 'h3',
                    variant: 'h6',
                    className: classes.heading
                },
                settings[_constants.SettingsKeys.HELP_TITLE]
            ),
            settings[_constants.SettingsKeys.HELP_INTRODUCTION] && _react2.default.createElement(
                _Typography2.default,
                {
                    component: 'p',
                    variant: 'body1',
                    classes: {
                        body1: classes.body
                    }
                },
                pathname.indexOf('/auth') === -1 && settings[_constants.SettingsKeys.HELP_INTRODUCTION]
            ),
            _react2.default.createElement(
                'div',
                { className: classes.panels },
                settings[_constants.SettingsKeys.HELP_F_A_Q_TITLE] && pathname.indexOf('/auth') === -1 && _react2.default.createElement(_appCore.AppExpansionPanel, {
                    summary: _react2.default.createElement(
                        'div',
                        { className: classes.summary },
                        _react2.default.createElement(_ArrowRightAlt2.default, null),
                        settings[_constants.SettingsKeys.HELP_F_A_Q_TITLE]
                    ),
                    details: _react2.default.createElement(
                        'div',
                        { onClick: onClick },
                        t("app.help.faq-title"),
                        " ",
                        _react2.default.createElement(
                            _reactRouterDom.Link,
                            { to: '/faq' },
                            t("app.help.faq-action")
                        )
                    )
                }),
                settings[_constants.SettingsKeys.HELP_PHONE_TITLE] && _react2.default.createElement(_appCore.AppExpansionPanel, {
                    summary: _react2.default.createElement(
                        'div',
                        { className: classes.summary },
                        _react2.default.createElement(_ArrowRightAlt2.default, null),
                        settings[_constants.SettingsKeys.HELP_PHONE_TITLE]
                    ),
                    details: _react2.default.createElement(
                        'div',
                        { onClick: onClick },
                        t("app.help.phone-title", { phoneNumber: settings[_constants.SettingsKeys.HELP_PHONE_NUMBER] || '' })
                    )
                }),
                settings[_constants.SettingsKeys.HELP_FEEDBACK_TITLE] && pathname.indexOf('/auth') === -1 && _react2.default.createElement(_appCore.AppExpansionPanel, {
                    summary: _react2.default.createElement(
                        'div',
                        { className: classes.summary },
                        _react2.default.createElement(_ArrowRightAlt2.default, null),
                        settings[_constants.SettingsKeys.HELP_FEEDBACK_TITLE]
                    ),
                    details: _react2.default.createElement(
                        'div',
                        { onClick: onClick },
                        _react2.default.createElement(
                            'div',
                            null,
                            t("app.help.feedback-title"),
                            " "
                        ),
                        _react2.default.createElement(
                            'a',
                            {
                                href: settings[_constants.SettingsKeys.HELP_FEEDBACK_URL],
                                target: '_blank'
                            },
                            settings[_constants.SettingsKeys.HELP_FEEDBACK_URL_TEXT] || t("app.help.feedback-action")
                        )
                    )
                }),
                settings[_constants.SettingsKeys.HELP_MAIL_TITLE] && _react2.default.createElement(_appCore.AppExpansionPanel, {
                    summary: _react2.default.createElement(
                        'div',
                        { className: classes.summary },
                        _react2.default.createElement(_ArrowRightAlt2.default, null),
                        settings[_constants.SettingsKeys.HELP_MAIL_TITLE]
                    ),
                    details: _react2.default.createElement(
                        'div',
                        {
                            onClick: onClick,
                            className: classes.content
                        },
                        t("app.help.mail-title"),
                        " ",
                        _react2.default.createElement(
                            'a',
                            { href: 'mailto:' + (settings[_constants.SettingsKeys.HELP_MAIL_EMAIL] || '') },
                            settings[_constants.SettingsKeys.HELP_MAIL_EMAIL] || ''
                        )
                    )
                }),
                authenticated && _react2.default.createElement(
                    _core.SettingAccessControl,
                    { name: _constants.SettingsKeys.SHOW_COLOPHON },
                    _react2.default.createElement(
                        'div',
                        { className: (0, _classnames2.default)(classes.summary, classes.summaryWithoutExpansion), onClick: function onClick() {
                                return history.push('/colophon');
                            } },
                        _react2.default.createElement(_ArrowRightAlt2.default, null),
                        t('app.help.colophon.title')
                    )
                )
            )
        ),
        _react2.default.createElement(
            _Fab2.default,
            {
                color: 'primary',
                'aria-label': t("common.add"),
                className: classes.fab,
                onClick: onClick
            },
            _react2.default.createElement(
                'span',
                {
                    className: (0, _classnames2.default)(classes.icon, classes.question, _defineProperty({}, classes.showIcon, !show))
                },
                '?'
            ),
            _react2.default.createElement(
                'span',
                {
                    className: (0, _classnames2.default)(classes.icon, _defineProperty({}, classes.showIcon, show))
                },
                _react2.default.createElement(_Clear2.default, null)
            )
        )
    );
};

exports.default = (0, _recompose.compose)((0, _styles.withStyles)(_styles2.styles, { name: 'HelpButton' }), _reactRouter.withRouter)(HelpButton);