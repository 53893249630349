import React, { useState, useEffect } from "react";
import { TextField, RadioField } from "@manakin/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				width: "100%",
			},
			groupElements: {
				"@media (min-width: 700px)": {
					display: "flex",
					justifyContent: "space-between",
					"&>*": {
						flex: "0 0 auto",
						width: "300px",
					},
				},
			},
			paragraph: {
				textAlign: "center",
				width: "100%",
				margin: "0 auto 3em",
				maxWidth: "560px",
			},
			termsOfService: {
				marginTop: theme.spacing(4),
				"& a": {
					color: "inherit",
				},
			},
			heading: {},
		}),
	{
		name: "AppRegistrationAuthData",
	}
);

const AuthData = (props) => {
	const { form, config, data = {} } = props;
	const { general = {} } = config;
	const { settings = [] } = data.settings;
	const classes = useStyles();
	const { fields: fieldsData } = form;
	const { t } = useTranslation();

	//state hooks
	const [terms, setTerms] = useState(null);

	//effect hooks
	useEffect(() => {
		if (settings && settings.length) {
			settings.forEach((setting) => {
				if (
					setting.value === "terms" &&
					setting.file &&
					setting.file.url
				) {
					setTerms(setting.file.url);
					form.onAddField({
						acceptTerms: {
							required: true,
						},
					});
				}
			});
		}
	}, [settings]);

	const preventCopyAndPaste = (event) => {
		event.preventDefault();
	}

	return (
		<div className={classes.root}>
			<form>
				<Typography variant="h2" className={classes.heading}>
					{t("common.fields.email.label")} &<br />
					{t("common.fields.password.label")}
				</Typography>
				<div className={classes.groupElements}>
					<TextField
						form={form}
						name="email"
						label={t("common.fields.email-address.label")}
						initialValue={fieldsData.email}
						onCopy={preventCopyAndPaste}
						onPaste={preventCopyAndPaste}
					/>
					<TextField
						form={form}
						name="password"
						helperText={
							general.defaultShowStrongPasswordHint ?
								t("common.fields.password.helper-text")
								: ""
						}
						label={t("common.fields.password.label")}
						initialValue={fieldsData.password}
						type="password"
						onCopy={preventCopyAndPaste}
						onPaste={preventCopyAndPaste}
					/>
				</div>
				<div className={classes.groupElements}>
					<TextField
						form={form}
						name="secondEmail"
						label={t("common.fields.repeat-email-address.label")}
						initialValue={fieldsData.secondEmail}
						onCopy={preventCopyAndPaste}
						onPaste={preventCopyAndPaste}
					/>
					<TextField
						form={form}
						name="secondPassword"
						label={t("common.fields.repeat-password.label")}
						initialValue={fieldsData.secondPassword}
						type="password"
						helperText={
							general.defaultShowStrongPasswordHint ?
								t("common.fields.password.helper-text")
								: ""
						}
						onCopy={preventCopyAndPaste}
						onPaste={preventCopyAndPaste}
					/>
				</div>
				{terms && (
					<div className={classes.termsOfService}>
						<RadioField
							form={form}
							fullWidth={true}
							fullLabelWidth={true}
							legendName={t("authentication.registration.accept-terms.name")}
							name="acceptTerms"
							options={[
								{
									label: (
										<span>
											{t("authentication.registration.accept-terms.label-first-part")}{" "}
											<a target="_blank" href={terms}>
												{t("authentication.registration.accept-terms.label-second-part")}
											</a>
										</span>
									),
									value: "ACCEPT",
								},
							]}
							initialValue={fieldsData.acceptTerms}
						/>
					</div>
				)}
			</form>
		</div>
	);
};

export default AuthData;
