import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { LoadImage, Loader } from '@manakin/app-core';
import { FeedbackBox } from '@manakin/app-workforms';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { styles } from './styles';
import { useTranslation } from 'react-i18next';

const StatementQuestion = (props) => {
	const { classes, data, onFinished, disabled, direction = 'default', onSubmit, saving } = props;
	const { t } = useTranslation();

	const handleSubmit = (userAnswer) => {
		if (onSubmit) onSubmit(userAnswer);
	};

	return (
		<div
			className={classNames(
				classes.root,
				{ [classes.saving]: saving },
				{ [classes.noBg]: data.backgroundColor === 'NONE' },
				{ [classes.primaryColors]: data.backgroundColor == 'PRIMARY' },
				{ [classes.secondaryColors]: data.backgroundColor == 'SECONDARY' }
			)}
		>
			{saving && (
				<div className={classes.loader}>
					<Loader />
				</div>
			)}

			<div
				className={classNames(classes.container, {
					[classes.columnDirection]: direction === 'column',
				})}
			>
				<div
					className={classNames(classes.wrapper, {
						[classes.noImage]: !data.image,
					})}
				>
					<div
						className={classNames(classes.content, {
							[classes.noImageContent]: !data.image,
						})}
					>
						<Typography
							component="p"
							variant="subtitle1"
							className={classNames('smallUnderline', classes.contentColor, classes.subHeader)}
						>
							{data.introduction || ''}
						</Typography>

						<Typography component="p" variant="h5" className={classNames(classes.contentColor, classes.quote)}>
							{data.statement}
						</Typography>

						<div className={classes.buttonContainer}>
							<div className={classes.buttons}>
								<Button
									className={classNames(classes.button, {
										[classes.active]: data.agree,
										[classes.inCorrect]: !data.correct && data.agree,
									})}
									variant="outlined"
									disabled={data.done || disabled}
									fullWidth
									onClick={() => handleSubmit(true)}
								>
									{data.answerOne || t('app.workforms.statement-question.agree')}
								</Button>
								<Button
									className={classNames(classes.button, {
										[classes.active]: data.agree != null && !data.agree,
										[classes.inCorrect]: !data.correct && data.agree != null && !data.agree,
									})}
									variant="outlined"
									disabled={data.done || disabled}
									fullWidth
									onClick={() => handleSubmit(false)}
								>
									{data.answerTwo || t('app.workforms.statement-question.disagree')}
								</Button>
							</div>
							{data.feedback && (
								<div
									className={classNames(classes.feedback, {
										[classes.activeFeedback]: data.feedback,
									})}
								>
									<FeedbackBox data={data} onFinished={onFinished} outlined={true} />
								</div>
							)}
						</div>
					</div>
				</div>
				{data.image && data.image.url && (
					<div className={classes.imageContainer}>
						<LoadImage
							objectFitContain={true}
							bottomCenter={true}
							src={(data.image && data.image.url) || ''}
							asset={data.image}
							enableFocusPoint={true}
							placeholder={(data.image && data.image.thumbnailUrl) || ''}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default compose(withStyles(styles, { name: 'AppStatementQuestion' }))(StatementQuestion);
