"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _core = require("@material-ui/core");

var _reactRedux = require("react-redux");

var _selectors = require("@manakin/app-core/ProgramsDropdown/selectors");

var _graphql = require("./graphql");

var _reactApollo = require("react-apollo");

var _reactRouterDom = require("react-router-dom");

var _Layout = require("./card/Layout");

var _Layout2 = _interopRequireDefault(_Layout);

var _RatingRequestAssignmentCard = require("./card/RatingRequestAssignmentCard");

var _RatingRequestAssignmentCard2 = _interopRequireDefault(_RatingRequestAssignmentCard);

var _selectors2 = require("@manakin/authentication/selectors");

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _lib = require("@manakin/app-core/lib");

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useStyles = (0, _core.makeStyles)(function (theme) {
	return {
		heading: {
			padding: "16px  0"
		},
		content: {
			margin: 0,
			color: "#BFBFBF",
			fontSize: "1.5rem",
			fontWeight: "600"
		},
		rightAligned: {
			textAlign: "right"
		}
	};
});

var RatingRequestsAssignments = function RatingRequestsAssignments(props) {
	var classes = useStyles();
	var history = (0, _reactRouterDom.useHistory)();

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	// Selectors


	var program = (0, _reactRedux.useSelector)(function (state) {
		return (0, _selectors.getProgram)(state) || {};
	});
	var appUser = (0, _reactRedux.useSelector)(function (state) {
		return (0, _selectors2.getAppUser)(state);
	}) || {};

	var _useState = (0, _react.useState)([]),
	    _useState2 = _slicedToArray(_useState, 2),
	    ratingRequests = _useState2[0],
	    setRatingRequests = _useState2[1];

	var _useQuery = (0, _reactApollo.useQuery)(_graphql.GQL_FETCH_RATING_REQUESTS, {
		skip: program === undefined || !appUser.id,
		variables: {
			filter: {
				programId: program,
				studentId: appUser && appUser.id
			}
		},
		onCompleted: function onCompleted(data) {
			var requests = (0, _lib.filterPendingRatingRequests)(data);
			if (requests) setRatingRequests(requests);
		}
	}),
	    loading = _useQuery.loading;

	var handleClick = function handleClick() {
		var ratingRequest = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

		if (ratingRequest.finalRating) {
			history.push("/FinalRating/" + ratingRequest.element.id);
		} else {
			history.push("/Rating/" + ratingRequest.element.id + "/" + appUser.id);
		}
	};

	if (loading) return "";

	return _react2.default.createElement(
		"div",
		null,
		_react2.default.createElement(
			_Layout2.default,
			{ className: classes.heading },
			_react2.default.createElement(
				_core.Typography,
				{ className: classes.content },
				t("app.rating-request.assignments.table-headers.assignments")
			),
			_react2.default.createElement(
				_core.Typography,
				{ className: (0, _classnames2.default)(classes.content, classes.rightAligned) },
				t("app.rating-request.assignments.table-headers.requested-by")
			)
		),
		ratingRequests.map(function (ratingRequest) {
			return _react2.default.createElement(_RatingRequestAssignmentCard2.default, {
				data: ratingRequest,
				key: ratingRequest.id,
				onClick: function onClick() {
					return handleClick(ratingRequest);
				}
			});
		})
	);
};

exports.default = RatingRequestsAssignments;