"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _core = require("@material-ui/core");

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useStyles = (0, _core.makeStyles)(function (theme) {
	return {
		root: {
			display: "grid",
			gridTemplateColumns: "auto auto",
			alignItems: "center"
		}
	};
});

var Layout = function Layout(props) {
	var children = props.children,
	    className = props.className;

	var classes = useStyles();
	return _react2.default.createElement(
		"div",
		{ className: (0, _classnames2.default)(classes.root, className) },
		children
	);
};

exports.default = Layout;