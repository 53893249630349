import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import renderHTML from 'react-render-html';
import { ImageWithFocusPoint } from '@manakin/app-core';
import PrintIcon from '@material-ui/icons/Print';

export const useStyles = makeStyles((theme) =>
	createStyles({
		printButton: {
			position: 'absolute',
			top: '25px',
			right: '25px',
			width: '30px',
			height: '30px',
		},
		print: {
			display: 'none',

			'& $content': {
				padding: 0,
			},
		},
        printItem: {
			pageBreakBefore: 'always'
        },
        imageContainer: {
            marginBottom: '15px',
        },
		[`@media print`]: {
			print: {
				display: 'block',
			},
			printButton: {
				display: 'none',
			},
		},
	})
);

const FactCardPrint = (props) => {
	const { items = [] } = props;
	const classes = useStyles();

	const handleClick = () => {
		window.print();
	};

	return (
		<div className={classes.root}>
			<PrintIcon onClick={handleClick} className={classes.printButton} />
			<div className={classes.print}>
				<div className={classes.content}>
					<div className={classes.wrapper}>
						{items &&
							items.map((item, index) => (
								<div className={classes.printItem}>
									{item.image && item.image.url && (
										<div className={classes.imageContainer}>
                                            <ImageWithFocusPoint asset={item.image} className={classes.carouselImg} />
                                        </div>
									)}
									<Typography variant="h3" component="h2" className={classes.title}>
										{(item && item.title) || ''}
									</Typography>
									<Typography variant="body1" component="div">
										{renderHTML((item && item.text) || '')}
									</Typography>
								</div>
							))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default FactCardPrint;
