import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { LoadImage, LongArrow } from '@manakin/app-core';
import scrollToComponent from 'react-scroll-to-component';
import renderHTML from 'react-render-html';

const styles = (theme) => ({
	root: {
		width: '100%',
		minHeight: '430px',
		backgroundColor: theme.manakin.primaryColor,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		position: 'relative',
		padding: '100px 0',
		opacity: 0,
		animation: 'fadeIn .3s ease .6s forwards',
		[theme.breakpoints.up('md')]: {
			minHeight: '70vh',
		},
		height: 0,
	},
	content: {
		textAlign: 'center',
		opacity: 0,
		animation: 'show .3s ease 1s forwards',
	},
	fontStyle: {
		color: '#C4C4C4',
	},
	heading: {
		color: 'white',
		marginBottom: '3rem',
		[theme.breakpoints.down('xs')]: {
			fontSize: '3rem',
			lineHeight: '4rem',
			letterSpacing: '.4rem',
		},
	},
	wrapper: {
		...theme.manakin.defaultWrapper,
	},
	icon: {
		position: 'absolute',
		color: theme.manakin.indicationColor[500],
		transformOrigin: 'center',
		transform: 'translate(-50%, 0) rotate(90deg)',
		bottom: '4.5rem',
		left: '50%',
		display: 'none',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',
		[theme.breakpoints.up('md')]: {
			transition: 'transform .3s',
			display: 'flex',
		},
		'&:hover': {
			[theme.breakpoints.up('md')]: {
				transform: 'translate(-50%, 10px) rotate(90deg)',
			},
		},
	},
});

const Header = (props) => {
	const { classes, data } = props;
	const image = data.image ? data.image.url : '';
	const headerImage = data.headerImage ? data.headerImage.url : '';
	const imagePlaceholder = data.image ? data.image.thumbnailUrl : '';
	const placeholder = data.headerImage ? data.headerImage.thumbnailUrl : '';
	let header = null;

	const handleClick = () => {
		scrollToComponent(header, {
			offset: 70,
			align: 'top',
			duration: 500,
		});
	};

	return (
		<div className={classes.root} color="light">
			<LoadImage
				src={headerImage || image}
				placeholder={placeholder || imagePlaceholder}
				withOverlay={true}
			/>
			<div className={classes.wrapper}>
				<div className={classes.content}>
					<Typography
						classes={{ h1: classes.heading }}
						component="h1"
						variant="h1"
					>
						{data.title || ''}
					</Typography>
					<Typography
						classes={{ subtitle1: classes.fontStyle }}
						component="div"
						variant="subtitle1"
					>
						{renderHTML(data.introText || '')}
					</Typography>
				</div>
			</div>
			<div
				className={classes.icon}
				onClick={handleClick}
				ref={(section) => {
					header = section;
				}}
			>
				<LongArrow/>
			</div>
		</div>
	);
};

export default compose(withStyles(styles, { name: 'AppFinalRatingHeader' }))(
	Header
);
