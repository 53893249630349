import React, { useState } from "react";
import { ProgressiveImage } from "@manakin/app-core";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles } from "@material-ui/styles";
import { useQuery } from '@apollo/react-hooks';
import { GQL_FETCH_CMS_HOME_PAGE_BLOCK_IMAGE_WITH_SUBTITLE } from "../graphql";

const useStyles = makeStyles(
    (theme) =>
        createStyles({
            image: {
                position: "relative",
                width: "100%",
                // height: '26rem',
                [theme.breakpoints.up("md")]: {
                    // height: '67rem',
                },
            },
            overlay: {
                backgroundColor: "white",
                position: "fixed",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                zIndex: "999",
                opacity: 0,
                transform: "scale(.8)",
                transformOrigin: "center",
                display: "flex",
                alignItems: "center",
                transition: "transform .2s, opacity .2s",
                pointerEvents: "none",
            },
            show: {
                opacity: 1,
                transform: "scale(1)",
                pointerEvents: "visible",
            },
            fullscreen: {
                width: "50px",
                height: "50px",
                backgroundColor: theme.palette.primary[300],
                position: "absolute",
                top: 0,
                right: "0",
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
            },
            content: {
                marginTop: "3rem",
                marginBottom: 0,
                textAlign: "center",
                fontWeight: "300",
            },
        }),
    {
        name: "AppHomepageBlockImageWithSubtitle",
    },
);

const ImageWithSubtitleBlock = (props) => {
    const classes = useStyles();
    const [ fullscreen, setFullscreen ] = useState(false);

    const { data = {} } = useQuery(GQL_FETCH_CMS_HOME_PAGE_BLOCK_IMAGE_WITH_SUBTITLE, {
        variables: {
            id: props.id,
        },
    });
    const { imageWithSubtitleHomepageBlock = {} } = data;
    const { image, zoom, subtitle } = imageWithSubtitleHomepageBlock;

    return (
        <div>
            <div className={classes.root}>
                <div className={classes.image}>
                    {zoom && (
                        <div
                            className={classes.fullscreen}
                            onClick={() => setFullscreen(true)}
                        >
                            <FullscreenIcon />
                        </div>
                    )}
                    <ProgressiveImage
                        preview={image ? image.thumbnailUrl : ""}
                        image={image ? image.url : ""}
                    />
                </div>
                {subtitle && (
                    <Typography
                        component='p'
                        variant='body2'
                        classes={{ body2: classes.content }}
                    >
                        {subtitle}
                    </Typography>
                )}
                {zoom && (
                    <div
                        className={classNames(classes.overlay, {
                            [classes.show]: fullscreen,
                        })}
                    >
                        <div
                            className={classes.fullscreen}
                            onClick={() => setFullscreen(false)}
                        >
                            <FullscreenExitIcon />
                        </div>

                        <ProgressiveImage
                            autoHeight={true}
                            preview={image ? image.thumbnailUrl : ""}
                            image={image ? image.url : ""}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ImageWithSubtitleBlock;
