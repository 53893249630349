"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _core = require("@material-ui/core");

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useStyles = (0, _core.makeStyles)(function (theme) {
	return {
		root: {
			minHeight: "80px",
			width: "100%",
			border: "1px solid " + theme.manakin.defaultBorderColor[500],
			display: "flex",
			alignItems: "stretch",
			cursor: "pointer",
			marginBottom: "20px",
			transition: "border-color .3s",
			"&:hover": {
				borderColor: theme.manakin.indicationColor[500]
			}
		},
		figure: {
			width: "80px",
			minWidth: "80px"
		},
		image: {
			width: "100%",
			height: "100%",
			objectFit: "cover"
		},
		content: { margin: 0 },
		contentContainer: {
			padding: "20px"
		}
	};
});

var BookTile = function BookTile(props) {
	var data = props.data,
	    onClick = props.onClick;

	var _ref = data || {},
	    _ref$image = _ref.image,
	    image = _ref$image === undefined ? {} : _ref$image;

	var classes = useStyles();

	return _react2.default.createElement(
		"div",
		{ className: classes.root, onClick: onClick },
		_react2.default.createElement(
			"figure",
			{ className: classes.figure },
			image && _react2.default.createElement("img", { className: classes.image, src: image.url || "" })
		),
		_react2.default.createElement(
			"div",
			{ className: classes.contentContainer },
			_react2.default.createElement(
				_core.Typography,
				{ className: classes.content, variant: "h6" },
				data.title
			),
			_react2.default.createElement(
				_core.Typography,
				{ className: classes.content },
				data.subtitle
			)
		)
	);
};

exports.default = BookTile;