"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _TableRow = require("@material-ui/core/TableRow");

var _TableRow2 = _interopRequireDefault(_TableRow);

var _Typography = require("@material-ui/core/Typography");

var _Typography2 = _interopRequireDefault(_Typography);

var _TableCell = require("@material-ui/core/TableCell");

var _TableCell2 = _interopRequireDefault(_TableCell);

var _core = require("@material-ui/core");

var _lib = require("../lib");

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useStyles = (0, _core.makeStyles)(function (theme) {
	return {
		content: {
			margin: 0
		}
	};
});

var TableRowComponent = function TableRowComponent(props) {
	var data = props.data,
	    _onClick = props.onClick;

	var classes = useStyles();

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	return _react2.default.createElement(
		_TableRow2.default,
		{ onClick: function onClick() {
				return _onClick(data);
			} },
		_react2.default.createElement(
			_TableCell2.default,
			{ component: "th", scope: "row" },
			_react2.default.createElement(
				_Typography2.default,
				{ variant: "h6" },
				data.subject.title
			),
			_react2.default.createElement(
				_Typography2.default,
				{ className: classes.content },
				data.subject.description
			)
		),
		_react2.default.createElement(
			_TableCell2.default,
			{ component: "th", scope: "row" },
			_react2.default.createElement(
				_Typography2.default,
				{ className: classes.content },
				data.student.fullName
			)
		),
		_react2.default.createElement(
			_TableCell2.default,
			{ component: "th", scope: "row" },
			_react2.default.createElement(
				_Typography2.default,
				{ className: classes.content },
				t("app-core.appointments-list.table-headers.trainer")
			)
		),
		_react2.default.createElement(
			_TableCell2.default,
			{ component: "th", scope: "row" },
			_react2.default.createElement(
				_Typography2.default,
				{ className: classes.content },
				(0, _lib.parseLocalDateTime)(data.time, t).format("D/MM/YYYY,"),
				_react2.default.createElement("br", null),
				(0, _lib.parseLocalDateTime)(data.time, t).format("H:mm")
			)
		)
	);
};

exports.default = TableRowComponent;