'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactI18next = require('react-i18next');

var _appCore = require('@manakin/app-core');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DemoAccountNoAccessDialog = function DemoAccountNoAccessDialog(props) {
	var onClose = props.onClose,
	    open = props.open;

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	return _react2.default.createElement(_appCore.StyledDialog, {
		open: open,
		onClose: onClose,
		title: t('app.account.demo-account-no-access-dialog.title'),
		contentText: t('app.account.demo-account-no-access-dialog.description'),
		positiveButtonText: t('common.ok') });
};

exports.default = DemoAccountNoAccessDialog;