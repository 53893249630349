import React, { useState, useEffect } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import createUuid from 'uuid/v4';
import classNames from 'classnames';
import MicNoneIcon from '@material-ui/icons/MicNone';
import Avatar from '@material-ui/core/Avatar';
import useSpeechToText from 'react-hook-speech-to-text';
import TextField from '@material-ui/core/TextField';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		transition: 'background-color 200ms linear',
		backgroundColor: 'transparent',
		'& *': { opacity: 1, pointerEvents: 'auto' },
	},
	loading: {
		height: theme.spacing(6),
		backgroundColor: theme.palette.grey[100],
		'& *': { opacity: 0, pointerEvents: 'none' },
	},
	input: {
		transition: 'opacity 200ms linear',
		width: (props) => ((props.readOnly || props.hideSpeechToText) ? '100%' : 'calc(100% - 100px)'),
	},
	inputLarge: {
		fontSize: '40px',
	},
	smallInputMargin: {
		marginBottom: '20px',
	},
	dateInput: {
		lineHeight: '27px',
		minHeight: '27px',
	},
	disabledLabel: {
		color: theme.manakin.disabledFieldLabelColor,
	},
	microphoneWrapper: {
		width: '60px',
		marginLeft: '40px',
		height: '60px',
		overflow: 'visible',
		backgroundColor: theme.manakin.tertiaryColor[500],
		'&:hover': {
			cursor: 'pointer',
			backgroundColor: theme.palette.grey[500],
		},
	},
	microphoneIcon: {
		width: '30px',
		height: '30px',
	},
	'@keyframes blink': {
		'0%': { opacity: '0' },
		'100%': { opacity: '0' },
		'50%': { opacity: '1' },
	},
	recordingIcon: {
		content: "''",
		width: '15px',
		height: '15px',
		backgroundColor: theme.manakin.indicationColor[500],
		position: 'absolute',
		top: '0',
		right: '0',
		opacity: '1',
		borderRadius: '100%',
	},
	recordingActive: {
		backgroundColor: 'red',
		animation: `$blink 2s infinite`,
	},
	disabledInput: {
		color: theme.manakin.disabledFieldTextColor,
	},
	label: {},
});

const SpeechField = (props) => {
	const {
		classes = {},
		className: classNameProp,
		inputClassName: inputClassNameProp,
		name,
		label,
		autoFocus = false,
		readOnly = false,
		type = 'text',
		fullWidth = true,
		hideAsterisk,
		form,
		loading,
		margin = 'normal',
		initialValue: _initial = false,
		shrink = true,
		inputLarge = false,
		onClick,
		placeholder,
		inputProps,
		onEnterPressed,
		hideSpeechToText = false
	} = props;
	const config = useSelector((state) => state.config) || {};
	const { general = {} } = config;
	const { t } = useTranslation();
	const { errors, schema } = form;
	const className = classNames(classes.root, { [classes.loading]: loading }, classNameProp);
	const initialValue = _initial ? _initial : '';
	const key = general.voiceApiKey;

	const { isRecording, results, startSpeechToText, stopSpeechToText, interimResult } = useSpeechToText({
		crossBrowser: true,
		googleApiKey: key,
		continuous: true,
		timeout: 0,
		useOnlyGoogleCloud: true,
		speechRecognitionProperties: {
			lang: t('common.language-code'),
			interimResults: true,
		},
		googleCloudRecognitionConfig: {
			languageCode: t('common.language-code'),
		},
	});

	//state hooks
	const [uuid] = useState(createUuid());
	const [value, setValue] = useState(initialValue);
	const [inputLabelProps, setInputLabelProps] = useState({});

	useEffect(() => {
		let _temp = {
			required: (schema[name] || {}).required && !hideAsterisk,
			htmlFor: uuid,
			className: classes.label,
		};
		if (shrink)
			setInputLabelProps({
				..._temp,
				shrink,
			});
		else
			setInputLabelProps({
				..._temp,
			});
	}, [classes]);

	useEffect(() => {
		if (!loading) {
			setValue(initialValue);
			form.onFieldChange({ key: name, value: initialValue });
		}
	}, [loading, initialValue]);

	const handleFieldChange = (event) => {
		let _value =
			type == 'number'
				? isNaN(parseInt(event.target.value))
					? event.target.value
					: parseInt(event.target.value)
				: event.target.value;

		setValue(_value);
		form.onFieldChange({ key: name, value: _value });
	};

	useEffect(() => {
		if ((results || interimResult) && !readOnly) {
			// Only append the last array value to the text, otherwise manual changes WILL be overridden
			const newValue = [value, results.pop()].filter((it) => !!it).join(',');
			setValue(newValue);
			form.onFieldChange({ key: name, value: newValue });
		}
	}, [results]);

	const handleEnterPress = (event) => {
		if (event.keyCode === 13 && onEnterPressed) {
			let _value = type == 'number' ? parseInt(event.target.value) : event.target.value;
			onEnterPressed(_value);
		}
	};

	return (
		<React.Fragment>
			<FormControl
				fullWidth={fullWidth}
				margin={margin}
				required={(schema[name] || {}).required}
				error={errors[name] && errors[name].length > 0}
				className={className}
			>
				<InputLabel
					{...inputLabelProps}
					classes={{
						...(readOnly && { root: classes.disabledLabel }),
					}}
				>
					{label}
				</InputLabel>
				<TextField
					multiline
					maxRows={4}
					id={uuid}
					type={type}
					value={value}
					onKeyUp={handleEnterPress}
					onChange={handleFieldChange}
					placeholder={placeholder}
					disabled={readOnly}
					onClick={onClick}
					inputProps={inputProps}
					autoFocus={autoFocus}
					readOnly={readOnly}
					className={classNames(classes.input, inputClassNameProp, {
						[classes.inputLarge]: inputLarge,
						[classes.disabledInput]: readOnly,
					})}
					classes={{
						...(type == 'date' && { input: classes.dateInput }),
						underline: classes.underline,
					}}
				/>
				{!readOnly && !hideSpeechToText && (
					<Avatar className={classes.microphoneWrapper} onClick={isRecording ? stopSpeechToText : startSpeechToText}>
						<MicNoneIcon fontSize="large" className={classes.microphoneIcon} />
						<div
							className={classNames(classes.recordingIcon, {
								[classes.recordingActive]: isRecording,
							})}
						/>
					</Avatar>
				)}
				<div className={classes.error}>
					{errors[name] && errors[name].length > 0 && errors[name].map((e, i) => <FormHelperText key={i}>{e}</FormHelperText>)}
				</div>
			</FormControl>
		</React.Fragment>
	);
};

export default withStyles(styles)(SpeechField);
