export const styles = (theme) => ({
	root: {
		width: '100%',
		backgroundColor: theme.manakin.defaultWorkformColor[500],
	},
	subHeader: {},
	contentColor: {
		color: theme.palette.getContrastText(theme.manakin.defaultWorkformColor[500]),
	},
	subHeader: {
		'&:after': {
			backgroundColor: theme.palette.getContrastText(theme.manakin.defaultWorkformColor[500]),
		},
	},
	noBg: {
		backgroundColor: 'transparent',
		'& $contentColor': {
			color: theme.manakin.defaultContentColor[500],
		},
		'& $button': {
			backgroundColor: theme.manakin.primaryPaletteColor[500],
			color: theme.manakin.primaryPaletteColor.contrastText,
		},
		'& $subHeader': {
			'&:after': {
				backgroundColor: theme.manakin.primaryPaletteColor[500],
			},
		},
	},
	secondaryColors: {
		backgroundColor: theme.manakin.secondaryWorkformColor[500],
		'& $contentColor': {
			color: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
		},
		'& $button': {
			color: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
		},
		'& $subHeader': {
			'&:after': {
				backgroundColor: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
			},
		},
	},
	primaryColors: {
		backgroundColor: theme.manakin.primaryWorkformColor[500],
		'& $contentColor': {
			color: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),
		},
		'& $button': {
			color: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),
		},
		'& $subHeader': {
			'&:after': {
				backgroundColor: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),
			},
		},
	},
	quote: {},
	container: {
		width: '100%',
		height: '100%',
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row',
		},
		'& $quote': {
			marginBottom: '5rem',
		},
	},
	content: {
		maxWidth: '650px',
	},
	loader: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		zIndex: 100,
	},
	wrapper: {
		...theme.manakin.defaultWrapper,
		order: 2,
		paddingTop: theme.manakin.defaultPadding,
		paddingBottom: '7rem',
		paddingTop: '60px',
		[theme.breakpoints.up('md')]: {
			order: 1,
			width: '56%',
			alignSelf: 'center',
			paddingBottom: '2.5rem',
			paddingTop: '55px',
		},
		[theme.breakpoints.up('lg')]: {
			padding: '10rem',
			width: '64%',
			alignSelf: 'center',
			paddingBottom: '10rem',
		},
	},
	headingRoot: {
		fontSize: '4rem',
		lineHeight: '5.4rem',
		letterSpacing: '.4rem',
		marginBottom: '2.4rem',
		[theme.breakpoints.up('xs')]: {
			fontSize: '3rem',
			lineHeight: '4rem',
		},
	},
	introduction: {
		[theme.breakpoints.up('md')]: {
			maxWidth: '364px',
		},
	},
	buttonContainer: {
		position: 'relative',
	},
	buttons: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	button: {
		marginBottom: '1.4rem',
		borderRadius: '5rem',
		color: theme.palette.getContrastText(theme.manakin.defaultWorkformColor[500]),
		[theme.breakpoints.up('md')]: {
			margin: '0 2rem 0 0',
		},
		[theme.breakpoints.up('lg')]: {
			margin: '0 5rem 0 0',
		},
		'&:last-child': {
			[theme.breakpoints.up('md')]: {
				margin: '0 0 0 0',
			},
		},
		'&:hover': {
			backgroundColor: theme.manakin.quaternaryThemeColor[500],
			color: theme.manakin.tertiaryThemeColor[500],
		},
	},
	inCorrect: {
		opacity: '0.5',
	},
	feedback: {
		position: 'relative',
		left: '0',
		zIndex: '2',
		opacity: '0',
		height: 0,
		width: '100%',
		overflow: 'hidden',
		pointerEvents: 'none',
		transition: 'opacity .3s',
		marginTop: '2rem',
	},
	activeFeedback: {
		opacity: '1',
		pointerEvents: 'auto',
		height: 'auto',
	},
	active: {
		backgroundColor: `${theme.manakin.correctAnswer[500]}`,
		borderColor: `${theme.manakin.correctAnswer[500]}`,
		color: `${theme.palette.getContrastText(theme.manakin.correctAnswer[500])}`,
	},
	imageContainer: {
		position: 'relative',
		order: 1,
		bottom: '0',
		right: '0',
		width: '100%',
		maxHeight: '30rem',
		height: '30rem',
		[theme.breakpoints.up('md')]: {
			order: 2,
			height: 'auto',
			maxHeight: '100%',
			position: 'relative',
			width: '44%',
		},
		[theme.breakpoints.up('lg')]: {
			width: '36%',
		},
	},
	columnDirection: {
		width: '100%',
		height: '100%',
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.up('lg')]: {
			flexDirection: 'column',
		},
		'& $quote': {
			marginBottom: '5rem',
		},
		'& $wrapper': {
			[theme.breakpoints.up('md')]: {
				order: 2,
				padding: '2.5rem',
				width: '100%',
			},
		},
		'& $imageContainer': {
			[theme.breakpoints.up('md')]: {
				order: 1,
				position: 'relative',
				height: '30rem',
				maxHeight: '30rem',
				width: '100%',
			},
			[theme.breakpoints.up('lg')]: {
				width: '100%',
			},
		},
	},
	saving: {
		position: 'relative',
		'&:after': {
			content: '""',
			position: 'absolute',
			width: '100%',
			height: '100%',
			backgroundColor: 'white',
			opacity: '0.7',
			zIndex: '99',
			top: 0,
			left: 0,
		},
	},
});
