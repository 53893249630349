import React, { useContext } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarMessage from './SnackbarMessage';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrent, isOpen } from './selectors';
import { processSnackbarMessage, closeSnackbar } from './actions';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { UiContext } from "@manakin/core"

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {},
			close: {},
		}),
	{
		name: "CoreSnackbarMessenger"
	}
);

const SnackbarMessenger = props => {
	const classes = useStyles(props);
	const dispatch = useDispatch();
	const oldSnackBarMessage = useSelector((state) => getCurrent(state));
	const oldSnackBarOpen = useSelector((state) => isOpen(state));

	const {
		snackBar: newSnackBarMessage,
		snackBarOpen: newSnackBarOpen,
		closeSnackBar
	} = useContext(UiContext);

	// Support the old snackbar and new snackbar system, for now
	const useOldSystem = newSnackBarMessage === undefined;
	const message = useOldSystem ? oldSnackBarMessage : newSnackBarMessage;
	const open = useOldSystem ? oldSnackBarOpen : newSnackBarOpen;

	const handleClose = (event, reason) => {
		if (reason === "clickaway") return;
		if (useOldSystem) {
			dispatch(closeSnackbar());
		} else {
			closeSnackBar();
		}
	};

	const handleExited = () => {
		if (useOldSystem) {
			dispatch(processSnackbarMessage());
		} else {
			closeSnackbar();
		}
	};

	return (
		<Snackbar
			classes={{ root: classes.root }}
			key={message && message.key}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			open={open}
			autoHideDuration={(message && message.duration) || 6000}
			onClose={handleClose}
			onExited={handleExited}
		>
			<SnackbarMessage onClose={handleClose} message={message}/>
		</Snackbar>
	)
}

export default SnackbarMessenger;
