import React, { useState, useEffect, useReducer, useCallback } from 'react';
import WidgetContainer from './parts/WidgetContainer';
import { BoxService } from '@manakin/app-core';
import { getAppUser } from '@manakin/authentication/selectors';
import { withQuery } from '@manakin/core';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { goBackAppBar } from '@manakin/app-core/AppBar/actions';
import { useQuery } from 'react-apollo';
import { GQL_CURRENT_APP_USER, GQL_FETCH_APP_WIDGETS } from './parts/graphql';
import { setAppUser } from '@manakin/authentication/actions';
import WelcomeWidget from './parts/WelcomeWidget';
import { widget } from './constants';

const styles = (theme) => ({
	loading: {
		width: '100vw',
		height: '100vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
});

const WidgetDashboard = (props) => {
	const { classes } = props;
	const dispatch = useDispatch();
	const appUser = useSelector((state) => getAppUser(state)) || {};

	const [loading, setLoading] = useState(true);
	const [resume, setResume] = useState(null);
	const [widgets, setWidgets] = useState([]);

	const [programData, setData] = useReducer(
		(state, data) => {
			return data.program || {};
		},
		{
			loading: true,
		}
	);

	const { data: widgetsData, loading: widgetsLoading } = useQuery(GQL_FETCH_APP_WIDGETS, {
		variables: {
			page: 0,
			pageSize: 99,
		},
		onCompleted: () => {
			if (widgetsData && widgetsData.appWidgets && widgetsData.appWidgets.appWidgets) {
				setWidgets(widgetsData.appWidgets.appWidgets);
			}
		},
	});

	// queries
	const { data: userData = {}, loading: userLoading } = useQuery(GQL_CURRENT_APP_USER);

	const setAppUserStore = useCallback((redirect) => dispatch(setAppUser(redirect)), [dispatch]);
	const goBackAppBarDispatch = useCallback((redirect) => dispatch(goBackAppBar(redirect)), [dispatch]);

	const handleLoadChange = (data) => {
		setLoading(data);
	};

	const handleData = (data, percentage = 0, appUserData) => {
		setData(data);
		setResume(appUserData);
	};

	useEffect(() => {
		if (!loading) {
			goBackAppBarDispatch('/altDashboard');
		}
	}, [loading]);

	useEffect(() => {
		if (!userLoading && userData && userData.currentAppUser) {
			const currentAppUser = userData.currentAppUser;
			setAppUserStore(currentAppUser);
		}
	}, [userData, userLoading]);

	return (
		<div className={classes.root}>
			<BoxService onNewData={handleData} onLoadChange={handleLoadChange}>
				<React.Fragment>
					<WelcomeWidget
						data={widgets.find((item) => item.type === widget.welcomeAppWidget)}
						user={appUser}
						programData={programData}
						resume={resume}
						loading={loading}
					/>
					<WidgetContainer data={widgets} loading={widgetsLoading} />
				</React.Fragment>
			</BoxService>
		</div>
	);
};

export default compose(withStyles(styles), withQuery)(WidgetDashboard);
