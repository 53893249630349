import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { WorkformViewer } from '@manakin/app-core';
import { Header, Introduction, Bottom } from './parts';
import VisibilitySensor from 'react-visibility-sensor';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

const Lesson = (props) => {
	const { classes, workforms, options, onFinish, onReset, lessonData = {}, prefix, box, preview = false, saving } = props;
	const location = useLocation();

	const [itemsInView, setItemsInView] = useState({});
	const [theposition, setTheposition] = useState(null);

	useEffect(() => {
		window.addEventListener('scroll', listenToScroll);
		return () => window.removeEventListener('scroll', listenToScroll);
	}, []);

	const listenToScroll = () => {
		const winScroll = window.pageYOffset || document.documentElement.scrollTop;

		const scrolled = winScroll;
		setTheposition(scrolled);
		// theposition.current = scrolled;
	};

	const handleChange = (isVisible, item) => {
		const _temp = { ...itemsInView };
		_temp[item.id] = _temp[item.id] ? _temp[item.id] : isVisible;
		setItemsInView(_temp);
	};

	return (
		<div className={classes.root}>
			<Header data={lessonData} prefix={prefix} />
			<Introduction data={lessonData} />
			{workforms &&
				workforms.map((item) => (
					<div key={item.id} className={classes.normalMargin}>
						<div
							className={classNames(classes.largeWrapper, {
								[classes.chatWrapper]: item.type === 'ChatBubble',
							})}
						>
							{item.type === 'ChatBubble' ? (
								<VisibilitySensor onChange={(data) => handleChange(data, item)}>
									<div>
										<WorkformViewer
											type={item.type}
											item={item}
											onFinish={onFinish}
											options={options}
											animate={itemsInView[item.id] || false}
										/>
									</div>
								</VisibilitySensor>
							) : (
								<WorkformViewer
									type={item.type}
									item={item}
									onFinish={onFinish}
									options={options}
									animate={itemsInView[item.id] || false}
									saving={saving}
									customLink={{
										state: {
											url: location.pathname,
											idx: theposition,
										},
									}}
								/>
							)}
						</div>
					</div>
				))}
			{!preview && <Bottom box={box} lessonData={lessonData} onClick={onReset} />}
		</div>
	);
};

export default withStyles(styles, { name: 'AppLesson' })(Lesson);
