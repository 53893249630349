import React from "react";
import { withStyles } from "@material-ui/core/styles";
import renderHTML from "react-render-html";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { LoadImage } from "@manakin/app-core";
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
    root: {
        backgroundColor: theme.manakin.defaultElementBGColor[500],
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            width: '100%',
            height: '100%',
            minHeight: '100vh',
        },
    },
    wrapper: {
        ...theme.manakin.extraSmallWrapper,
        paddingTop: theme.manakin.defaultPadding,
        paddingBottom: theme.manakin.defaultPadding,
        maxWidth: '500px',
    },
    image: {
        height: '460px',
        width: '100vw',
        backgroundSize: 'cover',
        position: 'relative',
        opacity: 0,
        animation: 'fadeIn .3s ease 1s forwards',
        [theme.breakpoints.up('md')]: {
            width: '50%',
            height: 'auto',
            order: 2,
        },
    },
    subheading: {
        fontWeight: 600,
        marginBottom: '3.2rem',
        color: theme.palette.getContrastText(
            theme.manakin.defaultElementBGColor[500]
        ),
        [theme.breakpoints.up('md')]: {
            marginBottom: '6.5rem',
        },
    },
    content: {
        backgroundColor: theme.manakin.defaultElementBGColor[500],
        color: theme.palette.getContrastText(
            theme.manakin.defaultElementBGColor[500]
        ),
        opacity: 0,
        animation: 'showBackwards .3s ease 1s forwards',
        padding: '50px 0',
        [theme.breakpoints.up('md')]: {
            width: '50%',
            height: 'auto',
            order: 1,
            display: 'flex',
            alignItems: 'center',
            padding: '100px 0',
        },
    },
    body: {
        color: theme.palette.getContrastText(
            theme.manakin.defaultElementBGColor[500]
        ),
    },
    button: {},
});

const Header = (props) => {
    const { t } = useTranslation();

    const handleClick = () => {
        props.onClick();
    };

    const { classes, data } = props;
    return (
        <div className={classes.root}>
            <div className={classes.image}>
                <LoadImage
                    src={data.image ? data.image.url : null}
                    placeholder={
                        data.image ? data.image.thumbnailUrl : null
                    }
                />
            </div>
            <div className={classes.content}>
                <div className={classes.wrapper}>
                    <Typography
                        component='p'
                        variant='h3'
                        component='h1'
                        classes={{ h3: classes.subheading }}
                    >
                        {data.introTitle || ""}
                    </Typography>
                    <Typography
                        component='div'
                        variant='body1'
                        className={classes.body}
                    >
                        {renderHTML(data.introText || "")}
                    </Typography>
                    <Button
                        variant='contained'
                        color='primary'
                        className={classes.button}
                        onClick={handleClick}
                    >
                        {data.introActionTitle || t("app.elements.case.intro-action")}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default withStyles(styles, { name: 'AppCaseHeader' })(Header);
