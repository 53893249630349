import React, { useCallback } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import { useQuery } from "@apollo/react-hooks";
import { showSnackbarMessage } from "@manakin/core/actions";
import "url-search-params-polyfill";
import useStyles from "./styles";
import { GQL_FETCH_CURRENT_APP_USER, GQL_FETCH_CURRENT_CMS_USER } from "./graphql";
import { useDispatch } from "react-redux";
import {useTranslation} from 'react-i18next';

const Reset2Factor = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  //dispatches
  const dispatch = useDispatch();
  const onSnackbarMessage = useCallback(
    (message, variant) =>
      dispatch(
        showSnackbarMessage({
          text: message || t("authentication.two-factor.reset.success"),
          variant: variant || "success",
        })
      ),
    [dispatch]
  );

  //queries
  const { loading, data: { currentAppUser: appUser } = {} } = useQuery(
    GQL_FETCH_CURRENT_APP_USER
  );
  const { data: { currentCmsUser: cmsUser } = {} } = useQuery(
    GQL_FETCH_CURRENT_CMS_USER
  );

  let currentUser = appUser
  if(!loading && !appUser) {
    currentUser = cmsUser;
  }

  currentUser = currentUser || {};

  //functions
  const onReset2Factor = () => {
    if (props.mutate) {
      props.mutate().then((data) => {
        if (!data.errors) onSnackbarMessage();
      });
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.smallWrapper}>
        <Typography className={classes.heading} variant="h2">
          {t("authentication.two-factor.reset.title")}
        </Typography>
        <Typography
          classes={{ body1: classes.paragraph }}
          variant="body1"
          gutterBottom
        >
          {t("authentication.two-factor.reset.description")}
        </Typography>
        <Typography classes={{ body1: classes.paragraph }} variant="body1">
          {currentUser.email || ""}
        </Typography>
        <Typography
          classes={{ body1: classes.paragraph }}
          variant="body1"
          gutterBottom
        >
          {t("authentication.two-factor.reset.extra-description")}
        </Typography>
        <FormControl margin="normal">
          <Button
            className={classes.submit}
            color="primary"
            variant="contained"
            onClick={onReset2Factor}
          >
            {t("authentication.two-factor.reset.action")}
          </Button>
        </FormControl>
      </div>
    </div>
  );
};

export default Reset2Factor;
