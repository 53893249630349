import gql from 'graphql-tag';
import { appAssetWithThumbnail } from '@manakin/app-core/graphQlHelpers';

export const GQL_FETCH_ENTRYTEST = gql`
    query($id: ID!) {
        entryTest(id: $id) {
            id
            type
            introTitle
            introText
            preTitle
            titleColor
            image {
                ${appAssetWithThumbnail}
            }
            workforms {
                id
                type
            }
        }
    }
`;
