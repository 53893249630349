'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _graphql = require('./graphql');

var _reactHooks = require('@apollo/react-hooks');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var useSetupReadSpeaker = function useSetupReadSpeaker() {
	var ref = (0, _react.useRef)(true);

	(0, _reactHooks.useQuery)(_graphql.GQL_FETCH_GLOBAL_SETTINGS, {
		variables: {
			category: 'SETTINGS_CATEGORY_GENERAL'
		},
		onCompleted: function onCompleted(data) {
			var _ref = data ? data.settings || {} : {},
			    _ref$settings = _ref.settings,
			    settings = _ref$settings === undefined ? [] : _ref$settings;

			if (ref.current) {
				ref.current = false;

				var settingsObj = settings.length && settings.reduce(function (obj, setting) {
					return _extends({}, obj, _defineProperty({}, setting.name, setting));
				}, {});

				if (settingsObj && settingsObj.readerFunctionActive && settingsObj.readerFunctionActive.value) {
					if (settingsObj.readerFunctionActive.value === 'true') {
						var webReaderConfig = document.createElement('script');
						var webReaderScript = document.createElement('script');
						webReaderScript.src = '//cdn1.readspeaker.com/script/10096/webReader/webReader.js?pids=wr';
						webReaderScript.async = true;
						window.rsConf = {
							general: { usePost: true },
							settings: {
								hlspeed: 85
							}
						};

						document.body.appendChild(webReaderConfig);
						document.body.appendChild(webReaderScript);
					}
				}
			}
		}
	});
};

exports.default = useSetupReadSpeaker;