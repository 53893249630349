'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.GQL_UPDATE_RATING_STUDENT_RESULT = exports.GQL_FETCH_RATING_STUDENT_RESULT_ASSETS = exports.GQL_UPLOAD_ASSET = undefined;

var _templateObject = _taggedTemplateLiteral(['\n    mutation uploadRatingFile(\n        $programId: ID!\n        $boxId: ID!\n        $elementId: ID!\n        $file: Upload!\n    ) {\n        uploadRatingFile(\n            programId: $programId\n            boxId: $boxId\n            elementId: $elementId\n            file: $file\n        ) {\n            id\n            assets {\n                id\n                url\n            }\n        }\n    }\n'], ['\n    mutation uploadRatingFile(\n        $programId: ID!\n        $boxId: ID!\n        $elementId: ID!\n        $file: Upload!\n    ) {\n        uploadRatingFile(\n            programId: $programId\n            boxId: $boxId\n            elementId: $elementId\n            file: $file\n        ) {\n            id\n            assets {\n                id\n                url\n            }\n        }\n    }\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n    query ratingStudentResult(\n        $programId: ID!\n        $boxId: ID!\n        $elementId: ID!\n        $studentId: ID!\n    ) {\n        ratingStudentResult(\n            programId: $programId\n            boxId: $boxId\n            elementId: $elementId\n            studentId: $studentId\n        ) {\n            id\n            assets {\n                id\n                name\n                url\n            }\n        }\n    }\n'], ['\n    query ratingStudentResult(\n        $programId: ID!\n        $boxId: ID!\n        $elementId: ID!\n        $studentId: ID!\n    ) {\n        ratingStudentResult(\n            programId: $programId\n            boxId: $boxId\n            elementId: $elementId\n            studentId: $studentId\n        ) {\n            id\n            assets {\n                id\n                name\n                url\n            }\n        }\n    }\n']),
    _templateObject3 = _taggedTemplateLiteral(['\n    mutation updateRatingStudentResult($input: UpdateRatingStudentResultInput!) {\n      updateRatingStudentResult(input: $input) {\n          id\n          assets {\n              id\n              url\n              name\n          }\n      }\n    }  \n'], ['\n    mutation updateRatingStudentResult($input: UpdateRatingStudentResultInput!) {\n      updateRatingStudentResult(input: $input) {\n          id\n          assets {\n              id\n              url\n              name\n          }\n      }\n    }  \n']);

var _graphqlTag = require('graphql-tag');

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_UPLOAD_ASSET = exports.GQL_UPLOAD_ASSET = (0, _graphqlTag2.default)(_templateObject);

var GQL_FETCH_RATING_STUDENT_RESULT_ASSETS = exports.GQL_FETCH_RATING_STUDENT_RESULT_ASSETS = (0, _graphqlTag2.default)(_templateObject2);

var GQL_UPDATE_RATING_STUDENT_RESULT = exports.GQL_UPDATE_RATING_STUDENT_RESULT = (0, _graphqlTag2.default)(_templateObject3);