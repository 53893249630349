import React, { useContext } from 'react';
import Stepper from './parts/Stepper';
import useStyles from './styles';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import StepsContext from './context/stepsContext';
import { useHistory } from 'react-router-dom';
import { WebshopSteps } from '../lib';

const WebshopWrapper = (props) => {
	const { children, form, onSubmit } = props;
	const { step, setStep, disabled } = useContext(StepsContext);
	const classes = useStyles();
	const history = useHistory();
	const { t } = useTranslation();

	const handleClick = () => {
		form.onValidate().then((r) => {
			if (step === WebshopSteps.AUTH) {
				onSubmit();
			} else {
				setStep(step + 1);
			}
		});
	};

	const handleBack = () => {
		if (step === 0) {
			history.push('/');
		} else {
			setStep(step - 1);
		}
	};

	return (
		<div className={classes.root}>
			<div className={classes.container}>
				<Stepper steps={Object.values(WebshopSteps)} />
				{children}
				<div className={classes.buttonGroup}>
					<Button onClick={handleBack} color="primary">
						{t('common.back')}
					</Button>
					<Button
						onClick={handleClick}
						color="primary"
						variant="contained"
						className={classes.mainButtonStyle}
						disabled={disabled <= step}
					>
						{t('common.continue')}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default WebshopWrapper;
