import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { compose } from "recompose";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
	root: {
		display: "flex",
		flexDirection: "row",

		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
		},
	},
	title: {
		color: "white",
		fontWeight: "400",
		marginBottom: 0,
	},
	subtitle: {
		color: "white",
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
	},
	imageContainer: {
		marginLeft: theme.spacing(3),
		width: "60%",
		display: "flex",
		[theme.breakpoints.down("sm")]: {
			width: "75%",
			margin: 0,
		},
		[theme.breakpoints.down("xs")]: {
			width: "100%",
			margin: 0,
		},
	},
	image: {
		maxWidth: 400,
		filter: "drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5))",
	},
	text: {
		marginBottom: 0,
		paddingBottom: "0 !important",
		color: theme.manakin.subHeaderColor[500],
		textAlign: "center",
		textTransform: "uppercase",
		fontWeight: "500",
	},
	button: {
		border: `2px solid ${theme.manakin.softBorderColor[500]}`,
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
		marginBottom: theme.spacing(6),
		minHeight: 0,
		"& > span": {
			textTransform: "uppercase",
			fontWeight: "bold",
		},
	},
	subHeader: {},
	contentColor: {},
});

const PlannerBlock = (props) => {
	const { classes, theme, student } = props;
	const history = useHistory();
	const { t } = useTranslation();

	const handleOpenPlanner = () => {
		history.push("/planner");
	};

	return (
		<div className={classes.root}>
			<div className={classes.metadata}>
				<Typography
					component="h4"
					variant="h4"
					className={classNames(
						classes.subHeader,
						classes.contentColor,
						classes.title
					)}
				>
					{t("app.progress-dashboard.blocks.planner.title")}
				</Typography>
				<Typography
					component="p"
					variant="subtitle1"
					className={classNames(
						classes.subHeader,
						classes.contentColor,
						classes.subtitle
					)}
				>
					{student
						? t("app.progress-dashboard.blocks.planner.description-student")
						: t("app.progress-dashboard.blocks.planner.description")}
				</Typography>
				<Button
					variant="contained"
					color="primary"
					size={"small"}
					className={classes.button}
					onClick={handleOpenPlanner}
				>
					{t("app.progress-dashboard.blocks.planner.action")}
				</Button>
			</div>
			<div className={classes.imageContainer}>
				<img
					alt={t("common.calendar")}
					src={theme.manakin.calendarVisual}
					className={classes.image}
				/>
			</div>
		</div>
	);
};

export default compose(
	withStyles(styles, {
		name: "AppProgressDashboardPlannerBlock",
		withTheme: true,
	}),
	connect(({ config }) => ({ config }))
)(PlannerBlock);
