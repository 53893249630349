'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});
var cbdFunctions = exports.cbdFunctions = {
	ASSISTENT_DROGIST: 'Assistent drogist',
	DROGIST: 'Drogist'
};

var LoginTypes = exports.LoginTypes = {
	Native: 'NATIVE',
	ThirdParty: 'THIRD_PARTY'
};