import React, { useState } from "react";
import Block from "./Block";
import { ProgressBlock } from "@manakin/app-core";
import { useQuery } from "react-apollo";
import { GQL_FETCH_PROGRAMS_PROGRESS } from "../graphql/graphql";
import { useTranslation } from 'react-i18next';
import { studentRoles } from "@manakin/app-core/lib";

const ProgramProgress = (props) => {
	const { currentAppUser, program, students } = props;
	const { t } = useTranslation();
	const [progress, setProgress] = useState(0);
	const isStudent =
		currentAppUser &&
		currentAppUser.roles &&
		currentAppUser.roles.some((role) => studentRoles.includes(role.name));

	// If data is loading, or we are a trainer and no students have been fetched, we are loading
	const dataLoading =
		!currentAppUser ||
		!program.id ||
		isStudent === undefined ||
		(isStudent === false && students === undefined);

	// Query
	useQuery(GQL_FETCH_PROGRAMS_PROGRESS, {
		skip: dataLoading,
		variables: {
			...(!dataLoading && {
				programs: [program.id],
				users: isStudent
					? [currentAppUser.id]
					: students.map((student) => student.id),
			}),
		},
		onCompleted: (data) => {
			if (
				!data ||
				!data.programResults ||
				data.programResults.length === 0
			)
				return;

			if (isStudent) {
				const programResult = data.programResults.find(
					(programResult) => programResult.program.id === program.id
				);
				if (programResult) {
					setProgress(programResult.progress);
				}
			} else {
				let totalProgress = 0;

				data.programResults.forEach((result) => {
					totalProgress += result.progress;
				});

				if (isStudent) {
					setProgress(totalProgress);
				} else {
					// Users who do not have any progress are also counted, because they are at 0 progress
					setProgress(totalProgress / students.length);
				}
			}
		},
	});

	return (
		<Block
			title={t("app.progress-dashboard.blocks.program-progress.title")}
			subtitle={program.name}
		>
			<ProgressBlock
				subtitle={t("common.completed")}
				progress={progress}
				warning={false}
			/>
		</Block>
	);
};

export default ProgramProgress;
