import gql from "graphql-tag";

export const GQL_FETCH_APP_USER = gql`
	query appUser($id: ID!) {
		appUser(id: $id) {
			id
			fullName
		}
	}
`;

export const GQL_FETCH_PROGRAM = gql`
	query program($id: ID!) {
		program(id: $id) {
			id
			name
		}
	}
`;
