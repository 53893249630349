import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { FilterSelectField } from '@manakin/app-core';
import { useHistory } from 'react-router';
import { Typography, Button } from '@material-ui/core';

const FrontPage = (props) => {
	const { tags, programId, category } = props;
	const { t } = useTranslation();
	const classes = useStyles();
	const history = useHistory();

	const [dataTags, setDataTags] = useState([]);

	const options =
		(tags &&
			tags.map((tag) => {
				return {
					id: tag.id,
					label: tag.name,
				};
			})) ||
		[];

	const handleClick = () => {
		if (dataTags && dataTags.length) {
			const tagsMap = dataTags.map((tag) => {
				return tag.id;
			}, []);
			if (tagsMap && tagsMap.length) {
				history.push({
					pathname: `/ExamTrainer/${programId}/programTrainer`,
					state: {
						tags: tagsMap,
						category: category
					},
				});
			}
		}
	};

	const handleChange = (tags) => {
		setDataTags(tags);
	};

	return (
		<React.Fragment>
			<Typography variant="h3">{t('app.dashboard.alt-content.exam-choose-tags')}</Typography>

			<div className={classes.selectField}>
			<FilterSelectField
				label={t('app.dashboard.alt-content.exam-choose-tags-label')}
				options={options}
				showButtons
				onChange={handleChange}
			/>
			</div>
			<Button variant="contained" color="primary" className={classes.button} onClick={handleClick}>
				{t('common.start')}
			</Button>
		</React.Fragment>
	);
};

export default FrontPage;
