import React from 'react';
import uuidv4 from 'uuid/v4';
import classNames from 'classnames';
import MuiDialog from '@material-ui/core/Dialog';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getData, isOpen } from './selectors';
import { closeDialog } from './actions';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({});

const mapStateToProps = (state, ownProps) => {
    return {
        open: isOpen(ownProps.name)(state),
        data: getData(ownProps.name)(state),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    onClose: () => dispatch(closeDialog(ownProps.name)),
});

class Dialog extends React.PureComponent {
    render() {
        const {
            render,
            name,
            open,
            onClose,
            classes,
            loading,
            data,
        } = this.props;
        const PaperProps = {};
        return (
            <React.Fragment>
                {render({ PaperProps, open, onClose, data })}
            </React.Fragment>
        );
    }
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(Dialog);
