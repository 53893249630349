'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _styles = require('@material-ui/core/styles');

var _Button = require('@material-ui/core/Button');

var _Button2 = _interopRequireDefault(_Button);

var _reactScrollToComponent = require('react-scroll-to-component');

var _reactScrollToComponent2 = _interopRequireDefault(_reactScrollToComponent);

var _reactRenderHtml = require('react-render-html');

var _reactRenderHtml2 = _interopRequireDefault(_reactRenderHtml);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = function styles(theme) {
    return {
        root: {
            backgroundColor: theme.manakin.primaryColor[500],
            padding: '20px'
        },
        content: {}
    };
};

var FeedbackBox = function (_React$PureComponent) {
    _inherits(FeedbackBox, _React$PureComponent);

    function FeedbackBox() {
        _classCallCheck(this, FeedbackBox);

        return _possibleConstructorReturn(this, (FeedbackBox.__proto__ || Object.getPrototypeOf(FeedbackBox)).apply(this, arguments));
    }

    _createClass(FeedbackBox, [{
        key: 'componentDidUpdate',
        value: function componentDidUpdate(prevProps) {
            var _this2 = this;

            if (!prevProps.active && this.props.active) {
                if (this.props.variant == 'trainer') {
                    this.props.onClick();
                } else if (!this.props.finished) {
                    setTimeout(function () {
                        (0, _reactScrollToComponent2.default)(_this2.FeedbackBox, {
                            offset: 30,
                            align: 'bottom',
                            duration: 500
                        });
                    }, 500);
                }
            }
        }
    }, {
        key: 'render',
        value: function render() {
            var _this3 = this;

            var _props = this.props,
                classes = _props.classes,
                _props$title = _props.title,
                title = _props$title === undefined ? '' : _props$title,
                _props$content = _props.content,
                content = _props$content === undefined ? '' : _props$content,
                buttonText = _props.buttonText,
                onClick = _props.onClick,
                _props$variant = _props.variant,
                variant = _props$variant === undefined ? 'default' : _props$variant;


            return _react2.default.createElement(
                _react2.default.Fragment,
                null,
                variant != 'trainer' && _react2.default.createElement(
                    'div',
                    {
                        className: classes.root,
                        ref: function ref(section) {
                            _this3.FeedbackBox = section;
                        }
                    },
                    _react2.default.createElement(
                        _Typography2.default,
                        {
                            component: 'h4',
                            variant: 'h4',
                            className: classes.content
                        },
                        title
                    ),
                    _react2.default.createElement(
                        _Typography2.default,
                        {
                            component: 'div',
                            variant: 'body1',
                            className: classes.content
                        },
                        (0, _reactRenderHtml2.default)(content || '')
                    ),
                    buttonText && _react2.default.createElement(
                        _Button2.default,
                        {
                            className: classes.button,
                            variant: 'contained',
                            color: 'primary',
                            fullWidth: true,
                            onClick: onClick
                        },
                        buttonText
                    )
                )
            );
        }
    }]);

    return FeedbackBox;
}(_react2.default.PureComponent);

exports.default = (0, _styles.withStyles)(styles, { name: 'AppFeedbackBox' })(FeedbackBox);