import { SET_APP_BAR_TITLE } from './actions';

const initialState = {
    title: '',
};

export const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_APP_BAR_TITLE: {
            return { ...state, title: action.payload.title };
        }
        default:
            return state;
    }
};

export default reducer;
