import React, { useEffect, useState } from 'react';
import { withWorkforms, ElementOverlay } from '@manakin/app-core';
import { compose } from 'recompose';
import BookCheck from './BookCheck';
import { connect } from 'react-redux';
import { changeColor } from '@manakin/app-core/ColorService/actions';
import { useLocation } from 'react-router-dom';
import { GQL_FETCH_STYLESHEET } from './graphql/graphql';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
const queryString = require('query-string');

const BookCheckPreview = (props) => {
    const { match, workformsData, changeColor } = props;
    const { rawData = {} } = workformsData;
    const query = match.params.query;
    const elementData = queryString.parse(query);
    const location = useLocation();
    const [items, setItems] = useState([]);
    const [itemTypes, setItemTypes] = useState([]);
    const { t } = useTranslation();

    //state hooks
    const [step, setStep] = useState(1);

    //queries
    const { data: styleSheetData = {}, loading } = useQuery(
        GQL_FETCH_STYLESHEET,
        {
            variables: {
                id: elementData.styleSheet || '',
            },
        }
    );

    useEffect(() => {
        if (elementData && elementData.items) {
            if (Array.isArray(elementData.items)) {
                setItems(elementData.items);
                setItemTypes(elementData.itemTypes);
            } else {
                setItems([elementData.items]);
                setItemTypes([elementData.itemTypes]);
            }
        }
    }, [query]);

    useEffect(() => {
        if (!loading) {
            const obj = {
                ...elementData,
                headerImage: {
                    url: elementData.headerImage,
                },
                image: {
                    url: elementData.image,
                },
                workforms: [
                    ...items.map((item, idx) => ({
                        id: item,
                        type: itemTypes[idx],
                    })),
                ],
            };

            changeColor({
                ...styleSheetData.styleSheet,
                location: location.pathname,
            });

            workformsData.loadWorkforms({ ...obj });
        }
    }, [loading, items, itemTypes]);

    const handleFinish = () => {
        setStep(step < 3 ? step + 1 : 2);
    };

    const handleClick = () => {
        setStep(step < 3 ? step + 1 : 1);
    };

    return (
        <ElementOverlay
            controls={false}
            background={rawData.borderColor || false}
            title={t("app.elements.book-check.title") || null}
        >
            <BookCheck
                {...workformsData}
                step={step}
                onExit={handleFinish}
                onClick={handleClick}
            />
        </ElementOverlay>
    );
};

export default compose(
    connect(null, (dispatch) => ({
        changeColor: (styleSheet) => dispatch(changeColor(styleSheet)),
    })),
    withWorkforms()
)(BookCheckPreview);
