import { makeStyles, createStyles } from '@material-ui/core/styles';

export default (props = {}) =>
	makeStyles(
		(theme) =>
			createStyles({
				root: {
					backgroundColor: props.loginBackgroundColor || theme.palette.primary[500],
					height: '100vh',
					minHeight: '600px',
					display: 'flex',
					position: "relative",
					[theme.breakpoints.down('md')]: {
						flexFlow: 'row wrap',
						justifyContent: 'center',
					},
				},
				logo: {
					backgroundImage: `url(${props.loginCustomerLogo || theme.manakin.logo})`,
					position: 'absolute',
					right: '4rem',
					top: '3rem',
					[theme.breakpoints.down('md')]: {
						position: 'relative',
						margin: '0 auto',
						right: 'auto',
						width: '100%',
						backgroundPosition: 'center',
					},
				},
				content: {
					width: '100%',
					backgroundImage: `url(${props.loginBackgroundImage || theme.manakin.loginBackgroundImage})`,
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
					backgroundPosition: 'right center',
					padding: '80px',
					[theme.breakpoints.up('md')]: {
						display: 'flex',
						'&>svg': {
							width: '100%',
							height: '100%',
						},
					},
					[theme.breakpoints.down('md')]: {
						position: 'absolute',
						opacity: '.1',
						top: 0,
						left: 0,
						height: '100vh',
						width: '100%',
						padding: '32px',
					},
				},
				visual: {
					...theme.manakin.visual,
					backgroundSize: 'contain',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
				},
				login: {
					width: '120%',
					display: 'flex',
					alignItems: 'flex-start',
					justifyContent: 'center',
					overflow: 'hidden',
					position: 'relative',
					opacity: 0,
					transform: 'translateY(32px)',
					transition: 'transform .3s ease, opacity .3s ease',
					[theme.breakpoints.up('md')]: {
						maxWidth: '640px',
						width: '100%',
						display: 'flex',
						flexDirection: 'column',
					},
					[theme.breakpoints.down('md')]: {
						alignItems: 'center',
					},
				},
				mounted: {
					width: '100%',
					opacity: '1',
					display: 'flex',
					flexDirection: 'column',
					transform: 'none',
					alignItems: 'center',
				},
				footNote: {
					textAlign: 'center',
					display: 'flex',
					flexDirection: 'column',
					bottom: '4.6rem',
					position: 'relative',
					marginTop: '5rem',
					[theme.breakpoints.up('md')]: {
						position: 'absolute',
						margin: 0,
						left: '50%',
						transform: 'translateX(-50%)',
					},
				},
				link: {
					marginTop: theme.spacing(1),
					textDecoration: 'none',
					color: '#009AC3',
					cursor: 'pointer',
				},
				headerTitle: {
					color: theme.palette.getContrastText(props.loginBackgroundColor || theme.palette.primary[500]),
					left: 0,
					fontSize: '3.8rem',
					paddingBottom: 0,
					lineHeight: '4.2rem',
					fontFamily: theme.manakin.logoFont || "'Ubuntu', sans-serif",
					fontWeight: 'bold',
					width: '100%',
					maxWidth: 400,
					marginBottom: '1.8rem',
				},
				visualTitle: {
					color: theme.palette.secondary[500],
					fontFamily: theme.manakin.defaultTitleFont,
					fontWeight: '500',
					position: 'absolute',
					bottom: '12rem',
					left: '9rem',
					display: 'none',
					[theme.breakpoints.up('lg')]: {
						fontSize: '12rem',
						lineHeight: '13rem',
						display: 'block',
					},
				},
				footerLogo: {
					background: theme.palette.getContrastText(props.loginBackgroundColor || theme.palette.primary[500]),
					maskImage: `url(${props.loginCustomerFooterLogo || theme.manakin.footerLogo})`,
					maskRepeat: 'no-repeat',
				},
				footNoteExtra: {},
				scrollIndicator: {
					position: 'absolute',
					bottom: '0',
					transform: 'translateX(-50%)',
					left: '50%',
					right: '50%',
					width: '50px',
					height: '50px',
					animation: '$UpDown .8s alternate infinite',
					cursor: 'pointer',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',

					'& span': {
						position: 'absolute',
						top: '0',
						width: '24px',
						height: '24px',
						borderLeft: '2px solid #000',
						borderBottom: '2px solid #000',
						transform: 'rotate(-45deg)',

						'&:nth-of-type(2)': {
							top: '14px'
						}
					}
				},
				'@keyframes UpDown': {
					from: {
					  bottom: '0'
					},
					to: {
					  bottom: '25px'
					}
				  }
			}),
		{
			name: 'appLoginHeader',
		}
	);
