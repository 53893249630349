import React from "react";
import Block from "./Block";
import Grid from "@material-ui/core/Grid";
import ReportBlock from "./ReportBlock";
import { maxCardGridDimensions } from "./lib";
import { AssignedStudentsOverview } from "@manakin/app-core";
import { useTranslation } from 'react-i18next';
import { useStyles } from "../styles";
import { Element } from "react-scroll";

const ManagementView = (props) => {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<React.Fragment>
			<Grid item {...maxCardGridDimensions}>
				<Block className={classes.reportBlock}>
					<ReportBlock/>
				</Block>
			</Grid>
			<Grid item {...maxCardGridDimensions}>
				<Element name="assignedStudentsOverview">
					<Block title={t("common.students")}>
						<AssignedStudentsOverview/>
					</Block>
				</Element>
			</Grid>
		</React.Fragment>
	);
};

export default ManagementView;
