import React, { useState, useEffect } from 'react';
import { useStyles } from './styles';
import { TextField, RadioField } from '@manakin/core';
import { Loader } from '@manakin/app-core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GQL_FETCH_SETTINGS } from '../graphql';
import { useQuery } from 'react-apollo';
import { SettingsKeys } from '@manakin/core/lib/constants';

const AuthForm = (props) => {
	const { form } = props;
	const classes = useStyles();
	const { fields: fieldsData } = form;
	const config = useSelector((state) => state.config) || {};
	const { general = {} } = config;
	const { t } = useTranslation();

	//state hooks
	const [terms, setTerms] = useState(null);

	const { data = {}, loading } = useQuery(GQL_FETCH_SETTINGS);
	const { settings = [] } = data.settings || {};

	//effect hooks
	useEffect(() => {
		if (settings && settings.length) {
			settings.forEach((setting) => {
				if (setting.value === SettingsKeys.TERMS && setting.file && setting.file.url) {
					setTerms(setting.file.url);
					form.onRequiredChange('acceptTerms', true);
				}
			});
		}
	}, [settings]);

	if (loading) return <Loader />;
	return (
		<React.Fragment>
			<div className={classes.groupElements}>
				<TextField form={form} name="email" label={t('common.fields.email-address.label')} initialValue={fieldsData.email} />
				<TextField
					form={form}
					name="password"
					helperText={general.defaultShowStrongPasswordHint ? t('common.fields.password.helper-text') : ''}
					label="Wachtwoord"
					initialValue={fieldsData.password}
					type="password"
				/>
			</div>
			<div className={classes.groupElements}>
				<TextField
					form={form}
					name="secondEmail"
					label={t('common.fields.repeat-email-address.label')}
					initialValue={fieldsData.secondEmail}
				/>
				<TextField
					form={form}
					name="secondPassword"
					label={t('common.fields.repeat-password.label')}
					initialValue={fieldsData.secondPassword}
					type="password"
					helperText={general.defaultShowStrongPasswordHint ? t('common.fields.password.helper-text') : ''}
				/>
			</div>
			{terms && (
				<div className={classes.termsOfService}>
					<RadioField
						form={form}
						fullWidth={true}
						fullLabelWidth={true}
						legendName={t('authentication.registration.accept-terms.name')}
						name="acceptTerms"
						options={[
							{
								label: (
									<span>
										{t('authentication.registration.accept-terms.label-first-part')}{' '}
										<a target="_blank" href={terms}>
											{t('authentication.registration.accept-terms.label-second-part')}
										</a>
									</span>
								),
								value: 'ACCEPT',
							},
						]}
						initialValue={fieldsData.acceptTerms}
					/>
				</div>
			)}
		</React.Fragment>
	);
};

export default AuthForm;
