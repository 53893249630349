import React from 'react';
import { styles } from './styles';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { compose } from 'recompose';
import Add from '@material-ui/icons/Add';
import classNames from 'classnames';

class SingleDot extends React.PureComponent {
    state = {
        show: false,
    };

    componentDidUpdate(prevProps) {
        if (prevProps.isVisible != this.props.isVisible) {
            this.setState((prevState) => ({
                show: this.props.isVisible,
            }));
        }
    }

    toggleHidden = () => {
        this.setState((prevState) => ({
            show: !prevState.show,
        }));
    };

    render() {
        const { classes } = this.props;
        const { props, state } = this;
        const position = {
            left: `${props.x}%`,
            top: `${props.y}%`,
        };
        return (
            <div className={classes.positioning} style={position}>
                <div className={classes.positioningMobile} style={position}>
                    <div
                        className={classNames(classes.addIcon, {
                            [classes.closeIcon]: this.state.show,
                        })}
                        onClick={() => this.toggleHidden(props.id)}
                    >
                        <Add className={classes.addIconRoot} />
                    </div>
                </div>

                {this.state.show && !props.text && props.title && (
                    <div
                        onClick={() => this.toggleHidden(props.id)}
                        className={classes.closeMobile}
                    >
                        <Add />
                    </div>
                )}
                <div
                    className={classNames(
                        classes.HotspotTitle,
                        {
                            [classes.titleLeft]:
                                props.popupOrientation == 'LEFT_TOP' ||
                                props.popupOrientation == 'LEFT_BOTTOM',
                        },
                        {
                            [classes.titleRight]:
                                props.popupOrientation == 'RIGHT_TOP' &&
                                props.popupOrientation == 'RIGHT_BOTTOM',
                        },
                        {
                            [classes.show]:
                                this.state.show && !props.text && props.title,
                        }
                    )}
                >
                    <Typography variant="h6" classes={{ h6: classes.title }}>
                        {props.title}
                    </Typography>
                </div>

                <div
                    className={classNames(
                        classes.HotspotBlockWrapper,
                        { [classes.rightTop]: props.popupOrientation == 'RIGHT_TOP', },
                        { [classes.rightBottom]: props.popupOrientation == 'RIGHT_BOTTOM', },
                        { [classes.leftTop]: props.popupOrientation == 'LEFT_TOP', },
                        { [classes.leftBottom]: props.popupOrientation == 'LEFT_BOTTOM', },
                        { [classes.show]: this.state.show && props.text }
                    )}
                >
                    <div
                        onClick={() => this.toggleHidden(props.id)}
                        className={classes.closeMobile}
                    >
                        <Add />
                    </div>
                    <div className={classes.HotspotBlockContent}>
                        <h2 className={classes.HotspotBlockTitle}>
                            {props.title}
                        </h2>
                        <p className={classes.HotspotBlockText}>{props.text}</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default compose(withStyles(styles, { name: 'SingleDot' }))(SingleDot);
