import React, { useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import { KeyboardArrowDown } from "@material-ui/icons";
import classNames from "classnames";

const useStyles = makeStyles(
    (theme) =>
        createStyles({
            root: {
                pointerEvents: "visible",
            },
            selected: {},
            menuItem: {
                height: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "white",
                "&:nth-child(even)": {
                    backgroundColor: "#f2f2f2",
                },
                [theme.breakpoints.up("md")]: {
                    minWidth: "450px",
                },
            },
            formControlLabel: {
                color: theme.manakin.defaultContentColor[500],
            },
            listItemText: {
                margin: theme.spacing(0, 0, 0, 1),
            },
            checked: {
                color: "black",
            },
            title: {
                display: "flex",
                alignItems: "center",
            },
            icon: {
                width: "20px",
                height: "20px",
                display: "flex",
            },
        }),
    {
        name: "AppDasboardFilterSelectFieldd",
    },
);

const MenuProps = {
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left",
    },
    getContentAnchorEl: null,
    disableScrollLock: true
};

const SelectField = (props) => {
    const { className, label, options = [], placeholder } = props;
    const classes = useStyles();
    const [ value, setValue ] = useState([]);
    const [ open, setOpen ] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => setOpen(false));
    }, []);

    useEffect(() => {
        if (value !== props.valueOverride && props.valueOverride) {
            setValue(props.valueOverride.map(value => (value.value || value.id)));
        }
    }, [ props.valueOverride ]);

    const handleChange = (event) => {
        const selectedIds = event.target.value;
        setValue(selectedIds);

        let selectedItems = [];
        options.forEach((item) => {
            if (selectedIds.some((id) => id === item.id)) {
                selectedItems.push({
                    label: item.name || item.label,
                    value: item.id,
                });
            }
        });

        if (props.onChange) props.onChange(selectedItems);
    };

    return (
        <FormControl className={classNames(classes.root, className)}>
            <InputLabel
                id='report-filter-label'
                classes={{ formControl: classes.formControlLabel }}
            >
                {label || placeholder}
            </InputLabel>
            <Select
                labelId='report-filter-label'
                id='report-filter-select'
                value={value}
                multiple
                renderValue={() => placeholder || ""}
                onChange={handleChange}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                open={open}
                MenuProps={MenuProps}
                IconComponent={KeyboardArrowDown}
            >
                {options.map((option) => (
                    <MenuItem
                        key={option.id}
                        value={option.id}
                        classes={{
                            root: classes.menuItem,
                            selected: classes.selected,
                        }}
                    >
                        <div className={classes.title}>
                            {option.icon && (
                                <figure className={classes.icon}>
                                    <img src={option.icon.url} />
                                </figure>
                            )}
                            <Typography
                                variant='body1'
                                className={classes.listItemText}
                            >
                                {option.name || option.label}
                            </Typography>
                        </div>
                        <Checkbox
                            checked={value.indexOf(option.id) > -1}
                            color='primary'
                            classes={{
                                checked: classes.checked,
                            }}
                        />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SelectField;
