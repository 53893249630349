"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});
var useBackgroundColors = function useBackgroundColors() {
	var backgroundColorsFromStylesheet = function backgroundColorsFromStylesheet(id) {
		var styleSheets = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
		var backgroundColorData = arguments[2];

		if (styleSheets.styleSheets && styleSheets.styleSheets.length && id) {
			var styleSheet = styleSheets.styleSheets.find(function (styleSheet) {
				return styleSheet.id === id;
			});

			if (styleSheet) {
				var array = [];
				if (styleSheet.indicationColor) array.push(styleSheet.indicationColor);
				if (styleSheet.defaultWorkformColor) array.push(styleSheet.defaultWorkformColor);
				if (styleSheet.primaryWorkformColor) array.push(styleSheet.primaryWorkformColor);
				if (styleSheet.secondaryWorkformColor) array.push(styleSheet.secondaryWorkformColor);
				if (styleSheet.themeColor) array.push(styleSheet.themeColor);

				return array;
			}
		} else if (backgroundColorData.value) {
			return JSON.parse(backgroundColorData.value);
		}
		return [];
	};

	return { backgroundColorsFromStylesheet: backgroundColorsFromStylesheet };
};

exports.default = useBackgroundColors;