'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _graphql = require('../graphql');

var _appCore = require('@manakin/app-core');

var _core = require('@material-ui/core');

var _reactRouterDom = require('react-router-dom');

var _reactApollo = require('react-apollo');

var _recompose = require('recompose');

var _reactRenderHtml = require('react-render-html');

var _reactRenderHtml2 = _interopRequireDefault(_reactRenderHtml);

var _ArrowRightAlt = require('@material-ui/icons/ArrowRightAlt');

var _ArrowRightAlt2 = _interopRequireDefault(_ArrowRightAlt);

var _Edit = require('@material-ui/icons/Edit');

var _Edit2 = _interopRequireDefault(_Edit);

var _reactHooks = require('@apollo/react-hooks');

var _reactI18next = require('react-i18next');

var _lib = require('@manakin/app-core/lib');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var SlideShowCreator = function SlideShowCreator(props) {
    var _classNames;

    var classes = props.classes,
        item = props.item,
        appUser = props.appUser;

    var _useTranslation = (0, _reactI18next.useTranslation)(),
        t = _useTranslation.t;

    var _useQuery = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_SLIDESHOW, {
        variables: {
            id: item.id
        }
    }),
        _useQuery$data = _useQuery.data,
        data = _useQuery$data === undefined ? {} : _useQuery$data;

    var _data$slideshowCreato = data.slideshowCreator,
        slideshowCreator = _data$slideshowCreato === undefined ? {} : _data$slideshowCreato;
    var _slideshowCreator$sli = slideshowCreator.slideshows,
        slideshows = _slideshowCreator$sli === undefined ? [] : _slideshowCreator$sli;

    var _ref = data || {},
        _ref$introTitle = _ref.introTitle,
        introTitle = _ref$introTitle === undefined ? '' : _ref$introTitle,
        _ref$text = _ref.text,
        text = _ref$text === undefined ? '' : _ref$text;

    return _react2.default.createElement(
        _appCore.AccessControl,
        { role: [].concat(_toConsumableArray(_lib.teacherRoles), [_lib.schoolManagerRole]) },
        _react2.default.createElement(
            'div',
            {
                className: (0, _classnames2.default)(classes.root, classes.slideshowCard, (_classNames = {}, _defineProperty(_classNames, classes.fullWidth, item.fullWidth), _defineProperty(_classNames, classes.noBackground, false), _defineProperty(_classNames, classes.show, true), _defineProperty(_classNames, classes.primaryBackgroundColor, item.backgroundColor == 'SECONDARY'), _defineProperty(_classNames, classes.secondaryBackgroundColor, item.backgroundColor == 'PRIMARY'), _classNames)),
                style: {
                    transition: 'all .5s .' + (1 + 1) + 's'
                }
            },
            _react2.default.createElement(
                'div',
                { className: (0, _classnames2.default)(classes.slideshowBox, classes.content, classes.slideshowContent) },
                _react2.default.createElement(
                    _core.Typography,
                    { component: 'h3', variant: 'h3' },
                    introTitle || ''
                ),
                _react2.default.createElement(
                    _core.Typography,
                    { component: 'div', variant: 'body1' },
                    (0, _reactRenderHtml2.default)(text || '')
                ),
                slideshows && slideshows.map(function (slide) {
                    return _react2.default.createElement(
                        _react2.default.Fragment,
                        { key: slide.id },
                        (slide.author && appUser.id == slide.author.id || slide.isPublic) && _react2.default.createElement(
                            'div',
                            {
                                className: classes.slideshowLinks,
                                key: slide.id
                            },
                            _react2.default.createElement(
                                'div',
                                { className: classes.slideshowLinksItem
                                },
                                _react2.default.createElement(
                                    _reactRouterDom.Link,
                                    {
                                        className: classes.link,
                                        to: '/slideshow/' + slide.id
                                    },
                                    _react2.default.createElement(
                                        _core.Typography,
                                        {
                                            component: 'strong',
                                            variant: 'subtitle1',
                                            className: classes.slideshowTitle
                                        },
                                        slide.title
                                    ),
                                    _react2.default.createElement(
                                        _core.Typography,
                                        {
                                            className: classes.slideshowAuthor
                                        },
                                        'Auteur:',
                                        ' ',
                                        slide.author && slide.author.fullName
                                    )
                                ),
                                _react2.default.createElement(
                                    'div',
                                    { className: classes.slideshowActions },
                                    _react2.default.createElement(
                                        _reactRouterDom.Link,
                                        {
                                            to: '/' + item.type + '/' + item.id + '/' + slide.id,
                                            className: classes.linkToSlideShowEdit
                                        },
                                        _react2.default.createElement(
                                            _core.Button,
                                            {
                                                color: 'primary',
                                                component: 'div'
                                            },
                                            _react2.default.createElement(_Edit2.default, { className: classes.linkToSlideShowEditIcon }),
                                            ' ',
                                            t("app.elements.element-card.change-slideshow")
                                        )
                                    ),
                                    _react2.default.createElement(
                                        _reactRouterDom.Link,
                                        {
                                            to: '/slideshow/' + slide.id,
                                            className: classes.linkToSlideShowEdit
                                        },
                                        _react2.default.createElement(
                                            _core.Button,
                                            {
                                                color: 'primary',
                                                component: 'div'
                                            },
                                            _react2.default.createElement(_ArrowRightAlt2.default, { className: classes.linkToSlideShowEditIcon }),
                                            ' ',
                                            t("app.elements.element-card.view-slideshow")
                                        )
                                    )
                                )
                            )
                        )
                    );
                }),
                _react2.default.createElement(
                    _reactRouterDom.Link,
                    {
                        to: '/' + item.type + '/' + item.id + '/new',
                        className: (0, _classnames2.default)(classes.link, classes.newSlideshowButton)
                    },
                    _react2.default.createElement(
                        _core.Button,
                        {
                            variant: 'contained',
                            color: 'primary',
                            fullWidth: true,
                            className: classes.button
                        },
                        t("app.elements.element-card.new-slideshow")
                    )
                )
            )
        )
    );
};

exports.default = (0, _recompose.compose)(_reactApollo.withApollo)(SlideShowCreator);