import React, { useCallback } from 'react';
import { useStyles } from './styles';
import { useSelector, useDispatch } from 'react-redux';
import LoginScreen from './parts/LoginScreen';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { GQL_LOGIN_APP } from './graphql';
import Cookies from 'js-cookie';
import { login } from '../actions';
import { showSnackbarMessage } from '@manakin/core/actions';
import { Typography } from '@material-ui/core';
import {useTranslation} from 'react-i18next';

const Login = (props) => {
	const { token, onSwitch, data: invitationData } = props;
	const { t, i18n } = useTranslation();
	const classes = useStyles();
	const dispatch = useDispatch();

	//selectors
	const config = useSelector((state) => state.config) || {};

	//dispatch
	const processSuccess = useCallback(
		(redirect, user) => dispatch(login(redirect, user)),
		[dispatch]
	);
	const processError = useCallback(
		(message) =>
			dispatch(
				showSnackbarMessage({
					text:
						message || t("authentication.invitation.login.failed"),
					variant: 'error',
				})
			),
		[dispatch]
	);

	//mutations
	const [mutate] = useMutation(GQL_LOGIN_APP);
	const history = useHistory();

	const handleSubmit = (event) => {
		mutate({
			variables: { ...event },
		})
			.then((data) => {
				if (!data.errors) {
					if (
						data.data.loginApp.user &&
						data.data.loginApp.user.locale
					) {
						const newLocale = data.data.loginApp.user.locale.code ===
						config.defaultLang
							? ""
							: data.data.loginApp.user.locale.code || "";

						Cookies.set('locale', newLocale);
						i18n.changeLanguage(newLocale);
					}

					if (data.data.loginApp && data.data.loginApp.mfa) {
						history.push({
							pathname: '/auth/2factor',
							state: {
								...data.data.loginApp,
								redirect: `/invitation/${token}`,
								env: 'APP',
							},
						});
					} else {
						console.log(
							'result.data.loginApp.user',
							data.data.loginApp.user
						);
						processSuccess(
							`/invitation/${token}`,
							data.data.loginApp.user
						);
					}
				} else {
					processError();
				}
			})
			.catch((e) => {
				processError();
			});
	};

	return (
		<div className={classes.root}>
			<div className={classes.container}>
				<LoginScreen
					token={token}
					onSubmit={handleSubmit}
					initial={invitationData.invitationByToken || null}
				/>
				<Typography className={classes.register}>
					{t("authentication.invitation.login.no-account")}{' '}
					<span onClick={onSwitch} className={classes.registerLink}>
						{t("authentication.invitation.login.register")}
					</span>
				</Typography>
			</div>
		</div>
	);
};

export default Login;
