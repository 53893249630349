import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Media from 'react-media';

const styles = (theme) => ({
    container: {
        width: '100%',
        maxWidth: '650px',
    },
    button: {
        display: 'inline',
        width: '100%',
        height: '80px',
        backgroundColor: '#000000',
        color: '#FFFFFF',
        border: '1px solid #FDC228',
        textAlign: 'left',
    },
    buttonMobile: {
        display: 'inline',
        width: '80%',
        height: '80px',
        backgroundColor: '#000000',
        color: '#FFFFFF',
        border: '1px solid #FDC228',
        textAlign: 'left',
        marginBottom: '32px !important',
    },
    buttonMarkup: {
        height: '20px',
        color: '#FFFFFF',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '1px',
        lineHeight: '24px',
        marginLeft: '40px',
    },
    group: {
        padding: theme.spacing(2),
        border: '1px solid #E6E6E6',
    },
    radioButton: {
        width: '100%',
        maxWidth: '650px',
        height: '90px',
        userSelect: 'none',
        backgroundColor: 'transparent',
    },
    radio: {
        color: '#000000 !important',
        marginLeft: '20px',
    },
});

class SingleChoice extends React.Component {
    state = {
        value: 0,
    };

    handleChange = (event) => {
        const id = event.target.value;
        document.getElementById(id).style.backgroundColor = '#FFECC2';

        this.props.questions.forEach((e) => {
            if ('q' + e.id !== id) {
                document.getElementById('q' + e.id).style.backgroundColor =
                    'transparent';
            }
        });

        this.setState({
            value: event.target.value,
        });
    };

    handleSubmit = (event) => {
        if (this.state.value !== 0) {
            const value =
                Number.parseInt(
                    this.state.value.substring(1, this.state.value.length)
                ) - 1;

            if (this.props.questions[value].answer) {
                // Right answer
                return true;
            } else {
                // Wrong answer
                return false;
            }
        } else {
            // No answer
            return false;
        }
    };

    render() {
        const { classes, buttonText, questions } = this.props;

        return (
            <div className={classes.container}>
                <RadioGroup
                    name="questions"
                    className={classes.group}
                    value={this.state.value}
                    onChange={this.handleChange}
                >
                    {questions.map((e) => {
                        return (
                            <FormControlLabel
                                id={'q' + e.id}
                                value={'q' + e.id}
                                control={<Radio className={classes.radio} />}
                                className={classes.radioButton}
                                label={e.question}
                            />
                        );
                    })}
                </RadioGroup>

                <Media query="(max-width: 860px)">
                    {(matches) =>
                        matches ? (
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <button
                                    className={classes.buttonMobile}
                                    onClick={this.handleSubmit}
                                >
                                    <div style={{ float: 'left' }}>
                                        <p className={classes.buttonMarkup}>
                                            {buttonText.toUpperCase()}
                                        </p>
                                    </div>
                                    <div style={{ float: 'right' }}>
                                        <p style={{ marginRight: '40px' }}>
                                            <ArrowRightAlt />
                                        </p>
                                    </div>
                                </button>
                            </div>
                        ) : (
                            <button
                                className={classes.button}
                                onClick={this.handleSubmit}
                            >
                                <div style={{ float: 'left' }}>
                                    <p className={classes.buttonMarkup}>
                                        {buttonText.toUpperCase()}
                                    </p>
                                </div>
                                <div style={{ float: 'right' }}>
                                    <p style={{ marginRight: '40px' }}>
                                        <ArrowRightAlt />
                                    </p>
                                </div>
                            </button>
                        )
                    }
                </Media>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(SingleChoice);
