import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { getUserType, isAuthenticated } from '../selectors';
import { logout as logoutAction } from '@manakin/authentication/actions';
import { GQL_CURRENT_APP_USER_VALIDATE, GQL_CURRENT_CMS_USER, GQL_VALID_LICENSE_CHECK } from '../graphql';
import { useQuery } from '@apollo/react-hooks';
import { Loader } from '@manakin/app-core';
import { managementRole, schoolManagerRole, teacherRoles, trainerRoles, userHasAnyRole } from '@manakin/app-core/lib';

const PrivateRoute = ({ component, authPath = '/auth', ...otherProps }) => {
	const config = useSelector((state) => state.config) || {};
	const authenticated = useSelector((state) => isAuthenticated(state));
	const userType = useSelector((state) => getUserType(state));
	const pathName = otherProps.location.pathname;
	const dispatch = useDispatch();

	// State hooks
	const [validLicense, setValidLicense] = useState(true);
	const [isTeacher, setIsTeacher] = useState(false);

	const { general = {} } = config;
	const { isCMS = false, sso = false } = general;

	const { data: userData = {}, loading: currentUserLoading } = useQuery(isCMS ? GQL_CURRENT_CMS_USER : GQL_CURRENT_APP_USER_VALIDATE, {
		fetchPolicy: 'no-cache',
		onCompleted: (data) => {
			const user = data.currentAppUser || data.currentCmsUser || {};

			if (userHasAnyRole(user, [ schoolManagerRole,...teacherRoles, ...trainerRoles, managementRole])) {
				setIsTeacher(true);
			}
		},
	});

	const user = userData.currentAppUser || userData.currentCmsUser || {};

	// Query hooks
	const { loading: licenseLoading, data: licenseData } = useQuery(GQL_VALID_LICENSE_CHECK, {
		skip: currentUserLoading,
		onCompleted: (data) => {
			// Check if license is valid using the given license check data
			if (data) {
				setValidLicense(data.validLicenseCheck.hasValidLicense);
			}
		},
		onError: () => {
			// dispatch(logoutAction());
		},
	});

	if (licenseLoading || currentUserLoading) return <Loader fullScreen={true} />;

	if (authenticated) {
		if (userType === 'app' && isCMS) {
			// User is no longer authenticated, or is visiting the CMS as APP user (or vice versa)
			dispatch(logoutAction());
			return <div />;
		}

		if (!user || !user.id) {
			// User is not authenticated even though the store says so, log out
			dispatch(logoutAction());
			return <div />;
		}

		if (pathName.startsWith('/account') || pathName.startsWith('/api/pdf/')) {
			// Just continue, but skip the no-license check
		} else if (!validLicense && !isTeacher && !isCMS && pathName !== '/no-license') {
			// If you are a student and do not have a license, default to /no-license
			return <Redirect to={'/no-license'} />;
		}

		return <Route {...otherProps} component={component} />;
	} else if (user.id && sso) {
		return <Redirect to={authPath + '/sso/' + user.id} />;
	} else {
		let loginParams = pathName ? '?redirect=' + pathName : '';
		return <Redirect to={authPath + '/login' + loginParams} />;
	}
};

export default PrivateRoute;
