import React, { useEffect } from 'react';
import { withWorkforms } from '@manakin/app-core';
import { compose } from 'recompose';
import { GQL_FETCH_STYLESHEET } from './graphql';
import { useQuery } from '@apollo/react-hooks';
import { connect } from 'react-redux';
import { changeColor } from '@manakin/app-core/ColorService/actions';
import { useLocation } from 'react-router-dom';
import FinalRating from "./FinalRating";

const queryString = require('query-string');

const FinalRatingPreviewContainer = (props) => {
	const { match, workformsData, changeColor } = props;
	const query = match.params.query;
	const finalRatingData = queryString.parse(query);
	const location = useLocation();

	const { data = {}, loading } = useQuery(GQL_FETCH_STYLESHEET, {
		variables: {
			id: finalRatingData.styleSheet || '',
		},
	});

	useEffect(() => {
		const items = Array.isArray(finalRatingData.items)
			? finalRatingData.items
			: finalRatingData.items
				? [ finalRatingData.items ]
				: [];

		if (!loading) {
			const obj = {
				...finalRatingData,
				headerImage: {
					url: finalRatingData.headerImage,
				},
				image: {
					url: finalRatingData.image,
				},
				workforms: [
					...items.map((item, idx) => ({
						id: item,
						type: finalRatingData.itemTypes[idx],
					})),
				],
				options: {
					showFeedback: true,
					nextButton: false,
				},
			};
			changeColor({
				...data.styleSheet,
				location: location.pathname,
			});
			workformsData.loadWorkforms({ ...obj });
		}
	}, [ loading ]);

	const handleFinish = () => {
		return true;
	};

	return (
		<FinalRating
			{...workformsData}
			preview={true}
			workforms={workformsData.workforms}
			onFinish={handleFinish}
		/>
	);
};

export default compose(
	connect(null, (dispatch) => ({
		changeColor: (styleSheet) => dispatch(changeColor(styleSheet)),
	})),
	withWorkforms()
)(FinalRatingPreviewContainer);
