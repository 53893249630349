import React from 'react';
import { ElementOverlay } from '@manakin/app-core';
import { withStyles, Typography } from '@material-ui/core';
import { styles } from './parts/styles';
import classNames from 'classnames';
import { withTranslation } from "react-i18next";
import { compose } from 'recompose';

class Slideshow extends React.PureComponent {
    state = {
        slideIdx: 0,
    };

    goToNextSlide = () => {
        const { slideIdx } = this.state;

        if (slideIdx === this.props.slides.length - 1) {
        } else {
            this.setState((prevState) => ({
                slideIdx: prevState.slideIdx + 1,
            }));
        }
    };

    render() {
        const { classes, slides = false, t } = this.props;
        const { slideIdx } = this.state;

        return (
            <ElementOverlay fullWidth={true} controls={true} title={t("common.element-types.slideshow-creator")}>
                <div className={classes.slideshowSwiper}>
                    {slides &&
                        slides.map((slide, idx) => (
                            <div
                                className={classNames(
                                    classes.slide,
                                    { [classes.active]: slideIdx == idx },
                                    { [classes.textSlide]: slide.type == 'text', },
                                    { [classes.imageSlide]: slide.type == 'image', }
                                )}
                                onClick={this.goToNextSlide}
                            >
                                {slide.type === 'text' && (
                                    <div className={classes.textSlideWrapper}>
                                        <Typography
                                            className={classes.textSlideTitle}
                                            variant="h2"
                                        >
                                            {slide.data.title}
                                        </Typography>
                                        <Typography>
                                            {slide.data.text}
                                        </Typography>
                                    </div>
                                )}
                                {slide.type === 'image' && (
                                    <React.Fragment>
                                        <img
                                            className={classes.imageSlideBackground}
                                            src={slide.data.src}
                                        />
                                        <img
                                            className={classes.imageSlideContent}
                                            src={slide.data.src}
                                        />
                                    </React.Fragment>
                                )}
                            </div>
                        ))}
                </div>
            </ElementOverlay>
        );
    }
}

export default compose(
    withStyles(styles),
    withTranslation()
)(Slideshow);
