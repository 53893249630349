import gql from 'graphql-tag';
import { asset, appAsset, backgroundImageAsset } from '@manakin/app-core/graphQlHelpers';

export const GQL_FETCH_FACT_CARD = gql`
    query($id: ID!) {
        factCard(id: $id) {
            id
            title
            vimeoId
            subtitle
            backgroundImage {
                ${backgroundImageAsset}
            }
            items {
                id
                title
                text
                image {
                    ${asset}
                }
                tags {
                    id
                    name
                    visibleInApp
                    image {
                        ${appAsset}
                    }
                }
            }
        }
    }
`;

export const GQL_FETCH_PREVIEW = gql`
    query {
        settings {
            settings {
                name
                value
            }
        }
    }
`;
