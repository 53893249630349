'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = exports.styles = function styles(theme) {
	return {
		body: {
			color: theme.palette.getContrastText(theme.manakin.primaryPaletteColor[500])
		},
		done: {},
		root: {
			height: '100%',
			width: '100%',
			position: 'relative',
			backgroundColor: theme.manakin.primaryPaletteColor[500],
			overflow: 'hidden',
			display: 'inline-block',
			'&:hover': {
				'& $backgroundImage': _defineProperty({}, theme.breakpoints.up('md'), {
					transform: 'scale(1.1)'
				})
			}
		},
		name: {},
		bookCheck: _defineProperty({
			backgroundColor: theme.manakin.primaryPaletteColor[500],
			padding: '3.6rem 3rem',
			'& $content': {
				display: 'none'
			},
			'& $backgroundImage': _defineProperty({
				display: 'none'
			}, theme.breakpoints.up('md'), {
				display: 'block',
				width: '150px',
				height: '100%'
			})
		}, theme.breakpoints.up('md'), {
			height: '14rem' /* anything less than the minHeight below to avoid flex center issues in IE11, see: https://github.com/philipwalton/flexbugs/issues/231 */
			, minHeight: '15rem',
			display: 'flex',
			flexDirection: 'row',
			padding: '2.5rem 5rem 2.5rem 20rem'
		}),
		bookCheckTitle: {
			fontSize: '26px',
			lineHeight: 'normal',
			marginBottom: theme.spacing(1)
		},
		bookCheckContent: _defineProperty({}, theme.breakpoints.up('md'), {
			display: 'flex',
			flexDirection: 'column',
			flex: '1 1 400px',
			paddingRight: '2rem',
			'& p': {
				marginBottom: 0
			}
		}),
		bookCheckFlex: _defineProperty({}, theme.breakpoints.up('md'), {
			display: 'flex',
			width: '100%',
			alignItems: 'center'
		}),
		externalLinkContent: _defineProperty({}, theme.breakpoints.up('md'), {
			display: 'flex',
			flexDirection: 'column',
			flex: '1 1 400px',
			paddingRight: '2rem',
			'& p': {
				marginBottom: 0
			}
		}),
		externalLinkFlex: _defineProperty({}, theme.breakpoints.up('md'), {
			display: 'flex',
			width: '100%',
			alignItems: 'center'
		}),
		digitalBookContent: _defineProperty({}, theme.breakpoints.up('md'), {
			display: 'flex',
			flexDirection: 'column',
			flex: '1 1 400px',
			paddingRight: '2rem',
			'& p': {
				marginBottom: 0
			}
		}),
		digitalBookFlex: _defineProperty({}, theme.breakpoints.up('md'), {
			display: 'flex',
			width: '100%',
			alignItems: 'center'
		}),
		button: {
			width: 'auto',
			minWidth: '260px',
			minHeight: '60px'
		},
		factCardsButton: {
			color: theme.palette.getContrastText(theme.manakin.primaryPaletteColor[500]),
			border: '3px solid ' + theme.palette.getContrastText(theme.manakin.primaryPaletteColor[500])
		},
		externalLinkButton: {
			color: theme.palette.getContrastText(theme.manakin.primaryPaletteColor[500]),
			border: '3px solid ' + theme.palette.getContrastText(theme.manakin.primaryPaletteColor[500])
		},
		digitalBookButton: {
			color: theme.palette.getContrastText(theme.manakin.primaryPaletteColor[500]),
			background: 'transparent',
			border: '3px solid ' + theme.palette.getContrastText(theme.manakin.primaryPaletteColor[500])
		},
		minHeight: _defineProperty({
			minHeight: '250px',
			'& $done': {
				top: '34px',
				position: 'absolute'
			}
		}, theme.breakpoints.up('md'), {
			minHeight: '500px'
		}),
		backgroundImage: {
			position: 'absolute',
			zIndex: '0',
			height: '100%',
			width: '100%',
			left: '0',
			top: '0',
			transition: 'width .3s, transform .3s, background-color .3s'
		},
		image: {
			width: '100%',
			height: '100%',
			objectFit: 'cover',
			fontFamily: "'object-fit: cover'"
		},
		link: {
			textDecoration: 'none'
		},
		content: _defineProperty({
			position: 'absolute',
			bottom: '26px',
			padding: '0 26px'
		}, theme.breakpoints.up('md'), {
			padding: '0 50px',
			bottom: '50px'
		}),
		disabled: {
			opacity: '.3',
			'&$root': {
				opacity: '.3'
			}
		},
		bodytitle: {
			color: theme.palette.getContrastText(theme.manakin.primaryPaletteColor[500])
		}
	};
};