"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.useStyles = undefined;

var _core = require("@material-ui/core");

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var useStyles = exports.useStyles = (0, _core.makeStyles)(function (theme) {
	return (0, _core.createStyles)({
		active: {
			background: theme.manakin.indicationColor[500],
			color: theme.palette.getContrastText(theme.manakin.indicationColor[500])
		},
		root: {
			backgroundColor: theme.manakin.primaryColor[500],
			minHeight: "100vh",
			display: "flex",
			justifyContent: "center",
			width: "100%"
		},
		calenderRoot: {
			background: "#FFF",
			padding: "30px 50px",
			borderRadius: "6px"
		},
		inner: _defineProperty({
			maxWidth: "550px",
			width: "100%",
			padding: "40px 20px",
			display: "flex",
			alignItems: "center",
			flexDirection: "column"
		}, theme.breakpoints.up("md"), {
			padding: "12rem 2rem"
		}),
		header: {
			marginBottom: "4rem"
		},
		content: {
			textAlign: "center",
			fontWeight: "400"
		},
		title: _defineProperty({
			maxWidth: "48rem",
			marginBottom: "1rem"
		}, theme.breakpoints.up("md"), {
			marginBottom: "4rem"
		}),
		input: {
			width: "100%",
			marginBottom: "2rem"
		}
	});
}, {
	name: "AppCalendar"
});