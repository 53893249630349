import React, { useEffect } from 'react';
import { SimpleDialog } from '@manakin/core';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { GQL_FETCH_EVENT_ENROLLMENTS, GQL_ENROLL_INTO_EVENT, GQL_FETCH_CURRENT_APP_USER } from '../graphql';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { useSnackBar } from '@manakin/hooks';
import moment from 'moment';
import PlannerPopupButtons from './PlannerPopupButtons';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		maxWidth: '600px',
	},
	image: {
		width: '100%',
		height: '200px',
		objectFit: 'cover',
	},
	content: {
		margin: '10px 0',
		[theme.breakpoints.up('md')]: {
			margin: '20px 0',
		},
	},
	time: {
		fontWeight: 'bold',
		margin: 0,
	},
}));

const PlannerPopup = (props) => {
	const { open, onClose, event } = props;
	const { title, description, image, id, date } = event || {};
	const snackBar = useSnackBar();
	const classes = useStyles();
	const { t } = useTranslation();

	const { data: currentUserData } = useQuery(GQL_FETCH_CURRENT_APP_USER);
	const { currentAppUser = {} } = currentUserData || {};

	const [mutate] = useMutation(GQL_ENROLL_INTO_EVENT, {
		onCompleted: (result) => {
			if (result && result.enrollIntoEvent) {
				getEventEnrollments();
				snackBar.success(t('app.dashboard.alt-content.planner.event.sign-up-success'));
			} else {
				snackBar.error(t('app.dashboard.alt-content.planner.event.sign-up-error'));
			}
		},
		onError: () => {
			snackBar.error(t('app.dashboard.alt-content.planner.event.sign-up-error'));
		},
	});

	const [getEventEnrollments, { data }] = useLazyQuery(GQL_FETCH_EVENT_ENROLLMENTS, {
		variables: {
			filter: {
				eventId: id,
			},
		},
	});
	const { eventEnrollments } = data || {};
	const { content = [] } = eventEnrollments || {};

	useEffect(() => {
		if (open && id && currentAppUser.id) {
			getEventEnrollments();
		}
	}, [id, currentAppUser.id]);

	const handleEnrollment = () => {
		if (currentAppUser.id && event.id) {
			mutate({
				variables: {
					eventId: event.id,
					userId: currentAppUser.id,
				},
			});
		}
	};

	return (
		<SimpleDialog open={open} onClose={onClose} title={title || ''} className={classes.root}>
			<Typography className={classes.time}>{moment(date).format('DD MM YYYY - HH:mm') || ''}</Typography>
			{image && <img className={classes.image} src={image.url || ''} />}
			<Typography className={classes.content}>{description || ''}</Typography>
			<PlannerPopupButtons event={event} enrollments={content} onEnrollment={handleEnrollment} />
		</SimpleDialog>
	);
};

export default PlannerPopup;
