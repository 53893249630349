import { CORE_STATE_ROOT } from '@manakin/core/selectors';
import { SNACKBAR_MESSENGER_STATE_ROOT } from '@manakin/core/SnackbarMessenger/selectors';
import { DIALOG_STATE_ROOT } from '@manakin/core/Dialog/selectors';
import { FORM_STATE_ROOT } from '@manakin/core/form/selectors';

import _ from 'lodash';

const storage = window.localStorage;

export function patchReduxState(reduxStateFragment) {
    let newReduxState = _.merge(
        JSON.parse(storage.getItem('reduxState') || '{}'),
        reduxStateFragment
    );
    storage.setItem('reduxState', JSON.stringify(newReduxState));
}

export function clearReduxState() {
    storage.removeItem('reduxState');
}

export function getReduxState() {
    return JSON.parse(storage.getItem('reduxState') || '{}');
}

export function getInitialReduxState() {
    const initialState = getReduxState();

    if (initialState[CORE_STATE_ROOT]) {
        delete initialState[CORE_STATE_ROOT][SNACKBAR_MESSENGER_STATE_ROOT];
        delete initialState[CORE_STATE_ROOT][DIALOG_STATE_ROOT];
        delete initialState[CORE_STATE_ROOT][FORM_STATE_ROOT];
    }

    return initialState;
}
