import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PageLayout from '../PageLayout';
import { TextField, RadioField, SelectField } from '@manakin/core';

const styles = (theme) => ({
    wrapper: {
        ...theme.manakin.smallWrapper,
        maxWidth: '770px',
    },
    groupElements: {
        '@media (min-width: 700px)': {
            display: 'flex',
            justifyContent: 'space-between',
            '&>*': {
                flex: '0 0 auto',
                width: '300px',
            },
        },
    },
    paragraph: {
        textAlign: 'center',
        width: '100%',
        margin: '0 auto 3em',
        maxWidth: '560px',
    },
    heading: {},
    radioButtons: {
        marginTop: '3rem',
    },
});

const AuthData = (props) => {
    const { classes, form } = props;

    useEffect(() => {
        props.onReady(true);
    }, []);

    return (
        <PageLayout
            title="Email & wachtwoord"
            intro="Vul hier je e-maildres in en kies een wachtwoord. Deze gebruik je om in te loggen."
        >
            <div className={classes.wrapper}>
                <div className={classes.groupElements}>
                    <TextField form={form} name="email" label="E-mailadres" />
                    <TextField
                        form={form}
                        name="password"
                        label="Wachtwoord"
                        type="password"
                    />
                </div>
                <div className={classes.groupElements}>
                    <TextField
                        form={form}
                        name="secondEmail"
                        label="Herhaal e-mailadres"
                    />
                    <TextField
                        form={form}
                        name="secondPassword"
                        label="Herhaal wachtwoord"
                        type="password"
                    />
                </div>
                <div className={classes.radioButtons}>
                    <RadioField
                        form={form}
                        fullWidth={true}
                        fullLabelWidth={true}
                        name="newsLetter"
                        options={[
                            {
                                label: (
                                    <span>
                                        Ik wil graag 12 keer per jaar de
                                        nieuwsbrief van het CBD ontvangen
                                    </span>
                                ),
                                value: 'ACCEPT',
                            },
                        ]}
                    />
                    <RadioField
                        form={form}
                        fullWidth={true}
                        fullLabelWidth={true}
                        name="acceptTerms"
                        showRequired={false}
                        options={[
                            {
                                label: (
                                    <span>
                                        Ik ga akkoord met de algemene
                                        voorwaarden *
                                    </span>
                                ),
                                value: 'ACCEPT',
                            },
                        ]}
                    />
                    <RadioField
                        form={form}
                        fullWidth={true}
                        fullLabelWidth={true}
                        name="acceptSecondTerms"
                        showRequired={false}
                        options={[
                            {
                                label: (
                                    <span>
                                        Ik ga akkoord met de
                                        toestemmingsverklaring *
                                    </span>
                                ),
                                value: 'ACCEPT',
                            },
                        ]}
                    />
                </div>
            </div>
        </PageLayout>
    );
};

export default withStyles(styles, { name: 'AppRegistrationAuthData' })(
    AuthData
);
