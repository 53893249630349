import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import { useSnackBar } from "@manakin/hooks";
import { GQL_CHANGE_PASSWORD, GQL_FETCH_APP_USER_FOR_STORE } from "../graphql";
import { PasswordField, withForm } from '@manakin/core';
import { compose } from 'recompose';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { login } from "../actions";
import {useTranslation} from 'react-i18next';
import { savePermissions } from '@manakin/core/actions';

const useStyles = makeStyles(
    (theme) =>
        createStyles({
            form: {
                marginTop: theme.spacing(2)
            },
            actions: {
                width: "100%"
            },
            submit: {
                margin: `${theme.spacing(3)}px auto 0`,
            },
        }),
    {
        name: 'ConfirmInvitationForm',
    }
);

export const formBundleStrongPassword = {
    password: {
        format: "strong-password",
        required: true,
    },
    repeatPassword: {
        required: true,
        couple: "password",
        coupleLabel: "wachtwoord",
        format: "strong-password",
    },
};

const formBundle = {
    password: {
        format: 'password',
        required: true,
    },
    repeatPassword: {
        format: 'password',
        couple: 'password',
        coupleLabel: 'wachtwoord',
        required: true,
    },
};

const ConfirmInvitation = props => {
    const { form } = props;
    const classes = useStyles();
    const params = useParams();
    const dispatch = useDispatch();
    const snackBar = useSnackBar();
    const { t } = useTranslation();
    const client = useApolloClient();

    const config = useSelector((state) => state.config);
    const { general = {} } = config || {};
    const { isCMS = false } = general;

    useEffect(() => {
        if (general.strongPassword) {
            form.onSchemaChange("password", formBundleStrongPassword.password);
            form.onSchemaChange("repeatPassword", formBundleStrongPassword.repeatPassword);
        }
    }, []);

    // Mutations
    const [ mutate ] = useMutation(GQL_CHANGE_PASSWORD);

    const handleSubmit = () => {
        form.onValidate().then(
            () => form.onSubmit().then((data) => {
                mutate({
                    variables: {
                        password: data.password,
                        token: params.token,
                    },
                }).then((result) => {
                    if (!result.errors) {
                        if (result.data && result.data.changePassword &&
                            result.data.changePassword.user && result.data.changePassword.user.roles) {
                            savePermissions(result.data.changePassword.user.roles);
                        }

                        snackBar.success(t("authentication.confirm-invitation.success"));

                        if (isCMS) {
                            dispatch(login(process.env.REACT_APP_BASENAME || `/cms`));
                        } else {
                            client.query({ query: GQL_FETCH_APP_USER_FOR_STORE })
                                .then(result => dispatch(login(`/`, result.data.currentAppUser)))
                                .catch(() => dispatch(login(`/`)));
                        }
                    } else {
                        snackBar.error(t("authentication.confirm-invitation.failed"));
                    }
                });
            }),
            (error) => {
                // Handled by individual fields
            }
        );
    };

    return (
        <React.Fragment>
            <div className={classes.form}>
                <PasswordField
                    name="password"
                    label={t("common.fields.password.label")}
                    placeholder={t("common.fields.password.placeholder")}
                    form={form}
                />
            </div>

            <div className={classes.form}>
                <PasswordField
                    name="repeatPassword"
                    label={t("common.fields.repeat-password.label")}
                    placeholder={t("common.fields.repeat-password.placeholder")}
                    onEnterPressed={handleSubmit}
                    form={form}
                />
            </div>

            <FormControl className={classes.actions} margin="normal">
                <Button
                    className={classes.submit}
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                >
                    {t("authentication.confirm-invitation.action")}
                </Button>
            </FormControl>
        </React.Fragment>
    );
};

export default compose(
    withForm(formBundle)
)(ConfirmInvitation);
