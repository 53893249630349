import React, { useEffect, useState } from 'react';
import { StyledDialog } from '@manakin/app-core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getAppUser } from '@manakin/authentication/selectors';
import { useSelector } from 'react-redux';
import { bpvRole, stringIsEmpty, userHasAnyRole } from '@manakin/app-core/lib';
import { useLazyQuery } from '@apollo/react-hooks';
import { GQL_FETCH_CURRENT_APP_USER_FOR_BPV } from '../graphql';

const MissingDataBPVDialog = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const appUser = useSelector((state) => getAppUser(state));
    const [ open, setOpen ] = useState(false);

    const [ getUser, {} ] = useLazyQuery(GQL_FETCH_CURRENT_APP_USER_FOR_BPV, {
        onCompleted: (data) => {
            if (data && data.currentAppUser) {
                if (stringIsEmpty(data.currentAppUser.function) || stringIsEmpty(data.currentAppUser.educationOrganisation)) {
                    setOpen(true);
                }
            }
        },
    });

    useEffect(() => {
        if (userHasAnyRole(appUser, [ bpvRole ]) && (stringIsEmpty(appUser.function) || stringIsEmpty(appUser.educationOrganisation))) {
            // If any value is empty, fetch the user to find out if that's actually the case. Due to issues with Redux,
            // when the user presses the back button at the account page, it will use the old appUser and not the newly
            // fetched one. We do not want to fetch the user every time the user visits the dashboard, so we fetch it only if empty
            getUser();
        }
    }, [ appUser ]);

    const handleClose = () => setOpen(false);

    const handleAction = () => {
        setOpen(false);
        history.push(`/account`);
    };

    return (
        <StyledDialog
            onClose={handleClose}
            open={open}
            title={t('app.progress-dashboard.missing-data-dialog.title')}
            contentText={t('app.progress-dashboard.missing-data-dialog.description')}
            positiveButtonText={t('app.progress-dashboard.missing-data-dialog.action')}
            onPositiveButtonClick={handleAction}
            negativeButtonText={t('common.cancel')}
            onNegativeButtonClick={handleClose} />
    );
};

export default MissingDataBPVDialog;
