import React, { useState, useEffect, useReducer, useMemo, useCallback } from 'react';
var _ = require('lodash');
import Header from './parts/Header';
import TeacherHeader from './parts/TeacherHeader';
import BoxLayout from './parts/BoxLayout';
import { BoxService, AccessControl } from '@manakin/app-core';
import { getAppUser } from '@manakin/authentication/selectors';
import { isListView } from '@manakin/app-core/Boxes/selectors';
import { withQuery } from '@manakin/core';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { goBackAppBar } from '@manakin/app-core/AppBar/actions';
import { Redirect } from 'react-router-dom';
import { managementRole, trainerRoles, schoolManagerAndTeacherRoles, studentRoles, userHasAnyRole } from '@manakin/app-core/lib';
import FavouriteProgramDialog from './parts/FavouriteProgramDialog';
import LinkSchoolDialog from './parts/LinkSchoolDialog';
import { useQuery } from 'react-apollo';
import { GQL_CURRENT_APP_USER } from './parts/graphql';
import { setAppUser } from '@manakin/authentication/actions';

const mapStateToProps = (state) => ({
	config: state.config,
	appUser: getAppUser(state),
	listView: isListView(state),
});

const mapDispatchToProps = (dispatch) => ({
	goBackAppBar: (data) => dispatch(goBackAppBar(data)),
});

const styles = (theme) => ({
	loading: {
		width: '100vw',
		height: '100vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
});

const Dashboard = (props) => {
	const { appUser = {}, listView, classes, config = {}, match = {} } = props;
	const { general = {} } = config;
	const { params = {} } = match;
	const dispatch = useDispatch();
	const showBoxLayout =
		!appUser ||
		!appUser.licenses ||
		appUser.licenses.some((license) => license.favouriteProductId) ||
		appUser.licenses.every((license) => !license.product.favouriteRequired);

	const [programData, setData] = useReducer(
		(state, data) => {
			return data.program || {};
		},
		{
			loading: true,
		}
	);

	const setAppUserStore = useCallback((redirect) => dispatch(setAppUser(redirect)), [dispatch]);

	// queries
	const { data: userData = {}, loading: userLoading } = useQuery(GQL_CURRENT_APP_USER);

	const [percentage, setPercentage] = useState({});
	const [loading, setLoading] = useState(true);
	const [roles, setRoles] = useState([]);
	const [resume, setResume] = useState(null);
	const [favouriteProgramDialogOpen, setFavouriteProgramDialogOpen] = useState(false);
	const [linkSchoolDialogOpen, setlinkSchoolDialogOpen] = useState(false);
	const [programBoxesFilter, setProgramBoxesFilter] = useState({});

	const handleLoadChange = (data) => {
		setLoading(data);
	};

	const handleData = (data, percentage = 0, appUserData) => {
		setData(data);
		setPercentage(percentage);
		setResume(appUserData);
	};

	useEffect(() => {
		if (!loading) {
			props.goBackAppBar('/altDashboard');
		}
	}, [loading]);

	useEffect(() => {
		if (appUser && appUser.roles) {
			const roles = appUser.roles.map((item) => item.name);
			setRoles(roles);
		}
	}, [appUser]);

	useEffect(() => {
		if (!userLoading && userData && userData.currentAppUser) {
			const currentAppUser = userData.currentAppUser;
			if (
				currentAppUser.licenses &&
				currentAppUser.licenses.some(
					(license) => !license.favouriteProductId && license.product.favouriteRequired && license.licenseGroup
				)
			) {
				setFavouriteProgramDialogOpen(true);
			}
			
			if (!currentAppUser.school && general.showNoSchoolDialog && userHasAnyRole(currentAppUser, studentRoles)) {
				setlinkSchoolDialogOpen(true);
			}

			setAppUserStore(currentAppUser);
		}
	}, [userData, userLoading]);

	const handleCloseFavouriteProgramDialog = () => {
		setFavouriteProgramDialogOpen(false);
	};

	const handleCloselinkSchoolDialog = () => {
		setlinkSchoolDialogOpen(false);
	};

	let listViewOnly = useMemo(() => {
		return _.find(config.pages, 'listViewOnly');
	}, [config.pages]);

	if (userHasAnyRole(appUser, [...trainerRoles, managementRole])) return <Redirect to="/progress-dashboard" />;

	return (
		<div className={classes.root}>
			<BoxService onNewData={handleData} onLoadChange={handleLoadChange} programBoxesFilter={programBoxesFilter}>
				<React.Fragment>
					<AccessControl role={schoolManagerAndTeacherRoles}>
						<TeacherHeader
							user={appUser}
							data={programData}
							loading={loading}
							roles={roles}
							secondStatisticsBox={general.secondStatisticsBox}
						/>
					</AccessControl>
					<AccessControl not={schoolManagerAndTeacherRoles}>
						<Header user={appUser} data={programData} loading={loading} roles={roles} />
					</AccessControl>
					{showBoxLayout && (
						<BoxLayout
							user={appUser}
							listView={listViewOnly ? listViewOnly : listView}
							data={programData}
							percentage={percentage}
							listViewOnly={listViewOnly}
							resume={resume}
							option={params.dashboardOption || false}
							loading={loading || programData.loading}
							onProgramBoxesFilterChange={setProgramBoxesFilter}
						/>
					)}
					<FavouriteProgramDialog
						open={favouriteProgramDialogOpen}
						onClose={handleCloseFavouriteProgramDialog}
						appUser={appUser}
					/>
					<LinkSchoolDialog
						open={linkSchoolDialogOpen}
						onClose={handleCloselinkSchoolDialog}
						appUser={appUser}
					/>
				</React.Fragment>
			</BoxService>
		</div>
	);
};

export default compose(withStyles(styles), withQuery, connect(mapStateToProps, mapDispatchToProps))(Dashboard);
