'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _BestTimes = require('./BestTimes');

var _BestTimes2 = _interopRequireDefault(_BestTimes);

var _recompose = require('recompose');

var _selectors = require('@manakin/app-core/Boxes/selectors');

var _selectors2 = require('@manakin/app-core/ProgramsDropdown/selectors');

var _appCore = require('@manakin/app-core');

var _selectors3 = require('@manakin/authentication/selectors');

var _reactRedux = require('react-redux');

var _reactApollo = require('react-apollo');

var _styles = require('@material-ui/core/styles');

var _graphql = require('./graphql');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = {
    loader: {
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute'
    }
};

var BestTimesContainer = function (_React$PureComponent) {
    _inherits(BestTimesContainer, _React$PureComponent);

    function BestTimesContainer() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, BestTimesContainer);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = BestTimesContainer.__proto__ || Object.getPrototypeOf(BestTimesContainer)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
            loading: true
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(BestTimesContainer, [{
        key: 'componentDidUpdate',
        value: function componentDidUpdate(prevProps) {
            if (prevProps.data.loading && !this.props.data.loading) {
                var _props = this.props,
                    data = _props.data,
                    box = _props.box,
                    program = _props.program,
                    elementId = _props.elementId;

                var schoolClass = null;

                data.appUser && data.appUser.schoolClasses && data.appUser.schoolClasses.forEach(function (item) {
                    schoolClass = item.id;
                });

                if (schoolClass && box && program && data.appUser && data.appUser.id && elementId) {
                    this.setState({
                        loading: false,
                        schoolClass: schoolClass,
                        box: box,
                        program: program,
                        user: data.appUser.id,
                        element: elementId
                    });
                } else {
                    this.setState({
                        loading: false,
                        noData: true
                    });
                }
            }
        }
    }, {
        key: 'render',
        value: function render() {
            var _props2 = this.props,
                classes = _props2.classes,
                _props2$variant = _props2.variant,
                variant = _props2$variant === undefined ? 'default' : _props2$variant,
                _props2$max = _props2.max,
                max = _props2$max === undefined ? false : _props2$max,
                _props2$noTitle = _props2.noTitle,
                noTitle = _props2$noTitle === undefined ? false : _props2$noTitle,
                _props2$noPadding = _props2.noPadding,
                noPadding = _props2$noPadding === undefined ? false : _props2$noPadding;


            if (this.state.loading) {
                return _react2.default.createElement(
                    'div',
                    { className: classes.loader },
                    _react2.default.createElement(_appCore.Loader, null)
                );
            } else if (!this.state.schoolClass) {
                return false;
            } else {
                return _react2.default.createElement(_BestTimes2.default, _extends({}, this.state, {
                    variant: variant,
                    max: max,
                    noTitle: noTitle,
                    noPadding: noPadding
                }));
            }
        }
    }]);

    return BestTimesContainer;
}(_react2.default.PureComponent);

exports.default = (0, _recompose.compose)((0, _reactRedux.connect)(function (state) {
    return {
        program: (0, _selectors2.getProgram)(state),
        box: (0, _selectors.getBoxId)(state),
        appUser: (0, _selectors3.getAppUser)(state)
    };
}), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_APP_USER, {
    options: function options(props) {
        return {
            variables: {
                id: props.appUser && props.appUser.id
            }
        };
    }
}), (0, _styles.withStyles)(styles))(BestTimesContainer);