"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _RatingRequestsAssignments = require("./RatingRequestsAssignments");

Object.defineProperty(exports, "default", {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_RatingRequestsAssignments).default;
  }
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }