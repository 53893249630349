import React from 'react';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { useStyles } from '../styles';

const PercentageBar = (props) => {
	const { percentage = 0 } = props;
	const perc = percentage > 0 ? percentage : 0;
	const classes = useStyles();

	return (
		<div className={classes.percentageBarRoot}>
			<div className={classes.percentageBarOuter}>
				<div className={classNames([classes.percentageBarInner, { lowPercentage: perc < 10 }])} style={{ width: perc + '%' }}>
					<Typography component="p" variant="body1" className={classes.percentageBarContent}>
						{perc}%
					</Typography>
				</div>
			</div>
		</div>
	);
};

export default PercentageBar;
