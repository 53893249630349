"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _TableCell = require("@material-ui/core/TableCell");

var _TableCell2 = _interopRequireDefault(_TableCell);

var _core = require("@material-ui/core");

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useStyles = (0, _core.makeStyles)(function (theme) {
	return {
		cell: {
			paddingRight: theme.spacing(1.5)
		}
	};
});

var ListHead = function ListHead(props) {
	var classes = useStyles();

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	return _react2.default.createElement(
		_react2.default.Fragment,
		null,
		_react2.default.createElement(
			_TableCell2.default,
			{ align: "left", className: classes.cell },
			t("common.table-headers.student")
		),
		_react2.default.createElement(
			_TableCell2.default,
			{ align: "left", className: classes.cell },
			t("common.school")
		),
		_react2.default.createElement(
			_TableCell2.default,
			{ align: "left", className: classes.cell },
			t("app.assigned-student.overview.table-headers.progress")
		),
		_react2.default.createElement(
			_TableCell2.default,
			{ align: "left", className: classes.cell },
			t("app.assigned-student.overview.table-headers.rating-requests")
		)
	);
};

exports.default = ListHead;