import gql from 'graphql-tag';
import { events } from '@manakin/app-core/graphQlHelpers';

export const GQL_FETCH_EVENTS = gql`
	query events($filter: EventFilter) {
		events(pagination: {page: 0, pageSize: 99}, filter: $filter) {
            ${events}
		}
	}
`;

export const GQL_FETCH_EVENT_ENROLLMENTS = gql`
	query eventEnrollments($filter: EventEnrollmentsFilter) {
		eventEnrollments(pagination: { page: 0, pageSize: 99 }, filter: $filter) {
			content {
				id
				eventId
				state
			}
		}
	}
`;

export const GQL_ENROLL_INTO_EVENT = gql`
	mutation enrollIntoEvent($eventId: ID!) {
		enrollIntoEvent(eventId: $eventId) {
			id
		}
	}
`;

export const GQL_FETCH_CURRENT_APP_USER = gql`
	query currentAppUser {
		currentAppUser {
			id
		}
	}
`;