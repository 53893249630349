import React, { useEffect, useReducer, useRef } from 'react';
import ProgressionFeedback from './ProgressionFeedback';
import { reducer } from '../lib';

const ProgressionFeedbackContainer = (props) => {
    const { data = {}, onFinished } = props;
    const { answers = [] } = data;

    const timeout = useRef(null);
    const mounted = useRef(true);

    //state hooks
    const [state, setState] = useReducer(reducer, {});

    //functions
    const setStartState = () => {
        let value = null;
        answers.forEach((answer) => {
            if (answer.selected) value = answer.id;
        });

        setState({
            ...data,
            feedback: false,
            done: value ? true : false,
            initial: true,
        });
    };

    const handleFinish = () => {
        onFinished({
            correct: state.correct,
            workform: { ...state },
        });
    };

    const handleChange = (index, value) => {
        const { answers = [] } = state;
        const _answers = answers.map((answer, idx) => ({
            ...answer,
            ...(index === idx && {
                selected: value,
            }),
        }));
        setState({ answers: _answers });
    };

    const handleRating = (value) => {
        setState({ correct: value });
    };

    const handleFeedbackChange = (event) => {
        const value = event.target.value;
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
            if (mounted.current) {
                setState({ answer: value });
            }
        }, 400);
    };

    //effect hooks
    useEffect(() => {
        return () => {
            return (mounted.current = false);
        };
    }, []);

    useEffect(() => {
        if (data.id) {
            setStartState();
        }
    }, [data.id]);

    useEffect(() => {
        if (state.initial !== undefined && !state.initial) {
            setState({ saved: true });
            setTimeout(() => {
                if (mounted.current) {
                    setState({ saved: false });
                }
            }, 4000);
        }
    }, [data]);

    useEffect(() => {
        if (state.initial !== undefined && !state.initial) {
            handleFinish();
        }
    }, [state.initial, state.answers, state.correct, state.answer]);

    return (
        <ProgressionFeedback
            data={state}
            onChange={handleChange}
            onRating={handleRating}
            onFeedbackChange={handleFeedbackChange}
        />
    );
};

export default ProgressionFeedbackContainer;
