'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _config = require('./config');

Object.defineProperty(exports, 'initI18n', {
  enumerable: true,
  get: function get() {
    return _config.initI18n;
  }
});