"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _styles = require("@material-ui/core/styles");

var _Typography = require("@material-ui/core/Typography");

var _Typography2 = _interopRequireDefault(_Typography);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
	return {
		listItem: {
			width: 200,
			display: "flex",
			cursor: "pointer",
			padding: theme.spacing(2) + "px 0"
		},
		listItemLine: {
			width: "40%",
			minWidth: "40%",
			height: 3,
			marginRight: "10%",
			alignSelf: "center"
		},
		listItemLineSelected: {
			backgroundColor: theme.manakin.indicationColor[500]
		},
		listItemTitle: {
			fontWeight: "normal",
			margin: 0
		},
		listItemTitleSelected: {
			color: theme.manakin.indicationColor[500]
		},
		badge: {
			top: 8,
			right: -16,
			marginLeft: theme.spacing(0.5),
			width: '1.2rem',
			height: '1.2rem',
			minWidth: '1.2rem',
			backgroundColor: 'red',
			borderRadius: '100%'
		}
	};
};

var MenuItem = function MenuItem(props) {
	var badge = props.badge,
	    idx = props.idx,
	    option = props.option,
	    classes = props.classes;

	//functions

	var handleMenuClick = function handleMenuClick() {
		if (props.onClick && option.value) props.onClick(option.value);
	};

	return _react2.default.createElement(
		"div",
		{ className: classes.listItem, key: idx, onClick: handleMenuClick },
		_react2.default.createElement("div", {
			className: (0, _classnames2.default)(classes.listItemLine, _defineProperty({}, classes.listItemLineSelected, option.selected))
		}),
		_react2.default.createElement(
			_Typography2.default,
			{
				classes: {
					subtitle1: (0, _classnames2.default)(classes.listItemTitle, _defineProperty({}, classes.listItemTitleSelected, option.selected))
				},
				align: "center",
				variant: "subtitle1"
			},
			option.label
		),
		badge && _react2.default.createElement("span", { className: (0, _classnames2.default)(classes.badge) })
	);
};

exports.default = (0, _styles.withStyles)(styles, { name: "AppAccountMenuItem" })(MenuItem);