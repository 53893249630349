import React from 'react';
import { WorkformLayout, SortSlider } from '@manakin/app-core';
import shuffle from 'shuffle-array';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

class SortQuestion extends React.PureComponent {
    state = {
        questions: [],
    };

    componentDidMount() {
        this.setStartState();
    }

    componentDidUpdate(prevProps) {
        if (
            (!prevProps.data.done && this.props.data.done) ||
            prevProps.data.id != this.props.data.id
        ) {
            this.setStartState();
        }
    }

    setStartState = () => {
        const { questions = [] } = this.props.data;

        const _questions = [...questions];
        shuffle(_questions);

        this.setState({
            questions: _questions,
        });
    };

    onNext = () => {
        if (this.props.onNext) this.props.onNext();
    };

    render() {
        const { data, options = {}, onFinished, t } = this.props;
        const { questions = [] } = this.state;

        return (
            <WorkformLayout
                question={data.question || ''}
                instruction={data.introduction || t("app.workforms.sort-question.introduction")}
                loading={false}
                quarterQuestion={true}
                renderAnswers={() => (
                    <SortSlider
                        questions={questions}
                        data={data}
                        options={options}
                        leftButton={
                            data.indicationTitle || t("app.workforms.sort-question.indication")
                        }
                        rightButton={
                            data.contraIndicationTitle || t("app.workforms.sort-question.contra-indication")
                        }
                        onNext={onFinished}
                    />
                )}
            />
        );
    }
}

export default compose(
    withTranslation()
)(SortQuestion);
