import React from "react";
import Layout from "./Layout";
import SaveBrowser from "./SaveBrowser";
import { makeStyles, Typography } from "@material-ui/core";
import ListItem from "./ListItem";
import QRCode from "./QRCode";
import CodeBlock from "./Codeblock";
import Button from "@material-ui/core/Button";
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	list: {
		paddingLeft: "20px",
	},
}));

const SetupTwoFactor = (props) => {
	const { state, form, onClick } = props;
	const classes = useStyles();
	const { t } = useTranslation();

	//functions
	const handleClick = () => {
		onClick();
	};

	return (
		<Layout title={t("authentication.two-factor.setup.title")}>
			<Typography>
				{t("authentication.two-factor.setup.description")}
			</Typography>
			<ol className={classes.list}>
				<ListItem
					title={
						<React.Fragment>
							{t("authentication.two-factor.setup.download-app.first-part")}{" "}
							<a target="_blank" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" >
								{t("authentication.two-factor.setup.download-app.play-store")}
							</a>
							{t("authentication.two-factor.setup.download-app.second-part")}{" "}
							<a target="_blank" href="https://apps.apple.com/nl/app/google-authenticator/id388497605" >
								{t("authentication.two-factor.setup.download-app.app-store")}
							</a>
						</React.Fragment>
					}
				/>
				<ListItem title={t("authentication.two-factor.setup.scan-qr")} />
				<ListItem
					title={t("authentication.two-factor.setup.enter-auth-code")}
				/>
			</ol>
			<QRCode dataUri={state.mfaDataUri} showSecretKey={process.env.NODE_ENV === "development"} />
			<CodeBlock form={form} />
			<SaveBrowser form={form}/>
			<Button variant="contained" color="primary" onClick={handleClick}>
				{t("common.confirm")}
			</Button>
		</Layout>
	);
};

export default SetupTwoFactor;
