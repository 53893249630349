"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _core = require("@material-ui/core");

var _Typography = require("@material-ui/core/Typography");

var _Typography2 = _interopRequireDefault(_Typography);

var _CheckCircleOutline = require("@material-ui/icons/CheckCircleOutline");

var _CheckCircleOutline2 = _interopRequireDefault(_CheckCircleOutline);

var _RemoveCircleOutlineOutlined = require("@material-ui/icons/RemoveCircleOutlineOutlined");

var _RemoveCircleOutlineOutlined2 = _interopRequireDefault(_RemoveCircleOutlineOutlined);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _lib = require("@manakin/app-core/lib");

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var useStyles = (0, _core.makeStyles)(function (theme) {
	return {
		content: {
			margin: 0,
			overflow: "hidden",
			textOverflow: "ellipsis"
		},
		iconContainer: {
			display: "flex",
			alignItems: "center"
		},
		check: {
			color: theme.palette.success.main
		},
		notActive: {
			color: theme.palette.warning.main
		},
		icon: {
			marginRight: "10px"
		},
		link: {
			cursor: "pointer",
			color: theme.manakin.linkColor[500],
			transition: "color .2s",
			"&:hover": {
				color: theme.manakin.linkColor[900]
			}
		}
	};
});

var TableRowContainer = function TableRowContainer(props) {
	var _classNames, _classNames2;

	var _props$active = props.active,
	    active = _props$active === undefined ? true : _props$active,
	    onClick = props.onClick,
	    _props$data = props.data,
	    data = _props$data === undefined ? {} : _props$data,
	    _props$superObj = props.superObj,
	    superObj = _props$superObj === undefined ? {} : _props$superObj,
	    request = props.request;

	var _ref = data.result || {},
	    _ref$rater = _ref.rater,
	    rater = _ref$rater === undefined ? {} : _ref$rater;

	var _request$element = request.element,
	    element = _request$element === undefined ? {} : _request$element;

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var _useState = (0, _react.useState)({
		title: t("app.account.bpv-report.report-state.default"),
		status: "DEFAULT"
	}),
	    _useState2 = _slicedToArray(_useState, 2),
	    state = _useState2[0],
	    setState = _useState2[1];

	var classes = useStyles();
	var role = void 0;

	rater.roles ? rater.roles.forEach(function (r) {
		role = r.name;
	}) : data.roles && data.roles.forEach(function (r) {
		role = r.name;
	});

	var handleClick = function handleClick() {
		if (!active && state.status === "DEFAULT" || state.status === "PENDING") onClick();
	};

	(0, _react.useEffect)(function () {
		if (!active && superObj[element.id]) {
			if (superObj[element.id].result) {
				setState({
					title: t("app.account.bpv-report.report-state.agreed"),
					status: "DONE"
				});
			} else {
				setState({
					title: t("app.account.bpv-report.report-state.pending"),
					status: "PENDING"
				});
			}
		}
	}, [superObj]);

	return _react2.default.createElement(
		_core.TableRow,
		null,
		_react2.default.createElement(
			_core.TableCell,
			{ component: "th", scope: "row" },
			_react2.default.createElement(
				_Typography2.default,
				{ className: classes.content },
				rater.email || data.email || ""
			)
		),
		_react2.default.createElement(
			_core.TableCell,
			{ component: "th", scope: "row" },
			_react2.default.createElement(
				_Typography2.default,
				{ className: classes.content },
				(0, _lib.getHumanReadableRoleTitle)(t, role) || ""
			)
		),
		_react2.default.createElement(
			_core.TableCell,
			{ component: "th", scope: "row" },
			_react2.default.createElement(
				"div",
				{
					className: (0, _classnames2.default)(classes.iconContainer, (_classNames = {}, _defineProperty(_classNames, classes.check, active || state.status === "DONE"), _defineProperty(_classNames, classes.notActive, !active && !state.status === "DONE"), _classNames))
				},
				active ? _react2.default.createElement(_CheckCircleOutline2.default, { className: classes.icon }) : state.status === "DONE" ? _react2.default.createElement(_CheckCircleOutline2.default, { className: classes.icon }) : _react2.default.createElement(_RemoveCircleOutlineOutlined2.default, {
					className: classes.icon
				}),
				_react2.default.createElement(
					_Typography2.default,
					{
						className: (0, _classnames2.default)(classes.content, (_classNames2 = {}, _defineProperty(_classNames2, classes.check, active || state.status === "DONE"), _defineProperty(_classNames2, classes.notActive, !active && !state.status === "DONE"), _classNames2))
					},
					active ? t("common.yes") : state.status === "DONE" ? t("common.yes") : t("common.no")
				)
			)
		),
		_react2.default.createElement(
			_core.TableCell,
			{
				component: "th",
				scope: "row",
				align: "right",
				onClick: handleClick
			},
			_react2.default.createElement(
				_Typography2.default,
				{
					className: (0, _classnames2.default)(classes.content, classes.link)
				},
				active ? "" : state.title
			)
		)
	);
};

exports.default = TableRowContainer;