'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.reducer = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _actions = require('./actions');

var initialState = {
    program: null,
    schoolClass: null
};

var reducer = exports.reducer = function reducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    switch (action.type) {
        case _actions.SELECT_PROGRAM:
            {
                if (action.payload.programID) {
                    return _extends({}, state, {
                        program: action.payload.programID,
                        schoolClass: action.payload.schoolClass
                    });
                } else {
                    return _extends({}, state, { program: action.payload, schoolClass: null });
                }
            }
        default:
            return state;
    }
};

exports.default = reducer;