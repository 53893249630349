import React, { useRef, useCallback } from 'react';
import { TextField, SpeechField } from '@manakin/core';
import { SignatureField, RatingChipGroup } from '@manakin/app-core';
import { makeStyles } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { getAppUser } from '@manakin/authentication/selectors';
import { showSnackbarMessage } from '@manakin/core/actions';
import { useTranslation } from 'react-i18next';
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: '50px 0',
	},
	wrapper: {
		...theme.manakin.smallWrapper,
		maxWidth: '550px',
	},
	signature: {
		margin: '50px 0 40px',
	},
	label: {
        marginTop: 32,
		marginBottom: 16,
	},
	compactLabel: {
		marginBottom: 16
	},
	poRating: {
		background: "white",
		padding: 32,
		borderRadius: 16,
		marginBottom: 64
	}
}));

const Form = (props) => {
	const { form, onSubmit, lastPoRatingRequest } = props;
	const classes = useStyles();
	const signatureRef = useRef();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const appUser = useSelector((state) => getAppUser(state));

	const onSnackbarMessage = useCallback(
		(message, variant) =>
			dispatch(
				showSnackbarMessage({
					text: message || t('app.bpv-report.signature-failed'),
					variant: variant || 'error',
				})
			),
		[dispatch]
	);

	const handleClick = () => {
		if (signatureRef.current.isEmpty()) {
			onSnackbarMessage();
			return;
		}

		signatureRef.current.getImageAsPng().then((file) => {
			onSubmit(file);
		});
	};

	return (
		<div className={classes.root}>
			<div className={classes.wrapper}>
				<InputLabel classes={{ root: classes.label }}>{t("app.bpv-report.po-rating")}</InputLabel>
				<div className={classes.poRating}>
					<InputLabel classes={{ root: classes.compactLabel }}>{t('common.rating')}</InputLabel>
					<RatingChipGroup
						form={form}
						readOnly={true}
						initialValue={lastPoRatingRequest && lastPoRatingRequest.result && lastPoRatingRequest.result.ranking}
						name='po-ranking'
					/>

					<InputLabel classes={{ root: classes.label }}>{t('common.explanation')}</InputLabel>
					<SpeechField
						name='po-explanation'
						label=''
						readOnly={true}
						form={form}
						initialValue={lastPoRatingRequest && lastPoRatingRequest.result && lastPoRatingRequest.result.explanation}
						placeholder={t('common.fields.explanation.placeholder')}
						className={classes.explanation}
						inputClassName={classes.explanation}
					/>
				</div>

				<TextField
					name="bpv report"
					label={t('app.bpv-report.fields.name.label')}
					form={form}
					readOnly
					initialValue={appUser.fullName}
				/>

                <InputLabel classes={{ root: classes.label }}>{t('common.rating')}</InputLabel>
                <RatingChipGroup form={form} name='ranking' />

                <InputLabel classes={{ root: classes.label }}>{t('common.explanation')}</InputLabel>
                <SpeechField
                    name='explanation'
                    label=''
                    form={form}
                    placeholder={t('common.fields.explanation.placeholder')}
                    className={classes.explanation}
                    hideSpeechToText={true}
                    inputClassName={classes.explanation}
                />

				<div className={classes.signature}>
					<SignatureField readOnly={false} ref={signatureRef} label={t('app.bpv-report.fields.signature.label')} />
				</div>
				<Button variant="contained" color="primary" fullWidth onClick={handleClick} disabled={false}>
					{t('common.save')}
				</Button>
			</div>
		</div>
	);
};

export default Form;
