import React from 'react';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import { LoadImage } from '@manakin/app-core';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from "./styles";
import { useTranslation } from 'react-i18next';

const Slide = props => {
    const {
        handleDataChange,
        slideIndex,
        slideType,
        slideData,
        slide,
    } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    let storedSlideData;

    slideData &&
    slideData.map((slide) => {
        if (slideIndex === slide.idx) {
            storedSlideData = slide.data;
        }
    });

    if (slideType === 'text') {
        return (
            <React.Fragment>
                <div className={classes.textSlide}>
                    <div className={classes.inputWrap}>
                        <TextField
                            classes={{ root: classes.slideTextRoot }}
                            multiline
                            rowsMax={Infinity}
                            defaultValue={
                                storedSlideData
                                    ? storedSlideData.title
                                    : null
                            }
                            autoFocus={true}
                            onChange={() =>
                                handleDataChange(slideIndex, 'title', event)
                            }
                            InputProps={{
                                disableUnderline: true,
                                placeholder: t("app.elements.slideshow-creator.slide.add-title"),
                            }}
                            inputProps={{
                                classes: {
                                    textarea: classes.slideTitleInput,
                                },
                            }}
                        />
                    </div>

                    <div
                        className={classNames(
                            classes.inputWrap,
                            classes.textSlideContent
                        )}
                    >
                        <TextField
                            classes={{ root: classes.slideTextRoot }}
                            multiline
                            rows={8}
                            rowsMax={Infinity}
                            defaultValue={
                                storedSlideData
                                    ? storedSlideData.text
                                    : null
                            }
                            onChange={() =>
                                handleDataChange(slideIndex, 'text', event)
                            }
                            InputProps={{
                                disableUnderline: true,
                                classes: {
                                    root: classes.slideTextFieldInput,
                                },
                                placeholder: t("app.elements.slideshow-creator.slide.add-text"),
                            }}
                            inputProps={{
                                classes: {
                                    textarea: classes.slideTextFieldInput,
                                },
                            }}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    } else if (slideType === 'image') {
        return (
            <div className={classes.imageSlide}>
                {slide.image && slide.image.asset && (
                    <LoadImage
                        src={slide.image.asset.url}
                        loader={<CircularProgress/>}
                    />
                )}
            </div>
        );
    }
};

export default Slide;
