import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { useStyles } from '../styles';

const StepperContainer = (props) => {
    const { step, steps } = props;
    const classes = useStyles();
    return (
        <Stepper
            classes={{ root: classes.stepperRoot }}
            activeStep={step}
            alternativeLabel
        >
            {steps.map((e, index) => (
                <Step classes={{ root: classes.stepRoot }} key={index}>
                    <StepLabel>{index}</StepLabel>
                </Step>
            ))}
        </Stepper>
    );
};

export default StepperContainer;
