'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = exports.styles = function styles(theme) {
    return {
        profileFields: {
            margin: '3rem 0 6rem 0'
        },
        changePasswordRoot: {
            display: 'flex'
        },
        changePasswordInput: {
            flexGrow: 1
        },
        changePasswordIcon: {
            maxHeight: 48,
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
        },
        deleteButton: {
            display: 'block',
            margin: '80px auto 0',
            color: theme.manakin.typeColor[800]
        },
        cancelDeleteButton: {
            flex: '1 1 50%'
        },
        confirmDeleteButton: {
            flex: '1 1 50%',
            backgroundColor: theme.manakin.typeColor[800],
            color: 'white',
            '&:hover': {
                backgroundColor: theme.manakin.typeColor[900]
            }
        },
        dialogActions: {
            display: 'flex',
            flexFlow: 'row wrap',
            width: '100%',
            marginTop: theme.spacing(2)
        },
        dialogPaper: {
            flexFlow: 'row wrap',
            padding: '32px'
        },
        dialogTitle: _defineProperty({
            width: '100%',
            margin: 0,
            padding: '12px 0',
            textAlign: 'left',
            fontSize: '2.5rem',
            lineHeight: '3.5rem'
        }, theme.breakpoints.up('md'), {
            padding: '32px 0'
        }),
        mainButtonStyle: {
            padding: '2rem',
            marginTop: '3rem',
            marginRight: '3rem'
        },
        organisationFields: {
            width: '100%'
        },
        selectFieldRoot: {
            margin: '16px 0 -35px 0'
        },
        checkbox: {
            margin: '-20px 0 30px',
            '& *': {
                margin: 0,
                width: 'auto'
            }
        },
        AppBar: {},
        readOnlyWarning: {
            marginTop: 24,
            textAlign: "left"
        }
    };
};