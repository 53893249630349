import React, { useEffect } from "react";
import Header from "./parts/Header";
import { getAppUser } from "@manakin/authentication/selectors";
import { isListView } from "@manakin/app-core/Boxes/selectors";
import { connect } from "react-redux";
import { compose } from "recompose";
import { setBackButton } from "@manakin/app-core/AppBar/actions";
import { AccessControl } from "@manakin/app-core";
import Grid from "@material-ui/core/Grid";
import Block from "./parts/Block";
import SessionDurationBlock from "./parts/SessionDurationBlock";
import ProfileBlock from "./parts/ProfileBlock";
import ProgramProgress from "./parts/ProgramProgress";
import { useStyles } from "./styles";
import { GQL_FETCH_CURRENT_APP_USER } from "./graphql";
import { useQuery } from "@apollo/react-hooks";
import { cardGridDimensions, largeCardGridDimensions } from "./parts/lib";
import StudentView from "./parts/StudentView";
import TrainerView from "./parts/TrainerView";
import {
	GQL_FETCH_APP_USERS,
	GQL_FETCH_INVITATION_STUDENT_IDS,
	GQL_FETCH_PROGRAM,
} from "./graphql/graphql";
import { getProgram } from "@manakin/app-core/ProgramsDropdown/selectors";
import RatingProgress from "./parts/RatingProgress";
import InfoBlock from "./parts/InfoBlock";
import ManagementView from "./parts/ManagementView";
import SessionsBlock from "./parts/SessionsBlock";
import {
	managementRole,
	trainerRoles,
	studentRoles
} from "@manakin/app-core/lib";
import { useLocation } from "react-router";
import { useTranslation } from 'react-i18next';
import MissingDataBPVDialog from './parts/MissingDataBPVDialog';

const mapStateToProps = (state) => ({
	config: state.config,
	appUser: getAppUser(state),
	listView: isListView(state),
	program: getProgram(state),
});

const mapDispatchToProps = (dispatch) => ({
	backButton: (data) => dispatch(setBackButton(data)),
});

const ProgressDashboard = (props) => {
	const location = useLocation();
	const { t } = useTranslation();

	// Query
	const { data = {}, loading } = useQuery(GQL_FETCH_CURRENT_APP_USER);
	const { data: programData = {} } = useQuery(GQL_FETCH_PROGRAM, {
		variables: { programId: props.program },
	});
	const { currentAppUser = {} } = data || {};
	const program = programData.program || {};
	const isTrainer =
		currentAppUser.roles &&
		currentAppUser.roles.some((role) => trainerRoles.includes(role.name));
	const isManagement =
		currentAppUser.roles &&
		currentAppUser.roles.some((role) => managementRole.includes(role.name));

	const { data: invitationStudentIdsData = {} } = useQuery(
		GQL_FETCH_INVITATION_STUDENT_IDS,
		{
			skip: !isTrainer || isManagement === true,
		}
	);
	const { invitationStudents } = invitationStudentIdsData;

	const { data: appUsersData = {} } = useQuery(GQL_FETCH_APP_USERS, {
		variables: {
			page: 0,
			pagesize: 999,
		},
		skip: !isManagement,
	});
	const { appUsers = {} } = appUsersData;
	const { users = [] } = appUsers || {};
	const students = invitationStudents || users;

	const classes = useStyles();

	useEffect(() => {
		props.backButton({
			show: true,
			location: location.pathname,
			url: "/",
		});
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className={classes.root}>
			<Header data={{}}/>

			<Grid
				className={classes.container}
				container
				spacing={3}
				alignItems="stretch"
			>
				<AccessControl role={[ ...trainerRoles, managementRole ]}>
					<Grid item {...cardGridDimensions}>
						<Block
							title={t("app.progress-dashboard.amount-of-students")}
						>
							<InfoBlock
								data={{
									title: t("common.students"),
									value: students && students.length,
								}}
							/>
						</Block>
					</Grid>
				</AccessControl>
				<AccessControl role={studentRoles}>
					<Grid item {...cardGridDimensions}>
						<Block>
							<ProfileBlock data={currentAppUser}/>
						</Block>
					</Grid>
				</AccessControl>
				<Grid item {...largeCardGridDimensions}>
					<Block>
						<SessionsBlock/>
					</Block>
				</Grid>
				<Grid item {...cardGridDimensions}>
					<ProgramProgress
						currentAppUser={currentAppUser}
						program={program}
						students={students}
					/>
				</Grid>
				<Grid item {...cardGridDimensions}>
					<RatingProgress program={program}/>
				</Grid>
				<Grid item {...cardGridDimensions}>
					<Block
						title={t("app.progress-dashboard.blocks.sessions.average-duration")}
					>
						<SessionDurationBlock/>
					</Block>
				</Grid>
				<AccessControl role={trainerRoles}>
					<TrainerView/>
				</AccessControl>
				<AccessControl role={studentRoles}>
					<StudentView
						currentAppUser={currentAppUser}
						program={program}
					/>
				</AccessControl>
				<AccessControl role={[ managementRole ]}>
					<ManagementView
						currentAppUser={currentAppUser}
						program={program}
					/>
				</AccessControl>
				<MissingDataBPVDialog/>
			</Grid>
		</div>
	);
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
	ProgressDashboard
);
