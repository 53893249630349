'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _selectors = require('@manakin/authentication/selectors');

var _actions = require('./actions');

var _reactRedux = require('react-redux');

var _selectors2 = require('./selectors');

var _graphql = require('./graphql');

var _reactHooks = require('@apollo/react-hooks');

var _reactApollo = require('react-apollo');

var _styles = require('@material-ui/styles');

var _lib = require('@manakin/app-core/lib');

var _Autocomplete = require('@material-ui/lab/Autocomplete');

var _Autocomplete2 = _interopRequireDefault(_Autocomplete);

var _TextField = require('@material-ui/core/TextField');

var _TextField2 = _interopRequireDefault(_TextField);

var _actions2 = require('@manakin/app-core/ProgramsDropdown/actions');

var _selectors3 = require('@manakin/app-core/ProgramsDropdown/selectors');

var _appCore = require('@manakin/app-core');

var _reactI18next = require('react-i18next');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var useStyles = (0, _styles.makeStyles)(function (theme) {
	return (0, _styles.createStyles)({
		select: {
			minWidth: '300px',
			overflow: 'hidden'
		},
		popper: {
			fontSize: '1.6rem',
			'& > div > ul > li > div': {
				fontSize: '1.6rem'
			}
		},
		selectColors: {},
		selectSubHeader: {
			fontSize: '1.8rem'
		}
	});
}, {
	name: 'AppGroupsDropdown'
});

var sortOptions = function sortOptions(a, b) {
	var nameA = a.trim().toLocaleUpperCase();
	var nameB = b.trim().toLocaleUpperCase();

	if (nameA > nameB) return 1;
	if (nameA < nameB) return -1;

	return 0;
};

var GroupsDropdown = function GroupsDropdown(props) {
	var _props$onlyShowGroups = props.onlyShowGroups,
	    onlyShowGroups = _props$onlyShowGroups === undefined ? false : _props$onlyShowGroups;

	var classes = useStyles();
	var dispatch = (0, _reactRedux.useDispatch)();
	var client = (0, _reactApollo.useApolloClient)();
	var storedAppUser = (0, _reactRedux.useSelector)(function (state) {
		return (0, _selectors.getAppUser)(state);
	}) || {};
	var storedGroupId = (0, _reactRedux.useSelector)(function (state) {
		return (0, _selectors2.getGroup)(state);
	});
	var storedProgramId = (0, _reactRedux.useSelector)(function (state) {
		return (0, _selectors3.getProgram)(state);
	});

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var _useState = (0, _react.useState)(true),
	    _useState2 = _slicedToArray(_useState, 2),
	    noClassesConnectedDialog = _useState2[0],
	    showNoClassesConnectedDialog = _useState2[1];

	var _useState3 = (0, _react.useState)(true),
	    _useState4 = _slicedToArray(_useState3, 2),
	    loading = _useState4[0],
	    setLoading = _useState4[1];

	var _useState5 = (0, _react.useState)({}),
	    _useState6 = _slicedToArray(_useState5, 2),
	    selected = _useState6[0],
	    setSelected = _useState6[1];

	var _useState7 = (0, _react.useState)([]),
	    _useState8 = _slicedToArray(_useState7, 2),
	    options = _useState8[0],
	    setOptions = _useState8[1];

	var _useLazyQuery = (0, _reactHooks.useLazyQuery)(_graphql.GQL_FETCH_GROUPS, {
		variables: {
			filter: {
				manager: storedAppUser.id,
				types: ['School']
			}
		},
		onCompleted: function onCompleted(data) {
			if (data && data.groups && data.groups.groups) {
				var isTeacher = (0, _lib.userHasAnyRole)(storedAppUser, _lib.teacherRoles);
				var isSchoolManager = (0, _lib.userHasAnyRole)(storedAppUser, _lib.schoolManagerRole);

				if (isTeacher || isSchoolManager) {
					data.groups.groups.forEach(function (school) {
						return processSchool(school, isSchoolManager);
					});
				} else {
					setLoading(false);
				}
			} else if (!loading) {
				setLoading(false);
			}
		}
	}),
	    _useLazyQuery2 = _slicedToArray(_useLazyQuery, 1),
	    fetchGroups = _useLazyQuery2[0];

	var _useLazyQuery3 = (0, _reactHooks.useLazyQuery)(_graphql.GQL_FETCH_GROUPS_FOR_SCHOOL_MANAGER, {
		onCompleted: function onCompleted(data) {
			if (data && data.schoolManagerGroups && data.schoolManagerGroups.groups) {
				setOptions(data.schoolManagerGroups.groups.map(function (group) {
					if (storedGroupId.id && storedGroupId.id === group.id) {
						setSelected({
							id: group.id,
							label: group.name,
							type: group.type
						});
					}
					return {
						id: group.id,
						label: group.name,
						type: group.type
					};
				}).sort(function (a, b) {
					return sortOptions(a.label, b.label);
				}));

				setLoading(false);
			} else if (!loading) {
				setLoading(false);
			}
		}
	}),
	    _useLazyQuery4 = _slicedToArray(_useLazyQuery3, 2),
	    fetchGroupsForSchoolManager = _useLazyQuery4[0],
	    schoolManagerData = _useLazyQuery4[1];

	(0, _react.useEffect)(function () {
		var isSchoolManager = (0, _lib.userHasAnyRole)(storedAppUser, _lib.schoolManagerRole);
		if (isSchoolManager) {
			fetchGroupsForSchoolManager();
		} else {
			fetchGroups();
		}
	}, [storedAppUser.id]);

	var processSchool = function processSchool(school, isSchoolManager) {
		client.query({
			query: _graphql.GQL_FETCH_SCHOOL,
			variables: { id: school.id },
			fetchPolicy: 'no-cache'
		}).then(function (result) {
			if (!result.data.school.classes.length) {
				setLoading(false);
				return;
			}

			var newOptions = [];
			var selectedFallback = false;
			result.data.school.classes.forEach(function (schoolClass) {
				// Show if you are a manager or the teacher of the class
				var show = isSchoolManager || schoolClass.teachers && schoolClass.teachers.some(function (i) {
					return i.id === storedAppUser.id;
				});

				var selected = void 0;
				if (show) {
					if (onlyShowGroups) {
						selected = {
							label: schoolClass.name,
							value: schoolClass.id,
							schoolClass: schoolClass
						};
						newOptions.push(selected);
					} else {
						schoolClass.products.map(function (product) {
							newOptions.push({
								label: schoolClass.name + ' - ' + product.name,
								subLabel: product.name,
								value: schoolClass.id + '-' + product.id,
								product: product,
								schoolClass: schoolClass
							});
						});

						var noProductConnected = {
							label: schoolClass.name + ' - ' + t('app.groups-dropdown.no-product-connected'),
							subLabel: t('app.groups-dropdown.no-product-connected'),
							value: '' + schoolClass.id,
							schoolClass: schoolClass
						};

						if (schoolClass.products.length === 0) {
							// No products, still allow the user to select the group
							newOptions.push(noProductConnected);
						}

						if (schoolClass.products && schoolClass.products.length) {
							var product = schoolClass.products.find(function (product) {
								return product.program.id === storedProgramId;
							}) || schoolClass.products[0];

							selected = {
								label: schoolClass.name + ' - ' + product.name,
								subLabel: product.name,
								value: schoolClass.id + '-' + product.id,
								product: product,
								schoolClass: schoolClass
							};
						} else {
							selected = noProductConnected;
						}
					}

					if (schoolClass.id === storedGroupId.id) {
						setSelected(selected);
					} else if (storedGroupId.id === undefined && selectedFallback === false) {
						// No group selected yet, default to first product the user has access to
						selectedFallback = true;
						setSelected(selected);

						dispatch((0, _actions.selectGroup)(selected.schoolClass));
						if (!onlyShowGroups && selected.product && selected.product.program) {
							dispatch((0, _actions2.selectProgram)(selected.product.program.id));
						}
					}

					setLoading(false);
				} else {
					setLoading(false);
				}
			});

			if (result.data.school.classes) {
				setOptions([].concat(_toConsumableArray(options), newOptions).sort(function (a, b) {
					return sortOptions(a.schoolClass.name, b.schoolClass.name);
				}));
			}
		}).catch(function () {
			return setLoading(false);
		});
	};

	var handleChange = function handleChange(event, value) {
		if (value && value.schoolClass) {
			setSelected(value);
			dispatch((0, _actions.selectGroup)(value.schoolClass));

			if (value.product && value.product.program) {
				dispatch((0, _actions2.selectProgram)(value.product.program.id));
			}
		} else if (value && value.type && value.type === 'SchoolClass') {
			setSelected(value);
			dispatch((0, _actions.selectGroup)(value));
			if (value.product && value.product.program) {
				dispatch((0, _actions2.selectProgram)(value.product.program.id));
			}
		} else if (value && value.type && value.type === 'School') {
			setSelected(value);
			dispatch((0, _actions.selectGroup)(value));
		}
	};

	if (!options.length && !loading) {
		return _react2.default.createElement(
			'div',
			null,
			_react2.default.createElement(
				_appCore.AccessControl,
				{ role: [_lib.schoolManagerRole] },
				_react2.default.createElement(_appCore.Dialog, {
					title: t('app.groups-dropdown.no-school-class-dialog.title'),
					open: noClassesConnectedDialog,
					onClose: function onClose() {
						return showNoClassesConnectedDialog(false);
					},
					content: t('app.groups-dropdown.no-school-class-dialog.description'),
					buttonText: t('common.close')
				})
			)
		);
	}

	return _react2.default.createElement(
		'div',
		null,
		_react2.default.createElement(_Autocomplete2.default, _extends({
			id: 'combo-box-demo',
			name: 'program',
			value: selected,
			options: options,
			getOptionLabel: function getOptionLabel(option) {
				return option.label || '';
			},
			onChange: handleChange
		}, !onlyShowGroups && {
			groupBy: function groupBy(option) {
				return option.schoolClass && option.schoolClass.name;
			},
			renderOption: function renderOption(option) {
				return option.subLabel;
			}
		}, {
			className: classes.select,
			disableClearable: true,
			noOptionsText: t('app.groups-dropdown.no-other-options'),
			classes: {
				listbox: classes.popper,
				input: classes.selectColors,
				popupIndicator: classes.selectColors,
				inputRoot: classes.selectColors,
				popper: classes.popper
			},
			renderInput: function renderInput(params) {
				return _react2.default.createElement(_TextField2.default, _extends({}, params, { variant: 'standard' }));
			}
		}))
	);
};

exports.default = GroupsDropdown;