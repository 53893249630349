import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { graphql } from 'react-apollo';
import AssetsViewerContent from './AssetsViewerContent';
import { connect } from 'react-redux';
import { isOpen, getName, getMulti, getAssets } from './selectors';
import { closeAssetsViewer, pickAsset } from '@manakin/core/actions';
import { compose } from 'recompose';
import { GQL_FETCH_CMS_SETTINGS } from './graphql';
import { withTranslation } from 'react-i18next';
import { SettingsKeys } from '@manakin/core/lib/constants';

const styles = (theme) => ({
    root: {},
    dialog: {
        width: '100%',
    },
    dialogContent: {
        overflowY: 'scroll',
        position: 'relative',
    },
    tile: {
        borderRadius: '4px',
    },
    assetImage: {
        cursor: 'pointer',
    },
    icon: {
        color: 'white',
    },
    bar: {
        transition: '0.25s',
    },
    unselectedBar: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    selectedBar: {
        backgroundColor: 'rgba(239, 74, 63, 0.8)',
    },
});

const mapStateToProps = (state) => ({
    open: isOpen(state),
    name: getName(state),
    multi: getMulti(state),
    assets: getAssets(state),
});

const mapDispatchToProps = (dispatch) => ({
    onClose: () => dispatch(closeAssetsViewer()),
    onAssetPick: (data) => dispatch(pickAsset(data)),
});

const Transition = React.forwardRef((props, ref) => (
    <Slide direction="up" {...props} ref={ref} />
));

class AssetsViewer extends React.PureComponent {
    state = {
        page: 0,
        pageSize: 12,
        pickedAssetId: '',
        pickedAssetSize: '',
        pickedAssetName: '',
        search: '',
        assets: {},
    };

    handleClose = () => {
        this.props.onClose();
    };

    reset = () => {
        this.setState({
            page: 0,
            pickedAssetId: '',
            pickedAssetSize: '',
        });
    };

    handleAssetPick = (assetId, size, asset) => {
        if (this.props.multi) {
            this.setState((prevState) => ({
                assets: {
                    ...prevState.assets,
                    [assetId]: {
                        bynderId: assetId,
                        derivative: size,
                        url: asset.thumbnails[size],
                    },
                },
            }));
        } else {
            this.setState({
                pickedAssetId: assetId,
                pickedAssetSize: size,
                picketAssetUrl: asset.thumbnails[size],
                pickedAssetName: asset.name
            });
        }
    };

    handlePageChange = (page) => {
        this.setState({
            page: parseInt(page),
        });
    };

    handleItemsPerPageChange = (pageSize) => {
        this.setState({
            pageSize: parseInt(pageSize),
        });
    };

    handleUsePickedAsset = () => {
        if (this.props.multi) {
            const { onAssetPick, name } = this.props;
            onAssetPick({ name: name, ...this.state.assets });
            this.handleClose();
        } else {
            const {
                pickedAssetId: assetId,
                pickedAssetSize: assetSize,
                picketAssetUrl,
                pickedAssetName: fileName
            } = this.state;
            const { onAssetPick, name } = this.props;
            onAssetPick({
                name: name,
                id: assetId,
                size: assetSize,
                url: picketAssetUrl,
                fileName: fileName
            });
            this.handleClose();
        }
    };

    handleSearch = (query) => {
        this.setState({
            page: 0,
            search: query,
        });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.open != this.props.open) {
            if (this.props.open) {
                const { assets = [] } = this.props;
                const { assets: stateAssets = {} } = this.state;

                if (this.isEmpty(stateAssets) && assets.length) {
                    if (this.props.multi) {
                        const obj = {};
                        assets.forEach((asset) => {
                            if (asset.asset && asset.asset.bynderId) {
                                obj[asset.asset.bynderId] = {
                                    bynderId: asset.asset.bynderId,
                                    derivative: asset.asset.derivative,
                                    url: asset.asset.url,
                                };
                            }
                        });

                        this.setState(() => ({
                            assets: obj,
                        }));
                    }
                }
                this.reset();
            }
        }
    }

    isEmpty = (obj) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false;
            }
        }

        return JSON.stringify(obj) === JSON.stringify({});
    };

    render() {
        const { classes, open = false, data, multi = false, t } = this.props;
        const {
            page,
            pageSize,
            pickedAssetId,
            pickedAssetSize,
            search,
            assets = {}
        } = this.state;
        const { settings = {} } = data;

        let projectName = '';
        settings &&
            settings.settings &&
            settings.settings.map((item) => {
                if (item.name === SettingsKeys.PROJECT_NAME) projectName = item.value;
            });

        return (
            <div className={classes.root}>
                <Dialog
                    fullWidth
                    maxWidth={false}
                    TransitionComponent={Transition}
                    classes={{
                        paper: classes.dialog,
                    }}
                    open={open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        {t("core.asset-viewer.title")}
                    </DialogTitle>
                    <DialogContent
                        classes={{
                            root: classes.dialogContent,
                        }}
                    >
                        <div>
                            <AssetsViewerContent
                                assets={assets}
                                multi={multi}
                                page={page}
                                open={open}
                                pageSize={pageSize}
                                search={search}
                                pickedAssetId={pickedAssetId}
                                pickedAssetSize={pickedAssetSize}
                                onAssetPick={this.handleAssetPick}
                                onSearch={this.handleSearch}
                                onPageChange={(page) => this.handlePageChange(page)}
                                projectName={projectName}
                                onItemsPerPageChange={(pageSize) => this.handleItemsPerPageChange(pageSize)}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            {t("common.cancel")}
                        </Button>
                        <Button
                            onClick={this.handleUsePickedAsset}
                            color="primary"
                        >
                            {t("common.use-plural")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default compose(
    graphql(GQL_FETCH_CMS_SETTINGS),
    withTranslation(),
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(AssetsViewer);
