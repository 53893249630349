import React from 'react';
import classNames from 'classnames';
import { default as MuiAppBar } from '@material-ui/core/AppBar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Link, NavLink } from 'react-router-dom';
import Shifter from '../AppMenu/Shifter';
import { toggleAppMenu } from '../AppMenu/actions';
import { logout } from '@manakin/authentication/actions';
import { connect } from 'react-redux';
import { getAppBarTitle } from './selectors';
import { compose } from 'recompose';
import { Breadcrumbs } from '@manakin/core';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
    root: {
        ...theme.mixins.toolbar,
    },
    appBarRoot: {
        backgroundColor: theme.palette.secondary[400],
    },
    title: {
        flex: '1 0 auto',
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
    selected: {
        color: 'purple',
    },
    breadcrumb: {
        transform: 'translateX(0)',
        transition: 'transform 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
    breadcrumbShift: {
        transform: 'translateX(300px)',
        transition: 'transform 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    },
});

const mapStateToProps = (state) => ({
    title: getAppBarTitle(state),
});

const mapDispatchToProps = (dispatch) => ({
    onToggleAppMenu: () => dispatch(toggleAppMenu()),
    onLogout: () => dispatch(logout()),
});

class AppBar extends React.Component {
    state = {
        anchorEl: null,
    };

    handleMenuToggle = () => {
        this.props.onToggleAppMenu();
    };

    handleAccountMenuOpen = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleAccountMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    handleLogout = () => {
        this.props.onLogout();
    };

    render() {
        const {
            classes,
            title,
            className: classNameProp,
            t
        } = this.props;
        const { anchorEl } = this.state;
        const accountMenuOpen = Boolean(anchorEl);
        const className = classNames(classes.root, classNameProp);

        return (
            <div className={className}>
                <Shifter
                    render={({ shifted }) => (
                        <MuiAppBar
                            classes={{ root: classes.appBarRoot }}
                            color="secondary"
                            position="fixed"
                            elevation={0}
                        >
                            <Toolbar>
                                {!shifted && (
                                    <IconButton
                                        onClick={this.handleMenuToggle}
                                        color="inherit"
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                )}
                                <div
                                    className={classNames(classes.breadcrumb, {
                                        [classes.breadcrumbShift]: shifted,
                                    })}
                                >
                                    {/* <Breadcrumbs separator={<strong> / </strong>} /> */}
                                </div>
                                <Typography
                                    className={classes.title}
                                    variant="h6"
                                    color="inherit"
                                >
                                    {title}
                                </Typography>
                                <div>
                                    <IconButton
                                        onClick={this.handleAccountMenuOpen}
                                        color="inherit"
                                    >
                                        <AccountCircle />
                                    </IconButton>
                                    <Menu
                                        id="AppBar__AccountMenu"
                                        anchorEl={anchorEl}
                                        getContentAnchorEl={null}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={accountMenuOpen}
                                        onClose={this.handleAccountMenuClose}
                                    >
                                        <Link
                                            className={classes.link}
                                            to={'/account'}
                                        >
                                            <MenuItem onClick={this.handleAccountMenuClose}>
                                                {t("core.app-bar.my-account")}
                                            </MenuItem>
                                        </Link>
                                        <MenuItem onClick={this.handleLogout}>
                                            {t("common.logout")}
                                        </MenuItem>
                                    </Menu>
                                </div>
                            </Toolbar>
                        </MuiAppBar>
                    )}
                />
            </div>
        );
    }
}

export default compose(
    withTranslation(),
    withStyles(styles, { name: 'ManakinCoreAppBar' }),
    connect(mapStateToProps, mapDispatchToProps)
)(AppBar);
