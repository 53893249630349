import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

const styles = (theme) => ({
    root: {},
    appBar: {},
    title: {
        flex: '1 0 auto',
    },
});

class DetailBar extends React.Component {
    handleClose = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
        this.props.history.goBack();
    };

    render() {
        const { classes, title } = this.props;
        return (
            <div className={classes.root}>
                <AppBar className={classes.appBar} position="fixed">
                    <Toolbar>
                        <IconButton color="inherit" onClick={this.handleClose}>
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            color="inherit"
                            className={classes.title}
                        >
                            {title}
                        </Typography>
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(DetailBar));
