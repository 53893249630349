import { createSelector } from 'reselect';
import { getCoreState } from '../selectors';

export const ASSETS_VIEWER_STATE_ROOT = 'assetsViewer';

const getState = createSelector(
    [getCoreState],
    (coreState) => coreState[ASSETS_VIEWER_STATE_ROOT]
);

export const isOpen = createSelector([getState], (state) => state.open);

export const getImageData = createSelector(
    [getState],
    (state) => state.imageData
);

export const getName = createSelector([getState], (state) => state.name);

export const getMulti = createSelector([getState], (state) => state.multi);

export const getAssets = createSelector(
    [getState],
    (state) => state.assets || []
);
