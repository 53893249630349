import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import classNames from "classnames";
import { Rate, CheckboxGroup, WorkformLayout } from "@manakin/app-core";
import TextField from "@material-ui/core/TextField";
import { styles } from "./styles";
import { useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import renderHTML from "react-render-html";
import Fade from "@material-ui/core/Fade";
import { getAppUser } from "@manakin/authentication/selectors";
import { trainerRoles, managementRole } from "@manakin/app-core/lib";
import { useTranslation } from 'react-i18next';

const ProgressionFeedback = (props) => {
	const {
		classes,
		direction,
		onChange,
		onRating,
		onFeedbackChange,
		data = {},
	} = props;
	const appUser = useSelector((state) => getAppUser(state));
	const { correct, answers, answer } = data;
	const { t } = useTranslation();

	const isTrainer = appUser.roles.some((role) =>
		trainerRoles.includes(role.name)
	);
	const isMM = appUser.roles.some((role) => role.name === managementRole);

	return (
		<WorkformLayout
			question={data.workTitle || data.title || ""}
			instruction={""}
			loading={false}
			direction={direction}
			compactQuestion={true}
			renderQuestion={() => (
				<div className={classes.wrapper}>
					<Typography
						component="div"
						variant="body1"
						classes={{ body1: classes.content }}
					>
						{renderHTML(data.introduction || "")}
					</Typography>
					<Rate
						readOnly={isTrainer || isMM}
						initialValue={correct}
						onChange={onRating}
					/>
				</div>
			)}
			renderAnswers={() => (
				<Fade in={correct || correct === false} timeout={400}>
					<div
						className={classNames(classes.wrapper, {
							[classes.hidden]:
								correct !== false && correct !== true,
						})}
					>
						<Typography
							component="p"
							variant="subtitle1"
							className={classNames(
								classes.subHeader,
								classes.contentColor,
								classes.answersHeader
							)}
						>
							1. {data.question}
						</Typography>

						<CheckboxGroup
							className={classes.checkBoxGroup}
							answers={answers}
							onChange={onChange}
							initialValue={""}
							readOnly={isTrainer || isMM}
						/>

						<Typography
							component="p"
							variant="subtitle1"
							className={classNames(
								classes.subHeader,
								classes.contentColor
							)}
						>
							2. {data.secondQuestion}
						</Typography>

						<TextField
							multiline
							rows={1}
							rowsMax={Infinity}
							defaultValue={answer}
							disabled={isTrainer || isMM}
							onChange={onFeedbackChange}
							InputProps={{
								placeholder: data.secondQuestionPlaceholder || t("app.workforms.progression-feedback.enter-answer"),
								classes: {
									inputMultiline: classes.textFieldInput,
								},
							}}
						/>
						<Fade in={data.saved} timeout={400}>
							<div className={classes.saved}>
								{t("app.workforms.progression-feedback.answer-saved")}
							</div>
						</Fade>
					</div>
				</Fade>
			)}
		/>
	);
};

export default compose(withStyles(styles, { name: "AppProgressionFeedback" }))(
	ProgressionFeedback
);
