import React, { useEffect } from 'react';
import { useStyles } from '../parts/styles';
import { TextField } from '@manakin/core';
import {useTranslation} from 'react-i18next';
import { useSelector } from "react-redux";
import { AuthDataFormSettingsPasswordStrong } from "./AuthDataFormSettings";

const AuthDataForm = (props) => {
	const { form = {}, initial } = props;
	const { fields: fieldsData } = form;
	const classes = useStyles();
	const { t } = useTranslation();

	const config = useSelector((state) => state.config);
	const { general = {} } = config || {};

	useEffect(() => {
		if (general.strongPassword) {
			form.onSchemaChange("password", AuthDataFormSettingsPasswordStrong.password);
			form.onSchemaChange("secondPassword", AuthDataFormSettingsPasswordStrong.secondPassword);
		}
	}, []);

	return (
		<React.Fragment>
			<div className={classes.groupElements}>
				<TextField
					form={form}
					name="email"
					label={t("common.fields.email-address.label")}
					initialValue={initial.trainerEmail}
					readOnly
				/>
				<TextField
					form={form}
					name="password"
					label={t("common.fields.password.label")}
					initialValue={fieldsData.password}
					type="password"
				/>
			</div>
			<div className={classes.groupElements}>
				<TextField
					form={form}
					name="secondEmail"
					label={t("common.fields.repeat-email-address.label")}
					initialValue={initial.trainerEmail}
					readOnly
				/>
				<TextField
					form={form}
					name="secondPassword"
					label={t("common.fields.repeat-password.label")}
					initialValue={fieldsData.secondPassword}
					type="password"
				/>
			</div>
		</React.Fragment>
	);
};

export default AuthDataForm;
