import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { WorkformLayout, LongArrow } from '@manakin/app-core';
import { FeedbackBox } from '@manakin/app-workforms';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import { styles } from './styles';
import ArrowUp from '@material-ui/icons/ExpandLess';
import ArrowDown from '@material-ui/icons/ExpandMore';
import { withTranslation } from 'react-i18next';

class OrderQuestion extends React.PureComponent {
	moveCard = (dragIndex, hoverIndex) => {
		if (this.props.onMoveCard) this.props.onMoveCard(dragIndex, hoverIndex);
	};

	handleSubmit = () => {
		if (this.props.onSubmit) this.props.onSubmit();
	};

	handleOnFinish = () => {
		if (this.props.onFinished) this.props.onFinished();
	};

	handleClick = () => {
		const answers = [...this.state.answers];
		setTimeout(() => {
			this.setState(
				{
					answers: answers,
				},
				300
			);
		});
	};

	handleMoveDown(idx, id) {
		if (this.props.onMoveDown) this.props.onMoveDown(idx, id);
	}

	handleMoveUp(idx, id) {
		if (this.props.onMoveUp) this.props.onMoveUp(idx, id);
	}

	render() {
		const { classes, disabled, data = {}, t } = this.props;
		const { answers, animateNext, animatePrev, animationSettings, correctAnswers = [], correct, finished, saving } = data;

		return (
			<WorkformLayout
				data={data}
				saving={saving}
				question={data.question || ''}
				instruction={data.introduction || t('app.workforms.order-question.introduction')}
				loading={false}
				renderAnswers={() => (
					<div className={classes.wrapper}>
						{answers &&
							answers.map((answer, i) => (
								<div className={classes.dragRoot} key={answer.id}>
									<span className={classes.number}>
										{i <= 10 && '0'}
										{i + 1}
									</span>

									<div
										className={classNames(
											classes.card,
											{ [classes.first]: i == 0 },
											{
												[classes.isCorrect]:
													correctAnswers.some((i) => i.id === answer.id) || (correct && finished),
											},
											{ [classes.animateNext]: animateNext == answer.id },
											{ [classes.animatePrev]: animatePrev == answer.id },
											{ [classes[`gapnext${animationSettings.gap}`]]: animateNext == answer.id },
											{ [classes[`gap${animationSettings.gap}`]]: animatePrev == answer.id }
										)}
									>
										<div className={classes.text}>{answer.title}</div>
										{!correctAnswers.some((i) => i.id === answer.id) && (
											<div className={classes.arrows}>
												<span>
													{i != 0 && <ArrowUp onClick={() => this.handleMoveUp(i, answer.id)} />}
													{i != answers.length - 1 && (
														<ArrowDown onClick={() => this.handleMoveDown(i, answer.id)} />
													)}
												</span>
											</div>
										)}
									</div>
								</div>
							))}
						<div className={classes.buttons}>
							{!data.feedback ? (
								<Button
									variant="contained"
									color="primary"
									fullWidth
									className={classes.button}
									onClick={this.handleSubmit}
									disabled={disabled}
								>
									{data.buttonText || t('common.check-answer')}
									<LongArrow className={classes.longArrowRoot} />
								</Button>
							) : (
								<div
									className={classNames(classes.feedback, {
										[classes.activeFeedback]: data.feedback,
									})}
								>
									<FeedbackBox data={data} onFinished={this.handleOnFinish} outlined={true} />
								</div>
							)}
						</div>
					</div>
				)}
			/>
		);
	}
}

export default compose(withStyles(styles, { name: 'AppOrderQuestion' }), withTranslation())(OrderQuestion);
