'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.getBoxId = exports.isListView = exports.APP_BOXES_LAYOUT_STATE_ROOT = undefined;

var _reselect = require('reselect');

var _selectors = require('../selectors');

var APP_BOXES_LAYOUT_STATE_ROOT = exports.APP_BOXES_LAYOUT_STATE_ROOT = 'boxes';

var getState = (0, _reselect.createSelector)([_selectors.getCoreState], function (coreState) {
    return coreState[APP_BOXES_LAYOUT_STATE_ROOT];
});

var isListView = exports.isListView = (0, _reselect.createSelector)([getState], function (state) {
    return state.listView;
});

var getBoxId = exports.getBoxId = (0, _reselect.createSelector)([getState], function (state) {
    return state.boxId;
});