import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { styles } from './styles';
import Bubble from './Bubble';

class ChatBubble extends React.PureComponent {
    render() {
        const { classes, data, animate, show } = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.bubbles}>
                    {data.bubbles &&
                        data.bubbles.map((item, idx) => (
                            <div key={item.id}>
                                <div
                                    className={classNames(
                                        classes.bubble,
                                        { [classes.animate]: animate },
                                        { [classes.showAnimate]: show },
                                        {
                                            [classes.rightPosition]:
                                                item.position == 'rechts',
                                        }
                                    )}
                                    style={{
                                        transition: `opacity .3s ${
                                            show ? 0 : idx + 1
                                        }s, transform .3s ${
                                            show ? 0 : idx + 1
                                        }s`,
                                    }}
                                >
                                    <Bubble
                                        data={{ ...data }}
                                        position={item.position}
                                    >
                                        {item.chatText}
                                    </Bubble>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(ChatBubble);
