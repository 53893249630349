import React from 'react';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { LongArrow } from '@manakin/app-core';
import { LoadImage } from '@manakin/app-core';
import renderHTML from 'react-render-html';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(
    (theme) =>
        createStyles({
            longArrow: {
                transform: 'translateX(0)',
                transition: 'transform .2s',
                '& .arrow': {
                    background: theme.palette.getContrastText(
                        theme.manakin.defaultWorkformColor[500]
                    ),
                },
            },
            root: {
                opacity: 1,
                cursor: 'e-resize',
                justifyContent: 'space-between',
                '&:hover': {
                    '& $longArrow': {
                        transform: 'translateX(5px)',
                    },
                },
                [theme.breakpoints.up('md')]: {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                },
            },
            header: {
                ...theme.manakin.workformTitleFont,
                color: theme.palette.getContrastText(
                    theme.manakin.defaultWorkformColor[500]
                ),
                marginTop: '20px',
                '&.smallUnderline': {
                    '&:after': {
                        backgroundColor: theme.palette.getContrastText(
                            theme.manakin.defaultWorkformColor[500]
                        ),
                    },
                },
                [theme.breakpoints.down('md')]: {
                    padding: 0,
                    marginBottom: '20px',
                    marginTop: 0,
                },
            },
            subHeader: {
                color: theme.palette.getContrastText(
                    theme.manakin.defaultWorkformColor[500]
                ),
                '&.smallUnderline': {
                    '&:after': {
                        backgroundColor: theme.palette.getContrastText(
                            theme.manakin.defaultWorkformColor[500]
                        ),
                    },
                },
            },
            contentColor: {
                color: theme.palette.getContrastText(
                    theme.manakin.defaultWorkformColor[500]
                ),
            },
            content: {
                margin: 0,
            },
            steps: {
                padding: 0,
                [theme.breakpoints.down('md')]: {
                    margin: 0,
                    fontSize: '20px',
                },
            },
            column: {
                width: '100%',
                marginBottom: '30px',
                [theme.breakpoints.up('md')]: {
                    width: '30%',
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    marginBottom: '0',
                },
            },
            image: {
                height: '220px',
                [theme.breakpoints.up('md')]: {
                    height: '440px',
                },
            },
            primary: {
                '& $contentColor': {
                    color: theme.palette.getContrastText(
                        theme.manakin.primaryWorkformColor[500]
                    ),
                    '&.smallUnderline': {
                        '&:after': {
                            backgroundColor: theme.palette.getContrastText(
                                theme.manakin.primaryWorkformColor[500]
                            ),
                        },
                    },
                },
                '& $longArrow': {
                    '& .arrow': {
                        background: theme.palette.getContrastText(
                            theme.manakin.primaryWorkformColor[500]
                        ),
                    },
                },
            },
            secondary: {
                '& $contentColor': {
                    color: theme.palette.getContrastText(
                        theme.manakin.secondaryWorkformColor[500]
                    ),
                    '&.smallUnderline': {
                        '&:after': {
                            backgroundColor: theme.palette.getContrastText(
                                theme.manakin.secondaryWorkformColor[500]
                            ),
                        },
                    },
                },
                '& $longArrow': {
                    '& .arrow': {
                        background: theme.palette.getContrastText(
                            theme.manakin.secondaryWorkformColor[500]
                        ),
                    },
                },
            },
            noBG: {
                backgroundColor: 'white',
                '& $contentColor': {
                    color: theme.manakin.defaultContentColor[500],
                    '&.smallUnderline': {
                        '&:after': {
                            backgroundColor:
                                theme.manakin.defaultContentColor[500],
                        },
                    },
                },
                '& $longArrow': {
                    '& .arrow': {
                        background: theme.manakin.defaultContentColor[500],
                    },
                },
            },
        }),
    {
        name: 'appRoadmapStep',
    }
);

const Step = (props) => {
    const { data = {}, idx, steps, backgroundColor } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div
            className={classNames(
                classes.root,
                { [classes.primary]: backgroundColor == 'PRIMARY' },
                { [classes.secondary]: backgroundColor == 'SECONDARY' },
                { [classes.noBG]: backgroundColor == 'NONE' }
            )}
        >
            <div className={classes.column}>
                <Typography
                    component="h2"
                    variant="h2"
                    className={classNames(
                        classes.header,
                        classes.steps,
                        classes.contentColor
                    )}
                >
                    {idx + 1} / {steps.length}
                </Typography>
                <Typography
                    component="p"
                    variant="subtitle1"
                    className={classNames(
                        'smallUnderline',
                        classes.subHeader,
                        classes.contentColor
                    )}
                >
                    {data.introduction || t("app.workforms.roadmap.introduction")}
                </Typography>
                <Typography
                    component="h2"
                    variant="h2"
                    className={classNames(classes.header, classes.contentColor)}
                >
                    {data.title || ''}
                </Typography>
                <LongArrow className={classes.longArrow} />
            </div>
            <div className={classNames(classes.image, classes.column)}>
                <LoadImage
                    relative={true}
                    src={data.image ? data.image.url || '' : ''}
                    asset={data.image}
                    enableFocusPoint={true}
                    placeholder={
                        data.image ? data.image.thumbnailUrl || '' : ''
                    }
                />
            </div>
            <div className={classes.column}>
                <Typography
                    component="div"
                    variant="body1"
                    className={classNames(
                        classes.content,
                        classes.contentColor
                    )}
                >
                    {renderHTML(data.text || '')}
                </Typography>
            </div>
        </div>
    );
};

export default Step;
