import React from 'react';
import Grid from '@material-ui/core/Grid/Grid';
import getDndContext from '../HTML5BackendContext/HTML5BackendContext';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext, DropTarget } from 'react-dnd';
import MultiBackend, { Preview } from 'react-dnd-multi-backend';

const styles = (theme) => ({});

const itemSpecs = {};

function collectDrop(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
    };
}

class DragContainer extends React.PureComponent {
    static generatePreview(type, item, style) {
        return (
            <div
                style={{
                    ...style,
                    width: '100px',
                    padding: '16px 32px',
                    boxShadow: '1px 1px 3px rgba(0,0,0,0.3)',
                    borderRadius: '4px',
                    backgroundColor: '#FAFAFA',
                }}
            >
                Item
            </div>
        );
    }

    render() {
        const { children } = this.props;
        return (
            <React.Fragment>
                {children}
                <Preview generator={DragContainer.generatePreview} />
            </React.Fragment>
        );
    }
}

export default compose(
    getDndContext(),
    DropTarget(
        (props) => {
            return props.target ? props.target : 'container';
        },
        itemSpecs,
        collectDrop
    ),
    withStyles(styles)
)(DragContainer);
