"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _styles = require("@material-ui/core/styles");

var _actions = require("@manakin/app-core/actions");

var _reactRouterDom = require("react-router-dom");

var _recompose = require("recompose");

var _reactRedux = require("react-redux");

var _Button = require("@material-ui/core/Button");

var _Button2 = _interopRequireDefault(_Button);

var _Close = require("@material-ui/icons/Close");

var _Close2 = _interopRequireDefault(_Close);

var _Done = require("@material-ui/icons/Done");

var _Done2 = _interopRequireDefault(_Done);

var _selectors = require("@manakin/app-core/Boxes/selectors");

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

var _AccessTime = require("@material-ui/icons/AccessTime");

var _AccessTime2 = _interopRequireDefault(_AccessTime);

var _styles2 = require("./styles");

var _appCore = require("@manakin/app-core");

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var mapStateToProps = function mapStateToProps(state) {
	return {
		config: state.config,
		boxId: (0, _selectors.getBoxId)(state)
	};
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
	return {
		onHideAppBar: function onHideAppBar(data) {
			return dispatch((0, _actions.hideAppBar)(data));
		}
	};
};

var ElementOverlay = function (_React$PureComponent) {
	_inherits(ElementOverlay, _React$PureComponent);

	function ElementOverlay() {
		var _ref;

		var _temp, _this, _ret;

		_classCallCheck(this, ElementOverlay);

		for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
			args[_key] = arguments[_key];
		}

		return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = ElementOverlay.__proto__ || Object.getPrototypeOf(ElementOverlay)).call.apply(_ref, [this].concat(args))), _this), _this.handleClick = function () {
			if (_this.props.onClick) _this.props.onClick();
		}, _this.handleBackButtonClick = function () {
			if (_this.props.onBackButtonClick) _this.props.onBackButtonClick();
		}, _this.closeEvents = function () {
			window.ReadSpeaker && window.ReadSpeaker.ui && window.ReadSpeaker.ui.getActivePlayer() && window.ReadSpeaker.ui.getActivePlayer().stop();
		}, _temp), _possibleConstructorReturn(_this, _ret);
	}

	_createClass(ElementOverlay, [{
		key: "componentDidMount",
		value: function componentDidMount() {
			var onHideAppBar = this.props.onHideAppBar;

			onHideAppBar({ path: this.props.location.pathname });
		}
	}, {
		key: "render",
		value: function render() {
			var _classNames;

			var _props = this.props,
			    _props$config = _props.config,
			    config = _props$config === undefined ? {} : _props$config,
			    _props$controls = _props.controls,
			    controls = _props$controls === undefined ? true : _props$controls,
			    children = _props.children,
			    _props$background = _props.background,
			    background = _props$background === undefined ? "SECONDARY" : _props$background,
			    classes = _props.classes,
			    boxId = _props.boxId,
			    _props$fullWidth = _props.fullWidth,
			    fullWidth = _props$fullWidth === undefined ? false : _props$fullWidth,
			    _props$title = _props.title,
			    title = _props$title === undefined ? null : _props$title,
			    _props$backButton = _props.backButton,
			    backButton = _props$backButton === undefined ? false : _props$backButton,
			    _props$color = _props.color,
			    color = _props$color === undefined ? "dark" : _props$color,
			    _props$variant = _props.variant,
			    variant = _props$variant === undefined ? "default" : _props$variant,
			    _props$timer = _props.timer,
			    timer = _props$timer === undefined ? "" : _props$timer,
			    _props$items = _props.items,
			    items = _props$items === undefined ? null : _props$items,
			    closeLocation = _props.closeLocation,
			    _props$customHeight = _props.customHeight,
			    customHeight = _props$customHeight === undefined ? false : _props$customHeight,
			    _props$customControls = _props.customControls,
			    customControls = _props$customControls === undefined ? false : _props$customControls,
			    t = _props.t;
			var _config$general = config.general,
			    general = _config$general === undefined ? {} : _config$general;


			return _react2.default.createElement(
				"div",
				{
					className: (0, _classnames2.default)(classes.root, (_classNames = {}, _defineProperty(_classNames, classes.primary, background == "PRIMARY"), _defineProperty(_classNames, classes.secondary, background == "SECONDARY"), _defineProperty(_classNames, classes.white, background == "WHITE"), _defineProperty(_classNames, classes.transparent, background == "TRANSPARENT"), _classNames), _defineProperty({}, classes.fullWidth, fullWidth), _defineProperty({}, classes.customHeight, customHeight), _defineProperty({}, classes.trainer, variant == "trainer"))
				},
				_react2.default.createElement(
					"div",
					{ className: classes.top },
					backButton && _react2.default.createElement(_appCore.BackButton, { onClick: this.handleBackButtonClick }),
					title && config && config.pages && config.pages.appElementOverlay && config.pages.appElementOverlay.showHeaderTitle && _react2.default.createElement(
						"p",
						{
							className: (0, _classnames2.default)(classes.title, _defineProperty({}, classes.lightColor, color == "light"))
						},
						title
					),
					!general.hideTimer && timer && _react2.default.createElement(
						_react2.default.Fragment,
						null,
						(variant == "trainer" || variant == "timer") && _react2.default.createElement(
							"div",
							{ className: classes.timer },
							_react2.default.createElement(_AccessTime2.default, null),
							_react2.default.createElement(
								"span",
								{ className: classes.moment },
								(0, _moment2.default)().minute(0).second(timer).format("mm:ss")
							)
						)
					),
					controls && _react2.default.createElement(
						_reactRouterDom.Link,
						{
							className: classes.link,
							to: closeLocation || "/box/" + boxId
						},
						_react2.default.createElement(
							_Button2.default,
							{
								variant: "contained",
								color: "primary",
								"aria-label": t("common.close"),
								className: classes.closeButton,
								onClick: this.closeEvents
							},
							_react2.default.createElement(_Close2.default, null)
						)
					),
					customControls && _react2.default.createElement(
						"div",
						{
							className: classes.link,
							onClick: this.handleClick
						},
						_react2.default.createElement(
							_Button2.default,
							{
								variant: "contained",
								color: "primary",
								"aria-label": t("common.close"),
								className: classes.closeButton
							},
							_react2.default.createElement(_Close2.default, null)
						)
					)
				),
				_react2.default.createElement("div", { className: classes.left }),
				_react2.default.createElement("div", { className: classes.right }),
				_react2.default.createElement(
					"div",
					{ className: classes.bottom },
					items && items.filter(function (item) {
						return item;
					}).map(function (item) {
						return _react2.default.createElement(
							"div",
							{ key: item.id, className: classes.checkBox },
							item.correct === false && _react2.default.createElement(_Close2.default, null),
							item.correct === true && _react2.default.createElement(_Done2.default, null)
						);
					})
				),
				_react2.default.createElement(
					"div",
					{ className: classes.content },
					children
				)
			);
		}
	}]);

	return ElementOverlay;
}(_react2.default.PureComponent);

exports.default = (0, _recompose.compose)(_reactRouterDom.withRouter, (0, _reactI18next.withTranslation)(), (0, _styles.withStyles)(_styles2.styles, { name: "AppElementOverlay" }), (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps))(ElementOverlay);