import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
    (theme) =>
        createStyles({
            root: {
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            content: {
                display: 'flex',
                padding: '20px',
                flexDirection: 'column',
                alignItems: 'center',
            },
            button: {
                marginTop: '20px',
            },
        }),
    {
        name: 'AppInvitation',
    }
);
