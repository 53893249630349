import React from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import DownloadIcon from '@manakin/core/icons/Download';
import JsFileDownloader from 'js-file-downloader';
var FileSaver = require('file-saver');

const useStyles = makeStyles(
	createStyles((theme) => ({
		title: {
			margin: 0,
			color: theme.palette.getContrastText(theme.manakin.LessonButtonColor[500]),
		},
		button: {
			padding: theme.spacing(3),
			width: '100%',
			display: 'flex',
			justifyContent: 'space-between',
			margin: theme.spacing(2, 0),
			textDecoration: 'none',
			backgroundColor: theme.manakin.LessonButtonColor[500],
			color: theme.palette.getContrastText(theme.manakin.LessonButtonColor[500]),
			cursor: 'pointer',
			transition: 'background-color .3s',
			'&:hover': {
				backgroundColor: theme.manakin.LessonButtonColor[400],
				'&$title': {
					color: theme.palette.getContrastText(theme.manakin.LessonButtonColor[400]),
				},
			},
		},
	})),
	{ name: 'AppAssignmentDownloadButton' }
);

const DownloadButton = (props) => {
	const { title = '', file } = props;
	const classes = useStyles();

	const handleClick = () => {
		if (!file) return;
		new JsFileDownloader({
			url: file.url,
			filename: file.name || 'download',
		}).catch(function (error) {
			console.log('ERROR downloading file', error);
		});
	};

	return (
		<span className={classes.button} onClick={handleClick}>
			<Typography
				component="span"
				variant="body1"
				className={classes.title}
			>
				{title}
			</Typography>
			<DownloadIcon />
		</span>
	);
};

export default DownloadButton;
