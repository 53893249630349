import React from "react";
import { createStyles, makeStyles } from "@material-ui/core";
import { Header, Content } from "./parts";
import { GQL_FETCH_CURRENT_APP_USER } from "./graphql";
import { useQuery } from "react-apollo";
import { Loader, ElementOverlay } from "@manakin/app-core";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";

export const useStyles = makeStyles((theme) =>
		createStyles({
			root: {
				minHeight: "100vh",
				paddingBottom: "100px",
				marginLeft: "auto",
				marginRight: "auto",
				width: "100%",
			},
		}),
	{ name: "AppRatingRequestsOverview" }
);

const RatingRequestsOverview = (props) => {
	const classes = useStyles();
	const history = useHistory();
	const { t } = useTranslation();

	// Queries
	const { data, loading, error } = useQuery(GQL_FETCH_CURRENT_APP_USER);

	const handleGoBack = () => {
		history.push("/progress-dashboard");
	};

	if (loading) return <Loader fullScreen/>;
	if (error || data.errors) return (
		<div className={classes.root}>
			{t("common.loading-user-failed")}
		</div>
	);

	return (
		<ElementOverlay
			fullWidth={true}
			title={t("app.rating-requests.title")}
			onClick={handleGoBack}
			controls={false}
			customControls={true}
		>
			<div className={classes.root}>
				<Header user={data.appUser || {}}/>
				<Content/>
			</div>
		</ElementOverlay>
	);
};

export default RatingRequestsOverview;
