'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = exports.styles = function styles(theme) {
    var _playButton;

    return {
        videoContainer: {
            position: 'relative',
            overflow: 'hidden',
            width: '100%',
            height: '100%'
            // maxHeight: '100vh',
            // minHeight: '250px',
        },
        fullScreen: {
            position: 'fixed',
            top: '0',
            left: '0',
            zIndex: 100
        },
        loadingState: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: 'white',
            left: 0,
            top: 0,
            zIndex: 99,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        // overflow: {
        //     '& $playerContainer': {
        //         position: 'relative',
        //         top: 0,
        //         left: 0,
        //         right: 0,
        //         bottom: 0,
        //         width: '100%',
        //         height: '100%',
        //         paddingBottom: 0,
        //     },
        //     '& $videoRoot': {
        //         width: '200% !important',
        //         height: '250% !important',
        //         transform: 'translate(-50%, -50%)',
        //         top: ' 50%',
        //         left: '50%',
        //     },
        // },
        fullWidth: {
            '& $videoRoot': {
                width: '200% !important',
                height: '250% !important',
                transform: 'translate(-50%, -50%)',
                top: ' 50%',
                left: '50%'
            }
        },
        playerContainer: {
            paddingBottom: '56.25%'
        },
        videoRoot: {
            position: 'absolute',
            top: 0,
            left: 0,
            pointerEvents: 'none',
            opacity: 0,
            transition: 'opacity 1s'
        },
        ready: {
            opacity: 1
        },
        controls: {
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            bottom: '-10px',
            left: 0,
            width: '100%',
            padding: '0 50px 0px 30px',
            opacity: 0,
            transform: 'translateY(20px)',
            pointerEvents: 'none',
            transition: 'transform .3s, opacity .3s'
        },
        progressBar: {
            width: '100%',
            transform: 'translateY(-4px)',
            cursor: 'default',
            '&> input[type=range]::-webkit-slider-runnable-track': {
                background: theme.manakin.primaryPaletteColor[500]
            }
        },
        showControls: {
            transform: 'translateY(0)',
            opacity: 1,
            pointerEvents: 'visible'
        },
        playButton: (_playButton = {
            zIndex: 10,
            position: 'absolute',
            top: 'calc(50% - 50px / 2)',
            left: 'calc(50% - 50px / 2)'
        }, _defineProperty(_playButton, theme.breakpoints.up('md'), {
            top: 'calc(50% - 96px / 2)',
            left: 'calc(50% - 96px / 2)'
        }), _defineProperty(_playButton, '& $icon', _defineProperty({
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            backgroundColor: 'black',
            color: 'white',
            boxShadow: '0 0 10px rgba(0, 0, 0, .5)'
        }, theme.breakpoints.up('md'), {
            width: '96px',
            height: '96px'
        })), _playButton),
        playButtonIcon: {
            fontSize: '48px'
        },
        zoomIn: {
            animation: 'zoomIn .3s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards'
        },
        zoomOut: {
            animation: 'zoomOut .6s cubic-bezier(0.68, -0.55, 0.27, 1.55) .1s forwards'
        },
        icon: {
            width: '64px',
            height: '64px',
            color: 'white',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            display: 'none'
        },
        show: {
            display: 'flex'
        },
        button: {
            position: 'absolute',
            top: '200px',
            left: 0,
            opacity: 0,
            transform: 'translateY(-100%)',
            transition: 'opacity .3s, transform .3s'
        },
        showButton: {
            transform: 'translateY(0)',
            opacity: 1,
            zIndex: 100
        },
        loader: {
            position: 'absolute',
            zIndex: 999,
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)'
        },
        hide: {
            display: 'none'
        },
        activeIcon: {
            color: theme.manakin.defaultActiveColor[500]
        }
    };
};