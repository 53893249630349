import React, { useCallback, useState } from 'react';
import { ElementOverlay, BPVBDownloadButton, Loader } from "@manakin/app-core";
import Layout from "./Layout";
import Introduction from "./Introduction";
import { withForm } from "@manakin/core";
import { compose } from "recompose";
import Form from "./Form";
import {
	GQL_ADD_RESULT,
	GQL_UPLOAD_ASSET,
	GQL_RATING_REQUEST, GQL_RATING_REQUESTS,
} from './graphql';
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
import { useHistory, useParams } from "react-router-dom";
import { showSnackbarMessage } from "@manakin/core/actions";
import download from "downloadjs";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

const form = {};

const BPVBRapport = (props) => {
	const { form } = props;
	const params = useParams();
	const history = useHistory();
	const dispatch = useDispatch();
	const { t } = useTranslation();
    const [ lastPoRatingRequest, setLastPoRatingRequest ] = useState({});

	const onSnackbarMessage = useCallback(
		(message, variant) =>
			dispatch(
				showSnackbarMessage({
					text: message || t("common.something-went-wrong"),
					variant: variant || "error",
				})
			),
		[dispatch]
	);

    const [fetchRatingRequests] = useLazyQuery(GQL_RATING_REQUESTS, {
        onCompleted: (data)=> {
            if (data && data.ratingRequests && data.ratingRequests.length > 0) {
                // Set the last rating request as the most recent
                setLastPoRatingRequest(data.ratingRequests.pop());
            }
        }
    });

	const { data, loading } = useQuery(GQL_RATING_REQUEST, {
		variables: {
			id: params.id,
		},
		onCompleted: (data) => {
			if (data && data.ratingRequestById) {
				const request = data.ratingRequestById;
                fetchRatingRequests({
                    variables: {
                        programId: request.program && request.program.id,
                        elementId: request.element && request.element.id,
                        studentId: request.student && request.student.id,
                    }
                });
            }
		}
	});
	const { ratingRequestById = {} } = data || {};

	const [uploadAsset] = useMutation(GQL_UPLOAD_ASSET);
	const [mutate] = useMutation(GQL_ADD_RESULT);

	//functions
	const handleClick = () => {
		history.push("/");
	};

	const handleMutation = (assetId, formData) => {
		if (assetId) {
			mutate({
				variables: {
					input: {
                        explanation: formData.explanation || '',
                        ranking: formData.ranking ? formData.ranking : (lastPoRatingRequest && lastPoRatingRequest.result) ? lastPoRatingRequest.result.ranking : 'GOOD',
                        ratingRequestId: params.id,
						signatureAssetId: assetId,
					},
				},
			}).then((r) => {
				if (!r.errors) {
					onSnackbarMessage(t("app.bpv-report.send-success"), "success");
					history.push("/");
				} else {
					onSnackbarMessage();
				}
			});
		}
	};

	const handleSubmit = (image) => {
		form.onSubmit().then((formData) => {
			uploadAsset({
				variables: {
					file: image,
				},
				context: { hasUpload: true },
			}).then((result) => {
				if (
					result.data &&
					result.data.uploadAsset &&
					result.data.uploadAsset.asset &&
					result.data.uploadAsset.asset.id
				) {
					handleMutation(result.data.uploadAsset.asset.id, formData);
				}
			});
		});
	};

	const handleDownload = () => {
		download(`/api/pdf/bpv/report/${params.id}/Rapport.pdf`);
	};

	if (loading) return <Loader fullScreen />;
	return (
		<ElementOverlay
			fullWidth={true}
			title={t("app.bpv-report.title")}
			onClick={handleClick}
			controls={false}
			customControls={true}
		>
			<Layout>
				<Introduction
					user={ratingRequestById.student}
					program={ratingRequestById.program}
				/>
				<BPVBDownloadButton
					program={ratingRequestById.program}
					onClick={handleDownload}
				/>
                <Form
                    form={form}
                    ratingRequest={ratingRequestById}
                    lastPoRatingRequest={lastPoRatingRequest}
                    onSubmit={handleSubmit} />
			</Layout>
		</ElementOverlay>
	);
};

export default compose(withForm(form))(BPVBRapport);
