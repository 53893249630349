'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.GQL_FETCH_SCHOOL = exports.GQL_FETCH_GROUPS_FOR_SCHOOL_MANAGER = exports.GQL_FETCH_GROUPS = undefined;

var _templateObject = _taggedTemplateLiteral(['\n    query groups($filter: GroupFilter) {\n        groups(filter: $filter) {\n            groups {\n                id\n            }\n        }\n    }\n'], ['\n    query groups($filter: GroupFilter) {\n        groups(filter: $filter) {\n            groups {\n                id\n            }\n        }\n    }\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n\tquery schoolManagerGroups {\n\t\tschoolManagerGroups {\n\t\t\tgroups {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\ttype\n\t\t\t}\n\t\t}\n\t}\n'], ['\n\tquery schoolManagerGroups {\n\t\tschoolManagerGroups {\n\t\t\tgroups {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\ttype\n\t\t\t}\n\t\t}\n\t}\n']),
    _templateObject3 = _taggedTemplateLiteral(['\n    query school($id: ID!) {\n        school(id: $id) {\n            id\n            name\n            classes {\n                id\n                name\n                managers {\n                    id\n                }\n                teachers {\n                    id\n                }\n                products {\n                    id\n                    name\n                    program {\n                        id\n                        name\n                    }\n                }\n            }\n        }\n    }\n'], ['\n    query school($id: ID!) {\n        school(id: $id) {\n            id\n            name\n            classes {\n                id\n                name\n                managers {\n                    id\n                }\n                teachers {\n                    id\n                }\n                products {\n                    id\n                    name\n                    program {\n                        id\n                        name\n                    }\n                }\n            }\n        }\n    }\n']);

var _graphqlTag = require('graphql-tag');

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_GROUPS = exports.GQL_FETCH_GROUPS = (0, _graphqlTag2.default)(_templateObject);

var GQL_FETCH_GROUPS_FOR_SCHOOL_MANAGER = exports.GQL_FETCH_GROUPS_FOR_SCHOOL_MANAGER = (0, _graphqlTag2.default)(_templateObject2);

var GQL_FETCH_SCHOOL = exports.GQL_FETCH_SCHOOL = (0, _graphqlTag2.default)(_templateObject3);