import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = (theme) => ({
    root: {
        position: 'relative',
        display: 'flex',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(7),
        paddingRight: theme.spacing(4),
    },
});

class ModuleContent extends React.PureComponent {
    render() {
        const { classes, children, className: classNameProp } = this.props;
        const className = classNames(classes.root, classNameProp);
        return <div className={className}>{children}</div>;
    }
}

export default withStyles(styles, { name: 'ManakinCoreModuleContent' })(
    ModuleContent
);
