import React, { useState, useEffect } from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import nlLocale from 'date-fns/locale/nl';
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker,
} from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import createUuid from 'uuid/v4';
import moment from 'moment';
import InputLabel from '@material-ui/core/InputLabel';

const DatePicker = (props) => {
    const {
        form,
        name,
        initialValue,
        defaultValue = '',
        loading = false,
        placeholder = 'dd-mm-yyyy',
        label = '',
        margin = 'normal',
        maxDate = '3000-01-01',
    } = props;
    const { errors, schema } = form;
    const uuid = createUuid();
    const selectedDate = new Date(defaultValue);

    //state hooks
    const [value, setValue] = useState(initialValue);

    //effect hooks
    useEffect(() => {
        if (!loading) {
            setValue(initialValue);
            form.onFieldChange({ key: name, value: initialValue });
        }
    }, [initialValue, loading]);

    const handleDateChange = (date) => {
        form.onFieldChange({
            key: name,
            value: moment(date).format('YYYY-MM-DD HH:mm'),
        });
        setValue(date);
    };

    return (
        <FormControl
            required={(schema[name] || {}).required}
            margin={margin}
            error={errors[name] && errors[name].length > 0}
        >
            <InputLabel shrink={true}>{label}</InputLabel>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nlLocale}>
                <KeyboardDateTimePicker
                    maxDate={maxDate}
                    variant="inline"
                    margin="normal"
                    id={uuid}
                    format="yyyy/MM/dd HH:mm"
                    initialFocusedDate={selectedDate}
                    value={value}
                    placeholder={placeholder}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>
            {errors[name] &&
                errors[name].length > 0 &&
                errors[name].map((e, i) => (
                    <FormHelperText key={i}>{e}</FormHelperText>
                ))}
        </FormControl>
    );
};

export default DatePicker;
