import React from 'react';
import { Elements } from '@manakin/app-core';

class ElementsLayout extends React.PureComponent {
	render() {
		const { elementResults, items, boxResults } = this.props;
		return <Elements boxResults={boxResults} items={items} elementResults={elementResults} />;
	}
}

export default ElementsLayout;
