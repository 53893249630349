'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.selectGroup = selectGroup;
var SELECT_GROUP = exports.SELECT_GROUP = '@manakin/app-core/SELECT_GROUP';

function selectGroup() {
    var group = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    return {
        type: SELECT_GROUP,
        payload: group
    };
}