import React, { useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core";
import { Header, Content } from "./parts";
import { GQL_FETCH_APP_USER, GQL_FETCH_PROGRAM } from "./graphql";
import { useQuery } from "react-apollo";
import { Loader } from "@manakin/app-core";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { setBackButton } from "@manakin/app-core/AppBar/actions";
import { setSuperUser } from "@manakin/authentication/actions";
import { getProgram } from "@manakin/app-core/ProgramsDropdown/selectors";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: "100vh",
		paddingBottom: "100px",
	},
}));

const AssignedStudent = (props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const location = useLocation();
	const { t } = useTranslation();

	const backButton = useCallback(
		(data) => dispatch(setBackButton(data)),
		[dispatch]
	);
	const superUser = useCallback(
		(id) => dispatch(setSuperUser(id)),
		[dispatch]
	);

	//selectors
	const program = useSelector((state) => getProgram(state));
	const params = useParams();

	//queries
	const { data: programData } = useQuery(GQL_FETCH_PROGRAM, {
		variables: {
			id: program,
		},
	});
	const { program: programObj = {} } = programData || {};
	const { data, loading, error } = useQuery(GQL_FETCH_APP_USER, {
		variables: {
			id: params.studentId,
		},
	});

	//effect hooks
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		backButton({
			show: true,
			location: location.pathname,
			url: "/progress-dashboard",
		});
		superUser(params.studentId);
	}, []);

	if (loading) return <Loader fullScreen />;
	if (error || data.errors)
		return (
			<div className={classes.root}>
				{t("app.assigned-student.user-loading-failed")}
			</div>
		);
	return (
		<div className={classes.root}>
			<Header user={data.appUser || {}} />
			<Content
				studentId={params.studentId}
				programName={programObj.name || ""}
			/>
		</div>
	);
};

export default AssignedStudent;
