import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
	(theme) =>
		createStyles({
			groupElements: {
				'@media (min-width: 700px)': {
					display: 'flex',
					justifyContent: 'space-between',
					'&>*': {
						flex: '0 0 auto',
						width: '300px',
					},
				},
			},
			selectField: {
				margin: theme.spacing(2, 0, 1, 0),
			},
			selectFieldPlaceholder: {
				margin: 0,
			},
			selectFieldValue: {
				margin: 0,
			},
			termsOfService: {
				marginTop: theme.spacing(4),
				'& a': {
					color: 'inherit',
				},
			},
			heading: {},
		}),
	{
		name: 'AppregistrationFields',
	}
);
