"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _NotificationListItem = require("./NotificationListItem");

var _NotificationListItem2 = _interopRequireDefault(_NotificationListItem);

var _core = require("@material-ui/core");

var _graphql = require("../graphql");

var _reactApollo = require("react-apollo");

var _reactRedux = require("react-redux");

var _actions = require("@manakin/core/actions");

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useStyles = (0, _core.makeStyles)(function (theme) {
	return {
		title: {
			marginBottom: "18px"
		}
	};
});

var NotificationList = function NotificationList(props) {
	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var data = props.data;

	var classes = useStyles();

	//dispatch functions
	var dispatch = (0, _reactRedux.useDispatch)();
	var showSnackbar = (0, _react.useCallback)(function (message, variant) {
		return dispatch((0, _actions.showSnackbarMessage)({
			text: message || t("common.save-failed"),
			variant: variant || "error"
		}));
	}, [dispatch]);

	//query

	var _useQuery = (0, _reactApollo.useQuery)(_graphql.GQL_FETCH_NOTIFICATION_SETTING, {
		variables: {
			id: data.id
		}
	}),
	    _useQuery$data = _useQuery.data,
	    notificationSettingData = _useQuery$data === undefined ? {} : _useQuery$data,
	    loading = _useQuery.loading;

	var _notificationSettingD = notificationSettingData.notificationSetting,
	    notificationSetting = _notificationSettingD === undefined ? {} : _notificationSettingD;

	var _ref = notificationSetting || {},
	    _ref$disabledOptions = _ref.disabledOptions,
	    disabledOptions = _ref$disabledOptions === undefined ? [] : _ref$disabledOptions;

	//mutation


	var _useMutation = (0, _reactApollo.useMutation)(_graphql.GQL_UPDATE_SETTING, {
		refetchQueries: ["notificationSetting"]
	}),
	    _useMutation2 = _slicedToArray(_useMutation, 2),
	    mutate = _useMutation2[0],
	    _useMutation2$ = _useMutation2[1],
	    mutationData = _useMutation2$.data,
	    error = _useMutation2$.error;

	//functions


	var handleChange = function handleChange(value, name) {
		var disabled = new Set(disabledOptions);
		value ? disabled.delete(name) : disabled.add(name);

		mutate({
			variables: {
				input: {
					disabledOptions: Array.from(disabled),
					notificationId: data.id
				}
			}
		}).catch(function (e) {
			showSnackbar();
		});
	};

	//effect hooks
	(0, _react.useEffect)(function () {
		if (mutationData && !mutationData.error) {
			showSnackbar(t("common.save-success"), "success");
		}
		if (error) {
			showSnackbar();
		}
	}, [mutationData, error]);

	return _react2.default.createElement(
		"div",
		null,
		_react2.default.createElement(
			_core.Typography,
			{ variant: "h5", className: classes.title },
			data.title
		),
		_react2.default.createElement(
			_core.Typography,
			null,
			data.description || ""
		),
		data.options.map(function (option, idx) {
			return _react2.default.createElement(_NotificationListItem2.default, {
				data: option,
				key: option,
				loading: loading,
				disabled: disabledOptions.some(function (e) {
					return e === option;
				}),
				onChange: handleChange
			});
		})
	);
};

exports.default = NotificationList;