import gql from 'graphql-tag';
import { appAssetWithThumbnail } from '@manakin/app-core/graphQlHelpers';

export const GQL_FETCH_FAQ = gql`
    query {
        faq {
            title
            description
            headerImage {
                ${appAssetWithThumbnail}
            }
            items {
                id
                question
                answer
            }
        }
    }
`;
