import gql from 'graphql-tag';
import { appAssetNoThumbnail } from '@manakin/app-core/graphQlHelpers';

export const GQL_FETCH_CMS_FACT_CARD = gql`
    query($id: ID!) {
        factCard(id: $id) {
            id
            title
            image {
                ${appAssetNoThumbnail}
            }
            file {
                ${appAssetNoThumbnail}
            }
        }
    }
`;