import React from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { TextField } from '@manakin/core';
import { withStyles } from '@material-ui/core/styles';
import { withForm } from '@manakin/core';
import { compose } from 'recompose';
import { useTranslation, withTranslation } from "react-i18next";

const styles = (theme) => ({
    root: {
        textAlign: 'center',
        width: '100%',
    },
    submit: {
        margin: `${theme.spacing(2)} auto 0`,
    },
});

const form = {
    email: {
        format: 'email',
        required: true,
    },
};

class ResetPasswordForm extends React.Component {
    render() {
        const { classes, form, t } = this.props;
        const { onSubmit } = form;

        return (
            <div className={classes.root}>
                <TextField
                    name="email"
                    label={t("common.fields.email-address.label")}
                    type="email"
                    form={form}
                    shrink={false}
                    hideAsterisk
                    onEnterPressed={onSubmit}
                />
                <FormControl margin="normal">
                    <Button
                        className={classes.submit}
                        color="primary"
                        variant="contained"
                        onClick={onSubmit}
                    >
                        {t("authentication.reset-password.action")}
                    </Button>
                </FormControl>
            </div>
        );
    }
}

export default compose(
    withForm(form),
    withTranslation(),
    withStyles(styles, { name: 'AuthenticationResetPasswordForm' })
)(ResetPasswordForm);
