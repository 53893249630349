import React, { useEffect, useCallback } from "react";
import { GQL_FETCH_SELF_EVALUATION } from "./graphql";
import { ElementOverlay, withWorkforms } from "@manakin/app-core";
import { getBoxId } from "@manakin/app-core/Boxes/selectors";
import { changeColor } from "@manakin/app-core/ColorService/actions";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EvaluationView from "./EvaluationView";
import { useTranslation } from 'react-i18next';
import { useViewCounting } from "@manakin/hooks";

let mounted = false;
const EvaluationContainer = (props) => {
	const { workformsData, location } = props;
	const params = useParams();
	const history = useHistory();
	const { t } = useTranslation();
	const { viewElement } = useViewCounting();

	//useSelector
	const boxId = useSelector((state) => getBoxId(state));
	const config = useSelector((state) => state.config) || {};

	//dispatch functions
	const dispatch = useDispatch();
	const handleChangeColor = useCallback(
		(styleSheet) => dispatch(changeColor(styleSheet)),
		[dispatch]
	);

	//queries
	const { data = {}, loading } = useQuery(GQL_FETCH_SELF_EVALUATION, {
		variables: { id: params.elementId },
	});
	const { selfEvaluation = {} } = data || {};

	//effect hooks
	useEffect(() => {
		if (!loading) {
			const options = {
				showFeedback: true,
				nextButton: false,
			};

			if (data.selfEvaluation) {
				handleChangeColor({
					...data.selfEvaluation.styleSheet,
					location: location.pathname,
				});

				data.selfEvaluation.workforms &&
					workformsData.loadWorkforms({
						...data.selfEvaluation,
						options: options,
						elementId: params.elementId,
					});

				viewElement(params.elementId);
			}
		}
	}, [loading]);

	const handleFinish = (data) => {
		workformsData.saveWorkform({
			...data,
			elementId: params.elementId,
			config: config,
			element: "selfEvaluation",
		});
	};

	const handleReset = () => {
		workformsData.resetElement(params.elementId);
	};

	const handleClose = () => {
		history.push(`/box/${boxId}`);
	};

	return (
		<ElementOverlay
			fullWidth={true}
			title={t("common.element-types.self-evaluation")}
		>
			<EvaluationView
				{...workformsData}
				evaluationData={selfEvaluation}
				onFinish={handleFinish}
				onReset={handleReset}
				onClose={handleClose}
				preview={false}
			/>
		</ElementOverlay>
	);
};

export default withWorkforms()(EvaluationContainer);
