import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { findDOMNode } from 'react-dom';
import {
    DragSource,
    DropTarget,
    ConnectDragSource,
    ConnectDropTarget,
    DragSourceMonitor,
    DropTargetMonitor,
} from 'react-dnd';

const styles = (theme) => ({
    root: {
        width: '100%',
    },
    small: {
        width: 'calc(50% - 10px)',
        display: 'inline-block',
        margin: '0 5px',
    },
});

let parent = null;
let initialDragIndex = null;
let source = null;
let payload = null;
let removeCallback = null;

const cardSource = {
    canDrag(props) {
        return !props.disabled;
    },
    beginDrag(props) {
        parent = props.parent;
        initialDragIndex = props.index;
        source = props.id;
        payload = props.payload;
        removeCallback = props.onRemove && props.onRemove.bind(null, props.id);

        return {
            id: props.id,
            index: props.index,
        };
    },
    isDragging(props, monitor) {
        return props.id === monitor.getItem().id;
    },
};

const cardTarget = {
    hover(props, monitor, component) {
        if (!component) return null;
        const node = component.getNode();
        if (!node) return null;

        const exists =
            props && props.items
                ? props.items.find((x) => x.dragId == source)
                : true;
        if (!exists) return;

        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;
        if (
            dragIndex === hoverIndex &&
            (parent ? parent == props.parent : true)
        )
            return;
        const hoverBoundingRect = node.getBoundingClientRect();
        const hoverMiddleY =
            (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const clientOffset = monitor.getClientOffset();
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;
        if (props.parent == parent || parent == null) {
            props.moveCard(
                initialDragIndex,
                hoverIndex,
                source,
                payload,
                removeCallback
            );
            initialDragIndex = hoverIndex;
        }

        monitor.getItem().index = hoverIndex;
    },
    drop(props, monitor) {
        if (props.enableDrop && (parent ? parent != props.parent : false)) {
            const hoverIndex = props.index;
            props.moveCard(0, hoverIndex, source, payload, removeCallback);
            monitor.getItem().index = hoverIndex;
        }
    },
};

function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        isDragging: monitor.isDragging(),
    };
}

function collectDrop(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
    };
}

class DragCard extends React.PureComponent {
    render() {
        const {
            classes,
            connectDragSource,
            connectDragPreview,
            connectDropTarget,
            children,
            isDragging,
            small = false,
        } = this.props;

        return (
            <div
                className={classNames(classes.root, { [classes.small]: small })}
            >
                {children(
                    connectDragSource,
                    connectDragPreview,
                    connectDropTarget,
                    isDragging
                )}
            </div>
        );
    }
}

export default compose(
    DragSource(
        (props) => {
            return props.target ? props.target : 'container';
        },
        cardSource,
        collect
    ),
    DropTarget(
        (props) => {
            return props.target ? props.target : 'container';
        },
        cardTarget,
        collectDrop
    ),
    withStyles(styles)
)(DragCard);
