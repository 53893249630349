'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styles = require('@material-ui/core/styles');

var _Table = require('@material-ui/core/Table');

var _Table2 = _interopRequireDefault(_Table);

var _TableBody = require('@material-ui/core/TableBody');

var _TableBody2 = _interopRequireDefault(_TableBody);

var _TableHead = require('@material-ui/core/TableHead');

var _TableHead2 = _interopRequireDefault(_TableHead);

var _TableRow = require('@material-ui/core/TableRow');

var _TableRow2 = _interopRequireDefault(_TableRow);

var _recompose = require('recompose');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
    return {
        root: _defineProperty({
            width: '100%'
        }, theme.breakpoints.down('sm'), {
            overflowX: 'scroll'
        }),
        Table: {
            minWidth: '700'
        }
    };
};

var SimpleTable = function (_React$PureComponent) {
    _inherits(SimpleTable, _React$PureComponent);

    function SimpleTable() {
        _classCallCheck(this, SimpleTable);

        return _possibleConstructorReturn(this, (SimpleTable.__proto__ || Object.getPrototypeOf(SimpleTable)).apply(this, arguments));
    }

    _createClass(SimpleTable, [{
        key: 'render',
        value: function render() {
            var _props = this.props,
                classes = _props.classes,
                renderHead = _props.renderHead,
                renderBody = _props.renderBody,
                className = _props.className;


            return _react2.default.createElement(
                'div',
                { className: classes.root },
                _react2.default.createElement(
                    _Table2.default,
                    { className: classes.table },
                    _react2.default.createElement(
                        _TableHead2.default,
                        { className: className },
                        _react2.default.createElement(
                            _TableRow2.default,
                            null,
                            renderHead()
                        )
                    ),
                    _react2.default.createElement(
                        _TableBody2.default,
                        null,
                        renderBody()
                    )
                )
            );
        }
    }]);

    return SimpleTable;
}(_react2.default.PureComponent);

SimpleTable.defaultProps = {
    renderHead: function renderHead() {},
    renderBody: function renderBody() {}
};
exports.default = (0, _recompose.compose)((0, _styles.withStyles)(styles))(SimpleTable);