import React from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import PercentageBar from './PercentageBar';
import { useStyles } from '../styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { backgroundColorKeys } from '../../../../constants';

const Answers = (props) => {
    const { poll, showPollResults, value, pollResults, onChange, backgroundColor } = props;
    const classes = useStyles();

    const handleChange = (event) => {
        if (onChange) onChange(event);
    };

    const getPollAnswerResult = (index) => pollResults.pollAnswerResults[index];

    const isChecked = (item) => {
        if (pollResults && pollResults.selectedAnswer) {
            return pollResults.selectedAnswer.answer.id === item.id;
        } else {
            return false;
        }
    };

    return (
        <div>
            <FormControl component='fieldset' className={classes.formControl} disabled={showPollResults}>
                <RadioGroup name='poll' onChange={handleChange} value={value}>
                    {poll && poll.answers && poll.answers.map((item, index) => {
                        const percentage = showPollResults && Math.floor(getPollAnswerResult(index).percentage * 100);
                        return (
                            <React.Fragment key={item.id}>
                                <FormControlLabel
                                    key={item.id}
                                    classes={{
                                        root: classes.formControlRoot,
                                        label: classes.formControlLabel,
                                    }}
                                    className={classNames(
                                        classes.content,
                                        { [classes.primary]: backgroundColor === backgroundColorKeys.primary },
                                        { [classes.secondary]: backgroundColor === backgroundColorKeys.secondary },
                                        { [classes.noBG]: backgroundColor === backgroundColorKeys.none },
                                        { 'active': isChecked(item) }
                                    )}
                                    control={<Radio className={'radio-hidden'} />}
                                    label={
                                        <div className={classes.answerInner}>
                                            <Typography component='p' variant='body1' className={classes.percentageBarContent}>
                                                {showPollResults ? `${percentage}%` : ''}
                                            </Typography>
                                            <Typography component='p' variant='body1' className={classes.answerTitle}>
                                                {item.title}
                                            </Typography>
                                        </div>
                                    }
                                    value={item.id}
                                />
                                <PercentageBar percentage={showPollResults ? percentage : 0} backgroundColor={backgroundColor} />
                            </React.Fragment>
                        );
                    })}
                </RadioGroup>
            </FormControl>
        </div>
    );
};

export default Answers;
