import React, { useState } from 'react';
import { Loader } from '@manakin/app-core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import Popup from './Popup/Popup';
import Grid from '@material-ui/core/Grid';
import { withForm } from '@manakin/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { backgroundColorKeys } from '../../constants';
import renderHTML from "react-render-html";

export const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {},
			wrapper: {
				paddingBottom: '2.5rem',
				height: '100%'
			},
			content: {
				backgroundColor: '#eee5e6',
				padding: `24px 40px`,
				height: '100%',
				display: "flex",
				flexDirection: "column"
			},
			contentSubTitle: {
				fontSize: '1.5rem',
				lineHeight: '3rem',
				marginBottom: 0,
				color: theme.manakin.defaultTitleColor[500],
			},
			contentTitle: {
				fontSize: '2.5rem',
				lineHeight: '2.9rem',
				marginBottom: '3.5rem'
			},
			contentText: {
				fontSize: '1.6rem',
				lineHeight: '3rem',
				color: theme.manakin.defaultTitleColor[500],
				flex: 1,
				"& ol, ul" : {
					listStyle: "inside"
				}
			},
			contentButton: {
				borderRadius: 0,
				paddingTop: '2.25rem',
				paddingBottom: '2.25rem',
				fontSize: '1.8rem',
				backgroundColor: theme.manakin.primaryColor[500],

				'&:hover': {
					backgroundColor: theme.manakin.primaryColor[700],
				},

				'& .MuiButton-label': {
					fontSize: '1.8rem',
					color: theme.palette.getContrastText(theme.manakin.primaryColor[500]),
				},
			},
			primary: {
				backgroundColor: theme.manakin.primaryWorkformColor[500],
				padding: 50,
				'& $contentSubTitle': {
					color: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),
				},
				'& $contentTitle': {
					color: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),
				},
				'& $contentText': {
					color: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),
				},
				'& $contentButton': {
					backgroundColor: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),

					'& .MuiButton-label': {
						fontSize: '1.8rem',
						color: 'white'
					},
				}
			},
			secondary: {
				backgroundColor: theme.manakin.secondaryWorkformColor[500],
				padding: 50,
				'& $contentSubTitle': {
					color: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
				},
				'& $contentTitle': {
					color: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
				},
				'& $contentText': {
					color: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
				},
				'& $contentButton': {
					backgroundColor: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),

					'& .MuiButton-label': {
						fontSize: '1.8rem',
						color: 'white'
					},
				}
			},
			noBG: {
				backgroundColor: 'white',
				padding: 50,
				'& $contentSubTitle': {
					color: theme.manakin.defaultContentColor[500],
				},
				'& $contentTitle': {
					color: theme.manakin.defaultContentColor[500],
				},
				'& $contentText': {
					color: theme.manakin.defaultContentColor[500],
				},
				'& $contentButton': {
					backgroundColor: theme.manakin.defaultContentColor[500],

					'& .MuiButton-label': {
						fontSize: '1.8rem',
						color: 'white'
					},
				}
			}
		}),
	{
		name: 'SuggestionBoxAppWidget',
	}
);


const formBundle = {
	text: {
		required: true
	},
}

const SuggestionBoxWidget = (props) => {
	const { title, gridProps, form, id, intro, actionTitle, loading, backgroundColor } = props;
	const { t } = useTranslation();
	const classes = useStyles();

	const formData = {
		id,
		title
	}

	//States
	const [openPopup, setOpenPopup] = useState(false);
	
	if (loading) return <Loader />;
	return (
        <Grid className={classes.root} item {...gridProps}>
			<div className={classes.wrapper}>
				<div className={classNames(
					classes.content,
					{ [classes.primary]: backgroundColor === backgroundColorKeys.primary },
					{ [classes.secondary]: backgroundColor === backgroundColorKeys.secondary },
					{ [classes.noBG]: backgroundColor === backgroundColorKeys.none },
				)}>
					<Typography className={classes.contentSubTitle}>{t('app.widget-dashboard.suggestion-box-widget.subtitle')}</Typography>
					<Typography component="h3" variant="h3" className={classes.contentTitle}>
						{title}
					</Typography>
					<Typography className={classes.contentText}>
						{intro ? renderHTML(intro) : t('app.widget-dashboard.suggestion-box-widget.text')}
					</Typography>
					<Button fullWidth variant="contained" className={classes.contentButton} onClick={() => setOpenPopup(true)}>
						{actionTitle || t('app.widget-dashboard.suggestion-box-widget.action-title')}
					</Button>
				</div>
			</div>
			<Popup open={openPopup} onClose={() => setOpenPopup(false)} form={form} {...formData} />
		</Grid>
	);
};

export default compose(withForm(formBundle))(SuggestionBoxWidget);
