'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _styles = require('@material-ui/core/styles');

var _recompose = require('recompose');

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _ErrorOutline = require('@material-ui/icons/ErrorOutline');

var _ErrorOutline2 = _interopRequireDefault(_ErrorOutline);

var _reactRouterDom = require('react-router-dom');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = function styles(theme) {
    return {
        root: {
            width: '100%',
            padding: '6rem',
            backgroundColor: theme.manakin.secondaryColor[500],
            marginBottom: '2rem'
        },
        fontStyle: {
            color: theme.palette.getContrastText(theme.manakin.secondaryColor[500])
        },
        title: {
            fontWeight: 'bold'
        },
        icon: {
            position: 'relative',
            top: '5px',
            marginRight: '1.6rem'
        },
        link: {
            textDecoration: 'none'
        }
    };
};

var NewsItem = function NewsItem(props) {
    var classes = props.classes;


    return _react2.default.createElement(
        'div',
        { className: classes.root },
        _react2.default.createElement(
            _reactRouterDom.Link,
            { to: '/news/' + props.id, className: classes.link },
            _react2.default.createElement(
                _Typography2.default,
                {
                    variant: 'body1',
                    component: 'p',
                    className: (0, _classnames2.default)(classes.fontStyle, classes.title)
                },
                _react2.default.createElement(_ErrorOutline2.default, { className: classes.icon }),
                props.title || ''
            ),
            _react2.default.createElement(
                _Typography2.default,
                {
                    variant: 'body1',
                    component: 'p',
                    className: (0, _classnames2.default)(classes.fontStyle, classes.introText)
                },
                props.introText || ''
            )
        )
    );
};

exports.default = (0, _recompose.compose)((0, _styles.withStyles)(styles, { name: 'AppAccountNewsItem' }))(NewsItem);