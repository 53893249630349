"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.GQL_FETCH_SELF_EVALUATION_RESULT = exports.GQL_FETCH_SELF_EVALUATIONS = undefined;

var _templateObject = _taggedTemplateLiteral(["\n\tquery selfEvaluations($programId: ID!) {\n\t\tselfEvaluations(programId: $programId) {\n\t\t\tid\n\t\t\ttitle\n\t\t\timage {\n\t\t\t\t", "\n\t\t\t}\n\t\t\tboxen {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tquery selfEvaluations($programId: ID!) {\n\t\tselfEvaluations(programId: $programId) {\n\t\t\tid\n\t\t\ttitle\n\t\t\timage {\n\t\t\t\t", "\n\t\t\t}\n\t\t\tboxen {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n"]),
    _templateObject2 = _taggedTemplateLiteral(["\n\tquery fetchSelfEvaluationResul($userId: ID!, $boxId : ID!, $programId: ID!, $elementId: ID!) {\n\t\telementResult(\n\t\t\tuser: $userId,\n\t\t\tbox: $boxId,\n\t\t\tprogram: $programId,\n\t\t\telement: $elementId\n\t\t) {\n\t\t\tfinished\n\t\t}\n\t}\n"], ["\n\tquery fetchSelfEvaluationResul($userId: ID!, $boxId : ID!, $programId: ID!, $elementId: ID!) {\n\t\telementResult(\n\t\t\tuser: $userId,\n\t\t\tbox: $boxId,\n\t\t\tprogram: $programId,\n\t\t\telement: $elementId\n\t\t) {\n\t\t\tfinished\n\t\t}\n\t}\n"]);

var _graphqlTag = require("graphql-tag");

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

var _graphQlHelpers = require("@manakin/app-core/graphQlHelpers");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_SELF_EVALUATIONS = exports.GQL_FETCH_SELF_EVALUATIONS = (0, _graphqlTag2.default)(_templateObject, _graphQlHelpers.appAssetWithThumbnail);

var GQL_FETCH_SELF_EVALUATION_RESULT = exports.GQL_FETCH_SELF_EVALUATION_RESULT = (0, _graphqlTag2.default)(_templateObject2);