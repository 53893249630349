import React, { useState, useEffect } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { withStyles } from '@material-ui/core/styles';
import { compose, withHandlers } from 'recompose';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import { styles } from './styles';
import {
	GQL_FETCH_SETTINGS,
	GQL_LOGIN_APP,
	GQL_PAY,
	GQL_ACQUIRE_LICENSES,
	GQL_FETCH_APP_USER_FOR_STORE,
	GQL_REGISTER_APP_USER,
} from '../graphql';
import { graphql, withApollo } from 'react-apollo';
import { Organisation, TrainingMandatory, PersonalData, AuthData, PayScreen, EtosDone } from './pages';
import { withForm } from '@manakin/core';
import { connect } from 'react-redux';
import { login } from '../actions';
import { showSnackbarMessage } from '@manakin/core/actions';
import { isAuthenticated } from '@manakin/authentication/selectors';
import { Typography } from '@material-ui/core';
import { Loader } from '@manakin/app-core';
import { logout } from '@manakin/authentication/actions';
import Cookies from 'js-cookie';
import { useMutation } from '@apollo/react-hooks';

const organisationForm = {
	globalID: {
		required: true,
		format: 'etos',
	},
};
const trainingForm = {};
const personalForm = {
	gender: {
		required: true,
	},
	firstName: {
		required: true,
	},
	lastName: {
		required: true,
	},
	dateOfBirth: {
		required: true,
		format: 'date',
	},
};
const authForm = {
	email: {
		required: true,
		format: 'email',
	},
	password: {
		format: 'password',
	},
	secondEmail: {
		required: true,
		couple: 'email',
		coupleLabel: 'e-mailadres',
		format: 'email',
	},
	secondPassword: {
		required: true,
		couple: 'password',
		coupleLabel: 'wachtwoord',
		format: 'password',
	},
	acceptTerms: {
		required: true,
	},
	acceptSecondTerms: {
		required: true,
	},
};
const payForm = {};

const formData = [organisationForm, trainingForm, personalForm, authForm, payForm];

const form = {
	...organisationForm,
	...trainingForm,
	...personalForm,
	...authForm,
	...payForm,
};

const AppRegistration = (props) => {
	const { classes, form, APP_USER = {}, match = {} } = props;
	const { loading, currentAppUser = {} } = APP_USER;
	const steps = ['1', '2', '3', '4', '5'];

	const [mutate] = useMutation(GQL_REGISTER_APP_USER);

	//state hooks
	const [activeStep, setActiveStep] = useState(match.params.pay && match.params.pay === 'etos' ? -1 : 0);
	const [buttonDisabled, setButtonDisabled] = useState(true);
	const [buttonText, setButtonText] = useState('Doorgaan');
	const [backButtonText, setBackButtonText] = useState('Terug');
	const [isSpecial, setIsSpecial] = useState(false);
	const [isEtos, setIsEtos] = useState(false);
	const [bank, setBank] = useState(false);
	const [mainOrganisation, setMainOrganisation] = useState('');
	const [price, setPrice] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (!loading) {
			if (props.match.params.pay && props.match.params.pay === 'pay' && props.authenticated) {
				setActiveStep(4);
			}
			if (
				currentAppUser &&
				currentAppUser.schoolClasses &&
				currentAppUser.schoolClasses.length &&
				props.match.params.pay &&
				props.authenticated
			) {
				let _org = '';
				const { schoolClasses = [] } = currentAppUser;
				
				const getAllOrganisations = (organisations) => {
					organisations.filter(Boolean).forEach((organisation) => {
						if (!organisation.mainOrganisation || !organisation.mainOrganisation.name) return;
						setMainOrganisation(organisation.mainOrganisation.name);
						_org = organisation.mainOrganisation.name;
						if (organisation.mainOrganisation.name.toLowerCase() !== 'etos eigen winkel') return;
						if (isEtos) return;
						setIsEtos(true);
						handlePay(true, _org);
					})
				}

				const getSchools = (schools) => {
					schools.filter(Boolean).forEach((school) => 
						getAllOrganisations(school.organisations)
					)
				}

				schoolClasses.forEach((schoolClass) =>
					getSchools(schoolClass.schools)
				);
			}
		}
	}, [APP_USER.loading]);

	//functions
	const handleClick = () => {
		form.onValidate().then(
			() => {
				if (activeStep === 4) {
					handlePay();
					return
				}
				if (activeStep === 3) {
					handleSubmit();
					return
				} 
				setActiveStep(activeStep + 1);
			},
			(error) => {
				let hasError = false;
				for (const key of Object.keys(formData[activeStep])) {
					if (error[key]) {
						hasError = (key !== 'globalID' || isEtos)
					}
				}
				if (hasError) return;
				if (activeStep === 4) {
					handlePay();
					return;
				} 
				if (activeStep === 3) {
					handleSubmit();
					return;
				}
				setActiveStep(activeStep + 1);
			}
			
		);
	};

	const handleBackButtonClick = () => {
		if (activeStep === 0 || activeStep === 4) {
			Cookies.remove('shop');
			props.history.push('/');
		}
		setActiveStep(activeStep - 1);
	};

	const handleReady = (ready) => {
		setButtonDisabled(!ready);
	};

	const handleSubmit = () => {
		form.onSubmit().then((data) => {
			const { settings = [] } = props.data;
			let role = null;

			settings.settings &&
				settings.settings.forEach((setting) => {
					if (setting.name === 'standardRole') {
						role = setting.value;
					}
				});

			//need clean otherProps!!!! so dont remove unused vars from below
			const {
				globalID,
				registerCode,
				acceptSecondTerms,
				secondEmail,
				affiliate,
				function: dataFunction = 'Assistent drogist',
				mainOrganisation,
				secondPassword,
				trainingMandatory2017 = false,
				trainingMandatory2018 = false,
				trainingMandatory2019 = false,
				trainingMandatory2020 = false,
				trainingMandatory2021 = false,
				trainingMandatory2022 = false,
				trainingMandatory2023 = false,
				...otherData
			} = data;

			let _data = {
				...otherData,
				registerCode: isEtos ? globalID : registerCode,
				userDataCbdInput: {
					function: dataFunction,
				},
				schoolClasses: [affiliate],
				roles: [role],
			};

			const shop = {
				trainingMandatory2017,
				trainingMandatory2018,
				trainingMandatory2019,
				trainingMandatory2020,
				trainingMandatory2021,
				trainingMandatory2022,
				trainingMandatory2023,
				function: dataFunction,
			};

			if (_data) {
				Cookies.set('shop', shop);

				mutate({ variables: { ..._data } })
					.then((result) => {
						if (result.errors) {
							result.errors.map((error) => {
								props.processError(error);
								console.log(`processError recieved from back-end log: ${error}`)
							});
						} else {
							handleLogin(otherData);
						}
					})
					.catch((error) => {
						console.log('ERROR', error);
					});
			}
		});
	};

	const handleLogin = (data) => {
		let redirect = '/auth/registration/pay';
		if (isEtos) redirect = '/auth/registration/etos';
		props.onLogin({ email: data.email, password: data.password, redirect });
	};

	const handleLogout = () => {
		props.onLogout();
		props.history.push('/');
	};

	const handlePay = (isEtos, org = null) => {
		if (props.authenticated) {
			const appUrl = process.env.REACT_APP_APP_URL || 'http://localhost:3000';
			const shopCookies = JSON.parse(Cookies.get('shop'));
			let schoolClassName = '';
			let street = '';
			let buildingNumber = '';
			let zipCode = '';
			let city = '';
			let country = '';
			let _bank = isEtos ? 'EtosBank' : bank;

			if (currentAppUser.schoolClasses && currentAppUser.schoolClasses[0]) {
				schoolClassName = currentAppUser.schoolClasses[0].name;
				street = currentAppUser.schoolClasses[0].street;
				buildingNumber = currentAppUser.schoolClasses[0].buildingNumber;
				zipCode = currentAppUser.schoolClasses[0].zipCode;
				city = currentAppUser.schoolClasses[0].city;
				country = currentAppUser.schoolClasses[0].country;
			}

			props
				.onPay({
					input: {
						description: 'CBD product',
						issuerId: _bank,
						entranceCode: 'CBD',
						returnURL: `${appUrl}/auth/payment-status`,
						userDataCbd: { ...shopCookies },
						mainOrganisationName: org ? org : mainOrganisation,
						schoolClassName: schoolClassName,
						street: street,
						buildingNumber: buildingNumber,
						zipCode: zipCode,
						city: city,
						country: country,
					}
				})
				.then((result) => {
					if (result.data && result.data.pay && result.data.pay.result && result.data.pay.result === 'OK') {
						if (isEtos) {
							handleAcquire();
						} else if (result.data.pay.orderId) {
							window.location.href = `/auth/payment-status/${result.data.pay.orderId}`;
						}
					} else {
						props.processPayFailed();
					}
				});
		} else {
			props.procesNoAuth();
		}
	};

	const handleAcquire = () => {
		Cookies.remove('shop');
		setIsLoading(true);
		props
			.onAcquire({
				unlimited: true,
			})
			.then((result) => {
				setIsLoading(false);
				setActiveStep(5);
			})
			.catch(() => {
				setIsLoading(false);
			});
	};

	const handleIsEtos = (data) => {
		setIsEtos(data);
	};

	const handleSpecial = (data) => {
		setIsSpecial(data);
	};

	const handleSetBank = (data) => {
		setBank(data);
	};

	const handlePrice = (data) => {
		setPrice(data);
	};

	if (loading || isLoading) {
		return (
			<div>
				<Loader fullScreen={true} />
			</div>
		);
	}
	return (
		<div className={classes.root}>
			<div className={classes.wrapper}>
				<Stepper classes={{ root: classes.stepperRoot }} activeStep={activeStep} alternativeLabel>
					{steps.map((label) => {
						return (
							<Step classes={{ root: classes.stepRoot }} key={label}>
								<StepLabel />
							</Step>
						);
					})}
				</Stepper>
			</div>
			<div className={classes.contentRoot}>
				{activeStep === 0 && <Organisation onReady={handleReady} form={form} onSpecial={handleSpecial} onIsEtos={handleIsEtos} />}
				{activeStep === 1 && <TrainingMandatory onReady={handleReady} form={form} isEtos={isEtos} />}
				{activeStep === 2 && <PersonalData onReady={handleReady} form={form} />}
				{activeStep === 3 && <AuthData onReady={handleReady} form={form} />}
				{activeStep === 4 && <PayScreen onReady={handleReady} form={form} onBank={handleSetBank} onPrice={handlePrice} />}
				{activeStep === 5 && <EtosDone onLogout={handleLogout} />}
			</div>
			<div className={classes.smallWrapper}>
				{isSpecial && (
					<div className={classes.special}>
						<Typography variant="body1">
							Neem contact op met het hoofdkantoor (afdeling opleidingen), zij melden u aan voor de nascholing.
						</Typography>
					</div>
				)}
				{!isSpecial && activeStep != 5 && (
					<div className={classes.controls}>
						<Button
							onClick={handleClick}
							color="primary"
							variant="contained"
							className={classes.mainButtonStyle}
							disabled={buttonDisabled}
						>
							{buttonText}
						</Button>
						<Button onClick={handleBackButtonClick} color="primary">
							{backButtonText}
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};

export default compose(
	connect(
		(state) => ({
			authenticated: isAuthenticated(state),
		}),
		(dispatch) => ({
			onLogout: () => dispatch(logout()),
			processSuccess: (redirect, user) => dispatch(login(redirect, user)),
			processPayFailed: () =>
				dispatch(
					showSnackbarMessage({
						text: 'Bank gegevens konden niet worden opgehaald, probeer het later nog eens.',
						variant: 'error',
					})
				),
			processLoginFailed: () =>
				dispatch(
					showSnackbarMessage({
						text: 'Inloggen mislukt, mogelijk zijn er onjuiste gegevens ingevuld.',
						variant: 'error',
					})
				),
			processError: (error) => {
				dispatch(
					showSnackbarMessage({
						text: 'Er ging iets mis tijdens het verwerken van jouw aanvraag, probeer het later opnieuw of neem contact op met onze support afdeling.',
						variant: 'error',
					})
				);
			},
			procesNoAuth: () =>
				dispatch(
					showSnackbarMessage({
						text: 'U bent niet meer ingelogd, Klik op "terug" om opnieuw in te loggen en de betaling te voltooien.',
						variant: 'error',
					})
				),
		})
	),
	graphql(GQL_FETCH_SETTINGS),
	graphql(GQL_ACQUIRE_LICENSES, { name: 'ACQUIRE_LICENSES' }),
	graphql(GQL_FETCH_APP_USER_FOR_STORE, {
		name: 'APP_USER',
		options: { fetchPolicy: 'no-cache' },
	}),
	graphql(GQL_PAY, {
		name: 'PAY',
	}),
	graphql(GQL_LOGIN_APP, {
		name: 'LOGIN_APP',
	}),
	withStyles(styles),
	withForm(form),
	withRouter,
	withHandlers({
		onPay:
			({ PAY }) =>
			(event) => {
				return PAY({
					variables: {
						...event,
					},
				});
			},
		onCreate:
			({ mutate }) =>
			(event) => {
				return mutate({
					variables: {
						...event,
					},
				});
			},
		onLogin:
			({ LOGIN_APP, processSuccess, processLoginFailed }) =>
			(event) => {
				LOGIN_APP({
					variables: {
						...event,
					},
				})
					.then((data) => {
						if (!data.errors) {
							processSuccess(event.redirect, data.data.loginApp.user);
						} else {
							processLoginFailed();
						}
					})
					.catch((e) => {
						processLoginFailed();
					});
			},
		onAcquire:
			({ ACQUIRE_LICENSES }) =>
			(event) => {
				return ACQUIRE_LICENSES({
					variables: {
						...event,
					},
				});
			},
	}),
	withApollo
)(AppRegistration);
