'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Dialog = require('@material-ui/core/Dialog');

var _Dialog2 = _interopRequireDefault(_Dialog);

var _core = require('@material-ui/core');

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _Button = require('@material-ui/core/Button');

var _Button2 = _interopRequireDefault(_Button);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _reactI18next = require('react-i18next');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var useStyles = (0, _core.makeStyles)(function (theme) {
	return {
		dialogPaper: {
			flexFlow: 'row wrap',
			padding: theme.spacing(6),
			textAlign: "center"
		},
		dialogTitle: _defineProperty({
			width: '100%',
			margin: 0,
			padding: '12px 0',
			fontSize: '4rem',
			lineHeight: '4rem'
		}, theme.breakpoints.up('md'), {
			padding: '32px 0'
		}),
		dialogActions: {
			display: 'flex',
			flexFlow: 'row wrap',
			flexDirection: "column",
			width: '100%',
			marginTop: theme.spacing(2)
		},
		button: {
			minHeight: "6rem",
			padding: 0
		},
		ok: {
			backgroundColor: theme.manakin.indicationColor[500],
			color: 'white',
			marginBottom: theme.spacing(),
			'&:hover': {
				backgroundColor: theme.manakin.indicationColor[700]
			}
		}
	};
});

var MaxRequestsDialog = function MaxRequestsDialog(props) {
	var onClose = props.onClose,
	    open = props.open,
	    isFinalRating = props.isFinalRating;

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var classes = useStyles();

	return _react2.default.createElement(
		_Dialog2.default,
		{
			open: open,
			onClose: onClose,
			PaperProps: { className: classes.dialogPaper }
		},
		_react2.default.createElement(
			_Typography2.default,
			{ variant: 'h3', className: classes.dialogTitle },
			t("app.rating-request.max-requests-dialog.title")
		),
		_react2.default.createElement(
			_core.DialogContent,
			null,
			_react2.default.createElement(
				_core.DialogContentText,
				null,
				isFinalRating ? t("app.rating-request.max-requests-dialog.final-rating-description") : t("app.rating-request.max-requests-dialog.rating-description")
			)
		),
		_react2.default.createElement(
			'div',
			{ className: classes.dialogActions },
			_react2.default.createElement(
				_Button2.default,
				{
					variant: 'contained',
					className: (0, _classnames2.default)(classes.button, classes.ok),
					onClick: onClose
				},
				t("common.close")
			)
		)
	);
};

exports.default = MaxRequestsDialog;