"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.GQL_FETCH_ORDERS = undefined;

var _templateObject = _taggedTemplateLiteral(["\n\tquery idealOrders($filter: IdealOrderFilter) {\n\t\tidealOrders(filter: $filter) {\n\t\t\tidealOrders {\n\t\t\t\torderID\n\t\t\t\tuserID\n\t\t\t\tinvoiceID\n\t\t\t\tinvoiceNumber\n\t\t\t\tinvoicePdfUrl\n\t\t\t\tname\n\t\t\t\torderDate\n\t\t\t\ttotalPrice\n\t\t\t\tidealStatus\n\t\t\t}\n\t\t\tcount\n\t\t}\n\t}\n"], ["\n\tquery idealOrders($filter: IdealOrderFilter) {\n\t\tidealOrders(filter: $filter) {\n\t\t\tidealOrders {\n\t\t\t\torderID\n\t\t\t\tuserID\n\t\t\t\tinvoiceID\n\t\t\t\tinvoiceNumber\n\t\t\t\tinvoicePdfUrl\n\t\t\t\tname\n\t\t\t\torderDate\n\t\t\t\ttotalPrice\n\t\t\t\tidealStatus\n\t\t\t}\n\t\t\tcount\n\t\t}\n\t}\n"]);

var _graphqlTag = require("graphql-tag");

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_ORDERS = exports.GQL_FETCH_ORDERS = (0, _graphqlTag2.default)(_templateObject);