import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import classNames from 'classnames';
import { GQL_FETCH_SESSION_DURATION_AVERAGE } from "../graphql/graphql";
import { useQuery } from "react-apollo";
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
	root: {
		display: "flex",
		alignItems: "center",
		flexGrow: 1
	},
	contentColor: {},
	durationContainer: {
		position: "relative",
		borderRadius: "100%",
		border: `3px solid ${theme.manakin.softBorderColor[500]}`,
		width: "75%",
		paddingBottom: "75%",
		height: 0,
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		marginLeft: "auto",
		marginRight: "auto",
		[theme.breakpoints.down('sm')]: {
			width: "60%",
			paddingBottom: "60%",
		},
		[theme.breakpoints.up('lg')]: {
			width: "60%",
			paddingBottom: "60%",
		}
	},
	durationDataContainer: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		margin: 0,
		textAlign: "center"
	},
	title: {
		color: theme.manakin.indicationColor[500],
		fontWeight: "400",
		marginBottom: 0
	},
	text: {
		marginBottom: 0,
		paddingBottom: '0 !important',
		color: theme.manakin.subHeaderColor[500],
		textAlign: "center",
		textTransform: "uppercase",
		fontWeight: "500",
	},
	subHeader: {
		fontWeight: "bold"
	},
});

const SessionDurationBlock = (props) => {
	const { classes } = props;
	const { t } = useTranslation();
	const [ duration, setDuration ] = useState("");
	const [ durationUnit, setDurationUnit ] = useState("");

	// Query
	useQuery(GQL_FETCH_SESSION_DURATION_AVERAGE, {
		onCompleted: (data) => {
			if (!data || !data.sessionDataPastYear) return;

			const { sessionAverageDuration = 0 } = data.sessionDataPastYear;
			const minutes = parseInt((sessionAverageDuration / 1000) / 60);

			if (minutes >= 60) {
				setDuration(Math.round((minutes / 60) * 10) / 10);
				setDurationUnit(t("common.hour"))
			} else {
				setDuration(minutes);
				setDurationUnit(t("common.minutes"))
			}
		}
	});

	return (
		<div className={classes.root}>
			<div className={classes.durationContainer}>
				<div className={classes.durationDataContainer}>
					<Typography
						component="h1"
						variant="h3"
						className={classNames(
							classes.title
						)}>
						{duration}
					</Typography>
					<Typography
						component="p"
						variant="body1"
						className={classNames(
							classes.subHeader,
							classes.contentColor,
							classes.text
						)}>
						{durationUnit}
					</Typography>
				</div>
			</div>
		</div>
	);
};

export default compose(
	withStyles(styles, { name: 'AppProgressDashboardSessionDurationBlock' }),
	connect(({ config }) => ({ config }))
)(SessionDurationBlock);
