import React, { useState, useCallback } from "react";
import { useStyles } from "../styles";
import { useSelector, useDispatch } from "react-redux";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { showSnackbarMessage } from "@manakin/core/actions";
import {
	GQL_CREATE_APPOINTMENT,
	GQL_UPDATE_APPOINTMENT,
	GQL_FETCH_APPOINTMENTS,
} from "../graphql";
import { compose } from "recompose";
import { withForm, Loader } from "@manakin/core";
import {
	Calendar,
	Planner,
	NotificationCTA,
	AppointmentsList,
} from "@manakin/app-core";
import Typography from "@material-ui/core/Typography";
import { getAppUser } from "@manakin/authentication/selectors";
import { trainerRoles } from "@manakin/app-core/lib";
import moment from "moment";
import "moment/min/locales";
import ChoiseDialog from "./ChoiseDialog";
import DeleteDialog from "./DeleteDialog";
import { useTranslation } from 'react-i18next';

const formBundle = {
	selectedUser: {
		required: true,
	},
	subject: {
		required: true,
	},
};

const defaultStateSettings = {
	open: false,
	data: null,
};

const Agenda = (props) => {
	const { form } = props;
	const classes = useStyles();
	const { t } = useTranslation();

	//state hooks
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [openPopup, setOpenPopup] = useState(defaultStateSettings);
	const [popupSettings, setPopupSettings] = useState(defaultStateSettings);
	const [deleteDialog, setDeleteDialog] = useState(defaultStateSettings);

	//selectors
	const appUser = useSelector((state) => getAppUser(state));
	const isTrainer =
		appUser &&
		appUser.roles &&
		appUser.roles.some((role) => trainerRoles.includes(role.name));

	//mutation
	const [mutate] = useMutation(GQL_CREATE_APPOINTMENT, {
		refetchQueries: ["appointments"],
	});

	const [mutateUpdate] = useMutation(GQL_UPDATE_APPOINTMENT, {
		refetchQueries: ["appointments"],
	});

	//queries
	const { data = {}, loading } = useQuery(GQL_FETCH_APPOINTMENTS, {
		variables:{
			includePastAppointments: false
		}
	});
	const appointments =
		(data.appointments && data.appointments.appointments) || [];
	const mapped = appointments && appointments.map((i) => i.time);

	//dispatches
	const dispatch = useDispatch();
	const onError = useCallback(
		(data) =>
			dispatch(
				showSnackbarMessage({
					text: data || t("common.save-failed"),
					variant: "error",
				})
			),
		[dispatch]
	);

	//functions
	const handleChoise = (appointment) => {
		setPopupSettings(defaultStateSettings);
		if (!appointment) return;

		const data = {
			open: true,
			data: appointment,
		};
		setOpenPopup(data);
	};

	const handleDateChange = (event) => {
		setSelectedDate(event);
		if (mapped.some((i) => moment(i).isSame(event, "day"))) {
			setPopupSettings({
				open: true,
				data: event,
			});
		} else {
			setOpenPopup({
				open: true,
				data: null,
			});
		}
	};

	const handleClose = (event) => {
		setOpenPopup(defaultStateSettings);
		setDeleteDialog(defaultStateSettings);
	};

	const handleCreate = (r, time) => {
		mutate({
			variables: {
				input: {
					studentId: r.selectedUser,
					subjectId: r.subject,
					time: new Date(moment(time).format()).toISOString(),
				},
			},
		}).then((r) => {
			if (!r.errors) {
				setOpenPopup(defaultStateSettings);
			} else {
				onError();
			}
		});
	};

	const handleUpdate = (r, time, data) => {
		mutateUpdate({
			variables: {
				input: {
					studentId: r.selectedUser,
					subjectId: r.subject,
					time: new Date(moment(time).format()).toISOString(),
					id: data.id,
				},
			},
		}).then((r) => {
			if (!r.errors) {
				setOpenPopup(defaultStateSettings);
			} else {
				onError();
			}
		});
	};

	const handleSubmit = (time, data) => {
		form.onSubmit()
			.then((r) => {
				if (r) {
					if (!data || (data && data === "addAccount")) handleCreate(r, time);
					else handleUpdate(r, time, data);
				}
			})
			.catch((e) => {
				onError();
			});
	};

	const handleDelete = (id) => {
		setDeleteDialog({
			open: true,
			data: id,
		});
	};

	if (loading) return <Loader fullScreen />;
	return (
		<React.Fragment>
			<Planner
				form={form}
				onClose={handleClose}
				onClick={handleSubmit}
				{...openPopup}
				date={selectedDate}
				onDelete={handleDelete}
			/>
			<div className={classes.agendaWrapper}>
				<div className={classes.flexObject}>
					<Calendar
						onClick={handleDateChange}
						initialValue={mapped}
						disabled={!isTrainer}
					/>
				</div>
				<div className={classes.flexObject}>
					<NotificationCTA />
				</div>
			</div>
			<div className={classes.appointments}>
				<Typography variant="h4" align="center">
					{t("app.planner.planned-appointments")}
				</Typography>
				<AppointmentsList onClick={handleChoise} />
			</div>
			<ChoiseDialog
				appointments={appointments}
				{...popupSettings}
				onClose={handleChoise}
			/>
			<DeleteDialog {...deleteDialog} onClose={handleClose} />
		</React.Fragment>
	);
};

export default compose(withForm(formBundle))(Agenda);
