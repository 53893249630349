import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
	listItem: { margin: 0 },
}));

const ListItem = (props) => {
	const { title } = props;
	const classes = useStyles();

	return (
		<li>
			<Typography className={classes.listItem}>{title}</Typography>
		</li>
	);
};

export default ListItem;
