"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.GQL_FETCH_RATING_STUDENT_RESULT_ASSETS = exports.GQL_FETCH_CURRENT_USER = exports.GQL_FETCH_RATING_REQUESTS = undefined;

var _templateObject = _taggedTemplateLiteral(["\n\tquery ratingRequests($filter: RatingRequestFilter) {\n\t\tratingRequests(pageNr: 0, pageSize: 2, filter: $filter) {\n\t\t\tid\n\t\t\tattempt\n\t\t\trequestedAt\n\t\t\tbox {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t\ttrainer {\n\t\t\t\tid\n\t\t\t\tfullName\n\t\t\t}\n\t\t\tresult {\n\t\t\t\tid\n\t\t\t\tranking\n\t\t\t\trater {\n\t\t\t\t\tid\n\t\t\t\t\tfullName\n\t\t\t\t}\n\t\t\t\tgradedAt\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tquery ratingRequests($filter: RatingRequestFilter) {\n\t\tratingRequests(pageNr: 0, pageSize: 2, filter: $filter) {\n\t\t\tid\n\t\t\tattempt\n\t\t\trequestedAt\n\t\t\tbox {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t\ttrainer {\n\t\t\t\tid\n\t\t\t\tfullName\n\t\t\t}\n\t\t\tresult {\n\t\t\t\tid\n\t\t\t\tranking\n\t\t\t\trater {\n\t\t\t\t\tid\n\t\t\t\t\tfullName\n\t\t\t\t}\n\t\t\t\tgradedAt\n\t\t\t}\n\t\t}\n\t}\n"]),
    _templateObject2 = _taggedTemplateLiteral(["\n\tquery currentAppUser {\n\t\tcurrentAppUser {\n\t\t\tid\n\t\t\troles {\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tquery currentAppUser {\n\t\tcurrentAppUser {\n\t\t\tid\n\t\t\troles {\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t}\n"]),
    _templateObject3 = _taggedTemplateLiteral(["\n\tquery ratingStudentResult(\n\t\t$programId: ID!\n\t\t$boxId: ID!\n\t\t$elementId: ID!\n\t\t$studentId: ID!\n\t) {\n\t\tratingStudentResult(\n\t\t\tprogramId: $programId\n\t\t\tboxId: $boxId\n\t\t\telementId: $elementId\n\t\t\tstudentId: $studentId\n\t\t) {\n\t\t\tid\n\t\t\tassets {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\turl\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tquery ratingStudentResult(\n\t\t$programId: ID!\n\t\t$boxId: ID!\n\t\t$elementId: ID!\n\t\t$studentId: ID!\n\t) {\n\t\tratingStudentResult(\n\t\t\tprogramId: $programId\n\t\t\tboxId: $boxId\n\t\t\telementId: $elementId\n\t\t\tstudentId: $studentId\n\t\t) {\n\t\t\tid\n\t\t\tassets {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\turl\n\t\t\t}\n\t\t}\n\t}\n"]);

var _graphqlTag = require("graphql-tag");

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_RATING_REQUESTS = exports.GQL_FETCH_RATING_REQUESTS = (0, _graphqlTag2.default)(_templateObject);

var GQL_FETCH_CURRENT_USER = exports.GQL_FETCH_CURRENT_USER = (0, _graphqlTag2.default)(_templateObject2);

var GQL_FETCH_RATING_STUDENT_RESULT_ASSETS = exports.GQL_FETCH_RATING_STUDENT_RESULT_ASSETS = (0, _graphqlTag2.default)(_templateObject3);