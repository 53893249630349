"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _core = require("@material-ui/core");

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _InsertDriveFileOutlined = require("@material-ui/icons/InsertDriveFileOutlined");

var _InsertDriveFileOutlined2 = _interopRequireDefault(_InsertDriveFileOutlined);

var _Button = require("@material-ui/core/Button");

var _Button2 = _interopRequireDefault(_Button);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useStyles = (0, _core.makeStyles)(function (theme) {
	return {
		root: {
			width: "100%",
			minHeight: "150px",
			backgroundColor: "white",
			borderRadius: "4px",
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			padding: "40px",
			"@media (max-width: 500px)": {
				flexDirection: "column",
				gap: theme.spacing(2)
			}
		},
		iconContainer: {
			width: "60px",
			height: "60px",
			minWidth: "60px",
			border: "1px solid " + theme.manakin.defaultBorderColor[500],
			borderRadius: "100%",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			marginRight: "30px"
		},
		icon: {
			color: theme.manakin.indicationColor[500]
		},
		content: {
			margin: 0
		},
		button: {
			minHeight: "6rem",
			padding: "8px 43px",
			borderColor: theme.manakin.indicationColor[500]
		},
		devide: {
			display: "flex"
		}
	};
});

var BPVBDownloadButton = function BPVBDownloadButton(props) {
	var className = props.className,
	    _props$program = props.program,
	    program = _props$program === undefined ? {} : _props$program,
	    onClick = props.onClick;

	var classes = useStyles();

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	return _react2.default.createElement(
		"div",
		{ className: (0, _classnames2.default)(classes.root, className) },
		_react2.default.createElement(
			"div",
			{ className: classes.devide },
			_react2.default.createElement(
				"div",
				{ className: classes.iconContainer },
				_react2.default.createElement(_InsertDriveFileOutlined2.default, { className: classes.icon })
			),
			_react2.default.createElement(
				"div",
				{ className: classes.contentContainer },
				_react2.default.createElement(
					_core.Typography,
					{ variant: "h6", className: classes.content },
					t("common.program")
				),
				_react2.default.createElement(
					_core.Typography,
					{ className: classes.content },
					program.name || ""
				)
			)
		),
		_react2.default.createElement(
			_Button2.default,
			{
				variant: "outlined",
				color: "primary",
				className: classes.button,
				onClick: onClick
			},
			t("common.download")
		)
	);
};

exports.default = BPVBDownloadButton;