import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiTableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import { compose } from 'recompose';
import { withTranslation } from "react-i18next";

const styles = (theme) => ({
    root: {
        borderBottom: '40px',
    },
    emptyRow: {
        height: 49,
    },
    noBorder: {
        borderColor: 'transparent',
    },
    pointer: {
        cursor: 'pointer',
    },
    noresult: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        opacity: 0.3,
    },
    checkboxCell: {
        width: 1,
    },
});

class TableBody extends React.Component {
    handleRowClick = (row) => (event) => {
        if (this.props.onRowClick) {
            this.props.onRowClick(row);
        }
    };

    handleCheckboxClick = (row) => (event) => {
        event.stopPropagation();
        if (this.props.onToggleSelect) {
            this.props.onToggleSelect(row);
        }
    };

    handleCheckboxCellClick = (event) => {
        event.stopPropagation();
    };

    render() {
        const {
            classes,
            rows,
            rowsPerPage,
            columns,
            selection,
            onRowClick,
            loading,
            totalCount,
            t,
            hideCheckBoxes
        } = this.props;

        const rowClass = onRowClick ? classes.pointer : '';
        const loadingLength = loading ? 0 : rows.allIds.length;

        const fillerRows = Array.apply(
            null,
            Array(
                Math.max(
                    0,
                    Math.min(
                        totalCount - loadingLength,
                        rowsPerPage - loadingLength
                    )
                )
            )
        );
        
        return (
            <MuiTableBody>
                {!loading &&
                    rows.allIds.map((rowId) => {
                        const row = rows.byId[rowId];
                        const selected = selection.indexOf(rowId) >= 0;
                        return (
                            <TableRow
                                hover
                                onClick={this.handleRowClick(row)}
                                role="checkbox"
                                tabIndex={-1}
                                key={row.id}
                                selected={selected}
                                className={rowClass}
                            >
                                {!hideCheckBoxes && (
                                    <TableCell
                                        padding='checkbox'
                                        className={classes.checkboxCell}
                                        onClick={this.handleCheckboxCellClick}
                                    >
                                        <Checkbox
                                            onClick={this.handleCheckboxClick(row)}
                                            checked={selected}
                                            color='default'
                                        />
                                    </TableCell>
                                )}

                                {columns.allIds.map((columnId) => {
                                    const column = columns.byId[columnId];
                                    let value = row[column.id];
                                    let component = null;

                                    if (Array.isArray(row[column.id])) {
                                        if (
                                            row[column.id][0] &&
                                            row[column.id][0].title
                                        ) {
                                            value = row[column.id]
                                                .map((item) => item.title)
                                                .join(", ");
                                        } else if (
                                            row[column.id][0] &&
                                            row[column.id][0].name
                                        ) {
                                            value = row[column.id]
                                                .map((item) => item.name)
                                                .join(", ");
                                        } else {
                                            value = t("common.unknown");
                                        }
                                    }

                                    if (typeof row[column.id] === 'boolean') {
                                        value = row[column.id] ? t("common.yes") : t("common.no");
                                    }

                                    if (
                                        row[column.id] &&
                                        row[column.id].hasOwnProperty(
                                            'fullName'
                                        )
                                    ) {
                                        value = row[column.id].fullName;
                                    }
                                    if (
                                        row[column.id] &&
                                        row[column.id].hasOwnProperty('name')
                                    ) {
                                        value = row[column.id].name;
                                    }
                                    if (column.value) {
                                        value = column.value(row);
                                    }

                                    if (
                                        row[column.id] &&
                                        row[column.id].hasOwnProperty('value')
                                    ) {
                                        value = row[column.id].value;
                                        component = row[column.id].component;
                                    }

                                    return (
                                        <TableCell key={column.id}>
                                            {component || value}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                {fillerRows.length > 0 &&
                    fillerRows.map((fillerRow, index) => (
                        <TableRow key={index} className={classes.emptyRow}>
                            <TableCell
                                className={classes.noBorder}
                                colSpan={columns.allIds.length + 1}
                            >
                                {' '}
                            </TableCell>
                        </TableRow>
                    ))}
                {rows.allIds.length === 0 && !loading && (
                    <TableRow className={classes.emptyRow}>
                        <TableCell colSpan={columns.allIds.length + 1}>
                            <Typography
                                className={classes.noresult}
                                align="center"
                                variant="subtitle1"
                            >
                                {t("common.no-results-found")}
                            </Typography>
                        </TableCell>
                    </TableRow>
                )}
            </MuiTableBody>
        );
    }
}

export default compose(
    withTranslation(),
    withStyles(styles, { name: 'CoreTableBody' })
)(TableBody);
