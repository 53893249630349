import React, { useEffect, useState } from 'react';
import { SelectField } from '@manakin/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { GQL_FETCH_GROUP } from '../graphql';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) =>
        createStyles({
            root: {
                width: '100%',
            },
            paragraph: {
                textAlign: 'center',
                width: '100%',
                margin: '0 auto 3em',
                maxWidth: '560px',
            },
            heading: {},
        }),
    {
        name: 'AppRegistrationSchoolClassData',
    },
);

const SchoolClassData = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [ school, setSchool ] = useState(null);

    const { form } = props;
    const { fields: fieldsData } = form;

    useEffect(() => {
        form.onRequiredChange('schoolClasses', true);
    }, []);

    useQuery(
        GQL_FETCH_GROUP,
        {
            variables: {
                id: fieldsData.schoolClass,
            },
            onCompleted: (result) => {
                if (result && result.data && result.data.group && result.data.group.id && result.data.group.name) {
                    setSchool({
                        id: result.data.group.id,
                        name: result.data.group.name,
                    });
                }
            },
        },
    );

    const selectedSchool = school ? school : fieldsData.school ? { id: fieldsData.school } : [];

    return (
        <div className={classes.root}>
            <Typography className={classes.heading} variant='h2'>
                {t('authentication.registration.school-class-data.title')}
            </Typography>
            <Typography
                classes={{ body1: classes.paragraph }}
                variant='body1'
                gutterBottom
            >
                {t('authentication.registration.school-class-data.intro')}
            </Typography>
            <SelectField
                queryName='groups'
                label={t('authentication.registration.school-class-data.fields.school-class.label')}
                form={form}
                name='schoolClasses'
                options={[
                    {
                        label: 'Algemeen klassement', // TODO literal should this hardcoded entry be removed?
                        id: '4304',
                    },
                ]}
                initialValue={selectedSchool}
            />
        </div>
    );
};

export default SchoolClassData;
