"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _recompose = require("recompose");

var _styles = require("@material-ui/core/styles");

var _appCore = require("@manakin/app-core");

var _core = require("@manakin/core");

var _Button = require("@material-ui/core/Button");

var _Button2 = _interopRequireDefault(_Button);

var _Grid = require("@material-ui/core/Grid");

var _Grid2 = _interopRequireDefault(_Grid);

var _Typography = require("@material-ui/core/Typography");

var _Typography2 = _interopRequireDefault(_Typography);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _RatingRequestItem = require("./RatingRequestItem");

var _RatingRequestItem2 = _interopRequireDefault(_RatingRequestItem);

var _reactRedux = require("react-redux");

var _graphql = require("./graphql");

var _reactHooks = require("@apollo/react-hooks");

var _actions = require("@manakin/core/actions");

var _selectors = require("@manakin/app-core/Boxes/selectors");

var _selectors2 = require("@manakin/app-core/ProgramsDropdown/selectors");

var _selectors3 = require("@manakin/authentication/selectors");

var _reactRouterDom = require("react-router-dom");

var _MaxRequestsDialog = require("./MaxRequestsDialog");

var _MaxRequestsDialog2 = _interopRequireDefault(_MaxRequestsDialog);

var _lib = require("@manakin/app-core/lib");

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
	var _root;

	return {
		root: (_root = {
			width: "100%",
			backgroundColor: "white",
			maxWidth: "100%",
			padding: "25px"
		}, _defineProperty(_root, theme.breakpoints.up("md"), {
			padding: "6rem"
		}), _defineProperty(_root, theme.breakpoints.up("lg"), {
			padding: "10rem 15rem"
		}), _root),
		button: _defineProperty({
			textAlign: "left",
			justifyContent: "start",
			marginTop: 0,
			marginBottom: theme.spacing(4)
		}, theme.breakpoints.up("md"), {
			marginTop: theme.spacing(4)
		}),
		longArrowRoot: {
			marginLeft: "auto",
			marginRight: theme.spacing(1)
		},
		contentFirst: _defineProperty({}, theme.breakpoints.up("md"), {
			paddingRight: "100px"
		}),
		contentSecond: _defineProperty({}, theme.breakpoints.up("md"), {
			paddingLeft: "50px"
		}),
		firstRatingRequestItem: {
			borderTop: "2px solid " + theme.manakin.defaultBorderColor[200]
		},
		infoBox: {
			marginTop: theme.spacing(3)
		},
		header: {},
		subHeader: {
			fontWeight: "bold"
		},
		contentColor: {}
	};
};

var formBundle = {
	trainer: {}
};

var RatingRequest = function RatingRequest(props) {
	var classes = props.classes,
	    appUser = props.appUser,
	    form = props.form,
	    isFinalRating = props.isFinalRating,
	    theme = props.theme,
	    superUser = props.superUser;

	var dispatch = (0, _reactRedux.useDispatch)();
	var params = (0, _reactRouterDom.useParams)();

	var _useState = (0, _react.useState)(false),
	    _useState2 = _slicedToArray(_useState, 2),
	    showMaxRequestsDialog = _useState2[0],
	    setShowMaxRequestsDialog = _useState2[1];

	var isMM = appUser.roles.some(function (role) {
		return role.name === _lib.managementRole;
	});

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	// Queries


	var _useQuery = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_INVITATION_TRAINERS),
	    _useQuery$data = _useQuery.data,
	    invitationTrainersData = _useQuery$data === undefined ? {} : _useQuery$data;

	var _useQuery2 = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_RATING_REQUESTS, {
		variables: {
			programId: props.program,
			boxId: props.box,
			elementId: params.elementId,
			studentId: superUser || appUser.id
		}
	}),
	    _useQuery2$data = _useQuery2.data,
	    ratingRequestsData = _useQuery2$data === undefined ? {} : _useQuery2$data,
	    refetchRatingRequests = _useQuery2.refetch;

	var _invitationTrainersDa = invitationTrainersData.invitationTrainers,
	    invitationTrainers = _invitationTrainersDa === undefined ? [] : _invitationTrainersDa;
	var _ratingRequestsData$r = ratingRequestsData.ratingRequest,
	    ratingRequest = _ratingRequestsData$r === undefined ? [] : _ratingRequestsData$r;

	var hasPendingRatingRequest = ratingRequest && !!ratingRequest.find(function (it) {
		return it.result === null;
	});

	var firstRating = (0, _lib.getFirstOrUndefined)((0, _lib.filterRatingRequestsIgnoreBpvb)(ratingRequest.filter(function (rating) {
		return rating.attempt === 1;
	})));
	var secondRating = (0, _lib.getFirstOrUndefined)((0, _lib.filterRatingRequestsIgnoreBpvb)(ratingRequest.filter(function (rating) {
		return rating.attempt === 2;
	})));

	var trainers = invitationTrainers.filter(function (trainer) {
		return trainer.roles.some(function (i) {
			return i.name == _lib.poRole;
		});
	}).map(function (trainer) {
		return {
			name: trainer.fullName,
			id: trainer.id
		};
	});

	// Mutations

	var _useMutation = (0, _reactHooks.useMutation)(_graphql.GQL_CREATE_ELEMENT_RESULT),
	    _useMutation2 = _slicedToArray(_useMutation, 1),
	    createElementResult = _useMutation2[0];

	var _useMutation3 = (0, _reactHooks.useMutation)(_graphql.GQL_SEND_RATING_REQUEST),
	    _useMutation4 = _slicedToArray(_useMutation3, 2),
	    createRatingRequest = _useMutation4[0],
	    _useMutation4$ = _useMutation4[1],
	    createRatingRequestData = _useMutation4$.data,
	    createRatingRequestError = _useMutation4$.error;

	var handleRatingRequest = function handleRatingRequest() {
		if (isFinalRating && firstRating || !isFinalRating && firstRating && secondRating) {
			// If there already is a final rating requested, or two normal ratings, show the dialog
			return setShowMaxRequestsDialog(true);
		}

		var box = props.box,
		    program = props.program,
		    match = props.match;
		var onSubmit = form.onSubmit;

		form.onValidate().then(function () {
			return onSubmit().then(function (data) {
				createRatingRequest({
					variables: {
						program: parseInt(program),
						box: parseInt(box),
						element: parseInt(match.params.elementId),
						trainer: parseInt(data.trainer)
					}
				});
			});
		}, function (error) {
			// Handled by inputs
		});
	};

	var onSucces = (0, _react.useCallback)(function () {
		return dispatch((0, _actions.showSnackbarMessage)({
			text: t("app.rating-request.details.success"),
			variant: "success"
		}));
	}, [dispatch]);

	var onError = (0, _react.useCallback)(function () {
		return dispatch((0, _actions.showSnackbarMessage)({
			text: t("app.rating-request.details.failed"),
			variant: "error"
		}));
	}, [dispatch]);

	(0, _react.useEffect)(function () {
		if (createRatingRequestData && createRatingRequestData.createRatingRequest) {
			onSucces();
			form.clear();
			refetchRatingRequests();
		}

		if (createRatingRequestError) {
			onError();
		}
	}, [createRatingRequestData, createRatingRequestError]);

	(0, _react.useEffect)(function () {
		if (ratingRequest && ratingRequest.length) {
			var status = "FINISHED";
			if (ratingRequest.some(function (r) {
				return r.result === null;
			})) {
				status = "PENDING";
			}
			createElementResult({
				variables: {
					user: appUser.id,
					program: props.program,
					box: props.box,
					element: params.elementId,
					ratingTeacher: status
				}
			}).catch(function (e) {
				return console.log("e", e);
			});
		}
	}, [ratingRequest]);

	return _react2.default.createElement(
		_Grid2.default,
		{ container: true, className: classes.root },
		_react2.default.createElement(
			_Grid2.default,
			_extends({
				item: true
			}, { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }, {
				className: classes.contentFirst
			}),
			_react2.default.createElement(
				_Typography2.default,
				{
					component: "h2",
					variant: "h3",
					className: (0, _classnames2.default)(classes.header, classes.contentColor)
				},
				isFinalRating ? t("app.rating-request.details.final-rating.title") : t("app.rating-request.details.rating.title")
			),
			_react2.default.createElement(
				_Typography2.default,
				{ component: "div", variant: "body1" },
				isFinalRating ? t("app.rating-request.details.final-rating.description") : t("app.rating-request.details.rating.description")
			),
			_react2.default.createElement(
				_react2.default.Fragment,
				null,
				isFinalRating && firstRating || hasPendingRatingRequest ? "" : _react2.default.createElement(
					_react2.default.Fragment,
					null,
					!isMM && _react2.default.createElement(_core.SelectField, {
						options: trainers,
						label: t("common.fields.trainer.label"),
						placeholder: " ",
						form: form,
						name: "trainer"
					}),
					_react2.default.createElement(
						_Button2.default,
						{
							variant: "contained",
							color: "primary",
							fullWidth: true,
							className: (0, _classnames2.default)(classes.button),
							onClick: handleRatingRequest,
							disabled: isMM
						},
						t("app.rating-request.details.action"),
						_react2.default.createElement(_appCore.LongArrow, { className: classes.longArrowRoot })
					)
				)
			)
		),
		_react2.default.createElement(
			_Grid2.default,
			_extends({
				item: true
			}, { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }, {
				className: classes.contentSecond
			}),
			_react2.default.createElement(
				_Typography2.default,
				{
					component: "div",
					variant: "body1",
					className: (0, _classnames2.default)(classes.contentColor, classes.header, classes.subHeader)
				},
				firstRating ? isFinalRating ? t("app.rating-request.details.final-rating.status") : t("app.rating-request.details.rating.status") : ""
			),
			firstRating && _react2.default.createElement(_RatingRequestItem2.default, {
				className: classes.firstRatingRequestItem,
				title: isFinalRating ? t("app.rating-request.details.final-rating.first-rating") : t("app.rating-request.details.rating.first-rating"),
				attempt: 1,
				element: ratingRequest,
				data: firstRating,
				refetch: refetchRatingRequests,
				isFinalRating: isFinalRating,
				isMM: isMM
			}),
			secondRating && _react2.default.createElement(_RatingRequestItem2.default, {
				title: t("app.rating-request.details.rating.second-rating"),
				attempt: 2,
				element: ratingRequest,
				data: secondRating,
				refetch: refetchRatingRequests,
				isFinalRating: isFinalRating
			}),
			isFinalRating && firstRating && firstRating.result && (firstRating.result.ranking === "GOOD" || firstRating.result.ranking === "SUFFICIENT") && _react2.default.createElement(_appCore.WarningBox, {
				className: classes.infoBox,
				color: firstRating.result.ranking === "GOOD" ? theme.manakin.successColor[500] : theme.manakin.warningColor[500],
				message: _react2.default.createElement(
					_react2.default.Fragment,
					null,
					firstRating.result.ranking === "GOOD" ? t("app.rating-request.details.warning.first-part-good") : t("app.rating-request.details.warning.first-part-sufficient"),
					" ",
					_react2.default.createElement(
						_reactRouterDom.Link,
						{ to: "/account/bpvb" },
						t("app.rating-request.details.warning.second-part-profile"),
						" "
					),
					t("app.rating-request.details.warning.third-part-download-report")
				)
			})
		),
		_react2.default.createElement(_MaxRequestsDialog2.default, {
			isFinalRating: isFinalRating,
			open: showMaxRequestsDialog,
			onClose: function onClose() {
				return setShowMaxRequestsDialog(false);
			}
		})
	);
};

exports.default = (0, _recompose.compose)((0, _styles.withStyles)(styles, { name: "AppRatingRequest", withTheme: true }), (0, _core.withForm)(formBundle), _reactRouterDom.withRouter, (0, _reactRedux.connect)(function (state) {
	return {
		program: (0, _selectors2.getProgram)(state),
		box: (0, _selectors.getBoxId)(state),
		appUser: (0, _selectors3.getAppUser)(state),
		superUser: (0, _selectors3.getSuperUser)(state)
	};
}))(RatingRequest);