import React from "react";
import { VideoPlayer } from "@manakin/app-core";
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import { useQuery } from "@apollo/react-hooks";
import { GQL_FETCH_CMS_HOME_PAGE_BLOCK_VIDEO_WITH_TITLE } from "../graphql";

const useStyles = makeStyles(
    (theme) =>
        createStyles({
            root: {
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
            },
            contentColor: {
                "&::after": {
                    backgroundColor: theme.manakin.indicationColor[500],
                },
            },
            secondaryColors: {
                paddingTop: "3rem",
                paddingBottom: "3rem",
                backgroundColor: theme.manakin.homePageBlockSecondaryBackgroundColor[500],
                "& $contentColor": {
                    color: theme.palette.getContrastText(
                        theme.manakin.homePageBlockSecondaryBackgroundColor[500],
                    ),
                    "&::after": {
                        backgroundColor: theme.palette.getContrastText(
                            theme.manakin.homePageBlockSecondaryBackgroundColor[500],
                        ),
                    },
                },
                [theme.breakpoints.up("md")]: {
                    padding: "10rem",
                },
            },
            primaryColors: {
                paddingTop: "3rem",
                paddingBottom: "3rem",
                backgroundColor: theme.manakin.homePageBlockPrimaryBackgroundColor[500],
                "& $contentColor": {
                    color: theme.palette.getContrastText(
                        theme.manakin.homePageBlockPrimaryBackgroundColor[500],
                    ),
                    "&::after": {
                        backgroundColor: theme.palette.getContrastText(
                            theme.manakin.homePageBlockPrimaryBackgroundColor[500],
                        ),
                    },
                },
                [theme.breakpoints.up("md")]: {
                    paddingTop: "10rem",
                    paddingBottom: "10rem",
                },
            },
            biggerAnswer: {},
            container: {
                display: "flex",
                flexDirection: "column",
                width: "100%",
                maxWidth: "1200px",
                [theme.breakpoints.up("md")]: {
                    flexDirection: "row",
                },
                [theme.breakpoints.up("lg")]: {},
            },
            fullWidth: {
                maxWidth: "100%",
            },
            answers: {
                [theme.breakpoints.up("md")]: {
                    width: "50%",
                },
                "&$biggerAnswer": {
                    [theme.breakpoints.up("md")]: {
                        width: "60%",
                    },
                },
            },
            question: {
                [theme.breakpoints.up("md")]: {
                    paddingRight: theme.manakin.defaultPadding,
                    width: "50%",
                },
                "&$biggerAnswer": {
                    [theme.breakpoints.up("md")]: {
                        width: "40%",
                    },
                },
            },
            quarterQuestion: {
                [theme.breakpoints.up("md")]: {
                    width: "37.5%",
                },
            },
            columnDirection: {
                flexDirection: "column",
                "& $answers": {
                    [theme.breakpoints.up("md")]: {
                        width: "100%",
                    },
                    "&$biggerAnswer": {
                        [theme.breakpoints.up("md")]: {
                            width: "100%",
                        },
                    },
                },
                "& $question": {
                    [theme.breakpoints.up("md")]: {
                        width: "100%",
                    },
                    "&$biggerAnswer": {
                        [theme.breakpoints.up("md")]: {
                            width: "100%",
                        },
                    },
                },
            },
            mirror: {
                "& $question": {
                    [theme.breakpoints.up("md")]: {
                        paddingLeft: theme.manakin.defaultPadding,
                        order: 2,
                    },
                },
                "& $answers": {
                    [theme.breakpoints.up("md")]: {
                        paddingLeft: 0,
                    },
                },
                "& $wrapper": {
                    [theme.breakpoints.up("md")]: {
                        paddingLeft: theme.manakin.defaultPadding,
                        paddingRight: 0,
                    },
                },
            },
            header: {
                fontWeight: "bold",
                marginBottom: "7rem",
                fontSize: "4rem",
                marginTop: "1.8rem",
                [theme.breakpoints.down("xs")]: {
                    fontSize: "2.4rem",
                    lineHeight: "4rem",
                    marginBottom: "3rem",
                },
            },
            subHeader: {},
            wrapper: {
                ...theme.manakin.defaultWrapper,
                [theme.breakpoints.up("md")]: {
                    maxWidth: "500px",
                    paddingLeft: "0",
                    margin: "0",
                },
            },
            fullAnswers: {
                [theme.breakpoints.up("md")]: {
                    width: "62.5%",
                },
            },
        }),
    {
        name: "AppAppHomepageBlockVideoWithTitle",
    },
);

const VideoWithTitleBlock = props => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { data = {} } = useQuery(GQL_FETCH_CMS_HOME_PAGE_BLOCK_VIDEO_WITH_TITLE, {
        variables: {
            id: props.id,
        },
    });
    const { videoWithTitleHomepageBlock = {} } = data;
    const { backgroundColor, fullWidth, title, introduction, direction, video, subtitle } = videoWithTitleHomepageBlock;

    return (
        <div
            className={classNames(
                classes.root,
                { [classes.primaryColors]: backgroundColor === "PRIMARY" },
                { [classes.secondaryColors]: backgroundColor === "SECONDARY" },
            )}
        >
            <div
                className={classNames(classes.container, classes.mirror, {
                    [classes.fullWidth]: fullWidth,
                    [classes.columnDirection]: direction === "column"
                })}
            >
                <div className={classNames(classes.question, classes.quarterQuestion)}>
                    <div className={classes.wrapper}>
                        <Typography
                            component='p'
                            variant='subtitle1'
                            className={classNames("smallUnderline", classes.subHeader, classes.contentColor)}
                        >
                            {introduction || t("cms.homepage.details.video-with-title.intro")}
                        </Typography>
                        <Typography
                            component='h2'
                            variant='h3'
                            className={classNames(classes.header, classes.contentColor)}
                        >
                            {title || ""}
                        </Typography>
                    </div>
                </div>
                <div className={classNames(classes.answers, classes.fullAnswers)}>
                    <VideoPlayer
                        videoId={video}
                        playing={false}
                        subtitle={subtitle}
                    />
                </div>
            </div>

        </div>
    );
};

export default VideoWithTitleBlock;
