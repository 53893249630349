'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _MenuItem = require('@material-ui/core/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _Select = require('@material-ui/core/Select');

var _Select2 = _interopRequireDefault(_Select);

var _jsCookie = require('js-cookie');

var _jsCookie2 = _interopRequireDefault(_jsCookie);

var _graphql = require('../graphql');

var _reactI18next = require('react-i18next');

var _reactApollo = require('react-apollo');

var _core = require('@material-ui/core');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useStyles = (0, _core.makeStyles)(function (theme) {
	return {
		selectFieldContainer: {
			position: 'absolute',
			right: '30px',
			top: '15px',
			zIndex: 99
		},
		selectFieldRoot: {
			textTransform: 'uppercase'
		},
		menuItemRoot: {
			textTransform: 'uppercase',
			width: '50px'
		}
	};
});

var LocaleSelectField = function LocaleSelectField() {
	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    i18n = _useTranslation.i18n;

	var classes = useStyles();

	var _useState = (0, _react.useState)([]),
	    _useState2 = _slicedToArray(_useState, 2),
	    locales = _useState2[0],
	    setLocales = _useState2[1];

	var _useState3 = (0, _react.useState)(''),
	    _useState4 = _slicedToArray(_useState3, 2),
	    localeValue = _useState4[0],
	    setLocaleValue = _useState4[1];

	var _useQuery = (0, _reactApollo.useQuery)(_graphql.GQL_FETCH_LOCALES, {
		onCompleted: function onCompleted() {
			var locOptions = [];

			if (data && data.locales && data.locales.locales) {
				locOptions = data.locales.locales.map(function (locale) {
					if (locale.enabled) {
						return {
							id: locale.code,
							label: locale.code.substring(0, 2)
						};
					}

					return null;
				});

				setLocales(locOptions.filter(function (locale) {
					return locale !== null;
				}));
			}
		}
	}),
	    data = _useQuery.data;

	(0, _react.useEffect)(function () {
		var locale = _jsCookie2.default.get('locale') || 'nl_NL';
		handleSetLanguage(locale);
	}, []);

	var handleSetLanguage = function handleSetLanguage(locale) {
		setLocaleValue(locale);
		i18n.changeLanguage(locale);
	};

	var handleChangeLanguage = function handleChangeLanguage(event) {
		var newLocale = event.target.value;
		if (newLocale) {
			_jsCookie2.default.set('locale', newLocale);
			handleSetLanguage(newLocale);
		}
	};

	return _react2.default.createElement(
		'div',
		{ className: classes.selectFieldContainer },
		_react2.default.createElement(
			_Select2.default,
			{ fullWidth: true, name: 'locale', onChange: handleChangeLanguage, value: localeValue, classes: { root: classes.selectFieldRoot } },
			locales && locales.map(function (locale) {
				return _react2.default.createElement(
					_MenuItem2.default,
					{ classes: { root: classes.menuItemRoot }, key: locale.id, value: locale.id },
					locale.label
				);
			})
		)
	);
};

exports.default = LocaleSelectField;