'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _recompose = require('recompose');

var _styles = require('@material-ui/core/styles');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _reactI18next = require('react-i18next');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
    return {
        root: {
            display: 'flex',
            flexDirection: "row"
        },
        chip: _defineProperty({
            borderRadius: "100px",
            padding: theme.spacing(0.5),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            border: '2px solid ' + theme.manakin.defaultBorderColor[200],
            marginRight: theme.spacing(2),
            cursor: "pointer",
            background: "white"
        }, theme.breakpoints.down('md'), {
            marginRight: theme.spacing()
        }),
        title: {
            marginBottom: 0,
            fontSize: "1.5rem"
        },
        good: {
            border: '2px solid ' + theme.manakin.successColor[500],
            backgroundColor: theme.manakin.successColor[500],
            "& > div": {
                color: "white"
            }
        },
        sufficient: {
            border: '2px solid ' + theme.manakin.warningColor[500],
            backgroundColor: theme.manakin.warningColor[500],
            "& > div": {
                color: "white"
            }
        },
        insufficient: {
            border: '2px solid ' + theme.manakin.wrongColor[500],
            backgroundColor: theme.manakin.wrongColor[500],
            "& > div": {
                color: "white"
            }
        }
    };
};

var RatingChipGroup = function RatingChipGroup(props) {
    var classes = props.classes,
        data = props.data,
        form = props.form,
        name = props.name,
        initialValue = props.initialValue,
        readOnly = props.readOnly;

    var _useState = (0, _react.useState)(null),
        _useState2 = _slicedToArray(_useState, 2),
        selectedRating = _useState2[0],
        setSelectedRating = _useState2[1];

    var _useTranslation = (0, _reactI18next.useTranslation)(),
        t = _useTranslation.t;

    var handleSelectRating = function handleSelectRating(rating) {
        if (readOnly) return;
        setSelectedRating(rating);
        form.onFieldChange({ key: name, value: rating });
    };

    (0, _react.useEffect)(function () {
        setSelectedRating(initialValue);
        form.onFieldChange({ key: name, value: initialValue });
    }, [initialValue]);

    return _react2.default.createElement(
        'div',
        { className: classes.root },
        _react2.default.createElement(
            'div',
            {
                onClick: function onClick() {
                    return handleSelectRating("GOOD");
                },
                className: (0, _classnames2.default)(classes.chip, _defineProperty({}, classes.good, selectedRating === "GOOD")) },
            _react2.default.createElement(
                _Typography2.default,
                {
                    component: 'div',
                    variant: 'body1',
                    className: (0, _classnames2.default)(classes.contentColor, classes.title) },
                t("app.rating-request.rating.good")
            )
        ),
        _react2.default.createElement(
            'div',
            {
                onClick: function onClick() {
                    return handleSelectRating("SUFFICIENT");
                },
                className: (0, _classnames2.default)(classes.chip, _defineProperty({}, classes.sufficient, selectedRating === "SUFFICIENT")) },
            _react2.default.createElement(
                _Typography2.default,
                {
                    component: 'div',
                    variant: 'body1',
                    className: (0, _classnames2.default)(classes.contentColor, classes.title) },
                t("app.rating-request.rating.sufficient")
            )
        ),
        _react2.default.createElement(
            'div',
            {
                onClick: function onClick() {
                    return handleSelectRating("INSUFFICIENT");
                },
                className: (0, _classnames2.default)(classes.chip, _defineProperty({}, classes.insufficient, selectedRating === "INSUFFICIENT")) },
            _react2.default.createElement(
                _Typography2.default,
                {
                    component: 'div',
                    variant: 'body1',
                    className: (0, _classnames2.default)(classes.contentColor, classes.title) },
                t("app.rating-request.rating.insufficient")
            )
        )
    );
};

exports.default = (0, _recompose.compose)((0, _styles.withStyles)(styles, { name: 'AppRatingChipGroup' }))(RatingChipGroup);