import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
    (theme) =>
        createStyles({
            loginRoot: {
                width: '100%',
                backgroundColor: theme.manakin.secondaryColor[500],
                padding: '20px',
                [theme.breakpoints.up('md')]: {
                    padding: '50px',
                },
            },
            root: {
                width: '100%',
            },
            groupElements: {
                '@media (min-width: 700px)': {
                    display: 'flex',
                    justifyContent: 'space-between',
                    '&>*': {
                        flex: '0 0 auto',
                        width: '300px',
                    },
                },
            },
            paragraph: {
                textAlign: 'center',
                width: '100%',
                margin: '0 auto 3em',
                maxWidth: '560px',
            },
            heading: {},
            loginHeading: {
                color: theme.palette.getContrastText(
                    theme.manakin.secondaryColor[500]
                ),
            },
            body: {
                color: theme.palette.getContrastText(
                    theme.manakin.secondaryColor[500]
                ),
            },
        }),
    {
        name: 'AppRegistrationPersonalData',
    }
);
