import gql from "graphql-tag";

export const GQL_FETCH_FEEDBACK = gql`
	query feedback(
		$askFeedbackId: ID!
		$boxId: ID!
		$elementId: ID!
		$programId: ID!
		$userId: ID!
	) {
		feedback(
			askFeedbackId: $askFeedbackId
			boxId: $boxId
			elementId: $elementId
			programId: $programId
			userId: $userId
		) {
			feedback
			respondent
			token
			createdOn
			feedbackOn
		}
	}
`;

export const GQL_MUTATE_FEEDBACK_LINK = gql`
	mutation generateFeedbackLink($input: GenerateFeedbackLinkInput) {
		generateFeedbackLink(input: $input) {
			feedback {
				token
				feedbackLink
			}
		}
	}
`;
