export const REFRESH_DATA = '@manakin/core/REFRESH_DATA';

export function refreshData(name) {
    return {
        type: REFRESH_DATA,
        payload: {
            name,
        },
    };
}
