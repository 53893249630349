import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizontalIcon from '@material-ui/icons/MoreHoriz';
import createUuid from 'uuid/v4';
import { withStyles } from '@material-ui/core/styles';

const styles = {};

class MenuButton extends React.Component {
    state = {
        showMenu: null,
        uuid: createUuid(),
    };

    handleClick = (event) => {
        this.setState({ showMenu: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ showMenu: null });
    };

    render() {
        const { showMenu, uuid } = this.state;
        const { classes, items } = this.props;

        return (
            <div>
                <IconButton
                    aria-owns={Boolean(showMenu) ? uuid : null}
                    aria-haspopup="true"
                    onClick={this.handleClick}
                >
                    <MoreHorizontalIcon className={classes.menuIcon} />
                </IconButton>
                <Menu
                    id={uuid}
                    anchorEl={showMenu}
                    open={Boolean(showMenu)}
                    onClose={this.handleClose}
                >
                    {items &&
                        items.map((item) => (
                            <MenuItem
                                key={item.id}
                                className={classes.menuItem}
                                onClick={() => onMenuItemClick(item.id)}
                            >
                                {item.title}
                            </MenuItem>
                        ))}
                </Menu>
            </div>
        );
    }
}

export default withStyles(styles)(MenuButton);
