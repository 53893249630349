import React, { useEffect } from 'react';
import { GQL_FETCH_SETTINGS } from './graphql';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { SettingsKeys } from '@manakin/core/lib/constants';
import { Loader } from '@manakin/app-core';
import { useSetting } from '@manakin/hooks';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

const useStyles = (props = {}) =>
	makeStyles(
		(theme) =>
			createStyles({
				root: {
					backgroundColor: props.loginBackgroundColor || theme.palette.primary[500],
					...(theme.manakin.loginBackgroundImage && {
						backgroundImage: 'url(' + theme.manakin.loginBackgroundImage + ')',
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover',
					}),
					minHeight: '100vh',
					width: '100vw',
					display: 'flex',
					flexFlow: 'row wrap',
					justifyContent: 'center',
					alignItems: 'center',
				},
				paper: {
					padding: theme.spacing(6),
					minWidth: 280
				},
			}),
		{
			name: 'appSsoLogin',
		},
	);

const SsoLogin = (props) => {
	const { data = {}, loading } = useQuery(GQL_FETCH_SETTINGS);
	const loginBackgroundColor = useSetting(data.settings, SettingsKeys.HOME_PAGE_BACKGROUND_COLOR, false);
	const { t } = useTranslation();
	const ssoUrl = process.env.REACT_APP_SSO_URL || undefined;

	const classes = useStyles({
		loginBackgroundColor: loginBackgroundColor,
	})();

    useEffect(() => {
		if (ssoUrl) {
			window.location.href = ssoUrl;
		}
	}, [ssoUrl]);

	if (loading) return <Loader fullScreen />;
	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				<Typography variant='body1'>
					{t('authentication.sso.login-redirect.instruction')}{' '}
					<br/>
					<a className={classes.link} href={ssoUrl || ''}>
						{t('authentication.sso.login-redirect.label')}
					</a>
				</Typography>
			</Paper>
		</div>
	);
};

export default SsoLogin;
