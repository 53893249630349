import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import createUuid from 'uuid/v4';
import DeleteIcon from '@manakin/core/icons/Delete';
import EditIcon from '@manakin/core/icons/Edit';
import MoveIcon from '@manakin/core/icons/Move';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";

const styles = (theme) => ({
    root: {
        height: '100%',
        minHeight: '260px',
        display: 'flex',
        cursor: 'pointer',
        transition: 'transform .2s',
        transform: 'scale(1)',
        '&:hover': {
            transform: 'scale(1.05)',
        },
    },
    cardContent: {
        padding: '64px 26px',
    },
    cardContentWithOverlay: {
        '&:after': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            zIndex: 0,
            background:
                'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%)',
        },
        '&>*': {
            position: 'relative',
            zIndex: 1,
        },
    },
    cardContentWithOverlayAndSelected: {
        '&:after': {
            background:
                'linear-gradient(180deg, rgba(0, 77, 255, 0.3) 0%, rgba(0, 77, 255, 0.6) 100%)',
        },
    },
    card: {
        boxShadow: 'none',
        backgroundColor: theme.palette.grey[100],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: 260,
        width: '100%',
        borderRadius: '10px',
        paddingBottom: '40px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    cardTags: {
        position: 'absolute',
        bottom: '18px',
        left: '26px',
        fontSize: '16px',
        fontStyle: 'italic',
    },
    cardTitle: {
        fontSize: '30px',
    },
    cardWithBackground: {
        position: 'relative',
    },
    cardContentWithImage: {
        color: 'white',
        overflow: 'hidden',
    },
    cardSelected: {
        backgroundColor: 'blue',
    },
    deleteIcon: {
        position: 'absolute',
        right: '16px',
        top: '16px',
    },
});

class StandardCard extends React.Component {
    state = {
        uuid: createUuid(),
        hasImage: false,
    };

    handleClick = (event) => {
        const { deleteMode, editMode, moveMode, item = {} } = this.props;
        const id = item.id;

        if (deleteMode) {
            this.handleDelete(id, event);
        } else if (editMode) {
            this.handleEdit(id, event);
        } else if (moveMode) {
            this.handleMove(id, event);
        } else {
            if (this.props.onClick) {
                // If we for example want to select items from the card grid, we'll need an onclick hook
                this.props.onClick();
            } else {
                const { history, url, id, item } = this.props;
                const newUrl = item.type
                    ? `${url}/${item.type}/${id}`
                    : `${url}/${id}`;
                history.push(newUrl);
            }
        }
    };

    handleMiddleClick = (event) => {
        const { url, id, item } = this.props;
        if (event.button === 1) {
            // Middle mouse click, open in new page
            if (url) {
                const newUrl = item.type
                    ? `${url}/${item.type}/${id}`
                    : `${url}/${id}`;
                window.open(newUrl, '_blank');
            } else if (item && item.redirect) {
                window.open(item.redirect, '_blank');
            }
        }
    };

    handleDelete = (event) => {
        if (event) event.stopPropagation();
        this.props.onDelete(this.props.item.id);
    };

    handleEdit = (event) => {
        if (event) event.stopPropagation();
        this.props.onEdit(this.props.item.id);
    };

    handleMove = (event) => {
        if (event) event.stopPropagation();
        this.props.onMove(this.props.item.id);
    };

    useWhiteOrBlackTextColor = (hex) => {
        if (hex && hex.length >= 7) {
            const hexToRGB = (hex) => [
                ('0x' + hex[1] + hex[2]) | 0,
                ('0x' + hex[3] + hex[4]) | 0,
                ('0x' + hex[5] + hex[6]) | 0,
            ];
            const rgb = hexToRGB(hex);
            return (rgb[0] * 299 + rgb[1] * 587 + rgb[1] * 114) / 1000 > 125
                ? '#74748B'
                : '#FFFFFF';
        } else {
            return '#FFFFFF';
        }
    };

    render() {
        const {
            className: classNameProp,
            classes,
            title,
            deleteMode,
            editMode,
            moveMode,
            moveSource,
            item,
            selected: isSelected,
            config = {},
            t
        } = this.props;
        const { uuid } = this.state;
        const { general = {} } = config;

        const className = classNames(classes.root, classNameProp);
        let backgroundImage = item.image
            ? `url("${item.image.url}")`
            : general.hideDummy
            ? null
            : 'url("/cms/images/dummy-image.png")';
        let background = {
            backgroundImage: backgroundImage,
            position: 'relative',
        };

        let textStyle = {};
        if (!backgroundImage) {
            background = {};
        }
        if (item.cardColor) {
            backgroundImage = null;
            textStyle.color = this.useWhiteOrBlackTextColor(item.cardColor);
            background = { backgroundColor: item.cardColor };
        }

        const selected = isSelected; //&& !editMode;

        const hasTags = item.tags && item.tags.length > 0;

        return (
            <div
                className={className}
                onClick={this.handleClick}
                onMouseDown={this.handleMiddleClick}
            >
                <Card
                    key={uuid}
                    className={classNames(
                        classes.card,
                        {
                            [classes.cardWithBackground]: backgroundImage,
                        },
                        selected ? classes.cardSelected : undefined
                    )}
                    style={background}
                >
                    {deleteMode && (
                        <IconButton
                            className={classes.deleteIcon}
                            onClick={this.handleDelete}
                        >
                            <DeleteIcon
                                style={{
                                    color:
                                        this.useWhiteOrBlackTextColor(
                                            item.cardColor
                                        ) || '',
                                }}
                            />
                        </IconButton>
                    )}
                    {editMode && (
                        <IconButton
                            className={classes.deleteIcon}
                            onClick={this.handleEdit}
                        >
                            <EditIcon
                                style={{
                                    color:
                                        this.useWhiteOrBlackTextColor(
                                            item.cardColor
                                        ) || '',
                                }}
                            />
                        </IconButton>
                    )}
                    {moveMode &&
                        moveSource(
                            <div>
                                <IconButton
                                    className={classes.deleteIcon}
                                    onClick={this.handleMove}
                                >
                                    <MoveIcon
                                        style={{
                                            color:
                                                this.useWhiteOrBlackTextColor(
                                                    item.cardColor
                                                ) || '',
                                        }}
                                    />
                                </IconButton>
                            </div>
                        )}
                    <CardHeader />
                    <CardContent
                        className={classNames(classes.cardContent, {
                            [classNames(
                                classes.cardContentWithOverlay,
                                selected
                                    ? classes.cardContentWithOverlayAndSelected
                                    : undefined
                            )]: backgroundImage,
                        })}
                    >
                        {item.prefix && (
                            <Typography
                                className={classNames(classes.cardPrefix, {
                                    [classes.cardContentWithImage]: backgroundImage,
                                })}
                                style={textStyle}
                                variant="caption"
                                gutterBottom
                                align="left"
                            >
                                {item.prefix}
                            </Typography>
                        )}
                        <Typography
                            className={classNames(classes.cardTitle, {
                                [classes.cardContentWithImage]: backgroundImage,
                            })}
                            style={textStyle}
                            variant="h5"
                            component="h3"
                        >
                            {title}
                        </Typography>
                        {hasTags && (
                            <Typography
                                className={classNames(classes.cardTags, {
                                    [classes.cardContentWithImage]: backgroundImage,
                                })}
                                style={textStyle}
                                variant="body1"
                            >
                                {t("core.standard-card.tags")}:{' '}
                                {item.tags.map(
                                    (item, index) =>
                                        (index > 0 ? ', ' : '') + item.name
                                )}
                            </Typography>
                        )}
                    </CardContent>
                </Card>
            </div>
        );
    }
}

export default compose(
    connect((state) => ({ config: state.config })),
    withTranslation(),
    withRouter,
    withStyles(styles, { name: 'cmsStandardCard' })
)(StandardCard);
