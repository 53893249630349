import React, { useContext, useEffect } from 'react';
import Introduction from './parts/Introduction';
import Product from './Product';
import useStyles from './styles';
import ShoppingListContext from './context/shoppingListContext';
import StepsContext from './context/stepsContext';
import { useQuery } from 'react-apollo';
import { GQL_FETCH_PRODUCTS } from '../graphql';
import { useTranslation } from 'react-i18next';
import { Loader } from '@manakin/app-core';
import { WebshopSteps } from '../lib';

const ProductsData = (props) => {
	const classes = useStyles();
	const { products } = useContext(ShoppingListContext);
	const { step, setDisabled } = useContext(StepsContext);
	const { t } = useTranslation();

	const { data, loading, error } = useQuery(GQL_FETCH_PRODUCTS);
	const { webshopProducts = [] } = data || {};

	useEffect(() => {
		var productsAmount = Object.keys(products).length;
		if (productsAmount > 0) {
			setDisabled(WebshopSteps.FINISHED);
		} else {
			setDisabled(step);
		}
	}, [products]);

	if (error) return <React.Fragment>{t('common.something-went-wrong')}</React.Fragment>;
	if (loading) <Loader fullscreen />;
	return (
		<React.Fragment>
			<Introduction
				title={t('authentication.webshop.products-data.title')}
				introduction={t('authentication.webshop.products-data.introduction')}
			/>
			<div className={classes.content}>
				<div className={classes.extraLargeWrapper}>
					<div className={classes.products}>
						{webshopProducts.map((product) => (
							<Product key={product.id} data={product} idx={product.id} />
						))}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default ProductsData;
