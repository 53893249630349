import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { SimpleTable } from '@manakin/app-core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import moment from 'moment';
import 'moment/min/locales';

const History = (props) => {
	const { title, history = [] } = props;
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.history}>
			<div className={classes.wrapper}>
				<Typography variant="h4">{title || t('common.results-page.view-score')}</Typography>

				<div className={classes.table}>
					<SimpleTable
						renderHead={() => (
							<React.Fragment>
								<TableCell align="left"></TableCell>
								<TableCell align="left"></TableCell>
								<TableCell align="left"></TableCell>
							</React.Fragment>
						)}
						renderBody={() => (
							<React.Fragment>
								{history.map((item) => (
									<TableRow key={item.id}>
										<TableCell align="left">
											<Typography variant="body1">
												{item.createdOn ? moment(item.createdOn).locale('nl').format('D MMMM YYYY') : ''}
											</Typography>
										</TableCell>
										<TableCell align="left">
											<Typography variant="body1">{item.score}</Typography>
										</TableCell>
										<TableCell align="left">
											<Typography variant="body1">{item.tags.join(', ')}</Typography>
										</TableCell>
									</TableRow>
								))}
							</React.Fragment>
						)}
					/>
				</div>
			</div>
		</div>
	);
};

export default History;
