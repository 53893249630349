"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactCalendar = require("react-calendar");

var _reactCalendar2 = _interopRequireDefault(_reactCalendar);

var _styles = require("./styles");

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

require("moment/min/locales");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CalendarComponent = function CalendarComponent(props) {
	var initialValue = props.initialValue,
	    disabled = props.disabled;

	var classes = (0, _styles.useStyles)();

	var getMinDate = function getMinDate() {
		var now = new Date();
		now.setHours(0, 0, 0, 0);
		return now;
	};

	// Functions
	var handleDateChange = function handleDateChange(event) {
		if (disabled) return;
		if (props.onClick) props.onClick(event);
	};

	var tileClassName = function tileClassName(_ref) {
		var date = _ref.date;

		var _date = (0, _moment2.default)(date);
		if (initialValue.some(function (i) {
			return (0, _moment2.default)(i).isSame(_date, "day");
		})) {
			return classes.active;
		}
	};

	return _react2.default.createElement(
		"div",
		{ className: classes.calenderRoot },
		_react2.default.createElement(_reactCalendar2.default, {
			value: new Date(2017, 0, 1),
			defaultActiveStartDate: new Date(),
			onChange: handleDateChange,
			next2Label: "",
			minDate: getMinDate(),
			prev2Label: "",
			tileClassName: tileClassName
		})
	);
};

exports.default = CalendarComponent;