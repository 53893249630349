import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        background: 'rgb(0 0 0 / 25%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        maxWidth: '500px',
        width: 'calc(100% - 3rem)',
        background: theme.palette.info.contrastText,
        padding: '6.5rem 4.5rem ',
        textAlign: 'center',
        lineHeight: '2.5rem',
        zIndex: '1',
    },
    progress: {
        marginTop: "4rem"
    },
}));


const PendingOverlay = (props) => {
    const classes = useStyles()
    const { t } = useTranslation();

    return(
        <div className={classes.root}>
            <div className={classes.container}>
                {t('common.connection-failed')}
                <CircularProgress className={classes.progress}/>
            </div>
        </div>
    )
}

export default PendingOverlay;