'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styles = require('@material-ui/core/styles');

var _selectors = require('@manakin/authentication/selectors');

var _graphql = require('./graphql');

var _index = require('./styles/index');

var _Bridge = require('./parts/Bridge');

var _Bridge2 = _interopRequireDefault(_Bridge);

var _SlideShowCreator = require('./parts/SlideShowCreator');

var _SlideShowCreator2 = _interopRequireDefault(_SlideShowCreator);

var _BookDownload = require('./parts/BookDownload');

var _BookDownload2 = _interopRequireDefault(_BookDownload);

var _BookCheck = require('./parts/BookCheck');

var _BookCheck2 = _interopRequireDefault(_BookCheck);

var _DefaultCard = require('./parts/DefaultCard');

var _DefaultCard2 = _interopRequireDefault(_DefaultCard);

var _Lesson = require('./parts/Lesson');

var _Lesson2 = _interopRequireDefault(_Lesson);

var _DefaultItem = require('./parts/DefaultItem');

var _DefaultItem2 = _interopRequireDefault(_DefaultItem);

var _VideoBridge = require('./parts/VideoBridge');

var _VideoBridge2 = _interopRequireDefault(_VideoBridge);

var _reactRedux = require('react-redux');

var _reactHooks = require('@apollo/react-hooks');

var _reactRouterDom = require('react-router-dom');

var _RatingCard = require('./parts/RatingCard');

var _RatingCard2 = _interopRequireDefault(_RatingCard);

var _FinalRatingCard = require('./parts/FinalRatingCard');

var _FinalRatingCard2 = _interopRequireDefault(_FinalRatingCard);

var _selectors2 = require('@manakin/app-core/Boxes/selectors');

var _selectors3 = require('@manakin/app-core/ProgramsDropdown/selectors');

var _FactCardsCard = require('../Elements/FactCardsCard');

var _FactCardsCard2 = _interopRequireDefault(_FactCardsCard);

var _reactI18next = require('react-i18next');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ElementGridCard = function ElementGridCard(props) {
	var item = props.item,
	    classes = props.classes,
	    _props$disabled = props.disabled,
	    disabled = _props$disabled === undefined ? false : _props$disabled,
	    idx = props.idx;

	var appUser = (0, _reactRedux.useSelector)(function (state) {
		return (0, _selectors.getAppUser)(state);
	});
	var boxId = (0, _reactRedux.useSelector)(function (state) {
		return (0, _selectors2.getBoxId)(state);
	});
	var programId = (0, _reactRedux.useSelector)(function (state) {
		return (0, _selectors3.getProgram)(state);
	});
	var config = (0, _reactRedux.useSelector)(function (state) {
		return state.config;
	}) || {};

	var _item$tags = item.tags,
	    tags = _item$tags === undefined ? [] : _item$tags;

	var history = (0, _reactRouterDom.useHistory)();

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	//queries


	var _useQuery = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_GLOBAL_SETTINGS),
	    _useQuery$data = _useQuery.data,
	    data = _useQuery$data === undefined ? {} : _useQuery$data;

	var _data$settings = data.settings,
	    settings = _data$settings === undefined ? [] : _data$settings;

	var _useState = (0, _react.useState)(),
	    _useState2 = _slicedToArray(_useState, 2),
	    compSettings = _useState2[0],
	    setCompSettings = _useState2[1];

	var _useState3 = (0, _react.useState)(false),
	    _useState4 = _slicedToArray(_useState3, 2),
	    show = _useState4[0],
	    setShow = _useState4[1];

	var _useQuery2 = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_ELEMENT_RESULTS, {
		variables: {
			box: boxId,
			program: programId,
			user: appUser.id,
			element: item.id
		}
	}),
	    elementResultData = _useQuery2.data;

	var _ref = elementResultData || {},
	    elementResult = _ref.elementResult;

	//effect hooks


	(0, _react.useEffect)(function () {
		if (settings.settings && settings.settings.length > 0) {
			var newSettings = {};
			settings.settings.forEach(function (setting) {
				if (setting.name == 'file') {
					newSettings[setting.name] = setting.file;
				} else {
					newSettings[setting.name] = setting.value;
				}
			});
			setCompSettings(_extends({}, newSettings));
		}
	}, []);

	(0, _react.useEffect)(function () {
		if (!show) {
			setTimeout(function () {
				setShow(true);
			}, 100 * idx);
		}
	}, [idx]);

	var handleLinkClick = function handleLinkClick(e, item) {
		if (item.type === 'Bridge' || item.type == 'VideoBridge' || item.type === 'BookDownload' || item.type === 'SlideshowCreator' || item.type === 'FactCardCollection') {
			return;
		}
		e && e.preventDefault();

		if (disabled) {
			if (props.onDisabledClick) props.onDisabledClick();
		} else {
			history.push('/' + item.type + '/' + item.id);
		}
	};

	return _react2.default.createElement(
		_react2.default.Fragment,
		null,
		_react2.default.createElement(
			_DefaultCard2.default,
			{
				appUser: appUser,
				item: item,
				classes: classes,
				disabled: disabled,
				bridge: item.type === 'Bridge' || item.type == 'VideoBridge',
				handleLinkClick: handleLinkClick
			},
			item.type == 'Lesson' && _react2.default.createElement(_Lesson2.default, {
				classes: classes,
				item: item,
				settings: compSettings,
				prefix: t('common.element-types.lesson'),
				elementResult: elementResult,
				tags: tags,
				show: show
			}),
			item.type == 'MasterClass' && _react2.default.createElement(_DefaultItem2.default, {
				query: _graphql.GQL_FETCH_MASTERCLASS,
				classes: classes,
				item: item,
				settings: compSettings,
				prefix: t('common.element-types.master-class'),
				dataName: 'masterClass',
				tags: tags,
				show: show,
				config: config
			}),
			item.type === 'SelfEvaluation' && _react2.default.createElement(_DefaultItem2.default, {
				query: _graphql.GQL_FETCH_SELF_EVALUATION,
				classes: classes,
				item: item,
				settings: settings,
				prefix: t('common.element-types.self-evaluation'),
				dataName: 'selfEvaluation',
				show: show,
				tags: tags
			}),
			item.type === 'Rating' && _react2.default.createElement(_RatingCard2.default, {
				query: _graphql.GQL_FETCH_RATING,
				classes: classes,
				item: item,
				appUser: appUser,
				boxId: boxId,
				programId: programId,
				settings: settings,
				prefix: t('common.element-types.rating'),
				dataName: 'rating',
				show: show,
				tags: tags,
				elementResult: elementResult
			}),
			item.type === 'FinalRating' && _react2.default.createElement(_FinalRatingCard2.default, {
				query: _graphql.GQL_FETCH_FINAL_RATING,
				classes: classes,
				item: item,
				settings: settings,
				prefix: t('common.element-types.final-rating'),
				dataName: 'finalRating',
				show: show,
				tags: tags,
				elementResult: elementResult
			}),
			item.type == 'Trainer' && _react2.default.createElement(_DefaultItem2.default, {
				query: _graphql.GQL_FETCH_TRAINER,
				classes: classes,
				item: item,
				settings: compSettings,
				prefix: t('common.element-types.trainer'),
				dataName: 'trainer',
				show: show,
				tags: tags
			}),
			item.type == 'ExamTrainer' && _react2.default.createElement(_DefaultItem2.default, {
				query: _graphql.GQL_FETCH_EXAM_TRAINER,
				classes: classes,
				item: item,
				settings: compSettings,
				prefix: t('common.element-types.exam-trainer'),
				dataName: 'examTrainer',
				show: show,
				tags: tags
			}),
			item.type == 'Film' && _react2.default.createElement(_DefaultItem2.default, {
				query: _graphql.GQL_FETCH_FILM,
				classes: classes,
				item: item,
				settings: compSettings,
				prefix: t('common.element-types.film'),
				dataName: 'film',
				show: show,
				tags: tags,
				config: config
			}),
			item.type == 'Case' && _react2.default.createElement(_DefaultItem2.default, {
				query: _graphql.GQL_FETCH_CASE,
				classes: classes,
				item: item,
				settings: compSettings,
				prefix: t('common.element-types.case'),
				dataName: 'case',
				show: show,
				tags: tags
			}),
			item.type == 'BettingGame' && _react2.default.createElement(_DefaultItem2.default, {
				query: _graphql.GQL_FETCH_BETTING_GAME,
				classes: classes,
				item: item,
				settings: compSettings,
				prefix: t('common.element-types.betting-game'),
				dataName: 'bettingGame',
				show: show,
				tags: tags
			}),
			item.type == 'ExerciseCard' && _react2.default.createElement(_DefaultItem2.default, {
				query: _graphql.GQL_FETCH_EXERCISE_CARD,
				classes: classes,
				item: item,
				settings: compSettings,
				withRating: true,
				prefix: t('common.element-types.exercise-card'),
				dataName: 'exerciseCard',
				show: show,
				tags: tags
			}),
			item.type == 'BookCheck' && _react2.default.createElement(_BookCheck2.default, {
				appUser: appUser,
				item: item,
				classes: classes,
				prefix: t('common.element-types.book-check'),
				show: show,
				tags: tags
			}),
			item.type == 'Bridge' && _react2.default.createElement(_Bridge2.default, { item: item, handleLinkClick: handleLinkClick, classes: classes, tags: tags, show: show }),
			item.type === 'VideoBridge' && _react2.default.createElement(_VideoBridge2.default, { item: item, handleLinkClick: handleLinkClick, classes: classes, show: show }),
			item.type == 'SlideshowCreator' && _react2.default.createElement(_SlideShowCreator2.default, {
				appUser: appUser,
				item: item,
				classes: classes,
				prefix: t('common.element-types.slideshow-creator')
			}),
			item.type == 'BookDownload' && _react2.default.createElement(_BookDownload2.default, {
				appUser: appUser,
				item: item,
				classes: classes,
				prefix: t('common.element-types.book-download'),
				tags: tags,
				show: show
			}),
			item.type == 'FactCardCollection' && _react2.default.createElement(_FactCardsCard2.default, { item: item, image: item.image, factCards: item.factCards.map(function (item) {
					return item.id;
				}) })
		)
	);
};

exports.default = (0, _styles.withStyles)(_index.styles, { name: 'AppElementCard' })(ElementGridCard);