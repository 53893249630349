import React from 'react';
import SpeedDial from '@manakin/core/SpeedDial';
import SpeedDialAction from '@manakin/core/SpeedDialAction';
import Add from '@manakin/core/icons/Add';
import Create from '@manakin/core/icons/Create';
import { useTranslation } from 'react-i18next';

const SpeedDials = (props) => {
	const { addTitle, onAddClick } = props;
	const { t } = useTranslation();

	return (
		<SpeedDial>
			<SpeedDialAction icon={<Add />} tooltipTitle={addTitle || t('common.add')} onClick={onAddClick} />
		</SpeedDial>
	);
};

export default SpeedDials;
