import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { graphql } from "react-apollo";
import { GQL_LOGOUT_CMS } from "../graphql";
import ResetPassword from "../ResetPassword";
import Reset2Factor from "@manakin/app-views/Reset2Factor";
import Confirm2FactorReset from "@manakin/app-views/Reset2Factor/Confirm2FactorReset";
import Login from "../Login";
import CmsConfirmInvitation from "../ConfirmInvitation/CmsConfirmInvitation";
import ConfirmReset from "../ConfirmReset";
import { SnackbarMessenger } from "@manakin/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Logout from "../Logout";
import TwoFactor from "../TwoFactor/TwoFactor";

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				height: "100vh",
				padding: theme.spacing(2),
				backgroundColor: theme.manakin.loginBackground,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			},
			paper: {
				height: "min-content",
				margin: "auto",
			},
			nativeInput: {
				padding: `${theme.spacing(2)}px 0`,
			},
			input: {
				paddingTop: 0,
			},
			label: {
				fontSize: "18px",
			},
		}),
	{
		name: "AuthenticationCmsAuthenticator",
	}
);

const CmsAuthenticator = (props) => {
	const { match } = props;
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Switch>
				<Route path={`${match.url}/logout`} component={Logout} />
				<Route
					path={`${match.url}/login`}
					component={() => <Login styles={classes} />}
				/>
				<Route path={`${match.url}/reset`} component={ResetPassword} />
				<Route path={`${match.url}/2factor`} component={TwoFactor} />
				<Route
					path={`${match.url}/reset-2factor/:token`}
					component={Confirm2FactorReset}
				/>
				<Route
					path={`${match.url}/reset-2factor`}
					component={Reset2Factor}
				/>
				<Route
					path={`${match.url}/confirm-invitation/:token`}
					component={CmsConfirmInvitation}
				/>
				<Route
					path={`${match.url}/confirm-reset/:token`}
					component={ConfirmReset}
				/>
				<Redirect from="/" to={`${match.url}/login`} />
			</Switch>
			<SnackbarMessenger />
		</div>
	);
};

export default CmsAuthenticator;
