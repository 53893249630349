import React from 'react';
import Typography from '@material-ui/core/Typography';
import useStyles from '../styles';

const Introduction = (props) => {
	const { title = '', introduction } = props;
	const classes = useStyles();

	return (
		<div className={classes.introduction}>
			<Typography variant="h3" className={classes.heading}>
				{title}
			</Typography>
			{introduction && (
				<Typography variant="body1" className={classes.introText}>
					{introduction}
				</Typography>
			)}
		</div>
	);
};

export default Introduction;
