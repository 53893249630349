import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
	(theme) =>
		createStyles({
			formControlRoot: {
				width: '100%',
				minHeight: '10rem',
				padding: '1rem',
				borderTop: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
				borderBottom: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
				margin: '0',
				'&:last-child': {
					borderBottom: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
				},

				'& .radio-hidden': {
					display: 'none',
				},

				'&.active': {
					backgroundColor: theme.manakin.primaryColor[100],
				},

				[theme.breakpoints.up('md')]: {
					padding: '1rem 3.4rem',
					borderLeft: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
					borderRight: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
				},
			},
			formControlLabel: {
				color: theme.manakin.primaryColor[500],
				fontFamily: theme.manakin.defaultAnswerFont,
				fontSize: '1.6rem',
				lineHeight: '3.2rem',
				margin: '0 0 0 1rem',

				'&.Mui-disabled': {
					color: theme.manakin.primaryColor[500],
				},

				[theme.breakpoints.up('md')]: {
					fontSize: '1.8rem',
					lineHeight: '3.8rem',
				},
			},
			formControl: {
				marginBottom: '2rem',
			},
			primary: {
				backgroundColor: theme.manakin.primaryWorkformColor[500],
				padding: '5rem 0',

				'& $percentageBarInner': {
					backgroundColor: theme.palette.primary[500],
				},

				'& $formControlRoot': {
					'&.active': {
						backgroundColor: theme.manakin.primaryWorkformColor[200],
					},
				},

				'& span, & p, & h2, & label, & textarea': {
					color: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),
				},

				'& .MuiFormControlLabel-label.Mui-disabled': {
					color: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),
				},

				'& .smallUnderline': {
					'&:after': {
						backgroundColor: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),
					},
				},

				[theme.breakpoints.up('md')]: {
					padding: '5rem 6rem',
				},
				[theme.breakpoints.up('lg')]: {
					padding: '10rem',
				},
			},
			secondary: {
				backgroundColor: theme.manakin.secondaryWorkformColor[500],
				padding: '5rem 0',

				'& $percentageBarInner': {
					backgroundColor: theme.manakin.secondaryColor[900],
				},

				'& $formControlRoot': {
					'&.active': {
						backgroundColor: theme.manakin.secondaryWorkformColor[200],
					},
				},

				'& span, & p, & h2, & label, & textarea': {
					color: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
				},

				'& .MuiFormControlLabel-label.Mui-disabled': {
					color: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
				},

				'& .smallUnderline': {
					'&:after': {
						backgroundColor: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
					},
				},

				[theme.breakpoints.up('md')]: {
					padding: '5rem 6rem',
				},
				[theme.breakpoints.up('lg')]: {
					padding: '10rem',
				},
			},
			percentageBarRoot: {
				width: '100%',
				display: 'flex',
				alignItems: 'center',
				padding: 0,
				marginBottom: '15px',
			},
			percentageBarOuter: {
				width: '100%',
				height: '.8rem',
				backgroundColor: `${theme.manakin.defaultBorderColor[500]}`,
				position: 'relative',
				padding: '15px 0',
			},
			percentageBarInner: {
				backgroundColor: theme.manakin.primaryColor[500],
				position: 'absolute',
				height: '.8rem',
				left: 0,
				top: 0,
				transition: 'width .3s',
				padding: '15px 0',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'flex-end',

				'&.lowPercentage': {
					justifyContent: 'flex-start',

					'& $percentageBarContent': {
						marginLeft: '10px',
					},
				},
			},
			percentageBarContent: {
				margin: 0,
				minWidth: '3rem',
				marginRight: '10px',
				color: '#fff',
			},
		}),
	{ name: 'AppPoll' }
);
