'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styles = require('@material-ui/core/styles');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _recompose = require('recompose');

var _reactRedux = require('react-redux');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var useStyles = (0, _styles.makeStyles)(function (theme) {
    return (0, _styles.createStyles)({
        root: {
            display: 'flex',
            maxWidth: '1200px',
            margin: '0 auto'
        },
        tag: _defineProperty({
            width: '100%',
            height: '100%',
            maxWidth: '42px',
            maxHeight: '42px',
            marginRight: '5px',
            '&>img': {
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                boxShadow: 'none!important'
            }
        }, theme.breakpoints.up('md'), {
            maxWidth: '60px',
            maxHeight: '60px'
        })
    });
}, {
    name: 'appTags'
});

var mapStateToProps = function mapStateToProps(state) {
    return {
        config: state.config
    };
};

var Tags = function Tags(props) {
    var _props$tags = props.tags,
        tags = _props$tags === undefined ? [] : _props$tags,
        className = props.className,
        _props$config = props.config,
        config = _props$config === undefined ? {} : _props$config;
    var _config$general = config.general,
        general = _config$general === undefined ? {} : _config$general;

    var classes = useStyles();

    if (!general.showTags) return '';
    if (!tags.some(function (i) {
        return i.visibleInApp;
    })) return '';
    return _react2.default.createElement(
        'div',
        { className: (0, _classnames2.default)(classes.root, className) },
        tags.some(function (i) {
            return i.visibleInApp;
        }) && tags.map(function (tag) {
            return _react2.default.createElement(
                _react2.default.Fragment,
                { key: tag.id },
                tag.visibleInApp && tag.image && _react2.default.createElement(
                    'figure',
                    { className: classes.tag },
                    _react2.default.createElement('img', { src: tag.image.url })
                )
            );
        })
    );
};

exports.default = (0, _recompose.compose)((0, _reactRedux.connect)(mapStateToProps))(Tags);