import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { showSnackbarMessage } from '@manakin/core/actions';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Pica from './pica';
import { FileDrop } from 'react-file-drop';
import { withTranslation } from "react-i18next";

const pica = Pica({ features: [ 'all' ] });

const styles = (theme) => ({
    root: {
        position: 'relative',
        display: 'inline-block',
    },
    input: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        opacity: 0,
    },
});

export class FileUploadDragDrop extends React.PureComponent {
    validateOrCompressImage = (file) => {
        return new Promise(((resolve, reject) => {
            const { fileSizeLimit = 5, processFileTooBig, t } = this.props;

            let fileSize = file.size / 1024 / 1024;
            if (fileSize <= fileSizeLimit / 2) {
                resolve(file);
            } else {
                // Try to compress the image
                try {
                    if (file.type.startsWith("image")) {
                        this.compressImage(file).then((blob) => {
                            resolve(blob);
                        });
                    } else {
                        // We cannot compress files other than images
                        reject();
                        processFileTooBig(fileSizeLimit, t);
                    }
                } catch (e) {
                    if (fileSize > fileSizeLimit) {
                        reject();
                        processFileTooBig(fileSizeLimit, t);
                    } else {
                        resolve(file);
                    }
                }
            }
        }))
    }

    compressImage = (file) => {
        return new Promise((resolve => {
            const { fileSizeLimit = 5, processFileTooBig, t } = this.props;

            let image = new Image();
            let canvas = document.createElement('canvas');
            image.src = URL.createObjectURL(file);
            image.onload = () => {
                canvas.width = image.width;
                canvas.height = image.height;

                pica.resize(image, canvas)
                    .then((result) => pica.toBlob(result, file.type, 0.65))
                    .then((result) => {
                        let resultFileSize = result.size / 1024 / 1024;
                        if (resultFileSize > fileSizeLimit) processFileTooBig(fileSizeLimit, t);
                        else {
                            result.name = file.name
                            resolve(result);
                        }
                    });
            };
        }))

    }

    handleFileUpload = async (newFiles) => {
        const { onFilesUpload, maxFiles = 1, accept } = this.props;

        // Loop through all files, filtering for accepted types and also validating whether
        // the files exceed the file limit and should be compressed
        Promise.all(
            Object.keys(newFiles)
                .map(fileKey => newFiles[fileKey])
                .filter(file => accept.includes(file.type))
                .map(file => this.validateOrCompressImage(file).then(async (newFile) => newFile))
        ).then((data) => {
            onFilesUpload(data
                .filter(file => file) // Filter null / undefined files
                .slice(0, maxFiles) // Only accept maxFiles
            );
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.enabled !== this.props.enabled) {
            if (this.refs.fileUpload) {
                this.refs.fileUpload.click();
            }
        }
    }

    render() {
        const { children, classes, enabled, accept = null, multiple = false } = this.props;

        return (
            <FileDrop onDrop={this.handleFileUpload}>
                {children}

                {enabled && (
                    <input
                        type="file"
                        accept={accept}
                        className={classes.input}
                        ref="fileUpload"
                        required
                        multiple={multiple}
                        onChange={(event) => event.target.validity.valid && this.handleFileUpload(event.target.files)}
                    />
                )}
            </FileDrop>
        );
    }
}

export default compose(
    withStyles(styles),
    withTranslation(),
    connect(null, (dispatch) => ({
        processFileTooBig: (sizeInMegaBytes, t) =>
            dispatch(
                showSnackbarMessage({
                    text: t("app-core.file-upload.upload-failed-file-too-big", { size: sizeInMegaBytes || 50 }),
                    variant: 'error',
                })
            ),
    }))
)(FileUploadDragDrop);
