import React, { useEffect } from 'react';
import { ElementOverlay, Stepper, withStepper, Loader, withWorkforms } from '@manakin/app-core';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router';

const timer = {
	timer: null,
};

const options = {
	showFeedback: false,
	nextButton: true,
	doneAfterFeedback: false,
};

const ExamTrainer = (props) => {
	const { stepper, workforms, loading, data = {}, prefix, workformsData, onExit } = props;
	const { t } = useTranslation();
	const params = useParams();
	const history = useHistory();

	useEffect(() => {
		if (!loading && workforms && workforms.length) {
			workformsData.loadWorkforms({
				workforms: workforms,
				options: options,
				type: 'ExamTrainer',
			});
		}
	}, [workforms]);

	useEffect(() => {
		if (workformsData && workformsData.workforms && !workformsData.loading) {
			stepper.setItems(workformsData.workforms || []);
		}
	}, [workformsData.workforms, workformsData.loading]);

	const handleExit = () => {
		if (params.elementId) {
			workformsData.saveElement({
				elementId: params.elementId,
				finished: true,
				correct: true,
			});
		}
		onExit(stepper);
	};

	const handleClose = () => {
		history.push('/');
	};

	if (loading) return <Loader fullScreen />;
	return (
		<ElementOverlay
			variant="trainer"
			title={data.preTitle || prefix || t('common.element-types.exam-trainer')}
			items={stepper.items}
			controls={params.elementId ? true : false}
			customControls={params.programId ? true : false}
			onClick={handleClose}
			background="white"
		>
			<Stepper stepper={stepper} variant="trainer" onExit={handleExit} options={options} />
		</ElementOverlay>
	);
};

export default compose(withStepper(timer), withWorkforms())(ExamTrainer);
