import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { Results as ResultsDefaultPage } from '../DefaultPages';
import { getGradeFromItems, Loader } from '@manakin/app-core';
import { ENTRY_EXAM_TRAINER_ROOT_PATH } from '../Router/Router';
import { GQL_FETCH_SCORES_FOR_ELEMENT, GQL_FETCH_EXAM_TRAINER } from './graphql';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

const Results = () => {
	const location = useLocation();
	const params = useParams();
	const history = useHistory();
	const { state } = location;
	const { items = [] } = state || {};
	const grade = getGradeFromItems(items);
	const { t } = useTranslation();

	const { data: examTrainerData, loading: examTrainerLoading } = useQuery(GQL_FETCH_EXAM_TRAINER, {
		variables: {
			id: params.elementId,
		},
	});
	const { examTrainer = {} } = examTrainerData || {};

	const { data, loading } = useQuery(GQL_FETCH_SCORES_FOR_ELEMENT, {
		variables: {
			examTrainerId: params.elementId,
			programId: params.programId,
		},
	});
	const { examTrainerScores = [] } = data || {};
	const scores = examTrainerScores.map((score) => {
		return {
			...score,
			tags: score.tags.map((score) => score.name, []),
		};
	});

	const handleReset = () => {
		if (params.programId && state.tags) {
			history.push({
				pathname: `/${ENTRY_EXAM_TRAINER_ROOT_PATH}/${params.programId}/programTrainer`,
				state: {
					tags: state.tags,
					category: state.category
				},
			});
		} else if (params.elementId) {
			history.push(`/${ENTRY_EXAM_TRAINER_ROOT_PATH}/${params.elementId}`);
		}
	};

	const handleCheck = () => {
		history.push({
			pathname: `/${ENTRY_EXAM_TRAINER_ROOT_PATH}/${params.elementId || params.programId}/trainerReview`,
			state: {
				items: items,
				programId: params.programId,
				tags: state.tags || null,
				category: state.category
			},
		});
	};

	const handleClose = () => {
		history.push('/');
	};

	if (loading) return <Loader />;
	return (
		<ResultsDefaultPage
			history={scores}
			grade={grade}
			successTitle={examTrainer.successText || null}
			failureTitle={examTrainer.failureText || null}
			variantTitle={t('common.element-types.exam-trainer')}
			onReset={handleReset}
			onCheck={handleCheck}
			onClick={handleClose}
			customControls={params.programId ? true : false}
		/>
	);
};

export default Results;
