import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';
import { Typography } from '@material-ui/core';

const styles = (theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        gap: 16
    },
    progress: {
        margin: theme.spacing(2),
    },
    center: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    text: {
        marginBottom: 0,
        fontWeight: 400
    }
});

class Loader extends React.Component {
    render() {
        const { classes, center = false, text = '', ...other } = this.props;
        return (
            <div className={classNames(classes.root, { [classes.center]: center })}>
                <CircularProgress className={classes.progress} {...other} />

                {text &&
                    <Typography
                        className={classes.text}
                        component="h1"
                        variant="h1"
                        gutterBottom
                    >
                        {text}
                    </Typography>   
                }
            </div>
        );
    }
}

export default withStyles(styles)(Loader);
