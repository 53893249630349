import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { compose } from 'recompose';
import renderHTML from 'react-render-html';
import { useReadSpeaker } from '@manakin/hooks';

const styles = (theme) => ({
	wrapper: {
		...theme.manakin.smallWrapper,
		maxWidth: '800px',
		paddingTop: theme.manakin.defaultPadding,
		marginBottom: '3rem',
		[theme.breakpoints.up('md')]: {
			minHeight: '240px',
			paddingTop: '12rem',
			marginBottom: '8rem',
		},
	},
	content: {
		margin: 0,
		fontFamily: theme.manakin.secondaryTitleFont,
		fontSize: '1.6rem',
		lineHeight: '3rem',
		color: theme.manakin.defaultContentColor[500],
		marginBottom: '1.4rem',
		fontWeight: '600',
		display: "flex",
		flexDirection: "column",
		[theme.breakpoints.up('md')]: {
			'& > :first-child:first-letter': {
				fontSize: '20rem',
				float: 'left',
				lineHeight: '16rem',
				margin: '0 3rem 0 0',
			},
			marginBottom: '4rem',
			fontSize: '2.4rem',
			lineHeight: '4rem',
		},
		"& > ul": {
			listStyle: "inside",
			paddingLeft: 16
		}
	},
});

const Introduction = (props) => {
	const { classes, data } = props;
	const { readSpeaker, readSpeakerId } = useReadSpeaker('readSpeaker-introduction-' + data.id);

	if (!data.introduction) {
		return '';
	}
	return (
		<div className={classes.root} id={readSpeakerId}>
			<div className={classes.wrapper}>
				{readSpeaker && renderHTML(readSpeaker)}
				<Typography component="div" variant="body1" classes={{ body1: classes.content }}>
					{renderHTML(data.introduction || '')}
				</Typography>
			</div>
		</div>
	);
};

export default compose(withStyles(styles, { name: 'AppLessonIntroduction' }))(Introduction);
