import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
    root: {},
});

const NotFound = ({ classes }) => {
    const { t } = useTranslation();
    return <div className={classes.root}>{t("common.page-does-not-exist")}</div>;
};

export default withStyles(styles)(NotFound);
