import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
	createStyles({
		stepperRoot: {
			width: "100%",
			alignItems: "center",
			justifyContent: "center",
		},
		stepRoot: {
			flex: "0 0 auto",
		},
		root: {
			padding: "14rem 0 0",
		},
		container: {
			display: "flex",
			flexWrap: "wrap",
			width: '100vw',
			maxWidth: "900px",
			alignItems: "center",
			margin: "0 auto 30px",
			justifyContent: "center",
			flexDirection: "column",
		},
		buttonGroup: {
			zIndex: 0,
			textAlign: "center",
			width: "100%",
		},
		buttonRoot: {
			display: "block",
			margin: "0 auto",
		},
		content: {
			width: "100%",
			margin: "0 0 80px",
		},
		regiatrationAppear: {
			display: "block",
		},
		registrationEnter: {
			opacity: 0,
			position: "relative",
			transform: "translateX(100px)",
		},
		registrationEnterActive: {
			opacity: 1,
			transform: "translateX(0)",
		},
		register: {
			marginTop: "20px",
		},
		smallParagraph: {
			textAlign: "center",
			width: "100%",
			margin: "0 auto",
			marginBottom: "0.5em",
			maxWidth: "560px",
			color: theme.palette.getContrastText(
				theme.manakin.primaryColor[500]
			),
		},
		heading: {
			margin: "0 0 20px 0",
			color: theme.palette.getContrastText(
				theme.manakin.primaryColor[500]
			),
		},
		paragraph: {
			textAlign: "center",
		},
	})
);