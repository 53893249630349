import React from 'react';
import Lesson from './Lesson';
import { Loader } from '@manakin/app-core';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
	loader: {
		width: '100vw',
		height: '100vh',
		position: 'fixed',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
});

const LessonView = (props) => {
	const { classes, onReset, preview = false, saving } = props;
	const { t } = useTranslation();

	if (props.loading) {
		return (
			<div className={classes.loader}>
				<Loader />
			</div>
		);
	} else if (props.workforms) {
		return (
			<React.Fragment>
				<Lesson
					prefix={props.title}
					lessonData={{ ...props.lessonData }}
					workforms={[...props.workforms]}
					options={{ ...props.options }}
					onFinish={props.onFinish}
					box={props.box}
					onReset={onReset}
					preview={preview}
					saving={saving}
				/>
			</React.Fragment>
		);
	} else {
		return t('common.something-went-wrong');
	}
};

export default withStyles(styles)(LessonView);
