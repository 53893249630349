import gql from 'graphql-tag';
import { fileAsset, appAssetWithThumbnail } from '@manakin/app-core/graphQlHelpers';

export const GQL_FETCH_BETTING_GAME = gql`
    query($id: ID!) {
        bettingGame(id: $id) {
            id
            type
            introTitle
            introText
            preTitle
            titleColor
            image {
                ${appAssetWithThumbnail}
            }
            styleSheet {
                id
                indicationColor
                defaultWorkformColor
                primaryWorkformColor
                secondaryWorkformColor
                themeColor
            }
            workforms {
                id
                type
            }
        }
    }
`;

export const GQL_FETCH_ELEMENT_RESULTS = gql`
    query($box: ID!, $program: ID!, $user: ID!) {
        elementResults(box: $box, program: $program, user: $user) {
            finished
            element {
                id
                type
                title
            }
            correct
        }
    }
`;

export const GQL_FETCH_STYLESHEET = gql`
	query styleSheet($id: ID!) {
		styleSheet(id: $id) {
			id
			title
			description
			customStyle
			indicationColor
			defaultWorkformColor
			primaryWorkformColor
			secondaryWorkformColor
			themeColor
		}
	}
`;

export const GQL_FETCH_GLOBAL_SETTINGS = gql`
    query {
        settings {
            settings {
                name
                value
                file {
                    ${fileAsset}
                }
            }
        }
    }
`;