import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { onError } from 'apollo-link-error';
import { store } from './store';
import { showSnackbarMessage } from '@manakin/core/actions';
import { push } from 'redux-first-history';

const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
    },
    mutate: {
        errorPolicy: 'all',
    },
};

const customFetch = (uri, options) => {
    return fetch(uri, {
        ...options,
        headers: {
            ...options.headers,
            'x-language': store.getState()['currentLanguage'] || 'default',
        },
    });
};

const httpLink = new BatchHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    batchInterval: 20,
    credentials: 'include',
    fetch: customFetch,
});

const uploadLink = createUploadLink({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    fetch: customFetch,
});

const errorLink = onError(
    ({ response, operation, graphQLErrors, networkError }) => {
        if (networkError) {
            if (
                operation.operationName === 'loginCms' &&
                networkError.statusCode === 401
            ) {
                store.dispatch(
                    showSnackbarMessage({
                        text:
                            'Inloggen mislukt. Controleer uw e-mail en wachtwoord.',
                        variant: 'error',
                    })
                );
            } else if (networkError.statusCode === 401) {
                store.dispatch(
                    showSnackbarMessage({
                        text: 'Sessie verlopen. Opniew inloggen vereist.',
                        variant: 'error',
                    })
                );
                store.dispatch(
                    push('/auth/login?redirect=' + window.location.pathname)
                );
            } else {
                console.log('onbekende serverfout');
            }
        } else if (graphQLErrors) {
            graphQLErrors.forEach(({ message, locations, path }) => {
                console.log('%cGRAPHQL ERROR %s', 'color: red', message);
            });
        }
    }
);

// const link = ApolloLink.from([uploadLink, errorLink, httpLink]);
// const link = ApolloLink.from([createUploadLink({ uri: process.env.REACT_APP_GRAPHQL_ENDPOINT }), errorLink, httpLink]);
const link = ApolloLink.split(
    (operation) => operation.getContext().hasUpload,
    uploadLink,
    httpLink,
    errorLink
);

const graphqlClient = new ApolloClient({
    cache: new InMemoryCache({
        addTypename: false,
    }),
    link,
    defaultOptions,
});

export default graphqlClient;
