"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _core = require("@material-ui/core");

var _reactRouterDom = require("react-router-dom");

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _Router = require("@manakin/app-views/Router/Router");

var _appCore = require("@manakin/app-core");

var _selectors = require("@manakin/app-core/ProgramsDropdown/selectors");

var _graphql = require("./graphql");

var _reactApollo = require("react-apollo");

var _reactRedux = require("react-redux");

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var useStyles = (0, _core.makeStyles)(function (theme) {
	var _block;

	return (0, _core.createStyles)({
		root: _defineProperty({
			width: "50%",
			textDecoration: "none"
		}, theme.breakpoints.up("md"), {
			marginLeft: "2.5rem"
		}),
		longArrow: {
			position: "relative",
			left: "50%",
			transform: "translateX(-50%)",
			marginTop: "50px",
			transition: "transform .2s",
			"& .arrow": {
				background: theme.manakin.defaultContentColor[500]
			}
		},
		block: (_block = {
			background: "white",
			height: "32rem",
			width: "100%",
			padding: "4rem",
			position: "relative",
			cursor: "pointer",
			overflow: "hidden",
			marginBottom: "2.5rem"
		}, _defineProperty(_block, theme.breakpoints.up("md"), {
			height: "50rem",
			padding: "6rem 0 0"
		}), _defineProperty(_block, theme.breakpoints.down("sm"), {
			paddingTop: 32,
			paddingBottom: 32,
			boxSizing: "content-box",
			width: "auto"
		}), _defineProperty(_block, "&:hover", {
			"& $longArrow": {
				transform: "translateX(-44%)",
				"& .arrow": {
					backgroundColor: theme.manakin.defaultContentHover[500]
				}
			}
		}), _block),
		progressBlock: _defineProperty({
			transform: "scale(1.3)"
		}, theme.breakpoints.down("sm"), {
			marginTop: 26
		})
	});
}, {
	name: "AppProgressionDashboardButton"
});

var ProgressionDashboardButton = function ProgressionDashboardButton(props) {
	var classes = useStyles();
	var program = (0, _reactRedux.useSelector)(function (state) {
		return (0, _selectors.getProgram)(state);
	}) || {};

	var _useState = (0, _react.useState)(false),
	    _useState2 = _slicedToArray(_useState, 2),
	    show = _useState2[0],
	    setShow = _useState2[1];

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	(0, _react.useEffect)(function () {
		// Due to the listview switch animation, Google's chart seems to fail to responsively render the
		// pie chart. Showing it after the animation solves the problem, because unfortunately, calling a
		// re-render of the chart does not fix the problem so it is the only solution for now.
		setTimeout(function () {
			setShow(true);
		}, 120);
	}, []);

	// Query

	var _useQuery = (0, _reactApollo.useQuery)(_graphql.GQL_FETCH_RATING_PROGRESS, {
		skip: program === undefined,
		variables: { programId: program }
	}),
	    _useQuery$data = _useQuery.data,
	    data = _useQuery$data === undefined ? {} : _useQuery$data;

	var _data$ratingProgress = data.ratingProgress,
	    ratingProgress = _data$ratingProgress === undefined ? {} : _data$ratingProgress;


	return _react2.default.createElement(
		_reactRouterDom.Link,
		{ className: classes.root, to: "/" + _Router.PROGRESS_DASHBOARD_ROOT_PATH },
		_react2.default.createElement(
			"div",
			{ className: classes.block },
			_react2.default.createElement(
				_core.Fade,
				{ "in": show },
				_react2.default.createElement(_appCore.ProgressBlock, {
					subtitle: t("app-core.progression-dashboard-button.title"),
					progress: ratingProgress.progress,
					warning: false,
					className: classes.progressBlock
				})
			),
			_react2.default.createElement(_appCore.LongArrow, { className: classes.longArrow })
		)
	);
};

exports.default = ProgressionDashboardButton;