import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid/Grid';
import Button from '@material-ui/core/Button/Button';
import Chip from '@material-ui/core/Chip';
import FilterIcon from '@material-ui/icons/Tune';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { AccessControl } from '@manakin/core';
import { compose } from 'recompose';
import { withTranslation } from "react-i18next";

const styles = (theme) => ({
    root: {
        padding: '16px 16px 16px 42px',
        backgroundColor: '#F8F8F9',
    },
    chip: {
        margin: theme.spacing(0.5),
        backgroundColor: '#4A90F2',
        color: 'white',
        '&:hover': {
            backgroundColor: '#3177C9',
        },
    },
    chipDeleteIcon: {
        width: 16,
        height: 16,
        backgroundColor: 'white',
        borderRadius: '100%',
        color: '#4A90F2',
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(0.5),
    },
    filters: {
        marginLeft: 'auto',
    },
    filterButton: {
        border: 'none',
        backgroundColor: '#4A90F2',
        padding: '8px 16px',
        margin: '0 16px',
        borderRadius: 25,
        color: 'white',
        minWidth: 'auto',
        '&:hover': {
            backgroundColor: '#3177C9',
        },
    },
    filterIcon: {
        marginLeft: 10,
    },
    changeFilter: {
        display: 'flex',
        marginLeft: 'auto',
        alignItems: 'center',
    },
    changeViewHeading: {
        marginRight: theme.spacing(1),
    },
    iconActive: {
        color: 'rgba(0, 0, 0, 0.64)',
    },
    iconInactive: {
        color: 'rgba(0, 0, 0, 0.34)',
    },
});

/***
 * Filters should be structured like this:
 *
 * [
 *  {
 *      id: 0,
 *      name: "My Filter",
 *      filters: [
 *          {
 *              id: 0,
 *              name: "First option",
 *              enabled: false
 *          }
 *      ]
 *  }
 * ]
 */
class FilterBar extends React.Component {
    /**
     * {Callback} onFilterDelete Called when delete cross is clicked on chip
     * {Array<Object>} filters All filters
     * {Callback} onFilterClick Called when the user wants to choose a filter
     *
     * @returns {*}
     */
    render() {
        const {
            classes,
            totalCount,
            filters,
            onFilterClick,
            onFilterDelete,
            deletePermissions,
            deleteMode = null,
            onDeleteModeChange,
            onDeleteSelection,
            t
        } = this.props;

        const resultText =
            totalCount > 0
                ? totalCount > 1
                    ? t("core.filter-bar.results", {count: totalCount})
                    : t("core.filter-bar.result")
                : t("common.no-results-found");

        return (
            <div className={classes.root}>
                <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justify="flex-start"
                >
                    <Grid item>
                        <Typography variant="subtitle1">
                            {resultText}
                        </Typography>
                    </Grid>
                    <Grid item className={classes.changeFilter}>
                        <div className={classes.filters}>
                            {filters &&
                                filters.map((filterType) => {
                                    return filterType.filters.map((filter) => {
                                        if (filter.enabled) {
                                            return (
                                                <Chip
                                                    key={`${filterType.name}-${filter.name}`}
                                                    clickable
                                                    label={filter.name}
                                                    onDelete={() =>
                                                        onFilterDelete(
                                                            filterType.id,
                                                            filter.id
                                                        )
                                                    }
                                                    deleteIcon={
                                                        <CloseIcon
                                                            className={
                                                                classes.chipDeleteIcon
                                                            }
                                                        />
                                                    }
                                                    className={classes.chip}
                                                />
                                            );
                                        }
                                    });
                                })}
                        </div>
                        {deleteMode != null && !deleteMode && (
                            <AccessControl renderRules={[deletePermissions]}>
                                <Tooltip
                                    title={t("common.delete")}
                                    key={'delete'}
                                >
                                    <IconButton
                                        onClick={onDeleteModeChange.bind(
                                            this,
                                            true
                                        )}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </AccessControl>
                        )}
                        {deleteMode && (
                            <div>
                                <Button
                                    onClick={onDeleteModeChange.bind(
                                        this,
                                        false
                                    )}
                                >
                                    {t("common.cancel")}
                                </Button>
                                <Button onClick={onDeleteSelection}>
                                    {t("common.delete")}
                                </Button>
                            </div>
                        )}
                        <Button
                            className={classes.filterButton}
                            onClick={() => onFilterClick()}
                        >
                            {t("common.filter")}
                            <FilterIcon className={classes.filterIcon} />
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default compose(
    withStyles(styles),
    withTranslation()
)(FilterBar);
