import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Loader } from '@manakin/app-core';
import { Header, Content } from './parts';
import classNames from 'classnames';

const styles = (theme) => ({
    root: {
        width: '100%',
    },
    loader: {
        backgroundColor: 'white',
        position: 'absolute',
        width: '100vw',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    content: {
        height: 0,
        overflow: 'hidden',
    },
    show: {
        height: 'auto',
    },
});

class Case extends React.Component {
    handleClick = () => {
        if (this.props.onClick) this.props.onClick();
    };

    render() {
        const {
            classes,
            loading,
            rawData,
            workforms,
            stateData,
            onNext,
            onUpdateCharacteristic,
            options,
            redoButtons,
            onSetWorkformDone
        } = this.props;

        if (loading) {
            return (
                <div className={classes.loader}>
                    <Loader />
                </div>
            );
        } else {
            return (
                <div className={classes.root}>
                    <div className={classes.root}>
                        <Header data={rawData} onClick={this.handleClick} />
                        <div
                            className={classNames(classes.content, {
                                [classes.show]: stateData.showContent,
                            })}
                        >
                            <Content
                                {...stateData}
                                workforms={workforms}
                                onNext={onNext}
                                onUpdateCharacteristic={onUpdateCharacteristic}
                                options={options}
                                onClose={this.props.onClose}
                                redo={this.props.redo}
                                redoButtons={redoButtons}
                                onSetWorkformDone={onSetWorkformDone}
                            />
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default withStyles(styles)(Case);
