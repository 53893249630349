import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import PersonalDataForm from '../forms/PersonalDataForm';
import { WarningBox } from '@manakin/app-core';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				width: '100%',
			},
			heading: {},
			warning:{
				marginTop: 24
			}
		}),
	{
		name: 'AppRegistrationPersonalData',
	}
);

const PersonalData = (props) => {
	const { config = {}, form } = props;
	const { pages = {} } = config || {};
	const { appRegistration = {} } = pages || {};
	const { showPersonalDataWarning = false } = appRegistration || {};
	const classes = useStyles();
	const { t } = useTranslation();

	useEffect(() => {
		if (appRegistration.registerCodeRequired)
			form.onRequiredChange('registerCode', true);
		if (appRegistration.showRegisterType)
			form.onRequiredChange('registerType', true);
		if (appRegistration.showBirthDate)
			form.onRequiredChange('dateOfBirth', true);
	}, []);

	return (
		<div className={classes.root}>
			<Typography variant="h2" className={classes.heading}>
				{t('authentication.registration.personal-data.title')}
			</Typography>
			<PersonalDataForm form={form} />

			{showPersonalDataWarning && (
				<WarningBox
					className={classes.warning}
					message={t("authentication.registration.personal-data.warning")} />
			)}
		</div>
	);
};

export default PersonalData;
