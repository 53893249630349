import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	root: {
		[theme.breakpoints.up("md")]: {
			textAlign: "center",
			marginBottom: "50px",
		},
	},
	title: {
		[theme.breakpoints.up("md")]: {
			fontWeight: 500,
			marginBottom: "50px",
		},
	},
	content: {
		maxWidth: "550px",
		margin: "0 auto",
	},
	intro: {
		[theme.breakpoints.up("md")]: {
			maxWidth: "420px",
			margin: "0 auto 50px",
		},
	},
}));

const Introduction = (props) => {
	const { user = {}, program = {} } = props;
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.root}>
			<Typography variant="h3" className={classes.title}>
				{t("app.bpv-report.introduction.title")}
			</Typography>
			<Typography variant="body2" className={classes.intro}>
                {t("app.bpv-report.introduction.intro", {
                    program: program.name,
                    name: user.fullName || "",
                })}
			</Typography>
			<Typography className={classes.content}>
				{t("app.bpv-report.introduction.description")}
			</Typography>
		</div>
	);
};

export default Introduction;
