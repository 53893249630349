export const styles = (theme) => ({
    root: {
        paddingTop: '6rem',
        paddingBottom: '6rem',
        width: '100%',
        height: '100%',
        minHeight: '100vh',
        backgroundColor: '#F2F2F2',
    },
    paragraph: {
        textAlign: 'center',
        width: '100%',
        margin: '0 auto 3em',
        maxWidth: '560px',
    },
    wrapper: {
        ...theme.manakin.defaultWrapper,
        maxWidth: theme.manakin.wrapper['small'],
        position: 'relative',
        width: '100%',
        margin: '0 auto',
    },
    smallWrapper: {
        maxWidth: theme.manakin.wrapper['extraSmall'],
        position: 'relative',
        zIndex: 1,
        width: '90%',
        margin: '0 auto',
    },
    footNote: {
        marginTop: theme.spacing(4),
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    link: {
        marginTop: theme.spacing(1),
        textDecoration: 'none',
        color: '#009AC3',
        cursor: 'pointer',
    },
    heading: {
        textAlign: 'center',
    },
    topContent: {
        display: 'flex',
        '& >*': {
            width: '50%',
        },
    },
    body: {
        marginLeft: '6rem',
        color: theme.manakin.primaryColor[500],
    },
    products: {
        display: 'flex',
        marginBottom: '4rem',
        flexDirection: 'column',
        '&>div': {
            marginBottom: '2rem',
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            '&>div': {
                marginBottom: '0',
            },
        },
    },
    largeWrapper: {
        ...theme.manakin.largeWrapper,
    },
    footNode: {
        padding: '2rem',
        border: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
        marginTop: '2rem',
    },
    smallFont: {
        fontSize: '1.6rem',
        margin: 0,
        color: theme.manakin.primaryColor[500],
    },
    controls: {
        textAlign: 'center',
    },
    mainButtonStyle: {
        display: 'block',
        margin: '0 auto',
        marginBottom: '2rem',
        padding: '2rem',
        minWidth: '30rem',
    },
    logoutButton: {
        margin: '0 auto',
        display: 'block',
    },
});
