'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.getOptions = exports.SELECT_OPTION_ROOT = undefined;

var _reselect = require('reselect');

var _selectors = require('../selectors');

var SELECT_OPTION_ROOT = exports.SELECT_OPTION_ROOT = 'selectOption';

var getState = (0, _reselect.createSelector)([_selectors.getCoreState], function (coreState) {
    return coreState[SELECT_OPTION_ROOT];
});

var getOptions = exports.getOptions = (0, _reselect.createSelector)([getState], function (state) {
    return state.option;
});