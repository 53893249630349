'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Dialog = require('@material-ui/core/Dialog');

var _Dialog2 = _interopRequireDefault(_Dialog);

var _core = require('@material-ui/core');

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _Button = require('@material-ui/core/Button');

var _Button2 = _interopRequireDefault(_Button);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _reactRedux = require('react-redux');

var _actions = require('@manakin/core/actions');

var _reactHooks = require('@apollo/react-hooks');

var _graphql = require('../graphql');

var _reactI18next = require('react-i18next');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var useStyles = (0, _core.makeStyles)(function (theme) {
	return {
		dialogPaper: {
			flexFlow: 'row wrap',
			padding: theme.spacing(6),
			textAlign: "center"
		},
		dialogTitle: _defineProperty({
			width: '100%',
			margin: 0,
			padding: '12px 0',
			fontSize: '4rem',
			lineHeight: '4rem'
		}, theme.breakpoints.up('md'), {
			padding: '32px 0'
		}),
		dialogActions: {
			display: 'flex',
			flexFlow: 'row wrap',
			flexDirection: "column",
			width: '100%',
			marginTop: theme.spacing(2)
		},
		button: {
			minHeight: "6rem",
			padding: 0
		},
		ok: {
			backgroundColor: theme.manakin.indicationColor[500],
			color: 'white',
			marginBottom: theme.spacing(),
			'&:hover': {
				backgroundColor: theme.manakin.indicationColor[700]
			}
		}
	};
});

var ResendRatingRequestDialog = function ResendRatingRequestDialog(props) {
	var finalRatingRequest = props.finalRatingRequest,
	    supervisor = props.supervisor,
	    onClose = props.onClose,
	    open = props.open;

	var classes = useStyles();
	var dispatch = (0, _reactRedux.useDispatch)();

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var _useMutation = (0, _reactHooks.useMutation)(_graphql.GQL_REQUEST_APPROVAL),
	    _useMutation2 = _slicedToArray(_useMutation, 2),
	    resendBpvReport = _useMutation2[0],
	    _useMutation2$ = _useMutation2[1],
	    resendBpvReportData = _useMutation2$.data,
	    resendBpvReportError = _useMutation2$.error;

	var handleResendBpvReport = function handleResendBpvReport() {
		resendBpvReport({
			variables: {
				input: {
					finalRatingRequestId: finalRatingRequest.id,
					supervisorId: supervisor.id
				}
			}
		});
	};

	(0, _react.useEffect)(function () {
		if (resendBpvReportData && resendBpvReportData.supervisorApprovalRequest) {
			dispatch((0, _actions.showSnackbarMessage)({
				text: t("app.account.bpv-report.resend-rating-request-dialog.success"),
				variant: "success"
			}));
			onClose();
		}

		if (resendBpvReportError) {
			dispatch((0, _actions.showSnackbarMessage)({
				text: t("app.account.bpv-report.resend-rating-request-dialog.failed"),
				variant: "error"
			}));
		}
	}, [resendBpvReportData, resendBpvReportError]);

	return _react2.default.createElement(
		_Dialog2.default,
		{
			open: open,
			onClose: onClose,
			PaperProps: { className: classes.dialogPaper }
		},
		_react2.default.createElement(
			_Typography2.default,
			{ variant: 'h3', className: classes.dialogTitle },
			t("app.account.bpv-report.resend-rating-request-dialog.title")
		),
		_react2.default.createElement(
			_core.DialogContent,
			null,
			_react2.default.createElement(
				_core.DialogContentText,
				null,
				t("app.account.bpv-report.resend-rating-request-dialog.description", { fullName: supervisor && supervisor.fullName })
			)
		),
		_react2.default.createElement(
			'div',
			{ className: classes.dialogActions },
			_react2.default.createElement(
				_Button2.default,
				{
					variant: 'contained',
					className: (0, _classnames2.default)(classes.button, classes.ok),
					onClick: handleResendBpvReport
				},
				t("app.account.bpv-report.resend-rating-request-dialog.action")
			),
			_react2.default.createElement(
				_Button2.default,
				{
					variant: 'contained',
					className: (0, _classnames2.default)(classes.button),
					onClick: onClose
				},
				t("common.close")
			)
		)
	);
};

exports.default = ResendRatingRequestDialog;