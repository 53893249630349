import React, {useEffect} from 'react';
import { SelectField, withForm } from '@manakin/core';
import { compose } from 'recompose';
import { GQL_FETCH_GROUPS, GQL_UPDATE_APP_USER } from '../graphql';
import { useTranslation } from 'react-i18next';
import { makeStyles, createStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			paddingTop: '6rem',
			minHeight: '100%',
			width: '100vw',
			height: '100vh',
		},
		smallWrapper: {
			maxWidth: theme.manakin.wrapper['small'],
			width: '90%',
			margin: '0 auto',
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
		},
		heading: {
			marginBottom: '20px',
		},
	})
);

const formBundle = {
	school: {
		required: true,
	},
};

const CheckBrinCode = (props) => {
	const { id, form, externalIds } = props;
	const classes = useStyles();
	const { t } = useTranslation();
	const history = useHistory();

	const [mutate] = useMutation(GQL_UPDATE_APP_USER, {
		onCompleted: (data) => {
			if (!data.errors) {
				history.push(`/auth/sso/${id}`);
			} else {
				history.push('/auth/sso/error');
			}
		},
	});

	const handleClick = () => {
		form.onValidate().then(
			() =>
				form.onSubmit().then((formData) => {
					mutate({
						variables: {
							id: id,
							school: formData.school,
						},
					});
				}),
			(error) => {
				history.push('/auth/sso/error');
			}
		);
	};

	return (
		<div className={classes.root}>
			<div className={classes.smallWrapper}>
				<Typography className={classes.heading} variant="h2">
					{t('authentication.registration.sso.check-brin.title')}
				</Typography>
				<SelectField
					isAsync={true}
					alpha={true}
					query={GQL_FETCH_GROUPS}
					queryName="groups"
					label={t('common.fields.school.label')}
					form={form}
					name="school"
					filter={{
						types: ['School'],
						externalId: externalIds,
						visibleForRegistration: true,
					}}
				/>
				<Button variant="contained" color="primary" className={classes.button} children={t('common.save')} onClick={handleClick} />
			</div>
		</div>
	);
};

export default compose(withForm(formBundle))(CheckBrinCode);
