import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import classNames from "classnames";
import { LoadImage } from "@manakin/app-core";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
	root: {
		display: "flex",
		alignItems: "center",
		flexGrow: 1,
		flexDirection: "column",
		transform: "scale(1)",
		transition: "transform .3s",
		"&:hover": {
			transform: "scale(1.01)",
		},
	},
	durationContainer: {
		position: "relative",
		borderRadius: "100%",
		width: "75%",
		paddingBottom: "75%",
		height: 0,
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		marginLeft: "auto",
		marginRight: "auto",
		[theme.breakpoints.down("sm")]: {
			width: "60%",
			paddingBottom: "60%",
		},
		[theme.breakpoints.up("lg")]: {
			width: "60%",
			paddingBottom: "60%",
		},
	},
	image: {
		"& > img": {
			borderRadius: "100%",
		},
	},
	title: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(),
		textAlign: "center",
	},
	link: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		cursor: "pointer",
		textDecoration: "none",
	},
	linkIcon: {
		color: theme.manakin.indicationColor[500],
		marginRight: theme.spacing(2),
	},
	linkText: {
		color: theme.manakin.indicationColor[500],
		fontWeight: "400",
		marginBottom: 0,
	},
	linkContainer: {
		textDecoration: "none",
	},
	emptyProfile: {
		width: "100%",
		height: "100%",
		background: theme.manakin.defaultBorderColor[300],
		position: "absolute",
		borderRadius: "100%",
	},
	subHeader: {},
	contentColor: {},
});

const ProfileBlock = (props) => {
	const { classes, data = {} } = props;
	const { t } = useTranslation();

	return (
		<Link to="/account" className={classes.linkContainer}>
			<div className={classes.root}>
				<div className={classes.durationContainer}>
					{data && data.profilePicture && data.profilePicture.url ? (
						<LoadImage
							className={classes.image}
							src={
								data &&
								data.profilePicture &&
								data.profilePicture.url
							}
						/>
					) : (
						<div className={classes.emptyProfile} />
					)}
				</div>
				<Typography
					component="h4"
					variant="h4"
					className={classNames(
						classes.subHeader,
						classes.contentColor,
						classes.title
					)}
				>
					{(data && data.fullName) || ""}
				</Typography>
				<div className={classes.link}>
					<EditIcon className={classes.linkIcon} />
					<Typography
						component="p"
						variant="body2"
						className={classNames(
							classes.subHeader,
							classes.contentColor,
							classes.linkText
						)}
					>
						{t("app.progress-dashboard.blocks.profile.action")}
					</Typography>
				</div>
			</div>
		</Link>
	);
};

export default compose(
	withStyles(styles, { name: "AppProgressDashboardProfileBlock" })
)(ProfileBlock);
