import gql from 'graphql-tag';
import { masterClass, film } from '@manakin/app-core/graphQlHelpers';
import { fileAsset } from '@manakin/app-core/graphQlHelpers';

export const GQL_FETCH_MASTERCLASS = gql`
    query masterClass($id: ID!) {
        masterClass(id: $id) {
            ${masterClass}
        }
    }
`;

export const GQL_FETCH_FILM = gql`
    query film($id: ID!) {
        film(id: $id) {
            ${film}
        }
    }
`;

export const GQL_FETCH_GLOBAL_SETTINGS = gql`
    query {
        settings {
            settings {
                name
                value
                file {
                    ${fileAsset}
                }
            }
        }
    }
`;

export const GQL_FETCH_STYLESHEET = gql`
	query styleSheet($id: ID!) {
		styleSheet(id: $id) {
			id
			title
			description
			customStyle
			indicationColor
			defaultWorkformColor
			primaryWorkformColor
			secondaryWorkformColor
			themeColor
		}
	}
`;
