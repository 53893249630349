export const SET_APP_BAR_TITLE = '@manakin/core/SET_APP_BAR_TITLE';

export function setAppBarTitle(title) {
    return {
        type: SET_APP_BAR_TITLE,
        payload: {
            title,
        },
    };
}
