import React from "react";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "../styles";

const Header = (props) => {
	const { user } = props;
	const classes = useStyles();

	return (
		<div className={classes.headerRoot}>
			<div className={classes.wrapper}>
				<Typography
					variant="h2"
					component="h1"
					className={classes.heading}
				> </Typography>
			</div>
		</div>
	);
};

export default Header;
