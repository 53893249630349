import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from 'react-i18next';
import Grid from "@material-ui/core/Grid";
import classNames from "classnames";
import { useStyles } from './styles/index';
import { Link, useHistory, useLocation } from 'react-router-dom';

const FactCardsReferral = (props) => {
    const { data } = props;
    const classes = useStyles();
    const { t } = useTranslation();
	const history = useHistory();
	const location = useLocation();

	const generateLocation = () => {
		const preview = location.pathname.startsWith("/preview");
		return {
			pathname: preview ? '/preview/factcards': '/factCards',
			state: {
				fromElement: false,
				fromPreview: preview,
				fromLesson: true,
				tags: data.filterTags ? [ ...data.filterTags ] : [],
			},
		}
	};

	const handleMiddleClick = (event) => {
		// The state of the location will be lost upon middle click, therefore manually push the new path
		if (event.button === 1) {
			history.push(generateLocation());
		}
	};

	return (
		<Grid item key={data.id}>
			<div className={classNames(classes.root,
                { 
					[classes.primary]: data.backgroundColor == 'PRIMARY',
                	[classes.secondary]: data.backgroundColor == 'SECONDARY',
                	[classes.noBG]: data.backgroundColor == 'NONE' })
				}>
				<div className={classes.image}>
					<img className={classes.imageSrc} src={data.image ? data.image.url : null} />
				</div>
				<div className={classes.content}>
					<div className={classes.textContent}>
						<Typography
							component="h4"
							variant="subtitle1"
							classes={{
								subtitle1: classes.title,
							}}
						>
							{data.title || t('app.workforms.fact-cards-referral.title')}
						</Typography>
						<Typography component="h4" variant="body1" classes={{ body1: classes.body }}>
							{data.text || t('app.workforms.fact-cards-referral.description')}
						</Typography>
					</div>
					<Button
						component={Link}
						variant="contained"
						color="primary"
						onMouseDown={handleMiddleClick}
						className={classes.button}
						to={generateLocation()}
					>
						{t('app.workforms.fact-cards-referral.action')}
					</Button>
				</div>
			</div>
		</Grid>
	);
};

export default FactCardsReferral;