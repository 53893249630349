import React from 'react';
import { replace } from 'redux-first-history';
import { connect } from 'react-redux';

function parseSearch(search) {
    let query = {
        search: '',
        limit: 12,
        offset: 0,
        order: 'asc',
        orderBy: '',
        filters: '',
        programs: '',
        types: '',
        type: '',
        organisations: '',
        schools: '',
        schoolClasses: '',
        licenseIsValid: '',
        withoutClass: '',
        withoutLicense: '',
        accountEnabled: '',
    };

    const searchSplit = search.split('?');
    if (searchSplit[1] !== undefined) {
        query = searchSplit[1].split('&').reduce((acc, curr) => {
            const keyVal = curr.split('=');
            if (keyVal.length === 2 && query[keyVal[0]] !== undefined) {
                acc[keyVal[0]] = Number.isInteger(acc[keyVal[0]])
                    ? parseInt(keyVal[1], 10)
                    : '' + decodeURIComponent(keyVal[1]);
            }

            return acc;
        }, query);
    }

    return query;
}

function parseQuery(query) {
    return Object.keys(query).reduce((acc, curr) => {
        return (
            acc +
            encodeURIComponent(curr) +
            '=' +
            encodeURIComponent(query[curr]) +
            '&'
        );
    }, '?');
}

function withQuery(WrappedComponent) {
    return connect(
        (state) => ({
            query: parseSearch(state.router.location.search),
        }),
        (dispatch) => ({
            onQueryChange: (query) => {
                return dispatch(
                    replace({
                        search: parseQuery(query),
                    })
                );
            },
        })
    )(
        class WithQuery extends React.Component {
            render() {
                return <WrappedComponent {...this.props} />;
            }
        }
    );
}

export default withQuery;
