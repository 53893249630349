"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _Dialog = require("@material-ui/core/Dialog");

var _Dialog2 = _interopRequireDefault(_Dialog);

var _core = require("@manakin/core");

var _core2 = require("@material-ui/core");

var _reactRedux = require("react-redux");

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

var _reactTimePicker = require("react-time-picker");

var _reactTimePicker2 = _interopRequireDefault(_reactTimePicker);

var _Button = require("@material-ui/core/Button");

var _Button2 = _interopRequireDefault(_Button);

var _graphql = require("./graphql");

var _styles = require("./styles");

var _actions = require("@manakin/core/actions");

var _lib = require("../lib");

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Planner = function Planner(props) {
	var onClose = props.onClose,
	    open = props.open,
	    date = props.date,
	    form = props.form,
	    _props$data = props.data,
	    data = _props$data === undefined ? {} : _props$data,
	    onDelete = props.onDelete;

	var classes = (0, _styles.useStyles)();

	var _useState = (0, _react.useState)("00:00"),
	    _useState2 = _slicedToArray(_useState, 2),
	    time = _useState2[0],
	    setTime = _useState2[1];

	var _useState3 = (0, _react.useState)(false),
	    _useState4 = _slicedToArray(_useState3, 2),
	    timeChanged = _useState4[0],
	    setTimeChanged = _useState4[1];

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var dispatch = (0, _reactRedux.useDispatch)();

	var handleClick = function handleClick() {
		if (!time) return;

		if (!timeChanged) {
			return dispatch((0, _actions.showSnackbarMessage)({
				text: t("app.planner.create-dialog.no-time-warning"),
				variant: "warning"
			}));
		}

		var formatTime = time.split(":");
		var formatDate = (0, _moment2.default)(date).set({
			hour: formatTime[0],
			minute: formatTime[1]
		});

		if (props.onClick) props.onClick(formatDate, data);
	};

	var handleChange = function handleChange(e) {
		// Because of the "onChange" property, the browser will also fire the native "onChange event", skip this
		if (typeof e === "string") {
			setTime(e);
			if (!timeChanged) setTimeChanged(true);
		}
	};

	var handleDelete = function handleDelete() {
		if (onDelete && data.id) onDelete(data.id);
	};

	var initialValue = data || {};

	return _react2.default.createElement(
		_Dialog2.default,
		{
			open: open,
			fullWidth: true,
			className: classes.dialog,
			maxWidth: "sm"
		},
		_react2.default.createElement(
			"div",
			{ className: classes.contentWrap },
			_react2.default.createElement(
				_core2.Typography,
				{ variant: "h4", className: classes.title },
				t("app.planner.create-dialog.title")
			),
			_react2.default.createElement(
				_core2.Typography,
				null,
				(0, _lib.parseLocalDateTime)(initialValue.time || date, t).format("dddd DD-MM-YYYY")
			),
			_react2.default.createElement(_reactTimePicker2.default, {
				onChange: function onChange(e) {
					return handleChange(e);
				},
				format: "HH:mm",
				value: initialValue.time ? (0, _lib.parseLocalDateTime)(initialValue.time, t).format("HH:mm") : time,
				disableClock: true,
				clearIcon: null
			}),
			_react2.default.createElement(_core.SelectField, {
				noLoading: true,
				isAsync: true,
				query: _graphql.GQL_FETCH_INVITATION_STUDENTS,
				queryName: "invitationStudents",
				label: t("common.student"),
				form: form,
				customQuery: true,
				name: "selectedUser",
				initialValue: initialValue.student || null
			}),
			_react2.default.createElement(_core.SelectField, {
				noLoading: true,
				isAsync: true,
				query: _graphql.GQL_FETCH_APPOINTMENT_SUBJECTS,
				queryName: "appointmentSubjects",
				customQuery: true,
				label: t("app.planner.create-dialog.fields.subject.label"),
				form: form,
				name: "subject",
				initialValue: initialValue.subject || null
			}),
			_react2.default.createElement(
				_Button2.default,
				{
					className: classes.button,
					onClick: handleClick,
					variant: "contained",
					color: "primary"
				},
				t("common.save")
			),
			_react2.default.createElement(
				_Button2.default,
				{
					color: "primary",
					className: classes.button,
					onClick: onClose
				},
				t("common.cancel")
			),
			data && data !== "addAccount" && _react2.default.createElement(
				_Button2.default,
				{
					color: "primary",
					className: classes.button,
					onClick: handleDelete
				},
				t("common.delete")
			)
		)
	);
};

exports.default = Planner;