import { createMuiTheme } from '@material-ui/core/styles';
import { createTheme, createColor } from '@manakin/theme';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';

const contentWidthM = '1140px';
const contentWidthL = '1600';
const contentWidthS = '748px';
const contentWidthXS = '570px';

//colors
const primaryColor = '#282828';
const secondaryColor = '#EDEDF5';
const primaryThemeColor = '#FFECC2';
const secondaryThemeColor = '#C0E1D7';
const tertiaryColor = "#FFFFFF"; //TODO
const quaternaryColor = "#000000"; //TODO
const quinaryColor = "#F44336"; //TODO
const defaultContentColor = '#000000';
const senaryColor = ""; //TODO
const septenaryColor = ""; //TODO
const octonaryColor = ""; //TODO
const nonaryColor = ""; //TODO
const denaryColor = ""; //TODO
const otherRedThemeColor = '#F44336';
const otherWhiteThemeColor = '#FFFFFF';
const otherBlackThemeColor = '#000000';
const otherWidgetThemeColor = '#5541C9';
const iconColor = '#242445';
const defaultTextColor = '#6E6E86';
const defaultTitleColor = '#242445';
const homeLinkColor = defaultContentColor;
const saveButton = '#34C279';
const saveButtonColor = 'white';
const checkboxChecked = '#34C279';
const labelColor = "#242445";
const defaultBorderColor = '#C9CCE0';
const loginBackground = '#fff';
const disabledFieldLabelColor = "rgba(36, 36, 69, 0.5)";
const disabledFieldTextColor = "rgba(110, 110, 134, 0.5)";
const disabledFieldBorderColor = "rgba(151,151,151,0.54)";
const warningColor = '#FE8612';


//fonts
const primaryFont = "'HK Grotesk', sans-serif";

//padding
const defaultContentPaddingLeft = "60px";
const defaultContentPaddingRight = "60px";

//margin
const normalMargin = "0 0 44px";
const normalMarginTop = "0";
const normalMarginBottom = "44px";

const theme = createMuiTheme({
    palette: {
        primary: createColor(primaryColor),
        secondary: createColor(secondaryColor),
        success: {
            light: green[200],
            main: green[400],
            dark: green[600],
            contrastText: '#ffffff'
        },
        info: {
            light: blue[300],
            main: blue[400],
            dark: blue[500],
            contrastText: '#ffffff'
        },
        warning: {
            light: amber[400],
            main: amber[600],
            dark: amber[700],
            contrastText: '#ffffff'
        }
    },
    manakin: createTheme({
        buttonColors: {
            saveButton: saveButton,
            saveButtonColor: saveButtonColor
        },
        defaultBordercolor: createColor(defaultBorderColor),
        defaultContentColor: createColor(defaultContentColor),
        defaultLabelColor: createColor(labelColor),
        defaultPadding: {
            normalPadding: '25px'
        },
        defaultTextColor: createColor(defaultTextColor),
        defaultTitleColor: createColor(defaultTitleColor),
        denaryThemeColor: createColor(denaryColor),
        disabledFieldBorderColor: disabledFieldBorderColor,
        disabledFieldLabelColor: disabledFieldLabelColor,
        disabledFieldTextColor: disabledFieldTextColor,
        homeLinkColor: createColor(homeLinkColor),
        iconColor: {
            color: createColor(iconColor)
        },
        loginBackground: loginBackground,
        nonaryThemeColor: createColor(nonaryColor),
        normalMargin: normalMargin,
        octonaryThemeColor: createColor(octonaryColor),
        otherBlackThemeColor: createColor(otherBlackThemeColor),
        otherBlueThemeColor: createColor(otherWidgetThemeColor),
        otherDarkBlueThemeColor: createColor(labelColor),
        otherRedThemeColor: createColor(otherRedThemeColor),
        otherWhiteThemeColor: createColor(otherWhiteThemeColor),
        primaryColor: createColor('#fff'),
        primaryFont: primaryFont,
        primaryThemeColor: createColor(primaryThemeColor),
        quaternaryThemeColor: createColor(quaternaryColor),
        quinaryThemeColor: createColor(quinaryColor),
        warningColor: createColor(warningColor),
        root: {
            width: '100%',
            height: '100%'
        },
        secondaryThemeColor: createColor(secondaryThemeColor),
        senaryThemeColor: createColor(senaryColor),
        septenaryThemeColor: createColor(septenaryColor),
        sizes: {
            appMenu: 300
        },
        tertiaryThemeColor: createColor(tertiaryColor),
        wrapper: {
            extraSmall: contentWidthXS,
            small: contentWidthS,
            medium: contentWidthM,
            large: contentWidthL
        },
    }),
    overrides: {
        AuthenticationConfirmReset: {
            root: {
                textAlign: 'center',
            },
        },
        MuiButton: {
            root: {
                border: '0',
                padding: '12px 20px',
                borderRadius: '22px'
            },
            contained: {
                boxShadow: 'none'
            },
            label: {
                fontFamily: primaryFont,
                fontSize: '18px',
                fontWeight: '600'
            }
        },
        MuiCheckbox: {
            root: {
                color: defaultTextColor,
                "&$checked": {
                    color: checkboxChecked
                }
            }
        },
        MuiDialog: {
            paperWidthSm: {
                minWidth: '400px',
            },
        },
        MuiDialogActions: {
            root: {
                backgroundColor: grey['100'],
                margin: '0',
                borderTop: '1px solid',
                borderColor: grey['300']
            }
        },
        MuiDialogContent: {
            root: {
                padding: '24px 24px 0',
                minHeight: '400px',
            },
        },
        MuiDialogTitle: {
            root: {
                backgroundColor: grey['100'],
                borderBottom: '1px solid',
                borderColor: grey['300'] + " !important"
            }
        },
        MuiFormControl: {
            marginNormal: {
                marginTop: normalMarginTop,
                marginBottom: normalMarginBottom
            }
        },
        MuiFormControlLabel: {
            label: {
                color: labelColor,
                fontSize: '18px',
            }
        },
        MuiFormGroup: {
            root:{
                marginTop: normalMarginTop,
                marginBottom: normalMarginBottom
            }
        },
        MuiFormLabel: {
            root: {
                fontSize: "24px",
                fontWeight: "600",
                color: labelColor
            }
        },
        MuiInput: {
            input: {
                padding: '6px 0 12px'
            },
            root: {
                paddingTop: '14px'
            }
        },
        MuiInputLabel: {
            root: {
                color: labelColor,
            }
        },
        MuiSpeedDial: {
            actionsClosed: {
                height: 0,
            },
        },
        MuiSwitch: {
            switchBase: {
                color: 'red',
                '&$checked': {
                    color: 'green!important',
                },
            },
            colorSecondary: {
                '&.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: grey['700']
                },
            }
        },
        MuiTablePagination: {
            input: {
                padding: '7px 0 0'
            }
        },
        MuiTypography: {
            h5: {
                fontFamily: primaryFont,
                fontSize: '20px',
                color: defaultTitleColor,
                fontWeight: 600
            },
            h2: {
                fontFamily: primaryFont,
                fontSize: '30px',
                fontWeight: 'bold',
                color: 'black'
            },
            h1: {
                fontFamily: primaryFont,
                fontSize: '18px',
                fontWeight: 'bold',
                color: 'black'
            },
            body1: {
                fontFamily: primaryFont
            },
            h6: {
                fontSize: '20px',
                fontFamily: primaryFont
            }
        },
    },
});

export default theme;
