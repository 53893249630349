import React, { useContext } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ShoppingListContext from './context/shoppingListContext';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import renderHTML from 'react-render-html';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				margin: '0 10px 10px',
				background: 'white',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				[theme.breakpoints.up('md')]: {
					margin: '0 10px 20px',
					flex: '1 0 21%',
					maxWidth: 'calc(100% / 2 - 20px)'
				},
				[theme.breakpoints.down("md")]: {
					minWidth: "35%"
				},
				[theme.breakpoints.up('lg')]: {
					maxWidth: 'calc(100% / 4 - 20px)',
				},
			},
			mainButtonStyle: {
				padding: '1.5rem 4rem',
				width: '100%',
				marginTop: '20px',
				[theme.breakpoints.up('md')]: {
					marginBottom: "1rem",
				},
			},
			row: {
				width: '100%',
				backgroundColor: 'white',
				padding: '2rem',
				display: 'flex',
				justifyContent: 'center',
				flexDirection: 'column',
				marginBottom: '2px',
				[theme.breakpoints.up('md')]: {
					padding: '1rem 3.8rem',
				},
				"& li, & p": {
					fontSize: "1.6rem",
					lineHeight: "3.2rem",
				}
			},
			usp: {
				padding: 0,
				marginTop: 0,
				marginBottom: "auto",
				"& li, & > p > p" :{
					padding: "2rem",
					[theme.breakpoints.up('md')]: {
						padding: "1rem 3.8rem"
					},
				},
				"& li": {
					borderBottom: `2px solid ${theme.manakin.loginBackground[500]}`,
					listStyleType: "none"
				}
			},
			top: {
				backgroundColor: theme.manakin.primaryColor[500],
				"& > p": {
					lineHeight: "3.2rem",
					fontSize: "1.9rem",
				},
				[theme.breakpoints.up('md')]: {
					padding: '2rem 3rem',
				},
			},
			pricing: {
				marginTop: "1rem"
			},
			heading: {
				color: 'white',
				textAlign: 'center',
			},
			fontStyle: {
				margin: 0,
			},
			active: {
				backgroundColor: 'white',
				color: theme.manakin.primaryColor[500],
				border: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
			},
			currentYear: {
				backgroundColor: 'white!important',
				border: 0,
			},
		}),
	{
		name: 'AppWebshopProduct',
	}
);

const Product = (props) => {
	const { data } = props;
	const { id, name = '', priceCents, firstUspText, secondUspText, showInWebshop } = data || {};
    const price = parseFloat(priceCents / 100).toFixed(2);
	const classes = useStyles();
	const { products, setProducts } = useContext(ShoppingListContext);
	const { t } = useTranslation();

	const handleClick = () => {
		let newProducts = { ...products, [id]: data };
		if (products[id]) {
			delete newProducts[id];
		}

		Cookies.set('shop', newProducts);
		setProducts(newProducts);
	};

	if (!showInWebshop) return <div />;
	return (
		<div className={classes.root}>
			<div className={classNames(classes.row, classes.top)}>
				<Typography variant="body2" className={classNames(classes.heading, classes.fontStyle)}>
					{name}
				</Typography>
			</div>
			{firstUspText && (
				<div className={classNames(classes.row, classes.usp)}>
					<Typography variant="body1" className={classes.fontStyle}>
						{renderHTML(firstUspText || '')}
					</Typography>
				</div>
			)}
			{secondUspText && (
				<div className={classNames(classes.row, classes.usp)}>
					<Typography variant="body1" className={classes.fontStyle}>
						{renderHTML(secondUspText || '')}
					</Typography>
				</div>
			)}
			<div className={classNames(classes.row, classes.pricing)}>
				<Typography variant="body1" className={classes.fontStyle}>
					{price} {t('authentication.webshop.no-btw')}
				</Typography>
				<Button
					onClick={handleClick}
					color="primary"
					variant="contained"
					className={classNames(classes.mainButtonStyle, {
						[classes.active]: products[id],
					})}
				>
					{products[id] ? t('common.delete') : t('common.add')}
				</Button>
			</div>
		</div>
	);
};

export default Product;
