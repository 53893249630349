import React from 'react';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ConfirmInvitationForm from "./ConfirmInvitationForm";
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(
    (theme) =>
        createStyles({
            root: {
                paddingTop: '6rem',
                minHeight: '100%',
                width: '100vw',
                height: '100vh',
            },
            heading: {
                textAlign: 'center',
            },
            smallWrapper: {
                maxWidth: theme.manakin.wrapper['extraSmall'],
                width: '90%',
                margin: '0 auto',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            },
            link: {
                marginTop: theme.spacing(3),
                textDecoration: 'none',
                display: 'block',
                textAlign: 'center',
                color: theme.manakin.defaultContentColor
                    ? theme.manakin.defaultContentColor[500]
                    : 'black',
                fontSize: '18px',
                '&:visited': {
                    color: theme.manakin.defaultContentColor
                        ? theme.manakin.defaultContentColor[500]
                        : 'black',
                },
            },
        }),
    {
        name: 'AppConfirmInvitation',
    }
);

const AppConfirmInvitation = props => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.smallWrapper}>
                <Typography className={classes.heading} variant="h2">
                    {t("authentication.confirm-invitation.title")}
                </Typography>

                <ConfirmInvitationForm/>
            </div>
        </div>
    );
};

export default AppConfirmInvitation;
