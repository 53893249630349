'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.isAppMenuOpen = exports.APP_MENU_STATE_ROOT_APP = undefined;

var _reselect = require('reselect');

var _selectors = require('../selectors');

var APP_MENU_STATE_ROOT_APP = exports.APP_MENU_STATE_ROOT_APP = 'appMenu';

var getState = (0, _reselect.createSelector)([_selectors.getCoreState], function (coreState) {
    return coreState[APP_MENU_STATE_ROOT_APP];
});

var isAppMenuOpen = exports.isAppMenuOpen = (0, _reselect.createSelector)([getState], function (state) {
    return state.open;
});