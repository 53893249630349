"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _styles = require("@material-ui/core/styles");

var _reactRedux = require("react-redux");

var _actions = require("./actions");

var _selectors = require("./selectors");

var _recompose = require("recompose");

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _SearchInput = require("./Search/parts/SearchInput");

var _SearchInput2 = _interopRequireDefault(_SearchInput);

var _parts = require("./Search/parts");

var _Button = require("@material-ui/core/Button");

var _Button2 = _interopRequireDefault(_Button);

var _Close = require("@material-ui/icons/Close");

var _Close2 = _interopRequireDefault(_Close);

var _reactRouterDom = require("react-router-dom");

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
	var _closeButton;

	return {
		root: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			zIndex: 1100,
			opacity: 0,
			animation: "showBackwards .3s ease forwards",
			overflow: "auto",
			boxShadow: "0 0 " + theme.spacing(1) + "px 0 rgba(0,0,0,.1)"
		},
		top: _defineProperty({
			width: "100%",
			height: theme.manakin.defaultMobileMenuHeight,
			backgroundColor: "white",
			top: 0,
			left: 0,
			position: "relative",
			zIndex: 99,
			display: "flex",
			alignItems: "center",
			paddingLeft: theme.manakin.defaultPadding
		}, theme.breakpoints.up("md"), {
			height: theme.manakin.defaultDesktopMenuHeight
		}),
		lightColor: {
			color: "white"
		},
		content: _defineProperty({
			backgroundColor: "white",
			width: "100%",
			padding: "64px 0 0",
			display: "flex"
		}, theme.breakpoints.up("md"), {
			padding: "100px 100px 0 50px"
		}),
		fullWidth: {
			"& $content": {
				padding: 0
			}
		},
		link: _defineProperty({
			height: theme.manakin.defaultMobileMenuHeight,
			width: theme.manakin.defaultMobileMenuHeight
		}, theme.breakpoints.up("md"), {
			width: theme.manakin.defaultDesktopMenuHeight,
			height: theme.manakin.defaultDesktopMenuHeight
		}),
		closeButton: (_closeButton = {
			borderRadius: 0,
			boxShadow: "none",
			height: theme.manakin.defaultMobileMenuHeight,
			minHeight: theme.manakin.defaultMobileMenuHeight,
			width: theme.manakin.defaultMobileMenuHeight,
			minWidth: theme.manakin.defaultMobileMenuHeight,
			top: 0
		}, _defineProperty(_closeButton, theme.breakpoints.up("md"), {
			width: theme.manakin.defaultDesktopMenuHeight,
			minWidth: theme.manakin.defaultDesktopMenuHeight,
			height: theme.manakin.defaultDesktopMenuHeight,
			minHeight: theme.manakin.defaultDesktopMenuHeight
		}), _defineProperty(_closeButton, "& svg", {
			transition: "transform .3s",
			transform: "rotate(0deg)"
		}), _defineProperty(_closeButton, "&:hover", {
			"& svg": _defineProperty({}, theme.breakpoints.up("md"), {
				transform: "rotate(90deg)"
			})
		}), _closeButton)
	};
};

var mapStateToProps = function mapStateToProps(state) {
	return {
		config: state.config,
		searchBarOpen: (0, _selectors.isSearchBarOpen)(state)
	};
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
	return {
		onToggleSearchBar: function onToggleSearchBar() {
			return dispatch((0, _actions.toggleSearchBar)());
		}
	};
};

var SearchBar = function (_React$PureComponent) {
	_inherits(SearchBar, _React$PureComponent);

	function SearchBar() {
		var _ref;

		var _temp, _this, _ret;

		_classCallCheck(this, SearchBar);

		for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
			args[_key] = arguments[_key];
		}

		return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = SearchBar.__proto__ || Object.getPrototypeOf(SearchBar)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
			query: ""
		}, _this.handleSearchQueryChange = function (query) {
			_this.setState({
				query: query
			});
		}, _this.handleClose = function () {
			_this.props.onToggleSearchBar();
		}, _this.handleResultClick = function (url, resultType) {
			var _this$props = _this.props,
			    onToggleSearchBar = _this$props.onToggleSearchBar,
			    location = _this$props.location,
			    _this$props$config = _this$props.config,
			    config = _this$props$config === undefined ? {} : _this$props$config;
			var _config$pages = config.pages,
			    pages = _config$pages === undefined ? {} : _config$pages;

			onToggleSearchBar();

			if (resultType === "Box" && pages.showDashboard && location.pathname === "/dashboard") {
				// If there is a custom dashboard for the whitelabel, save the original referrer location
				_this.props.history.push(url, { fromProgramDashboard: true });
			} else {
				_this.props.history.push(url);
			}
		}, _temp), _possibleConstructorReturn(_this, _ret);
	}

	_createClass(SearchBar, [{
		key: "render",
		value: function render() {
			var _props = this.props,
			    classes = _props.classes,
			    _props$fullWidth = _props.fullWidth,
			    fullWidth = _props$fullWidth === undefined ? true : _props$fullWidth,
			    searchBarOpen = _props.searchBarOpen,
			    t = _props.t;


			return _react2.default.createElement(
				"div",
				null,
				searchBarOpen && _react2.default.createElement(
					"div",
					{
						className: (0, _classnames2.default)(classes.root, _defineProperty({}, classes.fullWidth, fullWidth))
					},
					_react2.default.createElement(
						"div",
						{ className: classes.top },
						_react2.default.createElement(_SearchInput2.default, {
							fullWidth: fullWidth,
							onQueryChange: this.handleSearchQueryChange
						}),
						_react2.default.createElement(
							_Button2.default,
							{
								variant: "contained",
								color: "primary",
								"aria-label": t("common.close"),
								onClick: this.handleClose,
								className: classes.closeButton
							},
							_react2.default.createElement(_Close2.default, null)
						)
					),
					_react2.default.createElement(
						"div",
						{ className: classes.content },
						this.state.query.length > 2 && _react2.default.createElement(_parts.Results, _extends({}, this.state, {
							onResultClick: this.handleResultClick
						}))
					)
				)
			);
		}
	}]);

	return SearchBar;
}(_react2.default.PureComponent);

exports.default = (0, _recompose.compose)(_reactRouterDom.withRouter, (0, _reactI18next.withTranslation)(), (0, _styles.withStyles)(styles, { name: "AppSearchBar" }), (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps))(SearchBar);