import React from "react";
import Layout from "./Layout";
import SaveBrowser from "./SaveBrowser";
import { Link } from "react-router-dom";
import { makeStyles, Typography } from "@material-ui/core";
import CodeBlock from "./Codeblock";
import Button from "@material-ui/core/Button";
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	title: {
		margin: "2rem 0",
	},
	link: {
		marginTop: theme.spacing(2),
		display: "block",
	}
}));

const HandleTwoFactor = (props) => {
	const { form, onClick } = props;
	const classes = useStyles();
	const { t } = useTranslation();

	// Functions
	const handleClick = () => {
		onClick();
	};

	return (
		<Layout title={t("authentication.two-factor.login.title")}>
			<Typography className={classes.title}>
				{t("authentication.two-factor.login.description")}
			</Typography>
			<CodeBlock form={form} />
			<SaveBrowser form={form}/>
			<Button variant="contained" color="primary" onClick={handleClick}>
				{t("common.confirm")}
			</Button>
			<Link className={classes.link} to={`reset-2factor`}>
				{t("authentication.two-factor.login.reset-action")}
			</Link>
		</Layout>
	);
};

export default HandleTwoFactor;
