import React, { useState, useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core";
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: { width: "100%" },
  label: { margin: 0, lineHeight: "2.2rem" },
}));

const SaveBrowser = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { form, initialValue = true } = props;
  const name = "rememberMe";

  const [value, setValue] = useState(initialValue);

  const handleChange = (event) => {
    setValue(event.target.checked);
    if (form) form.onFieldChange({ key: name, value: event.target.checked });
  };

  useEffect(() => {
    setValue(initialValue);
    if (form) form.onFieldChange({ key: name, value: initialValue });
  }, [initialValue]);

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={value}
            onChange={handleChange}
            name={name}
            color="primary"
          />
        }
        label={t("authentication.two-factor.login.trust-browser")}
        classes={{
          root: classes.root,
          label: classes.label,
        }}
      />
    </div>
  );
};

export default SaveBrowser;
