'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _graphql = require('../graphql');

var _core = require('@material-ui/core');

var _Done = require('@material-ui/icons/Done');

var _Done2 = _interopRequireDefault(_Done);

var _reactHooks = require('@apollo/react-hooks');

var _ = require('../../');

var _appCore = require('@manakin/app-core');

var _reactI18next = require('react-i18next');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var BookCheck = function BookCheck(props) {
    var _classNames, _classNames2;

    var classes = props.classes,
        item = props.item,
        tags = props.tags,
        show = props.show;

    var imageUrl = item.image ? item.image.url : '';
    var placeholder = item.image ? item.image.thumbnailUrl : '';

    var _useTranslation = (0, _reactI18next.useTranslation)(),
        t = _useTranslation.t;

    var _useQuery = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_BOOKCHECK, {
        variables: {
            id: item.id
        }
    }),
        data = _useQuery.data;

    var _ref = data && data.bookCheck || {},
        _ref$cardTitle = _ref.cardTitle,
        cardTitle = _ref$cardTitle === undefined ? '' : _ref$cardTitle,
        _ref$cardText = _ref.cardText,
        cardText = _ref$cardText === undefined ? '' : _ref$cardText,
        _ref$titleColor = _ref.titleColor,
        titleColor = _ref$titleColor === undefined ? '' : _ref$titleColor;

    return _react2.default.createElement(
        'div',
        {
            className: (0, _classnames2.default)(classes.root, classes[item.type], (_classNames = {}, _defineProperty(_classNames, classes.fullWidth, item.fullWidth), _defineProperty(_classNames, classes.noMargin, true), _defineProperty(_classNames, classes.bookCheck, true), _defineProperty(_classNames, classes.disabled, !data), _defineProperty(_classNames, classes.show, show), _classNames)),
            style: {
                transition: 'transform .5s .' + 1 + 's, opacity .5s .' + 1 + 's',
                backgroundColor: item.backgroundColor
            }
        },
        _react2.default.createElement(_appCore.Tags, {
            tags: tags,
            className: (0, _classnames2.default)(classes.tags, (_classNames2 = {}, _defineProperty(_classNames2, classes.withIcon, item.done != undefined), _defineProperty(_classNames2, classes.bookcheckTag, item.type === 'BookCheck'), _classNames2))
        }),
        imageUrl && _react2.default.createElement(
            'div',
            { className: classes.backgroundImage },
            _react2.default.createElement(_.LoadImage, { src: imageUrl, placeholder: placeholder })
        ),
        _react2.default.createElement(
            'div',
            {
                className: (0, _classnames2.default)(classes.bookCheckFlex, _defineProperty({}, classes.bookCheckFlexDone, item.done))
            },
            _react2.default.createElement(
                'div',
                {
                    className: (0, _classnames2.default)(classes.bookCheckContent, _defineProperty({}, classes.darkContent, titleColor === 'SECONDARY'), _defineProperty({}, classes.lightContent, titleColor === 'PRIMARY'))
                },
                _react2.default.createElement(
                    _core.Typography,
                    {
                        className: (0, _classnames2.default)(classes.bodytitle, classes.name),
                        component: 'h5',
                        variant: 'h5'
                    },
                    cardTitle
                ),
                _react2.default.createElement(
                    _core.Typography,
                    {
                        component: 'p',
                        variant: 'body1',
                        className: classes.body
                    },
                    cardText
                )
            ),
            _react2.default.createElement(
                _core.Button,
                {
                    variant: 'contained',
                    color: 'primary',
                    fullWidth: true,
                    className: (0, _classnames2.default)(classes.button, classes.bookCheckButton, _defineProperty({}, classes.darkBookCheckButtonColor, titleColor === 'SECONDARY'), _defineProperty({}, classes.lightBookCheckButtonColor, titleColor === 'PRIMARY'))
                },
                t("app.elements.element-card.start-book-check"),
                item.done && _react2.default.createElement(
                    'div',
                    {
                        className: (0, _classnames2.default)(classes.done, classes.bookCheckDone)
                    },
                    _react2.default.createElement(
                        'span',
                        { className: classes.doneIcon },
                        item.done && _react2.default.createElement(_Done2.default, {
                            classes: { root: classes.iconRoot }
                        })
                    )
                )
            )
        )
    );
};

exports.default = BookCheck;