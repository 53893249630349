'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _core = require('@material-ui/core');

var _reactI18next = require('react-i18next');

var _appCore = require('@manakin/app-core');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useStyles = (0, _core.makeStyles)(function (theme) {
    return {
        content: {
            marginBottom: 32
        }
    };
});

var NoLicenseForProgramDialog = function NoLicenseForProgramDialog(props) {
    var onClose = props.onClose,
        open = props.open,
        appUser = props.appUser,
        group = props.group;

    var classes = useStyles();

    var _useTranslation = (0, _reactI18next.useTranslation)(),
        t = _useTranslation.t;

    var handleClose = function handleClose(event, reason) {
        if (reason === 'backdropClick') return;
        if (onClose) onClose();
    };

    var getMissingProductNames = function getMissingProductNames() {
        var userProducts = [];
        if (appUser && appUser.licenses) {
            appUser.licenses.forEach(function (license) {
                if (license.product) {
                    userProducts.push(license.product.id);

                    if (license.product.subProducts) {
                        license.product.subProducts.forEach(function (product) {
                            return userProducts.push(product.id);
                        });
                    }
                }
            });
        }

        var missingProducts = group.products && group.products.filter(function (product) {
            return !userProducts.includes(product.id);
        }) || [];

        return missingProducts.map(function (product) {
            return product.name;
        }).join(', ') || '';
    };

    return _react2.default.createElement(_appCore.StyledDialog, {
        open: open,
        onClose: handleClose,
        extraContent: _react2.default.createElement(
            'div',
            { className: classes.content },
            t('app-core.programs-dropdown.no-license-for-program-dialog.description-first-part'),
            ' ',
            _react2.default.createElement(
                'a',
                { href: process.env.REACT_APP_SSO_URL + '/Pages/LD/Secure/ActivateLicense.aspx' },
                t('app-core.programs-dropdown.no-license-for-program-dialog.description-middle-part')
            ),
            ' ',
            t('app-core.programs-dropdown.no-license-for-program-dialog.description-last-part', { productName: getMissingProductNames() })
        ),
        onPositiveButtonClick: handleClose,
        positiveButtonText: t('common.close') });
};

exports.default = NoLicenseForProgramDialog;