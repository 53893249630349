import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { graphql } from 'react-apollo';
import AssetsViewerContent from './AssetsViewerContent';
import { isOpen, getName } from './selectors';
import { closeAssetsViewer, pickAsset } from '@manakin/core/actions';
import { compose } from 'recompose';
import { GQL_FETCH_CMS_SETTINGS } from './graphql';
import { withTranslation } from "react-i18next";
import { SettingsKeys } from '@manakin/core/lib/constants';

const styles = (theme) => ({
    root: {},
    dialog: {
        width: '100%',
    },
    tile: {
        borderRadius: '4px',
    },
    assetImage: {
        cursor: 'pointer',
    },
    icon: {
        color: 'white',
    },
    bar: {
        transition: '0.25s',
    },
    unselectedBar: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    selectedBar: {
        backgroundColor: 'rgba(239, 74, 63, 0.8)',
    },
    dialogContent: {
        overflowY: 'scroll',
        position: 'relative',
    },
    searchBar: {
        zIndex: 1,
        position: 'fixed',
        paddingTop: theme.spacing(2),
        backgroundColor: 'white',
        left: 24,
        right: 24,
    },
    hiddenSearchBarAsPadding: {
        visibility: 'hidden',
        marginBottom: 16,
    },
});

const mapStateToProps = (state) => ({
    open: isOpen(state),
    name: getName(state),
});

const mapDispatchToProps = (dispatch) => ({
    onClose: () => dispatch(closeAssetsViewer()),
    onAssetPick: (data) => dispatch(pickAsset(data)),
});

const Transition = React.forwardRef((props, ref) => (
    <Slide direction="up" {...props} ref={ref} />
));

class AssetsViewerRichText extends React.Component {
    state = {
        page: 0,
        pageSize: 12,
        pickedAssetId: '',
        picketAssetSize: '',
        search: '',
    };

    handleClose = () => {
        this.props.onClose();
    };

    handleAssetPick = (assetId, size, asset) => {
        this.setState({
            pickedAssetId: assetId,
            picketAssetSize: size,
            picketAssetUrl: asset.thumbnails[size],
        });
    };

    handleSearch = (query) => {
        this.setState({
            page: 0,
            search: query,
        });
    };

    handlePageChange = (page) => {
        this.setState({
            page: parseInt(page),
        });
    };

    handleItemsPerPageChange = (pageSize) => {
        this.setState({
            pageSize: parseInt(pageSize),
        });
    };

    handleUsePickedAsset = () => {
        const {
            pickedAssetId: assetId,
            picketAssetSize: assetSize,
            picketAssetUrl,
        } = this.state;
        const { onAssetPick, name } = this.props;
        onAssetPick({
            name: name,
            id: assetId,
            size: assetSize,
            url: picketAssetUrl,
        });
        this.handleClose();
    };

    handleSearch = (query) => {
        this.setState({
            page: 0,
            search: query,
        });
    };

    render() {
        const { classes, open = false, data = {}, t } = this.props;
        const {
            page,
            pageSize,
            pickedAssetId,
            picketAssetSize,
            search,
        } = this.state;
        const { settings = {} } = data;

        let projectName = '';
        settings &&
            settings.settings &&
            settings.settings.map((item) => {
                if (item.name === SettingsKeys.PROJECT_NAME) projectName = item.value;
            });

        return (
            <div className={classes.root}>
                <Dialog
                    fullWidth
                    maxWidth={false}
                    TransitionComponent={Transition}
                    classes={{
                        paper: classes.dialog,
                    }}
                    open={open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        {t("core.asset-viewer.title")}
                    </DialogTitle>
                    <DialogContent
                        classes={{
                            root: classes.dialogContent,
                        }}
                    >
                        <AssetsViewerContent
                            page={page}
                            open={open}
                            search={search}
                            pageSize={pageSize}
                            pickedAssetId={pickedAssetId}
                            onSearch={this.handleSearch}
                            pickedAssetSize={picketAssetSize}
                            onAssetPick={this.handleAssetPick}
                            onPageChange={(page) => this.handlePageChange(page)}
                            projectName={projectName}
                            onItemsPerPageChange={(pageSize) =>
                                this.handleItemsPerPageChange(pageSize)
                            }
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            {t("common.cancel")}
                        </Button>
                        <Button
                            onClick={this.handleUsePickedAsset}
                            color="primary"
                        >
                            {t("common.use-plural")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default compose(
    graphql(GQL_FETCH_CMS_SETTINGS),
    withTranslation(),
    withStyles(styles)
)(AssetsViewerRichText);
