import React, { useCallback } from "react";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from 'react-i18next';
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles } from '@material-ui/styles';
import classNames from "classnames";
import ContinueIcon from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			backgroundColor: theme.manakin.defaultWorkformColor[500],
			display: 'flex',
			position: 'relative',
			height: '100%',
			cursor: 'pointer',
			[theme.breakpoints.down('sm')]: {
				flexFlow: 'row nowrap',
			},
		},
		content: {
			padding: theme.spacing(1),
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			width: '100%',
			flexFlow: 'row wrap',
			color: theme.palette.getContrastText(theme.manakin.defaultContentColor[500]),
			[theme.breakpoints.up('md')]: {
				flex: '1 0 calc(100% - 250px)',
				flexFlow: 'row nowrap',
			},
		},
		primary: {
			backgroundColor: theme.manakin.primaryWorkformColor[500],
			'& $content': {
				color: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),
			},
		},
		secondary: {
			backgroundColor: theme.manakin.secondaryWorkformColor[500],
			'& $content': {
				color: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
			},
		},
		noBG: {
			backgroundColor: 'transparent',
			border: `2px solid ${theme.manakin.defaultContentColor[500]}`,
			'& $content': {
				color: theme.manakin.defaultContentColor[500],
			},
		},
		textContent: {
			[theme.breakpoints.up('md')]: {
				flex: 1,
			},
		},
		textContentFullwidth: {
			[theme.breakpoints.up('md')]: {
				marginLeft: theme.spacing(2),
				marginRight: theme.spacing(8),
			},
		},
		image: {
			backgroundColor: theme.manakin.defaultWorkformColor[500],
			position: 'relative',
			minHeight: '150px',
			flex: '0 1 150px',
			aspectRatio: '1/1',
			display: 'none',

			[theme.breakpoints.up('sm')]: {
				display: 'block',
			},
		},
		body: {
			color: theme.palette.getContrastText(theme.manakin.defaultWorkformColor[500]),
			[theme.breakpoints.up('md')]: {
				margin: 0,
			},
			lineHeight: 'normal',
		},
		title: {
			fontSize: '24px',
			lineHeight: 'normal',
			marginBottom: 0,
			color: theme.palette.getContrastText(theme.manakin.defaultWorkformColor[500]),
			hyphens: 'auto',
		},
		imageSrc: {
			height: '100%',
			objectFit: 'cover',
			fontFamily: "'object-fit: cover'",
		},
		button: {
			color: theme.palette.getContrastText(theme.manakin.defaultWorkformColor[500]),
		},
		action: {
			position: 'absolute',
			right: 0,
			top: 0,
			cursor: 'pointer',
			width: '30px',
			height: '30px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			[theme.breakpoints.up('md')]: {
				width: '60px',
				height: '60px',
			},
		},
		actionIcon: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: '2.5rem',
			height: '2.5rem',
			color: theme.palette.getContrastText(theme.manakin.defaultWorkformColor[500]),
		},
		iconRoot: {
			flex: '1',
			width: 'auto',
			height: 'auto',
		},
	})
);

const FactCardReferralCard = (props) => {
    const { item, factCards, index, onClick, backgroundColor } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    const gridWidth = item.fullWidth ? 12 : 6;

    const handleClick = () => {
        onClick(factCards[index].id, factCards[index]);
    };

    return (
        <Grid
            item key={item.id}
            xs={12}
            sm={gridWidth}
            md={gridWidth}
            lg={gridWidth}
            xl={gridWidth}
        >
            <div className={classNames(classes.root,
                { 
					[classes.primary]: backgroundColor == 'PRIMARY',
                	[classes.secondary]: backgroundColor == 'SECONDARY',
                	[classes.noBG]: backgroundColor == 'NONE' })
				} onClick={handleClick}>
                <div className={classes.image}>
                    <img
                        className={classes.imageSrc}
                        src={factCards[index].image ? factCards[index].image.url : null}
                    />
                </div>
                <div className={classes.content}>
                    <div className={classNames(classes.textContent, {
                        [classes.textContentFullwidth]: item.fullWidth,
                    })}>
                        <Typography
                            component='h4'
                            variant='subtitle1'
                            classes={{
                                subtitle1: classes.title,
                            }}
                        >
                            {item.title || t("app.workforms.fact-card-referral.title", { title: factCards[index].title })}
                        </Typography>
                        <Typography
                            component='h4'
                            variant='body1'
                            classes={{ body1: classes.body }}
                        >
                            {item.text || t("app.workforms.fact-card-referral.description")}
                        </Typography>
                    </div>
					<div className={classes.action}>
						<span className={classes.actionIcon}>
							<ContinueIcon classes={{ root: classes.iconRoot }} />
						</span>
					</div>
                </div>
            </div>
        </Grid>
    );
};

export default FactCardReferralCard;