'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styles = require('@material-ui/core/styles');

var _InputLabel = require('@material-ui/core/InputLabel');

var _InputLabel2 = _interopRequireDefault(_InputLabel);

var _MenuItem = require('@material-ui/core/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _FormControl = require('@material-ui/core/FormControl');

var _FormControl2 = _interopRequireDefault(_FormControl);

var _Select = require('@material-ui/core/Select');

var _Select2 = _interopRequireDefault(_Select);

var _Checkbox = require('@material-ui/core/Checkbox');

var _Checkbox2 = _interopRequireDefault(_Checkbox);

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _icons = require('@material-ui/icons');

var _Chip = require('@material-ui/core/Chip');

var _Chip2 = _interopRequireDefault(_Chip);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var useStyles = (0, _styles.makeStyles)(function (theme) {
	var _menuItem;

	return (0, _styles.createStyles)({
		root: {
			pointerEvents: 'visible'
		},
		selected: {},
		menuItem: (_menuItem = {
			height: '50px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			backgroundColor: 'white',
			'&:nth-child(even)': {
				backgroundColor: '#f2f2f2'
			},
			'&:hover': {
				backgroundColor: theme.palette.primary[300],
				'& $listItemText': {
					color: theme.palette.getContrastText(theme.palette.primary[300])
				}
			}
		}, _defineProperty(_menuItem, theme.breakpoints.up('md'), {
			minWidth: '450px'
		}), _defineProperty(_menuItem, '&$selected', {
			backgroundColor: theme.palette.primary[500],
			color: theme.palette.getContrastText(theme.palette.primary[500]),
			'&:hover': {
				backgroundColor: theme.palette.primary[300],
				color: theme.palette.getContrastText(theme.palette.primary[300])
			}
		}), _menuItem),
		formControlLabel: {
			// transform: "translate(0, 33px)",
			color: theme.manakin.defaultContentColor[500]
		},
		listItemText: {
			margin: '0 0 0 10px'
		},
		checked: {
			color: 'black'
		},
		title: {
			display: 'flex',
			alignItems: 'center'
		},
		icon: {
			width: '20px',
			height: '20px',
			display: 'flex'
		},
		label: {
			color: theme.manakin.defaultContentColor[500]
		},
		chips: {
			marginTop: '10px',
			textAlign: 'left'
		},
		chip: {
			backgroundColor: 'white',
			marginRight: '20px'
		}
	});
}, {
	name: 'AppDasboardFilterSelectField'
});

var FilterSelectField = function FilterSelectField(props) {
	var label = props.label,
	    _props$options = props.options,
	    options = _props$options === undefined ? [] : _props$options,
	    _props$name = props.name,
	    name = _props$name === undefined ? '' : _props$name,
	    _props$initialValue = props.initialValue,
	    initialValue = _props$initialValue === undefined ? [] : _props$initialValue;

	var classes = useStyles();

	//state hooks

	var _useState = (0, _react.useState)([]),
	    _useState2 = _slicedToArray(_useState, 2),
	    value = _useState2[0],
	    setValue = _useState2[1];

	var _useState3 = (0, _react.useState)(false),
	    _useState4 = _slicedToArray(_useState3, 2),
	    open = _useState4[0],
	    setOpen = _useState4[1];

	//effect hooks


	(0, _react.useEffect)(function () {
		window.addEventListener('scroll', handleScroll);
	}, []);

	(0, _react.useEffect)(function () {
		if (initialValue.length && options) {
			var initial = [];
			options.forEach(function (i) {
				if (initialValue.some(function (val) {
					return val === i.label;
				})) initial.push(i.label);
			});
			setValue(initial);
			handleForm(initial);
		}
	}, [initialValue]);

	//functions
	var handleForm = function handleForm(val) {
		var arr = [];
		options.forEach(function (item) {
			if (val.some(function (i) {
				return i === item.label;
			})) arr.push(item);
		});

		if (props.onChange) props.onChange(arr, name);
	};

	var handleChange = function handleChange(event) {
		var val = event.target.value;
		setValue(val);
		handleForm(val);
	};

	var handleDelete = function handleDelete(val) {
		return function () {
			var arr = [];

			value.forEach(function (item) {
				if (item !== val) arr.push(item);
			});
			setValue(arr);
			handleForm(arr);
		};
	};

	var handleOpenState = function handleOpenState(state) {
		setOpen(state);
	};

	var handleScroll = function handleScroll() {
		handleOpenState(false);
	};

	return _react2.default.createElement(
		_FormControl2.default,
		{ className: classes.root },
		_react2.default.createElement(
			_InputLabel2.default,
			{ id: 'filter-label', classes: { formControl: classes.formControlLabel } },
			label
		),
		_react2.default.createElement(
			_Select2.default,
			{
				labelId: 'filter-label',
				id: 'theme-select',
				value: value,
				multiple: true,
				renderValue: function renderValue(selected) {
					return '';
				},
				onChange: handleChange,
				onOpen: function onOpen() {
					return handleOpenState(true);
				},
				onClose: function onClose() {
					return handleOpenState(false);
				},
				open: open,
				IconComponent: _icons.KeyboardArrowDown
			},
			options.map(function (option) {
				return _react2.default.createElement(
					_MenuItem2.default,
					{
						key: option.id,
						value: option.label,
						classes: {
							root: classes.menuItem,
							selected: classes.selected
						}
					},
					_react2.default.createElement(
						'div',
						{ className: classes.title },
						option.icon && _react2.default.createElement(
							'figure',
							{ className: classes.icon },
							_react2.default.createElement('img', { src: option.icon.url })
						),
						_react2.default.createElement(
							_Typography2.default,
							{ variant: 'body1', className: classes.listItemText },
							option.label
						)
					),
					_react2.default.createElement(_Checkbox2.default, {
						checked: value.indexOf(option.label) > -1,
						color: 'primary',
						classes: {
							checked: classes.checked
						}
					})
				);
			})
		),
		_react2.default.createElement(
			'div',
			{ className: classes.chips },
			value.map(function (val) {
				return _react2.default.createElement(_Chip2.default, { key: val, label: val, className: classes.chip, onDelete: handleDelete(val) });
			})
		)
	);
};

exports.default = FilterSelectField;