"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactRenderHtml = require("react-render-html");

var _reactRenderHtml2 = _interopRequireDefault(_reactRenderHtml);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _reactApollo = require("react-apollo");

var _recompose = require("recompose");

var _graphql = require("../graphql");

var _core = require("@material-ui/core");

var _ = require("../../");

var _ArrowRightAlt = require("@material-ui/icons/ArrowRightAlt");

var _ArrowRightAlt2 = _interopRequireDefault(_ArrowRightAlt);

var _reactHooks = require("@apollo/react-hooks");

var _appCore = require("@manakin/app-core");

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var FileSaver = require("file-saver");


var BookDownload = function BookDownload(props) {
	var _classNames, _classNames2, _classNames4;

	var item = props.item,
	    classes = props.classes,
	    client = props.client,
	    tags = props.tags;

	var imageUrl = item.image ? item.image.url : "";
	var placeholder = item.image ? item.image.thumbnailUrl : "";

	var _useLazyQuery = (0, _reactHooks.useLazyQuery)(_graphql.GQL_FETCH_FILE),
	    _useLazyQuery2 = _slicedToArray(_useLazyQuery, 2),
	    loadBookFile = _useLazyQuery2[0],
	    _useLazyQuery2$ = _useLazyQuery2[1],
	    errors = _useLazyQuery2$.errors,
	    bookData = _useLazyQuery2$.data;

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var _useQuery = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_BOOK_DOWNLOAD, {
		variables: {
			id: item.id
		}
	}),
	    data = _useQuery.data;

	var _ref = data && data.bookDownload || {},
	    _ref$introTitle = _ref.introTitle,
	    introTitle = _ref$introTitle === undefined ? "" : _ref$introTitle,
	    _ref$text = _ref.text,
	    text = _ref$text === undefined ? "" : _ref$text,
	    _ref$workforms = _ref.workforms,
	    workforms = _ref$workforms === undefined ? [] : _ref$workforms,
	    _ref$titleColor = _ref.titleColor,
	    titleColor = _ref$titleColor === undefined ? "" : _ref$titleColor;

	(0, _react.useEffect)(function () {
		if (!errors && bookData) {
			var fileDownload = bookData.fileDownload;

			if (!fileDownload) return;

			if (fileDownload.openAction && fileDownload.openAction == "current") {
				FileSaver.saveAs(fileDownload.file && fileDownload.file.url);
			} else if (fileDownload.file && fileDownload.file.url) {
				window.open(fileDownload.file.url, "_blank");
			}
		}
	}, [bookData]);

	var downloadFile = function downloadFile(id) {
		if (workforms) {
			loadBookFile({
				variables: {
					id: id
				}
			});
		}
	};

	return _react2.default.createElement(
		"div",
		{
			className: (0, _classnames2.default)(classes.root, classes.show, classes[item.type], (_classNames = {}, _defineProperty(_classNames, classes.fullWidth, item.fullWidth), _defineProperty(_classNames, classes.noMargin, false), _defineProperty(_classNames, classes.noBackground, false), _defineProperty(_classNames, classes.bookCheck, workforms.length <= 1), _defineProperty(_classNames, classes.primaryBackgroundColor, item.backgroundColor == "SECONDARY"), _defineProperty(_classNames, classes.secondaryBackgroundColor, item.backgroundColor == "PRIMARY"), _classNames)),
			style: {
				transition: "transform .5s ." + (1 + 1) + "s, opacity .5s ." + (1 + 1) + "s",
				backgroundColor: item.backgroundColor
			}
		},
		_react2.default.createElement(_appCore.Tags, {
			tags: tags,
			className: (0, _classnames2.default)(classes.tags, (_classNames2 = {}, _defineProperty(_classNames2, classes.withIcon, item.done != undefined), _defineProperty(_classNames2, classes.bookcheckTag, item.type === "BookCheck"), _classNames2))
		}),
		imageUrl && workforms.length <= 1 && _react2.default.createElement(
			"div",
			{ className: classes.backgroundImage },
			_react2.default.createElement(_.LoadImage, { src: imageUrl, placeholder: placeholder })
		),
		_react2.default.createElement(
			"div",
			{
				className: (0, _classnames2.default)(classes.bookCheckFlex, _defineProperty({}, classes.bookCheckFlexDone, item.done))
			},
			_react2.default.createElement(
				"div",
				{
					className: (0, _classnames2.default)((_classNames4 = {}, _defineProperty(_classNames4, classes.bookCheckContent, workforms.length <= 1), _defineProperty(_classNames4, classes.bridgeContent, workforms.length > 1), _classNames4), _defineProperty({}, classes.darkContent, titleColor === "SECONDARY"), _defineProperty({}, classes.lightContent, titleColor === "PRIMARY"))
				},
				_react2.default.createElement(
					_core.Typography,
					{
						component: "h5",
						variant: "h5",
						className: (0, _classnames2.default)(classes.bodytitle, classes.name)
					},
					introTitle || ""
				),
				_react2.default.createElement(
					_core.Typography,
					{
						component: "div",
						variant: "body1",
						className: classes.body
					},
					(0, _reactRenderHtml2.default)(text || "")
				),
				workforms.length > 1 && _react2.default.createElement(
					"div",
					{ className: classes.downloadLinks },
					workforms.map(function (workform) {
						return _react2.default.createElement(
							_core.Typography,
							{
								key: workform.id,
								className: (0, _classnames2.default)(classes.downloadButton, _defineProperty({}, classes.darkBookCheckButtonColor, titleColor === "SECONDARY"), _defineProperty({}, classes.lightBookCheckButtonColor, titleColor === "PRIMARY")),
								onClick: function onClick() {
									return FileSaver.saveAs(workform);
								}
							},
							_react2.default.createElement(
								"span",
								{ className: classes.iconRoot },
								_react2.default.createElement(_ArrowRightAlt2.default, null)
							),
							_react2.default.createElement(
								"span",
								null,
								workform.introduction || t("app.elements.element-card.download-file")
							)
						);
					})
				)
			),
			workforms.length <= 1 && _react2.default.createElement(
				_core.Button,
				{
					variant: "contained",
					color: "primary",
					fullWidth: true,
					className: (0, _classnames2.default)(classes.button, classes.bookCheckButton, _defineProperty({}, classes.darkBookCheckButtonColor, titleColor === "SECONDARY"), _defineProperty({}, classes.lightBookCheckButtonColor, titleColor === "PRIMARY")),
					onClick: function onClick() {
						return downloadFile(workforms[0].id);
					}
				},
				t("app.elements.element-card.download-file")
			)
		)
	);
};

exports.default = (0, _recompose.compose)(_reactApollo.withApollo)(BookDownload);