import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Media from 'react-media';

const styles = (theme) => ({
    container: {
        width: '100%',
        maxWidth: '650px',
    },
    button: {
        display: 'inline',
        width: '100%',
        height: '80px',
        backgroundColor: '#000000',
        color: '#FFFFFF',
        border: '1px solid #FDC228',
        textAlign: 'left',
    },
    buttonMobile: {
        display: 'inline',
        width: '80%',
        height: '80px',
        backgroundColor: '#000000',
        color: '#FFFFFF',
        border: '1px solid #FDC228',
        textAlign: 'left',
        marginBottom: '32px !important',
    },
    buttonMarkup: {
        height: '20px',
        color: '#FFFFFF',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '1px',
        lineHeight: '24px',
        marginLeft: '40px',
    },
    group: {
        padding: theme.spacing(2),
        border: '1px solid #E6E6E6',
    },
    checkBoxLabel: {
        width: '100%',
        maxWidth: '650px',
        height: '90px',
        userSelect: 'none',
        backgroundColor: 'transparent',
    },
    checkbox: {
        color: '#000000 !important',
        marginLeft: '20px',
    },
});

class MultipleChoice extends React.Component {
    state = {
        value: 0,
        selectedAnswers: new Map(),
    };

    handleChange = (event) => {
        const id = event.target.value;
        let element = document.getElementById(id);

        if (element.dataset.clicked === 'true') {
            element.style.backgroundColor = 'transparent';
            element.dataset.clicked = false;
            this.state.selectedAnswers.delete(id);
        } else {
            element.style.backgroundColor = '#FFECC2';
            element.dataset.clicked = true;
            this.state.selectedAnswers.set(id, true);
        }

        this.setState({
            value: event.target.value,
        });
    };

    handleSubmit = (event) => {
        let counter = 0;
        let correctAnswers = this.props.questions.filter(
            (e) => e.answer === true
        ).length;

        this.props.questions.forEach((question) => {
            const element = this.state.selectedAnswers.get('q' + question.id);

            if (element) {
                if (
                    element ===
                    this.props.questions.find((e) => e.id === question.id)
                        .answer
                ) {
                    counter++;
                } else {
                    counter--;
                }
            }
        });

        if (counter === correctAnswers) {
            return true; // Correct answers
        } else {
            return false; // Incorrect answers
        }
    };

    render() {
        const { classes, buttonText, questions } = this.props;

        return (
            <div className={classes.container}>
                <div className={classes.group}>
                    {questions.map((e) => {
                        return (
                            <FormControlLabel
                                id={'q' + e.id}
                                value={'q' + e.id}
                                control={
                                    <Checkbox className={classes.checkbox} />
                                }
                                className={classes.checkBoxLabel}
                                onChange={this.handleChange}
                                label={e.question}
                                data-clicked={false}
                            />
                        );
                    })}
                </div>

                <Media query="(max-width: 860px)">
                    {(matches) =>
                        matches ? (
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <button
                                    className={classes.buttonMobile}
                                    onClick={this.handleSubmit}
                                >
                                    <div style={{ float: 'left' }}>
                                        <p className={classes.buttonMarkup}>
                                            {buttonText.toUpperCase()}
                                        </p>
                                    </div>
                                    <div style={{ float: 'right' }}>
                                        <p style={{ marginRight: '40px' }}>
                                            <ArrowRightAlt />
                                        </p>
                                    </div>
                                </button>
                            </div>
                        ) : (
                            <button
                                className={classes.button}
                                onClick={this.handleSubmit}
                            >
                                <div style={{ float: 'left' }}>
                                    <p className={classes.buttonMarkup}>
                                        {buttonText.toUpperCase()}
                                    </p>
                                </div>
                                <div style={{ float: 'right' }}>
                                    <p style={{ marginRight: '40px' }}>
                                        <ArrowRightAlt />
                                    </p>
                                </div>
                            </button>
                        )
                    }
                </Media>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(MultipleChoice);
