"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.GQL_FETCH_RATING_REQUESTS = undefined;

var _templateObject = _taggedTemplateLiteral(["\n\tquery ratingRequests($filter: RatingRequestFilter) {\n\t\tratingRequests(pageNr: 0, pageSize: 999, filter: $filter) {\n\t\t\tid\n\t\t\tattempt\n\t\t\tfinalRating\n\t\t\tbox {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\timage {\n\t\t\t\t\t", "\n\t\t\t\t}\n\t\t\t}\n\t\t\telement {\n\t\t\t\tid\n\t\t\t}\n\t\t\ttrainer {\n\t\t\t\tid\n\t\t\t\tfullName\n\t\t\t}\n\t\t\tresult {\n\t\t\t\tid\n\t\t\t\tranking\n\t\t\t\trater {\n\t\t\t\t\tid\n\t\t\t\t\tfullName\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tquery ratingRequests($filter: RatingRequestFilter) {\n\t\tratingRequests(pageNr: 0, pageSize: 999, filter: $filter) {\n\t\t\tid\n\t\t\tattempt\n\t\t\tfinalRating\n\t\t\tbox {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\timage {\n\t\t\t\t\t", "\n\t\t\t\t}\n\t\t\t}\n\t\t\telement {\n\t\t\t\tid\n\t\t\t}\n\t\t\ttrainer {\n\t\t\t\tid\n\t\t\t\tfullName\n\t\t\t}\n\t\t\tresult {\n\t\t\t\tid\n\t\t\t\tranking\n\t\t\t\trater {\n\t\t\t\t\tid\n\t\t\t\t\tfullName\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"]);

var _graphqlTag = require("graphql-tag");

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

var _graphQlHelpers = require("@manakin/app-core/graphQlHelpers");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_RATING_REQUESTS = exports.GQL_FETCH_RATING_REQUESTS = (0, _graphqlTag2.default)(_templateObject, _graphQlHelpers.appAssetWithThumbnail);