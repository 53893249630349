import React from "react";
import { useStyles } from "../styles";
import { Typography } from "@material-ui/core";
import {
	AssignedStudentRatings,
	AssignedStudentReflections,
	AssignedStudentFinalRatings,
} from "@manakin/app-core";
import { useTranslation } from 'react-i18next';

const Content = (props) => {
	const { studentId, programName = "" } = props;
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.contentRoot}>
			<div className={classes.contentWrapper}>
				<div className={classes.contentTop}>
					<Typography
						variant="h4"
						component="h2"
						className={classes.content}
					>
						{t("common.overview")}
					</Typography>
					<Typography className={classes.content}>
						{programName}
					</Typography>
				</div>
				<div className={classes.row}>
					<AssignedStudentRatings studentId={studentId} />
				</div>
				<div className={classes.row}>
					<AssignedStudentReflections studentId={studentId} />
				</div>
				<div className={classes.row}>
					<AssignedStudentFinalRatings studentId={studentId} />
				</div>
			</div>
		</div>
	);
};

export default Content;
