import React from 'react';
import FeedbackBox from './FeedbackBox';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

class FeedbackBoxContainer extends React.PureComponent {
	state = {};

	componentDidMount() {
		if (this.props.data) {
			this.processData(this.props.data);
		}
	}

	processData = (data) => {
		this.setState(
			{ ...data, },
			() => this.showFeedbackBox(data)
		);
	}

    componentDidUpdate(prevProps) {
        const {data: previousData = {}} = prevProps;
        const {data: currentData = {}} = this.props;

		if (currentData && previousData && previousData.correct !== currentData.correct && currentData.done) {
			this.processData(currentData);
		} else if (previousData.id != currentData.id) {
			this.setState(
				{ ...currentData },
				() => this.showFeedbackBox(currentData),
			);
		}
    }

	showFeedback = (data) => {
		const { correct, feedbackCorrect, feedbackInCorrect, options = {}, done } = data;

		const { t } = this.props;

		const showButton = options.nextButton != undefined ? options.nextButton : true;
		const showTitle = options.showFeedbackTitle != undefined ? options.showFeedbackTitle : true;

		this.setState({
			title: showTitle ? (correct ? t('app.workforms.feedback-box.good') : t('app.workforms.feedback-box.wrong')) : '',
			hint: true,
			done: true,
			content: correct ? feedbackCorrect : feedbackInCorrect,
			buttonText: showButton ? t('common.resume') : '',
		});

		if (!showButton && !done && this.props.onFinished) {
			this.props.onFinished();
		}
		if (showButton && !done && correct) {
			if (this.props.onCorrect) this.props.onCorrect();
		}
	};

	showHint = (data) => {
		const { t } = this.props;

		this.setState({
			title: t('common.hint'),
			hint: true,
			content: data.hintText || '',
			buttonText: data.buttonText || t('common.resume'),
		});
	};

	handleClick = () => {
		const { onSetWorkformDone } = this.props;

		if (!this.state.done) {
			if(onSetWorkformDone) {
				onSetWorkformDone();
			}
			this.showFeedback(this.props.data);
		} else if (this.props.onFinished) {
			this.props.onFinished();
		}
	};

	showFeedbackBox = (data) => {
		const hasHint = data.hintText ? (data.hintText == '<p><br></p>' ? false : true) : false;

		if (data.correct || data.done) this.showFeedback(data);
		else if (hasHint && !this.state.hint) this.showHint(data);
		else this.showFeedback(data);
	};

	render() {
		return <FeedbackBox {...this.props} {...this.state} onClick={this.handleClick} />;
	}
}

export default compose(withTranslation())(FeedbackBoxContainer);
