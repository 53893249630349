"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _graphql = require("./graphql");

var _appCore = require("@manakin/app-core");

var _core = require("@material-ui/core");

var _reactRedux = require("react-redux");

var _selectors = require("@manakin/app-core/ProgramsDropdown/selectors");

var _reactApollo = require("react-apollo");

var _actions = require("@manakin/app-core/actions");

var _reactRouterDom = require("react-router-dom");

var _selectors2 = require("@manakin/authentication/selectors");

var _lib = require("@manakin/app-core/lib");

var _reactI18next = require("react-i18next");

var _StudentSelfEvaluationCard = require("./StudentSelfEvaluationCard");

var _StudentSelfEvaluationCard2 = _interopRequireDefault(_StudentSelfEvaluationCard);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useStyles = (0, _core.makeStyles)(function (theme) {
	return {
		heading: {
			padding: "16px  0"
		}
	};
});

var AssignedStudentReflections = function AssignedStudentReflections(props) {
	var studentId = props.studentId;

	var program = (0, _reactRedux.useSelector)(function (state) {
		return (0, _selectors.getProgram)(state);
	});
	var appUser = (0, _reactRedux.useSelector)(function (state) {
		return (0, _selectors2.getAppUser)(state);
	});
	var isPo = appUser.roles.some(function (role) {
		return role.name === _lib.poRole || role.name === _lib.managementRole;
	});
	var classes = useStyles();
	var history = (0, _reactRouterDom.useHistory)();

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var dispatch = (0, _reactRedux.useDispatch)();
	var onSetBoxId = (0, _react.useCallback)(function (id) {
		return dispatch((0, _actions.setBoxId)(id));
	}, [dispatch]);

	var _useQuery = (0, _reactApollo.useQuery)(_graphql.GQL_FETCH_SELF_EVALUATIONS, {
		variables: {
			programId: program
		}
	}),
	    data = _useQuery.data,
	    loading = _useQuery.loading;

	var _ref = data || {},
	    _ref$selfEvaluations = _ref.selfEvaluations,
	    selfEvaluations = _ref$selfEvaluations === undefined ? [] : _ref$selfEvaluations;

	//functions


	var handleClick = function handleClick(evaluation) {
		return function () {
			if (isPo || _lib.bpvRole) {
				onSetBoxId(evaluation.boxen[0].id);
				history.push("/SelfEvaluation/" + evaluation.id);
			}
		};
	};

	if (loading) return "";
	return _react2.default.createElement(
		"div",
		null,
		_react2.default.createElement(
			_appCore.StudentRatingCardLayout,
			{ className: classes.heading },
			_react2.default.createElement(
				_core.Typography,
				{ variant: "h5" },
				t('app.assigned-student.self-evaluation.title')
			)
		),
		selfEvaluations.map(function (evaluation) {
			return _react2.default.createElement(_StudentSelfEvaluationCard2.default, {
				key: evaluation.id,
				onClick: handleClick(evaluation),
				studentId: studentId,
				disabled: !isPo && !_lib.bpvRole,
				data: evaluation,
				image: evaluation.image ? evaluation.image : false });
		})
	);
};

exports.default = AssignedStudentReflections;