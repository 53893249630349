import { makeStyles, createStyles, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React from 'react';
import EventIcon from '@material-ui/icons/Event';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				width: '100%',
				height: 'auto',
				padding: '30px',
				background: theme.manakin.defaultBackgroundColor[400],
				textAlign: 'left',
			},
			content: {
				color: theme.palette.getContrastText(theme.manakin.defaultBackgroundColor[400]),
				textAlign: 'left',
				fontFamily: theme.manakin.defaultContentFont,
				lineHeight: '1.5',
				fontSize: '18px'
			},
			title: {
				color: theme.palette.getContrastText(theme.manakin.defaultBackgroundColor[400]),
				fontSize: '21px',
				textAlign: 'left',
				fontFamily: theme.manakin.defaultContentFont,
				letterSpacing: '0'
			},
			button: {
				padding: '1rem',
				fontFamily: theme.manakin.defaultContentFont,
				color: theme.manakin.defaultBackgroundColor[400],
				fontWeight: 'bold',
				paddingRight: '15px',
				paddingLeft: '15px',
			},
			buttonContainer: {
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
			},
			icon: {
				color: theme.palette.getContrastText(theme.manakin.defaultBackgroundColor[400]),
				width: '6rem',
				height: '6rem',
				display: 'none',
				[theme.breakpoints.up('sm')]: {
					display: 'block',
				},
			},
			chevron: {
				width: '30px',
				height: '30px',
				marginLeft: '5px',
				color: theme.manakin.defaultBackgroundColor[400],
			}
		}),
	{
		name: 'AppCalendarButton',
	}
);

const Calendar = (props) => {
	const { className = {}, onClick } = props;
	const { t } = useTranslation();

	const classes = useStyles();
	return (
		<div className={classNames(classes.root, className)}>
			<Typography variant="h6" className={classes.title}>
				{t('app.dashboard.alt-content.planner-button.title')}
			</Typography>
			<Typography className={classes.content}>{t('app.dashboard.alt-content.planner-button.content')}</Typography>
			<div className={classes.buttonContainer}>
				<Button onClick={onClick} color="secondary" variant="contained" className={classes.button}>
					{t('app.dashboard.alt-content.planner-button.button-title')}
					<ChevronRightIcon className={classes.chevron} />
				</Button>
				<EventIcon className={classes.icon} />
			</div>
		</div>
	);
};

export default Calendar;
