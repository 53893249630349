import React from "react";
import { VideoPlayer, WorkformLayout } from "@manakin/app-core";
import { useTranslation } from "react-i18next";

const VideoWithTitle = props => {
    const { data } = props;
    const { t } = useTranslation();

    return (
        <WorkformLayout
            question={data.workTitle || ""}
            instruction={data.introduction || t("app.workforms.video-with-title.introduction")}
            quarterQuestion={true}
            loading={false}
            mirror={true}
            renderAnswers={() => (
                <VideoPlayer
                    videoId={data.video}
                    playing={false}
                    subtitle={data.subtitle}
                />
            )}
        />
    );
};

export default VideoWithTitle;
