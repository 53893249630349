import React, { useState, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import renderHTML from "react-render-html";
import classNames from "classnames";
import { LongArrow, Tags } from "@manakin/app-core";
import { connect } from "react-redux";
import { compose } from "recompose";
import { useGetConfig } from "@manakin/hooks";
import { useTranslation } from 'react-i18next';
import FactCardPrint from './FactCardPrint';
import { InputLabel } from '@material-ui/core';

const styles = (theme) => ({
	title: {},
	root: {
		display: "flex",
		flexWrap: "wrap",
		flexDirection: "column",
		width: "100%",
		position: 'relative',
		[theme.breakpoints.up("sm")]: {
			flexDirection: "row",
		},
	},
	titlesRoot: {
		width: "100%",
		overflowY: "auto",
		padding: theme.manakin.defaultPadding,
		backgroundColor: theme.manakin.defaultBackgroundColor[500],
		[theme.breakpoints.up("sm")]: {
			width: "50%",
		},
		[theme.breakpoints.up("md")]: {
			display: "flex",
			alignItems: "flex-start",
			maxHeight: "calc(100vh - 100px)",
		},
		[theme.breakpoints.up("lg")]: {
			padding: "10rem",
		},
	},
	titles: {
		width: "100%",
	},
	longArrow: {
		position: "absolute",
		display: "none",
		right: "28px",
		top: "50%",
		transform: "translateY(-50%)",
		transition: "transform .3s",
		[theme.breakpoints.up("md")]: {
			display: "block",
		},
		"& .arrow": {
			transition: "background .3s",
			background: theme.palette.secondary[500],
		},
	},
	titleContainer: {
		padding: "16px",
		width: "100%",
		backgroundColor: "white",
		marginBottom: "10px",
		cursor: "pointer",
		position: "relative",
		"& $title": {
			margin: 0,
		},
		"&:hover": {
			"& $longArrow": {
				transform: "translate(5px, -50%)",
			},
		},
		[theme.breakpoints.up("md")]: {
			padding: "28px 100px 28px 28px",
		},
	},
	active: {
		backgroundColor: theme.palette.secondary[500],
		"& $title": {
			color: theme.palette.secondary["contrastText"],
		},
		"& $longArrow": {
			"& .arrow": {
				background: theme.palette.secondary["contrastText"],
			},
		},
	},
	content: {
		padding: "45px 0",
		opacity: 1,
        order: 2,
		transition: "opacity .2s",
		[theme.breakpoints.up("sm")]: {
			width: "50%",
		},
		[theme.breakpoints.up("md")]: {
			padding: "8rem 0",
			maxHeight: "calc(100vh - 100px)",
			alignItems: "flex-start",
			overflowY: "auto",
		},
		[theme.breakpoints.up("lg")]: {
			padding: "10rem 0",
		},
	},
	animate: {
		"& $content": {
			opacity: 0,
		},
	},
	wrapper: {
		...theme.manakin.defaultWrapper,
		[theme.breakpoints.up("lg")]: {
			paddingLeft: "10rem",
			paddingRight: "10rem",
		},
	},
	tags: {
		display: "inline-flex",
	},
	[`@media print`]: {
		noPrint:{
			display: "none",
		},
	}
});

const FactCardTitles = (props) => {
	const { classes, items = [], config = {} } = props;
	const { t } = useTranslation();

    const inputEl = useRef(null);

	//state hooks
	const [slide, setSlide] = useState(0);
	const [curItem, setCurItem] = useState(items[slide] || {});
	const [animate, setAnimate] = useState(false);

	window.scrollTo(0, 0);

	//effect hooks
	useEffect(() => {
		setCurItem(items[slide]);
	}, [slide]);

	const handleClick = (index) => {
		setAnimate(true);
		setTimeout(() => {
            if(inputEl && inputEl.current) {
                inputEl.current.scrollTo(0, 0);
            }
			setAnimate(false);
			setSlide(index);
		}, 300);
	};

	//custom hooks
	const hideInstruction = useGetConfig(
		"pages",
		"appFactCard",
		"hideInstruction",
		config
	);

	return (
		<div className={classNames(classes.root, { [classes.animate]: animate })}>
			<div className={classNames(classes.content, classes.noPrint)} ref={inputEl}>
				<div className={classes.wrapper}>
					{props.items && props.items.length > 1 && !hideInstruction && (
						<Typography variant="subtitle2" component="p" className={classNames('smallUnderline', classes.preTitle)}>
							{t('app.fact-card.select-part')}
						</Typography>
					)}
					<Typography variant="h3" component="h2" className={classes.title}>
						{(curItem && curItem.title) || ''}
					</Typography>
					<Typography variant="body1" component="div">
						{renderHTML((curItem && curItem.text) || '')}
					</Typography>
				</div>
			</div>

			<div className={classNames(classes.titlesRoot, classes.noPrint)}>
				<div className={classes.titles}>
					{items &&
						items.map((item, index) => (
							<div
								key={item.id}
								className={classNames(classes.titleContainer, {
									[classes.active]: slide === index,
								})}
								onClick={() => handleClick(index)}
							>
								<Typography variant="h6" className={classes.title}>
									<Tags tags={item.tags || []} className={classNames(classes.tags)} />
									{(item && item.title) || ''}
								</Typography>
								<LongArrow className={classes.longArrow} />
							</div>
						))}
				</div>
			</div>
			<FactCardPrint items={items} />
		</div>
	);
};

export default compose(
	withStyles(styles, { name: "AppFactCardTitles" }),
	connect(({ config }) => ({ config }))
)(FactCardTitles);
