import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent, DialogContentText, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-apollo';
import { useSnackBar } from '@manakin/hooks';
import { GQL_FETCH_REGISTRATION_GROUPS, GQL_UPDATE_APP_USER } from './graphql';
import { SelectField } from '@manakin/core';
import { withForm } from '@manakin/core';
import { compose } from 'recompose';

const useStyles = makeStyles((theme) => ({
	dialogPaper: {
		flexDirection: 'column',
		padding: theme.spacing(6),
	},
	dialogTitle: {
		width: '100%',
		margin: 0,
		paddingBottom: '12px',
		fontSize: '3rem',
		lineHeight: '3rem',
		textTransform: 'uppercase',
		[theme.breakpoints.up('md')]: {
			paddingBottom: '32px',
		},
	},
	dialogActions: {
		display: 'flex',
		flexFlow: 'row wrap',
		flexDirection: 'column',
		marginTop: theme.spacing(2),
	},
	dialogText: {},
	button: {
		minHeight: '6rem',
		padding: 0,
		backgroundColor: theme.manakin.indicationColor[500],
		color: 'white',
		marginBottom: theme.spacing(),
		'&:hover': {
			backgroundColor: theme.manakin.indicationColor[700],
		},
	},
	selectFieldWrapper: {
		marginBottom: '15px',
	},
}));

const formBundle = {
	school: {
		required: true
	},
};

const LinkSchoolDialog = (props) => {
	const { onClose, open, appUser, form } = props;
	const classes = useStyles();
	const { t } = useTranslation();
	const snackBar = useSnackBar();

	const [updateAppUser] = useMutation(GQL_UPDATE_APP_USER, {
		refetchQueries: ['currentAppUser', 'programs'],
	});

	const handleClose = (event, reason) => {
		if (reason === 'backdropClick') return;
		if (onClose) onClose();
	};

	const handleClick = () => {
		form.onValidate().then(
			() =>
				form.onSubmit().then((formData) => {
					updateAppUser({
						variables: {
							id: appUser.id,
							school: formData.school,
						},
					}).then(() => {
						onClose();
					}, () => {
						snackBar.error(t('app.dashboard.link-school-dialog.save-failed'));
					});
				}),
		);
	};

	return (
		<Dialog open={open} handleClose={handleClose} PaperProps={{ className: classes.dialogPaper }}>
			<Typography variant="h3" className={classes.dialogTitle}>
				{t('app.dashboard.link-school-dialog.title')}
			</Typography>

			<DialogContent>
				<DialogContentText className={classes.dialogText}>
					{t('app.dashboard.link-school-dialog.description')}
				</DialogContentText>

				<SelectField
					isAsync={true}
					alpha={true}
					customQuery={true}
					query={GQL_FETCH_REGISTRATION_GROUPS}
					queryName="registrationGroups"
					label={t('app.dashboard.link-school-dialog.select-label')}
					form={form}
					name="school"
					filter={{
						types: ['School'],
						visibleForRegistration: true,
					}}
				/>
			</DialogContent>

			<div className={classes.dialogActions}>
				<Button variant="contained" className={classes.button} onClick={handleClick}>
					{t('app.dashboard.link-school-dialog.submit-text')}
				</Button>
			</div>
		</Dialog>
	);
};

export default compose(withForm(formBundle))(LinkSchoolDialog);
