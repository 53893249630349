'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Drawer = require('@material-ui/core/Drawer');

var _Drawer2 = _interopRequireDefault(_Drawer);

var _styles = require('@material-ui/core/styles');

var _Close = require('@material-ui/icons/Close');

var _Close2 = _interopRequireDefault(_Close);

var _IconButton = require('@material-ui/core/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _reactRedux = require('react-redux');

var _actions = require('./actions');

var _selectors = require('./selectors');

var _recompose = require('recompose');

var _reactI18next = require('react-i18next');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var mapStateToProps = function mapStateToProps(state) {
    return {
        open: (0, _selectors.isAppMenuOpen)(state)
    };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
    return {
        onToggleDrawer: function onToggleDrawer() {
            return dispatch((0, _actions.toggleAppMenu)());
        }
    };
};

var styles = function styles(theme) {
    return {
        paperRoot: _defineProperty({
            width: '80vw',
            minWidth: '230px',
            border: '0',
            justifyContent: 'space-between'
        }, theme.breakpoints.up('md'), {
            width: '50vw'
        }),
        top: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
        },
        icon: _defineProperty({
            color: theme.manakin.mainMenuSecondaryColor[500],
            fontSize: '1.4rem',
            lineHeight: '1.4rem'
        }, theme.breakpoints.up('md'), {
            fontSize: '1.6rem',
            lineHeight: '1.6rem'
        }),
        middle: _defineProperty({}, theme.breakpoints.up('md'), {
            display: 'flex',
            justifyContent: 'center'
        }),
        iconText: _defineProperty({
            cursor: 'pointer',
            color: theme.manakin.mainMenuSecondaryColor[500],
            fontSize: '1.4rem',
            lineHeight: '1.4rem'
        }, theme.breakpoints.up('md'), {
            fontSize: '1.6rem',
            lineHeight: '1.6rem'
        }),
        link: {}
    };
};

var AppMenu = function (_React$PureComponent) {
    _inherits(AppMenu, _React$PureComponent);

    function AppMenu() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, AppMenu);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = AppMenu.__proto__ || Object.getPrototypeOf(AppMenu)).call.apply(_ref, [this].concat(args))), _this), _this.handleClose = function () {
            _this.props.onToggleDrawer();
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(AppMenu, [{
        key: 'render',
        value: function render() {
            var _props = this.props,
                children = _props.children,
                classes = _props.classes,
                renderGeneral = _props.renderGeneral,
                renderBottom = _props.renderBottom,
                open = _props.open,
                t = _props.t;


            return _react2.default.createElement(
                _Drawer2.default,
                {
                    variant: 'persistent',
                    className: classes.root,
                    classes: { paper: classes.paperRoot },
                    open: open,
                    onClose: this.handleClose,
                    SlideProps: { unmountOnExit: true }
                },
                _react2.default.createElement(
                    'div',
                    { className: classes.top },
                    _react2.default.createElement(
                        _IconButton2.default,
                        {
                            className: classes.icon,
                            onClick: this.handleClose,
                            color: 'inherit',
                            disableRipple: true
                        },
                        _react2.default.createElement(_Close2.default, null)
                    ),
                    _react2.default.createElement(
                        'span',
                        {
                            className: classes.iconText,
                            onClick: this.handleClose
                        },
                        t("common.close")
                    )
                ),
                _react2.default.createElement(
                    'div',
                    { className: classes.middle, onClick: this.handleClose },
                    renderGeneral()
                ),
                _react2.default.createElement(
                    'div',
                    { className: classes.bottom },
                    renderBottom()
                )
            );
        }
    }]);

    return AppMenu;
}(_react2.default.PureComponent);

AppMenu.defaultProps = {
    renderGeneral: function renderGeneral() {},
    renderBottom: function renderBottom() {}
};
exports.default = (0, _recompose.compose)((0, _styles.withStyles)(styles, { name: 'AppMenu' }), (0, _reactI18next.withTranslation)(), (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps))(AppMenu);