import React from 'react';
import { AppRadioGroup } from '@manakin/app-core';

const Answers = (props) => {
	const { data = {}, onChange } = props;

	return (
		<React.Fragment>
			<AppRadioGroup
				done={data.done || false}
				answers={data.answers || []}
				initialValue={data.initialValue || ''}
				onChange={onChange}
			/>
		</React.Fragment>
	);
};

export default Answers;
