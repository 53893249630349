"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.GQL_CREATE_ELEMENT_RESULT = exports.GQL_FETCH_RATING_REQUESTS = exports.GQL_UPDATE_RATING_REQUEST = exports.GQL_SEND_RATING_REQUEST = exports.GQL_FETCH_INVITATION_TRAINERS = undefined;

var _templateObject = _taggedTemplateLiteral(["\n\tquery invitationTrainers {\n\t\tinvitationTrainers {\n\t\t\tfullName\n\t\t\tid\n\t\t\troles {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tquery invitationTrainers {\n\t\tinvitationTrainers {\n\t\t\tfullName\n\t\t\tid\n\t\t\troles {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t}\n"]),
    _templateObject2 = _taggedTemplateLiteral(["\n\tmutation createRatingRequest(\n\t\t$program: Int!\n\t\t$box: Int!\n\t\t$element: Int!\n\t\t$trainer: Int!\n\t) {\n\t\tcreateRatingRequest(\n\t\t\tinput: {\n\t\t\t\tprogramId: $program\n\t\t\t\tboxId: $box\n\t\t\t\telementId: $element\n\t\t\t\ttrainerId: $trainer\n\t\t\t}\n\t\t) {\n\t\t\tratingRequest {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tmutation createRatingRequest(\n\t\t$program: Int!\n\t\t$box: Int!\n\t\t$element: Int!\n\t\t$trainer: Int!\n\t) {\n\t\tcreateRatingRequest(\n\t\t\tinput: {\n\t\t\t\tprogramId: $program\n\t\t\t\tboxId: $box\n\t\t\t\telementId: $element\n\t\t\t\ttrainerId: $trainer\n\t\t\t}\n\t\t) {\n\t\t\tratingRequest {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n"]),
    _templateObject3 = _taggedTemplateLiteral(["\n\tmutation updateRatingRequest($id: ID!, $trainerId: Int!) {\n\t\tupdateRatingRequest(\n\t\t\tinput: { ratingRequestId: $id, trainerId: $trainerId }\n\t\t) {\n\t\t\tratingRequest {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tmutation updateRatingRequest($id: ID!, $trainerId: Int!) {\n\t\tupdateRatingRequest(\n\t\t\tinput: { ratingRequestId: $id, trainerId: $trainerId }\n\t\t) {\n\t\t\tratingRequest {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n"]),
    _templateObject4 = _taggedTemplateLiteral(["\n\tquery ratingRequest(\n\t\t$boxId: ID!\n\t\t$elementId: ID!\n\t\t$programId: ID!\n\t\t$studentId: ID!\n\t) {\n\t\tratingRequest(\n\t\t\tboxId: $boxId\n\t\t\telementId: $elementId\n\t\t\tprogramId: $programId\n\t\t\tstudentId: $studentId\n\t\t) {\n\t\t\tid\n\t\t\tattempt\n\t\t\telement {\n\t\t\t\tid\n\t\t\t\ttitle\n\t\t\t}\n\t\t\ttrainer {\n\t\t\t\tid\n\t\t\t\tfullName\n\t\t\t\troles {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t\tstudent {\n\t\t\t\tid\n\t\t\t\tfullName\n\t\t\t}\n\t\t\tresult {\n\t\t\t\tid\n\t\t\t\texplanation\n\t\t\t\tgradedAt\n\t\t\t\tranking\n\t\t\t\trater {\n\t\t\t\t\tfullName\n\t\t\t\t}\n\t\t\t\tsignature {\n\t\t\t\t\turl\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tquery ratingRequest(\n\t\t$boxId: ID!\n\t\t$elementId: ID!\n\t\t$programId: ID!\n\t\t$studentId: ID!\n\t) {\n\t\tratingRequest(\n\t\t\tboxId: $boxId\n\t\t\telementId: $elementId\n\t\t\tprogramId: $programId\n\t\t\tstudentId: $studentId\n\t\t) {\n\t\t\tid\n\t\t\tattempt\n\t\t\telement {\n\t\t\t\tid\n\t\t\t\ttitle\n\t\t\t}\n\t\t\ttrainer {\n\t\t\t\tid\n\t\t\t\tfullName\n\t\t\t\troles {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t\tstudent {\n\t\t\t\tid\n\t\t\t\tfullName\n\t\t\t}\n\t\t\tresult {\n\t\t\t\tid\n\t\t\t\texplanation\n\t\t\t\tgradedAt\n\t\t\t\tranking\n\t\t\t\trater {\n\t\t\t\t\tfullName\n\t\t\t\t}\n\t\t\t\tsignature {\n\t\t\t\t\turl\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"]),
    _templateObject5 = _taggedTemplateLiteral(["\n\tmutation createElementResult(\n\t\t$user: ID!\n\t\t$program: ID!\n\t\t$box: ID!\n\t\t$element: ID!\n\t\t$ratingTeacher: String\n\t) {\n\t\tcreateElementResult(\n\t\t\tinput: {\n\t\t\t\tuser: $user\n\t\t\t\tprogram: $program\n\t\t\t\tbox: $box\n\t\t\t\telement: $element\n\t\t\t\tratingTeacher: $ratingTeacher\n\t\t\t}\n\t\t) {\n\t\t\tresult {\n\t\t\t\tfinished\n\t\t\t\tratingTeacher\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tmutation createElementResult(\n\t\t$user: ID!\n\t\t$program: ID!\n\t\t$box: ID!\n\t\t$element: ID!\n\t\t$ratingTeacher: String\n\t) {\n\t\tcreateElementResult(\n\t\t\tinput: {\n\t\t\t\tuser: $user\n\t\t\t\tprogram: $program\n\t\t\t\tbox: $box\n\t\t\t\telement: $element\n\t\t\t\tratingTeacher: $ratingTeacher\n\t\t\t}\n\t\t) {\n\t\t\tresult {\n\t\t\t\tfinished\n\t\t\t\tratingTeacher\n\t\t\t}\n\t\t}\n\t}\n"]);

var _graphqlTag = require("graphql-tag");

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_INVITATION_TRAINERS = exports.GQL_FETCH_INVITATION_TRAINERS = (0, _graphqlTag2.default)(_templateObject);

var GQL_SEND_RATING_REQUEST = exports.GQL_SEND_RATING_REQUEST = (0, _graphqlTag2.default)(_templateObject2);
var GQL_UPDATE_RATING_REQUEST = exports.GQL_UPDATE_RATING_REQUEST = (0, _graphqlTag2.default)(_templateObject3);

var GQL_FETCH_RATING_REQUESTS = exports.GQL_FETCH_RATING_REQUESTS = (0, _graphqlTag2.default)(_templateObject4);

var GQL_CREATE_ELEMENT_RESULT = exports.GQL_CREATE_ELEMENT_RESULT = (0, _graphqlTag2.default)(_templateObject5);