import React, { useState } from 'react';
import { ElementOverlay } from '@manakin/app-core';
import Heading from './Heading';
import Body from './Body';
import { withStyles } from '@material-ui/core';
import { getBoxId } from '@manakin/app-core/Boxes/selectors';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useSetting } from '@manakin/hooks';
import { useQuery } from "@apollo/react-hooks";
import { SettingsKeys } from '@manakin/core/lib/constants';
import { GQL_FETCH_GLOBAL_SETTINGS } from "../../graphql";
import { useTranslation } from 'react-i18next';
import { DEFAULT_POINTS_PER_BOX } from "../../parts/BoxInfo";

const DEFAULT_STARTING_CAPITAL = 300;
const styles = (theme) => ({
    innerRoot: {
        width: '100%',
    },
});

const FrontPage = (props) => {
    const {
        classes,
        data = {},
        boxId,
        config = {},
        controls = true,
        settings = [],
    } = props;
    const { t } = useTranslation();
    const { data: settingsData = {} } = useQuery(GQL_FETCH_GLOBAL_SETTINGS);
    const STARTING_CAPITAL = parseInt(useSetting(settingsData.settings, SettingsKeys.BETTINGGAME_START_CAPITAL) || DEFAULT_STARTING_CAPITAL);
    const POINTS_PER_FINISHED_BOX = parseInt(
		useSetting(
			settingsData.settings,
			SettingsKeys.BETTINGGAME_POINTS_PER_FINISHED_BOX
		) || DEFAULT_POINTS_PER_BOX
	);

    const hasPoints = POINTS_PER_FINISHED_BOX ? true : false

    //state hooks
    const [points, setPoints] = useState(STARTING_CAPITAL);
    const [loading, setLoading] = useState(hasPoints);

    //functions
    const handlePoints = (_points) => {
        setPoints(STARTING_CAPITAL + _points);
    };

    const handleClick = () => {
        if (props.onClick) props.onClick(points);
    };

    const handleHighscoreClick = () => {
        if (props.onHighscoreClick) props.onHighscoreClick();
    };

    const handleStoppedLoading = () => {
        setLoading(false);
    };
    let prefix = t("app.elements.betting-game.title");

    settings.forEach((i) => {
        if (i.name === SettingsKeys.BETTINGGAME_PREFIX && i.value) prefix = i.value;
    });
    return (
		<div>
			<ElementOverlay fullWidth={true} controls={controls} title={prefix}>
				<div className={classes.innerRoot}>
					{!loading && (
						<Heading
							onClick={handleClick}
							onHighscoreClick={handleHighscoreClick}
							data={data}
							hasPoints={hasPoints}
						/>
					)}
					{POINTS_PER_FINISHED_BOX && (
						<Body
							onAddPoints={handlePoints}
							stoppedLoading={handleStoppedLoading}
							boxId={boxId}
						/>
					)}
				</div>
			</ElementOverlay>
		</div>
	);
};

const mapStateToProps = (state) => ({
    config: state.config,
    boxId: getBoxId(state),
});

export default compose(connect(mapStateToProps), withStyles(styles))(FrontPage);
