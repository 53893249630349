import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { StandardCard } from '@manakin/core';
import { compose } from 'recompose';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
    root: {
        padding: '40px 40px 110px 40px',
    },
    noresult: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        opacity: 0.3,
        width: '100%',
    },
});

class CardsGrid extends React.Component {
    handleChangePage = (event, page) => {
        if (event === null) {
            return;
        }
        const { query, onQueryChange } = this.props;
        onQueryChange({ ...query, offset: page * query.limit });
        this.resetSelection();
    };

    handleChangeRowsPerPage = (event) => {
        const { query, onQueryChange } = this.props;
        onQueryChange({ ...query, offset: 0, limit: event.target.value });
        this.resetSelection();
    };

    resetSelection = () => {
        this.setState({ selection: [], selectAll: false });
    };

    getLabelDisplayedRows = ({ from, to, count }) => count > 0 ? this.props.t("common.rows-displayed", {
        from: from, to: to, count: count,
    }) : "";

    handleCardClick = (itemId) => {
        this.props.onCardClick(itemId);
    };

    render() {
        const {
            items,
            spacing = 3,
            classes,
            url,
            query,
            totalCount,
            onCardClick,
            t
        } = this.props;
        const page = Math.max(0, Math.floor(query.offset / query.limit));

        return (
            <div className={classes.root}>
                <Grid container spacing={spacing}>
                    {!items.length && (
                        <Typography
                            className={classes.noresult}
                            align="center"
                            variant="subtitle1"
                        >
                            {t("common.no-results-found")}
                        </Typography>
                    )}
                    {items.map((item) => (
                        <Grid key={item.id} item xs={12} md={6} lg={3} xl={2}>
                            <StandardCard
                                item={item}
                                id={item.id}
                                title={item.title}
                                selected={item.selected}
                                onClick={
                                    onCardClick
                                        ? this.handleCardClick.bind(this, item.id)
                                        : null
                                }
                                url={url}
                            />
                        </Grid>
                    ))}
                </Grid>
                <TablePagination
                    component="div"
                    count={totalCount}
                    rowsPerPage={query.limit}
                    page={page}
                    rowsPerPageOptions={[12, 24, 48, 96]}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    labelDisplayedRows={this.getLabelDisplayedRows}
                    labelRowsPerPage={t("common.rows-per-page")}
                />
            </div>
        );
    }
}

export default compose(
    withTranslation(),
    withStyles(styles)
)(CardsGrid);
