import React, { useState, useEffect } from 'react';
import { DefaultFrontPage, DefaultResultsPage } from '../DefaultPages';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { GQL_FETCH_ENTRYTEST } from './graphql';
import { withWorkforms, Loader } from '@manakin/app-core';
import Questions from './Questions';
import { useTranslation } from 'react-i18next';
import { useViewCounting } from "@manakin/hooks";

const options = {
    showFeedback: false,
    nextButton: false,
};

const EntryTestContainer = (props) => {
    const { data, workformsData } = props;
    const { loading, entryTest = {} } = data;
    const { loading: workformsLoading } = workformsData;
    const { t } = useTranslation();
    const { viewElement } = useViewCounting();

    //state hooks
    const [page, setPage] = useState(1);

    //effect hooks
    useEffect(() => {
        if (!loading) {
            const elementId = props.match.params.elementId;
            
            if (entryTest.workforms) {
                workformsData.loadWorkforms({
                    ...entryTest,
                    options: options,
                    elementId: elementId,
                });
                workformsData.loadElementResult(elementId);

                viewElement(elementId);
            }
            
        }
    }, [props.data.loading]);

    useEffect(() => {
        if (!workformsData.loading) {
            const finishedWorkforms = workformsData.workforms.filter(
                (item) => item.finished
            );
            if (
                finishedWorkforms.length == workformsData.workforms.length &&
                page != 3
            ) {
                setPage(3);
            }
        }
    }, [workformsData]);

    //functions
    const handleClick = () => {
        setPage(2);
    };

    const handleExit = (stepper) => {
        const { items = [] } = stepper;
        items.forEach((item, idx) => {
            workformsData.saveWorkform({
                ...item,
                workform: {
                    ...workformsData.workforms.filter(
                        (workform) => workform.id === item.id
                    )[0],
                    correct: item.correct,
                },
                elementId: props.match.params.elementId,
                ifFinished: true,
            });
        });
    };

    if (loading || workformsLoading) {
        return (
            <div>
                <Loader fullScreen={true} />
            </div>
        );
    }
    return (
        <React.Fragment>
            {page === 1 && (
                <DefaultFrontPage
                    buttonText={t("app.elements.entry-test.start-test-action")}
                    {...entryTest}
                    onClick={handleClick}
                />
            )}
            {page === 2 && <Questions {...workformsData} onExit={handleExit} />}
            {page === 3 && (
                <DefaultResultsPage
                    {...workformsData}
                    url={props.match.url}
                    title={t("app.elements.entry-test.view-test-results-action")}
                />
            )}
        </React.Fragment>
    );
};

export default compose(
    graphql(GQL_FETCH_ENTRYTEST, {
        options: (props) => ({
            variables: { id: props.match.params.elementId },
        }),
    }),
    withRouter,
    withWorkforms()
)(EntryTestContainer);
