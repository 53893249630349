import React, { useEffect, useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import {
	GQL_FETCH_ELEMENT_RESULT,
	GQL_CREATE_ELEMENT_RESULT,
} from '../../graphql';
import { Loader } from '@manakin/app-core';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { withForm, SelectField } from '@manakin/core';
import { compose } from 'recompose';
import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { showSnackbarMessage } from '@manakin/core/actions';
import { connect } from 'react-redux';
import MailIcon from '@material-ui/icons/MailOutline';
import { getExerciseRating } from '@manakin/app-core/lib';
import { useSetting } from '@manakin/hooks';
import { SettingsKeys } from '@manakin/core/lib/constants';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	body: {
		margin: 0,
	},
	selectField: {
		margin: 0,
		width: '300px',
		marginTop: '-15px',
	},
}));

const form = {
	action: {},
};

const ListItem = (props) => {
	const {
		element,
		appUser,
		box,
		program,
		form,
		processSuccess,
		processFailure,
		settings,
	} = props;
	const classes = useStyles();
	const { t } = useTranslation();
	const exercisecardEnableTeacherMessages = useSetting(
		settings.settings,
		SettingsKeys.EXERCISE_CARD_ENABLE_TEACHER_MESSAGES
	);
	const exercisecardEnableTeacherFeedback = useSetting(
		settings.settings,
		SettingsKeys.TEACHER_CAN_GIVE_FEEDBACK_ON_EXERCISE_CARD
	);
	const showMessageAction =
		element.type === 'ExerciseCard'
			? exercisecardEnableTeacherMessages !== 'false'
			: true;

	//state hooks
	const [rating, setRating] = useState('');

	//queries
	const { data = {}, loading } = useQuery(GQL_FETCH_ELEMENT_RESULT, {
		variables: {
			box: box.id,
			program: program.id,
			user: appUser.id,
			element: element.id,
		},
	});
	const { elementResult = {} } = data;

	//mutations
	const [mutate, { data: mutationData, error: mutationError }] = useMutation(
		GQL_CREATE_ELEMENT_RESULT
	);

	useEffect(() => {
		if (mutationError) {
			processFailure(t);
		} else if (mutationData) {
			processSuccess(t);
		}
	}, [mutationData, mutationError]);

	useEffect(() => {
		if (elementResult && elementResult.rating) {
			const temp = getExerciseRating(elementResult);
			setRating(temp);
		}
	}, [elementResult]);

	//funcitons
	const handleChange = (data) => {
		mutate({
			variables: {
				user: appUser.id,
				program: program.id,
				box: box.id,
				element: element.id,
				finished: true,
				rating: elementResult.rating,
				ratingTeacher: data.value || '',
			},
		});
	};

	const handleMessage = () => {
		if (props.onMessageClick) props.onMessageClick(appUser, element);
	};

	if (loading) return <Loader fullScreen />;
	return (
		<TableRow>
			<TableCell component="th" scope="row">
				<Typography
					component="p"
					variant="body1"
					className={classes.body}
				>
					{element.title}
				</Typography>
			</TableCell>
			<TableCell component="th" scope="row">
				<Typography
					component="p"
					variant="body1"
					className={classes.body}
				>
					{elementResult && elementResult.finished ? t("common.yes") : t("common.no")}
				</Typography>
			</TableCell>
			<TableCell component="th" scope="row">
				<Typography
					component="p"
					variant="body1"
					className={classes.body}
				>
					{rating}
				</Typography>
			</TableCell>
			{exercisecardEnableTeacherFeedback !== 'false' && (
				<TableCell component="th" scope="row">
					<SelectField
						form={form}
						name="action"
						placeholder={t("app.student-report.fields.action.placeholder")}
						initialValue={{
							id: elementResult && elementResult.ratingTeacher,
						}}
						classes={{
							root: classes.selectField,
						}}
						options={[
							{
								id: t("app.student-report.fields.action.options.feedback-teacher-1"),
								label: t("app.student-report.fields.action.options.feedback-teacher-1"),
							},
							{
								id: t("app.student-report.fields.action.options.feedback-teacher-2"),
								label: t("app.student-report.fields.action.options.feedback-teacher-2"),
							},
							{
								id: t("app.student-report.fields.action.options.feedback-teacher-3"),
								label: t("app.student-report.fields.action.options.feedback-teacher-3"),
							},
							{
								id: t("app.student-report.fields.action.options.feedback-teacher-4"),
								label: t("app.student-report.fields.action.options.feedback-teacher-4"),
							},
						]}
						onChange={handleChange}
					/>
				</TableCell>
			)}
			{showMessageAction && (
				<TableCell component="th" scope="row">
					<IconButton onClick={handleMessage}>
						<MailIcon />
					</IconButton>
				</TableCell>
			)}
		</TableRow>
	);
};

export default compose(
	connect(
		null,
		(dispatch) => ({
			processSuccess: (t) => {
				dispatch(
					showSnackbarMessage({
						text: t("app.student-report.save-result-success"),
						variant: 'success',
					})
				);
			},
			processFailure: (t) => {
				dispatch(
					showSnackbarMessage({
						text: t("app.student-report.save-result-failed"),
						variant: 'error',
					})
				);
			},
		})
	),
	withForm(form)
)(ListItem);
