'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCoreState = exports.APP_CORE_STATE_ROOT = undefined;

var _reselect = require('reselect');

var APP_CORE_STATE_ROOT = exports.APP_CORE_STATE_ROOT = '@manakin/app-core';

var getCoreState = exports.getCoreState = function getCoreState(state) {
  return state[APP_CORE_STATE_ROOT];
};