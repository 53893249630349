import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { GQL_FETCH_NEWS_LIST } from '../graphql';
import renderHTML from 'react-render-html';
import { AccessControl } from '@manakin/app-core';
import { useTranslation } from 'react-i18next';
import { teacherRoles, schoolManagerRole } from '@manakin/app-core/lib';

const styles = (theme) => ({
    buttonText: {},
    body: {},
    root: {
        width: '100%',
        '& $buttonText': {
            marginBottom: 0,
            color: theme.palette.getContrastText(theme.palette.secondary[500]),
        },
        '& $body': {
            margin: 0,
        },
    },
    rightArrow: {
        width: '11px',
        height: '11px',
        position: 'absolute',
        right: '2rem',
        color: theme.palette.primary[500],
        [theme.breakpoints.up('md')]: {
            right: '4rem',
        },
    },
    content: {
        padding: '2rem',
        backgroundColor: theme.manakin.defaultBackgroundColor[500],
        position: 'relative',
        '& $rightArrow': {
            bottom: '4rem',
        },
        [theme.breakpoints.up('md')]: {
            padding: '5rem 7rem 5rem 5rem',
            '& $rightArrow': {
                bottom: '5rem',
            },
        },
    },
    button: {
        backgroundColor: theme.palette.secondary[500],
        height: '5rem',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '0 2rem',
        position: 'relative',
        textDecoration: 'none',
        '& $rightArrow': {
            top: '11px',
            color: theme.palette.getContrastText(theme.palette.secondary[500]),
        },
        [theme.breakpoints.up('md')]: {
            padding: '0 5rem',
        },
    },
    buttonExport: {
        backgroundColor: theme.palette.primary[500],
        height: '5rem',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '0 2rem',
        position: 'relative',
        textDecoration: 'none',
        '& $rightArrow': {
            top: '11px',
            color: theme.palette.getContrastText(theme.palette.primary[500]),
        },
        [theme.breakpoints.up('md')]: {
            padding: '0 5rem',
        },
    },
    linkStyle: {
        textDecoration: 'none',
    },
});

const app_url = process.env.REACT_APP_APP_URL || 'http://localhost:3000';

const News = (props) => {
    const { classes, data = {} } = props;
    const { newsList = {} } = data;
    const { t } = useTranslation();
    let news = {};

    if (newsList.news && newsList.news.length) {
        news = newsList.news[0];
    }

    if (!news.id) {
        return '';
    }
    return (
        <div className={classes.root}>
            <Link to={`/news/${news.id}`} className={classes.linkStyle}>
                <div className={classes.content}>
                    <Typography variant="h6">{news.title || ''}</Typography>
                    <Typography
                        variant="body1"
                        className={classes.body}
                        component="div"
                    >
                        {renderHTML(news.introText || '')}
                    </Typography>
                    <div className={classes.rightArrow}>
                        <ArrowRight />
                    </div>
                </div>
            </Link>
            <Link to="/account/news" className={classes.button}>
                <Typography variant="body1" className={classes.buttonText}>
                    {t("app.alt-dashboard.news-action")}
                </Typography>
                <div className={classes.rightArrow}>
                    <ArrowRight />
                </div>
            </Link>
            <AccessControl role={[...teacherRoles, schoolManagerRole]}>
                <Link
                    to={{
                        pathname: `${app_url}/api/cbd/export/users/manager`,
                    }}
                    className={classes.buttonExport}
                    target="_blank"
                >
                    <Typography variant="body1" className={classes.buttonText}>
                        {t("app.alt-dashboard.export-action")}
                    </Typography>
                    <div className={classes.rightArrow}>
                        <ArrowRight />
                    </div>
                </Link>
            </AccessControl>
        </div>
    );
};

export default compose(
    withStyles(styles, { name: 'AppImpNews' }),
    graphql(GQL_FETCH_NEWS_LIST, {
        options: () => ({
            variables: {
                page: 0,
                pagesize: 1,
                filter: {
                    category: ['URGENT'],
                },
            },
        }),
    })
)(News);
