import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
    wrapper: {
        ...theme.manakin.smallWrapper,
    },
    intro: {
        textAlign: 'center',
    },
    heading: {
        fontSize: '4.8rem',
        fontWeight: 'bold',
        lineHeight: '5.2rem',
    },
    introText: {
        maxWidth: '50rem',
        margin: '0 auto',
        color: theme.manakin.primaryColor[500],
    },
    content: {
        margin: '4rem auto',
    },
});

const PageLayout = (props) => {
    const { classes, title = '', intro = '', children } = props;
    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <div className={classes.intro}>
                    <Typography variant="h3" className={classes.heading}>
                        {title}
                    </Typography>
                    {intro && (
                        <Typography
                            variant="body1"
                            className={classes.introText}
                        >
                            {intro}
                        </Typography>
                    )}
                </div>
            </div>
            <div className={classes.content}>{children}</div>
        </div>
    );
};

export default withStyles(styles)(PageLayout);
