import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Carousel } from '@manakin/app-core';
import { Step } from './Steps';
import classNames from 'classnames';

const useStyles = makeStyles(
    (theme) =>
        createStyles({
            root: {
                backgroundColor: theme.manakin.defaultWorkformColor[500],
                padding: '5rem 0',
                [theme.breakpoints.up('lg')]: {
                    padding: '10rem 0rem',
                },
            },
            primary: {
                backgroundColor: theme.manakin.primaryWorkformColor[500],
            },
            secondary: {
                backgroundColor: theme.manakin.secondaryWorkformColor[500],
            },
            noBG: {
                backgroundColor: 'white',
            },
            wrapper: {
                ...theme.manakin.extraLargeWrapper,
                [theme.breakpoints.up('md')]: {
                    padding: '0 6rem',
                },
                [theme.breakpoints.up('lg')]: {
                    padding: '0 15rem',
                },
            },
        }),
    {
        name: 'appRoadmap',
    }
);

const Roadmap = (props) => {
    const { data = {} } = props;
    const backgroundColor = data.backgroundColor || null;
    const classes = useStyles();

    //state hooks
    const [step, setStep] = useState(0);

    //functions
    const handleClick = (props) => {
        const newStep = step + 1 >= data.steps.length ? 0 : step + 1;
        setStep(newStep);
    };

    return (
        <div
            className={classNames(
                classes.root,
                { [classes.primary]: backgroundColor == 'PRIMARY' },
                { [classes.secondary]: backgroundColor == 'SECONDARY' },
                { [classes.noBG]: backgroundColor == 'NONE' }
            )}
            onClick={handleClick}
        >
            <div className={classes.wrapper}>
                <div className={classes.steps}>
                    <Carousel
                        slide={step}
                        slideIndex={step}
                        slidesToShowMD={1}
                        slidesToScrollMD={1}
                        frameOverflow="hidden"
                    >
                        {data.steps &&
                            data.steps.map((item, idx) => (
                                <Step
                                    key={idx}
                                    data={item}
                                    idx={idx}
                                    steps={data.steps}
                                    backgroundColor={backgroundColor}
                                    key={idx}
                                />
                            ))}
                    </Carousel>
                </div>
            </div>
        </div>
    );
};

export default Roadmap;
