'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.FileUpload = undefined;

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styles = require('@material-ui/core/styles');

var _reactApollo = require('react-apollo');

var _actions = require('@manakin/core/actions');

var _recompose = require('recompose');

var _reactRedux = require('react-redux');

var _pica = require('./pica');

var _pica2 = _interopRequireDefault(_pica);

var _reactI18next = require('react-i18next');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var pica = (0, _pica2.default)({ features: ['all'] });

var styles = function styles(theme) {
    return {
        root: {
            position: 'relative',
            display: 'inline-block'
        },
        input: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            opacity: 0
        }
    };
};

var FileUpload = exports.FileUpload = function (_React$PureComponent) {
    _inherits(FileUpload, _React$PureComponent);

    function FileUpload() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, FileUpload);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = FileUpload.__proto__ || Object.getPrototypeOf(FileUpload)).call.apply(_ref, [this].concat(args))), _this), _this.handleFileUpload = function (file) {
            var _this$props = _this.props,
                _this$props$fileSizeL = _this$props.fileSizeLimit,
                fileSizeLimit = _this$props$fileSizeL === undefined ? 5 : _this$props$fileSizeL,
                processFileTooBig = _this$props.processFileTooBig,
                onFileUpload = _this$props.onFileUpload,
                t = _this$props.t;


            var fileSize = file.size / 1024 / 1024;
            if (fileSize <= fileSizeLimit / 2) {
                onFileUpload(file);
            } else {
                // Try to compress the image
                try {
                    var image = new Image();
                    var canvas = document.createElement('canvas');
                    image.src = URL.createObjectURL(file);
                    image.onload = function () {
                        canvas.width = image.width;
                        canvas.height = image.height;

                        pica.resize(image, canvas).then(function (result) {
                            return pica.toBlob(result, file.type, 0.65);
                        }).then(function (result) {
                            var resultFileSize = result.size / 1024 / 1024;
                            if (resultFileSize > fileSizeLimit) processFileTooBig(t);else {
                                result.name = file.name;
                                onFileUpload(result);
                            }
                        });
                    };
                } catch (e) {
                    if (fileSize > fileSizeLimit) processFileTooBig(t);else onFileUpload(file);
                }
            }
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(FileUpload, [{
        key: 'componentDidUpdate',
        value: function componentDidUpdate(prevProps) {
            if (prevProps.enabled !== this.props.enabled) {
                if (this.refs.fileUpload) {
                    this.refs.fileUpload.click();
                }
            }
        }
    }, {
        key: 'render',
        value: function render() {
            var _this2 = this;

            var _props = this.props,
                children = _props.children,
                classes = _props.classes,
                enabled = _props.enabled,
                _props$accept = _props.accept,
                accept = _props$accept === undefined ? null : _props$accept;


            return _react2.default.createElement(
                'div',
                { className: classes.root },
                children,
                enabled && _react2.default.createElement('input', {
                    type: 'file',
                    accept: accept,
                    className: classes.input,
                    ref: 'fileUpload',
                    required: true,
                    onChange: function onChange(_ref2) {
                        var _ref2$target = _ref2.target,
                            validity = _ref2$target.validity,
                            _ref2$target$files = _slicedToArray(_ref2$target.files, 1),
                            file = _ref2$target$files[0];

                        return validity.valid && _this2.handleFileUpload(file);
                    }
                })
            );
        }
    }]);

    return FileUpload;
}(_react2.default.PureComponent);

exports.default = (0, _recompose.compose)((0, _styles.withStyles)(styles), (0, _reactI18next.withTranslation)(), (0, _reactRedux.connect)(null, function (dispatch) {
    return {
        processFileTooBig: function processFileTooBig(t) {
            return dispatch((0, _actions.showSnackbarMessage)({
                text: t("app-core.file-upload.upload-failed-file-too-big", { size: 50 }),
                variant: 'error'
            }));
        }
    };
}))(FileUpload);