import gql from 'graphql-tag';

export const GQL_FETCH_SUMMARY = gql`
    query($id: ID!) {
        summary(id: $id) {
            id
            type
            introTitle
            introText
            preTitle
            workforms {
                id
                type
            }
        }
    }
`;
