import gql from "graphql-tag";
import { asset, backgroundImageAsset } from "@manakin/app-core/graphQlHelpers";

export const GQL_FETCH_CMS_HOME_PAGE_BLOCKS_ORDER = gql`
    query homepageBlockOrder {
        setting(name: "homepageBlocks") {
            name
            value
        }
    }
`;

export const GQL_FETCH_CMS_HOME_PAGE_BLOCKS_IDS = gql`
    query getHomepageBlocks {
        homepageBlocks {
            homepageBlocks {
                id
                type
            }
        }
    }
`;

export const GQL_FETCH_CMS_HOME_PAGE_BLOCKS = gql`
    query getHomepageBlocks {
        homepageBlocks {
            homepageBlocks {
                id
                title
                type
                backgroundImage {
                    ${backgroundImageAsset}
                }
                ... on ImageWithSubtitleDto {
                    text
                    subtitle
                    zoom
                    image {
                        ${asset}
                    }
                }
                ... on ShortTextDto {
                    text
                }
                ... on TitleAndTextDto {
                    text
                    text2
                    backgroundColor
                }
                ... on TitleAndTextImageDto {
                    text
                    backgroundColor
                    imagePosition
                    image {
                        ${asset}
                    }
                }
                ... on VideoWithTitleDto {
                    posterImage {
                        ${asset}
                    }
                    video
                }
            }
        }
    }
`;

export const GQL_FETCH_CMS_HOME_PAGE_BLOCK_TITLE_AND_TEXT = gql`
    query getTitleAndTextHomepageBlock($id: ID!) {
        titleAndTextHomepageBlock(id: $id)  {
            id
            title
            text
            text2
            backgroundColor
            backgroundImage {
                ${backgroundImageAsset}
            }
        }
    }
`;

export const GQL_FETCH_CMS_HOME_PAGE_BLOCK_TITLE_AND_TEXT_IMAGE = gql`
    query getTitleAndTextImageHomepageBlock($id: ID!) {
        titleAndTextImageHomepageBlock(id: $id)  {
            id
            title
            text
            backgroundImage {
                ${backgroundImageAsset}
            }
            backgroundColor
            image {
                ${asset}
            }
            imagePosition
        }
    }
`;

export const GQL_FETCH_CMS_HOME_PAGE_BLOCK_IMAGE_WITH_SUBTITLE = gql`
    query getImageWithSubtitleHomepageBlock($id: ID!) {
        imageWithSubtitleHomepageBlock(id: $id)  {
            id
            title
            backgroundImage {
                ${backgroundImageAsset}
            }
            subtitle
            image {
                ${asset}
            }
            zoom
        }
    }
`;

export const GQL_FETCH_CMS_HOME_PAGE_BLOCK_VIDEO_WITH_TITLE = gql`
    query getVideoWithTitleHomepageBlock($id: ID!) {
        videoWithTitleHomepageBlock(id: $id)  {
            id
            title
            subtitle
            backgroundColor
            backgroundImage {
                ${backgroundImageAsset}
            }
            video
            posterImage {
                ${asset}
            }
        }
    }
`;

export const GQL_FETCH_CMS_HOME_PAGE_BLOCK_SHORT_TEXT = gql`
    query getShortTextHomepageBlock($id: ID!) {
        shortTextHomepageBlock(id: $id)  {
            id
            title
            text
            type
            backgroundImage {
                ${backgroundImageAsset}
            }
            author
            createdOn
        }
    }
`;