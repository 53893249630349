'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require('react');

var _constants = require('@manakin/core/lib/constants');

var _actions = require('@manakin/core/actions');

var useSetupScriptTags = function useSetupScriptTags() {
	var ref = (0, _react.useRef)(true);

	if (ref.current) {
		ref.current = false;

		var scriptTags = (0, _actions.getSettingValue)(_constants.SettingsKeys.SCRIPT_TAGS);

		if (scriptTags) {
			var scriptTagsWrapper = document.createElement('div');
			scriptTagsWrapper.innerHTML = scriptTags;

			document.head.appendChild(scriptTagsWrapper);
		}
	}
};

exports.default = useSetupScriptTags;