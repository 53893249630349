"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _graphql = require("./graphql");

var _reactHooks = require("@apollo/react-hooks");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var interval = void 0;
var intervalSpeed = 5 * 1000 * 60;
var HeartBeat = function HeartBeat(props) {
    var children = props.children;

    //mutations

    var _useMutation = (0, _reactHooks.useMutation)(_graphql.GQL_UPDATE_HEARTBEAT),
        _useMutation2 = _slicedToArray(_useMutation, 1),
        mutate = _useMutation2[0];

    //effect hooks


    (0, _react.useEffect)(function () {
        mutate().catch(function () {
            return null;
        });
        startTimer();

        return function () {
            clearInterval(interval);
        };
    }, []);

    var startTimer = function startTimer() {
        interval = setInterval(function () {
            mutate().catch(function () {
                return null;
            });
        }, intervalSpeed);
    };

    return _react2.default.createElement(
        _react2.default.Fragment,
        null,
        children
    );
};

exports.default = HeartBeat;