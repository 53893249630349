import gql from 'graphql-tag';
import { fileAsset, appAssetWithThumbnail } from '@manakin/app-core/graphQlHelpers';

export const GQL_FETCH_GLOBAL_SETTINGS = gql`
    query settings{
        settings {
            settings {
                name
                value
                file {
                    ${fileAsset}
                }
            }
        }
    }
`;
