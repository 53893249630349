'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _styles = require('@material-ui/core/styles');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = function styles(theme) {
    return {
        root: {
            width: '54px',
            height: '14px',
            position: 'relative'
        },
        base: {
            position: 'absolute',
            height: '2px',
            width: '100%',
            top: '50%',
            left: 0,
            transform: 'translateY(-50%)',
            backgroundColor: 'currentColor'
        },
        '.arrow': {
            background: 'green'
        },
        point: {
            position: 'absolute',
            right: 0,
            height: '6px',
            width: '2px'
        },
        top: {
            top: '50%',
            transformOrigin: 'center bottom',
            transform: 'translateY(-100%) rotate(-40deg)',
            backgroundColor: 'currentColor'
        },
        bottom: {
            bottom: '50%',
            transformOrigin: 'center top',
            transform: 'translateY(100%) rotate(40deg)',
            backgroundColor: 'currentColor'
        }
    };
};

var LongArrow = function LongArrow(props) {
    var classes = props.classes,
        classNameProp = props.className;


    var className = (0, _classnames2.default)(classes.root, classNameProp);

    return _react2.default.createElement(
        'div',
        { className: className },
        _react2.default.createElement('div', { className: (0, _classnames2.default)(classes.base, 'arrow') }),
        _react2.default.createElement('div', { className: (0, _classnames2.default)(classes.top, 'arrow', classes.point) }),
        _react2.default.createElement('div', {
            className: (0, _classnames2.default)(classes.bottom, 'arrow', classes.point)
        })
    );
};

exports.default = (0, _styles.withStyles)(styles)(LongArrow);