import React from 'react';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {},
			success: {
				backgroundColor: theme.palette.success.dark,
			},
			error: {
				backgroundColor: theme.palette.error.dark,
			},
			info: {
				backgroundColor: theme.palette.info.dark,
			},
			warning: {
				backgroundColor: theme.palette.warning.dark,
			},
			icon: {
				fontSize: 22,
				opacity: 0.8,
				marginRight: theme.spacing(2),
			},
			message: {
				display: 'flex',
				alignItems: 'center',
				fontWeight: 300,
				fontSize: '1.6rem',
			},
			badge: {
				backgroundColor: 'rgba(255,255,255,0.3)',
			},
			badgeRoot: {
				marginLeft: 3 * theme.spacing(1),
			},
		}),
	{
		name: 'CoreSnackbarMessage',
	}
);

const SnackbarMessage = props => {
	const classes = useStyles();
	const { onClose, message = {}, ...other } = props;
	const { variant, text, batchCount } = message;

	return (
		<SnackbarContent
			className={[ classes.root, classes[variant] ].join(' ')}
			message={
				<span className={classes.message}>
                    {variant === 'success' && (
						<CheckCircleIcon className={classes.icon}/>
					)}
					{variant === 'error' && (
						<ErrorIcon className={classes.icon}/>
					)}
					{variant === 'info' && (
						<InfoIcon className={classes.icon}/>
					)}
					{variant === 'warning' && (
						<WarningIcon className={classes.icon}/>
					)}
					{text}
					{batchCount > 1 && (
						<Badge
							classes={{
								badge: classes.badge,
								root: classes.badgeRoot,
							}}
							badgeContent={batchCount}/>
					)}
                    </span>
			}
			action={[
				<IconButton key="close" color="inherit" onClick={onClose}>
					<CloseIcon/>
				</IconButton>,
			]}
			{...other}
		/>
	);
};

export default SnackbarMessage;
