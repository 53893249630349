'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactHooks = require('@apollo/react-hooks');

var _graphql = require('./graphql');

var _appCore = require('@manakin/app-core');

var _reactRouterDom = require('react-router-dom');

var _selectors = require('./selectors');

var _reactRedux = require('react-redux');

var _recompose = require('recompose');

var _styles = require('@material-ui/core/styles');

var _constants = require('@manakin/core/lib/constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var mounted = false;

var useStyles = (0, _styles.makeStyles)(function (theme) {
	return (0, _styles.createStyles)({
		loader: {
			position: 'fixed',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			backgroundColor: 'white',
			zIndex: 9999999
		}
	});
}, {
	name: 'AuthenticationCmsAuthenticator'
});

var ColorService = function ColorService(props) {
	var children = props.children,
	    colors = props.colors;

	var classes = useStyles();

	var _useState = (0, _react.useState)(false),
	    _useState2 = _slicedToArray(_useState, 2),
	    loadingChangeColor = _useState2[0],
	    setLoading = _useState2[1];

	var location = (0, _reactRouterDom.useLocation)();

	var _useReducer = (0, _react.useReducer)(function (state, action) {
		setTimeout(function () {
			setLoading(false);
		}, 200);
		var _colors = _extends({}, state, action);
		if (Object.keys(_colors).length > 0 && props.onChange) {
			props.onChange(_colors);
		}

		return _extends({}, state, action);
	}, {}),
	    _useReducer2 = _slicedToArray(_useReducer, 2),
	    colorState = _useReducer2[0],
	    setColorState = _useReducer2[1];

	var _useQuery = (0, _reactHooks.useQuery)(_graphql.GQL_SETTINGS, {
		fetchPolicy: 'no-cache'
	}),
	    _useQuery$data = _useQuery.data,
	    data = _useQuery$data === undefined ? {} : _useQuery$data,
	    loading = _useQuery.loading;

	(0, _react.useEffect)(function () {
		mounted = true;
		return function () {
			return mounted = false;
		};
	}, []);

	var timer = void 0;
	(0, _react.useEffect)(function () {
		if (!loading) {
			setLoading(true);
			if (!colors || colorsIsEmpty(colors) || colors.location !== location.pathname) {
				clearTimeout(timer);
				timer = setTimeout(function () {
					if (mounted) {
						setSettings();
					}
				}, 150);
			} else {
				clearTimeout(timer);
				timer = setTimeout(function () {
					if (mounted) {
						setColorState(_extends({}, colors));
					}
				}, 150);
			}
		}
	}, [colors, location]);

	(0, _react.useEffect)(function () {
		if (!loading) {
			setSettings();
		}
	}, [data, loading]);

	var colorsIsEmpty = function colorsIsEmpty(c) {
		if (c.indicationColor || c.defaultWorkformColor || c.primaryWorkformColor || c.secondaryWorkformColor || c.themeColor) return false;

		return true;
	};

	var setSettings = function setSettings() {
		if (!loading && data.settings && data.settings.settings) {
			setLoading(true);
			var obj = {
				defaultWorkformColor: null,
				primaryWorkformColor: null,
				secondaryWorkformColor: null,
				themeColor: null
			};

			var copiedSettings = JSON.parse(JSON.stringify(data.settings.settings));

			copiedSettings.forEach(function (item) {
				switch (item.name) {
					case _constants.SettingsKeys.DEFAULT_BOX_COLOR:
					case _constants.SettingsKeys.DEFAULT_ELEMENT_COLOR:
					case _constants.SettingsKeys.PRIMARY_ELEMENT_COLOR:
					case _constants.SettingsKeys.SECONDARY_ELEMENT_COLOR:
					case _constants.SettingsKeys.PRIMARY_BUTTON_BACKGROUND_COLOR:
					case _constants.SettingsKeys.PRIMARY_BUTTON_HOVER_BACKGROUND_COLOR:
					case _constants.SettingsKeys.PRIMARY_BUTTON_BORDER_COLOR:
					case _constants.SettingsKeys.PRIMARY_BUTTON_HOVER_BORDER_COLOR:
					case _constants.SettingsKeys.SECONDARY_BUTTON_BACKGROUND_COLOR:
					case _constants.SettingsKeys.SECONDARY_BUTTON_HOVER_BACKGROUND_COLOR:
					case _constants.SettingsKeys.SECONDARY_BUTTON_BORDER_COLOR:
					case _constants.SettingsKeys.SECONDARY_BUTTON_HOVER_BORDER_COLOR:
					case _constants.SettingsKeys.PRIMARY_BACKGROUND_COLOR:
					case _constants.SettingsKeys.THEME_COLOR:
					case _constants.SettingsKeys.INDICATION_COLOR:
					case _constants.SettingsKeys.PRIMARY_FONT_COLOR:
					case _constants.SettingsKeys.BUTTON_BORDER_COLOR:
					case _constants.SettingsKeys.HOME_PAGE_BLOCK_PRIMARY_BACKGROUND_COLOR:
					case _constants.SettingsKeys.HOME_PAGE_BLOCK_SECONDARY_BACKGROUND_COLOR:
						obj[item.name] = item.value;
						break;
					case _constants.SettingsKeys.BUTTON_BORDER_RADIUS:
					case _constants.SettingsKeys.HOMEPAGE_BUTTON_RADIUS:
						obj[item.name] = item.value + 'px';
						break;
					default:
						obj[item.name] = null;
						return;
				}
			});

			if (mounted) {
				setColorState(obj);
			}
		}
	};

	if (loading) return _react2.default.createElement(_appCore.Loader, { fullScreen: true });
	return _react2.default.createElement(
		_react2.default.Fragment,
		null,
		loadingChangeColor && _react2.default.createElement(
			'div',
			{ className: classes.loader },
			_react2.default.createElement(_appCore.Loader, { fullScreen: true })
		),
		children
	);
};

exports.default = (0, _recompose.compose)((0, _reactRedux.connect)(function (state) {
	return {
		colors: (0, _selectors.getStylesheet)(state)
	};
}))(ColorService);