export const styles = (theme) => ({
    slide: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        transition: 'opacity .3s',
        opacity: 0,
    },
    active: {
        opacity: 1,
    },
    slideshowSwiper: {
        display: 'block',
        width: '100vw',
        height: '100vh',
        '& .swiper-container': {
            width: '100%',
            height: '100%',
        },
        '& .swiper-pagination': {
            top: 'auto',
            bottom: 0,
            borderTop: '1px solid ' + theme.palette.primary.main,
            background: theme.palette.primary.main,
            height: '8px',
        },
        '& .swiper-slide': {
            height: 'auto',
            maxHeight: '100vh',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
        },
    },
    imageSlide: {
        backgroundColor: 'black',
    },
    imageSlideBackground: {
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: '.35',
        zIndex: '-1',
        width: '100%',
        height: '100%',
        display: 'block',
        margin: '0 auto',
        maxWidth: 'none',
        objectFit: 'cover',
    },
    imageSlideContent: {
        width: 'auto',
        height: '100vh',
        display: 'block',
        margin: '0 auto',
        objectFit: 'contain',
    },
    textSlide: {
        backgroundColor: 'white',
    },
    textSlideTitle: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '2.5rem',
            lineHeight: 'normal',
        },
    },
    textSlideWrapper: {
        ...theme.manakin.largeWrapper,
        minHeight: '100%',
        paddingTop: '100px',
        paddingBottom: theme.spacing(8),
        [theme.breakpoints.up('md')]: {
            paddingTop: 'calc(100px + 10vh)',
        },
    },
    slideshowProgressFill: {
        background: theme.manakin.primaryColor[500],
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        transform: 'scale(0)',
        transformOrigin: 'left top',
    },
});
