import { Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "./styles";
import PersonalDataForm from "../forms/PersonalDataForm";
import {useTranslation} from 'react-i18next';

const PersonalData = (props) => {
	const { data, form } = props;
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.root}>
			<Typography variant="h2" className={classes.heading}>
				{t("authentication.invitation.personal-data.title-first-part")}
				<br />
				{t("authentication.invitation.personal-data.title-second-part")}
			</Typography>
			<PersonalDataForm data={data} form={form} />
		</div>
	);
};

export default PersonalData;
