import React from "react";
import classNames from "classnames";
import { WorkformLayout, LongArrow } from "@manakin/app-core";
import Button from "@material-ui/core/Button";
import { useStyles } from "./styles";
import { useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import renderHTML from "react-render-html";
import ShareIcon from "@material-ui/icons/Share";
import { getAppUser } from "@manakin/authentication/selectors";
import AskFeedbackBox from "./AskFeedbackBox";
import { poRole, bpvRole, managementRole } from '@manakin/app-core/lib';
import { useTranslation } from 'react-i18next';

const AskFeedback = (props) => {
	const { direction, onSubmit, data = {}, feedback = {} } = props;
	const appUser = useSelector((state) => getAppUser(state));
	const classes = useStyles();
	const { t } = useTranslation();

	const isTrainer = (appUser && appUser.roles) ? appUser.roles.some((role) => role.name === poRole || role.name === managementRole || role.name === bpvRole) : false;

	return (
		<WorkformLayout
			question={
				data.title || t("app.workforms.ask-feedback.title")
			}
			instruction={""}
			loading={false}
			direction={direction}
			compactQuestion={true}
			renderQuestion={() => (
				<div className={classes.wrapper}>
					<Typography component="div" variant="body1">
						{renderHTML(data.introduction || "")}
					</Typography>

					<Button
						variant="contained"
						color="primary"
						fullWidth
						className={classNames(classes.button)}
						onClick={onSubmit}
						disabled={isTrainer}
					>
						<ShareIcon className={classes.shareIcon} />
						{data.shareText || t("app.workforms.ask-feedback.share-text")}
						<LongArrow className={classes.longArrowRoot} />
					</Button>
				</div>
			)}
			renderAnswers={() => (
				<React.Fragment>
					{feedback && (
						<div className={classes.wrapper}>
							<Typography
								component="p"
								variant="subtitle1"
								className={classNames(
									classes.contentColor,
									classes.recentFeedbackHeader
								)}
							>
								{t("app.workforms.ask-feedback.last-feedback")}
							</Typography>

							{feedback.createdOn && (
								<AskFeedbackBox data={feedback} />
							)}
						</div>
					)}
				</React.Fragment>
			)}
		/>
	);
};

export default AskFeedback;
