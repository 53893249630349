import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import DownloadButton from './DownloadButton';
import { useSetting } from "@manakin/hooks";
import { useQuery } from "@apollo/react-hooks";
import { GQL_FETCH_SETTINGS } from "../graphql";
import { SettingsKeys } from '@manakin/core/lib/constants';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(
    createStyles((theme) => ({
        root: {
            backgroundColor: theme.manakin.defaultElementBGColor[500],
            width: '100%',
            height: 'auto',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
                width: '100%',
                height: 'auto',
            },
        },
        wrapper: {
            ...theme.manakin.extraSmallWrapper,
            paddingTop: theme.manakin.defaultPadding,
            paddingBottom: theme.manakin.defaultPadding,
            maxWidth: '740px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        content: {
            backgroundColor: theme.manakin.defaultElementBGColor[500],
            opacity: 0,
            animation: 'showBackwards .3s ease 1s forwards',
            padding: '50px 0',
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '100%',
                height: 'auto',
                order: 1,
                display: 'flex',
                alignItems: 'center',
                padding: '100px 0',
                flexDirection: 'column',
            },
        },
        actionButton: {
            margin: `${theme.spacing(4)}px auto`,
            display: 'block',
        },
        heading: {
            textAlign: 'center',
            color: theme.palette.getContrastText(
                theme.manakin.defaultElementBGColor[500]
            ),
        },
    })),
    { name: 'AppAssignmentHeader' }
);

const Header = (props) => {
    const { handleDoneButton, workforms = [], rawData = {} } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    const { data: settingsData = {} } = useQuery(GQL_FETCH_SETTINGS);
    const teacherCanGiveFeedbackOnExerciseCard = useSetting(settingsData.settings, SettingsKeys.TEACHER_CAN_GIVE_FEEDBACK_ON_EXERCISE_CARD);

    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <div className={classes.content}>
                    <Typography
                        className={classes.heading}
                        component="h1"
                        variant="h2"
                    >
                        {rawData.title || ''}
                    </Typography>
                    {rawData.enabled && teacherCanGiveFeedbackOnExerciseCard !== "false" && (
                        <Button
                            className={classes.actionButton}
                            onClick={handleDoneButton}
                            variant="contained"
                            color="primary"
                        >
                            {t("app.assignment-card.title")}
                        </Button>
                    )}
                    {workforms.map((item, idx) => (
                        <React.Fragment key={item.id || `empty${idx}`}>
                            {item.type === 'FileDownload' && (
                                <DownloadButton {...item} />
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Header;
