import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import renderHTML from 'react-render-html';
import classNames from 'classnames';
import { useReadSpeaker } from '@manakin/hooks';

const styles = (theme) => ({
	root: {
		backgroundColor: theme.manakin.defaultWorkformColor[500],
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		padding: '5rem 0',
		flexDirection: "row",
		[theme.breakpoints.up('lg')]: {
			maxHeight: '600px',
			overflow: 'hidden',
		},
	},
	primary: {
		backgroundColor: theme.manakin.primaryWorkformColor[500],
		'& $content': {
			color: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),
			'& a': {
				color: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),
			}
		},
		'& $header': {
			color: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),
			'&.smallUnderline': {
				'&:after': {
					backgroundColor: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),
				},
			},
		},
	},
	secondary: {
		backgroundColor: theme.manakin.secondaryWorkformColor[500],
		'& $content': {
			color: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
			'& a': {
				color: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
			}
		},
		'& $header': {
			color: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
			'&.smallUnderline': {
				'&:after': {
					backgroundColor: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
				},
			},
		},
	},
	noBG: {
		backgroundColor: 'white',
		'& $content': {
			color: theme.manakin.defaultContentColor[500],
			'& a': {
				color: theme.manakin.defaultContentColor[500],
			}
		},
		'& $header': {
			color: theme.manakin.defaultContentColor[500],
			'&.smallUnderline': {
				'&:after': {
					backgroundColor: theme.manakin.indicationColor[500],
				},
			},
		},
	},
	wrapper: {
		...theme.manakin.extraLargeWrapper,
		[theme.breakpoints.up('md')]: {
			padding: '0 6rem',
		},
		[theme.breakpoints.up('lg')]: {
			padding: '0 9rem',
		},
	},
	secondTextEmpty: {
		[theme.breakpoints.up('md')]: {
			columns: '2',
			columnGap: '10rem',
		},
	},
	columns: {
		[theme.breakpoints.up('md')]: {
			display: 'grid',
			gridTemplateColumns: '1fr 1fr',
			gap: '90px',
		},
	},
	header: {
		...theme.manakin.workformTitleFont,
		paddingBottom: '3rem',
		marginBottom: '3rem',
		color: theme.palette.getContrastText(theme.manakin.defaultWorkformColor[500]),
		[theme.breakpoints.down('xs')]: {
			fontSize: '3rem',
			lineHeight: '4rem',
		},
		'&.smallUnderline': {
			'&:after': {
				backgroundColor: theme.palette.getContrastText(theme.manakin.defaultWorkformColor[500]),
			},
		},
	},
	content: {
		color: theme.palette.getContrastText(theme.manakin.defaultWorkformColor[500]),
		'& a': {
			color: theme.palette.getContrastText(theme.manakin.defaultWorkformColor[500]),
		},
		'& ul, & ol': {
			listStylePosition: "inside"
		},
		fontWeight: 400,
		fontSize: '1.8rem',
		lineHeight: '3.6rem',
		fontFamily: theme.manakin.defaultContentFont,
		[theme.breakpoints.up('lg')]: {
			overflow: 'hidden',
		},
	},
});

const TitleAndText = (props) => {
	const { data, classes } = props;
	const backgroundColor = data.backgroundColor || null;
	const { readSpeaker, readSpeakerId } = useReadSpeaker('readSpeaker-titleAndText-' + data.id);

	return (
		<div
			className={classNames(
				classes.root,
				{ [classes.primary]: backgroundColor == 'PRIMARY' },
				{ [classes.secondary]: backgroundColor == 'SECONDARY' },
				{ [classes.noBG]: backgroundColor == 'NONE' }
			)}
		>
			<div className={classes.wrapper}>
				<div
					className={classNames(classes.content, {
						[classes.secondTextEmpty]: !data.text2 || data.text2 === '' || data.text2 === '<p><br></p>',
					})}
					id={readSpeakerId}
				>
					<Typography component="h2" variant="h2" className={classNames('smallUnderline', classes.header)}>
						{data.workTitle || ''}
					</Typography>
					{readSpeaker && renderHTML(readSpeaker)}
					<div
						className={classNames({
							[classes.columns]: data.text2 && data.text2 !== '' && data.text2 !== '<p><br></p>',
						})}
					>
						<div className={classes.columns1}>{renderHTML(data.text || '')}</div>
						{data.text2 && data.text2 !== '' && data.text2 !== '<p><br></p>' && (
							<div className={classes.columns2}>{renderHTML(data.text2)}</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default compose(withStyles(styles, { name: 'AppTitleAndText' }))(TitleAndText);
