import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Carousel, NewsItem } from '@manakin/app-core';
import classNames from 'classnames';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { GQL_FETCH_NEWS_LIST } from './graphql';
import { Loader } from '@manakin/app-core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const styles = (theme) => ({
    root: {
        padding: '45px 0 0',
        [theme.breakpoints.up('sm')]: {
            padding: '45px 0',
        },
        [theme.breakpoints.up('md')]: {
            padding: '86px 0 45px',
        },
    },
    newsItem: {
        padding: '0px 12px 25px',
        [theme.breakpoints.up('md')]: {
            padding: '0px 25px 25px',
        },
    },
    first: {
        padding: '0 12px 25px 0',
        [theme.breakpoints.up('md')]: {
            padding: '0px 25px 25px 0',
        },
    },
    carouselRoot: {
        ...theme.manakin.extraLargeWrapper,
        overflow: 'hidden',
    },
});

const NewsCarousel = (props) => {
    const { classes, data = {}, title } = props;
    const { loading = true, newsList = {} } = data;
    const { t } = useTranslation();
    const history = useHistory();

    const handleGoToNews = () => history.push("/account/news");

    if (loading) {
        return <Loader />;
    }

    if (newsList.count === 0) {
        return '';
    }

    return (
        <div className={classes.root}>
            <div className={classes.carouselRoot}>
                <Carousel
                    slidesToShow={1.1}
                    slidesToShowSM={2.1}
                    slidesToShowMD={3.1}
                    title={title || t("app-core.news-carousel.title")}
                    withoutControls={false}
                >
                    {newsList.news &&
                        newsList.news.map((news, idx) => (
                            <div
                                key={news.id}
                                className={classNames(classes.newsItem, {
                                    [classes.first]: idx === 0,
                                })}
                            >
                                <NewsItem {...news} />
                            </div>
                        ))}
                </Carousel>
            </div>
        </div>
    );
};

export default compose(
    withStyles(styles),
    graphql(GQL_FETCH_NEWS_LIST, {
        options: (props) => ({
            variables: {
                page: 0,
                pagesize: 10,
                filter: {
                    category: props.category ? [...props.category] : null,
                },
            },
        }),
    })
)(NewsCarousel);
