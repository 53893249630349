import React from 'react';
import { useStyles } from './styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import renderHTML from 'react-render-html';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

const Content = (props) => {
	const { data = {}, buttonDisabled, onClick, buttonLabel } = props;
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<div className={classes.wrapper}>
			<div className={classes.content}>
				<div className={classes.title}>
					<Typography
						component="h1"
						variant="h1"
						className={classNames(
							classes.titleRoot,
							{ [classes.secondaryTitleColor]: data.titleColor == 'SECONDARY' },
							{ [classes.primaryTitleColor]: data.titleColor == 'PRIMARY' }
						)}
					>
						{data.introTitle || ''}
					</Typography>
				</div>
				<div className={classes.body}>
					<Typography
						component="div"
						variant="body1"
						className={classNames(
							classes.body1,
							{ [classes.secondaryTitleColor]: data.titleColor == 'SECONDARY' },
							{ [classes.primaryTitleColor]: data.titleColor == 'PRIMARY' }
						)}
					>
						{renderHTML(data.introText || '')}
					</Typography>
				</div>
				<Button disabled={buttonDisabled} variant="contained" color="primary" className={classes.button} onClick={onClick}>
					{buttonLabel || t('app.elements.trainer.start-quiz')}
				</Button>
			</div>
		</div>
	);
};

export default Content;
