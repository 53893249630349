'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _graphql = require('../../graphql');

var _Button = require('@material-ui/core/Button');

var _Button2 = _interopRequireDefault(_Button);

var _recompose = require('recompose');

var _core = require('@manakin/core');

var _Grid = require('@material-ui/core/Grid');

var _Grid2 = _interopRequireDefault(_Grid);

var _core2 = require('@material-ui/core');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _reactHooks = require('@apollo/react-hooks');

var _reactRedux = require('react-redux');

var _reactI18next = require('react-i18next');

var _selectors = require('@manakin/authentication/selectors');

var _lib = require('@manakin/app-core/lib');

var _DemoAccountNoAccessDialog = require('../../DemoAccountNoAccessDialog/DemoAccountNoAccessDialog');

var _DemoAccountNoAccessDialog2 = _interopRequireDefault(_DemoAccountNoAccessDialog);

var _hooks = require('@manakin/hooks');

var _appCore = require('@manakin/app-core');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useStyles = (0, _core2.makeStyles)(function (theme) {
	return {
		root: {
			margin: 0,
			background: "#FFFFFF",
			padding: theme.spacing(6),
			paddingTop: theme.spacing(4),
			borderRadius: 4
		},
		inputs: {
			marginBottom: theme.spacing(2)
		},
		selectFieldRoot: {
			margin: "16px 0 -36px 0"
		}
	};
});

var formBundle = {
	email: {
		required: true,
		format: "email"
	},
	role: {
		required: true
	}
};
var fieldProps = { xs: 12, sm: 6, md: 6, lg: 6, xl: 6 };

var InviteTrainerCard = function InviteTrainerCard(props) {
	var config = (0, _reactRedux.useSelector)(function (state) {
		return state.config;
	}) || {};
	var appUser = (0, _reactRedux.useSelector)(function (state) {
		return (0, _selectors.getAppUser)(state);
	});
	var form = props.form,
	    classNameProp = props.className,
	    _props$invitations = props.invitations,
	    invitations = _props$invitations === undefined ? [] : _props$invitations;

	var classes = useStyles();
	var _config$general = config.general,
	    general = _config$general === undefined ? {} : _config$general;

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var snackBar = (0, _hooks.useSnackBar)();

	var _useState = (0, _react.useState)(false),
	    _useState2 = _slicedToArray(_useState, 2),
	    showDemoAccountNoAccessDialog = _useState2[0],
	    setShowDemoAccountNoAccessDialog = _useState2[1];

	var _useQuery = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_APP_ROLES),
	    data = _useQuery.data,
	    loading = _useQuery.loading;

	var _ref = data || {},
	    appRoles = _ref.appRoles;

	var _ref2 = appRoles || {},
	    _ref2$roles = _ref2.roles,
	    roles = _ref2$roles === undefined ? [] : _ref2$roles;

	// Mutations


	var _useMutation = (0, _reactHooks.useMutation)(_graphql.GQL_SEND_INVITE),
	    _useMutation2 = _slicedToArray(_useMutation, 2),
	    sendInvite = _useMutation2[0],
	    _useMutation2$ = _useMutation2[1],
	    sendInviteData = _useMutation2$.data,
	    sendInviteLoading = _useMutation2$.loading,
	    sendInviteError = _useMutation2$.error;

	//funcitons


	var getRoles = function getRoles(_roles) {
		var amountOfPo = invitations.filter(function (i) {
			return i.trainerRole.name === _lib.poRole;
		}).length;
		var amountOfBPVB = invitations.filter(function (i) {
			return i.trainerRole.name === _lib.bpvRole;
		}).length;
		var maxPo = general.maxPo || 3;
		var maxBPVB = general.maxBPVB || 1;

		return _roles.filter(function (role) {
			return role.name === _lib.poRole && amountOfPo < maxPo || role.name === _lib.bpvRole && amountOfBPVB < maxBPVB;
		}).map(function (r) {
			return {
				id: r.id,
				name: r.title
			};
		});
	};

	var handleSendInvite = function handleSendInvite() {
		if (appUser && appUser.roles && appUser.roles.some(function (role) {
			return role.name === _lib.demoRole;
		})) {
			setShowDemoAccountNoAccessDialog(true);
			return;
		}

		var onSubmit = form.onSubmit;

		form.onValidate().then(function () {
			return onSubmit().then(function (data) {
				var role = roles.find(function (role) {
					return role.id === data.role;
				});
				sendInvite({
					variables: _extends({}, data, {
						role: role.name
					})
				});
			});
		}, function (error) {
			// Handled by inputs
		});
	};

	(0, _react.useEffect)(function () {
		if (sendInviteData && sendInviteData.createInvitation) {
			snackBar.success(t("app.account.invite.send-success"));
			form.clear();
			props.onSuccess();
		}

		if (sendInviteError) {
			var message = null;
			if (sendInviteError.toString().includes("trainer.role.mismatch")) {
				message = t("app.account.invite.send-already-connected-failed");
			}
			snackBar.error(message || t("app.account.invite.send-failed"));
		}
	}, [sendInviteData, sendInviteError]);

	var filteredRoles = getRoles(roles);

	return _react2.default.createElement(
		'div',
		{ className: (0, _classnames2.default)(classes.root, classNameProp) },
		loading || sendInviteLoading ? _react2.default.createElement(_appCore.Loader, null) : _react2.default.createElement(
			_react2.default.Fragment,
			null,
			_react2.default.createElement(
				_Grid2.default,
				{
					container: true,
					spacing: 2,
					alignItems: 'center',
					classes: {
						container: classes.inputs
					}
				},
				_react2.default.createElement(
					_Grid2.default,
					_extends({ item: true }, fieldProps),
					_react2.default.createElement(_core.TextField, {
						name: 'email',
						label: t("common.fields.email-address.label"),
						placeholder: t("common.fields.email-address.placeholder"),
						initialValue: "",
						form: form
					})
				),
				_react2.default.createElement(
					_Grid2.default,
					_extends({ item: true }, fieldProps),
					_react2.default.createElement(
						'div',
						{ className: classes.selectFieldRoot },
						_react2.default.createElement(_core.SelectField, {
							options: filteredRoles,
							label: t('common.fields.role.label'),
							placeholder: t('common.fields.role.placeholder'),
							form: form,
							name: 'role'
						})
					)
				)
			),
			_react2.default.createElement(
				_Button2.default,
				{
					variant: 'contained',
					color: 'primary',
					fullWidth: true,
					onClick: handleSendInvite,
					disabled: false
				},
				t("app.account.invite.send")
			)
		),
		_react2.default.createElement(_DemoAccountNoAccessDialog2.default, { open: showDemoAccountNoAccessDialog, onClose: function onClose() {
				return setShowDemoAccountNoAccessDialog(false);
			} })
	);
};

exports.default = (0, _recompose.compose)((0, _core.withForm)(formBundle))(InviteTrainerCard);