import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { graphql } from "react-apollo";
import { GQL_RESET_MFA_TOKEN } from "./graphql/graphql";
import Confirm2FactorReset from "@manakin/authentication/Confirm2FactorReset";

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				backgroundColor: theme.manakin.defaultBackgroundColor? theme.manakin.defaultBackgroundColor[500]: 'none',
				[theme.breakpoints.up("md")]: {
					height: "100vh",
					minHeight: "900px",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
				},
			},
			content: {
				height: "auto",
			},
		}),
	{
		name: "AppConfim2FactorReset",
	}
);

const AppConfirm2FactorReset =
	graphql(GQL_RESET_MFA_TOKEN)(Confirm2FactorReset);

const Confirm2FactorResetView = () => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<AppConfirm2FactorReset />
		</div>
	);
};

export default Confirm2FactorResetView;
