import React from 'react';
import { Route } from 'react-router-dom';
import { PreviewContainer } from '../Lesson';
import { BookCheckPreview } from '../BookCheck';
import { TrainerPreview } from '../Trainer';
import { FactCardPreview } from '../FactCard';
import PreviewBettingGame from '../BettingGame/PreviewBettingGame';
import { TransitionRouter } from '@manakin/app-core';
import CasePreview from '../Case/CasePreview';
import { PreviewMasterclass } from '../MasterAndVideo';
import PreviewAssignmentCard from '../AssignmentCard/PreviewAssignmentCard';
import PreviewRecap from '../Recap/PreviewRecap';

class Router extends React.PureComponent {
    render() {
        return (
            <TransitionRouter>
                <Route
                    path={'/preview/Lesson/:query'}
                    component={PreviewContainer}
                />
                <Route
                    path={'/preview/BookCheck/:query'}
                    component={BookCheckPreview}
                />
                <Route
                    path={'/preview/BettingGame/:query'}
                    component={PreviewBettingGame}
                />
                <Route
                    path={'/preview/MasterClass/:query'}
                    component={PreviewMasterclass}
                />
                <Route
                    path={'/preview/Samenvatting/:query'}
                    component={PreviewRecap}
                />
                <Route
                    path={'/preview/ExerciseCard/:query'}
                    component={PreviewAssignmentCard}
                />
                <Route
                    path={'/preview/Film/:query'}
                    component={PreviewMasterclass}
                />
                <Route path={'/preview/Case/:query'} component={CasePreview} />
                <Route
                    path={'/preview/Trainer/:query'}
                    component={TrainerPreview}
                />
                <Route
                    path={'/preview/Factcard/:factCardId/:userId'}
                    component={FactCardPreview}
                />
            </TransitionRouter>
        );
    }
}

export default Router;
