import React from 'react';
import { useDispatch } from 'react-redux';
import { login } from '../actions';
import { selectGroup } from '@manakin/app-core/GroupsDropdown/actions';
import { push } from 'redux-first-history';
import { GQL_CURRENT_APP_USER } from "../graphql";
import { useQuery } from '@apollo/react-hooks';
import {useTranslation} from 'react-i18next';
import Cookies from 'js-cookie';
import { StorageKeys } from '@manakin/core/lib/constants';

const AppImpersonateUser = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useQuery(GQL_CURRENT_APP_USER, {
        onCompleted: (data) => {
            if (data && data.currentAppUser) {
                //To avoid showing data of other sessions when impersonating. Specific for the /rapportage page.
                Cookies.remove(StorageKeys.REPORT_FILTERS);
                dispatch(selectGroup(null));
                //
                
                dispatch(login("/dashboard", data.currentAppUser));
            } else {
                dispatch(push("/desktop"));
            }
        }
    });

    return (<div>{t("common.loading")}</div>);
};

export default AppImpersonateUser;
