import React from 'react';
import {
    ContentExpansionPanel,
    InfoSideBarActionField,
    InfoSideBarInfoField,
    InfoSideBarEditField,
} from '@manakin/core';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button/Button';

const styles = (theme) => ({
    root: {
        width: 350,
        backgroundColor: theme.palette.secondary['500'],
        marginLeft: 'auto',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        top: '0',
        boxSizing: 'border-box',
        right: 0,
        height: '100%',
    },
    list: {
        width: '100%',
    },
});

/**
 * Sidebar containing dropdowns that contains info, for example document info
 */
class InfoSideBar extends React.Component {
    /**
     * Items should be structured like this:
     * [
     *      {
     *          id: "publish",
     *          title: "Publiceren",
     *          isExpanded: true,
     *          items: [
     *              {
     *                  id: "visibility",
     *                  title: "Zichtbaarheid",
     *                  description: "Niet zichtbaar",
     *                  type: "EDIT"
     *              },
     *          ]
     *      },
     *      {
     *          id: "docInfo",
     *          title: "Doc info",
     *          isExpanded: true,
     *          items: [
     *              {
     *                  id: "last-edited",
     *                  title: "Laatst aangepast",
     *                  description: "21 minuten geleden aangepast",
     *                  type: "INFO"
     *              },
     *          ]
     *      }
     * ]
     * @returns {*}
     */
    render() {
        const {
            classes,
            items,
            onExpansion,
            onEditClick,
            onFloatingActionButtonClick,
        } = this.props;

        return (
            <div className={classes.root}>
                {items &&
                    items.map((item) => {
                        let listItems = [];

                        item.items.map((optionItem) => {
                            listItems.push(
                                optionItem.type === 'ACTION' ? (
                                    <InfoSideBarActionField
                                        key={optionItem.id}
                                        title={optionItem.title}
                                        onActionClick={() =>
                                            onActionClick(
                                                item.id,
                                                optionItem.id
                                            )
                                        }
                                    />
                                ) : optionItem.type === 'EDIT' ? (
                                    <InfoSideBarEditField
                                        key={optionItem.id}
                                        title={optionItem.title}
                                        description={optionItem.description}
                                        onEditClick={() =>
                                            onEditClick(item.id, optionItem.id)
                                        }
                                    />
                                ) : optionItem.type === 'INFO' ? (
                                    <InfoSideBarInfoField
                                        key={optionItem.id}
                                        title={optionItem.title}
                                        description={optionItem.description}
                                    />
                                ) : (
                                    ''
                                )
                            );
                        });

                        return (
                            <ContentExpansionPanel
                                key={item.id}
                                expanded={item.isExpanded}
                                onExpansion={() => onExpansion(item.id)}
                                title={item.title}
                                isInfoPanel
                                content={
                                    <List className={classes.list}>
                                        {listItems}
                                    </List>
                                }
                            />
                        );
                    })}
            </div>
        );
    }
}

export default withStyles(styles)(InfoSideBar);
