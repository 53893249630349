import React, { useEffect } from 'react';
import { Loader } from '@manakin/app-core';
import Cookies from 'js-cookie';
import { createStyles, makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { useMutation } from 'react-apollo';
import { GQL_LOGOUT_CMS } from '../graphql';
import { GQL_LOGOUT } from './graphql';
import { StorageKeys } from '@manakin/core/lib/constants';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				backgroundColor: theme.manakin.primaryColor[500],
				minHeight: '100vh',
				minWidth: '200px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			},
		}),
	{
		name: 'AppLogout',
	}
);

const Logout = (props) => {
	const classes = useStyles();

	const config = useSelector((state) => state.config) || {};
	const { general = {} } = config;
	const { isCMS = false } = general;

	const [logout, { client }] = useMutation(isCMS ? GQL_LOGOUT_CMS : GQL_LOGOUT, {
		update() {
			client.resetStore().then(() => {
				console.log('cache cleared');
			});
		},
	});

	const clear = () => {
		localStorage.removeItem(StorageKeys.SETTINGS);
		localStorage.removeItem(StorageKeys.REDUX_STATE);
		Cookies.remove('jwt_bespeak');
		Cookies.remove('shop');
		window.location = '/';
	};

	useEffect(() => {
		logout().then(clear).catch(clear);
	}, []);

	return (
		<div className={classes.root}>
			<Loader />
		</div>
	);
};

export default Logout;
