import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
	(theme) =>
		createStyles({
			headerRoot: {
				padding: "8rem 0 4rem",
			},
			heading: {
				marginBottom: "4.4rem",
			},
			wrapper: {
				...theme.manakin.smallWrapper,
				[theme.breakpoints.up("md")]: {
					paddingTop: "15rem",
					textAlign: "center",
				},
			},
			contentWrapper: {
				...theme.manakin.defaultWrapper,
			},
			contentTop: {
				marginBottom: "6rem",
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
			},
			content: {
				margin: 0,
			},
			row: {
				marginBottom: "50px",
			},
		}),
	{ name: "AppAssignedStudent" }
);
