import { makeStyles, createStyles } from '@material-ui/styles';

const useStyles = makeStyles(
    (theme) =>
        createStyles({
            root: {
                width: '100%',
            },
            header: {
                width: '100%',
                height: 'auto',
                backgroundColor: theme.manakin.defaultElementBGColor[500],
                padding: '15rem 0 40px 0',
            },
            headerWrapper: {
                ...theme.manakin.largeWrapper,
                display: 'flex',
                flexFlow: 'row wrap',
                margin: '0 auto',
            },
            slideShowSetup: {
                display: 'flex',
                flex: '1 1 480px',
                flexFlow: 'row wrap',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginRight: '32px',
                [theme.breakpoints.down('sm')]: {
                    margin: 0,
                    flex: '1 1 100%',
                },
            },
            slideShowActions: {
                display: 'flex',
                flexFlow: 'column nowrap',
                flexShrink: 0,
                [theme.breakpoints.down('sm')]: {
                    order: '-1',
                    margin: '0 0 32px',
                },
            },
            button: {
                backgroundColor: theme.palette.primary.main,
                padding: '18px 28px',
                '&:hover': {
                    backgroundColor: theme.palette.primary[300],
                },
            },
            checkbox: {
                background: 'white',
                borderRadius: 0,
                '&:hover': {
                    backgroundColor: 'white',
                },
            },
            formControl: {
                margin: '0 auto 0 50px',
                [theme.breakpoints.down('md')]: {
                    margin: '16px 0 0',
                    width: '100%',
                },
            },
            formControlLabel: {
                margin: '0 0 0 20px',
                lineHeight: 'normal',
                color: theme.palette.getContrastText(
                    theme.manakin.defaultElementBGColor[500]
                ),
            },
            saveButton: {
                margin: '0 0 32px',
                padding: '20px 40px',
                [theme.breakpoints.down('sm')]: {
                    minHeight: 0,
                    padding: '20px',
                },
            },
            buttonIcon: {
                marginRight: '16px',
            },
            deleteButton: {
                color: theme.palette.primary.main,
            },
            slideShowTitle: {
                width: '100%',
                margin: '0 0 16px',
                backgroundColor: theme.manakin.defaultElementBGColor[500],
                alignSelf: 'flex-start',
                margin: 0,
                color: theme.palette.getContrastText(
                    theme.manakin.defaultElementBGColor[500]
                ),
                paddingLeft: '5px',
            },
            newSlidePopper: {
                zIndex: 99,
                borderRadius: '4px',
            },
            slideSetupWrapper: {
                ...theme.manakin.largeWrapper,
                margin: '0 auto',
                padding: '5rem 0 8rem 0',
            },
            slideSetupButton: {
                margin: 'auto',
                background: 'transparent',
                '&:hover': {
                    background: theme.manakin.defaultBorderColor[500],
                },
            },
            slideSetupButtonLabel: {
                fontSize: '24px',
                [theme.breakpoints.down('sm')]: {
                    fontSize: '16px',
                },
            },
            slideSetup: {
                display: 'flex',
                flexFlow: 'column nowrap',
                border: '1px solid ' + theme.manakin.defaultBorderColor[900] + '',
                flexGrow: 1,
                flexBasis: '480px',
                marginLeft: '50px',
                minHeight: '50vh',
                alignSelf: 'flex-start',
                [theme.breakpoints.down('md')]: {
                    marginLeft: '16px',
                },
                [theme.breakpoints.down('sm')]: {
                    marginLeft: 0,
                },
                '& $inputWrap': {
                    marginBottom: 20,
                },
            },
            textSlide: {
                display: 'inherit',
                flexFlow: 'inherit',
                flex: 1,
                padding: '50px 50px 30px',
                [theme.breakpoints.down('sm')]: {
                    padding: '16px 16px 0',
                },
            },
            textSlideContent: {
                flexGrow: 1,
            },
            imageSlide: {
                width: '100%',
                height: '100%',
                flex: 1,
                objectFit: 'cover',
                position: 'relative',
            },
            newSlideMenu: {
                display: 'flex',
                flexFlow: 'row wrap',
                padding: '10px',
                '& $slidePreviewWrapper': {
                    margin: '0 0 10px',
                },
            },
            slidePreviewsWrapper: {
                maxWidth: '100%',
                flexShrink: 0,
                [theme.breakpoints.down('sm')]: {
                    flexGrow: 1,
                    width: '100%',
                    padding: 0,
                },
            },
            slidePreviewsList: {
                display: 'flex',
                flexFlow: 'row nowrap',
                marginBottom: '16px',
                maxHeight: '60vh',
                overflowY: 'scroll',
                WebkitOverflowScrolling: 'touch',
                [theme.breakpoints.up('md')]: {
                    flexFlow: 'column nowrap',
                    marginBottom: 0,
                    paddingRight: '40px',
                    '&::-webkit-scrollbar': {
                        width: '10px',
                        position: 'absolute',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.manakin.defaultBorderColor[400],
                    },
                },
            },
            slidePreviewItem: {
                margin: '0 20px 20px 0',
                [theme.breakpoints.up('md')]: {
                    margin: '0 0 20px',
                },
            },
            slidePreviewWrapper: {
                position: 'relative',
                height: '125px',
                width: '200px',
                maxWidth: '100%',
                overflow: 'hidden',
                transition: 'opacity .2s ease',
                [theme.breakpoints.down('sm')]: {
                    margin: '0 auto',
                },
                '&:hover': {
                    '&:not(.activeSlide) $slidePreview': {
                        border: '1px solid ' + theme.palette.primary[100] + '',
                    },
                    '& $deleteSlide': {
                        visibility: 'visible',
                        opacity: 1,
                        transitionDelay: '.1s',
                    },
                },
                '&.activeSlide': {
                    '& $slidePreview': {
                        borderColor: theme.palette.primary.main,
                    },
                },
                '&.blurredSlide': {
                    opacity: '.5',
                },
            },
            addSlideButton: {
                backgroundColor: 'black',
                color: 'white',
            },
            slidePreview: {
                display: 'block',
                padding: 0,
                height: '100%',
                maxWidth: '100%',
                border: '1px solid ' + theme.manakin.defaultBorderColor[800] + '',
                borderRadius: 0,
                overflow: 'hidden',
                color: theme.palette.primary[200],
                '& img': {
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                },
                '&:hover': {
                    background: 'transparent',
                },
            },
            slidePreviewLabel: {
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                flexFlow: 'column nowrap',
                height: '100%',
                zIndex: 0, // touchRipple fix
            },
            slidePreviewTextWrapper: {
                padding: '16px',
                backgroundColor: 'white',
                textAlign: 'left',
                display: 'block',
                width: '100%',
                height: '100%',
                '&::before': {
                    content: `""`,
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    height: '50%',
                    background:
                        'linear-gradient(to top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%)',
                    zIndex: 1,
                },
            },
            slidePreviewTitle: {
                fontSize: '12px',
                lineHeight: '125%',
                fontWeight: 800,
                letterSpacing: 0,
                margin: '0 0 8px',
            },
            slidePreviewText: {
                margin: 0,
                fontSize: '10px',
                lineHeight: '150%',
                letterSpacing: 0,
                opacity: '.35',
            },
            deleteSlide: {
                position: 'absolute',
                top: '1px',
                right: '1px',
                zIndex: 2,
                width: '40px',
                height: '40px',
                color: theme.palette.primary.main,
                backgroundColor: theme.manakin.primaryColor[500],
                borderRadius: 0,
                fontSize: '20px',
                padding: 0,
                visibility: 'hidden',
                opacity: 0,
                transition: 'all .2s ease',
                '&:hover': {
                    backgroundColor: theme.manakin.primaryColor[800],
                },
            },
            newSlideMenuItem: {
                width: 'auto',
                height: 'auto',
                padding: 0,
                margin: '10px',
                flexFlow: 'column',
                [theme.breakpoints.down('sm')]: {
                    maxWidth: '40vw',
                },
            },
            slidePreviewToggle: {
                padding: 0,
                height: '100%',
            },
            inputWrap: {
                width: '100%',
                padding: '30px 40px',
                backgroundImage:
                    `linear-gradient(to right, ` +
                    theme.manakin.defaultBorderColor[800] +
                    ` 50%, transparent 50%), 
      linear-gradient(to right, ` +
                    theme.manakin.defaultBorderColor[800] +
                    ` 50%, transparent 50%), 
      linear-gradient(to bottom, ` +
                    theme.manakin.defaultBorderColor[800] +
                    ` 50%, transparent 50%), 
      linear-gradient(to bottom, ` +
                    theme.manakin.defaultBorderColor[800] +
                    ` 50%, transparent 50%)`,
                backgroundPosition: 'left top, left bottom, left top, right top',
                backgroundRepeat: 'repeat-x, repeat-x, repeat-y, repeat-y',
                backgroundSize: '10px 1px, 10px 1px, 1px 10px, 1px 10px',
                [theme.breakpoints.down('sm')]: {
                    padding: '16px',
                },
            },
            slideTextRoot: {
                padding: 0,
            },
            slideTitleInput: {
                fontSize: '32px',
                fontWeight: 800,
                lineHeight: '150%',
                padding: 0,
                minHeight: 0,
                [theme.breakpoints.down('sm')]: {
                    fontSize: '22px',
                },
            },
            slideTextFieldInput: {
                lineHeight: '150%',
                fontSize: '2rem',
                padding: 0,
                overflow: 'hidden',
                minHeight: 0,
            },
            slideText: {
                padding: 20,
                height: '120px',
                border: '1px solid ' + theme.manakin.defaultBorderColor[600] + '',
                flexFlow: 'row wrap',
                justifyContent: 'flex-start',
                '&::before, &::after': {
                    content: `''`,
                    flexShrink: 0,
                    transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1)',
                },
                '&::before': {
                    width: '80%',
                    height: '10px',
                    marginBottom: '10px',
                    backgroundColor: theme.manakin.defaultBorderColor[600],
                },
                '&::after': {
                    height: '30px',
                    width: '100%',
                    borderTop:
                        '10px solid ' + theme.manakin.defaultBorderColor[600] + '',
                    borderBottom:
                        '10px solid ' + theme.manakin.defaultBorderColor[600] + '',
                },
                '&:hover': {
                    borderColor: theme.palette.primary.main,
                    backgroundColor: 'transparent',
                },
            },
            addIcon: {
                display: 'inline-block',
                verticalAlign: 'top',
                padding: '13px',
                boxSizing: 'content-box',
                color: 'white',
                backgroundColor: theme.palette.primary.main,
            },
            imageDialog: {
                '& $slidePreviewWrapper': {
                    height: '92px',
                },
            },
            imageDialogActions: {
                marginTop: '32px',
            },
            imageDialogOverflowHelper: {
                overflow: 'hidden',
            },

            exitSaveDialog: {},
            exitSaveDialogActions: {
                display: "flex",
                flexDirection: "row",
                marginTop: '32px',
                justifyContent: "space-evenly"
            },
        }),
    {
        name: 'SlideshowSetup',
    }
);

export default useStyles;

