import React, { useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				backgroundColor: theme.manakin.secondaryBackgroundColor[100],
				width: '100%',
				padding: '12rem 0 8rem',
				[theme.breakpoints.up('md')]: {
					padding: '18rem 0 8rem',
				},
			},
			heading: {
				textAlign: 'center',
			},
			content: {
				textAlign: 'center',
				'& $heading': {
					marginBottom: '3.4rem',
					color: theme.palette.getContrastText(theme.manakin.secondaryBackgroundColor[100]),
				},
			},
			wrapper: {
				...theme.manakin.defaultWrapper,
			},
			appBar: {},
			appBarBackground: {},
			button: {
				color: theme.palette.getContrastText(theme.manakin.secondaryBackgroundColor[100]),
				borderColor: theme.palette.getContrastText(theme.manakin.secondaryBackgroundColor[100]),
			},
		}),
	{
		name: 'StudentRapportHeader',
	}
);

const Header = (props) => {
	const { appUser = {}, params, config = {}, setAppBarColor } = props;
	const { general = {} } = config;
	const classes = useStyles();
	const location = useLocation();
	const { t } = useTranslation();

	useEffect(() => {
		setAppBarColor({
			color: 'primary',
			path: location.pathname,
			className: classes.appBar,
			backgroundClassName: classes.appBarBackground,
		});
	}, []);

	return (
		<div className={classes.root}>
			<div className={classes.wrapper}>
				<div className={classes.content}>
					<Typography component="h1" variant="h2" className={classes.heading}>
						{appUser.fullName || ''}
					</Typography>
					{general.downloadRapportage && (
						<Button
							variant="outlined"
							color="primary"
							href={`/api/pdf/exercise-card/report/${params.productId}/${appUser.id}/voortgangsrapport.pdf`}
							target="_blank"
							className={classes.button}
						>
							{t('app.student-report.download-report')}
						</Button>
					)}
				</div>
			</div>
		</div>
	);
};

export default Header;
