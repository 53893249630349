"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _core = require("@material-ui/core");

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _NotificationsActiveOutlined = require("@material-ui/icons/NotificationsActiveOutlined");

var _NotificationsActiveOutlined2 = _interopRequireDefault(_NotificationsActiveOutlined);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _reactRouterDom = require("react-router-dom");

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var useStyles = (0, _core.makeStyles)(function (theme) {
	return {
		root: _defineProperty({
			width: "100%",
			minHeight: "400px",
			backgroundColor: theme.manakin.primaryElementBGColor[500],
			borderRadius: "6px",
			display: "flex",
			justifyContent: "flex-start",
			alignItems: "center",
			flexDirection: "column",
			padding: "60px 25px 10px",
			cursor: "pointer",
			transform: "scale(1)",
			transition: "transform .2s"
		}, theme.breakpoints.up("md"), {
			padding: "60px 50px 10px",
			"&:hover": {
				transform: "scale(1.01)"
			}
		}),
		iconContainer: {
			width: "100px",
			height: "100px",
			backgroundColor: theme.manakin.primaryElementBGColor[300],
			borderRadius: "100%",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			marginBottom: "3rem"
		},
		icon: {
			fontSize: "40px",
			color: theme.palette.getContrastText(theme.manakin.primaryElementBGColor[300]),
			width: "5rem",
			height: "5rem"
		},
		content: {
			alignSelf: "flex-start",
			color: theme.palette.getContrastText(theme.manakin.primaryElementBGColor[500])
		},
		body: {
			lineHeight: "36px"
		},
		link: {
			textDecoration: "none"
		}
	};
});

var NotificationCTA = function NotificationCTA(props) {
	var classes = useStyles();

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	return _react2.default.createElement(
		_reactRouterDom.Link,
		{ to: "/account/notifications", className: classes.link },
		_react2.default.createElement(
			"div",
			{ className: classes.root },
			_react2.default.createElement(
				"div",
				{ className: classes.iconContainer },
				_react2.default.createElement(_NotificationsActiveOutlined2.default, { className: classes.icon })
			),
			_react2.default.createElement(
				_core.Typography,
				{ variant: "h6", className: classes.content },
				t("app-core.notification-call-to-action.title")
			),
			_react2.default.createElement(
				_core.Typography,
				{ className: (0, _classnames2.default)(classes.content) },
				t("app-core.notification-call-to-action.description")
			)
		)
	);
};

exports.default = NotificationCTA;