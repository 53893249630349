import React, { useEffect, useCallback } from 'react';
import LessonView from './LessonView';
import { GQL_FETCH_LESSON, GQL_FETCH_CMS_BOX } from './graphql';
import { ElementOverlay, withWorkforms } from '@manakin/app-core';
import { getBoxId } from '@manakin/app-core/Boxes/selectors';
import { changeColor } from '@manakin/app-core/ColorService/actions';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SettingsKeys } from '@manakin/core/lib/constants';
import { useViewCounting } from "@manakin/hooks";

let mounted = false;
const LessonContainer = (props) => {
	const { workformsData, location } = props;
	const params = useParams();
	const { t } = useTranslation();
	const { viewElement } = useViewCounting();

	//useSelector
	const boxId = useSelector((state) => getBoxId(state));
	const config = useSelector((state) => state.config) || {};

	//dispatch functions
	const dispatch = useDispatch();
	const handleChangeColor = useCallback((styleSheet) => dispatch(changeColor(styleSheet)), [dispatch]);

	//queries
	const { data = {}, loading } = useQuery(GQL_FETCH_LESSON, {
		variables: { id: params.elementId },
	});
	const { data: CMSBOX = {} } = useQuery(GQL_FETCH_CMS_BOX, {
		variables: { id: boxId },
	});

	//effect hooks
	useEffect(() => {
		mounted = true;
		if (!workformsData.loading) {
			if (location.state && location.state.idx) {
				setTimeout(() => {
					if (mounted) {
						window.scrollTo(0, location.state.idx);
					}
				}, 1000);
			} else {
				setTimeout(() => {
					if (mounted) {
						window.scrollTo(0, 0);
					}
				}, 0);
			}
		}
		return () => (mounted = false);
	}, [workformsData.loading]);

	useEffect(() => {
		if (!loading) {
			const options = {
				showFeedback: true,
				nextButton: false,
			};

			if (data.lesson) {
				handleChangeColor({
					...data.lesson.styleSheet,
					location: location.pathname,
				});

				data.lesson.workforms &&
					workformsData.loadWorkforms({
						...data.lesson,
						options: options,
						elementId: params.elementId,
					});

				viewElement(params.elementId);
			}
		}
	}, [loading]);

	const handleFinish = (data) => {
		workformsData.saveWorkform({
			...data,
			elementId: params.elementId,
			config: config,
			element: 'Lesson',
		});
	};

	const handleReset = (id) => {
		workformsData.resetElement(id);
	};

	const { box = {} } = CMSBOX;
	const { lesson = {} } = data;
	const { generalSettings = {} } = workformsData;

	const showTitle =
		config.pages && config.pages.lesson
			? config.pages.lesson.showNavTitle == undefined
				? true
				: config.pages.lesson.showNavTitle
			: true;
	const title = showTitle ? lesson.preTitle || generalSettings[SettingsKeys.LESSON_PREFIX] || t('common.element-types.lesson') : '';

	return (
		<ElementOverlay fullWidth={true} title={title}>
			<LessonView
				{...workformsData}
				lessonData={lesson}
				onFinish={handleFinish}
				box={box}
				onReset={handleReset}
				preview={false}
				title={title}
			/>
		</ElementOverlay>
	);
};

export default withWorkforms()(LessonContainer);
