import React from "react";
import renderHTML from "react-render-html";
import Typography from "@material-ui/core/Typography";
import { isContain } from '@manakin/app-workforms';
import { createStyles, makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(
    (theme) =>
        createStyles({
            root: {
                [theme.breakpoints.up("md")]: {
                    marginBottom: "-5rem",
                    display: "flex",
                    flexWrap: "wrap",
                },
            },
            card: {
                minHeight: "30rem",
                padding: "2rem",
                display: "flex",
                marginBottom: "1.5rem",
                backgroundSize: "cover",
                "&:last-child": {
                    marginBottom: 0,
                    [theme.breakpoints.up("md")]: {
                        marginBottom: "5rem",
                    },
                },
                [theme.breakpoints.up("md")]: {
                    width: "calc(50% - 2.5rem)",
                    marginBottom: "5rem",
                    marginRight: "2.5rem",
                    padding: "5rem",
                },
                "&:nth-child(even)": {
                    [theme.breakpoints.up("md")]: {
                        marginRight: 0,
                        marginLeft: "2.5rem",
                    },
                },
            },
            content: {
                backgroundColor: "white",
                padding: "2rem",
                width: "100%",
                opacity: 0,
                transition: "opacity .3s",
                [theme.breakpoints.up("md")]: {
                    padding: "3.5rem 5rem 4rem",
                },
                "&:hover": {
                    opacity: 1,
                },
            },
            title: {
                fontFamily: theme.manakin.defaultContentFont,
                fontSize: "3rem",
                lineHeight: "4.4rem",
                fontWeight: "800",
                letterSpacing: 0,
            },
            text: {
                fontFamily: theme.manakin.secondaryTitleFont,
                fontSize: "1.8rem",
                lineHeight: "3rem",
                [theme.breakpoints.up("md")]: {
                    fontSize: "2.2rem",
                    lineHeight: "4rem",
                },
                "& li": {
                    margin: "0 0 0 3rem",
                },
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                "-webkit-line-clamp": 7,
                "-webkit-box-orient": "vertical",
            },
        }),
    {
        name: "AppMoodboard",
    },
);

const Moodboard = props => {
    const { data } = props;
    const classes = useStyles();

    const getBackgroundSize = (item) => {
        return isContain(item.image && item.image.fit, false) ? 'contain' : 'cover';
    };

    return (
        <div className={classes.root}>
            {data.boards &&
            data.boards.map((item) => (
                <div
                    className={classes.card}
                    style={{
                        backgroundImage: `url('${item.image ? item.image.url : ""}')`,
                        backgroundPosition: item.image && item.image.focusPointX >= 0 ? `${item.image.focusPointX}% ${item.image.focusPointY}%` : "",
                        backgroundSize: getBackgroundSize(item)
                    }}
                    key={item.id}
                >
                    <div className={classes.content}>
                        {item.title && (
                            <Typography
                                component='h4'
                                variant='h6'
                                classes={{ h6: classes.title }}
                                className='smallUnderline'
                            >
                                {item.title}
                            </Typography>
                        )}
                        <div className={classes.text}>
                            {renderHTML(item.text || "")}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Moodboard;
