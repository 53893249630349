"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.GQL_FETCH_TRAINERS = exports.GQL_FETCH_RATING_REQUESTS = exports.GQL_REQUEST_APPROVAL = undefined;

var _templateObject = _taggedTemplateLiteral(["\n\tmutation supervisorApprovalRequest($input: SupervisorRatingRequestInput) {\n\t\tsupervisorApprovalRequest(input: $input) {\n\t\t\tratingRequest {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tmutation supervisorApprovalRequest($input: SupervisorRatingRequestInput) {\n\t\tsupervisorApprovalRequest(input: $input) {\n\t\t\tratingRequest {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n"]),
    _templateObject2 = _taggedTemplateLiteral(["\n\tquery ratingRequests(\n\t\t$filter: RatingRequestFilter\n\t\t$page: Int!\n\t\t$pagesize: Int!\n\t) {\n\t\tratingRequests(filter: $filter, pageNr: $page, pageSize: $pagesize) {\n\t\t\tid\n\t\t\tfinalRating\n\t\t\tprogram {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t\telement {\n\t\t\t\tid\n\t\t\t\ttitle\n\t\t\t}\n\t\t\tresult {\n\t\t\t\trater {\n\t\t\t\t\tfullName\n\t\t\t\t\temail\n\t\t\t\t\troles {\n\t\t\t\t\t\tid\n\t\t\t\t\t\tname\n\t\t\t\t\t\ttitle\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t\tranking\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tquery ratingRequests(\n\t\t$filter: RatingRequestFilter\n\t\t$page: Int!\n\t\t$pagesize: Int!\n\t) {\n\t\tratingRequests(filter: $filter, pageNr: $page, pageSize: $pagesize) {\n\t\t\tid\n\t\t\tfinalRating\n\t\t\tprogram {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t\telement {\n\t\t\t\tid\n\t\t\t\ttitle\n\t\t\t}\n\t\t\tresult {\n\t\t\t\trater {\n\t\t\t\t\tfullName\n\t\t\t\t\temail\n\t\t\t\t\troles {\n\t\t\t\t\t\tid\n\t\t\t\t\t\tname\n\t\t\t\t\t\ttitle\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t\tranking\n\t\t\t}\n\t\t}\n\t}\n"]),
    _templateObject3 = _taggedTemplateLiteral(["\n\tquery invitationTrainers {\n\t\tinvitationTrainers {\n\t\t\tid\n\t\t\tfullName\n\t\t\temail\n\t\t\troles {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\ttitle\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tquery invitationTrainers {\n\t\tinvitationTrainers {\n\t\t\tid\n\t\t\tfullName\n\t\t\temail\n\t\t\troles {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\ttitle\n\t\t\t}\n\t\t}\n\t}\n"]);

var _graphqlTag = require("graphql-tag");

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_REQUEST_APPROVAL = exports.GQL_REQUEST_APPROVAL = (0, _graphqlTag2.default)(_templateObject);

var GQL_FETCH_RATING_REQUESTS = exports.GQL_FETCH_RATING_REQUESTS = (0, _graphqlTag2.default)(_templateObject2);

var GQL_FETCH_TRAINERS = exports.GQL_FETCH_TRAINERS = (0, _graphqlTag2.default)(_templateObject3);