'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styles = require('./styles');

var _reactI18next = require('react-i18next');

var _AssignmentTurnedInOutlined = require('@material-ui/icons/AssignmentTurnedInOutlined');

var _AssignmentTurnedInOutlined2 = _interopRequireDefault(_AssignmentTurnedInOutlined);

var _AssignmentReturnedOutlined = require('@material-ui/icons/AssignmentReturnedOutlined');

var _AssignmentReturnedOutlined2 = _interopRequireDefault(_AssignmentReturnedOutlined);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RatingDates = function RatingDates(props) {
	var data = props.data;

	var _ref = data || {},
	    result = _ref.result;

	var _ref2 = result || {},
	    gradedAt = _ref2.gradedAt;

	var classes = (0, _styles.useStyles)();

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var formatDate = function formatDate(date) {
		return (0, _moment2.default)(date).locale(t("common.locale-code")).format('DD MMM ‘YY');
	};

	return _react2.default.createElement(
		'div',
		{ className: classes.dates },
		_react2.default.createElement(
			'span',
			{ className: classes.date },
			_react2.default.createElement(_AssignmentReturnedOutlined2.default, null),
			data.requestedAt ? formatDate(data.requestedAt) : '...'
		),
		_react2.default.createElement(
			'span',
			{ className: classes.date },
			_react2.default.createElement(_AssignmentTurnedInOutlined2.default, null),
			gradedAt ? formatDate(gradedAt) : '...'
		)
	);
};

exports.default = RatingDates;