export const SET_BREADCRUMB = '@manakin/core/SET_BREADCRUMB';

export function setBreadcrumb(path, data) {
    return {
        type: SET_BREADCRUMB,
        payload: {
            data,
            path,
        },
    };
}
