'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _styles = require('@material-ui/styles');

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var useStyles = (0, _styles.makeStyles)(function (theme) {
    var _milestone;

    return (0, _styles.createStyles)({
        wrapper: _extends({}, theme.manakin.largeWrapper, {
            transition: 'max-width .3s'
        }),
        smallWrapper: _extends({}, theme.manakin.defaultWrapper, {
            backgroundColor: theme.manakin.primaryColor[500],
            paddingBottom: '20rem'
        }),
        loading: {
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        milestone: (_milestone = {
            width: '100%',
            fontFamily: theme.manakin.defaultTitleFont,
            zIndex: 1,
            fontSize: '12px',
            letterSpacing: '.4rem',
            lineHeight: '2rem',
            textAlign: 'center',
            textTransform: 'uppercase',
            position: 'relative',
            marginBottom: '4.6rem'
        }, _defineProperty(_milestone, 'position', 'relative'), _defineProperty(_milestone, '&:after', {
            content: "''",
            position: 'absolute',
            height: '1px',
            width: '100%',
            top: '50%',
            transform: 'translateY(-50%)',
            left: 0,
            backgroundColor: 'black'
        }), _milestone),
        innerMileStone: {
            padding: '10px 40px',
            background: 'white',
            position: 'relative',
            zIndex: 1
        }
    });
}, {
    name: 'AppBoxes'
});

exports.default = useStyles;