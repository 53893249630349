import gql from 'graphql-tag';
import { appAssetWithThumbnail } from '@manakin/app-core/graphQlHelpers';

export const GQL_FETCH_PROGRAM = gql`
    query($id: ID!) {
        program(id: $id) {
            id
            boxes {
                id
                name
                image {
                    ${appAssetWithThumbnail}
                }
                elements {
                    id
                    title
                    type
                }
            }
        }
    }
`;

export const GQL_FETCH_BOXES = gql`
    query {
        boxes {
            boxes {
                id
                name
                image {
                    ${appAssetWithThumbnail}
                }
                elements {
                    id
                    title
                    type
                }
            }
            count
        }
    }
`;

export const GQL_FETCH_ELEMENT_RESULTS = gql`
    query($box: ID!, $program: ID!, $user: ID!) {
        elementResults(box: $box, program: $program, user: $user) {
            finished
            element {
                id
                type
                title
            }
            correct
        }
    }
`;
