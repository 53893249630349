export const styles = (theme) => ({
    wrapper: {
        ...theme.manakin.defaultWrapper,
        maxWidth: '950px',
    },
    icon: {
        width: '80px',
        height: '50px',
        background: `url(${theme.manakin.pdfIcon})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        marginRight: '4rem',
    },
    content: {
        border: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
        padding: '1rem',
        display: 'flex',
        alignItems: 'center',
        transition: 'border-color .3s',
        '&:hover': {
            borderColor: theme.manakin.defaultContentHover[500],
        },
        [theme.breakpoints.up('md')]: {
            padding: '4rem',
        },
    },
    text: {
        width: '100%',
        '& p': {
            margin: 0,
            maxWidth: '43rem',
        },
    },
    button: {
        marginTop: '3rem',
    },
    title: {
        fontSize: '1.8rem',
        lineHeight: '2.2rem',
        marginBottom: '.8rem',
    },
    body: {
        fontSize: '1.6rem',
        lineHeight: '2.2rem',
        opacity: '.5',
        margin: 0,
    },
    link: {
        margin: '0 0 0 4rem',
        color: theme.manakin.linkColor[500],
        textDecoration: 'none',
    },
});
