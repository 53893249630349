import { makeStyles, createStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
	(theme) =>
		createStyles({
			topRoot: {
				[theme.breakpoints.up('md')]: {
					width: '100%',
					display: 'flex',
				},
			},
			closeButton: {
				marginRight: '10px',
			},
			left: {
				[theme.breakpoints.up('md')]: {
					width: 'calc(100% - 280px)',
				},
				[theme.breakpoints.up('lg')]: {
					width: 'calc(100% - 350px)',
				},
			},
			chatBubble: {
				width: '100%',
				maxWidth: '860px',
			},
			chatBubbles: {
				padding: '7rem 0 4rem',
				[theme.breakpoints.up('md')]: {
					padding: '10rem 0',
				},
				[theme.breakpoints.up('lg')]: {
					padding: '11.6rem 0px',
				},
			},
			question: {
				paddingTop: '2rem',
				paddingBottom: '2rem',
				borderTop: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
				[theme.breakpoints.up('md')]: {
					...theme.manakin.defaultWrapper,
					padding: '5rem',
				},
				[theme.breakpoints.up('lg')]: {
					borderColor: 'transparent',
					padding: '13rem 5rem',
				},
			},
			content: {
				width: '100%',
				height: 'auto',
				[theme.breakpoints.up('md')]: {
					display: 'flex',
					justifyContent: 'center',
				},
			},
			sidebar: {
				padding: '4rem',
				display: 'block',
				width: '100%',
				backgroundColor: theme.manakin.primaryElementBGColor[500],
				[theme.breakpoints.up('md')]: {
					width: '280px',
				},
				[theme.breakpoints.up('lg')]: {
					width: '400px',
				},
			},
			body: {
				margin: 0,
				'@global ul': {
					paddingLeft: '2.25rem',
				},
				color: theme.palette.getContrastText(theme.manakin.primaryElementBGColor[500]),
			},
			hide: {
				display: 'none',
			},
			title: {
				fontFamily: theme.manakin.defaultTitleFont,
				fontSize: '1.4rem',
				letterSpacing: '.4rem',
				lineHeight: '3.2rem',
				color: theme.palette.getContrastText(theme.manakin.primaryElementBGColor[500]),
			},
			list: {
				paddingLeft: '2rem',
				color: theme.palette.getContrastText(theme.manakin.primaryElementBGColor[500]),
			},
			redoButtons: {
				width: '100%',
				height: '10rem',
				display: 'flex',
				[theme.breakpoints.up('md')]: {
					height: '40rem',
				},
				[theme.breakpoints.down('md')]: {
					flexDirection: 'column',
				},
			},
			closeButtonRedoLabel: {
				fontSize: '2rem',
				[theme.breakpoints.up('md')]: {
					fontSize: '4rem',
				},
			},
			closeButtonRedo: {
				width: '100%',
				height: '10rem',
				fontFamily: `${theme.manakin.defaultTitleFont}!important`,
				fontSize: '2rem',
				alignItems: 'center',
				justifyContent: 'center',
				textAlign: 'center',
				textDecoration: 'none',
				transition: 'background-color .3s',
				cursor: 'pointer',
				backgroundColor: theme.manakin.LessonButtonColor[500],
				margin: '0 0.5rem',
				color: theme.palette.getContrastText(theme.manakin.LessonButtonColor[500]),
				padding: theme.spacing(2),
				letterSpacing: '4px',
				[theme.breakpoints.up('md')]: {
					fontSize: '4rem',
					height: '40rem',
					fontWeight: '600',
				},
				'&:hover': {
					backgroundColor: theme.manakin.LessonButtonColor[700],
				},
			},
		}),
	{
		name: 'AppCaseContent',
	}
);