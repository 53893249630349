import {
    OPEN_ASSETS_VIEWER,
    CLOSE_ASSETS_VIEWER,
    PICK_ASSET,
    RESET_ASSETS_VIEWER,
} from './actions';

const initialState = {
    open: false,
    name: 'general',
    imageData: {},
};

export const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case OPEN_ASSETS_VIEWER: {
            return {
                ...state,
                open: true,
                name: action.payload.name,
                multi: action.payload.multi,
                assets: action.payload.assets || '',
            };
        }
        case CLOSE_ASSETS_VIEWER: {
            return {
                ...state,
                open: false,
            };
        }
        case PICK_ASSET: {
            let obj = {};
            obj[action.payload.videoData.name] = action.payload.videoData;

            return {
                ...state,
                imageData: {
                    ...state.imageData,
                    ...obj,
                },
            };
        }
        case RESET_ASSETS_VIEWER: {
            return {
                ...initialState,
            };
        }
        default:
            return state;
    }
};

export default reducer;
