import gql from 'graphql-tag';
import { fileAsset, appAssetNoThumbnail, appAssetWithThumbnail } from '@manakin/app-core/graphQlHelpers';

export const GQL_FETCH_LESSON = gql`
    query lessonView($id: ID!) {
        lesson(id: $id) {
            id
            introTitle
            time
            type
            introduction
            preTitle
            image {
                ${appAssetWithThumbnail}
            }
            headerImage {
                ${appAssetWithThumbnail}
            }
            styleSheet {
                id
                indicationColor
                defaultWorkformColor
                primaryWorkformColor
                secondaryWorkformColor
                themeColor
            }
            workforms {
                id
                type
                tags {
                    id
                    name
                    visibleInApp
                    image {
                        ${appAssetNoThumbnail}
                    }
                }
                correctWorkforms {
                    id
                    title
                    type
                }
                inCorrectWorkforms {
                    id
                    title
                    type
                }
            }
        }
    }
`;

export const GQL_FETCH_CMS_BOX = gql`
    query($id: ID!) {
        box(id: $id) {
            id
            elements {
                id
                type
            }
        }
    }
`;

export const GQL_FETCH_GLOBAL_SETTINGS = gql`
    query {
        settings {
            settings {
                name
                value
                file {
                    ${fileAsset}
                }
            }
        }
    }
`;

export const GQL_FETCH_STYLESHEET = gql`
    query styleSheet($id: ID!) {
        styleSheet(id: $id) {
            id
            title
            description
            customStyle
            indicationColor
            defaultWorkformColor
            primaryWorkformColor
            secondaryWorkformColor
            themeColor
        }
    }
`;
