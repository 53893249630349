'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.changeBoxResults = changeBoxResults;
var CHANGE_BOX_RESULTS = exports.CHANGE_BOX_RESULTS = '@manakin/app-core/CHANGE_BOX_RESULTS';

function changeBoxResults() {
    var results = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    return {
        type: CHANGE_BOX_RESULTS,
        payload: results
    };
}