import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormHelperText from '@material-ui/core/FormHelperText';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Input from '@material-ui/core/Input';
import createUuid from 'uuid/v4';

class PasswordField extends React.Component {
    state = {
        uuid: createUuid(),
        showPassword: false,
    };

    handleFieldChange = (event) => {
        const { form, name } = this.props;
        form.onFieldChange({ key: name, value: event.target.value });
    };

    handleToggleShowPassword = (event) => {
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword,
        }));
    };

    handleEnterPress = (event) => {
        const { onEnterPressed } = this.props;
        if (event.keyCode === 13 && onEnterPressed) onEnterPressed();
    };

    handleMouseDownPassword = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    componentDidMount() {
        const { form, name, initialValue } = this.props;
        form.onFieldChange({ key: name, value: initialValue });
    }

    render() {
        const {
            name,
            classes = {},
            label,
            placeholder,
            fullWidth = true,
            hideAsterisk,
            shrink,
            inputProps,
            form,
        } = this.props;
        const { uuid, showPassword } = this.state;
        const { errors, schema, fields } = form;

        let inputLabelProps = {
            required: (schema[name] || {}).required && !hideAsterisk,
            htmlFor: uuid,
            className: classes.label,
        };

        if (shrink != undefined) inputLabelProps.shrink = shrink;

        return (
            <FormControl
                error={errors[name] && errors[name].length > 0}
                fullWidth={fullWidth}
                margin="dense"
                required={schema[name].required}
            >
                <InputLabel {...inputLabelProps}>{label}</InputLabel>
                <Input
                    id={uuid}
                    autoComplete="on"
                    placeholder={placeholder}
                    type={showPassword ? 'text' : 'password'}
                    value={fields[name] || ''}
                    onKeyUp={this.handleEnterPress}
                    inputProps={inputProps}
                    onChange={this.handleFieldChange}
                    className={classes.input}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                onClick={this.handleToggleShowPassword}
                                onMouseDown={this.handleMouseDownPassword}
                                className={classes.adornment}
                            >
                                {showPassword ? (
                                    <VisibilityOffIcon />
                                ) : (
                                    <VisibilityIcon />
                                )}
                            </IconButton>
                        </InputAdornment>
                    }
                />
                {errors[name] &&
                    errors[name].length > 0 &&
                    errors[name].map((e, i) => (
                        <FormHelperText key={i}>{e}</FormHelperText>
                    ))}
            </FormControl>
        );
    }
}

export default PasswordField;
