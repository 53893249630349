"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.GQL_CREATE_ELEMENT_RESULT = exports.GQL_UPLOAD_ASSET = exports.GQL_FETCH_RATING_REQUESTS = exports.GQL_SEND_RATING_REQUEST_RESPONSE = undefined;

var _templateObject = _taggedTemplateLiteral(["\n\tmutation ratingRequestRespond(\n\t\t$ratingRequestId: Int!\n\t\t$ranking: Ranking!\n\t\t$signatureAssetId: Int\n\t\t$explanation: String!\n\t) {\n\t\taddResultToRatingRequest(\n\t\t\tinput: {\n\t\t\t\tratingRequestId: $ratingRequestId\n\t\t\t\tranking: $ranking\n\t\t\t\tsignatureAssetId: $signatureAssetId\n\t\t\t\texplanation: $explanation\n\t\t\t}\n\t\t) {\n\t\t\tratingRequest {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tmutation ratingRequestRespond(\n\t\t$ratingRequestId: Int!\n\t\t$ranking: Ranking!\n\t\t$signatureAssetId: Int\n\t\t$explanation: String!\n\t) {\n\t\taddResultToRatingRequest(\n\t\t\tinput: {\n\t\t\t\tratingRequestId: $ratingRequestId\n\t\t\t\tranking: $ranking\n\t\t\t\tsignatureAssetId: $signatureAssetId\n\t\t\t\texplanation: $explanation\n\t\t\t}\n\t\t) {\n\t\t\tratingRequest {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n"]),
    _templateObject2 = _taggedTemplateLiteral(["\n\tquery ratingRequests(\n\t\t$pageNr: Int!\n\t\t$pageSize: Int!\n\t\t$filter: RatingRequestFilter\n\t) {\n\t\tratingRequests(pageNr: $pageNr, pageSize: $pageSize, filter: $filter) {\n\t\t\tid\n\t\t\tattempt\n\t\t\telement {\n\t\t\t\tid\n\t\t\t}\n\t\t\ttrainer {\n\t\t\t\tid\n\t\t\t\tfullName\n\t\t\t}\n\t\t\tstudent {\n\t\t\t\tid\n\t\t\t\tfullName\n\t\t\t}\n\t\t\tresult {\n\t\t\t\tid\n\t\t\t\texplanation\n\t\t\t\tgradedAt\n\t\t\t\tranking\n\t\t\t\trater {\n\t\t\t\t\tfullName\n\t\t\t\t}\n\t\t\t\tsignature {\n\t\t\t\t\turl\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tquery ratingRequests(\n\t\t$pageNr: Int!\n\t\t$pageSize: Int!\n\t\t$filter: RatingRequestFilter\n\t) {\n\t\tratingRequests(pageNr: $pageNr, pageSize: $pageSize, filter: $filter) {\n\t\t\tid\n\t\t\tattempt\n\t\t\telement {\n\t\t\t\tid\n\t\t\t}\n\t\t\ttrainer {\n\t\t\t\tid\n\t\t\t\tfullName\n\t\t\t}\n\t\t\tstudent {\n\t\t\t\tid\n\t\t\t\tfullName\n\t\t\t}\n\t\t\tresult {\n\t\t\t\tid\n\t\t\t\texplanation\n\t\t\t\tgradedAt\n\t\t\t\tranking\n\t\t\t\trater {\n\t\t\t\t\tfullName\n\t\t\t\t}\n\t\t\t\tsignature {\n\t\t\t\t\turl\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"]),
    _templateObject3 = _taggedTemplateLiteral(["\n\tmutation uploadAsset($file: Upload!) {\n\t\tuploadAsset(file: $file) {\n\t\t\tasset {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tmutation uploadAsset($file: Upload!) {\n\t\tuploadAsset(file: $file) {\n\t\t\tasset {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n"]),
    _templateObject4 = _taggedTemplateLiteral(["\n\tmutation createElementResult(\n\t\t$user: ID!\n\t\t$program: ID!\n\t\t$box: ID!\n\t\t$element: ID!\n\t\t$ratingTeacher: String\n\t) {\n\t\tcreateElementResult(\n\t\t\tinput: {\n\t\t\t\tuser: $user\n\t\t\t\tprogram: $program\n\t\t\t\tbox: $box\n\t\t\t\telement: $element\n\t\t\t\tratingTeacher: $ratingTeacher\n\t\t\t}\n\t\t) {\n\t\t\tresult {\n\t\t\t\tfinished\n\t\t\t\tratingTeacher\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tmutation createElementResult(\n\t\t$user: ID!\n\t\t$program: ID!\n\t\t$box: ID!\n\t\t$element: ID!\n\t\t$ratingTeacher: String\n\t) {\n\t\tcreateElementResult(\n\t\t\tinput: {\n\t\t\t\tuser: $user\n\t\t\t\tprogram: $program\n\t\t\t\tbox: $box\n\t\t\t\telement: $element\n\t\t\t\tratingTeacher: $ratingTeacher\n\t\t\t}\n\t\t) {\n\t\t\tresult {\n\t\t\t\tfinished\n\t\t\t\tratingTeacher\n\t\t\t}\n\t\t}\n\t}\n"]);

var _graphqlTag = require("graphql-tag");

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_SEND_RATING_REQUEST_RESPONSE = exports.GQL_SEND_RATING_REQUEST_RESPONSE = (0, _graphqlTag2.default)(_templateObject);

var GQL_FETCH_RATING_REQUESTS = exports.GQL_FETCH_RATING_REQUESTS = (0, _graphqlTag2.default)(_templateObject2);

var GQL_UPLOAD_ASSET = exports.GQL_UPLOAD_ASSET = (0, _graphqlTag2.default)(_templateObject3);

var GQL_CREATE_ELEMENT_RESULT = exports.GQL_CREATE_ELEMENT_RESULT = (0, _graphqlTag2.default)(_templateObject4);