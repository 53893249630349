export const PersonalDataForm = {
    firstName: {
        required: true,
    },
    middleName: {
        required: false,
    },
    lastName: {
        required: true,
    },
    dateOfBirth: {
        required: false,
        format: 'date',
    },
    phonenumber: {},
    gender: {},
    registerCode: {},
    registerType: {},
};
