import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { connect, useDispatch } from "react-redux";
import { compose } from "recompose";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import JsFileDownloader from "js-file-downloader";
import { showSnackbarMessage } from "@manakin/core/actions";
import { useSelector } from "react-redux";
import { getProgram } from "@manakin/app-core/ProgramsDropdown/selectors";
import { GQL_STUDENTS_FOR_EXPORT } from "../graphql";
import { useQuery } from "@apollo/react-hooks";
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
	root: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
			alignItems: "start",
		},
	},
	title: {
		color: "white",
		fontWeight: "400",
		marginBottom: 0,
		paddingLeft: theme.spacing(5),
		paddingRight: theme.spacing(6),
		[theme.breakpoints.down("sm")]: {
			paddingLeft: theme.spacing(3),
			paddingRight: theme.spacing(),
		},
	},
	subtitle: {
		color: "white",
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		paddingRight: theme.spacing(),
		flexGrow: 1,
	},
	imageAndTitle: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		[theme.breakpoints.down("sm")]: {
			marginBottom: "20px",
		},
	},
	image: {
		width: 56,
	},
	text: {
		marginBottom: 0,
		paddingBottom: "0 !important",
		color: theme.manakin.subHeaderColor[500],
		textAlign: "center",
		textTransform: "uppercase",
		fontWeight: "500",
	},
	button: {
		border: `2px solid ${theme.manakin.softBorderColor[500]}`,
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
		minHeight: 0,
		"& > span": {
			textTransform: "uppercase",
			fontWeight: "bold",
		},
	},
	subHeader: {},
	contentColor: {},
});

const ReportBlock = (props) => {
	const { classes, config = {}, theme } = props;
	const { general = {} } = config;
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const program = useSelector((state) => getProgram(state));

	const { data = {} } = useQuery(GQL_STUDENTS_FOR_EXPORT, {
		fetchPolicy: "no-cache",
		variables: {
			input: {
				page: 0,
				pagesize: 999,
				programId: program
			},
		},
	});
	const { invitationStudentOverviewCompact: invitationStudentOverview = [] } = data || {};

	const handleDownloadReport = () => {
		if (!general.excelApi) return;

		const studentIds = invitationStudentOverview
			.map((i) => i.id)
			.join(",");

		if (!studentIds.length) return;

		new JsFileDownloader({
			url: `${general.excelApi}/${studentIds}`,
			filename: "rapportage.xls",
		})
			.then(() => {
				snackBar(t("app.progress-dashboard.blocks.report.file-download-success"), "success");
			})
			.catch((_) => {
				snackBar(t("app.progress-dashboard.blocks.report.file-download-failed"), "error");
			});
	};

	const snackBar = (message, variant) =>
		dispatch(
			showSnackbarMessage({
				text: message,
				variant: variant,
			})
		);

	return (
		<div className={classes.root}>
			<div className={classes.imageAndTitle}>
				<img
					alt={t("app.progress-dashboard.blocks.report.title")}
					src={theme.manakin.reportVisual}
					className={classes.image}
				/>
				<Typography
					component="h4"
					variant="h4"
					className={classNames(
						classes.subHeader,
						classes.contentColor,
						classes.title
					)}
				>
					{t("app.progress-dashboard.blocks.report.title")}
				</Typography>
			</div>

			<Button
				variant="contained"
				color="primary"
				size={"small"}
				className={classes.button}
				onClick={handleDownloadReport}
			>
				{t("app.progress-dashboard.blocks.report.action")}
			</Button>
		</div>
	);
};

export default compose(
	withStyles(styles, {
		name: "AppProgressDashboardReportBlock",
		withTheme: true,
	}),
	connect(({ config }) => ({ config }))
)(ReportBlock);
