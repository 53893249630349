import gql from 'graphql-tag';

export const GQL_FETCH_DIGITAL_BOOK = gql`
    query digitalBook($id: ID!) {
        digitalBook(id: $id) {
            id
            book {
                title
                embedCode
            }
        }
    }
`;
