import React from 'react';
import { Carousel, LoadImage, Tags } from '@manakin/app-core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import renderHTML from 'react-render-html';
import { styles } from './styles';
import { compose } from 'recompose';
import {ImageWithFocusPoint} from "@manakin/app-core";
import { withTranslation } from "react-i18next";
import FactCardPrint from './FactCardPrint';

class FactCard extends React.Component {
    state = {
        active: false,
        slideIndex: 0,
    };

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    handleClick = (item, idx) => {
        this.setState(
            {
                animateOut: true,
            },
            () => {
                setTimeout(() => {
                    if (this.props.onClick) this.props.onClick(item);
                    this.setState({ slideIndex: idx, animateOut: false });
                }, 300);
            }
        );
    };

    render() {
        const { classes, t } = this.props;
        const { props, state } = this;
        let curItem = props.items[state.slideIndex] || {};

        return (
            <div
                className={classNames(classes.root, {
                    [classes.animate]: state.animateOut,
                })}
            >
                <div
                    className={classNames(
                        classes.imageContainer,
                        classes[`amount${props.items.length}`],
                        classes.noPrint
                    )}
                >
                    {props.items.map((item, idx) => (
                        <div
                            key={item.id}
                            className={classNames(
                                classes.desktopImage,
                                { [classes.active]: idx == state.slideIndex },
                                {
                                    [classes.blurred]:
                                        state.active !== false
                                            ? state.active !== idx
                                            : null,
                                },
                                { [classes.noHover]: props.items.length < 2 }
                            )}
                            onClick={() => this.handleClick(item, idx)}
                        >
                            <Tags
                                tags={item.tags || []}
                                className={classNames(classes.tags)}
                            />
                            {item.image && item.image.url ? (
                                <LoadImage
                                    objectFitContain={true}
                                    asset={item.image}
                                    src={(item.image && item.image.url) || ''}
                                    enableFocusPoint={true}
                                />
                            ) : (
                                <Typography
                                    variant="h6"
                                    className={classes.imageTitle}
                                >
                                    {item.title || ''}
                                </Typography>
                            )}
                        </div>
                    ))}
                </div>
                <div className={
                    classNames(
                        classes.wrapper,
                        classes.noPrint
                    )
                }>
                    <div className={classes.content}>
                        {props.items.length > 1 && (
                            <Typography
                                variant="subtitle2"
                                component="p"
                                className={classNames(
                                    'smallUnderline',
                                    classes.preTitle
                                )}
                            >
                                {t("common.fields.image.label")}
                            </Typography>
                        )}
                        <Typography
                            variant="h3"
                            component="h2"
                            className={classes.title}
                        >
                            {curItem.title || ''}
                        </Typography>
                    </div>
                    <div className={classes.carousel}>
                        <Carousel
                            slide={state.slideIndex}
                            slideIndex={this.state.slideIndex}
                        >
                            {props.items.map((item, idx) => (
                                <div
                                    onClick={() => this.handleClick(item, idx)}
                                    className={classNames(classes.image, {
                                        [classes.active]:
                                            idx == state.slideIndex,
                                    })}
                                    key={item.id}
                                >
                                    <Tags
                                        tags={item.tags || []}
                                        className={classNames(classes.tags)}
                                    />
                                    {item.image && item.image.url ? (
                                        <ImageWithFocusPoint
                                            asset={item.image}
                                            className={classes.carouselImg}/>
                                    ) : (
                                        <Typography
                                            variant="h6"
                                            className={classes.imageTitle}
                                        >
                                            {item.title || ''}
                                        </Typography>
                                    )}
                                </div>
                            ))}
                        </Carousel>
                    </div>
                    <div className={classes.content}>
                        <Typography variant="body1" component="div">
                            {renderHTML(curItem.text || '')}
                        </Typography>
                    </div>
                </div>

                <FactCardPrint items={props.items} />
            </div>
        );
    }
}

export default compose(
    withStyles(styles, { name: 'AppFactCard' }),
    withTranslation()
)(FactCard);
