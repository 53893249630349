"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.GQL_FETCH_RATINGS = undefined;

var _templateObject = _taggedTemplateLiteral(["\n\tquery ratings($programId: ID!) {\n\t\tratings(programId: $programId) {\n\t\t\tid\n\t\t\ttitle\n\t\t\tauthor {\n\t\t\t\tid\n\t\t\t\tfullName\n\t\t\t}\n\t\t\timage {\n\t\t\t\t", "\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tquery ratings($programId: ID!) {\n\t\tratings(programId: $programId) {\n\t\t\tid\n\t\t\ttitle\n\t\t\tauthor {\n\t\t\t\tid\n\t\t\t\tfullName\n\t\t\t}\n\t\t\timage {\n\t\t\t\t", "\n\t\t\t}\n\t\t}\n\t}\n"]);

var _graphqlTag = require("graphql-tag");

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

var _graphQlHelpers = require("@manakin/app-core/graphQlHelpers");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_RATINGS = exports.GQL_FETCH_RATINGS = (0, _graphqlTag2.default)(_templateObject, _graphQlHelpers.appAssetWithThumbnail);