'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRenderHtml = require('react-render-html');

var _reactRenderHtml2 = _interopRequireDefault(_reactRenderHtml);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _graphql = require('../graphql');

var _core = require('@material-ui/core');

var _VideoCard = require('@manakin/app-core/ElementCard/VideoCard');

var _VideoCard2 = _interopRequireDefault(_VideoCard);

var _appCore = require('@manakin/app-core');

var _reactHooks = require('@apollo/react-hooks');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var Bridge = function Bridge(props) {
    var _classNames, _classNames4;

    var classes = props.classes,
        item = props.item,
        handleLinkClick = props.handleLinkClick,
        tags = props.tags,
        show = props.show;

    //queries

    var _useQuery = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_BRIDGE, {
        variables: {
            id: item.id
        }
    }),
        _useQuery$data = _useQuery.data,
        data = _useQuery$data === undefined ? {} : _useQuery$data,
        loading = _useQuery.loading;

    var _data$bridge = data.bridge,
        bridge = _data$bridge === undefined ? {} : _data$bridge;
    var _bridge$vimeoId = bridge.vimeoId,
        vimeoId = _bridge$vimeoId === undefined ? '' : _bridge$vimeoId,
        _bridge$text = bridge.text,
        text = _bridge$text === undefined ? '' : _bridge$text,
        _bridge$titleColor = bridge.titleColor,
        titleColor = _bridge$titleColor === undefined ? '' : _bridge$titleColor;


    if (vimeoId) {
        return _react2.default.createElement(_VideoCard2.default, {
            show: show,
            onDisabledClick: handleLinkClick,
            item: item,
            bridgeVideo: vimeoId,
            subtitle: bridge.subtitle
        });
    }

    return _react2.default.createElement(
        'div',
        {
            className: (0, _classnames2.default)(classes.root, (_classNames = {}, _defineProperty(_classNames, classes.fullWidth, item.fullWidth), _defineProperty(_classNames, classes.noBackground, true), _defineProperty(_classNames, classes.show, show), _classNames), _defineProperty({}, classes.primaryBackgroundColor, item.backgroundColor == 'PRIMARY'), _defineProperty({}, classes.secondaryBackgroundColor, item.backgroundColor == 'SECONDARY')),
            style: {
                transition: 'all .5s .' + (1 + 1) + 's',
                backgroundColor: item.backgroundColor
            }
        },
        _react2.default.createElement(_appCore.Tags, {
            tags: tags,
            className: (0, _classnames2.default)(classes.tags, (_classNames4 = {}, _defineProperty(_classNames4, classes.withIcon, item.done != undefined), _defineProperty(_classNames4, classes.bookcheckTag, item.type === 'BookCheck'), _classNames4))
        }),
        _react2.default.createElement(
            'div',
            { className: classes.bridgeContent },
            _react2.default.createElement(
                _core.Typography,
                {
                    variant: 'h3',
                    className: (0, _classnames2.default)(classes.contentColor, classes.contentTitle, _defineProperty({}, classes.darkColor, titleColor === 'SECONDARY'), _defineProperty({}, classes.lightColor, titleColor === 'PRIMARY'))
                },
                item.title
            ),
            _react2.default.createElement(
                _core.Typography,
                {
                    component: 'div',
                    variant: 'body1',
                    className: (0, _classnames2.default)(classes.contentColor, _defineProperty({}, classes.darkColor, titleColor === 'SECONDARY'), _defineProperty({}, classes.lightColor, titleColor === 'PRIMARY'))
                },
                (0, _reactRenderHtml2.default)(text || '')
            )
        )
    );
};

exports.default = Bridge;