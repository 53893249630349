export const styles = (theme) => ({
	wrapper: {
		...theme.manakin.defaultWrapper,
		[theme.breakpoints.up('md')]: {
			padding: 0,
		},
	},
	answersRoot: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	card: {
		display: 'flex',
		height: 0,
		width: 'calc(100% - 5px)',
		paddingBottom: 'calc(35% - 5px)',
		marginRight: '5px',
		position: 'relative',
		marginBottom: '20px',
		cursor: 'pointer',
		//overflow: 'hidden',
		[theme.breakpoints.up('lg')]: {
			width: 'calc(100% - 20px)',
			paddingBottom: 'calc(25% - 20px)',
			marginBottom: '20px',
			marginRight: '20px',
		},
	},
	innerCard: {
		backgroundColor: theme.manakin.primaryColor[500],
		position: 'absolute',
		zIndex: '2',
		left: 0,
		top: 0,
		width: '50%',
		height: '100%',
		cursor: 'pointer',

		'&:hover': {
			'& $image img': {
				transform: 'scale(1.2)',
			},
		},
	},
	image: {
		width: '100%',
		display: 'flex',
		height: '100%',
		flexDirection: 'column',
		overflow: 'hidden',
		position: 'relative',
		zIndex: '2',

		'& img': {
			transition: 'transform .2s',
		},
	},
	backgroundImage: {},
	selected: {},
	checked: {
		position: 'absolute',
		width: 'calc(100% + 14px)',
		height: 'calc(100% + 14px)',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%) scale(.85)',
		alignItems: 'center',
		justifyContent: 'center',
		opacity: 0,
		display: 'flex',
		pointerEvents: 'none',
		border: '2px solid black',
		transition: 'transform .2s ease, opacity .2s ease',
	},
	iconRoot: {
		width: '1.3rem',
		height: '1.3rem',
	},
	showIcon: {
		opacity: 1,
		transform: 'translate(-50%, -50%) scale(1)',
	},
	feedback: {
		position: 'absolute',
		bottom: 0,
		left: 0,
		width: '100%',
		zIndex: 1,
		transform: 'translateY(20px)',
		opacity: 0,
		transition: 'transform .3s, opacity .3s',
		pointerEvents: 'none',
		[theme.breakpoints.up('md')]: {
			position: 'relative',
			transition: 'transform .3s, opacity .3s',
			top: 0,
			height: 0,
		},
	},
	activeFeedback: {
		position: 'relative',
		transform: 'translateY(0)',
		height: 'auto',
		opacity: 1,
		pointerEvents: 'auto',
		[theme.breakpoints.up('md')]: {
			height: 'auto',
		},
	},
	buttons: {
		position: 'relative',
		marginTop: '2rem',
		display: 'inline-block',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			paddingRight: '0',
			marginTop: '0',
		},
	},
	answerTitle: {
		margin: '0 20px',
	},
	answerTitleContainer: {
		position: 'absolute',
		alignItems: 'center',
		right: 0,
		top: 0,
		width: '50%',
		height: '100%',
		display: 'flex',
		backgroundColor: 'white',
		transition: 'background-color .2s',
	},
	button: {
		'& span': {
			justifyContent: 'space-between',
			paddingLeft: '2rem',
			paddingRight: '2rem',
		},
	},
	longArrow: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'block',
		},
	},
	active: {
		backgroundColor: theme.manakin.correctAnswer[500],
		'& answerTitleContainer': {
			backgroundColor: theme.manakin.correctAnswer[500],
		},
		'& $answerTitle': {
			color: theme.palette.getContrastText(
				theme.manakin.correctAnswer[500]
			),
		},
	},
	correct: {
		border: '2px solid black',
	},
	disabled: {
		opacity: 0.5,
		cursor: 'default',
		'&:hover': {
			'& $image img': {
				transform: 'scale(1)',
			},
		},
	},
});
