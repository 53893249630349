import React, { useState, useEffect } from 'react';
import { graphql } from 'react-apollo';
import { GQL_CHECK_STATUS, GQL_ACQUIRE_LICENSES } from '../graphql';
import { withStyles, Typography } from '@material-ui/core';
import queryString from 'query-string';
import { Loader } from '@manakin/app-core';
import { withHandlers } from 'recompose';
import { withRouter } from 'react-router';
import Button from '@material-ui/core/Button';
import { logout } from '@manakin/authentication/actions';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import { compose } from 'recompose';

const styles = (theme) => ({
    root: {
        padding: '14rem 0',
        backgroundColor: '#F2F2F2',
        minHeight: '100vh',
    },
    wrapper: {
        ...theme.manakin.smallWrapper,
    },
    intro: {
        textAlign: 'center',
    },
    heading: {
        fontSize: '4.8rem',
        fontWeight: 'bold',
        lineHeight: '5.2rem',
    },
    introText: {
        maxWidth: '50rem',
        margin: '0 auto',
        color: theme.manakin.primaryColor[500],
    },
    content: {
        margin: '4rem auto',
    },
    mainButtonStyle: {
        padding: '2rem',
        minWidth: '30rem',
        marginTop: '2.8rem',
    },
});

const PaymentFinished = (props) => {
    const { classes } = props;
    Cookies.remove('shop');

    //state hooke
    const [loading, setIsLoading] = useState(true);
    const [heading, setHeading] = useState('Gelukt!');
    const [buttonText, setButtonText] = useState('Inloggen');
    const [body, setBody] = useState(
        'Je hebt nu toegang tot je aangeschafte producten. Login in om gelijk aan de slag te gaan'
    );
    const [acquired, setAcquired] = useState(false);

    //effect hooks
    useEffect(() => {
        const parsed = queryString.parse(location.search);

        if (parsed.trxid) {
            props
                .onCheck({
                    transactionId: parsed.trxid,
                })
                .then((result) => {
                    if (
                        result.data &&
                        result.data.idealStatus &&
                        result.data.idealStatus.transaction &&
                        result.data.idealStatus.transaction.status &&
                        result.data.idealStatus.transaction.status === 'Success'
                    ) {
                        setIsLoading(false);
                        setAcquired(true);
                    } else {
                        setIsLoading(false);
                        setHeading('Betaling mislukt');
                        setBody(
                            'Er ging iets mis tijdens het betalen, Login om het nog een keer te proberen'
                        );
                        setAcquired(true);
                    }
                });
        } else {
            setIsLoading(false);
            setHeading('Betaling mislukt');
            setBody(
                'Er ging iets mis tijdens het betalen, Login om het nog een keer te proberen'
            );
            setButtonText('Opnieuw proberen');
        }
    }, []);

    //fucntions
    const handleAcquire = () => {
        props
            .onAcquire({
                unlimited: false,
            })
            .then((result) => {
                if (
                    result.data &&
                    result.data.acquireLicenses &&
                    result.data.acquireLicenses.result &&
                    result.data.acquireLicenses.result === 'OK'
                ) {
                    setAcquired(true);
                }
            });
    };

    const handleClick = () => {
        props.onLogout();
    };

    if (loading) {
        return (
            <div>
                <Loader fullScreen={true} />
            </div>
        );
    }
    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <Typography variant="h3" component="h1">
                    {heading}
                </Typography>
                <Typography variant="body1" component="p">
                    {body}
                </Typography>
                {acquired && (
                    <Button
                        onClick={handleClick}
                        color="primary"
                        variant="contained"
                        className={classes.mainButtonStyle}
                    >
                        {buttonText}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default compose(
    connect(null, (dispatch) => ({
        onLogout: () => dispatch(logout()),
    })),
    withStyles(styles),
    withRouter,
    graphql(GQL_CHECK_STATUS),
    graphql(GQL_ACQUIRE_LICENSES, { name: 'ACQUIRE_LICENSES' }),
    withHandlers({
        onCheck: ({ mutate }) => (event) => {
            return mutate({
                variables: {
                    ...event,
                },
            });
        },
        onAcquire: ({ ACQUIRE_LICENSES }) => (event) => {
            return ACQUIRE_LICENSES({
                variables: {
                    ...event,
                },
            });
        },
    })
)(PaymentFinished);
