import React, { useEffect } from 'react';
import useStyles from './styles';
import PersonalDataForm from '../forms/PersonalDataForm';
import Introduction from './parts/Introduction';
import { useTranslation } from 'react-i18next';
import { FormKeys } from '@manakin/core';

const PersonalData = (props) => {
	const { form } = props;
	const classes = useStyles();
	const { t } = useTranslation();

	useEffect(() => {
		form.onRequiredChange(FormKeys.firstName, true);
		form.onRequiredChange(FormKeys.lastName, true);
		return () => {
			form.onRequiredChange(FormKeys.firstName, false);
			form.onRequiredChange(FormKeys.lastName, false);
			form.onRequiredChange(FormKeys.gender, false);
			form.onRequiredChange(FormKeys.dateOfBirth, false);
			form.onRequiredChange(FormKeys.phonenumber, false);
		};
	}, []);

	return (
		<React.Fragment>
			<Introduction
				title={t('authentication.registration.personal-data.title')}
				Introduction={t('authentication.registration.personal-data.introduction')}
			/>
			<div className={classes.content}>
				<div className={classes.defaultWrapper}>
					<div className={classes.content}>
						<PersonalDataForm form={form} />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default PersonalData;
