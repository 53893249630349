'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _reactMedia = require('react-media');

var _reactMedia2 = _interopRequireDefault(_reactMedia);

var _Menu = require('../Menu/Menu');

var _Menu2 = _interopRequireDefault(_Menu);

var _recompose = require('recompose');

var _reactRedux = require('react-redux');

var _styles = require('@material-ui/core/styles');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var useStyles = (0, _styles.makeStyles)(function (theme) {
	return (0, _styles.createStyles)({
		root: {
			padding: '180px 0',
			margin: '0 auto',
			position: 'relative',
			minHeight: '100vh',
			background: 'url(' + theme.manakin.accountBackgroundImage + ') top right / auto 60vh fixed no-repeat'
		},
		mobileRoot: {
			padding: '40px 0',
			margin: '0 auto',
			position: 'relative'
		},
		smallWrapper: _extends({}, theme.manakin.smallWrapper),
		wrapper: _extends({}, theme.manakin.defaultWrapper, {
			maxWidth: '800px'
		}),
		title: _defineProperty({
			fontSize: '6rem',
			lineHeight: '7rem',
			position: 'relative',
			margin: '0 0 7rem',
			letterSpacing: '0.5rem'
		}, theme.breakpoints.down('sm'), {
			fontSize: '3.4rem',
			lineHeight: '5rem'
		}),
		underline: {
			width: '50px',
			height: '2px',
			backgroundColor: theme.manakin.defaultContentColor[500],
			position: 'absolute',
			bottom: 0,
			left: '50%',
			transform: 'translate(-50%, 3rem)'
		},
		children: {
			margin: '8rem 0 0'
		},
		introduction: {
			whiteSpace: 'pre-wrap'
		}
	});
}, {
	name: 'AppAccountWrapper'
});

var AccountWrapper = function AccountWrapper(props) {
	var children = props.children,
	    _props$title = props.title,
	    title = _props$title === undefined ? '' : _props$title,
	    _props$introduction = props.introduction,
	    introduction = _props$introduction === undefined ? '' : _props$introduction,
	    _props$config = props.config,
	    config = _props$config === undefined ? {} : _props$config;
	var _config$pages = config.pages,
	    pages = _config$pages === undefined ? {} : _config$pages;

	var renderMenu = pages.appAccount && pages.appAccount.showMenu != undefined ? pages.appAccount.showMenu : true;
	var classes = useStyles();

	var renderGeneral = function renderGeneral() {
		return _react2.default.createElement(
			'div',
			null,
			renderMenu && _react2.default.createElement(_Menu2.default, { location: location }),
			_react2.default.createElement(
				'div',
				{ className: classes.smallWrapper },
				_react2.default.createElement(
					_Typography2.default,
					{ align: 'center', variant: 'h2', classes: { h2: classes.title } },
					title,
					_react2.default.createElement('span', { className: classes.underline })
				),
				_react2.default.createElement(
					_Typography2.default,
					{ align: 'center', variant: 'body1', className: classes.introduction },
					introduction
				)
			),
			_react2.default.createElement(
				'div',
				{ className: classes.wrapper },
				_react2.default.createElement(
					'div',
					{ className: classes.children },
					children
				)
			)
		);
	};

	return _react2.default.createElement(
		_reactMedia2.default,
		{ query: '(max-width: 1140px)' },
		function (matches) {
			return matches ? _react2.default.createElement(
				'div',
				{ className: classes.mobileRoot },
				renderGeneral()
			) : _react2.default.createElement(
				'div',
				{ className: classes.root },
				renderGeneral()
			);
		}
	);
};

exports.default = (0, _recompose.compose)((0, _reactRedux.connect)(function (state) {
	return { config: state.config };
}))(AccountWrapper);