import React from 'react';
import ImageMapper from './ImageMapper';
import { openAssetsViewer, resetAssetViewer } from '@manakin/core/actions';
import { getImageData } from '@manakin/core/AssetsViewer/selectors';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import createUuid from 'uuid/v4';
import Cookies from 'js-cookie';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { withTranslation } from "react-i18next";

const mapStateToProps = (state) => ({
    config: state.config,
    imageData: getImageData(state),
});

const mapDispatchToProps = (dispatch) => ({
    openAssets: (name) => dispatch(openAssetsViewer(name)),
    resetAssets: () => dispatch(resetAssetViewer()),
});

class ImageMapperContainer extends React.Component {
    state = {
        uuid: createUuid(),
        moveHotspotId: undefined
    };

    componentDidMount() {
        if (this.props.initialValue) {
            const { initialValue } = this.props;
            let hotspots = {};
            initialValue.forEach((val) => {
                hotspots[val.id] = { ...val };
            });

            this.setState({ hotspots: hotspots }, () => this.saveComponent());
        }

        if (this.props.initialImage) {
            this.setState({ image: this.props.initialImage }, () => {
                this.props.form.onFieldChange({
                    key: this.props.imageName,
                    value: this.props.initialImage,
                });
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { uuid } = this.state;

        const prevId = prevProps.form.fields.hotspotImagePreview
            ? prevProps.form.fields.hotspotImagePreview.bynderId
            : undefined;
        const curId = this.props.form.fields.hotspotImagePreview
            ? this.props.form.fields.hotspotImagePreview.bynderId
            : undefined;

        if (prevId != curId && curId) {
            const imageData = this.props.form.fields.hotspotImagePreview;

            this.setState(
                {
                    image: imageData,
                },
                () => this.saveComponent()
            );
            this.saveImage(imageData);
        } else if (prevId != curId && !curId) {
            this.setState(
                {
                    image: null,
                },
                () => this.saveComponent(null)
            );
            this.saveImage(null);
        }
        if (prevProps.initialImage != this.props.initialImage) {
            this.setState({ image: this.props.initialImage }, () => {
                this.props.form.onFieldChange({
                    key: this.props.imageName,
                    value: this.props.initialImage,
                });
            });
        }
        if (
            this.props.initialValue &&
            prevProps.initialValue != this.props.initialValue
        ) {
            const { initialValue } = this.props;
            let hotspots = {};
            initialValue.forEach((val) => {
                hotspots[val.id] = { ...val };
            });

            this.setState({ hotspots: hotspots }, () => this.saveComponent());
        }
    }

    saveComponent = () => {
        const { form, name } = this.props;
        if (this.state.hotspots) {
            const { hotspots } = this.state;
            const hotspotsArr = Object.keys(hotspots).map((key) => {
                const { open, ...otherSpots } = hotspots[key];
                return {
                    ...otherSpots,
                    radius: 1,
                };
            }, []);

            form.onFieldChange({ key: name, value: hotspotsArr });
        } else {
            form.onFieldChange({ key: name, value: [] });
        }
    };

    saveImage = (imageData) => {
        const { uuid } = this.state;
        const { form, imageName } = this.props;

        form.onFieldChange({
            key: imageName,
            value: {
                ...imageData,
            },
        });
    };

    handleImageClick = (event) => {
        const { config = {} } = this.props;
        const { moveHotspotId } = this.state;
        const { languages = [] } = config;
        const lang = Cookies.get('locale');
        let showMenu = true;

        if (languages.length) {
            showMenu = false;
            if (lang === languages[0].short) showMenu = true;
        }

        if (!showMenu) {
            this.setState({ openDialog: true });
            return;
        }

        const coords = {
            x: event.nativeEvent.layerX,
            y: event.nativeEvent.layerY,
        };
        const imageHeight = event.nativeEvent.target.clientHeight;
        const imageWidth = event.nativeEvent.target.width;
        const x = Math.round((coords.x / imageWidth) * 100);
        const y = Math.round((coords.y / imageHeight) * 100);
        const _id = createUuid();

        if (moveHotspotId) {
            // User has chosen the option to move a hotspot, move it instead of creating a new one at this spot
            this.setState(
                (prevState) => ({
                    hotspots: {
                        ...Object.keys(prevState.hotspots).map((key) => {
                            return {
                                ...prevState.hotspots[key],
                                open: false,
                                ...((key === moveHotspotId) && {
                                    x: x,
                                    y: y
                                })
                            }
                        })
                    },
                    moveHotspotId: undefined,
                    hint: undefined
                }),
                () => this.saveComponent()
            );
            
            return;
        }

        this.setState(
            (prevState) => ({
                hotspots: {
                    ...Object.keys(prevState.hotspots).map((key) => ({
                        ...prevState.hotspots[key],
                        open: false,
                    })),
                    [_id]: {
                        x: x,
                        y: y,
                        title: '',
                        text: '',
                        popupOrientation: 'RIGHT_BOTTOM',
                        open: true,
                    },
                },
            }),
            () => this.saveComponent()
        );
    };

    handleClick = () => {
        const { openAssets, readOnly = false } = this.props;

        if (!readOnly) {
            const { uuid } = this.state;
            openAssets(uuid);
        }
    };

    handleChange = (data) => {
        this.setState(
            (prevState) => ({
                hotspots: {
                    ...prevState.hotspots,
                    [data.id]: {
                        ...prevState.hotspots[data.id],
                        [data.name]: data.value,
                    },
                },
            }),
            () => this.saveComponent()
        );
    };

    handleMove = (id) => {
        const { t } = this.props;
        this.setState({
            moveHotspotId: id,
            hint: t("cms.workforms.hotspot-map.move-hotspot-instruction")
        });
    };

    handleHotspotClick = (name) => {
        this.setState((prevState) => ({
            hotspots: {
                ...Object.keys(prevState.hotspots).map((key) => ({
                    ...prevState.hotspots[key],
                    open: false,
                })),
                [name]: {
                    ...prevState.hotspots[name],
                    open: true,
                },
            },
        }));
    };

    handleDelete = (id) => {
        const hotspots = { ...this.state.hotspots };
        delete hotspots[id];

        this.setState(
            {
                hotspots: { ...hotspots },
            },
            () => this.saveComponent()
        );
    };

    handleClose = () => {
        this.setState((prevState) => ({
            hotspots: {
                ...Object.keys(prevState.hotspots).map((key) => ({
                    ...prevState.hotspots[key],
                    open: false,
                })),
            },
        }));
    };

    handleCloseDialog = () => {
        this.setState({
            openDialog: false,
        });
    };

    render() {
        const { form, t } = this.props;
        const { open, openDialog, hint } = this.state;

        return (
            <div>
                <Dialog
                    open={openDialog}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {t("cms.workforms.hotspot.no-add-dialog.title")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {t("cms.workforms.hotspot.no-add-dialog.description")}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleCloseDialog}
                            color="primary"
                            autoFocus
                        >
                            {t("common.ok")}
                        </Button>
                    </DialogActions>
                </Dialog>
                <ImageMapper
                    {...this.state}
                    onHotspotClick={this.handleHotspotClick}
                    onClick={this.handleClick}
                    onImageClick={this.handleImageClick}
                    onClose={this.handleClose}
                    onChange={this.handleChange}
                    onDelete={this.handleDelete}
                    onMove={this.handleMove}
                    hint={hint}
                    form={form}
                />
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(ImageMapperContainer);
