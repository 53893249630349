'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _core = require('@material-ui/core');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useStyles = (0, _core.makeStyles)(function (theme) {
    return {
        colorPicker: {
            paddingLeft: '5px',
            width: '80%',
            height: '25%',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer'
        },
        input: {
            marginRight: '10px'
        }
    };
});

var Picker = function Picker(props) {
    var initial = props.initial;

    var _useState = (0, _react.useState)(initial || '#000000'),
        _useState2 = _slicedToArray(_useState, 2),
        hex = _useState2[0],
        setHex = _useState2[1];

    var classes = useStyles();

    var handleChange = function handleChange(e) {
        var val = e.target.value;
        setHex(val);
        if (props.onChange) props.onChange({ value: val, name: props.name });
    };

    return _react2.default.createElement(
        'div',
        { className: classes.colorPicker },
        _react2.default.createElement('input', {
            type: 'color',
            id: 'color1',
            name: 'color1',
            className: classes.input,
            value: hex,
            onChange: handleChange }),
        _react2.default.createElement(
            'p',
            null,
            hex
        )
    );
};

exports.default = Picker;