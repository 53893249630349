import React from "react";
import {
	ElementOverlay,
	TransitionRouter,
	withStepper,
	Stepper,
} from "@manakin/app-core";
import { Route } from "react-router-dom";
import { compose } from "recompose";
import Header from "./parts/Header";
import Outro from "./parts/Outro";
import { withTranslation } from "react-i18next";
import { SettingsKeys } from '@manakin/core/lib/constants';

const BOOKCHECK_ROOT_PATH = "BookCheck";

class BookCheck extends React.PureComponent {
	componentDidMount(prevProps) {
		if (!this.props.loading) {
			const { stepper, workforms } = this.props;
			stepper.setItems(workforms || []);
		}
	}

	handleClick = (event) => {
		if (this.props.onClick) this.props.onClick(event);
	};

	handleExit = (count) => {
		if (this.props.onExit) this.props.onExit(count);
	};

	render() {
		const {
			rawData = {},
			loading,
			options = {},
			stepper,
			step = false,
			saveWorkform,
			elementId,
			elementResult,
			settings = [],
			workforms,
			t
		} = this.props;

		let prefix = t("app.elements.book-check.title");

		settings.forEach((i) => {
			if (i.name === SettingsKeys.BOOKCHECK_PREFIX && i.value) prefix = i.value;
		});

		if (step) {
			return (
				<React.Fragment>
					{step == 1 && (
						<Header
							data={rawData}
							loading={loading}
							onClick={this.handleClick}
						/>
					)}
					{step == 2 && (
						<Stepper
							stepper={stepper}
							options={options}
							onExit={this.handleExit}
							variant="bookCheck"
						/>
					)}
					{step == 3 && (
						<Outro data={rawData} onClick={this.handleClick} />
					)}
				</React.Fragment>
			);
		} else {
			return (
				<ElementOverlay
					background={rawData.borderColor || false}
					title={prefix}
				>
					<TransitionRouter>
						<Route
							exact
							path={`/${BOOKCHECK_ROOT_PATH}/:elementId`}
							render={() => (
								<Header
									data={rawData}
									loading={loading}
									onClick={this.handleClick}
								/>
							)}
						/>
						<Route
							exact
							path={`/${BOOKCHECK_ROOT_PATH}/:elementId/questions`}
							render={() => (
								<Stepper
									stepper={stepper}
									options={options}
									onExit={this.handleExit}
									variant="bookCheck"
									save={saveWorkform}
									elementId={elementId}
								/>
							)}
						/>
						<Route
							exact
							path={`/${BOOKCHECK_ROOT_PATH}/:elementId/outro`}
							render={() => (
								<Outro
									data={rawData}
									onClick={this.handleClick}
									elementResult={elementResult}
									workforms={workforms}
								/>
							)}
						/>
					</TransitionRouter>
				</ElementOverlay>
			);
		}
	}
}

export default compose(
	withStepper(),
	withTranslation(),
)(BookCheck);
