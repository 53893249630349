'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactApollo = require('react-apollo');

var _graphql = require('./graphql');

var _queries = require('./queries');

var _selectors = require('@manakin/app-core/Boxes/selectors');

var _selectors2 = require('@manakin/app-core/ProgramsDropdown/selectors');

var _selectors3 = require('@manakin/authentication/selectors');

var _reactRedux = require('react-redux');

var _recompose = require('recompose');

var _lib = require('../lib');

var _reactI18next = require('react-i18next');

var _constants = require('@manakin/core/lib/constants');

var _actions = require('@manakin/core/actions');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var mapStateToProps = function mapStateToProps(state) {
	return {
		boxId: (0, _selectors.getBoxId)(state),
		appUser: (0, _selectors3.getAppUser)(state),
		superUser: (0, _selectors3.getSuperUser)(state),
		program: (0, _selectors2.getProgram)(state)
	};
};

var withWorkforms = function withWorkforms(data) {
	return function (WrappedComponent) {
		return (0, _recompose.compose)(_reactApollo.withApollo, (0, _reactApollo.graphql)(_graphql.GQL_FETCH_GLOBAL_SETTINGS, {
			name: 'SETTINGS'
		}), (0, _reactApollo.graphql)(_graphql.GQL_DELETE_WORKFORM_RESULT, {
			name: 'DELETE_WORKFORM'
		}), (0, _reactI18next.withTranslation)(), (0, _reactApollo.graphql)(_graphql.GQL_CREATE_WORKFORM_RESULT), (0, _recompose.withHandlers)({
			onUpdate: function onUpdate(_ref) {
				var mutate = _ref.mutate;
				return function (event) {
					return mutate({
						variables: _extends({}, event)
					});
				};
			},
			onDelete: function onDelete(_ref2) {
				var DELETE_WORKFORM = _ref2.DELETE_WORKFORM;
				return function (event) {
					return DELETE_WORKFORM({
						variables: _extends({}, event)
					});
				};
			}
		}), (0, _reactRedux.connect)(mapStateToProps))(function (_React$Component) {
			_inherits(WithWorkforms, _React$Component);

			function WithWorkforms() {
				var _ref3;

				var _temp, _this, _ret;

				_classCallCheck(this, WithWorkforms);

				for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
					args[_key] = arguments[_key];
				}

				return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref3 = WithWorkforms.__proto__ || Object.getPrototypeOf(WithWorkforms)).call.apply(_ref3, [this].concat(args))), _this), _this.state = {
					loading: true,
					saving: false,
					workforms: [],
					options: {},
					finished: false,
					split: false,
					hasReset: false
				}, _this.setElementInProgress = function (element) {
					_this.handleLoadElementResult(element).then(function (result) {
						if (_this.state.elementResult == undefined) {
							_this.handleSaveElement({
								elementId: element,
								finished: false
							});
						}
					});
				}, _this.handleLoadElementResult = function (data) {
					var returnData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

					if (data) {
						var client = _this.props.client;

						var boxId = _this.props.boxId;
						var programId = _this.props.program;
						var userId = _this.props.superUser || _this.props.appUser.id;
						var elementId = data;

						if (userId && programId && boxId && elementId) {
							if (returnData) {
								return client.query({
									query: _graphql.GQL_FETCH_ELEMENT_RESULTS,
									variables: {
										user: userId,
										program: programId,
										box: boxId,
										element: elementId
									}
								});
							} else {
								return client.query({
									query: _graphql.GQL_FETCH_ELEMENT_RESULTS,
									variables: {
										user: userId,
										program: programId,
										box: boxId,
										element: elementId
									}
								}).then(function (result) {
									if (!result.errors && result.data.elementResult) {
										_this.setState({
											elementResult: result.data.elementResult
										});
									}
								});
							}
						} else {
							console.log('Er ging iets fout');
						}
					}
				}, _this.getWorkformResult = function (data) {
					if (data.element) {
						var client = _this.props.client;

						var boxId = _this.props.boxId;
						var userId = _this.props.superUser || _this.props.appUser.id;
						var elementId = data.element;
						var workformId = data.id;
						var programId = _this.props.program;

						if (userId && programId && boxId && elementId && workformId) {
							return client.query({
								query: _graphql.GQL_FETCH_WORKFORM_RESULT,
								variables: {
									user: userId,
									program: programId,
									box: boxId,
									element: elementId,
									workform: workformId
								}
							});
						}
					}
				}, _this.merge = function (workform, result) {
					var results = [];
					if (result.data.workformResult && result.data.workformResult.answers && result.data.workformResult.answers.length && workform && workform.answers) {
						result.data.workformResult.answers.forEach(function (i) {
							var id = i.id;
							workform.answers.forEach(function (answer) {
								if (answer.id == id) results = [].concat(_toConsumableArray(results), [_extends({}, answer, i)]);
							});
						});
					} else if (workform && workform.answers) {
						return workform.answers;
					}
					return results;
				}, _this.lowerFirst = function (s) {
					if (typeof s !== 'string') return '';
					return s.charAt(0).toLowerCase() + s.slice(1);
				}, _this.handleLoadWorkforms = function (data) {
					var _this$props = _this.props,
					    client = _this$props.client,
					    t = _this$props.t;


					var items = [];
					var count = 0;

					_this.setState({
						rawData: data
					});

					data.elementId && _this.setElementInProgress(data.elementId);

					if (data.workforms.length == 0) {
						_this.setState(function (prevState) {
							return {
								workforms: [],
								loading: false,
								options: data.options || {}
							};
						});
					}

					var _savable = (0, _lib.savable)(data.workforms);

					if (!_savable.length && data.elementId) {
						_this.handleSaveElement({
							elementId: data.elementId,
							correct: true,
							finished: true
						});
					}

					data.workforms.forEach(function (item, i) {
						(function (idx) {
							if ((0, _queries.getQuery)(item.type)) {
								client.query({
									query: (0, _queries.getQuery)(item.type),
									variables: { id: item.id }
								}).then(function (result) {
									var _this$state$generalSe = _this.state.generalSettings,
									    generalSettings = _this$state$generalSe === undefined ? {} : _this$state$generalSe;

									var _workform = result.data[(0, _queries.getPropName)(item.type)] || item;
									var instructionTextKey = _this.lowerFirst(_workform.type) + 'InstructionText';

									if (data.elementId) {
										_this.getWorkformResult(_extends({}, _workform, {
											element: data.elementId || null
										})).then(function (result) {
											var answers = _this.merge(_workform, result);

											var workform = _extends({}, _workform, {
												correctWorkforms: item.correctWorkforms ? [].concat(_toConsumableArray(item.correctWorkforms)) : [],
												inCorrectWorkforms: item.inCorrectWorkforms ? [].concat(_toConsumableArray(item.inCorrectWorkforms)) : [],
												finished: result.data.workformResult && result.data.workformResult.finished,
												correct: result.data.workformResult && result.data.workformResult.statement,
												answer: result.data.workformResult && result.data.workformResult.answer !== undefined && result.data.workformResult.answer !== null ? result.data.workformResult.answer : _workform.answer,
												introduction: _workform.introduction || generalSettings && generalSettings[instructionTextKey] || (0, _actions.getSettingValue)(instructionTextKey) || '',
												buttonText: _workform.buttonText || generalSettings && generalSettings[_constants.SettingsKeys.GENERAL_BUTTON_CHECK_TEXT] || (0, _actions.getSettingValue)(_constants.SettingsKeys.GENERAL_BUTTON_CHECK_TEXT) || t('common.check-answer'),
												split: item.split ? item.split : false,
												rawResult: result.data.workformResult
											});

											if (workform.answers) {
												workform.answers = answers;
											}
											count = count + 1;
											items[idx] = workform;

											if (count >= data.workforms.length && data.elementId) {
												_this.handleLoadElementResult(data.elementId, true).then(function (r) {
													if (r.data && r.data.elementResult) {
														if (!r.data.elementResult.finished) {
															_this.handlePercentage(items, data.elementId || null, true);
														}
													}
												});
											}

											_this.setState({
												workforms: [].concat(items),
												startWorkforms: [].concat(items),
												loading: count < data.workforms.length,
												options: data.options || {},
												type: data.type,
												elementId: data.elementId || null
											});
										}).catch(function (e) {
											console.log('ERROR 2', e);
										});
									} else {
										count = count + 1;
										items[idx] = _extends({}, _workform, {
											introduction: _workform.introduction || generalSettings && generalSettings[instructionTextKey] || (0, _actions.getSettingValue)(instructionTextKey) || '',
											buttonText: _workform.buttonText || generalSettings && generalSettings[_constants.SettingsKeys.GENERAL_BUTTON_CHECK_TEXT] || (0, _actions.getSettingValue)(_constants.SettingsKeys.GENERAL_BUTTON_CHECK_TEXT) || t('common.check-answer')
										});
										_this.setState({
											workforms: [].concat(items),
											startWorkforms: [].concat(items),
											loading: count < data.workforms.length,
											options: data.options || {},
											type: data.type,
											elementId: data.elementId || null
										});
									}
								}).catch(function (e) {});
							} else {
								items[idx] = item;
								count = count + 1;
								_this.setState(function (prevState) {
									return {
										workforms: [].concat(items),
										loading: count < data.workforms.length,
										options: data.options || {}
									};
								});
							}
						})(i);
					});
				}, _this.handlePercentage = function (workforms, elementId, ifFinished, withCheck) {
					var _this$state = _this.state,
					    _this$state$generalSe2 = _this$state.generalSettings,
					    generalSettings = _this$state$generalSe2 === undefined ? {} : _this$state$generalSe2,
					    type = _this$state.type;

					var percentage = 0;

					switch (type) {
						case 'BookCheck':
							percentage = generalSettings[_constants.SettingsKeys.BOOKCHECK_FINISHED_RULES] || '50';
							break;
						case 'Lesson':
							percentage = generalSettings[_constants.SettingsKeys.LESSON_FINISHED_RULES] || '0';
							break;
						default:
							percentage = '0';
					}

					var _savable = (0, _lib.savable)(workforms);

					var count = 0;
					var finished = 0;

					_savable.forEach(function (item) {
						count = item.correct ? ++count : count;
						finished = item.finished ? ++finished : finished;
					});

					var percentageDone = 100 * finished / _savable.length;
					var percentageDoneCorrect = 100 * count / _savable.length;
					var correct = percentageDoneCorrect >= percentage ? true : false;

					if (!ifFinished || finished >= _savable.length) {
						_this.handleSaveElement({
							elementId: elementId,
							correct: correct,
							finished: finished >= _savable.length,
							rating: correct,
							progress: percentageDone
						});
					}
				}, _this.handleLoadExtra = function (data) {
					if (_this.state.type == 'Lesson') {
						var split = _this.state.split;

						if (data.workform && data.workform.correctWorkforms && data.workform.correctWorkforms.length && data.correct && !split) {
							_this.setWorkform(data.workform.correctWorkforms, data.workform.id, data.elementId);
						}

						if (data.workform && data.workform.inCorrectWorkforms && data.workform.inCorrectWorkforms.length && !data.correct && !split) {
							_this.setWorkform(data.workform.inCorrectWorkforms, data.workform.id, data.elementId);
						}
					}
				}, _this.setWorkform = function (w, id, elementId) {
					var client = _this.props.client;

					var items = [];
					var workforms = void 0;
					var count = 0;

					w.forEach(function (item, i) {
						(function (idx) {
							if ((0, _queries.getQuery)(item.type)) {
								client.query({
									query: (0, _queries.getQuery)(item.type),
									variables: { id: item.id }
								}).then(function (result) {
									var _workform = result.data[(0, _queries.getPropName)(item.type)] || item;
									_this.getWorkformResult(_extends({}, _workform, {
										element: elementId
									})).then(function (result) {
										var answers = _this.merge(_workform, result);
										var workform = _extends({}, _workform, {
											correctWorkforms: item.correctWorkforms ? [].concat(_toConsumableArray(item.correctWorkforms)) : [],
											inCorrectWorkforms: item.inCorrectWorkforms ? [].concat(_toConsumableArray(item.inCorrectWorkforms)) : [],
											finished: result.data.workformResult && result.data.workformResult.finished,
											correct: result.data.workformResult && result.data.workformResult.statement
										});
										if (workform.answers) {
											workform.answers = answers;
										}
										items[idx] = workform;
										workforms = items;
										count = count + 1;
										if (count >= w.length) {
											_this.handleArrayMerge(workforms, id, elementId);
										}
									});
								});
							}
						})(i);
					});
				}, _this.handleArrayMerge = function (w, id, elementId) {
					var idx = 0;
					var _workforms = [].concat(_toConsumableArray(_this.state.workforms));
					_workforms && _workforms.forEach(function (e, i) {
						if (e.id == id) idx = i + 1;
					});
					_workforms.splice.apply(_workforms, [idx, 0].concat(_toConsumableArray(w)));

					_this.setState({
						split: true,
						workforms: [].concat(_toConsumableArray(_workforms))
					}, function () {
						_this.handlePercentage(_this.state.workforms, elementId);
					});
				}, _this.handleSaveWorkform = function (data) {
					var extraData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

					var boxId = _this.props.boxId;
					var userId = _this.props.appUser && _this.props.appUser.id;
					var elementId = data.elementId;
					var workformId = data.workform && data.workform.id;
					var programId = _this.props.program;
					var _data$ifFinished = data.ifFinished,
					    ifFinished = _data$ifFinished === undefined ? false : _data$ifFinished;

					var answers = data.workform && data.workform.answers ? data.workform.answers.map(function (i) {
						return {
							id: i.id,
							selected: i.selected
						};
					}) : [];

					if (userId && programId && boxId && elementId && workformId) {
						_this.setState({ saving: true });
						_this.props.onUpdate(_extends({
							answer: data.workform.answer || null,
							answers: [].concat(_toConsumableArray(answers)),
							user: userId,
							program: programId,
							box: boxId,
							element: elementId,
							workform: workformId,
							finished: true,
							statement: data.correct
						}, extraData)).then(function (result) {
							if (result.errors) {
								if (data.onReject && data.requestCounter) {
									data.onReject(data, data.requestCounter);
								} else if (data.onReject) {
									data.onReject(data);
								}
								return;
							}
							if (data.onFullfill) data.onFullfill();
							_this.setState({ saving: false });
							var idx = 0;
							_this.state.workforms.forEach(function (item, i) {
								if (item.id == data.workform.id) {
									idx = i;
								}
							});
							var _workforms = [].concat(_toConsumableArray(_this.state.workforms));
							_workforms[idx] = data.workform;
							_workforms[idx].done = true;
							_workforms[idx].finished = true;

							_this.setState({
								workforms: [].concat(_toConsumableArray(_workforms))
							}, function () {
								_this.handleLoadExtra(data);
								_this.handlePercentage(_this.state.workforms, elementId, ifFinished);
							});
						}).catch(function () {
							if (data.onReject && data.requestCounter) {
								data.onReject(data, data.requestCounter);
							} else if (data.onReject) {
								data.onReject(data);
							}
						});
					}
				}, _this.handleSetWorkformDone = function (data) {
					var workformId = data.workform && data.workform.id;

					if (workformId) {
						var idx = _this.state.workforms.findIndex(function (item) {
							return item.id === data.workform.id;
						});

						var _workforms = [].concat(_toConsumableArray(_this.state.workforms));
						_workforms[idx] = data.workform;
						_workforms[idx].done = true;
						_workforms[idx].finished = true;

						_this.setState({ workforms: _workforms });
					}
				}, _this.handleResetElement = function (id) {
					var url = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
					var setReset = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

					_this.setState({ hasReset: false }, function () {
						var boxId = _this.props.boxId;
						var userId = _this.props.appUser.id;
						var elementId = id;
						var programId = _this.props.program;

						if (userId && programId && boxId && elementId) {
							_this.setState({
								loading: true
							});
							_this.props.onDelete({
								user: userId,
								program: programId,
								box: boxId,
								element: elementId
							}).then(function (r) {
								if (setReset) {
									_this.handleLoadElementResult(id).then(function () {
										_this.setState({
											hasReset: true,
											loading: false
										});
									});
								} else {
									if (url) location.href = url;else location.reload();
								}
							});
						}
					});
				}, _this.handleSaveElement = function (data) {
					var client = _this.props.client;

					var boxId = _this.props.boxId;
					var userId = _this.props.superUser || _this.props.appUser.id;
					var elementId = data.elementId;
					var programId = _this.props.program;

					if (userId && programId && boxId && elementId) {
						return client.mutate({
							mutation: _graphql.GQL_CREATE_ELEMENT_RESULT,
							variables: {
								box: boxId,
								program: programId,
								user: userId,
								element: elementId,
								bestTime: data.count || null,
								rating: data.count || data.rating || null,
								progress: data.progress || null,
								finished: data.finished == undefined ? true : data.finished,
								correct: data.correct || null
							}
						});
					}
				}, _this.handleHasResettet = function () {
					_this.setState({ hasReset: false });
				}, _temp), _possibleConstructorReturn(_this, _ret);
			}

			_createClass(WithWorkforms, [{
				key: 'componentDidUpdate',
				value: function componentDidUpdate(prevProps, prevState) {
					var _this2 = this;

					if (prevState.loading && !this.state.loading && this.state.type == 'Lesson') {
						var _state = this.state,
						    workforms = _state.workforms,
						    elementId = _state.elementId;

						var _savable = (0, _lib.savable)(workforms);

						_savable.forEach(function (item) {
							if (item.finished) {
								_this2.handleLoadExtra({
									workform: _extends({}, item),
									correct: item.correct,
									elementId: elementId
								});
							}
						});
					}
					if (prevProps.SETTINGS.loading && !this.props.SETTINGS.loading) {
						var _props$SETTINGS = this.props.SETTINGS,
						    SETTINGS = _props$SETTINGS === undefined ? {} : _props$SETTINGS;
						var _SETTINGS$settings = SETTINGS.settings,
						    settings = _SETTINGS$settings === undefined ? {} : _SETTINGS$settings;


						if (settings && settings.settings && settings.settings.length) {
							var newSettings = {};
							settings.settings.forEach(function (setting) {
								if (setting.name == _constants.SettingsKeys.FILE) {
									newSettings[setting.name] = setting.file;
								} else {
									newSettings[setting.name] = setting.value;
								}
							});

							this.setState({
								generalSettings: _extends({}, newSettings)
							});
						}
					}
				}
			}, {
				key: 'render',
				value: function render() {
					var otherProps = _objectWithoutProperties(this.props, []);

					var _state2 = this.state,
					    loading = _state2.loading,
					    workforms = _state2.workforms,
					    options = _state2.options,
					    hasReset = _state2.hasReset,
					    elementResult = _state2.elementResult,
					    rawData = _state2.rawData,
					    generalSettings = _state2.generalSettings,
					    saving = _state2.saving;


					return _react2.default.createElement(WrappedComponent, _extends({}, otherProps, {
						workformsData: {
							loading: loading,
							saving: saving,
							workforms: workforms,
							generalSettings: generalSettings,
							options: options,
							rawData: rawData,
							elementResult: elementResult,
							hasReset: hasReset,
							loadWorkforms: this.handleLoadWorkforms,
							loadElementResult: this.handleLoadElementResult,
							saveWorkform: this.handleSaveWorkform,
							setWorkformDone: this.handleSetWorkformDone,
							resetElement: this.handleResetElement,
							saveElement: this.handleSaveElement,
							setHasResettet: this.handleHasResettet
						}
					}));
				}
			}]);

			return WithWorkforms;
		}(_react2.default.Component));
	};
};

exports.default = withWorkforms;