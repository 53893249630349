import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    button: {
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,0.08)',
        },
    },
});

class TableToolbarAction extends React.Component {
    render() {
        const { classes, icon, label, tooltip, key, onClick } = this.props;

        return (
            <Tooltip title={tooltip} key={key}>
                <IconButton
                    onClick={onClick}
                    color="inherit"
                    className={classes.button}
                >
                    {icon}
                </IconButton>
            </Tooltip>
        );
    }
}

export default withStyles(styles, { name: 'CoreTableToolbarAction' })(
    TableToolbarAction
);
