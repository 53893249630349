import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { SelectField, withForm } from '@manakin/core';
import { useQuery } from '@apollo/react-hooks';
import { GQL_FETCH_CURRENT_USER_PRODUCTS } from './graphql';
import ColophonCreditItem from './parts/ColophonCreditItem';
import Typography from '@material-ui/core/Typography';
import { getProgram } from '@manakin/app-core/ProgramsDropdown/selectors';
import { useSelector } from 'react-redux';
import BackIcon from '@material-ui/icons/ArrowBack';
import { IconButton, Toolbar } from '@material-ui/core';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => {
        const contrastColor = theme.palette.getContrastText(theme.manakin.indicationColor[600]);
        return createStyles({
            root: {
                backgroundColor: theme.manakin.indicationColor[500],
                height: '100vh',
                position: 'absolute',
                zIndex: 1100,
                overflow: 'auto',
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,
            },
            wrapper: {
                ...theme.manakin.defaultWrapper,
                paddingTop: '12rem',
                marginBottom: '3rem',
                opacity: 0,
                animation: 'showBackwards .3s ease 1.3s forwards',
                [theme.breakpoints.up('md')]: {
                    minHeight: '240px',
                    marginBottom: '8rem',
                },
            },
            chapterTitle: {
                color: contrastColor,
                marginTop: 24,
                marginBottom: 24
            },
            chapterItems: {
                display: 'grid',
                gridTemplateColumns: '1fr',
                [theme.breakpoints.up('sm')]: {
                    gridTemplateColumns: 'repeat(2, 1fr)',
                },
                [theme.breakpoints.up('md')]: {
                    gridTemplateColumns: 'repeat(3, 1fr)',
                },
            },
            select: {
                '& .MuiFormLabel-root': {
                    color: contrastColor,
                },
                '& .MuiTypography-root': {
                    color: contrastColor,
                },
                '& .MuiInput-underline:before': {
                    borderBottom: `1px solid ${contrastColor}`,
                    opacity: '0.42',
                },
                '& .MuiInput-underline:after': {
                    borderBottom: `2px solid ${contrastColor}`,
                },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                    borderBottom: `2px solid ${contrastColor}`,
                    opacity: '0.87',
                },
            },
            toolBarRoot: {
                display: 'flex',
                height: '10rem',
                paddingLeft: '2.4rem',
                flexFlow: 'row wrap',
                alignItems: 'center',
                position: 'fixed',
                background: theme.manakin.indicationColor[500],
                width: '100%',
                zIndex: 1,
            },
            backButtonRoot: {
                border: `1px solid ${contrastColor}`,
                color: contrastColor,
                borderRadius: 0,
                backgroundColor: 'transparent',
                padding: '15px',
            },
            menuText: {
                fontFamily: theme.manakin.defaultContentFont,
                color: contrastColor,
                cursor: 'pointer',
                fontSize: '1.8rem',
                lineHeight: '2.4rem',
                marginLeft: '1.6rem',
                fontWeight: '500',
            },
        });
    },
);

const Colophon = (props) => {
    const { form } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const [ product, setProduct ] = useState();
    const [ products, setProducts ] = useState([]);
    const program = useSelector((state) => getProgram(state));

    const { loading } = useQuery(GQL_FETCH_CURRENT_USER_PRODUCTS, {
        onCompleted: (data) => {
            if (data && data.currentAppUser && data.currentAppUser.licenses) {
                let products = [];
                data.currentAppUser.licenses.forEach(license => {
                    if (!products.find(product => product.id == license.product.id)) {
                        products.push(license.product);
                    }
                });

                setProducts(products);

                if (products.length === 1) {
                    // Only 1 product, auto select
                    setProduct(products[0]);
                } else if (products && products.length > 1) {
                    // Get the product from the current program
                    const foundProduct = products.find(product => product.program && product.program.id == program);
                    if (foundProduct) setProduct(foundProduct);
                }
            }
        },
    });

    const handleProductChange = (selectedProduct) => {
        const foundProduct = products.find(product => product.id == selectedProduct.value);
        if (foundProduct) setProduct(foundProduct);
    };

    return (
        <div className={classes.root}>
            <Toolbar classes={{ root: classes.toolBarRoot }}>
                <IconButton
                    classes={{ root: classNames(classes.backButtonRoot) }}
                    onClick={history.goBack}>
                    <BackIcon />
                </IconButton>
                <span
                    className={classNames(classes.menuText)}
                    onClick={history.goBack}>
						{t('common.back')}
					</span>
            </Toolbar>

            <div className={classes.wrapper}>
                {products.length > 1 && (
                    <SelectField
                        options={products}
                        loading={loading}
                        className={classes.select}
                        name='product'
                        initialValue={product}
                        onChange={handleProductChange}
                        label={t('app.colophon.fields.colophon.label')}
                        form={form}
                    />
                )}

                {(product && product.colophon && product.colophon.chapters) ? (
                    <React.Fragment>
                        {product.colophon.chapters.map(chapter => (
                            <div key={chapter.id} className={classes.chapter}>
                                <Typography
                                    classes={{ h3: classes.chapterTitle }}
                                    component='h3'
                                    variant='h3'>
                                    {chapter.title}
                                </Typography>
                                <div className={classes.chapterItems}>
                                    {chapter.credits && (chapter.credits).map(credit => (
                                        <ColophonCreditItem key={credit.id} credit={credit} />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </React.Fragment>
                ) : (
                    <Typography
                        classes={{ h3: classes.chapterTitle }}
                        component='h3'
                        variant='h3'
                    >
                        {t('app.colophon.no-colophon-found')}
                    </Typography>
                )}
            </div>
        </div>
    );
};

export default withForm({})(Colophon);
