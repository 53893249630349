import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Typography } from "@material-ui/core";
import { useParams } from "react-router";
import { GQL_ACCEPT_INVITATION } from "./graphql";
import { useStyles } from "./styles";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { Loader } from "@manakin/app-core";
import {useTranslation} from 'react-i18next';

const InvitationSucces = (props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [mutate, { loading }] = useMutation(GQL_ACCEPT_INVITATION);
	const params = useParams();
	const [text, setText] = useState(t("authentication.invitation.success.invite-accepted"));

	//functions
	const handleText = (r) => {
		if (r.errors && r.errors.length) {
			let message = t("authentication.invitation.success.invite-failed");
			r.errors.forEach((e) => {
				if (e.message.indexOf("Error: invitation already accepted for token") !== -1) {
					message = t("authentication.invitation.success.failed-already-exists");
				}
			});
			setText(message);
		}
	};

	const handleMutation = () => {
		mutate({
			variables: {
				input: {
					token: params.token,
				},
			},
		})
			.then((r) => {
				handleText(r);
			})
			.catch((e) => {
				setText(t("authentication.invitation.success.invite-failed"));
			});
	};

	useEffect(() => {
		if (params && params.token) {
			handleMutation();
		}
	}, []);

	if (loading) return <Loader fullScreen />;
	return (
		<div className={classes.root}>
			<div className={classes.container}>
				<Typography className={classes.heading} variant="h2">
					{t("authentication.invitation.success.title")}
				</Typography>
				<Typography
					classes={{ body1: classes.paragraph }}
					variant="body1"
					gutterBottom
				>
					{t("authentication.invitation.success.thanks-note", { whitelabel: t("common.whitelabel") })}
				</Typography>
			</div>
			<div className={classes.container}>
				<Typography variant="h5">
					{t("authentication.invitation.success.status")}
				</Typography>
				<Typography>{text}</Typography>
			</div>
			<Link to={"/"}>
				<Button
					variant="contained"
					classes={{ root: classes.buttonRoot }}
					color="primary"
				>
					{t("common.continue")}
				</Button>
			</Link>
		</div>
	);
};

export default InvitationSucces;
