'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _AccountWrapper = require('../AccountWrapper');

var _AccountWrapper2 = _interopRequireDefault(_AccountWrapper);

var _core = require('@manakin/core');

var _appCore = require('@manakin/app-core');

var _recompose = require('recompose');

var _styles = require('@material-ui/core/styles');

var _Grid = require('@material-ui/core/Grid');

var _Grid2 = _interopRequireDefault(_Grid);

var _Button = require('@material-ui/core/Button');

var _Button2 = _interopRequireDefault(_Button);

var _IconButton = require('@material-ui/core/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _ProfilePicture = require('./parts/ProfilePicture');

var _ProfilePicture2 = _interopRequireDefault(_ProfilePicture);

var _graphql = require('../graphql');

var _reactApollo = require('react-apollo');

var _reactRedux = require('react-redux');

var _actions = require('@manakin/app-core/actions');

var _Edit = require('@manakin/core/icons/Edit');

var _Edit2 = _interopRequireDefault(_Edit);

var _reduxFirstHistory = require('redux-first-history');

var _selectors = require('@manakin/authentication/selectors');

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _Dialog = require('@material-ui/core/Dialog');

var _Dialog2 = _interopRequireDefault(_Dialog);

var _actions2 = require('@manakin/authentication/actions');

var _styles2 = require('./styles');

var _actions3 = require('@manakin/core/actions');

var _jsCookie = require('js-cookie');

var _jsCookie2 = _interopRequireDefault(_jsCookie);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _reactHooks = require('@apollo/react-hooks');

var _hooks = require('@manakin/hooks');

var _constants = require('@manakin/core/lib/constants');

var _reactI18next = require('react-i18next');

var _lib = require('@manakin/app-core/lib');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var REGISTRATION_GENDER_ENABLED = _constants.SettingsKeys.REGISTRATION_GENDER_ENABLED,
    REGISTRATION_GENDER_REQUIRED = _constants.SettingsKeys.REGISTRATION_GENDER_REQUIRED,
    REGISTRATION_BIRTH_DATE_ENABLED = _constants.SettingsKeys.REGISTRATION_BIRTH_DATE_ENABLED,
    REGISTRATION_BIRTH_DATE_REQUIRED = _constants.SettingsKeys.REGISTRATION_BIRTH_DATE_REQUIRED,
    REGISTRATION_FUNCTION_ENABLED = _constants.SettingsKeys.REGISTRATION_FUNCTION_ENABLED,
    REGISTRATION_FUNCTION_REQUIRED = _constants.SettingsKeys.REGISTRATION_FUNCTION_REQUIRED,
    REGISTRATION_EDUCATION_ORGANISATION_ENABLED = _constants.SettingsKeys.REGISTRATION_EDUCATION_ORGANISATION_ENABLED,
    REGISTRATION_EDUCATION_ORGANISATION_REQUIRED = _constants.SettingsKeys.REGISTRATION_EDUCATION_ORGANISATION_REQUIRED;


var formBundle = {
	password: {
		required: true
	},
	firstName: {
		required: true
	},
	lastName: {
		required: true
	}
};

var timer = null;

var Account = function Account(props) {
	var onSetAppBarColor = props.onSetAppBarColor,
	    location = props.location,
	    goToChangePasswordPage = props.goToChangePasswordPage,
	    form = props.form,
	    data = props.data,
	    classes = props.classes,
	    APP_USER = props.APP_USER,
	    _props$LOCALES = props.LOCALES,
	    LOCALES = _props$LOCALES === undefined ? {} : _props$LOCALES,
	    _props$config = props.config,
	    config = _props$config === undefined ? {} : _props$config;

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t,
	    i18n = _useTranslation.i18n;

	var localesLoading = LOCALES.loading,
	    _LOCALES$locales = LOCALES.locales,
	    locales = _LOCALES$locales === undefined ? {} : _LOCALES$locales;
	var loading = APP_USER.loading,
	    _APP_USER$appUser = APP_USER.appUser,
	    currentAppUser = _APP_USER$appUser === undefined ? {} : _APP_USER$appUser;
	var _currentAppUser$userD = currentAppUser.userDataCbd,
	    userDataCbd = _currentAppUser$userD === undefined ? {} : _currentAppUser$userD;

	var fieldProps = { xs: 12, sm: 6, md: 6, lg: 6, xl: 6 };
	var snackBar = (0, _hooks.useSnackBar)();
	var _config$pages = config.pages,
	    pages = _config$pages === undefined ? {} : _config$pages,
	    _config$general = config.general,
	    general = _config$general === undefined ? {} : _config$general,
	    _config$customColors = config.customColors,
	    customColors = _config$customColors === undefined ? {} : _config$customColors,
	    _config$account = config.account,
	    account = _config$account === undefined ? {} : _config$account;
	var _pages$appAccount = pages.appAccount,
	    accountConfig = _pages$appAccount === undefined ? {} : _pages$appAccount;

	var _ref = account || {},
	    _ref$showBPVBRapport = _ref.showBPVBRapport,
	    showBPVBRapport = _ref$showBPVBRapport === undefined ? false : _ref$showBPVBRapport;

	var userFunction = userDataCbd && userDataCbd.function ? userDataCbd.function === 'Assistent drogist' ? 'Assistent Drogist' : 'Drogist' : '';
	var locOptions = [];

	var _useState = (0, _react.useState)(false),
	    _useState2 = _slicedToArray(_useState, 2),
	    readOnly = _useState2[0],
	    setReadOnly = _useState2[1];

	var editName = readOnly === false ? general.editNameReadOnly || false : readOnly;

	locales.locales && locales.locales.map(function (item) {
		if (item.enabled) {
			locOptions.push({
				id: item.code,
				label: item.title
			});
		}
	});

	var otherFunction = userFunction === 'Drogist' ? 'Assistent Drogist' : 'Drogist';

	//config variables
	var hideSchool = accountConfig.hideSchool;
	var hideSchoolClass = accountConfig.hideSchoolClass;
	var hideOrganisation = accountConfig && accountConfig.hideOrganisation;
	var hideFunction = accountConfig && accountConfig.hideFunction;
	var hideDelete = accountConfig && accountConfig.hideDelete;
	var switchFunction = accountConfig && accountConfig.switchFunction;
	var isBpv = (0, _lib.userHasAnyRole)(currentAppUser, _lib.bpvRole);
	var isStudent = (0, _lib.userHasAnyRole)(currentAppUser, _lib.studentRoles);

	//state hooks

	var _useState3 = (0, _react.useState)(false),
	    _useState4 = _slicedToArray(_useState3, 2),
	    open = _useState4[0],
	    setOpen = _useState4[1];

	var _useState5 = (0, _react.useState)([]),
	    _useState6 = _slicedToArray(_useState5, 2),
	    schools = _useState6[0],
	    setSchools = _useState6[1];

	var _useState7 = (0, _react.useState)([]),
	    _useState8 = _slicedToArray(_useState7, 2),
	    organisations = _useState8[0],
	    setOrganisations = _useState8[1];

	var _useState9 = (0, _react.useState)(false),
	    _useState10 = _slicedToArray(_useState9, 2),
	    openFunction = _useState10[0],
	    setOpenFunction = _useState10[1];

	var _useState11 = (0, _react.useState)(false),
	    _useState12 = _slicedToArray(_useState11, 2),
	    openDepartment = _useState12[0],
	    setOpenDepartment = _useState12[1];

	var _useState13 = (0, _react.useState)([]),
	    _useState14 = _slicedToArray(_useState13, 2),
	    options = _useState14[0],
	    setOptions = _useState14[1];

	var _useState15 = (0, _react.useState)(null),
	    _useState16 = _slicedToArray(_useState15, 2),
	    selectedOrganisation = _useState16[0],
	    setSelectedOrganisation = _useState16[1];

	var _useQuery = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_SETTINGS, {
		onCompleted: function onCompleted(data) {
			if (data && data.settings && data.settings.settings) {
				var settings = data.settings.settings.reduce(function (obj, item) {
					return Object.assign(obj, _defineProperty({}, item.name, item));
				}, {});

				changeFormRequired(settings, 'gender', REGISTRATION_GENDER_ENABLED, REGISTRATION_GENDER_REQUIRED);
				changeFormRequired(settings, 'dateOfBirth', REGISTRATION_BIRTH_DATE_ENABLED, REGISTRATION_BIRTH_DATE_REQUIRED);

				if (isBpv) {
					changeFormRequired(settings, 'function', REGISTRATION_FUNCTION_ENABLED, REGISTRATION_FUNCTION_REQUIRED);
					changeFormRequired(settings, 'educationOrganisation', REGISTRATION_EDUCATION_ORGANISATION_ENABLED, REGISTRATION_EDUCATION_ORGANISATION_REQUIRED);
				}
			}
		}
	}),
	    _useQuery$data = _useQuery.data,
	    settingsData = _useQuery$data === undefined ? {} : _useQuery$data;

	(0, _reactHooks.useQuery)(_graphql.GQL_FETCH_HAS_FINAL_RATING_REQUESTS, {
		skip: !isStudent || !showBPVBRapport,
		onCompleted: function onCompleted(data) {
			if (data && data.ratingRequests && data.ratingRequests.length > 0) {
				// User has a final rating request, forbid any profile changes
				setReadOnly(true);
			}
		}
	});

	var changeFormRequired = function changeFormRequired(settings, name, enabledKey, settingKey) {
		// If not enabled, default to not required
		var required = settings[enabledKey] && settings[enabledKey].value === 'true' ? settings[settingKey].value === 'true' : false;
		form.onSchemaChange(name, _extends({}, form.schema[name], { required: required }));
	};

	var registrationGenderEnabled = (0, _hooks.useBooleanSetting)(settingsData.settings, REGISTRATION_GENDER_ENABLED);
	var registrationBirthDateEnabled = (0, _hooks.useBooleanSetting)(settingsData.settings, REGISTRATION_BIRTH_DATE_ENABLED);
	var registrationFunctionEnabled = (0, _hooks.useBooleanSetting)(settingsData.settings, REGISTRATION_FUNCTION_ENABLED);
	var registrationEducationOrganisationEnabled = (0, _hooks.useBooleanSetting)(settingsData.settings, REGISTRATION_EDUCATION_ORGANISATION_ENABLED);

	(0, _react.useEffect)(function () {
		onSetAppBarColor({
			backgroundColor: customColors.accountAppBar || 'transparent',
			color: customColors.accountAppBarColor || 'secondary',
			path: location.pathname
		});
	}, []);

	(0, _react.useEffect)(function () {
		if (!data.loading) {
			if (data.groups && data.groups.groups) {
				var arr = data.groups.groups.filter(function (item) {
					return item.enabled;
				});
				setOptions(arr);
			}
		}
	}, [data.loading]);

	(0, _react.useEffect)(function () {
		if (!loading) {
			var _schools = [];
			var _organisations = [];
			currentAppUser.schoolClasses && currentAppUser.schoolClasses.forEach(function (item) {
				if (item.schools.length) _schools = [].concat(_toConsumableArray(_schools), _toConsumableArray(item.schools.map(function (s) {
					return s.name;
				})));
				item.schools.forEach(function (school) {
					if (school.organisations && school.organisations.length) {
						_organisations = school.organisations;
					}
				});
			});
			setSchools(_schools);

			if (currentAppUser.schoolClasses && !currentAppUser.schoolClasses.length && currentAppUser.school) {
				if (currentAppUser.school.organisations && currentAppUser.school.organisations.length) {
					_organisations = currentAppUser.school.organisations;
				}
			}

			setOrganisations(_organisations);
		}
	}, [loading, currentAppUser]);

	(0, _react.useEffect)(function () {
		var _form$fields = form.fields,
		    fields = _form$fields === undefined ? {} : _form$fields;

		if (currentAppUser) {
			var fieldsToCheck = ['firstName', 'lastName', 'middleName', 'gender', 'dateOfBirth', 'function', 'educationOrganisation'];
			if (fieldsToCheck.some(function (key) {
				return fields[key] && currentAppUser[key] != fields[key];
			})) {
				// One of the given fields' value has changed, save
				handleSave();
			}
		}
	}, [form.fields, currentAppUser]);

	//functions
	var handleSave = function handleSave() {
		clearTimeout(timer);
		timer = setTimeout(function () {
			form.onSubmit().then(function (data) {
				if (data) {
					props.onUpdate({
						id: currentAppUser.id,
						firstName: data.firstName,
						lastName: data.lastName || '',
						middleName: data.middleName || '',
						gender: data.gender || 'UNKNOWN',
						dateOfBirth: data.dateOfBirth || '',
						function: data.function || '',
						educationOrganisation: data.educationOrganisation || ''
					});
				}
			});
		}, 800);
	};

	var handleChangePassword = function handleChangePassword() {
		goToChangePasswordPage();
	};

	var openConfirmationDialog = function openConfirmationDialog() {
		setOpen(true);
	};

	var closeConfirmationDialog = function closeConfirmationDialog() {
		setOpen(false);
	};

	var handleClick = function handleClick() {
		setOpenFunction(true);
	};

	var closeFunction = function closeFunction() {
		setOpenFunction(false);
	};

	var handleDepartmentClick = function handleDepartmentClick() {
		setOpenDepartment(true);
	};

	var closeDepartment = function closeDepartment() {
		setOpenDepartment(false);
	};

	var handleNewsLetterChange = function handleNewsLetterChange(event) {
		props.onUpdateNewsLetter({
			id: currentAppUser.id,
			newsLetter: event.target.checked
		});
	};

	var handleAppUserDelete = function handleAppUserDelete() {
		form.onSubmit().then(function (formData) {
			if (form.fields && form.fields.password) {
				props.client.mutate({
					mutation: _graphql.GQL_DELETE_USER,
					variables: {
						id: props.appUser.id,
						password: formData.password,
						sendVerifyMail: true
					}
				}).then(function (result) {
					if (!result.errors) {
						props.onLogout();
					} else {
						snackBar.error(t('app.account.details.delete-account-dialog.wrong-password'));
					}
				});
			}
		});
	};

	var handleFunctionChange = function handleFunctionChange() {
		if (currentAppUser && currentAppUser.id) {
			props.changeFunction({ id: currentAppUser.id }).then(function (result) {
				if (!result.errors) {
					props.onChangeFunctionSucces(t);
				} else {
					props.onChangeFunctionFailed(t);
				}

				setOpenFunction(false);
			}).catch(function (e) {
				props.onChangeFunctionFailed(t);
				setOpenFunction(false);
			});
		}
	};

	var handleOrganisationChange = function handleOrganisationChange(data) {
		if (data.value != selectedOrganisation) {
			setSelectedOrganisation(data.value || null);
		}
	};

	var handleDepartmentChange = function handleDepartmentChange() {
		var _form$fields2 = form.fields,
		    fields = _form$fields2 === undefined ? {} : _form$fields2;


		if (!fields.affiliate) {
			props.onChangeDepartmentIncomplete(t);
		} else {
			if (currentAppUser && currentAppUser.id) {
				props.changeDepartment({
					id: currentAppUser.id,
					group: fields.affiliate
				}).then(function (result) {
					if (!result.errors) {
						props.onChangeFunctionSucces(t);
					} else {
						props.onChangeFunctionFailed(t);
					}
					closeDepartment();
				}).catch(function (e) {
					props.onChangeFunctionFailed(t);
					closeDepartment();
				});
			}
		}
	};

	var handleChangeLanguage = function handleChangeLanguage(selectedLanguage) {
		if (selectedLanguage && selectedLanguage.value) {
			var newLocale = selectedLanguage.value;
			_jsCookie2.default.set('locale', newLocale);
			i18n.changeLanguage(newLocale);
			props.onUpdate({ id: currentAppUser.id, locale: newLocale });
		}
	};

	if (loading) {
		return _react2.default.createElement(_appCore.Loader, { fullScreen: true });
	}
	return _react2.default.createElement(
		'div',
		null,
		_react2.default.createElement(
			_AccountWrapper2.default,
			{ title: t('app.account.details.title'), introduction: t('app.account.details.intro') },
			_react2.default.createElement(
				_appCore.ContentHeader,
				{ title: t('app.account.details.profile-header') },
				_react2.default.createElement(_ProfilePicture2.default, { profilePicture: currentAppUser.profilePicture, user: props.appUser }),
				_react2.default.createElement(
					_Grid2.default,
					{
						container: true,
						spacing: 2,
						alignItems: 'center',
						classes: {
							container: classes.profileFields
						}
					},
					accountConfig && accountConfig.showInitials && _react2.default.createElement(
						_Grid2.default,
						_extends({ item: true }, fieldProps),
						_react2.default.createElement(_core.TextField, {
							name: 'initials',
							label: t('common.fields.initials.label'),
							readOnly: true,
							loading: false,
							initialValue: currentAppUser.initials || '',
							form: form
						})
					),
					_react2.default.createElement(
						_Grid2.default,
						_extends({ item: true }, fieldProps),
						_react2.default.createElement(_core.TextField, {
							name: 'firstName',
							label: t('common.fields.first-name.label'),
							readOnly: editName,
							loading: false,
							initialValue: currentAppUser.firstName || '',
							form: form,
							inputProps: {
								maxLength: 35
							}
						})
					),
					_react2.default.createElement(
						_Grid2.default,
						_extends({ item: true }, fieldProps),
						_react2.default.createElement(_core.TextField, {
							name: 'middleName',
							label: t('common.fields.middle-name.label'),
							readOnly: editName,
							loading: false,
							initialValue: currentAppUser.middleName || '',
							form: form,
							inputProps: {
								maxLength: 35
							}
						})
					),
					_react2.default.createElement(
						_Grid2.default,
						_extends({ item: true }, fieldProps),
						_react2.default.createElement(_core.TextField, {
							name: 'lastName',
							label: t('common.fields.last-name.label'),
							readOnly: editName,
							loading: false,
							initialValue: currentAppUser.lastName || '',
							form: form,
							inputProps: {
								maxLength: 35
							}
						})
					),
					_react2.default.createElement(
						_Grid2.default,
						_extends({ item: true }, fieldProps),
						_react2.default.createElement(_core.TextField, {
							name: 'email',
							label: t('common.fields.email-address.label'),
							readOnly: true,
							loading: false,
							initialValue: currentAppUser.email || '',
							form: form
						})
					),
					registrationGenderEnabled && _react2.default.createElement(
						_Grid2.default,
						_extends({ item: true }, fieldProps),
						_react2.default.createElement(_core.RadioField, {
							form: form,
							name: 'gender',
							readOnly: readOnly,
							legendName: t('common.fields.gender.label'),
							color: 'primary',
							options: [{
								label: t('common.fields.gender-types.male'),
								value: 'MALE'
							}, {
								label: t('common.fields.gender-types.female'),
								value: 'FEMALE'
							}, {
								label: t('common.fields.gender-types.unknown'),
								value: 'UNKNOWN'
							}],
							initialValue: currentAppUser.gender || ''
						})
					),
					registrationBirthDateEnabled && _react2.default.createElement(
						_Grid2.default,
						_extends({ item: true }, fieldProps),
						_react2.default.createElement(_core.DatePicker, {
							form: form,
							keyboardOnly: true,
							readOnly: readOnly,
							name: 'dateOfBirth',
							label: t('common.fields.birthdate.label'),
							initialValue: currentAppUser.dateOfBirth,
							defaultValue: '2000-01-01',
							maxDate: (0, _moment2.default)().format('YYYY-MM-DD')
						})
					),
					accountConfig && !accountConfig.hidePassword && _react2.default.createElement(
						_Grid2.default,
						_extends({ item: true }, fieldProps),
						_react2.default.createElement(
							'div',
							{ className: classes.changePasswordRoot },
							_react2.default.createElement(
								'div',
								{ className: classes.changePasswordInput },
								_react2.default.createElement(_core.TextField, {
									name: 'password',
									label: t('common.fields.password.label'),
									readOnly: true,
									loading: false,
									initialValue: '********',
									form: form
								})
							),
							_react2.default.createElement(
								_IconButton2.default,
								{
									onClick: handleChangePassword,
									classes: {
										root: classes.changePasswordIcon
									}
								},
								_react2.default.createElement(_Edit2.default, null)
							)
						)
					),
					readOnly && _react2.default.createElement(_appCore.WarningBox, {
						className: classes.readOnlyWarning,
						message: t("app.account.details.warning-read-only") }),
					isBpv && registrationFunctionEnabled && _react2.default.createElement(
						_Grid2.default,
						_extends({ item: true }, fieldProps),
						_react2.default.createElement(_core.TextField, {
							name: 'function',
							label: t('common.fields.function.label'),
							initialValue: currentAppUser.function || '',
							form: form
						})
					),
					isBpv && registrationEducationOrganisationEnabled && _react2.default.createElement(
						_Grid2.default,
						_extends({ item: true }, fieldProps),
						_react2.default.createElement(_core.TextField, {
							name: 'educationOrganisation',
							label: t('common.fields.education-organisation.label'),
							initialValue: currentAppUser.educationOrganisation || '',
							form: form
						})
					),
					accountConfig && accountConfig.showLanguageSelect && _react2.default.createElement(
						_Grid2.default,
						_extends({ item: true }, fieldProps),
						_react2.default.createElement(
							'div',
							{ className: classes.selectFieldRoot },
							_react2.default.createElement(_core.SelectField, {
								loading: loading || localesLoading,
								options: locOptions,
								name: 'locale',
								onChange: handleChangeLanguage,
								label: t('common.fields.locale.label'),
								initialValue: currentAppUser.locale ? {
									id: currentAppUser.locale.code,
									label: currentAppUser.locale.title
								} : {
									id: '',
									label: t('common.languages.english')
								},
								form: form
							})
						)
					)
				),
				accountConfig && accountConfig.showNewsLetter && _react2.default.createElement(
					_Grid2.default,
					{ container: true, spacing: 9, alignItems: 'center' },
					_react2.default.createElement(
						_Grid2.default,
						{ item: true, xs: 12 },
						_react2.default.createElement(_core.CheckboxField, {
							className: classes.checkbox,
							name: 'newsLetter',
							label: t('common.fields.newsletter.label'),
							initialValue: currentAppUser.newsLetter || false,
							loading: loading,
							form: form,
							onChange: handleNewsLetterChange
						})
					)
				)
			),
			accountConfig && accountConfig.showEducation && _react2.default.createElement(
				_appCore.ContentHeader,
				{ title: t('app.account.details.education-header') },
				_react2.default.createElement(
					_Grid2.default,
					{ container: true, spacing: 2, alignItems: 'center' },
					!hideSchool && _react2.default.createElement(
						_Grid2.default,
						_extends({ item: true }, fieldProps),
						_react2.default.createElement(_core.TextField, {
							name: 'school',
							label: t('common.fields.school.label'),
							readOnly: true,
							loading: false,
							initialValue: schools.length && schools.join(',') || currentAppUser.school && currentAppUser.school.name || '',
							form: form
						})
					),
					!hideOrganisation && _react2.default.createElement(
						_Grid2.default,
						_extends({ item: true }, fieldProps),
						_react2.default.createElement(_core.TextField, {
							name: 'organisations',
							label: t('common.fields.organisation.label'),
							readOnly: true,
							loading: false,
							initialValue: organisations && organisations.map(function (x) {
								return x.name;
							}).join(',') || '',
							form: form
						})
					),
					!hideSchoolClass && _react2.default.createElement(
						_appCore.AccessControl,
						{ not: _lib.schoolManagerAndTeacherRoles },
						_react2.default.createElement(
							_Grid2.default,
							_extends({ item: true }, fieldProps),
							_react2.default.createElement(_core.TextField, {
								name: 'schoolClass',
								label: t('common.fields.school-class.label'),
								readOnly: true,
								loading: false,
								initialValue: currentAppUser.schoolClasses && currentAppUser.schoolClasses.map(function (x) {
									return x.name;
								}).join(',') || '',
								form: form
							})
						)
					),
					!hideFunction && _react2.default.createElement(
						_Grid2.default,
						_extends({ item: true }, fieldProps),
						_react2.default.createElement(_core.TextField, {
							name: 'userFunction',
							label: t('common.fields.function.label'),
							readOnly: true,
							loading: false,
							initialValue: userFunction || '',
							form: form
						})
					)
				)
			),
			!hideDelete && _react2.default.createElement(
				_react2.default.Fragment,
				null,
				_react2.default.createElement(_Button2.default, {
					className: classes.deleteButton,
					children: t('app.account.details.delete-account'),
					onClick: openConfirmationDialog
				}),
				_react2.default.createElement(
					_Dialog2.default,
					{ open: open, onClose: closeConfirmationDialog, PaperProps: { className: classes.dialogPaper } },
					_react2.default.createElement(
						_Typography2.default,
						{ variant: 'h3', className: classes.dialogTitle },
						t('app.account.details.delete-account-dialog.title')
					),
					_react2.default.createElement(_core.PasswordField, {
						name: 'password',
						label: t('app.account.details.delete-account-dialog.password-label'),
						shrink: true,
						placeholder: t('app.account.details.delete-account-dialog.password-placeholder'),
						form: form
					}),
					_react2.default.createElement(
						'div',
						{ className: classes.dialogActions },
						_react2.default.createElement(
							_Button2.default,
							{ variant: 'contained', className: classes.confirmDeleteButton, onClick: handleAppUserDelete },
							t('common.yes')
						),
						_react2.default.createElement(
							_Button2.default,
							{ variant: 'contained', className: classes.cancelDeleteButton, onClick: closeConfirmationDialog },
							t('common.cancel')
						)
					)
				)
			),
			switchFunction && _react2.default.createElement(
				_react2.default.Fragment,
				null,
				_react2.default.createElement(
					'div',
					{ className: classes.buttonBundle },
					_react2.default.createElement(
						_Button2.default,
						{
							onClick: handleClick,
							color: 'primary',
							variant: 'contained',
							className: classes.mainButtonStyle
						},
						t("app.account.details.function-switch.request-switch-action")
					),
					_react2.default.createElement(
						_Button2.default,
						{
							onClick: handleDepartmentClick,
							color: 'primary',
							variant: 'contained',
							className: classes.mainButtonStyle
						},
						t("app.account.details.branch-switch.request-switch-action")
					)
				),
				_react2.default.createElement(
					_Dialog2.default,
					{
						open: openDepartment,
						onClose: closeDepartment,
						PaperProps: { className: classes.dialogPaper }
					},
					_react2.default.createElement(
						_Typography2.default,
						{ variant: 'h3', className: classes.dialogTitle },
						t("app.account.details.branch-switch.dialog.title")
					),
					_react2.default.createElement(
						_Typography2.default,
						{ variant: 'body1', className: classes.dialogContent },
						t("app.account.details.branch-switch.dialog.description")
					),
					_react2.default.createElement(
						'div',
						{ className: classes.organisationFields },
						_react2.default.createElement(_core.SelectField, {
							options: options,
							name: 'mainOrganisation',
							label: t("app.account.details.branch-switch.dialog.choose-organisation"),
							form: form,
							initialValue: { id: form.fields && form.fields.mainOrganisation },
							onChange: handleOrganisationChange,
							alpha: true
						}),
						selectedOrganisation && _react2.default.createElement(_appCore.SchoolclassFromMainOrganisation, { id: selectedOrganisation, form: form })
					),
					_react2.default.createElement(
						'div',
						{ className: classes.dialogActions },
						_react2.default.createElement(
							_Button2.default,
							{ color: 'primary', variant: 'contained', onClick: handleDepartmentChange },
							t("common.yes")
						),
						_react2.default.createElement(
							_Button2.default,
							{ variant: 'contained', className: classes.cancelDeleteButton, onClick: closeDepartment },
							t("common.cancel")
						)
					)
				),
				_react2.default.createElement(
					_Dialog2.default,
					{ open: openFunction, onClose: closeFunction, PaperProps: { className: classes.dialogPaper } },
					_react2.default.createElement(
						_Typography2.default,
						{ variant: 'h3', className: classes.dialogTitle },
						t("app.account.details.function-switch.dialog.title")
					),
					_react2.default.createElement(
						_Typography2.default,
						{ variant: 'body1', className: classes.dialogContent },
						_react2.default.createElement(_reactI18next.Trans, { i18nKey: "app.account.details.function-switch.dialog.description", userFunction: userFunction, otherFunction: otherFunction })
					),
					_react2.default.createElement(
						'div',
						{ className: classes.dialogActions },
						_react2.default.createElement(
							_Button2.default,
							{ color: 'primary', variant: 'contained', onClick: handleFunctionChange },
							t("common.yes")
						),
						_react2.default.createElement(
							_Button2.default,
							{ variant: 'contained', className: classes.cancelDeleteButton, onClick: closeFunction },
							t("common.cancel")
						)
					)
				)
			)
		)
	);
};

exports.default = (0, _recompose.compose)((0, _styles.withStyles)(_styles2.styles, { name: 'AppAccount' }), (0, _core.withForm)(formBundle), _reactApollo.withApollo, (0, _reactI18next.withTranslation)(), (0, _reactRedux.connect)(function (state) {
	return {
		config: state.config,
		appUser: (0, _selectors.getAppUser)(state)
	};
}, function (dispatch) {
	return {
		onSetAppBarColor: function onSetAppBarColor(color) {
			return dispatch((0, _actions.setAppBarBackgroundColor)(color));
		},
		onSetAppBarDropDown: function onSetAppBarDropDown(dropDown) {
			return dispatch((0, _actions.setAppBarDropDown)(dropDown));
		},
		goToChangePasswordPage: function goToChangePasswordPage() {
			return dispatch((0, _reduxFirstHistory.push)('/account/change-password'));
		},
		onLogin: function onLogin(user) {
			dispatch((0, _actions2.login)('/account', user));
		},
		onLogout: function onLogout() {
			return dispatch((0, _actions2.logout)());
		},
		onChangeDepartmentIncomplete: function onChangeDepartmentIncomplete(t) {
			return dispatch((0, _actions3.showSnackbarMessage)({
				text: t('app.account.details.change-department-failed'),
				variant: 'error'
			}));
		},
		onChangeFunctionSucces: function onChangeFunctionSucces(t) {
			return dispatch((0, _actions3.showSnackbarMessage)({
				text: t('app.account.details.change-function-success'),
				variant: 'success'
			}));
		},
		onChangeFunctionFailed: function onChangeFunctionFailed(t) {
			return dispatch((0, _actions3.showSnackbarMessage)({
				text: t('app.account.details.change-function-failed'),
				variant: 'error'
			}));
		},
		processUpdateSuccess: function processUpdateSuccess(t) {
			dispatch((0, _actions3.showSnackbarMessage)({
				text: t('app.account.details.save-success'),
				variant: 'success'
			}));
			// dispatch(push("/"));
		},
		processUpdateSuccessNewsLetter: function processUpdateSuccessNewsLetter(t) {
			dispatch((0, _actions3.showSnackbarMessage)({
				text: t('app.account.details.change-newsletter-success'),
				variant: 'success'
			}));
		},
		processUpdateFailure: function processUpdateFailure(t) {
			dispatch((0, _actions3.showSnackbarMessage)({
				text: t('app.account.details.save-failed'),
				variant: 'error'
			}));
		}
	};
}), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_CURRENT_APP_USER, {
	name: 'APP_USER',
	options: function options(props) {
		return {
			variables: {
				id: props.appUser && props.appUser.id
			}
		};
	}
}), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_GROUPS, {
	options: function options(props) {
		return {
			variables: {
				page: 0,
				pagesize: 3000,
				filter: {
					types: ['MainOrganisation']
				}
			}
		};
	}
}), (0, _reactApollo.graphql)(_graphql.GQL_UPDATE_APP_USER, { name: 'updateAppUser' }), (0, _reactApollo.graphql)(_graphql.GQL_CHANGE_FUNCTION), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_LOCALES, { name: 'LOCALES' }), (0, _reactApollo.graphql)(_graphql.GQL_CHANGE_DEPARTMENT, { name: 'CHANGE_DEPARTMENT' }), (0, _recompose.withHandlers)({
	onUpdateNewsLetter: function onUpdateNewsLetter(_ref2) {
		var updateAppUser = _ref2.updateAppUser,
		    processUpdateSuccessNewsLetter = _ref2.processUpdateSuccessNewsLetter,
		    processUpdateFailure = _ref2.processUpdateFailure,
		    t = _ref2.t;
		return function (event) {
			return updateAppUser({
				variables: _extends({}, event),
				context: {
					hasUpload: true
				}
			}).then(function (result) {
				if (!result.errors) {
					processUpdateSuccessNewsLetter(t);
				} else {
					processUpdateFailure(t);
				}
			});
		};
	},
	onUpdate: function onUpdate(_ref3) {
		var updateAppUser = _ref3.updateAppUser,
		    processUpdateSuccess = _ref3.processUpdateSuccess,
		    processUpdateFailure = _ref3.processUpdateFailure,
		    onLogin = _ref3.onLogin,
		    t = _ref3.t;
		return function (event) {
			return updateAppUser({
				variables: _extends({}, event)
			}).then(function (result) {
				if (!result.errors) {
					onLogin(result.data.updateAppUser.user);
					processUpdateSuccess(t);
				} else {
					processUpdateFailure(t);
				}
			}).catch(function (e) {});
		};
	},
	changeFunction: function changeFunction(_ref4) {
		var mutate = _ref4.mutate;
		return function (event) {
			return mutate({
				variables: _extends({}, event)
			});
		};
	},
	changeDepartment: function changeDepartment(_ref5) {
		var CHANGE_DEPARTMENT = _ref5.CHANGE_DEPARTMENT;
		return function (event) {
			return CHANGE_DEPARTMENT({
				variables: _extends({}, event)
			});
		};
	}
}))(Account);