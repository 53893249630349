import React from 'react';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Zoom from '@material-ui/core/Zoom';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { withTranslation } from "react-i18next";

const styles = (theme) => ({
    root: {
        padding: '0 40px 0 0!important',
        textAlign: 'right',
    },
    input: {
        margin: 0,
        padding: 0,
    },
    clear: {
        marginRight: -1 * theme.spacing(1),
        color: theme.palette.text.disabled,
        '&:hover': {
            color: theme.palette.text.primary,
        },
    },
    clearIcon: {
        height: 2 * theme.spacing(1),
        width: 2 * theme.spacing(1),
        color: 'inherit',
    },
});

class Search extends React.Component {
    state = {
        query: this.props.defaultValue || '',
        previousValue: this.props.defaultValue || '',
    };

    handleClear = () => {
        this.setState({ query: '' });
        if (this.props.onSearch) {
            if (this.state.previousValue !== '') {
                this.props.onSearch('');
                this.setState({ previousValue: '' });
            }
        }
        this.searchInput.focus();
    };

    handleChange = (event) => {
        this.setState({ query: event.target.value });
    };

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            if (
                this.props.onSearch &&
                this.state.previousValue !== this.state.query
            ) {
                this.setState({ previousValue: this.state.query });
                this.props.onSearch(this.state.query);
            }
            event.preventDefault();
        }
    };

    handleBlur = (event) => {
        if (
            this.props.onSearch &&
            this.state.previousValue !== this.state.query
        ) {
            this.setState({ previousValue: this.state.query });
            this.props.onSearch(this.state.query);
        }
    };

    render() {
        const {
            classes,
            theme,
            className: classNameProp,
            placeholder = false,
            t
        } = this.props;
        const _placeholder = placeholder
            ? placeholder
            : t("common.search");

        const { query } = this.state;

        return (
            <div className={[classes.root, classNameProp].join(' ')}>
                <Input
                    inputRef={(input) => (this.searchInput = input)}
                    className={classes.input}
                    placeholder={_placeholder}
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    }
                    endAdornment={
                        <InputAdornment position="end">
                            <Zoom
                                in={query.length > 0}
                                style={{
                                    transitionDelay:
                                        theme.transitions.duration.shortest,
                                }}
                                timeout={{
                                    exit: theme.transitions.duration.standard,
                                }}
                            >
                                <IconButton
                                    onClick={this.handleClear}
                                    color="inherit"
                                    className={classes.clear}
                                >
                                    <CloseIcon className={classes.clearIcon} />
                                </IconButton>
                            </Zoom>
                        </InputAdornment>
                    }
                    value={query}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                    onBlur={this.handleBlur}
                />
            </div>
        );
    }
}

export default compose(
    withTranslation(),
    withStyles(styles, { withTheme: true, name: 'CoreSearch' })
)(Search);
