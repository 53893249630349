import React, { useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";
import {
	WorkformViewer,
	DocumentUpload,
	BottomButtons,
	RatingRequest,
	RatingRespond,
} from "@manakin/app-core";
import { Header } from "./parts";
import classNames from "classnames";
import { useLocation, useParams, withRouter } from "react-router-dom";
import { getAppUser } from "@manakin/authentication/selectors";
import { compose } from "redux";
import { connect } from "react-redux";
import { getProgram } from "@manakin/app-core/ProgramsDropdown/selectors";
import { getBoxId } from "@manakin/app-core/Boxes/selectors";
import { managementRole } from "@manakin/app-core/lib";

const Rating = (props) => {
	const {
		classes,
		workforms,
		options,
		onFinish,
		ratingData = {},
		prefix,
		preview = false,
		onClose,
		appUser,
	} = props;
	const location = useLocation();
	const currentScrollPosition = useRef(null);
	const params = useParams();
	const data = {
		programId: props.program,
		boxId: props.box,
		elementId: params.elementId,
		studentId: params.studentId || appUser.id,
	};

	//functions
	useEffect(() => {
		window.addEventListener("scroll", listenToScroll);
		return () => window.removeEventListener("scroll", listenToScroll);
	}, []);

	const listenToScroll = () => {
		currentScrollPosition.current =
			window.pageYOffset || document.documentElement.scrollTop;
	};

	const isTrainer =
		appUser &&
		appUser.roles &&
		appUser.roles.find((role) => role.trainer === true) !== undefined;

	const isMM = appUser.roles.some((role) => role.name === managementRole);

	return (
		<div className={classes.root}>
			<Header
				data={{
					...ratingData,
				}}
				prefix={prefix}
			/>

			{workforms &&
				workforms.map((item) => (
					<div key={item.id} className={classes.normalMargin}>
						<div className={classNames(classes.largeWrapper)}>
							<WorkformViewer
								type={item.type}
								item={item}
								onFinish={onFinish}
								options={options}
								customLink={{
									state: {
										url: location.pathname,
										idx: currentScrollPosition.current,
									},
								}}
							/>
						</div>
					</div>
				))}

			{ratingData && ratingData.allowFileUpload && (
				<div className={classes.normalMargin}>
					<div className={classNames(classes.largeWrapper)}>
						<DocumentUpload
							data={data}
							readOnly={isTrainer || isMM}
							hideDelete={isMM}
						/>
					</div>
				</div>
			)}

			{!isTrainer && (
				<div className={classes.normalMargin}>
					<div className={classNames(classes.largeWrapper)}>
						<RatingRequest
							isFinalRating={false}
							appUser={appUser}
						/>
					</div>
				</div>
			)}

			{isTrainer && (
				<div className={classes.normalMargin}>
					<div className={classNames(classes.largeWrapper)}>
						<RatingRespond
							isFinalRating={false}
							studentId={params.studentId}
						/>
					</div>
				</div>
			)}

			{!preview && <BottomButtons onClose={onClose} />}
		</div>
	);
};

export default compose(
	withStyles(styles, { name: "AppRating" }),
	withRouter,
	connect((state) => ({
		config: state.config,
		program: getProgram(state),
		box: getBoxId(state),
		appUser: getAppUser(state),
	}))
)(Rating);
