import React from "react";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "../styles";
import { useTranslation } from 'react-i18next';

const Header = (props) => {
	const { user } = props;
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.headerRoot}>
			<div className={classes.wrapper}>
				<Typography
					variant="h2"
					component="h1"
					className={classes.heading}
				>
					{user.fullName}
				</Typography>
				<Typography>
					{t("app.assigned-student.intro")}
				</Typography>
			</div>
		</div>
	);
};

export default Header;
