import React from "react";
import Block from "./Block";
import { ProgressBlock } from "@manakin/app-core";
import { useQuery } from "react-apollo";
import { GQL_FETCH_RATING_PROGRESS } from "../graphql/graphql";
import { useTranslation } from 'react-i18next';

const RatingProgress = (props) => {
	const { program } = props;
	const { t } = useTranslation();

	// Query
	const { data = {} } = useQuery(GQL_FETCH_RATING_PROGRESS, {
		skip: props.program.id === undefined,
		variables: { programId: program.id }
	});
	const { ratingProgress = {} } = data;

	return (
		<Block
			title={t("app.progress-dashboard.blocks.rating-progress.title")}
			subtitle={program.name}
		>
			<ProgressBlock
				subtitle={t("app.progress-dashboard.blocks.rating-progress.progress")}
				progress={ratingProgress && ratingProgress.progress || 0}
				warning={false}
			/>
		</Block>
	);
};

export default RatingProgress;
