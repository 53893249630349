import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';
import LoginForm from '../../LoginForm';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';

const LoginScreen = (props) => {
    const { onSubmit, initial } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.loginRoot}>
            <Typography
                variant="h2"
                className={classNames(classes.heading, classes.loginHeading)}
            >
                {t("authentication.invitation.login-screen.title")}
            </Typography>
            <Typography className={classes.body}>
                {t("authentication.invitation.login-screen.description")}
            </Typography>
            <LoginForm
                onSubmit={onSubmit}
                readOnly={true}
                initialValue={initial.trainerEmail}
            />
        </div>
    );
};

export default LoginScreen;
