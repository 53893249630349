import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import SelectedIcon from '@material-ui/icons/CheckCircle';
import AssetsViewerItemBar from './AssetsViewerItemBar';
import Grid from '@material-ui/core/Grid';
import TablePagination from '@material-ui/core/TablePagination';
import { GQL_FETCH_CMS_ASSETS } from './graphql';
import Typography from '@material-ui/core/Typography';
import AssetViewerSearchBar from './AssetViewerSearchBar';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { getSettingValue } from '@manakin/core/actions';
import { SettingsKeys } from '@manakin/core/lib/constants';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {},
		tile: {
			height: 200,
			display: 'flex',
			flexDirection: 'column',
		},
		assetImage: {
			cursor: 'pointer',
			width: '100%',
			height: '100%',
			backgroundSize: 'cover',
			backgroundPosition: 'center',
			borderRadius: '4px',
			position: 'relative',
		},
		icon: {},
		progress: {
			margin: `${theme.spacing(4)}px auto`,
			display: 'block',
		},
		pagination: {
			marginTop: theme.spacing(1),
			display: 'flex',
			alignItems: 'center',
			float: 'right',
		},
		selectedOverlay: {
			position: 'absolute',
			width: '100%',
			height: '100%',
			border: '4px solid #000000',
			boxSizing: 'border-box',
			backgroundColor: 'rgba(45, 45, 45, 0.4)',
			zIndex: 1,
			borderRadius: '4px',
		},
		selectedOverlayText: {
			color: 'white',
			fontFamily: `"Arial", sans-serif`,
			fontWeight: 'bold',
			top: 0,
			right: 0,
			position: 'absolute',
			display: 'flex',
			alignItems: 'center',
		},
		selectedIcon: {
			color: 'white',
		},
		noResult: {
			paddingTop: theme.spacing(4),
			paddingBottom: theme.spacing(4),
			opacity: 0.3,
			width: '100%',
		},
		searchBarParent: {
			position: 'relative',
		},
		searchBar: {
			zIndex: 100,
			backgroundColor: 'white',
			minWidth: 500,
		},
		hiddenSearchBarAsPadding: {
			visibility: 'hidden',
			marginBottom: theme.spacing(2),
		},
		uploadBox: {},
	})
);

const AssetsDialog = (props) => {
	const {
		page = 0,
		pageSize = 12,
		pickedAssetId,
		pickedAssetSize,
		open,
		search,
		onSearch,
		assets = {},
		multi = false,
		onAssetPick,
		onPageChange,
		onItemsPerPageChange,
	} = props;

	const { t } = useTranslation();
	const classes = useStyles();

	const [clickedImage, setClickedImage] = useState(null);
	const [assetsArray, setAssetsArray] = useState([]);

	const customerName = getSettingValue(SettingsKeys.CUSTOMER_NAME) || '';
	const projectName = getSettingValue(SettingsKeys.PROJECT_NAME) || '';

	const { data, loading } = useQuery(GQL_FETCH_CMS_ASSETS, {
		variables: {
			page: page || 0,
			pagesize: pageSize || 12,
			customerName: customerName,
			projectName: projectName,
			search: search || '',
			type: 'image',
		}
	});
	const hasData = data && data.mediaList && data.mediaList.mediaList && !loading;

	useEffect(() => {
		if (data && data.mediaList && data.mediaList.mediaList && !loading) {
			setAssetsArray(data.mediaList.mediaList);
		}
	}, [data, loading]);

	const handleClickedImage = (assetId) => () => {
		setClickedImage(assetId);
	};

	const handleMenuClosed = () => {
		setClickedImage(null);
	};

	const handlePickAsset = (assetId, size, asset) => {
		if (onAssetPick) onAssetPick(assetId, size, asset);
	};

	const handleChangePage = (event, page) => {
		if (event === null) return;
		onPageChange(page);
	};

	const handleChangeItemsPerPage = (event) => {
		onItemsPerPageChange(event.target.value);
		onPageChange(0);
	};

	const getLabelDisplayedRows = ({ from, to, count }) =>
		count > 0
			? t('common.rows-displayed', {
					from: from,
					to: to,
					count: count,
			  })
			: '';

	return (
		<div className={classes.root}>
			{open && (
				<div>
					{!data || !data.mediaList || loading ? (
                            <div>
                                <CircularProgress className={classes.progress} />
                            </div>
                        ) : (
                            ''
                        )}

					{hasData && (
						<div>
							<div className={classes.searchBarParent}>
								<div className={classes.searchBar}>
									<AssetViewerSearchBar initialValue={search} onSubmit={onSearch} />
								</div>
							</div>

							<Grid container spacing={3}>
								{assetsArray &&
									assetsArray.map((asset) => {
										let thumbnails = asset.thumbnails;
										let thumbnail = '';

										if (Object.keys(thumbnails || {}).length > 0) {
											thumbnail = thumbnails.thul ? thumbnails.thul : thumbnails[Object.keys(thumbnails)[0]];
											if (asset.id === pickedAssetId && thumbnails.hasOwnProperty(pickedAssetSize)) {
												thumbnail = thumbnails[pickedAssetSize];
											}
										}

										return (
											<Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={asset.id}>
												<div className={classes.tile}>
													<div
														style={{
															backgroundImage: 'url(' + thumbnail + ')',
														}}
														className={classes.assetImage}
														onClick={handleClickedImage(asset.id)}
													>
														{multi && assets[asset.id] && assets[asset.id].derivative != 'none' && (
															<div className={classes.selectedOverlay}>
																<span className={classes.selectedOverlayText}>
																	{assets[asset.id].derivative
																		? assets[asset.id].derivative.toUpperCase()
																		: ''}

																	<IconButton>
																		<SelectedIcon className={classes.selectedIcon} />
																	</IconButton>
																</span>
															</div>
														)}

														{!multi && asset.id === pickedAssetId ? (
															<div className={classes.selectedOverlay}>
																<span className={classes.selectedOverlayText}>
																	{pickedAssetSize ? pickedAssetSize.toUpperCase() : ''}

																	<IconButton>
																		<SelectedIcon className={classes.selectedIcon} />
																	</IconButton>
																</span>
															</div>
														) : (
															''
														)}
													</div>

													<AssetsViewerItemBar
														key={asset.id}
														id={asset.id}
														asset={asset}
														menuOpen={clickedImage === asset.id}
														menuClosed={handleMenuClosed}
														onSizePick={(size) => handlePickAsset(asset.id, size, asset)}
													/>
												</div>
											</Grid>
										);
									})}

								{assetsArray && assetsArray.length === 0 && (
									<Typography className={classes.noResult} align="center" variant="subtitle1">
										{t('common.no-results-found')}
									</Typography>
								)}
							</Grid>
							<TablePagination
								component="div"
								count={data && data.medialist && data.mediaList.count}
								rowsPerPage={pageSize}
								page={page}
								onChangePage={handleChangePage}
								onChangeRowsPerPage={handleChangeItemsPerPage}
								labelDisplayedRows={getLabelDisplayedRows}
								rowsPerPageOptions={[12, 24, 48, 96]}
								labelRowsPerPage={t('common.rows-per-page')}
							/>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default AssetsDialog;
