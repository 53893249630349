import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from '../styles';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { getAppUser } from '@manakin/authentication/selectors';
import { useSelector } from "react-redux";
import { poRole } from "@manakin/app-core/lib";

const Header = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const appUser = useSelector((state) => getAppUser(state)) || {};
    const isTrainer = appUser && appUser.roles.find((it) => it.name.includes(poRole));
    
    return (
        <div className={classes.header}>
            <Typography
                variant="h3"
                className={classNames(classes.content, classes.title)}
            >
                {t("app.planner.title")}
            </Typography>
            <Typography className={classes.content}>
                {isTrainer ? t("app.planner.intro-trainer") : t("app.planner.intro")}
            </Typography>
        </div>
    );
};

export default Header;
