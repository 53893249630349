const AuthDataFormSettingsPassword = {
    password: {
        format: 'password',
        required: true,
    },
    secondPassword: {
        format: 'password',
        couple: 'password',
        coupleLabel: 'wachtwoord',
        required: true,
    },
};

export const AuthDataFormSettingsPasswordStrong = {
    password: {
        format: "strong-password",
        required: true,
    },
    secondPassword: {
        required: true,
        couple: "password",
        coupleLabel: "wachtwoord",
        format: "strong-password",
    },
};

export const AuthDataFormSettings = {
    email: {
        required: true,
        format: 'email',
    },
    password: AuthDataFormSettingsPassword.password,
    secondEmail: {
        required: true,
        couple: 'email',
        coupleLabel: 'e-mailadres',
        format: 'email',
    },
    secondPassword: AuthDataFormSettingsPassword.secondPassword
};
