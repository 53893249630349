import React, { useState, useEffect } from 'react';
import Paper from '../Paper';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Link, useHistory } from 'react-router-dom';
import LoginForm from '../LoginForm';
import { GQL_LOGIN_APP, GQL_FETCH_SETTINGS } from '../graphql';
import { login, setUserType } from '../actions';
import { showSnackbarMessage } from '@manakin/core/actions';
import Cookies from 'js-cookie';
import { useMutation, useQuery } from '@apollo/react-hooks';
import 'url-search-params-polyfill';
import { useSetting } from '@manakin/hooks';
import { BPVB_RAPPORT_ROOT_PATH } from '@manakin/app-views/Router/Router';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { SettingsKeys } from '@manakin/core/lib/constants';
import DemoButtonLogin from '@manakin/app-views/ProgressDashboard/parts/DemoButtonLogin';

const Import = (props) => {
	const { children, config = {}, loginBackgroundColor = false } = props;
	const classes = useStyles({
		loginBackgroundColor: loginBackgroundColor,
	})();
	const { pages = {}, general = {} } = config;
	const { appRegistration = {} } = pages;
	const history = useHistory();
	const { t, i18n } = useTranslation();

	//mutation hooks
	const [loginApp] = useMutation(GQL_LOGIN_APP, {
		refetchQueries: ['validLicenseCheck'],
	});
	const { data = {} } = useQuery(GQL_FETCH_SETTINGS);

	const loginButtonName = useSetting(data.settings, SettingsKeys.LOGIN_BUTTON_NAME) || null;

	const loginButtonUrl = useSetting(data.settings, SettingsKeys.LOGIN_BUTTON_URL) || null;

	//state hooks
	const isIosApp = navigator.userAgent.indexOf('wrapper') !== -1;

	//functions
	const handleSubmit = (event) => {
		const params = new URLSearchParams(props.search);
		let redirect = params.get('redirect') || '/';
		let uri = redirect;
		if (
			redirect.indexOf(BPVB_RAPPORT_ROOT_PATH) !== -1 ||
			redirect.indexOf('/webshop') !== -1 ||
			redirect.indexOf('/no-license') !== -1
		) {
			uri = '/';
		}

		onLogin({ ...event, redirect: uri });
	};

	const onLogin = (event) => {
		loginApp({
			variables: { ...event },
		})
			.then((result) => {
				if (!result.errors) {
					if (result.data.loginApp.user && result.data.loginApp.user.locale) {
						const newLocale =
							result.data.loginApp.user.locale.code === config.defaultLang ? '' : result.data.loginApp.user.locale.code || '';

						Cookies.set('locale', newLocale);
						i18n.changeLanguage(newLocale);
					}

					if (result.data.loginApp && result.data.loginApp.mfa) {
						history.push({
							pathname: '/auth/2factor',
							state: {
								...result.data.loginApp,
								redirect: event.redirect,
								env: 'APP',
							},
						});
					} else {
						props.processSuccess(event.redirect, result.data.loginApp.user);
					}
				} else {
					let message = null;
					if (result.errors.some((item) => item.message === 'APP user is disabled'))
						message = t('authentication.login.account-deactivated');
					props.processLoginFailed(message, t);
				}
			})
			.catch((e) => {
				props.processLoginFailed(false, t);
			});
	};

	return (
		<Paper className={classes.root}>
			{children}
			<LoginForm onSubmit={handleSubmit} loginBackgroundColor={loginBackgroundColor} />

			{!general.sso && (
				<Link className={classes.link} to={`reset`}>
					{t('authentication.login.password-forgot')}
				</Link>
			)}
			
			<DemoButtonLogin /> 
			
			{!appRegistration.hideRegistration && !isIosApp && (
				<Link className={classes.registrationLink} to={'registration'}>
					<Button variant="outlined" color="primary" className={classes.button} fullWidth>
						{t('authentication.login.create-account')}
					</Button>
				</Link>
			)}
			{loginButtonName !== 'loading' && loginButtonUrl !== 'loading' && loginButtonUrl && (
				<a className={classes.registrationLink} href={loginButtonUrl} target="_blank">
					<Button variant="outlined" color="primary" className={classes.button} fullWidth>
						{loginButtonName || t('authentication.login.webshop')}
					</Button>
				</a>
			)}
			

		</Paper>
	);
};

export default compose(
	connect(
	(state) => ({ config: state.config }),
		(dispatch) => ({
			processSuccess: (redirect, user) => {
				dispatch(login(redirect, user));
				dispatch(setUserType('app'));
			},
			processLoginFailed: (message, t) =>
				dispatch(
					showSnackbarMessage({
						text: message || t('authentication.login.failed'),
						variant: 'error',
					})
				),
		})
	)
)(Import);
