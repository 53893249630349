"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _appCore = require("@manakin/app-core");

var _TableCell = require("@material-ui/core/TableCell");

var _TableCell2 = _interopRequireDefault(_TableCell);

var _graphql = require("./graphql");

var _reactHooks = require("@apollo/react-hooks");

var _Loader = require("../Loader");

var _Loader2 = _interopRequireDefault(_Loader);

var _TableRow = require("./TableRow");

var _TableRow2 = _interopRequireDefault(_TableRow);

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AppointmentsList = function AppointmentsList(props) {
	var onClick = props.onClick;

	var _useQuery = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_APPOINTMENTS, {
		fetchPolicy: "no-cache",
		variables: {
			includePastAppointments: false
		}
	}),
	    _useQuery$data = _useQuery.data,
	    data = _useQuery$data === undefined ? {} : _useQuery$data,
	    loading = _useQuery.loading;

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var appointments = data.appointments && data.appointments.appointments || [];

	var handleClick = function handleClick(event) {
		if (onClick) onClick(event);
	};

	if (loading) return _react2.default.createElement(_Loader2.default, { fullScreen: true });
	return _react2.default.createElement(_appCore.SimpleTable, {
		renderHead: function renderHead() {
			return _react2.default.createElement(
				_react2.default.Fragment,
				null,
				_react2.default.createElement(
					_TableCell2.default,
					{ align: "left" },
					t("app-core.appointments-list.table-headers.part")
				),
				_react2.default.createElement(
					_TableCell2.default,
					{ align: "left" },
					t("common.table-headers.student")
				),
				_react2.default.createElement(
					_TableCell2.default,
					{ align: "left" },
					t("app-core.appointments-list.table-headers.trainer")
				),
				_react2.default.createElement(
					_TableCell2.default,
					{ align: "left" },
					t("common.table-headers.date")
				)
			);
		},
		renderBody: function renderBody() {
			return _react2.default.createElement(
				_react2.default.Fragment,
				null,
				appointments.map(function (appointment) {
					return _react2.default.createElement(_TableRow2.default, {
						data: appointment,
						key: appointment.id,
						onClick: handleClick
					});
				})
			);
		}
	});
};

exports.default = AppointmentsList;