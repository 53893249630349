"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _styles = require("../styles");

var _styles2 = require("@material-ui/core/styles");

var _Typography = require("@material-ui/core/Typography");

var _Typography2 = _interopRequireDefault(_Typography);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

require("moment/min/locales");

var _momentDurationFormat = require("moment-duration-format");

var _momentDurationFormat2 = _interopRequireDefault(_momentDurationFormat);

var _reactApollo = require("react-apollo");

var _recompose = require("recompose");

var _graphql = require("../graphql");

var _reactHooks = require("@apollo/react-hooks");

var _appCore = require("@manakin/app-core");

var _reactRedux = require("react-redux");

var _selectors = require("@manakin/app-core/ProgramsDropdown/selectors");

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

(0, _momentDurationFormat2.default)(_moment2.default);

var Manager = function Manager(props) {
	var classes = props.classes,
	    group = props.group,
	    appUser = props.appUser,
	    _props$variant = props.variant,
	    variant = _props$variant === undefined ? "default" : _props$variant,
	    _props$type = props.type,
	    type = _props$type === undefined ? "" : _props$type,
	    client = props.client;

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var storedProgramId = (0, _reactRedux.useSelector)(function (state) {
		return (0, _selectors.getProgram)(state);
	});

	//state hooks

	var _useState = (0, _react.useState)(0),
	    _useState2 = _slicedToArray(_useState, 2),
	    classStudents = _useState2[0],
	    setClassStudents = _useState2[1];

	var _useState3 = (0, _react.useState)([]),
	    _useState4 = _slicedToArray(_useState3, 2),
	    activeStudents = _useState4[0],
	    setActiveStudents = _useState4[1];

	var _useState5 = (0, _react.useState)(0),
	    _useState6 = _slicedToArray(_useState5, 2),
	    percentage = _useState6[0],
	    setPercentage = _useState6[1];

	//reducers


	var standardReducer = function standardReducer(state, data) {
		var total = state;

		if (data == "reset") {
			return 0;
		}
		if (data) {
			total = state + data;
		}

		return total;
	};

	var _useReducer = (0, _react.useReducer)(standardReducer, 0),
	    _useReducer2 = _slicedToArray(_useReducer, 2),
	    sessionTotal = _useReducer2[0],
	    setSessionTotal = _useReducer2[1];

	var _useReducer3 = (0, _react.useReducer)(standardReducer, 0),
	    _useReducer4 = _slicedToArray(_useReducer3, 2),
	    totalFinished = _useReducer4[0],
	    setTotalFinished = _useReducer4[1];

	var _useReducer5 = (0, _react.useReducer)(standardReducer, 0),
	    _useReducer6 = _slicedToArray(_useReducer5, 2),
	    totalElements = _useReducer6[0],
	    setTotalElements = _useReducer6[1];

	//queries


	var _useQuery = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_GROUPS, {
		variables: {
			filter: {
				teacher: appUser.id
			}
		}
	}),
	    data = _useQuery.data,
	    loading = _useQuery.loading;

	//lazy queries


	var _useLazyQuery = (0, _reactHooks.useLazyQuery)(_graphql.GQL_FETCH_SCHOOL_CLASS, {
		onCompleted: function onCompleted(data) {
			if (data.schoolClass && data.schoolClass.students) {
				if (data.schoolClass.products) {
					var studentsArray = data.schoolClass.students.map(function (i) {
						return i.id;
					}, []);
					getProgramResults({
						variables: {
							users: studentsArray,
							programs: [storedProgramId]
						}
					});
				}

				setClassStudents(data.schoolClass.students.length);
				setActiveStudents(data.schoolClass.students.filter(function (item) {
					return item.sessionAverageDuration;
				}));
				data.schoolClass.students.forEach(function (item) {
					setSessionTotal(item.sessionAverageDuration);
				});
			} else {
				setClassStudents(0);
			}
		}
	}),
	    _useLazyQuery2 = _slicedToArray(_useLazyQuery, 2),
	    getSchoolClass = _useLazyQuery2[0],
	    schoolClassDataLoading = _useLazyQuery2[1].loading;

	var _useLazyQuery3 = (0, _reactHooks.useLazyQuery)(_graphql.GQL_FETCH_PROGRAM_RESULTS),
	    _useLazyQuery4 = _slicedToArray(_useLazyQuery3, 2),
	    getProgramResults = _useLazyQuery4[0],
	    _useLazyQuery4$ = _useLazyQuery4[1],
	    programResultsDataLoading = _useLazyQuery4$.loading,
	    _useLazyQuery4$$data = _useLazyQuery4$.data,
	    programResultsData = _useLazyQuery4$$data === undefined ? {} : _useLazyQuery4$$data;

	(0, _react.useEffect)(function () {
		if (group && storedProgramId) {
			setSessionTotal("reset");
			setTotalFinished("reset");
			setTotalElements("reset");

			getSchoolClass({
				variables: {
					id: group
				}
			});
		}
	}, [group, storedProgramId]);

	(0, _react.useEffect)(function () {
		var temp = Math.ceil(totalFinished / totalElements * 100);

		setPercentage(temp);
	}, [totalFinished, totalElements]);

	(0, _react.useEffect)(function () {
		if (!programResultsDataLoading && programResultsData && programResultsData.programResults && programResultsData.programResults.length) {
			var total = programResultsData.programResults.reduce(function (total, current) {
				return total + parseFloat(current.progress);
			}, 0);
			setPercentage((total / (classStudents || 0)).toFixed(0));
		} else if (!programResultsDataLoading, classStudents) {
			setPercentage(0);
		}
	}, [programResultsData, programResultsDataLoading]);

	//functions
	if (schoolClassDataLoading || loading) return _react2.default.createElement(_appCore.Loader, null);
	return _react2.default.createElement(
		"div",
		{ className: classes.rowTeacher },
		_react2.default.createElement(
			"div",
			{ className: classes.specificStatisticItemTeacher },
			_react2.default.createElement(
				_Typography2.default,
				{
					classes: { h2: (0, _classnames2.default)(classes.statisticsTitle) },
					component: "p",
					variant: "h2"
				},
				classStudents || 0
			),
			_react2.default.createElement(
				_Typography2.default,
				{
					classes: { body1: (0, _classnames2.default)(classes.stasticsBody) },
					component: "p",
					variant: "body1"
				},
				_react2.default.createElement(_reactI18next.Trans, { i18nKey: "app.statistics-box.amount-of-students-in-class" })
			)
		),
		_react2.default.createElement(
			"div",
			{ className: classes.specificStatisticItemTeacher },
			_react2.default.createElement(
				_Typography2.default,
				{
					classes: { h2: (0, _classnames2.default)(classes.statisticsTitle) },
					component: "p",
					variant: "h2"
				},
				sessionTotal && activeStudents.length && _moment2.default.duration(sessionTotal / (activeStudents.length || 0), "milliseconds").format("hh:mm", { trim: false })
			),
			_react2.default.createElement(
				_Typography2.default,
				{
					classes: { body1: (0, _classnames2.default)(classes.stasticsBody) },
					component: "p",
					variant: "body1"
				},
				t("app.statistics-box.average-session-duration")
			)
		),
		variant === "rapportage_bak" ? _react2.default.createElement(
			"div",
			{ className: classes.specificStatisticItemTeacher },
			_react2.default.createElement(
				_Typography2.default,
				{
					classes: { h2: (0, _classnames2.default)(classes.statisticsTitle) },
					component: "p",
					variant: "h2"
				},
				percentage || 0
			),
			_react2.default.createElement(
				_Typography2.default,
				{
					classes: { body1: (0, _classnames2.default)(classes.stasticsBody) },
					component: "p",
					variant: "body1"
				},
				t("app.statistics-box.average-progress")
			)
		) : _react2.default.createElement(
			"div",
			{ className: classes.specificStatisticItemTeacher },
			_react2.default.createElement(
				_Typography2.default,
				{
					classes: { h2: (0, _classnames2.default)(classes.statisticsTitle) },
					component: "p",
					variant: "h2"
				},
				data && data.groups && data.groups.count || 0
			),
			_react2.default.createElement(
				_Typography2.default,
				{
					classes: { body1: (0, _classnames2.default)(classes.stasticsBody) },
					component: "p",
					variant: "body1"
				},
				t("app.statistics-box.classes")
			)
		)
	);
};

exports.default = (0, _recompose.compose)(_reactApollo.withApollo, (0, _styles2.withStyles)(_styles.styles))(Manager);