import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/min/locales';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import Toolbar from './Toolbar';
import Event from './Event';
const localizer = momentLocalizer(moment);

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '100px 0',
	},
	calendar: {
        height: 1000,
		'& .rbc-event': {
			backgroundColor: 'transparent',
			padding: 0
		},
	},
}));

const BigCalendar = (props) => {
	const { views = ['month'], onRangeChange, events, date, onSelectEvent } = props;
	const classes = useStyles();
	const { i18n } = useTranslation();

	return (
		<div className={classes.root}>
			<Calendar
				onSelectEvent={onSelectEvent}
				culture={i18n.language}
				localizer={localizer}
				events={events}
				startAccessor="start"
				endAccessor="end"
				views={views}
				components={{
					toolbar: Toolbar,
					event: Event,
				}}
				className={classes.calendar}
				onRangeChange={onRangeChange}
				date={date ? new Date(date) : undefined}
			/>
		</div>
	);
};

export default BigCalendar;
