"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _appCore = require("@manakin/app-core");

var _core = require("@material-ui/core");

var _styles = require("./styles");

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var StudentCard = function StudentCard(props) {
	var children = props.children,
	    image = props.image,
	    data = props.data,
	    disabled = props.disabled;

	var classes = (0, _styles.useStyles)();

	return _react2.default.createElement(
		_appCore.StudentRatingCardLayout,
		{
			className: (0, _classnames2.default)(classes.layout, _defineProperty({}, classes.disabled, disabled))
		},
		_react2.default.createElement(
			"div",
			{ className: classes.imageContainer },
			_react2.default.createElement(
				"div",
				{ className: classes.image },
				_react2.default.createElement(_appCore.LoadImage, {
					src: image && image.url,
					placeholder: image && image.thumbnailUrl
				})
			),
			_react2.default.createElement(
				_core.Typography,
				{ className: classes.content },
				data.title || ""
			)
		),
		children
	);
};

exports.default = StudentCard;