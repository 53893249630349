import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { LoadImage, Tags } from '@manakin/app-core';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { backgroundColorKeys } from '../../constants';

const styles = (theme) => ({
    body: {
        marginBottom: 0
    },
    heading: {
        fontSize: '1.8rem',
        lineHeight: '1.8rem'
    },
    root: {
        textDecoration: 'none',
        height: 135,
        '& $body': {
            fontSize: '1.6rem',
            fontWeight: 300,
            lineHeight: '2.2rem',
        },
    },
    figure: {
        width: '100%',
        position: 'relative',
        maxHeight: 135,
        maxWidth: 135,
        [theme.breakpoints.up('md')]: {
            
        },
    },
    tags: {
		top: '10px',
		left: '10px',
		position: 'absolute',
		zIndex: '99',
		'&>figure': {
            maxWidth: 35,
            maxHeight: 35
		},
	},
    contentWrapper: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        background: '#fff',
        padding: '15px'
    },
    readMore: {
        color: 'inherit'
    },
    primary: {
        '& $heading': {
            color: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),
        }
    },
    secondary: {
        '& $heading': {
            color: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
        }
    },
    noBG: {
        '& $heading': {
            color: theme.manakin.defaultContentColor[500],
        }
    }
});

const NewsItem = (props) => {
    const { classes, image = {}, title = '', introText = '', id, tags = [], backgroundColor } = props;
    const { t } = useTranslation();

    const stripText = (str, total) => {
        return str.length > total ? `${str.substring(0, total)}...`: str;
    }

    return (
        <Link to={`/news/${id}`} className={classNames(
            classes.root,
            { [classes.primary]: backgroundColor === backgroundColorKeys.primary },
            { [classes.secondary]: backgroundColor === backgroundColorKeys.secondary },
            { [classes.noBG]: backgroundColor === backgroundColorKeys.none },
        )}>
            <div className={classes.figure}>
                <LoadImage
                    src={
                        image
                            ? image.url ||
                              process.env.PUBLIC_URL + '/dummy-news.png'
                            : process.env.PUBLIC_URL + '/dummy-news.png'
                    }
                    placeholder={image ? image.thumbnailUrl || '' : ''}
                />
                {tags && <Tags className={classes.tags} tags={tags} />}
            </div>
            <div className={classes.contentWrapper}>
                <Typography component="h5" variant="h5" className={classes.heading}>
                    {stripText(title, 50)}
                </Typography>
                <Typography component="p" variant="body1" className={classes.body}>
                    {stripText(introText, 110)} <Link className={classes.readMore} to={`/news/${id}`}>{t('common.read-more-lowercase')}</Link>
                </Typography>
            </div>
        </Link>
    );
};

export default withStyles(styles)(NewsItem);
