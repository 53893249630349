import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { ChromePicker, CirclePicker } from "react-color";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	colorPicker: {
		display: "flex",
		alignItems: "center",
		marginBottom: "40px",
	},
	input: {
		marginRight: "10px",
		cursor: "pointer",
	},
	title: {
		fontSize: "16px",
		fontWeight: 600,
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
	},
	reset: {
		display: "inline-block",
		cursor: "pointer",
		color: "red",
		marginLeft: "10px",
	},
	content: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
	},
    henk: {
        '& span>div>span>div': {
            border: '1px solid black'
        }
    }
}));

let timer = null;

const Picker = (props) => {
	const {
		initialValue,
		form,
		name,
		label = "",
		type = "default",
		colors = [],
	} = props;
	const [hex, setHex] = useState(initialValue || "");
	const { t } = useTranslation();

	//effect hooks
	useEffect(() => {
		if (!hex && initialValue) {
			setHex(initialValue);
			if (form) form.onFieldChange({ key: name, value: initialValue });
		}
	}, [initialValue]);

	const classes = useStyles();

	//functions
	const handleChange = (color) => {
		const val = color ? color.hex || "" : "";
		setHex(val);

		if (props.onChange) props.onChange({ value: val, name: props.name });
		if (form) form.onFieldChange({ key: name, value: val });
	};

	const handleReset = () => {
		if (form) form.onFieldChange({ key: name, value: "" });
		setHex("");
	};

	return (
		<div className={classes.colorPicker}>
			<div className={classes.content}>
				<Typography variant="h6" className={classes.title}>
					{label}
				</Typography>
				{type === "default" && (
					<ChromePicker
						color={hex}
						onChangeComplete={handleChange}
						disableAlpha
					/>
				)}
				{type === "circle" && (
					<CirclePicker
                        className={classes.henk}
						color={hex}
						onChangeComplete={handleChange}
						disableAlpha
						colors={colors}
					/>
				)}
			</div>
			{hex && (
				<Typography className={classes.reset} onClick={handleReset}>
					{t("common.reset")}
				</Typography>
			)}
		</div>
	);
};

export default Picker;
