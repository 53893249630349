import React from 'react';
import AppRegistration from '@manakin/authentication/AppRegistration';
import AppWebshop from '../AppWebshop/AppWebshop';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import LocaleSelect from '@manakin/app-core/LocaleSelect';
import { GQL_FETCH_SETTINGS } from '../graphql';
import { useQuery } from 'react-apollo';
import { useBooleanSetting } from '@manakin/hooks';
import { SettingsKeys } from '@manakin/core/lib/constants';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				width: '100%',
				minHeight: '100%',
			},
			smallWrapper: {
				...theme.manakin.smallWrapper,
				paddingBottom: '100px',
				zIndex: 1,
			},
			heading: {},
		}),
	{
		name: 'AppRegistrationScreen',
	}
);

const Registration = () => {
	const config = useSelector((state) => state.config);
    const classes = useStyles();
	const { pages } = config || {};
	const { appRegistration = {} } = pages || {};

	const { data: settingsData = {} } = useQuery(GQL_FETCH_SETTINGS);
	const multilanguageEnabled = useBooleanSetting(settingsData.settings, SettingsKeys.MULTILANGUAGE_ENABLED)

	if (appRegistration.webshop) {
		return <AppWebshop />;
	}

	return (
		<div className={classes.root}>
			<div className={classes.smallWrapper}>
				<div className={classes.registrationRoot}>
					<AppRegistration />
				</div>
			</div>
			{multilanguageEnabled &&
				<LocaleSelect />
			}
		</div>
	);
};

export default Registration;
