"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require("react-redux");

var _graphql = require("./graphql");

var _reactApollo = require("react-apollo");

var _reactRouter = require("react-router");

var _lib = require("@manakin/app-core/lib");

var _selectors = require("@manakin/authentication/selectors");

var _AppBarMessage = require("./AppBarMessage");

var _AppBarMessage2 = _interopRequireDefault(_AppBarMessage);

var _reactRouterDom = require("react-router-dom");

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AppBarmessages = function AppBarmessages(props) {
	var appUser = (0, _reactRedux.useSelector)(function (state) {
		return (0, _selectors.getAppUser)(state);
	});
	var history = (0, _reactRouter.useHistory)();
	var location = (0, _reactRouterDom.useLocation)();

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var isTrainer = appUser && appUser.roles && appUser.roles.some(function (role) {
		return _lib.trainerRoles.includes(role.name);
	});

	var _useQuery = (0, _reactApollo.useQuery)(_graphql.GQL_FETCH_INVITES, { skip: isTrainer }),
	    data = _useQuery.data,
	    loading = _useQuery.loading;

	var _ref = data || {},
	    invitations = _ref.invitations;

	var _useQuery2 = (0, _reactApollo.useQuery)(_graphql.GQL_FETCH_RATING_REQUESTS, {
		skip: isTrainer,
		variables: {
			page: 0,
			pagesize: 99,
			filter: { finalRatings: true }
		}
	}),
	    ratingRequestsData = _useQuery2.data,
	    ratingRequestLoading = _useQuery2.loading;

	var _ref2 = ratingRequestsData || {},
	    finalRatingRequests = _ref2.ratingRequests;

	var _useQuery3 = (0, _reactApollo.useQuery)(_graphql.GQL_FETCH_RATING_REQUESTS, {
		skip: isTrainer,
		variables: {
			page: 0,
			pagesize: 99,
			filter: { supervisorRatings: true }
		}
	}),
	    superRatingData = _useQuery3.data,
	    superRatingDataLoading = _useQuery3.loading;

	var _ref3 = superRatingData || {},
	    _ref3$ratingRequests = _ref3.ratingRequests,
	    superRatingsRequests = _ref3$ratingRequests === undefined ? [] : _ref3$ratingRequests;

	var _useQuery4 = (0, _reactApollo.useQuery)(_graphql.GQL_FETCH_INVITATION_STUDENTS, { skip: !isTrainer }),
	    _useQuery4$data = _useQuery4.data,
	    invitationStudentData = _useQuery4$data === undefined ? {} : _useQuery4$data;

	var invitationStudents = invitationStudentData.invitationStudents;


	var handleClick = function handleClick() {
		history.push("/account/invite");
	};
	var handleBPV = function handleBPV() {
		history.push("/account/bpvb");
	};

	var handleTrainerInvitations = function handleTrainerInvitations() {
		history.push("/account/invite");
	};

	if (location.pathname === "/no-license") return "";

	// Show a message bar for the PO that there is no student connected
	if (isTrainer && invitationStudents && invitationStudents.length === 0) {
		return _react2.default.createElement(_AppBarMessage2.default, {
			onClick: handleTrainerInvitations,
			message: t("app-core.app-bar-messages.trainer-connect") });
	}

	// Show no message bar if trainer / management
	if (appUser.roles && appUser.roles.some(function (role) {
		return _lib.trainerRoles.includes(role.name) || _lib.managementRole.includes(role.name);
	})) {
		return "";
	}

	// Show bar telling student that the BPV report is ready
	if (finalRatingRequests && finalRatingRequests.length && finalRatingRequests.some(function (request) {
		return request.result;
	}) && !superRatingsRequests.length) {
		return _react2.default.createElement(_AppBarMessage2.default, {
			onClick: handleBPV,
			message: t("app-core.app-bar-messages.bpv-report-ready")
		});
	}

	// Show bar telling student to invite an PO / BPVB
	if (loading || invitations && invitations.length) return "";
	return _react2.default.createElement(_AppBarMessage2.default, {
		onClick: handleClick,
		message: t("app-core.app-bar-messages.connect")
	});
};

exports.default = AppBarmessages;