import React from "react";
import { validateEntity, Failure } from "@manakin/validation";
import { compose } from "recompose";
import { withTranslation } from "react-i18next";

const withForm = (schema) => (WrappedComponent) => {
	return compose(
		withTranslation()
	)(
		class WithForm extends React.Component {
			state = {
				schema,
				fields: Object.keys(schema).reduce((fields, key) => {
					fields[key] = null;
					return fields;
				}, {}),
				errors: Object.keys(schema).reduce((errors, key) => {
					errors[key] = [];
					return errors;
				}, {}),
			};

			clear = () => {
				this.setState((prevState) => ({
					schema,
					fields: Object.keys(schema).reduce((fields, key) => {
						fields[key] = null;
						return fields;
					}, {}),
					errors: Object.keys(schema).reduce((errors, key) => {
						errors[key] = [];
						return errors;
					}, {}),
				}));
			};

			addField = (event) => {
				const newFields = Object.keys(event).reduce((fields, key) => {
					fields[key] = null;
					return fields;
				}, {});
				const newErrorFields = Object.keys(event).reduce(
					(errors, key) => {
						errors[key] = [];
						return errors;
					},
					{}
				);

				this.setState((prevState) => ({
					schema: { ...prevState.schema, ...event },
					fields: { ...prevState.fields, ...newFields },
					errors: { ...prevState.errors, ...newErrorFields },
				}));
			};

			removeField = (event) => {
				let newState = { ...this.state };

				event.map((item) => {
					delete newState.fields[item];
					delete newState.schema[item];
					delete newState.errors[item];
				});

				this.setState(newState);
			};

			handleValidate = (fieldName) => {
				return new Promise((resolve, reject) => {
					this.setState(
						(prevState) => {
							const fieldsToValidate =
								fieldName !== undefined
									? [fieldName]
									: Object.keys(prevState.fields);

							const validationResults = validateEntity(
								Object.keys(prevState.schema).reduce(
									(s, key) => {
										if (fieldsToValidate.includes(key)) {
											s[key] = prevState.schema[key];
										}
										return s;
									},
									{}
								),
								prevState.fields
							);

							if (Failure.hasInstance(validationResults)) {
								return {
									errors: validationResults.value.reduce(
										(result, { propertyName, errors }) => {
											const currentResult =
												result[propertyName] || [];
											result[propertyName] =
												currentResult.concat(
													errors.map((e) =>
														this.createErrorMessage(
															prevState.schema[
																propertyName
															],
															e
														)
													)
												);

											return result;
										},
										{}
									),
								};
							}

							return {
								errors: {},
							};
						},
						() => {
							if (
								Object.keys(this.state.errors).reduce(
									(hasErrors, key) =>
										hasErrors ||
										this.state.errors[key].length > 0,
									false
								)
							) {
								reject(this.state.errors);
							} else {
								resolve(true);
							}
						}
					);
				});
			};

			handleSubmit = (event) => {
				return this.handleValidate().then(
					() => {
						if (this.props.onSubmit) {
							this.props.onSubmit(this.state.fields);
						}
						return this.state.fields;
					},
					() => {
						return false;
					}
				);
			};

			handleFieldChange = (event) => {
				this.setState((prevState) => ({
					fields: { ...prevState.fields, [event.key]: event.value },
					errors: { ...prevState.errors, [event.key]: [] },
				}));
			};

			handleRequiredChange = (key, required) => {
				this.setState((prevState) => ({
					schema: {
						...prevState.schema,
						[key]: {
							...prevState.schema[key],
							required: required,
						},
					},
				}));
			};

            handleSchemaChange = (key, schema) => {
                this.setState((prevState) => ({
                    schema: {
                        ...prevState.schema,
                        [key]: {
                            ...prevState.schema[key],
                            ...schema,
                        },
                    },
                }));
            };

			createErrorMessage = (schemaProps, key) => {
				const { t } = this.props;

				if (key === "required") {
					return t("common.validation.required");
				}

				if (key === "maxLength") {
					return t("common.validation.max-length", { amount: schemaProps[key] });
				}

				if (key === "minLength") {
					return t("common.validation.min-length", { amount: schemaProps[key] });
				}

				if (key === "format" && schemaProps[key] === "email") {
					return t("common.validation.invalid-email");
				}

				if (key === "format" && schemaProps[key] === "password") {
					return t("common.validation.password");
				}

				if (
					key === "format" &&
					schemaProps[key] === "strong-password"
				) {
					return t("common.validation.strong-password");
				}

				if (key === "format" && schemaProps[key] === "phoneNumber") {
					return t("common.validation.phone-number");
				}

				if (key === "format" && schemaProps[key] === "date") {
					return t("common.validation.date");
				}

				if (key === "couple") {
					return t("common.validation.couple-no-match", { label: schemaProps.coupleLabel });
				}

				if (
					key === "type" &&
					schemaProps[key] === "number" &&
					schemaProps.required
				) {
					return t("common.validation.number");
				}

				if (key === "type" && schemaProps[key] === "integer") {
					return t("common.validation.invalid-number");
				}

				if (schemaProps["type"] === "number" && key === "minValue") {
					return t("common.validation.min-number", { amount: schemaProps[key] });
				}

				if (schemaProps["type"] === "number" && key === "maxValue") {
					return t("common.validation.max-number", { amount: schemaProps[key] });
				}

				if (key === "format" && schemaProps[key] === "url") {
					return t("common.validation.url");
				}

				return t("common.validation.unknown");
			};

			render() {
				const { ...otherProps } = this.props;

				return (
					<WrappedComponent
						{...otherProps}
						form={{
							...this.state,
							clear: this.clear,
							onFieldChange: this.handleFieldChange,
							onValidate: this.handleValidate,
							onSubmit: this.handleSubmit,
							onAddField: this.addField,
							onRemoveField: this.removeField,
							onRequiredChange: this.handleRequiredChange,
							onSchemaChange: this.handleSchemaChange
						}}
					/>
				);
			}
		}
	);
};

export default withForm;
