import gql from 'graphql-tag';
import { appAssetNoThumbnail } from '@manakin/app-core/graphQlHelpers';

export const GQL_FETCH_EXERCISE_CARD = gql`
    query exerciseCard($id: ID!) {
        exerciseCard(id: $id) {
            id
            title
            enabled
            feedback1
            feedback2
            feedback3
            feedback4
            styleSheet {
                id
                indicationColor
                defaultWorkformColor
                primaryWorkformColor
                secondaryWorkformColor
                themeColor
            }
            workforms {
                id
                type
                tags {
                    id
                    name
                    visibleInApp
                    image {
                        ${appAssetNoThumbnail}
                    }
                }
                correctWorkforms {
                    id
                    title
                    type
                }
                inCorrectWorkforms {
                    id
                    title
                    type
                }
            }
        }
    }
`;

export const GQL_FETCH_FILE_DOWNLOAD = gql`
    query fileDownload($id: ID!) {
        fileDownload(id: $id) {
            id
            title
            file {
                ${appAssetNoThumbnail}
            }
        }
    }
`;

export const GQL_CREATE_ELEMENT_RESULT = gql`
    mutation(
        $user: ID!
        $program: ID!
        $box: ID!
        $element: ID!
        $finished: Boolean
        $rating: String
    ) {
        createElementResult(
            input: {
                user: $user
                program: $program
                box: $box
                element: $element
                finished: $finished
                rating: $rating
            }
        ) {
            result {
                finished
            }
        }
    }
`;

export const GQL_FETCH_ELEMENT_RESULT = gql`
    query($box: ID!, $program: ID!, $user: ID!, $element: ID!) {
        elementResult(
            box: $box
            program: $program
            user: $user
            element: $element
        ) {
            finished
            rating
            ratingTeacher
        }
    }
`;

export const GQL_DELETE_ELEMENT_RESULT = gql`
    mutation($id: ID!) {
        deleteElementResult(id: $id) {
            result
        }
    }
`;

export const GQL_FETCH_STYLESHEET = gql`
	query styleSheet($id: ID!) {
		styleSheet(id: $id) {
			id
			title
			description
			customStyle
			indicationColor
			defaultWorkformColor
			primaryWorkformColor
			secondaryWorkformColor
			themeColor
		}
	}
`;

export const GQL_FETCH_SETTINGS = gql`
    query settings {
        settings {
            settings {
                name
                value
                file {
                    id
                    url
                }
            }
        }
    }
`;