import React, { useEffect } from 'react';
import { withWorkforms } from '@manakin/app-core';
import { GQL_FETCH_SUMMARY } from './graphql';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import Recap from './Recap';
import { useViewCounting } from "@manakin/hooks";

const RecapContainer = (props) => {
    const { data, workformsData } = props;
    const { loading = true, summary = {} } = data;
    const { viewElement } = useViewCounting();

    //effect hooks
    useEffect(() => {
        if (!loading) {
            const elementId = props.match.params.elementId;

            const options = {
                showFeedback: false,
                nextButton: false,
            };

            if (summary.workforms) {
                workformsData.loadWorkforms({
                    ...summary,
                    options: options,
                    elementId: elementId,
                });
                workformsData.loadElementResult(elementId);
                viewElement(elementId);
            }
        }
    }, [props.data.loading]);

    return (
        <Recap
            loading={loading}
            summary={summary}
            workformsData={workformsData}
        />
    );
};

export default compose(
    graphql(GQL_FETCH_SUMMARY, {
        options: (props) => ({
            variables: { id: props.match.params.elementId },
        }),
    }),
    withWorkforms()
)(RecapContainer);
