import React from "react";
import { useStyles } from "./styles";
import FactCardReferralCard from "./FactCardsReferral";
import Grid from "@material-ui/core/Grid";

const FactCardsReferralContainer = (props) => {
	const { data } = props;
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Grid className={classes.grid}>
				<FactCardReferralCard data={data} />
			</Grid>
		</div>
	);
};

export default FactCardsReferralContainer;
