import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(
    (theme) =>
        createStyles({
            wrapper: {
                ...theme.manakin.defaultWrapper,
                [theme.breakpoints.up('md')]: {
                    padding: 0,
                    paddingRight: '100px',
                },
                marginBottom: '3rem',
            },
            recentFeedbackHeader: {
                marginTop: '3rem',
                [theme.breakpoints.up('md')]: {
                    marginBottom: '3rem',
                },
            },
            button: {
                textAlign: 'left',
                justifyContent: 'start',
            },
            shareIcon: {
                marginRight: theme.spacing(2),
            },
            longArrowRoot: {
                marginLeft: 'auto',
                marginRight: theme.spacing(1),
            },
            feedbackUserIcon: {
                color: '#C4C4C4',
                width: 24,
                height: 24,
            },
            feedbackUser: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            },
            feedbackUserContainer: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                [theme.breakpoints.down('xs')]: {
                    alignItems: 'start',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    marginLeft: theme.spacing(2),
                },
            },
            feedbackUserName: {
                marginLeft: theme.spacing(2),
                marginRight: theme.spacing(3),
                marginBottom: 0,
                [theme.breakpoints.down('xs')]: {
                    marginLeft: 0,
                },
            },
            feedbackDate: {
                marginBottom: 0,
                paddingBottom: '0 !important',
                color: theme.manakin.subHeaderColor[500],
            },
            subHeader: {},
            feedbackLink: {
                padding: '10px',
                backgroundColor: theme.palette.info.light,
                color: theme.palette.getContrastText(theme.palette.info.light),
                width: '100%',
                textAlign: 'center',
            },
            feedbackLinkContent: {
                margin: 0,
                wordBreak: "break-all"
            },
        }),
    {
        name: 'AppAskFeedback',
    }
);
