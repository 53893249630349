'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.GQL_FETCH_PROCESS_STEP = exports.GQL_FETCH_POLL_QUESTION = exports.GQL_FETCH_OPEN_QUESTION = exports.GQL_FETCH_ROADMAP = exports.GQL_FETCH_ELEMENT_REFERRAL = exports.GQL_FETCH_HOTSPOTMAP = exports.GQL_FETCH_SCENE = exports.GQL_FETCH_HOTSPOT = exports.GQL_FETCH_IMAGE_AND_TEXT_MPC = exports.GQL_FETCH_CHAT_BUBBLE = exports.GQL_FETCH_SORT_QUESTION = exports.GQL_FETCH_CMS_WORK_FORM_FILE_DOWNLOAD = exports.GQL_FETCH_TITLE_AND_TEXT_IMAGE = exports.GQL_FETCH_TITLE_AND_TEXT = exports.GQL_FETCH_WHAT_IS_WHAT_QUESTION = exports.GQL_FETCH_IMAGE_WITH_SUBTITLE = exports.GQL_FETCH_CHOICE_QUESTION = exports.GQL_FETCH_STATEMENT_QUESTION = exports.GQL_FETCH_TEXT_IMAGE_CHECK_QUESTION = exports.GQL_FETCH_IMAGE_CHECK_QUESTION = exports.GQL_FETCH_TEXT_CHECK_QUESTION = exports.GQL_FETCH_MOODBOARD = exports.GQL_FETCH_ORDER_QUESTION = exports.GQL_FETCH_EXTERNAL_LINK = exports.GQL_FETCH_FACTCARDS_REFERRAL = exports.GQL_FETCH_FACTCARD_REFERRAL = exports.GQL_FETCH_IMAGE_MPC = exports.GQL_FETCH_VIDEO_WITH_TITLE = exports.GQL_FETCH_SHORT_TEXT = exports.GQL_FETCH_TEXT_MPC_WITH_IMAGE = exports.GQL_FETCH_TEXT_MPC = exports.GQL_FETCH_PROGRESSION_FEEDBACK = exports.GQL_FETCH_ASK_FEEDBACK = exports.GQL_FETCH_CRITERIA = exports.GQL_FETCH_WORKFORM_RESULT = exports.GQL_CREATE_WORKFORM_RESULT = exports.GQL_FETCH_ELEMENT_RESULTS = exports.GQL_CREATE_ELEMENT_RESULT = exports.GQL_DELETE_WORKFORM_RESULT = exports.GQL_FETCH_GLOBAL_SETTINGS = undefined;

var _templateObject = _taggedTemplateLiteral(['\n    query settings{\n        settings {\n            ', '\n        }\n    }\n'], ['\n    query settings{\n        settings {\n            ', '\n        }\n    }\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n\tmutation ($user: ID!, $program: ID!, $box: ID!, $element: ID!) {\n\t\tdeleteWorkformResults(input: { user: $user, program: $program, box: $box, element: $element }) {\n\t\t\tresult\n\t\t}\n\t}\n'], ['\n\tmutation ($user: ID!, $program: ID!, $box: ID!, $element: ID!) {\n\t\tdeleteWorkformResults(input: { user: $user, program: $program, box: $box, element: $element }) {\n\t\t\tresult\n\t\t}\n\t}\n']),
    _templateObject3 = _taggedTemplateLiteral(['\n\tmutation createElementResult(\n\t\t$user: ID!\n\t\t$program: ID!\n\t\t$box: ID!\n\t\t$element: ID!\n\t\t$finished: Boolean\n\t\t$bestTime: Int\n\t\t$correct: Boolean\n\t\t$rating: String\n\t\t$progress: Float\n\t) {\n\t\tcreateElementResult(\n\t\t\tinput: {\n\t\t\t\tuser: $user\n\t\t\t\tprogram: $program\n\t\t\t\tbox: $box\n\t\t\t\telement: $element\n\t\t\t\tfinished: $finished\n\t\t\t\tbestTime: $bestTime\n\t\t\t\tcorrect: $correct\n\t\t\t\trating: $rating\n\t\t\t\tprogress: $progress\n\t\t\t}\n\t\t) {\n\t\t\tresult {\n\t\t\t\tfinished\n\t\t\t}\n\t\t}\n\t}\n'], ['\n\tmutation createElementResult(\n\t\t$user: ID!\n\t\t$program: ID!\n\t\t$box: ID!\n\t\t$element: ID!\n\t\t$finished: Boolean\n\t\t$bestTime: Int\n\t\t$correct: Boolean\n\t\t$rating: String\n\t\t$progress: Float\n\t) {\n\t\tcreateElementResult(\n\t\t\tinput: {\n\t\t\t\tuser: $user\n\t\t\t\tprogram: $program\n\t\t\t\tbox: $box\n\t\t\t\telement: $element\n\t\t\t\tfinished: $finished\n\t\t\t\tbestTime: $bestTime\n\t\t\t\tcorrect: $correct\n\t\t\t\trating: $rating\n\t\t\t\tprogress: $progress\n\t\t\t}\n\t\t) {\n\t\t\tresult {\n\t\t\t\tfinished\n\t\t\t}\n\t\t}\n\t}\n']),
    _templateObject4 = _taggedTemplateLiteral(['\n    query($box: ID!, $program: ID!, $user: ID!, $element: ID!) {\n        elementResult(\n            box: $box\n            program: $program\n            user: $user\n            element: $element\n        ) {\n            ', '\n        }\n    }\n'], ['\n    query($box: ID!, $program: ID!, $user: ID!, $element: ID!) {\n        elementResult(\n            box: $box\n            program: $program\n            user: $user\n            element: $element\n        ) {\n            ', '\n        }\n    }\n']),
    _templateObject5 = _taggedTemplateLiteral(['\n\tmutation createWorkformResult(\n\t\t$user: ID!\n\t\t$program: ID!\n\t\t$box: ID!\n\t\t$element: ID!\n\t\t$workform: ID!\n\t\t$answers: [WorkformResultAnswerInput]\n\t\t$finished: Boolean\n\t\t$statement: Boolean\n\t\t$answer: String\n\t) {\n\t\tcreateWorkformResult(\n\t\t\tinput: {\n\t\t\t\tuser: $user\n\t\t\t\tprogram: $program\n\t\t\t\tbox: $box\n\t\t\t\telement: $element\n\t\t\t\tworkform: $workform\n\t\t\t\tanswers: $answers\n\t\t\t\tfinished: $finished\n\t\t\t\tstatement: $statement\n\t\t\t\tanswer: $answer\n\t\t\t}\n\t\t) {\n\t\t\tresult {\n\t\t\t\tfinished\n\t\t\t\tstatement\n\t\t\t\tanswer\n\t\t\t\tanswers {\n\t\t\t\t\tid\n\t\t\t\t\tselected\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n'], ['\n\tmutation createWorkformResult(\n\t\t$user: ID!\n\t\t$program: ID!\n\t\t$box: ID!\n\t\t$element: ID!\n\t\t$workform: ID!\n\t\t$answers: [WorkformResultAnswerInput]\n\t\t$finished: Boolean\n\t\t$statement: Boolean\n\t\t$answer: String\n\t) {\n\t\tcreateWorkformResult(\n\t\t\tinput: {\n\t\t\t\tuser: $user\n\t\t\t\tprogram: $program\n\t\t\t\tbox: $box\n\t\t\t\telement: $element\n\t\t\t\tworkform: $workform\n\t\t\t\tanswers: $answers\n\t\t\t\tfinished: $finished\n\t\t\t\tstatement: $statement\n\t\t\t\tanswer: $answer\n\t\t\t}\n\t\t) {\n\t\t\tresult {\n\t\t\t\tfinished\n\t\t\t\tstatement\n\t\t\t\tanswer\n\t\t\t\tanswers {\n\t\t\t\t\tid\n\t\t\t\t\tselected\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n']),
    _templateObject6 = _taggedTemplateLiteral(['\n    query workformResult($user: ID!, $program: ID!, $box: ID!, $element: ID!, $workform: ID!) {\n        workformResult(\n            user: $user\n            program: $program\n            box: $box\n            element: $element\n            workform: $workform\n        ) {\n            ', '\n        }\n    }\n'], ['\n    query workformResult($user: ID!, $program: ID!, $box: ID!, $element: ID!, $workform: ID!) {\n        workformResult(\n            user: $user\n            program: $program\n            box: $box\n            element: $element\n            workform: $workform\n        ) {\n            ', '\n        }\n    }\n']),
    _templateObject7 = _taggedTemplateLiteral(['\n    query criteria($id: ID!) {\n        criteria(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query criteria($id: ID!) {\n        criteria(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject8 = _taggedTemplateLiteral(['\n    query askFeedback($id: ID!) {\n        askFeedback(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query askFeedback($id: ID!) {\n        askFeedback(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject9 = _taggedTemplateLiteral(['\n    query progressionFeedback($id: ID!) {\n        progressionFeedback(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query progressionFeedback($id: ID!) {\n        progressionFeedback(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject10 = _taggedTemplateLiteral(['\n    query textMPC($id: ID!) {\n        textMPC(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query textMPC($id: ID!) {\n        textMPC(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject11 = _taggedTemplateLiteral(['\n    query textMPCWithImage($id: ID!) {\n        textMPCWithImage(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query textMPCWithImage($id: ID!) {\n        textMPCWithImage(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject12 = _taggedTemplateLiteral(['\n    query shortText($id: ID!) {\n        shortText(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query shortText($id: ID!) {\n        shortText(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject13 = _taggedTemplateLiteral(['\n    query videoWithTitle($id: ID!) {\n        videoWithTitle(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query videoWithTitle($id: ID!) {\n        videoWithTitle(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject14 = _taggedTemplateLiteral(['\n    query imageMPC($id: ID!) {\n        imageMPC(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query imageMPC($id: ID!) {\n        imageMPC(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject15 = _taggedTemplateLiteral(['\n    query factCardReferral($id: ID!) {\n        factCardReferral(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query factCardReferral($id: ID!) {\n        factCardReferral(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject16 = _taggedTemplateLiteral(['\n    query factCardsReferral($id: ID!) {\n        factCardsReferral(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query factCardsReferral($id: ID!) {\n        factCardsReferral(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject17 = _taggedTemplateLiteral(['\n    query externalLink($id: ID!) {\n        externalLink(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query externalLink($id: ID!) {\n        externalLink(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject18 = _taggedTemplateLiteral(['\n    query orderQuestion($id: ID!) {\n        orderQuestion(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query orderQuestion($id: ID!) {\n        orderQuestion(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject19 = _taggedTemplateLiteral(['\n    query moodboard($id: ID!) {\n        moodboard(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query moodboard($id: ID!) {\n        moodboard(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject20 = _taggedTemplateLiteral(['\n    query textCheckQuestion($id: ID!) {\n        textCheckQuestion(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query textCheckQuestion($id: ID!) {\n        textCheckQuestion(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject21 = _taggedTemplateLiteral(['\n    query imageCheckQuestion($id: ID!) {\n        imageCheckQuestion(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query imageCheckQuestion($id: ID!) {\n        imageCheckQuestion(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject22 = _taggedTemplateLiteral(['\n    query imageAndTextCheckQuestion($id: ID!) {\n        imageAndTextCheckQuestion(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query imageAndTextCheckQuestion($id: ID!) {\n        imageAndTextCheckQuestion(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject23 = _taggedTemplateLiteral(['\n    query statementQuestion($id: ID!) {\n        statementQuestion(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query statementQuestion($id: ID!) {\n        statementQuestion(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject24 = _taggedTemplateLiteral(['\n    query choiceQuestion($id: ID!) {\n        choiceQuestion(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query choiceQuestion($id: ID!) {\n        choiceQuestion(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject25 = _taggedTemplateLiteral(['\n    query imageWithSubtitle($id: ID!) {\n        imageWithSubtitle(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query imageWithSubtitle($id: ID!) {\n        imageWithSubtitle(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject26 = _taggedTemplateLiteral(['\n    query whatIsWhatQuestion($id: ID!) {\n        whatIsWhatQuestion(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query whatIsWhatQuestion($id: ID!) {\n        whatIsWhatQuestion(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject27 = _taggedTemplateLiteral(['\n    query titleAndText($id: ID!) {\n        titleAndText(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query titleAndText($id: ID!) {\n        titleAndText(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject28 = _taggedTemplateLiteral(['\n    query titleAndTextImage($id: ID!) {\n        titleAndTextImage(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query titleAndTextImage($id: ID!) {\n        titleAndTextImage(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject29 = _taggedTemplateLiteral(['\n    query fileDownload($id: ID!) {\n        fileDownload(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query fileDownload($id: ID!) {\n        fileDownload(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject30 = _taggedTemplateLiteral(['\n    query sortQuestion($id: ID!) {\n        sortQuestion(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query sortQuestion($id: ID!) {\n        sortQuestion(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject31 = _taggedTemplateLiteral(['\n    query chatBubble($id: ID!) {\n        chatBubble(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query chatBubble($id: ID!) {\n        chatBubble(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject32 = _taggedTemplateLiteral(['\n    query imageAndTextMPC($id: ID!) {\n        imageAndTextMPC(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query imageAndTextMPC($id: ID!) {\n        imageAndTextMPC(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject33 = _taggedTemplateLiteral(['\n    query hotspot($id: ID!) {\n        hotspot(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query hotspot($id: ID!) {\n        hotspot(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject34 = _taggedTemplateLiteral(['\n    query scene($id: ID!) {\n        scene(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query scene($id: ID!) {\n        scene(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject35 = _taggedTemplateLiteral(['\n    query hotspotMap($id: ID!) {\n        hotspotMap(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query hotspotMap($id: ID!) {\n        hotspotMap(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject36 = _taggedTemplateLiteral(['\n    query referralElement($id: ID!) {\n        referralElement(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query referralElement($id: ID!) {\n        referralElement(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject37 = _taggedTemplateLiteral(['\n    query roadmap($id: ID!) {\n        roadmap(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query roadmap($id: ID!) {\n        roadmap(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject38 = _taggedTemplateLiteral(['\n    query openQuestion($id: ID!) {\n        openQuestion(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query openQuestion($id: ID!) {\n        openQuestion(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject39 = _taggedTemplateLiteral(['\n    query pollQuestion($id: ID!) {\n        pollQuestion(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query pollQuestion($id: ID!) {\n        pollQuestion(id: $id) {\n            ', '\n        }\n    }\n']),
    _templateObject40 = _taggedTemplateLiteral(['\n    query processStep($id: ID!) {\n        processStep(id: $id) {\n            ', '\n        }\n    }\n'], ['\n    query processStep($id: ID!) {\n        processStep(id: $id) {\n            ', '\n        }\n    }\n']);

var _graphqlTag = require('graphql-tag');

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

var _graphQlHelpers = require('../graphQlHelpers');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_GLOBAL_SETTINGS = exports.GQL_FETCH_GLOBAL_SETTINGS = (0, _graphqlTag2.default)(_templateObject, _graphQlHelpers.settings);

var GQL_DELETE_WORKFORM_RESULT = exports.GQL_DELETE_WORKFORM_RESULT = (0, _graphqlTag2.default)(_templateObject2);

var GQL_CREATE_ELEMENT_RESULT = exports.GQL_CREATE_ELEMENT_RESULT = (0, _graphqlTag2.default)(_templateObject3);

var GQL_FETCH_ELEMENT_RESULTS = exports.GQL_FETCH_ELEMENT_RESULTS = (0, _graphqlTag2.default)(_templateObject4, _graphQlHelpers.elementResult);

var GQL_CREATE_WORKFORM_RESULT = exports.GQL_CREATE_WORKFORM_RESULT = (0, _graphqlTag2.default)(_templateObject5);

var GQL_FETCH_WORKFORM_RESULT = exports.GQL_FETCH_WORKFORM_RESULT = (0, _graphqlTag2.default)(_templateObject6, _graphQlHelpers.workformsResult);

var GQL_FETCH_CRITERIA = exports.GQL_FETCH_CRITERIA = (0, _graphqlTag2.default)(_templateObject7, _graphQlHelpers.criteria);

var GQL_FETCH_ASK_FEEDBACK = exports.GQL_FETCH_ASK_FEEDBACK = (0, _graphqlTag2.default)(_templateObject8, _graphQlHelpers.askFeedback);

var GQL_FETCH_PROGRESSION_FEEDBACK = exports.GQL_FETCH_PROGRESSION_FEEDBACK = (0, _graphqlTag2.default)(_templateObject9, _graphQlHelpers.progressionFeedback);

var GQL_FETCH_TEXT_MPC = exports.GQL_FETCH_TEXT_MPC = (0, _graphqlTag2.default)(_templateObject10, _graphQlHelpers.textMPC);

var GQL_FETCH_TEXT_MPC_WITH_IMAGE = exports.GQL_FETCH_TEXT_MPC_WITH_IMAGE = (0, _graphqlTag2.default)(_templateObject11, _graphQlHelpers.textMPCWithImage);

var GQL_FETCH_SHORT_TEXT = exports.GQL_FETCH_SHORT_TEXT = (0, _graphqlTag2.default)(_templateObject12, _graphQlHelpers.shortText);

var GQL_FETCH_VIDEO_WITH_TITLE = exports.GQL_FETCH_VIDEO_WITH_TITLE = (0, _graphqlTag2.default)(_templateObject13, _graphQlHelpers.videoWithTitle);

var GQL_FETCH_IMAGE_MPC = exports.GQL_FETCH_IMAGE_MPC = (0, _graphqlTag2.default)(_templateObject14, _graphQlHelpers.imageMPC);

var GQL_FETCH_FACTCARD_REFERRAL = exports.GQL_FETCH_FACTCARD_REFERRAL = (0, _graphqlTag2.default)(_templateObject15, _graphQlHelpers.factCardReferral);

var GQL_FETCH_FACTCARDS_REFERRAL = exports.GQL_FETCH_FACTCARDS_REFERRAL = (0, _graphqlTag2.default)(_templateObject16, _graphQlHelpers.factCardsReferral);

var GQL_FETCH_EXTERNAL_LINK = exports.GQL_FETCH_EXTERNAL_LINK = (0, _graphqlTag2.default)(_templateObject17, _graphQlHelpers.externalLink);

var GQL_FETCH_ORDER_QUESTION = exports.GQL_FETCH_ORDER_QUESTION = (0, _graphqlTag2.default)(_templateObject18, _graphQlHelpers.orderQuestion);

var GQL_FETCH_MOODBOARD = exports.GQL_FETCH_MOODBOARD = (0, _graphqlTag2.default)(_templateObject19, _graphQlHelpers.moodboard);

var GQL_FETCH_TEXT_CHECK_QUESTION = exports.GQL_FETCH_TEXT_CHECK_QUESTION = (0, _graphqlTag2.default)(_templateObject20, _graphQlHelpers.textCheckQuestion);

var GQL_FETCH_IMAGE_CHECK_QUESTION = exports.GQL_FETCH_IMAGE_CHECK_QUESTION = (0, _graphqlTag2.default)(_templateObject21, _graphQlHelpers.imageCheckQuestion);

var GQL_FETCH_TEXT_IMAGE_CHECK_QUESTION = exports.GQL_FETCH_TEXT_IMAGE_CHECK_QUESTION = (0, _graphqlTag2.default)(_templateObject22, _graphQlHelpers.imageAndTextCheckQuestion);

var GQL_FETCH_STATEMENT_QUESTION = exports.GQL_FETCH_STATEMENT_QUESTION = (0, _graphqlTag2.default)(_templateObject23, _graphQlHelpers.statementQuestion);

var GQL_FETCH_CHOICE_QUESTION = exports.GQL_FETCH_CHOICE_QUESTION = (0, _graphqlTag2.default)(_templateObject24, _graphQlHelpers.choiceQuestion);

var GQL_FETCH_IMAGE_WITH_SUBTITLE = exports.GQL_FETCH_IMAGE_WITH_SUBTITLE = (0, _graphqlTag2.default)(_templateObject25, _graphQlHelpers.imageWithSubtitle);

var GQL_FETCH_WHAT_IS_WHAT_QUESTION = exports.GQL_FETCH_WHAT_IS_WHAT_QUESTION = (0, _graphqlTag2.default)(_templateObject26, _graphQlHelpers.whatIsWhatQuestion);

var GQL_FETCH_TITLE_AND_TEXT = exports.GQL_FETCH_TITLE_AND_TEXT = (0, _graphqlTag2.default)(_templateObject27, _graphQlHelpers.titleAndText);

var GQL_FETCH_TITLE_AND_TEXT_IMAGE = exports.GQL_FETCH_TITLE_AND_TEXT_IMAGE = (0, _graphqlTag2.default)(_templateObject28, _graphQlHelpers.titleAndTextImage);

var GQL_FETCH_CMS_WORK_FORM_FILE_DOWNLOAD = exports.GQL_FETCH_CMS_WORK_FORM_FILE_DOWNLOAD = (0, _graphqlTag2.default)(_templateObject29, _graphQlHelpers.fileDownload);

var GQL_FETCH_SORT_QUESTION = exports.GQL_FETCH_SORT_QUESTION = (0, _graphqlTag2.default)(_templateObject30, _graphQlHelpers.sortQuestion);

var GQL_FETCH_CHAT_BUBBLE = exports.GQL_FETCH_CHAT_BUBBLE = (0, _graphqlTag2.default)(_templateObject31, _graphQlHelpers.chatBubble);

var GQL_FETCH_IMAGE_AND_TEXT_MPC = exports.GQL_FETCH_IMAGE_AND_TEXT_MPC = (0, _graphqlTag2.default)(_templateObject32, _graphQlHelpers.imageAndTextMPC);

var GQL_FETCH_HOTSPOT = exports.GQL_FETCH_HOTSPOT = (0, _graphqlTag2.default)(_templateObject33, _graphQlHelpers.hotspot);

var GQL_FETCH_SCENE = exports.GQL_FETCH_SCENE = (0, _graphqlTag2.default)(_templateObject34, _graphQlHelpers.scene);

var GQL_FETCH_HOTSPOTMAP = exports.GQL_FETCH_HOTSPOTMAP = (0, _graphqlTag2.default)(_templateObject35, _graphQlHelpers.hotspotMap);

var GQL_FETCH_ELEMENT_REFERRAL = exports.GQL_FETCH_ELEMENT_REFERRAL = (0, _graphqlTag2.default)(_templateObject36, _graphQlHelpers.referralElement);

var GQL_FETCH_ROADMAP = exports.GQL_FETCH_ROADMAP = (0, _graphqlTag2.default)(_templateObject37, _graphQlHelpers.roadmap);

var GQL_FETCH_OPEN_QUESTION = exports.GQL_FETCH_OPEN_QUESTION = (0, _graphqlTag2.default)(_templateObject38, _graphQlHelpers.openQuestion);

var GQL_FETCH_POLL_QUESTION = exports.GQL_FETCH_POLL_QUESTION = (0, _graphqlTag2.default)(_templateObject39, _graphQlHelpers.pollQuestion);

var GQL_FETCH_PROCESS_STEP = exports.GQL_FETCH_PROCESS_STEP = (0, _graphqlTag2.default)(_templateObject40, _graphQlHelpers.processStep);