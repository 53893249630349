import {
    SHOW_SNACKBAR_MESSAGE,
    PROCESS_SNACKBAR_MESSAGE,
    CLOSE_SNACKBAR,
} from './actions';

const initialState = {
    current: {},
    open: false,
    queue: [],
};

export const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case SHOW_SNACKBAR_MESSAGE: {
            const newQueue = [...state.queue, action.payload.message];
            const nextMessage = !state.open ? newQueue.shift() : state.current;
            let batchCount = nextMessage.batchCount;

            if (state.open) {
                let followingMessage = newQueue.shift();

                while (
                    nextMessage &&
                    followingMessage &&
                    followingMessage.variant === nextMessage.variant &&
                    followingMessage.text === nextMessage.text
                ) {
                    batchCount++;
                    followingMessage = newQueue.shift();
                }

                if (followingMessage) {
                    newQueue.unshift(followingMessage);
                }
            }

            return {
                ...state,
                open: batchCount > nextMessage.batchCount ? true : !state.open,
                queue: newQueue,
                current: { ...nextMessage, batchCount },
            };
        }
        case PROCESS_SNACKBAR_MESSAGE: {
            const newQueue = [...state.queue];
            const nextMessage = newQueue.shift();
            let batchCount = 1;
            let followingMessage = newQueue.shift();

            while (
                nextMessage &&
                followingMessage &&
                followingMessage.variant === nextMessage.variant &&
                followingMessage.text === nextMessage.text
            ) {
                batchCount++;
                followingMessage = newQueue.shift();
            }

            if (followingMessage) {
                newQueue.unshift(followingMessage);
            }

            return {
                ...state,
                current: nextMessage ? { ...nextMessage, batchCount } : {},
                queue: newQueue,
                open: nextMessage ? true : state.open,
            };
        }
        case CLOSE_SNACKBAR: {
            return {
                ...state,
                open: false,
            };
        }
        default:
            return state;
    }
};

export default reducer;
