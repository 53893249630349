'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _styles = require('@material-ui/styles');

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactI18next = require('react-i18next');

var _AccountWrapper = require('../AccountWrapper');

var _AccountWrapper2 = _interopRequireDefault(_AccountWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useStyles = (0, _styles.makeStyles)(function (theme) {
	return {
		content: {
			textAlign: 'center',
			display: 'block'
		}
	};
});

var SsoPage = function SsoPage(props) {
	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var classes = useStyles();
	var ssoUrl = process.env.REACT_APP_SSO_URL || '';

	return _react2.default.createElement(
		_AccountWrapper2.default,
		{ title: t('app.account.sso.title'), introduction: t('app.account.sso.intro'), wrapper: 'medium' },
		_react2.default.createElement(
			'a',
			{ className: classes.content, href: ssoUrl },
			t('app.account.sso.link')
		)
	);
};

exports.default = SsoPage;