'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _recompose = require('recompose');

var _selectors = require('../ProgramsDropdown/selectors');

var _actions = require('../ProgramsDropdown/actions');

var _actions2 = require('../GroupsDropdown/actions');

var _selectors2 = require('@manakin/authentication/selectors');

var _selectors3 = require('../GroupsDropdown/selectors');

var _reactRedux = require('react-redux');

var _graphql = require('./graphql');

var _reactApollo = require('react-apollo');

var _cloneDeep = require('lodash/cloneDeep');

var _cloneDeep2 = _interopRequireDefault(_cloneDeep);

var _actions3 = require('@manakin/authentication/actions');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var BoxService = function BoxService(props) {
    var program = props.program,
        data = props.data,
        children = props.children,
        _props$BOX_RESULTS = props.BOX_RESULTS,
        BOX_RESULTS = _props$BOX_RESULTS === undefined ? {} : _props$BOX_RESULTS,
        selectProgram = props.selectProgram;

    var loading = data.loading,
        _data$appUser = data.appUser,
        _appUser = _data$appUser === undefined ? {} : _data$appUser;

    var boxResultsLoading = BOX_RESULTS.loading,
        _BOX_RESULTS$boxResul = BOX_RESULTS.boxResults,
        boxResults = _BOX_RESULTS$boxResul === undefined ? [] : _BOX_RESULTS$boxResul;

    //state hooks

    var _useState = (0, _react.useState)([]),
        _useState2 = _slicedToArray(_useState, 2),
        boxes = _useState2[0],
        setBoxes = _useState2[1];

    var _useState3 = (0, _react.useState)([]),
        _useState4 = _slicedToArray(_useState3, 2),
        milestones = _useState4[0],
        setMilestones = _useState4[1];

    //effect hooks

    // useEffect(() => {
    //   if (_appUser.licenses && _appUser.licenses.length && !loading) {
    //     let hasProgram = false;

    //     const options = _appUser.licenses.map(item => {
    //       const obj = {
    //         id: item.licenseGroup.product.program.id,
    //         label: item.licenseGroup.product.program.name
    //       };

    //       if (program === item.licenseGroup.product.program.id) {
    //         hasProgram = true;
    //       }

    //       return obj;
    //     });

    //     if (hasProgram) {
    //       selectProgram(program);
    //     } else if (options.length) {
    //       selectProgram(options[0].id);
    //     } else {
    //       selectProgram(null);
    //     }
    //   } else {
    //     selectProgram(null);
    //   }
    // }, [loading]);

    (0, _react.useEffect)(function () {
        if (!loading && !boxResultsLoading) {
            handleProgram();
        }
    }, [loading, boxResultsLoading]);

    //functions
    var handleProgram = function handleProgram() {
        var boxes = [];
        var milestones = [];

        if (_appUser) {
            _appUser.schoolClasses && _appUser.schoolClasses.forEach(function (item) {
                var product = item.products && item.products.find(function (product) {
                    return product.program && product.program.id === program;
                });

                if (product && product.program) {
                    if (product.boxes.length) {
                        boxes = [].concat(_toConsumableArray(product.boxes));
                    }
                    if (item.milestones.length) {
                        milestones = [].concat(_toConsumableArray(item.milestones));
                    }
                }
            });
        }
        setBoxes(boxes);
        setMilestones(milestones);

        if (program && typeof program === 'string') fetchBoxes();
    };

    var fetchBoxes = function fetchBoxes() {
        if (props.onLoadChange) props.onLoadChange(true);
        props.client.query({
            query: _graphql.GQL_FETCH_PROGRAM,
            variables: { id: program }
        }).then(function (result) {
            if (!result.errors && result.data) {
                getBoxResults(result.data);
            } else {
                if (props.onLoadChange) props.onLoadChange(false);
            }
        }, function (error) {
            if (props.onLoadChange) props.onLoadChange(false);
        });
    };

    var getBoxResults = function getBoxResults(_data) {
        if (props.onLoadChange) props.onLoadChange(false);
        var _program = (0, _cloneDeep2.default)(_data);
        var resumeBox = null;

        _program.program.boxes && _program.program.boxes.forEach(function (item) {
            item.forEach(function (box) {
                if (boxResults.some(function (r) {
                    return r.box.id == box.id && r.finished;
                })) {
                    box['isFinished'] = true;
                }
            });
        });

        if (props.onNewData) {
            if (_appUser.boxId && _appUser.programId) {
                if (_appUser.programId === _program.program.id) {
                    _program.program.boxes && _program.program.boxes.forEach(function (_box) {
                        return _box.forEach(function (box) {
                            if (box.id === _appUser.boxId) {
                                resumeBox = _extends({}, box, {
                                    time: _appUser.elementId
                                });
                            }
                        });
                    });
                }
            }

            props.onNewData(_program, null, resumeBox);
        }
    };

    return _react2.default.createElement(
        _react2.default.Fragment,
        null,
        children
    );
};

exports.default = (0, _recompose.compose)((0, _reactRedux.connect)(function (state) {
    return {
        program: (0, _selectors.getProgram)(state),
        appUser: (0, _selectors2.getAppUser)(state),
        group: (0, _selectors3.getGroup)(state),
        config: state.config
    };
}, function (dispatch) {
    return {
        selectProgram: function selectProgram(program) {
            return dispatch((0, _actions.selectProgram)(program));
        },
        selectGroup: function selectGroup(group) {
            return dispatch((0, _actions2.selectGroup)(group));
        },
        onLogin: function onLogin(user) {
            return dispatch((0, _actions3.login)('/', user));
        }
    };
}), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_APP_USER, {
    options: function options(props) {
        return {
            variables: {
                id: props.appUser && props.appUser.id
            }
        };
    }
}), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_BOX_RESULTS, {
    name: 'BOX_RESULTS',
    options: function options(props) {
        return {
            variables: {
                program: props.program,
                user: props.appUser && props.appUser.id
            }
        };
    }
}), _reactApollo.withApollo)(BoxService);