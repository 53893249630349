"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// TODO temporarily until the ticket from the refactor is done. Boolean values should be parsed to prevent
//  the component from having to manually decided whether it is true or "true"
var useBooleanSetting = function useBooleanSetting(settings) {
    var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    var returnLoading = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

    if (settings === undefined || settings === null) {
        return returnLoading ? "loading" : null;
    } else {
        var _obj = settings.settings && settings.settings.reduce(function (obj, item) {
            obj[item.name] = item.value === "true";
            return obj;
        }, {});

        if (key && _obj && _obj[key]) {
            return _obj[key];
        }

        return null;
    }
};

exports.default = useBooleanSetting;