import { useQuery } from '@apollo/react-hooks';
import React from 'react';
import { Loader } from '@manakin/app-core';
import { GQL_CURRENT_APP_USER, GQL_FETCH_GROUPS } from '../graphql';
import CheckBrinCode from './CheckBrinCode';
import 'url-search-params-polyfill';
import { useLocation } from 'react-router-dom';
import { Redirect } from 'react-router';

const GateKeeper = (props) => {
	const { data, loading } = useQuery(GQL_CURRENT_APP_USER);
    const { currentAppUser = {} } = data || {};
    const { data: groupsData, loading: groupsLoading } = useQuery(GQL_FETCH_GROUPS, {
		variables: {
			filter: {
				manager: currentAppUser.id,
				teacher: currentAppUser.id,
				student: currentAppUser.id,
				types: ['School'],
			},
			page: 0,
			pagesize: 999,
		},
	});
    const {groups = {}} = groupsData || {}
    const location = useLocation()
    const params = new URLSearchParams(location.search);
    let externalIds = params.get('brincodes') || '';

	if (loading || groupsLoading || !groupsData) return <Loader fullScreen />;
	if (groups && (!groups.groups || groups.groups.length < 1) && currentAppUser.id) {
		return <CheckBrinCode id={currentAppUser.id} externalIds={externalIds.split(',')} />;
	}
	if (currentAppUser && currentAppUser.id && groups && groups.groups && groups.groups.length) {
		return <Redirect to={`/auth/sso/${currentAppUser.id}`} />;
	}
	return <Redirect to="/auth/sso/error" />;
};

export default GateKeeper;
