'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});
var useArrayToObject = function useArrayToObject(array) {
	var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
	var value = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'value';

	if (array === undefined || array === null) {
		return {};
	} else {
		var _obj = array.reduce(function (obj, item) {
			obj[item[key]] = item[value];
			return obj;
		}, {});
		return _obj;
	}
};

exports.default = useArrayToObject;