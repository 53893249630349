export const styles = (theme) => ({
	wrapper: {
		...theme.manakin.defaultWrapper,
		[theme.breakpoints.up("md")]: {
			padding: 0,
			marginBottom: "3rem",
		},
	},
	answersHeader: {
		marginTop: "3rem",
	},
	checkBoxGroup: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(5),
	},
	textFieldInput: {
		minHeight: 0,
	},
	hidden: {
		display: "none",
	},
	saved: {
		width: "100%",
		padding: "10px",
		marginTop: "20px",
		backgroundColor: theme.palette.success.light,
		color: theme.palette.success.main,
		border: `1px solid ${theme.palette.success.main}`,
	},
});
