'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styles = require('@material-ui/core/styles');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _CircularProgress = require('@material-ui/core/CircularProgress');

var _CircularProgress2 = _interopRequireDefault(_CircularProgress);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = function styles(theme) {
    return {
        root: {
            width: '100%',
            textAlign: 'center'
        },
        light: {
            color: 'white'
        },
        progress: {},
        full: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            '& $progress': {
                position: 'absolute',
                left: 'calc(50% - 20px)',
                top: 'calc(50% - 20px)'
            }
        }
    };
};

var Loader = function (_React$PureComponent) {
    _inherits(Loader, _React$PureComponent);

    function Loader() {
        _classCallCheck(this, Loader);

        return _possibleConstructorReturn(this, (Loader.__proto__ || Object.getPrototypeOf(Loader)).apply(this, arguments));
    }

    _createClass(Loader, [{
        key: 'render',
        value: function render() {
            var _props = this.props,
                classes = _props.classes,
                _props$color = _props.color,
                color = _props$color === undefined ? 'dark' : _props$color,
                _props$fullScreen = _props.fullScreen,
                fullScreen = _props$fullScreen === undefined ? false : _props$fullScreen,
                _props$fullscreen = _props.fullscreen,
                fullscreen = _props$fullscreen === undefined ? false : _props$fullscreen;


            return _react2.default.createElement(
                'div',
                {
                    className: (0, _classnames2.default)(classes.root, _defineProperty({}, classes.full, fullScreen || fullscreen))
                },
                _react2.default.createElement(_CircularProgress2.default, {
                    disableShrink: true,
                    className: (0, _classnames2.default)(classes.progress, _defineProperty({}, classes.light, color == 'light'))
                })
            );
        }
    }]);

    return Loader;
}(_react2.default.PureComponent);

exports.default = (0, _styles.withStyles)(styles, { name: 'AppLoader' })(Loader);