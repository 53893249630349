'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styles = require('@material-ui/core/styles');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _objectFitImages = require('object-fit-images');

var _objectFitImages2 = _interopRequireDefault(_objectFitImages);

var _appCore = require('@manakin/app-core');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
	return {
		image: {
			width: '100%',
			height: '100%',
			objectFit: 'cover',
			fontFamily: "'object-fit: cover'"
		},
		autoHeight: {
			height: 'auto'
		},
		alignRight: {
			objectPosition: 'right'
		},
		objectFitContain: {
			objectFit: 'contain',
			fontFamily: "'object-fit: contain'",
			objectPosition: 'center'
		},
		bottomCenter: {
			objectPosition: 'bottom'
		}
	};
};

var ProgressiveImage = function ProgressiveImage(props) {
	var _classNames2;

	var isMounted = _react2.default.useRef(true);
	var image = props.image,
	    classes = props.classes,
	    _props$autoHeight = props.autoHeight,
	    autoHeight = _props$autoHeight === undefined ? false : _props$autoHeight,
	    _props$align = props.align,
	    align = _props$align === undefined ? 'default' : _props$align,
	    _props$objectFitConta = props.objectFitContain,
	    objectFitContain = _props$objectFitConta === undefined ? false : _props$objectFitConta,
	    _props$enableFocusPoi = props.enableFocusPoint,
	    enableFocusPoint = _props$enableFocusPoi === undefined ? false : _props$enableFocusPoi,
	    asset = props.asset,
	    bottomCenter = props.bottomCenter;

	//state hooks

	var _useState = (0, _react.useState)(props.preview),
	    _useState2 = _slicedToArray(_useState, 2),
	    currentImage = _useState2[0],
	    setCurrentImage = _useState2[1];

	var _useState3 = (0, _react.useState)(true),
	    _useState4 = _slicedToArray(_useState3, 2),
	    loading = _useState4[0],
	    setLoading = _useState4[1];

	//effect hooks


	(0, _react.useEffect)(function () {
		fetchImage(props.image);
		(0, _objectFitImages2.default)();
	}, []);

	(0, _react.useEffect)(function () {
		if (isMounted) {
			setCurrentImage(props.preview);
			setLoading(true);
		}
		return function () {
			return isMounted.current = false;
		};
	}, [image]);

	(0, _react.useEffect)(function () {
		fetchImage(image);

		return function () {
			return isMounted.current = false;
		};
	}, [currentImage]);

	//functions
	var fetchImage = function fetchImage(src) {
		var image = new Image();
		image.onload = function () {
			if (isMounted) {
				setCurrentImage(image.src);
				setLoading(false);
			}
		};
		image.src = src;
	};

	var style = function style(_loading) {
		return {
			transition: '.4s filter linear',
			transitionDelay: '.4s',
			filter: '' + (_loading ? 'blur(6px)' : '')
		};
	};

	if (enableFocusPoint) {
		var _classNames;

		return _react2.default.createElement(
			_react2.default.Fragment,
			null,
			currentImage && _react2.default.createElement(_appCore.ImageWithFocusPoint, {
				imageProps: {
					style: style(loading),
					className: (0, _classnames2.default)(classes.image, (_classNames = {}, _defineProperty(_classNames, classes.autoHeight, autoHeight), _defineProperty(_classNames, classes.alignRight, align === 'right'), _defineProperty(_classNames, classes.objectFitContain, objectFitContain), _defineProperty(_classNames, classes.bottomCenter, bottomCenter), _classNames))
				},
				asset: asset,
				src: currentImage
			})
		);
	}

	return _react2.default.createElement(
		_react2.default.Fragment,
		null,
		currentImage && _react2.default.createElement('img', {
			style: style(loading),
			className: (0, _classnames2.default)(classes.image, (_classNames2 = {}, _defineProperty(_classNames2, classes.autoHeight, autoHeight), _defineProperty(_classNames2, classes.alignRight, align === 'right'), _defineProperty(_classNames2, classes.objectFitContain, objectFitContain), _classNames2)),
			src: currentImage
		})
	);
};

exports.default = (0, _styles.withStyles)(styles, { name: 'appProgressiveImage' })(ProgressiveImage);