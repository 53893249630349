import gql from 'graphql-tag';

export const GQL_FETCH_SETTINGS = gql`
	query settingsLoginHeader {
		settings {
			settings {
				name
				value
				file {
					id
					url
				}
			}
		}
	}
`;
