import React from "react";
import classNames from "classnames";
import AsyncSelect from "react-select/async";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import CancelIcon from "@material-ui/icons/Cancel";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import { compose } from "recompose";
import { graphql } from "react-apollo";
import { withApollo } from "react-apollo";
import { GQL_FETCH_TAGS, GQL_CREATE_TAG } from "../graphql";
import { connect } from "react-redux";
import { showSnackbarMessage } from "@manakin/core/actions";
import { withTranslation } from "react-i18next";

const styles = (theme) => ({
	root: {
		flex: "1 0 auto",
		transition: "background-color 200ms linear",
		backgroundColor: "transparent",
		"& *": { opacity: 1, pointerEvents: "auto" },
		flexGrow: 1,
		height: "auto",
		margin: theme.manakin.normalMargin,
	},
	loading: {
		height: theme.spacing(6),
		backgroundColor: theme.palette.grey[100],
		"& *": { opacity: 0, pointerEvents: "none" },
	},
	input: {
		display: "flex",
		height: "auto",
	},
	valueContainer: {
		display: "flex",
		flexWrap: "wrap",
		flex: 1,
		alignItems: "center",
	},
	chip: {
		margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
	},
	chipFocused: {
		backgroundColor: emphasize(
			theme.palette.type === "light"
				? theme.palette.grey[300]
				: theme.palette.grey[700],
			0.08
		),
	},
	noOptionsMessage: {
		padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
	},
	singleValue: {
		fontSize: 16,
	},
	placeholder: {
		position: "absolute",
		left: 2,
		fontSize: 16,
	},
	paper: {
		position: "absolute",
		zIndex: 1,
		marginTop: theme.spacing(1),
		left: 0,
		right: 0,
	},
	divider: {
		height: theme.spacing(2),
	},
	disabledLabel: {
		color: theme.manakin.disabledFieldLabelColor,
	},
	disabledInput: {
		color: theme.manakin.disabledFieldTextColor,
	},
});

function NoOptionsMessage(props) {
	return (
		<Typography
			color="textSecondary"
			className={props.selectProps.classes.noOptionsMessage}
			{...props.innerProps}
		>
			{props.children}
		</Typography>
	);
}

function inputComponent({ inputRef, ...props }) {
	return <div ref={inputRef} {...props} />;
}

function Control(props) {
	const classes = props.selectProps.classes;

	return (
		<TextField
			fullWidth
			InputProps={{
				inputComponent,
				inputProps: {
					className: classNames(classes.input, {
						[classes.disabledInput]: props.isDisabled,
					}),
					inputRef: props.innerRef,
					children: props.children,
					...props.innerProps,
				},
			}}
			{...props.selectProps.textFieldProps}
			disabled={props.isDisabled}
		/>
	);
}

function Option(props) {
	return (
		<MenuItem
			buttonRef={props.innerRef}
			selected={props.isFocused}
			component="div"
			style={{
				fontWeight: props.isSelected ? 500 : 400,
			}}
			onMouseDown={props.innerProps.onClick}
			{...props.innerProps}
			onClick={null}
		>
			{props.children}
		</MenuItem>
	);
}

function Placeholder(props) {
	return (
		<Typography
			color="textSecondary"
			className={classNames(props.selectProps.classes.placeholder, {
				[props.selectProps.classes.disabledInput]: props.isDisabled,
			})}
			{...props.innerProps}
		>
			{props.children}
		</Typography>
	);
}

function SingleValue(props) {
	return (
		<Typography
			className={props.selectProps.classes.singleValue}
			{...props.innerProps}
		>
			{props.children}
		</Typography>
	);
}

function ValueContainer(props) {
	return (
		<div className={props.selectProps.classes.valueContainer}>
			{props.children}
		</div>
	);
}

function MultiValue(props) {
	return (
		<Chip
			tabIndex={-1}
			label={props.children}
			className={classNames(props.selectProps.classes.chip, {
				[props.selectProps.classes.chipFocused]: props.isFocused,
			})}
			onDelete={props.isDisabled ? null : props.removeProps.onClick}
			deleteIcon={<CancelIcon {...props.removeProps} />}
		/>
	);
}

function Menu(props) {
	return (
		<Paper
			square
			className={props.selectProps.classes.paper}
			{...props.innerProps}
		>
			{props.children}
		</Paper>
	);
}

const components = {
	Control,
	Menu,
	MultiValue,
	NoOptionsMessage,
	Option,
	Placeholder,
	SingleValue,
	ValueContainer,
};

class SearchTags extends React.PureComponent {
	state = {
		value: [],
	};

    componentDidMount() {
        this.setStartValue();
    }

	componentDidUpdate(prevProps) {
		if (prevProps.loading && !this.props.loading) {
            this.setStartValue()
		}
	}

    setStartValue = () => {
        const { name, form, initialValue } = this.props;
        const _value =
            initialValue &&
            initialValue.map((value) => ({
                value: value.value || value.id,
                label: value.label || value.name,
            }));

        this.setState({ value: _value });
        form.onFieldChange({
            key: name,
            value: _value,
        });   
    }

	promiseOptions = (inputValue) => {
		return this.props.client
			.query({
				query: GQL_FETCH_TAGS,
				variables: {
					page: 0,
					pagesize: 99,
					search: inputValue || "",
				},
			})
			.then((result) => {
				return result.data.tags.tags.map((r) => ({
					value: r.id,
					label: r.name,
				}));
			});
	};

	handleChange = (value, actionMeta) => {
		const { form, onCreateTag, mutate, name } = this.props;

		if (actionMeta.action == "create-option") {
			/*
           * Removed
           *
           const lastVal = value[value.length - 1].label;
            mutate({
                variables: {
                    name: lastVal,
                },
            }).then((result) => {
                if (result.data) {
                    const val = {
                        value: result.data.createTag.tag.id,
                        label: result.data.createTag.tag.name,
                    };
                    let _value = [...value];
                    _value[value.length - 1] = val;
                    form.onFieldChange({
                        key: name,
                        value: _value.map((v) => ({
                            id: v.value,
                            name: v.label,
                        })),
                    });
                    this.setState({ value: _value });
                }
            });
            */
		} else if (actionMeta.action == "remove-value") {
			const newValue = this.state.value.filter(
				(v) => v.value !== actionMeta.removedValue.value
			);

			this.setState({
				value: newValue,
			});
			form.onFieldChange({
				key: name,
				value: newValue.map((v) => ({ value: v.value, name: v.label })),
			});
		} else {
			this.setState({ value: value || [] });
			form.onFieldChange({
				key: name,
				value: value ? value.map((v) => ({ value: v.value, name: v.label })) : [],
			});
		}
	};

	render() {
		const {
			classes,
			loading,
			form,
			label,
			className: classNameProp,
			readOnly = false,
			closeMenuOnSelect = false,
			t
		} = this.props;
        const {value = []} = this.state;
		const { errors, schema } = form;
		const className = classNames(
			classes.root,
			{ [classes.loading]: loading },
			classNameProp
		);

		return (
			<div className={className}>
				<AsyncSelect
					classes={classes}
					isMulti
					isClearable
					required={(schema[name] || {}).required}
					isDisabled={readOnly}
					closeMenuOnSelect={closeMenuOnSelect}
					defaultOptions
					formatCreateLabel={(userInput) => t("core.search-tags.create-label", { name: userInput })}
					textFieldProps={{
						...(errors[name] &&
							errors[name].length > 0 && {
								error: errors[name][0],
							}),
						label: label || t("core.search-tags.fields.tags.label"),
						required: (schema[name] || {}).required,
						InputLabelProps: {
							shrink: true,
							...(readOnly && {
								classes: {
									root: classes.disabledLabel,
								},
							}),
						},
						readOnly: readOnly,
					}}
					loadOptions={this.promiseOptions}
					components={components}
					onChange={this.handleChange}
					placeholder={t("core.search-tags.fields.tags.placeholder")}
					value={value}
					menuPortalTarget={document.body}
					styles={{
						menuPortal: (base) => ({
							...base,
							zIndex: "1301 !important",
						}),
					}}
				/>
			</div>
		);
	}
}

export default compose(
	graphql(GQL_CREATE_TAG),
	withTranslation(),
	connect(null, (dispatch) => ({
		processSucces: (t) => {
			dispatch(
				showSnackbarMessage({
					text: t("core.search-tags.save-success"),
					variant: "success",
				})
			);
		},
	})),
	withApollo,
	withStyles(styles, { withTheme: true })
)(SearchTags);
