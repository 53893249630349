import { combineReducers } from 'redux';
import appMenuReducer from './AppMenu/reducer';
import appBarReducer from './AppBar/reducer';
import snackbarMessengerReducer from './SnackbarMessenger/reducer';
import assetsViewerReducer from './AssetsViewer/reducer';
import dialogReducer from './Dialog/reducer';
import breadcrumbsReducer from './Breadcrumbs/reducer';
import formReducer from './form/reducer';
import libReducer from './lib/reducer';
import { APP_MENU_STATE_ROOT } from './AppMenu/selectors';
import { APP_BAR_STATE_ROOT } from './AppBar/selectors';
import { SNACKBAR_MESSENGER_STATE_ROOT } from './SnackbarMessenger/selectors';
import { DIALOG_STATE_ROOT } from './Dialog/selectors';
import { BREADCRUMBS_STATE_ROOT } from './Breadcrumbs/selectors';
import { FORM_STATE_ROOT } from './form/selectors';
import { LIB_STATE_ROOT } from './lib/selectors';
import { ASSETS_VIEWER_STATE_ROOT } from './AssetsViewer/selectors';

const reducers = {
    [APP_MENU_STATE_ROOT]: appMenuReducer,
    [APP_BAR_STATE_ROOT]: appBarReducer,
    [ASSETS_VIEWER_STATE_ROOT]: assetsViewerReducer,
    [SNACKBAR_MESSENGER_STATE_ROOT]: snackbarMessengerReducer,
    [DIALOG_STATE_ROOT]: dialogReducer,
    [FORM_STATE_ROOT]: formReducer,
    [LIB_STATE_ROOT]: libReducer,
    [BREADCRUMBS_STATE_ROOT]: breadcrumbsReducer,
};

export const reducer = combineReducers(reducers);
export default reducer;
