import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import {
    PrivateRoute,
    AppAuthenticator,
    AppImpersonateUser,
} from '@manakin/authentication';
import { SnackbarMessenger, UiProvider } from '@manakin/core';
import App from '../App';
import PreviewRouter from '@manakin/app-views/PreviewRouter';
import { Provider } from 'react-redux';
import { ApolloProvider } from 'react-apollo';
import { Switch, Route, Router } from 'react-router-dom';
import { HelpButton } from '@manakin/app-core';

const Root = (props) => {
    const { store, theme, client, history, ui } = props;

    return (
        <Provider store={store}>
            <ApolloProvider client={client}>
                <MuiThemeProvider theme={theme()}>
                    <UiProvider ui={ui}>
                        <Router history={history}>
                            <div>
                                <SnackbarMessenger />
                                <HelpButton />
                                <Switch>
                                    <Route
                                        path={'/impersonate'}
                                        component={AppImpersonateUser}
                                    />
                                    <Route
                                        path={`/preview/:elementType/:query`}
                                        component={PreviewRouter}
                                    />
                                    <Route
                                        path="/auth"
                                        component={AppAuthenticator}
                                    />
                                    <PrivateRoute path="/" component={App} />
                                </Switch>
                            </div>
                        </Router>
                    </UiProvider>
                </MuiThemeProvider>
            </ApolloProvider>
        </Provider>
    );
};

export default Root;
