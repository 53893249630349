import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { LoadImage } from '@manakin/app-core';
import { styles } from './styles';
import { getBoxId } from '@manakin/app-core/Boxes/selectors';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import { GQL_FETCH_BOX } from '../graphql';
import classNames from 'classnames';
import renderHTML from "react-render-html";
import { useTranslation } from 'react-i18next';

const Header = (props) => {
	const { classes, headerImage: _headerImage, fromBox = false, fromElement = false, boxId, isTags = false } = props;
	const headerImage = _headerImage ? _headerImage.url : '';
	const { t } = useTranslation();

	const { data = {} } = useQuery(GQL_FETCH_BOX, {
		variables: { id: boxId },
	});

	let box = {};
	if (data) {
		box = data.box || {};
	}

	const getTitle = () => {
        if (isTags) {
            return t('app.fact-card.overview.title-from-tags');
        }else if (fromBox && !fromElement) {
            return t('app.fact-card.overview.title-from-box');
        } else if (fromElement) {
            return t('app.fact-card.overview.title-from-element');
        } else {
            return t('app.fact-card.overview.title');
        }
	};

	let boxName = '';
	if (box.name) {
		//regex to remove HTML tags
		boxName = box.name.replace(/(<([^>]+)>)/gi, '');
	}

	const getDescription = () => {
        if (isTags) {
            return t('app.fact-card.overview.content-from-tags');
        } else if (fromBox && !fromElement) {
            return t('app.fact-card.overview.content-from-box', { box: boxName || '' });
        } else if (fromElement) {
            return t('app.fact-card.overview.content-from-element');
        } else {
            return t('app.fact-card.overview.content');
        }
	}; 

	return (
		<div className={classes.root} color="light">
			<LoadImage src={headerImage} withOverlay={false} />
			<div className={classes.wrapper}>
				<div className={classes.content}>
					<Typography component="h1" variant="h2" className={classNames('underline', classes.fontStyles, classes.heading)}>
						{getTitle()}
					</Typography>
					<Typography component="p" variant="body1" className={classes.fontStyles}>
						{renderHTML(getDescription())} <br />
						{t('app.fact-card.overview.help-text')}
					</Typography>
				</div>
			</div>
		</div>
	);
};

export default compose(
	withStyles(styles, { name: 'FactCardsHeader' }),
	connect((state) => ({
		boxId: getBoxId(state),
	}))
)(Header);
