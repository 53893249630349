"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _TableRow = require("@material-ui/core/TableRow");

var _TableRow2 = _interopRequireDefault(_TableRow);

var _Typography = require("@material-ui/core/Typography");

var _Typography2 = _interopRequireDefault(_Typography);

var _TableCell = require("@material-ui/core/TableCell");

var _TableCell2 = _interopRequireDefault(_TableCell);

var _core = require("@material-ui/core");

var _reactRouterDom = require("react-router-dom");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useStyles = (0, _core.makeStyles)(function (theme) {
	return {
		content: {
			margin: 0,
			transition: "color .3s"
		},
		row: {
			cursor: "pointer",
			"&:hover": {
				"& $content": {
					color: theme.manakin.defaultContentHover[500]
				}
			}
		},
		cell: {
			wordBreak: "break-word"
		}
	};
});

var TableRowComponent = function TableRowComponent(props) {
	var data = props.data,
	    program = props.program;

	var _ref = data || {},
	    _ref$student = _ref.student,
	    student = _ref$student === undefined ? {} : _ref$student;

	var school = student.school || [];
	var classes = useStyles();
	var history = (0, _reactRouterDom.useHistory)();

	var handleClick = function handleClick() {
		history.push("student/" + data.student.id);
	};
	var requests = data.ratingRequests ? data.ratingRequests.filter(function (i) {
		return !i.result;
	}) : [];

	if (!data) return null;
	return _react2.default.createElement(
		_TableRow2.default,
		{ onClick: handleClick, className: classes.row },
		_react2.default.createElement(
			_TableCell2.default,
			{ component: "th", scope: "row", className: classes.cell },
			_react2.default.createElement(
				_Typography2.default,
				{ className: classes.content },
				data.student.fullName
			)
		),
		_react2.default.createElement(
			_TableCell2.default,
			{ component: "th", scope: "row", className: classes.cell },
			_react2.default.createElement(
				_Typography2.default,
				{ className: classes.content },
				school && (Array.isArray(school) ? school : [school].filter(function (it) {
					return !!it;
				})).map(function (schoolClass) {
					return schoolClass.name;
				}).join(", ")
			)
		),
		_react2.default.createElement(
			_TableCell2.default,
			{ component: "th", scope: "row", className: classes.cell },
			_react2.default.createElement(
				_Typography2.default,
				{ className: classes.content },
				data.programResult ? data.programResult.progress || 0 : 0,
				"%"
			)
		),
		_react2.default.createElement(
			_TableCell2.default,
			{ component: "th", scope: "row", className: classes.cell },
			_react2.default.createElement(
				_Typography2.default,
				{ className: classes.content },
				requests.length
			)
		)
	);
};

exports.default = TableRowComponent;