import React, { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "@manakin/authentication/actions";
import SetupTwoFactor from "./SetupTwoFactor";
import { withForm } from "@manakin/core";
import { compose } from "recompose";
import HandleTwoFactor from "./HandleTwoFactor";
import { GQL_VERIFY_MFA, GQL_CURRENT_APP_USER } from "../graphql";
import { useMutation } from "react-apollo";
import { useLazyQuery } from "@apollo/react-hooks";
import { login } from "../actions";
import { showSnackbarMessage } from "@manakin/core/actions";
import {useTranslation} from 'react-i18next';

const form = {
	mfaCode: {
		required: true,
	},
};

const TwoFactor = (props) => {
	const { form } = props;
	const location = useLocation();
	const { state = {} } = location;
	const { redirect = "" } = state || {};
	const dispatch = useDispatch();
	const { t } = useTranslation();

	//query
	const [getCurrentUser, { data, loading }] =
		useLazyQuery(GQL_CURRENT_APP_USER);

	//mutations
	const [mutate] = useMutation(GQL_VERIFY_MFA);

	//dispatch functions
	const onLogout = useCallback(() => dispatch(logout()), [dispatch]);
	const processSuccess = useCallback(
		(s, u) => dispatch(login(s, u)),
		[dispatch]
	);
	const processSuccessCMS = useCallback(
		(s) => dispatch(login(s)),
		[dispatch]
	);
	const showSnackbar = useCallback(
		() =>
			dispatch(
				showSnackbarMessage({
					text: t("authentication.two-factor.login.authentication-code-failed"),
					variant: "error",
				})
			),
		[dispatch]
	);

	//effect hooks
	useEffect(() => {
		if (!state.mfa) {
			onLogout();
		}
	}, []);

	useEffect(() => {
		if (!loading && data && data.currentAppUser) {
			processSuccess(redirect, data.currentAppUser);
		}
	}, [data, loading]);

	//functions
	const handleClick = () => {
		form.onSubmit().then((r) => {
			mutate({
				variables: {
					input: {
						...r,
					},
				},
			}).then((result) => {
				if (result.data && result.data.verifyMfa) {
					if (state.env === "APP") {
						getCurrentUser();
					} else {
						processSuccessCMS(redirect);
					}
				} else {
					showSnackbar();
				}
			});
		});
	};

	if (state.showQr)
		return (
			<SetupTwoFactor state={state} form={form} onClick={handleClick} />
		);
	if (!state.showQr)
		return (
			<HandleTwoFactor form={form} onClick={handleClick} state={state} />
		);
};

export default compose(withForm(form))(TwoFactor);
