import React, { useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";
import { WorkformViewer, BottomButtons } from "@manakin/app-core";
import { Header } from "./parts";
import classNames from "classnames";
import { useLocation } from "react-router-dom";

const Evaluation = (props) => {
	const {
		classes,
		workforms,
		options,
		onFinish,
		evaluationData = {},
		prefix,
		onReset,
		preview = false,
		onClose,
	} = props;
	const location = useLocation();
	const currentScrollPosition = useRef(null);

	//functions
	useEffect(() => {
		window.addEventListener("scroll", listenToScroll);
		return () => window.removeEventListener("scroll", listenToScroll);
	}, []);

	const listenToScroll = () => {
		currentScrollPosition.current =
			window.pageYOffset || document.documentElement.scrollTop;
	};

	return (
		<div className={classes.root}>
			<Header
				data={{
					...evaluationData,
				}}
				prefix={prefix}
			/>

			{workforms &&
				workforms.map((item) => (
					<div key={item.id} className={classes.normalMargin}>
						<div className={classNames(classes.largeWrapper)}>
							<WorkformViewer
								type={item.type}
								item={item}
								onFinish={onFinish}
								options={options}
								customLink={{
									state: {
										url: location.pathname,
										idx: currentScrollPosition.current,
									},
								}}
							/>
						</div>
					</div>
				))}

			{!preview && (
				<BottomButtons
					reverse={true}
					onClose={onClose}
					onReset={onReset} />
			)}
		</div>
	);
};

export default withStyles(styles, { name: "AppEvaluation" })(Evaluation);
