'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.reducer = undefined;

var _reducers;

var _redux = require('redux');

var _reducer = require('./AppBar/reducer');

var _reducer2 = _interopRequireDefault(_reducer);

var _reducer3 = require('./AppBarDropdown/reducer');

var _reducer4 = _interopRequireDefault(_reducer3);

var _reducer5 = require('./AppMenu/reducer');

var _reducer6 = _interopRequireDefault(_reducer5);

var _reducer7 = require('./Boxes/reducer');

var _reducer8 = _interopRequireDefault(_reducer7);

var _reducer9 = require('./WorkformViewer/reducer');

var _reducer10 = _interopRequireDefault(_reducer9);

var _reducer11 = require('./ProgramsDropdown/reducer');

var _reducer12 = _interopRequireDefault(_reducer11);

var _reducer13 = require('./GroupsDropdown/reducer');

var _reducer14 = _interopRequireDefault(_reducer13);

var _reducer15 = require('./SearchBar/reducer');

var _reducer16 = _interopRequireDefault(_reducer15);

var _reducer17 = require('./BoxService/reducer');

var _reducer18 = _interopRequireDefault(_reducer17);

var _reducer19 = require('./ColorService/reducer');

var _reducer20 = _interopRequireDefault(_reducer19);

var _selectors = require('./AppBar/selectors');

var _selectors2 = require('./AppBarDropdown/selectors');

var _selectors3 = require('./AppMenu/selectors');

var _selectors4 = require('./Boxes/selectors');

var _selectors5 = require('./WorkformViewer/selectors');

var _selectors6 = require('./ProgramsDropdown/selectors');

var _selectors7 = require('./GroupsDropdown/selectors');

var _selectors8 = require('./SearchBar/selectors');

var _selectors9 = require('./BoxService/selectors');

var _selectors10 = require('./ColorService/selectors');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var reducers = (_reducers = {}, _defineProperty(_reducers, _selectors.APP_BAR_APP_ROOT, _reducer2.default), _defineProperty(_reducers, _selectors2.SELECT_OPTION_ROOT, _reducer4.default), _defineProperty(_reducers, _selectors3.APP_MENU_STATE_ROOT_APP, _reducer6.default), _defineProperty(_reducers, _selectors4.APP_BOXES_LAYOUT_STATE_ROOT, _reducer8.default), _defineProperty(_reducers, _selectors6.SELECT_PROGRAM_ROOT, _reducer12.default), _defineProperty(_reducers, _selectors6.SELECT_PROGRAM_ROOT, _reducer12.default), _defineProperty(_reducers, _selectors7.SELECT_GROUP_ROOT, _reducer14.default), _defineProperty(_reducers, _selectors8.SEARCH_BAR_STATE_ROOT_APP, _reducer16.default), _defineProperty(_reducers, _selectors5.APP_WORKFORMS_STATE_ROOT, _reducer10.default), _defineProperty(_reducers, _selectors9.CHANGE_BOX_RESULTS_ROOT, _reducer18.default), _defineProperty(_reducers, _selectors10.SELECT_CHANGE_COLOR_ROOT, _reducer20.default), _reducers);

var reducer = exports.reducer = (0, _redux.combineReducers)(reducers);
exports.default = reducer;