import React from 'react';
import { Table } from '@manakin/core';

class Overview extends React.Component {
    render() {
        const {
            loading,
            query,
            totalCount = 0,
            rows,
            onQueryChange,
            columns,
            onRowClick,
            actions,
        } = this.props;

        return (
            <div>
                <Table
                    query={query}
                    onQueryChange={onQueryChange}
                    totalCount={totalCount}
                    rows={rows}
                    columns={columns}
                    onRowClick={onRowClick}
                    actions={actions}
                    loading={loading}
                />
            </div>
        );
    }
}

export default Overview;
