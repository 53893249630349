import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { GQL_FETCH_BETTING_GAME, GQL_FETCH_GLOBAL_SETTINGS } from './graphql';
import { graphql } from 'react-apollo';
import { withWorkforms, Loader } from '@manakin/app-core';
import BettingGameView from './BettingGameView';
import { changeColor } from '@manakin/app-core/ColorService/actions';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useViewCounting } from "@manakin/hooks";

const BettingGame = (props) => {
    const { data, workformsData, match, changeColor, SETTINGS = {} } = props;
    const { settings = {} } = SETTINGS;
    const location = useLocation();
    const { viewElement } = useViewCounting();

    const { loading, bettingGame = {} } = data;
    const _elementId = match.params.elementId;

    //effect hooks
    useEffect(() => {
        if (bettingGame.workforms) {
            const options = {
                showFeedback: false,
                nextButton: false,
            };

            changeColor({
                ...bettingGame.styleSheet,
                location: location.pathname,
            });

            workformsData.loadWorkforms({
                ...bettingGame,
                options,
                elementId: _elementId,
            });
            workformsData.loadElementResult(_elementId);

            viewElement(_elementId);
        }
    }, [loading]);

    if (loading) {
        return (
            <div>
                <Loader fullScreen={true} />
            </div>
        );
    }
    return (
        <BettingGameView
            settings={settings.settings || []}
            workformsData={workformsData}
            elementId={_elementId || null}
            bettingGame={bettingGame}
            saveData
        />
    );
};

export default compose(
    connect(null, (dispatch) => ({
        changeColor: (styleSheet) => dispatch(changeColor(styleSheet)),
    })),
    graphql(GQL_FETCH_GLOBAL_SETTINGS, {
        name: 'SETTINGS',
    }),
    graphql(GQL_FETCH_BETTING_GAME, {
        options: (props) => ({
            variables: { id: props.match.params.elementId },
        }),
    }),
    withWorkforms()
)(BettingGame);
