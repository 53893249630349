import { makeStyles, createStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
    (theme) =>
        createStyles({
            wrapper: {
                [theme.breakpoints.up('md')]: {
                    ...theme.manakin.defaultWrapper,
                    maxWidth: '500px',
                    paddingLeft: '0',
                    margin: '0',
                },
            },
            mobile: {
                position: 'relative',
                textAlign: 'center',
                [theme.breakpoints.up('md')]: {
                    display: 'none',
                },
            },
            image: {
                width: 'auto',
                position: 'relative',
                display: 'inline-block',
            },
            relative: {
                position: 'relative',
            },
            button: {
                margin: '20px',
                width: 'calc(100% - 40px)!important',
                [theme.breakpoints.up('md')]: {
                    width: '100%!important',
                    margin: '0',
                },
            },
            desktop: {
                position: 'relative',
                display: 'none',
                textAlign: 'center',
                [theme.breakpoints.up('md')]: {
                    display: 'block',
                },
            },
            image: {
                width: 'auto',
                position: 'relative',
                display: 'inline-block',
            },
            feedback: {
                position: 'relative',
                left: '0',
                zIndex: '2',
                opacity: '0',
                height: 0,
                width: '100%',
                overflow: 'hidden',
                pointerEvents: 'none',
                transition: 'opacity .3s',
                top: 0,
                [theme.breakpoints.up('md')]: {
                    top: '-80px',
                },
            },
            activeFeedback: {
                opacity: '1',
                pointerEvents: 'auto',
                height: 'auto',
            },
        }),
    {
        name: 'AppTextMPCWithImage',
    }
);
