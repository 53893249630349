'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useGetElementTypes = function useGetElementTypes() {
    var boxResults = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    var percentage = (0, _react.useMemo)(function () {
        return boxResults ? Math.floor(boxResults.progress || 0) : 0;
    }, [boxResults]);

    return percentage;
};

exports.default = useGetElementTypes;