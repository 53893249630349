import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { GQL_FETCH_SERVER_INFO } from '../graphql';
import { useQuery } from '@apollo/react-hooks';

const styles = (theme) => ({
    root: {
        margin: '0 10px 10px',
        [theme.breakpoints.up('md')]: {
            margin: '0 10px 0',
        },
    },
    mainButtonStyle: {
        padding: '1.5rem 4rem',
        width: '100%',
        marginTop: '20px',
    },
    row: {
        width: '100%',
        backgroundColor: 'white',
        padding: '2rem',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        marginBottom: '2px',
        [theme.breakpoints.up('md')]: {
            padding: '2rem 5rem',
        },
    },
    top: {
        backgroundColor: theme.manakin.primaryColor[500],
    },
    heading: {
        color: 'white',
        textAlign: 'center',
    },
    fontStyle: {
        margin: 0,
    },
    active: {
        backgroundColor: 'white',
        color: theme.manakin.primaryColor[500],
        border: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
    },
    currentYear: {
        backgroundColor: 'white!important',
        border: 0,
    },
});

const Product = (props) => {
    const { classes, userFunction, disabled = false, isUpdate } = props;

    const { loading: serverLoading, error, data: serverData = {} } = useQuery(
        GQL_FETCH_SERVER_INFO
    );
    const { serverInfo = {} } = serverData;

    //state hooks
    const [buttonText, setButtonText] = useState('Toevoegen');
    const [active, setActive] = useState(false);
    const [amount, setAmount] = useState(5);
    const [title, setTitle] = useState('Drogist');
    const [currentYear, setCurrentYear] = useState();

    //effect hooks
    useEffect(() => {
        setButtonText(active ? 'Verwijderen' : 'Toevoegen');
        if (props.year === currentYear && !isUpdate) {
            setButtonText('Geselecteerd');
        }
        if (disabled) setButtonText('Aangeschaft');
    }, [active, currentYear, disabled]);

    useEffect(() => {
        if (!serverLoading) {
            if (serverInfo.currentYear) {
                const current = serverInfo.currentYear;
                setCurrentYear(current);
            }
        }
    }, [serverLoading]);

    useEffect(() => {
        if (userFunction === 'Drogist') {
            setAmount(6);
            setTitle('Drogist');
        } else {
            setAmount(5);
            setTitle('Assistent Drogist');
        }
    }, [userFunction]);

    useEffect(() => {
        setActive(props.initialValue);
        props.form.onFieldChange({
            key: props.name,
            value: props.initialValue,
        });
    }, []);

    useEffect(() => {
        if (currentYear) {
            if (props.year === currentYear && !isUpdate) {
                handleActive(true);
            }
        }
    }, [currentYear]);

    //functions
    const handleClick = () => {
        handleActive(!active);
    };

    const handleActive = (val) => {
        props.form.onFieldChange({
            key: props.name,
            value: val,
        });

        if (props.onClick) props.onClick({ year: props.year, value: val });
        setActive(val);
    };

    return (
        <div className={classes.root}>
            <div className={classNames(classes.row, classes.top)}>
                <Typography
                    variant="body2"
                    className={classNames(classes.heading, classes.fontStyle)}
                >
                    {title} nascholing {props.year}
                </Typography>
            </div>
            <div className={classNames(classes.row, classes.bullet)}>
                <Typography variant="body1" className={classes.fontStyle}>
                    {amount} eindtoetsen
                </Typography>
            </div>
            <div className={classNames(classes.row, classes.bullet)}>
                <Typography variant="body1" className={classes.fontStyle}>
                    Toegang tot nascholingsmodule t/m 31 december {currentYear}.
                </Typography>
            </div>
            <div className={classes.row}>
                <Typography variant="body1" className={classes.fontStyle}>
                    {props.price}
                </Typography>
                <Button
                    onClick={handleClick}
                    color="primary"
                    variant="contained"
                    disabled={
                        disabled || (currentYear === props.year && !isUpdate)
                    }
                    className={classNames(classes.mainButtonStyle, {
                        [classes.active]: active,
                    })}
                    classes={{
                        disabled: classNames({
                            [classes.currentYear]:
                                props.year === currentYear && !isUpdate,
                        }),
                    }}
                >
                    {buttonText}
                </Button>
            </div>
        </div>
    );
};

export default withStyles(styles)(Product);
