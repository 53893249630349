import React from 'react';
import { WorkformLayout } from '@manakin/app-core';
import { Image, Question } from './parts';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const TextMPCWithImage = (props) => {
	const { data = {}, onChange, onSubmit, onFinished, disabled, onSetWorkformDone } = props;
	const { t } = useTranslation();

	return (
		<WorkformLayout
			question={data.question || ''}
			instruction={data.introduction || t('app.workforms.text-mpc-with-image.check-image')}
			loading={false}
			renderQuestion={() => (
				<Question data={data} disabled={disabled} onChange={onChange} onSubmit={onSubmit} onSetWorkformDone={onSetWorkformDone} onFinished={onFinished} />
			)}
			renderAnswers={() => <Image data={data} />}
		/>
	);
};

export default compose(connect((state) => ({ config: state.config })))(TextMPCWithImage);
