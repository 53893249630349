"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _core = require("@material-ui/core");

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _Link = require("@material-ui/icons/Link");

var _Link2 = _interopRequireDefault(_Link);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var useStyles = (0, _core.makeStyles)(function (theme) {
	return {
		root: {
			height: "80px",
			width: "100%",
			backgroundColor: theme.manakin.indicationColor[500],
			color: theme.palette.getContrastText(theme.manakin.indicationColor[500]),
			display: "flex",
			alignItems: "center",
			justifyContent: "center"
		},
		clickableRoot: {
			cursor: "pointer",
			transition: "background-color .3s, color .3s",
			"&:hover": {
				backgroundColor: theme.manakin.indicationColor[900],
				color: theme.palette.getContrastText(theme.manakin.indicationColor[900])
			}
		},
		fixedRoot: {
			position: "fixed",
			zIndex: 1
		},
		largeRoot: {
			height: "100px"
		},
		icon: {
			color: "black",
			fontSize: "2rem",
			marginRight: "20px"
		}
	};
});

var AppBarmessages = function AppBarmessages(props) {
	var _classNames;

	var onClick = props.onClick,
	    message = props.message,
	    large = props.large,
	    fixed = props.fixed;

	var classes = useStyles();

	var handleClick = function handleClick() {
		if (onClick) onClick();
	};

	return _react2.default.createElement(
		"div",
		{
			className: (0, _classnames2.default)(classes.root, (_classNames = {}, _defineProperty(_classNames, classes.clickableRoot, onClick !== undefined), _defineProperty(_classNames, classes.largeRoot, large), _defineProperty(_classNames, classes.fixedRoot, fixed), _classNames)),
			onClick: handleClick },
		_react2.default.createElement(_Link2.default, { className: classes.icon }),
		message || ""
	);
};

exports.default = AppBarmessages;