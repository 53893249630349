'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.GQL_FETCH_PRODUCT = exports.GQL_FETCH_APP_USER_FOR_STORE = exports.GQL_DELETE_LICENSE = exports.GQL_FETCH_PRODUCTS = exports.GQL_CLAIM_LICENSE = exports.GQL_UPSERT_LICENSE_GROUP = exports.GQL_GENERATE_LICENSES = exports.GQL_FETCH_LICENSE_TO_CLAIM = exports.GQL_FETCH_LICENSE = exports.GQL_FETCH_LICENSES = undefined;

var _templateObject = _taggedTemplateLiteral(['\n\tquery licenseGroups($page: Int!, $pagesize: Int!, $search: String, $direction: String, $sortProperties: [String]) {\n\t\tlicenseGroups(page: $page, pagesize: $pagesize, search: $search, direction: $direction, sortProperties: $sortProperties) {\n\t\t\tlicenseGroups {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tcreatedOn\n\t\t\t\tdaysValid\n\t\t\t\tproduct {\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t\tcount\n\t\t}\n\t}\n'], ['\n\tquery licenseGroups($page: Int!, $pagesize: Int!, $search: String, $direction: String, $sortProperties: [String]) {\n\t\tlicenseGroups(page: $page, pagesize: $pagesize, search: $search, direction: $direction, sortProperties: $sortProperties) {\n\t\t\tlicenseGroups {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tcreatedOn\n\t\t\t\tdaysValid\n\t\t\t\tproduct {\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t\tcount\n\t\t}\n\t}\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n\tquery licenseGroup($id: ID!) {\n\t\tlicenseGroup(id: $id) {\n\t\t\tid\n\t\t\tname\n\t\t\tproduct {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t\tidentifier\n\t\t\tcreatedOn\n\t\t\tdaysValid\n\t\t\tvalidUntil\n\t\t\tlicenses {\n\t\t\t\tcode\n\t\t\t\tclaimedOn\n\t\t\t\tvalidUntil\n\t\t\t\tuser {\n\t\t\t\t\tid\n\t\t\t\t\tfullName\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n'], ['\n\tquery licenseGroup($id: ID!) {\n\t\tlicenseGroup(id: $id) {\n\t\t\tid\n\t\t\tname\n\t\t\tproduct {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t\tidentifier\n\t\t\tcreatedOn\n\t\t\tdaysValid\n\t\t\tvalidUntil\n\t\t\tlicenses {\n\t\t\t\tcode\n\t\t\t\tclaimedOn\n\t\t\t\tvalidUntil\n\t\t\t\tuser {\n\t\t\t\t\tid\n\t\t\t\t\tfullName\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n']),
    _templateObject3 = _taggedTemplateLiteral(['\n\tquery license($code: String!) {\n\t\tlicense(code: $code) {\n\t\t\tcode\n\t\t\tclaimedOn\n\t\t\tvalidUntil\n\t\t}\n\t}\n'], ['\n\tquery license($code: String!) {\n\t\tlicense(code: $code) {\n\t\t\tcode\n\t\t\tclaimedOn\n\t\t\tvalidUntil\n\t\t}\n\t}\n']),
    _templateObject4 = _taggedTemplateLiteral(['\n\tmutation generateLicenses($identifier: String, $product: ID!, $amount: Int!, $name: String!, $validUntil: String, $daysValid: Int) {\n\t\tgenerateLicenses(input: { identifier: $identifier, product: $product, amount: $amount, name: $name, validUntil: $validUntil, daysValid: $daysValid }) {\n\t\t\tlicenseGroup {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n'], ['\n\tmutation generateLicenses($identifier: String, $product: ID!, $amount: Int!, $name: String!, $validUntil: String, $daysValid: Int) {\n\t\tgenerateLicenses(input: { identifier: $identifier, product: $product, amount: $amount, name: $name, validUntil: $validUntil, daysValid: $daysValid }) {\n\t\t\tlicenseGroup {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n']),
    _templateObject5 = _taggedTemplateLiteral(['\n\tmutation upsertLicenseGroup($input: UpsertLicenseGroupInput) {\n\t\tupsertLicenseGroup(input: $input) {\n\t\t\tlicenseGroup {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n'], ['\n\tmutation upsertLicenseGroup($input: UpsertLicenseGroupInput) {\n\t\tupsertLicenseGroup(input: $input) {\n\t\t\tlicenseGroup {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n']),
    _templateObject6 = _taggedTemplateLiteral(['\n    mutation claimLicense($code: String!) {\n        claimLicense(input: { code: $code }) {\n            result\n        }\n    }\n'], ['\n    mutation claimLicense($code: String!) {\n        claimLicense(input: { code: $code }) {\n            result\n        }\n    }\n']),
    _templateObject7 = _taggedTemplateLiteral(['\n\tquery products($page: Int!, $pagesize: Int!, $search: String) {\n\t\tproducts(page: $page, pagesize: $pagesize, search: $search) {\n\t\t\tproducts {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tprefix\n\t\t\t\tpriceCents\n\t\t\t\tprogram {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t\tcount\n\t\t}\n\t}\n'], ['\n\tquery products($page: Int!, $pagesize: Int!, $search: String) {\n\t\tproducts(page: $page, pagesize: $pagesize, search: $search) {\n\t\t\tproducts {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tprefix\n\t\t\t\tpriceCents\n\t\t\t\tprogram {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t\tcount\n\t\t}\n\t}\n']),
    _templateObject8 = _taggedTemplateLiteral(['\n\tmutation deleteLicenseGroup($id: ID!) {\n\t\tdeleteLicenseGroup(id: $id) {\n\t\t\tresult\n\t\t}\n\t}\n'], ['\n\tmutation deleteLicenseGroup($id: ID!) {\n\t\tdeleteLicenseGroup(id: $id) {\n\t\t\tresult\n\t\t}\n\t}\n']),
    _templateObject9 = _taggedTemplateLiteral(['\n\tquery currentAppUser {\n\t\tcurrentAppUser {\n\t\t\tid\n\t\t\tfirstName\n\t\t\tlastName\n\t\t\tlicenses {\n\t\t\t\tcode\n\t\t\t\tvalidUntil\n\t\t\t\tfavouriteProductId\n\t\t\t\tlicenseGroup {\n\t\t\t\t\tid\n\t\t\t\t\tproduct {\n\t\t\t\t\t\tid\n\t\t\t\t\t\tprogram {\n\t\t\t\t\t\t\tid\n\t\t\t\t\t\t\tname\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t\tproduct {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t\tfavouriteRequired\n\t\t\t\t\tsubProducts {\n\t\t\t\t\t\tid\n\t\t\t\t\t\tname\n\t\t\t\t\t\tfavouriteRequired\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t\tlocale {\n\t\t\t\tcode\n\t\t\t}\n\t\t\tgroup {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t\troles {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t}\n'], ['\n\tquery currentAppUser {\n\t\tcurrentAppUser {\n\t\t\tid\n\t\t\tfirstName\n\t\t\tlastName\n\t\t\tlicenses {\n\t\t\t\tcode\n\t\t\t\tvalidUntil\n\t\t\t\tfavouriteProductId\n\t\t\t\tlicenseGroup {\n\t\t\t\t\tid\n\t\t\t\t\tproduct {\n\t\t\t\t\t\tid\n\t\t\t\t\t\tprogram {\n\t\t\t\t\t\t\tid\n\t\t\t\t\t\t\tname\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t\tproduct {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t\tfavouriteRequired\n\t\t\t\t\tsubProducts {\n\t\t\t\t\t\tid\n\t\t\t\t\t\tname\n\t\t\t\t\t\tfavouriteRequired\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t\tlocale {\n\t\t\t\tcode\n\t\t\t}\n\t\t\tgroup {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t\troles {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t}\n']),
    _templateObject10 = _taggedTemplateLiteral(['\n\tquery product($id: ID!) {\n\t\tproduct(id: $id) {\n\t\t\tid\n\t\t\tdaysValid\n\t\t\tvalidUntil\n\t\t}\n\t}\n'], ['\n\tquery product($id: ID!) {\n\t\tproduct(id: $id) {\n\t\t\tid\n\t\t\tdaysValid\n\t\t\tvalidUntil\n\t\t}\n\t}\n']);

var _graphqlTag = require('graphql-tag');

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_LICENSES = exports.GQL_FETCH_LICENSES = (0, _graphqlTag2.default)(_templateObject);

var GQL_FETCH_LICENSE = exports.GQL_FETCH_LICENSE = (0, _graphqlTag2.default)(_templateObject2);

var GQL_FETCH_LICENSE_TO_CLAIM = exports.GQL_FETCH_LICENSE_TO_CLAIM = (0, _graphqlTag2.default)(_templateObject3);

var GQL_GENERATE_LICENSES = exports.GQL_GENERATE_LICENSES = (0, _graphqlTag2.default)(_templateObject4);

var GQL_UPSERT_LICENSE_GROUP = exports.GQL_UPSERT_LICENSE_GROUP = (0, _graphqlTag2.default)(_templateObject5);

var GQL_CLAIM_LICENSE = exports.GQL_CLAIM_LICENSE = (0, _graphqlTag2.default)(_templateObject6);

var GQL_FETCH_PRODUCTS = exports.GQL_FETCH_PRODUCTS = (0, _graphqlTag2.default)(_templateObject7);

var GQL_DELETE_LICENSE = exports.GQL_DELETE_LICENSE = (0, _graphqlTag2.default)(_templateObject8);

var GQL_FETCH_APP_USER_FOR_STORE = exports.GQL_FETCH_APP_USER_FOR_STORE = (0, _graphqlTag2.default)(_templateObject9);

var GQL_FETCH_PRODUCT = exports.GQL_FETCH_PRODUCT = (0, _graphqlTag2.default)(_templateObject10);