import gql from 'graphql-tag';

export const GQL_ACCEPT_INVITATION = gql`
    mutation acceptInvitation($input: AcceptInvitationInput!) {
        acceptInvitation(input: $input) {
            id
            state
        }
    }
`;
