import React from 'react';
import { connect } from 'react-redux';
import { setBreadcrumb } from './actions';
import { compose } from "recompose";
import { withTranslation } from "react-i18next";

const mapDispatchToProps = (dispatch) => ({
    onMount: (Breadcrumbs, location) =>
        dispatch(setBreadcrumb(location.pathname, Breadcrumbs)),
});

const withCustomBreadcrumb = (BreadcrumbsTranslationKey) => (WrappedComponent) => {
    return compose(
        connect(
            null,
            mapDispatchToProps
        ),
        withTranslation()
    )(
        class extends React.Component {
            componentDidMount() {
                const { onMount, location, t } = this.props;
                onMount(t(BreadcrumbsTranslationKey), location);
                if (this.someFunction) {
                    this.someFunction();
                }
            }

            render() {
                return <WrappedComponent {...this.props} />;
            }
        }
    );
};

export default withCustomBreadcrumb;
