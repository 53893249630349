"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.GQL_FETCH_RATING_REQUESTS = exports.GQL_FETCH_ELEMENT_RESULTS = exports.GQL_FETCH_FINAL_RATING = exports.GQL_FETCH_RATING = exports.GQL_FETCH_SELF_EVALUATION = exports.GQL_FETCH_GLOBAL_SETTINGS = exports.GQL_FETCH_FILE = exports.GQL_FETCH_ENTRY_TEST = exports.GQL_FETCH_BOOK_DOWNLOAD = exports.GQL_FETCH_SLIDESHOW = exports.GQL_FETCH_EXAM_TRAINER = exports.GQL_FETCH_TRAINER = exports.GQL_FETCH_FILM = exports.GQL_FETCH_MASTERCLASS = exports.GQL_FETCH_EXERCISE_CARD = exports.GQL_FETCH_BETTING_GAME = exports.GQL_FETCH_CASE = exports.GQL_FETCH_LESSON = exports.GQL_FETCH_BOOKCHECK = exports.GQL_FETCH_VIDEO_BRIDGE = exports.GQL_FETCH_BRIDGE = undefined;

var _templateObject = _taggedTemplateLiteral(["\n    query bridge($id: ID!) {\n        bridge(id: $id) {\n            ", "\n        }\n    }\n"], ["\n    query bridge($id: ID!) {\n        bridge(id: $id) {\n            ", "\n        }\n    }\n"]),
    _templateObject2 = _taggedTemplateLiteral(["\n    query videoBridge($id: ID!) {\n        videoBridge(id: $id) {\n            ", "\n        }\n    }\n"], ["\n    query videoBridge($id: ID!) {\n        videoBridge(id: $id) {\n            ", "\n        }\n    }\n"]),
    _templateObject3 = _taggedTemplateLiteral(["\n    query bookcheck($id: ID!) {\n        bookCheck(id: $id) {\n            ", "\n        }\n    }\n"], ["\n    query bookcheck($id: ID!) {\n        bookCheck(id: $id) {\n            ", "\n        }\n    }\n"]),
    _templateObject4 = _taggedTemplateLiteral(["\n    query lesson($id: ID!) {\n        lesson(id: $id) {\n            ", "\n        }\n    }\n"], ["\n    query lesson($id: ID!) {\n        lesson(id: $id) {\n            ", "\n        }\n    }\n"]),
    _templateObject5 = _taggedTemplateLiteral(["\n    query case($id: ID!) {\n        case(id: $id) {\n            ", "\n        }\n    }\n"], ["\n    query case($id: ID!) {\n        case(id: $id) {\n            ", "\n        }\n    }\n"]),
    _templateObject6 = _taggedTemplateLiteral(["\n    query bettingGame($id: ID!) {\n        bettingGame(id: $id) {\n            ", "\n        }\n    }\n"], ["\n    query bettingGame($id: ID!) {\n        bettingGame(id: $id) {\n            ", "\n        }\n    }\n"]),
    _templateObject7 = _taggedTemplateLiteral(["\n    query exerciseCard($id: ID!) {\n        exerciseCard(id: $id) {\n            ", "\n        }\n    }\n"], ["\n    query exerciseCard($id: ID!) {\n        exerciseCard(id: $id) {\n            ", "\n        }\n    }\n"]),
    _templateObject8 = _taggedTemplateLiteral(["\n    query masterClass($id: ID!) {\n        masterClass(id: $id) {\n            ", "\n        }\n    }\n"], ["\n    query masterClass($id: ID!) {\n        masterClass(id: $id) {\n            ", "\n        }\n    }\n"]),
    _templateObject9 = _taggedTemplateLiteral(["\n    query film($id: ID!) {\n        film(id: $id) {\n            ", "\n        }\n    }\n"], ["\n    query film($id: ID!) {\n        film(id: $id) {\n            ", "\n        }\n    }\n"]),
    _templateObject10 = _taggedTemplateLiteral(["\n    query trainer($id: ID!) {\n        trainer(id: $id) {\n            ", "\n        }\n    }\n"], ["\n    query trainer($id: ID!) {\n        trainer(id: $id) {\n            ", "\n        }\n    }\n"]),
    _templateObject11 = _taggedTemplateLiteral(["\n    query examTrainer($id: ID!) {\n        examTrainer(id: $id) {\n            ", "\n        }\n    }\n"], ["\n    query examTrainer($id: ID!) {\n        examTrainer(id: $id) {\n            ", "\n        }\n    }\n"]),
    _templateObject12 = _taggedTemplateLiteral(["\n    query slideshowCreator($id: ID!) {\n        slideshowCreator(id: $id) {\n            ", "\n        }\n    }\n"], ["\n    query slideshowCreator($id: ID!) {\n        slideshowCreator(id: $id) {\n            ", "\n        }\n    }\n"]),
    _templateObject13 = _taggedTemplateLiteral(["\n    query bookDownload($id: ID!) {\n        bookDownload(id: $id) {\n            ", "\n        }\n    }\n"], ["\n    query bookDownload($id: ID!) {\n        bookDownload(id: $id) {\n            ", "\n        }\n    }\n"]),
    _templateObject14 = _taggedTemplateLiteral(["\n    query entryTest($id: ID!) {\n        entryTest(id: $id) {\n            ", "\n        }\n    }\n"], ["\n    query entryTest($id: ID!) {\n        entryTest(id: $id) {\n            ", "\n        }\n    }\n"]),
    _templateObject15 = _taggedTemplateLiteral(["\n    query fileDownload($id: ID!) {\n        fileDownload(id: $id) {\n            ", "\n        }\n    }\n"], ["\n    query fileDownload($id: ID!) {\n        fileDownload(id: $id) {\n            ", "\n        }\n    }\n"]),
    _templateObject16 = _taggedTemplateLiteral(["\n    query settings{\n        settings {\n            ", "\n        }\n    }\n"], ["\n    query settings{\n        settings {\n            ", "\n        }\n    }\n"]),
    _templateObject17 = _taggedTemplateLiteral(["\n    query selfEvaluation($id: ID!) {\n        selfEvaluation(id: $id) {\n            ", "\n        }\n    }\n"], ["\n    query selfEvaluation($id: ID!) {\n        selfEvaluation(id: $id) {\n            ", "\n        }\n    }\n"]),
    _templateObject18 = _taggedTemplateLiteral(["\n    query rating($id: ID!) {\n        rating(id: $id) {\n            ", "\n        }\n    }\n"], ["\n    query rating($id: ID!) {\n        rating(id: $id) {\n            ", "\n        }\n    }\n"]),
    _templateObject19 = _taggedTemplateLiteral(["\n    query finalRating($id: ID!) {\n        finalRating(id: $id) {\n            ", "\n        }\n    }\n"], ["\n    query finalRating($id: ID!) {\n        finalRating(id: $id) {\n            ", "\n        }\n    }\n"]),
    _templateObject20 = _taggedTemplateLiteral(["\n    query elementResult($box: ID!, $program: ID!, $user: ID!, $element: ID!) {\n        elementResult(\n            box: $box\n            program: $program\n            user: $user\n            element: $element\n        ) {\n            ", "\n        }\n    }\n"], ["\n    query elementResult($box: ID!, $program: ID!, $user: ID!, $element: ID!) {\n        elementResult(\n            box: $box\n            program: $program\n            user: $user\n            element: $element\n        ) {\n            ", "\n        }\n    }\n"]),
    _templateObject21 = _taggedTemplateLiteral(["\n\tquery ratingRequest(\n\t\t$boxId: ID!\n\t\t$elementId: ID!\n\t\t$programId: ID!\n\t\t$studentId: ID!\n\t) {\n\t\tratingRequest(\n\t\t\tboxId: $boxId\n\t\t\telementId: $elementId\n\t\t\tprogramId: $programId\n\t\t\tstudentId: $studentId\n\t\t) {\n\t\t\tresult {\n\t\t\t\tranking\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tquery ratingRequest(\n\t\t$boxId: ID!\n\t\t$elementId: ID!\n\t\t$programId: ID!\n\t\t$studentId: ID!\n\t) {\n\t\tratingRequest(\n\t\t\tboxId: $boxId\n\t\t\telementId: $elementId\n\t\t\tprogramId: $programId\n\t\t\tstudentId: $studentId\n\t\t) {\n\t\t\tresult {\n\t\t\t\tranking\n\t\t\t}\n\t\t}\n\t}\n"]);

var _graphqlTag = require("graphql-tag");

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

var _graphQlHelpers = require("../graphQlHelpers");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_BRIDGE = exports.GQL_FETCH_BRIDGE = (0, _graphqlTag2.default)(_templateObject, _graphQlHelpers.bridge);

var GQL_FETCH_VIDEO_BRIDGE = exports.GQL_FETCH_VIDEO_BRIDGE = (0, _graphqlTag2.default)(_templateObject2, _graphQlHelpers.videoBridge);

var GQL_FETCH_BOOKCHECK = exports.GQL_FETCH_BOOKCHECK = (0, _graphqlTag2.default)(_templateObject3, _graphQlHelpers.bookCheck);

var GQL_FETCH_LESSON = exports.GQL_FETCH_LESSON = (0, _graphqlTag2.default)(_templateObject4, _graphQlHelpers.lesson);

var GQL_FETCH_CASE = exports.GQL_FETCH_CASE = (0, _graphqlTag2.default)(_templateObject5, _graphQlHelpers.caseQuery);

var GQL_FETCH_BETTING_GAME = exports.GQL_FETCH_BETTING_GAME = (0, _graphqlTag2.default)(_templateObject6, _graphQlHelpers.bettingGame);

var GQL_FETCH_EXERCISE_CARD = exports.GQL_FETCH_EXERCISE_CARD = (0, _graphqlTag2.default)(_templateObject7, _graphQlHelpers.exerciseCard);

var GQL_FETCH_MASTERCLASS = exports.GQL_FETCH_MASTERCLASS = (0, _graphqlTag2.default)(_templateObject8, _graphQlHelpers.masterClass);

var GQL_FETCH_FILM = exports.GQL_FETCH_FILM = (0, _graphqlTag2.default)(_templateObject9, _graphQlHelpers.film);

var GQL_FETCH_TRAINER = exports.GQL_FETCH_TRAINER = (0, _graphqlTag2.default)(_templateObject10, _graphQlHelpers.trainer);

var GQL_FETCH_EXAM_TRAINER = exports.GQL_FETCH_EXAM_TRAINER = (0, _graphqlTag2.default)(_templateObject11, _graphQlHelpers.examTrainer);

var GQL_FETCH_SLIDESHOW = exports.GQL_FETCH_SLIDESHOW = (0, _graphqlTag2.default)(_templateObject12, _graphQlHelpers.slideshowCreator);

var GQL_FETCH_BOOK_DOWNLOAD = exports.GQL_FETCH_BOOK_DOWNLOAD = (0, _graphqlTag2.default)(_templateObject13, _graphQlHelpers.bookDownload);

var GQL_FETCH_ENTRY_TEST = exports.GQL_FETCH_ENTRY_TEST = (0, _graphqlTag2.default)(_templateObject14, _graphQlHelpers.entryTest);

var GQL_FETCH_FILE = exports.GQL_FETCH_FILE = (0, _graphqlTag2.default)(_templateObject15, _graphQlHelpers.fileDownload);

var GQL_FETCH_GLOBAL_SETTINGS = exports.GQL_FETCH_GLOBAL_SETTINGS = (0, _graphqlTag2.default)(_templateObject16, _graphQlHelpers.settings);

var GQL_FETCH_SELF_EVALUATION = exports.GQL_FETCH_SELF_EVALUATION = (0, _graphqlTag2.default)(_templateObject17, _graphQlHelpers.selfEvaluation);

var GQL_FETCH_RATING = exports.GQL_FETCH_RATING = (0, _graphqlTag2.default)(_templateObject18, _graphQlHelpers.rating);

var GQL_FETCH_FINAL_RATING = exports.GQL_FETCH_FINAL_RATING = (0, _graphqlTag2.default)(_templateObject19, _graphQlHelpers.finalRating);

var GQL_FETCH_ELEMENT_RESULTS = exports.GQL_FETCH_ELEMENT_RESULTS = (0, _graphqlTag2.default)(_templateObject20, _graphQlHelpers.elementResult);

var GQL_FETCH_RATING_REQUESTS = exports.GQL_FETCH_RATING_REQUESTS = (0, _graphqlTag2.default)(_templateObject21);