import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { LoadImage } from "@manakin/app-core";
import { compose } from "recompose";
import { savable } from "@manakin/app-core/lib";
import { useWhiteOrBlackTextColor } from "@manakin/app-core/lib";
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
	root: {
		padding: "84px 30px",
		minHeight: "calc(100vh - 64px)",
		position: "relative",
		[theme.breakpoints.up("md")]: {
			height: "calc(100vh - 100px)",
			minHeight: "100%",
			display: "flex",
			padding: "0",
			alignItems: "center",
			width: "100%",
		},
		[theme.breakpoints.up("lg")]: {
			justifyContent: "space-between",
		},
	},
	content: {
		position: "relative",
		zIndex: "1",
		[theme.breakpoints.up("md")]: {
			padding: "20px 20px 20px 80px",
			width: "70%",
		},
		[theme.breakpoints.up("lg")]: {
			padding: "20px 20px 20px 130px",
			maxWidth: "600px",
		},
	},
	bgImage: {
		position: "absolute",
		width: "50vw",
		bottom: 0,
		right: 0,
		[theme.breakpoints.up("md")]: {
			position: "relative",
			width: "40%",
			height: "100%",
			display: "flex",
			alignItems: "center",
		},
		"& img": {
			flex: "1",
		},
	},
	buttonsContainer: {
		display: "flex",
		[theme.breakpoints.up("md")]: {
			flexDirection: "row",
		},
	},
	link: {
		textDecoration: "none",
	},
	button: {
		backgroundColor: "white",
		[theme.breakpoints.up("md")]: {
			width: "auto!important",
		},
	},
	done: {
		backgroundColor: theme.manakin.primaryPaletteColor[500],
		textAlign: "center",
		padding: "17px 20px",
		borderRadius: "4rem",
		margin: "0 2rem 0 0",
		display: "flex",
		alignItems: "center",
		[theme.breakpoints.up("md")]: {
			width: "auto",
		},
	},
	doneTitle: {
		lineHeight: "3.2rem",
		marginBottom: 0,
	},
});

const Outro = function(props) {
	const {
		classes,
		data,
		elementResult = {},
		workforms = [],
	} = props;
	const image = data.image ? data.image.url : null;
	const placeholder = data.image ? data.image.thumbnailUrl : null;
	const doneColor = useWhiteOrBlackTextColor(data.backgroundColor);
	const savableWorkforms = savable(workforms);
	const correctCount = savableWorkforms.filter((e) => e.correct).length;
	const { t } = useTranslation();

	const handleClick = () => {
		props.onClick(true);
	};

	return (
		<div className={classes.root}>
			<div className={classes.content}>
				<React.Fragment>
					<Typography component='h1' variant='h2'>
						{elementResult.correct
							? t("app.elements.book-check.outro.title-correct")
							: t("app.elements.book-check.outro.title-incorrect")}
					</Typography>
					<Typography variant='h5'>
						{correctCount} / {savableWorkforms.length || 0}{" "}
						{t("common.correct")}
					</Typography>
					<Typography component='p' variant='body2'>
						{elementResult.correct
							? t("app.elements.book-check.outro.description-correct")
							: t("app.elements.book-check.outro.description-incorrect")}
					</Typography>
				</React.Fragment>

				<div className={classes.buttonsContainer}>
					<div className={classes.done}>
						<Typography
							style={{ color: doneColor }}
							className={classes.doneTitle}
							align='center'
							variant='body1'
						>
							{t("common.finished")}
						</Typography>
					</div>

					<Button
						variant='outlined'
						color='primary'
						fullWidth
						className={classes.button}
						onClick={handleClick}
					>
						{t("app.elements.book-check.outro.redo")}
					</Button>
				</div>
			</div>
			{image && (
				<div className={classes.bgImage}>
					<LoadImage src={image} placeholder={placeholder} />
				</div>
			)}
		</div>
	);
};

export default compose(
	withStyles(styles, { name: "AppBookCheckOutro" })
)(Outro);
