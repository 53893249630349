import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
    root: {
        flex: '1 0 auto',
        transition: 'background-color 200ms linear',
        backgroundColor: 'transparent',
        '& *': { opacity: 1, pointerEvents: 'auto' },
        color: 'blue',
        '&$checked': {
            color: 'red',
        },
    },
    loading: {
        height: theme.spacing(6),
        backgroundColor: theme.palette.grey[100],
        '& *': { opacity: 0, pointerEvents: 'none' },
    },
    label: {
        fontSize: 18,
        marginBottom: theme.spacing(1),
    },
    selectAllLabel: {
        fontWeight: 'bold',
    },
    description: {
        fontSize: '12px',
        marginBottom: '10px',
    },
});

class CheckboxGroup extends React.Component {
    state = {
        rolesOptions: {},
        selectAll: false,
    };

    componentDidUpdate(prevProps) {
        if (prevProps.loading && !this.props.loading) {
            const { form, initialValue, name, options } = this.props;
            const _initialValue = initialValue
                ? initialValue.reduce((obj, item) => {
                      obj[item.id] = true;
                      return obj;
                  }, {})
                : initialValue;

            this.setState({ rolesOptions: _initialValue });

            form.onFieldChange({ key: name, value: initialValue });
        }
    }

    componentDidMount() {
        const { form, initialValue, name, options } = this.props;

        const _initialValue = initialValue
            ? initialValue.reduce((obj, item) => {
                  obj[item.id] = true;
                  return obj;
              }, {})
            : {};

        this.setState({ rolesOptions: _initialValue });

        form.onFieldChange({ key: name, value: initialValue });
    }

    handleChange = (event, child) => {
        if (event.target.value === 'select_all') {
            const { form, name, options } = this.props;
            const { selectAll } = this.state;
            const newSelectAll = !selectAll;

            if (!newSelectAll) {
                this.setState({
                    selectAll: newSelectAll,
                    rolesOptions: [],
                });

                form.onFieldChange({ key: name, value: [] });
            } else {
                const newRoleOptions = options.reduce((allRoles, role) => {
                    allRoles[role.id] = newSelectAll;
                    return allRoles;
                }, {});

                this.setState({
                    selectAll: newSelectAll,
                    rolesOptions: newRoleOptions,
                });

                form.onFieldChange({
                    key: name,
                    value: options.map((role) => ({ id: role.id })),
                });
            }
        } else {
            const { form, name } = this.props;
            const { fields } = form;
            const fieldsData = fields[name] || [];
            const { rolesOptions } = this.state;

            this.setState({
                selectAll: false,
                rolesOptions: {
                    ...rolesOptions,
                    [event.target.value]: event.target.checked,
                },
            });

            const idx = fieldsData.findIndex(
                (element) => element.id == event.target.value
            );
            if (idx < 0) {
                form.onFieldChange({
                    key: name,
                    value: [...fieldsData, { id: event.target.value }],
                });
            } else {
                let arr = [...fieldsData];
                arr.splice(idx, 1);
                form.onFieldChange({ key: name, value: arr });
            }
        }
    };

    render() {
        const {
            legendName = 'no legend in props',
            fullWidth = true,
            options = [],
            readOnly = false,
            form,
            name,
            classes,
            t
        } = this.props;
        const { fields } = form;
        const { rolesOptions, selectAll } = this.state;

        return (
            <FormControl fullWidth={fullWidth} margin="normal">
                <FormLabel className={classes.label}>{legendName}</FormLabel>
                <FormGroup>
                    {[
                        {
                            id: 'select_all',
                            label: t("common.select-all"),
                        },
                    ]
                        .concat(options || [])
                        .map((option, i) => (
                            <React.Fragment>
                                <FormControlLabel
                                    key={option.id}
                                    control={
                                        <Checkbox
                                            color="default"
                                            disabled={readOnly}
                                            value={option.id}
                                            checked={
                                                i === 0
                                                    ? selectAll
                                                    : rolesOptions[option.id] ||
                                                      false
                                            }
                                            onChange={this.handleChange}
                                        />
                                    }
                                    label={option.label}
                                    classes={{
                                        ...(option.id === 'select_all' && {
                                            label: classes.selectAllLabel,
                                        }),
                                    }}
                                />
                                {option.description && (
                                    <span className={classes.description}>
                                        {option.description || ''}
                                    </span>
                                )}
                            </React.Fragment>
                        ))}
                </FormGroup>
            </FormControl>
        );
    }
}

export default compose(
    withTranslation(),
    withStyles(styles)
)(CheckboxGroup);
