"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.savePermissions = undefined;
exports.hasPermission = hasPermission;

var _constants = require("@manakin/core/lib/constants");

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function hasPermission(permission) {
    var privileges = JSON.parse(localStorage.getItem(_constants.StorageKeys.PRIVILEGES) || "[]");
    return privileges.indexOf(permission) !== -1;
}

var savePermissions = exports.savePermissions = function savePermissions(roles) {
    var privileges = [];
    roles.forEach(function (role) {
        role.privileges.forEach(function (privilege) {
            privileges.push(privilege.id);
        });
    });

    localStorage.setItem(_constants.StorageKeys.PRIVILEGES, JSON.stringify([].concat(_toConsumableArray(new Set(privileges)))));
};