"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _Button = require("@material-ui/core/Button");

var _Button2 = _interopRequireDefault(_Button);

var _Dialog = require("@material-ui/core/Dialog");

var _Dialog2 = _interopRequireDefault(_Dialog);

var _DialogActions = require("@material-ui/core/DialogActions");

var _DialogActions2 = _interopRequireDefault(_DialogActions);

var _DialogContent = require("@material-ui/core/DialogContent");

var _DialogContent2 = _interopRequireDefault(_DialogContent);

var _DialogContentText = require("@material-ui/core/DialogContentText");

var _DialogContentText2 = _interopRequireDefault(_DialogContentText);

var _DialogTitle = require("@material-ui/core/DialogTitle");

var _DialogTitle2 = _interopRequireDefault(_DialogTitle);

var _styles = require("@material-ui/core/styles");

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = function styles(theme) {
    return {
        paperRoot: {
            width: '100%',
            padding: '2rem'
        },
        title: {
            marginBottom: '1.6rem'
        }
    };
};

var AppDialog = function AppDialog(props) {
    var _useTranslation = (0, _reactI18next.useTranslation)(),
        t = _useTranslation.t;

    var classes = props.classes,
        title = props.title,
        content = props.content,
        _props$buttonText = props.buttonText,
        buttonText = _props$buttonText === undefined ? t("common.continue") : _props$buttonText,
        _props$open = props.open,
        open = _props$open === undefined ? false : _props$open,
        onClose = props.onClose;


    var handleClose = function handleClose() {
        onClose();
    };

    return _react2.default.createElement(
        "div",
        null,
        _react2.default.createElement(
            _Dialog2.default,
            {
                open: open,
                onClose: handleClose,
                "aria-labelledby": "dialog-title",
                "aria-describedby": "dialog-descriptions",
                maxWidth: "sm",
                classes: { paper: classes.paperRoot }
            },
            _react2.default.createElement(
                _DialogTitle2.default,
                {
                    id: "dialog-title",
                    className: classes.title,
                    disableTypography: true
                },
                title
            ),
            _react2.default.createElement(
                _DialogContent2.default,
                null,
                _react2.default.createElement(
                    _DialogContentText2.default,
                    { id: "dialog-descriptions" },
                    content
                )
            ),
            _react2.default.createElement(
                _DialogActions2.default,
                null,
                _react2.default.createElement(
                    _Button2.default,
                    { onClick: handleClose, color: "primary" },
                    buttonText
                )
            )
        )
    );
};

exports.default = (0, _styles.withStyles)(styles)(AppDialog);