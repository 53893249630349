import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField } from "@manakin/core";
import withForm from "../form/withForm";
import { compose } from "recompose";
import { useTranslation } from "react-i18next";

const styles = (theme) => ({
    root: {},
});

const formBundle = {
    search: {},
};

const AssetViewerSearchBar = function(props) {
    const { classes, form, initialValue, onSubmit } = props;
    const { t } = useTranslation();

    const handleSearch = (value) => {
        onSubmit(value);
    };

    return (
        <div className={classes.root}>
            <TextField
                name='search'
                label={t("common.fields.search.label")}
                placeholder={t("common.fields.search.placeholder")}
                initialValue={initialValue}
                form={form}
                onEnterPressed={handleSearch}
            />
        </div>
    );
};

export default compose(
    withForm(formBundle),
    withStyles(styles),
)(AssetViewerSearchBar);
