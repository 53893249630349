import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { useCountUp } from 'react-countup';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
    startPoints: {
        position: 'relative',
        zIndex: 1,
        width: '96px',
        height: '96px',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            width: '132px',
            height: '132px',
        },
    },
    pointsTitle: {
        margin: 0,
        color: theme.palette.getContrastText(theme.manakin.defaultElementBGColor[500]),
        fontFamily: theme.manakin.defaultTitleFont,
        fontSize: '3rem',
        lineHeight: 1,
    },
    pointsBody: {
        margin: 0,
        lineHeight: 1,
        color: theme.palette.getContrastText(theme.manakin.defaultElementBGColor[500]),
    },
    pointDifference: {
        position: 'absolute',
        zIndex: '-1',
        top: 0,
        left: '50%',
        transform: 'translate(-50%, 0)',
        opacity: 0,
        transition: 'transform .3s ease, opacity .3s ease, color .3s .3s ease',
        backfaceVisibility: 'hidden',
        fontFamily: theme.manakin.defaultTitleFont,
        fontSize: '4rem',
        lineHeight: 1,
    },
    correct: {
        opacity: 1,
        transform: 'translate(-50%, -150%)',
        color: 'limegreen',
        transition: 'transform .3s ease, opacity .3s ease, color .3s ease',
    },
    incorrect: {
        opacity: 1,
        transform: 'translate(-50%, -150%)',
        color: 'orangered',
        transition: 'transform .3s ease, opacity .3s ease, color .3s ease',
    },
});

const PointsInfo = (props) => {
    // Props
    const { classes, points = 0 } = props;
    const { t } = useTranslation();

    // State hooks
    const [prevPoints, setPrevPoints] = useState(0);
    const [_points, setDifference] = useState(0);
    const [showPointsInfo, setShowPointsInfo] = useState(0);

    // Functions
    useEffect(() => {
        setPrevPoints(points);
    });

    useEffect(() => {
        setDifference({
            difference: points - prevPoints,
            correct: points > prevPoints ? true : false,
        });
        setPrevPoints(points);
        update(points);
        setShowPointsInfo(true);
    }, [points]);

    const handleCountEnd = () => {
        setShowPointsInfo(false);
    };

    const { countUp, update } = useCountUp({
        start: prevPoints,
        end: points,
        onEnd: () => handleCountEnd(),
    });

    return (
        <div className={classes.startPoints}>
            <span
                className={`${classes.pointDifference}${
                    showPointsInfo
                        ? _points.correct
                            ? ` ${classes.correct}`
                            : ` ${classes.incorrect}`
                        : ''
                }`}
            >
                {_points.correct && '+'}
                {_points.difference}
            </span>
            <div className={classes.pointsTitle}>{countUp}</div>
            <Typography variant="body1" className={classes.pointsBody}>
                {t("app.elements.betting-game.points")}
            </Typography>
        </div>
    );
};

export default compose(
    connect((state) => ({ config: state.config })),
    withStyles(styles, { name: 'AppPointsInfo' })
)(PointsInfo);
