import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import ArrowUp from '@material-ui/icons/ArrowUpward';
import CorrectIcon from '@material-ui/icons/Done';
import { ImageWithFocusPoint } from "@manakin/app-core";

const styles = (theme) => ({
    text: {
        margin: '0',
    },
    item: {
        border: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
        width: '100%',
        height: '150px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        padding: '1rem 3.4rem 1rem 1rem',
        position: 'relative',
        transform: 'translateY(0)',
        backgroundColor: 'white',
        transition: 'background-color .3s',
        '& $text': {
            fontSize: '1.6rem',
            lineHeight: '2.6rem',
            [theme.breakpoints.up('md')]: {
                fontSize: '1.8rem',
                lineHeight: '3.6rem',
            },
        },
        [theme.breakpoints.up('md')]: {
            height: '200px',
            padding: '6rem 3rem 2rem 3rem',
            justifyContent: 'center',
        },
        [theme.breakpoints.up('lg')]: {
            height: 'auto',
            minHeight: '20rem',
            padding: '8rem 2rem 4rem',
        },
    },
    isQuestion: {
        padding: '1rem',
        [theme.breakpoints.up('md')]: {
            padding: '3rem',
        },
    },
    image: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        fontFamily: "'object-fit: cover'",
        objectPosition: 'center',
        left: 0,
        top: 0,
    },
    arrow: {
        backgroundColor: 'white',
        width: '30px',
        height: '30px',
        position: 'absolute',
        right: '1rem',
        border: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transformOrigin: 'center',
        cursor: 'pointer',
        transition: 'background-color .3s',
        [theme.breakpoints.up('md')]: {
            width: '50px',
            height: '50px',
        },
        [theme.breakpoints.up('lg')]: {
            top: '1rem',
            transform: 'rotate(-90deg)',
        },
        '&:hover': {
            [theme.breakpoints.up('md')]: {
                backgroundColor: theme.manakin.secondaryColor[500],
            },
        },
    },
    arrowNext: {
        transform: 'rotate(180deg)',
        bottom: '1rem',
        [theme.breakpoints.up('lg')]: {
            right: '1rem',
            transform: 'rotate(90deg)',
        },
    },
    arrowBack: {
        top: '1rem',
        [theme.breakpoints.up('lg')]: {
            right: 'auto',
            left: '1rem',
        },
    },
    arrows: {
        opacity: 1,
        transition: 'opacity .1s',
    },
    animatePrev: {
        transform: 'translateY(-100%)',
        transition: 'transform .3s',
        '& $arrows': {
            opacity: 0,
        },
        [theme.breakpoints.up('lg')]: {
            transform: 'translate(-100%, 0)',
        },
    },
    animateNext: {
        transform: 'translateY(100%)',
        transition: 'transform .3s',
        '& $arrows': {
            opacity: 0,
        },
        [theme.breakpoints.up('lg')]: {
            transform: 'translate(100%, 0)',
        },
    },
    animatePrevGap: {
        transform: 'translateY(-200%)',
        transition: 'transform .3s',
        '& $arrows': {
            opacity: 0,
        },
        [theme.breakpoints.up('lg')]: {
            transform: 'translate(-200%, 0)',
        },
    },
    animateNextGap: {
        transform: 'translateY(200%)',
        transition: 'transform .3s',
        '& $arrows': {
            opacity: 0,
        },
        [theme.breakpoints.up('lg')]: {
            transform: 'translate(200%, 0)',
        },
    },
    correctIcon: {
        position: 'absolute',
        top: '1rem',
        right: '1rem',
        width: '30px',
        height: '30px',
        padding: theme.spacing(0.5),
        border: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
        backgroundColor: theme.manakin.correctAnswer[500],
        [theme.breakpoints.up('md')]: {
            width: '50px',
            height: '50px',
            padding: theme.spacing(1.5),
        },
    },
});

class Item extends React.PureComponent {
    render() {
        const {
            classes,
            item,
            isAnswer = false,
            idx,
            length,
            animateNext,
            animatePrev,
            onArrowClick,
            correctAnswer,
            animationSettings = false,
            allCorrect = false,
        } = this.props;

        return (
            <div
                className={classNames(
                    classes.item,
                    { [classes.hasImage]: item.image },
                    { [classes.isQuestion]: !isAnswer },
                    { [classes.animateNext]: animateNext == idx && isAnswer && !animationSettings.gap, },
                    { [classes.animatePrev]: animatePrev == idx && isAnswer && !animationSettings.gap, },
                    { [classes.animateNextGap]: animateNext == idx && isAnswer && animationSettings.gap, },
                    { [classes.animatePrevGap]: animatePrev == idx && isAnswer && animationSettings.gap, }
                )}
            >
                {item.image ? (
                    <ImageWithFocusPoint asset={item.image} imageProps={{className: classes.image}}/>
                ) : (
                    <Typography component="p" variant="body1" className={classes.text}>
                        {item.text}
                    </Typography>
                )}
                {isAnswer &&
                    (correctAnswer !== item.id && !allCorrect ? (
                        <div className={classes.arrows}>
                            {idx == 0 && (
                                <div
                                    onClick={() => onArrowClick({ direction: 'next', idx: idx, })}
                                    className={classNames(classes.arrow, classes.arrowNext)}
                                >
                                    <ArrowUp />
                                </div>
                            )}
                            {idx > 0 && idx < length - 1 && (
                                <div>
                                    <div
                                        onClick={() => onArrowClick({ direction: 'next', idx: idx, })}
                                        className={classNames(classes.arrow, classes.arrowNext)}
                                    >
                                        <ArrowUp />
                                    </div>
                                    <div
                                        onClick={() => onArrowClick({ direction: 'back', idx: idx, })}
                                        className={classNames(classes.arrow, classes.arrowBack)}
                                    >
                                        <ArrowUp />
                                    </div>
                                </div>
                            )}
                            {idx == length - 1 && (
                                <div
                                    onClick={() => onArrowClick({ direction: 'back', idx: idx, })}
                                    className={classNames(classes.arrow, classes.arrowBack)}
                                >
                                    <ArrowUp />
                                </div>
                            )}
                        </div>
                    ) : (
                        <CorrectIcon className={classes.correctIcon} />
                    ))}
            </div>
        );
    }
}

export default withStyles(styles, { name: 'AppWhatIsWhatItem' })(Item);
