'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.toggleAppMenu = toggleAppMenu;
var TOGGLE_APP_MENU_APP = exports.TOGGLE_APP_MENU_APP = '@manakin/core/TOGGLE_APP_MENU_APP';

function toggleAppMenu() {
    return {
        type: TOGGLE_APP_MENU_APP,
        payload: {}
    };
}