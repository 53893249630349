import React, { useEffect } from 'react';
import { GQL_FETCH_DIGITAL_BOOK } from './graphql';
import { useViewCounting } from '@manakin/hooks';
import { ElementOverlay, Loader } from '@manakin/app-core';
import renderHTML from 'react-render-html';
import { makeStyles } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    content: {
        width: '100%',
        [theme.breakpoints.up('xl')]: {
            padding: '0 21rem',
        },
    },
}));

const DigitalBookContainer = (props) => {
    const { viewElement } = useViewCounting();
    const { elementId } = useParams();
    const classes = useStyles();

    const { data = {}, loading } = useQuery(GQL_FETCH_DIGITAL_BOOK, {
        variables: {
            id: elementId,
        },
    });
    const { digitalBook = {} } = data || {};

    useEffect(() => viewElement(elementId), []);

    if (loading) return (<div><Loader fullScreen={true} /></div>);

    return (
        <ElementOverlay controls={true} fullWidth={true}>
            <div className={classes.content}>
                {renderHTML(digitalBook.book && digitalBook.book.embedCode)}
            </div>
        </ElementOverlay>
    );
};

export default DigitalBookContainer;
