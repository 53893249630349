"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.GQL_FETCH_INVITATION_STUDENTS = exports.GQL_FETCH_RATING_REQUESTS = exports.GQL_FETCH_INVITES = exports.GQL_FETCH_MESSAGES = undefined;

var _templateObject = _taggedTemplateLiteral(["\n\tquery currentMessagesReceived {\n\t\tcurrentMessagesReceived {\n\t\t\tmessagesReceived {\n\t\t\t\tid\n\t\t\t\treadOn\n\t\t\t}\n\t\t\tcount\n\t\t}\n\t}\n"], ["\n\tquery currentMessagesReceived {\n\t\tcurrentMessagesReceived {\n\t\t\tmessagesReceived {\n\t\t\t\tid\n\t\t\t\treadOn\n\t\t\t}\n\t\t\tcount\n\t\t}\n\t}\n"]),
    _templateObject2 = _taggedTemplateLiteral(["\n\tquery invitations {\n\t\tinvitations {\n\t\t\tid\n\t\t\tstate\n\t\t\ttrainerEmail\n\t\t\ttrainerRole {\n\t\t\t\tname\n\t\t\t\ttitle\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tquery invitations {\n\t\tinvitations {\n\t\t\tid\n\t\t\tstate\n\t\t\ttrainerEmail\n\t\t\ttrainerRole {\n\t\t\t\tname\n\t\t\t\ttitle\n\t\t\t}\n\t\t}\n\t}\n"]),
    _templateObject3 = _taggedTemplateLiteral(["\n\tquery ratingRequests(\n\t\t$filter: RatingRequestFilter\n\t\t$page: Int!\n\t\t$pagesize: Int!\n\t) {\n\t\tratingRequests(filter: $filter, pageNr: $page, pageSize: $pagesize) {\n\t\t\tid\n\t\t\tfinalRating\n\t\t\tresult {\n\t\t\t\trater {\n\t\t\t\t\tfullName\n\t\t\t\t\temail\n\t\t\t\t\troles {\n\t\t\t\t\t\tid\n\t\t\t\t\t\tname\n\t\t\t\t\t\ttitle\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t\tranking\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tquery ratingRequests(\n\t\t$filter: RatingRequestFilter\n\t\t$page: Int!\n\t\t$pagesize: Int!\n\t) {\n\t\tratingRequests(filter: $filter, pageNr: $page, pageSize: $pagesize) {\n\t\t\tid\n\t\t\tfinalRating\n\t\t\tresult {\n\t\t\t\trater {\n\t\t\t\t\tfullName\n\t\t\t\t\temail\n\t\t\t\t\troles {\n\t\t\t\t\t\tid\n\t\t\t\t\t\tname\n\t\t\t\t\t\ttitle\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t\tranking\n\t\t\t}\n\t\t}\n\t}\n"]),
    _templateObject4 = _taggedTemplateLiteral(["\n    query invitationStudents {\n        invitationStudents {\n            id\n        }\n    }\n"], ["\n    query invitationStudents {\n        invitationStudents {\n            id\n        }\n    }\n"]);

var _graphqlTag = require("graphql-tag");

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_MESSAGES = exports.GQL_FETCH_MESSAGES = (0, _graphqlTag2.default)(_templateObject);

var GQL_FETCH_INVITES = exports.GQL_FETCH_INVITES = (0, _graphqlTag2.default)(_templateObject2);

var GQL_FETCH_RATING_REQUESTS = exports.GQL_FETCH_RATING_REQUESTS = (0, _graphqlTag2.default)(_templateObject3);

var GQL_FETCH_INVITATION_STUDENTS = exports.GQL_FETCH_INVITATION_STUDENTS = (0, _graphqlTag2.default)(_templateObject4);