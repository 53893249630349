'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _appCore = require('@manakin/app-core');

var _reactApollo = require('react-apollo');

var _reactI18next = require('react-i18next');

var _graphql = require('./graphql');

var _selectors = require('@manakin/app-core/ProgramsDropdown/selectors');

var _reactRedux = require('react-redux');

var _core = require('@material-ui/core');

var _CheckRounded = require('@material-ui/icons/CheckRounded');

var _CheckRounded2 = _interopRequireDefault(_CheckRounded);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useStyles = (0, _core.makeStyles)(function (theme) {
    return {
        rating: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '2px solid ' + theme.palette.success.main,
            borderRadius: '100%',
            height: '54px',
            width: '54px'
        },
        checkIcon: {
            color: theme.palette.success.main,
            height: 28,
            width: 28
        }
    };
});

var StudentRatingCard = function StudentRatingCard(props) {
    var classes = useStyles();
    var data = props.data,
        studentId = props.studentId,
        disabled = props.disabled,
        image = props.image,
        onClick = props.onClick;

    var _useTranslation = (0, _reactI18next.useTranslation)(),
        t = _useTranslation.t;

    var program = (0, _reactRedux.useSelector)(function (state) {
        return (0, _selectors.getProgram)(state);
    });

    // Queries

    var _useQuery = (0, _reactApollo.useQuery)(_graphql.GQL_FETCH_SELF_EVALUATION_RESULT, {
        skip: !data || !data.boxen || data.boxen.length === 0,
        variables: {
            userId: studentId,
            programId: program,
            boxId: data && data.boxen && data.boxen.length > 0 && data.boxen[0].id,
            elementId: data.id
        }
    }),
        evaluationResultData = _useQuery.data,
        error = _useQuery.error,
        loading = _useQuery.loading;

    var _ref = evaluationResultData || {},
        _ref$elementResult = _ref.elementResult,
        elementResult = _ref$elementResult === undefined ? {} : _ref$elementResult;

    if (error) t('common.loading-data-failed');
    if (loading) return _react2.default.createElement(_appCore.Loader, null);

    return _react2.default.createElement(
        'div',
        { onClick: onClick },
        _react2.default.createElement(
            _appCore.StudentCard,
            { studentId: studentId, disabled: disabled, data: data, image: image },
            _react2.default.createElement(
                _react2.default.Fragment,
                null,
                _react2.default.createElement('div', { className: classes.spacer }),
                _react2.default.createElement('div', { className: classes.spacer })
            )
        )
    );
};

exports.default = StudentRatingCard;