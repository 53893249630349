import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import classNames from 'classnames';
import { useQuery } from "react-apollo";

import MailIcon from '@material-ui/icons/MailOutlined';
import { GQL_FETCH_RATING_REQUESTS } from "../graphql/graphql";
import { useHistory } from "react-router-dom";
import { filterPendingRatingRequests } from "@manakin/app-core/lib";

const styles = (theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexGrow: 1
    },
    container: {
        display: "flex",
        flexDirection: "row",
        cursor: "pointer"
    },
    icon: {
        width: 56,
        height: 56,
        color: theme.manakin.subHeaderColor[500],
        marginRight: theme.spacing(2)
    },
    title: {
        color: theme.manakin.indicationColor[500],
        fontWeight: "400",
        marginBottom: 0,
        textDecoration: "underline"
    },
    subHeader: {},
    contentColor: {},
});

const RateRequestsBlock = (props) => {
	const { currentAppUser, program } = props;
	const { classes } = props;
    const history = useHistory();
    const [ ratingRequests, setRatingRequests ] = useState([]);

	// Query
    const { data = {} } = useQuery(GQL_FETCH_RATING_REQUESTS, {
        skip: currentAppUser.id === undefined || program.id === undefined,
        variables: {
            pageNr: 0,
            pageSize: 999,
            filter: {
                studentId: currentAppUser.id,
                programId: program.id
            }
        },
        onCompleted: data => {
            const requests = filterPendingRatingRequests(data);
            if (requests) setRatingRequests(requests);
        }
    });

    const handleClick = () => {
      	history.push("/ratingRequests");
    };

    return (
        <div className={classes.root}>
            <div className={classes.container} onClick={handleClick}>
                <MailIcon className={classes.icon}/>
                <Typography
                    component="h1"
                    variant="h3"
                    className={classNames(
                        classes.title
                    )}>
                    {ratingRequests.length}
                </Typography>
            </div>
        </div>
    );
};

export default compose(
    withStyles(styles, { name: 'AppProgressDashboardRateRequestsBlock' })
)(RateRequestsBlock);
