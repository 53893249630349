import React, { useEffect } from 'react';
import { ElementOverlay, Loader } from '@manakin/app-core';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { compose } from 'recompose';
import renderHTML from 'react-render-html';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
    smallWrapper: {
        ...theme.manakin.smallWrapper,
        maxWidth: '75rem',
    },
    wrapper: {
        ...theme.manakin.smallWrapper,
    },
    contentColor: {
        color: theme.manakin.primaryColor[500],
    },
    content: {
        padding: '2rem 0 8rem',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            padding: '5rem 0 10rem',
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: '12rem',
            paddingBottom: '12rem',
        },
    },
    heading: {
        [theme.breakpoints.up('md')]: {
            fontSize: '5.6rem',
            lineHeight: '7rem',
        },
    },
    marginBottom: {
        marginBottom: '2rem',
    },
    list: {
        paddingLeft: '2rem',
    },
    body: {
        color: theme.manakin.defaultContentColor[500],
        '& ul': {
            padding: '0 0 0 2rem',
        },
    },
});

const Recap = (props) => {
    const { summary, workformsData, classes, loading, controls = true } = props;
    const { t } = useTranslation();

    //effect hooks
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (loading) {
        return (
            <div>
                <Loader fullScreen={true} />
            </div>
        );
    }
    return (
        <div className={classes.root}>
            <ElementOverlay controls={controls}>
                <div className={classes.content}>
                    <div className={classes.smallWrapper}>
                        <Typography variant="h1" className={classes.heading}>
                            {summary.introTitle || t("common.element-types.summary")}
                        </Typography>
                    </div>
                    <div className={classes.smallWrapper}>
                        <Typography
                            variant="body1"
                            component="div"
                            className={classes.body}
                        >
                            <div className={classes.marginBottom}>
                                {renderHTML(summary.introText || '')}
                            </div>
                            {workformsData.workforms &&
                                workformsData.workforms.map((item) => (
                                    <React.Fragment key={item.id}>
                                        {renderHTML(item.text || '')}
                                    </React.Fragment>
                                ))}
                        </Typography>
                    </div>
                </div>
            </ElementOverlay>
        </div>
    );
};

export default compose(withStyles(styles))(Recap);
