"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _styles = require("@material-ui/core/styles");

var _recompose = require("recompose");

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _ThumbUp = require("@material-ui/icons/ThumbUp");

var _ThumbUp2 = _interopRequireDefault(_ThumbUp);

var _ThumbDown = require("@material-ui/icons/ThumbDown");

var _ThumbDown2 = _interopRequireDefault(_ThumbDown);

var _IconButton = require("@material-ui/core/IconButton");

var _IconButton2 = _interopRequireDefault(_IconButton);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
	return {
		iconButton: _defineProperty({
			width: "50px",
			height: "50px",
			color: "#C4C4C4",
			border: "2px solid " + theme.manakin.softBorderColor[500],
			"&:first-child": {
				marginRight: theme.spacing(2)
			}
		}, theme.breakpoints.up("md"), {
			width: "100px",
			height: "100px"
		}),
		icon: _defineProperty({
			width: "16px",
			height: "16px"
		}, theme.breakpoints.up("md"), {
			width: "36px",
			height: "36px"
		}),
		reverseIcon: {
			transform: "scaleX(-1)"
		},
		thumbUp: {
			border: "none",
			backgroundColor: theme.palette.success.main,
			color: theme.palette.success.contrastText,
			"&:hover": {
				backgroundColor: theme.palette.success.dark
			},
			"&:active": {
				backgroundColor: theme.palette.success.main
			}
		},
		thumbDown: {
			border: "none",
			backgroundColor: theme.palette.error.main,
			color: theme.palette.error.contrastText,
			"&:hover": {
				backgroundColor: theme.palette.error.dark
			},
			"&:active": {
				backgroundColor: theme.palette.error.main
			}
		}
	};
};

var Rate = function Rate(props) {
	var classes = props.classes,
	    _props$readOnly = props.readOnly,
	    readOnly = _props$readOnly === undefined ? false : _props$readOnly;

	var _useState = (0, _react.useState)(undefined),
	    _useState2 = _slicedToArray(_useState, 2),
	    value = _useState2[0],
	    setValue = _useState2[1];

	(0, _react.useEffect)(function () {
		setValue(props.initialValue);
	}, []);

	(0, _react.useEffect)(function () {
		setValue(props.initialValue);
	}, [props.initialValue]);

	var handleChange = function handleChange(value) {
		if (!readOnly) {
			props.onChange(value);
			setValue(value);
		}
	};

	return _react2.default.createElement(
		"div",
		null,
		_react2.default.createElement(
			_IconButton2.default,
			{
				onClick: function onClick() {
					return handleChange(true);
				},
				className: (0, _classnames2.default)(classes.iconButton, _defineProperty({}, classes.thumbUp, value === true))
			},
			_react2.default.createElement(_ThumbUp2.default, { className: classes.icon })
		),
		_react2.default.createElement(
			_IconButton2.default,
			{
				color: "primary",
				onClick: function onClick() {
					return handleChange(false);
				},
				className: (0, _classnames2.default)(classes.iconButton, _defineProperty({}, classes.thumbDown, value === false))
			},
			_react2.default.createElement(_ThumbDown2.default, {
				className: (0, _classnames2.default)(classes.icon, classes.reverseIcon)
			})
		)
	);
};

exports.default = (0, _recompose.compose)((0, _styles.withStyles)(styles, { name: "Rate" }))(Rate);