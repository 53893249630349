import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				backgroundColor: theme.manakin.secondaryBackgroundColor[500],
				width: '100%',
				paddingTop: '8rem',
				paddingBottom: '8rem',
			},
			header: {
				paddingBottom: '100px',
			},
			history: {
				backgroundColor: theme.manakin.secondaryBackgroundColor[300],
				width: '100%',
				paddingTop: '8rem',
				paddingBottom: '8rem',
			},
			wrapper: {
				...theme.manakin.smallWrapper,
				textAlign: 'center',
			},
			retryButton: {
				marginRight: '10px',
			},
			buttons: {
				marginTop: '50px',
			},
		}),
	{ name: 'AppResultPage' }
);
