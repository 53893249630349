export const widget = {
    newsAppWidget: "NewsAppWidget",
    boxAppWidget: "BoxAppWidget",
    suggestionBoxAppWidget: "SuggestionBoxAppWidget",
    pollAppWidget: "PollAppWidget",
    welcomeAppWidget: "WelcomeAppWidget",
};

export const backgroundColorKeys = {
    primary: 'PRIMARY',
    secondary: 'SECONDARY',
    none: 'NONE',
}