export const styles = (theme) => ({
	root: {
		width: '100%',
		minHeight: '90vh',
		backgroundColor: theme.manakin.secondaryColor[200],
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		justifyContent: 'center',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		position: 'relative',
		padding: '100px 0',
		opacity: 0,
		animation: 'fadeIn .3s ease 1s forwards',
		[theme.breakpoints.up('md')]: {
			minHeight: '720px',
			padding: '13rem 0 30rem',
		},
	},
	wrapper: {
		...theme.manakin.defaultWrapper,
		maxWidth: '70rem',
	},
	content: {
		textAlign: 'center',
		opacity: 0,
		animation: 'show .3s ease 1.3s forwards',
		margin: '0 auto',
	},
	heading: {
		'&::after': {
			backgroundColor: theme.manakin.indicationColor[500],
		},
	}, 
	fontStyles: {},
});
