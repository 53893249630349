import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Carousel, NewsItem, Loader } from '@manakin/app-core';
import classNames from 'classnames';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { GQL_FETCH_NEWS_LIST } from '../graphql';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import WidgetHeader from '../WidgetHeader';

const styles = (theme) => ({
	root: {
		paddingTop: '45px',
		[theme.breakpoints.up('sm')]: {
			padding: '45px 0',
		},
	},
	boxItem: {
		padding: '0 12px 0',
	},
	first: {
		padding: '0 12px 0 0',
	},
	carouselRoot: {
		paddingRight: '8px',

		[theme.breakpoints.up('sm')]: {
			overflow: 'hidden',
		},
	},
	carouselWrapper: {
		overflow: 'hidden',
		paddingBottom: '6.5rem',

        '& .slider-control-centerleft': {
            bottom: '-30px!important',
            top: 'auto!important',
        },
        '& .slider-control-centerright': {
            bottom: '-30px!important',
            top: 'auto!important',
        },
	},
});

const NewsCarousel = (props) => {
	const { classes, data = {}, title, backgroundColor } = props;
	const { loading = true, newsList = {} } = data;
	const { t } = useTranslation();
	const history = useHistory();

	const handleGoToNews = () => history.push('/account/news');

	if (loading) {
		return <Loader />;
	}

	if (newsList.count === 0) {
		return '';
	}

	return (
		<React.Fragment>
			<div className={classes.carouselWrapper}>
				<WidgetHeader title={title || t('app-core.news-carousel.title')} backgroundColor={backgroundColor} onClick={handleGoToNews}></WidgetHeader>
				<div className={classes.carouselRoot}>
					<Carousel
						slidesToShow={1.2}
						slidesToShowSM={2.2}
						slidesToShowMD={2.6}
						withoutControls={false}
					>
						{newsList.news &&
							newsList.news.map((news, idx) => (
								<div
									key={news.id}
									className={classNames(classes.newsItem, {
										[classes.first]: idx === 0,
									})}
								>
									<NewsItem {...news} />
								</div>
							))}
					</Carousel>
				</div>
			</div>
		</React.Fragment>
	);
};

export default compose(
	withStyles(styles),
	graphql(GQL_FETCH_NEWS_LIST, {
		options: (props) => ({
			variables: {
				page: 0,
				pagesize: 10,
				filter: {
					category: props.category ? [...props.category] : null,
				},
			},
		}),
	})
)(NewsCarousel);
