import React, { useState, useContext, useEffect, useMemo } from 'react';
import Introduction from './parts/Introduction';
import { SelectField, TextField } from '@manakin/core';
import { GQL_FETCH_REGISTRATION_GROUPS } from '../graphql';
import { useQuery, useLazyQuery } from 'react-apollo';
import StepsContext from './context/stepsContext';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { SettingAccessControl } from '@manakin/core';
import { SettingsKeys } from '@manakin/core/lib/constants';
import { isSettingEnabled } from '@manakin/core/actions';

const GroupsData = (props) => {
	const { form = {} } = props;
	const classes = useStyles();
	const { t } = useTranslation();

	const [selectedOrganisation, setSelectedOrganisation] = useState(false);
	const { step, setDisabled } = useContext(StepsContext);

	//queries
	const [getSchools, { data: schoolData, error: schoolError, loading }] = useLazyQuery(GQL_FETCH_REGISTRATION_GROUPS, {
		onCompleted: () => {
			if (!schoolError) {
				setSelectedOrganisation(true);
			}
		},
	});
	const { registrationGroups: schoolDataOptions = [] } = schoolData || {};

	const { data, error } = useQuery(GQL_FETCH_REGISTRATION_GROUPS, {
		variables: {
			page: 0,
			pagesize: 9999,
			filter: {
				types: ['Organisation'],
				visibleForRegistration: true
			},
		},
	});
	const { registrationGroups = [] } = data || {};

	const handleChange = (value) => {
		if (!value || !value.value) {
			setSelectedOrganisation(false);
		}
	};

	useEffect(() => {
		if (form.fields.group) {
			getSchools({
				variables: {
					page: 0,
					pagesize: 999,
					filter: {
						types: ['School'],
						// visibleForRegistration: true,
						organisations: [form.fields.group],
					},
				},
			});
		}
	}, [form.fields.group]);

	useEffect(() => {
		if (form.fields && form.fields.school) {
			setDisabled(step + 1);
		} else {
			setDisabled(step);
		}
	}, [form.fields.school]);

	const changeFormRequired = (settingName, name) => {
		if (!settingName) return;
		const required = isSettingEnabled(settingName);
		form.onSchemaChange(name, { ...form.schema[name], required: required });
	};

	useEffect(() => {
		changeFormRequired(SettingsKeys.REGISTER_CODE_FIELD_REQUIRED, 'registerCode');
	}, []);

	if (error || schoolError) return <React.Fragment>{t('common.something-went-wrong')}</React.Fragment>;
	return (
		<React.Fragment>
			<Introduction
				title={t('authentication.webshop.groups-data.title')}
				introduction={t('authentication.webshop.groups-data.title')}
			/>
			<div className={classes.content}>
				<div className={classes.wrapper}>
					<div className={classes.row}>
						<SelectField
							options={registrationGroups}
							name="group"
							label={t('common.fields.organisation.label')}
							form={form}
							initialValue={{
								id: form.fields && form.fields.group,
							}}
							onChange={handleChange}
						/>
					</div>
					{selectedOrganisation && (
						<SelectField
							loading={loading || !schoolDataOptions.length}
							label={t('common.fields.school.label')}
							form={form}
							name="school"
							initialValue={form.fields && form.fields.school}
							options={schoolDataOptions}
						/>
					)}
					<SettingAccessControl name={SettingsKeys.REGISTER_CODE_FIELD_ENABLED}>
						<div className={classes.row}>
							<TextField
								form={form}
								name="registerCode"
								label={t('common.fields.register-code.label')}
								placeholder={t('common.fields.register-code.placeholder')}
								shrink={false}
								initialValue={(form.fields && form.fields.registerCode) || ''}
							/>
						</div>
					</SettingAccessControl>
				</div>
			</div>
		</React.Fragment>
	);
};

export default GroupsData;
