'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _appWorkforms = require('@manakin/app-workforms');

var _styles = require('@material-ui/core/styles');

var _appCore = require('@manakin/app-core');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var useStyles = (0, _styles.makeStyles)(function (theme) {
	return (0, _styles.createStyles)({
		tags: _defineProperty({
			padding: '0 25px'
		}, theme.breakpoints.up('md'), {
			padding: 0
		}),
		absoluteTag: _defineProperty({
			position: 'absolute',
			top: '20px',
			left: '20px',
			zIndex: 999,
			padding: '0 25px'
		}, theme.breakpoints.down('md'), {
			padding: '0',
			top: '0px',
			position: 'relative',
			transform: 'translateY(60px)'
		})
	});
}, {
	name: 'appWorkformViewer'
});

var WorkformViewer = function WorkformViewer(props) {
	var _props$item = props.item,
	    item = _props$item === undefined ? {} : _props$item,
	    onFinish = props.onFinish,
	    _props$options = props.options,
	    options = _props$options === undefined ? {} : _props$options,
	    _props$type = props.type,
	    type = _props$type === undefined ? '' : _props$type,
	    _props$contentStyle = props.contentStyle,
	    contentStyle = _props$contentStyle === undefined ? 'default' : _props$contentStyle,
	    _props$direction = props.direction,
	    direction = _props$direction === undefined ? 'default' : _props$direction,
	    _props$customLink = props.customLink,
	    customLink = _props$customLink === undefined ? false : _props$customLink,
	    _props$withNext = props.withNext,
	    withNext = _props$withNext === undefined ? false : _props$withNext,
	    animateProp = props.animate,
	    tagClass = props.tagClass,
	    _props$exceptions = props.exceptions,
	    exceptions = _props$exceptions === undefined ? [] : _props$exceptions,
	    saving = props.saving,
	    onSetWorkformDone = props.onSetWorkformDone;

	var classes = useStyles();

	var _useState = (0, _react.useState)(false),
	    _useState2 = _slicedToArray(_useState, 2),
	    animate = _useState2[0],
	    setAnimate = _useState2[1];

	//effect hooks


	(0, _react.useEffect)(function () {
		var timeout = null;
		if (animateProp) {
			timeout = setTimeout(function () {
				setAnimate(true);
			}, 300);
		} else {
			setAnimate(false);
		}

		return function () {
			return clearTimeout(timeout);
		};
	}, [animateProp]);

	if (exceptions.includes(type)) return '';

	return _react2.default.createElement(
		_react2.default.Fragment,
		null,
		_react2.default.createElement(_appCore.Tags, {
			tags: item.tags || [],
			className: (0, _classnames2.default)(classes.tags, tagClass, _defineProperty({}, classes.absoluteTag, type === 'StatementQuestion' || type === 'TitleAndText' || type === 'TitleAndTextImage' || type === 'ImageWithSubtitle'))
		}),
		type == 'PollQuestion' && _react2.default.createElement(_appWorkforms.PollQuestion, { data: item }),
		type == 'OpenQuestion' && _react2.default.createElement(_appWorkforms.OpenQuestion, { data: item, options: options, onFinished: onFinish }),
		type == 'ReferralElement' && _react2.default.createElement(_appWorkforms.ReferralElement, { data: item }),
		type === 'ChatBubble' && _react2.default.createElement(_appWorkforms.ChatBubble, { data: item, animate: animate }),
		type == 'FactCardReferral' && _react2.default.createElement(_appWorkforms.FactCardReferral, { data: item, options: options, onFinished: onFinish, customLink: customLink, withNext: withNext }),
		type == 'FactCardsReferral' && _react2.default.createElement(_appWorkforms.FactCardsReferral, { data: item, options: options, onFinished: onFinish, customLink: customLink, withNext: withNext }),
		type == 'FileDownload' && _react2.default.createElement(_appWorkforms.DownloadButton, { data: item, withNext: withNext, onFinished: onFinish }),
		type == 'Hotspot' && _react2.default.createElement(_appWorkforms.Hotspot, { data: item, options: options, onFinished: onFinish }),
		type == 'HotspotMap' && _react2.default.createElement(_appWorkforms.HotspotMap, { data: item, options: options, onFinished: onFinish }),
		type == 'ImageAndTextMPC' && _react2.default.createElement(_appWorkforms.ImageAndTextMPC, { data: item, options: options, onFinished: onFinish, direction: direction, saving: saving }),
		type == 'ImageAndTextCheckQuestion' && _react2.default.createElement(_appWorkforms.ImageAndTextCheckQuestion, { data: item, options: options, onFinished: onFinish, saving: saving }),
		type == 'ImageCheckQuestion' && _react2.default.createElement(_appWorkforms.ImageCheckQuestion, { data: item, options: options, onFinished: onFinish, saving: saving }),
		type == 'ImageMPC' && _react2.default.createElement(_appWorkforms.ImageMPC, { data: item, options: options, onFinished: onFinish, direction: direction, saving: saving }),
		type == 'ImageWithSubtitle' && _react2.default.createElement(_appWorkforms.ImageWithSubtitle, { data: item, onFinished: onFinish, withNext: withNext }),
		type == 'Moodboard' && _react2.default.createElement(_appWorkforms.Moodboard, { data: item }),
		type == 'OrderQuestion' && _react2.default.createElement(_appWorkforms.OrderQuestion, { data: item, options: options, onFinished: onFinish, saving: saving }),
		type == 'ShortText' && _react2.default.createElement(_appWorkforms.ShortText, { data: item, contentStyle: contentStyle, withNext: withNext, onFinished: onFinish }),
		type == 'SortQuestion' && _react2.default.createElement(_appWorkforms.SortQuestion, { data: item, options: options, onFinished: onFinish, saving: saving }),
		type === 'Roadmap' && _react2.default.createElement(_appWorkforms.Roadmap, { data: item, options: options, onFinished: onFinish }),
		type === 'ChoiceQuestion' && _react2.default.createElement(_appWorkforms.ChoiceQuestion, { data: item, options: options, onFinished: onFinish, saving: saving }),
		type == 'StatementQuestion' && _react2.default.createElement(_appWorkforms.StatementQuestion, { data: item, options: options, onFinished: onFinish, direction: direction, saving: saving }),
		type == 'TextCheckQuestion' && _react2.default.createElement(_appWorkforms.TextCheckQuestion, { data: item, options: options, onFinished: onFinish, saving: saving, onSetWorkformDone: onSetWorkformDone, direction: direction }),
		type == 'TextMPCWithImage' && _react2.default.createElement(_appWorkforms.TextMPCWithImage, { data: item, options: options, onFinished: onFinish, direction: direction, saving: saving, onSetWorkformDone: onSetWorkformDone }),
		type == 'TextMPC' && _react2.default.createElement(_appWorkforms.TextMPC, { data: item, options: options, onFinished: onFinish, direction: direction, saving: saving, onSetWorkformDone: onSetWorkformDone }),
		type == 'ProgressionFeedback' && _react2.default.createElement(_appWorkforms.ProgressionFeedback, { data: item, options: options, onFinished: onFinish, direction: direction }),
		type == 'AskFeedback' && _react2.default.createElement(_appWorkforms.AskFeedback, { data: item, options: options, onFinished: onFinish, direction: direction }),
		type == 'TitleAndText' && _react2.default.createElement(_appWorkforms.TitleAndText, { data: item }),
		type == 'TitleAndTextImage' && _react2.default.createElement(_appWorkforms.TitleAndTextImage, { data: item, withNext: withNext, onFinished: onFinish }),
		type == 'VideoWithTitle' && _react2.default.createElement(_appWorkforms.VideoWithTitle, { data: item }),
		type == 'Criteria' && _react2.default.createElement(_appWorkforms.Criteria, { data: item }),
		type == 'WhatIsWhatQuestion' && _react2.default.createElement(_appWorkforms.WhatIsWhatQuestion, { data: item, options: options, onFinished: onFinish, saving: saving }),
		type == 'Scene' && _react2.default.createElement(_appWorkforms.Scene, { workformId: item.id, onNext: onFinish }),
		type == 'ProcessStep' && _react2.default.createElement(_appWorkforms.ProcessStep, { data: item, onFinished: onFinish, withNext: withNext }),
		type == 'ExternalLink' && _react2.default.createElement(_appWorkforms.ExternalLink, { data: item, options: options, onFinished: onFinish, customLink: customLink, withNext: withNext })
	);
};

exports.default = WorkformViewer;