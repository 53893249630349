import { makeStyles, createStyles } from "@material-ui/core";

export const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				backgroundColor: theme.manakin.primaryColor[500],
				minHeight: "100vh",
				display: "flex",
				justifyContent: "center",
				width: "100%",
			},
			dialogInner: {
				[theme.breakpoints.down("md")]: {
					padding: "20px",
				},
			},
			inner: {
				maxWidth: "550px",
				width: "100%",
				padding: "40px 20px",
				display: "flex",
				alignItems: "center",
				flexDirection: "column",
				[theme.breakpoints.up("md")]: {
					padding: "12rem 2rem",
					maxWidth: "900px",
				},
			},
			header: {
				marginBottom: "4rem",
			},
			content: {
				textAlign: "center",
			},
			title: {
				maxWidth: "48rem",
				margin: "0 auto 1rem",
				[theme.breakpoints.up("md")]: {
					margin: "0 auto 4rem",
				},
			},
			dialogTitle: {
				margin: 0,
			},
			input: {
				width: "100%",
				marginBottom: "2rem",
			},
			agendaWrapper: {
				display: "flex",
				flexDirection: "column",
				[theme.breakpoints.up("md")]: {
					width: "950px",
					flexDirection: "row",
					justifyContent: "center",
				},
			},
			flexObject: {
				width: "100%",
				margin: "20px 0",
				[theme.breakpoints.up("md")]: {
					width: "50%",
					margin: "0 22px",
				},
			},
			appointments: {
				width: " 100%",
				marginTop: "20px",
				[theme.breakpoints.up("md")]: {
					marginTop: "80px",
				},
			},
			appointmentItemHeader: {
				lineHeight: "20px"
			},
			appointmentItemContent: {
				lineHeight: "20px",
				fontSize: "17px"
			}
		}),
	{
		name: "AppAgenda",
	}
);
