import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { isAppMenuOpen } from './selectors';
import { connect } from 'react-redux';
import classNames from 'classnames';

const styles = (theme) => ({
    root: {
        paddingLeft: 0,
        transition: theme.transitions.create(['padding-left'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    shifted: {
        paddingLeft: theme.manakin.sizes.appMenu,
        transition: theme.transitions.create(['padding-left'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
});

const mapStateToProps = (state) => ({
    shifted: isAppMenuOpen(state),
});

const Shifter = (props) => {
    const { shifted, render, className: classNameProp, classes } = props;
    const className = classNames(
        classes.root,
        {
            [classes.shifted]: shifted,
        },
        classNameProp
    );
    return <div className={className}>{render({ shifted })}</div>;
};

export default withStyles(styles)(connect(mapStateToProps, null)(Shifter));
