import React, { useEffect } from "react";
import { useStyles } from "./styles";
import Header from "./parts/Header";
import Agenda from "./parts/Agenda";
import { ElementOverlay } from "@manakin/app-core";
import { useHistory } from "react-router-dom";
import { PROGRESS_DASHBOARD_ROOT_PATH } from "../Router/Router";
import { useTranslation } from 'react-i18next';

const Planner = () => {
	const classes = useStyles();
	const history = useHistory();
	const { t } = useTranslation();

	const handleClick = () => {
		history.push(PROGRESS_DASHBOARD_ROOT_PATH);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<ElementOverlay
			fullWidth={true}
			title={t("app.planner.title").toLocaleUpperCase()}
			onClick={handleClick}
			controls={false}
			customControls={true}
		>
			<div className={classes.root}>
				<div className={classes.inner}>
					<Header />
					<Agenda />
				</div>
			</div>
		</ElementOverlay>
	);
};

export default Planner;
