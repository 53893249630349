import gql from "graphql-tag";

export const GQL_FETCH_APPOINTMENTS = gql`
	query appointments($includePastAppointments: Boolean) {
        appointments(page: 0, pagesize: 999, direction: "ASC", sortProperties: ["time"], filter: { includePastAppointments: $includePastAppointments }) {
			count
			appointments {
				id
				student {
					id
					fullName
				}
				subject {
					id
					description
					title
				}
				time
			}
		}
	}
`;

export const GQL_FETCH_APPOINTMENT_SUBJECTS = gql`
	query appointmentSubjects {
		appointmentSubjects {
			description
			id
			index
			title
		}
	}
`;

export const GQL_CREATE_APPOINTMENT = gql`
	mutation createAppointment($input: CreateAppointmentInput!) {
		createAppointment(input: $input) {
			appointment {
				student {
					id
				}
				subject {
					id
				}
				time
			}
		}
	}
`;

export const GQL_UPDATE_APPOINTMENT = gql`
	mutation updateAppointment($input: UpdateAppointmentInput) {
		updateAppointment(input: $input) {
			appointment {
				student {
					id
				}
				subject {
					id
				}
				time
			}
		}
	}
`;

export const GQL_DELETE_APPOINTMENT = gql`
	mutation deleteAppointment($id: Long!) {
		deleteAppointment(id: $id)
	}
`;

export const GQL_FETCH_APP_USERS = gql`
	query appUsers {
		appUsers {
			users {
				id
				fullName
			}
		}
	}
`;
