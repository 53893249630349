import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
    (theme) => {
        const backgroundColor = theme.manakin.primaryColor[500];
        const textColor = theme.palette.getContrastText(theme.manakin.primaryColor[500]);

        return createStyles({
            formControlRoot: {
                width: '100%',
                minHeight: '10rem',
                padding: '1rem 2rem',
                border: `1px solid ${theme.palette.getContrastText(backgroundColor)}`,
                margin: '0',
                '& .radio-hidden': {
                    display: 'none',
                },
                [theme.breakpoints.up('md')]: {
                    padding: '1rem 3.4rem',
                },
            },
            formControlLabel: {
                marginBottom: 0,
                '&.Mui-disabled': {
                    color: textColor,
                },
            },
            formControl: {
                marginBottom: '2rem',
            },
            answerInner: {
                display: 'grid',
                gridTemplateColumns: '70px auto',
                alignItems: 'center',
            },
            answerTitle: {
                color: textColor,
                fontFamily: theme.manakin.defaultAnswerFont,
                fontSize: '1.6rem',
                lineHeight: '3.2rem',
                margin: '0 0 0 1rem',
                maxWidth: 300,
                [theme.breakpoints.up('md')]: {
                    fontSize: '1.8rem',
                    lineHeight: '3.8rem',
                },
            },
            percentageBarRoot: {
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                padding: 0,
                marginBottom: '15px',
            },
            percentageBarOuter: {
                width: '100%',
                height: '.8rem',
                backgroundColor: backgroundColor,
                position: 'relative',
                padding: '15px 0',
            },
            percentageBarInner: {
                backgroundColor: theme.palette.getContrastText(backgroundColor),
                position: 'absolute',
                height: '.8rem',
                left: 0,
                top: 0,
                transition: 'width .3s',
                padding: '15px 0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                '&.lowPercentage': {
                    justifyContent: 'flex-start',

                    '& $percentageBarContent': {
                        marginLeft: '10px',
                    },
                },
            },
            percentageBarContent: {
                margin: 0,
                minWidth: '3rem',
                marginRight: '10px',
                color: textColor,
                fontWeight: 'bold',
            },
            primary: {
				'& $percentageBarContent': {
					color: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),
				},
				'& $answerTitle': {
					color: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),
				},
                '& $percentageBarOuter': {
					backgroundColor: theme.manakin.primaryWorkformColor[500],
				},
				'& $percentageBarInner': {
					backgroundColor: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),
				},
                '&$formControlRoot': {
                    border: `1px solid ${theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500])}`,
                }
			},
			secondary: {
				'& $percentageBarContent': {
					color: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
				},
				'& $answerTitle': {
					color: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
				},
                '& $percentageBarOuter': {
					backgroundColor: theme.manakin.secondaryWorkformColor[500],
				},
				'& $percentageBarInner': {
					backgroundColor: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
				},
                '&$formControlRoot': {
                    border: `1px solid ${theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500])}`,
                }
			},
			noBG: {
				'& $percentageBarContent': {
					color: theme.manakin.defaultContentColor[500],
				},
				'& $answerTitle': {
					color: theme.manakin.defaultContentColor[500],
				},
				'& $percentageBarOuter': {
					backgroundColor: theme.manakin.defaultContentColor[500],
				},
				'& $percentageBarInner': {
					backgroundColor: theme.manakin.defaultContentColor[500],
				},
                '&$formControlRoot': {
                    border: `1px solid ${theme.manakin.defaultContentColor[500]}`,
                }
			}
        });
    },
    { name: 'AppPoll' },
);
