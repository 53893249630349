import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(
    (theme) =>
        createStyles({
            root: {
                paddingTop: '6rem',
                background: theme.manakin.primaryColor[500],
                width: '100vw',
                height: '100vh',
                minHeight: '100%',
            },
            heading: {
                textAlign: 'center',
                width: '100%',
                marginBottom: '1em',
            },
            smallWrapper: {
                maxWidth: theme.manakin.wrapper['extraSmall'],
                width: '90%',
                margin: '0 auto',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            },
        }),
    {
        name: 'AuthenticationConfirmReset',
    }
);
