import React, { useState, useEffect } from 'react';
import { useStyles } from './styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { KeyboardArrowDown } from '@material-ui/icons';
import MenuItemContent from './MenuItemContent';
import InputLabel from '@material-ui/core/InputLabel';

const SelectField = (props) => {
	const { options = [], onChange, emptyLabel, initialValue } = props;
	const classes = useStyles();

	//state hooks
	const [value, setValue] = useState('');
	const [open, setOpen] = useState(false);

	//effect hooks
	useEffect(() => {
		if (initialValue) {
			setValue(initialValue.toString());
		}
	}, [initialValue]);

	//functions
	const handleChange = (event) => {
		if (props.onChangeWithConfirmation) {
			return handleChangeWithConfirmation(event);
		}

		const val = event.target.value;
		setValue(val);
		if (onChange) onChange(val);
	};

	/** Only set the value if the parent acknowledges it. This basically makes this component semi-controlled */
	const handleChangeWithConfirmation = (event) => {
		const value = event.target.value;

		props.onChangeWithConfirmation(value, () => {
			setValue(value);
		});
	}

	const handleOpenState = (state) => {
		setOpen(state);
	};

	return (
		<FormControl className={classes.root}>
			{emptyLabel && (
				<InputLabel id="demo-simple-select-label" classes={{ formControl: classes.formControlLabel }}>
					{emptyLabel}
				</InputLabel>
			)}
			<Select
				value={value}
				onChange={handleChange}
				onOpen={() => handleOpenState(true)}
				onClose={() => handleOpenState(false)}
				open={open}
				MenuProps={{
					disableScrollLock: true,
				}}
				IconComponent={KeyboardArrowDown}
				labelId="demo-simple-select-label"
				className={classes.select}
				inputProps={{
					classes: {
						icon: classes.icon,
					},
				}}
			>
				{options.map((option, idx) => (
					<MenuItem
						key={`${idx}-${option.id}`}
						value={option.id}
						classes={{
							root: classes.menuItem,
							selected: classes.selected,
						}}
					>
						<MenuItemContent label={option.label} main={option.type === 'School'} />
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default SelectField;
