import React, { useState, useEffect } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.secondary[200],
        display: 'flex',
        paddingLeft: theme.spacing(7),
        paddingRight: theme.spacing(4),
        borderBottom: '1px solid ' + theme.palette.divider,
        justifyContent: 'space-between',
        height: '80px',
        position: "sticky",
        zIndex: "1",
        top: 64
    },
    title: {},
    actions: {
        flex: '0 0  auto',
        display: 'flex',
        alignItems: 'center',
    },
    action: {
        flex: '0 0 auto',
    },
    primaryAction: {
        flex: '0 0 auto',
        paddingLeft: theme.spacing(2),
    },
    tabsRoot: {
        marginTop: 'auto',
        borderBottom: '1px solid #e8e8e8',
    },
    tabsIndicator: {
        backgroundColor: '#242445',
    },
    tabRoot: {
        color: 'black',
        textTransform: 'initial',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover': {
            color: '#242445',
            opacity: 1,
        },
        '&:focus': {
            color: '#242445',
        },
    },
    tabTootError: {
        color: 'red',
    },
});

const ModuleToolbar = (props) => {
    const {
        classes,
        title,
        children,
        actions,
        primaryAction,
        tabAction = false,
        advancedTabAction = false,
        errors,
        config = {},
        hideTransBar = false,
    } = props;

    const { languages = [] } = config;

    //state hooks
    const [tabValue, setTabValue] = useState(props.initial || '');
    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(false);
    const [newValue, setNewValue] = useState(null);
    const { t, i18n } = useTranslation();

    //effect hooks
    useEffect(() => {
        const lang = Cookies.get('locale');

        if (lang) {
            languages.forEach((l, idx) => {
                if (l.short === lang) setValue(idx);
            });
        }
    }, []);

    //functions
    const handleTabChange = (event, newValue) => {
        if (props.onTabChange) {
            props.onTabChange(event, newValue);
        }
        setTabValue(newValue);
    };

    const handleChange = (event, nv) => {
        setNewValue(nv);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        let newLocale = languages[newValue] ? languages[newValue].short || '' : '';
        Cookies.set('locale', newLocale);

        if (props.onLangSwitch) props.onLangSwitch(newLocale);
        setValue(newValue);
        setOpen(false);
    };

    return (
        <React.Fragment>
            {languages.length > 0 && !hideTransBar && (
                <Toolbar>
                    <div className={classes.languages}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="Translation tab"
                            indicatorColor="primary"
                            variant="scrollable"
                        >
                            {languages.map((lang) => (
                                <Tab
                                    key={lang.short}
                                    label={lang.name}
                                    id={lang.short}
                                />
                            ))}
                        </Tabs>
                    </div>
                </Toolbar>
            )}
            <Toolbar classes={{ root: classes.root }}>
                {(tabAction || advancedTabAction) && (
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        scrollButtons="auto"
                        variant="scrollable"
                        classes={{
                            root: classes.tabsRoot,
                            indicator: classes.tabsIndicator,
                        }}
                    >
                        {tabAction && tabAction.map((tab, idx) => (
                            <Tab
                                key={idx}
                                value={tab}
                                classes={{ root: classes.tabRoot }}
                                label={tab}
                                className={classNames(classes.tabRoot, {
                                    [classes.tabTootError]:
                                        errors.find((el) => el == tab) || false,
                                })}
                            />
                        ))}

                        {/* Advanced tabs allows the dev to specify different values and labels for tabs */}
                        {advancedTabAction && advancedTabAction.map((tab, index) => (
                            <Tab
                                key={index}
                                value={tab.value}
                                classes={{ root: classes.tabRoot }}
                                label={tab.name}
                                className={classNames(classes.tabRoot, {
                                    [classes.tabTootError]:
                                    errors.find((el) => el === tab) || false,
                                })}
                            />
                        ))}
                    </Tabs>
                )}
                {title && (
                    <Typography
                        className={classes.title}
                        variant="h6"
                        color="inherit"
                    >
                        {title}
                    </Typography>
                )}
                <div>{children}</div>
                <div className={classes.actions}>
                    {actions &&
                        actions.map((action, i) => (
                            <div key={i} className={classes.action}>
                                {action}
                            </div>
                        ))}
                    {primaryAction && (
                        <div className={classes.primaryAction}>
                            {primaryAction}
                        </div>
                    )}
                </div>
            </Toolbar>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {t("core.change-language-dialog.title")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {t("core.change-language-dialog.description")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {t("core.change-language-dialog.action-cancel")}
                    </Button>
                    <Button onClick={handleConfirm} color="primary">
                        {t("core.change-language-dialog.action-proceed")}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default compose(
    connect(({ config }) => ({ config })),
    withStyles(styles, { name: 'ManakinCoreModuleToolbar' })
)(ModuleToolbar);
