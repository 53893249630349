import React from 'react';
import { ElementOverlay, Stepper, withStepper, Loader } from '@manakin/app-core';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
	loader: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
	},
	tag: {
		position: 'absolute',
		top: '0px',
		left: '16px',
		[theme.breakpoints.up('md')]: {
			top: '36px',
			width: '100%',
			maxWidth: '1300px',
			padding: '0 45px',
			left: '50%',
			transform: 'translateX(-50%)',
		},
		[theme.breakpoints.up('lg')]: {
			maxWidth: '1414px',
			padding: '0 100px',
		},
	},
});

const timer = {
	timer: null,
};

class Questions extends React.PureComponent {
	state = {
		loading: false,
	};

	componentDidMount(prevProps) {
		if (!this.props.loading) {
			const { stepper, workforms } = this.props;
			stepper.setItems(workforms || []);
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.workforms != this.props.workforms) {
			if (!this.props.loading) {
				const { stepper, workforms } = this.props;
				stepper.setItems(workforms || []);
			}
		}
	}

	handleExit = () => {
		const { stepper } = this.props;

		let allCorrect = true;
		let items = [...stepper.items];

		const _items = items.sort(function (a, b) {
			const aVal = a.correct ? 1 : 0;
			const bVal = b.correct ? 1 : 0;
			if (aVal == 0 || bVal == 0) allCorrect = false;

			return aVal - bVal;
		});

		if (allCorrect) {
			this.setState({ loading: true });
			stepper.stopCounting();
			this.props.onExit(stepper.count);
		} else {
			stepper.resetItems(_items);
		}
	};

	render() {
		const { stepper, classes, loading, controls = true, prefix = null, data = {}, t } = this.props;
		const { loading: stateLoading } = this.state;

		if (loading || stateLoading) {
			return (
				<div className={classes.loader}>
					{' '}
					<Loader />{' '}
				</div>
			);
		} else {
			return (
				<ElementOverlay
					variant="trainer"
					title={data.preTitle || prefix || t('common.element-types.trainer')}
					items={stepper.items}
					timer={stepper.count}
					controls={controls}
					background="white"
				>
					<Stepper
						stepper={stepper}
						variant="trainer"
						onExit={this.handleExit}
						tagClass={classes.tag}
						options={{ trainer: true }}
					/>
				</ElementOverlay>
			);
		}
	}
}

export default compose(withStyles(styles), withStepper(timer), withTranslation())(Questions);
