import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Media from 'react-media';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';

const styles = (theme) => ({
    container: {
        width: '100%',
        backgroundColor: theme.manakin.secondaryThemeColor[500],
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'right bottom',
    },
    statement: {
        paddingLeft: '99px',
        paddingTop: '88px',
        fontSize: '18px',
        lineHeight: '36px',
        width: '359px',
    },
    statementMobile: {
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop: '16px',
        fontSize: '18px',
        lineHeight: '36px',
        width: '100%',
    },
    question: {
        width: '75%',
        paddingTop: '32px',
        paddingLeft: '99px',
        fontSize: '68px',
        lineHeight: '80px',
        fontFamily: 'Zilla Slab',
    },
    questionMobile: {
        paddingLeft: '16px',
        paddingRight: '16px',
        fontSize: '45pt',
        fontFamily: 'Zilla Slab',
        width: '100%',
    },
    buttonContainer: {
        paddingLeft: '99px',
        paddingTop: '48px',
    },
    buttonContainerMobile: {
        paddingLeft: '0',
        paddingTop: '32px',
        textAlign: 'center',
        width: '100%',
    },
    button: {
        marginRight: '50px',
        marginBottom: '99px',
        width: '300px',
        height: '100px',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '4px',
        lineHeight: '20px',
        textAlign: 'center',
        backgroundColor: 'transparent',
        border: '2px solid #000000',
        textTransform: 'uppercase',
    },
    buttonMobile: {
        margin: '8px !important',
        width: '80%',
        maxWidth: '300px',
        height: '100px',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '4px',
        lineHeight: '20px',
        textAlign: 'center',
        backgroundColor: 'transparent',
        border: '2px solid #000000',
        textTransform: 'uppercase',
    },
});

class StatementQuestion extends React.Component {
    handleSubmit = (userAnswer) => {
        if (userAnswer === this.props.answer) {
            return true; // right input by user
        } else {
            return false; // wrong input by user
        }
    };

    render() {
        const { classes, statement, question, image, t } = this.props;

        let triggered;

        return (
            <div
                className={classes.container}
                style={{ backgroundImage: `url(${image})` }}
            >
                <Media query="(max-width: 860px)">
                    {(matches) =>
                        matches ? (
                            <div>
                                <Typography className={classes.statementMobile}>
                                    {statement}
                                </Typography>
                            </div>
                        ) : (
                            <div>
                                <Typography className={classes.statement}>
                                    {statement}
                                </Typography>
                            </div>
                        )
                    }
                </Media>

                <Media query="(max-width: 860px)">
                    {(matches) =>
                        matches ? (
                            <Typography className={classes.questionMobile}>
                                &#8220;{question}&#8221;
                            </Typography>
                        ) : (
                            <Typography className={classes.question}>
                                &#8220;{question}&#8221;
                            </Typography>
                        )
                    }
                </Media>

                <Media query="(max-width: 860px)">
                    {(matches) =>
                        matches ? (
                            <div className={classes.buttonContainerMobile}>
                                <button
                                    className={classes.buttonMobile}
                                    onClick={() => this.handleSubmit(true)}
                                >
                                    {t("app.workforms.statement-question.agree")}
                                </button>
                                <button
                                    className={classes.buttonMobile}
                                    onClick={() => this.handleSubmit(false)}
                                >
                                    {t("app.workforms.statement-question.disagree")}
                                </button>
                            </div>
                        ) : (
                            <div className={classes.buttonContainer}>
                                <button
                                    className={classes.button}
                                    onClick={() => this.handleSubmit(true)}
                                >
                                    {t("app.workforms.statement-question.agree")}
                                </button>
                                <button
                                    className={classes.button}
                                    onClick={() => this.handleSubmit(false)}
                                >
                                    {t("app.workforms.statement-question.disagree")}
                                </button>
                            </div>
                        )
                    }
                </Media>
            </div>
        );
    }
}

export default compose(
    withStyles(styles, { withTheme: true }),
    withTranslation()
)(StatementQuestion);
