import React, { useEffect, useState } from 'react';
import { withWorkforms, Loader } from '@manakin/app-core';
import { compose } from 'recompose';
import { useQuery } from '@apollo/react-hooks';
import { connect } from 'react-redux';
import { changeColor } from '@manakin/app-core/ColorService/actions';
const queryString = require('query-string');
import Recap from './Recap';

const PreviewRecap = (props) => {
    const { match, workformsData = {} } = props;
    const { loading = true } = workformsData;
    const query = match.params.query;
    const elementData = queryString.parse(query);

    useEffect(() => {
        const items = Array.isArray(elementData.items)
            ? elementData.items
            : elementData.items
            ? [elementData.items]
            : [];

        window.scrollTo(0, 0);
        const obj = {
            ...elementData,
            headerImage: {
                url: elementData.headerImage,
            },
            image: {
                url: elementData.image,
            },
            workforms: [
                ...items.map((item, idx) => ({
                    id: item,
                    type: elementData.itemTypes[idx],
                })),
            ],
            options: {
                showFeedback: true,
                nextButton: true,
            },
        };

        workformsData.loadWorkforms({ ...obj });
    }, []);

    return (
        <Recap
            loading={loading}
            summary={workformsData.rawData}
            workformsData={workformsData}
            controls={false}
        />
    );
};

export default compose(withWorkforms())(PreviewRecap);
