import React, { useState } from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Button, Dialog, DialogActions, DialogTitle, Slide } from "@material-ui/core";
import FocusPointDialogContent from "./FocusPointDialogContent";
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

const useStyles = makeStyles(
    (theme) =>
        createStyles({
            root: {
                minWidth: "auto",
                maxWidth: "auto",
            },
        }),
    {
        name: "FocusPointDialog",
    },
);

const defaultCoordinates = {
    x: undefined,
    y: undefined,
};

const FocusPointDialog = (props) => {
    const { form, name, image, open, onClose, initialValue, onHandleFit } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    const [ focusPointCoordinates, setFocusPointCoordinates ] = useState(defaultCoordinates);

    const handleClose = () => {
        setFocusPointCoordinates(defaultCoordinates);
        onClose();
    };

    const handleUseFocusPoint = () => {
        const { form, name } = props;

        if (form.fields && form.fields[name]) {
            form.onFieldChange({
                key: name,
                value: {
                    bynderId: form.fields[name].bynderId,
                    derivative: form.fields[name].derivative,
                    url: form.fields[name].url,
                    fit: form.fields[name].fit,
                    focusPointX: focusPointCoordinates.x,
                    focusPointY: focusPointCoordinates.y,
                },
            });
        }

        handleClose();
    };

    return (
		<Dialog name="selectFocusPointDialog" open={open} className={classes.root} TransitionComponent={Transition} onClose={handleClose}>
			<DialogTitle>{t('core.focus-point-dialog.title')}</DialogTitle>

			<FocusPointDialogContent
				form={form}
				name={name}
				image={image}
				initialValue={initialValue}
				onHandleFit={onHandleFit}
				focusPointCoordinates={focusPointCoordinates}
				setFocusPointCoordinates={setFocusPointCoordinates}
			/>

			<DialogActions>
				<Button onClick={handleClose}>{t('common.cancel')}</Button>
				<Button onClick={handleUseFocusPoint} color="primary">
					{t('common.use-plural')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default FocusPointDialog;