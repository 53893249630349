import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	toolBar: {
		display: 'flex',
		alignItems: 'center',
	},
	title: {
		color: theme.palette.primary.contrastText,
		margin: 0,
	},
	content: {
		padding: '50px 30px',
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenDialog = (props) => {
	const { open = false, onClose, title, children } = props;
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
			<AppBar>
				<Toolbar>
					<IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
						<CloseIcon />
					</IconButton>
					<Typography variant="h6" className={classes.title}>
						{title || ''}
					</Typography>
				</Toolbar>
			</AppBar>
			<div className={classes.content}>{children}</div>
		</Dialog>
	);
};

export default FullScreenDialog;
