import React from "react";
import renderHTML from "react-render-html";
import Typography from "@material-ui/core/Typography";
import { useReadSpeaker } from "@manakin/hooks";
import { createStyles, makeStyles } from "@material-ui/styles";
import { GQL_FETCH_CMS_HOME_PAGE_BLOCK_SHORT_TEXT } from "../graphql";
import { useQuery } from "@apollo/react-hooks";

const useStyles = makeStyles(
    (theme) =>
        createStyles({
            wrapper: {
                ...theme.manakin.smallWrapper,
                maxWidth: "800px",
            },
            content: {
                margin: 0,
                color: theme.manakin.defaultContentColor[500],
                "@global ul": {
                    paddingLeft: "2.25rem",
                },
            },
        }),
    {
        name: "AppHomepageBlockShortText",
    },
);

const ShortTextBlock = (props) => {
    const classes = useStyles();
    const { readSpeaker, readSpeakerId } = useReadSpeaker("readSpeaker-shortText-" + props.id);

    const { data = {} } = useQuery(GQL_FETCH_CMS_HOME_PAGE_BLOCK_SHORT_TEXT, {
        variables: {
            id: props.id,
        },
    });
    const { shortTextHomepageBlock = {} } = data;
    const { text } = shortTextHomepageBlock;

    return (
        <div className={classes.root}>
            <div className={classes.wrapper} id={readSpeakerId}>
                {readSpeaker && renderHTML(readSpeaker)}
                <Typography
                    component='div'
                    variant='body1'
                    classes={{ body1: classes.content }}
                >
                    {renderHTML(text || "")}
                </Typography>
            </div>
        </div>
    );
};

export default ShortTextBlock;
