import React from 'react';
import Header from './parts/Header.js';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import NewsCarousel from '../NewsCarousel';
import { isSettingEnabled } from '@manakin/core/actions';
import { SettingsKeys } from '@manakin/core/lib/constants';

const useStyles = makeStyles(
    (theme) =>
        createStyles({
            root: {
                width: '100%'
            },
            dialogPaper: {
                flexFlow: 'row wrap',
                padding: '32px',
            },
            confirmDeleteButton: {
                padding: '2rem',
                marginRight: '20px',
            },
        }),
    {
        name: 'AppLoginView',
    }
);

const Login = (props) => {
    const classes = useStyles();
    const showNews = isSettingEnabled(SettingsKeys.SHOW_HOMEPAGE_NEWS);

    return (
        <div className={classes.root}>
            <Header search={props.location.search} />
            {showNews && <NewsCarousel category={['HOMEPAGE']} />}
        </div>
    );
};

export default compose(
    withRouter
)(Login);
