'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _core = require('@material-ui/core');

var _jsCookie = require('js-cookie');

var _jsCookie2 = _interopRequireDefault(_jsCookie);

var _Picker = require('./Picker');

var _Picker2 = _interopRequireDefault(_Picker);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _reactI18next = require('react-i18next');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var useStyles = (0, _core.makeStyles)(function (theme) {
    return {
        root: {
            position: 'fixed',
            zIndex: 999999999,
            width: '400px',
            padding: '20px',
            top: '50%',
            left: '-390px',
            transform: 'translateY(-50%)',
            display: 'flex',
            flexDirection: 'column',
            transition: 'left 0.5s',
            backgroundColor: 'white',
            border: '1px solid ' + theme.manakin.defaultBorderColor[500]
        },
        active: {
            left: 0
        },
        handler: {
            position: 'absolute',
            width: '10px',
            height: '100%',
            right: 0,
            top: 0,
            backgroundColor: 'grey',
            cursor: 'pointer'
        },
        title: {
            margin: '10px 0'
        },
        button: {
            margin: '20px 10px 0 0'
        }
    };
});

var ColorPicker = function ColorPicker(props) {
    var classes = useStyles();

    var _useState = (0, _react.useState)(false),
        _useState2 = _slicedToArray(_useState, 2),
        active = _useState2[0],
        setActive = _useState2[1];

    var _useState3 = (0, _react.useState)({
        primary: _jsCookie2.default.get('primary') || '#3B6FF9',
        secondary: _jsCookie2.default.get('secondary') || '#FC7A4C',
        tertiary: _jsCookie2.default.get('tertiary') || '#F28F7F',
        rest: _jsCookie2.default.get('palette') || '#20293F'
    }),
        _useState4 = _slicedToArray(_useState3, 2),
        colors = _useState4[0],
        setColors = _useState4[1];

    var _useTranslation = (0, _reactI18next.useTranslation)(),
        t = _useTranslation.t;

    var handleClick = function handleClick() {
        setActive(!active);
    };

    var handleChange = function handleChange(data) {
        setColors(_extends({}, colors, _defineProperty({}, data.name, data.value)));
    };

    var handleSubmit = function handleSubmit() {
        setActive(false);
        _jsCookie2.default.set('primary', colors.primary, { expires: 7 });
        _jsCookie2.default.set('secondary', colors.secondary, { expires: 7 });
        _jsCookie2.default.set('tertiary', colors.tertiary, { expires: 7 });
        _jsCookie2.default.set('palette', colors.rest, { expires: 7 });
        window.location.reload();
    };

    var handleReset = function handleReset() {
        _jsCookie2.default.remove('primary');
        _jsCookie2.default.remove('secondary');
        _jsCookie2.default.remove('tertiary');
        _jsCookie2.default.remove('palette');
        window.location.reload();
    };

    return _react2.default.createElement(
        'div',
        { className: (0, _classnames2.default)(classes.root, _defineProperty({}, classes.active, active)) },
        _react2.default.createElement(
            _Typography2.default,
            { variant: 'h4', className: classes.title },
            t("app-core.color-picker.title")
        ),
        _react2.default.createElement(
            'div',
            { className: 'slide' },
            _react2.default.createElement(
                _Typography2.default,
                { variant: 'h6', className: classes.title },
                t("app-core.color-picker.variants.primary")
            ),
            _react2.default.createElement(_Picker2.default, {
                name: 'primary',
                onChange: handleChange,
                initial: colors.primary
            }),
            _react2.default.createElement(
                _Typography2.default,
                { variant: 'h6', className: classes.title },
                t("app-core.color-picker.variants.secondary")
            ),
            _react2.default.createElement(_Picker2.default, {
                name: 'secondary',
                onChange: handleChange,
                initial: colors.secondary
            }),
            _react2.default.createElement(
                _Typography2.default,
                { variant: 'h6', className: classes.title },
                t("app-core.color-picker.variants.tertiary")
            ),
            _react2.default.createElement(_Picker2.default, {
                name: 'tertiary',
                onChange: handleChange,
                initial: colors.tertiary
            }),
            _react2.default.createElement(
                _Typography2.default,
                { variant: 'h6', className: classes.title },
                t("app-core.color-picker.variants.rest")
            ),
            _react2.default.createElement(_Picker2.default, {
                name: 'rest',
                onChange: handleChange,
                initial: colors.rest
            }),
            _react2.default.createElement(
                _core.Button,
                {
                    variant: 'contained',
                    color: 'primary',
                    onClick: handleSubmit,
                    size: 'small',
                    className: classes.button
                },
                t("common.edit")
            ),
            _react2.default.createElement(
                _core.Button,
                {
                    variant: 'contained',
                    color: 'primary',
                    onClick: handleReset,
                    size: 'small',
                    className: classes.button
                },
                t("common.reset")
            )
        ),
        _react2.default.createElement('div', { className: classes.handler, onClick: handleClick })
    );
};

exports.default = ColorPicker;