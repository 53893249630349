import React, { createContext } from 'react';

const StepsContext = createContext({
	step: 0,
	setStep: () => {},
	disabled: 0,
    setDisabled: () => {}
});

export default StepsContext;
