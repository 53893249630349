import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowIcon from '@material-ui/icons/ArrowRightAlt';
import { LoadImage } from '@manakin/app-core';
import scrollToComponent from 'react-scroll-to-component';

const styles = (theme) => ({
    root: {
        backgroundColor: theme.manakin.primaryColor[500],
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundSize: 'cover',
        opacity: 0,
        animation: 'fadeIn .3s ease 1s forwards',
        position: 'relative',
    },
    wrapper: {
        ...theme.manakin.largeWrapper,
        margin: 0,
    },
    content: {
        textAlign: 'center',
        padding: '200px 0',
        opacity: 0,
        animation: 'show .3s ease 1.3s forwards',
    },
    fontStyle: {
        color: 'white',
    },
    heading: {
        color: 'white',
        letterSpacing: '3rem',
        marginBottom: '3.6rem',
        textTransform: 'uppercase',
    },
    body: {
        maxWidth: '860px',
        margin: '0 auto',
    },
    icon: {
        position: 'absolute',
        color: 'white',
        transformOrigin: 'center',
        transform: 'translate(-50%, 0) rotate(90deg)',
        bottom: '4.5rem',
        left: '50%',
        display: 'none',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        [theme.breakpoints.up('md')]: {
            transition: 'transform .3s',
            display: 'flex',
        },
        '&:hover': {
            [theme.breakpoints.up('md')]: {
                transform: 'translate(-50%, 10px) rotate(90deg)',
            },
        },
    },
    iconRoot: {
        width: '3rem',
        height: '3rem',
    },
});

class Header extends React.PureComponent {
    handleClick = () => {
        scrollToComponent(this.Header, {
            offset: 70,
            align: 'top',
            duration: 500,
        });
    };

    render() {
        const { classes, data = {} } = this.props;
        const dataImage = data ? data.image || {} : {};
        const dataHeaderImage = data ? data.headerImage || {} : {};

        const image = dataImage && dataImage.url;
        const headerImage = dataHeaderImage && dataHeaderImage.url;
        const imagePlaceholder = dataImage && dataImage.thumbnailUrl;
        const placeholder = dataHeaderImage && dataHeaderImage.thumbnailUrl;

        return (
            <div className={classes.root} color="light">
                <LoadImage
                    src={headerImage || image}
                    placeholder={placeholder || imagePlaceholder}
                />
                <div className={classes.wrapper}>
                    <div className={classes.content}>
                        <Typography
                            classes={{ h1: classes.heading }}
                            component="h1"
                            variant="h1"
                        >
                            {data.title || ''}
                        </Typography>
                        <div className={classes.body}>
                            <Typography
                                classes={{ subtitle1: classes.fontStyle }}
                                component="p"
                                variant="subtitle1"
                            >
                                {data.description}
                            </Typography>
                        </div>
                    </div>
                </div>
                <div
                    className={classes.icon}
                    onClick={this.handleClick}
                    ref={(section) => {
                        this.Header = section;
                    }}
                >
                    <ArrowIcon classes={{ root: classes.iconRoot }} />
                </div>
            </div>
        );
    }
}

export default withStyles(styles, { name: 'HeaderFaq' })(Header);
