import React from 'react';
import TextMPCWithImage from './TextMPCWithImage';
import shuffle from 'shuffle-array';
import { hasHint } from '../lib';

class TextMpcWithImageContainer extends React.Component {
    state = {
        hasAnswer: false,
        buttonDisabled: false,
    };

    componentDidMount() {
        this.setStartState();
    }

    componentDidUpdate(prevProps) {
        if (
            (!prevProps.data.done && this.props.data.done) ||
            prevProps.data.id != this.props.data.id
        ) {
            this.setStartState(true);
            this.setState({ buttonDisabled: false });
        }
    }

    setStartState = (second) => {
        const { options = {} } = this.props;
        const { answers, shuffleAnswers = true } = this.props.data;

        const _answers = answers.map((answer) => ({
            ...answer,
            selected: answer.selected == undefined ? false : answer.selected,
        }));

        if (shuffleAnswers && !second) {
            shuffle(_answers);
        }

        let value = null;
        _answers.forEach((answer) => {
            if (answer.selected) value = answer.id;
        });

        this.setState(
            {
                ...this.props.data,
                options: { ...options },
                initialValue: value,
                answers: _answers,
            },
            () => {
                const correct = this.checkAnswer(value);
                this.setState({
                    correct: correct,
                    feedback:
                        value && options && options.showFeedback ? true : false,
                    done: value ? true : false,
                });
            }
        );
    };

    checkAnswer = (value) => {
        let correct = false;
        const { answers = [] } = this.state;

        answers.forEach((answer) => {
            if (answer.correct && answer.id == value) correct = true;
        });

        return correct;
    };

    handleChange = (event) => {
        const correct = this.checkAnswer(event.target.value);
        const _answers = [...this.state.answers];
        const val = event.target.value;

        const answers = _answers.map((answer) => {
            let selected = false;
            if (answer.id == val) selected = true;
            return {
                ...answer,
                selected: selected,
            };
        });

        this.setState({
            value: event.target.value,
            correct: correct,
            answers: answers,
        });
    };

    handleSubmit = () => {
        const { options = {}, data } = this.props;
        if (options && options.showFeedback) {
            if(!hasHint(data)) {
				this.handleSetWorkformDone();
			}
            this.setState({ feedback: true, options: options });
        } else {
            this.setState({ buttonDisabled: true });
            this.handleFinish();
        }
    };

    handleFinish = () => {
        this.props.onFinished({
            correct: this.state.correct,
            workform: { ...this.state },
        });
    };

    handleSetWorkformDone = () => {
		const { onSetWorkformDone } = this.props;
		if(onSetWorkformDone) { 
			onSetWorkformDone({
				workform: { ...this.state }
			}); 
		}
	}

    render() {
        const { direction = 'default' } = this.props;
        const { answers = [] } = this.state;

        return (
            <TextMPCWithImage
                data={{ ...this.state }}
                onSubmit={this.handleSubmit}
                onFinished={this.handleFinish}
                onSetWorkformDone={this.handleSetWorkformDone}
                onChange={this.handleChange}
                direction={direction}
                disabled={
                    !answers.some((item) => item.selected) ||
                    this.state.buttonDisabled
                }
            />
        );
    }
}

export default TextMpcWithImageContainer;
