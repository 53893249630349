import gql from 'graphql-tag';

export const GQL_FETCH_POLL_RESULT = gql`
    query pollQuestionResult($poll: ID!) {
        pollResult(poll: $poll) {
            selectedAnswer {
                answer {
                    id
                }
            }
            totalVotes
            pollAnswerResults {
                answerId
                votes
                percentage
            }
        }
    }
`;

export const GQL_MUTATE_SUBMIT_POLL_ANSWER = gql`
    mutation submitPollQuestionAnswer($poll: ID!, $answer: ID!) {
        submitPollAnswer(poll: $poll, answer: $answer) {
            selectedAnswer {
                answer {
                    id
                }
            }
            totalVotes
            pollAnswerResults {
                answerId
                votes
                percentage
            }
        }
    }
`;
