'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.default = {
	programs: {
		read: 'PROGRAMS_READ',
		write: 'PROGRAMS_WRITE',
		delete: 'PROGRAMS_DELETE'
	},
	usersManagerExport: {
		read: 'USERS_MANAGER_EXPORT'
	}
};