import React, { useEffect, useState } from 'react';
import { SelectField } from '@manakin/core';
import Typography from '@material-ui/core/Typography';
import { GQL_FETCH_GROUP, GQL_FETCH_REGISTRATION_GROUPS } from '../graphql';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useLazyQuery } from '@apollo/react-hooks';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(
    (theme) =>
        createStyles({
            root: {
                width: '100%',
            },
            paragraph: {
                textAlign: 'center',
                width: '100%',
                margin: '0 auto 3em',
                maxWidth: '560px',
            },
            heading: {},
        }),
    {
        name: 'AppRegistrationSchoolData',
    }
);

const SchoolData = (props) => {
    const { form } = props;
    const { fields: fieldsData } = form;
    const classes = useStyles();
    const { t } = useTranslation();

    //state hooks
    const [school, setSchool] = useState(null);

    //queries
    const [getGroup, { data: groupData = {}, loading }] = useLazyQuery(
        GQL_FETCH_GROUP
    );

    //effect hooks
    useEffect(() => {
        form.onRequiredChange('school', true);

        if (fieldsData.school) {
            getGroup({
                variables: { id: fieldsData.school },
            });
        }
    }, []);

    useEffect(() => {
        if (groupData) {
            if (groupData.group && groupData.group.id && groupData.group.name) {
                setSchool({
                    id: groupData.group.id,
                    name: groupData.group.name,
                });
            }
        }
    }, [groupData]);

    const selectedSchool = school
        ? school
        : fieldsData.school
        ? { id: fieldsData.school }
        : [];

    return (
        <div className={classes.root}>
            <Typography variant="h2" className={classes.heading}>
                {t("authentication.registration.school-data.title")}
            </Typography>
            <Typography
                classes={{ body1: classes.paragraph }}
                variant="body1"
                gutterBottom
            >
                {t("authentication.registration.school-data.intro")}
            </Typography>
            <SelectField
                isAsync={true}
                alpha={true}
                loading={loading}
                customQuery={true}
                query={GQL_FETCH_REGISTRATION_GROUPS}
                queryName="registrationGroups"
                label={t("common.fields.school.label")}
                form={form}
                name="school"
                filter={{
                    types: ['School'],
                    visibleForRegistration: true,
                }}
                initialValue={selectedSchool}
            />
        </div>
    );
};

export default SchoolData;
