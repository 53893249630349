export const styles = (theme) => ({
	root: {
		width: "100%"
	},
	heading: {
		textTransform: 'uppercase',
	},
	body: {
		[theme.breakpoints.up('lg')]: {
			marginBottom: '3.8rem',
		},
	},
	factCard: {
		position: 'relative',
	},
	container: {
		...theme.manakin.largeWrapper,
		paddingTop: '7rem',
		paddingBottom: '7rem',
		backgroundColor: 'white',
		maxWidth: '120rem',
		[theme.breakpoints.up('md')]: {
			marginTop: '-22rem',
			padding: '10rem 6rem',
		},
	},
	refContainer: {
		width: '100%',
		background: 'transparent',
		height: '1px',
	},
	singleCard: {
		[theme.breakpoints.up('lg')]: {
			padding: '0 6rem!important',
		},
	},
	hide: {
		display: 'none',
	},
	cardHover: {
		textDecoration: 'none',
		cursor: 'pointer',
	},
	imageWrapper: {
		backgroundColor: theme.manakin.secondaryColor[50],
		position: 'relative',
		height: 'auto',
		height: '250px',
		overflow: 'hidden',
		marginBottom: '2.6rem',
	},
	image: {
		display: 'inline-block',
		width: '100%',
		height: '100%',
		transform: 'scale(1)',
		transition: 'all .3s',
		'&:hover': {
			transform: 'scale(1.2)',
		},
	},
	tags: {
		position: 'absolute',
		top: '10px',
		right: '10px',
		zIndex: 99,
	},
	readMoreLink: {
		fontSize: '16px',
		color: theme.manakin.secondaryColor[500],
		display: 'block',
	},
});
