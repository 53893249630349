import gql from "graphql-tag";

export const GQL_FETCH_CURRENT_APP_USER = gql`
	query currentAppUser {
		currentAppUser {
			id
			fullName
			roles {
				id
				name
				title
			}
			profilePicture {
				url
				id
			}
		}
	}
`;

export const GQL_FETCH_RATING_REQUESTS = gql`
	query ratingRequests($filter: RatingRequestFilter) {
		ratingRequests(pageNr: 0, pageSize: 999, filter: $filter) {
			id
			attempt
			finalRating
			box {
				id
			}
			result {
				id
			}
		}
	}
`;
