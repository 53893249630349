import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ProgramBlock from './ProgramBlock';
import { Loader } from '@manakin/app-core';
import BoxServicev2 from '@manakin/app-core/BoxService/BoxServicev2';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { ResumeButton } from '@manakin/app-core';
import { GQL_FETCH_BOX_RESULTS, GQL_FETCH_APP_USER } from '../graphql';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { getAppUser } from '@manakin/authentication/selectors';
import { getProgram } from '@manakin/app-core/ProgramsDropdown/selectors';
import { connect } from 'react-redux';
import classNames from 'classnames';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { styles } from './styles';
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => ({
    appUserData: getAppUser(state),
    program: getProgram(state),
});

const ProgramBlocks = (props) => {
    const { classes, APP_USER } = props;
    const { loading: appUserLoading } = APP_USER;
    const { t } = useTranslation();

    const [loading, setIsLoading] = useState(true);
    const [data, setData] = useState({});
    const [resume, setResume] = useState(null);
    const [yearsArray, setYearsArray] = useState(null);
    const [boxes, setBoxes] = useState([]);
    const [animate, setAnimate] = useState(false);
    const [functionObj, setFunctionObj] = useState(false);
    const [hasSet, setHasSet] = useState(false);
    const [arrSet, setHasArrSet] = useState(false);

    useEffect(() => {
        if (
            data &&
            !data.loading &&
            !APP_USER.loading &&
            functionObj &&
            yearsArray &&
            yearsArray.length &&
            !arrSet
        ) {
            setHasArrSet(true);
            const { program = {} } = data;
            let arr = [];

            program.boxes &&
                program.boxes.forEach((item) => {
                    item.forEach((box) => {
                        if (!box.isFinished) {
                            let isDrogist = false;
                            if (box.yearProgress) {
                                if (
                                    functionObj[box.yearProgress] === 'Drogist'
                                ) {
                                    isDrogist = true;
                                }
                            }

                            if (!isDrogist && box.yearProgress) {
                            } else if (isDrogist && box.yearProgress) {
                                if (
                                    yearsArray.some(
                                        (year) => year.year == box.yearProgress
                                    )
                                ) {
                                    if (
                                        box.childVersion &&
                                        box.childVersion.id
                                    ) {
                                    } else {
                                        arr.push(box);
                                    }
                                }
                            } else {
                                if (box.childVersion && box.childVersion.id) {
                                } else {
                                    arr.push(box);
                                }
                            }
                        }
                    });
                });

            setBoxes(arr);
            setTimeout(() => {
                setAnimate(true);
            }, 200);
        }
    }, [yearsArray, functionObj]);

    useEffect(() => {
        if (data.program && !loading && !appUserLoading && !hasSet) {
            setHasSet(true);

            const { appUser = {} } = APP_USER;
            if (appUser.userDataCbd) {
                let arr = [];
                let _obj = {};
                for (let [key, value] of Object.entries(appUser.userDataCbd)) {
                    if (key.indexOf('trainingMandatory') !== -1 && value) {
                        arr.push({
                            year: getSecondPart(key, 'trainingMandatory'),
                        });
                    }
                    if (key.indexOf('function20') !== -1 && value) {
                        _obj[getSecondPart(key, 'function')] =
                            value ||
                            appUser.userDataCbd.function ||
                            'Assistent drogist';
                    }
                }
                setFunctionObj(_obj);
                setYearsArray(arr);
            }
        }
    }, [loading, data, appUserLoading]);

    const getSecondPart = (str, firstPart) => {
        return str.split(firstPart)[1];
    };
    const handleData = (data, percentage, appUserData) => {
        setData(data);
        setResume(appUserData);
    };
    const _boxes = [...boxes];
    return (
        <BoxServicev2
            onNewData={handleData}
            onLoadChange={(data) => setIsLoading(data)}
        >
            {!loading ? (
                <div
                    className={classNames(classes.root, {
                        [classes.animate]: animate,
                    })}
                >
                    {resume && (
                        <div className={classes.smallWrapper}>
                            <ResumeButton {...resume} />
                        </div>
                    )}
                    <div className={classes.wrapper}>
                        <div className={classes.bar}>
                            <Typography
                                variant="h4"
                                className={classes.fontStyle}
                            >
                                {t("app.alt-dashboard.program-blocks.title")}
                            </Typography>
                            <Link to="/dashboard" className={classes.link}>
                                <Typography
                                    variant="body1"
                                    className={classNames(
                                        classes.fontStyle,
                                        classes.linkText
                                    )}
                                >
                                    {t("app.alt-dashboard.program-blocks.dashboard-action")} <ChevronRightIcon />
                                </Typography>
                            </Link>
                        </div>
                        <div className={classes.content}>
                            {_boxes &&
                                _boxes
                                    .splice(0, 4)
                                    .map((item) => (
                                        <ProgramBlock key={item.id} {...item} />
                                    ))}
                        </div>
                    </div>
                </div>
            ) : (
                <div className={classes.loading}>
                    <Loader />
                </div>
            )}
        </BoxServicev2>
    );
};

export default compose(
    connect(mapStateToProps),
    graphql(GQL_FETCH_BOX_RESULTS, {
        options: (props) => ({
            variables: {
                program: props.program,
                user: props.appUserData.id,
            },
        }),
    }),
    graphql(GQL_FETCH_APP_USER, {
        name: 'APP_USER',
        options: (props) => ({
            variables: {
                id: props.appUserData.id,
            },
        }),
    }),
    withStyles(styles)
)(ProgramBlocks);
