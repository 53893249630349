import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const styles = (theme) => ({});

class InputChoiceField extends React.PureComponent {
    state = {
        checked: true,
    };

    componentDidUpdate(prevProps) {
        if (prevProps.initialValue != this.props.initialValue) {
            const { form, name, initialValue = '' } = this.props;
            this.setState({ checked: initialValue });
            form.onFieldChange({ key: name, value: initialValue });
        }
    }

    componentDidMount() {
        const { form, name, initialValue = false } = this.props;
        this.setState({ checked: initialValue });
        form.onFieldChange({ key: name, value: initialValue });
    }

    handleChange = (event) => {
        const { form, name, onChange } = this.props;
        this.setState({ checked: event.target.checked });
        form.onFieldChange({ key: name, value: event.target.checked });
        if (onChange) onChange(event.target.checked);
    };

    render() {
        const {
            className: classNameProp,
            classes,
            label,
            loading,
            readOnly = false,
        } = this.props;

        const className = classNames(
            classes.root,
            {
                [classes.loading]: loading,
            },
            classNameProp
        );

        return (
            <FormGroup row className={className}>
                <FormControlLabel
                    control={
                        <Switch
                            color="primary"
                            disabled={readOnly}
                            checked={this.state.checked}
                            onChange={this.handleChange}
                        />
                    }
                    label={label}
                />
            </FormGroup>
        );
    }
}

export default withStyles(styles)(InputChoiceField);
