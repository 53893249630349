'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _graphql = require('./graphql');

var _reactHooks = require('@apollo/react-hooks');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useGetElementPercentage = function useGetElementPercentage(workforms) {
    var _useQuery = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_WORKFORM_TYPES),
        _useQuery$data = _useQuery.data,
        data = _useQuery$data === undefined ? {} : _useQuery$data,
        loading = _useQuery.loading;

    var _data$workformTypes = data.workformTypes,
        workformTypes = _data$workformTypes === undefined ? [] : _data$workformTypes;

    var _useState = (0, _react.useState)([]),
        _useState2 = _slicedToArray(_useState, 2),
        workformResults = _useState2[0],
        setWorkformResults = _useState2[1];

    var _useState3 = (0, _react.useState)(0),
        _useState4 = _slicedToArray(_useState3, 2),
        percentage = _useState4[0],
        setPercentage = _useState4[1];

    (0, _react.useEffect)(function () {
        if (!loading && workformTypes && workforms && workforms.length) {
            var filtered = workforms.filter(function (workform) {
                return workformTypes.some(function (i) {
                    return i.id === workform.type && i.canHaveResult;
                });
            });

            setWorkformResults(filtered);
        }
    }, [loading, workforms]);

    (0, _react.useEffect)(function () {
        if (workformResults.length) {
            var finished = workformResults.filter(function (i) {
                return i.finished;
            });
            var _percentage = Math.floor(finished.length / workformResults.length * 100);

            setPercentage(_percentage > 100 ? 100 : _percentage);
        }
    }, [workformResults]);

    return percentage;
};

exports.default = useGetElementPercentage;