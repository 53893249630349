import React from 'react';
import OpenQuestionTextArea from './components/OpenQuestionTextArea';
import OpenQuestionTextFeedback from './components/OpenQuestionTextFeedback';
import OpenQuestionCheckboxFeedback from './components/OpenQuestionCheckboxFeedback';

const Answers = (props) => {
	const { data } = props;

	if (props.step === 1) {
		return <OpenQuestionTextArea {...props} />;
	} else if (props.step === 2) {
		if (data.questionFeedback) {
			return <OpenQuestionTextFeedback feedback={data.questionFeedback} {...props} />;
		} else {
			return <OpenQuestionCheckboxFeedback {...props} attentionPoints={data.attentionPoints} />;
		}
	}
};

export default Answers;
