import React from 'react';
import { TextField, DatePicker } from '@manakin/core';
import { useStyles } from '../parts/styles';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import { GQL_FETCH_SETTINGS } from '../../graphql';
import { useQuery } from 'react-apollo';
import { useBooleanSetting } from '@manakin/hooks';
import { SettingsKeys } from '@manakin/core/lib/constants';
import { bpvRole } from '@manakin/app-core/lib';
const {
    REGISTRATION_FUNCTION_ENABLED, REGISTRATION_FUNCTION_REQUIRED,
    REGISTRATION_EDUCATION_ORGANISATION_ENABLED, REGISTRATION_EDUCATION_ORGANISATION_REQUIRED,
} = SettingsKeys;

const PersonalDataForm = (props) => {
    const { data, form = {} } = props;
    const { fields: fieldsData } = form;
    const classes = useStyles();
    const { t } = useTranslation();

    const isBpv = data && data.invitationByToken && data.invitationByToken.trainerRole && data.invitationByToken.trainerRole  && data.invitationByToken.trainerRole.name === bpvRole;

    const { data: settingsData = {} } = useQuery(GQL_FETCH_SETTINGS, {
        skip: !isBpv,
        onCompleted: (data) => {
            if (data && data.settings && data.settings.settings) {
                const settings = data.settings.settings.reduce((obj, item) => Object.assign(obj, { [item.name]: item }), {});

                changeFormRequired(settings, 'function', REGISTRATION_FUNCTION_ENABLED, REGISTRATION_FUNCTION_REQUIRED);
                changeFormRequired(settings, 'educationOrganisation', REGISTRATION_EDUCATION_ORGANISATION_ENABLED, REGISTRATION_EDUCATION_ORGANISATION_REQUIRED);
            }
        },
    });
    const changeFormRequired = (settings, name, enabledKey, settingKey) => {
        if (!settings[enabledKey]) return;
        // If not enabled, default to not required
        const required = settings[enabledKey].value === 'true' ? settings[settingKey].value === 'true' : false;
        form.onRequiredChange(name, required);
    };

    const registrationFunctionEnabled = useBooleanSetting(settingsData.settings, REGISTRATION_FUNCTION_ENABLED);
    const registrationEducationOrganisationEnabled = useBooleanSetting(settingsData.settings, REGISTRATION_EDUCATION_ORGANISATION_ENABLED);

    return (
        <React.Fragment>
            <div className={classes.groupElements}>
                <TextField
                    form={form}
                    name="firstName"
                    label={t("common.fields.first-name.label")}
                    initialValue={fieldsData.firstName || ''}
                    shrink={false}
                />
                <TextField
                    form={form}
                    name="middleName"
                    label={t("common.fields.middle-name.label")}
                    initialValue={fieldsData.middleName || ''}
                    shrink={false}
                />
            </div>
            <div className={classes.groupElements}>
                <TextField
                    form={form}
                    name="lastName"
                    label={t("common.fields.last-name.label")}
                    initialValue={fieldsData.lastName || ''}
                    shrink={false}
                />
                <DatePicker
                    form={form}
                    name="dateOfBirth"
                    label={t("common.fields.birthdate.label")}
                    initialValue={fieldsData.dateOfBirth}
                    defaultValue="2000-01-01"
                    keyboardOnly={true}
                    maxDate={moment().format('YYYY-MM-DD')}
                />
            </div>

            {isBpv && (
                <div className={classes.groupElements}>
                    {registrationFunctionEnabled && (
                        <TextField
                            name='function'
                            label={t('common.fields.function.label')}
                            shrink={false}
                            initialValue={fieldsData.function}
                            form={form}
                        />
                    )}

                    {registrationEducationOrganisationEnabled && (
                        <TextField
                            name='educationOrganisation'
                            label={t('common.fields.education-organisation.label')}
                            shrink={false}
                            initialValue={fieldsData.educationOrganisation}
                            form={form}
                        />
                    )}
                </div>
            )}
        </React.Fragment>
    );
};

export default PersonalDataForm;
