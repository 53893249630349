"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _AccountWrapper = require("../AccountWrapper");

var _AccountWrapper2 = _interopRequireDefault(_AccountWrapper);

var _reactRedux = require("react-redux");

var _graphql = require("../graphql");

var _reactHooks = require("@apollo/react-hooks");

var _appCore = require("@manakin/app-core");

var _TableCell = require("@material-ui/core/TableCell");

var _TableCell2 = _interopRequireDefault(_TableCell);

var _Typography = require("@material-ui/core/Typography");

var _Typography2 = _interopRequireDefault(_Typography);

var _TableRow = require("@material-ui/core/TableRow");

var _TableRow2 = _interopRequireDefault(_TableRow);

var _core = require("@material-ui/core");

var _actions = require("@manakin/app-core/actions");

var _reactRouterDom = require("react-router-dom");

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useStyles = (0, _core.makeStyles)(function (theme) {
	return {
		body: {
			margin: 0
		}
	};
});

var Rapportage = function Rapportage(props) {
	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var config = (0, _reactRedux.useSelector)(function (state) {
		return state.config;
	}) || {};
	var classes = useStyles();
	var _config$pages = config.pages,
	    pages = _config$pages === undefined ? {} : _config$pages,
	    _config$customColors = config.customColors,
	    customColors = _config$customColors === undefined ? {} : _config$customColors;
	var _pages$general = pages.general,
	    general = _pages$general === undefined ? {} : _pages$general;
	var rapportType = general.rapportType;

	var location = (0, _reactRouterDom.useLocation)();

	var _useQuery = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_CURRENT_APP_USER_REAL),
	    _useQuery$data = _useQuery.data,
	    data = _useQuery$data === undefined ? {} : _useQuery$data,
	    loading = _useQuery.loading;

	var _data$currentAppUser = data.currentAppUser,
	    currentAppUser = _data$currentAppUser === undefined ? {} : _data$currentAppUser;
	var _currentAppUser$licen = currentAppUser.licenses,
	    licenses = _currentAppUser$licen === undefined ? [] : _currentAppUser$licen;


	var dispatch = (0, _reactRedux.useDispatch)();
	var onSetAppBarColor = (0, _react.useCallback)(function (color) {
		return dispatch((0, _actions.setAppBarBackgroundColor)(color));
	}, [dispatch]);

	(0, _react.useEffect)(function () {
		onSetAppBarColor({
			backgroundColor: customColors.accountAppBar,
			color: "secondary",
			path: location.pathname
		});
	}, []);

	var downloadUrl = function downloadUrl(item) {
		return "/api/pdf" + ("/" + (general.rapportType || "product")) + ("/report/" + item.product.id) + ("/" + currentAppUser.id) + ("/rapport-" + item.product.program.name + ".pdf");
	};

	var getTypeColumnHeader = function getTypeColumnHeader() {
		switch (rapportType) {
			case "userprogress":
				return t("common.table-headers.program");
			case "product":
				return t("common.table-headers.product");
			default:
				return "";
		}
	};

	var getTypeColumnValue = function getTypeColumnValue(item) {
		switch (rapportType) {
			case "userprogress":
				return item.product.program.name;
			case "product":
				return item.product.name;
			default:
				return item && item.product && item.product.program ? item.product.program.name : "";
		}
	};

	if (loading) return _react2.default.createElement(_appCore.Loader, null);

	return _react2.default.createElement(
		_AccountWrapper2.default,
		{
			title: t("app.account.reports.title"),
			wrapper: "medium"
		},
		licenses && _react2.default.createElement(_appCore.SimpleTable, {
			renderHead: function renderHead() {
				return _react2.default.createElement(
					_react2.default.Fragment,
					null,
					_react2.default.createElement(
						_TableCell2.default,
						{ align: "left" },
						getTypeColumnHeader()
					),
					_react2.default.createElement(
						_TableCell2.default,
						{ align: "right" },
						t("common.table-headers.report")
					)
				);
			},
			renderBody: function renderBody() {
				return _react2.default.createElement(
					_react2.default.Fragment,
					null,
					licenses.map(function (item) {
						return _react2.default.createElement(
							_TableRow2.default,
							{ key: item.code },
							_react2.default.createElement(
								_TableCell2.default,
								{ align: "left" },
								_react2.default.createElement(
									_Typography2.default,
									{
										variant: "body1",
										className: classes.body
									},
									getTypeColumnValue(item)
								)
							),
							_react2.default.createElement(
								_TableCell2.default,
								{ align: "right" },
								_react2.default.createElement(
									"a",
									{
										href: downloadUrl(item),
										target: "_blank"
									},
									_react2.default.createElement(
										_Typography2.default,
										{
											variant: "body1",
											className: classes.body
										},
										t("app.account.reports.download")
									)
								)
							)
						);
					})
				);
			}
		})
	);
};

exports.default = Rapportage;