export const styles = (theme) => ({
    FontColor: {},
    backgroundImage: {},
    root: {
        height: 'auto',
        minHeight: '100vh',
        width: '100vw',
        opacity: 0,
        animation: 'fadeIn .3s ease 1s forwards',
        position: 'relative',
    },
    innerRoot: {
        height: 'auto',
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        backgroundSize: 'cover',
        position: 'relative',
        padding: '10rem 0',
    },
    showContent: {
        minHeight: '80vh',
    },
    wrapper: {
        ...theme.manakin.largeWrapper,
        position: 'relative',
    },
    content: {
        textAlign: 'center',
    },
    primaryFontStyle: {
        color: 'white',
    },
    fontStyle: {
        textAlign: 'center',
    },
    playButton: {
        width: '64px',
        height: '64px',
        color: 'white',
        backgroundColor: theme.palette.primary[500],
        borderRadius: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 auto',
        cursor: 'pointer',
        opacity: 0,
        animation: 'show .3s ease 1.4s forwards',
        [theme.breakpoints.up('md')]: {
            width: '14.5rem',
            height: '14.5rem',
            marginTop: '3.6rem',
            transform: 'scale(1)',
            transition: 'transform .3s',
        },
        '&:hover': {
            [theme.breakpoints.up('md')]: {
                transform: 'scale(.8)',
            },
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: '60px',
        },
    },
    contentWrapper: {
        ...theme.manakin.defaultWrapper,
        paddingTop: '90px',
        paddingBottom: '90px',
    },
    contentBlock: {
        padding: '0 0 120px',
    },
    scenesGrid: {
        '& $gridGutterHelper': {
            margin: '-20px -50px',
        },
        '& $gridBlock': {
            padding: '20px 50px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
    },
    gridGutterHelper: {
        display: 'inherit',
        flex: '1 1 100%',
        flexWrap: 'inherit',
        margin: '-50px',
        [theme.breakpoints.down('md')]: {
            margin: '-20px',
        },
    },
    gridBlock: {
        padding: '50px',
        [theme.breakpoints.down('md')]: {
            padding: '20px',
        },
        [theme.breakpoints.down('sm')]: {
            flex: '1 1 100%',
            maxWidth: '100%',
        },
    },
    gridBlockThumbnail: {
        width: '100%',
        height: '250px',
        objectFit: 'cover',
        marginBottom: '24px',
    },
    gridBlockTitle: {
        fontSize: '18px',
        lineHeight: 'normal',
    },
    gridBlockSceneTitle: {
        fontSize: '24px',
        lineHeight: 'normal',
    },
    gridBlockText: {
        '&:last-of-type': {
            marginBottom: 0,
        },
    },
    cardHover: {
        textDecoration: 'none',
    },
    title: {
        [theme.breakpoints.up('md')]: {
            marginBottom: '3rem',
        },
    },
    contentHeading: {
        fontSize: '40px',
        marginBottom: '36px',
        '&::after': {
            content: `''`,
            display: 'block',
            width: '50px',
            height: '2px',
            backgroundColor: theme.palette.primary.main,
        },
    },
    scenePlayButton: {
        width: '64px',
        height: '64px',
        color: 'white',
        backgroundColor: theme.palette.primary[500],
        borderRadius: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 auto',
        cursor: 'pointer',
        opacity: 0,
        animation: 'show .3s ease 1.4s forwards',
        [theme.breakpoints.up('md')]: {
            width: '14.5rem',
            height: '14.5rem',
            marginTop: '3.6rem',
            transform: 'scale(1)',
            transition: 'transform .3s',
            '&:hover': {
                transform: 'scale(.8)',
            },
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: '60px',
        },
    },
    hasImage: {},
    playContainer: {
        position: 'relative',
        '& $scenePlayButton': {
            position: 'absolute',
            margin: 0,
            padding: '10px',
            width: '40px',
            height: '40px',
            animation: 'none',
            transform: 'translate(0)',
            top: '-24px',
            left: '0',
            opacity: 1,
            '&:hover': {
                transform: 'translate(-50%, -50%)',
            },
            [theme.breakpoints.up('md')]: {
                width: '80px',
                height: '80px',
                padding: '28px',
                transform: 'translate(-50%, -50%)',
                top: '50%',
                left: '50%',
            },
        },
        '& $hasImage': {
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
    },
    sceneThumbnail: {
        width: '100%',
        height: '150px',
        objectFit: 'cover',
        [theme.breakpoints.up('md')]: {
            height: '250px',
        },
    },
    factCardContainer: {
        position: 'relative',
    },
    tags: {
        position: 'absolute',
        top: '20px',
        right: '20px',
        zIndex: 99,
        [theme.breakpoints.up('lg')]: {
            top: '60px',
            right: '60px',
        },
    },
    scenesButton: {
        marginTop: '2rem',
    },
});
