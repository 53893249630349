import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
	root: {
		position: 'absolute',
		top: '45%',
		width: '100%',
		textAlign: 'center'
	}
});

const LoginToken = (props) => {
	const { classes } = props;
	const { t } = useTranslation();
	const hash = window.location.hash.substring(1);
	const appUrl = process.env.REACT_APP_APP_URL || 'http://localhost:3000';

	if (hash) {
		fetch(`${appUrl}/api/toegang-org/jws`, {
			method: 'POST',
			credentials: 'include',
			redirect: 'follow',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ token: hash }),
		}).then((response) => {
			if (response && response.redirected && response.ok && response.url) {
				window.location.replace(response.url);
			}
		});
	}

	return <div className={classes.root}>{t('authentication.registration.token.message')}</div>;
};

export default compose(withStyles(styles))(LoginToken);
