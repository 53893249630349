import React, { useState, useEffect } from 'react';
import { withWorkforms, Loader } from '@manakin/app-core';
import { compose } from 'recompose';
import Trainer from './Trainer';
import { changeColor } from '@manakin/app-core/ColorService/actions';
import { useLocation } from 'react-router-dom';
import { GQL_FETCH_STYLESHEET } from './graphql';
import { useQuery } from '@apollo/react-hooks';
import { connect } from 'react-redux';
const queryString = require('query-string');

const TrainerPreview = (props) => {
	const { workformsData, match, changeColor } = props;
	const query = match.params.query;
	const elementData = queryString.parse(query);
	const location = useLocation();

	//queries
	const { data = {}, loading } = useQuery(GQL_FETCH_STYLESHEET, {
		variables: {
			id: elementData.styleSheet || '',
		},
	});

	//state hooks
	const [step, setStep] = useState(1);
	const [items, setItems] = useState([]);
	const [itemTypes, setItemTypes] = useState([]);

	useEffect(() => {
		if (elementData && elementData.items) {
			if (Array.isArray(elementData.items)) {
				setItems(elementData.items);
				setItemTypes(elementData.itemTypes);
			} else {
				setItems([elementData.items]);
				setItemTypes([elementData.itemTypes]);
			}
		}
	}, [query]);

	useEffect(() => {
		if (!loading) {
			const obj = {
				...elementData,
				headerImage: {
					url: elementData.headerImage,
				},
				image: {
					url: elementData.image,
				},
				workforms: [
					...items.map((item, idx) => ({
						id: item,
						type: itemTypes[idx],
					})),
				],
				options: {},
			};

			changeColor({
				...data.styleSheet,
				location: location.pathname,
			});

			workformsData.loadWorkforms({ ...obj });
		}
	}, [loading, items, itemTypes]);

	const handleFinish = () => {
		setStep(3);
	};

	const handleClick = () => {
		setStep(2);
	};

	if (workformsData.loading || loading) return <Loader fullScreen />;
	return (
		<Trainer
			{...workformsData}
			step={step}
			preview={true}
			controls={false}
			onExit={handleFinish}
			onClick={handleClick}
		/>
	);
};

export default compose(
	connect(null, (dispatch) => ({
		changeColor: (styleSheet) => dispatch(changeColor(styleSheet)),
	})),
	withWorkforms()
)(TrainerPreview);
