'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _constants = require('@manakin/core/lib/constants');

var _actions = require('@manakin/core/actions');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useCopyProtection = function useCopyProtection() {
    var copyProtectionEnabled = (0, _actions.isSettingEnabled)(_constants.SettingsKeys.COPY_PROTECTION_ENABLED);

    (0, _react.useEffect)(function () {
        if (copyProtectionEnabled) {
            document.addEventListener('copy', function (event) {
                return event.preventDefault();
            });
            document.oncontextmenu = function () {
                return false;
            };
        }
    }, [copyProtectionEnabled]);
};

exports.default = useCopyProtection;