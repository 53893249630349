import { compose, curry, Failure, Success } from './lib';
import {
    checkRequired,
    checkMaxLength,
    checkMinLength,
    checkMaxValue,
    checkMinValue,
    checkType,
    checkFormat,
    checkCouple,
} from "./checks";

export const booleanToValidation = curry((failureString, valid) =>
    valid ? Success(true) : Failure([failureString])
);
export const validateRequired = compose(
    booleanToValidation('required'),
    checkRequired
);
export const validateMaxLength = compose(
    booleanToValidation('maxLength'),
    checkMaxLength
);
export const validateMinLength = compose(
    booleanToValidation('minLength'),
    checkMinLength
);
export const validateMaxValue = compose(
    booleanToValidation('maxValue'),
    checkMaxValue
);
export const validateMinValue = compose(
    booleanToValidation('minValue'),
    checkMinValue
);
export const validateType = compose(booleanToValidation('type'), checkType);
export const validateFormat = compose(
    booleanToValidation('format'),
    checkFormat
);

export const validateCouple = compose(
    booleanToValidation('couple'),
    checkCouple
);

export const validateProperty = (schema, propertyValue, entity) => {
    let validators = [
        validateRequired,
        validateMaxLength,
        validateMinLength,
        validateMaxValue,
        validateMinValue,
        validateType,
        validateFormat,
        validateCouple,
    ];
    return validators.reduce(
        (validationResult, validator) =>
            validationResult.concat(validator(schema, propertyValue, entity)),
        Success(true)
    );
};

export const validateEntity = (schema, entity) => {
    return Object.keys(schema).reduce((validationResult, propertyName) => {
        return validationResult.concat(
            validateProperty(
                schema[propertyName],
                entity[propertyName],
                entity
            ).mapFailure((v) => [
                {
                    propertyName: propertyName,
                    errors: v,
                },
            ])
        );
    }, Success(true));
};
