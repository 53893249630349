import React from 'react';
import { TransitionRouter } from '@manakin/app-core';
import { Route } from 'react-router-dom';
import FirstPage from './parts/FirstPage';
import Questions from './parts/Questions';
import LastPage from './parts/LastPage';

const TRAINER_ROOT_PATH = 'Trainer';

class Trainer extends React.PureComponent {
    handleExit = (count) => {
        if (this.props.onExit) this.props.onExit(count);
    };

    render() {
        const {
            rawData = {},
            loading,
            workforms = [],
            step = false,
            onClick,
            controls = true,
            preview = false,
            prefix = null,
        } = this.props;

        if (step) {
            return (
                <React.Fragment>
                    {step == 1 && (
                        <FirstPage
                            preview={preview}
                            prefix={prefix}
                            controls={controls}
                            data={rawData}
                            loading={loading}
                            onClick={onClick}
                        />
                    )}
                    {step == 2 && (
                        <Questions
                            preview={preview}
                            prefix={prefix}
                            controls={controls}
                            data={rawData}
                            workforms={workforms}
                            loading={loading}
                            onExit={this.handleExit}
                        />
                    )}
                    {step == 3 && (
                        <LastPage
                            preview={preview}
                            prefix={prefix}
                            controls={controls}
                            data={rawData}
                            onClick={onClick}
                        />
                    )}
                </React.Fragment>
            );
        } else {
            return (
                <TransitionRouter>
                    <Route
                        exact
                        path={`/${TRAINER_ROOT_PATH}/:elementId`}
                        render={() => (
                            <FirstPage
                                prefix={prefix}
                                preview={preview}
                                data={rawData}
                                controls={controls}
                                loading={loading}
                                onClick={onClick}
                            />
                        )}
                    />
                    <Route
                        exact
                        path={`/${TRAINER_ROOT_PATH}/:elementId/questions`}
                        render={() => (
                            <Questions
                                prefix={prefix}
                                preview={preview}
                                data={rawData}
                                workforms={workforms}
                                controls={controls}
                                loading={loading}
                                onExit={this.handleExit}
                            />
                        )}
                    />
                    <Route
                        exact
                        path={`/${TRAINER_ROOT_PATH}/:elementId/outro/:elementId/:count`}
                        render={(props) => (
                            <LastPage
                                {...props}
                                prefix={prefix}
                                preview={preview}
                                data={rawData}
                                controls={controls}
                                onClick={onClick}
                            />
                        )}
                    />
                </TransitionRouter>
            );
        }
    }
}

export default Trainer;
