'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _ViewStream = require('@material-ui/icons/ViewStream');

var _ViewStream2 = _interopRequireDefault(_ViewStream);

var _FormatListBulleted = require('@material-ui/icons/FormatListBulleted');

var _FormatListBulleted2 = _interopRequireDefault(_FormatListBulleted);

var _Tabs = require('@material-ui/core/Tabs');

var _Tabs2 = _interopRequireDefault(_Tabs);

var _Tab = require('@material-ui/core/Tab');

var _Tab2 = _interopRequireDefault(_Tab);

var _actions = require('../Boxes/actions');

var _selectors = require('../Boxes/selectors');

var _reactRedux = require('react-redux');

var _recompose = require('recompose');

var _styles = require('@material-ui/core/styles');

var _reactI18next = require('react-i18next');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var useStyles = (0, _styles.makeStyles)(function (theme) {
    return (0, _styles.createStyles)({
        wrapper: _extends({}, theme.manakin.defaultWrapper, _defineProperty({

            display: 'none'
        }, theme.breakpoints.up('sm'), {
            display: 'block'
        })),
        tabsRoot: {
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: '2rem'
        },
        tabsIndicator: {
            display: 'none'
        },
        tabRoot: _extends({
            height: '4rem',
            minWidth: '4rem',
            minHeight: '4rem',
            border: '1px solid'
        }, theme.manakin.layoutButtonStyle, _defineProperty({}, theme.breakpoints.up('md'), {
            height: '6rem',
            minWidth: '6rem',
            minHeight: '6rem'
        })),
        tabSelected: _extends({}, theme.manakin.layoutButtonStyleSelected),
        label: {
            marginRight: '2rem',
            fontFamily: theme.manakin.secondaryTitleFont,
            color: theme.manakin.navigationColor[500],
            position: 'absolute',
            right: '150px',
            top: '17px'
        }
    });
}, {
    name: 'AppLayoutButtons'
});

var mapStateToProps = function mapStateToProps(state) {
    return {
        listView: (0, _selectors.isListView)(state)
    };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
    return {
        onToggleView: function onToggleView() {
            return dispatch((0, _actions.toggleBoxesView)());
        }
    };
};

var LayoutButtons = function LayoutButtons(props) {
    var listView = props.listView,
        onToggleView = props.onToggleView;

    var classes = useStyles();
    var value = listView ? 1 : 0;

    var _useTranslation = (0, _reactI18next.useTranslation)(),
        t = _useTranslation.t;

    var handleChange = function handleChange(event, value) {
        onToggleView();
    };

    return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(
            'div',
            { className: classes.wrapper },
            _react2.default.createElement(
                'span',
                { className: classes.label },
                t("common.view-plural")
            ),
            _react2.default.createElement(
                _Tabs2.default,
                {
                    value: value,
                    onChange: handleChange,
                    classes: {
                        flexContainer: classes.tabsRoot,
                        indicator: classes.tabsIndicator
                    }
                },
                _react2.default.createElement(_Tab2.default, {
                    value: 0,
                    icon: _react2.default.createElement(_ViewStream2.default, null),
                    classes: {
                        root: classes.tabRoot,
                        selected: classes.tabSelected
                    }
                }),
                _react2.default.createElement(_Tab2.default, {
                    value: 1,
                    icon: _react2.default.createElement(_FormatListBulleted2.default, null),
                    classes: {
                        root: classes.tabRoot,
                        selected: classes.tabSelected
                    }
                })
            )
        )
    );
};

exports.default = (0, _recompose.compose)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps))(LayoutButtons);