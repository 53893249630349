import React, { useState } from 'react';
import { ElementOverlay } from '@manakin/app-core';
import FrontPage from './FrontPage';
import { useLocation } from 'react-router';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import ArrowDropDownCircle from '@material-ui/icons/ArrowDropDownCircle';
import { Typography } from '@material-ui/core';
import scrollToComponent from 'react-scroll-to-component';
import History from '../../DefaultPages/Results/History';
import { useQuery } from '@apollo/react-hooks';
import { GQL_FETCH_SCORES_FOR_ELEMENT } from '../graphql';

const FrontPageContainer = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const classes = useStyles();
	const { state = {} } = location;

	const [section, setSection] = useState('');

	const { data, loading } = useQuery(GQL_FETCH_SCORES_FOR_ELEMENT, {
		variables: {
			programId: state.programId,
		},
	});
	const { examTrainerScores = [] } = data || {};
	const scores = examTrainerScores.map((score) => {
		return {
			...score,
			tags: score.tags.map((score) => score.name, []),
		};
	});

	const handleScrollClick = () => {
		scrollToComponent(section, {
			offset: 70,
			align: 'top',
			duration: 500,
		});
	};

	return (
		<ElementOverlay title={t('common.element-types.exam-trainer')} fullWidth={true} customHeight={true}>
			<div className={classNames(classes.innerRoot)}>
				<div className={classes.wrapper}>
					<div className={classes.content}>
						<FrontPage {...state} />
					</div>
					{scores && (
						<Typography
							component="div"
							variant="body1"
							classes={{ body1: classes.topScores }}
							onClick={handleScrollClick}
							ref={(section) => {
								setSection(section);
							}}
						>
							<ArrowDropDownCircle className={classes.icon} />
							{t('app.elements.trainer.see-top-scores')}
						</Typography>
					)}
				</div>
				{scores && (
					<History history={scores} />
				)}
			</div>
		</ElementOverlay>
	);
};

export default FrontPageContainer;
