import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import renderHTML from "react-render-html";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";

const styles = (theme) => ({
    root: {},
    wrapper: {
        ...theme.manakin.extraLargeWrapper,
        [theme.breakpoints.up("lg")]: {
            paddingLeft: 100,
            paddingRight: 100,
        },
        [theme.breakpoints.down("md")]: {
            paddingTop: 40,
            paddingBottom: 40,
            paddingLeft: 40,
            paddingRight: 40,
        },
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        },
        display: "flex",
        background: theme.manakin.primaryWorkformColor[500],
        paddingTop: 50,
        paddingBottom: 50,
        paddingLeft: 70,
        paddingRight: 70,
        alignItems: "center",
    },
    content: {
        fontWeight: 400,
        margin: 0,
        color: theme.manakin.defaultContentColor[500],
        "@global ul": {
            paddingLeft: "2.25rem",
        },
    },
    lightContent: {
        "& $content": {
            color: "white",
        },
    },
    button: {
        marginTop: "3rem",
    },
    text: {
        minWidth: "40%",
        [theme.breakpoints.down("xs")]: {
            marginBottom: theme.spacing(3),
        },
    },
    text2Container: {
        display: "flex",
        flexDirection: "column",
    },
    text2: {
        lineHeight: "normal",
        fontSize: "2.25rem",
        flexGrow: 1,
    },
    primary: {
        backgroundColor: theme.manakin.primaryWorkformColor[500],
        "& $content": {
            "& span, & p, & h2, & li": {
                color: theme.palette.getContrastText(theme.manakin.defaultWorkformColor[500]),
            },
        },
    },
    secondary: {
        backgroundColor: theme.manakin.secondaryWorkformColor[500],
        "& $content": {
            "& span, & p, & h2, & li": {
                color: theme.palette.getContrastText(theme.manakin.defaultWorkformColor[500]),
            },
        },
    },
    noBG: {
        backgroundColor: "white",
        "& $content": {
            color: theme.manakin.defaultContentColor[500],
        },
    },
});

const ProcessStep = (props) => {
    const { classes, data, contentStyle = "light" } = props;
    const backgroundColor = data.backgroundColor || null;

    return (
        <div
            className={classNames(classes.root, {
                [classes.lightContent]: contentStyle === "light",
            })}
        >
            <div
                className={classNames(
                    classes.wrapper,
                    { [classes.primary]: backgroundColor == "PRIMARY" },
                    { [classes.secondary]: backgroundColor == "SECONDARY" },
                    { [classes.noBG]: backgroundColor == "NONE" },
                )}>
                <Typography
                    component='h2'
                    variant='h3'
                    classes={{ h3: classNames(classes.content, classes.text) }}
                >
                    {renderHTML(data.text || "")}
                </Typography>
                <div className={classes.text2Container}>
                    <Typography
                        component='div'
                        variant='body1'
                        classes={{ body1: classNames(classes.content, classes.text2) }}
                    >
                        {renderHTML(data.text2 || "")}
                    </Typography>
                </div>
            </div>
        </div>
    );
};

export default compose(
    withStyles(styles, { name: "AppProcessStep" }),
)(ProcessStep);
