import React, { useEffect, useState } from "react";
import { GQL_LOGIN_TOKEN } from "../graphql";
import { useStyles } from "./styles";
import { useMutation } from "@apollo/react-hooks";
import { Loader } from "@manakin/app-core";
import { ErrorPage, SuccessPage } from "./pages";

const AppConfirmRegistration = (props) => {
	const { token } = props;
	const classes = useStyles();

	//state hooks
	const [licenses, setLicenses] = useState([]);
	const [loading, setLoading] = useState(true);

	//mutations
	const [mutate, { data, error }] = useMutation(GQL_LOGIN_TOKEN);

	//effect hooks
	useEffect(() => {
		mutate({
			variables: {
				token: token,
			},
		}).catch((err) => {
			// Failed, prevent crash
		});
	}, []);

	useEffect(() => {
		if (data) {
			const licenses = data.data.loginTokenApp
				? data.data.loginTokenApp.user.licenses
				: [];
			setLoading(false);
			setLicenses(licenses);
		}
		if (error) setLoading(false);
	}, [data, error]);

	if (loading) return <Loader fullScreen />;
	if (error) return <ErrorPage classes={classes} />;
	return <SuccessPage classes={classes} licenses={licenses} />;
};

export default AppConfirmRegistration;
