import gql from 'graphql-tag';
import {
    box,
    elementResults,
    currentAppUser,
    boxResults,
    fileAsset
} from '@manakin/app-core/graphQlHelpers';

export const GQL_FETCH_BOX_RESULTS = gql`
    query boxResults($program: ID!, $user: ID!, $box: ID) {
        boxResults(program: $program, user: $user, box: $box) {
            ${boxResults}
        }
    }
`;

export const GQL_FETCH_CMS_BOX = gql`
    query box($id: ID!) {
        box(id: $id) {
            ${box}
        } 
    }
`;

export const GQL_FETCH_ELEMENT_RESULTS = gql`
    query elementResults($box: ID!, $program: ID!, $user: ID!) {
        elementResults(box: $box, program: $program, user: $user) {
            ${elementResults}
        }
    }
`;

export const GQL_UPDATE_USER = gql`
    mutation updateAppUser(
        $id: ID!
        $programId: String
        $boxId: String
        $elementId: String
    ) {
        updateAppUser(
            input: {
                id: $id
                programId: $programId
                boxId: $boxId
                elementId: $elementId
            }
        ) {
            user {
                ${currentAppUser}
            }
        }
    }
`;

export const GQL_FETCH_SETTINGS = gql`
    query settings {
        settings {
            settings {
                name
                value
                file {
                    ${fileAsset}
                }
            }
        }
    }
`;

export const GQL_CREATE_BOX_RESULT = gql`
  mutation(
    $user: ID!
    $program: ID!
    $box: ID!
    $finished: Boolean
    $register: ID
  ) {
    upsertBoxResult(
      input: {
        user: $user
        program: $program
        box: $box
        finished: $finished
        register: $register
      }
    ) {
      result {
        id
        finished
        register {
          id
          title
        }
      }
    }
  }
`;
