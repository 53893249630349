export const styles = (theme) => ({
    emptyRoot: {
        padding: '100px',
        textAlign: 'center',
    },
    wrapper: {
        ...theme.manakin.defaultWrapper,
    },
    list: {
        borderTop: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
    },
    amount: {
        padding: '3rem 0',
        width: '100%',
        borderBottom: `1px solid ${theme.manakin.defaultBorderColor[900]}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.up('md')]: {
            padding: '3rem 0 4rem',
        },
    },
    students: {
        margin: 0,
    },
    schoolClasses: {
        padding: '4rem 0 10rem',
    },
    button: {
        marginRight: '2rem',
        marginBottom: '6rem',
    },
    licenseName: {
        ...theme.manakin.defaultWrapper,
        fontFamily: theme.manakin.secondaryTitleFont,
        color: 'black',
        fontSize: '1.4rem',
        letterSpacing: '.17rem',
        fontWeight: 'bold',
        textAlign: 'right',
        margin: 0,
        width: 'auto',
    },
    topBar: {
        ...theme.manakin.defaultWrapper,
        maxWidth: "",
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'flex-end',
        paddingBottom: theme.spacing(2.5),
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            justifyContent: 'center',
        },
    },
    reportsContainer: {
        ...theme.manakin.defaultWrapper,
        maxWidth: 1050
    },
    reportsWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexDirection: 'column',

        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row', 
            gap: 25,
        }
    },
    icon: {
        cursor: 'pointer',
        transition: 'color .3s',
        color: theme.manakin.defaultContentColor ? theme.manakin.defaultContentColor[500] : "",
        '&:hover': {
            color: theme.manakin.defaultContentHover[500],
        },
        [theme.breakpoints.up('md')]: {
            margin: '8px 24px 0 0',
        },
    },
    sortIcon: {
        width: '20px',
        height: '20px',
        opacity: 1,
    },
    reportSelect: {
        backgroundColor: theme.manakin.secondaryTypeColor[500],
        color: theme.manakin.primaryTypeColor[500],
    },
    reportSelectInput: {
        padding:
            theme.spacing(1.5) +
            'px ' +
            theme.spacing(6) +
            'px ' +
            theme.spacing(1.5) +
            'px ' +
            theme.spacing(2) +
            'px',
        minHeight: 0,
        fontFamily: theme.manakin.defaultTitleFont,
        fontSize: '12px',
        lineHeight: 'normal',
        letterSpacing: '4px',
    },
    dropdownIcon: {
        color: 'white',
        right: theme.spacing(2),
    },
    selectWrapper: {
        display: 'grid',
        gridTemplateColumns: "33.33% 33.33% 33.33%",
        flexFlow: 'row',
        alignItems: 'center',
        margin: theme.spacing(4, 0, 0),
        gap: theme.spacing(2)
    },
    selectField: {
        fontFamily: theme.manakin.defaultTitleFont,
        [theme.breakpoints.up('md')]: {
            margin: theme.spacing(0, 4, 0, 0),
        },
        '& svg': {
            color: 'white',
        },
    },
    downloadReport: {
        paddingTop: theme.spacing(2.5),
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        justifyContent: 'flex-end',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    downloadReportIcon: {
        marginLeft: theme.spacing(1),
    },
    chip: {
        backgroundColor: "#CAE8EB",
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1)
    },
    noStudents: {
        textAlign: 'center',
        marginTop: theme.spacing(1.5)
    }
});
