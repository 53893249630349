import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import classNames from 'classnames';

const styles = (theme) => ({
    root: {},
    radioFieldLabel: {
        marginBottom: 0,
    },
    radioInput: {
        color: theme.manakin.checkboxChecked,
    },
    fullWidthLabel: {
        width: '100%',
    },
    underline: {
        textDecoration: 'underline',
    },
    legendName: {
        textAlign: 'left',
        transformOrigin: 'top left',
        transform: 'scale(0.75)',
    },
});

const RadioField = (props) => {
    const {
        legendName = '',
        fullWidth = true,
        fullLabelWidth = false,
        label = 'No label in props',
        name = 'PLEASEGIVENAME!!',
        options,
        form,
        classes,
        loading,
        underline,
        initialValue = '',
        readOnly,
        showRequired = true,
    } = props;

    const { errors, schema } = form;
    const isRequired = (schema[name] || {}).required;

    //state hooks
    const [value, setValue] = useState('');

    //effect hooks
    useEffect(() => {
        if (!loading) {
            setValue(initialValue);
            form.onFieldChange({ key: name, value: initialValue });
        }
    }, [loading]);

    const handleChange = (event) => {
        setValue(event.target.value);
        form.onFieldChange({ key: name, value: event.target.value });
    };

    return (
        <FormControl
            fullWidth={fullWidth}
            disabled={readOnly}
            error={errors[name] && errors[name].length > 0}
        >
            {showRequired && (
                <FormLabel component='legend' className={classes.legendName}>
                    {legendName}
                    {isRequired && ' *'}
                </FormLabel>
            )}

            <RadioGroup
                aria-label={label}
                name={name}
                value={value}
                onChange={handleChange}
            >
                {options.map((option) => (
                    <FormControlLabel
                        key={option.value}
                        value={option.value}
                        control={
                            <Radio
                                color="default"
                                classes={{ checked: classes.radioInput }}
                            />
                        }
                        label={option.label}
                        classes={{
                            ...(fullLabelWidth && {
                                root: classes.fullWidthLabel,
                            }),
                            label: classNames(classes.radioFieldLabel, {
                                [classes.underline]: underline,
                            }),
                        }}
                    />
                ))}
            </RadioGroup>
            {errors[name] &&
                errors[name].length > 0 &&
                errors[name].map((e, i) => (
                    <FormHelperText
                        key={i}
                        classes={{ root: classes.radioFieldHelperText }}
                    >
                        {e}
                    </FormHelperText>
                ))}
        </FormControl>
    );
};

export default withStyles(styles)(RadioField);
