import React, { useState, useEffect } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Switch from "@material-ui/core/Switch";
import FormHelperText from "@material-ui/core/FormHelperText";

const SwitchField = (props) => {
	const {
		form,
		name = "PLEASEGIVENAME!!",
		label = "",
		loading = false,
		readOnly = false,
		initialValue = false,
		margin = 'normal'
	} = props;
	const { errors = {}, schema = {} } = form || {};
	const isRequired = (schema[name] || {}).required;

	//state hooks
	const [value, setValue] = useState(props.value || false);

	//effect hooks
	useEffect(() => {
		if (!loading) {
			setValue(initialValue);
			if (form) form.onFieldChange({ key: name, value: initialValue });
		}
	}, [loading]);

	//function
	const handleChange = (e, value) => {
		setValue(value);
		if (form) form.onFieldChange({ key: name, value: value });
		if (props.onChange) props.onChange(value, name);
	};

	return (
		<FormControl
			margin={margin}
			disabled={readOnly}
			error={errors[name] && errors[name].length > 0}
		>
			<FormControlLabel
				control={
					<Switch
						size="small"
						checked={value}
						onChange={handleChange}
						value={name}
					/>
				}
				label={`${label}${isRequired ? " *" : ""}`}
			/>
			{errors[name] &&
				errors[name].length > 0 &&
				errors[name].map((e, i) => (
					<FormHelperText key={i}>{e}</FormHelperText>
				))}
		</FormControl>
	);
};

export default SwitchField;
