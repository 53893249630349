import React, { useState, useEffect } from 'react';
import { WorkformViewer, ElementOverlay, LoadImage } from '@manakin/app-core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import FactCardReferral from './FactCardReferral';
import { isFlatWorkform } from '@manakin/app-core/lib';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
	root: {
		width: '100%',
		display: 'flex',
		padding: '4.8rem 0',
		opacity: 1,
		position: 'relative',
		transform: 'translateX(20px)',
		transition: 'transform .4s, opacity .2s',
		opacity: 0,
		[theme.breakpoints.up('md')]: {
			padding: '50px',
		},
		[theme.breakpoints.up('lg')]: {
			padding: '100px',
		},
		'&>div:not($tag)': {
			width: '100%',
			height: '100%',
		},
	},
	flat: {
		display: 'block',
		height: 'auto',
		maxWidth: '1200px',
		margin: '0 auto',
		padding: '20px',
		'&>div': {
			height: 'auto!important',
		},
	},
	sceneBackground: {
		backgroundColor: theme.manakin.primaryColor[500],
		position: 'absolute',
		height: '100%',
		top: 0,
		left: 0,
		width: '100%',
		display: 'flex',
	},
	scene: {
		padding: 0,
		position: 'static',
		maxHeight: '100vh',
	},
	animateIn: {
		transform: 'translateX(0)',
		opacity: 1,
	},
	animate: {
		opacity: 0,
	},
	factCard: {
		height: '100px',
	},
	buttonContainer: {
		width: '100%',
		textAlign: 'right',
		marginTop: '30px',
		paddingBottom: '100px',
	},
	button: {},
	tag: {
		position: 'absolute',
		zIndex: '999',
		left: '20px',
		top: '20px',
	},
});

const Questions = (props) => {
	const { classes, workforms = [], options, background = '', controls = true, saving } = props;
	//state hooks
	const [step, setStep] = useState(0);
	const [jump, setJump] = useState(false);
	const [currentWorkform, setCurrentWorkform] = useState({});
	const [prevScene, setPrevScene] = useState({});
	const [animate, setAnimate] = useState(false);
	const [animateIn, setAnimateIn] = useState(true);
	const location = useLocation();
	const { t } = useTranslation();

	//effect hooks
	useEffect(() => {
		if (location && location.state) {
			handleClick(location.state.idx);
		}
	}, []);

	useEffect(() => {
		if (animate) {
			setTimeout(() => {
				setAnimate(false);
				setCurrentWorkform({ ...workforms[step] });
				setTimeout(() => {
					if (workforms[step].type === 'Scene') setPrevScene(workforms[step]);
					setAnimateIn(true);
				}, 200);
			}, 300);
		}
	}, [animate]);

	useEffect(() => {
		setCurrentWorkform({ ...workforms[step] });
	}, [workforms]);

	//functions
	const handleNext = (data) => {
		if (jump || (currentWorkform.split && !data.correct)) handleClick(2);
		else handleClick(1);
	};

	const handleClick = (amount) => {
		if (currentWorkform.split && amount === 1) setJump(true);
		else setJump(false);
		const idx = amount + step;
		if (workforms[idx]) {
			setStep(idx);
			setAnimate(true);
			setAnimateIn(false);
		} else {
			if (props.onFinish) props.onFinish(workforms);
		}
	};

	return (
		<ElementOverlay fullWidth={currentWorkform.type === 'Scene' ? true : false} background={background} controls={controls}>
			{currentWorkform && currentWorkform.id && (
				<div
					className={classNames(classes.root, {
						[classes.scene]: currentWorkform.type === 'Scene',
						[classes.animate]: animate,
						[classes.animateIn]: animateIn,
						[classes.flat]: isFlatWorkform(currentWorkform.type),
					})}
				>
					{currentWorkform.type === 'FactCardReferral' ? (
						<div className={classes.factCard}>
							<WorkformViewer
								type={currentWorkform.type}
								item={currentWorkform}
								onFinish={handleNext}
								options={options}
								tagClass={classes.tag}
								customLink={{
									withId: true,
									state: {
										url: location.pathname,
										idx: step + 1,
									},
								}}
							/>
						</div>
					) : (
						<WorkformViewer
							type={currentWorkform.type}
							item={currentWorkform}
							onFinish={handleNext}
							options={options}
							tagClass={classes.tag}
							saving={saving}
						/>
					)}
					{isFlatWorkform(currentWorkform.type) && (
						<div className={classes.buttonContainer}>
							<Button variant="outlined" color="primary" onClick={handleNext} className={classes.button}>
								{t('common.resume')}
							</Button>
						</div>
					)}
				</div>
			)}
		</ElementOverlay>
	);
};

export default withStyles(styles)(Questions);
