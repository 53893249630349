import React from 'react';
import { WorkformLayout } from '@manakin/app-core';
import FormControl from '@material-ui/core/FormControl';
import { useStyles } from './styles';
import Typography from '@material-ui/core/Typography';
import { FeedbackBox } from '@manakin/app-workforms';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Answers from './Answers';

const OpenQuestion = (props) => {
	const { data, answer } = props;
	const classes = useStyles();
	const backgroundColor = data.backgroundColor || null;
	const { t } = useTranslation();

	return (
		<div
			className={classNames(
				classes.root,
				{ [classes.primary]: backgroundColor == 'PRIMARY' },
				{ [classes.secondary]: backgroundColor == 'SECONDARY' },
				{ [classes.noBG]: backgroundColor == 'NONE' }
			)}
		>
			<div className={classes.wrapper}>
				<div className={classes.content}>
					<WorkformLayout
						question={data.question || ''}
						instruction={data.introduction || t('app.workforms.text-check-question.introduction')}
						loading={false}
						renderQuestion={() => (
							<FormControl component="fieldset">
								<Typography component="div" variant="body1" className={classes.contentColor}>
									{answer && 
										<div className={classes.answerBox}>
											<small>{t('app.workforms.open-question.your-answer')}</small> 
											{answer}
										</div>
									}
								</Typography>
							</FormControl>
						)}
						renderAnswers={() => (
							<React.Fragment>
								<Answers {...props} />
								{data.hintText && data.hintText !== '<p><br></p>' && data.finished && (
									<div className={classes.feedback}>
										<FeedbackBox
											data={{
												correct: true,
												feedbackCorrect: data.hintText,
												options: {
													nextButton: false,
													showFeedbackTitle: false,
												},
											}}
											outlined={true}
										/>
									</div>
								)}
							</React.Fragment>
						)}
					/>
				</div>
			</div>
		</div>
	);
};

export default OpenQuestion;
