import React, { useEffect, useState } from 'react';
import { withWorkforms, Loader } from '@manakin/app-core';
import { compose } from 'recompose';
import { GQL_FETCH_STYLESHEET } from './graphql';
import { useQuery } from '@apollo/react-hooks';
import { connect } from 'react-redux';
import { changeColor } from '@manakin/app-core/ColorService/actions';
import { useLocation } from 'react-router-dom';
import AssignmentCardView from './AssignmentCardView';
const queryString = require('query-string');

const mapStateToProps = (state) => ({
    config: state.config,
});

const PreviewAssignmentCard = (props) => {
    const { match, workformsData, changeColor, config = {} } = props;
    const query = match.params.query;
    const elementData = queryString.parse(query);
    const location = useLocation();
    const [items, setItems] = useState([]);

    const { data = {}, loading } = useQuery(GQL_FETCH_STYLESHEET, {
        variables: {
            id: elementData.styleSheet || '',
        },
    });

    useEffect(() => {
        if (elementData && elementData.items) {
            if (Array.isArray(elementData.items)) {
                setItems(elementData.items);
            } else {
                setItems([elementData.items]);
            }
        }
    }, [query]);

    //effect hooks
    useEffect(() => {
        if (!loading) {
            window.scrollTo(0, 0);
            const obj = {
                ...elementData,
                headerImage: {
                    url: elementData.headerImage,
                },
                image: {
                    url: elementData.image,
                },
                workforms: [
                    ...items.map((item, idx) => ({
                        id: item,
                        type: elementData.itemTypes[idx],
                    })),
                ],
                options: {},
            };

            changeColor({
                ...data.styleSheet,
                location: location.pathname,
            });
            workformsData.loadWorkforms({ ...obj });
        }
    }, [loading, items]);

    const handleSaveChoice = (value) => {
        return;
    };

    const handleReset = () => {
        return;
    };

    return (
        <AssignmentCardView
            workformsData={workformsData}
            isDisabled={false}
            config={config}
            onSaveChoice={handleSaveChoice}
            error={false}
            elementResult={{}}
            loading={loading}
            onReset={handleReset}
            preview
        />
    );
};

export default compose(
    connect(mapStateToProps, (dispatch) => ({
        changeColor: (styleSheet) => dispatch(changeColor(styleSheet)),
    })),
    withWorkforms()
)(PreviewAssignmentCard);
