import React, { useState, useEffect, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { TransitionRouter, withWorkforms, Loader } from '@manakin/app-core';
import { GQL_FETCH_MASTERCLASS, GQL_FETCH_GLOBAL_SETTINGS } from './graphql';
import { Route, withRouter } from 'react-router-dom';
import { DefaultFrontPage } from '../DefaultPages';
import Questions from './Questions';
import { connect } from 'react-redux';
import { changeColor } from '@manakin/app-core/ColorService/actions';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { useViewCounting } from "@manakin/hooks";

const MASTERCLASS_ROOT_PATH = 'MasterClass';

const styles = (theme) => ({});

const Masterclass = (props) => {
    const { workformsData, classes, changeColor, config = {} } = props;
    const { general = {} } = config;
    const location = useLocation();
    const { rawData = {} } = workformsData;
    const loaded = useRef(false);
    const { t } = useTranslation();
    const { viewElement } = useViewCounting();

    const { data = {}, loading } = useQuery(GQL_FETCH_MASTERCLASS, {
        variables: { id: props.match.params.elementId },
    });
    const { masterClass = {} } = data;

    //State hooks
    const [factCards, setFactCards] = useState([]);
    const [isFinished, setIsFinished] = useState(false);

    //Effect hooks
    useEffect(() => {
        if (!loading && !loaded.current) {
            window.scrollTo(0, 0);
            loaded.current = true;
            const options = {
                showFeedback: false,
                nextButton: true,
            };

            if (masterClass.workforms) {
                const elementId = props.match.params.elementId;

                changeColor({
                    ...masterClass.styleSheet,
                    location: location.pathname,
                });

                workformsData.loadWorkforms({
                    ...masterClass,
                    options: options,
                    elementId: elementId
                });
                workformsData.loadElementResult(elementId);

                viewElement(elementId);
            }
        }
    }, [loading]);

    useEffect(() => {
        if (!workformsData.loading) {
            if (
                workformsData.elementResult &&
                workformsData.elementResult.finished &&
                workformsData.elementResult.correct &&
                general.showScenesButton
            ) {
                setIsFinished(true);
            }
            if (
                workformsData.rawData &&
                workformsData.rawData.factCards &&
                workformsData.rawData.factCards.length
            ) {
                setFactCards([...workformsData.rawData.factCards]);
            }
        }
    }, [workformsData]);

    //functions
    const handleClick = () => {
        props.history.push(`${props.match.url}/questions`);
    };

    const handleFinish = () => {
        workformsData
            .saveElement({
                elementId: props.match.params.elementId,
                finished: true,
                correct: true,
            })
            .then(() => {
                props.history.push(`${props.match.url}/outro`);
            });
    };

    const handleScenesClick = () => {
        props.history.push(`${props.match.url}/outro`);
    };

    if (loading || workformsData.loading) {
        return (
            <div className={classes.loading}>
                <Loader fullScreen={true} />
            </div>
        );
    }
    return (
        <React.Fragment>
            <TransitionRouter>
                <Route
                    exact
                    path={`/${MASTERCLASS_ROOT_PATH}/:elementId`}
                    render={() => (
                        <DefaultFrontPage
                            playButton={true}
                            outroButton={isFinished}
                            scenesText={t("app.elements.masterclass.view-scenes")}
                            onScenesClick={handleScenesClick}
                            {...masterClass}
                            factCards={factCards}
                            onClick={handleClick}
                            controls={true}
                        />
                    )}
                />
                <Route
                    exact
                    path={`/${MASTERCLASS_ROOT_PATH}/:elementId/questions`}
                    render={() => (
                        <Questions
                            {...workformsData}
                            onFinish={handleFinish}
                            location={props.location}
                            background={rawData.borderColor || ''}
                        />
                    )}
                />
                <Route
                    exact
                    path={`/${MASTERCLASS_ROOT_PATH}/:elementId/outro`}
                    render={() => (
                        <DefaultFrontPage
                            buttonText={t("common.replay")}
                            {...masterClass}
                            factCards={factCards}
                            onClick={handleClick}
                        />
                    )}
                />
            </TransitionRouter>
        </React.Fragment>
    );
};

export default compose(
    connect(
        (state) => ({ config: state.config }),
        (dispatch) => ({
            changeColor: (styleSheet) => dispatch(changeColor(styleSheet)),
        })
    ),
    graphql(GQL_FETCH_GLOBAL_SETTINGS, {
        name: 'SETTINGS',
    }),
    withRouter,
    withWorkforms(),
    withStyles(styles)
)(Masterclass);
