import React, { useState, useEffect } from 'react';
import { withWorkforms } from '@manakin/app-core';
import {
    GQL_FETCH_EXERCISE_CARD,
    GQL_CREATE_ELEMENT_RESULT,
    GQL_FETCH_ELEMENT_RESULT,
} from './graphql';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useLocation, useParams } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { getBoxId } from '@manakin/app-core/Boxes/selectors';
import { getProgram } from '@manakin/app-core/ProgramsDropdown/selectors';
import { getAppUser } from '@manakin/authentication/selectors';
import { showSnackbarMessage } from '@manakin/core/actions';
import AssignmentCardView from './AssignmentCardView';
import { changeColor } from '@manakin/app-core/ColorService/actions';
import { useTranslation } from 'react-i18next';
import { useViewCounting } from "@manakin/hooks";

const mapStateToProps = (state) => ({
    config: state.config,
    boxId: getBoxId(state),
    appUser: getAppUser(state),
    program: getProgram(state),
});

const AssignmentCard = (props) => {
    const {
        workformsData = {},
        boxId,
        appUser,
        program,
        processSuccess,
        processFailure,
        config = {},
        changeColor,
    } = props;
    const location = useLocation();

    const [error, setError] = useState(false);
    const [dis, setDis] = useState(false);
    const params = useParams();
    const { t } = useTranslation();
    const { viewElement } = useViewCounting();

    //queries
    const { data = {}, loading } = useQuery(GQL_FETCH_EXERCISE_CARD, {
        variables: {
            id: params.elementId,
        },
    });
    const { exerciseCard = {} } = data;

    const {
        data: elementResultData = {},
        loading: elementResultLoading,
    } = useQuery(GQL_FETCH_ELEMENT_RESULT, {
        variables: {
            user: appUser.id,
            program: program,
            box: boxId,
            element: exerciseCard.id,
        },
    });
    const { elementResult = {} } = elementResultData;

    //mutations
    const [
        mutate,
        { data: mutationData, loading: mutationLoading, error: mutationError },
    ] = useMutation(GQL_CREATE_ELEMENT_RESULT);

    //effect hooks
    useEffect(() => {
        if (!loading && exerciseCard) {
            changeColor({
                ...exerciseCard.styleSheet,
                location: location.pathname,
            });
            workformsData.loadWorkforms({
                ...exerciseCard,
                options: {},
                elementId: exerciseCard.id,
            });

            viewElement(exerciseCard.id);
        }
    }, [exerciseCard, loading]);

    useEffect(() => {
        if (mutationData) {
            if (mutationData.createElementResult.result.finished) {
                setDis(true);
                processSuccess(t);
            } else {
                setDis(true);
                window.location.reload();
            }
        }
        if (mutationError) processFailure(t);
    }, [mutationData, mutationError]);

    const handleSaveChoice = (value) => {
        setError(false);
        if (!value) {
            setError(true);
            return;
        }

        mutate({
            variables: {
                user: appUser.id,
                program: program,
                box: boxId,
                element: exerciseCard.id,
                finished: true,
                rating: value,
            },
        });
    };

    const handleReset = () => {
        mutate({
            variables: {
                user: appUser.id,
                program: program,
                box: boxId,
                element: exerciseCard.id,
                finished: false,
                rating: '',
            },
        });
    };

    const isDisabled =
        (elementResult &&
            elementResult.finished &&
            elementResult.rating &&
            true) ||
        dis ||
        false;

    return (
        <AssignmentCardView
            workformsData={workformsData}
            isDisabled={isDisabled}
            config={config}
            onSaveChoice={handleSaveChoice}
            error={error}
            elementResult={elementResult}
            loading={loading || elementResultLoading}
            onReset={handleReset}
            boxId={boxId}
        />
    );
};

export default compose(
    connect(mapStateToProps, (dispatch) => ({
        changeColor: (styleSheet) => dispatch(changeColor(styleSheet)),
        processSuccess: (t) => {
            dispatch(
                showSnackbarMessage({
                    text: t("app.assignment-card.save-success"),
                    variant: 'success',
                })
            );
        },
        processFailure: (t) => {
            dispatch(
                showSnackbarMessage({
                    text: t("app.assignment-card.save-failed"),
                    variant: 'error',
                })
            );
        },
    })),
    withWorkforms()
)(AssignmentCard);
