import React, { useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import TitleAndTextBlock from "../Homepage/Blocks/TitleAndTextBlock";
import ShortTextBlock from "../Homepage/Blocks/ShortTextBlock";
import TitleAndTextImageBlock from "../Homepage/Blocks/TitleAndTextImageBlock";
import VideoWithTitleBlock from "../Homepage/Blocks/VideoWithTitleBlock";
import classNames from "classnames";
import ImageWithSubtitleBlock from "../Homepage/Blocks/ImageWithSubtitleBlock";
import { useQuery } from "@apollo/react-hooks";
import { GQL_FETCH_CMS_HOME_PAGE_BLOCKS_IDS, GQL_FETCH_CMS_HOME_PAGE_BLOCKS_ORDER } from './graphql';
import { useSetupReadSpeaker } from '@manakin/hooks';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(
    (theme) =>
        createStyles({
            root: {
                width: "100%",
                overflowX: "hidden",
            },
            largeWrapper: {
                [theme.breakpoints.up("md")]: {
                    ...theme.manakin.extraLargeWrapper,
                    paddingLeft: "16px",
                    paddingRight: "16px",
                },
            },
            normalMargin: {
                margin: "3rem 0",
                [theme.breakpoints.up("md")]: {
                    margin: "15rem 0",
                },
            },
			demoEnvironmentContainer: {
				justifyContent: "center",
				display: "flex",
				marginTop: theme.spacing(2),
				marginBottom: theme.spacing(2)
			}
        }),
    {
        name: "AppHomepage",
    },
);

const Homepage = (props) => {
    const classes = useStyles();
    const [ items, setItems ] = useState([]);
    const { i18n } = useTranslation();
    const { data: settingsData = {}, loading: settingsLoading } = useQuery(GQL_FETCH_CMS_HOME_PAGE_BLOCKS_ORDER);

    useQuery(GQL_FETCH_CMS_HOME_PAGE_BLOCKS_IDS, {
        skip: settingsLoading,
        variables: {
            pagination: {
                page: 0,
                pageSize: 99,
            },
        },
        onCompleted: (data) => {
            if (data && data.homepageBlocks) {
                const order = settingsData && settingsData.setting && settingsData.setting.value;
                const newItems = data.homepageBlocks.homepageBlocks.map(block => ({
                    ...block,
                    dragId: block.id,
                }));

                if (order) {
                    const customOrder = JSON.parse(order);
                    customOrder.forEach((orderItem, index) => {
                        if (newItems.findIndex(item => item.id === orderItem) === -1) {
                            // Item in order is no longer present, remove from list
                            customOrder.splice(index, 1);
                        }
                    });

                    setItems(
                        customOrder
                            .map(itemId => {
                                // Use order in settings and add the rest to the end of the list
                                const foundIndex = newItems.findIndex(item => item.id === itemId);
                                return foundIndex !== -1 ? newItems.splice(foundIndex, 1)[0] : null;
                            })
                            .filter(item => item)
                            .concat(newItems),
                    );
                } else {
                    setItems(newItems);
                }
            }
        },
    });

    //setup read speaker
    useSetupReadSpeaker();

    return (
        <div className={classes.root}>
            {items && items.map(item => (
                <div key={item.id} className={classes.normalMargin}>
                    <div className={classNames(classes.largeWrapper)} key={i18n.language}>
                        {item.type === "TitleAndText" && <TitleAndTextBlock id={item.id} />}
                        {item.type === "TitleAndTextImage" && <TitleAndTextImageBlock id={item.id} />}
                        {item.type === "ImageWithSubtitle" && <ImageWithSubtitleBlock id={item.id} />}
                        {item.type === "VideoWithTitle" && <VideoWithTitleBlock id={item.id} />}
                        {item.type === "ShortText" && <ShortTextBlock id={item.id} />}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Homepage;
