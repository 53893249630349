export const authDataForm = {
	email: {
		required: true,
		format: "email",
	},
	password: {
		format: "password",
		required: true,
	},
	secondEmail: {
		required: true,
		couple: "email",
		coupleLabel: "e-mailadres",
		format: "email",
	},
	secondPassword: {
		required: true,
		couple: "password",
		coupleLabel: "wachtwoord",
		format: "password",
	},
};
