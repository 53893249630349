import React, { useState } from 'react';
import UiContext from '../UiContext';

const defaultSnackBarState = {
	current: undefined,
	open: false,
	queue: []
}

const UiProvider = props => {
	const [ snackBarState, setSnackBarState ] = useState(defaultSnackBarState);

	const showSnackBar = (text: string, variant: string = "success", duration: number = 6000) => {
		const message = {
			key: new Date().getTime(),
			batchCount: 1,
			text,
			variant,
			duration
		};

		if (snackBarState.open) {
			// SnackBar still open, duplicate messages might come in

			if (snackBarState.queue.length === 0 &&
				snackBarState.current &&
				snackBarState.current.variant === message.variant &&
				snackBarState.current.text === message.text
			) {
				// Same message that is currently being displayed, just up the count
				setSnackBarState({
					...snackBarState,
					current: {
						...snackBarState.current,
						batchCount: snackBarState.current.batchCount + 1
					}
				});
			} else {
				let found = false;
				const newQueue = [ ...snackBarState.queue ]
					.filter(item => item)
					.map(item => {
						console.log(item)
						// If the same message already exists, increase the batchCount
						if (item.variant === message.variant && item.text === message.text) {
							found = true;
							return {
								...item,
								batchCount: item.batchCount + 1
							}
						}

						return item
					});

				// If no similar message is found, it means it's new and can be added at the back of the queue
				if (!found) {
					newQueue.push(message);
				}

				setSnackBarState({
					...snackBarState,
					queue: newQueue
				});
			}
		} else {
			setSnackBarState({
				current: message,
				open: true,
				queue: []
			})
		}
	};

	const closeSnackBar = () => {
		const newQueue = [ ...snackBarState.queue ];
		const newMessage = newQueue.shift();

		setSnackBarState({
			current: newMessage,
			queue: newQueue,
			open: newMessage !== undefined
		});
	}

	return (
		<UiContext.Provider value={{
			...props.ui,
			snackBar: snackBarState.current,
			snackBarOpen: snackBarState.open,
			showSnackBar,
			closeSnackBar,
			setSnackBarState
		}}>
			{props.children}
		</UiContext.Provider>
	);
};

export default UiProvider;