import React from 'react';
import Paper from '../Paper';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ConfirmInvitationForm from "./ConfirmInvitationForm";
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(
    (theme) =>
        createStyles({
            root: {}
        }),
    {
        name: 'CmsConfirmInvitation',
    }
);

const CmsConfirmInvitation = props => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Paper className={classes.root}>
            <Typography variant="h5" component="h1">
                {t("authentication.confirm-invitation.title")}
            </Typography>

            <ConfirmInvitationForm/>
        </Paper>
    );
};

export default CmsConfirmInvitation;
