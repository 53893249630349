import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Header from './parts/Header';
import ElementsLayout from './parts/ElementsLayout';
import {
	GQL_FETCH_CMS_BOX,
	GQL_FETCH_ELEMENT_RESULTS,
	GQL_FETCH_BOX_RESULTS,
	GQL_UPDATE_USER
} from './graphql/graphql';
import { getProgram } from '@manakin/app-core/ProgramsDropdown/selectors';
import { getAppUser } from '@manakin/authentication/selectors';
import { setAppBarBackgroundColor, setBoxId } from '@manakin/app-core/actions';
import { Loader } from '@manakin/app-core';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { setAppUser } from '@manakin/authentication/actions';
import moment from 'moment';
import { managementRole, trainerRoles } from '@manakin/app-core/lib';
import { useTranslation } from 'react-i18next';
import { selectProgram } from '@manakin/app-core/ProgramsDropdown/actions';
import CreateBoxResultDialog from './parts/CreateBoxResultDialog';
import { richTextEmpty } from "@manakin/core/lib";
import { cbdFunctions } from '@manakin/app-core/constants';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				position: 'relative',
			},
			appBar: {},
			appBarBackground: {},
			appBarBackgroundWithImage: {},
			loader: {
				backgroundColor: 'white',
				position: 'absolute',
				width: '100vw',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
			},
			footerLogo: {},
		}),
	{
		name: 'AppBox',
	}
);

const Box = (props) => {
	//props
	const { match, location, boxId } = props;
	const classes = useStyles();
	const history = useHistory();
	const params = useParams();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	//selectors
	const appUser = useSelector((state) => getAppUser(state));
	const program = useSelector((state) => getProgram(state));
	const config = useSelector((state) => state.config) || {};

	//dispatch functions
	const onSetAppBarColor = useCallback((color) => dispatch(setAppBarBackgroundColor(color)), [dispatch]);
	const onSetBoxId = useCallback((id) => dispatch(setBoxId(id)), [dispatch]);
	const onSelectProgram = useCallback((program) => dispatch(selectProgram(program)), [dispatch]);
	const processSuccess = useCallback((redirect) => dispatch(setAppUser(redirect)), [dispatch]);

	//mutations
	const [mutate, { data: mutationData }] = useMutation(GQL_UPDATE_USER);

	//queries
	const { data = {}, loading } = useQuery(GQL_FETCH_CMS_BOX, {
		variables: {
			id: params.boxid,
		},
	});

	const boxResultsData = useQuery(GQL_FETCH_BOX_RESULTS, {
		variables: {
			box: boxId || params.boxid,
			program: program,
			user: appUser.id,
		},
	});

	const boxResults = useMemo(() => {
		if (!boxResultsData.loading) {
			if (boxResultsData.data && boxResultsData.data.boxResults && boxResultsData.data.boxResults[0]) {
				return boxResultsData.data.boxResults[0];
			}
			return null;
		}
	}, [boxResultsData, boxResultsData.loading]);

	const { data: elementResultsQuery = {} } = useQuery(GQL_FETCH_ELEMENT_RESULTS, {
		variables: {
			box: boxId || params.boxid,
			program: program,
			user: appUser.id,
		},
	});
	const { box = {} } = data;
	const { elements: elementsData = [] } = box;
	const { elementResults: elementResultsData = [] } = elementResultsQuery;

	const noAccessToBox =
		(appUser && appUser.userDataCbd && appUser.userDataCbd.function === cbdFunctions.ASSISTENT_DROGIST && box.yearProgress !== null) ||
		false;
		
	//state hooks
	const [openDialog, setOpenDialog] = useState(false);

	//effect hooks
	useEffect(() => {
		if (mutationData) {
			processSuccess(mutationData.updateAppUser.user);
		}
	}, [processSuccess, mutationData]);

	useEffect(() => {
		if (match.params.boxid && program && appUser && appUser.id) {
			const time = moment().unix();
			mutate({
				variables: {
					id: appUser.id,
					programId: program,
					boxId: noAccessToBox ? null : match.params.boxid,
					elementId: time,
				},
			});
		}

		if (location && location.state && location.state.fromProgramDashboard !== undefined) {
			sessionStorage.setItem("fromProgramDashboard", location.state.fromProgramDashboard);
		}
	}, []);

	useEffect(() => {
		onSetBoxId(params.boxid);

		if (params.programId) {
			const { licenses = [] } = appUser;
			let hasLicense = false;
			licenses.forEach((license) => {
				if (
					license.licenseGroup &&
					license.licenseGroup.product &&
					license.licenseGroup.product.program &&
					license.licenseGroup.product.program.id
				) {
					if (license.licenseGroup.product.program.id === params.programId) {
						hasLicense = true;
					}
				}
			});
			if (hasLicense) onSelectProgram(params.programId);
			else history.push('/');
		}
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
		if (!loading) {
			let appBarColor = 'secondary';
			if (box.backgroundImage && box.backgroundImage.url) {
				appBarColor = 'light';
			}
			onSetAppBarColor({
				color: appBarColor,
				path: location.pathname,
				className: classes.appBar,
				backgroundClassName: classes.appBarBackground,
			});
			const check = Cookies.get(`box${program}${appUser.id}popup`);
			let arr = [];
			if (config.pages && config.pages.box && config.pages.box.showCompletePopup && !check) {
				config.pages.box.showCompletePopup.forEach((item, idx) => {
					arr = arr.concat(box.elements.filter((i) => i.type == item));
					if (idx == config.pages.box.showCompletePopup.length - 1) handleArr(arr);
				});
			}
		}
	}, [loading, data]);

	const handleArr = (arr) => {
		const _arr = arr.filter((i) => !i.correct);

		if (!_arr.length) {
			setOpenDialog(true);
		}
	};

	const handleClose = () => {
		setOpenDialog(false);
	};

	if (appUser.roles.some((role) => trainerRoles.includes(role.name) || managementRole.includes(role.name)))
		return <Redirect to="/progress-dashboard" />;
	if (loading) {
		return <Loader fullScreen />;
	}

	if (noAccessToBox) return <Redirect to="/dashboard" />

	return (
		<div className={classes.root}>
			<Header 
				title={richTextEmpty(box.appTitle) || box.htmlName}
				description={box.description || ''}
				boxResults={boxResults}
				config={config}
				loading={loading}
				backgroundImage={box.backgroundImage || {}}
				headerTitleColor={box.headerTitleColor}
			/>
			<ElementsLayout
				items={elementsData}
				boxResults={boxResults}
				elementResults={elementResultsData}
				boxId={params.boxid}
			/>
			<CreateBoxResultDialog open={openDialog} onClose={handleClose} boxData={box} appUser={appUser} program={program} />
			{config.pages && config.pages.appLogin && config.pages.appLogin.footerLogo && <div className={classes.footerLogo} />}
		</div>
	);
};

export default Box;
