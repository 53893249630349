import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
    (theme) =>
        createStyles({
            root: {
                padding: '80px 0 0',
                backgroundColor: theme.manakin.primaryColor[500],
                height: '100vh',
                display: 'flex',
            },
            stepperRoot: {
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
            },
            stepRoot: {
                flex: '0 0 auto',
            },
            container: {
                display: 'flex',
                flexWrap: 'wrap',
                width: '100%',
                maxWidth: '900px',
                alignItems: 'center',
                margin: '0 auto',
                alignSelf: 'center',
                [theme.breakpoints.down('sm')]: {
                    width: '80%',
                },
            },
            buttonGroup: {
                zIndex: 0,
                textAlign: 'center',
                width: '100%',
                '& a': {
                    textDecoration: 'none',
                },
            },
            buttonRoot: {
                display: 'block',
                margin: '0 auto',
            },
            content: {
                width: '100%',
                margin: '0 0 80px',
            },
            regiatrationAppear: {
                display: 'block',
            },
            registrationEnter: {
                opacity: 0,
                position: 'relative',
                transform: 'translateX(100px)',
            },
            registrationEnterActive: {
                opacity: 1,
                transform: 'translateX(0)',
            },
            paragraph: {
                textAlign: 'center',
                width: '100%',
                margin: '0 auto 3em',
                maxWidth: '560px',
                color: theme.palette.getContrastText(
                    theme.manakin.primaryColor[500]
                ),
            },
            smallParagraph: {
                textAlign: 'center',
                width: '100%',
                margin: '0 auto',
                marginBottom: '0.5em',
                maxWidth: '560px',
                color: theme.palette.getContrastText(
                    theme.manakin.primaryColor[500]
                ),
            },
            Link: {
                textDecoration: 'none',
            },
            heading: {
                color: theme.palette.getContrastText(
                    theme.manakin.primaryColor[500]
                ),
            },
        }),
    {
        name: 'AppRegistrationSuccess',
    }
);
