'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _appWorkforms = require('@manakin/app-workforms');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ImageWithFocusPoint = function ImageWithFocusPoint(props) {
	var asset = props.asset,
	    imageProps = props.imageProps;

	var _ref = asset || {},
	    focusPointX = _ref.focusPointX,
	    focusPointY = _ref.focusPointY,
	    fit = _ref.fit;

	var style = (0, _react.useMemo)(function () {
		var style = {};
		if (focusPointX !== undefined && focusPointY !== undefined) {
			style.objectPosition = focusPointX + '% ' + focusPointY + '%';
		}

		if (fit !== undefined) {
			if ((0, _appWorkforms.isContain)(fit, false)) {
				style.objectFit = 'contain';
				style.fontFamily = "'object-fit: contain'";
				style.objectPosition = 'center';
			}
		}

		return style;
	}, [focusPointX, focusPointY]);

	return _react2.default.createElement('img', _extends({}, imageProps, { className: (0, _classnames2.default)(imageProps && imageProps.className), style: style, src: asset && asset.url || '' }));
};

exports.default = ImageWithFocusPoint;