import { makeStyles, createStyles } from '@material-ui/core/styles';

export default (props = {}) => {
	return makeStyles(
		(theme) => {
			const contrast = props.loginBackgroundColor
				? theme.palette.getContrastText(props.loginBackgroundColor)
				: theme.manakin.homeLinkColor ? theme.manakin.homeLinkColor[500] : "";
			return createStyles({
				root: {
					display: 'flex',
					flexFlow: 'column nowrap',
				},
				submit: {
					marginTop: theme.spacing(2),
				},
				progress: {
					margin: `${theme.spacing(4)}px auto`,
					display: 'block',
					width: 25,
					height: 25,
					color: 'white',
				},
				nativeInput: {},
				inputRoot: {},
				input: {
					[contrast && 'color']: contrast,
					[contrast && '&:before']: {
						borderColor: contrast,
					},
					[contrast && '&:after']: {
						borderColor: contrast,
					},
					[contrast && '&:hover']: {
						'&:before': {
							borderColor: contrast,
						},
						'&:after': {
							borderColor: contrast,
						},
					},
				},
				label: {
					[contrast && 'color']: contrast,
				},
				adornment: {
					[contrast && 'color']: contrast,
				},
			});
		},
		{
			name: 'AuthenticationLoginForm',
		}
	);
};
