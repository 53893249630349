import React from 'react';
import { SelectImageField } from '@manakin/core';
import Button from '@material-ui/core/Button';
import Hotspot from './Hotspot';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles((theme) =>
    createStyles({
        uploadBox: {
            position: 'relative',
        },
        hotspot: {
            position: 'absolute',
            width: '10px',
            height: '10px',
            backgroundColor: 'red',
            zIndex: '2',
        },
        image: {
            width: '100%',
            '& img': {
                width: '100%',
            },
        },
        hint: {
            display: 'flex',
            marginBottom: 16,
            justifyContent: 'center',
            minHeight: 24,
        },
        hintIcon: {
            marginRight: 16,
        },
    }),
);

const ImageMapper = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const {
        onClick,
        image = null,
        hint,
        onImageClick,
        hotspots = {},
        onChange,
        onDelete,
        onHotspotClick,
        onMove,
        onClose,
        form,
    } = props;

    return (
        <div className={classes.root}>
            <Button color='primary' onClick={onClick}>
                {t('common.fields.image.label')}
            </Button>

            <SelectImageField
                form={form}
                name='hotspotImagePreview'
                label={t('common.fields.image.label')}
                initialValue={image || ''}
            />
            <div className={classes.hint}>
                {/* Always show hint container so there will be no UI height changes */}
                {hint && (
                    <React.Fragment>
                        <InfoIcon className={classes.hintIcon} />
                        <Typography variant='body1'>{hint || ''}</Typography>
                    </React.Fragment>
                )}
            </div>

            <div className={classes.uploadBox}>
                {image && (
                    <React.Fragment>
                        {Object.keys(hotspots).map((key) => (
                            <Hotspot
                                hotspot={hotspots[key]}
                                id={key}
                                key={key}
                                onHotspotClick={onHotspotClick}
                                onMove={onMove}
                                onClose={onClose}
                                onChange={onChange}
                                onDelete={onDelete}
                            />
                        ))}
                        <div className={classes.image}>
                            <img src={image.url} onClick={onImageClick} />
                        </div>
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};

export default ImageMapper;
