import React from 'react';
import OrderQuestion from './OrderQuestion';
import shuffle from 'shuffle-array';

class OrderQuestionContainer extends React.PureComponent {
	state = {
		animationSettings: { gap: false },
		buttonDisabled: false,
		correctAnswers: [],
	};

	componentDidMount() {
		this.setStartState();
	}

	componentDidUpdate(prevProps) {
		if ((!prevProps.data.done && this.props.data.done) || prevProps.data.id != this.props.data.id) {
			this.setStartState();
			this.setState({ buttonDisabled: false });
		}
	}

	setStartState = () => {
		const { data, options = {} } = this.props;
		const _answers = data.answers ? [...data.answers] : [];
		const answers = data.finished ? [..._answers] : shuffle(_answers);

		this.setState({
			...this.props.data,
			answers: answers,
			animateNext: '',
			animatePrev: -1,
			options: options,
			correct: data.finished ? data.correct : this.checkOrder(answers),
			feedback: data.finished && options && options.showFeedback ? true : false,
			done: data.finished,
		});
	};

	swapItems = (_array, a, b) => {
		let array = [..._array];
		let temp = array[a];

		array[a] = array[b];
		array[b] = temp;
		return array;
	};

	handleOnMoveDown = (idx, id) => {
		const { answers: _answers, correctAnswer, correctAnswers } = this.state;
		if (idx === _answers.length - 1 || this.state.finished) return;
		let index = [];

		_answers.forEach((answer, i) => {
			if (!correctAnswers.some((item) => item.id === answer.id) && i > idx) {
				index.push(i);
			}
		});

		if (index[0] < _answers.length) {
			const answers = this.swapItems(_answers, idx, index[0]);
			this.setMoveState({
				answers: answers,
				animatePrev: id,
				animateNext: _answers[index[0]].id,
				settings: {
					gap: index[0] - idx,
				},
			});
		}
	};

	handleOnMoveUp = (idx, id) => {
		if (idx === 0 || this.state.finished) return;
		const { answers: _answers, correctAnswer, correctAnswers } = this.state;
		let index = [];

		_answers.forEach((answer, i) => {
			if (!correctAnswers.some((item) => item.id === answer.id) && i < idx) {
				index.push(i);
			}
		});

		if (index[index.length - 1] >= 0 || index.length === 0) {
			const answers = this.swapItems(_answers, idx, index[index.length - 1]);
			this.setMoveState({
				answers: answers,
				animateNext: id,
				animatePrev: _answers[index[index.length - 1]].id,
				settings: {
					gap: idx - index[index.length - 1],
				},
			});
		}
	};

	setMoveState = (obj) => {
		this.setState(
			{
				animateNext: obj.animateNext,
				animatePrev: obj.animatePrev,
				animationSettings: { ...obj.settings },
			},
			() => {
				setTimeout(() => {
					this.setState({
						answers: obj.answers,
						animateNext: null,
						animatePrev: null,
						correct: this.checkOrder(obj.answers),
					});
				}, 300);
			}
		);
	};

	checkOrder = (answers) => {
		const { data } = this.props;
		let correct = true;

		answers.forEach((answer, idx) => {
			if (answer.id != data.answers[idx].id) correct = false;
		});
		return correct;
	};

	getWrongAnswers = () => {
		const { data } = this.props;
		const { answers } = this.state;
		return answers.filter((answer, index) => answer.id != data.answers[index].id);
	};

	setOneCorrect = () => {
		const { data } = this.props;
		const { answers: selectedAnswers } = this.state;
		let correctAnswer = null;
		let _correctAnswers = [];
		let wrongAnswers = [];

		selectedAnswers.forEach((answer, idx) => {
			if (answer.id == data.answers[idx].id) {
				_correctAnswers.push(answer);
				correctAnswer = answer.id;
			} else {
				wrongAnswers.push(answer);
			}
		});

		const correct = this.checkOrder(selectedAnswers);

		if (!correct) {
			const random = Math.floor(Math.random() * wrongAnswers.length);
			const freeAnswerId = wrongAnswers[random].id;
			delete wrongAnswers[random];
			let selectedAnswerIndex;
			let freeAnswerIndex = null;

			selectedAnswers.forEach((answer, i) => {
				if (answer.id == freeAnswerId) {
					// Add one random answer to the correct answers list
					selectedAnswerIndex = i;
					_correctAnswers.push(answer);
				}
			});

			data.answers.forEach((answer, i) => {
				if (answer.id === freeAnswerId) {
					freeAnswerIndex = i;
				}
			});

			const arr = this.swapItems(selectedAnswers, selectedAnswerIndex, freeAnswerIndex);

			this.setState({
				answers: arr,
				correctAnswer: correctAnswer,
				correctAnswers: _correctAnswers,
				correct: wrongAnswers.length === 0,
			});
		} else {
			selectedAnswers.forEach((item, i) => {
				_correctAnswers.push(item);
			});

			this.setState({
				correctAnswer: correctAnswer,
				correctAnswers: _correctAnswers,
				correct: this.checkOrder(selectedAnswers),
			});
		}
	};

	handleSubmit = () => {
		const { options = {}, data } = this.props;
		const { correct } = this.state;

		if (options && options.showFeedback) {
			this.setState({ feedback: true, options: options });
			data.hintText && !correct && this.setOneCorrect();
		} else {
			this.setState({ buttonDisabled: true }, this.handleFinish);
		}
	};

	handleFinish = () => {
		if (!this.state.correct && this.getWrongAnswers().length === 0) {
			// If the status is not correct, but there are no remaining wrong answers, assume everything is correct but not checked
			return this.setState(
				{
					correctAnswers: [...this.state.answers],
					correct: this.checkOrder(this.state.answers),
					done: this.checkOrder(this.state.answers),
				},
				() => this.handleFinish()
			);
		}

		this.props.onFinished({
			correct: this.state.correct,
			workform: { ...this.state },
		});
	};

	render() {
		const { saving } = this.props;

		return (
			<OrderQuestion
				data={{ ...this.state }}
				saving={saving}
				realAnswers={this.props.data.answers}
				onMoveDown={this.handleOnMoveDown}
				onMoveUp={this.handleOnMoveUp}
				onSubmit={this.handleSubmit}
				onFinished={this.handleFinish}
				disabled={this.state.buttonDisabled}
			/>
		);
	}
}

export default OrderQuestionContainer;
