import React from 'react';
import {
    Dialog,
    DialogContent,
    Typography,
    withStyles,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
    popupRoot: {
        width: '100%',
        padding: '50px!important',
        [theme.breakpoints.up('md')]: {
            padding: '0 0 5rem!important',
            minWidth: '50rem',
        },
    },
    dialogPaper: {
        padding: 0,
        width: '100%',
        fontSize: '2.2rem',
        position: 'relative',
    },
    heading: {
        width: '100%',
        minHeight: '9rem',
    },
    nextCategory: {
        color: theme.manakin.defaultContentColor[400],
        fontSize: '2.2rem',
        fontWeight: 600,
    },
    title: {
        fontSize: '3rem',
        fontWeight: 'bold',
        color: theme.manakin.defaultContentColor[500],
        textAlign: 'left',
        [theme.breakpoints.up('md')]: {
            fontSize: '5rem',
            lineHeight: '6.4rem',
        },
    },
    body: {
        textAlign: 'center',
    },
    points: {
        textAlign: 'center',
        color: theme.palette.primary[500],
        fontSize: '2.2rem',
        marginBottom: '3.9rem',
    },
    slider: {
        marginBottom: '50px',
    },
});

const RowBonusPopup = (props) => {
    const { open = false, classes, points = 0 } = props;
    const { t } = useTranslation();

    //functions
    const handleClick = () => {
        props.onClose();
    };

    return (
        <div>
            <Dialog open={open} onClose={props.onClose}>
                <DialogContent className={classes.popupRoot}>
                    <div className={classes.heading}>
                        <Typography variant="body2" className={classes.title}>
                            {t("app.elements.betting-game.time-bonus-dialog.title")}
                        </Typography>
                        <Typography variant="body2" className={classes.body}>
                            {parseInt(points) > 0
                                ? t("app.elements.betting-game.time-bonus-dialog.description-time-bonus")
                                : t("app.elements.betting-game.time-bonus-dialog.description-no-time-bonus")}
                        </Typography>
                        <Typography variant="body2" className={classes.points}>
                            {t("app.elements.betting-game.time-bonus-dialog.points", { points: points })}
                        </Typography>
                    </div>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        className={classes.button}
                        onClick={handleClick}
                    >
                        {t("app.elements.betting-game.time-bonus-dialog.action")}
                    </Button>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default compose(
    withStyles(styles)
)(RowBonusPopup);
