import { makeStyles, createStyles } from '@material-ui/core';
import React from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useTranslation } from 'react-i18next';
import { ApiKeys } from '@manakin/core';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			downloadReport: {
				paddingTop: theme.spacing(2.5),
				display: 'flex',
				alignItems: 'center',
				cursor: 'pointer',
				justifyContent: 'flex-end',
				'&:hover': {
					textDecoration: 'underline',
				},
			},
			downloadReportIcon: {
				marginLeft: theme.spacing(1),
			},
		}),
	{
		name: 'AppCbdExportUsersManagerButton',
	}
);

const CbdExportUsersManagerButton = () => {
	const { t } = useTranslation();
	const classes = useStyles();

	const app_url = process.env.REACT_APP_APP_URL || 'http://localhost:3000';

	const handleClick = () => {
		window.open(`${app_url}${ApiKeys.cbdExportUsersManager}`);
	}

	return (
		<div className={classes.downloadReport} onClick={handleClick}>
			{t('app.report.class-list.export-cbd-users')}
			<GetAppIcon className={classes.downloadReportIcon} />
		</div>
	);
};

export default CbdExportUsersManagerButton;
