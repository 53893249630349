import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { compose } from 'recompose';
import renderHTML from 'react-render-html';
import CriteriaItem from './CriteriaItem';
import { useReadSpeaker } from "@manakin/hooks";

const styles = (theme) => ({
    wrapper: {
        ...theme.manakin.smallWrapper,
        maxWidth: '800px',
        [theme.breakpoints.up('md')]: {
            minHeight: '240px',
        },
    },
    compactHeader: {
        marginBottom: '1rem',
    },
    subtitle: {
        fontWeight: 700,
        [theme.breakpoints.up('md')]: {
            marginBottom: '32px',
        },
    },
});

const Introduction = (props) => {
    const { classes, data } = props;
    const { readSpeaker, readSpeakerId } = useReadSpeaker("readSpeaker-introduction-" + data.id);

    if (!data.id) return '';

    return (
        <div className={classes.root} id={readSpeakerId}>
            <div className={classes.wrapper}>
                {readSpeaker && renderHTML(readSpeaker)}

                <Typography
                    component="p"
                    variant="subtitle1"
                    className={classes.subtitle}
                >
                    {data.title}
                </Typography>

                {data.criteriaItems &&
                    data.criteriaItems.map((criteria, index) => (
                        <CriteriaItem
                            key={`${criteria}${index}`}
                            number={index + 1}
                            text={criteria}
                        />
                    ))}
            </div>
        </div>
    );
};

export default compose(withStyles(styles, { name: 'AppCriteria' }))(
    Introduction
);
