'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.getStylesheet = exports.SELECT_CHANGE_COLOR_ROOT = undefined;

var _reselect = require('reselect');

var _selectors = require('../selectors');

var SELECT_CHANGE_COLOR_ROOT = exports.SELECT_CHANGE_COLOR_ROOT = 'changeColor';

var getState = (0, _reselect.createSelector)([_selectors.getCoreState], function (coreState) {
    return coreState[SELECT_CHANGE_COLOR_ROOT];
});

var getStylesheet = exports.getStylesheet = (0, _reselect.createSelector)([getState], function (state) {
    return state.styleSheet;
});