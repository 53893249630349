import React, { useEffect, useState } from "react";
import { Dropable } from "@manakin/core";
import { Draggable } from "react-beautiful-dnd";
import { MultiBoxCard, SingleBoxCard } from "@manakin/app-core";
import MoveIcon from "@material-ui/icons/OpenWith";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { GQL_FETCH_SCHOOL_CLASS_PROGRAM, GQL_UPDATE_SCHOOL_CLASS_PROGRAM } from "./graphql";
import { useMutation, useQuery } from "@apollo/react-hooks";
import MilestoneDialog from "./MilestoneDialog/MilestoneDialog";
import { useSnackBar } from "@manakin/hooks";
import { createStyles, makeStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import {saveAppBar} from '@manakin/app-core/AppBar/actions';
import {getSave} from '@manakin/app-core/AppBar/selectors';


const useStyles = makeStyles(
    (theme) =>
        createStyles({
            root: {
                paddingBottom: "10rem",
            },
            wrapper: {
                ...theme.manakin.defaultWrapper,
                maxWidth: "800px",
            },
            move: {
                cursor: "move",
            },
            boxCardRoot: {
                position: "relative",
                width: "100%",
            },
            mileStone: {
                position: "absolute",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: theme.manakin.defaultTitleFont,
                zIndex: 1,
                fontSize: "12px",
                letterSpacing: ".4rem",
                lineHeight: "2rem",
                textAlign: "center",
                top: "-3rem",
                opacity: 0,
                transition: "opacity .2s",
                cursor: "pointer",
                textTransform: "uppercase",
                "&:after": {
                    content: "''",
                    width: "100%",
                    zIndex: -1,
                    height: "1px",
                    backgroundColor: "#000",
                    position: "absolute",
                    left: 0,
                    top: "50%",
                },
                "&:hover": {
                    opacity: 1,
                },
            },
            activeMileStone: {
                opacity: 0.2,
            },
            mileStoneText: {
                backgroundColor: theme.manakin.defaultContentHover[500],
                color: theme.palette.getContrastText(
                    theme.manakin.defaultContentHover[500],
                ),
                padding: "0 3rem",
            },
        }),
    {
        name: "appProgramOrder",
    },
);

const ProgramOrder = (props) => {
    const { schoolClass, disable = false, program } = props;
    const classes = useStyles();
    const snackBar = useSnackBar();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [ boxes, setBoxes ] = useState([]);
    const [ milestones, setMilestones ] = useState({});
    const [ milestone, setMilestone ] = useState({});
    const [ milestoneDialogOpen, setMilestoneDialogOpen ] = useState(false);
    const isSave = useSelector((state) => getSave(state));

    const [ updateSchoolClassProgram ] = useMutation(GQL_UPDATE_SCHOOL_CLASS_PROGRAM);
    const { data: schoolClassProgramData = {}, loading: schoolClassProgramLoading } = useQuery(GQL_FETCH_SCHOOL_CLASS_PROGRAM, {
        fetchPolicy: "no-cache",
        variables: {
            programId: program.id || 0,
            schoolClassId: schoolClass.id || 0,
        },
    });
    const { schoolClassProgram } = schoolClassProgramData || {};

    useEffect(() => {
        if (isSave === "teacherProgram") {
            handleSave();
        }
    }, [isSave]);

    useEffect(() => {
        if (!schoolClassProgramLoading && schoolClass && schoolClassProgram) {
            const { milestones } = schoolClassProgram;

            const newMileStones = {};
            milestones.forEach(milestone => newMileStones[milestone.box.id] = milestone);
            setMilestones(newMileStones);

            if (program && program.boxes) {
                if (schoolClassProgram.boxes && schoolClassProgram.boxes.length) {
                    setBoxes(schoolClassProgram.boxes);
                } else {
                    setBoxes(program.boxes);
                }
            }
        }
    }, [ program.id, schoolClass, schoolClassProgram ]);

    const handleMilestoneClick = (data) => {
        if (data[0].id) {
            setMilestone(data[0].id);
            setMilestoneDialogOpen(true);
        }
    };
    const handleMilestone = (data) => {
        setMilestones({
            ...milestones,
            [data.id]: { text: data.text, id: data.id },
        });
    };

    const handleSetItems = (_items) => {
        setBoxes([ ..._items ]);
    };

    const handleSave = () => {
        // Acknowledge save action
        dispatch(saveAppBar(""));

        let _boxes = [];
        boxes.forEach((boxList) => _boxes.push(boxList.map((box) => box.id)));

        const _milestones = Object.keys(milestones).map((key) => ({
            box: key,
            text: milestones[key].text,
        })).filter(item => item.text);

        updateSchoolClassProgram({
            variables: {
                input: {
                    boxes: _boxes,
                    milestones: [ ..._milestones ],
                    program: program.id,
                    schoolClass: schoolClass ? schoolClass.id || null : null,
                },
            },
        }).then(result => {
            if (!result.errors) {
                snackBar.success(t("app.teacher-program.save-success"));
            } else {
                snackBar.error(t("app.teacher-program.save-failed"));
            }
        });
    };

    return (
        <div className={classes.root}>
            <MilestoneDialog
                mileStone={milestone}
                mileStones={milestones}
                open={milestoneDialogOpen}
                onClose={() => setMilestoneDialogOpen(false)}
                onSave={handleMilestone} />

            <Dropable custom={true} items={boxes} onSetItems={handleSetItems}>
                {(provided) => (
                    <React.Fragment>
                        {boxes && boxes.map((boxContainer, i) => (
                            <Draggable
                                draggableId={boxContainer[0].id}
                                index={i}
                                key={boxContainer[0].id}>
                                {(provided) => (
                                    <div
                                        className={classes.boxCardRoot}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <div
                                            className={classNames(
                                                classes.mileStone,
                                                { [classes.activeMileStone]: milestones[boxContainer[0].id] && milestones[boxContainer[0].id].text },
                                            )}
                                            onClick={() => handleMilestoneClick(boxContainer)}>
                                                <span className={classes.mileStoneText}>
                                                    {milestones[boxContainer[0].id]
                                                        ? milestones[boxContainer[0].id].text ||
                                                        t("app-core.program-order.add-period")
                                                        : t("app-core.program-order.add-period")}
                                                </span>
                                        </div>
                                        {boxContainer.length === 1 ? (
                                            <div className={classes.wrapper}>
                                                <SingleBoxCard data={boxContainer[0]}>
                                                    <div className={classes.move}>
                                                        <MoveIcon />
                                                    </div>
                                                </SingleBoxCard>
                                            </div>
                                        ) : (
                                            <div className={classes.smallWrapper}>
                                                <MultiBoxCard
                                                    disable={disable}
                                                    data={boxContainer}
                                                >
                                                    <div className={classes.move}>
                                                        <MoveIcon />
                                                    </div>
                                                </MultiBoxCard>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </Draggable>
                        ))}
                    </React.Fragment>
                )}
            </Dropable>
        </div>
    );
};

export default ProgramOrder;
