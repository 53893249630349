import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AccessControl, StyledDialog } from '@manakin/app-core';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { trainerRoles } from '@manakin/app-core/lib';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { useSnackBar } from '@manakin/hooks';
import { isSettingEnabled } from '@manakin/core/actions';
import { login, logoutNoRedirect, setUserType } from '@manakin/authentication/actions';
import { GQL_CREATE_DEMO_USER, GQL_FETCH_DEMO_ENVIRONMENT_ENABLED } from '../graphql';
import { SettingsKeys } from '@manakin/core/lib/constants';
import { SettingAccessControl } from '@manakin/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import classNames from "classnames";

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
			},
			button: {
			},
			loginDemoButton: {
			},
		}),
	{
		name: 'AppLoginDemoButton', 
	}
);

const DemoButton = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const snackBar = useSnackBar();
	const classes = useStyles();
	const demoEnvironmentEnabled = isSettingEnabled(SettingsKeys.DEMO_ENVIRONMENT_ENABLED)
	const [ createDemoUser ] = useMutation(GQL_CREATE_DEMO_USER);

	const goToDemoEnvironment = () => {
		createDemoUser().then(result => {
			if (result.errors) {
				snackBar.error(t('app.progress-dashboard.demo.failed'));
			} else {
				dispatch(logoutNoRedirect());
				dispatch(login('/', result.data.createDemoAppUser));
				dispatch(setUserType('app'));
			}
		});
	};

	if (!demoEnvironmentEnabled) return (<React.Fragment />);

	return (
		<SettingAccessControl name={SettingsKeys.DEMO_BUTTON_ON_LOGIN_PAGE_ENABLED}>
			<div className={classes.root}>
				<Button
					variant="outlined" color="primary" className={classNames(classes.button, classes.loginDemoButton)} fullWidth
					onClick={goToDemoEnvironment}
				>
					{t('authentication.login.demo')}
				</Button>
				
			</div>
		</SettingAccessControl>
	);
};

export default DemoButton;
