import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AccessControl, StyledDialog } from '@manakin/app-core';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { trainerRoles } from '@manakin/app-core/lib';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { useSnackBar } from '@manakin/hooks';
import { login, logoutNoRedirect, setUserType } from '@manakin/authentication/actions';
import { GQL_CREATE_DEMO_USER, GQL_FETCH_DEMO_ENVIRONMENT_ENABLED } from '../graphql';

import { makeStyles, createStyles } from '@material-ui/styles';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				justifyContent: 'center',
				display: 'flex',
				marginRight: 8
			},
			button: {
				border: '1px solid ' + theme.manakin.defaultBorderColor[500],
				borderRadius: 0,
				backgroundColor: 'transparent',
				padding: '15px',
				minHeight: 0
			}
		}),
	{
		name: 'AppDemoButton',
	}
);


const DemoButton = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const snackBar = useSnackBar();
	const classes = useStyles();
	const [ showDemoDialog, setShowDemoDialog ] = useState(false);

	const { data: demoData = {} } = useQuery(GQL_FETCH_DEMO_ENVIRONMENT_ENABLED);
	const demoEnvironmentEnabled = (demoData && demoData.setting && demoData.setting.value === 'true') || false;
	const [ createDemoUser ] = useMutation(GQL_CREATE_DEMO_USER);

	const goToDemoEnvironment = () => {
		createDemoUser().then(result => {
			if (result.errors) {
				snackBar.error(t('app.progress-dashboard.demo.failed'));
			} else {
				dispatch(logoutNoRedirect());
				dispatch(login('/', result.data.createDemoAppUser));
				dispatch(setUserType('app'));
			}
		});
	};

	if (!demoEnvironmentEnabled) return (<React.Fragment />);

	return (
		<AccessControl role={trainerRoles}>
			<div className={classes.root}>
				<Button
					color='primary'
					className={classes.button}
					onClick={() => setShowDemoDialog(true)}
				>
					{t('app.progress-dashboard.demo.action')}
				</Button>
				<StyledDialog
					title={t('app.progress-dashboard.demo.dialog.title')}
					contentText={t('app.progress-dashboard.demo.dialog.description')}
					open={showDemoDialog}
					onClose={() => setShowDemoDialog(false)}
					positiveButtonText={t('app.progress-dashboard.demo.dialog.positive-action')}
					onPositiveButtonClick={goToDemoEnvironment}
					onNegativeButtonText={t('app.progress-dashboard.demo.dialog.negative-action')} />
			</div>
		</AccessControl>
	);
};

export default DemoButton;
