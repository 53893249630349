import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(
    (theme) =>
        createStyles({
            root: {
                paddingTop: '6rem',
                minHeight: '100%',
                width: '100vw',
                height: '100vh',
                backgroundColor: theme.manakin.primaryColor
                    ? theme.manakin.primaryColor[500]
                    : 'white',
            },
            heading: {
                textAlign: 'center',
            },
            paragraph: {
                textAlign: 'center',
                width: '100%',
                margin: '0 auto 3em',
                maxWidth: '560px',
            },
            wrapper: {
                maxWidth: theme.manakin.wrapper['small'],
                position: 'relative',
                width: '100%',
                margin: '0 auto',
            },
            smallWrapper: {
                maxWidth: theme.manakin.wrapper['extraSmall'],
                width: '90%',
                margin: '0 auto',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            },
            footNote: {
                marginTop: theme.spacing(4),
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
            },
            link: {
                textDecoration: 'none',
                display: 'block',
                textAlign: 'center',
                color: theme.manakin.defaultContentColor
                    ? theme.manakin.defaultContentColor[500]
                    : 'black',
                marginTop: theme.spacing(3),
                fontSize: '18px',
                '&:visited': {
                    color: theme.manakin.defaultContentColor
                        ? theme.manakin.defaultContentColor[500]
                        : 'black',
                },
            },
        }),
    {
        name: 'AuthenticationCmsAuthenticator',
    }
);
