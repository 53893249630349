import React from 'react';
import { LongArrow } from '@manakin/app-core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { backgroundColorKeys } from '../constants';

const useStyles = makeStyles((theme) => ({
	root: {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		color: theme.manakin.defaultTitleColor[500],
        marginBottom: 0
	},
	longArrowRoot: {
		marginLeft: 16,
		color: theme.manakin.defaultTitleColor[500],
	},
    primary: {
        color: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),
        marginBottom: 0,
        '& $longArrowRoot': {
            color: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),
        }
    },
    secondary: {
        color: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
        marginBottom: 0,
        '& $longArrowRoot': {
            color: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
        }
    },
    noBG: {
        color: theme.manakin.defaultContentColor[500],
        marginBottom: 0,
        '& $longArrowRoot': {
            color: theme.manakin.defaultContentColor[500],
        }
    }
}));

const SeeMoreButton = (props) => {
	const { onClick, backgroundColor } = props;
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<Typography
			variant="body1"
			onClick={onClick}
			className={classNames(
				classes.root,
				{ [classes.primary]: backgroundColor === backgroundColorKeys.primary },
				{ [classes.secondary]: backgroundColor === backgroundColorKeys.secondary },
				{ [classes.noBG]: backgroundColor === backgroundColorKeys.none },
			)}
		>
			{t('common.view-all')}
			<LongArrow className={classes.longArrowRoot} />
		</Typography>
	);
};

export default SeeMoreButton;
