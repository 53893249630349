import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { LoadImage, Tags } from '@manakin/app-core';
import { styles } from './styles';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useViewCounting } from "@manakin/hooks";
var FileSaver = require('file-saver');

const maxCharacters = 100;

const FactCardItem = (props) => {
	const { classes, factCard } = props;
	const history = useHistory();
	const { t } = useTranslation();
	const { viewFactCard } = useViewCounting();

	const truncateText = factCard.text ? factCard.text.length > maxCharacters : false;
	const isFactCardFile = factCard && factCard.file && factCard.file.url !== undefined;

	// Truncating the text instead of doing by css, because limiting by amount of lines will differ per device and
	// also we don't know when we should show the "read more" link
	const text = factCard.text ? factCard.text.slice(0, Math.min(factCard.text.length, maxCharacters)).trim() : '';

	const handleClick = useCallback(() => {
		if (factCard.file && factCard.file.url) {
			viewFactCard(factCard.id);
			window.open(factCard.file.url);
		} else {
			history.push(`/factcard/${factCard.id}`);
		}
	}, [history, factCard]);

	return (
		<Grid className={classes.singleCard} item xs={12} sm={6} md={4}>
			<div className={classes.cardHover} onClick={handleClick}>
				<div className={classes.factCard}>
					<Tags className={classes.tags} tags={factCard.tags || []} />
					<div className={classes.imageWrapper}>
						<div className={classes.image}>
							<LoadImage src={factCard.image ? factCard.image.url : ''} asset={factCard.image} enableFocusPoint={true} />
						</div>
					</div>
					<Typography component="h6" variant="h6" className={classes.heading}>
						{factCard.title}
					</Typography>
					<Typography
						classes={{
							body1: classes.body,
						}}
						component="p"
						variant="body1"
					>
						{text}
						{truncateText ? '…' : ''}
						{!isFactCardFile && truncateText && (
							<Link to={`/factcard/${factCard.id}`} className={classes.readMoreLink} onClick={(e) => e.preventDefault()}>
								{t('common.read-more').toLocaleLowerCase()}
							</Link>
						)}
					</Typography>
				</div>
			</div>
		</Grid>
	);
};

export default withStyles(styles, { name: 'FactCards' })(FactCardItem);
