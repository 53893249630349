import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { compose } from 'recompose';
import { GQL_FETCH_NEWS_LIST } from '../graphql';
import { Loader } from '@manakin/app-core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import SeeMoreButton from '../SeeMoreButton';
import { useQuery } from '@apollo/react-hooks';
import NewsItem from './NewsItem';
import { isMobile } from '@manakin/app-core/lib';
import NewsCarousel from './NewsCarousel';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import { backgroundColorKeys } from '../../constants';
import WidgetHeader from '../WidgetHeader';

const styles = (theme) => ({
    root: {
        paddingTop: '45px',
		marginBottom: '2.5rem',

        [theme.breakpoints.up('sm')]: {
            padding: '45px 0',
        },
    },
    wrapper: {
    },
    title: {
        marginBottom: '4rem'
    },
	newsListWrapper: {
		overflow: 'hidden',
		display: 'grid',
		gridTemplateColumns: '1fr',
		gridGap: '24px',
		marginBottom: '2.5rem',

		[theme.breakpoints.up('md')]: {
			gridTemplateColumns: '1fr 1fr',
		},
	},
	newsItemRoot: {
		display: 'flex',
		flexDirection: 'row',
	},
    newsItem: {
        padding: '0px 12px 25px',
        [theme.breakpoints.up('md')]: {
            padding: '0px 25px 25px',
        },
    },
	primary: {
		backgroundColor: theme.manakin.primaryWorkformColor[500],
        padding: 50,

		'& $title': {
			color: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),
		}
	},
	secondary: {
		backgroundColor: theme.manakin.secondaryWorkformColor[500],
        padding: 50,

		'& $title': {
			color: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
		}
	},
	noBG: {
		backgroundColor: 'white',
        padding: 50,

		'& $title': {
            color: theme.manakin.defaultContentColor[500],
		}
	}
});

const NewsWidget = (props) => {
	const { classes, title, gridProps, backgroundColor } = props;
	const { t } = useTranslation();
	const history = useHistory();

	const [newsList, setNewsList] = useState('');

	const { data, loading } = useQuery(GQL_FETCH_NEWS_LIST, {
		variables: {
			page: 0,
			pagesize: 4,
			filter: {
				category: props.category ? [...props.category] : null,
			},
		},
		onCompleted: () => {
			if (data && data.newsList) {
				setNewsList(data.newsList);
			}
		},
	});

	const handleGoToNews = () => history.push('/account/news');

	if (loading) {
		return <Loader />;
	}

	if (newsList.count === 0) {
		return '';
	}

	if (isMobile) {
		return (
            <Grid className={classes.root} item {...gridProps}>
                <NewsCarousel title={title} backgroundColor={backgroundColor} />
            </Grid>
        )
	} else {
		return (
            <Grid className={classes.root} item {...gridProps}>
				<div className={classNames(
					classes.wrapper,
					{ [classes.primary]: backgroundColor === backgroundColorKeys.primary },
					{ [classes.secondary]: backgroundColor === backgroundColorKeys.secondary },
					{ [classes.noBG]: backgroundColor === backgroundColorKeys.none },
				)}
			>
					<WidgetHeader title={title || t('app-core.news-carousel.title')} backgroundColor={backgroundColor} onClick={handleGoToNews}></WidgetHeader>
                    <div className={classes.newsListWrapper}>
                        {newsList.news &&
                            newsList.news.map((news, idx) => (
                                <NewsItem
                                    {...news}
                                    classes={{
                                        root: classes.newsItemRoot,
                                    }}
									backgroundColor={backgroundColor}
                                />
                            ))}
                    </div>
                </div>
			</Grid>
		);
	}
};

export default compose(withStyles(styles))(NewsWidget);
