import { createStore, compose, applyMiddleware } from 'redux';
// import combinedReducers from '../reducers.js';
import middlewares from './middlewares.js';
import { patchReduxState, getInitialReduxState } from './storage';
import { createReduxHistoryContext, reachify } from 'redux-first-history';
import throttle from 'lodash/throttle';
import { createBrowserHistory } from 'history';

import { reducer as authenticationReducer } from '@manakin/authentication/reducer';
import { reducer as authorizationReducer } from '@manakin/authorization/reducer';
import { reducer as coreReducer } from '@manakin/core/reducer';
import { reducer as appCoreReducer } from '@manakin/app-core/reducer';
import { AUTHENTICATION_STATE_ROOT } from '@manakin/authentication/selectors';
import { AUTHORIZATION_STATE_ROOT } from '@manakin/authorization';
import { CORE_STATE_ROOT } from '@manakin/core/selectors';
import { APP_CORE_STATE_ROOT } from '@manakin/app-core/selectors';
import { combineReducers } from 'redux';
import config from '../config/config';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const initialStoreState = getInitialReduxState();

const {
    createReduxHistory,
    routerMiddleware,
    routerReducer,
} = createReduxHistoryContext({
    history: createBrowserHistory({
        basename: process.env.REACT_APP_BASENAME || '/',
    }),
});

let _store = createStore(
    combineReducers({
        router: routerReducer,
        [AUTHENTICATION_STATE_ROOT]: authenticationReducer,
        [AUTHORIZATION_STATE_ROOT]: authorizationReducer,
        [CORE_STATE_ROOT]: coreReducer,
        [APP_CORE_STATE_ROOT]: appCoreReducer,
        config,
    }),
    initialStoreState,
    composeEnhancers(
        applyMiddleware(...middlewares),
        applyMiddleware(routerMiddleware)
    )
);

_store.subscribe(
    throttle(() => {
        patchReduxState(store.getState());
    }, 1000)
);

export const store = _store;
export const history = createReduxHistory(store);
export const reachHistory = reachify(history);
