"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _uniqueId = require("lodash/uniqueId");

var _uniqueId2 = _interopRequireDefault(_uniqueId);

var _reactI18next = require("react-i18next");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var readSpeakerHtml = function readSpeakerHtml(t, readSpeakerId) {
    return "\n\t<div id='readspeaker_button" + (0, _uniqueId2.default)() + "' class='rs_skip rsbtn rs_preserve'>\n\t\t<a  rel='nofollow' class='rsbtn_play' accessKey='L' \n\t\t \ttitle='" + t("app.read-speaker.title") + "' \n\t\t \thref='//app-eu.readspeaker.com/cgi-bin/rsent?customerid=10096&amp;lang=nl_nl&amp;readid=" + readSpeakerId + "&amp;url=" + encodeURI(window.location.href) + "'>\n\t\t\t<span class='rsbtn_left rsimg rspart'><span class='rsbtn_text'><span>" + t("app.read-speaker.action") + "</span></span></span>\n\t\t\t<span class='rsbtn_right rsimg rsplay rspart'></span>\n\t\t</a>\n\t</div>\n";
};

var useReadSpeaker = function useReadSpeaker(readSpeakerId) {
    var _useTranslation = (0, _reactI18next.useTranslation)(),
        t = _useTranslation.t;

    var _useState = (0, _react.useState)(),
        _useState2 = _slicedToArray(_useState, 2),
        readSpeaker = _useState2[0],
        setReadSpeaker = _useState2[1];

    (0, _react.useEffect)(function () {
        window.ReadSpeaker && setReadSpeaker(readSpeakerHtml(t, readSpeakerId));
    }, [window.ReadSpeaker]);

    (0, _react.useEffect)(function () {
        readSpeaker && window.ReadSpeaker && window.ReadSpeaker.ui && window.ReadSpeaker.ui.addClickEvents();
    }, [readSpeaker]);

    return { readSpeaker: readSpeaker, readSpeakerId: readSpeakerId };
};

exports.default = useReadSpeaker;