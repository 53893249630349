import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    Typography,
    withStyles,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { SliderInput } from '@manakin/core';
import { compose } from 'recompose';
import renderHTML from 'react-render-html';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
    popupRoot: {
        width: '100%',
        padding: '50px',
        '&:first-child': {
            padding: '50px',
        },
    },
    dialogPaper: {
        padding: 0,
        width: '100%',
        fontSize: '2.2rem',
        position: 'relative',
    },
    heading: {
        width: '100%',
        minHeight: '9rem',
    },
    points: {
        top: '-18px',
        right: '-20px',
        backgroundSize: 'contain',
        backgroundImage: `url(${theme.manakin.pinkDotImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        textAlign: 'center',
        display: 'inline-flex',
        flexFlow: 'column nowrap',
        width: '90px',
        height: '90px',
        justifyContent: 'center',
        position: 'absolute',
        [theme.breakpoints.up('md')]: {
            right: '50px',
            top: '32px',
        },
    },
    pointsTitle: {
        color: 'white',
        fontSize: '2.2rem',
        lineHeight: 1,
        margin: 0,
    },
    pointsBody: {
        color: 'white',
        lineHeight: 1,
        margin: 0,
        fontWeight: 600,
        fontSize: '1.8rem',
    },
    nextQuestion: {
        margin: '0 0 4px',
        fontSize: '2.2rem',
        fontWeight: 'normal',
    },
    nextCategory: {
        color: theme.palette.secondary[400],
        fontSize: '2.2rem',
        fontWeight: 600,
    },
    slider: {
        marginBottom: '50px',
    },
    button: {},
});

const PointsPopup = (props) => {
    const {
        open = false,
        classes,
        stepper,
        correct = null,
        onRealClose,
        points,
    } = props;
    const { t } = useTranslation();

    //state hooks
    const [val, setVal] = useState(0);
    const [hintText, setHintText] = useState(false);

    //effect hooks
    useEffect(() => {
        if (open) {
            setVal(0);
            if (stepper && stepper.items && stepper.items[stepper.step]) {
				if (correct === null) {
					setHintText(stepper.items[stepper.step].hintText || false);
				} else {
					setHintText(stepper.items[stepper.step + 1].hintText || false);
				}
            }
        }
    }, [open]);

    //functions
    const handleClick = () => {
        props.onChange(val);
        if (correct === null) {
            props.onStart();
        } else {
            stepper.onSubmit(correct).then((data) => {
                if (data < 0) {
                    props.onFinish(correct);
                }
            });
        }
        onRealClose();
    };

    const handleChange = (value) => {
        setVal(value);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={props.onClose}
                PaperProps={{ classes: { root: classes.dialogPaper } }}
            >
                <DialogContent className={classes.popupRoot}>
                    <div className={classes.points}>
                        <Typography
                            variant="h3"
                            className={classes.pointsTitle}
                        >
                            {points}
                        </Typography>
                        <Typography
                            variant="body1"
                            className={classes.pointsBody}
                        >
                            {t("app.elements.betting-game.points-dialog.title")}
                        </Typography>
                    </div>
                    <div className={classes.heading}>
                        {hintText && (
                            <React.Fragment>
                                <Typography
                                    className={classes.nextQuestion}
                                    variant="body2"
                                >
                                    {t("app.elements.betting-game.points-dialog.hint")}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    className={classes.nextCategory}
                                    component="div"
                                >
                                    {renderHTML(hintText)}
                                </Typography>
                            </React.Fragment>
                        )}
                    </div>
                    <div className={classes.slider}>
                        <SliderInput
                            label={t("app.elements.betting-game.points-dialog.bet-amount-slider-label")}
                            onChange={handleChange}
                            max={points > 100 ? 100 : points}
                        />
                    </div>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        className={classes.button}
                        onClick={handleClick}
                    >
                        {t("app.elements.betting-game.points-dialog.action")}
                    </Button>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default compose(
    withStyles(styles, { name: 'appPointsPopup' })
)(PointsPopup);
