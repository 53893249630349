"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.GQL_FETCH_RATING_PROGRESS = undefined;

var _templateObject = _taggedTemplateLiteral(["\n\tquery ratingProgress($programId: ID!) {\n\t\tratingProgress(programId: $programId) {\n\t\t\ttotalRatingElements\n\t\t\ttotalRankedSufficient\n\t\t\tprogress\n\t\t}\n\t}\t\n"], ["\n\tquery ratingProgress($programId: ID!) {\n\t\tratingProgress(programId: $programId) {\n\t\t\ttotalRatingElements\n\t\t\ttotalRankedSufficient\n\t\t\tprogress\n\t\t}\n\t}\t\n"]);

var _graphqlTag = require("graphql-tag");

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_RATING_PROGRESS = exports.GQL_FETCH_RATING_PROGRESS = (0, _graphqlTag2.default)(_templateObject);